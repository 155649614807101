const defaultOptions = new Map();

function bridgeOptionsState(state = defaultOptions, action) {
  //reducer
  switch (action.type) {
    case "UPDATE_BRIDGE_OPTIONS":
      let bridgeOptionsMap = new Map();
      if (action.clear) {
        return bridgeOptionsMap;
      }
      bridgeOptionsMap.set(action.scenarioId, action.bridgeOptions);
    return bridgeOptionsMap;
    case "GET_BRIDGE_OPTIONS":
      return state;
    default:
      return state;
  }
}

export { bridgeOptionsState };
