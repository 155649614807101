import { useEffect } from "react";
import { useState } from "react";
import { DROPDOWN_TYPE, HEATMAP } from "../../class/constants";
import { findOptionByKey } from "../../class/utils";
import Container from "../manageColumns/Container";
import { CustomSelect } from "../../form/elements";
import Dropdown from "../../newComponents/DropDown";

const HMConfigureSelect = ({
  stacksData,
  defaultPslData,
  defaultStack,
  onStackChange,
  fillConfObj
}) => {
  const [stack, setStack] = useState(defaultStack);
  const [pslData, setPslData] = useState(defaultPslData);

  useEffect(() => {
    // when changing stack, this will allow the component to rerender if the pslines were changed
    if(JSON.stringify(defaultPslData) !== JSON.stringify(pslData)) {
      setPslData(defaultPslData);
    }

    $(".heatmap-configure .dropdown.radio-select").addClass("heatmap-configure");
    $(".heatmap-configure.dropdown-tree ul").addClass("heatmap-configure");
    $(".heatmap-configure.dropdown-tree li").addClass("heatmap-configure");
    $(".heatmap-configure .search").addClass("heatmap-configure");
  });

  const handleStackChange = (selectedStack) => {
    fillConfObj(HEATMAP.CONFIGURE_OBJECT.stack, selectedStack.value);
    setStack(selectedStack);
    onStackChange(selectedStack.value);
  };

  const handlePSLChange = (node) => {
    fillConfObj(HEATMAP.CONFIGURE_OBJECT.selected_psl, node)
    let updatedData = updateAllData(pslData, false); // uncheck all checked lines
    updatedData = updateData(pslData, node);
    setPslData(updatedData);
  };

  /**
   * loops over the lines and their children and updates it to checked or not checked
   * @param {} arr
   * @param {*} isChecked
   * @returns
   */
  const updateAllData = (arr, isChecked) => {
    arr.forEach((row) => {
      row.checked = isChecked;
      if (!row.children) {
        return;
      }
      updateAllData(row.children, isChecked);
    });

    return arr;
  };

  /**
   * loops over the lines and their children to find the matching node and updates it
   * @param {} arr
   * @param {*} node
   * @returns
   */
  const updateData = (arr, node) => {
    arr.forEach((row) => {
      if (row.value === node.value) {
        row.checked = node.checked;
        return;
      }
      if (!row.children) {
        return;
      }
      updateData(row.children, node);
    });

    return arr;
  };

  const stackLabel = ({label, isDisabled, tooltipText}) => (
    <div className={"heatmap-configure"} uk-tooltip={!!tooltipText ? tooltipText : ""}> {label} </div>
  );

  return (
    <div className="heatmap-configure uk-margin-bottom">
      <div className="heatmap-configure uk-margin-bottom">
        <label className="heatmap-configure label-configure-landscape">
          {HEATMAP.LABELS.stack_label}
        </label>
        <Dropdown
          id="select-set"
          className="uk-cursor-pointer uk-padding-xsmall-top input__dropdown"
          value={findOptionByKey(stacksData, stack)}
          onChange={(e) => handleStackChange(e)}
          options={stacksData}
          formatOptionLabel={stackLabel}
          // class={"heatmap-configure configure-table-data-select"}
          type={DROPDOWN_TYPE.INPUT}
        />
      </div>
      <div className="heatmap-configure ">
        <div className="heatmap-configure">
          <label className="heatmap-configure label-configure-landscape">
            {HEATMAP.LABELS.psl_label}
          </label>
          <i
            className="heatmap-configure fs-12 fal fa-info-circle uk-margin-xsmall-left uk-cursor-pointer"
            uk-tooltip={"title:" + HEATMAP.INFO.psl_tooltip}
          />
        </div>
        <Container
          data={pslData}
          onChange={(e) => handlePSLChange(e)}
          mode={"radioSelect"}
          className={
            "heatmap-configure dropdown-tree heatmap-configure-dropdown uk-margin-small-top "
          }
        />
      </div>
    </div>
  );
};

export default HMConfigureSelect;
