var isNewDesign = false;
function createTabulatorInput(oldClass) {
    var input = document.createElement("input");
    if (isNewDesign){
        for (let classes of oldClass) {
            input.classList.add(classes);
        }
        input.classList.add("tabulator__input")
    }else{
        for (let classes of oldClass) {
            input.classList.add(classes);
        }
    } 
    return input;
}

export {createTabulatorInput};