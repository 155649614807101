const ReportHeader = ({ headerElements }) => {
  return (
    <div className="report_header_elements_container">
      {headerElements &&
        headerElements.map(function (item) {
          return item;
        })}
    </div>
  );
};

export { ReportHeader };
