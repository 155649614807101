import React, { Component } from "react";
import { formatValString } from '../../class/format.js';
import { ALL_WIDGETS, DASHBOARDS, FormatTypes } from "../../class/constants.js";
import { lang } from "../../language/messages_en.js";
import { deepCompareObjects } from "../../class/utils.js";
const _value = "value";
const _value0 = "value0";
const _value1 = "value1";
const _valueType = "valueType";
const _arrow = "arrow";
const _up ="up";
const _footerValue ="footerValue";
const _footerVariance ="footerVariance";
const _footerValueType = "footerValueType";
const _footerVarianceType = "footerVarianceType";
const _value1Prev ="value1_previous";
const _value0Prev ="value0_previous";
class MetricWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    shouldComponentUpdate(nextProps) {
        return !deepCompareObjects(nextProps, this.props);  //each widget was rendering 12 times, now only once on mount and once when data is fetched
    }

    render() {
        let data = this.props.data;
        data = data || {_footerValue: 0, _footerVariance: 0, _value:0, _footerValueType:FormatTypes.NUMERIC};
        let sign1 = Object.keys(data).length > 0 && data[_footerValue] && data[_footerVariance] && Number(Number(data[_footerVariance]).toFixed(2))> 0 ? "+" : "";
        let sign2 = Object.keys(data).length > 0 &&  data[_footerVariance] && data[_footerVariance] && Number(Number(data[_footerVariance]).toFixed(2)) > 0 ? "+" : "";
        let isLineAttribute = this.props.config && this.props.config.line_attribute && this.props.config.line_attribute.type === DASHBOARDS.WIDGET_TYPES.LINE_ATTRIBUTE;
        let value  = Object.keys(data).length > 0 && data[_value] && data[_valueType] ? formatValString(data[_value], (isLineAttribute && data[_valueType] === FormatTypes.AMOUNT ? FormatTypes.NUMERIC : data[_valueType])) : "";
        let footerValue = Object.keys(data).length > 0 && data[_footerValue] && data[_footerValueType] ?
        sign1 + formatValString(data[_footerValue], (isLineAttribute && data[_footerValueType] === FormatTypes.AMOUNT ? FormatTypes.NUMERIC : data[_footerValueType])) : "";
        let  footerVariance = Object.keys(data).length > 0 &&  data[_footerVariance] &&  data[_footerVarianceType] ?
        sign2 + formatValString(data[_footerVariance], (isLineAttribute && data[_footerVarianceType] === FormatTypes.AMOUNT ? FormatTypes.NUMERIC : data[_footerVarianceType])) : "";
        let arrowColor = ""
        if(data[_arrow] &&  data[_arrow] === _up  && this.props.config[DASHBOARDS.CHANGE_COLOR_LOGIC] === lang.arrow.green_up.value){
            arrowColor = "greenText";
        } else if (data[_arrow] &&  data[_arrow] === _up  && this.props.config[DASHBOARDS.CHANGE_COLOR_LOGIC] === lang.arrow.red_up.value){
            arrowColor = "uk-text-primary";
        } else if (data[_arrow] &&  data[_arrow] !== _up  && this.props.config[DASHBOARDS.CHANGE_COLOR_LOGIC] === lang.arrow.green_up.value){
            arrowColor = "uk-text-primary";
        }  else if (data[_arrow] &&  data[_arrow] !== _up  && this.props.config[DASHBOARDS.CHANGE_COLOR_LOGIC] === lang.arrow.red_up.value){
            arrowColor = "greenText";
        } 
        let color = Number(data[_footerValue]) === 0 ? "orange-color" : arrowColor;
        let TextColor =  Object.keys(data).length > 0 && data[_value] && data[_value] < 0 &&  "uk-text-primary";
        let _class = Number(data[_footerValue]) === 0 || footerVariance === "-" ? "fas fa-minus orange-color" : data[_arrow] ? data[_arrow] === _up ? "fas fa-caret-up fa-lg "+arrowColor : "fas fa-caret-down fa-lg "+arrowColor : "";       
        let value0 = "";
        let value1 = "";
        let footerVariance0 = "";
        let footerVariance1 = "";
        let color0 = "";
        let color1 = "";
        let TextColor0 = "";
        let TextColor1 = "";
        if (this.props.config.count_type === FormatTypes.RANGE){
            value0 = value.split(" to ")[0];
            value1 = value.split(" to ")[1];
            footerVariance0 = footerVariance.split(" to ")[0];
            footerVariance1 = footerVariance.split(" to ")[1];
            color0 = Object.keys(data).length > 0 && data[_value0Prev] && Number(data[_value0Prev]) < 0 ? "uk-text-primary" : "";
            color1 = Object.keys(data).length > 0 && data[_value1Prev] && Number(data[_value1Prev]) < 0 ? "uk-text-primary" : "";
            TextColor0 =  (Object.keys(data).length > 0 && data[_value0] && data[_value0] < 0)? "uk-text-primary" :"";
            TextColor1 =  (Object.keys(data).length > 0 && data[_value1] && data[_value1] < 0)? "uk-text-primary" :"";
        }
        return (
            this.props.config.count_type !== FormatTypes.RANGE ?
            <div className="uk-flex uk-flex-column uk-flex-middle uk-flex-center uk-padding-small-top">
                <span>
                    {this.props.secondHeader}
                </span>
                <div>
                    <span className={"widget-text-primary " + TextColor}>{value}</span> 
                    <i className={"uk-margin-small-left fa-2x " + _class} />
                </div>
                <div className={"uk-display-grid " + color} >
                    <span className={"uk-nav-center uk-text-xmedium" + (this.props.comparison !== ALL_WIDGETS.YEAR_OVER_YEAR? " uk-text-capitalize":"") +  (_class ==="fas fa-minus orange-color" ? " text-black" :"")}>{this.props.comparison ===  DASHBOARDS.COMPARISON_TYPES.NONE ? "" : this.props.comparison === ALL_WIDGETS.YEAR_OVER_YEAR? "Year over Year" :this.props.comparison}</span> {/*as per PI-16130, in "Year over year", the 2 Y must be capital but the O don't */}          
                    <div className="uk-flex uk-flex-center">
                    {data[_footerVariance] ? 
                        <span className="widget-text-secondary uk-padding-xsmall-right">{footerVariance}</span>
                    :""}
                    {data[_footerValue] ? 
                        <span className="widget-text-secondary">{footerValue}</span>
                        :""}
                    </div>
                </div>
            </div>
            :
            <div className="uk-flex uk-flex-column uk-flex-middle uk-flex-center uk-padding-small-top">
                <span>
                    {this.props.secondHeader}
                </span>
                <span className="uk-nav-center uk-text-xmedium ">{lang.range}</span>
                <div className="metric-range-container">
                    <span className={"widget-text-primary " + TextColor0}>{value0}</span> 
                    <span className={"widget-text-primary "}>{" to "}</span> 
                    <span className={"widget-text-primary " + TextColor1}>{value1}</span> 
                    <i className={"uk-margin-small-left fa-2x " + _class} />
                </div>
                <div className={"uk-display-grid " + color} >
                    <span className={"uk-nav-center uk-text-xmedium" + (this.props.comparison !== ALL_WIDGETS.YEAR_OVER_YEAR? " uk-text-capitalize":"")}>{this.props.comparison ===  DASHBOARDS.COMPARISON_TYPES.NONE ? "" : this.props.comparison === ALL_WIDGETS.YEAR_OVER_YEAR? "Year over Year" :this.props.comparison}</span> {/*as per PI-16130, in "Year over year", the 2 Y must be capital but the O don't */}          
                    <div className="uk-flex uk-flex-center">
                    {data[_footerVariance] ? 
                        <div>
                            <span className={"widget-text-secondary uk-padding-xsmall-right " + color0}>{footerVariance0}</span>
                            <span className={"widget-text-secondary uk-padding-xsmall-right "}>{"to"}</span>
                            <span className={"widget-text-secondary uk-padding-xsmall-right " + color1}>{footerVariance1}</span>
                        </div>
                    :""}
                    {data[_footerValue] ? 
                        <span className="widget-text-secondary">{footerValue}</span>
                        :""}
                    </div>
                </div>
            </div>
   
        );
    }
}

export default MetricWidget;