import {FETCH_METHOD, fetchAPI, FETCHAPI_PARAMS} from "../../../../class/networkUtils";
import {forwardRef, useEffect, useImperativeHandle, useRef, useState, createContext} from "react";
import {ReactComponent as CalendarsIcon} from "../../../../styles/images/icons/calendars.svg";
import NewBanner from "../../../NewBanner";
import { copyObjectValues, formatDateMMDDYYY, getTranslationFile } from "../../../../class/utils";
import AssignGlStrings from "./AssignGlStrings";
import { Tabs } from "../../../../form/elements";
import { BUTTON_TYPE, BUTTON_VARIANT, costtype, DIALOG_SIZE, LISTENER, PS_MAPPING, SIZES } from "../../../../class/constants";
import { linearizeHierarchy } from "../../../../class/array";
import GlStringsReviewTable from "./GlStringsReviewTable";
import Button from "../../../../newComponents/Button";
import { ReactComponent as NextIcon } from "../../../../styles/images/icons/next-white.svg";
import Modal from "../../../../newComponents/Modal";
import { emitEvent, useListener } from "../../../../actions/CustomEventHandler";
import MonthlyBuildCurrentPeriodBreadcrumbs from "../../MonthlyBuildCurrentPeriodBreadCrumps";


const _classList = "hideMe disableMe"
const _className = "className";
const  _assignedCombinations = "assigned_combinations";
const  _excluded =  PS_MAPPING.PSS_FILTER_LABELS.EXCLUDED_LABEL;
const  _unAssigned = PS_MAPPING.PSS_FILTER_LABELS.UNASSIGNED_LABEL;
const lang = getTranslationFile();

const SetupProfitStacks = (props, ref) => {


    useListener(LISTENER.PORFILE.LOGOUT_START, (eventData) => {
        if(canSaveProgress){
            prepareBeforeLeave("logOut");
        } else {
            emitEvent(LISTENER.PORFILE.LOGOUT_END);
        }
    });

    useListener(LISTENER.PORFILE.CHANGE_CLIENT_START, (eventData) => {
        if(canSaveProgress){
            prepareBeforeLeave("switchClient", eventData.detail);
        } else {
            emitEvent(LISTENER.PORFILE.CHANGE_CLIENT_END, eventData.detail);
        }
    });
    
    const [glStringsData, setGlStringsData] = useState();
    const [glStringsOriginalData, setGlStringsOriginalData] = useState();
    const [selectedTab, setSelectedTab] = useState(0);
    const [pslTree,setPSLTree] = useState([]);
    const [transitionDisplayMap,setTransitionDisplayMap] = useState({});
    const [lstTransitionColumnNames,setLstTransitionColumnNames]= useState([]);
    const [linearizedPSLs,setLinearizedPSLs] = useState([]);
    const [isError,setIsError] = useState(false);
    const [saveGlModalOpen, setSaveGlModalOpen] = useState(false);
    const [canSaveProgress, setCanSaveProgress] = useState(false);
    const [redirectTo, setRedirectTo] = useState(false);
    const [eventData, setEventData] = useState(false);

    const assignGlStringsRef = useRef();
        
    const {
        endDate,
        period,
        startDate,
        hideCostCenters,
        handleBack,
        renderMonthlyBuildBanner
    } = props;

    const proceed = () => {
        document.getElementById("monthly-build-next").click();
    }

    const saveProgress = (callback) => {
        const updateCombinations = stringsToBeSaved();
        const partialSave = updateCombinations.find( e => !e.pssId && !e.excluded) ? true : false; // if there is still unassigned combinations, then it is partial, else if all are assigned then it is fully save
        props.saveGlStrings(updateCombinations, partialSave, callback, true);
    }

    useImperativeHandle(ref, () => ({
        getGlStringsDataToSave: () => {
            return prepareSaveData();
        },
        setCanSaveProgress: () => {
            return setCanSaveProgressParam();
        },
        getCanSaveProgress : () => {
            return canSaveProgress;
        },
        handleBack: (destination) => {
            return prepareBeforeLeave(destination);
        },
        setSaveGlModalOpen:(param)=>{
            return setSaveGlModalOpenParam(param);
        }
    }));

    const setCanSaveProgressParam = () => {
        setCanSaveProgress(false); // now data is the latest, turn off the save button
        setGlStringsOriginalData(glStringsData); // after save, the original data is the current data
    }

    const setSaveGlModalOpenParam = (param) => {
        prepareBeforeLeave(param)
    }

    const tabs = [
        lang.monthly_build.setup_profit_stacks.tabs.strings,
        lang.monthly_build.setup_profit_stacks.tabs.review
    ]
        

    const formulatePSLTreeData = (pslTree) => {
        for(let e in pslTree){
            let obj = pslTree[e];
            obj.label = obj.name;
            obj.value = obj.pssId;
            if(obj.children){
                obj[_className] = _classList;
                obj.label = obj.name;
                obj.value = obj.pssId;
                formulatePSLTreeData(obj.children);
            }
        }
    } 

    useEffect(() => {
        getGlStringsData(period);
       
    }, []);

    useEffect(() => {
        if(glStringsData?.unassignedCombinations?.length === 0) {
            $(".page").css({width:"39vw"});
        } else if(glStringsData) {
            checkForChange();
        }
    }, [glStringsData]);

    useEffect(() => {
        buttonToShow(selectedTab, glStringsData);
        if(selectedTab === 0) { //In case you were in review screen then you returned to assign gl strings tab
           setIsError(false);
           if([_unAssigned,_excluded].includes(glStringsData?.unassignedCombinations[0]?.assigned_to[0])){
                assignGlStringsRef?.current?.onChangePSL(undefined,[])
           } else {
                assignGlStringsRef?.current?.onChangePSL(glStringsData?.unassignedCombinations[0]?.assigned_to[0],[glStringsData?.unassignedCombinations[0]?.assigned_to[0]])
           }
        }
    },[selectedTab])

    const buttonToShow = (tab, glStringsDataParam) => {
        if(glStringsDataParam?.unassignedCombinations?.length > 0){
            document.getElementById("monthly-build-next").style.display = "none";
            if (tab == 0){
                document.getElementById("save-gl-strings").style.display = "none";
                document.getElementById("go-to-review").style.display = "block";
                document.getElementById("save-progress").style.display = "block";
            } else {
                document.getElementById("save-gl-strings").style.display = "block";
                document.getElementById("go-to-review").style.display = "none";
                document.getElementById("save-progress").style.display = "none";
            }
        }
    }

    const formatPSLPredictionData = (data,linearizedPSLs) => {
        const unassignedCombinations = data.unassignedCombinations
        let aiPredictions = data.aiPredictions;
        for(let e in unassignedCombinations){
            let newlyCreatedSuggestedPSLs = [];
            let combination = unassignedCombinations[e].fields_combination;
            let suggestedPSLs = aiPredictions[combination];
            if(suggestedPSLs && suggestedPSLs.length) {
                for(let sPSL in suggestedPSLs){
                    let pslName = suggestedPSLs[sPSL].predictedPsl;
                    let confidenceRate = suggestedPSLs[sPSL].confidenceRate;
                    let pslObj = linearizedPSLs.filter(element => element.name.toLowerCase().trim() === pslName.toLowerCase().trim()).length ? linearizedPSLs.filter(element => element.name.toLowerCase().trim() === pslName.toLowerCase().trim())[0] : {};
                    if(Object.keys(pslObj).length) {
                        pslObj.confidenceRate = confidenceRate;
                        newlyCreatedSuggestedPSLs.push(pslObj)
                    }
                }
                unassignedCombinations[e]["suggestedPSLs"] = newlyCreatedSuggestedPSLs.sort((a,b) => b.confidenceRate - a.confidenceRate);
            } else {
                unassignedCombinations[e]["suggestedPSLs"] = [];
            }
        }
        return data;
    }

    const formatAssignedData = (data,linearizedPSLs,type) => {
        const assignedCombinations =  type === _assignedCombinations ? data.assignedCombinations : data.unassignedCombinations.filter((combination) =>  {        
            return ![_unAssigned,_excluded].includes(combination.assigned_to[0])
        });
        for(let e in assignedCombinations){
            let assignedTo = assignedCombinations[e].assigned_to[0];
            let assignedToPsl = linearizedPSLs.filter((psl)=>{
                return psl.name === assignedTo;
            })
            let path = getPSLPathParam(assignedToPsl[0], linearizedPSLs);
            assignedCombinations[e].assigned_to = assignedToPsl;
            assignedCombinations[e]["path"] = path;
        }
        return data;
    }

    const getGlStringsData = (currentPeriod) => {
        let query = {
          action: "getGlStringsData", //It's still required by networkUtils.js
          period: currentPeriod
        };
        
        let onThenCallback = (data) => {
            let pslTree = data.pslTree;
            pslTree = pslTree.filter(e=> ![costtype.attribute,costtype.calculated].includes(e.costtype)) //Remove attribute and calculated lines
            formulatePSLTreeData(pslTree);
            setPSLTree(pslTree);
            let linearizedPSLs = linearizeHierarchy(pslTree,"children",true);
            let linearizedPSLsForPrediction = linearizedPSLs.filter(e=>!e.hasChildren)
            let modifiedData = formatPSLPredictionData(data,linearizedPSLsForPrediction);
            modifiedData = formatAssignedData(modifiedData,linearizedPSLs,"");
            modifiedData = formatAssignedData(modifiedData,linearizedPSLs,_assignedCombinations);
            let transitionDisplayMap =  modifiedData?.assignedCombinations?.length > 0 ? modifiedData?.assignedCombinations?.[0]?.transitionDisplayMap : modifiedData?.unassignedCombinations?.[0]?.transitionDisplayMap;
            let lstTransitionColumnNames =  modifiedData?.assignedCombinations?.length > 0 ? modifiedData?.assignedCombinations?.[0]?.combinationTransitionColumns : modifiedData?.unassignedCombinations?.[0]?.combinationTransitionColumns;
            lstTransitionColumnNames = lstTransitionColumnNames?.map(e => Object.keys(e)[0]);
            setTransitionDisplayMap(transitionDisplayMap);
            setLstTransitionColumnNames(lstTransitionColumnNames);
            setLinearizedPSLs(linearizedPSLs)
            setGlStringsData(copyObjectValues(modifiedData));
            setGlStringsOriginalData(copyObjectValues(modifiedData));
            buttonToShow(selectedTab, data)
        };
    
        let fetchOptions = {
          [FETCHAPI_PARAMS.funcName]: "getGlStringsData",
          [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
          [FETCHAPI_PARAMS.showLoader]: true,
          [FETCHAPI_PARAMS.path]: "/get-gl-strings-info",
          [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
          [FETCHAPI_PARAMS.query]: query,
          [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
          [FETCHAPI_PARAMS.periods]: period,
          [FETCHAPI_PARAMS.screenName]:lang.observability.monthly_build.assign_gl_strings.screen_name,
          [FETCHAPI_PARAMS.requestDescription]:lang.observability.monthly_build.assign_gl_strings.requests_description.get_gl_strings_data   
        };
        fetchAPI(fetchOptions);
      };
    

    const changeSelectedTab = (selected) => {
        let toBeSelected = selected === tabs[0] ? 0 : 1 ;
        if(toBeSelected !== selectedTab){
            setSelectedTab(toBeSelected)
        }
    }

    const flipSign = (selected) => {
        setGlStringsData((prevGlStringsData) => {
            const updatedData = [...prevGlStringsData.unassignedCombinations];
        
            // Update the selected item
            updatedData[selected] = {
                ...updatedData[selected],
                flipped: !updatedData[selected].flipped,
                total: -1 * updatedData[selected].total,
                edited: true
            };
        
            // Return the updated state
            return { ...prevGlStringsData, unassignedCombinations: updatedData };
        });
      };
    
    const getPSLPath = (pslInfo) => {
        if(pslInfo.path){
            let path  = pslInfo.path;
            let splittedPath = path.split(",")
            let newSplittedPath = splittedPath.slice(0, -2);
            let names = [];
            for(let costkey in newSplittedPath) {
                let name = linearizedPSLs.filter(e=> e.costKey === newSplittedPath[costkey])[0]?.name;
                if(name){
                    names.push(name);
                }
            }
            return names.join(" > ")
        }
    }

    const getPSLPathParam = (pslInfo, linearizedPSLsParam) => {
        if(pslInfo.path){
            let path  = pslInfo.path;
            let splittedPath = path.split(",")
            let newSplittedPath = splittedPath.slice(0, -2);
            let names = [];
            for(let costkey in newSplittedPath) {
                let name = linearizedPSLsParam.filter(e=> e.costKey === newSplittedPath[costkey])[0]?.name;
                if(name){
                    names.push(name);
                }
            }
            return names.join(" > ")
        }
    }
    
    const assignTo = (selectedGLString, selectedPSL, assignedType) =>{
        if(!Object.keys(selectedPSL).length){
            setIsError(true);
        } else {
            let path = getPSLPath(selectedPSL);
            setGlStringsData((prevGlStringsData) => {
                const updatedData = [...prevGlStringsData.unassignedCombinations];
                const ai_suggested = updatedData[selectedGLString].suggestedPSLs.length > 0;
            
                // Update the selected item
                updatedData[selectedGLString] = {
                    ...updatedData[selectedGLString],
                    assigned_to:[selectedPSL],
                    edited: true,
                    path: path,
                    assigned_type: assignedType,
                    ai_suggested: ai_suggested
                };
                // Return the updated state
                return { ...prevGlStringsData, unassignedCombinations: updatedData };
            });
            if(selectedGLString < (glStringsData.unassignedCombinations.length -1) ){
                assignGlStringsRef.current.changeCard(selectedGLString + 2);
            } else {
                assignGlStringsRef.current.setIsMultipleCards(false);
            }
            assignGlStringsRef.current.setIsManualAssign(false);
        }
    }

    const unAssign = (selectedGLString) =>{
        setGlStringsData((prevGlStringsData) => {
            const updatedData = [...prevGlStringsData.unassignedCombinations];
        
            // Update the selected item
            updatedData[selectedGLString] = {
                ...updatedData[selectedGLString],
                assigned_to:[_unAssigned],
                edited: true,
                assigned_type:''
            };
            // Return the updated state
            return { ...prevGlStringsData, unassignedCombinations: updatedData };
        });
        assignGlStringsRef.current.onChangePSL(undefined,[])
        if(glStringsData?.unassignedCombinations[selectedGLString]?.suggestedPSLs?.length > 1) {
            assignGlStringsRef.current.setIsMultipleCards(true)
        } else {
            assignGlStringsRef.current.setIsMultipleCards(false)
        }

        if(glStringsData?.unassignedCombinations[selectedGLString]?.suggestedPSLs?.length) {
            let pssId =  glStringsData?.unassignedCombinations[selectedGLString]?.suggestedPSLs[0].pssId;
            let pslSiblings = assignGlStringsRef.current.getPSLSiblings(Number(pssId));
            if(pslSiblings.length > 1) {
                assignGlStringsRef.current.setShowBanner(true);
            } else {
                assignGlStringsRef.current.setShowBanner(false);
            }
        }
        assignGlStringsRef.current.setIsManualAssign(false);
    }

    const exclude = (selectedGLString) => {
        setGlStringsData((prevGlStringsData) => {
            const updatedData = [...prevGlStringsData.unassignedCombinations];
        
            // Update the selected item
            updatedData[selectedGLString] = {
                ...updatedData[selectedGLString],
                assigned_to:[_excluded],
                edited: true,
                assigned_type:''
            };
            // Return the updated state
            return { ...prevGlStringsData, unassignedCombinations: updatedData };
        });
        if(selectedGLString < (glStringsData.unassignedCombinations.length -1) ){
            assignGlStringsRef.current.changeCard(selectedGLString + 2);
        } else {
            assignGlStringsRef.current.setIsMultipleCards(false);
        }
        assignGlStringsRef.current.setIsManualAssign(false);
    }
              
    const goToCard = (card) => {
        changeSelectedTab(tabs[0]);
        let cardIndex = glStringsData.unassignedCombinations.findIndex( // get index of selected card, combination is the only unique field
            (item) => item.fields_combination === card
          );
          
        assignGlStringsRef.current.changeCard(Number(cardIndex)+1) // +1 because index starts count from 0
    }

    const prepareSaveData = () => {
        if(glStringsData?.unassignedCombinations?.length > 0 ){
            const canSave = !glStringsData.unassignedCombinations.find(item => [_unAssigned].includes(item.assigned_to[0])); // if there is unassigned combinations, block the user from saving.
            if(canSave){
                return stringsToBeSaved(); // returned if user has assigned all combinations
            }
            return canSave; // returned if user did not assign all combinations
        }
        return []; // in case there are no combinations to assign, user is allowed to proceed without save
      }


    const stringsToBeSaved = () => {
        return glStringsData.unassignedCombinations.map(item => ({
            pssId: item.assigned_to[0].pssLeadingId || item.assigned_to[0].pssId,
            combinationId: String(item.raw_file_type_transition_id),
            flipped: item.flipped,
            assignedType: item.assigned_type || "MANUALLY",
            aiSuggested: item.ai_suggested || false,
            excluded: item.assigned_to[0] === _excluded
        }));
    }


/**
 * called when there is change in {glStringsData} to prepare edited data
 * and compare it with original data
 * controls when to switch the save progress button
 */
    const checkForChange = () => {
        let editedGlStrings = glStringsData.unassignedCombinations.filter((item) => item.edited); // get edited data from ui
        if(editedGlStrings.length > 0){ // handle the mounting of screen 
            let editedGlStringsUniqueIndex = editedGlStrings.map( item =>  {return item.fields_combination}); // get combinations that works as unique id
            let GlStringsBeforeEditing = glStringsOriginalData.unassignedCombinations.filter((item) => editedGlStringsUniqueIndex.includes(item.fields_combination)); // get only orignal data of the editied GlStrings
            let changed = compareGlStrings(GlStringsBeforeEditing, editedGlStrings);
            setCanSaveProgress(changed); // enable/disable save progress button, and show modal when exiting the page
        }
    }

    /**
     * takes orignal and edited GlStrings, compare only flipped and assigned_to properties
     * return true on the first change found, else complete the itteration 
     * return false if there is no change
     * 
     * @param {data retrieved from api on screen opened} original 
     * @param {changed data from ui} edited 
     * @returns 
     */
    const compareGlStrings = (original, edited) => {
        // Iterate through each element and compare specific properties
        for (let i = 0; i < original.length; i++) {
            const { flipped: flipped1, assigned_to: assignedTo1 } = original[i];
            const { flipped: flipped2, assigned_to: assignedTo2 } = edited[i];

            // Compare "flipped" and "assigned_to" properties
            if (flipped1 !== flipped2 || JSON.stringify(assignedTo1) !== JSON.stringify(assignedTo2)) {
                return true;
            }
        }

        // If all elements are equal, return false
        return false;
    }

    const saveGlModalActions = () => {
        return (
            <>
                <Button
                    label={lang.monthly_build.setup_profit_stacks.modal.save}
                    variant={BUTTON_VARIANT.PRIMARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={() => closeModal(true)}
                />

                <Button
                    label={lang.monthly_build.setup_profit_stacks.modal.leave}
                    variant={BUTTON_VARIANT.TERTIARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={() => closeModal(false)}
                />
            </>
        )
    }

    const closeModal = (save) => {
        setSaveGlModalOpen(false);
        if(save){
            saveProgress(redirectAfterClose)
        } else if(redirectTo){
            redirectAfterClose();
        }
    }

    const redirectAfterClose = () => {
        switch (redirectTo) {
            case "back":
                handleBack(true);
                break;

            case "logOut":
                emitEvent(LISTENER.PORFILE.LOGOUT_END);
                break;

            case "switchClient":
                if(!document.getElementById("profileContainer")){ // check if the profile is opened, so that the listner is available 
                    document.getElementById("profile-icon").click();
                }
                emitEvent(LISTENER.PORFILE.CHANGE_CLIENT_END, eventData);
                break;

            case "changeReport":
                props.changeReport();
                break;
        
            default:
                setSaveGlModalOpen(false);
                break;
        }
    }

    const saveGlModalBody = () => {
        return (
            <h4>{lang.monthly_build.setup_profit_stacks.modal.progress_lost}</h4>
        ) 
    }

    const prepareBeforeLeave = (destination, event) => {
        if(event){
            setEventData(event);
        } 
        setSaveGlModalOpen(true);
        setRedirectTo(destination);
    }
    let style = {};
    if(selectedTab === 0) {
        $(".step-box").css({width:"73vw"})
        style.width = "47vw"; 
    } else {
        $(".step-box").css({width:"78vw"})
        style.width = $(".step-box").width() - 200
    }
    return (
        <div style={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
             {glStringsData?.unassignedCombinations?.length > 0 &&
                <div className="uk-flex pi-vertical-tab uk-padding-large-top uk-padding-large-right uk-padding-xsmall-bottom">
                    <div className="uk-padding-large-right">
                        <Tabs tabs={tabs} onChange={(e)=>changeSelectedTab(e)} activeTab={tabs[selectedTab]}></Tabs>
                    </div>
                </div>
            }
            <div className={glStringsData?.unassignedCombinations?.length == 0 ? "proceed-margin-left" : ""}>
                <div className="small-gap-flex">
                    <span className="p-text small-padding-bottom">
                        {lang.monthly_build.setup_profit_stacks.step.replace("[s]", hideCostCenters ? "4":"5")}
                        </span>
                    <span className="h1-text"> {lang.monthly_build.setup_profit_stacks.title}</span>
                    <MonthlyBuildCurrentPeriodBreadcrumbs currentPeriod={period}/>
                    {renderMonthlyBuildBanner()}
                </div>
                <div className="page" style={style}>
                {!glStringsData?.unassignedCombinations?.length > 0 ?
                    <div className="p-text">{lang.monthly_build.setup_profit_stacks.intro_no_unassigned_gl_strings}</div> 
                :<>
                    {selectedTab === 0 ?
                        <AssignGlStrings key={selectedTab} ref={assignGlStringsRef} glStrings={glStringsData} flipSign={flipSign} pslTree={pslTree} linearizedPSLs={linearizedPSLs}setPSLTree={setPSLTree} assignTo={assignTo}
                            getPSLPath={getPSLPath}unAssign ={unAssign} setIsError={setIsError} isError={isError} lstTransitionColumnNames={lstTransitionColumnNames} transitionDisplayMap={transitionDisplayMap} exclude={exclude}/>
                        :
                        <GlStringsReviewTable data={glStringsData} goToCard={goToCard} lstTransitionColumnNames={lstTransitionColumnNames} transitionDisplayMap={transitionDisplayMap}/>
                    }
                    <div style={{ display: "flex", gap: 10 }}>
                        <Button
                            id={"go-to-review"}  
                            label={"Go To Review"}
                            variant={BUTTON_VARIANT.PRIMARY}
                            size={SIZES.LARGE}
                            type={BUTTON_TYPE.DEFAULT}
                            onBtnClick={() => changeSelectedTab(tabs[1])}
                            rightIcon={<NextIcon />}
                            className={"uk-margin-large-top"}
                        />
                        <Button
                            id={"save-progress"}  
                            label={"Save progress"}
                            variant={BUTTON_VARIANT.TERTIARY}
                            size={SIZES.LARGE}
                            type={BUTTON_TYPE.DEFAULT}
                            onBtnClick={saveProgress}
                            className={"uk-margin-large-top"}
                            disabled={!canSaveProgress}
                        />
                    </div>
                    <Button
                        id={"save-gl-strings"}  
                        label={"Confirm and proceed"}
                        variant={BUTTON_VARIANT.PRIMARY}
                        size={SIZES.LARGE}
                        type={BUTTON_TYPE.DEFAULT}
                        onBtnClick={proceed}
                        rightIcon={<NextIcon />}
                        className={"uk-margin-large-top"}
                    />
                </>
                }
                </div>
            </div>
            {/*<div className="large-gap-flex">*/}

            {/*</div>*/}
            <Modal //modal appears when the user has changes and trying to exist the report or logging out
                key={"save-glstring-before-exit"}
                id={"save-glstring-before-exit"}
                title={lang.monthly_build.setup_profit_stacks.modal.title}
                openDialog={saveGlModalOpen}
                bodyContent={saveGlModalBody}
                dialogActions={saveGlModalActions}
                closeClick={() => setSaveGlModalOpen(false)} 
                size={DIALOG_SIZE.LARGE}
                />
            {canSaveProgress && <label className="uk-hidden"  id="save-before-leave"/>}
        </div>
    );
};

export default forwardRef(SetupProfitStacks);
