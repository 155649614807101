import React, {Component} from 'react';
import { CustomSelect } from '../../form/elements.js';
import {findOptionByKey} from '../../class/utils.js';

import {
    BUTTON_TYPE,
    BUTTON_VARIANT,
    DROPDOWN_TYPE,
    ROW_STATUS,
    SIZES
} from '../../class/constants';
import Dropdown from '../../newComponents/DropDown.js';
import Button from '../../newComponents/Button.js';

const $ = require('jquery');

/**
 *
 *
 * @author [Bassem Arnaout]
 */
class LeverColumn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            [ROW_STATUS.FIELD]: !!this.props.leverObj && Object.keys(this.props.leverObj).length > 0 ? this.props.leverObj.state[ROW_STATUS.FIELD] : ROW_STATUS.VALUES.NEW,
        }

        this.deleteLever = this.deleteLever.bind(this);
        // this.runMatchingEntityQuery = this.runMatchingEntityQuery.bind(this);
        // this.runValidation = this.runValidation.bind(this);


        this.leverObj = {
            lever_description: "",
            lever_value: ""
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.groups !== this.props.groups) {
            this.setState({groups: this.props.groups})
        }
    }

    onChangeAttribute(e, type) {
        let tempState = {};


        if (type === "lever_description") {
            var value = $(e.currentTarget).val();
            this.leverObj["lever_description"] = value;
        }
        if (type === "lever_value") {
            this.leverObj["lever_value"] = e["value"];
        }


        if (this.state[ROW_STATUS.FIELD] !== ROW_STATUS.VALUES.NEW) {
            tempState[ROW_STATUS.FIELD] = ROW_STATUS.VALUES.EDITED;
        }
        this.setState(tempState);
    }

    setData(data) {
        this.leverObj = data;

        this.setState({
            [ROW_STATUS.FIELD]: ROW_STATUS.VALUES.OLD,
            isOld: true     //this state doesn't change values on change attr, unlike rowStatus
        });
    }

    deleteLever() {

        let callback = () => {
            this.setState({
                [ROW_STATUS.FIELD]: ROW_STATUS.VALUES.DELETED
            }, function () {
                this.props.reload();
            });
        }
        this.props.deleteLever(callback);
    }

    render() {
        if (this.state[ROW_STATUS.FIELD] === ROW_STATUS.VALUES.DELETED) {
            return (<React.Fragment></React.Fragment>);
        }

        return (

            <tr className="">
                <td className="width-200">
                    <Dropdown
                        id="select-sourceFileColumn"
                        className="uk-display-inline-block width-200 uk-margin-default-right uk-margin-default-bottom input__dropdown"
                        name="uploadedFilePeriodStart"
                        placeholder="Select lever"
                        value={findOptionByKey(this.props.groups, Number(this.leverObj["lever_value"]))}
                        options={this.props.groups}
                        onChange={(e) => this.onChangeAttribute(e, "lever_value")}
                        type={DROPDOWN_TYPE.INPUT}
                    />
                </td>
                <td className="width-350">
                    <textarea  placeholder = "Describe your lever" value={this.leverObj["lever_description"]} onChange={(e) => this.onChangeAttribute(e, "lever_description")} className="form-control textarea-width-350 uk-margin-xsmall-right uk-margin-default-bottom input-default"/>
                </td>
                <td className="uk-flex" style={{marginLeft:"0.521vw"}}>
                    <Button 
                        variant={BUTTON_VARIANT.SECONDARY}
                        size={SIZES.DEFAULT}
                        type={BUTTON_TYPE.DEFAULT}
                        title = "Show History"
                        className="uk-button-icon transparent-bg"
                        onBtnClick={this.deleteLever}
                        leftIcon={<i className="fal fa-trash-alt fa-lg"/>}
                     />
                </td>
            </tr>
        );
    }
}

export default LeverColumn;