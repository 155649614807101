import { ClickAwayListener } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { BUTTON_DROPDOWN_VARIANT, BUTTON_TYPE, BUTTON_VARIANT, SIZES, TLC_CHART_COLORS } from "../../class/constants";
import { lang } from "../../language/messages_en";
import ButtonDropdown from "../../newComponents/ButtonDropdown";
import SearchableDropdown from "../SearchableDropdown";
import "./selectEntities.css";

const SelectEntitiesDropdown = ({ vectorEntities, onSelectEntitiesApply, isTrend }) => {
  const [open, setOpen] = useState(false);
  const [entities, setEntities] = useState([]);

  const searchableDropdownRef = useRef();
  const selectedEntitiesArr = useRef([]);

  /**
   * After rendering this component and fetching vector entities, we set them in a state.
   * selectedEntitiesArr is filled with the checked entities.
   */
  useEffect(() => {
    if (vectorEntities && Object.keys(vectorEntities).length > 0) {
      setEntities(vectorEntities);
      selectedEntitiesArr.current = vectorEntities.filter((f) => f.checked).map((m) => m.value);
    }
  }, [Object.keys(vectorEntities)]);

  useEffect(() => {
      handleClickAway(); // to handle closing the dropdown when clicking on view trend
  }, [isTrend]);

  /**
   * Opens/closes the dropdown
   */
  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  /**
   * In case we click outside of the component, close it
   */
  const handleClickAway = () => {
    setOpen(false);
  };

  /**
   * On checkbox change function.
   * We fill the selectedEntitiesArr with the checked entities and in case we have reached 3 selected entities
   * the others will be disabled.
   * @param {*} item
   */
  const handleCheckboxClick = (item) => {
    item.checked = !item.checked;
    updateSelectedEntitiesArr(item);
    searchableDropdownRef.current?.setLimitReached(selectedEntitiesArr.current.length >= 3);
  };

  /**
   * Add item to array if checked=true and remove it if checked=false.
   * @param {*} item 
   */
  const updateSelectedEntitiesArr = (item) => {
    if (item.checked) {
      selectedEntitiesArr.current.push(item.value);
    } else {
      selectedEntitiesArr.current = selectedEntitiesArr.current.filter((ent) => ent !== item.value);
    }
  }

  const onApplyClick = () => {
    if (onSelectEntitiesApply) {
      let checkedEntities = entities.filter((f) => f.checked);
      assignColorsToEntities(checkedEntities);
      onSelectEntitiesApply(checkedEntities);
    }
    // Close dropdown on apply click
    handleClickAway();
  };

  /**
   * Assign colors to selected entities
   * @param {*} entities 
   */
  const assignColorsToEntities = (entities) => {
    entities.map((ent, index) => {
      ent.legendColor = TLC_CHART_COLORS[index].color;
      ent.bubbleColor = TLC_CHART_COLORS[index].color;
      return ent;
    });
  }

  const renderSelectEntitiesBody = () => {
    return (
      <div className="select-entities-dropdown" /*style={{position: "absolute", right: "26.8vw", top: "5.5vw", height: "80%"}}*/>
        <div className="select-entities-title-container">
          <span className="select-entities-title">{lang.tlc_landscape.select_entities_title}</span>
          <span className="select-entities-description">
            {lang.tlc_landscape.select_entities_description + " "}
            <span className="select-entities-limit-text">{lang.tlc_landscape.maximum_entities + "."}</span>
          </span>
        </div>
        <div style={{ width: "100%", height: "90%" }}>
          <SearchableDropdown
            ref={searchableDropdownRef}
            removeClearBtn
            sectionsData={[{ itemsList: entities }]}
            handleCheckboxChange={handleCheckboxClick}
            searchCriterias={["label"]}
            limitReached={selectedEntitiesArr.current?.length >= 3}
            primaryButtonProps={{
              className: "select-entities-apply-btn",
              text: lang.modal.buttons.apply,
              variant: BUTTON_VARIANT.PRIMARY,
              size: SIZES.DEFAULT,
              direction: "column",
              style: { width: "37%" },
              clickEvent: onApplyClick,
              id: "apply-entities",
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div style={{ position: "relative", display: "flex", flexDirection: "row-reverse" }}>
        <ButtonDropdown
          id="tlc-select-entities-btn"
          type={BUTTON_TYPE.DEFAULT}
          size={SIZES.DEFAULT}
          placeholder={lang.tlc_landscape.select_entities_title}
          dropIcon={<i className={"fas fa-plus-circle"} />}
          variant={BUTTON_DROPDOWN_VARIANT.BUTTON}
          onBtnClick={handleClick}
        />
        {open ? renderSelectEntitiesBody() : null}
      </div>
    </ClickAwayListener>
  );
};

export { SelectEntitiesDropdown };
