import React, { Component } from "react";
import Select, { components } from "react-select";
import { lang } from "../language/messages_en";
import { convertPxToViewport} from "../class/formatting.js"
import '../styles/common.css';
import { DROPDOWN_TYPE, SELECTED_PROFILE_EROSION } from "../class/constants";
import Input from "../newComponents/Input";
import DropDown from "../newComponents/DropDown";

const { MenuList, ValueContainer, SingleValue, Placeholder } = components;

const CustomMenuList = ({ selectProps, ...props }) => {
  const { onInputChange, inputValue, onMenuInputFocus , placeholder} = selectProps;

  // Copied from source
  const ariaAttributes = {
    "aria-autocomplete": "list",
    "aria-label": selectProps["aria-label"],
    "aria-labelledby": selectProps["aria-labelledby"]
  };

  return (
    <div className="uk-overflow-auto">
      <div className="uk-flex uk-flex-middle">
        <Input
          id="input_field"
          isSearch = {true}
          // style={{
          //   width: "100%",
          //   boxSizing: "border-box",
          //   padding: convertPxToViewport(10),
          //   border: "none",
          //   borderBottom: convertPxToViewport(1) + " solid lightgrey"
          // }}
          autoCorrect="off"
          autoComplete="off"
          spellCheck="false"
          type="text"
          value={inputValue}
          onChange={(e) =>
            onInputChange(e.currentTarget.value, {
              action: "input-change"
            })
          }
          onMouseDown={(e) => {
            e.stopPropagation();
            e.target.focus();
          }}
          onTouchEnd={(e) => {
            e.stopPropagation();
            e.target.focus();
          }}
          onFocus={onMenuInputFocus}
          placeholder= {placeholder}
          {...ariaAttributes}
        />
        {/* <i id="search-icon" className="fal fa-search fa-lg uk-margin-small-right"></i> */}
      </div>
      <MenuList className="overflow-y-visible" {...props} selectProps={selectProps} maxHeight={convertPxToViewport(280)} /> 
    </div>
  );
};

// Set custom `SingleValue` and `Placeholder` to keep them when searching
const CustomValueContainer = ({ children, selectProps, ...props }) => {
  const commonProps = {
    cx: props.cx,
    clearValue: props.clearValue,
    getStyles: props.getStyles,
    getValue: props.getValue,
    hasValue: props.hasValue,
    isMulti: props.isMulti,
    isRtl: props.isRtl,
    options: props.options,
    selectOption: props.selectOption,
    setValue: props.setValue,
    selectProps,
    theme: props.theme
  };

  return (
    <ValueContainer {...props} selectProps={selectProps}>
      {React.Children.map(children, (child) => {
        return child ? (
          child
        ) : props.hasValue ? (
          <SingleValue
            {...commonProps}
            isFocused={selectProps.isFocused}
            isDisabled={selectProps.isDisabled}
          >
            {selectProps.getOptionLabel(props.getValue()[0])}
          </SingleValue>
        ) : (
          <Placeholder
            {...commonProps}
            key="placeholder"
            isDisabled={selectProps.isDisabled}
            data={props.getValue()}
          >
            {selectProps.placeholder}
          </Placeholder>
        );
      })}
    </ValueContainer>
  );
};
/**
 * author: Mostafa Haydar
 * dynamic component that adds a customized search input inside the drop down ot the react select 
 * {props} options(data rendred inside the dropdown), onChange (function called on selection of option) and OptionComponent
 */
class SearchableSelect extends Component {
  constructor(props) {
    super(props)
    this.state = {
      inputValue:"",
      isFocused: false,
    }
    this.dropdownRef = React.createRef();

  }

  setIsFocused=(param)=>{
    this.setState({
        isFocused: param,
    })
  }

  closeDropDown=(e)=>{
    let _this = this;
    var element = document.getElementById('split_manage_columns_dropdown_container'+_this.props.constraint);
    var second_element = document.getElementById('arrow_icon_container');
    var element3 = document.getElementById('chevron_down_icon');
  
    if ((e?.target !== element && element !== null) && e.target !== second_element && e?.target !== element3 && (!element.contains(e.target))) {
        if (_this.dropdownRef.current.getDropDown()) {
          _this.dropdownRef.current.showDropdown(); 
      }
    }
  }
  componentDidMount() {
    let _this = this;
    $(document).on("click", _this.closeDropDown);
  }

  componentWillUnmount() {
    let _this = this;
    $(document).off("click", _this.closeDropDown);
  }

  setInputValue=(param,e)=>{
    let _this = this;
    if (e.action === 'menu-close'){
      _this.setState({
        isFocused: false
      },()=>{
        _this.forceUpdate();
        if (_this.dropdownRef.current.getDropDown()) {
          _this.dropdownRef.current.showDropdown(); 
        }
      })
    } else{
      this.setState({
        inputValue: param,
    })
    }
      
  }

  onChange=(option)=>{
    this.setIsFocused(false);
    if(sessionStorage.getItem(SELECTED_PROFILE_EROSION)){
			sessionStorage.removeItem(SELECTED_PROFILE_EROSION); 
		}
    if(this.props.fromDashboard){
      this.props.onChange(option);// select dashboard
    }else{
      this.props.onChange(option, false, undefined,undefined,undefined,undefined,true)// apply profile
    }
  }

  render() {

    let _this = this;
    let isFocused = this.state.isFocused;
    let inputValue = this.state.inputValue;

    return (
      <div id={"split_manage_columns_dropdown_container"+_this.props.constraint} className={this.props.isFromExpandingList ? " expanding_list_manage_columns" : ""}>
        {/* <Select
          className=""

          classNamePrefix="split-inner"
          placeholder={_this.props.placeholder}
          options={_this.props.options}
          components={{
            Option: _this.props.OptionComponent,
            IndicatorSeparator: () => null,
            MenuList: CustomMenuList,
            ValueContainer: CustomValueContainer
          }}
          inputValue={inputValue}
          isSearchable={false}
          value ={this.props.value?this.props.value:""}
          onMenuInputFocus={() => _this.setIsFocused(true)}
          onChange={(option) => _this.onChange(option)}
          onInputChange={(val) => _this.setInputValue(val)}
          styles = {this.props.splitDropdownCustomStyles}
          {...{
            menuIsOpen: isFocused || undefined,
            isFocused: isFocused || undefined
          }}
          theme={theme => ({
            ...theme,
            colors: {
                ...theme.colors,
                primary25: '#eeeeee',
                primary: '#dcdcdc',
            },
        })}
        maxMenuHeight={convertPxToViewport(300)}
        /> */}
        <DropDown
          ref={this.dropdownRef}
          id={"split_manage_columns_dropdown"}
          className="input__dropdown"
          // classNamePrefix="split-inner"
          placeholder={_this.props.placeholder}
          options={_this.props.options}
          defaultComponents={{
            Option: _this.props.OptionComponent,
            IndicatorSeparator: () => null,
            MenuList: CustomMenuList,
            ValueContainer: CustomValueContainer
          }}
          CustomMenuList = {CustomMenuList}
          inputValue={inputValue}
          isSearchable={false}
          value ={this.props.value?this.props.value:""}
          onMenuInputFocus={() => _this.setIsFocused(true)}
          onChange={(option) => _this.onChange(option)}
          onInputChange={(val, e) => _this.setInputValue(val,e)}
          type={DROPDOWN_TYPE.INPUT}
          onlyArrow = {true}
          menuWidth={this.props.menuWidth ? this.props.menuWidth : 400}
          menuIsOpen={true}
          firstAttr = {"#split_manage_columns_dropdown_container"+_this.props.constraint}
          isFocused={isFocused}

          // styles = {this.props.splitDropdownCustomStyles}
          // {...{
          //   menuIsOpen: isFocused || undefined,
          //   isFocused: isFocused || undefined
          // }}
        //   theme={theme => ({
        //     ...theme,
        //     colors: {
        //         ...theme.colors,
        //         primary25: '#eeeeee',
        //         primary: '#dcdcdc',
        //     },
        // })}
        maxMenuHeight={convertPxToViewport(300)}
        />
      </div>
    );
  }
}

export default SearchableSelect;
