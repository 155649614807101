import React, { Component } from 'react';
import { PS_MAPPING, API_URL, UPLOAD_SECTIONS, ALL_WIDGETS, DEFINE_FIELDS, IS_TRANSITIONED, ROW_STATUS, GLACCOUNTS_FIELDS, RAW_ITEMS, CALCULATED_COLUMNS, COST_FUNCTIONS_FIELDS } from '../../class/constants';
import { getTranslationFile, getSectionExists, copyObjectValues, findOptionByKeyValue, tryParse} from '../../class/utils';
import { FETCHAPI_PARAMS, FETCH_METHOD, fetchAPI } from "../../class/networkUtils";
import CalculatedProfitStackLine from "../../components/manageColumns/CalculatedProfitStackLine";
import '../../styles/dataModeling.css';
import ExceptionDriver from './ExceptionDriver';
import { FormComponent } from '../../form/FormElements';
import { CustomSelect } from "../../form/elements";
import Popup from 'react-popup';

const lang = getTranslationFile();
const $ = require('jquery');




/**
 *  ExceptionDriver conatiner hold ExceptionDrives conainer and handles changes done  
 * @author [Bassem Arnaout]
 * @extends Component
 * **/

class CalculatedSetupContainer extends Component {
    constructor(props) {
        super(props);
        let line = this.props.mappedLine;
        let originalLine = copyObjectValues(this.props.mappedLine);
        let tempState = {};
        tempState.line = line;
        tempState.originalLine = originalLine;
    
        this.state = 
           tempState;
        this.inputRefs = {};
        this.fetchAPI = fetchAPI.bind(this); 
    }
    
    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.mappedLine) !== JSON.stringify(this.props.mappedLine)) {
            this.setState({
                line: copyObjectValues(this.props.mappedLine),
                originalLine:copyObjectValues(this.props.mappedLine)
                
            })
        }
    }

    /**
     * updated mapped Lined object key values based on type sent 
     * @param {*} e 
     * @param {*} type 
     * @param {*} reset 
     */
    updateMappedRow=(e, type, reset)=>{
        let tempState = {};
        let _this = this;
        let mappedLine = _this.state.line;
        
        if (type === PS_MAPPING.FIELDS.DESCRIPTION) {
            mappedLine[PS_MAPPING.FIELDS.DESCRIPTION] = $(e.currentTarget).val();
        }
        if (type === PS_MAPPING.FIELDS.NAME) {
            mappedLine[PS_MAPPING.FIELDS.NAME] = $(e.currentTarget).val(); //! add validation on name
        }
        tempState.line = mappedLine;
        
        this.setState(tempState);
      
    }

    appendToFormula=($node,isNumBtn)=>{
        let tempState = {};
        if(isNumBtn){
            if ($node.attr("node_value") === undefined) {
                return;
            }
        }
        let node = $($node.target);
        let value = isNumBtn ? $node.attr("node_value").trim() : node.attr("node_index");
        let formula = isNumBtn ? $node.attr("node_value").trim() : node.attr("node_formula");
        let returnName = isNumBtn ? $node.attr("node_value").trim() : node.attr("node_return_name");
        let display_name = isNumBtn ? $node.attr("node_value").trim() : node.attr("node_"+COST_FUNCTIONS_FIELDS.NAME);
        let type = isNumBtn ? $node.attr("node_type").trim() :  node.attr("node_"+CALCULATED_COLUMNS.FIELDS.COLUMN_FIELDS.TYPE);
        let _class = isNumBtn ? $node.attr("node_class") :"";
        let formulaResult = copyObjectValues(this.state.line[PS_MAPPING.FIELDS.MAP_FORMULA])
        if(typeof formulaResult === "string"  && formulaResut !== ""){
            formulaResult = tryParse(formulaResult);
        }
        let result = copyObjectValues(formulaResult);
        result.push({
           value: value,
           display_name:display_name,
           formula: formula,
           type: type,
           class:_class,
           returnName:returnName
        });

        let mappedLine = this.state.line;
        mappedLine[PS_MAPPING.FIELDS.MAP_FORMULA] = result;
        tempState.line = mappedLine;
        this.setState(tempState);
    }

    updateFormula=(formula)=>{
        let tempState  = {};
        let mappedLine = this.state.line;
        mappedLine[PS_MAPPING.FIELDS.MAP_FORMULA] = formula;
        tempState.line = mappedLine;
        this.setState(tempState);
    }

    deleteLastFormulaItem = () => {
        let tempState = {};
        let mappedLine = this.state.line;
        let originalMappedLine = copyObjectValues(this.state.originalLine);
        let mappedLineFormula  = mappedLine[PS_MAPPING.FIELDS.MAP_FORMULA];
        mappedLineFormula.pop();
        tempState.line = mappedLine;
        tempState.originalLine = originalMappedLine;
        this.setState(tempState);
         
    }

    deleteFormula = () => {
        let tempState = {};
        let mappedLine = this.state.line;
        mappedLine[PS_MAPPING.FIELDS.MAP_FORMULA] = [];
        tempState.line = mappedLine;
        this.setState(tempState);
    }

    resetFormulaChanges = () => {
        let tempState = {};
        let mappedLine = this.state.line;
        mappedLine[PS_MAPPING.FIELDS.MAP_FORMULA] = this.state.originalLine[PS_MAPPING.FIELDS.MAP_FORMULA];
        tempState.line = mappedLine;
        this.setState(tempState);
    }

    /**
     * function sets warning message to user on click of submit
     * @param {*} message 
     */
    setWarning=(message)=> {
        this.setState({
            isNameValid: message
        })
    }
    render() {
        return(
            <div>
                <div className="uk-margin-medium-top">
                    <div className="uk-margin-xmedium-bottom">
                        <label htmlFor="Name" className="uk-text-xmedium">{lang.manage_access.NAME}<sup className="red">*</sup></label>
                        <FormComponent tag="input" id={PS_MAPPING.FIELDS.NAME} ref={r => this.inputRefs[PS_MAPPING.NAME] = r} className="uk-input" placeholder={lang.psl_placeholders.calculated_line_name}
                        onChange={(e)=>{this.updateMappedRow(e, PS_MAPPING.FIELDS.NAME)}} value={this.props.currentRow ? this.props.currentRow[PS_MAPPING.FIELDS.NAME] : ""}
                        />
                        {this.props.isNameValid && this.props.isNameValid !== "" ? <div id="calc-col-warn" className="fs-12 red italic">{this.props.isNameValid}</div> : ""}
                    </div>
                    <div className="uk-margin-xmedium-bottom">
                        <label htmlFor="Description" className="uk-text-xmedium">{lang.manage_access.DESCRIPTION}</label>
                        <FormComponent tag="textarea" id={PS_MAPPING.DESCRIPTION} ref={r => this.inputRefs[PS_MAPPING.FIELDS.DESCRIPTION] = r} className="uk-textarea" placeholder={lang.psl_placeholders.calculated_line_description}
                        onChange={(e)=>{this.updateMappedRow(e, PS_MAPPING.FIELDS.DESCRIPTION)}} value={this.props.currentRow ? this.props.currentRow[PS_MAPPING.FIELDS.DESCRIPTION] : ""}
                        />
                    </div>
                    <div className="uk-margin-xmedium-bottom">
                        <CalculatedProfitStackLine ref={r=>this.calculatedPslRef = r} profitStackLineColumns={this.props.calculatedProfitStackFields} mappedLine = {this.props.mappedLine} line = {this.state.line} currentRow={this.props.currentRow} isPssMapping={true} 
                        updateFormula={this.updateFormula} appendToFormula={this.appendToFormula} deleteLastFormulaItem={this.deleteLastFormulaItem} deleteFormula={this.deleteFormula} resetFormulaChanges={this.resetFormulaChanges}/>
                    </div>
                </div>
            </div>
        );

    }

}

export default CalculatedSetupContainer;