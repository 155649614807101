const defaultOptions = new Map();

function vectorEntities(state = defaultOptions, action) {
  //reducer
  switch (action.type) {
    case "UPDATE_VECTOR_ENTITIES":
      if (action.clear) {
        let vectorEntitiesMap = new Map();
        return vectorEntitiesMap;
    }
    let vectorEntitiesMap = new Map(state);
    vectorEntitiesMap.set(action.scenarioId, action.vectorEntities);
    return vectorEntitiesMap;
    case "GET_VECTOR_ENTITIES":
      return state;
    default:
      return state;
  }
}

export { vectorEntities };
