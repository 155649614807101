//This should be the main component
import React, { Component } from 'react';
import setChartData from '../../class/bridgeReport.js'
import { HEADER_ELEMENT } from '../../class/constants.js';
import { getTranslationFile } from '../../class/utils.js';
import { getPrintButton } from '../../components/Print.js';

const shortid = require('shortid');
const lang = getTranslationFile();
const $ = require('jquery');
class BridgeChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };
        this.bridgeChartRef = React.createRef();

    }

    generateReport() {
    }


    componentDidUpdate(prevProps) {
        if ((!prevProps.chartData && this.props.chartData ) || (prevProps.chartData && JSON.stringify(prevProps.chartData) !== JSON.stringify(this.props.chartData))) {
            setChartData(this.props.chartData, this.props.chartUnit, this.props.bridgeLabel, this.props.drill, this.props.mixVector, this.props.unique_id, this.props.fromWidget);
        }else if (this.props.event && this.props.event.item && this.props.event.item.columnGraphics){
            $(this.props.event.item.columnGraphics.node.firstChild).attr('stroke-width','5');
            $(this.props.event.item.columnGraphics.node.firstChild).attr('stroke','#0E5FFA');
        }
    }

    componentDidMount() {
        setChartData(this.props.chartData, this.props.chartUnit, this.props.bridgeLabel, this.props.drill, this.props.mixVector, this.props.unique_id, this.props.fromWidget);
    }

    renderLeftPanel(){
        var comp = this;
        if(comp.props.selectedNames){
            var listItems = comp.props.selectedNames.map((item) =>
                <li key={shortid.generate()}>
                    {item}
                </li>
            );

            return <React.Fragment>
                <div className={"uk-flex uk-flex-center"}>
                    <div className="fs-14 uk-text-bold uk-padding-remove uk-margin-medium-right">
                        {lang.bridge_selected_entities}
                    </div>
                    <ul className="uk-flex list-of-entities" key={shortid.generate()}>
                        {listItems}
                    </ul>
                </div>
            </React.Fragment>
        }
    }

    render() {
      return (
        <div ref={this.bridgeChartRef} className='bridge-chart-container' style={{ width: "100%" }}>
          <div className="uk-text-center uk-text-bold fs-14">{this.props.bridgeLabel + " " + HEADER_ELEMENT.BRIDGE}</div>
          <div className={(this.props.fromWidget ? "" : "chart-header") + " uk-flex uk-flex-between uk-flex-middle uk-padding-large-left-right"}>
            {this.renderLeftPanel()}
            {this.props.fromWidget ? "" : getPrintButton(HEADER_ELEMENT.BRIDGE)}
          </div>
          <div className="uk-flex uk-flex-center bridge-chart-div" style={{ width: "100%" }}>
            <div className={"uk-flex uk-flex-center bridge-chart " + this.props.className} id={this.props.unique_id} style={{ width: "100%" }} />
          </div>
        </div>
      );
    }
}

export default BridgeChart;