import { ALL_REPORTS, API_URL, DATASET } from "./constants";
import { alertAndLogError } from "./jqueries";
import { getSectionId, getNumberOfConos, tryParse } from "./utils";
import { fetchAPI, FETCHAPI_PARAMS, FETCH_METHOD } from "./networkUtils";
import { setLocalStorageValueByParameter } from "./common";

/**
 * This functio fetches the datasets filled in the dataset dropdown
 * -- Can be passed a callback to execute after fetching
 * -- Must be bound in the constructor
 */
function fetchDatasets() {
    let query = "action=getDataSets"
        + "&tempScenarioId=" + this.tempScenarioId
        + "&scenario_id=" + this.state.scenario
        + "&section_id=" + getSectionId("PM", this.props.match.params.sectionId);
    setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
    fetch(`${process.env.REACT_APP_BASE_URL}${API_URL.PROFIT_LANDSCAPE}?${query}`, {mode: 'cors', credentials: 'include'})
        .then((response) => {
            if (response.status === 403) {
                this.logout();
            }
            return response.json()
        })
        .then((data) => {
            var tempState = {};
            if (data.data && data.data.length) {
                data = data.data;
                tempState.dataSet = data[0][DATASET.DATASET];
            }

            var dataSets = [];
            var numOfConos = getNumberOfConos(data);

            data.map(function (item) {
                dataSets.push({
                    value: numOfConos > 1 ? item[DATASET.CONO] + "_" + item[DATASET.DATASET] : item[DATASET.DATASET],
                    label: numOfConos > 1 ? item[DATASET.CONO] + "_" + item[DATASET.DATASET] : item[DATASET.DATASET],
                    className: "dataSet-option",
                    version: item[DATASET.VERSION],
                    [DATASET.CONO]: item[DATASET.CONO]
                });
            });
        }).catch((error)=>{
            alertAndLogError(error);
        }).then(()=>{

        })
}

function getClientSetting(settingIds, callback) {
    if(!Array.isArray(settingIds)) {
        settingIds = [settingIds];
    }
    var query = {
        action: "getClientSetting",
        setting_ids: settingIds
    };

    var onThenCallback = callback;

    var fetchOptions = {
        [FETCHAPI_PARAMS.funcName]: "getClientSetting",
        [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.config,
        [FETCHAPI_PARAMS.showLoader]: true,
        [FETCHAPI_PARAMS.path]: API_URL.CLIENT_INFO,
        [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
        [FETCHAPI_PARAMS.query]: query,
        [FETCHAPI_PARAMS.onThenCallback]: onThenCallback
    }
    this.fetchAPI(fetchOptions);
}

function getCostClassification(callback, scenarioId=this.props.scenarioId) {
    const _this = this;
    var query = {
        action:"getCostClassification",
        scenario_id: scenarioId,
    }

    var onThenCallback = function(data){
        if(data) {
            if(callback) {
                callback(data);
            } else {
                _this.setState({
                    costClassification: data.costClassification ? data.costClassification : [],
                    glCosts: data.glCosts ? data.glCosts : [],
                    hiddenVectors: data.hiddenVectors ? data.hiddenVectors : [],
                    metricFields: data.metricFields ? data.metricFields : []
                });
            }
        }
    }

    var fetchOptions = {
        [FETCHAPI_PARAMS.funcName]: "getCostClassification",
        [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
        [FETCHAPI_PARAMS.showLoader]: false,
        [FETCHAPI_PARAMS.path]: API_URL.PROFIT_STACK,
        [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
        [FETCHAPI_PARAMS.query]: query,
        [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
        [FETCHAPI_PARAMS.machine_name]: this?.props?.machine_name,
        [FETCHAPI_PARAMS.profitFormat]: ALL_REPORTS.PROFIT_MAP
    }
    fetchAPI(fetchOptions);
}


export {fetchDatasets, getClientSetting, getCostClassification}