import React, { Component } from 'react';
import Input from '../newComponents/Input';

class FormComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stopReadingFromProps: false
        }

        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.reset = this.reset.bind(this);
    }

    onChange(evt) {
        if(this.props.onLiveChange) {
            this.props.onLiveChange(evt);
        }

        this.setState({
            value: evt.currentTarget.value,
            stopReadingFromProps: true
        });
    }

    onBlur(evt) {
        if (this.props.onChange) {
            this.props.onChange(evt, this.state.value);
        }
    }

    componentDidUpdate() {
        if(this.state.value === undefined && this.props.value !== undefined &&  !this.state.stopReadingFromProps) {
            this.setState({
                value: this.props.value
            })
        }
    }

    reset() {
        this.setState({
            value: undefined,
            stopReadingFromProps: false
        });
    }

    render() {
        const Tag = this.props.tag;
        return  <form autoComplete="off">
                    {this.props.tag === "input" ? 
                        <Input type={this.props.type} id={this.props.id} className={this.props.className} value={this.state.stopReadingFromProps ? this.state.value : this.props.value || this.state.value || ""} 
                            onChange={this.onChange} onBlur={this.onBlur} placeholder={this.props.placeholder} maxLength={this.props.maxLength} disabled={this.props.disabled} />
                        :
                        <Tag id={this.props.id} className={this.props.className + " input-default"} value={this.state.stopReadingFromProps ? this.state.value : this.props.value || this.state.value || ""} 
                            onChange={this.onChange} onBlur={this.onBlur} placeholder={this.props.placeholder} disabled={this.props.disabled}/>
                    }
                </form>
        
    }
}

export { FormComponent }