import React, {Suspense, useEffect, useRef, useState} from 'react';
import {useHistory, useParams} from "react-router-dom/cjs/react-router-dom";

import ErrorBoundary from "../../ErrorBoundary";
import {ReportHeader} from "../ReportHeader";
import {renderTitle} from "../functions/componentFunctions";
import DropDown from "../../newComponents/DropDown";
import Button from "../../newComponents/Button";
import {copyObjectValues, findOptionByKey} from "../../class/utils";
import {logout} from "../api/api";
import {getClientPeriods, getEngineScenarios, troubleshootCostFunctionFromApi} from "../api/apiDataModeling";
import {useAuthenticated} from "../CustomHooks";
import {saveCookie} from "../../class/jqueries";
import ConfigureData from "../../sections/ConfigureData";
import {toggleLoader} from "../../class/common";
import {
    ALL_WIDGETS, API_URL, BUTTON_TYPE, BUTTON_VARIANT, CONFIGURE_SECTIONS, DIALOG_SIZE,
    DROPDOWN_TYPE,
    HEADER_ELEMENT, IS_BUILD_RUNNING, MENU_ITEM, SIZES,
} from "../../class/constants";
import {lang} from "../../language/messages_en";
import Modal from "../../newComponents/Modal";
import {CircleLoader} from "../../form/elements";
import {FETCH_METHOD, fetchAPI, FETCHAPI_PARAMS} from "../../class/networkUtils";
import { useDispatch } from 'react-redux';

const ConfigureBuildWrapper = (props) => {

    const { userAllowedMenuLinks } = props;
    const { userSettings } = props;
    const { checkingForUnsavedChanges } = props;


    /** References & react utility */
    const history = useHistory();
    const params = useParams();
    const profitFormat = ALL_WIDGETS.FIELDS.CONFIGURE_AND_BUILD;
    const childRef = useRef();
    const dispatch = useDispatch();


    const [reportTitle, setReportTitle] = useState(ALL_WIDGETS.TITLES.DATA_MODELING.CONFIGURE_BUILD);
    const [headerElements, setHeaderElements] = useState([]);

    const [scenariosState, setScenariosState] = useState([]);
    const [isScenarioChanged, setScenarioChanged] = useState(false);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [clientPeriodsState, setClientPeriodsState] = useState({});
    const [selectedPeriod, setSelectedPeriod] = useState();
    const [enableMB, setEnableMB] = useState();
    const [isLoaderShown, setisLoaderShown] = useState();
    const [scenarioDisabled, setScenarioDisabled] = useState(false);
    const [runJob, setRunJob] = useState(false);
    const [buildPercentage, setBuildPercentage] = useState(false);
    const [circleLoader, setCircleLoader] = useState();
    const [scenarioOption, setScenarioOption] = useState();
    const [tabSelected, setTabSelected] = useState(CONFIGURE_SECTIONS.TITLES.CONFIG_COST_FUNCTIONS);
    const [viewMode, setViewMode] = useState(true);
    const [openSaveFirstModal, setOpenSaveFirstModal] = useState(false);
    const [divIdState, setDivIdState] = useState();
    const [destinationClientIdState, setDestinationClientIdState] = useState();
    const [openChangeScenarioDialog, setOpenChangeScenarioDialog] = useState(false);
    const [newScenarioChosen, setNewScenarioChosen] = useState();

    const isAuthenticated = useAuthenticated(userAllowedMenuLinks);

    useEffect(() => {
        getClientPeriods(undefined, undefined, setClientPeriodsState, profitFormat, params, userSettings);
        getEngineScenarios(userAllowedMenuLinks, profitFormat, scenariosState, undefined, false, setScenariosState, true, history,lang.observability.configure_build.key);
        setScenarioChanged(false)
    }, []);

    useEffect(() => {
        if (!isAuthenticated) {
            logout();
        }
    }, [isAuthenticated]);

    /**
     * @function useEffect()
     * @description When clientPeriods are fetched and whenever the user makes a change, we rerender the header elements
     */
    useEffect(() => {
        // if (Object.keys(scenariosState).length > 0 && Object.keys(clientPeriodsState).length > 0) {
            let headerElements = [];
            headerElements = getHeaderElements();
            setHeaderElements(headerElements);

        // }
    }, [hasUnsavedChanges, scenariosState, clientPeriodsState, reportTitle, selectedPeriod, tabSelected, viewMode, enableMB, circleLoader, buildPercentage, sessionStorage.getItem(IS_BUILD_RUNNING)]);

    /**
     * @function useEffect()
     * To check for unsaved changes when changing client or report without saving
     */
    useEffect(() => {
        if (checkingForUnsavedChanges) {
            setDivIdState(props.divId);
            setDestinationClientIdState(props.destinationClientId);
            if (childRef?.current?.checkHasChange()) {
                openSaveFirstDialog();
            } else {
                props.setCheckingForUnsavedChanges(false);
                props.setCheckedUnsavedChangesRef(true, props.divId && Object.keys(props.divId).length > 0, !!props.destinationClientId);
            }
        }
    }, [checkingForUnsavedChanges, props.divId, props.destinationClientId]);

    /**
     * @function handlePeriodChange(newPeriod)
     * functionality: set period selection and get data
     * @param {object} newPeriod passed when changing period.
     */
    const handlePeriodChange = (newPeriod) => {
        let newSelectedPeriod = copyObjectValues(newPeriod);
        setSelectedPeriod(newSelectedPeriod);
        childRef.current.setChosenPeriod(newPeriod);
        saveCookie("period", newPeriod.value);
    };

    /**
     * @function rollBack()
     * functionality: when clicking on cancel just close dialog
     */
    const rollBack = () => {
        setOpenChangeScenarioDialog(false);
    }

    const chooseScenarioAfterChange = () => {
        var tempState = copyObjectValues(scenariosState);
        tempState.scenario = newScenarioChosen;
        tempState.scenarios = [newScenarioChosen.value];
        tempState.scenarioObjects = [newScenarioChosen];
        tempState.nextScenarios = [newScenarioChosen];
        tempState.nextScenariosObjects = [newScenarioChosen];
        tempState.scenarioStatus = newScenarioChosen.scenario_status;

        setScenariosState(tempState);
        setScenarioChanged(true);
        childRef.current.resetChanges();
        startChangeScenario(newScenarioChosen);
    }

    /**
     * @function resetChangeThenScenario()
     * functionality: when clicking on no close dialog then change scenario value
     */
    const resetChangeThenScenario = () => {

        setOpenChangeScenarioDialog(false)
        chooseScenarioAfterChange();

    }

    /**
     * @function handleScenarioChange(newScenario)
     * functionality: when changing scenario without changes change dropdown value
     * if we have changes save scenario in state and open dialog
     * @param {object} newScenario passed when changing scenario.
     */

    const handleScenarioChange = (newScenario) => {

        if(childRef.current.checkHasChange()){
            setOpenChangeScenarioDialog(true);
            setNewScenarioChosen(newScenario)
        } else {
            var tempState = copyObjectValues(scenariosState);
            tempState.scenario = newScenario;
            tempState.scenarios = [newScenario.value];
            tempState.scenarioObjects = [newScenario];
            tempState.nextScenarios = [newScenario];
            tempState.nextScenariosObjects = [newScenario];
            tempState.scenarioStatus = newScenario.scenario_status;

            setScenariosState(tempState);
            setScenarioChanged(true);
            onChangeScenario(newScenario);
        }
    };

    const onChangeScenario = (option) => {
        // Move to here, causes the current ref to be accessed during onClick
        if(childRef.current && childRef.current.state.isChangedCostFunction && childRef.current.state.tabSelected === CONFIGURE_SECTIONS.TITLES.CONFIG_COST_FUNCTIONS
            || childRef.current && childRef.current.state.isChangedVectors && childRef.current.state.tabSelected === CONFIGURE_SECTIONS.TITLES.CONFIG_VECTORS
            || childRef.current && childRef.current.state.isChangedQuadrants && childRef.current.state.tabSelected === CONFIGURE_SECTIONS.TITLES.CONFIG_QUADRANTS) {
            setScenarioOption(option)
            // this.setState({
            //     option: option
            // },function(){
            //     this.setChangeScenarioDialogOpen(true);
            // })
        }
        else{
            startChangeScenario(option);
        }
    }



    const goToBuild = () => {
            props.goToReport(undefined, undefined, undefined, "/" + ALL_WIDGETS.CONFIGURE + "/" + MENU_ITEM.FIELDS.DATA_MODELING + "/" + ALL_WIDGETS.CONFIGURE_AND_BUILD);
        };


    /**
     * during the build, update the progress percentage
     * @param {*} percentage
     */
    const setRunningJob = (runningRequests)=>{
        sessionStorage.setItem("runningRequests",runningRequests);
        setRunJob(runningRequests);
    }

    /**
     * during the build, update the progress percentage
     * @param {*} percentage
     */
    const setBuildPerc=(percentage)=>{
        sessionStorage.setItem("buildPerc",percentage);
        setBuildPercentage(percentage)
    }
    const enableManageBuilds = (value) => {
        setEnableMB(value)
    }
    // const setIsLoaderShown = (loaderShown) => {
    //     if (isLoaderShown !== loaderShown) {
    //         setIsLoaderShown(loaderShown);
    //         // this.setState({
    //         //     isLoaderShown: isLoaderShown
    //         // })
    //     }
    // }

    /**
     * @function stopFullBuild()
     * functionality: stop build
     */
    const stopFullBuild = () => {
        let onThenCallback = () => {
        }
        let query = {
            action: "stopFullBuild",
            scenario_id: scenariosState?.scenario?.value
        }

        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "stopFullBuild",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]:lang.observability.configure_build.screen_name,
            [FETCHAPI_PARAMS.requestDescription]:lang.observability.configure_build.requests_description.stop_full_build
        };
        fetchAPI(fetchOptions);
    }

    const getScenarios = (callBack,isBuild, showLoader) =>{
        getEngineScenarios(userAllowedMenuLinks, profitFormat, scenariosState, callBack, isBuild, setScenariosState, showLoader, history, lang.observability.configure_build.key);
    }

    /**
     * show the circle loader when the build starts
     * @param {*} show
     */
    const setShowCircleLoader = (show) => {
        sessionStorage.setItem(IS_BUILD_RUNNING,show);
        setCircleLoader(show);
    }

    const troubleshootCostFunction = (costKey, timePeriod, updateData) => {
        troubleshootCostFunctionFromApi (scenariosState?.scenario?.value, userSettings.costCenter, costKey, timePeriod, updateData);
    }
    /**
     * @function startChangeScenario(option)
     * functionality: handling change scenario functionality even if we have a loader
     * @param {object} option passed when changing scenario.
     */
    const startChangeScenario = (option)=> {
        if(childRef.current && (childRef.current.childRef || childRef.current.vectorsRef)){// if we are in the configure data screen
            function myDisplayer(some) {
                setTimeout(() => {
                    childRef.current.onChangeScenario();// second function, to do after the first one finishs
                }, 1000);
            }
            let myPromise = new Promise(function(myResolve, myReject) {
                // if(reportTitle.toLowerCase() === ALL_WIDGETS.CONFIGURE){
                    toggleLoader(true, 'onChangeScenario');
                // }
                // handleScenarioChange();// first function to do
                myResolve("fulfilled");// when done
            });
            myPromise.then(
                function(value) {myDisplayer(value);}
            );
        }
        else if (childRef.current && option) {
            // this.setState({
            //     scenarioStatus: option[SCENARIOS.F.STATUS]
            // })
            if (childRef.current && childRef.current.onChangeScenario) {
                childRef.current.onChangeScenario(option);
            }
        }
    }
    /**
     * @function saveThenChangeScenario()
     * functionality: when clicking yes save changes then change scenario and close dialog
     */
    const saveThenChangeScenario = () => {
        childRef.current.startSave(undefined, undefined, undefined, undefined, chooseScenarioAfterChange);
        openChangeScenarioModal(false);
    }

    const changeScenarioConfirmDialogActions = () => {
        return (
            <>
                <Button
                    id="save-changes-btn"
                    label={lang.modal.buttons.yes}
                    variant={BUTTON_VARIANT.PRIMARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={saveThenChangeScenario}
                />
                <Button
                    label={lang.modal.buttons.no}
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={resetChangeThenScenario}
                />
                <Button
                    label={lang.modal.buttons.cancel}
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={rollBack}
                />
            </>
        )
    }

    const saveFirstDialogActions = () =>{
        return(
            <>
                <Button
                    label={lang.modal.buttons.yes}
                    variant={BUTTON_VARIANT.PRIMARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    aria-label="Close"
                    onBtnClick={onButtonYes}
                />
                <Button
                    label={lang.modal.buttons.no}
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    aria-label="Close"
                    onBtnClick={onButtonNo}
                />
                <Button
                    label={lang.modal.buttons.cancel}
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    aria-label="Close"
                    onBtnClick={closeModelingSettingsModal}
                />
            </>
        )
    }


    const onButtonNo = () => {
        props.setChangingReport(false);
        props.setCheckingForUnsavedChanges(false);
        props.setCheckedUnsavedChangesRef(true, divIdState && Object.keys(divIdState).length > 0, !!destinationClientIdState);
    };

    const onButtonYes = () => {
        let cb = () => {
            props.setCheckingForUnsavedChanges(false);
            props.setCheckedUnsavedChangesRef(true, divIdState && Object.keys(divIdState).length > 0, !!destinationClientIdState);
        }

        childRef.current.startSave(undefined, undefined, undefined, cb);
    }


    const onManageBuildClick = () => {
        childRef.current.onManageBuildClick();
    }

    const setSaveFirstDialogOpen = (isOpen) => {
        setOpenSaveFirstModal(isOpen);
    }
    const openChangeScenarioModal = (isOpen) => {
        setOpenChangeScenarioDialog(isOpen);
    }

    const messageDialogContent = (msg) => {
        return (<span className='fs-16'>{msg}</span>)
    }

    const openSaveFirstDialog = () => {
        setOpenSaveFirstModal(true);
    };

    const closeModelingSettingsModal = () => {
        setOpenSaveFirstModal(false);
        setDestinationClientIdState(undefined);
        setDivIdState(undefined);
        props.setCheckingForUnsavedChanges(false);
        props.setCheckedUnsavedChangesRef(false, false, false);
        props.setChangingReport(false);
    };

    /**
     * @function getHeaderElements()
     * @description This function renders reportTile, save button and reset button in case the user makes any change
     * @returns array of components to be rendered in {@link ReportHeader} component
     */
    const getHeaderElements = () => {
        let headerElements = [];

        headerElements.push(
            <div style={{display: "flex", alignItems: "center", columnGap: "0.42vw", width: "100%"}}>
                {renderTitle(reportTitle)}
                <DropDown
                    id="select-set"
                    className="width-200 input__dropdown"
                    name={HEADER_ELEMENT.SCENARIO}
                    value={findOptionByKey(scenariosState.scenarioList, scenariosState.scenario)}
                    onChange={handleScenarioChange}
                    options={scenariosState.scenarioList}
                    type={DROPDOWN_TYPE.INPUT}
                    disabled={scenarioDisabled}
                />
                { (tabSelected === CONFIGURE_SECTIONS.TITLES.CONFIG_COST_FUNCTIONS && viewMode) &&
                <DropDown
                    id="PeriodRange"
                    className="uk-width-small input__dropdown"
                    name="PeriodRange"
                    value={findOptionByKey(clientPeriodsState.periods, selectedPeriod ?? clientPeriodsState.nextPeriod)}
                    onChange={(e) => handlePeriodChange(e)}
                    options={clientPeriodsState.periods}
                    type={DROPDOWN_TYPE.INPUT}
                />}
                {circleLoader || (sessionStorage.getItem(IS_BUILD_RUNNING) === "true")?
                    <li className="uk-display-flex" style={{marginLeft: "auto"}}>
                        <p className=" uk-margin-small-right fs-15 assign-cost uk-margin-xsmall-top"> {sessionStorage.runningRequests} </p>
                        <CircleLoader className={"uk-margin-xsmall-top"}
                                      percentage={Math.round(Number(sessionStorage.getItem("buildPerc"))) || 0}
                                      radius={15}/>
                        <Button
                            id="stop-full-build"
                            label={lang.navigation.btns.stop}
                            variant={BUTTON_VARIANT.SECONDARY}
                            size={SIZES.DEFAULT}
                            type={BUTTON_TYPE.DEFAULT}
                            className="uk-margin-default-right uk-margin-xmedium-left uk-margin-xsmall-top"
                            onBtnClick={stopFullBuild}
                        />
                    </li> :
                    <div className="d-inline-flex" key={lang.navigation.btns.header_manage_build} style={{marginLeft: "auto"}}>
                        <Button
                            id="Configure_ManageBuild_Button"
                            label={lang.navigation.btns.header_manage_build}
                            variant={BUTTON_VARIANT.SECONDARY}
                            size={SIZES.DEFAULT}
                            type={BUTTON_TYPE.DEFAULT}
                            disabled={!enableMB}
                            leftIcon={<a className="Managebuild-btn-icon" uk-icon="icon: cog"/>}
                            onBtnClick={onManageBuildClick}
                        />
                    </div>
                }

            </div>
        );

        return headerElements;
    };

    // const showSkeleton = !(Object.keys(scenariosState).length > 0 && Object.keys(clientPeriodsState).length && headerElements?.length > 0);

    return (
        <ErrorBoundary>
            <Suspense fallback={<p id="loading">Loading...</p>}>
                {/*{showSkeleton && <LoaderSkeleton />}*/}
                <div
                    id={"main-component-container"}
                    className={"main-component-container "}
                    style={{ "--banner-height": 0 }}
                >
                    <div className={"header-banner-div-hidden"}>
                    </div>
                    <div className="main-report-header">
                        <ReportHeader headerElements={headerElements} />
                    </div>
                    <div className="main_report">
                        {Object.keys(scenariosState).length > 0 && Object.keys(clientPeriodsState).length && (
                        <ConfigureData ref={childRef}
                                       // onManageBuildClick={this.onManageBuildClick}
                                       // setReportTitle={this.setReportTitle}
                                       startChangeScenario={startChangeScenario}
                                       troubleshootCostFunction={troubleshootCostFunction}
                                       // onChangeScenario={this.onChangeScenario} // call in on handleScenarioChange
                                       setShowCircleLoader={setShowCircleLoader}
                                       goToBuild={goToBuild}
                                       // updateConfigureHeaderElements={this.updateConfigureHeaderElements} // remove period dropdown on other tabs
                                       setBuildPerc={setBuildPerc}
                                       setRunningJob={setRunningJob}
                                       user={userSettings.user}
                                       userAllowedSections={userAllowedMenuLinks}
                                       scenarioList={scenariosState.scenarioList}
                                       tablePrefix={userSettings.tablePrefix}
                                       project_id={userSettings.projectId}
                                       clientId={userSettings.clientId}
                                       clientName={userSettings.name}
                                       scenarioId={scenariosState?.scenario?.value}
                                       scenarioStatus={scenariosState.scenarioStatus}
                                       scenarioChanged={isScenarioChanged}
                                       costCenter={userSettings.costCenter}
                                       selectedPeriod={selectedPeriod ?? clientPeriodsState.nextPeriod}
                                       periods={clientPeriodsState.periods}
                                       generateSegmentVectorsLimit={userSettings.generate_segment_vectors_limit}
                                       launchAppToast={props.launchAppToast}
                                       // reportsHeaderElements={reportsHeaderElements}
                                       getScenarios={getScenarios}
                                       enableManageBuilds={enableManageBuilds} //Maybe not used
                                       // setIsLoaderShown={setIsLoaderShown} // maybe not used
                                       setScenarioDisabled={setScenarioDisabled}
                                       setTabSelected={setTabSelected}
                                       setViewMode={setViewMode}
                                       dispatch={dispatch}
                        />)}
                    </div>
                </div>
            </Suspense>
            <Modal
                id={"menu-save-first-dialog"}
                openDialog={openSaveFirstModal}
                closeClick={() => setSaveFirstDialogOpen(false)}
                bodyContent={() => messageDialogContent(lang.dashboards.messages.want_to_save_first)}
                dialogActions={saveFirstDialogActions}
                size={DIALOG_SIZE.MEDIUM}
            />
            <Modal
                id={"change-scenario-confirm-dialog"}
                openDialog={openChangeScenarioDialog}
                bodyContent={() => <h4>{lang.dashboards.messages.want_to_save_first}</h4>}
                dialogActions={changeScenarioConfirmDialogActions}
                closeClick={() => openChangeScenarioModal(false)}
                size={DIALOG_SIZE.MEDIUM}
            />
        </ErrorBoundary>
    );
};

export {ConfigureBuildWrapper};
