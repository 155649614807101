import React, { Component } from 'react';
import { getTranslationFile } from '../../class/utils.js';
import { getObjectAsArray } from '../../class/jqueries.js';
import { RAW_ITEMS, STAGING_SECTIONS, ENGINE_FILTER, Formats, FILTER, VECTOR_STAGING_ATTRIBUTES} from '../../class/constants';
import EngineFilterDialog from '../filter/EngineFilterDialog';
import FormulaDrop from './FormulaDrop';

const MESSAGES = getTranslationFile();

const _rawFileSubtypeId = ENGINE_FILTER.KEYS.RAW_FILE_SUBTYPE_ID;
const _fileType = ENGINE_FILTER.KEYS.FILE;
const _column = ENGINE_FILTER.KEYS.COLUMN;
const _function = ENGINE_FILTER.KEYS.FUNCTION;
const _valueOptions = ENGINE_FILTER.KEYS.VALUE_OPTIONS;
const _columnOptions = ENGINE_FILTER.KEYS.COLUMN_OPTIONS;
const _fileOptions = ENGINE_FILTER.KEYS.FILE_OPTIONS;
const _functionOptions = ENGINE_FILTER.KEYS.FUNCTION_OPTIONS;
const _fieldDataType = ENGINE_FILTER.KEYS.FIELD_DATA_TYPE;
const _logicalOperator = ENGINE_FILTER.KEYS.LOGICAL_OPERATOR;

const _calcCols = STAGING_SECTIONS.CALCULATED_COLUMNS;

/**
 * ConditionValueFormula Component filter phase 3 and Formula Value if True
 * ConditionValueFormula/ Metrics Mapping
 * @author [Sarah Farjallah]
 */
class ConditionValueFormula extends Component{
    constructor(props) {
        super(props);
        this.state = {
        }
        this.removeCondition = this.removeCondition.bind(this);
        this.setFilterAttrOptions = this.setFilterAttrOptions.bind(this);
        this.handleFilterRowChange = this.handleFilterRowChange.bind(this);
        this.removeFilterRowInCondition = this.removeFilterRowInCondition.bind(this);   
    }

    componentDidUpdate() {
        var obj = this;
        if(this.props.conditionObject && this.props.conditionObject.filters && this.props.conditionObject.filters.length > 0) {
            this.props.conditionObject.filters.forEach((condFilter, rowIndex) =>{
                if(!obj.filterDialRef.state.filterRefs[rowIndex]) return;
                let filterObj = obj.filterDialRef.state.filterRefs[rowIndex].ref.current.filterObj;
                if (filterObj[RAW_ITEMS.SUBTYPE_ID] === "vectors") {
                    filterObj[_columnOptions] = obj.props.vectors;
                } else if (filterObj[RAW_ITEMS.SUBTYPE_ID] === _calcCols) {
                    filterObj[_columnOptions] = obj.props.calculatedCols;
                } 
                //if no file options, do nothing cz file options are passed as props
                // if(!filterObj[_fileOptions] || !filterObj[_fileOptions].length) {
                //     obj.setFilterAttrOptions(rowIndex, _fileOptions, obj.props.fileNames);
                // }

                if(!filterObj[_columnOptions] || !filterObj[_columnOptions].length) {
                    let valueObj = {
                        value: condFilter[_fileType ],
                        [_rawFileSubtypeId]: condFilter[_rawFileSubtypeId]
                    };
                    //set file value to fetch column options
                    obj.handleFilterRowChange(undefined, _fileType, rowIndex, obj.props.conditionNumber, valueObj, true);
                }

                if(!filterObj[_valueOptions] || !filterObj[_valueOptions].length || !filterObj[_functionOptions] || !filterObj[_functionOptions].length) {
                    let valueObj = {
                        value: condFilter[_column],
                        [_function]: condFilter[_function],
                        [_fieldDataType]: condFilter[_fieldDataType]
                    }
                    //set column to fetch functions and values
                    obj.handleFilterRowChange(undefined, _column, rowIndex, obj.props.conditionNumber, valueObj, true);
                }
            });
        }
    }

    componentDidMount() {
        var arr = this.props.conditionObject && this.props.conditionObject.filters ? this.props.conditionObject.filters : [];
        this.filterDialRef.setFilterFromOutside(arr);
        this.forceUpdate();
    }

    setFilterAttrOptions(rowIndex, attribute, options) {
        var _this = this;
        if(!this.filterDialRef.state.filterRefs[rowIndex] || !this.filterDialRef.state.filterRefs[rowIndex].ref.current) {
            return;
        }
        if(attribute === _valueOptions) {
            this.filterDialRef.state.filterRefs[rowIndex].ref.current.isLoading = false;
        }
        this.filterDialRef.state.filterRefs[rowIndex].ref.current.filterObj[attribute] = options;
        let menuIsOpen = this.filterDialRef.state.filterRefs[rowIndex].ref.current.state.menuIsOpen;
        this.filterDialRef.state.filterRefs[rowIndex].ref.current.refresh(function() {
            if(menuIsOpen) {
                _this.filterDialRef.state.filterRefs[rowIndex].ref.current.selectRef.focus();
            }
        });
    }

    handleFilterRowChange(object, attribute, rowIndex, conditionIndex, valueObj, refresh) {
        var _this = this;
        var row =  this.filterDialRef && this.filterDialRef.state.filterRefs &&
            this.filterDialRef.state.filterRefs[rowIndex].ref.current.filterObj ? this.filterDialRef.state.filterRefs[rowIndex].ref.current.filterObj : {};

        let callback = ()=>{};        
        switch (attribute){
            case _column:
                var functionOptions = getObjectAsArray(MESSAGES.ui_filter.dropdowns.engine_filter_functions, valueObj[_fieldDataType].toLowerCase(), "value_type");
                _this.setFilterAttrOptions(rowIndex, _functionOptions, functionOptions);
                callback = function(values) {
                    //setting the value options
                    _this.setFilterAttrOptions(rowIndex, _valueOptions, values);
                    row[_valueOptions] = values;
                    _this.filterDialRef.state.filterRefs[rowIndex].ref.current.isLoading = false
                }
                if (row && row[_fieldDataType].toLowerCase() !== Formats.Numeric.toLowerCase()) {
                    var subTypeId = row[RAW_ITEMS.SUBTYPE_ID];
                    var type = "";
                    var value = row[_column];
                    let valueModified = row[_column].replace(FILTER.VALUES.FIELD.KEY,"").replace(FILTER.VALUES.FIELD.NAME,"").replace(FILTER.VALUES.FIELD.NUMBER,"");
                    let vectorId = "";
                    let filterAttribute = "";
                    let hiddenVectors = _this.props.hiddenVectors;
                    if (row[RAW_ITEMS.SUBTYPE_ID] === "vectors") {
                        type="vectors";
                        if (row[RAW_ITEMS.SUBTYPE_ID] === "vectors" && row.column !== "") {
                            subTypeId = _this.props.vectors.filter(el=>el.value === row.column)[0][RAW_ITEMS.SUBTYPE_ID];
                            value = _this.props.vectors.filter(el=>el.value === row.column)[0].usedValue;
                            vectorId = _this.props.vectorOptions.filter(el=>el.value === valueModified).length > 0 ? _this.props.vectorOptions.filter(el=>el.value === valueModified)[0][VECTOR_STAGING_ATTRIBUTES.ID] : hiddenVectors.filter(el=>el[VECTOR_STAGING_ATTRIBUTES.NAME] === valueModified).length > 0 ? hiddenVectors.filter(el=>el[VECTOR_STAGING_ATTRIBUTES.NAME] === valueModified)[0][VECTOR_STAGING_ATTRIBUTES.ID]: "0";
                            if(row.column.endsWith(FILTER.VALUES.FIELD.KEY)){
                                filterAttribute = FILTER.VALUES.FIELD.KEY;
                            } else if(row.column.endsWith(FILTER.VALUES.FIELD.NAME)){
                                filterAttribute = FILTER.VALUES.FIELD.NAME;
                            } else {
                                filterAttribute = FILTER.VALUES.FIELD.NUMBER;
                            }
                        } else {
                            filterAttribute = FILTER.VALUES.FIELD.KEY;
                            vectorId = "0";
                        }
                    }
                    _this.props.getColumnValues(subTypeId, row[_fieldDataType], value, rowIndex, row[_function], rowIndex, "", callback, type,vectorId,filterAttribute);
                }
                break;
            case _fileType:
                let cols = [];
                if (valueObj[RAW_ITEMS.SUBTYPE_NAME] === "vectors") {
                    cols = _this.props.vectors;
                } else if (valueObj[RAW_ITEMS.SUBTYPE_NAME] === _calcCols) {
                    cols = _this.props.calculatedCols;
                } else {
                    var options = _this.props.allTypeColumns.filter(row=>row[RAW_ITEMS.SUBTYPE_ID] === valueObj[RAW_ITEMS.SUBTYPE_ID]);
                    cols = [];
                    for (var e in options) {
                        cols.push({label:options[e][RAW_ITEMS.FIELD_NAME] , value:options[e][RAW_ITEMS.FIELD_NAME] , [RAW_ITEMS.SUBTYPE_NAME]:options[e][RAW_ITEMS.SUBTYPE_NAME],
                            [RAW_ITEMS.SUBTYPE_ID]: options[e][RAW_ITEMS.SUBTYPE_ID], field_data_type: options[e][RAW_ITEMS.FIELD_DATA_TYPE],
                            [RAW_ITEMS.DATA_FIELD]: options[e][RAW_ITEMS.DATA_FIELD]
                        })
                    }
                }
                _this.setFilterAttrOptions(rowIndex, _columnOptions, cols);
                break;

            case _logicalOperator: // to enable column drop down when adding new row      
                _this.setFilterAttrOptions(rowIndex+1, _fileOptions, row[_fileOptions], row[_rawFileSubtypeId]);
                _this.setFilterAttrOptions(rowIndex+1, _columnOptions, row[_columnOptions]);
            default:
                break;
        }
        
        if (refresh === undefined) {
            refresh = true;
        } else {
            refresh = false;
        }

        this.props.updateFilter(this.props.rowIndex, this.filterDialRef.state.filterRefs[rowIndex].ref.current.filterObj, rowIndex, refresh);

    }

    removeCondition(){
        this.props.deleteCondition(this.props.rowIndex);   
    }

    removeFilterRowInCondition(rowIndex) {
        this.props.removeFilterRowInCondition(rowIndex, this.props.conditionNumber);   
    }

    updateConditionFilter=(filterRefs)=>{
        let _this = this;
        _this.props.updateConditionFilter(filterRefs, this.props.conditionNumber);
    }

    render() {
        var _this = this;

        var formula = this.props.formula && this.props.formula.length > 0 ? this.props.formula:
        this.props.mappedMetric && this.props.mappedMetric.metric_configuration && this.props.mappedMetric.metric_configuration.length > 0 && this.props.mappedMetric.metric_configuration[0].conditionFormula &&
                this.props.mappedMetric.metric_configuration[0].conditionFormula.length > 0 &&
                this.props.mappedMetric.metric_configuration[0].conditionFormula[0].conditions &&
                this.props.mappedMetric.metric_configuration[0].conditionFormula[0].conditions[_this.props.conditionNumber] && 
                this.props.mappedMetric.metric_configuration[0].conditionFormula[0].conditions[_this.props.conditionNumber].result
                ? this.props.mappedMetric.metric_configuration[0].conditionFormula[0].conditions[_this.props.conditionNumber].result : [];
   
        return (
            <div className="uk-border uk-border-rounded uk-margin-top">
                <div className="uk-display-flex justify-content-between align-items-center condition-header">
                    <span className="uk-text-bold fs-14">{"Condition " + ((Number(this.props.conditionNumber) + 1).toString())}</span>
                    <div className="uk-button-icon transparent-bg uk-float-right uk-text-normal" onClick={() => { this.removeCondition() }}>
                        <i className="fal fa-trash-alt fa-lg uk-margin-small-right" />
                        {MESSAGES.metrics_delete_condition_button}
                    </div>
                </div>
                <div className="w100 uk-display-inline-flex uk-padding-small">
                    <div className="w100">
                        <div className="vectors-filter-container condition_filter_container">
                        <EngineFilterDialog
                                stagingReport={STAGING_SECTIONS.METRICS_MAPPING}
                                fileTypeOptions={this.props.chosenFiles ? this.props.chosenFiles : this.props.fileNames}
                                ref={el => this.filterDialRef = el}
                                onDropDownChange={this.handleFilterRowChange}
                                loadEntitiesFunc={this.props.loadEntitiesFunc}
                                compNumber={this.props.conditionNumber+1}
                                showFileDropdown={true} 
                                conditionIndex={this.props.conditionNumber}
                                removeFilterRowInCondition={this.removeFilterRowInCondition}
                                vectorOptions = {this.props.vectorOptions}
                                periodName={this.props.periodName}
                                hiddenVectors = {this.props.hiddenVectors}
                                updateConditionFilter={this.updateConditionFilter}
                                />
                        </div>
                         <FormulaDrop appendToFormula={this.props.appendToFormula}
                            ref={el=>this.formulaRef = el} isInnerComp={true}
                            hasConditions={this.props.hasConditions}
                            validateFormulaResult={this.props.validateFormulaResult}
                            setFormulaIndex={this.props.setFormulaIndex} resetFormulaChanges={this.props.resetFormulaChanges}
                            deleteLastFormulaItem={this.props.deleteLastFormulaItem} deleteFormula={this.props.deleteFormula}
                            report={STAGING_SECTIONS.METRICS_MAPPING} conditionNumber={this.props.conditionNumber}
                            formula={formula} />
                    </div>
                </div>
            </div>
         
        );
    }
}

export default ConditionValueFormula;