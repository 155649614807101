//This is the second sub component
import React, { Component } from 'react';
import {TabulatorFull} from "tabulator-tables";
import { linearizeHierarchy } from '../../class/array.js';
import { cleanUpTabulatorColumns, getCostTooltipData, handleCurrency, toggleLoader } from '../../class/common.js';
import {
  AMOUNT_OF,
  AVG_MED_TOGGLE_TABS,
  BUTTON_TYPE,
  BUTTON_VARIANT,
  CONFIGURE_TOGGLE_TABS,
  DIALOG_SIZE,
  FIRST_ATTRIBUTE,
  FY_VALUES,
  FormatTypes,
  PER_SELECTIOn,
  PROFIT_STACK_LINES,
  PSL,
  PSS,
  PS_MAPPING,
  SCENARIO_STATUS,
  SECOND_ATTRIBUTE,
  SELECTED_CONF_OPTIONS,
  SIZES,
  STACKS_RANGE,
  STACK_CONF,
  VECTOR_ANALYSIS,
  VECTOR_ATTRIBUTE,
  YTD_TOTAL_COLUMN_SUFFIX,
  column_suffixes_values,
  costtype
} from '../../class/constants.js';
import { formatValHTML, formatValString } from '../../class/format.js';
import { convertPxToViewport, convertViewportToPx } from '../../class/formatting';
import { changeHeaderWidthOnOverFlow, replaceSpecialCharacters } from '../../class/jqueries.js';
import { fetchAPI } from '../../class/networkUtils.js';
import { exportToExcel } from '../../class/tabulatorExport.js';
import { copyObjectValues, updateChildAttrByKey } from '../../class/utils.js';
import { lang } from '../../language/messages_en.js';
import Button from '../../newComponents/Button.js';
import Modal from '../../newComponents/Modal.js';
import { customFilterEditor, getExpandCollapseButtons } from '../../newComponents/tabulatorComponents.js';
/**
 * Profit stacks table in profit stacks screen + profit stack table in bridge report screen
 * @author [Sarah Farjallah]
 */
const _children = PS_MAPPING.FIELDS.CHILDREN;
const $ = require('jquery');
const PROFIT_STACK = "profit_stack";
const PROFIT_STACK_TITLE = "Profit Stack";
const CURRENT = "selection";
const PRE = "comparison";
const PERC = "perc";
const ARROW_UP = "arrowUp";
const VARIANCE_PERC = "variance_perc";
const REV_PERC = "perc_";
const IS_GREEN = "isGreen";
const TD = "_td";
const FORMAT = "format";
const SEGMENTS_DELTA ="segments_delta"
const VARIANCE_TD = "_comparison_tdvariance";
const VARIANCE = "_comparisonvariance";
const GROUP = "group";
const CUSTOM_VIEWS = "customViews";
const Inflector = require('inflected');
const PARENT = "parent";
const ATTRIBUTES = "attributes";
const AMOUNTS = "amounts";
const ENT = "_ent_";
const MED = "_med";
const TITLES = "titles";

const EXPAND_ELEMENT =
{ 
    title: '', 
    field: PS_MAPPING.FIELDS.EXPAND,
    headerSort: false,
    dontFilter: true,
    width: '6%',
    cssClass: "expand-collapse-cell",
    download: false
}
const _groupedEntities = lang.heatmap_configure.entities.grouped_entities;

class ProfitStackTabulator extends Component{
    constructor(props) {
        super(props);
        this.state = {
            filterFinal: "[]",
            columns: [],
            level:0,
            lowestLevel:0,
            isTableExpanded:false,
            showConfigureDialog: false,
            showExportDialog: false,
            fromExpandCollapseButton: false,
        }
        this.fetchAPI = fetchAPI.bind(this);
        this.setColumns = this.setColumns.bind(this);
        this.getTabulator = this.getTabulator.bind(this);
        this.expandNextLevel = this.expandNextLevel.bind(this);
        this.collapseAll = this.collapseAll.bind(this);
        this.exportTableToExcel = this.exportTableToExcel.bind(this);
        this.getColumnFormatter = this.getColumnFormatter.bind(this);
        this.checkAllTree = this.checkAllTree.bind(this);
        this.linearize = this.linearize.bind(this);
        this.extractLowestLevel = this.extractLowestLevel.bind(this);
        this.appendExportUnderScore = this.appendExportUnderScore.bind(this);

        this.exportBtnRef = React.createRef();
        this.expandBtnRef = React.createRef();
        this.bridgeExpandBtnRef = React.createRef();
        this.collapseBtnRef = React.createRef();

    }
    
    getTabulator() {
		if(this.tabulator)
			return this.tabulator;
		return null;
    }

    getTabulator2() {
		if(this.tabulator2)
			return this.tabulator2;
		return null;
    }
    /**
     * Formats sub-columns of YOY to have a colored circle next to selected year and comparison year
     * @param {*} cell 
     * @param {*} params 
     * @returns 
     */
    addYOYCustomTitleFormatter(cell, params) {
        let div = document.createElement("div");
        let icon = document.createElement("span");
        div.classList.add("uk-flex-middle");
        if (params.field && params.field.includes(CURRENT) && !params.field.includes("variance") && params.comp.props.yearOptions.length > 1) {
            icon.classList.add("colored-circle", "uk-margin-small-left");
            icon.style.backgroundColor = params.comp.props.yearOptions[1].color;
            div.innerHTML = params.title.replace(" ","");
            div.appendChild(icon);
        }else if(params.field && params.field.includes(PRE) && !params.field.includes("variance") && params.comp.props.yearOptions.length > 0){
            icon.classList.add("colored-circle", "uk-margin-small-left");
            icon.style.backgroundColor = params.comp.props.yearOptions[0].color;
            div.innerHTML = params.title.replace(" ","");
            div.appendChild(icon);
        }else {
            div.innerHTML = params.title?.includes("Avg-1-") || params.title?.includes("Med-1-") || params.title?.includes("Avg-2-") || params.title?.includes("Med-2-")? Inflector.singularize(params.title.replace("Avg-1-","Avg").replace("Avg-2-","Avg").replace("Med-1-","Med").replace("Med-2-","Med")):params.title;
        }
        return div;
    }

    /**
     * Formats group column title to have a colored circle next to title for multiple entities (Stacks by Range)
     * @param {*} cell 
     * @param {*} params 
     * @returns 
     */
    addRangeCustomTitleFormatter = (cell, params) => {
        let div = document.createElement("div");
        div.classList.add("uk-text-overflow-ellipsis");

        if(params.color) {
            let icon = document.createElement("span");
            div.classList.add("uk-flex-middle", "group_title_circle");
            icon.style.backgroundColor = params.color;
            div.innerHTML = params.title;
            if (params.comp.props.isFromList) {
                icon.classList.add("colored-circle", "uk-margin-small-right");
                div.prepend(icon);
            } else {
                icon.classList.add("colored-circle", "uk-margin-small-left");
                div.append(icon);
            }
        } else {
            if(params.title === VECTOR_ANALYSIS.FIELDS.DELTA_SYMBOL){
                div.classList.add("delta_symbol");
            }
            div.innerHTML = params.title;
        }
        
        return div;
    }

    /**
     * @function checkIfContainerHasOverflow()
     * check if container has overflow and based on it change width of header
     * to keep the column border and header border aligned.
     */

    checkIfContainerHasOverflow = () => {
        let _this = this;
        let element = $("#profit-stack-table #profit_stack_comp_table .tabulator-tableholder")[0];
        if(!element) {
          return;
        }

        let content = element.scrollHeight;
        let mainContainer = element.clientHeight;
        let contentWidth = element.scrollWidth;
        let mainContainerWidth = element.clientWidth;
        let elementToModify = (_this.props.isYTD || _this.props.isMoM) ? $(".tabulator-col.frozenRightColumn") : _this.props.isYOY || _this.props.isFromBubble || _this.props.isFromList ? $(".tabulator-col-group") : null;

        if (elementToModify) {
            let overFlow = content > mainContainer;
            let horizontalOverflow = contentWidth > mainContainerWidth + 75;
            if (_this.props.isYTD || _this.props.isMoM) {
                if ((elementToModify.attr("role", "columnheader"))) {
                    changeHeaderWidthOnOverFlow(overFlow, elementToModify, convertViewportToPx(200), convertViewportToPx(214));
                }
            } else if (_this.props.isYOY) {
                changeHeaderWidthOnOverFlow(overFlow, elementToModify.last(), convertViewportToPx(800), convertViewportToPx(800));

            } else if (_this.props.isFromBubble){
                changeHeaderWidthOnOverFlow(overFlow, elementToModify.last(), convertViewportToPx(550), convertViewportToPx(550), horizontalOverflow, convertViewportToPx(665));
            } else if (_this.props.isFromList && elementToModify.length > 2) {
                changeHeaderWidthOnOverFlow(overFlow, elementToModify.last(), convertViewportToPx(690), convertViewportToPx(690));
            }
        }
    }
    
    getColumnFormatter(colField, groupCol, classNameRight, classNameLeft, shouldLoadColumns, colTitle) {
        let _this = this;
        var columnFormatter;
        if(colField && colField === VECTOR_ANALYSIS.FIELDS.NAME){
            columnFormatter = function(cell, formatterParams) {
                var rowData = cell.getRow().getData();
                let rowElement = cell.getRow().getElement();

                var div = document.createElement("div");
                var p = document.createElement("p");
                p.classList.add("uk-text-overflow-ellipsis");
                div.classList.add("uk-text-overflow-ellipsis");
                p.innerHTML = rowData[VECTOR_ANALYSIS.FIELDS.NAME];
                var name = rowData[VECTOR_ANALYSIS.FIELDS.NAME]; // Keep name as is
                if(_this.state.headerValue){
                 var headerValue = _this.state.headerValue.toLowerCase().trim(); // Convert headerValue to lowercase for case-insensitive comparison
                }

                if (name.toLowerCase().includes(headerValue)) { // Convert name to lowercase for case-insensitive comparison
                    var index = name.toLowerCase().indexOf(headerValue); // Convert name to lowercase for case-insensitive comparison
                    var highlightedName = name.substring(0, index) +
                        '<span class="highlight_search_result">' + name.substring(index, index + headerValue.length) + '</span>' +
                        name.substring(index + headerValue.length);
                    p.innerHTML = highlightedName;
                } else {
                    p.innerHTML = name;
                }
                p.setAttribute("id", replaceSpecialCharacters(rowData[VECTOR_ANALYSIS.FIELDS.NAME].replace(/ /g,'_'),""));
                if(rowData[VECTOR_ANALYSIS.FIELDS.LEVEL] !== 0 && rowData[VECTOR_ANALYSIS.FIELDS.LEVEL] !== 1 && rowData[VECTOR_ANALYSIS.FIELDS.LEVEL] !== 2) {
                    var pixels = (rowData[VECTOR_ANALYSIS.FIELDS.LEVEL]-1)*5;
                    // $(p).css("padding-left", convertPxToViewport(pixels));
                }

                // if (rowData[VECTOR_ANALYSIS.FIELDS.LEVEL] === 0) {
                //     $(rowElement).css({"border-top":"0.052vw solid #DCDCDC"});
                // } else if (rowData[VECTOR_ANALYSIS.FIELDS.LEVEL] === 1){
                //     $(rowElement).css({"border-top":"0.052vw solid #DCDCDC"});
                // } else {
                //     $(rowElement).css({"border-top":"0.052vw solid #cacaca"});
                // }
                rowElement.classList.add(`row_level_${rowData[VECTOR_ANALYSIS.FIELDS.LEVEL]}`)
                p.classList.add("loading-cost-classification");
                $(p).on("mouseenter", () => {
                    if ( _this.props.costClassification && _this.props.costClassification.filter(e => e.returnname === rowData[VECTOR_ANALYSIS.FIELDS.RETURN_NAME]).length > 0) {
                        $(p).addClass("psl-title uk-cursor-pointer");
                        $(p).on("click", () => _this.setMoreDetailsDialogOpen(true, rowData));
                    } else if (!rowData.is_expandable && (_this.props.costClassification?.length === 0)) {
                        $(p).addClass("wait-cursor");
                    }
                });
               
                div.append(p);
                return div;
            }
        } else if (!_this.props.isRange && colField && (colField.replace(" ","_").includes(VARIANCE_PERC) || colField.replace(" ","_").includes(REV_PERC))){// adding the arrows next to varince percentage
            columnFormatter = function(cell, formatterParams) {
                var p = document.createElement("p");
                var icon = document.createElement('i');
                var rowData = cell.getRow().getData();
                p.classList.add('variance-perc');
                icon.classList.add('fas');
                var td = colField.includes(TD) ? VARIANCE_TD : VARIANCE;
                if (formatValString(rowData[td],(rowData.format ? rowData.format : FormatTypes.AMOUNT) ) === "-") {
                    icon.classList.add('fa-minus');
                    icon.classList.add('orange-color');
                } else {
                    /*if ((rowData[ARROW_UP] === "true" && !colField.includes(TD) || rowData[ARROW_UP+TD] === "true" && colField.includes(TD))) {
                        icon.classList.add('fa-caret-up', 'fa-lg');
                    } */
                    if ((rowData[ARROW_UP] === "true" && !colField.includes(TD) || rowData[ARROW_UP+TD] === "true" && colField.includes(TD))) {
                        icon.classList.add('fa-caret-up', 'fa-lg');
                    } else {
                        icon.classList.add('fa-caret-down', 'fa-lg');
                    }
                    if ((rowData.costtype === costtype.standard && rowData.returnname.endsWith("_ms")) || rowData[GROUP] && rowData[GROUP] === CUSTOM_VIEWS) {
                        // rowData.costtype === costtype.standard && rowData.returnname.endsWith("_ms")) ===> standard group (Created in manage stacks)
                        icon.classList.add('text-black');
                    }else if ((rowData[IS_GREEN] === "true" && !colField.includes(TD) || rowData[IS_GREEN+TD] === "true" && colField.includes(TD))) {
                        icon.classList.add('arrow_variance_positive');
                    } else {
                        icon.classList.add('uk-text-primary');
                    }
                }
                p.innerHTML = rowData[VECTOR_ANALYSIS.FIELDS.ATTRIBUTE] === 'attribute' && !colField.includes(PERC) ? formatValHTML(cell.getValue(), FormatTypes.NUMERIC) : formatValHTML(cell.getValue(), cell.getColumn().getDefinition().format_type);
                if(colField.includes(VARIANCE_PERC)) {
                    p.appendChild(icon);
                }
                return p;
            }
        } else{
            columnFormatter = function(cell, formatterParams) {
                let rowData = cell.getRow().getData();
                let colDefinition = cell.getColumn()?.getDefinition();
                let colField = cell.getColumn().getField();
                let secondAttr = _this.props.stackConfigObj?.secondAttribute?.value;
                let firstAttr = _this.props.linearizedData?.filter(e=>e[PS_MAPPING.FIELDS.RETURN_NAME.toLowerCase()] === _this.props.stackConfigObj?.firstAttribute?.value)[0];
                let filteredCheckedEntities = _this.props.checkedEntities?.find(e=>e.label === groupCol.title) || _this.props.checkedEntities?.find(e=>e.value === groupCol.field);
                let uniqueKeyFomEntities = filteredCheckedEntities?.uniqueKey;

                let amountStr = groupCol && groupCol.field === SEGMENTS_DELTA ?groupCol.field 
                    : groupCol && groupCol.field !== _groupedEntities && _this.props.isRange ?
                         groupCol.field? "amount_ent_"+uniqueKeyFomEntities
                         : "amount_ent_"+(groupCol.title !== "" ?  groupCol.title.split("(")[1].replaceAll(/\)/g, "") : "")
                            : "amount";
                let amountPrefix = "";
                if(_this.props.checkedEntities?.[0]?.vectorMachineName1 && filteredCheckedEntities){
                    amountPrefix = "_"+Object.keys(filteredCheckedEntities)
                    .filter(key => key.startsWith("vectorValue"))
                    .map(key => filteredCheckedEntities[key])
                    .filter(value => value)
                    .join("_");
                }
                let checkedEntities = groupCol && groupCol.field === SEGMENTS_DELTA?_this.props.checkedEntities?.map(e=>e.uniqueKey || e.value):"";
                var p = document.createElement("p");
                let img = document.createElement("img");
                img.src = '/images/FhHRx.gif'; // the loader image
                img.style.width = convertPxToViewport(15);
                img.style.height = convertPxToViewport(15);
                // if psl is calculated, we set its value to 0 if in configure we select per attribute or per vector
                let isNullified = rowData[PS_MAPPING.FIELDS.COSTTYPE] === costtype.calculated && rowData.format === FormatTypes.PERCENTAGE; 

                if (colDefinition && colDefinition?.format_type) {
                    let format = "";
                    if((colField.split(ENT)[0]===PER_SELECTIOn || colField.split(ENT)[0] === STACKS_RANGE.FIELDS.AMOUNT)&& shouldLoadColumns && shouldLoadColumns.includes(STACK_CONF.AMOUNT_TYPE)){
                        p = document.createElement("p");
                        p.style.marginLeft = '98%';
                        p.appendChild(img); // show loader
                    }else{
                        format = rowData[VECTOR_ANALYSIS.FIELDS.ATTRIBUTE] === 'attribute' && !colField.includes(PERC) ? FormatTypes.NUMERIC : 
                                    rowData[FORMAT] ? rowData[FORMAT] : colDefinition?.format_type ? colDefinition.format_type : FormatTypes.AMOUNT;
                        if(colField.split(ENT)[0]===PER_SELECTIOn){
                            format = colDefinition.format_type;
                        }
                        let value = _this.props.stackConfigObj ? 
                                  (_this.props.stackConfigObj.amountType === STACKS_RANGE.FIELDS.AMOUNT ? rowData[amountStr] : (groupCol?.field === SEGMENTS_DELTA ?
                                    rowData.per_selection_segments_delta : rowData[PER_SELECTIOn+(groupCol && groupCol.field !== _groupedEntities ? "_"+(uniqueKeyFomEntities || groupCol?.field+amountPrefix):"")]
                                  ))
                                : cell.getValue();
                        let valFormat = (_this.props.stackConfigObj && _this.props.stackConfigObj.amountType !== STACKS_RANGE.FIELDS.AMOUNT && rowData.costtype === costtype.attribute ? FormatTypes.PERCENTAGE : format)
                           
                        if(_this.props.isMoM) {
                          let columnPeriod = colTitle.trim();
                          let isTotal = columnPeriod === "Total";

                          if(!!_this.props.stackConfigObj.psl){
                            valFormat = FormatTypes.PERCENTAGE;
                            value = rowData.costtype === costtype.attribute  || (rowData[PS_MAPPING.FIELDS.COSTTYPE] === costtype.calculated && rowData.format === FormatTypes.PERCENTAGE) ? 0 
                            : (cell.getValue()/_this.props.linearizedData.find(e=>e[PS_MAPPING.FIELDS.RETURN_NAME.toLowerCase()] === _this.props.stackConfigObj[SELECTED_CONF_OPTIONS.PER_PSL])[colField])*100;
                          } else if(!!_this.props.stackConfigObj?.firstAttribute) {
                            let attribute = _this.props.stackConfigObj?.firstAttribute.value;
                            if(_this.props.stackConfigObj.isFirstMedian) {
                              if(columnPeriod === "Total") {
                                let mediansData = _this.props.mediansData;
                                value = mediansData && mediansData[attribute + "_A"] ? mediansData[attribute + "_A"][rowData[PSS.RETURN_NAME]] : 0;
                              } else {
                                let mediansDataByPeriod = _this.props.mediansDataByPeriod;
                                value = mediansDataByPeriod && mediansDataByPeriod[attribute + "_A_" + columnPeriod] ? mediansDataByPeriod[attribute + "_A_" + columnPeriod][rowData[PSS.RETURN_NAME]] : 0;
                              }
                              value = isNullified ? 0 : value;
                            } else {
                              let selectedAttrVal = _this.props.linearizedData.find(e=>e[PS_MAPPING.FIELDS.RETURN_NAME.toLowerCase()] === attribute)[colField]
                              value = isNullified ? 0 : cell.getValue()/selectedAttrVal;
                            }
                          } else if(!!_this.props.stackConfigObj?.secondAttribute) {
                            if(_this.props.stackConfigObj.isSecondMedian){
                                let field = _this.props.stackConfigObj.secondAttribute.value;
                                if (isNullified) {
                                    value = 0;
                                }else{
                                    let mediansData = isTotal?_this.props.mediansData : _this.props.mediansDataByPeriod;
                                    let periodsPrefix = isTotal?"":("_"+colTitle.trim());
                                    value = mediansData && mediansData[field+"_A"+periodsPrefix]?mediansData[field+"_A"+periodsPrefix][rowData[PSS.RETURN_NAME]]:0;
                                }
                            }else{
                                let vectorCount = columnPeriod === "Total" ? _this.props.vectorCountData[0].totalCount : _this.props.vectorCountData?.find(f => f.period === colTitle?.trim())?.count
                                value = isNullified ? 0 : cell.getValue()/vectorCount;
                            }
                          } else {
                            value = cell.getValue();
                            valFormat = _this.props.stackConfigObj?.amountType !== STACKS_RANGE.FIELDS.AMOUNT ? FormatTypes.PERCENTAGE : valFormat;
                          }
                          
                        }
                        p.innerHTML =  formatValHTML(value, valFormat);
                    }
                    if(_this.props.stackConfigObj && _this.props.stackConfigObj[STACK_CONF.IS_FIRST_MED] && colDefinition.title.startsWith(handleCurrency("Med-1- $")) && _this.props.mediansData){
                        let field = _this.props.stackConfigObj.firstAttribute.value;
                        if(shouldLoadColumns && ([STACK_CONF.IS_FIRST_MED, FIRST_ATTRIBUTE.value].includes(shouldLoadColumns))){
                            p = document.createElement("p");
                            p.style.marginLeft = '98%';
                            p.appendChild(img);
                        }else{
                            if ((rowData[PS_MAPPING.FIELDS.COSTTYPE] === costtype.attribute) ||
                                (_this.props.attributes.find(e=>field === e.value && !e.isCount)) ||
                                (rowData[PS_MAPPING.FIELDS.COSTTYPE] === costtype.calculated && rowData.format === FormatTypes.PERCENTAGE)) {
                                p.innerHTML =  formatValHTML(0, format);
                            }else{
                                let deltaMed = groupCol && groupCol.field === SEGMENTS_DELTA? (Number(_this.props.mediansData[field+"_"+checkedEntities[0]]?_this.props.mediansData[field+"_"+checkedEntities[0]][rowData.returnname]:0) || 0) - (Number(_this.props.mediansData[field+"_"+checkedEntities[1]]?_this.props.mediansData[field+"_"+checkedEntities[1]][rowData.returnname]:0) || 0):"";
                                p.innerHTML = deltaMed && deltaMed!==""? formatValHTML(deltaMed, format) :_this.props.mediansData[field+(groupCol?("_"+groupCol.field.toUpperCase()+amountPrefix):"_A")]?formatValHTML(_this.props.mediansData[field+(groupCol?("_"+groupCol.field.toUpperCase()+amountPrefix):"_A")][rowData[PSS.RETURN_NAME]], format):formatValHTML(0, format);
                            }
                        }
                    }else if(_this.props.stackConfigObj && _this.props.stackConfigObj[STACK_CONF.IS_SECOND_MED] && colDefinition.title.startsWith(handleCurrency("Med-2- $")) && _this.props.mediansData){
                        if(shouldLoadColumns && (shouldLoadColumns.includes(SECOND_ATTRIBUTE.value))){
                            p = document.createElement("p");
                            p.style.marginLeft = '98%';
                            p.appendChild(img);
                        }else{
                          if (isNullified) {
                            p.innerHTML = formatValHTML(0, format);
                          } else {
                            let deltaMed = "";
                            if (groupCol?.field === SEGMENTS_DELTA) {
                              let hasFirstColMed = !!_this.props.mediansData[secondAttr + "_" + checkedEntities[0]];
                              let hasSecondColMed = !!_this.props.mediansData[secondAttr + "_" + checkedEntities[1]];

                              let firstColValue = hasFirstColMed ? Number(_this.props.mediansData[secondAttr + "_" + checkedEntities[0]][rowData.returnname]) : 0;
                              let secondColValue = hasSecondColMed ? Number(_this.props.mediansData[secondAttr + "_" + checkedEntities[1]][rowData.returnname]) : 0;
                              deltaMed = firstColValue - secondColValue;
                            }

                            let secondAttrSuffix = groupCol ? "_" + groupCol.field.toUpperCase() + amountPrefix : "_A";
                            if (deltaMed && deltaMed !== "") {
                              p.innerHTML = formatValHTML(deltaMed, format);
                            } else if (_this.props.mediansData[secondAttr + secondAttrSuffix]) {
                              p.innerHTML = formatValHTML(_this.props.mediansData[secondAttr + secondAttrSuffix][rowData[PSS.RETURN_NAME]], format);
                            } else {
                              p.innerHTML = formatValHTML(0, format);
                            }
                          }
                        }
                    }else if (_this.props.firstParentCH && _this.props.stackConfigObj && colField.split(ENT)[0] === _this.props.stackConfigObj.psl) {
                        if(shouldLoadColumns && shouldLoadColumns.includes(PSL.value)){
                            p = document.createElement("p");
                            p.style.marginLeft = '98%';
                            p.appendChild(img);
                        }else{
                            format = FormatTypes.PERCENTAGE;
                            let deltaPerc = groupCol && groupCol.field === SEGMENTS_DELTA? 100*((Number(rowData["amount_ent_"+checkedEntities[0]+amountPrefix]/_this.props.linearizedData.filter(e=>e.returnname === _this.props.firstParentCH.returnName)[0]["amount_ent_"+checkedEntities[0]]) || 0) - (Number(rowData["amount_ent_"+checkedEntities[1]]/_this.props.linearizedData.filter(e=>e.returnname === _this.props.firstParentCH.returnName)[0]["amount_ent_"+checkedEntities[1]]) || 0)):"";
                            p.innerHTML = rowData.costtype === costtype.attribute  || (rowData[PS_MAPPING.FIELDS.COSTTYPE] === costtype.calculated && rowData.format === FormatTypes.PERCENTAGE) ? formatValHTML(0,format) : deltaPerc !== ""?formatValHTML(deltaPerc, format): formatValHTML((rowData[amountStr]/_this.props.linearizedData.filter(e=>e[PS_MAPPING.FIELDS.RETURN_NAME.toLowerCase()] === _this.props.firstParentCH[PS_MAPPING.FIELDS.RETURN_NAME])[0][amountStr])*100, format);
                        }
                    } else if (_this.props.firstAttributeCH && colTitle.includes("-1-") &&_this.props.stackConfigObj.firstAttribute && colField.split(ENT)[0] === _this.props.stackConfigObj.firstAttribute.value) {
                        if(shouldLoadColumns && (shouldLoadColumns.includes(FIRST_ATTRIBUTE.value) || shouldLoadColumns.includes(STACK_CONF.IS_FIRST_MED))){
                            p = document.createElement("p");
                            p.style.marginLeft = '98%';
                            p.appendChild(img);
                        }else{
                            // format = FormatTypes.RATIO+":"+colField.split(ENT)[0];
                            if (rowData[PS_MAPPING.FIELDS.COSTTYPE] === costtype.attribute  || 
                                (rowData[PS_MAPPING.FIELDS.COSTTYPE] === costtype.calculated && rowData.format === FormatTypes.PERCENTAGE)) {
                                p.innerHTML =  formatValHTML(0, format);
                            }else{
                                let deltaVal = groupCol && groupCol.field === SEGMENTS_DELTA? (Number(rowData["amount_ent_"+checkedEntities[0]]/firstAttr["amount_ent_"+checkedEntities[0]]) || 0) - (Number(rowData["amount_ent_"+checkedEntities[1]]/firstAttr["amount_ent_"+checkedEntities[1]]) || 0):"";
                                p.innerHTML =  deltaVal!==""?formatValHTML(deltaVal,format):formatValHTML((rowData[amountStr]/firstAttr[amountStr]), format);
                            }
                        }
                    }else if (_this.props.secondAttributeCH && colTitle.includes("-2-") && _this.props.stackConfigObj.secondAttribute && colField.split(ENT)[0] === _this.props.stackConfigObj.secondAttribute.value) {
                        if(shouldLoadColumns && (shouldLoadColumns.includes(SECOND_ATTRIBUTE.value) || shouldLoadColumns.includes(STACK_CONF.IS_SECOND_MED))){
                            p = document.createElement("p");
                            p.style.marginLeft = '98%';
                            p.appendChild(img);
                        }else{
                            if (isNullified) {
                              p.innerHTML =  formatValHTML(0, format);
                            } else {
                              let deltaVal = "";
                              let isEntityStacks = !!groupCol;
                              let vectorCount = isEntityStacks ? _this.props.vectorCountData[(uniqueKeyFomEntities) || (groupCol.field+amountPrefix)] : _this.props.vectorCountData;

                              if (groupCol?.field === SEGMENTS_DELTA) {
                                let firstColVectorCount = _this.props.vectorCountData[checkedEntities[0]];
                                let secondColVectorCount = _this.props.vectorCountData[checkedEntities[1]];
                                let firstColValue = Number(rowData["amount_ent_"+checkedEntities[0]]/firstColVectorCount) || 0;
                                let secondColValue = Number(rowData["amount_ent_"+checkedEntities[1]]/secondColVectorCount) || 0;
                                deltaVal = firstColValue - secondColValue;
                              }

                              if (deltaVal && deltaVal !== "") {
                                p.innerHTML = formatValHTML(deltaVal, format);
                              } else {
                                p.innerHTML = formatValHTML((rowData[amountStr]/vectorCount), format);
                              }
                            }
                        }
                    }
                    if(classNameRight || classNameLeft){
                        p.className = classNameRight || classNameLeft;
                    }
                }  
                return p;
            }
        }
        return columnFormatter;
    }

    getColumnAccessor(colField, groupCol, colTitle) {
        let _this = this;
        var columnFormatter;
        if (colField && colField === VECTOR_ANALYSIS.FIELDS.NAME) {
            columnFormatter = function (value, data, type, params, colComponent, rowComponent) {
                return value;
            }
        } else if (!_this.props.isRange && colField && (colField.includes(VARIANCE_PERC) || colField.includes(REV_PERC))) {// adding the arrows next to varince percentage
            columnFormatter = function (value, data, type, params, colComponent, rowComponent) {
                data.format = colComponent._column.definition.format_type; //colComponent._column.field && data[VECTOR_ANALYSIS.FIELDS.ATTRIBUTE] === costtype.attribute && !colComponent._column.field.includes(PERC) ?
                   // formatValString(value, FormatTypes.NUMERIC) : formatValString(value, colComponent._column.definition.format_type)
                return value;
            }
        } else { 
            columnFormatter = function (value, data, type, params, colComponent, rowComponent) {
              let cellValue = value;  
              let rowData = data;
              let isNullified = rowData[PS_MAPPING.FIELDS.COSTTYPE] === costtype.calculated && rowData.format === FormatTypes.PERCENTAGE;
              let filteredCheckedEntities = _this.props.checkedEntities?.find(e=>e.label === groupCol.title) || _this.props.checkedEntities?.find(e=>e.value === groupCol.field);
              let uniqueKeyFomEntities = filteredCheckedEntities?.uniqueKey;
              let amountStr = groupCol && groupCol.field === SEGMENTS_DELTA?groupCol.field : groupCol && groupCol.field !== _groupedEntities && _this.props.isRange? groupCol.field?"amount_ent_"+uniqueKeyFomEntities: "amount_ent_"+groupCol.title.split("(")[1].replaceAll(/\)/g, ""): "amount";
                let amountPrefix = "";
                if(_this.props.checkedEntities?.[0]?.vectorMachineName1 && filteredCheckedEntities){
                    amountPrefix = "_"+Object.keys(filteredCheckedEntities)
                    .filter(key => key.startsWith("vectorValue"))
                    .map(key => filteredCheckedEntities[key])
                    .filter(value => value)
                    .join("_");
                }

              let checkedEntities = groupCol && groupCol.field === SEGMENTS_DELTA?_this.props.checkedEntities?.map(e=>e.uniqueKey || e.value):"";
              let format = rowComponent._row.data[FORMAT] ? rowComponent._row.data[FORMAT] : colComponent._column.definition.format_type ? colComponent._column.definition.format_type : FormatTypes.AMOUNT;
              
              let dataFormat  = _this.props.stackConfigObj && _this.props.stackConfigObj.amountType !== STACKS_RANGE.FIELDS.AMOUNT && rowData.costtype === costtype.attribute ? FormatTypes.PERCENTAGE : _this.props.stackConfigObj?(_this.props.stackConfigObj.amountType === STACKS_RANGE.FIELDS.AMOUNT?format:FormatTypes.PERCENTAGE):format;
              data[colField+"_format"] = dataFormat;
          
              value = _this.props.stackConfigObj?(_this.props.stackConfigObj.amountType === STACKS_RANGE.FIELDS.AMOUNT ?rowData[amountStr]: groupCol?.field === SEGMENTS_DELTA? rowData.per_selection_segments_delta :rowData[PER_SELECTIOn+(groupCol?"_"+(uniqueKeyFomEntities ||( groupCol?.field+amountPrefix)):"")]): cellValue;
            
              if(_this.props.isMoM) {
                // if psl is calculated, we set its value to 0 if in configure we select per attribute or per vector
                let columnPeriod = colTitle.trim();

                if(!!_this.props.stackConfigObj.psl){
                    data[colField+"_format"] = FormatTypes.PERCENTAGE;
                    value = rowData.costtype === costtype.attribute  || (rowData[PS_MAPPING.FIELDS.COSTTYPE] === costtype.calculated && rowData.format === FormatTypes.PERCENTAGE) ? 0 
                    : (cellValue/_this.props.linearizedData.find(e=>e[PS_MAPPING.FIELDS.RETURN_NAME.toLowerCase()] === _this.props.stackConfigObj[SELECTED_CONF_OPTIONS.PER_PSL])[colField])*100;
                } else if(!!_this.props.stackConfigObj?.firstAttribute) {
                  let attribute = _this.props.stackConfigObj?.firstAttribute.value;
                  if(_this.props.stackConfigObj.isFirstMedian) {
                    if(columnPeriod === "Total") {
                      let mediansData = _this.props.mediansData;
                      value = mediansData[attribute + "_A"] ? mediansData[attribute + "_A"][rowData[PSS.RETURN_NAME]] : 0;
                    } else {
                      let mediansDataByPeriod = _this.props.mediansDataByPeriod;
                      value = mediansDataByPeriod[attribute + "_A_" + columnPeriod] ? mediansDataByPeriod[attribute + "_A_" + columnPeriod][rowData[PSS.RETURN_NAME]] : 0;
                    }
                    value = isNullified ? 0 : value;
                  } else {
                    let selectedAttrVal = _this.props.linearizedData.find(e=>e[PS_MAPPING.FIELDS.RETURN_NAME.toLowerCase()] === attribute)[colField]
                    value = isNullified ? 0 : cellValue/selectedAttrVal;
                  }
                  data[colField+"_format"] = isNullified ? FormatTypes.NUMERIC : FormatTypes.AMOUNT;
                } else if(!!_this.props.stackConfigObj.secondAttribute) {
                    let attribute = _this.props.stackConfigObj?.secondAttribute.value;
                    if(_this.props.stackConfigObj.isSecondMedian) {
                        if(columnPeriod === "Total") {
                            let mediansData = _this.props.mediansData;
                            value = mediansData[attribute + "_A"] ? mediansData[attribute + "_A"][rowData[PSS.RETURN_NAME]] : 0;
                        } else {
                            let mediansDataByPeriod = _this.props.mediansDataByPeriod;
                            value = mediansDataByPeriod[attribute + "_A_" + columnPeriod] ? mediansDataByPeriod[attribute + "_A_" + columnPeriod][rowData[PSS.RETURN_NAME]] : 0;
                        }
                        value = isNullified ? 0 : value;
                    }else{
                        let vectorCount = columnPeriod === "Total" ? _this.props.vectorCountData[0].totalCount : _this.props.vectorCountData?.find(f => f.period === columnPeriod)?.count
                        value = isNullified ? 0 : cellValue/vectorCount;
                    }
                    data[colField+"_format"] = isNullified ? FormatTypes.NUMERIC : FormatTypes.AMOUNT;
                } else{
                    value = cellValue;
                }
              }

              let linearizedData=[];
              let deltaVal = groupCol && groupCol.field === SEGMENTS_DELTA? rowData[colField+"_ent_"+checkedEntities[0]] - rowData[colField+"_ent_"+checkedEntities[1]]:"";
              if(colComponent._column.definition.title.startsWith(handleCurrency("Med-1- $")) && _this.props.mediansData) {
                if (isNullified) {
                  value = 0;
                  data[colField+"_format"]  = FormatTypes.NUMERIC; 
                } else {
                  data[colField+"_format"] = FormatTypes.AMOUNT;
                  let firstAttr =_this.props.stackConfigObj.firstAttribute.value;
                  let deltaMed = "";
                  
                  if (groupCol?.field === SEGMENTS_DELTA) {
                    let hasFirstColMed = !!_this.props.mediansData[firstAttr + "_" + checkedEntities[0]];
                    let hasSecondColMed = !!_this.props.mediansData[firstAttr + "_" + checkedEntities[1]];
  
                    let firstColValue = hasFirstColMed ? Number(_this.props.mediansData[firstAttr + "_" + checkedEntities[0]][rowData.returnname]) : 0;
                    let secondColValue = hasSecondColMed ? Number(_this.props.mediansData[firstAttr + "_" + checkedEntities[1]][rowData.returnname]) : 0;
                    deltaMed = firstColValue - secondColValue;
                  }
  
                  let firstAttrSuffix = groupCol ? "_" + groupCol.field.toUpperCase() + amountPrefix : "_A";
                  if (deltaMed && deltaMed !== "") {
                    value = deltaMed;
                  } else if (_this.props.mediansData[firstAttr + firstAttrSuffix]) {
                    value = _this.props.mediansData[firstAttr + firstAttrSuffix][rowData[PSS.RETURN_NAME]];
                  } else {
                    value = 0;
                  }
                }
              } else if(colComponent._column.definition.title.startsWith(handleCurrency("Med-2- $")) && _this.props.mediansData){
                if (isNullified) {
                  value = 0;
                  data[colField+"_format"]  = FormatTypes.NUMERIC; 
                } else {
                  let secondAttr = _this.props.stackConfigObj.secondAttribute.value;
                  let deltaMed = "";
                    data[colField+"_format"] = FormatTypes.AMOUNT;
                    if (groupCol?.field === SEGMENTS_DELTA) {
                    let hasFirstColMed = !!_this.props.mediansData[secondAttr + "_" + checkedEntities[0]];
                    let hasSecondColMed = !!_this.props.mediansData[secondAttr + "_" + checkedEntities[1]];

                    let firstColValue = hasFirstColMed ? Number(_this.props.mediansData[secondAttr + "_" + checkedEntities[0]][rowData.returnname]) : 0;
                    let secondColValue = hasSecondColMed ? Number(_this.props.mediansData[secondAttr + "_" + checkedEntities[1]][rowData.returnname]) : 0;
                    deltaMed = firstColValue - secondColValue;
                  }

                  let secondAttrSuffix = groupCol ? "_" + groupCol.field.toUpperCase() + amountPrefix : "_A";
                  if (deltaMed && deltaMed !== "") {
                    value = deltaMed;
                  } else if (_this.props.mediansData[secondAttr + secondAttrSuffix]) {
                    value = _this.props.mediansData[secondAttr + secondAttrSuffix][rowData[PSS.RETURN_NAME]];
                  } else {
                    value = 0;
                  }
                }    
              } else if(_this.props.stackConfigObj && colField.split(ENT)[0] === _this.props.stackConfigObj.psl) {
                  linearizedData = _this.props.linearizedData;
                  let filterAmount = linearizedData.filter(e => e[PS_MAPPING.FIELDS.RETURN_NAME.toLowerCase()]===data[PS_MAPPING.FIELDS.RETURN_NAME.toLowerCase()]).length > 0 ?
                  linearizedData.filter(e => e[PS_MAPPING.FIELDS.RETURN_NAME.toLowerCase()]===data[PS_MAPPING.FIELDS.RETURN_NAME.toLowerCase()])[0][amountStr] : 0;
                  format = isNullified ? FormatTypes.NUMERIC : FormatTypes.PERCENTAGE;
                  value = isNullified ?  0 : deltaVal!==""?deltaVal: ( filterAmount / (linearizedData.filter(e => e[PS_MAPPING.FIELDS.RETURN_NAME.toLowerCase()] === _this.props.firstParentCH[PS_MAPPING.FIELDS.RETURN_NAME])[0][amountStr]))* 100;
                  data[colField+"_format"]  = format;
              } else if (_this.props.firstAttributeCH && colTitle.includes("-1-") && _this.props.stackConfigObj.firstAttribute && colField.split(ENT)[0] === _this.props.stackConfigObj.firstAttribute.value) {
                if (isNullified) {
                  value = 0;
                  data[colField+"_format"]  = FormatTypes.NUMERIC; 
                } else {
                  linearizedData = _this.props.linearizedData;
                  let filterAmount = linearizedData.filter(e => e[PS_MAPPING.FIELDS.RETURN_NAME.toLowerCase()]===data[PS_MAPPING.FIELDS.RETURN_NAME.toLowerCase()]).length > 0 ?
                      linearizedData.filter(e => e[PS_MAPPING.FIELDS.RETURN_NAME.toLowerCase()]===data[PS_MAPPING.FIELDS.RETURN_NAME.toLowerCase()])[0][amountStr] : 0;
                  value = deltaVal!==""?deltaVal:(filterAmount / linearizedData.filter(e=>e[PS_MAPPING.FIELDS.RETURN_NAME.toLowerCase()] === _this.props.stackConfigObj.firstAttribute.value)[0][amountStr]);
                  data[colField+"_format"] = FormatTypes.AMOUNT;
                }  
              } else if (_this.props.secondAttributeCH && colTitle.includes("-2-") && _this.props.stackConfigObj.secondAttribute && colField.split(ENT)[0] === _this.props.stackConfigObj.secondAttribute.value) {
                if (isNullified) {
                  value = 0;
                  data[colField+"_format"]  = FormatTypes.NUMERIC; 
                } else {
                  let isEntityStacks = !!groupCol;
                  let vectorCount = isEntityStacks ? _this.props.vectorCountData[(uniqueKeyFomEntities) || (groupCol.field + amountPrefix)] : _this.props.vectorCountData;

                  value = deltaVal !== "" ? deltaVal: rowComponent._row.data[amountStr] / vectorCount;
                  data[colField+"_format"] = FormatTypes.AMOUNT;
                }
              }
                
              return formatValString(value, data[colField+"_format"]) ===  "-" ? 0 : value;
            }
        }
        return columnFormatter;
    }


    /**
     * fix the title of columns when the loader is shown (show the new title)
     * @param {*} cell 
     * @param {*} param 
     * @returns 
     */
    addSuffixForTitle = (cell, param) =>{
        let div = document.createElement("div");
        let p = document.createElement("p");
        div.classList.add("display_table", "uk-text-overflow-ellipsis");
        switch(param.funcName) {
            case PARENT: { // title for the selected psl
                p.innerHTML = "%/"+param.title;
                p.title = p.innerHTML
                break;
            }
            case ATTRIBUTES: {// title for first and second attributes (avg and med)
                p.innerHTML = (param.isMed ? "Med ": "Avg ")+ handleCurrency("$/")+Inflector.singularize(param.title.replace("Med-1- ","").replace("Med-2- ","").replace("Avg-1- ","").replace("Avg-2- ","").replace(handleCurrency("$/"),""));
                p.title = p.innerHTML;
                break;
            }
            case AMOUNTS:{ // title for the amount column
                p.innerHTML = param.title + (param.amountType === CONFIGURE_TOGGLE_TABS[1].value? column_suffixes_values.per_selection.label : column_suffixes_values.per_total.label);
                p.title = p.innerHTML
                break;
            }
            case TITLES:{
                p.innerHTML = param.title.replace("-1-","").replace("-2-","");
                p.title = p.innerHTML
                break;
            }
        }
        div.appendChild(p);
        return div;
    }

    removeToggledOffCols = (columns,stackConf, fromCallBack) =>{
        let _this = this;
        const insert = (arr, index, newItem) => [
            // part of the array before the specified index
            ...arr.slice(0, index),
            // inserted item
            newItem,
            // part of the array after the specified index
            ...arr.slice(index)
        ]
        columns.forEach(col=>{// if we have multiple entities
            if(col.columns){
                if(!stackConf.psl){// remove psl if it's turned off
                    col.columns = col.columns.filter(e=> !e.title.startsWith("%") && e.field.split(ENT)[0] !== _this.props.firstParentCH[STACKS_RANGE.FIELDS.RETURN_NAME]);
                }else if(!col.columns.find(e=>e.title.startsWith("%/")) && stackConf.psl) {
                    col.columns = insert(col.columns,1 + (stackConf.amountType === "amount") ? 1 : 2,{title:"%/" + (_this.props.pslOptions.find(e=>e.value === stackConf.psl)?_this.props.pslOptions.find(e=>e.value === stackConf.psl).label:""), field:stackConf.psl, minWidth:130, format_type:FormatTypes.PERCENTAGE})
                }
                let isFirstParentOn = col.columns.find(e=>e.title.startsWith("%/"))?1:0;
                if(stackConf.amountType === STACKS_RANGE.FIELDS.AMOUNT){
                    col.columns = col.columns.filter(e=>e.field.split(ENT)[0] !== PER_SELECTIOn);
                }
                let areBothAmountsSelected = columns.find(e=>e.field === PER_SELECTIOn)?2:1;
                if(!stackConf.firstAttribute){// remove firstAttribute if it's turned off
                    if(fromCallBack){
                        col.columns = col.columns.filter(e=>e.field.split(ENT)[0].replace(MED,"") !== _this.props.firstAttributeCH[STACKS_RANGE.FIELDS.RETURN_NAME] && !e.title.includes("-1-"));
                    }else{
                        col.columns = col.columns.filter(e=>!(e.field.split(ENT)[0].replace(MED,"") === _this.props.firstAttributeCH[STACKS_RANGE.FIELDS.RETURN_NAME] && e.title.includes("-1-")));
                    }
                }else if(stackConf.firstAttribute && (!col.columns.find(e=>e.title.includes("-1-")))){// add first attribute if it wasnt toggled on in the first go
                    col.columns = insert(col.columns,1+isFirstParentOn + areBothAmountsSelected,{title: (handleCurrency(!stackConf.isFirstMedian?"Avg-1- $/" :"Med-1- $/")) +_this.props.stackConfigObj.firstAttribute.label, field:stackConf.firstAttribute, minWidth:130, format_type:FormatTypes.AMOUNT})
                }
                let isFirstAttributeOn = col.columns.find(e=>e.title.startsWith("-1-"))?1:0;
                if(!stackConf.secondAttribute){// remove secondAttribute if it's turned off
                    if(fromCallBack){
                        col.columns = col.columns.filter(e=>e.field.split(ENT)[0].replace(MED,"") !== _this.props.secondAttributeCH[STACKS_RANGE.FIELDS.RETURN_NAME] && !e.title.includes("-2-"));
                    }else{
                        col.columns = col.columns.filter(e=>!(e.field.split(ENT)[0].replace(MED,"") === _this.props.secondAttributeCH[STACKS_RANGE.FIELDS.RETURN_NAME] && e.title.includes("-2-")));
                    }
                }else if(stackConf.secondAttribute && (!col.columns.find(e=>e.title.includes("-2-")))){// add second attribute if it wasnt toggled on in the first go
                    col.columns = insert(col.columns,1+isFirstParentOn + isFirstAttributeOn + areBothAmountsSelected,{title: (handleCurrency(!stackConf.isSecondMedian?"Avg-2- $/" :"Med-2- $/")) +_this.props.stackConfigObj.secondAttribute.label, field:stackConf.secondAttribute, minWidth:130, format_type:FormatTypes.AMOUNT})
                }
            }
        })
        // ------- for single entity -------
        if(this.props.isTotal){
            if(!stackConf.psl && _this.props.firstParentCH ){// remove psl if it's turned off
                columns = columns.filter(e=> !e.title.startsWith("%") && e.field !== _this.props.firstParentCH[STACKS_RANGE.FIELDS.RETURN_NAME]);
            }else if(!columns.find(e=>e.title.startsWith("%/")) && stackConf.psl) {
                columns = insert(columns,1 + (stackConf.amountType === "amount") ? 1 : 2,{title:"%/" + (_this.props.pslOptions.find(e=>e.value === stackConf.psl)?_this.props.pslOptions.find(e=>e.value === stackConf.psl).label:""), field:stackConf.psl, minWidth:130, format_type:FormatTypes.PERCENTAGE})
            }
            let isFirstParentOn = columns.find(e=>e.title.startsWith("%/"))?1:0;
            if(stackConf.amountType === STACKS_RANGE.FIELDS.AMOUNT){
                columns = columns.filter(e=>e.field !== PER_SELECTIOn);
            }
            let areBothAmountsSelected = columns.find(e=>e.field === PER_SELECTIOn)?2:1;
            if(!stackConf.firstAttribute && _this.props.firstAttributeCH){// remove firstAttribute if it's turned off
                if(fromCallBack){
                    columns = columns.filter(e=>e.field.replace(MED,"") !== _this.props.firstAttributeCH[STACKS_RANGE.FIELDS.RETURN_NAME] && (!e.title.includes("-1-")));
                }else{
                    columns = columns.filter(e=>!(e.field.replace(MED,"") === _this.props.firstAttributeCH[STACKS_RANGE.FIELDS.RETURN_NAME] && (e.title.includes("-1-"))));
                }
            }else if(stackConf.firstAttribute && (!columns.find(e=>e.title.includes("-1-")))){// add first attribute if it wasnt toggled on in the first go
                columns = insert(columns,1+isFirstParentOn + areBothAmountsSelected,{title: (handleCurrency(!stackConf.isFirstMedian?"Avg-1- $/" :"Med-1- $/")) +_this.props.stackConfigObj.firstAttribute.label, field:stackConf.firstAttribute, minWidth:130, format_type:FormatTypes.AMOUNT})
            }
            let isFirstAttributeOn = columns.find(e=>e.title.startsWith("-1-"))?1:0;
            if(!stackConf.secondAttribute && _this.props.secondAttributeCH){// remove secondAttribute if it's turned off
                if(fromCallBack){
                    columns = columns.filter(e=>e.field.replace(MED,"") !== _this.props.secondAttributeCH[STACKS_RANGE.FIELDS.RETURN_NAME] && (!e.title.includes("-2-")));
                }else{
                    columns = columns.filter(e=>!(e.field.replace(MED,"") === _this.props.secondAttributeCH[STACKS_RANGE.FIELDS.RETURN_NAME] && (e.title.includes("-2-"))));
                } 
            }else if(stackConf.secondAttribute && (!columns.find(e=>e.title.includes("-2-")))){// add second attribute if it wasnt toggled on in the first go
                columns = insert(columns,1+isFirstParentOn + isFirstAttributeOn + areBothAmountsSelected,{title: (handleCurrency(!stackConf.isSecondMedian?"Avg-2- $/" :"Med-2- $/")) +_this.props.stackConfigObj.secondAttribute.label, field:stackConf.secondAttribute, minWidth:130, format_type:FormatTypes.AMOUNT})
            }
        }
        return columns;
    }

    callSearch = (obj, headerValue) => {
        let _this = this;
        let rows = obj.tabulator.getRows();
        _this.setState({
            fromExpandCollapseButton: false,
        }, () => {
            obj.searchInNestedRows(rows, headerValue);
        })
    }
    /**
     * call setColumns with the columns that should load
     * @param {*} shouldLoadColumns 
     * @param {*} stackConf 
     */
    showLoaderOnColumn = (shouldLoadColumns, stackConf) =>{
        let _this = this;
        let columns = copyObjectValues(this.props.columns);
        columns = _this.removeToggledOffCols(columns,stackConf);
        _this.setColumns(_this.props.data, columns, false, shouldLoadColumns);
    }

    setColumns(data, columns, isResize, shouldLoadColumns, id) {
        var obj = this;
        var tabulator = id ? obj.tabulator2 : obj.tabulator;
        let appendExportUnderScore=(name, level) =>{
            var underscores= "";
            for (var i=0; i<level; i++){
                underscores += "___";
            }
            return underscores+name;
        }
        let  nameAccessor = function(value, data, type, params, column){
            return appendExportUnderScore(value, params.obj.props.viewId !== 0 ? data.level-1 : data.level);
        }
        if(obj.props.isRange){
            if (columns && columns.length > 0) {
                columns.forEach(col => {
                    col.dontFilter = true;
                    col.headerSort = false;
                    if(obj.props.firstAttributeCH && col.field && col.field.split(ENT)[0].replace(MED,"") === obj.props.firstAttributeCH[STACKS_RANGE.FIELDS.RETURN_NAME]){
                        col.title = Inflector.singularize(col.title);
                        col.titleDownload = Inflector.singularize(col.title.replace("-1-","").replace("-2-",""));
                    }
                    if(obj.props.secondAttributeCH && col.field && col.field.split(ENT)[0].replace(MED,"") === obj.props.secondAttributeCH[STACKS_RANGE.FIELDS.RETURN_NAME]){
                        col.title = Inflector.singularize(col.title);
                        col.titleDownload = Inflector.singularize(col.title.replace("-1-","").replace("-2-",""));
                    }
                    if(col.columns){
                        col.columns.forEach(cl => {
                            cl.dontFilter = true;
                            cl.headerSort = false;
                            if(obj.props.firstAttributeCH && cl.field && cl.field.split(ENT)[0].replace(MED,"") === obj.props.firstAttributeCH[STACKS_RANGE.FIELDS.RETURN_NAME]){
                                cl.title = Inflector.singularize(cl.title);
                                cl.titleDownload = Inflector.singularize(cl.title.replace("-1-","").replace("-2-",""));
                            }
                            if(obj.props.secondAttributeCH && cl.field && cl.field.split(ENT)[0].replace(MED,"") === obj.props.secondAttributeCH[STACKS_RANGE.FIELDS.RETURN_NAME]){
                                cl.title = Inflector.singularize(cl.title);
                                cl.titleDownload = Inflector.singularize(cl.title.replace("-1-","").replace("-2-",""));
                            }
                        });
                    }
                });
            }
        }
        columns = cleanUpTabulatorColumns(columns, null ,null , id ? this.getTabulator2 : this.getTabulator, {id: id || this.props.tableId, is_default_collapsed: true});
        
        if (columns && columns.length > 0) {
            let j =0;
            columns.forEach(col => {
                if(obj.props.isRange){
                    col.cssClass = "wrap-col";
                    col.tooltip = true;
                    col.headerTooltip = true
                }
                if(obj.props.isRange && obj.props.isExtended && columns.length > 2) { // add colored circle next to entites if we have more than one
                    col.titleFormatter = obj.addRangeCustomTitleFormatter;
                    col.titleFormatterParams = {comp:obj, field: col.field, title: col.title, color: col.color};
                }
                if(col.columns) {
                    let i = col.columns.length;
                    let firstColumnHidden = false;
                    col.columns.forEach(c => {
                        if(obj.props.component === PROFIT_STACK && obj.props.showChartColors && c.field !== VECTOR_ANALYSIS.FIELDS.NAME) {
                            c.titleFormatter = obj.addYOYCustomTitleFormatter;
                            c.titleFormatterParams = {comp:obj,field: c.field, title: c.title};
                        }
                        if(obj.props.isRange && c.field && c.field.split(ENT)[0] === STACKS_RANGE.FIELDS.AMOUNT){
                            c.visible = this.props.stackConfigObj && this.props.stackConfigObj.amountType && this.props.stackConfigObj.amountType !== STACKS_RANGE.FIELDS.AMOUNT? false: true;
                            if(shouldLoadColumns && shouldLoadColumns.includes(STACK_CONF.AMOUNT_TYPE) && obj.props.stackConfigObj.amountType !== STACKS_RANGE.FIELDS.AMOUNT){
                                c.titleFormatter = obj.addSuffixForTitle;// add title formatters of new columns
                                c.titleFormatterParams = {comp:obj, funcName: AMOUNTS, amountType: obj.props.stackConfigObj.amountType, title: c.title};
                                c.visible = true;
                            }
                        }
                        if(obj.props.isRange && c.field && c.field.split(ENT)[0] === PER_SELECTIOn){
                            if(shouldLoadColumns && shouldLoadColumns.includes(STACK_CONF.AMOUNT_TYPE) /*&& obj.props.stackConfigObj.amountType === STACKS_RANGE.FIELDS.AMOUNT*/){
                                c.visible = false;
                            }
                        }
                        if(obj.props.firstParentCH && obj.props.isRange && c.field && c.field.split(ENT)[0] === obj.props.firstParentCH[STACKS_RANGE.FIELDS.RETURN_NAME]){
                            if(shouldLoadColumns && shouldLoadColumns.includes(PSL.value)){
                                c.titleFormatter = obj.addSuffixForTitle;
                                c.titleFormatterParams = {comp:obj, funcName: PARENT, title: obj.props.lenearizedPslOptions.find(e=>e.value === obj.props.stackConfigObj.psl).label};
                                c.field = obj.props.lenearizedPslOptions.find(e=>e.value === obj.props.stackConfigObj.psl).value
                            }
                            c.titleDownload = c.title;
                        }   
                        if(obj.props.firstAttributeCH && c.field && c.title && c.title.includes("-1-") && c.field.split(ENT)[0].replace(MED,"") === obj.props.firstAttributeCH[STACKS_RANGE.FIELDS.RETURN_NAME]){
                            c.titleFormatter = obj.addSuffixForTitle;
                            c.titleFormatterParams = {comp:obj, funcName: ATTRIBUTES, isMed: obj.props.stackConfigObj.isFirstMedian, title: obj.props.stackConfigObj.firstAttribute.label};
                            if(shouldLoadColumns && (shouldLoadColumns.includes(STACK_CONF.IS_FIRST_MED) || shouldLoadColumns.includes(FIRST_ATTRIBUTE.value))){
                                c.field = obj.props.stackConfigObj.firstAttribute.value
                            }
                        }
                        if(obj.props.secondAttributeCH && c.field && c.title && c.title.includes("-2-") && c.field.split(ENT)[0].replace(MED,"") === obj.props.secondAttributeCH[STACKS_RANGE.FIELDS.RETURN_NAME]){
                            c.titleFormatter = obj.addSuffixForTitle;
                            c.titleFormatterParams = {comp:obj, funcName: ATTRIBUTES, isMed: obj.props.stackConfigObj.isSecondMedian, title: obj.props.stackConfigObj.secondAttribute.label};
                            if(shouldLoadColumns && (shouldLoadColumns.includes(STACK_CONF.IS_SECOND_MED) || shouldLoadColumns.includes(SECOND_ATTRIBUTE.value))){
                                c.field = obj.props.stackConfigObj.secondAttribute.value
                            }
                        }
                        if(c.visible !== false){
                            j++;
                        }else{
                            firstColumnHidden = true;
                        }
                        i--;
                        if(c.field !== undefined) {
                            if(c.field.toUpperCase().includes(VECTOR_ANALYSIS.TITLES.COMPARISON.toUpperCase()) ||
                                c.field.toUpperCase().includes(VECTOR_ANALYSIS.TITLES.SELECTION.toUpperCase()) ||
                                c.field.toUpperCase().includes(VECTOR_ANALYSIS.TITLES.DIFFERENCE.toUpperCase())) {
                                    c.titleFormatter = (col) => this.props.getSymbolsTooltipFormatter(col, this.props.getToolTipMessage(c.title, obj.props.comparisonValue, obj))
                            }
                        }

                        c.formatter = obj.getColumnFormatter(c.field, col, i===0 ?"addBorderRightToParent":"", j===1 && i === col.columns.length -(firstColumnHidden?2:1)?"addBorderLeftToParent":"", shouldLoadColumns, c.title); 
                        c.accessor = obj.getColumnAccessor(c.field, col, c.title); 
                        if(c.field === VECTOR_ANALYSIS.FIELDS.NAME) {
                            c.tooltip = col.title === PROFIT_STACK_LINES;   //adding tooltip for Profit Stack Lines column
                            c.accessorDownload = nameAccessor;
                            c.accessorDownloadParams = {obj};
                            c.cssClass = "frozenLeftColumn";
                            c.width = convertViewportToPx(250);
                            c.minWidth = convertViewportToPx(250);
                            c.maxWidth = convertViewportToPx(500);
                            c.resizable = true;
                            if(!obj.props.isBridge) {
                              c.headerFilter = customFilterEditor;
                              c.headerFilterParams = {
                                  func: (headerValue)=>{obj.callSearch(obj, headerValue)},
                                  getResultCount: obj.getSearchResultCount,
                                  collapseOnClearSearch: obj.props.expandCollapseClick
                              }
                              c.headerFilterFunc = () => { return true; };
                            }
                        }else{
                            // if(c.field?.includes(VARIANCE_TD)){
                            //     c.width = convertViewportToPx(170);
                            //     c.minWidth = convertViewportToPx(170);
                            // }
                            c.resizable = obj.props.isBridge;
                        }
                        
                        if(c.field === STACKS_RANGE.FIELDS.AMOUNT){
                            c.cssClass = "left-align";
                        }
                    });
                } else {
                    if(col.field === VECTOR_ANALYSIS.FIELDS.NAME) {
                        col.tooltip = col.title === PROFIT_STACK_LINES;   //adding tooltip for Profit Stack Lines column
                        col.accessorDownload = nameAccessor;
                        col.accessorDownloadParams = {obj}
                        col.cssClass = "frozenLeftColumn";
                        col.width = convertViewportToPx(252);
                        col.minWidth = convertViewportToPx(252);
                        col.maxWidth = convertViewportToPx(500);
                        col.resizable = true;
                        if(!obj.props.isBridge) { 
                          col.headerFilter = customFilterEditor;
                          col.headerFilterParams = {
                              func: (headerValue)=>{obj.callSearch(obj, headerValue)},
                              getResultCount: obj.getSearchResultCount,
                              collapseOnClearSearch: obj.props.expandCollapseClick
                          }
                          col.headerFilterFunc = () => { return true; };
                        }
                    }else{
                        col.resizable = obj.props.isBridge;
                    }
                    if(col.field.startsWith(YTD_TOTAL_COLUMN_SUFFIX)){
                        col.cssClass = "frozenRightColumn";
                        col.width = convertViewportToPx(200);
                        col.minWidth = convertViewportToPx(200);
                        col.maxWidth = convertViewportToPx(200);
                    }
                    if (col.field === VECTOR_ANALYSIS.FIELDS.NAME) {
                        col.tooltip = col.title === PROFIT_STACK_LINES;   //adding tooltip for Profit Stack Lines column
                    }
                }
                if(obj.props.isRange){
                    EXPAND_ELEMENT.cssClass = EXPAND_ELEMENT.cssClass + " frozen-expand-column";
                    if(col.field === STACKS_RANGE.FIELDS.NAME){
                        col.width = convertViewportToPx(252);
                        col.minWidth = convertViewportToPx(252);
                        col.maxWidth = convertViewportToPx(500);
                        col.resizable = true;
                    }else{
                        col.resizable = false
                    }
                    if(col.field === STACKS_RANGE.FIELDS.AMOUNT){
                        // col.width = convertViewportToPx(120);
                        col.visible = this.props.stackConfigObj && this.props.stackConfigObj.amountType && this.props.stackConfigObj.amountType !== STACKS_RANGE.FIELDS.AMOUNT? false: true;
                        if(shouldLoadColumns && shouldLoadColumns.includes(STACK_CONF.AMOUNT_TYPE) && obj.props.stackConfigObj.amountType !== STACKS_RANGE.FIELDS.AMOUNT){
                            col.titleFormatter = obj.addSuffixForTitle;
                            col.titleFormatterParams = {comp:obj, funcName: AMOUNTS, amountType: obj.props.stackConfigObj.amountType, title: col.title};
                            col.visible = true;
                        }
                    }
                    if(col.field === PER_SELECTIOn){
                        // col.width = convertViewportToPx(120);
                        if(shouldLoadColumns && shouldLoadColumns.includes(STACK_CONF.AMOUNT_TYPE) /*&& obj.props.stackConfigObj.amountType === STACKS_RANGE.FIELDS.AMOUNT*/){
                            col.visible = false;
                        }
                    }
                    if(obj.props.firstParentCH && col.field === obj.props.firstParentCH[STACKS_RANGE.FIELDS.RETURN_NAME]){
                        // col.width = convertViewportToPx(140);
                        if(shouldLoadColumns && shouldLoadColumns.includes(PSL.value)){
                            col.titleFormatter = obj.addSuffixForTitle;
                            col.titleFormatterParams = {comp:obj, funcName: PARENT, title: obj.props.lenearizedPslOptions.find(e=>e.value === obj.props.stackConfigObj.psl).label};
                            col.field = obj.props.lenearizedPslOptions.find(e=>e.value === obj.props.stackConfigObj.psl).value;
                        }
                        col.titleDownload = col.title;
                    }
                    if(obj.props.firstAttributeCH && col.field && col.title && col.title.includes("-1-") && 
                            (col.field.split(ENT)[0].replace(MED,"") === obj.props.firstAttributeCH[STACKS_RANGE.FIELDS.RETURN_NAME]
                            || col.field.split(ENT)[0].replace(MED,"") === obj.props.stackConfigObj.firstAttribute.value)){
                        col.title = Inflector.singularize(col.title);
                        col.titleFormatter = obj.addSuffixForTitle;
                        col.titleFormatterParams = {comp:obj, funcName: ATTRIBUTES, isMed: obj.props.stackConfigObj.isFirstMedian, title: obj.props.stackConfigObj.firstAttribute.label};
                        if(shouldLoadColumns && (shouldLoadColumns.includes(STACK_CONF.IS_FIRST_MED) || shouldLoadColumns.includes(FIRST_ATTRIBUTE.value))){
                            col.field = obj.props.stackConfigObj.firstAttribute.value
                        }
                        col.titleDownload = Inflector.singularize(col.title.replace("-1-","").replace("-2-",""));
                        // col.width = convertViewportToPx(120);
                    }
                    if(obj.props.secondAttributeCH && col.field && col.title && col.title.includes("-2-") && 
                            (col.field.split(ENT)[0].replace(MED,"") === obj.props.secondAttributeCH[STACKS_RANGE.FIELDS.RETURN_NAME]
                            || col.field.split(ENT)[0].replace(MED,"") === obj.props.stackConfigObj.secondAttribute.value)){
                        col.title = Inflector.singularize(col.title);
                        col.titleFormatter = obj.addSuffixForTitle;
                        col.titleFormatterParams = {comp:obj, funcName: ATTRIBUTES, isMed: obj.props.stackConfigObj.isSecondMedian, title: obj.props.stackConfigObj.secondAttribute.label};
                        if(shouldLoadColumns && (shouldLoadColumns.includes(STACK_CONF.IS_SECOND_MED) || shouldLoadColumns.includes(SECOND_ATTRIBUTE.value))){
                            col.field = obj.props.stackConfigObj.secondAttribute.value
                        }
                        col.titleDownload = Inflector.singularize(col.title.replace("-1-","").replace("-2-",""));
                        // col.width = convertPxToViewport(120);
                    }
                }
                col.accessor = obj.getColumnAccessor(col.field,undefined, col.title); 
                col.formatter = obj.getColumnFormatter(col.field, undefined, undefined, undefined, shouldLoadColumns, col.title);
            });
        }
        if(columns && this.props.isBridge){
            columns.unshift(EXPAND_ELEMENT);
        }
        var level = [0];
        this.extractLowestLevel(data, level);
        this.setState({
            level : 0,
            lowestLevel: level[0],
            originalData: data
        },function(){
            if (data && !isResize && columns) {
                tabulator.setColumns(columns);
                tabulator.setData(data.filter(e=>!e.hidden));
            }
            if(this.props.isBridge && !this.state.isTableExpanded){
                $("#"+this.props.tableId).hide();
            } else if(this.props.isBridge && this.state.isTableExpanded){
                $("#"+this.props.tableId).show();
            }

            if(this.props.isRange) {
                let div = document.createElement("div");
                div.classList.add("fixed-title-column");
    
                $(".tabulator-col.frozenColumn").append(div);
            }
        })
    }

    addBordersToCellsCode=()=>{
        $('[class^= addBorderRightToParent]').each(function() {
            if(!$(this).parent()[0].classList.contains("border-right-solid")){
                $(this).parent()[0].classList.add("border-right-solid");
            }
        });
        $('[class^= addBorderLeftToParent]').each(function() {
            if(!$(this).parent()[0].classList.contains("border-left-solid")){
                $(this).parent()[0].classList.add("border-left-solid");
            }
        });
    }

    searchInNestedRows = (dataRows, headerValue) => {
        let _this = this;
        _this.setState({
            headerValue: headerValue
        },()=>{
            _this.tabulator.replaceData(_this.state.originalData.filter(e => !e.hidden));
        })
    }
    onTabulatorRenderComplete=()=> {
        this.addBordersToCellsCode()
        // let children = $(".tabulator-headers")[0].children;
        // for(let child in children){
        //     if(children[child].classList.contains("tabulator-col-group")){
        //         children[child].classList.add("border-right-solid");
        //     }
        // }
        if (!this.tabulator) {
            return;
        }
        this.checkIfContainerHasOverflow()
    }

    exportTableToExcel(options, allRows) {
        let sheets = {};
        let tabulator = allRows ? this.tabulator2 : this.tabulator;
        sheets["Description"] = "#excel_details_table"
		    sheets["Data"] = true;
        let data = this.linearize(linearizeHierarchy(this.props.data), tabulator.getData(),[],_children);
        let exportOptions = this.props.exportOpts;
        exportOptions.data = data;
        exportOptions.sheets = sheets;
        exportOptions.isRange = this.props.isRange;
        // when table has a group column/sub-columns, dataStartRow should start at 2
        exportOptions.dataStartRow= this.props.isYOY || this.props.isExtended || this.props.isBridge ? 2 : 1;

        exportToExcel(exportOptions, tabulator);
        this.addBordersToCells();
        
	}

    closeConfigureDropdown = () => {
        let _this = this;
        $(document).on("mouseover click", function (event) {
          if (($("#stack_table_configure_dialog:hover").length === 0) && ($("#stacks-table-configure-btn:hover").length === 0)) {
            if (event.type === "click" && _this.state.showConfigureDialog) {
              _this.setState({
                showConfigureDialog: false
              })
            }
          }
        });
      }

      closeExportDropdown = () => {
        let _this = this;
        $(document).on("mouseover click", function (event) {
          if (($("#stack_table_export_dialog:hover").length === 0) && ($("#stacks-table-export-btn:hover").length === 0)) {
            if (event.type === "click" && _this.state.showExportDialog) {
              _this.setState({
                showExportDialog: false
              })
            }
          }
        });
      }
    
  componentDidMount() {
    const _this = this;
    _this.initializeTabulator();

    if(this.props.isRange && this.props.isExtended) {
        $(".range .tabulator-tableholder").addClass("extended-range");
    } else {
        $(".range .tabulator-tableholder").addClass("menu-range");
    }

    this.closeConfigureDropdown();
    this.closeExportDropdown();
    window.addEventListener("resize", () => {_this.setColumns(_this.props.data, _this.props.columns, true);});

    }

    appendExportUnderScore(name, level) {
        var underscores= "";
        for (var i=0; i<level; i++){
            underscores += "___";
        }
        return underscores+name;
    }

    linearize(arr, tabData, resultArr, childrenField) {
        for(var row in tabData) {
            let level  = arr.filter(e=>e.returnname === tabData[row].returnname)[0].level;
            tabData[row][VECTOR_ANALYSIS.FIELDS.NAME] = this.appendExportUnderScore(tabData[row][VECTOR_ANALYSIS.FIELDS.NAME],this.props.viewId !== 0 ? level-1 :level);
            resultArr.push(tabData[row]);
            if(tabData[row][childrenField] && tabData[row][childrenField].length > 0 && tabData[row].expanded) {
                resultArr = this.linearize(arr,tabData[row][childrenField], resultArr, childrenField);
            }
        }
        return resultArr;
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //    return shallowCompare(this, nextProps, nextState);
    // }

    checkAllTree(isExpand, rows) {
        var obj = this;
        var allRows = rows || this.tabulator.getRows();
        var res = false;
        allRows.forEach(function(row) {
            if (isExpand) {
                if (row.getData().children && row._row.modules.dataTree.open) {
                    res = true;
                }
            } else {
                if (row.getData().children && !row._row.modules.dataTree.open) {
                    res =  true;
                }else{
                    if(row.getData().children){
                        var oldRes = res;
                        res = obj.checkAllTree(isExpand, row.getTreeChildren()) === true ? true : oldRes;
                    } 
                }   
            }  
        });
        return res;
    }
    moreDetailsDialogContent = () => {
        let _this = this;
        let rowData = _this.state.selectedRow;

        if(!rowData) { return; }

        return getCostTooltipData(_this.props.costClassification, 
                _this.props.glCosts, _this.props.client_costcenter, 
                rowData, _this.props.periodsList, 
                _this.props.vectorOptions,
                _this.props.metricFields, 
                _this.props.hiddenVectors
            );
    }
    moreDetailsDialogActions = () => {
        return (
            <Button 
                label={"Close"}
                variant={BUTTON_VARIANT.SECONDARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                onBtnClick={() => this.setMoreDetailsDialogOpen(false, undefined)}
            />
        )
    }
    setMoreDetailsDialogOpen = (isOpen, row) => {
        let _this = this;
        _this.setState({
            openMoreDetailsDialog: isOpen,
            selectedRow: row,
        })
    
    }
    
    collapseAll(rows=this.tabulator.getRows(), mainCall=true){
        const _this = this;
        rows.forEach(function(row) {
            row.treeCollapse();
            row.getData().expanded = false;
            if(row.getData().children){
                _this.collapseAll(row.getTreeChildren())
            }
        });

        if(mainCall) {  //do not set state for every child that is collapsed, only once
            _this.setState({
                level :0
            });
        }
    }

    expandNextLevel(rows=this.tabulator.getRows(), mainCall=true){
        const _this = this;
        rows.forEach(function(row) {
            if(row.getData().level === _this.state.level) {
                row.treeExpand();
                row.getData().expanded = true;
            } else if(row.getData().children) {
                _this.expandNextLevel(row.getTreeChildren(), false);
            }
        });

        if(mainCall) {  //do not set state for every child that is expanded, only once
            this.setState({
                level: ++this.state.level
            });
        }
    }
    extractLowestLevel(data, level){
        for(var e in data){
            if (data[e].level > level[0]) {
                level[0] = data[e].level;
            }
            if (data[e].children && data[e].children.length > 0) {
                this.extractLowestLevel(data[e].children, level)
            }
        }
    }

    expandCollapseAllTabData=(data, isExpanded)=>{
        let _this = this;
        data.forEach((item) => {
            item.expanded = isExpanded;
            if (item.children && item.children.length > 0 && isExpanded) {
              item.children = _this.expandCollapseAllTabData(item.children, isExpanded);
            }
          });
          return data;
    }

    collapseOnClearSearch = () => {
        let _this = this;
        _this.state.originalData = _this.expandCollapseAllTabData(_this.state.originalData, false);
        _this.setState({
            isAllExpanded :false,
            isTableExpanded: false
        },()=>{
            _this.tabulator.replaceData(_this.state.originalData.filter(e => !e.hidden));
            this.expandBtnRef?.current?.setIsDisabled(false);
            this.collapseBtnRef?.current?.setIsDisabled(false);
        });
    }

    expandCollapseAll() {
        let _this = this;
        _this.tabulator.options.dataTreeStartExpanded = !_this.state.isAllExpanded;
        _this.state.originalData = _this.expandCollapseAllTabData(_this.state.originalData, !_this.state.isAllExpanded);
        _this.setState({
            isAllExpanded :!_this.state.isAllExpanded,
            fromExpandCollapseButton : true
        },()=>{
            _this.tabulator.replaceData(_this.state.originalData.filter(e => !e.hidden));
            this.expandBtnRef?.current?.setIsDisabled(false);
            this.collapseBtnRef?.current?.setIsDisabled(false);
            _this.tabulator.redraw();
            // $("#Expand_PS").removeClass("btn-disabled");
        });
    }

    getSearchResultCount = () => {
        let _this = this;
        let data = _this.tabulator.getData();
        let linearizedData = linearizeHierarchy(data, _children);
        let resultFound = linearizedData.filter(f=>f.name.toLowerCase().includes(_this.state.headerValue.toLowerCase().trim()));
        return resultFound.length;

    }
    hasMatchingChild = (row, headerValue, fromChildren) => {
        let _this = this;
        if (row.name.toLowerCase().includes(headerValue.toLowerCase().trim()) && fromChildren) {
            return true;
        }
        let children = row.children;
        if (children) {
            for (var e in children) {
                if (_this.hasMatchingChild(children[e], headerValue, true)) {
                    return true;
                }
            }
        }
    }

    initializeTabulator = () => {
      let _this = this;
      var options = {
        layout: this.props.isRange ? "fitColumns" : "fitColumns",      //fit columns to width of table if it is not range
        responsiveLayout: false,  //hide columns that dont fit on the table
        addRowPos: "top",          //when adding a new row, add it to the top of the table
        history: true,             //allow undo and redo actions on the table
        pagination: false, //"local",       //paginate the data
        movableColumns: false,     //allow column order to be changed
        autoResize: false,
        // resizableColumns: false,
        resizableRows: false,       //allow row order to be changed
        selectable: false,
        dataTree: true,
        dataTreeChildField: _children,
        dataTreeStartExpanded: function (row){
            if (_this.state.fromExpandCollapseButton) {
                return _this.state.isAllExpanded;
            } else {
                if (_this.state.headerValue && (_this.hasMatchingChild(row.getData(), _this.state.headerValue))) {
                    return true;
                } else {
                    return false;
                }
            }
        },
        dataTreeElementColumn: _this.props.isBridge ?  PS_MAPPING.FIELDS.EXPAND : VECTOR_ANALYSIS.FIELDS.NAME,
        dataTreeCollapseElement: _this.props.isBridge ? getExpandCollapseButtons(false, ["uk-button-icon","tabulator_expand_icon"],["tabulator_expand_icon"]) : "<button id='expanded_tabulator_arrow'></button>",
        dataTreeExpandElement: _this.props.isBridge ? getExpandCollapseButtons(true, ["uk-button-icon","tabulator_expand_icon"],["tabulator_expand_icon"]) : "<button id='collapsed_tabulator_arrow'></button>",
        dataTreeChildIndent: 15,
        dataTreeBranchElement: false, //hide branch element
        invalidOptionWarnings: false,
        reactiveData: true,      //tabulator listens to any change in the data array and updates the table
        virtualDomBuffer: 450,
        columnHeaderVertAlign: "bottom",
        placeholder: "No data available",
        height: "100%",
        width: "100%",
        renderComplete: _this.onTabulatorRenderComplete,
        rowFormatter: function (row) {
          const rowData = row.getData();
          const uniqueId = rowData.returnname;
          // Update the button element with the same id attribute
          const expandCollapseCell = row.getElement().querySelector(".tabulator-cell.expand-collapse-cell.frozen-expand-column");
          if (expandCollapseCell) {
            const buttonElement = expandCollapseCell.querySelector("button");
            if (buttonElement) {
              buttonElement.setAttribute("id", `expand_collapse_${uniqueId}`);
            }
          }
          return row;
        },
        dataTreeRowExpanded: function (row, level) {
          row.getData().expanded = true;
          _this.addBordersToCellsCode();
          _this.checkIfContainerHasOverflow()
        },
        dataTreeRowCollapsed: function (row, level) {
          row.getData().expanded = false;
          _this.addBordersToCellsCode();
          _this.checkIfContainerHasOverflow()
        },
        downloadReady: function (fileContents, blob) {
          toggleLoader(false, "tablesToExcel");
          return blob; //must return a blob to proceed with the download, return false to abort download
        }
      }
      var options2 = copyObjectValues(options);
      _this.tabulator = new TabulatorFull("#"+this.props.tableId, options);
      options2.dataTreeStartExpanded = true;
      _this.tabulator2 = new TabulatorFull("#expanded_1", options2);
    }
    componentDidUpdate(prevProps) {
        if ((this.props.data && this.props.columns && this.props.dataId && prevProps.dataId !== this.props.dataId)) {
            this.setState({
                headerValue:""
            })
            this.setColumns(this.props.data, this.props.columns);
            this.setColumns(this.props.data, this.props.columns, undefined, undefined, "expanded_1");
            if (this.props.data && this.props.data.length > 0 &&  this.props.changedConfiguration && 
                this.props.changedConfiguration.length === 0) {
                // $("#Export_Bridge_Table").removeClass("btn-disabled");
                this.exportBtnRef?.current?.setIsDisabled(false);
                this.expandBtnRef?.current?.setIsDisabled(false);
                this.bridgeExpandBtnRef?.current?.setIsDisabled(false);
                this.collapseBtnRef?.current?.setIsDisabled(false);
                // $("#Expand_PS").removeClass("disabled");
            }
            
        }

        // adding a wait cursor if cost classificaton are still being fetched
        if(this.props?.costClassification?.length !== prevProps.costClassification?.length && this.props?.costClassification?.length > 0) {
            $(".loading-cost-classification").removeClass("wait-cursor");
        }

        if (this.props.isRange !== prevProps.isRange || this.props.isFromBubble !== prevProps.isFromBubble || this.props.isTotal !== prevProps.isTotal) { // delete the instance of tabulator when changing from total stacks to yoy/ytd or from total stacks to extended
          // Destroy existing instance
          this.tabulator.destroy();
          this.tabulator2.destroy();
          this.initializeTabulator();
        }
    }

    /**
     * this function in called on expanding or collapsing the drilled table table
     */
    expandTable=()=>{
        this.setState({
            isTableExpanded: !this.state.isTableExpanded
        },function(){
            if(!this.state.isTableExpanded){
                $("#"+this.props.tableId).hide();
                $("#Export_Bridge_Table").hide();
                $(".buttons").hide();
               
                $("#bridge_report_tables").removeClass("half-screen-tables")
                $("#bridge_report_tables").removeClass("tables-full-screen")
                $("#bridge_report_tables").addClass("height-unset")
                $(".vector-analysis-bridge-report").removeClass("half-chart")
            } else {
                $("#bridge-chart-div").addClass("chart-dimensions-when-drill-is-active");
                $("#"+this.props.tableId).show();
                $("#Export_Bridge_Table").show();
                $(".buttons").show();

                $("#bridge_report_tables").addClass("half-screen-tables");
                $("#bridge_report_tables").removeClass("tables-full-screen");
                $("#bridge_report_tables").removeClass("height-unset");
                $(".vector-analysis-bridge-report").addClass("half-chart")
                this.setColumns(this.props.data, this.props.columns);
            }
        })
    }

    refreshData=()=>{
        let _this = this;
        if(_this.props.data !== undefined && _this.props.columns !== undefined){
            _this.setColumns(_this.props.data, _this.props.columns);
        }
    }

    /**
     * function toggles table based on its expanded state
     */
    toggleTable(){
        if(this.state.isTableExpanded){ // we used jquery to show and hide table because the table needs to be rendered so it doesnt crash
            $("."+this.props.tableId).show();
        }else{
            $("."+this.props.tableId).hide();
        }
    }

    showConfigureDialog =()=>{
        let _this = this;
        if (!_this) {
            $("#stack_table_configure_dialog").show();
        }
        _this.setState({
            showConfigureDialog: !_this.state.showConfigureDialog
        })
    }

    showExportDialog =()=>{
        let _this = this;
        if (!_this) {
            $("#stack_table_export_dialog").show();
        }
        _this.setState({
            showExportDialog: !_this.state.showExportDialog
        })
    }

    initConfigureDropdownList = () => {
        let obj = this.props.currStackConfigObj;
        let pslOptions = copyObjectValues(this.props.pslOptions);
        if (obj) {
            pslOptions.map(m => m.checked = false); // remove all checked psl options
            pslOptions = updateChildAttrByKey(pslOptions, obj.psl, ["checked"], true, "value");
        } else {
            pslOptions.map(m => m.checked = false);
            pslOptions[0].checked = true;
        }
        
        let titlePrefix = "[average]";
        let attributeTitle = titlePrefix + FIRST_ATTRIBUTE.label;        
        let vectorDropdownTitle = titlePrefix + VECTOR_ATTRIBUTE.label;
        attributeTitle = handleCurrency(attributeTitle);
        vectorDropdownTitle = handleCurrency(vectorDropdownTitle);

        const optionLabel = ({label, isGroupTitle, tooltipText}) => (
          <div className={(isGroupTitle ? "option-group-header-label" : "") +" option-padding"} uk-tooltip={!!tooltipText ? tooltipText : ""}>
              {label}
          </div>
        );

        return [
            {
                type: PSL.value,
                toggleDefaultValue: obj.hidePsl ? FY_VALUES.OFF : FY_VALUES.ON,
                toggleTitle: AMOUNT_OF + ( pslOptions?.length > 0 ? pslOptions[0]?.label : ""),
                isTreeDropdown: true,
                dropdownData: pslOptions,
                dropdownPlaceholder: lang.heatmap_configure.select_ps_line,
                dropdownMode: "radioSelect",
            },
            {
                type: FIRST_ATTRIBUTE.value,
                toggleDefaultValue: obj.hideFirstAttribute ? FY_VALUES.OFF : FY_VALUES.ON,
                toggleTitle: attributeTitle,
                dropdownData: this.props.attributes,
                dropdownDefaultValue: obj.firstAttribute,
                dropdownPlaceholder: lang.configure_attribute,
                toggleTabOptions: AVG_MED_TOGGLE_TABS,
                selectedTab: obj.isFirstMedian ? AVG_MED_TOGGLE_TABS[1].value : AVG_MED_TOGGLE_TABS[0].value
            },
            {
              type: VECTOR_ATTRIBUTE.value,
              toggleDefaultValue: obj.hideSecondAttribute ? FY_VALUES.OFF : FY_VALUES.ON,
              toggleTitle: vectorDropdownTitle,
              dropdownData: this.props.vectorOptions,
              dropdownDefaultValue: obj.secondAttribute,
              dropdownPlaceholder: lang.manage_columns.select_vector,
              toggleTabOptions: AVG_MED_TOGGLE_TABS,
              selectedTab: obj.isSecondMedian ? AVG_MED_TOGGLE_TABS[1].value : AVG_MED_TOGGLE_TABS[0].value,
              optionLabel: optionLabel,
              showSelectedTooltip: true
            },
        ];
    }

    initConfigureDropdownListMoM = () => {
        let obj = this.props.currStackConfigObj;
        let pslOptions = copyObjectValues(this.props.pslOptions);
        if (obj) {
            pslOptions.map(m => m.checked = false); // remove all checked psl options
            pslOptions = updateChildAttrByKey(pslOptions, obj.psl, ["checked"], true, "value");
        } else {
            pslOptions.map(m => m.checked = false);
            pslOptions[0].checked = true;
        }

        
        let titlePrefix = "[average]";
        let vectorDropdownTitle = titlePrefix + VECTOR_ATTRIBUTE.label;
        let attributeTitle = titlePrefix + FIRST_ATTRIBUTE.label;
        attributeTitle = handleCurrency(attributeTitle);
        vectorDropdownTitle = handleCurrency(vectorDropdownTitle);


        const optionLabel = ({label, isGroupTitle, tooltipText}) => (
          <div className={(isGroupTitle ? "option-group-header-label" : "") +" option-padding"} uk-tooltip={!!tooltipText ? tooltipText : ""}>
              {label}
          </div>
        );
        return [
            {
              type: PSL.value,
              toggleDefaultValue: !obj.hidePsl,
              toggleTitle:AMOUNT_OF + ( pslOptions?.length > 0 ?this.props.isMoM ? pslOptions[0]?.label : pslOptions.find(e=>e.checked).label : ""),
              isTreeDropdown: true,
              dropdownData: pslOptions,
              dropdownPlaceholder: lang.heatmap_configure.select_ps_line,
              dropdownMode: "radioSelect",
            },
            {
              type: FIRST_ATTRIBUTE.value,
              toggleTitle: attributeTitle,
              dropdownData: this.props.attributes,
              dropdownDefaultValue: this.props.attributes[0],
              dropdownPlaceholder: lang.configure_attribute,
              toggleTabOptions: AVG_MED_TOGGLE_TABS, 
              selectedTab: AVG_MED_TOGGLE_TABS[0].value
            },
            {
              type: VECTOR_ATTRIBUTE.value,
              toggleTitle: vectorDropdownTitle,
              dropdownData: this.props.vectorOptions,
              dropdownDefaultValue: this.props.vectorOptions.filter(v => !v.isGroupTitle)[0],
              dropdownPlaceholder: lang.manage_columns.select_vector,
              toggleTabOptions: AVG_MED_TOGGLE_TABS,
              selectedTab: AVG_MED_TOGGLE_TABS[0].value,
              optionLabel: optionLabel,
              showSelectedTooltip: true
          },
        ];
    }
    onApplyConfigClick = (stackConfigObj, notApply) => {
        this.props.onApplyConfigClick(stackConfigObj, notApply);
        if (!notApply) {
            this.setState({showConfigureDialog: false})
        }
    }

    /**
     * to add borders between the groups
     */
    addBordersToCells=()=>{
        let _this = this;
        if(this.props.isProfitStack){
            setTimeout(() => {
                _this.addBordersToCellsCode();
            }, 250);
        }
    }

    render() {
        this.addBordersToCellsCode();

        return(
            <div id='psl_definition_table' style={{height: "100%"}} className={this.props.compId ? "profit_stack_comp_table has_grouping" : this.props.isRange ? "profit_stack_comp_table stacksRange has_grouping" : this.props.isBridge ? "bridge_report_container has_grouping" : "has_grouping"}>
                {this.props.isBridge && <div className="mapping-header-stacks uk-flex-between uk-padding-large-right">
                    <div className="uk-flex">
                        {this.props.isTableFullScreen && 
                        <Button   
                            variant={BUTTON_VARIANT.TERTIARY2}
                            size={SIZES.ICON}
                            type={BUTTON_TYPE.DEFAULT}
                            leftIcon={<i className={"fs-14 far fa-chevron" + (this.state.isTableExpanded ? "-down" : "-up")} />}
                            onBtnClick={() => this.expandTable()}
                        />
                        }
                        {(this.state.isTableExpanded) && 
                          <Button   
                            variant={BUTTON_VARIANT.TERTIARY}
                            size={SIZES.ICON}
                            type={BUTTON_TYPE.DEFAULT}
                            leftIcon={<i className={"fa-lg far fa-" + (this.props.isTableFullScreen ? "expand" : "compress")} />}
                            className="uk-margin-xsmall-left"
                            onBtnClick={() => this.props.maximizeTable()}
                        />
                        }
                        {<span className="fs-14 uk-flex-inline uk-flex-middle uk-padding-xxsmall-left uk-padding-small-right"><b>{PROFIT_STACK_TITLE}</b></span>}
                        { this.state.isTableExpanded && 
                            <div className="buttons uk-flex uk-flex-middle">
                                {this.state.isAllExpanded ?
                                    <Button 
                                       ref={this.collapseBtnRef}
                                        id="Collapse_PS"  
                                        label={"Collapse all"}
                                        title={"Collapse all"}
                                        variant={BUTTON_VARIANT.SECONDARY}
                                        size={SIZES.DEFAULT}
                                        type={BUTTON_TYPE.DEFAULT}
                                        leftIcon={<i className={"fal fa-minus-square fa-lg"} aria-hidden="true" />}
                                        className="uk-display-none"
                                        onBtnClick={() => { this.expandCollapseAll()}}
                                    />
                                    : this.state.isTableExpanded ? 
                                  
                                    <Button 
                                        ref={this.expandBtnRef}
                                        id="Expand_PS"
                                        label={"Expand all"}
                                        title={"Expand all"}
                                        variant={BUTTON_VARIANT.SECONDARY}
                                        size={SIZES.DEFAULT}
                                        type={BUTTON_TYPE.DEFAULT}
                                        disabled={!this.props.data || this.props.data.length=== 0}
                                        leftIcon={<i className={"fal fa-plus-square fa-lg"} aria-hidden="true" />}
                                        onBtnClick={() => { this.expandCollapseAll()}}
                                    /> : ""                                
                                }
                            </div>
                        }
                        
                    </div>
                    <div className={this.props.isBridge ? '' : "uk-flex uk-flex-middle gap_between_buttons"}>
                        <div id="Export_Bridge_Table_Parent_Div">
                            {this.props.isBridge && this.state.isTableExpanded && 
                                <Button 
                                    id="Export_Bridge_Table" 
                                    title="Export visible data"
                                    label={"Export Excel"}
                                    variant={BUTTON_VARIANT.SECONDARY}
                                    size={SIZES.DEFAULT}
                                    type={BUTTON_TYPE.DEFAULT}
                                    leftIcon={<i className={"fal fa-file-excel fa-lg"} aria-hidden="true" />}
                                    onBtnClick={() => { this.exportTableToExcel()}}
                                />
                            }
                        </div>
                       
                      </div>
                     
                </div>}
                {<div id={this.props.tableId} className={this.props.tableId} ref={this.props.tableId} />}
                {<div id={"expanded_1"} className={"uk-hidden"} ref={"expanded_1"} />}
                <Modal
                    id={"stacks-more-details-dialog"}
                    title={lang.psl_tooltip.profit_stack_line + (this.state.selectedRow?.name)}
                    openDialog={this.state.openMoreDetailsDialog}
                    bodyContent={this.moreDetailsDialogContent}
                    dialogActions={this.moreDetailsDialogActions}
                    closeClick={() => this.setMoreDetailsDialogOpen(false, undefined)}
                    size={this.props.overwritePopupSize ? DIALOG_SIZE.XLARGE : DIALOG_SIZE.MEDIUM} 
                />
            </div>

        );
    }
}

export default ProfitStackTabulator;