import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { getTranslationFile } from './class/utils';
import { LoaderWaitingMessage } from './form/elements.js';
import ManageReports from "./sections/ManageReports.js";
import './styles/common.css';
const lang = getTranslationFile();

/**
 *
 * Custom Report/Menu
 * @author [Sarah Farjallah]
 */

class CustomerReport extends Component  {
    constructor(props) {
		super(props);
    }

    refreshUserAllowedMenuLinks=()=>{
        let _this = this;
        _this.props.callGetUserSection(true);
    }
    
    render() {
        return (
          <div style={{height:"100%"}}>
            <LoaderWaitingMessage />
            <header className="header">
              <div className="d-flex justify-content-between"></div>
              <section className="filter-control"></section>
            </header>
            <ManageReports
              ref={(el) => (this.childRef = el)}
              sectionId={this.props.match.params.sectionId}
              user_email={this.props.userSettingsState.user.email}
              user={this.props.userSettingsState.user}
              userAllowedSections={this.props.userAllowedMenuLinks}
              reportDesrcriptionSizeLimit = {this.props.userSettingsState.reportDesrcriptionSizeLimit}
              history={this.props.history}
              refreshUserAllowedMenuLinks={this.refreshUserAllowedMenuLinks}
              userAllowedMenuLinks={this.props.userAllowedMenuLinks}
              goToReport={this.props.goToReport}
            />
          </div>
        );
    }

}

export default withRouter(CustomerReport);
