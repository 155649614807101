const HIGH = "high";
const MEDIUM = "medium";
const LOW = "low";
export class ColorLabel {
  constructor() {
    this.value = "";
  }

  setValue(value) {
    this.value = value;
  }

  getValue(value) {
    this.value = value;
  }

  getLabelObject(value) {
    switch (value?.toString()?.toLowerCase()?.trim()) {
      case HIGH:
        return {
          color: "rgba(108, 217, 91, 1)",
          label: value,
          value: value,
          bubbleColor: "rgba(33, 161, 12, 1)",
          textColor: "white",
          legendColor: "#5DC6F3",
          trendColor:"rgba(33, 161, 12, 1)",
          highLightColor:"rgba(33, 161, 12, 1)"
        };

      case LOW:
        return {
          color: "rgba(255, 88, 88, 1)",
          label:value,
          value: value,
          bubbleColor: "rgba(206, 16, 16, 1)",
          textColor: "white",
          legendColor: "#030451",
          trendColor:"rgba(206, 16, 16, 1)",
          highLightColor:"rgba(206, 16, 16, 1)"

        };

      case MEDIUM:
        return {
          color: "rgba(255, 201, 130, 1)",
          label: value,
          value: value,
          name: value,
          bubbleColor: "rgba(255, 169, 57, 1)",
          textColor: "white",
          legendColor: "#5F60C8",
          trendColor:"rgba(255, 169, 57, 1)",
          highLightColor:"rgba(255, 169, 57, 1)"

        };
      default:
        return {
          color: "#fff",
          label: undefined,
          value: undefined,
          name: undefined,
          bubbleColor: "",
          textColor: "black",
        };
    }
  }
}
