export function updateUserSettings(settings) {
  return {
    type: 'UPDATE_USERSETTINGS',
    userSettings: settings,
  }
}

export function getUserSetting() {
  return {
    type: 'GET_USERSETTINGS'
  }
}

