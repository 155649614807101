import React from 'react';
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { BUTTON_TYPE, BUTTON_VARIANT, SIZES } from "../class/constants";

/**
 * Button component
 * @param {string} id Id of the button
 * @param {string} label The label of the button,  (required)
 * @param {string} size Could be "small", "default" or "large" (use constants) (required)
 * @param {string} className additional classNames
 * @param {Element} leftIcon Adds a left icon (should be sent as a component and not a string)
 * @param {Element} rightIcon Adds a right icon (should be sent as a component and not a string)
 * @param {string} variant Could be "primary", "secondary" or "tertiary" (use constants) (required)
 * @param {string} type Could be "default" or "danger" (use constants) (required)
 * @param {Boolan} disabled Sets the button to be disbaled
 * @param {function} onBtnClick A function that is called when the button is clicked.
 * @returns The rendered button component
 */
const Button = ({
  id,
  label,
  size,
  className,
  leftIcon,
  rightIcon,
  variant,
  type,
  disabled=false,
  onBtnClick,
  centeredIcon,
  ...props // other props that needs to be added. like: aria-label...
}, ref) => {
  useImperativeHandle(ref, () =>({
    setIsDisabled: (val) => {
      setIsDisabled(val)
    }
  }))

  const [isDisabled, setIsDisabled] = useState(disabled);
  
  let btnClassNames = `custom-btn btn-${type}-${variant} btn-size-${size}`; // Add classname based on size, type & variant
  btnClassNames += isDisabled ? " btn-disabled" : "";
  btnClassNames += " " + className;

  useEffect(() => {
    setIsDisabled(disabled)
  }, [disabled])

  return (
    <button {...props}  id={id} disabled={isDisabled} className={btnClassNames} onClick={onBtnClick} type="button">
      {centeredIcon && centeredIcon}
      {leftIcon && <i className={!leftIcon || size === SIZES.ICON ? "" : "margin-xsmall-right"} style={{pointerEvents:"none"}}>{leftIcon}</i>}
      {label && <span style={{whiteSpace:"nowrap", pointerEvents:"none"}}>{label}</span>}
      {rightIcon && <i className={!rightIcon || size === SIZES.ICON ? "" : "margin-xsmall-left"} style={{pointerEvents:"none"}}>{rightIcon}</i>}
    </button>
  );
};


export default forwardRef(Button);
