const defaulHeatmapObjBeforeBack = [];
const defaulHeatmapListData = [];

function heatmapObjBeforeBack(state = defaulHeatmapObjBeforeBack, action) {
  switch (action.type) {
    case "UPDATE_HEATMAP_OBJECT_BEFORE_BACK":
      if (action.clear) {
        let heatmapObjBeforeBackHashMap = new Map();
        return heatmapObjBeforeBackHashMap;
      }
      let heatmapObjBeforeBackHashMap = new Map(state);
      heatmapObjBeforeBackHashMap.set(action.key, action.heatmapObjBeforeBack);
      return heatmapObjBeforeBackHashMap;
    case "GET_HEATMAP_OBJECT_BEFORE_BACK":
      return state;
    default:
      return state;
  }
}

function heatmapListData(state = defaulHeatmapListData, action) {
  switch (action.type) {
    case "UPDATE_HEATMAP_LIST_DATA":
      if (action.clear) {
        let heatmapListDataMap = new Map();
        return heatmapListDataMap;
      }
      let heatmapListDataMap = new Map(state);
      heatmapListDataMap.set(action.key, action.heatmapListData);
      return heatmapListDataMap;
    case "GET_HEATMAP_LIST_DATA":
      return state;
    default:
      return state;
  }
}

export { heatmapObjBeforeBack, heatmapListData };
