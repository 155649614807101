import React, { Component } from 'react';
import 'jquery';
import FiscalCalendar from '../../sections/settings/FiscalCalendar.js';


/**
 * Periods Section in Modeling Settings
 * @author [Mostafa]
 */
class Periods extends Component {
		
	constructor(props) {
		super(props);
		this.state = {
        };
    }

    render() {
        return (
            <div>
                {this.props.periods?
                    <FiscalCalendar ref={el=> this.fiscalCalendarRef = el} machineName={this.props.machineName} periods={this.props.periods} clientId={this.props.clientId}
                    active_period_limit={this.props.active_period_limit} setData={this.props.setData} fiscalOriginalPeriods={this.props.fiscalOriginalPeriods} getClientPeriods={this.props.getClientPeriods}
                    isChangingTab={this.props.isChangingTab} dispatch={this.props.dispatch} changingReport={this.props.changingReport}/>
                :""}
            </div>
        );
    }
}

export default Periods;