import React from "react";
import {SortableContext, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {DraggableOption} from "./DraggableOption";

export const DraggableOptionRow = ({options}) => {
    return (
        <div>
            {/* The SortableContext component is critical as it provides the drag-and-drop context for all children components,
        making them sortable. It is a part of @dnd-kit/sortable and is essential for enabling drag-and-drop functionality. */}
            <SortableContext items={options} strategy={verticalListSortingStrategy}> {/* A sorting strategy that defines how items should be sorted. In this case, verticalListSortingStrategy is used,
        which organizes the items in a vertical list and handles the logic for dragging items up and down. */}
                {options.map((item) => (
                    <DraggableOption id={item.id} title={item.title} key={item.id}/>
                ))}
            </SortableContext>
        </div>
    );
};