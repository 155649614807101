import { VECTOR_GROUPS, VECTOR_STAGING_ATTRIBUTES } from "../../class/constants";

function getSecondDimensionVectors(vectorOptions) {
  let _vectorGroup = VECTOR_STAGING_ATTRIBUTES.GROUP;
  let _displayOrder = VECTOR_STAGING_ATTRIBUTES.DISPLAY_ORDER;
  let groups = [...new Set(vectorOptions.map((e) => e[_vectorGroup]))];
  vectorOptions = vectorOptions.filter((v) => !v.isDisabled); //remove disabled options

  if (!vectorOptions.length) {
    return ["", ""];
  }

  let firstDimension = null;
  let secondDimension = null;
  //fetch the indices of "Customer" and "Product", if "Product" group doesn't exist, try "Operations" and if also doesn't exist, just use "Customer" again
  let _1stGrpIndex = groups.indexOf(VECTOR_GROUPS.CUSTOMER);
  let _2ndGrpIndex = groups.includes(VECTOR_GROUPS.PRODUCT)
    ? groups.indexOf(VECTOR_GROUPS.PRODUCT)
    : groups.includes(VECTOR_GROUPS.OPERATIONS)
    ? groups.indexOf(VECTOR_GROUPS.OPERATIONS)
    : _1stGrpIndex;

  if (groups.length === 1) {
    // if we have only 1 group
    _1stGrpIndex = 0;
    _2ndGrpIndex = 0;
  } else if (_1stGrpIndex === -1) {
    // if we have no access to customer
    _1stGrpIndex = 0;
    _2ndGrpIndex++;
  }
  let getMinDisplayRank = (options, group, except) => {
    let rank = -1;
    options
      .filter((e) => e[_vectorGroup] === group)
      .forEach((e) => {
        if ((rank === -1 || rank > e[_displayOrder]) && (except === undefined || e[_displayOrder] !== except)) {
          rank = e[_displayOrder]; //set value to lowest rank
        }
      });
    return rank;
  };

  let min1stGroupRank = getMinDisplayRank(vectorOptions, groups[_1stGrpIndex]); //set value to lowest rank
  let firstDimensionObj = vectorOptions.filter(
    (e) => e[_vectorGroup] === groups[_1stGrpIndex] && e[_displayOrder] === min1stGroupRank && !e.isDisabled
  )[0];
  firstDimension = firstDimensionObj ? firstDimensionObj.value : "";

  let min2ndGroupRank = getMinDisplayRank(
    vectorOptions.filter((e) => e["value"] !== firstDimension),
    groups[_2ndGrpIndex]
  );
  let secondDimensionObj = vectorOptions.filter(
    (e) => e[_vectorGroup] === groups[_2ndGrpIndex] && e[_displayOrder] === min2ndGroupRank && !e.isDisabled
  )[0];
  secondDimension = secondDimensionObj ? secondDimensionObj.value : "";

  return [firstDimension, secondDimension];
}

function disableVectors(vectors, deleteVectors) {
  if (deleteVectors) {
    let generatedVectors = vectors.filter((vector) => vector.generated || vector.isGroupTitle);
    return generatedVectors;
  } else {
    vectors.map((vector) => (vector.isDisabled = !vector.generated || vector.isGroupTitle));
    return vectors;
  }
  return vectors;
}

function enableVectors(vectors) {
  for (var e in vectors) {
    if (!vectors[e].isGroupTitle) {
      vectors[e].isDisabled = false;
    }
  }
  return vectors;
}

export { getSecondDimensionVectors, disableVectors, enableVectors };
