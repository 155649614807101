import React, { Component } from 'react';
import { getTranslationFile, findOptionByKey } from '../../class/utils';
import { DROPDOWN_TYPE, VECTOR_MAPPING} from '../../class/constants';
import { CustomSelect } from '../../form/elements.js';
import { addRowNumberToOptions } from '../../class/jqueries';
import { arrangeFileNames } from '../filter/FilterHelperFunctions';
import { convertPxToViewport } from '../../class/formatting';
import DropDown from '../../newComponents/DropDown';

const MESSAGES = getTranslationFile();

/**
 * Vector Mapping - Concatenated Column
 * @author [Lara Jannoun]
 * Epic: PI-12720
 */
class ConcatenateColumn extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
        this.onChangeOption = this.onChangeOption.bind(this);

        this.subtypeIdAttrName = VECTOR_MAPPING.ATTR[(this.props.identifier+"_SUBTYPE_ID").toUpperCase()];
        this.valueAttrName = VECTOR_MAPPING.ATTR[(this.props.identifier+"_VALUE").toUpperCase()];
    }

    onChangeOption(valueObj, attr, type, decrement, condition, isManual) {
        this.props.onChangeOption(valueObj, attr, type, decrement, condition, isManual);
    }

    render() {
        var obj = this;
        if(this.props.isSameAsKey){
            addRowNumberToOptions(this.props.oldFields, 0);
        } else{
            addRowNumberToOptions(this.props.fields, 0);
        }
        //to make the list open upwards
        const topStyles = {
            menu: (provided, state) => ({
                ...provided,
                top: 'auto',
                bottom: '100%',
                zIndex: 3
            }),
            option: (styles) => ({
                ...styles,
                padding: convertPxToViewport(8)+ ' 0 '+convertPxToViewport(8)+' '+convertPxToViewport(3),
            }),
            control: (provided, state) => ({
                ...provided,
                borderRadius: convertPxToViewport(4),
                minHeight: convertPxToViewport(38),
                borderWidth: convertPxToViewport(1),
            }),
            placeholder: (styles) => ({
                ...styles,
                marginLeft: convertPxToViewport(2),
                marginRRight: convertPxToViewport(2),
            })
        }

        const bottomStyles = {
            menu: (provided, state) => ({
            ...provided,
                bottom: 'auto',
                top: '100%',
                zIndex: 3
            }),
            option: (styles) => ({
                ...styles,
                padding: convertPxToViewport(8)+ ' 0 '+convertPxToViewport(8)+' '+convertPxToViewport(3) ,
            }),
            control: (provided, state) => ({
                ...provided,
                borderRadius: convertPxToViewport(4),
                minHeight: convertPxToViewport(38),
                borderWidth: convertPxToViewport(1),
            }),
            placeholder: (styles) => ({
                ...styles,
                marginLeft: convertPxToViewport(2),
                marginRRight: convertPxToViewport(2),
            })
        }      
        
        const optionLabel = ({ value, label, isDisabled }) => (
            <div title={label} className={(isDisabled ? "option-group-header" : "")+ " option-padding"}>{label}</div>
        );

        var connectorValue = "";
        if (this.props.basic) {
            connectorValue = findOptionByKey(this.props.connectorSelectOptions, this.props.fieldsValue[this.valueAttrName] && this.props.fieldsValue[this.valueAttrName].length > 0 &&
                this.props.fieldsValue[this.valueAttrName][this.props.rowIndex] && this.props.fieldsValue[this.valueAttrName][this.props.rowIndex].value ?
                this.props.fieldsValue[this.valueAttrName][this.props.rowIndex].value : this.props.fieldsValue[this.valueAttrName][this.props.rowIndex] ?
                this.props.fieldsValue[this.valueAttrName][this.props.rowIndex] : "");
        } else if(this.props.advanced && this.props.isTrueOrFalse){
            connectorValue = findOptionByKey(this.props.connectorSelectOptions, this.props.trueCondition &&  this.props.trueCondition.length > 0 && this.props.trueCondition[this.props.rowIndex] && this.props.trueCondition[this.props.rowIndex].length > 0 &&  this.props.trueCondition[this.props.rowIndex][this.props.conditionNumber] && this.props.trueCondition[this.props.rowIndex][this.props.conditionNumber].length > 0 &&  
                this.props.trueCondition[this.props.rowIndex][this.props.conditionNumber].value ? this.props.trueCondition[this.props.rowIndex][this.props.conditionNumber].value : this.props.trueCondition && this.props.trueCondition[this.props.rowIndex]
                && this.props.trueCondition[this.props.rowIndex].length > 0 && this.props.trueCondition[this.props.rowIndex][this.props.conditionNumber] ? this.props.trueCondition[this.props.rowIndex][this.props.conditionNumber] : "");
        } else if (this.props.advanced && this.props.isTrueOrFalse === false){
            connectorValue = findOptionByKey(this.props.connectorSelectOptions, this.props.falseCondition && this.props.falseCondition.length > 0 && this.props.falseCondition[0]
                && this.props.falseCondition[0][this.props.rowIndex] && this.props.falseCondition[0][this.props.rowIndex].value ? this.props.falseCondition[0][this.props.rowIndex].value
                : this.props.falseCondition && this.props.falseCondition[0] && this.props.falseCondition[0].length > 0 && this.props.falseCondition[0][this.props.rowIndex] ? this.props.falseCondition[0][this.props.rowIndex] : "")
        }

        var fileValue = "";
        if (this.props.basic) {
            fileValue = findOptionByKey(this.props.fileOptions, this.props.fieldsValue[this.valueAttrName] && this.props.fieldsValue[this.valueAttrName].length > 0
                && this.props.fieldsValue[this.valueAttrName][this.props.rowIndex + 1] && this.props.fieldsValue[this.valueAttrName][this.props.rowIndex + 1].value ?
                this.props.fieldsValue[this.valueAttrName][this.props.rowIndex + 1].value : this.props.fieldsValue[this.valueAttrName][this.props.rowIndex + 1] ?
                this.props.fieldsValue[this.valueAttrName][this.props.rowIndex + 1] : "");
        } else if (this.props.advanced && this.props.isTrueOrFalse) {
            fileValue = findOptionByKey(this.props.fileOptions, this.props.trueCondition &&  this.props.trueCondition.length > 0 &&  this.props.trueCondition[this.props.rowIndex] && this.props.trueCondition[this.props.rowIndex].length > 0 && this.props.trueCondition[this.props.rowIndex][this.props.conditionNumber+1] && this.props.trueCondition[this.props.rowIndex][this.props.conditionNumber+1].length > 0 &&  
                this.props.trueCondition[this.props.rowIndex][this.props.conditionNumber+1] && this.props.trueCondition[this.props.rowIndex][this.props.conditionNumber+1].value ? this.props.trueCondition[this.props.rowIndex][this.props.conditionNumber+1].value : this.props.trueCondition && this.props.trueCondition[this.props.rowIndex] && this.props.trueCondition[this.props.rowIndex].length > 0 && this.props.trueCondition[this.props.rowIndex][this.props.conditionNumber] 
                && this.props.trueCondition[this.props.rowIndex][this.props.conditionNumber+1] ? this.props.trueCondition[this.props.rowIndex][this.props.conditionNumber+1] : "");
        } else if (this.props.advanced && this.props.isTrueOrFalse === false){
            fileValue = findOptionByKey(this.props.fileOptions, this.props.falseCondition && this.props.falseCondition.length > 0 && this.props.falseCondition[0]
                && this.props.falseCondition[0].length > 0 && this.props.falseCondition[0][this.props.rowIndex + 1] && this.props.falseCondition[0][this.props.rowIndex + 1].value ? this.props.falseCondition[0][this.props.rowIndex + 1].value
                : this.props.falseCondition && this.props.falseCondition[0] && this.props.falseCondition[0].length > 0 && this.props.falseCondition[0][this.props.rowIndex + 1] ? this.props.falseCondition[0][this.props.rowIndex + 1] : "");
        }

        var columnValue = "";
        if (this.props.basic) {
            columnValue = findOptionByKey(this.props.columnSelectOptions, this.props.fieldsValue[this.valueAttrName] && this.props.fieldsValue[this.valueAttrName].length > 0
                && this.props.fieldsValue[this.valueAttrName][this.props.rowIndex + 2] && this.props.fieldsValue[this.valueAttrName][this.props.rowIndex + 2].value ?
                this.props.fieldsValue[this.valueAttrName][this.props.rowIndex + 2].value : this.props.fieldsValue[this.valueAttrName][this.props.rowIndex + 2] ?
                this.props.fieldsValue[this.valueAttrName][this.props.rowIndex + 2] : "");
        } else if (this.props.advanced && this.props.isTrueOrFalse) {
            columnValue = findOptionByKey(this.props.columnSelectOptions,  this.props.trueCondition &&  this.props.trueCondition.length > 0 && this.props.trueCondition[this.props.rowIndex] && this.props.trueCondition[this.props.rowIndex].length > 0 && this.props.trueCondition[this.props.rowIndex][this.props.conditionNumber+2]
                && this.props.trueCondition[this.props.rowIndex][this.props.conditionNumber + 2].value ? this.props.trueCondition[this.props.rowIndex][this.props.conditionNumber + 2].value : this.props.trueCondition && this.props.trueCondition[this.props.rowIndex] && this.props.trueCondition[this.props.rowIndex][this.props.conditionNumber+2] && this.props.trueCondition[this.props.rowIndex][this.props.conditionNumber+2].length > 0
                ? this.props.trueCondition[this.props.rowIndex][this.props.conditionNumber + 2] : "");
        } else if (this.props.advanced && this.props.isTrueOrFalse === false){
            columnValue = findOptionByKey(this.props.columnSelectOptions, this.props.falseCondition && this.props.falseCondition.length > 0 && this.props.falseCondition[0]
                && this.props.falseCondition[0][this.props.rowIndex + 2] && this.props.falseCondition[0][this.props.rowIndex + 2].value ? this.props.falseCondition[0][this.props.rowIndex + 2].value :
                this.props.falseCondition && this.props.falseCondition[0] && this.props.falseCondition[0].length > 0 && this.props.falseCondition[0][this.props.rowIndex + 2] ? this.props.falseCondition[0][this.props.rowIndex + 2] : "");
        }

        return (
            <div className="concatenad-column-parent">
                { this.props.basic ?
                    <div className="mrgb10 mrgt10 height-30">
                        {this.props.rowIndex === 1 ?
                            <h5 className="uk-text-capitalize uk-text-bold">{MESSAGES.vectors_mapping_concatenated_column_title_long}</h5>
                            : ""}
                        <div className="uk-float-right uk-button-icon delete-btn-margin transparent-bg" rowIndex={this.props.rowIndex} onClick={this.props.deleteValueRow}>
                            <i className={"fal fa-trash-alt fa-lg"} rowIndex={this.props.rowIndex}></i>
                            <span className="uk-margin-small-left">Delete</span>
                        </div>
                    </div>
                    :""
                } 
                {
                    this.props.advanced ? 
                        <div className={(this.props.conditionNumber === 1 ? "mrgt20 " : "mrgt10 ") + "mrgb10 height-30"}>
                            {
                                this.props.isTrueOrFalse === true ? 
                                    <React.Fragment>
                                        { this.props.conditionNumber === 1?
                                            <h5 className="uk-text-capitalize uk-text-bold">{MESSAGES.vectors_mapping_concatenated_column_title_long}</h5>
                                            : ""
                                        }
                                        <div className={(this.props.advancedIsDisabled ? "disabled " : "") + "uk-button-icon uk-float-right"} disabled={this.props.advancedIsDisabled} rowIndex={this.props.conditionNumber} index={this.props.rowIndex} onClick={(e) => obj.props.deleteValueRow(e, true)}>
                                            <i className={(this.props.advancedIsDisabled ? "disabled " : "") + "fal fa-trash-alt fa-lg"} disabled={this.props.advancedIsDisabled} rowIndex={this.props.conditionNumber} index={this.props.rowIndex}></i>
                                            <span className="uk-margin-small-left">Delete</span>
                                        </div>
                                    </React.Fragment>
                                : this.props.isTrueOrFalse === false ?
                                    <React.Fragment>
                                        { this.props.rowIndex === 1 ?
                                            <h5 className="uk-text-capitalize uk-text-bold">{MESSAGES.vectors_mapping_concatenated_column_title_long}</h5>
                                            : ""
                                        }
                                        <div className={(this.props.selectFalseDisabled ? "disabled " : "") + "uk-button-icon uk-float-right"} disabled={this.props.selectFalseDisabled} rowIndex={this.props.rowIndex} onClick={(e) => obj.props.deleteValueRow(e, false)}>
                                            <i className={(this.props.selectFalseDisabled ? "disabled " : "") + "fal fa-trash-alt fa-lg"} disabled={this.props.selectFalseDisabled} rowIndex={this.props.rowIndex} ></i>
                                            <span className="uk-margin-small-left">Delete</span>
                                        </div>
                                    </React.Fragment>
                            : ""
                                    
                            }
                    </div>
                    : ""
                }
                <div className="concatenad-column">
                    <div className="uk-display-flex uk-margin-small-bottom justify-content-between">
                        <div className="uk-text-xmedium align-items-center uk-display-flex">{MESSAGES.vectors_mapping_concatenated_column_connector}</div>
                        <CustomSelect
                            className="uk-float-right width-200"
                            value={connectorValue}
                            options={this.props.connectorSelectOptions}
                            onChange={(valueObj)=> this.props.basic ? this.onChangeOption(valueObj, this.valueAttrName, VECTOR_MAPPING.ATTR.TYPE_VALUES.CONNECTOR) : this.onChangeOption(valueObj, this.valueAttrName, VECTOR_MAPPING.ATTR.TYPE_VALUES.CONNECTOR, 0, this.props.isTrueOrFalse, undefined)}
                            placeholder={MESSAGES.vectors_mapping_concatenated_column_connector_placeholder}
                            styles={this.valueAttrName && this.valueAttrName  === VECTOR_MAPPING.FIELDS.VECTOR_NAME_VALUE ? topStyles : bottomStyles }
                            name="connector"
                            isDisabled={this.props.advanced && this.props.isTrueOrFalse ? this.props.advancedIsDisabled : this.props.advanced && !this.props.isTrueOrFalse ? this.props.selectFalseDisabled : false}
                        />
                        {/* <DropDown //To remove comment and comment <CustomSelect
                            className="uk-float-right width-200"
                            value={connectorValue}
                            options={this.props.connectorSelectOptions}
                            onChange={(valueObj) => this.props.basic ? this.onChangeOption(valueObj, this.valueAttrName, VECTOR_MAPPING.ATTR.TYPE_VALUES.CONNECTOR) : this.onChangeOption(valueObj, this.valueAttrName, VECTOR_MAPPING.ATTR.TYPE_VALUES.CONNECTOR, 0, this.props.isTrueOrFalse, undefined)}
                            placeholder={MESSAGES.vectors_mapping_concatenated_column_connector_placeholder}
                            name="connector"
                            disabled={this.props.advanced && this.props.isTrueOrFalse ? this.props.advancedIsDisabled : this.props.advanced && !this.props.isTrueOrFalse ? this.props.selectFalseDisabled : false}
                            type={DROPDOWN_TYPE.INPUT}
                        /> */}
                    </div>
                    <div className="uk-display-flex uk-margin-small-bottom justify-content-between">
                        <div className="uk-text-xmedium align-items-center uk-display-flex">{MESSAGES.vectors_mapping_concatenated_column_file}</div>
                        <CustomSelect 
                            className="uk-float-right width-200"
                            value={fileValue}
                            options={arrangeFileNames(this.props.fileOptions) || []}
                            onChange={(valueObj) => this.props.basic ? this.onChangeOption(valueObj, this.valueAttrName, VECTOR_MAPPING.ATTR.TYPE_VALUES.FILE) : this.onChangeOption(valueObj, this.valueAttrName, VECTOR_MAPPING.ATTR.TYPE_VALUES.FILE, 0, this.props.isTrueOrFalse, undefined)}
                            placeholder={MESSAGES.vectors_mapping_concatenated_column_file_placeholder}
                            styles={this.valueAttrName && this.valueAttrName  === VECTOR_MAPPING.FIELDS.VECTOR_NAME_VALUE ? topStyles : bottomStyles }
                            name="file"
                            formatOptionLabel={optionLabel}
                            isDisabled={this.props.advanced && this.props.isTrueOrFalse ? this.props.advancedIsDisabled : this.props.advanced && !this.props.isTrueOrFalse ? this.props.selectFalseDisabled : false}
                        />
                        {/* <DropDown //To remove comment and comment <CustomSelect
                            className="uk-float-right width-200"
                            value={fileValue}
                            options={arrangeFileNames(this.props.fileOptions) || []}
                            onChange={(valueObj) => this.props.basic ? this.onChangeOption(valueObj, this.valueAttrName, VECTOR_MAPPING.ATTR.TYPE_VALUES.FILE) : this.onChangeOption(valueObj, this.valueAttrName, VECTOR_MAPPING.ATTR.TYPE_VALUES.FILE, 0, this.props.isTrueOrFalse, undefined)}
                            placeholder={MESSAGES.vectors_mapping_concatenated_column_file_placeholder}
                            name="file"
                            formatOptionLabel={optionLabel}
                            type={DROPDOWN_TYPE.INPUT}
                            disabled={this.props.advanced && this.props.isTrueOrFalse ? this.props.advancedIsDisabled : this.props.advanced && !this.props.isTrueOrFalse ? this.props.selectFalseDisabled : false}
                        /> */}
                    </div>
                    <div className="uk-display-flex justify-content-between">
                        <div className="uk-text-xmedium align-items-center uk-display-flex">{MESSAGES.vectors_mapping_concatenated_column_column.replace("[key]", this.props.identifierName)}</div>
                        <CustomSelect
                            className="uk-float-right width-200"
                            value={columnValue}
                            options={this.props.columnSelectOptions}
                            onChange={(valueObj) => this.props.basic ? this.onChangeOption(valueObj, this.valueAttrName, VECTOR_MAPPING.ATTR.TYPE_VALUES.FIELD) : this.onChangeOption(valueObj, this.valueAttrName, VECTOR_MAPPING.ATTR.TYPE_VALUES.FIELD, 0, this.props.isTrueOrFalse, undefined)}
                            placeholder={MESSAGES.vectors_mapping_concatenated_column_column_placeholder}
                            styles={this.valueAttrName && this.valueAttrName  === VECTOR_MAPPING.FIELDS.VECTOR_NAME_VALUE ? topStyles : bottomStyles }
                            name="column"
                            isDisabled={this.props.advanced && this.props.isTrueOrFalse ? this.props.advancedIsDisabled : this.props.advanced && !this.props.isTrueOrFalse ? this.props.selectFalseDisabled : false}
                        />
                        {/* <DropDown //To remove comment and comment <CustomSelect
                            className="uk-float-right width-200"
                            value={columnValue}
                            options={this.props.columnSelectOptions}
                            onChange={(valueObj) => this.props.basic ? this.onChangeOption(valueObj, this.valueAttrName, VECTOR_MAPPING.ATTR.TYPE_VALUES.FIELD) : this.onChangeOption(valueObj, this.valueAttrName, VECTOR_MAPPING.ATTR.TYPE_VALUES.FIELD, 0, this.props.isTrueOrFalse, undefined)}
                            placeholder={MESSAGES.vectors_mapping_concatenated_column_column_placeholder}
                            name="column"
                            type={DROPDOWN_TYPE.INPUT}
                            disabled={this.props.advanced && this.props.isTrueOrFalse ? this.props.advancedIsDisabled : this.props.advanced && !this.props.isTrueOrFalse ? this.props.selectFalseDisabled : false}
                        /> */}
                    </div>
                </div>
            </div>
        );
    }
}


export default ConcatenateColumn;