import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import '../styles/geography.css'
import { convertPxToViewport } from "../class/formatting";

const MapKeyLegend = forwardRef(({
 lowestValue,
 highestValue,
 psl,
 highestVal,
 lowestVal,
 colors,
 isStandard,
 isCalculated
},ref) => {
  return (
    <div className="mainContainer">
        <span className="map-key-text" style={{fontSize:convertPxToViewport(14)}}>{psl}</span>
        {isStandard || (isCalculated && highestVal>= 0 && lowestVal <= 0) ?
        <div class="containermap" style={{width:'8.25vw'}}>
          <div className="" style={{height:convertPxToViewport(24,true),width:convertPxToViewport(24),backgroundColor:colors[0]}}></div>
          <div className="" style={{height:convertPxToViewport(24,true),width:convertPxToViewport(24),backgroundColor:colors[1]}}></div>
          <div className="" style={{height:convertPxToViewport(24,true),width:convertPxToViewport(24),backgroundColor:colors[2]}}></div>
          <div className="" style={{height:convertPxToViewport(24,true),width:convertPxToViewport(24),backgroundColor:colors[3]}}></div>
          <div className="" style={{height:convertPxToViewport(24,true),width:convertPxToViewport(24),backgroundColor:colors[4]}}></div>
          <div className="" style={{height:convertPxToViewport(24,true),width:convertPxToViewport(24),backgroundColor:colors[5]}}></div>            
        </div>
        : isCalculated && highestVal>= 0 && lowestVal >= 0 ? 
        <div class="containermap" style={{width:'8.25vw'}}>
          <div className="" style={{height:convertPxToViewport(24,true),width:convertPxToViewport(48),backgroundColor:colors[3]}}></div>
          <div className="" style={{height:convertPxToViewport(24,true),width:convertPxToViewport(48),backgroundColor:colors[4]}}></div>
          <div className="" style={{height:convertPxToViewport(24,true),width:convertPxToViewport(48),backgroundColor:colors[5]}}></div>            
        </div>
        : isCalculated && highestVal<= 0 && lowestVal <= 0 ?
        <div class="containermap" style={{width:'8.25vw'}}>
          <div className="" style={{height:convertPxToViewport(24,true),width:convertPxToViewport(48),backgroundColor:colors[0]}}></div>
          <div className="" style={{height:convertPxToViewport(24,true),width:convertPxToViewport(48),backgroundColor:colors[1]}}></div>
          <div className="" style={{height:convertPxToViewport(24,true),width:convertPxToViewport(48),backgroundColor:colors[2]}}></div>            
        </div> 
        :""}
        {highestVal === lowestVal && highestVal < 0 ? 
          <div className="valuesContainer">
            <div style={{fontSize:convertPxToViewport(12),display:"-webkit-inline-box"}}>{lowestValue}</div>
            </div>
          : highestVal === lowestVal && highestVal > 0 ?
            <div className="valuesContainer">
              <div className="uk-margin-small-right" style={{float:"right",fontSize:convertPxToViewport(12),display:"-webkit-inline-box"}}>{highestValue}</div>
            </div>
            :
            <div className="valuesContainer">
              <div style={{fontSize:convertPxToViewport(12),display:"-webkit-inline-box"}}>{lowestValue}</div>
              <div className="uk-margin-small-right" style={{float:"right",fontSize:convertPxToViewport(12),display:"-webkit-inline-box"}}>{highestValue}</div>
            </div>
        }
    </div>
  );
});

export default MapKeyLegend;
