import { EventEmitter } from 'events';

class UIManager extends EventEmitter {
  constructor() {
    super();
    this.toastMessage = '';
    this.isToastVisible = false;
  }

  showToast(message) {
    this.toastMessage = message;
    this.isToastVisible = true;
    this.emit('toast', { message, show: true });
  }

  hideToast() {
    this.toastMessage = '';
    this.isToastVisible = false;
    this.emit('toast', { message: '', show: false });
  }

}


const uiManager = new UIManager();
export default uiManager;
