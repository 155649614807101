import React, { Component, useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom'
import { TabulatorFull  } from "tabulator-tables"; // for full package
import { cleanUpTabulatorColumns ,checkIfSectionIdExists} from '../../class/common';
import { ALL_WIDGETS, COLUMN_PROFILE, MANAGE_STACKS, MENU_ITEM, SECTION} from '../../class/constants';
import { convertPxToViewport } from '../../class/formatting';
import {
    copyObjectValues,
    getTranslationFile,
    getSectionExists,
    removeWrapperNodeAndKeepChildren
} from '../../class/utils';
import { getTableButton, getTableIconButton } from "../../newComponents/tabulatorComponents"
import '../../styles/manageStacks.css';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';

const $ = require('jquery');
const lang = getTranslationFile();
const StacksTabulator =  forwardRef((props,ref) => {
    const {data, columns, id, index,tableData,userAllowedMenuLinks,compatibilityObj, isCompanyLimitReached, isUserLimitReached, companyStacks, userStacks,companyLimit,userLimit,isLimitReached} = props;
    const prevProps = usePrevious(id);
    let TableRef = useRef();
    let prevData = usePrevious(data);
    let compatibility = useRef(compatibilityObj);
    let prevCompatibility = usePrevious(compatibilityObj);
    let history = useHistory();
    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
        ref.current = value;
        });
        return ref.current;
    }

    useImperativeHandle(ref,()=>{
        return {
            replaceData:()=>{
                return replaceData();
            }
        };
    })

    useEffect(()=>{
        if(TableRef.current && data && !prevData && JSON.stringify(data) !== JSON.stringify(prevData)){
            TableRef.current.setColumns(getTabulatorColumns(columns))
            TableRef.current.setData(data);
            TableRef.current.setSort(MANAGE_STACKS.FIELDS.VISIBILITY,"asc");
        }
        if(TableRef.current && data && prevData && JSON.stringify(data) !== JSON.stringify(prevData)){
            TableRef.current.setData(data);
            TableRef.current.setSort(MANAGE_STACKS.FIELDS.VISIBILITY,"asc");
        }
    },[data])
 
    useEffect(()=>{
        if(TableRef.current && compatibilityObj && compatibilityObj.length && JSON.stringify(compatibilityObj) !== JSON.stringify(prevCompatibility)){
            compatibility.current = compatibilityObj;
            TableRef.current.setData(data);
            TableRef.current.setSort(MANAGE_STACKS.FIELDS.VISIBILITY,"asc");
        }
    },[compatibilityObj])// set data when receiving the compatibilityObj from props

    const replaceData = () => {
        setTimeout(()=>{
                TableRef?.current?.setData(data);
                TableRef.current.redraw();
        },500)
        
    }

    const getColumnFormatter = (colName) => {
        var columnFormatter = "";
        switch(colName){
            case MANAGE_STACKS.FIELDS.VIEW:
                    columnFormatter = function(cell, formatterParams) {
                        let isUserViewAllowed = getSectionExists(userAllowedMenuLinks, MENU_ITEM.FIELDS.MANAGE_STACKS) || getSectionExists(userAllowedMenuLinks, "view_user_stacks");
                        let isCompanyViewAllowed = getSectionExists(userAllowedMenuLinks, MENU_ITEM.FIELDS.VIEW_COMPANY_STACKS) || getSectionExists(userAllowedMenuLinks, "view_company_stacks");
                        let rowNumber = cell.getRow().getPosition();
                        let rowData = cell.getRow().getData();
                        let visibility = cell.getRow().getData()[MANAGE_STACKS.FIELDS.VISIBILITY];
                        
                        let pMain = document.createElement("p");
                        let viewBtn = getTableIconButton(["far","fa-eye", "uk-margin-xsmall-left", "icon_color"]);
                        viewBtn.style.padding = 0
                        viewBtn.style.paddingLeft = 5
                        
                        let currentComp = compatibility.current? compatibility.current.filter(e=>e.stack_id === rowData.custom_stack_id):[];
                        if(currentComp && currentComp[0] && currentComp[0].isPublishedIncompatible){
                            viewBtn.classList.add("uk-disabled","disabled");
                            pMain.setAttribute("uk-tooltip",lang.stack_incompatible);
                        }else{
                            pMain.setAttribute("uk-tooltip",lang.preview)
                        }

                        if((!isUserViewAllowed && visibility === "user") || (!isCompanyViewAllowed && visibility === "company")){
                            viewBtn.classList.add("uk-disabled","disabled");
                            pMain.setAttribute("uk-tooltip", lang.stack_incompatible);
                        }
                        // viewBtn.title = "View";
                        viewBtn.id = "view_" + rowNumber;
                        viewBtn.style.display = 'none';
                        viewBtn.onclick = () => {
                            history.push({
                                pathname:"/report_configurations/"+ALL_WIDGETS.FIELDS.TOTAL_STACKS_URL,
                                // pathname: "profitStack/" + d.id + "/" + rowData.custom_stack_id,
                                search: "?",
                                hash: "",
                                state: {
                                    scenarioState:props.history.location?.state?.scenarioState,
                                    origin:window.location.href,
                                    origin_name:"Manage Stacks",
                                    profitStackItems: [{}],
                                    isCustomViews:false,
                                    isManageStacks:true,
                                    drilling: false,
                                    stack:rowData,
                                    profitStackViewId:rowData.custom_stack_id,
                                    headerOptionChanged: false,
                                    mainFilter: props.filterFinal,
                                    startDate: props.customStartDate,
                                    endDate: props.customEndDate
                                    // scenario: comp.state.scenario
                                }
                            });
                        };
                        pMain.appendChild(viewBtn);
                        return pMain;
                    };
                break;
            case MANAGE_STACKS.FIELDS.VISIBILITY:
                    columnFormatter = function(cell, formatterParams) {
                        let pMain = document.createElement("p");
                        let icon = document.createElement("i"); 
                        if(cell.getValue() === COLUMN_PROFILE.VISIBILITY_OPTIONS.USER){
                            icon.classList.add("fa-lg", "fal", "fa-user", "font_weight_icon");
                           
                        } else{
                            icon.classList.add("fa-lg", "fal", "fa-users", "font_weight_icon");
                        }
                        pMain.appendChild(icon);
                        return pMain;
                    };
                break;
            case MANAGE_STACKS.FIELDS.CREATED_DATE:
            case MANAGE_STACKS.FIELDS.MODIFIED_DATE: 
                columnFormatter = function(cell, formatterParams) {
                    var options = { year: 'numeric', month: '2-digit', day: '2-digit' , hour:'2-digit', minute:'2-digit' };
                    return new Date(cell.getValue()).toLocaleString("en-US", options);
                };
            break;
            case MANAGE_STACKS.FIELDS.COMPATIBILITY: 
                columnFormatter = function(cell, formatterParams) {
                    var mainDiv = document.createElement("div");
                    let rowData = cell.getRow().getData();
                    let pMain = document.createElement("p");
                    let currentComp = compatibility.current? compatibility.current.filter(e=>e.stack_id === rowData.custom_stack_id):[];
                    let html = "";
                    if(currentComp && currentComp[0]){
                        let order =[]
                        currentComp[0].allScenariosDetails.forEach(item=>{
                            order.push({index:Number(item.scenario.split("-")[0]),object:item});
                            order.sort(function (a, b) { return a.index - b.index });
                        })
                        order.forEach(item=>{
                            html += (item.object.isValid? "<div class='uk-flex'><div class='greenText'>✓</div><div class='uk-margin-xsmall-left'>" + item.object.scenario +"</div></div>"
                            : "<div class='uk-flex'><div class='red'>✗</div><div class='uk-margin-xsmall-left'>" + item.object.scenario +"</div></div>")
                        });
                    }
                    pMain.setAttribute('uk-tooltip', html);
                    
                    pMain.innerHTML= compatibility.current && currentComp[0]? currentComp[0].validScenarios.length+ "/" + props.totalScenarios:"";
                    mainDiv.appendChild(pMain);
                    if(pMain.innerHTML && !cell.getValue()){
                        cell.setValue(pMain.innerHTML);
                    }
                    if(currentComp &&  currentComp[0] && currentComp[0].isPublishedIncompatible){
                        let warningDiv = document.createElement("i");
                        warningDiv.classList.add("fa", "fa-exclamation-circle", "pi-text-yellow","uk-margin-xsmall-left");
                        mainDiv.appendChild(warningDiv);
                    }
                    mainDiv.classList.add("uk-cursor-pointer", "uk-margin-left")
                    return mainDiv;
                };
            break;
            case MANAGE_STACKS.FIELDS.ACTIONS:
			columnFormatter = function(cell, formatterParams) {
                let isUserAllowed = getSectionExists(props.userAllowedMenuLinks, MENU_ITEM.FIELDS.MANAGE_STACKS) || getSectionExists(props.userAllowedMenuLinks, "manage_user_stacks");
                let isCompanyAllowed = getSectionExists(props.userAllowedMenuLinks, MENU_ITEM.FIELDS.CREATE_EDIT_COMPANY_STACKS) || getSectionExists(props.userAllowedMenuLinks, "manage_company_stacks");

                let rowNumber = cell.getRow().getPosition();
                let rowData = cell.getRow().getData();
			    // actions three dotted dropdown button container
                var dropdownButton = document.createElement("div");
                var dropdownContainer = document.createElement("div");
                dropdownButton.classList.add("uk-button-icon");
                dropdownContainer.id = "dropDown_"+rowNumber;
                dropdownContainer.setAttribute("uk-dropdown", "mode: click");

                // three dotted button
                var dotsButtonContainer = document.createElement("div");
                var dotsButton = getTableIconButton(["fa-2x", "fal", "fa-ellipsis-v", "icon_color"], ["uk-button-icon"]);
                dotsButton.id = "actions_"+rowNumber;
                dotsButton.style.display = "none";
                dotsButtonContainer.classList.add("uk-inline");
                
                // edit stack button
                var editIconDiv = getTableButton(
                    lang.manage_columns.titles.edit,
                    [],
                    ["justify-content-start", "manage_stacks_dropdown_button"],
                    ["fa-lg", "fal","fa-edit" ,"uk-margin-small-right", "uk-margin-small-left"],
                    "left",
                    lang.manage_columns.titles.edit
                ); 
                editIconDiv.style.width = "100%";
            
               
                // clone stack button
                var cloneIconDiv = getTableButton(
                    lang.manage_stacks.clone_stack,
                    [],
                    ["justify-content-start", "manage_stacks_dropdown_button"],
                    ["far","fa-clone", "uk-margin-small-right", "uk-margin-small-left"],
                    "left",
                    lang.manage_stacks.clone_stack
                ); 
                cloneIconDiv.style.width = "100%";

                // delete stack button
                var deleteIconDiv = getTableButton(
                    lang.manage_stacks.delete_stack,
                    [],
                    ["justify-content-start", "manage_stacks_dropdown_button"],
                    ["fal", "fa-trash-alt" ,"uk-margin-small-right", "uk-margin-small-left"],
                    "left",
                    lang.manage_stacks.delete_stack
                ); 
                deleteIconDiv.style.width = "100%";

                let isLimitReachedObj =  isLimitReached();
                if (isCompanyAllowed && isUserAllowed) {
                    if (isLimitReachedObj.isUserLimitReached && isLimitReachedObj.isCompanyLimitReached) {
                        cloneIconDiv.classList.add( "disabled-with-events");
                        cloneIconDiv.setAttribute("uk-tooltip", !isUserAllowed ? lang.manage_stacks.no_permission : lang.manage_stacks.delete_to_proceed);
                    } else {
                        cloneIconDiv.onclick = (e) => {
                            props.cloneStack(cell);
                        }
                    }
                }else if (isCompanyAllowed) {
                    if (isLimitReachedObj.isCompanyLimitReached) {
                        cloneIconDiv.classList.add( "disabled-with-events");
                        cloneIconDiv.setAttribute("uk-tooltip", !isUserAllowed ? lang.manage_stacks.no_permission : lang.manage_stacks.delete_to_proceed);
                    } else {
                        cloneIconDiv.onclick = (e) => {
                            props.cloneStack(cell);
                        }
                    }
                } else if (isUserAllowed){
                    if (isLimitReachedObj.isUserLimitReached) {
                        cloneIconDiv.classList.add( "disabled-with-events");
                        cloneIconDiv.setAttribute("uk-tooltip", !isUserAllowed ? lang.manage_stacks.no_permission : lang.manage_stacks.delete_to_proceed);
                    } else {
                        cloneIconDiv.onclick = (e) => {
                            props.cloneStack(cell);
                        }
                    }
                }
                
                if(rowData.visibility === "user") {
                    if (!isUserAllowed) {
                        editIconDiv.classList.add("disabled-with-events");
                        deleteIconDiv.classList.add( "disabled-with-events");
                        editIconDiv.setAttribute("uk-tooltip", lang.manage_stacks.no_permission);
                        deleteIconDiv.setAttribute("uk-tooltip", lang.manage_stacks.no_permission);
                    } else {
                        deleteIconDiv.onclick = (e) => {
                            props.setConfirmDeleteStackDialogOpen(true, rowData)
                        }
                        editIconDiv.onclick = (e) => {
                            props.editStack(cell)
                        }
                    }
                }
                if(rowData.visibility === "company") {
                    if (!isCompanyAllowed) {
                        editIconDiv.classList.add("disabled-with-events");
                        deleteIconDiv.classList.add( "disabled-with-events");
                        editIconDiv.setAttribute("uk-tooltip", lang.manage_stacks.no_permission);
                        deleteIconDiv.setAttribute("uk-tooltip", lang.manage_stacks.no_permission);
                    } else {
                        deleteIconDiv.onclick = (e) => {
                            props.setConfirmDeleteStackDialogOpen(true, rowData)
                        }
                        editIconDiv.onclick = (e) => {
                            props.editStack(cell)
                        }
                    }
                }
                dropdownContainer.appendChild(editIconDiv);
                dropdownContainer.appendChild(cloneIconDiv);
                dropdownContainer.appendChild(deleteIconDiv);

                dotsButtonContainer.appendChild(dotsButton);
                dotsButtonContainer.appendChild(dropdownContainer);
                return dotsButtonContainer;
                
			};
            break;
               
        }
        return columnFormatter;
    }

    const getTabulatorColumns = (columns) => {
        columns = copyObjectValues(columns) || [];
        columns.forEach(col => {
            if([MANAGE_STACKS.FIELDS.VISIBILITY,MANAGE_STACKS.FIELDS.VIEW,MANAGE_STACKS.FIELDS.ACTIONS, MANAGE_STACKS.FIELDS.CUSTOM_STACK_ID].includes(col.field)){
                col.dontFilter = true;
                if(col.field !== MANAGE_STACKS.FIELDS.CUSTOM_STACK_ID) {
                    // col.width = '3%';
                }else{
                    // col.minWidth='0%';
                }
            }
            if(window.innerWidth > 3000 && [MANAGE_STACKS.FIELDS.COMPATIBILITY].includes(col.field)){
              col.width = convertPxToViewport(col.width);
            }
            
            if(window.innerWidth > 3000 && ![MANAGE_STACKS.FIELDS.VISIBILITY,MANAGE_STACKS.FIELDS.ACTIONS,MANAGE_STACKS.FIELDS.VIEW,MANAGE_STACKS.FIELDS.COMPATIBILITY].includes(col.field)) {
                // col.width = '14%';
            }
        });
        columns = cleanUpTabulatorColumns(columns, null, null, TableRef.current, { id: "stacksTable" });
        columns.forEach(col => {
            if(col.field !== MANAGE_STACKS.FIELDS.CUSTOM_STACK_ID){
                col.formatter = getColumnFormatter(col.field);               
            }           
        });
        return columns;     
    }

    const addListenersOnHover = (rowNumber) => {
        if(document.getElementById("view_"+ rowNumber) && document.getElementById("actions_"+rowNumber) && document.getElementById("view_"+rowNumber).parentElement){
            document.getElementById("view_"+rowNumber).parentElement.parentElement.parentElement.addEventListener("mouseover", event => {
                $("#view_"+rowNumber).css("display", "block");
                $("#actions_"+rowNumber).css("display", "flex");
            });
            document.addEventListener("mouseout", event => {
                $("#view_"+rowNumber).css("display", "none");
                if($("#actions_"+rowNumber).attr('aria-expanded') === "true"){
                    $("#actions_"+rowNumber).css("display", "flex");
                }else{
                    $("#actions_"+rowNumber).css("display", "none");
                }
            });
        }
        
    }

    const getTabulator=()=> {
		if(TableRef)
			return TableRef;
		return null;
	}

    const onTabulatorRenderComplete = () => {
        if(TableRef.current && TableRef.current.getRows('active')){// don't add listener to the not rendered rows (not rendered row don't have parent element)
            TableRef.current.getRows('active').forEach(row =>{
                addListenersOnHover(row.getPosition());
            });
        }
    }


    useEffect(() => {
        let options = {
            layout: "fitColumns",      //fit columns to width of table
            responsiveLayout: true,  //hide columns that dont fit on the table
            tooltips: false,            //show tool tips on cells
            addRowPos: "top",          //when adding a new row, add it to the top of the table
            history: true,             //allow undo and redo actions on the table
            pagination: false, //"local",       //paginate the data
            movableColumns: false,     //allow column order to be changed
            selectable: false,
            movableRows: false,
            columnDefaults:{
                resizable:false, //maximum column width of 300px for all columns
            },
            autoResize: true,
            resizableRows: false,       //allow row order to be changed
            virtualDom: false,
            placeholder: lang.no_data_available,
            height: "100%", //($(window).height() - $("#To_Scroll_List").offset().top-20)+"px",
            width: "100%",
            // renderComplete: onTabulatorRenderComplete,
            tooltips: function (column) {
                if ([MANAGE_STACKS.FIELDS.CREATED_DATE, MANAGE_STACKS.FIELDS.MODIFIED_DATE].includes(column._cell.column.field)) {
                    return ""
                } else {
                    return column._cell.value;
                }
            }

        }
        TableRef.current = new TabulatorFull("#stacksTable", options)
        if(TableRef.current && columns && data){
            TableRef.current.setColumns(getTabulatorColumns(columns))
            TableRef.current.setData(data);
            TableRef.current.setSort(MANAGE_STACKS.FIELDS.VISIBILITY,"asc");
        }
        TableRef.current.on("renderComplete", function(){
            if (TableRef.current.getRows().length > 0) {
                onTabulatorRenderComplete();
                removeWrapperNodeAndKeepChildren('.tabulator-header-contents');
            }
        });
    },[]) 

    useEffect(() => {
        document.removeEventListener('mouseout', addListenersOnHover());
        document.removeEventListener('mouseover', addListenersOnHover());
    },[data]);

		return(
            <div className="stacks-table"> 
                <div id="stacksTable"  className="tabulator" forwardref="stacksTable"/>
            </div>
            
		);
})

export default StacksTabulator;