import { useEffect, useState } from "react";
import "./periodsContainer.css";
import { DialogActions } from "@mui/material";

import {
  ReformatDate,
  formatDateMMDDYYY,
  getTranslationFile,
} from "../../class/utils.js";
import {
  BUTTON_TYPE,
  BUTTON_VARIANT,
  DIALOG_SIZE,
  SIZES,
} from "../../class/constants.js";
import Button from "../../newComponents/Button.js";
import Input from "../../newComponents/Input.js";
import Modal from "../../newComponents/Modal.js";
const lang = getTranslationFile();

const PeriodsContainer = ({
  currPeriod,
  previousPeriod,
  startDate,
  endDate,
  isEndDateEditable,
  onSaveDate,
  setChangesSaved,
  openChangesNotSaved,
  setOpenChangesNotSaved,
  customStepperRef,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [endDateVal, setEndDateVal] = useState();
  const [editMode, setEditMode] = useState(false); // when the period is editable, edit mode is used to see if the edit btn is clicked or not

  const dialogBody = () => {
    return (
      <div className="p-text">
        {lang.monthly_build.intro.why_cant_change_enddate_description}
      </div>
    );
  };

  const dialogActions = () => {
    return (
      <Button
        label={lang.modal.buttons.ok}
        variant={BUTTON_VARIANT.PRIMARY}
        size={SIZES.DEFAULT}
        type={BUTTON_TYPE.DEFAULT}
        onBtnClick={() => setOpenDialog(false)}
      />
    );
  };

  const unsavedChangesDialogBody = () => {
    return (
      <div className="p-text">
        {lang.monthly_build.intro.do_you_want_save_changes
          .replace("[start_date]", startDate)
          .replace(
            "[end_date]",
            formatDateMMDDYYY(endDateVal ? endDateVal : endDate)
          )}
      </div>
    );
  };

  const unsavedChangesDialogActions = () => {
    return (
      <>
        <Button
          label={lang.monthly_build.intro.discard_changes}
          variant={BUTTON_VARIANT.TERTIARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => {
            setOpenChangesNotSaved(false);
            setEditMode(false);
            setChangesSaved(true);
          }}
        />
        <Button
          label={lang.monthly_build.intro.save_proceed_btn}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => {
            setChangesSaved(true);
            onSaveDate(endDateVal ? endDateVal : endDate);
            setEditMode(false);
            setOpenChangesNotSaved(false);
            customStepperRef?.current?.nextBtnClick(true);
          }}
        />
      </>
    );
  };

  const editBtnClick = () => {
    setEditMode(true);
    setChangesSaved(false);
  };
  const saveBtnClick = () => {
    setEditMode(false);
    setChangesSaved(true);
    if (onSaveDate) {
      onSaveDate(endDateVal || endDate); // if the user didn't change the date and clicked on save, endDateVal will be undefined
    }
  };

  const saveTempDate = (e) => {
    setEndDateVal(e.currentTarget.value);
  };

  //////// These 3 functions prevents the user from typing the date manually
  const handleKeyDown = (event) => {
    event.preventDefault();
  };

  const handleWheel = (event) => {
    event.preventDefault();
  };

  const handleArrowKeys = (event) => {
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault();
    }
  };

  return (
    <div
      className="large-padding-bottom"
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <span className="h2-text small-padding-bottom">
        {lang.monthly_build.intro.period_setup.replace("[period]", currPeriod)}
      </span>
      <span className="p-text medium-padding-bottom">
        {lang.monthly_build.intro.last_built_period.replace(
          "[period]",
          previousPeriod
        )}
      </span>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span className="p-text xsmall-padding-bottom">
            {lang.monthly_build.intro.start_date}
          </span>
          <span className="h4-text">{startDate}</span>
        </div>
        <span className="horizontal-divider"></span>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span className="p-text xsmall-padding-bottom">
            {lang.monthly_build.intro.end_date}
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {isEndDateEditable && editMode ? (
              <Input
                id="end-date-input"
                className="h4-text edit-text-field"
                defaultValue={ReformatDate(endDate, true)}
                type="date"
                min={ReformatDate(startDate, true)}
                onChange={saveTempDate}
                onKeyDown={handleKeyDown}
                onWheel={handleWheel}
                onKeyUp={handleArrowKeys}
              />
            ) : (
              <span id="end-date" className="h4-text">
                {endDate}
              </span>
            )}
            {isEndDateEditable ? (
              !editMode ? (
                <Button
                  variant={BUTTON_VARIANT.SECONDARY}
                  size={SIZES.ICON}
                  type={BUTTON_TYPE.DEFAULT}
                  onBtnClick={editBtnClick}
                  leftIcon={
                    <i className="fal fa-edit fa-lg" aria-hidden="true" />
                  }
                />
              ) : (
                <div style={{ display: "flex" }}>
                  <Button
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.ICON}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={saveBtnClick}
                    leftIcon={
                      <i className="fal fa-save fa-lg" aria-hidden="true"></i>
                    }
                  />
                  <Button
                    label={lang.modal.buttons.cancel}
                    variant={BUTTON_VARIANT.TERTIARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={() => {
                      setEditMode(false);
                      setChangesSaved(true);
                    }}
                  />
                </div>
              )
            ) : (
              <span
                id={"cant-change-period-button"}
                className="link-text"
                style={{ marginLeft: "8px", cursor: "pointer" }}
                onClick={() => setOpenDialog(true)}
              >
                {lang.monthly_build.intro.why_cant_change_this}
              </span>
            )}
          </div>
        </div>
      </div>

      <Modal
        id={"why-cant-change-period-dialog"}
        title={lang.monthly_build.intro.why_cant_change_enddate}
        openDialog={openDialog}
        bodyContent={dialogBody}
        dialogActions={dialogActions}
        closeClick={() => setOpenDialog(false)}
        size={DIALOG_SIZE.MEDIUM}
      />
      <Modal
        id={"period-changes-not-saved-dialog"}
        title={lang.monthly_build.intro.changes_were_not_saved}
        openDialog={openChangesNotSaved}
        bodyContent={unsavedChangesDialogBody}
        dialogActions={unsavedChangesDialogActions}
        closeClick={() => setOpenChangesNotSaved(false)}
        size={DIALOG_SIZE.MEDIUM}
      />
    </div>
  );
};

export default PeriodsContainer;
