import React, { Component } from 'react';
import Popup from 'react-popup';
import { TabulatorFull  } from "tabulator-tables"; // for full package
import { copyObjectValues } from '../class/utils.js';
import { cleanUpTabulatorColumns, goToReport, toggleLoader } from '../class/common.js';
import { MANAGE_REPORT, FormatTypes, ROW_STATUS, CUSTOM_REPORT, SECTION, BUTTON_VARIANT, SIZES, BUTTON_TYPE, DIALOG_SIZE, TABULATOR_BUTTON, MENU_ITEM } from '../class/constants';
import ReportMapping from "./manageReports/ReportMapping.js";
import { lang } from '../language/messages_en';
import { convertPxToViewport, convertViewportToPx } from '../class/formatting';

import { setLocalStorageValueByParameter } from '../class/common.js';
import Button from '../newComponents/Button.js';
import { getTableButton, getTableIcon } from '../newComponents/tabulatorComponents.js';
import Modal from '../newComponents/Modal.js';
import { linearizeHierarchy } from '../class/array.js';
import { logout } from '../templateLayout/api/api.js';
import { getReportURLWithoutReportName } from '../class/acl.js';
import { FETCHAPI_PARAMS, FETCH_METHOD, fetchAPI } from '../class/networkUtils.js';

const $ = require('jquery');

const baseUrl = process.env.REACT_APP_BASE_URL;
const path = "/Admin";

const newRowTemplate = {
    [MANAGE_REPORT.FIELDS.NAME]: "",
    [MANAGE_REPORT.FIELDS.URL]: "",
    [MANAGE_REPORT.FIELDS.DESCRIPTION]: "",
    [MANAGE_REPORT.FIELDS.CREATION_DATE]: "",
    [MANAGE_REPORT.FIELDS.CLICKS]: "",
    [MANAGE_REPORT.FIELDS.ID]: "",
    [MANAGE_REPORT.FIELDS.SECTION_ID]: -1,
    [MANAGE_REPORT.FIELDS.ROW_STATUS]: ROW_STATUS.VALUES.NEW,
}
const SCENARIO_COLUMNS = [
    {
        field: MANAGE_REPORT.FIELDS.ADD_ROW,
        width: '7%',
        headerSort: false,
    },
    {
        field: MANAGE_REPORT.FIELDS.NAME,
        title: MANAGE_REPORT.TITLES.NAME,
        width: '15%',
        format_type: FormatTypes.TEXT,
        sorter: "string",
        headerSort: false,
        sorterParams: { alignEmptyValues: "bottom" },
    },
    {
        field: MANAGE_REPORT.FIELDS.URL,
        title: MANAGE_REPORT.TITLES.URL,
        width: '30%',
        format_type: FormatTypes.TEXT,
        sorter: "string",
        headerSort: false,
        sorterParams: { alignEmptyValues: "bottom" },
    },
    {
        field: MANAGE_REPORT.FIELDS.CREATION_DATE,
        title: MANAGE_REPORT.TITLES.CREATION_DATE,
        width: '20%',
        headerSort: false,
        headerFilter: false,
        dontFilter: true
    },
    {
        field: MANAGE_REPORT.FIELDS.CLICKS,
        title: MANAGE_REPORT.TITLES.CLICKS,
        paddingTop: convertPxToViewport(10),
        minWidth: '5%',
        formatter: FormatTypes.NUMERIC,
        sorter: "numeric",
        headerSort: false,
        sorterParams: { alignEmptyValues: "bottom" },
    },
    {
        field: MANAGE_REPORT.FIELDS.ROW_STATUS,
        title: MANAGE_REPORT.TITLES.ROW_STATUS,
        width: '5%',
        formatter: "status",
        sorterParams: { alignEmptyValues: "bottom" },
        visible: false
    },
    {
        field: MANAGE_REPORT.FIELDS.ID,
        title: MANAGE_REPORT.FIELDS.ID,
        width: '5%',
        visible: false
    },
    {
        field: MANAGE_REPORT.FIELDS.SECTION_ID,
        title: MANAGE_REPORT.FIELDS.SECTION_ID,
        width: '5%',
        visible: false
    },
    {
        field: MANAGE_REPORT.FIELDS.DELETE,
        width: '10%',
        headerSort: false,
    },
];

/**
 *
 * Manage Reports/Menu
 * @author [Sarah Farjallah]
 */

class ManageReports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scenariosData: []
        };
        this.getClientReports = this.getClientReports.bind(this);
        this.getColumnFormatter = this.getColumnFormatter.bind(this);
        this.addNewRowTitleFormatter = this.addNewRowTitleFormatter.bind(this);
        this.addTooltipTitleFormatter = this.addTooltipTitleFormatter.bind(this);
        this.showFormula = this.showFormula.bind(this);
        this.hideFormula = this.hideFormula.bind(this);
        this.deleteReport = this.deleteReport.bind(this);
        // this.goToReport = goToReport.bind(this);

    }


    addNewRowTitleFormatter(cell, params) {
        let btn = getTableButton(
            "Add report",
            ["uk-text-normal"],
            ["uk-button-icon", "transparent-bg", "dark-bg"],
            [
                "far",
                "fa-plus-circle",
                "fa-lg",
                "add-manage-scenarios-plus-top",
                "uk-margin-small-right"
            ],
            "left",
            "Add new report", 
            "",
            TABULATOR_BUTTON.BUTTON
        );

        btn.onclick = () => params.funkName();
        return btn;
    }

    getCustomReport(reportName) {
        let _this = this;
        let reportingMenuLinks = _this.props.userAllowedMenuLinks.filter(f => f.menu_item_category === MENU_ITEM.FIELDS.REPORTING.toUpperCase());
        reportingMenuLinks = linearizeHierarchy(reportingMenuLinks, "children");
        let selectedLink = reportingMenuLinks.filter(f => f.menu_item_machine_name.toLowerCase() === reportName.toLowerCase() && f.category === CUSTOM_REPORT)[0];
        
        if (!selectedLink) {
            logout();
            return;
        }
        let url = "";
        if (selectedLink.is_tableau) {
            let selectedLinkURL = getReportURLWithoutReportName(reportingMenuLinks, selectedLink);
            url = selectedLinkURL + "/" + selectedLink.menu_item_machine_name
        } else {
            window.open(selectedLink.url, "_blank");
            return; 
        }
        return url;
    }

    getColumnFormatter(colField) {
        var columnFormatter = "";
        var obj = this;
        switch (colField) {
            case MANAGE_REPORT.FIELDS.ADD_ROW:
                columnFormatter = function (cell) {
                    var mapBtn = getTableIcon(["fal", "fa-sliders-h", "fa-lg"])
                    mapBtn.title = "Map";
                    mapBtn.onclick = () => {
                        obj.showFormula(cell);
                    };
                    var p = document.createElement("p");
                    p.appendChild(mapBtn);
                    return p;
                }
                break;
            case MANAGE_REPORT.FIELDS.DELETE:
                columnFormatter = function (cell) {
                    var mapBtn = getTableIcon(["fal", "fa-trash-alt", "fa-lg"])
                    mapBtn.title = "Delete report";
                    mapBtn.onclick = () => {
                        obj.setState({
                            section: cell.getRow().getData()[MANAGE_REPORT.FIELDS.SECTION_ID],
                            report: cell.getRow().getData()[MANAGE_REPORT.FIELDS.ID],
                            openConfirmDeleteDialog: true
                        }
                            // ,function(){

                            //     // window._profitIsleOpenModal('confirmDelete');
                            // }
                        )
                    };
                    var p = document.createElement("p");
                    p.appendChild(mapBtn);
                    return p;
                }
                break;

            case MANAGE_REPORT.FIELDS.URL:
                columnFormatter = function (cell) {
                    let row = cell.getRow().getData();
                    let href = window.location.origin + "/" + CUSTOM_REPORT + "/" + row[SECTION.NAME].toLowerCase().replaceAll(" ", "_") + "/" + row[SECTION.ID];
                    var a = document.createElement("a");
                    var link = document.createTextNode(row[MANAGE_REPORT.FIELDS.URL]);
                    a.appendChild(link);
                    a.style.cursor = "pointer";
                    a.setAttribute("url", row[MANAGE_REPORT.FIELDS.URL]);
                    a.setAttribute("data_href", href);
                    a.onclick = (e) => {
                        let reportUrl = obj.getCustomReport(row[SECTION.NAME].toLowerCase().replaceAll(" ", "_"));
                        if (reportUrl) {
                            obj.props.goToReport(undefined, undefined, undefined, reportUrl);
                        }
                    };
                    var p = document.createElement("p");
                    p.appendChild(a);
                    return p;
                }
                break;
            default:
                columnFormatter = function (cell, formatterParams) {
                    return cell.getValue();
                }
                break;
        }

        return columnFormatter;
    }

    showFormula(cell) {
        // This is to give the create columns table 50% width when glaccountmodal appears
        $("#mapping-parent").removeClass("w100").addClass("w50");
        const _this = this;
        function showModal() {
            let position = cell ? cell.getRow().getPosition() : -1;
            let rowData = cell ? cell.getRow().getData() : copyObjectValues(newRowTemplate);
            _this.nextMustBeDisabled = true;  //disable when formula is open
            _this.setState({
                showFormula: true,
                rowPosition: position,
                operatedRow: rowData,
            }, function () {
                // $(".calculated-columns-table").animate({width: "65%"}, 1000, "linear");
                $("#mapping-parent").removeClass("w100").addClass("w50");
                $('#report_mapping').show('slide', { direction: 'right' }, 500);
            });
        }

        //hide the Modal first and then reshow it in case it was opened for another vector
        if ($('#report_mapping').is(":visible")) {
            this.hideFormula(showModal);
        } else {
            showModal();
        }
    }

    hideFormula(fromCancel) {
        // This is to give the create columns table full width when glaccountmodal is hid
        $("#mapping-parent").addClass("w100").removeClass("w50");
        var _this = this;
        $('#report_mapping').hide('slide', { direction: 'right' }, 500, function () {
            _this.nextMustBeDisabled = false;
            _this.setState({
                showFormula: false,
                operatedRow: null
            }, function () {
                if (!fromCancel) {
                    this.getClientReports();
                }
            });
        });
    }

    componentDidMount() {
        this.getClientReports();

        var obj = this;
        var options = {
            layout: "fitColumns",      //fit columns to width of table
            responsiveLayout: false,   //hide columns that dont fit on the table
            tooltips: true,            //show tool tips on cells
            addRowPos: "top",          //when adding a new row, add it to the top of the table
            history: true,             //allow undo and redo actions on the table
            pagination: false,         //paginate the data
            movableColumns: false,     //allow column order to be changed
            selectable: false,
            movableRows: false,
            resizableColumns: false,
            autoResize: true,
            resizableRows: false,       //allow row order to be changed
            virtualDomBuffer: 800,
            placeholder: "",
            width: "100%",
            height: "100%",
            reactiveData: true,      //tabulator listens to any change in the data array and updates the table
            // renderComplete: this.onTabulatorRenderComplete,
            tooltips: function (column) {
                return column._cell.value;
            },
        }
        this.tabulator = new TabulatorFull(this.refs.mainTable, options);
        var tableColumns = cleanUpTabulatorColumns(copyObjectValues(SCENARIO_COLUMNS), null, this.refreshFilterDivs, this.tabulator, { id: "manageReport" });
        tableColumns.forEach(col => {
            if (col.field === MANAGE_REPORT.FIELDS.ADD_ROW) {
                col.titleFormatter = obj.addNewRowTitleFormatter;
                col.titleFormatterParams = { funkName: obj.showFormula, obj: this.state };
            } else if (col.field === MANAGE_REPORT.FIELDS.DELETE) {
                col.maxWidth = convertViewportToPx(170)
            }
            col.formatter = obj.getColumnFormatter(col.field);
        });
        this.tabulator.on("tableBuilt", function(){
            obj.onTabulatorRenderComplete();
            obj.tabulator.setColumns(tableColumns);
        });
    }

    addTooltipTitleFormatter(cell) {
        var div = document.createElement("div");

        var p = document.createElement("p");
        p.innerHTML = cell.getValue();
        var el = document.createElement("i");
        el.classList.add("far", "fa-info-circle", "uk-margin-xsmall-left", "uk-cursor-pointer");
        el.setAttribute("uk-tooltip", cell.getValue());

        p.appendChild(el);
        div.appendChild(p);

        return div;
    }

    getClientReports() {
        let _this = this;
        var query = {
            action: "getClientReports"
        }
        let onThenCallback = (data) => {
            _this.setState({
                reports: data.reports,
                accessGroups: data.access_groups
            }, function () {
                _this.tabulator.replaceData(data.reports);
            });
          };
          let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getClientReports",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: path,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]: lang.observability.output.manage_custom_reports.screen_name,
            [FETCHAPI_PARAMS.requestDescription]: lang.observability.output.manage_custom_reports.request_description.client_reports,
          };
          fetchAPI(fetchOptions);
    }


    deleteReport() {
        // toggleLoader(true, 'deleteReport');
        var query = {
            action: "deleteReport",
            name: this.state.section,
            report: this.state.report,
        }
        let onThenCallback = (data) => {
            if (data.result === 'SUCCESS') {
                window.location.reload();
            }
          };
          let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "deleteReport",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: path,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]: lang.observability.output.manage_custom_reports.screen_name,
            [FETCHAPI_PARAMS.requestDescription]: lang.observability.output.manage_custom_reports.request_description.delete,
          };
          fetchAPI(fetchOptions);
    }


    componentDidUpdate() {
        var data = this.state.reports;
        this.tabulator.replaceData(data);
    }

    onTabulatorRenderComplete() {

        if (!this.tabulator)
            return;
    }

    confirmDeleteDialogActions = () => {
        return (
            <>
                <Button
                    label={"Confirm"}
                    variant={BUTTON_VARIANT.PRIMARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={this.deleteReport}
                />
                <Button
                    label={lang.modal.buttons.cancel}
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={this.closeConfirmDeleteDialog}
                />
            </>
        )
    }

    confirmDeleteDialogContent = () => {
        return <h4>Are you sure you want to delete this report?</h4>

    }

    closeConfirmDeleteDialog = () => {
        let _this = this;
        _this.setState({
            openConfirmDeleteDialog: false
        })
    }


    render() {
        return (
            <div style={{height:"100%"}}>
                <Popup />
                <div id="manageReport" className="uk-display-inline-flex uk-width-1-1" style={{height:"100%"}}>
                    <div id="mapping-parent" className="w100">
                        <div className="uk-border uk-background-default">
                            <div className="mapping-header">
                                <span className="uk-text-bold uk-text-xmedium ">{lang.manage_reports.manage_reports_table_title}</span>
                                <i className="fs-12 fal fa-info-circle uk-margin-xsmall-left uk-cursor-pointer" uk-tooltip={"This table includes all the reports created."} />
                            </div>
                            <div className="manageReport">
                                <div id="manageReport" ref="mainTable" />
                            </div>
                        </div>
                    </div>
                    <Modal
                        id={"confirm-delete-manage-reports-dialog"}
                        dialogActions={this.confirmDeleteDialogActions}
                        bodyContent={this.confirmDeleteDialogContent}
                        openDialog={this.state.openConfirmDeleteDialog}
                        closeClick={this.closeConfirmDeleteDialog}
                        size={DIALOG_SIZE.SMALL}
                    />
                    {this.state.showFormula ?
                        <div id="report_mapping" className="GL-accounts-modal uk-margin-remove-bottom" style={{height:"100%"}}>
                            <ReportMapping reportRow={this.state.operatedRow} accessGroups={this.state.accessGroups}
                             hideFormula={this.hideFormula} getClientReports={this.getClientReports} reports={this.state.reports}
                             userAllowedMenuLinks={this.props.userAllowedMenuLinks} refreshUserAllowedMenuLinks={this.props.refreshUserAllowedMenuLinks}
                             reportDesrcriptionSizeLimit={this.props.reportDesrcriptionSizeLimit} />
                        </div>
                        : ""}
                </div>
            </div>
        );
    }
}

export default ManageReports;