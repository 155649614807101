import { Divider, List, ListSubheader } from "@mui/material";
import { useRef } from "react";
import { ReactComponent as SegmentationIcon } from "../styles/images/icons/segmentation-icon.svg";


const SimpleOptionsList = (
  {
    itemsList,
    onClick,
    extraInfo, // to show extra info next to list labe (ie: vector number)
    allowSegmentationIcon,
    ...props
  }
) => {
  let containerRef = useRef({});

  const handleClick = (event, value) => {
    event.stopPropagation();
    if (typeof onClick === "function") {
      onClick(value);
    }
  };

  let zoom = ((window.outerWidth - 10) / window.innerWidth) * 100;
  return (
    <List sx={{ width: "100%", p: 0, maxHeight: zoom > 109 ? "35vh" : "38vh", overflow: "auto" }} ref={containerRef}>
      {itemsList?.map((item) => {
        const labelId = `checkbox-list-label-${item.value}`;
        if (item.isGroupTitle) {
          return (
            <ListSubheader
              key={labelId}
              sx={{
                fontSize: "0.8vw",
                fontWeight: "bold",
                lineHeight: 3,
                backgroundColor: "#F3F3F3",
                fontFamily: "'Open Sans', Arial, sans-serif",
                letterSpacing: "normal",
              }}
            >
              {item.label}
            </ListSubheader>
          );
        }

        return (
          <>
            <div key={labelId} className="uk-display-flex drill-option-container" onClick={(event) => handleClick(event, item)}>
                <span id={labelId} className="drill-option simple-option-list" >
                  <span className="overflow-hidden"> {item.label} </span>
                  {allowSegmentationIcon && item.generated?
                    <SegmentationIcon className="segmentation-icon" />
                  :""}
                </span>
                {extraInfo ? (
                  <div key={labelId} className="extra-info-text uk-float-right col-md-2 uk-margin-small-right">
                    {item[extraInfo] !== item.label ? item[extraInfo] : ""}
                  </div>
                ) : null}
            </div>
            <div className="separator"></div>
          </>
        );
      })}
    </List>
  );
};

export default SimpleOptionsList;