import React, {Component} from 'react';
import { COST_FUNCTIONS_FIELDS, STAGING_SECTIONS, CALCULATED_COLUMNS as CALC_COLS, PS_MAPPING, PROFILE_COLUMN, costtype, DIALOG_SIZE, BUTTON_VARIANT, SIZES, BUTTON_TYPE } from '../../class/constants';
import {getTranslationFile, copyObjectValues, deepCompareObjects, tryParse} from '../../class/utils';
import ControlButtons from '../../sections/stageData/ControlButtons';
import FormulaDrop from '../../sections/stageData/FormulaDrop';
import Popup from 'react-popup';
import Column from './Column';
import { getEmbeddedChild, linearizeHierarchy } from '../../class/array';
import { lang } from '../../language/messages_en';
import Input from '../../newComponents/Input';
import Modal from '../../newComponents/Modal';
import Button from '../../newComponents/Button';

const MESSAGES = getTranslationFile();
const $= require('jquery');
const _pssLine = "pss_line";
const UIKit = require("uikit");

//column attributes
const _columnName = PROFILE_COLUMN.NAME;
const _returnName = PROFILE_COLUMN.RETURN_NAME;
const _isExpandable = PROFILE_COLUMN.IS_EXPANDABLE;
const _isExpanded = PROFILE_COLUMN.IS_EXPANDED;
const _children = PROFILE_COLUMN.CHILDREN;
const _level = PROFILE_COLUMN.LEVEL;
const _isGroup = PROFILE_COLUMN.IS_GROUP;
const _isInSearchResult = PROFILE_COLUMN.IS_IN_SEARCH_RESULT;

const _number = PROFILE_COLUMN.NUMBER;
const _costKey =  PS_MAPPING.FIELDS.COST_KEY;
const _nameInfact = PROFILE_COLUMN.NAME_IN_FACT;
const _pslKey = PROFILE_COLUMN.PSL_KEY;
const _name = PS_MAPPING.FIELDS.NAME;
const _costType = PS_MAPPING.FIELDS.COSTTYPE;
const _returnNameInFact = PS_MAPPING.FIELDS.RETURN_NAME;
const _percentages="percentages";
const _attributes="attributes";
const _averages="averages"; 
const _medians= "medians";
const _category = "category"
const _childrenInFact = "children"


/**
 *
 * Rule Formula in configure screen
 * @author [Bassem Arnaout]
 */

class CalculatedProfitStackLine extends Component {
    constructor(props) {
        super(props);
        let pslColumns = copyObjectValues(this.props.profitStackLineColumns);
        if(pslColumns.length > 0 && !this.props.isPssMapping && !this.props.isStacks) {
            pslColumns[0][_isExpanded] = true;
        }
        this.state = {
            pslFormula: {formula:[]},
            formula_unchanged: {formula:[]},
            profitStackLineColumns: pslColumns
        };

        this.appendToFormula = this.appendToFormula.bind(this);
        this.deleteLastFormulaItem = this.deleteLastFormulaItem.bind(this);
        this.resetFormulaChanges = this.resetFormulaChanges.bind(this);
        this.deleteFormula = this.deleteFormula.bind(this);
        this.updateFormula = this.updateFormula.bind(this);
        this.applyFormula = this.applyFormula.bind(this);
        this.toggleColumn = this.toggleColumn.bind(this);
        this.filterColumns = this.filterColumns.bind(this);
    }

    componentDidUpdate(prevProps){
        if(!deepCompareObjects(this.props.profitStackLineColumns, prevProps.profitStackLineColumns)){
            let pslColumns = copyObjectValues(this.props.profitStackLineColumns);
            if (pslColumns.length > 0 && !prevProps.isPssMapping) {
                pslColumns[0][_isExpanded] = true;
                this.setState({
                    profitStackLineColumns: pslColumns
                })
            }
            
        }
        if(JSON.stringify(this.props.line) !== JSON.stringify(prevProps.line)){
            this.setState({
                line:prevProps.line
            })
        }
    }
    
    appendToFormula($node, isNumBtn) {
        if(this.props.isPssMapping){
            this.props.appendToFormula($node, isNumBtn)
        } else {
            if(isNumBtn){
                if ($node.attr("node_value") === undefined) {
                    return;
                }
            }
            let node = $($node.target);
            let value = isNumBtn ? $node.attr("node_value").trim() : node.attr("node_index");
            let formula = isNumBtn ? $node.attr("node_value").trim() : node.attr("node_formula");
            let returnName = isNumBtn ? $node.attr("node_value").trim() : node.attr("node_return_name");
            let display_name = isNumBtn ? $node.attr("node_value").trim() : node.attr("node_"+COST_FUNCTIONS_FIELDS.NAME);
            let type = isNumBtn ? $node.attr("node_type").trim() :  node.attr("node_"+CALC_COLS.FIELDS.COLUMN_FIELDS.TYPE);
            let _class = isNumBtn ? $node.attr("node_class") :"";
            
            let result = copyObjectValues(this.state.pslFormula.formula);
            if(typeof result === "string"){
                result = tryParse(result);
            }
            result.push({
               value: value,
               display_name:display_name,
               formula: formula,
               type: type,
               class:_class,
               returnName:returnName
            });
    
            this.setState({
                pslFormula: {formula: result}
            });
        }
       
    }

    deleteLastFormulaItem() {
        if(this.props.isPssMapping){
            this.props.deleteLastFormulaItem();
        } else {
            let formula = copyObjectValues(this.state.pslFormula);
            if(typeof formula.formula  === "string") {
                formula.formula = tryParse(formula.formula);
            }
            formula.formula.pop();
            this.setState({
                pslFormula: formula
            });
        }
       
    }

    resetFormulaChanges() {
        if(this.props.isStacks && !(this.props.line && this.props.line.formula)){
            this.setState({
                pslFormula: this.state.formula_unchanged
            });
        }else if(this.props.isPssMapping || this.props.isStacks){
            this.props.resetFormulaChanges();
        } else {
            this.setState({
                pslFormula: this.state.formula_unchanged
            });
        }
       
    }

    deleteFormula() {
        if(this.props.isPssMapping){
            this.props.deleteFormula();
        } else {
            this.setState({
                pslFormula: {formula: []}
            });
        }
       
    }

    updateFormula(formula) {
        this.setState({
            pslFormula: {formula: formula}
        });
    }

    setDraggable() {
        var draggableOptions = {
            scroll: false,
            cursor: "grab",
            helper: 'clone',
            appendTo: 'body',
            containment: 'window',
            revert: "invalid",
        };

        $(".draggable-col-index, .draggable-numpd-btn").draggable(draggableOptions);
    }

    componentDidMount() {
        if(this.props.isStacks && this.props.line){
            this.setState({
                pslFormula: this.props.line,
            })
        }
        this.setDraggable();
    }


    applyFormula() {
        
        if(!this.formulaDRef.validateFormulaResult(this.state.pslFormula.formula)) {
          this.setInfoDialogOpen(true, MESSAGES.formula.invalid_formula)
            return;
        }else{
            this.props.saveFormula(this.state.pslFormula);
        }
    }

    resetFormula(){
        if(this.props.isPssMapping){
            this.props.resetFormula();
        } else {
            this.state.pslFormula.formula = copyObjectValues(this.props.pslFormula.formula);
            this.setState(this.state);
        }
       
    }

    toggleColumn(keyValue, key, forceValue=undefined) {
        let data = copyObjectValues(this.state.profitStackLineColumns);
        let children = this.props.isPssMapping || this.props.isStacks ? _childrenInFact : _children;
        let returnName = this.props.isPssMapping? _returnNameInFact : _returnName;
        let column = getEmbeddedChild(data, children, (this.props.isStacks? _costKey : returnName), keyValue);// if from stacks, search by costkeys
        if(!column) {
            return;     //if column not found, do not continue
        }
        column[key] = forceValue !== undefined ? forceValue : !column[key];
        
        this.setState({
            profitStackLineColumns: data
        });
    }

    resetFilterColumns = () => {
      const _this = this;
      let data = copyObjectValues(_this.state.profitStackLineColumns);
      data = _this.updateSearchResult(data, "")

      _this.setState({
          profitStackLineColumns: data,
          isSearching: false,
          searchValue: ""
      });

    }

    filterColumns(e) {
        const _this = this;
        clearTimeout(this.searchTimeout);
        let inputValue = e.target.value;

        this.searchTimeout = setTimeout(()=>{
            let data = copyObjectValues(_this.state.profitStackLineColumns);
            data = _this.updateSearchResult(data, inputValue);

            _this.setState({
                profitStackLineColumns: data,
                isSearching: inputValue !== "",
                searchValue: inputValue
            });
        }, 300);
    }

    updateSearchResult(data, inputValue) {
        for(let index in data) {
            let element = data[index];
            let name = this.props.isPssMapping || this.props.isStacks ? _name :_columnName;
            let children = this.props.isPssMapping || this.props.isStacks ? _childrenInFact : _children;
            element[_isInSearchResult] = element[name].toLowerCase().includes(inputValue.toLowerCase());

            if(element[children]) {
                this.updateSearchResult(element[children], inputValue);
            }
        }

        return data;
    }

    setInfoDialogOpen = (isOpen, infoMsg) => {
      let _this = this;
      _this.setState({
        openInfoDialog: isOpen,
        infoMsg: infoMsg || this.state.infoMsg
      })
    }

    openInfoDialogActions = () => {
      return (
        <Button
          label={lang.modal.buttons.ok}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => this.setInfoDialogOpen(false)}
        />
      )
    }
    renderData(data, displayAsParent=true, level=0, type, lineType) {
        let renderedArr = [];
        let returnName = this.props.isPssMapping || this.props.isStacks?_returnNameInFact:_returnName;
        let name = this.props.isPssMapping || this.props.isStacks?_name:_columnName;
        let children = this.props.isPssMapping || this.props.isStacks?_childrenInFact:_children
        let costKey = this.props.isPssMapping || this.props.isStacks?_costKey :_pslKey;
    
      
        if(!this.props.isPssMapping){
            data = data.filter(e=>![_percentages,_medians,_attributes,_averages].includes(e[_category]));
        }
        
        for(let column in data) {
            column = data[column];
            let isGroup = !this.props.isPssMapping ? column[_isGroup] : false;
            var number = column[_number];
            if(column[_isInSearchResult] || !displayAsParent) {
                renderedArr.push(
                    <Column key={"profile-column-"+column[returnName]+"_"+displayAsParent+"_"+level}
                        value={this.props.isStacks? column[_costKey]:column[returnName]}  name={column[name]}
                        isCheckable={!isGroup} displayAsParent={displayAsParent}
                        isExpandable={column[children] && column[children].length >0}
                        isExpanded={column[_isExpanded]} toggleColumn={this.toggleColumn}
                        level={column[_level]} 
                        searchValue={this.state.searchValue} isInSearchResult={column[_isInSearchResult]}
                        node_formula = {column[_nameInfact]}
                        node_return_name={column[_returnName] || column.returnName} 
                        node_name={column[_columnName]} 
                        node_costkey={column[costKey]?column[costKey].replace("Insert",""):""}
                        node_index= {this.props.isPssMapping || this.props.isStacks ? column[costKey].replace("Insert","") :number}
                        appendToFormula = {this.appendToFormula}
                    />
                );
            }
            if(column[children] && column[_isExpanded] && (column[_isInSearchResult] || !displayAsParent)) {
                renderedArr = renderedArr.concat(this.renderData(column[children], false, level+1));   //increase the level to indicate a level deeper in the tree
            }
            if(column[children] &&  this.state.isSearching  && (displayAsParent || level === 0)) {
                renderedArr = renderedArr.concat(this.renderData(column[children], true, level));
            }
        }

        return renderedArr;
    }
  render() {
    var list = this.renderData(this.state.profitStackLineColumns);
    var formula = this.props.isPssMapping ? this.props.line[PS_MAPPING.FIELDS.MAP_FORMULA] : this.state.pslFormula.formula;
    let linearisedList = linearizeHierarchy(this.state.profitStackLineColumns, _children);
    return (
      <React.Fragment>
        <div className="manage-columns-control-button uk-flex">
          <div className="calculated-profit-stack-line-columns">
            <div className="fs-14 uk-text-bold uk-margin-xsmall-bottom">{this.props.isPssMapping || this.props.isStacks ? MESSAGES.ps_mapping.left_section.title : "Columns"}</div>
            <Input id="psl-search" type="text" className="" isSearch={true} onChange={this.filterColumns} placeholder={this.props.isPssMapping || this.props.isStacks ? MESSAGES.pss_mapping_search_placeholder : MESSAGES.manage_columns.text.search_placeholder} />
            <div className="psl-overflow-container">{list}</div>
          </div>
          <ControlButtons appendToFormula={($node) => this.appendToFormula($node, true)} numpadOnly={false} report={STAGING_SECTIONS.PROFIT_STACK_MAPPING} />
        </div>
        <FormulaDrop ref={r => this.formulaDRef = r} appendToFormula={this.appendToFormula}
          formula={formula} isInnerComp={false} setFormulaIndex={this.setFormulaIndex} linearisedProfitStackLineColumns={linearisedList}
          deleteLastFormulaItem={this.deleteLastFormulaItem} resetFormulaChanges={this.resetFormulaChanges}
          deleteFormula={this.deleteFormula} hasConditions={false} emptyFormulaText={MESSAGES.formula.text_cpsl}
          updateFormulaInParent={this.updateFormula} isCalculatedPSL={true} isPssMapping={this.props.isPssMapping} isStacks={this.props.isStacks}/>
        <Modal
          id={"info-dialog"}
          openDialog={this.state.openInfoDialog}
          bodyContent={() => <h4>{this.state.infoMsg}</h4>}
          dialogActions={this.openInfoDialogActions}
          closeClick={() => this.setInfoDialogOpen(false)}
          size={DIALOG_SIZE.MEDIUM}
        />
        <Modal
          id={"info-dialog"}
          openDialog={this.state.openInfoDialog}
          bodyContent={() => <h4>{this.state.infoMsg}</h4>}
          dialogActions={this.openInfoDialogActions}
          closeClick={() => this.setInfoDialogOpen(false)}
          size={DIALOG_SIZE.MEDIUM}
        />
      </React.Fragment>
    );
  }
}

export default CalculatedProfitStackLine;