const defaultImagesSignedUrls = [];

function imagesSignedUrls(state=defaultImagesSignedUrls, action) { //reducer
    switch (action.type) {
        case "UPDATE_IMAGES_SIGNED_URLS":
            return {...state, imagesSignedUrls:action.imagesSignedUrls}
        default:
        return state;
    }
}

  
export {imagesSignedUrls};