function getVectorsBasedOnEntityCount(entityCount, vectors, objectNone) {
    let filteredVectors = vectors.filter(f => f?.entityCount < entityCount);
    filteredVectors.unshift(objectNone);
    return filteredVectors;
}

function getVectorsGeneratedUnderSpecificGroup(vectorGroup, vectors, objectNone) {
    let filteredVectors = vectors.filter(f => f?.vector_group === vectorGroup && f.generated);
    filteredVectors.unshift(objectNone);
    return filteredVectors;
}
export {getVectorsBasedOnEntityCount, getVectorsGeneratedUnderSpecificGroup};

