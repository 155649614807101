import Input from "../../newComponents/Input";

function PasswordInputField({handleValidation, handlePasswordChange, passwordValue, passwordError}){
    return (
        <>
    <div className="form-group my-3">
        <Input id="expiryPassword" type="password" value={passwordValue}  onChange={handlePasswordChange} onKeyUp={handleValidation} name="password" placeholder="Password" /*className="form-control password-input-width"*/ />
        <p className="text-danger">{passwordError}</p>
   </div>
          
        </>
    )
}

export default PasswordInputField;