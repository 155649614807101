import { ReactComponent as RequiredFileIcon } from "../../styles/images/icons/required_file.svg";
import { ReactComponent as ErrorIcon } from "../../styles/images/icons/error.svg";
import { ReactComponent as SuccededIcon } from "../../styles/images/icons/succeded.svg";
import { ReactComponent as AutomatedFile } from "../../styles/images/icons/automated.svg";
import { lang } from "../../language/messages_en";
import "./laneCard.css";
import Button from "../../newComponents/Button";
import { BUTTON_TYPE, BUTTON_VARIANT, SIZES, UPLOAD_SECTIONS } from "../../class/constants";

const LaneCard = ({
  section,
  namingTemplate,
  subSection,
  isRequired,
  status,
  laneId,
  isRejected,
  response,
  proceedWarning,
  isHidden,
  toggleErrorsDialog,
  toggleIgnoreDialog,
  isAutomated,
  automationFailure,
  hasPreviousLinks,
  hasCurrentLinks,
  hasPreviousCrosswalk,
  rawFileId,
  isCrosswalkIgnored,
  rawFileStatus
}) => {
  let hasErrors = [lang.monthly_build.upload_files.file_status.failed_upload, lang.monthly_build.upload_files.file_status.failed_decrypt].includes(status) || [lang.monthly_build.upload_files.file_status.failed_upload].includes(rawFileStatus) || isRejected;
  let hasSucceeded = status === lang.monthly_build.upload_files.file_status.passed  || (rawFileStatus === lang.monthly_build.upload_files.file_status.passed && hasCurrentLinks);
  let isFilePending = laneId === lang.monthly_build.upload_files.status.pending.value;
  let validations = response ? JSON.parse(response).validation : [];
  let isNotUploaded = laneId === lang.monthly_build.upload_files.status.not_uploaded.value;
  let succededWithErrors = validations?.find(e => e.validation_status === "warning" && section.toLowerCase()!== UPLOAD_SECTIONS.FIELDS.CROSSWALK);
  let isCrossWalkHighlighted = section.toLowerCase() === UPLOAD_SECTIONS.FIELDS.CROSSWALK && hasPreviousCrosswalk && hasPreviousLinks && !isCrosswalkIgnored && isNotUploaded;
  let isProcessed = laneId === lang.monthly_build.upload_files.status.processed.value;
  let failedRedefine = status === "failed_redefine";
  let showErrors = (hasErrors && !isFilePending) || automationFailure || failedRedefine; // showErrors=true, if the file has errors

  return (
    <div key={namingTemplate} className={"lane-card " + (showErrors || proceedWarning || isCrossWalkHighlighted ? "bg-red" : "") + (isHidden ? " hide" : "")}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignSelf: "stretch",
          marginBottom: "0.21vw"
        }}
      >
        <span className={"ds-name-label xsmall-padding-bottom " + (showErrors || proceedWarning ? "error-red-text  " : "")}>{section}</span>
        <div  style={{
          display: "flex",
          flexDirection: "row"
        }}>
            {isAutomated && <AutomatedFile />}
            {isRequired && <RequiredFileIcon className="uk-margin-xsmall-left" />}
        </div>
        
      </div>
      <span className={"file-name-label " + (!hasErrors ? "small-padding-bottom" : "")}>
        {subSection}
        <span className="file-name-template-label"> / {namingTemplate}</span>
      </span>

      {!isFilePending && hasSucceeded && !succededWithErrors && !hasErrors && !automationFailure && isProcessed && (
        <span className="small-padding-bottom">
          <SuccededIcon />
          <span className="succeed-text">
            {lang.monthly_build.upload_files.succeded_text}
          </span>
        </span>
      )}

      {!isFilePending && !hasErrors && succededWithErrors && !failedRedefine && !automationFailure && (
        <div className="errors-container small-padding-bottom">
          <div>
            <SuccededIcon />
            <span className="succeed-text succeed-with-errors ">
              {lang.monthly_build.upload_files.succeded_with_errors}
            </span>
          </div>
          <Button 
              label={lang.monthly_build.upload_files.see_erros}
              variant={BUTTON_VARIANT.TERTIARY}
              size={SIZES.DEFAULT}
              type={BUTTON_TYPE.DEFAULT}
              className="warning-btn"
              onBtnClick={() => toggleErrorsDialog(namingTemplate)}
          />
        </div>
      )}

      {showErrors && (
        <div className="errors-container">
          <div>
            <ErrorIcon />
            <span className="error-text">
              {isAutomated ? lang.monthly_build.upload_files.errors_in_automated_file : lang.monthly_build.upload_files.errors_in_file}
            </span>
          </div>
          <Button 
              label={lang.monthly_build.upload_files.see_erros}
              variant={BUTTON_VARIANT.TERTIARY}
              size={SIZES.DEFAULT}
              type={BUTTON_TYPE.DEFAULT}
              className="error-btn"
              onBtnClick={() => toggleErrorsDialog(namingTemplate)}
          />
        </div>
      )}
      
      {isCrossWalkHighlighted && (
        <div style={{alignSelf:"stretch"}}>
          <Button 
              label={lang.monthly_build.upload_files.ignore}
              variant={BUTTON_VARIANT.TERTIARY}
              size={SIZES.DEFAULT}
              type={BUTTON_TYPE.DEFAULT}
              className="error-btn"
              style={{color:"black", float:"right"}}
              onBtnClick={() => toggleIgnoreDialog(Number(hasPreviousCrosswalk))}
          />
        </div>
      )}

      {proceedWarning && !showErrors && (
          <div className="small-padding-bottom">
            <ErrorIcon />
            <span className="error-text">
              {isFilePending ? lang.monthly_build.upload_files.pending_process : isAutomated ? lang.monthly_build.upload_files.errors_in_automated_file : lang.monthly_build.upload_files.pending_upload}
            </span>
          </div>
      )}

      {isFilePending && !proceedWarning && (
        <span className="pending-text small-padding-bottom">
          {lang.monthly_build.upload_files.pending_process_sftp}
        </span>
      )}
    </div>
  );
};

export default LaneCard;
