import {ReactComponent as FlagIcon} from "../../styles/images/icons/flag.svg";
import CustomStepper from "../stepper/CustomStepper";
import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {ALL_WIDGETS, API_URL, FISCAL_YEAR, INITIAL_COMPLETED_STEPS, SIDE_PANEL_BUILD, TIE_OUT_PL_GL, INITIAL_COMPLETED_BUILD_SUB_STEPS, IS_BUILD_RUNNING, BUTTON_VARIANT, SIZES, BUTTON_TYPE, DIALOG_SIZE, BUILD_PROGRESS_STEPS} from "../../class/constants";
import Introduction from "./steps/Introduction";
import UploadFiles from "./steps/UploadFiles";

import {convertFirstLetterToUpperCase, copyObjectValues, getTranslationFile, parseBoolean} from "../../class/utils.js";
import {deleteItemFromSession, FETCH_METHOD, fetchAPI, FETCHAPI_PARAMS,} from "../../class/networkUtils";
import {suggestEndDate, suggestNewPeriod} from "../../sections/profitStack/helpers";
import {getPeriodQuarter} from "../../class/date";
import TieOutPLGL from "./steps/tieOutPLGL/TieOutPLGL";
import CostCenters from "./steps/costCenters/CostCenters";
import SetupProfitStacks from "./steps/setupProfitStacks/SetupProfitStacks";
import $ from "jquery";
import BuildStep from "./steps/buildStep/BuildStep";
import NewBanner from "../NewBanner.js";
import {ReactComponent as CalendarsIcon} from "../../styles/images/icons/calendars.svg";
import {ReactComponent as ArrowRotateLeftIcon} from "../../styles/images/icons/arrow-rotate-left.svg";
import Button from "../../newComponents/Button.js";
import Modal from "../../newComponents/Modal.js";
import { updatePeriodsStatus, updatePeriodsStatusState } from "../../actions/scenariosActions.js";


const lang = getTranslationFile();

const MonthlyBuild = (props, ref) => {

  const INTRODUCTION_LABEL = lang.monthly_build.steps.value.introduction;
  const UPLOAD_LABEL = lang.monthly_build.steps.value.upload_files;
  const TIE_OUT_LABEL = lang.monthly_build.steps.value.tie_out_pl_gl;
  const DEFINE_CC_LABEL = lang.monthly_build.steps.value.define_cost_centers;
  const SETUP_PS_LABEL = lang.monthly_build.steps.value.setup_profit_stack;
  const BUILD_LABEL = lang.monthly_build.steps.value.build_scenario;
  const STEP_STATUS = lang.monthly_build.steps.status;
    
    
  const [endDateVal, setEndDateVal] = useState();
  const [currPeriod, setCurrPeriod] = useState("");
  const [previousPeriod, setPreviousPeriod] = useState("");
  const [isEndDateEditable, setIsEndDateEditable] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [bannerError, setBannerError] = useState("");
  const [changesSaved, setChangesSaved] = useState(true);
  const [openChangesNotSaved, setOpenChangesNotSaved] = useState(false);
  const [canProceed, setCanProceed] = useState(true);
  const [forceRebuild, setForceRebuild] = useState(false);
  const [isBuildInitiated, setIsBuildInitiated] = useState(false);
  const [cancelRevert, setCancelRevert] = useState(false);
  const [showRollbackButton, setShowRollbackButton] = useState(false);
  const [isProceedBtnPressed, setIsProceedBtnPressed] = useState(false);
  const [completedSteps, setCompletedSteps] = useState(INITIAL_COMPLETED_STEPS);
  const [tieOutErrorMessage, setTieOutErrorMessage] = useState(lang.monthly_build.tie_out_pl_gl.no_value_error_message);
  const [costCentersErrorMessage, setCostCentersErrorMessage] = useState("");
  const [profitStacksSetupErrorMessage, setProfitStacksSetupErrorMessage] = useState("");
  const [openConfirmCancelevert, setOpenConfirmCancelRevert] = useState(false);
  const [buildSubsteps, setBuildSubsteps] = useState(null);
  //Refresh render here is used for react to render properly as it fails to identify a change in objects passed as props
  const [refreshRender, setRefreshRender] = useState(false);
  const [hideCostCenters, setHideCostCenters] = useState(false);
  const [lastMonthlyBuildStatus,setLastMonthlyBuildStatus] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [toastIsError, setToastIsError] = useState(false);
  const [publishedScenarioId,setPublishedScenarioId] = useState(0);
  const customStepperRef = useRef();
  const uploadFilesRef = useRef();
  const tieOutPlGlRef = useRef();
  const costCentersRef = useRef();
  const setUpProfitStacksRef = useRef();
  const BuildStepRef = useRef();
  const IntroStepRef = useRef();
  const forceRebuildRef = useRef(false); // to set original data from api that comes on request end
  const canCancelRevertRef = useRef(false)

  useEffect(() => {
    resetSteps();
    getMonthlyBuildPeriod();
  }, []);

  useImperativeHandle(ref, () => ({
    openSaveBeforeLeaveDialog:(param)=>{
        return openSaveBeforeLeaveDialogParam(param);
    }
}));

    const launchToast = (toastMessage, toastIsError)=> {
        $("#toastMonthlyBuild").addClass("show");
        setToastMessage(toastMessage);
        setToastIsError(toastIsError);

        setTimeout(function(){
            $("#toastMonthlyBuild").removeClass("show");
        }, 4000);
    }

    const resetSteps = () => {
        setCompletedSteps({
            INTRODUCTION: false,
            UPLOAD_FILES: false,
            TIE_OUT_PL_GL: false,
            DEFINE_COST_CENTERS: false,
            SETUP_PROFIT_STACK: false,
            BUILD_SCENARIO: false
        });
    };

  const getMonthlyBuildPeriod = () => {
    let query = { action: "getMonthlyBuildPeriod" };
    let onThenCallback = (data) => {
      if (data) {
        // if endDate = "" => error=no published scenario
        setIsEndDateEditable(data.canEditDate);
        setPreviousPeriod(data.previousPeriod);
        setStartDate(data.startDate);
        setBannerError(data.errorMessage);
        // suggest end date if returned empty
        let endDate = data.endDate !== "" ? data.endDate : suggestEndDate(data.startDate);
        setEndDateVal(endDate);

        let currentPeriod = data.currentPeriod !== "" ? data.currentPeriod : suggestNewPeriod(data.previousPeriod);
        if(data.publishedScenarioId){
          setPublishedScenarioId(data.publishedScenarioId)
        }
        setCurrPeriod(currentPeriod);
        
        fetchPrerequisitesData(currentPeriod,data.publishedScenarioId, data.errorMessage);
      }
    };
    let fetchOptions = {
      [FETCHAPI_PARAMS.funcName]: "getMonthlyBuildPeriod",
      [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
      [FETCHAPI_PARAMS.showLoader]: true,
      [FETCHAPI_PARAMS.path]: "/get-monthly-build-period", // PI-27751 check the comment put in the API project (MonthlyBuildController)
      [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
      [FETCHAPI_PARAMS.query]: query,
      [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
      [FETCHAPI_PARAMS.screenName]:lang.observability.monthly_build.introduction.screen_name,
      [FETCHAPI_PARAMS.requestDescription]:lang.observability.monthly_build.introduction.requests_description.get_monthly_build_period
    };
    fetchAPI(fetchOptions);
  };

  const trackBuildProgress=(scenarioId,callback,callbackUpdateStepper)=>{
      let count = 0;
      let doneRequests = 0;
      let totalRequests = 8;
      let runningRequests = "";
      let query = {
          action: "trackBuildProgress",
          scenario_id: scenarioId,
      }
  
      let onThenCallback = (data) => {
        let succeededJobs = data.SucceededJobs.length > 0 ? copyObjectValues(data.SucceededJobs) : [lang.build_progress_currently_running_job.preparing_data[0]]
        let currentJob = convertFirstLetterToUpperCase(succeededJobs?.pop().replace("_"," ").toLowerCase());
        let status = data.status;
        if (lang.build_progress_currently_running_job.preparing_data.includes(currentJob)) {
            currentJob = BUILD_PROGRESS_STEPS.PREPARING_DATA;
        } else if (currentJob === lang.build_progress_currently_running_job.assigning_amounts){
            currentJob = BUILD_PROGRESS_STEPS.ASSIGNING_AMOUNTS;
        } else if (lang.build_progress_currently_running_job.generating_data.includes(currentJob)){
            currentJob = BUILD_PROGRESS_STEPS.GENERATING_DATA;
        } else if (currentJob === lang.build_progress_currently_running_job.generating_segments){
            currentJob = BUILD_PROGRESS_STEPS.GENERATING_SEGMENTS;
        } else if (currentJob === lang.build_progress_currently_running_job.build_done){
            currentJob = BUILD_PROGRESS_STEPS.BUILD_COMPLETE;
            status = "succeeded";
        }
          doneRequests = data.SucceededJobs?data.SucceededJobs.length:0;
          totalRequests = data.TotalJobs;
          runningRequests = currentJob;
          let percentage = 100*(doneRequests/totalRequests);
          if((data.isEngineRunRunning || parseBoolean(sessionStorage.getItem(IS_BUILD_RUNNING+"_"+scenarioId))) && status !== "failed") {
            if(currentJob !== ''){
              sessionStorage.setItem("runningRequests_"+scenarioId,runningRequests)
            }
            if(status !=="canceling"){
                sessionStorage.setItem("buildPerc_"+scenarioId,percentage)
            }
            if(status === "running" && percentage){
                sessionStorage.setItem("isBuildRunning_"+scenarioId,true)
            } 
            if(typeof callbackUpdateStepper === "function") {
              callbackUpdateStepper();
            }
          } else {
            sessionStorage.removeItem("runningRequests_"+scenarioId);
            sessionStorage.removeItem("buildPerc_"+scenarioId);
            sessionStorage.removeItem("isBuildRunning_"+scenarioId);
            if( typeof callback === "function"){
              callback(status);
            }
          }
          //Remove those from session since they should be filled in BuildProgress
          sessionStorage.removeItem("isBuildCompleted_"+scenarioId);
          sessionStorage.removeItem("isBuildError");
        }
  
      let fetchOptions = {
          [FETCHAPI_PARAMS.funcName]: "trackBuildProgress",
          [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
          [FETCHAPI_PARAMS.showLoader]: true,
          [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
          [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
          [FETCHAPI_PARAMS.query]: query,
          [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
          [FETCHAPI_PARAMS.screenName]:lang.observability.monthly_build.build.screen_name,
          [FETCHAPI_PARAMS.requestDescription]:lang.observability.monthly_build.build.requests_description.track_build_progress
        };
      count++;
      fetchAPI(fetchOptions);
  }
  const getLastMonthlyBuildStatus = (scenarioId,period,callback,status) => {
    let query = {
      action: "getLastMonthlyBuildStatus",
      scenario_id: scenarioId,
      period: period
    }
    let onThenCallback = (data) => {
      if(data && data.status !== undefined) {
        let succeededJobs = data.succeededjobs.length > 0 ? JSON.parse(data.succeededjobs) : [lang.build_progress_currently_running_job.preparing_data[0]]
        let currentJob = convertFirstLetterToUpperCase(succeededJobs?.pop().replace("_"," ").toLowerCase());
        if (lang.build_progress_currently_running_job.preparing_data.includes(currentJob)) {
            currentJob = BUILD_PROGRESS_STEPS.PREPARING_DATA;
        } else if (currentJob === lang.build_progress_currently_running_job.assigning_amounts){
            currentJob = BUILD_PROGRESS_STEPS.ASSIGNING_AMOUNTS;
        } else if (lang.build_progress_currently_running_job.generating_data.includes(currentJob)){
            currentJob = BUILD_PROGRESS_STEPS.GENERATING_DATA;
        } else if (currentJob === lang.build_progress_currently_running_job.generating_segments){
            currentJob = BUILD_PROGRESS_STEPS.GENERATING_SEGMENTS;
        } else if (currentJob === lang.build_progress_currently_running_job.build_done){
            currentJob = BUILD_PROGRESS_STEPS.BUILD_COMPLETE;
        }
        sessionStorage.setItem("currentJob_"+scenarioId, currentJob)
        if(status === "failed") { //In case of failure engine run is stuck at running but status of trackbuilding progress is being updated accordingly (There is no workaround for this)
          setLastMonthlyBuildStatus("failed")
        } else {
          setLastMonthlyBuildStatus(data.status)
        }
        if(callback && typeof callback === "function"){
          callback();
        }
      }
      
    }
    let fetchOptions = {
      [FETCHAPI_PARAMS.funcName]: "getLastMonthlyBuildStatus",
      [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
      [FETCHAPI_PARAMS.showLoader]: true,
      [FETCHAPI_PARAMS.path]: "/get-last-monthly-build-status", 
      [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
      [FETCHAPI_PARAMS.query]: query,
      [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
      [FETCHAPI_PARAMS.periods]: period,
      [FETCHAPI_PARAMS.screenName]:lang.observability.monthly_build.introduction.screen_name,
      [FETCHAPI_PARAMS.requestDescription]:lang.observability.monthly_build.introduction.requests_description.get_last_monthly_build_status
    };
    fetchAPI(fetchOptions);
  }
  /**
   * get data needed to start monthly build process
   * @param currentPeriod 
   */
  const fetchPrerequisitesData = (currentPeriod,publishedScenarioId, errorMsg) => {
    let query = {
      action: "prerequisitesData", //It's still required by networkUtils.js
      period: currentPeriod
    };
    let onThenCallback = (data) => {
    if (data) {
        setCancelRevert(data.canCancelRevert);
        canCancelRevertRef.current = data.canCancelRevert;
        setHideCostCenters(!data.costCenterStateDefined); // some clients doesn't have cost centers
        setShowRollbackButton(data.canRevertToPreviousPeriod);
        if(data.monthlyBuildStepsState && (errorMsg === "" || errorMsg.startsWith("Period deactivated"))){ // get step states for stepper
            const updatedCompletedSteps = completedSteps; 
            setIsBuildInitiated(data.isBuildInitiated);
            forceRebuildRef.current = false;
            const monthlyBuildStepsState = data.monthlyBuildStepsState.sort((a,b) => (a.step_order > b.step_order ? 1 : -1));
            let foundUncompletedStep = 0; // must be false to change it on the first uncompleted step
            let buildSubsteps = [];
            for (const step of monthlyBuildStepsState) {

                if(!step.parent_id){ //if it is not a substep(doesn't have parent_id)
                    if(step.status == STEP_STATUS.REBUILD){
                        setForceRebuild(true);
                        forceRebuildRef.current = true;
                    }

                    updatedCompletedSteps[step.name] = (step.status == STEP_STATUS.COMPLETED);
                    if(!updatedCompletedSteps[step.name] && foundUncompletedStep == 0 ){ // if the step is not completed and we didn't find the first uncompleted step yet

                        if(data.costCenterStateDefined || (!data.costCenterStateDefined && steps.find(e => e.value == step.name).numb !== 3)){ // if the client have cost centers continue logic, or if the client doesn't have cost centers, and the first uncompleted step is not define cost centers, continue logic
                            foundUncompletedStep = step.name; // set the firstuncompleted step so we can redirect to it, and the condition above will be always false
                        }
                    }
                } else {
                    buildSubsteps.push(step);
                } 
            }
            let foundUncompletedStepNumb = steps.find(e => e.value == foundUncompletedStep)?.numb; // find index in "steps" variable so we can redirect stepper to it
            if(!data.costCenterStateDefined && foundUncompletedStepNumb > 3){ // to handle clients with no costcenters
                foundUncompletedStepNumb -= 1;
            }
            const callbackUpdateStepper = () => {
              customStepperRef?.current?.setActiveStep(foundUncompletedStepNumb); // redirect stepper
              setCompletedSteps(updatedCompletedSteps);
              setRefreshRender(!refreshRender);
            }
            if(foundUncompletedStep === BUILD_LABEL && publishedScenarioId){
                setBuildSubsteps(buildSubsteps);
                const callback = (status) => {
                  getLastMonthlyBuildStatus(publishedScenarioId,currentPeriod,callbackUpdateStepper,status)
                }
                trackBuildProgress(publishedScenarioId,callback,callbackUpdateStepper);
              
            } else {
                setBuildSubsteps(null);
                callbackUpdateStepper()
            }
            
        }
      }
    };

    let fetchOptions = {
      [FETCHAPI_PARAMS.funcName]: "prerequisitesData",
      [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
      [FETCHAPI_PARAMS.showLoader]: true,
      [FETCHAPI_PARAMS.path]: "/get-prerequisites-data",
      [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
      [FETCHAPI_PARAMS.query]: query,
      [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
      [FETCHAPI_PARAMS.periods]: currentPeriod,
      [FETCHAPI_PARAMS.screenName]:lang.observability.monthly_build.introduction.screen_name,
      [FETCHAPI_PARAMS.requestDescription]:lang.observability.monthly_build.introduction.requests_description.get_prerequisites_data
    };
    fetchAPI(fetchOptions);
  };

  const saveMonthlyPeriod = () => {
    let periodObj = currPeriod.split("P");
    let year = periodObj[0];
    let period = "P" + periodObj[1];
    let quarter = getPeriodQuarter(currPeriod).replace(year, "");

    let query = {
      action: "saveFiscalPeriods",
      fiscalPeriods: [{
        "year": year,
        "period": period,
        "start_date_var": startDate,
        "end_date_var": endDateVal,
        "quarter": quarter,
        "status": FISCAL_YEAR.ROW_STATUS_VALUES.ADDED,
        "period_status": isEndDateEditable === "true" ? FISCAL_YEAR.PERIOD_STATUS_VALUES.NEW : FISCAL_YEAR.PERIOD_STATUS_VALUES.OLD ,
        "period_apply_status": FISCAL_YEAR.PERIOD_APPLY_VALUES.APPLIED,
        "is_active": FISCAL_YEAR.PERIOD_STATUS_VALUES.ACTIVE
      }]
    };

    let fetchOptions = {
      [FETCHAPI_PARAMS.funcName]: "saveFiscalPeriods",
      [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
      [FETCHAPI_PARAMS.showLoader]: true,
      [FETCHAPI_PARAMS.path]: "/DataModeling",
      [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
      [FETCHAPI_PARAMS.query]: query,
      [FETCHAPI_PARAMS.screenName]:lang.observability.monthly_build.introduction.screen_name,
      [FETCHAPI_PARAMS.requestDescription]:lang.observability.monthly_build.introduction.requests_description.save_fiscal_periods
    };
    fetchAPI(fetchOptions);
  };
  
  const getStepsState = (parent) => {
    let query = {
        action: "getStepsState", //It's still required by networkUtils.js
        parent: parent
      };
      let onThenCallback = (data) => {
      if (data) {  
        canCancelRevertRef.current = data.canCancelRevert 
        setCancelRevert(canCancelRevertRef.current);
          if(data.monthlyBuildStepsState){ // get step states for stepper
            if(parent ==  BUILD_LABEL){
                //sometimes, when a publish is running and we refresh the screen, status is being fetched on monthly build screen mount, and we then redirect to build step, when the request is launched again, the publish request is done and reseted the steps state, so there is no sync between the first request and second request. so this is to handle that
                const stepsToUse = buildSubsteps == null ? data.monthlyBuildStepsState : buildSubsteps;

                BuildStepRef.current.buildSubStepsStatusInitializer(stepsToUse);
            } else {
                forceRebuildRef.current = false;
                const updatedCompletedSteps = completedSteps;
                const monthlyBuildStepsState = data.monthlyBuildStepsState;
                for (const step of monthlyBuildStepsState) {
                    updatedCompletedSteps[step.name] = (step.status == STEP_STATUS.COMPLETED);

                    if(step.name === "BUILD_SCENARIO" && step.status === "REBUILD") {
                        forceRebuildRef.current = true;
                    }
                }
                setCompletedSteps(updatedCompletedSteps);
                setRefreshRender(!refreshRender);
                setForceRebuild(forceRebuildRef.current);
            }
          }
        }
      };

      let fetchOptions = {
        [FETCHAPI_PARAMS.funcName]: "getStepsState",
        [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
        [FETCHAPI_PARAMS.showLoader]: true,
        [FETCHAPI_PARAMS.path]: "/get-steps-state",
        [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
        [FETCHAPI_PARAMS.query]: query,
        [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
        [FETCHAPI_PARAMS.screenName]:lang.observability.monthly_build.screen_name,
        [FETCHAPI_PARAMS.requestDescription]:lang.observability.monthly_build.requests_description.get_steps_state
      };
      fetchAPI(fetchOptions);
  }


  /**
   * send request to set step state as true in DB
   * @param {step} step
   */

  const setStepChecked = (step) => {

    let query = {
        action: "setStepChecked", //It's still required by networkUtils.js
        step: step
    };

    let onThenCallback = () => {
        getStepsState("")
    }

    let fetchOptions = {
      [FETCHAPI_PARAMS.funcName]: "setStepChecked",
      [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
      [FETCHAPI_PARAMS.showLoader]: true,
      [FETCHAPI_PARAMS.path]: "/set-step-checked",
      [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
      [FETCHAPI_PARAMS.query]: query,
      [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
      [FETCHAPI_PARAMS.screenName]:lang.observability.monthly_build.screen_name,
      [FETCHAPI_PARAMS.requestDescription]:lang.observability.monthly_build.requests_description.set_step_checked
    };
    fetchAPI(fetchOptions);
  };

   /**
   * send request to check intro step as completed and remove data of rollback incase found
   */

   const proceedFromIntro = () => {

    let query = {
        action: "proceedFromIntro", //It's still required by networkUtils.js
    };

    let onThenCallback = () => {
        setStepChecked(lang.monthly_build.steps.value.introduction);
        setShowRollbackButton(false);
    }

    let fetchOptions = {
      [FETCHAPI_PARAMS.funcName]: "proceedFromIntro",
      [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
      [FETCHAPI_PARAMS.showLoader]: true,
      [FETCHAPI_PARAMS.path]: "/proceed-from-intro",
      [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
      [FETCHAPI_PARAMS.query]: query,
      [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
      [FETCHAPI_PARAMS.screenName]:lang.observability.monthly_build.introduction.screen_name,
      [FETCHAPI_PARAMS.requestDescription]:lang.observability.monthly_build.introduction.requests_description.proceed_from_intro
    };
    fetchAPI(fetchOptions);
  };

    /**
   * Save combinations configuration
   * @param {glStrings} glStrings combinations assigned to psl
   */
    const saveGlStrings = (updateCombinations, partialSave, callback, fromSaveProgress) => {
        let query = {
            action: "updateCombinations", //It's still required by networkUtils.js
            updateCombinations,    //combinations with the assigned psl line
            partialSave: partialSave
        };

        const hasChanges = setUpProfitStacksRef?.current?.getCanSaveProgress()

        const onThenCallback = (data) => {
          //needs change here rename success to can proceed or not
            let canUserProceed = data.success;
            getStepsState("");
            setCanProceed(canUserProceed);
            if(canUserProceed && !fromSaveProgress) {
                document.getElementById("monthly-build-next").style.display = "block";
                callback(); // is the user can proceed, move from setupProfitStacks
            } else if (fromSaveProgress) {
                setUpProfitStacksRef?.current?.setCanSaveProgress();
                launchToast(lang.monthly_build.setup_profit_stacks.progress_saved_successfully, false);

                // Save done, return to profit stacks
                if(typeof callback == "function"){
                    setTimeout(function(){
                        callback();
                    }, 1000);
                }
            } else {
              setProfitStacksSetupErrorMessage(lang.monthly_build.setup_profit_stacks.error_message_bad_request)
            }
        }

        let fetchOptions = {
          [FETCHAPI_PARAMS.funcName]: "update-combinations",
          [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
          [FETCHAPI_PARAMS.showLoader]: true,
          [FETCHAPI_PARAMS.path]: "/update-combinations",
          [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
          [FETCHAPI_PARAMS.query]: query,
          [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
          [FETCHAPI_PARAMS.screenName]:lang.observability.monthly_build.assign_gl_strings.screen_name,
          [FETCHAPI_PARAMS.requestDescription]:lang.observability.monthly_build.assign_gl_strings.requests_description.update_combinations
        };
        if(hasChanges){
            fetchAPI(fetchOptions);
        } else {
            setCanProceed(true);
            setStepChecked(lang.monthly_build.steps.value.setup_profit_stack);
            callback(); // callback is the function that sets activeStep + 1 to go to next step
        } 
      };

  const canProceedFromIntro = (callback, areChangesSaved=changesSaved) => {
    const doesUserUnderstand = IntroStepRef?.current?.allCheckboxesChecked();
    if(!areChangesSaved) {
      setOpenChangesNotSaved(true);
    }
    if (bannerError === "" && doesUserUnderstand && areChangesSaved) {
        if(isEndDateEditable === "true"){
            setIsEndDateEditable("false"); // when pressing start, make the end date read only so that it wont be editable on back
            saveMonthlyPeriod();
            deleteItemFromSession("getClientPeriods");
        }
      setCanProceed(true);
      showRollbackButton ? proceedFromIntro() : setStepChecked(lang.monthly_build.steps.value.introduction);
      callback(); // callback is the function that sets activeStep + 1 to go to next step
    };
    
  }

  const prepareProceedFromUpload = (callback) => {
    uploadFilesRef?.current?.resetFilter();
    uploadFilesRef?.current?.getBoardData(callback);

  }

  const setTieOutMessageParam = (messageParam) => {
    setTieOutErrorMessage(messageParam);
  }

  const setCostCentersErrorMessageParam = (messageParam) => {
    setCostCentersErrorMessage(messageParam);
  }

  const proceedFromUpload = (callback) => {
     // set proceedBtn state to true to know that the proceed btn was pressed
     uploadFilesRef?.current?.proceedBtnPressed();
     setIsProceedBtnPressed(true);

     // canProceed is a flag that allows the error under proceed btn to show
     let canUserProceed = uploadFilesRef?.current?.canUserProceed();
     setCanProceed(canUserProceed);
     if(canUserProceed) {
       callback(); // is the user can proceed, go to step 3
       setStepChecked(lang.monthly_build.steps.value.upload_files);
     }
  }

    const proceedFromTieOut = (callback) => {
        if(tieOutPlGlRef?.current?.accountInfoData?.allGLAccountNumbersAreDefined === false){
            setTieOutErrorMessage(lang.monthly_build.tie_out_pl_gl.resolve_concern_error_message);
            showErrorsDialog();
            setCanProceed(false);
        } else {
            if (tieOutPlGlRef?.current?.tiedOutValue !== "" && tieOutPlGlRef?.current?.tiedOutValue !== "-") {
                setTieOutMessageParam(lang.monthly_build.tie_out_pl_gl.no_tie_out_error_message);
            } else {
                setTieOutMessageParam(lang.monthly_build.tie_out_pl_gl.no_value_error_message);
            }

            if (tieOutPlGlRef?.current?.tieOutCalculation() && tieOutPlGlRef?.current?.tieOutCalculation()?.tieOutResult !== TIE_OUT_PL_GL.NOT_TIED_OUT) {
                tieOutPlGlRef?.current?.saveTiedOutValue();
                setCanProceed(true);
                callback();
            } else {
                if (tieOutPlGlRef?.current?.tieOutCalculation()?.tieOutResult === TIE_OUT_PL_GL.NOT_TIED_OUT) {
                    setTieOutErrorMessage(lang.monthly_build.tie_out_pl_gl.resolve_concern_error_message);
                }
                showErrorsDialog();
                setCanProceed(false);
            }
        }
    }

    const ignoreRequiredUploadAndProceed = () => {
        setCanProceed(true);
        setStepChecked(lang.monthly_build.steps.value.upload_files);
        customStepperRef?.current?.setActiveStep(2);
        handleStepComplete(1)
    }

    const proceedFromConfigureCostCenter = (callback) => {
        let costCenterCannotBePredicted = costCentersRef?.current?.costCenterCannotBePredicted(); // flag fetched from api

        let newCostCentersWithLatestPeriod = costCentersRef?.current?.getNewCostCentersWithLatestPeriod();

        // Can save cost centers ONLY in case prediction flag is false
        let canSave = !costCenterCannotBePredicted;
        let errorMessage = lang.monthly_build.intro.proceed_error_msg;
        if (canSave && newCostCentersWithLatestPeriod && newCostCentersWithLatestPeriod.length > 0) {
            let newCostCentersHasState = newCostCentersWithLatestPeriod.every(costCenter => costCenter.cost_center_state != null);
            // Can save cost centers ONLY in case prediction flag is false and all the NEW cost centers have state defined in the UI
            if (!newCostCentersHasState){
              errorMessage = lang.monthly_build.cost_centers.empty_states_error_message;
            }
            canSave &= newCostCentersHasState;
        }
        if (canSave) {
            costCentersRef?.current?.saveCostCenters(callback);
        } else {
            setCostCentersErrorMessage(errorMessage);
            setCanProceed(false);
        }
    }

    const configureTieOutNext = (callback) => {
        setStepChecked(lang.monthly_build.steps.value.define_cost_centers);
        
        setCanProceed(true);
        callback();
    }

  const handleStepComplete = (stepNumb) => {
    let updatedCompletedSteps = completedSteps; // get a copy of current stepper state
    const stepValue = steps.find(e => e.numb == stepNumb).value; // find the completed step value by matching number of step
    updatedCompletedSteps[stepValue] = true; // set completed step state to true on specific value
    setCompletedSteps(updatedCompletedSteps); // update the state with new values.
    setRefreshRender(!refreshRender); 
  };

  const goToUpload = () => {
    if(customStepperRef.current){
      customStepperRef.current.handleBack();
    }
  };

  const showErrorsDialog = () => {
    if(customStepperRef.current){
      customStepperRef.current.handleShowErrorsDialog();
    }
  };

  const hideErrorsDialog = () => {
    if(customStepperRef.current){
      customStepperRef.current.hideErrorsDialog();
    }
  };

  const glStringsProceed = (callback) => {
    let updateCombinations = setUpProfitStacksRef?.current?.getGlStringsDataToSave();

    if(updateCombinations && updateCombinations.length === 0){
        saveGlStrings(updateCombinations, false, callback); //Send to API and handle 0 there to "Tick" the step
        setCanProceed(true); // user has no unassigned combinations, he can proceed 
        callback(); 
    } else {    
        // canProceed is a flag that allows the error under proceed btn to show
        let canSave = !!updateCombinations;

        if(canSave) {
            saveGlStrings(updateCombinations, false, callback);
        } else {
            setCanProceed(false);
            setProfitStacksSetupErrorMessage(lang.monthly_build.setup_profit_stacks.error_message);
        }
    }
  }

  const handleBackParam = (param) => {
    customStepperRef?.current?.handleBack(param);
  }

  const openSaveBeforeLeaveDialogParam = (param) => {
    setUpProfitStacksRef?.current?.setSaveGlModalOpen(param);
  }

  const setCanProceedParam = (param) => {
    setCanProceed(param);
  }

  let steps = [
    {
      numb: 0,
      label: lang.monthly_build.steps.name.introduction,
      value: INTRODUCTION_LABEL,
      content: (
        <Introduction
          ref={IntroStepRef}
          currPeriod={currPeriod}
          previousPeriod={previousPeriod}
          startDate={startDate}
          endDate={endDateVal}
          onSaveDate={setEndDateVal}
          isEndDateEditable={isEndDateEditable}
          bannerError={bannerError}
          openChangesNotSaved={openChangesNotSaved}
          setOpenChangesNotSaved={setOpenChangesNotSaved}
          setChangesSaved={setChangesSaved}
          customStepperRef={customStepperRef}
          glSubtypes ={props.glSubtypes}
          allActualGLColumns={props.allActualGLColumns}
          renderMonthlyBuildBanner = { () => renderMonthlyBuildBanner()}
          getMonthlyBuildPeriod = {getMonthlyBuildPeriod}
          dispatch = {props.dispatch}
          publishedScenarioId={publishedScenarioId}
          showRollbackButton={showRollbackButton}
        />
      ),
      hasErrors: bannerError !== "",
      errorMsg: lang.monthly_build.intro.proceed_error_msg,
      proceedClick: (callback, areChangesSaved) => canProceedFromIntro(callback, areChangesSaved),
      completed : completedSteps[INTRODUCTION_LABEL]
    },
    {
      numb: 1,
      label: lang.monthly_build.steps.name.upload_files,
      value: UPLOAD_LABEL,
      content: (
        <UploadFiles
          ref={uploadFilesRef}
          period={currPeriod}
          startDate={startDate}
          endDate={endDateVal}
          user={props.user}
          machineName={props.machineName}
          isProceedBtnPressed={isProceedBtnPressed}
          goToStep3={proceedFromUpload}
          ignoreRequiredUploadAndProceed={ignoreRequiredUploadAndProceed}
          renderMonthlyBuildBanner = { () => renderMonthlyBuildBanner()}
        />
      ),
      nextBtnClassName: "proceed-margin-left",
      errorMsgClassName: "proceed-margin-left upload-error-text",
      errorMsg: lang.monthly_build.upload_files.proceed_error_msg,
      hasErrors: !canProceed && canProceed!== undefined,
      proceedClick: (callback) => prepareProceedFromUpload(callback),
      completed : completedSteps[UPLOAD_LABEL]
    },
    {
      numb: 2,
      label: lang.monthly_build.steps.name.tie_out_pl_gl,
      value: TIE_OUT_LABEL,
      content: (
          <TieOutPLGL
              ref={tieOutPlGlRef}
              period={currPeriod}
              startDate={startDate}
              endDate={endDateVal}
              user={props.user}
              machineName={props.machineName}
              isProceedBtnPressed={isProceedBtnPressed}
              goToStep4={proceedFromTieOut}
              setTieOutMessageParam={setTieOutMessageParam}
              goToUpload={goToUpload}
              showErrorsDialog={showErrorsDialog}
              hideErrorsDialog={hideErrorsDialog}
              renderMonthlyBuildBanner = { () => renderMonthlyBuildBanner()}
          />
      ),
      nextBtnClassName: "proceed-margin-left",
      errorMsgClassName: "proceed-margin-left upload-error-text",
      errorMsg: tieOutErrorMessage,
      hasErrors: !canProceed,
      proceedClick: (callback) => proceedFromTieOut(callback),
      completed : completedSteps[TIE_OUT_LABEL],
      isSkippable: true,
      skip: () => {
        setStepChecked(TIE_OUT_LABEL);
        customStepperRef?.current?.nextBtnClick(true, true);
    }
    },
    {
      numb: 3,
      label: lang.monthly_build.steps.name.define_cost_centers,
      value: DEFINE_CC_LABEL,
      content: (
          <CostCenters
              ref={costCentersRef}
              period={currPeriod}
              startDate={startDate}
              endDate={endDateVal}
              setCostCentersErrorMessage={setCostCentersErrorMessageParam}
              configureTieOutNext={configureTieOutNext}
              renderMonthlyBuildBanner = { () => renderMonthlyBuildBanner()}
          />
      ),
      errorMsgClassName: "upload-error-text",
      errorMsg: costCentersErrorMessage,
      hasErrors: !canProceed,
      proceedClick: (callback) => proceedFromConfigureCostCenter(callback),
      completed : completedSteps[DEFINE_CC_LABEL],
      saveAndProceed: true
    },
    {
        numb: 4,
        label: lang.monthly_build.steps.name.setup_profit_stack,
        value: SETUP_PS_LABEL,
        content: (
            <SetupProfitStacks
                ref={setUpProfitStacksRef}
                period={currPeriod}
                startDate={startDate}
                endDate={endDateVal}
                hideCostCenters={hideCostCenters}
                saveGlStrings={saveGlStrings}
                handleBack={handleBackParam}
                changeReport={props.changeReport}
                renderMonthlyBuildBanner = { () => renderMonthlyBuildBanner()}
            />
        ),
        errorMsgClassName: "proceed-margin-left upload-error-text",
        nextBtnClassName: "proceed-margin-left",
        completed : completedSteps[SETUP_PS_LABEL],
        proceedClick: (callback) => glStringsProceed(callback),
        errorMsg: profitStacksSetupErrorMessage,
        hasErrors: !canProceed,
    },
    {
      numb: 5,
      label: lang.monthly_build.steps.name.build_scenario,
      value: BUILD_LABEL,
      icon: <FlagIcon />,
      content: (
          <BuildStep
              ref={BuildStepRef}
              period={currPeriod}
              startDate={startDate}
              endDate={endDateVal}
              hideCostCenters={hideCostCenters}
              publishedScenarioId={publishedScenarioId}
              lastMonthlyBuildStatus={lastMonthlyBuildStatus}
              getStepsState={getStepsState}
              setStepChecked={setStepChecked}
              dispatch = {props.dispatch}
              renderMonthlyBuildBanner = { () => renderMonthlyBuildBanner()}
              setCancelRevert ={()=> setCancelRevertParam()}
              setForceRebuild = {() => setForceRebuildParam()}
              setProgressBarClickable = {customStepperRef?.current?.setProgressBarClickable}
              forceRebuild = {forceRebuild}
              setIsBuildInitiated={() => setIsBuildInitiatedParam()}
              isBuildInitiated={isBuildInitiated}
              callbackAfterCancelBuild = {customStepperRef?.current?.callbackAfterCancelBuild}
          />
      ),
      completed : completedSteps[BUILD_LABEL]
    },
  ];

  if(hideCostCenters){
    const indexToRemove = steps.findIndex(step => step.numb === 3);
    if (indexToRemove !== -1) {
      steps.splice(indexToRemove, 1);
      for (let i = indexToRemove; i < steps.length; i++) {
        steps[i].numb -= 1;
      }
    }
  }

    const renderMonthlyBuildBanner = () => {
        if(forceRebuild){
            return (
                <NewBanner
                    id={"force-rebuild-banner"}
                    label={<span>{lang.monthly_build.force_rebuild_message}</span>}
                    icon={<CalendarsIcon />}
                    bannerClassName={"banner-error large-margin-bottom"}
                    labelClassName={"banner-text red-text"}
                />
            )            
        } else if(cancelRevert){
            return (
                <NewBanner
                    id={"cancel-revert-banner"}
                    label={<span>{lang.monthly_build.cancel_revert_message.replace("%P%",currPeriod)}</span>}
                    icon={<ArrowRotateLeftIcon/>}
                    bannerClassName={"banner-info banner-upload full-width"}
                    body={    
                      <Button 
                        id="cancel-revert-bttn"
                        label={lang.monthly_build.cancel_revert_btn}
                        variant={BUTTON_VARIANT.SECONDARY}
                        size={SIZES.DEFAULT}
                        type={BUTTON_TYPE.DEFAULT}
                        className='uk-margin-default-left'
                        onBtnClick={() => setOpenConfirmCancelRevert(true)}/> 
                    }
                    labelClassName={"banner-text yellow-text"}
                />
          )
        }
    }

    const setForceRebuildParam = (param) => {
        if (forceRebuildRef?.current){ //if forceRebuild was initiated from api in the first place
            setForceRebuild(param)
        }
        
    } 

    const setCancelRevertParam = (param) => {
      if(canCancelRevertRef?.current){
          setCancelRevert(param)
      }
    }

    const setIsBuildInitiatedParam = () => {
        setIsBuildInitiated(true)
    }

    const cancelRevertMonthlyBuild = () => {
      let query = {
        action: "cancelRevertMonthlyBuild", //It's still required by networkUtils.js
      };
      let onThenCallback = (data) => {
          if (data.success) {
              document.getElementById("monthly-build-next").style.display = "block";
              props.dispatch(updatePeriodsStatus([],publishedScenarioId));
              props.dispatch(updatePeriodsStatusState([],publishedScenarioId+"_"+12));
  
              getMonthlyBuildPeriod()
          }
      };
  
      let fetchOptions = {
        [FETCHAPI_PARAMS.funcName]: "cancelRevertMonthlyBuild",
        [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
        [FETCHAPI_PARAMS.showLoader]: true,
        [FETCHAPI_PARAMS.path]: "/cancel-revert-monthly-build",
        [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
        [FETCHAPI_PARAMS.query]: query,
        [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
        [FETCHAPI_PARAMS.screenName]:lang.observability.monthly_build.screen_name,
        [FETCHAPI_PARAMS.requestDescription]:lang.observability.monthly_build.requests_description.cancel_revert
      };
      setOpenConfirmCancelRevert(false);
      fetchAPI(fetchOptions);
    };

    const confirmCancelRevertModalBody = () => {
      return <h4>{lang.monthly_build.cancel_rollback.body}</h4>;
    };

    const confirmCancelRevertModalActions = () => {
      return (
        <>
          <Button
            label={lang.monthly_build.cancel_rollback.cancel_rollback_btn}
            variant={BUTTON_VARIANT.PRIMARY}
            size={SIZES.DEFAULT}
            type={BUTTON_TYPE.DEFAULT}
            onBtnClick={cancelRevertMonthlyBuild}
          />
  
          <Button
            label={lang.monthly_build.cancel_rollback.dismiss_btn}
            variant={BUTTON_VARIANT.TERTIARY}
            size={SIZES.DEFAULT}
            type={BUTTON_TYPE.DEFAULT}
            onBtnClick={() => setOpenConfirmCancelRevert(false)}
          />
        </>
      );
    };
   

  return (
    <div style={{ marginTop: "1.4vw" }}>
        <div id="toastMonthlyBuild" className="toast toast-success">
            <div id="desc"><i className={"fa-lg fas uk-margin-small-right " + (toastIsError ? "fa-minus-circle uk-text-primary" : "fa-check-circle greenText")} aria-hidden="true"></i>{toastMessage}</div>
        </div>
      {/*<h5 className="report-title">{ALL_WIDGETS.TITLES.MONTHLY_BUILD}</h5>*/}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CustomStepper
            ref={customStepperRef} steps={steps} handleStepComplete={handleStepComplete}
            refreshRender={refreshRender} setUpProfitStacksRef={setUpProfitStacksRef}
            BuildStepRef={BuildStepRef} getStepsState={getStepsState}
            setOpenConfirmRevert={IntroStepRef?.current?.setOpenConfirmRevert}
            showRollbackButton={showRollbackButton} setCanProceed={setCanProceedParam}
            setOpenCancelBuildModal={BuildStepRef.current?.setOpenCancelBuildModal}
            getCurrBuildStatus = {BuildStepRef?.current?.getCurrBuildStatus}
            previousPeriod={previousPeriod}
            />
        {/*<div className={"background-rect"}></div>*/}
      </div>
      <Modal //modal appears when the user clicks on republish button
        key={"confirm-cancel-revert"}
        id={"confirm-cancel-revert"}
        title={lang.monthly_build.cancel_rollback.title.replace("%P%", currPeriod)}
        openDialog={openConfirmCancelevert}
        bodyContent={confirmCancelRevertModalBody}
        dialogActions={confirmCancelRevertModalActions}
        closeClick={() => setOpenConfirmCancelRevert(false)}
        size={DIALOG_SIZE.LARGE}
      /> 
    </div>
  );
};

export default forwardRef(MonthlyBuild);
