import React, {Component} from 'react';
import {BUTTON_TYPE, BUTTON_VARIANT, ROW_STATUS, SIZES} from '../../class/constants.js';
import LeverColumn from './LeverColumn'
import {getTranslationFile, copyObjectValues} from '../../class/utils.js';
import Button from '../../newComponents/Button.js';


const lang = getTranslationFile();

/**
 * Levers
 *
 * @author [Bassem Arnaout]
 */
class Levers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numberOfIterations: 0,
            leversRef: [],


        };

        this.addLever = this.addLever.bind(this);
        this.deleteLever = this.deleteLever.bind(this);
        this.reload = this.reload.bind(this);
    }

    deleteLever(callback) {

        callback();

    }

    addLever() {
        this.setState({
            numberOfIterations: this.state.numberOfIterations + 1,
        })
    }

    setLevers(data) {
        let _this = this;
        this.setState({
            leversData: data,
            numberOfIterations: data.length
        }, () => {
            _this.setLeversData();
        })
    }

    /**
     * setting the data of each individual lever
     */
    setLeversData() {
        let _this = this;
        this.state.leversRef.forEach((ref, index) => {
            ref.setData(_this.state.leversData[index]);

        });
    }

    getLeversData() {
        let data = [];
        let rank =1;
        this.state.leversRef.forEach(ref => {

            let tempObj = copyObjectValues(ref.leverObj);
            tempObj[ROW_STATUS.FIELD] = ref.state[ROW_STATUS.FIELD];
            tempObj.rank = rank;
            rank = rank+1;
            data.push(tempObj);
        });

        return data;
    }

    handleLinkRef(ref) {
        if (ref != null) {
            this.state.leversRef[ref.props.index] = ref;
        }
    }

    checkLevers() {
        var levers = this.state.leversRef.filter(el => el.state[ROW_STATUS.FIELD] !== ROW_STATUS.VALUES.DELETED);
        if (this.state.numberOfIterations > 0) {
            if (this.state.numberOfIterations > this.state.leversRef.length) {
                return 1;
            } else {
                return levers.length
            }
        } else {
            return 0;
        }
    }

    reload() {
        this.forceUpdate();
    }

    displayLevers() {
        var iterations = this.state.numberOfIterations;
        var temp = [];
        var groups = this.props.groups;
        var groupsOptions = [];
        for (var e in groups) {
            groupsOptions.push({value: groups[e].custom_stack_line_id, label: groups[e].name})
        }
        for (var i = 0; i < iterations; i++) {

            temp.push(<LeverColumn key={"lever_" + i} deleteLever={this.deleteLever} index={i}
                                   ref={r => this.handleLinkRef(r)}
                                   leverObj={this.state.leversRef[i] ? this.state.leversRef[i] : {}}
                                   reload={this.reload} groups={groupsOptions}
            />);
        }
        return temp;
    }

    render() {
        var levers = this.displayLevers();

        return (
            <div className="levers-container">
                <div className=" uk-flex-between">
                    <Button 
                        variant={BUTTON_VARIANT.SECONDARY}
                        size={SIZES.DEFAULT}
                        type={BUTTON_TYPE.DEFAULT}
                        label = {lang.bridge_configurations.add_lever}
                        className="uk-button-icon transparent-bg"
                        onBtnClick={() => {
                            this.addLever()
                        }}
                        leftIcon={<i className="far fa-plus-circle fa-lg uk-margin-small-right"/>}
                    />
                </div>

                {levers.length > 0 && this.checkLevers() > 0 ?
                    <div className="uk-margin-xmedium-top">
                        <table cellpadding="20" cellspacing="20" border="0" className="w50 height100">
                            <thead>
                            <tr>
                                <td className="fs-14 uk-padding-xxsmall-bottom">{lang.bridge_configurations.group}</td>
                                <td className="fs-14 uk-padding-xxsmall-bottom">{lang.bridge_configurations.description}</td>
                                <td></td>
                            </tr>
                            </thead>
                            <tbody>
                            {levers}
                            </tbody>
                        </table>
                    </div>
                    : ""}
            </div>
        );

    }

}

export default Levers;