import React, {Component} from 'react';
import LegendItem from "./LegendItem";

const spanStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};


class Legend extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <React.Fragment>
                <div id='legend-text' className={"uk-flex uk-flex-middle uk-flex-center uk-padding-xxsmall-top-bottom"}style={spanStyles}>
                    {this.props.legendItems().map((text) => {
                        return <LegendItem legendText={text}/>
                    })}
                </div>
            </React.Fragment>
        )

    }

}


export default Legend;