import SearchIcon from "@mui/icons-material/Search";
import { ReactComponent as ClearIcon } from "../styles/images/icons/clear.svg";
import { Autocomplete, TextField } from "@mui/material";

/**
 * Search input with search icon
 * @returns
 */
const SearchBar = ({
  placeholderText = "Search",
  onInputChange,
}) => {
  function onChange(e) {
    onInputChange(e.currentTarget.value);
  }
  return (
    <Autocomplete
      id={"autocomplete-search-bar"}
      className={"search-bar"}
      onInputChange={onChange}
      options={[]} // this should be filled if we want to make the search autocomplete
      freeSolo // to remove the select dropdown from this component and make it only a textField
      autoComplete={false}
      clearIcon={<ClearIcon />}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth 
          variant="standard"
          InputProps={{
            ...params.InputProps,
            type: "search",
            startAdornment: <SearchIcon sx={{ color: "#8D8D8D", fontSize: "1.1vw", mr: 1 }} />,
            disableUnderline: true,
            placeholder: placeholderText,
            sx: {
              fontSize: "0.63vw",
              border: "1px solid #DCDCDC",
              borderRadius: 1,
              px: "0.31vw",
            },
          }}
          sx={{
            boxShadow: "none",
            my: 1,
            px: 1,
            zIndex: 6
          }}
        />
      )}
    />
  );
};

export default SearchBar;
