import React, { Component } from 'react';
import { getTranslationFile, deepCompareObjects, copyObjectValues, tryParse } from '../../class/utils';
import { BUTTON_TYPE, BUTTON_VARIANT, CALCULATED_COLUMNS as CALC_COLS, FORMULA_ELEMENT_TYPE, RAW_ITEMS, SIZES, STAGING_SECTIONS } from '../../class/constants';
import { addSimpleTooltipIcon } from '../../class/common';
import { isDigit } from '../../class/number';
import { convertPxToViewport } from '../../class/formatting';
import Button from '../../newComponents/Button';

const $ = require('jquery');
const lang = getTranslationFile();

class FormulaDrop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formula: copyObjectValues(this.props.formula) || [],
            isFormulaValid: true
        };

        this.setDroppable = this.setDroppable.bind(this);
        this.setFormulaIndex = this.setFormulaIndex.bind(this);
        this.setFormulaFocused = this.setFormulaFocused.bind(this);
        this.deleteLastFormulaItem = this.deleteLastFormulaItem.bind(this);
        this.resetFormulaChanges = this.resetFormulaChanges.bind(this);
        this.deleteFormula = this.deleteFormula.bind(this);
        
        this.innerComp = false;
        this.isPSCalculated = false;
        this.isMetricMapping = false;
    }

    validateFormulaResult(formulaResult) {
        if(typeof formulaResult === "string" && formulaResult!== ""){
            formulaResult = tryParse(formulaResult);
        }
        var isValidated = true;
        var tempFormula = "";
        var tempFormulaResult = null;
        var previousType = "";
        var previousVal = "";
        formulaResult?.map(item=>{
            if(previousType === item[CALC_COLS.FIELDS.COLUMN_FIELDS.TYPE] && item[CALC_COLS.FIELDS.COLUMN_FIELDS.TYPE] !== FORMULA_ELEMENT_TYPE.CONTROL) {
                isValidated = false;
                return;
            }

            if(item[CALC_COLS.FIELDS.COLUMN_FIELDS.VALUE] === "." && !isDigit(previousVal)) {
                //if current item is "." and is preceded by anything but a digit, return false
                isValidated = false;
                return;
            }

            //item is either a numpad btn or an operator
            if([FORMULA_ELEMENT_TYPE.OPERATOR, FORMULA_ELEMENT_TYPE.CONTROL].includes(item[CALC_COLS.FIELDS.COLUMN_FIELDS.TYPE])) {
                tempFormula += item.value;
            } else {
                tempFormula += "("+ Math.random() +")";
            }
            previousType = item[CALC_COLS.FIELDS.COLUMN_FIELDS.TYPE];
            previousVal = item[CALC_COLS.FIELDS.COLUMN_FIELDS.VALUE];
        });

        if(isValidated) {
            try{
                eval ("tempFormulaResult = "+ tempFormula +";");
            } catch(e){
                tempFormulaResult = null;
            }
            
            if([null, undefined, Infinity, -Infinity].includes(tempFormulaResult) || isNaN(tempFormulaResult)){
                isValidated = false;
            }
        }

        return isValidated;
    }

    setDroppable(onReceiveProps) {
        var obj = this;
        if(!this.state.dragDropSet || onReceiveProps) {
            $(this.dropRef).droppable({     //using ref in order to avoid conflict between multiple identical dom objects
                drop: function( event, ui ) {
                    let $node = $(ui.draggable);
                    let $clone = $node.clone();

                    //do not continue if dropping a filter row
                    if(!$node.hasClass("draggable-col-index") && !$node.hasClass("draggable-numpd-btn") && !$node.hasClass("costkey-draggable")){
                        return;
                    }

                    obj.setFormulaIndex();  //to make controls be appended to this formula
                    let isNumBtn = $node.hasClass("numpd-btn");

                    if(!obj.isPSCalculated) {
                        let file = $clone.attr(RAW_ITEMS.FILE);
                        let column = $clone.attr(RAW_ITEMS.COLUMN);
                        file = file && file !== "" ? JSON.parse(file) : undefined;
                        column = column && column !== "" ? JSON.parse(column) : undefined;

                        let nodeType = isNumBtn ? $clone.attr("node_type") : FORMULA_ELEMENT_TYPE.COLUMN;
                        let nodeClass = isNumBtn ? $clone.attr("node_class") : "formula-column-name span-lg";
                        let value = isNumBtn ? $clone.attr("node_value").trim() : column && column[RAW_ITEMS.VALUE] ? column[RAW_ITEMS.VALUE] : column;
                        let colIndex = isNumBtn ? undefined : $clone.text().trim();
                        obj.props.appendToFormula(value, nodeType, nodeClass, file, column, colIndex);
                    } else {
                        //sending the clone so that the function in the parent extracts what it needs from it
                        //better than adding if...else cases in the common component
                        obj.props.appendToFormula($clone, isNumBtn);
                    }
                }
            });

            this.state.dragDropSet = true;
        }
    }

    componentDidMount() {
        this.innerComp = this.props.isInnerComp;
        this.isPSCalculated = this.props.report === STAGING_SECTIONS.PROFIT_STACK_MAPPING;
        this.isMetricMapping = this.props.report === STAGING_SECTIONS.METRICS_MAPPING;

        this.setDroppable();
        this.setState({
            formula_unchanged: this.props.formula
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.formula && (!deepCompareObjects(this.props.formula, prevState.formula)
            || !deepCompareObjects(this.state.formula, prevState.formula)) && !deepCompareObjects(this.state.formula, this.props.formula)) {
                //when updating from props and setting state, this func will be called right before setting the state to the value of the props
                //we already updated the first time, we should not update a second time
            this.setState({
                formula: copyObjectValues(this.props.formula),
                isFormulaValid: !(this.props.formula && this.props.formula.length) || this.validateFormulaResult(this.props.formula)
            },function(){
                this.setDroppable(true);
            });
        }
    }

    setFormulaIndex() {
        if(this.props.setFormulaIndex) {
            this.props.setFormulaIndex(this.props.conditionNumber);
        }
        this.setFormulaFocused();
    }
    
    setFormulaFocused() {
        $(".formula-inner-container-focus").each(function(){
            $(this).removeClass("formula-inner-container-focus");
        });
        $(this.dropRef).addClass("formula-inner-container-focus");
    }

    deleteLastFormulaItem() {
        if(this.isPSCalculated) {
            this._deleteLastFormulaItem();
        } else if(this.innerComp) {
            this.props.deleteLastFormulaItem(this.props.conditionNumber);
        } else {
            this.props.deleteLastFormulaItem();
        }
    }
    
    resetFormulaChanges() {
        if(this.isPSCalculated) {
            this._resetFormulaChanges();
        } else if(this.innerComp) {
            this.props.resetFormulaChanges(this.props.conditionNumber);
        } else {
            this.props.resetFormulaChanges();
        }
    }

    deleteFormula() {
        if(this.isPSCalculated) {
            this._clearFormula();
        } else if(this.innerComp) {
            this.props.deleteFormula(this.props.conditionNumber);
        } else {
            this.props.deleteFormula();
        }
    }

    //#region new functions
    //trying to move some functionality from outer components inside this one for more modularity
    _deleteLastFormulaItem() {
        let tempFormula = copyObjectValues(this.state.formula);
        tempFormula.pop();

        this.setState({
            formula: tempFormula
        }, this.updateFormulaInParent);
    }

    _resetFormulaChanges() {
        var tempFormula = [];
        var resultToLoop = this.state.formula_unchanged;
        resultToLoop.map((item)=>{
            tempFormula.push(item);
        });

        this.setState({
            formula: tempFormula
        }, this.updateFormulaInParent);
    }

    _clearFormula() {        
        this.setState({
            formula: []
        }, this.updateFormulaInParent);
    }

    updateFormulaInParent() {
        if(this.props.updateFormulaInParent) {
            this.props.updateFormulaInParent(this.state.formula);
        }
    }
    //#endregion

    render() {
        var  title = this.innerComp ? lang.formula.formula_drop.formula_true : this.props.hasConditions ? lang.formula.formula_drop.formula_false : lang.formula.formula_drop.formula;
        let formula = copyObjectValues(this.state.formula);
        if(typeof formula  === "string" && formula !== ""){
            formula = tryParse(formula);
        }
        if (formula && formula.length>0) {
            formula = formula.map((el) => {
                if(el.type === FORMULA_ELEMENT_TYPE.OPERATOR) {
                    return <i className={"fal fa-lg " + el[CALC_COLS.FIELDS.COLUMN_FIELDS.CLASS]} key={el[CALC_COLS.FIELDS.COLUMN_FIELDS.VALUE] + "_" + Math.random()}></i>
                } else {
                    let fileName = el[CALC_COLS.FIELDS.COLUMN_FIELDS.FILE] && el[CALC_COLS.FIELDS.COLUMN_FIELDS.FILE].label ? el[CALC_COLS.FIELDS.COLUMN_FIELDS.FILE].label : el[RAW_ITEMS.SUBTYPE_NAME]  ? el[RAW_ITEMS.SUBTYPE_NAME]  : "";
                    let colName = el[CALC_COLS.FIELDS.COLUMN_FIELDS.COLUMN] && el[CALC_COLS.FIELDS.COLUMN_FIELDS.COLUMN].label ? el[CALC_COLS.FIELDS.COLUMN_FIELDS.COLUMN].label : el[RAW_ITEMS.FIELD_NAME] ? el[RAW_ITEMS.FIELD_NAME] : "";
                    let title = el[CALC_COLS.FIELDS.COLUMN_FIELDS.TYPE] === FORMULA_ELEMENT_TYPE.COLUMN && fileName && colName? fileName +": "+ colName : el[CALC_COLS.FIELDS.COLUMN_FIELDS.VALUE];
                    let text = el[CALC_COLS.FIELDS.COLUMN_FIELDS.TYPE] === FORMULA_ELEMENT_TYPE.COLUMN ? el[CALC_COLS.FIELDS.COLUMN_FIELDS.COLUMN_INDEX] || el[CALC_COLS.FIELDS.COLUMN_FIELDS.VALUE] : el[CALC_COLS.FIELDS.COLUMN_FIELDS.VALUE];
                    let tooltipText = fileName + "&#58" + colName;
                    let span = "";
                    let borderClass = el.type === FORMULA_ELEMENT_TYPE.CONTROL ? "" : "border-square ";
                    let psslines = this.props.linearisedProfitStackLineColumns;
                    if(el[CALC_COLS.FIELDS.COLUMN_FIELDS.TYPE] !== FORMULA_ELEMENT_TYPE.COLUMN && psslines && el[CALC_COLS.FIELDS.RETURN_NAME]){
                        let filteredPsLines = psslines.filter(e=>e.column_return_name === el[CALC_COLS.FIELDS.RETURN_NAME])[0];
                        if(filteredPsLines){
                            text = filteredPsLines.number;
                        }
                    }
                    el.type === FORMULA_ELEMENT_TYPE.COLUMN ?
                    span = <span className={borderClass+"uk-margin-xsmall-right-left"} key={text + "_" + Math.random()} uk-tooltip={ el.type === FORMULA_ELEMENT_TYPE.COLUMN ? "title: " + tooltipText + "; pos: right" : title}>{text?.toString()?.replace(/_ms/g,"00")}</span>
                    : el.type === FORMULA_ELEMENT_TYPE.PSS_LINE ?
                    span = <span className={borderClass+" uk-margin-xsmall-right-left"} key={text + "_" + Math.random()} uk-tooltip={ el.type === FORMULA_ELEMENT_TYPE.COLUMN ? "title: " + tooltipText + "; pos: right" : title}>{text?.toString()?.replace(/_ms/g,"00")}</span>
                    : span = <span className={borderClass+" uk-margin-xsmall-right-left " + el[CALC_COLS.FIELDS.COLUMN_FIELDS.CLASS]} key={text + "_" + Math.random()}>{text.replace(/_ms/g,"00")}</span>;
                    return span;
                }
            });
        }
        
        return(
            <div className={("formula-margin" + this.props.isStacks? " uk-margin-small-top":"")}>
                <div className="formula-header">
                    <div className="fs-14 uk-margin-default-bottom uk-display-flex uk-flex-bottom align-self-end">{title}
                        {this.props.isPssMapping || this.props.isStacks ? <span className="input_required_text">{"(Required)"}</span> :<span className="uk-margin-xsmall-top">{addSimpleTooltipIcon("TBD")}</span> }
                    </div>
                    <div className="uk-margin-small-bottom" style={{display:"flex"}}>
                        {/* <div className="uk-button-icon transparent-bg" onClick={this.deleteLastFormulaItem}>
                            <i className="fal fa-backspace fs-16" /><span className="uk-text-xmedium uk-margin-small-left">Backspace</span>
                        </div> */}
                        <Button   
                                id=""
                                label={"Backspace"}
                                // title={lang.header.tooltips.csv}
                                variant={BUTTON_VARIANT.TERTIARY}
                                size={SIZES.DEFAULT}
                                type={BUTTON_TYPE.DEFAULT}
                                onBtnClick={this.deleteLastFormulaItem}
                                leftIcon={<i className="fal fa-backspace fs-16" />}
                            />
                        {/* <div className="uk-button-icon transparent-bg uk-margin-default-left" onClick={this.resetFormulaChanges}>
                            <i className="fal fa-undo-alt fs-16" /><span className="uk-text-xmedium uk-margin-small-left">Reset</span>
                        </div> */}
                        <Button   
                                id=""
                                label={"Reset"}
                                // title={lang.header.tooltips.csv}
                                variant={BUTTON_VARIANT.TERTIARY}
                                size={SIZES.DEFAULT}
                                type={BUTTON_TYPE.DEFAULT}
                                onBtnClick={this.resetFormulaChanges}
                                leftIcon={<i className="fal fa-undo-alt fs-16" />}
                            />
                        {/* <div className="uk-button-icon transparent-bg uk-margin-default-left" onClick={this.deleteFormula}>
                            <i className="fal fa-times-circle fa-lg fs-16" /><span className="uk-text-xmedium uk-margin-small-left">Clear</span>
                        </div> */}
                        <Button   
                                id=""
                                label={"Clear"}
                                // title={lang.header.tooltips.csv}
                                variant={BUTTON_VARIANT.TERTIARY}
                                size={SIZES.DEFAULT}
                                type={BUTTON_TYPE.DEFAULT}
                                onBtnClick={this.deleteFormula}
                                leftIcon={<i className="fal fa-times-circle fa-lg fs-16" />}
                            />
                    </div>
                </div>
                <div className="uk-padding-xmedium-left-right formula-inner-container uk-border uk-border-rounded" ref={rf=>this.dropRef = rf} onClick={this.setFormulaIndex}>
                    {!formula || formula.length === 0 ?
                        <p className="red italic">{this.isPSCalculated && this.props.configure ? lang.formula.text_ps_mapping_section :  this.isPSCalculated && this.props.isCalculatedPSL ?  lang.formula.text_cpsl  : this.isPSCalculated ? lang.formula.text_ps_mapping : this.props.emptyFormulaText || lang.formula.text_standard}</p>
                    : formula}
                    {!this.state.isFormulaValid ? 
                        <p className="red italic">{lang.formula.formula_drop.wrong_formula}</p>
                    : ""}
                </div>
            </div>
        );
    }
}


export default FormulaDrop;