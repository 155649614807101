import React, { useEffect, useRef, useState } from 'react';
import './Style/MonthlyBuildCurrentPeriodBreadcrumbs.css';
import { ReactComponent as RightArrowIcon } from "../../styles/images/icons/chevron-right-regular-disabled.svg";
import HoverableList from './HoverableList'; 

const MonthlyBuildCurrentPeriodBreadcrumbs = ({ currentPeriod, listTitle, periods }) => {
    
    const [showList, setShowList] = useState(false);
    const [countHovered, setCountHovered] = useState(false);
    const [mount, setMount] = useState(true);

    const HoverableListRef = useRef(null);

    const periodsCount = periods?.length;

    useEffect(() => {
        if(!mount){ //to not be called on screen mount
            controlListVisibilty();
        } else {
            setMount(false);
        }
    }, [countHovered]);

    const controlListVisibilty = () => {
        if(!countHovered && !HoverableListRef?.current?.isLisHovered()){ //both number and list must not be hovered to close the list
            setShowList(false);
        } else {
            setShowList(true);
        }
    }

    return (
        <div className="breadcrumbs-container fs-14 border-bottom-light-grey uk-padding-small-bottom uk-margin-default-bottom">
            <span>Current Period</span>
            <RightArrowIcon className="breadcrumb-icon" />
            <span>{currentPeriod}</span>
            {periodsCount > 0 && (
                <div
                    onMouseEnter={() => setCountHovered(true)}
                    onMouseLeave={() => setCountHovered(false)}
                >
                    <div className={`empty-div-count`} />  {/* to give larger space to the user to hover */}
                    <div className="periods-count">
                        {periodsCount}
                        {showList && (
                            <HoverableList ref={HoverableListRef} title={listTitle} periods={periods} controlListVisibilty={() => controlListVisibilty()} />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default MonthlyBuildCurrentPeriodBreadcrumbs;
