import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { tryParse } from "../class/utils";


// Initialize Firebase
var config = tryParse(process.env.REACT_APP_FIREBASE_CONFIG);
var auth;
if (config) {
  const fb1 = initializeApp(config);
  auth = getAuth(fb1);
}

export {auth}



