import React, { Component } from 'react';


class LegendItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cssStyle: "delta-legend fs-14 black uk-margin-xsmall-top uk-margin-xsmall-bottom uk-text-bold"
        }
    }

    getTextColor = () => {
        let text = this.props.legendText;
        let cssStyle = this.state.cssStyle;

        if (text.includes("α")) {
            cssStyle = "fs-14 alpha_text uk-margin-xmedium-right uk-margin-xsmall-top uk-margin-xsmall-bottom"
        } else if (text.includes("β")) {
            cssStyle = "fs-14 beta_text uk-margin-xmedium-right uk-margin-xsmall-top uk-margin-xsmall-bottom"
        }

        this.setState({
            cssStyle: cssStyle
        })

    }

    componentDidMount() {
        this.getTextColor();
    }


    render() {
        return (
            <React.Fragment>
                <span className={this.state.cssStyle} style={{display:"flex"}} dangerouslySetInnerHTML={{ __html: this.props.legendText }}></span>
            </React.Fragment>
        )
    }
}


export default LegendItem;