import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {copyObjectValues, findOptionByKey} from "../../class/utils";
import {FETCH_METHOD, fetchAPI, FETCHAPI_PARAMS} from "../../class/networkUtils";
import {DROPDOWN_TYPE, NONE, SUPPLY_CHAIN_SETUP, VECTOR_GROUPS} from "../../class/constants";
import "./vectorsConfiguration.css";
import DropDown from "../../newComponents/DropDown";
import { updateVectorEntities } from "../../templateLayout/actions/vectorEntitiesActions";
import {
    getVectorsBasedOnEntityCount,
    getVectorsGeneratedUnderSpecificGroup,
} from "../../class/vectorsConfigurationUtils";
import { useDispatch } from "react-redux";
import { lang } from "../../language/messages_en";
import { getOutputScreenName } from "../../class/common";

const VectorsConfiguration = (props, ref) => {
    useImperativeHandle(ref, () => ({
        saveVectorsConf: (callback) => {
            saveVectorsConfiguration(callback);
        },
    }));

    const dispatch = useDispatch();

    const [vectorsConfiguration, setVectorsConfiguration] = useState(copyObjectValues(props?.vectorsConfiguration));
    const [originalVectorsConfiguration, setOriginalVectorsConfiguration] = useState(copyObjectValues(props?.vectorsConfiguration));
    const objectNone = {label: NONE.label, value: ""};

    useEffect(() => {
        if (JSON.stringify(vectorsConfiguration) !== JSON.stringify(originalVectorsConfiguration)) {
            props.setHasUnsavedChanges(true);
        }
    }, [vectorsConfiguration, originalVectorsConfiguration]);

    useEffect(() => {
        checkIfVectorExists();
    }, [props.vectors]);

    const returnVectorsArrayValues = () => {
        let vectorsArrayFiltered = props.vectors.filter(m => !m.isGroupTitle);
        let vectorsArray = vectorsArrayFiltered.map((m) => {
            return m.value
        })
        return vectorsArray;
    }

    const returnVectorsNameFromVectorsConfiguration = () => {
        let vectorConfigurationNameArrayFiltered = vectorsConfiguration.filter(f => f?.vector_name);
        let vectorConfigurationNameArray = vectorConfigurationNameArrayFiltered?.map((m) => {
            return m.vector_name;
        })
        return vectorConfigurationNameArray
    }

    const checkUnsavedChanges = (changedVectorsConfiguration, hasUnsavedChanges) => {
        for (let e of returnVectorsNameFromVectorsConfiguration()) {
            if (!returnVectorsArrayValues().includes(e) && e !== "") {
                changedVectorsConfiguration.map(changedVector => {
                    if (changedVector.vector_name === e) {
                        changedVector.vector_name = "";
                    }
                    return changedVector;
                });
                hasUnsavedChanges = true;
                return hasUnsavedChanges;
            }
        }
    }

    const checkIfVectorExists = () => {
        let changedVectorsConfiguration = copyObjectValues(vectorsConfiguration);
        let hasUnsavedChanges = false;
        setVectorsConfiguration(changedVectorsConfiguration);
        props.setHasUnsavedChanges(checkUnsavedChanges(changedVectorsConfiguration, hasUnsavedChanges));
    }


    const saveVectorsConfiguration = (callback) => {
        const query = {
            action: "saveVectorsConfiguration",
            vectorsConfiguration: JSON.stringify(vectorsConfiguration),
        };

        const onThenCallback = (data) => {
            if (data.success) {
                setOriginalVectorsConfiguration(vectorsConfiguration);
                props.setHasUnsavedChanges(false);
                dispatch(updateVectorEntities(undefined, [], true))
            }
            props.updateConfigurationObject(!!data.success, SUPPLY_CHAIN_SETUP.TABS_DETAILS.VECTORS.NAME, vectorsConfiguration);
            if(typeof callback === "function") {
                callback();
            }
        };

        const fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "saveVectorsConfiguration",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: SUPPLY_CHAIN_SETUP.API_URLS.SAVE_VECTORS_CONFIGURATION,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.requestDescription]: lang.observability.output.supply_chain.save_vectors,
            [FETCHAPI_PARAMS.screenName]: getOutputScreenName(window.location.href),
        };

        fetchAPI(fetchOptions);
    };

    const removeSelectedOptions = (vectorsOptions, vectorsConfigurationSelected) => {
        return vectorsOptions.filter(f => !vectorsConfigurationSelected.includes(f.value));
    }

    const setVectorsOptions = (vector) => {
        let vectorsOptions = copyObjectValues(props.vectors);
        let vectorsConfigurationSelected = vectorsConfiguration.map((m) => {return m.vector_name});
        vectorsOptions = removeSelectedOptions(vectorsOptions,vectorsConfigurationSelected);

        if (vector.vector_type === SUPPLY_CHAIN_SETUP.VECTORS_DROPDOWN_TYPE.LOWEST_VECTOR) {
            return getVectorsGeneratedUnderSpecificGroup(VECTOR_GROUPS.PRODUCT,vectorsOptions, objectNone);
        } else if (vector.vector_type === SUPPLY_CHAIN_SETUP.VECTORS_DROPDOWN_TYPE.PRODUCT_HIERARCHY || vector.vector_type === SUPPLY_CHAIN_SETUP.VECTORS_DROPDOWN_TYPE.CHANNEL) {
            return getVectorsBasedOnEntityCount(100, vectorsOptions, objectNone);
        } else if (vector.vector_type === SUPPLY_CHAIN_SETUP.VECTORS_DROPDOWN_TYPE.SUPPLY) {
            vectorsOptions.unshift(objectNone);
            return vectorsOptions;
        }
    }

    const updateVectorsConfiguration = (e, vector, vectorsConfig) => {
        vectorsConfig.map((m) => {
            if (m.vector_type === vector.vector_type) {
                m.vector_name = e.value;
            }
        })
        return vectorsConfig;
    }

    const handleVectorsChange = (e, vector) => {
        let vectorsConfig = copyObjectValues(vectorsConfiguration)
        let updatedVectors = updateVectorsConfiguration(e, vector, vectorsConfig)
        setVectorsConfiguration(updatedVectors);
    }

    const setVectorDropdownValue = (vector) => {
        let vectorOption = {};
        if ((vector.vector_name === "" || vector.vector_name === null) || (findOptionByKey(props.vectors, vector.vector_name).length === 0)) {
            vectorOption = objectNone;
        } else {
            vectorOption = findOptionByKey(props.vectors, vector.vector_name)
        }
        return vectorOption
    }

    const renderVectorComponent = (vector, index) => {
        return(
            <div className="vector-component">
                <div className="vector-text">
                    {vector.vector_text.split("<i>")[0]} 
                    {vector.vector_text.split("<i>").length > 0 ? <span className="italic-msg"> {vector.vector_text.split("<i>")[1]} </span> : ""}
                </div>
                <div>
                    <DropDown
                        id={"select_vector_"+index}
                        className="uk-width-xxlarge input__dropdown"
                        // value={vector.vector_name === null || vector.vector_name === undefined ? objectNone : findOptionByKey(props.vectors, vector.vector_name)}
                        value={setVectorDropdownValue(vector)}
                        options={setVectorsOptions(vector)}
                        onChange={(e) => handleVectorsChange(e, vector)}
                        type={DROPDOWN_TYPE.INPUT}
                    />
                </div>
            </div>
        );
    }


    const renderVectorsConfiguration = () => {
        return (
            <div className="vectors_body">
                {copyObjectValues(vectorsConfiguration)?.map((vector, index) => (
                    index % 2 === 0 && 
                    (<div className = "vector-row">
                        {renderVectorComponent(vector, index)}
                        {vectorsConfiguration[index + 1] && renderVectorComponent(vectorsConfiguration[index + 1], index)}
                    </div>
                    )))
                }
            </div>
        )
    }

    return (
        <>
            <div className="vectors_container">
                {renderVectorsConfiguration()}
            </div>
        </>
    );
};

export default forwardRef(VectorsConfiguration);
