import {advancedFormatCurrency} from "./number.js"
import {STATUS_ENUM, QUADRANTS} from "./constants"
import { convertEasternTZToLocal, formatDate } from "./utils.js";
import { convertPxToViewport } from "./formatting.js";

var $ = require('jquery');
// $.autocomplete = require('jquery-autocomplete');

var timestampFormatter = function(cell, formatterParams, onRendered){
    var v = cell.getValue();
    if(v){
        return new Date(v).toLocaleString();
    } else {
        return "-";
    }
};

var quadrantFormatter = function(cell, formatterParams, onRendered){
    var v = cell.getValue() || "";  //take empty string as value if undefined
    if(v) v = v.toLowerCase();
    var html = "<div class='quadrant ";
    if(v.indexOf("_") >= 0){
        html += v.toUpperCase();
    } else {
        if (v === QUADRANTS.IOP.name.toLowerCase() || v === QUADRANTS.IOP.value || v.indexOf(QUADRANTS.IOP.value) === 0) {
            html += "IOP green";
        } else if (v === QUADRANTS.PT.name.toLowerCase() || v === QUADRANTS.PT.value || v.indexOf(QUADRANTS.PT.value) === 0) {
            html += "PT cyan";
        } else if (v === QUADRANTS.MN.name.toLowerCase() || v === QUADRANTS.MN.value || v.indexOf(QUADRANTS.MN.value) === 0) {
            html += "MN yellow";
        } else if (v === QUADRANTS.CR.name.toLowerCase() || v === QUADRANTS.CR.value || v.indexOf(QUADRANTS.CR.value) === 0) {
            html += "CR orange";
        } else {
            html += "red";
        }
    }
    v = v.replace("_", ":").toUpperCase();
    html += "' >"+v+"</div>";
    return html;
};

var moneyFormatter = function(cell, formatterParams, onRendered) {
	var v = cell.getValue();
	var html = "";
	if(!formatterParams.symbol){
		formatterParams.symbol = "$";
	}
	if(v < 0) {
		html += "<div class='red'>";
	}
	else {
		html += "<div >";
	}
	html += advancedFormatCurrency(v, formatterParams.symbol, formatterParams.precision, formatterParams.bUnit);
	if(v < 0) {
		html += "</div>";
	}
	else {
		html += "</div>";
	}
	return html;
}

var createthreeDotsFormatter = function(callback){
    var threeDotsFormatter = function(cell, formatterParams, onRendered) {
        return "...";
    };
    return threeDotsFormatter;
};


var numberFormatter = function(cell, formatterParams, onRendered) {
	var v = cell.getValue();
	var html = "";
	if(v < 0) {
		html += "<div class='red'>";
	}
	else {
		html += "<div >";
	}
	html += advancedFormatCurrency(v, "", formatterParams.precision, formatterParams.bUnit);
	if(v < 0) {
		html += "</div>";
	}
	else {
		html += "</div>";
	}
	return html;
}

var percentFormatter = function(cell, formatterParams, onRendered) {
	var v = cell.getValue();
	var html = "";
    var formattedV = advancedFormatCurrency(v, "", formatterParams.precision, formatterParams.bUnit);
    if(formattedV === "-")
        return "<div>-</div>";

    if(v < 0) {
		html += "<div class='red'>" + formattedV + "%</div>";
	} else {
		html += "<div >" + formattedV + "%</div>";
	}
	return html;
}

var ratioFormatter = function(cell, formatterParams, onRendered) {
	var v = cell.getValue();
	var html = "";
	if(v < 0) {
		html += "<div class='red'>";
	}
	else {
		html += "<div >";
	}
	html += advancedFormatCurrency(Math.abs(v*100), "", formatterParams.precision, formatterParams.bUnit);
	if(v < 0) {
		html += "%</div>";
	}
	else {
		html += "%</div>";
	}
	return html;
}

var filesFormatter = function(cell, formatterParams, onRendered) {
    var v = cell.getValue();
    if(v === undefined) {	//if row is empty, do not add any elements
        return "";
    }
    var files = JSON.parse(v).files;
    if(files.length > 0) {
        var fileToOverwrite = files.map(function(item) { return item.value });
        return " <div style='white-space: initial; text-overflow: ellipsis; overflow: hidden;' title='"+fileToOverwrite.join("\n")+"'>"+ fileToOverwrite.join("\n")+"</div>";
        //    white-space: initial;overflow: auto;
    }
    return "";
}

var selectFormatter = function(cell, formatterParams) {
    var select = document.createElement("select");
    for (var option in formatterParams.options) {
        option  = formatterParams.options[option];

        var dropDownOption = document.createElement("option");
        if(typeof option === "object") {
            dropDownOption.value = option.value;
            dropDownOption.innerHTML = option.name;
        } else {
            dropDownOption.value = option;
            dropDownOption.innerHTML = option;
        }
        select.appendChild(dropDownOption);
    }

    if(cell.getValue() === "") {
        cell.setValue(formatterParams.defaultValue);
    }
    
    return select;
}

var textInputFormatter = function(cell, formatterParams) {
    var input = document.createElement("input");
    if(cell.getValue() === undefined) {
        cell.setValue(formatterParams.defaultValue);
    }

    input.value = cell.getValue();

    return input;
}


var statusFormatter = function(cell, formatterParams, onRendered) {
    if(cell.getValue() === undefined) {	//if row is empty, do not add any elements
        return "";
    }
    var value = STATUS_ENUM[cell.getValue()];

    var icon = document.createElement("i");
    icon.title = value;
    if(value === STATUS_ENUM.fail) {
        icon.classList.add("fas", "fa-times", "uk-margin-small-left");
    } else {
        icon.classList.add("fas", "fa-check", "uk-margin-small-left");
    }
    return icon;
};

var dateFormatter = function(cell, formatterParams, onRendered) {
    if(cell.getValue() === undefined) {	//if row is empty, do not add any elements
        return "";
    }
    return formatDate(convertEasternTZToLocal(cell.getValue()), formatterParams.format);
};

var minMaxFilterEditor = function(callback){
    var f = function(cell, onRendered, success, cancel, editorParams){
        var end;

        var dropDiv = $.parseHTML("<div id ='filter-dropdown-"+cell.getField()+"' class='animation: uk-animation-slide-top-small; duration: 300; uk-card uk-card-body uk-card-small uk-card-default'"+
            " uk-drop='boundary: .tabulator' style='width:auto; padding:"+ convertPxToViewport(10) +"; background: #f7f7f7; border"+ convertPxToViewport(1) +" solid #DCDCDC; border-radius:0; position: fixed; z-index: 1;' aria-hidden='true'></div>")[0];
            // #filter-dropdown-button-"+cell.getField()+":parent; '")[0];

        var dropCont = document.createElement("div");
        dropCont.setAttribute("id", "filter-dropdown-cont-"+cell.getField());
        dropCont.appendChild(dropDiv);
        var tableId = cell.getColumn().getTable().element.getAttribute("id");
        var old = $('#'+tableId+' #filter-dropdown-cont-'+cell.getField());
        if(old)
            $(old).remove();
        // document.body.appendChild(dropCont);
        cell.getColumn().getElement().appendChild(dropCont);    //add the filter div inside the tile div

        //create and style inputs
        var start = document.createElement("input");
        start.setAttribute("type", "number");  
        // start.setAttribute("jqueryui", "spinner");     //add spinner functionality
        start.setAttribute("placeholder", "Min");
        // start.setAttribute("min", Math.floor(editorParams.min));
        // start.setAttribute("max", Math.ceil(editorParams.max));
        start.style.padding = convertPxToViewport(4);
        start.style.width = convertPxToViewport(125);
        start.style.marginBottom = convertPxToViewport(5);
        start.style.border = convertPxToViewport(1) + " solid #DCDCDC";
        start.style.borderRadius = convertPxToViewport(4);
        start.style.fontWeight = "normal";
        start.style.boxSizing = "border-box";
        var step = Math.round((parseFloat(editorParams.max+"") - parseFloat(editorParams.min+""))/10000)*100;        
        // step = Math.round(step/Math.pow(10,(step+"").length))*Math.pow(10,(step+"").length);

        if(!(step >= 1 && step !== Infinity))
            step = 1;
        start.setAttribute("step", step);
        start.value = cell.getValue();
        end = start.cloneNode();
        
        var adjustEnd = function(e){
            e.stopPropagation();
            if(parseFloat(end.value) <= parseFloat(start.value)){
                end.value = parseFloat(start.value) + 1;
            }
        };
        var adjustStart = function(e){
            e.stopPropagation();
            if(parseFloat(end.value) <= parseFloat(start.value)){
                start.value = parseFloat(end.value) - 1;
            }
        };
        start.addEventListener("change", adjustEnd);
        start.addEventListener("click", adjustEnd);
        end.addEventListener("change", adjustStart);
        end.addEventListener("click", adjustStart);
        dropDiv.addEventListener("click", function(ev){
            ev.stopPropagation();
        });
        dropDiv.addEventListener("mousedown", function(ev){
            // this event will prevent the drop from being hidden if you click inside the drop and drag out
            $('#'+dropDiv.id).on({'beforehide': function(ev) {
                return false;
            }});
            ev.stopPropagation(); // disabling drag
        });
        // document.addEventListener("mouseup", function(ev){
        //     // this event will clear the beforehide listener so that the uikit drop will close when clicking outside the drop
        //     $('#'+dropDiv.id).off('beforehide');
        // });
        end.setAttribute("placeholder", "Max")

        var buildValues = function(e){
            var tableId = cell.getColumn().getTable().element.getAttribute("id");
            e.stopPropagation();
            // checkMinMax();
            if(start.value === "" && end.value === "")
            {
                success();
                $('#'+tableId+' #filter-dropdown-button-'+cell.getField()).removeClass('filtered');
            }
            else
            {
                success({ start:start.value, end:end.value });
                $('#'+tableId+' #filter-dropdown-button-'+cell.getField()).addClass('filtered');
            }

            $("*[id*=filter-dropdown-]:not('*[id*=filter-dropdown-cont]'):not('*[id*=filter-dropdown-button]').uk-open").off('beforehide');
            $("*[id*=filter-dropdown-]:not('*[id*=filter-dropdown-cont]'):not('*[id*=filter-dropdown-button]').uk-open").on('beforeshow', () => {
                $(".text-link[hidden]").removeAttr("hidden");
                $(".text-link[aria-hidden]").removeAttr("aria-hidden");
            });
            $('#'+tableId+' #filter-dropdown-button-'+cell.getField()).click();
        }

        var resetValues = function(e){
            e.stopPropagation();
            start.value = "";
            end.value = "";
            buildValues(e);
        }
        var obj = createGoClearButtons();
        var butGo = obj.butGo;
        var butClear = obj.butClear;

        butGo.addEventListener("click", buildValues);
        butClear.addEventListener("click", resetValues);

        var startDiv = document.createElement("div");
        startDiv.appendChild(start);
        var endDiv = document.createElement("div");
        endDiv.appendChild(end);
        var buttonsDiv = document.createElement("div"); //only added so that buttons appear under on their own level
        buttonsDiv.setAttribute("class", 'uk-flex justify-content-around');
        buttonsDiv.appendChild(butClear);
        buttonsDiv.appendChild(butGo);
        dropDiv.appendChild(startDiv);
        dropDiv.appendChild(endDiv);
        dropDiv.appendChild(buttonsDiv);

        //fixing css
        $(".tabulator-header").css("overflow","visible");

        return "";
    };
    return f;
 }

var createGoClearButtons = function(){
    var butGo = document.createElement("div");
    butGo.setAttribute("class", 'text-link');
    butGo.setAttribute("value", 'Apply');
    butGo.innerHTML = "Apply";
    butGo.style.display = "inline-flex";


    var butClear = butGo.cloneNode();
    butClear.innerHTML = "Clear";
    butClear.setAttribute("value", 'Clear');
    return {butGo:butGo, butClear:butClear};
}


var minMaxFilterFunction = function(headerValue, rowValue, rowData, filterParams){
    if(headerValue === undefined)
        return true;
    //headerValue - the value of the header filter element
    //rowValue - the value of the column in this row
    //rowData - the data for the row being filtered
    //filterParams - params object passed to the headerFilterFuncParams property
    var start = parseFloat(headerValue.start);
    var end = parseFloat(headerValue.end);
    var ret = false;
    rowValue = parseFloat(rowValue);

    if(!(start >= 0 || start < 0)) {
        start = "N/A";
    }
    if(!start) {
        start = "N/A";
    }
    
    if(!(end >= 0 || end < 0)) {
        end = "N/A";
    }
    if(!end) {
        end = "N/A";
    }
    
    if(rowValue >= 0 || rowValue < 0){
        if(rowValue >= start || start === "N/A") {
            if(rowValue <= end || end === "N/A") {
                ret = true;
            }
        }
    }
    return ret;

    // return false; //must return a boolean, true if it passes the filter.
}


var textFilterEditor = function(callback) {
    var f = function(cell, onRendered, success, cancel, editorParams){
        
        // var dropDiv = $.parseHTML("<div id ='filter-dropdown-"+cell.getField()+"' class='uk-card uk-card-body uk-card-small uk-card-default' uk-drop='mode:click; pos:bottom-left; "+
        //     " boundary:#filter-dropdown-button-"+cell.getField()+"; boundary-align: true' "+
        //     " style='width:auto'></div>")[0];
        var dropDiv = $.parseHTML("<div id ='filter-dropdown-"+cell.getField()+"' class='animation: uk-animation-slide-top-small; duration: 300; uk-card uk-card-body uk-card-small uk-card-default'"+
            " uk-drop='boundary: .tabulator' style='width:auto;  padding:"+ convertPxToViewport(10) +"; background: #f7f7f7; border"+ convertPxToViewport(1) +" solid #DCDCDC; border-radius:0; position: fixed; z-index: 1;' aria-hidden='true'></div>")[0];
            // #filter-dropdown-button-"+cell.getField()+":parent; '")[0];

        // var container = document.createElement("div");
        // container.setAttribute("class", "filter-div");

        if(!editorParams)
            editorParams = {};
        if(!editorParams.placeholder)
            editorParams.placeholder = "filter column..";

        var tableId = cell.getColumn().getTable().element.getAttribute("id");
        //create and style inputs
        var inputField = document.createElement("input");
        inputField.setAttribute("type", "text");
        inputField.setAttribute("placeholder", editorParams.placeholder);
        inputField.setAttribute('id', 'input-'+cell.getField()+tableId);
        inputField.style.padding = convertPxToViewport(4);
        inputField.style.width = convertPxToViewport(125);
        inputField.style.marginBottom =convertPxToViewport(5);
        inputField.style.border = convertPxToViewport(1) + " solid #DCDCDC";
        inputField.style.borderRadius = convertPxToViewport(4);
        inputField.style.boxSizing = "border-box";
        inputField.style.display = 'block';
        inputField.style.fontWeight = "normal";

        inputField.value = editorParams.headerFilters && editorParams.headerFilters.value ? editorParams.headerFilters.value.val : cell.getValue();
        var oldVal = inputField.value;

        var buildValues = function(){
            if(inputField.value !== oldVal) {
                if (inputField.value === "") {
                    success();
                    $('#'+tableId+' #filter-dropdown-button-'+cell.getField()).removeClass('filtered');
                } else {
                    success({val:inputField.value});
                    $('#'+tableId+' #filter-dropdown-button-'+cell.getField()).addClass('filtered');
                }
                if(typeof callback === "function") {
                    callback();
                }
            }
            oldVal = inputField.value;
            $("*[id*=filter-dropdown-]:not('*[id*=filter-dropdown-cont]'):not('*[id*=filter-dropdown-button]').uk-open").off('beforehide');
            $("*[id*=filter-dropdown-]:not('*[id*=filter-dropdown-cont]'):not('*[id*=filter-dropdown-button]').uk-open").on('beforeshow', () => {
                $(".text-link[hidden]").removeAttr("hidden");
                $(".text-link[aria-hidden]").removeAttr("aria-hidden");
            });
            
            $('#'+tableId+' #filter-dropdown-button-'+cell.getField()).click();
        }

        var resetValues = function(){
            inputField.value = null;
            buildValues();
        }
        

        var obj = createGoClearButtons();
        var butGo = obj.butGo;
        var butClear = obj.butClear;
        
        butGo.addEventListener("click", buildValues);
        butClear.addEventListener("click", resetValues);

        dropDiv.appendChild(inputField);
        var buttonsDiv = document.createElement("div");
        buttonsDiv.setAttribute("class", 'uk-flex justify-content-around');
        buttonsDiv.appendChild(butClear);
        buttonsDiv.appendChild(butGo);
        dropDiv.appendChild(buttonsDiv);
        dropDiv.addEventListener("click", function(ev){
            ev.stopPropagation();
        });
        dropDiv.addEventListener("mousedown", function(ev){
            // this event will prevent the drop from being hidden if you click inside the drop and drag out
            $('#'+dropDiv.id).on({'beforehide': function(ev) {
                return false;
            }});
            ev.stopPropagation(); // disabling drag
        });
        // document.addEventListener("mouseup", function(ev){
        //     // this event will clear the beforehide listener so that the uikit drop will close when clicking outside the drop
        //     $('#'+dropDiv.id).off('beforehide');
        // });

        var dropCont = document.createElement('div');
        dropCont.setAttribute("id", "filter-dropdown-cont-"+cell.getField());
        dropCont.appendChild(dropDiv);
        var tableId = cell.getColumn().getTable().element.getAttribute("id");
        var old = $('#'+tableId+' #filter-dropdown-cont-'+cell.getField());
        if(old)
            $(old).remove();
        document.body.appendChild(dropCont);
        cell.getColumn().getElement().appendChild(dropCont);    //add the filter div inside the tile div
        // $(document).ready(function(){
        //     $('#input-'+cell.getField()).autocomplete({
        //         source: editorParams.values
        //     });    
        // });
        return "";
     }
    return f;
}

 var textFilterFunction = function(headerValue, rowValue, rowData, filterParams){
    if(headerValue === undefined)
        return true;
    var headVal = (headerValue?headerValue.val:"")+"";
    var rowVal = rowValue+"";
    headVal = headVal.toLowerCase();
    rowVal = rowVal.toLowerCase();
    var ret = false;
    if(rowVal.toLowerCase().replaceAll(" ","").indexOf(headVal.toLowerCase().replaceAll(" ","")) >= 0)
        ret = true;
    return ret;
}

var selectFilterEditor = function(callback) {
    var f = function(cell, onRendered, success, cancel, editorParams){

        var end;        
        var dropDiv = $.parseHTML("<div id ='filter-dropdown-"+cell.getField()+"' class='animation: uk-animation-slide-top-small; duration: 300; uk-card uk-card-body uk-card-small uk-card-default'"+
            " uk-drop='boundary: .tabulator' style='width:auto;padding:"+ convertPxToViewport(10) +"; background: #f7f7f7; border"+ convertPxToViewport(1) +" solid #DCDCDC; border-radius:0; position: fixed; z-index: 1;' aria-hidden='true'></div>")[0];

        if(!editorParams)
            editorParams = {};
        if(!editorParams.placeholder)
            editorParams.placeholder = "filter column..";

        //create and style inputs
        var inputField = document.createElement("select");
        //Create and append the options
        var array = cell.getColumn().getDefinition().headerFilterParams.values;
        for (var i = 0; i < array.length; i++) {
            var option = document.createElement("option");
            option.value = array[i];
            option.text = array[i];
            inputField.appendChild(option);
        }

        inputField.setAttribute("placeholder", editorParams.placeholder);
        inputField.setAttribute('id', 'input-'+cell.getField())
        inputField.style.padding = convertPxToViewport(4);
        inputField.style.width = convertPxToViewport(100);
        inputField.style.fontWeight = "normal";
        inputField.style.boxSizing = "border-box";
        inputField.style.display = 'block';
        inputField.value = cell.getValue();
        var oldVal = "";

        var buildValues = function(){
            var tableId = cell.getColumn().getTable().element.getAttribute("id");
            if(inputField.value !== oldVal)
            {
                if (inputField.value === "") {
                    success();
                    $('#'+tableId+' #filter-dropdown-button-'+cell.getField()).removeClass('filtered');
                } else {
                    success({val:inputField.value});
                    $('#'+tableId+' #filter-dropdown-button-'+cell.getField()).addClass('filtered');
                }
                if(typeof callback === "function") {
                    callback();
                }
            }
            oldVal = inputField.value;

            $("*[id*=filter-dropdown-]:not('*[id*=filter-dropdown-cont]'):not('*[id*=filter-dropdown-button]').uk-open").off('beforehide');
            $("*[id*=filter-dropdown-]:not('*[id*=filter-dropdown-cont]'):not('*[id*=filter-dropdown-button]').uk-open").on('beforeshow', () => {
                $(".text-link[hidden]").removeAttr("hidden");
                $(".text-link[aria-hidden]").removeAttr("aria-hidden");
            });

            $('#'+tableId+' #filter-dropdown-button-'+cell.getField()).click();
        }

        var resetValues = function(){
            inputField.value = null;
            buildValues();
        }
        
        var obj = createGoClearButtons();
        var butGo = obj.butGo;
        var butClear = obj.butClear;
        
        butGo.addEventListener("click", buildValues);
        butClear.addEventListener("click", resetValues);

        dropDiv.appendChild(inputField);
        var buttonsDiv = document.createElement("div");
        buttonsDiv.setAttribute("class", 'uk-flex justify-content-around');
        buttonsDiv.appendChild(butClear);
        buttonsDiv.appendChild(butGo);
        dropDiv.appendChild(buttonsDiv);
        dropDiv.addEventListener("click", function(ev){
            ev.stopPropagation();
        });
        dropDiv.addEventListener("mousedown", function(ev){
            // this event will prevent the drop from being hidden if you click inside the drop and drag out
            $('#'+dropDiv.id).on({'beforehide': function(ev) {
                return false;
            }});
            ev.stopPropagation(); // disabling drag
        });
        // document.addEventListener("mouseup", function(ev){
        //     // this event will clear the beforehide listener so that the uikit drop will close when clicking outside the drop
        //     $('#'+dropDiv.id).off('beforehide');
        // });

        var dropCont = document.createElement('div');
        dropCont.setAttribute("id", "filter-dropdown-cont-"+cell.getField());
        dropCont.appendChild(dropDiv);
        var tableId = cell.getColumn().getTable().element.getAttribute("id");
        var old = $('#'+tableId+' #filter-dropdown-cont-'+cell.getField());
        if(old)
            $(old).remove();
        document.body.appendChild(dropCont);
        cell.getColumn().getElement().appendChild(dropCont);    //add the filter div inside the tile div
        return "";
     }
    return f;
}

var selectFilterFunction = function(headerValue, rowValue, rowData, filterParams){
    if(headerValue === undefined)
        return true;
    var headVal = (headerValue?headerValue.val:"")+"";
    var rowVal = rowValue+"";
    headVal = headVal.toLowerCase();
    rowVal = rowVal.toLowerCase();
    var ret = false;
    if(rowVal.indexOf(headVal) >= 0)
        ret = true;
    if(filterParams.hasOwnProperty("childs") && filterParams.hasOwnProperty("field") && rowData.hasOwnProperty(filterParams.childs) && rowData[filterParams.childs].length > 0) {
        for(var i in rowData[filterParams.childs]) {
            var child = rowData.children[i];
            var found = selectFilterFunction(headerValue, child[filterParams.field], child, filterParams);
            if (found) return found;
        }
    }
    return ret;
}

var createExpandableGroupTitle = function(col, funkName) {
    var titleFormatter = function(cell, formatterParams) {
        var title = cell.getValue();
        return "<div class='filter-expand-but' id='"+funkName+"' onclick='window[\""+funkName+"\"]();' ></div>" + title;
    };
    return titleFormatter;
};

/**
 * 
 * @author [Lara Jannoun]
 */
var tickCross = function(cell) {
    var v = cell.getValue();
    var html = "";

    if(v === 'fail') {
        html += "<div class='red'>✗</div>";
    } else if(v === 'pass'){
        html += "<div class='greenText'>✓</div>";
    } else if(v === 'warning'){
        html += "<div class='orangeText'>⚠</div>";
    }

    return html;
}

/**
 * 
 * @author [Lara Jannoun]
 */
var menuDropDownFormatter = function (cell, options) {
    var html = "";
    html += "<div class='d-inline-block' id='"+options.id+"'>";
    html += "    <button id='uk-dropdown-icon' class='uk-button-icon dots-button transparent-bg add-row'>";
    html += "       <i class='far fa-plus-circle fa-lg'></i>";
    html += "    </button>";
    html += "   <div id='dropdown-container' uk-dropdown='mode: click; pos: bottom-left; offset: 5' class='in-dropdown-container uk-position-fixed width-150'>";
    html += "       <div class='uk-display-grid'>";
    for (var i in options.fields) {
        html += "<span id='"+options.fields[i].id+"' class='uk-button-icon'>"+options.fields[i].displayName+"</span>";
    }
    html += "       </div>";
    html += "   </div>";
    html += "</div>";
    
    return html;
}

function noMatchFilterFunction(headerValue, rowValue, rowData, filterParams) {
    const formattedRowValue = rowValue ? rowValue.toLowerCase() : "no match";
    const formattedHeaderValue = headerValue ? headerValue.val?.toLowerCase() : "";

    // Check if the formatted row value contains the header value
    return formattedRowValue.includes(formattedHeaderValue);
}

export { timestampFormatter, statusFormatter, dateFormatter, filesFormatter, ratioFormatter, percentFormatter, numberFormatter, moneyFormatter, quadrantFormatter, minMaxFilterEditor, minMaxFilterFunction, 
    textFilterEditor, textFilterFunction, selectFilterEditor, selectFilterFunction, createExpandableGroupTitle, createthreeDotsFormatter, selectFormatter, textInputFormatter, 
    tickCross, menuDropDownFormatter, noMatchFilterFunction };