import { FILTER } from "../../class/constants";
import { lang } from "../../language/messages_en";

function addCorrespondingParenthesisToFilter(filters) {
  const _parenthesisBefore = FILTER.KEYS.PARENTHESIS_BEFORE;
  const _parenthesisAfter = FILTER.KEYS.PARENTHESIS_AFTER;
  const _logicalOperator = FILTER.KEYS.LOGICAL_OPERATOR;
  const _OR = lang.ui_filter.dropdowns.buttons.OR;

  if(filters === "string") {
      filters = JSON.parse(filters);
  }

  filters.map((filter, index) => {
      let operator = "";
      operator = filter[_logicalOperator];

      filter[_parenthesisBefore] = "";  //reset parenthesis
      filter[_parenthesisAfter] = "";  //reset parenthesis

      filters[0][_parenthesisBefore] = "(";
      filters[filters.length-1][_parenthesisAfter] = ")";

      if(operator === _OR && index > 0) {
          filters[index-1][_parenthesisAfter] = ")";
          filter[_parenthesisBefore] = "(";
      }
  });

  return filters;
}

export {addCorrespondingParenthesisToFilter};