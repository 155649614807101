import {forwardRef} from "react";
import Button from "../../../newComponents/Button";
import { BUTTON_TYPE, BUTTON_VARIANT, SIZES } from "../../../class/constants";
import { getTranslationFile } from "../../../class/utils";

const lang = getTranslationFile();


const PaginationNextPrev = (props, ref) => {

        const {selected} = props;
        const {total} = props;
        const {changeCard} = props;


    return (
        <div className="prev-next-pagination">
            <div>
                <Button 
                        label={lang.navigation.btns.previous}
                        variant={BUTTON_VARIANT.TERTIARY}
                        size={SIZES.DEFAULT}
                        type={BUTTON_TYPE.DEFAULT}
                        className={"uk-text-xmedium uk-margin-small-left"}
                        onBtnClick={()=>changeCard(Number(selected)-1)}
                        disabled={selected == 1}
                    />
            </div>
           
            <div>{selected + "/"+ total}</div>
            <div>
                <Button 
                        label={lang.navigation.btns.next}
                        variant={BUTTON_VARIANT.TERTIARY}
                        size={SIZES.DEFAULT}
                        type={BUTTON_TYPE.DEFAULT}
                        className={"uk-text-xmedium uk-margin-small-left"}
                        disabled={selected == total}
                        onBtnClick={()=>changeCard(Number(selected)+1)}
                    />
            </div>
        </div>
    );
};

export default forwardRef(PaginationNextPrev);
