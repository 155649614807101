
// Converts a #ffffff hex string into an [r,g,b] array
var h2r = function(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16)
  ] : null;
};

// Inverse of the above
var r2h = function(rgb) {
  return "#" + ((1 << 24) + (rgb[0] << 16) + (rgb[1] << 8) + rgb[2]).toString(16).slice(1);
}

var _interpolateColor = function(color1, color2, factor) {
    if (arguments.length < 3) { factor = 0.5; }
    var result = color1.slice();
    for (var i=0;i<3;i++) {
      result[i] = Math.round(result[i] + factor*(color2[i]-color1[i]));
    }
    return result;
  };

function interpolateFromTo(col1, col2, factor) {
    return r2h(_interpolateColor(h2r(col1), h2r(col2), factor));
}

/**
 * calculate the color based on the value
 * @param {*} value 
 * @param {*} minValue 
 * @param {*} maxValue 
 * @param {*} minColor 
 * @param {*} maxColor 
 * @returns 
 */
function interpolateColor(value, minValue, maxValue, minColor, maxColor) {
  // Normalize the value between 0 and 1
  const normalizedValue = maxValue === minValue ? 1 : (value - minValue) / (maxValue - minValue);

  // Extract the RGB components of the minimum and maximum colors
  const [minRed, minGreen, minBlue] = minColor;
  const [maxRed, maxGreen, maxBlue] = maxColor;

  // Interpolate the color components based on the normalized value
  const interpolatedRed = Math.round(minRed + (maxRed - minRed) * normalizedValue);
  const interpolatedGreen = Math.round(minGreen + (maxGreen - minGreen) * normalizedValue);
  const interpolatedBlue = Math.round(minBlue + (maxBlue - minBlue) * normalizedValue);

  // Return the interpolated color as a CSS-formatted string
  return `rgb(${interpolatedRed}, ${interpolatedGreen}, ${interpolatedBlue})`;
}

function generateColorGradient (startColor, endColor, numColors) {
  const startR = parseInt(startColor.substring(1, 3), 16);
  const startG = parseInt(startColor.substring(3, 5), 16);
  const startB = parseInt(startColor.substring(5, 7), 16);

  const endR = parseInt(endColor.substring(1, 3), 16);
  const endG = parseInt(endColor.substring(3, 5), 16);
  const endB = parseInt(endColor.substring(5, 7), 16);

  const stepR = (endR - startR) / (numColors - 1);
  const stepG = (endG - startG) / (numColors - 1);
  const stepB = (endB - startB) / (numColors - 1);

  const colors = [];

  for (let i = 0; i < numColors; i++) {
    const r = Math.round(startR + stepR * i);
    const g = Math.round(startG + stepG * i);
    const b = Math.round(startB + stepB * i);

    const color = `rgb(${r}, ${g}, ${b})`;
    colors.push(color);
  }
  return colors;
};

export {interpolateFromTo, interpolateColor, generateColorGradient }