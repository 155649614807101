import { Box, ClickAwayListener } from "@mui/material";
import { useState } from "react";
import { ReactComponent as RoundInfoIcon } from "../styles/images/menu-svgs/info.svg";
import Button from "../newComponents/Button";
import { ALL_WIDGETS, BUTTON_TYPE, BUTTON_VARIANT, SIZES } from "../class/constants";
import { lang } from "../language/messages_en";
import { getSectionExists } from "../class/utils";
import { ReactComponent as LinkOpener } from "../styles/images/arrow-up-right-from-square-link.svg";
import $ from 'jquery';
import { redirectToPSLineDefinitionsScreen, redirectToVectorDefinitionsScreen, redirectToModelCoherenceScreen } from "./functions/componentFunctions";
import Label from "../newComponents/Label";
const HeaderInfo = ({scenario, userAllowedMenuLinks, setProfitSegmentationOpen}) => {
  const [open, setOpen] = useState(false);
  let isListofVectorDefinitionsAllowed = getSectionExists(userAllowedMenuLinks, ALL_WIDGETS.FIELDS.LIST_OF_VECTOR_DEFINITIONS);
  let isListofProfitStackLineDefinitionsAllowed = getSectionExists(
    userAllowedMenuLinks,
    ALL_WIDGETS.FIELDS.PROFIT_STACK_LINE_DEFINITIONS
  );
  let isModelCoherenceAllowed = getSectionExists(
      userAllowedMenuLinks,
      ALL_WIDGETS.FIELDS.MODEL_COHERENCE
  );
  
  
  const handleClick = () => {
    setOpen((prev) => !prev);
    const currentColor = $('.info-icon-div').css('background-color');
   
    const newColor = currentColor === 'rgb(241, 242, 253)' ? 'white' : '#F1F2FD';
    $('.info-icon-div').css('background-color', newColor);
    $('.info-icon-nav-header').css('background-color', newColor);

  };
  
  const handleClickAway = () => {
    setOpen(false);
    $('.info-icon-nav-header').css('background-color', 'white');
    $('.info-icon-div').css('background-color', 'white');
  };
  
  const onVectorsDefClick = (event) => {
    redirectToVectorDefinitionsScreen(event, scenario);
    handleClick(); //close dropdown after redirecting
  };

  const onListOfPslDefClick = (event) => {
    redirectToPSLineDefinitionsScreen(event, scenario);
    handleClick(); //close dropdown after redirecting
  };

  const onModelCoherenceClick = (event) => {
    redirectToModelCoherenceScreen(event, scenario);
    handleClick(); //close dropdown after redirecting
  };

  const onProfitSegmentClick = (open) => {
    // document.getElementById("profit_segment_definition_image_link").click();
    setProfitSegmentationOpen(open)
  };
 
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box sx={{ position: "relative" }}>
        <div className="info-icon-div" style={{ display: "flex" }}>
          <RoundInfoIcon   className="uk-cursor-pointer info-icon-nav-header" onClick={handleClick} />
        </div>
        <div uk-lightbox="animation: fade;" style={{display:"none"}}>
          {/*<a*/}
          {/*  id="profit_segment_definition_image_link"*/}
          {/*  // href="\images\profit_segments.png"*/}
          {/*  // href={imagesSignedUrls[0]?.url}*/}
          {/*  data-caption={lang.profit_segment_definition.profit_segment_definition_title}*/}
          {/*/>*/}
        </div>
       
        {open ? (
            <div id="vectorDefinitionContainer" className={"vectorDefinitionContainer-popover"}>
              <div className="vector-definitions-title-div ">
                <span className="vector-definitions-text">{lang.your_configuration_model}</span>
              </div>
              <div className="vector-definitions-button-div">
                {isListofVectorDefinitionsAllowed && (
                    <Button
                        label={lang.vector_definitions.vector_definitions_title}
                        title={lang.vector_definitions.vector_definitions_title}
                        variant={BUTTON_VARIANT.TERTIARY}
                        size={SIZES.DEFAULT}
                        type={BUTTON_TYPE.DEFAULT}
                        className="uk-button-icon justify-content-start"
                        onBtnClick={onVectorsDefClick}
                        rightIcon={<LinkOpener/>}
                    />
                )}
              </div>
              <div className="vector-definitions-button-div">
                {isListofProfitStackLineDefinitionsAllowed && (
                    <Button
                        label={lang.profit_stack_line_definitions.profit_stack_line_definitions_title}
                        title={lang.profit_stack_line_definitions.profit_stack_line_definitions_title}
                        variant={BUTTON_VARIANT.TERTIARY}
                        size={SIZES.DEFAULT}
                        type={BUTTON_TYPE.DEFAULT}
                        className={"uk-button-icon justify-content-start"}
                        onBtnClick={onListOfPslDefClick}
                        rightIcon={<LinkOpener/>}
                    />
                )}
              </div>
              <div className="vector-definitions-button-div">
                {isModelCoherenceAllowed && (
                    <Button
                        label={lang.model_coherence.model_coherence_title}
                        title={lang.model_coherence.model_coherence_title}
                        variant={BUTTON_VARIANT.TERTIARY}
                        size={SIZES.DEFAULT}
                        type={BUTTON_TYPE.DEFAULT}
                        className={"uk-button-icon justify-content-start"}
                        onBtnClick={onModelCoherenceClick}
                        rightIcon={<span><span><Label size="xsmall" value="BETA"></Label></span> <span className="uk-margin-xsmall-left"> <LinkOpener/></span></span> }
                    />
                )}
              </div>
              <div className="vector-definitions-title-div">
                <span className="vector-definitions-text">{lang.profit_isle_methodology}</span>
              </div>
              <div className="vector-definitions-button-div">
                <Button
                    label={lang.profit_segment_definition.profit_segment_definition_title}
                    title={lang.profit_segment_definition.profit_segment_definition_title}
                    variant={BUTTON_VARIANT.TERTIARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    className={"uk-button-icon justify-content-start"}
                    onBtnClick={() => onProfitSegmentClick(true)}
                />
              </div>
            </div>
        ) : null}
      </Box>
    </ClickAwayListener>
  );
};

export default HeaderInfo;