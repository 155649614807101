import {MENU_ITEM, SECTION} from './constants.js';

function getSectionExists(sections, sectionReturnName) {
	var exists = false;
	for(var section in sections) {
		section = sections[section];

		if(section[SECTION.RETURN_NAME] === sectionReturnName) {
			exists = true;
			break;
		}
	}

	return exists;
}

function getParentSection(sections, sectionReturnName) {
	var parentSection = "";
	sections.forEach((section, index) => {
		if(section[SECTION.RETURN_NAME] === sectionReturnName) {
			parentSection = getSectionById(sections, section[SECTION.PARENT_ID]);
		}
	});

	return parentSection;
}

function getSectionById(sections, id) {
	var section = "";
	sections.forEach((tempSection, index) => {
		if(tempSection[SECTION.ID] === id) {
			section = tempSection;
		}
	});

	return section;
}

function getSectionChildren(sections, sectionReturnName) {
	var children = [];
	sections.forEach((section, index) => {
		if(section[SECTION.RETURN_NAME] === sectionReturnName) {
			let parentId = section[SECTION.ID];

			sections.forEach((subSection) => {
				if(subSection[SECTION.PARENT_ID] === parentId && subSection[SECTION.PARENT_ID] !== subSection[SECTION.ID]) {
					//so that it wont return parent sections that have their id equal to their parent id
					children.push(subSection);
				}
			});
		}
	});

	return children;
}

/**
 * get all modules and parents hat we can select as destination for a custom report.
 * @param {*} destinationReports 
 * @returns 
 */
function getDestinationReports(destinationReports) {
	// filter on reporting childs
	destinationReports = destinationReports?.filter(e=>e[MENU_ITEM.COLUMNS.MENU_ITEM_CATEGORY] === MENU_ITEM.CATEGORIES.REPORTING && e.menu_item_type === 'link_group');
	// Process each filtered report to create the dropdown format
	const formattedReports = destinationReports?.map(report => {
		// Create a new node for the current report
		const formattedNode = {
			label: report.menu_item_display_name,
			value: report.id
		};
		// Recursively process children
		formattedNode.children = getDestinationReports(report.children);
		return formattedNode;
	});
	return formattedReports || [];
}


/**
 * get the first url of the selected module
 * if i choose financial analytics, it returns /financial/stacks/mom url
 * @param {*} item module object (ex:Supply chain management)
 * @returns the url of the first child the user has access to in the selected module
 */
const getFirstURLofModule = (item) => {
  if(!item) {
    return "";
  }

  if(item?.children.length === 0) {
    return item.menu_item_machine_name;
  }

  if(item?.children.length > 0 && item.children.filter(f => f.category === 'menu_item').length === 0) {
    return item.menu_item_machine_name;
  }

  if(item?.children.length > 0) {
    return item.menu_item_machine_name + "/" + getFirstURLofModule(item.children[0]);
  }
}

/**
 * gets the report url from its parent object
 * @param {*} reportLinks linearized menu links
 * @param {*} selectedReport the selected report name
 * @returns 
 */
const getReportURLWithoutReportName = (reportLinks, selectedReport) => {
  if(!selectedReport.parent_machine_name || reportLinks?.length === 0) {
    return "";
  }

  let newItem = reportLinks.find(f => f.menu_item_machine_name === selectedReport.parent_machine_name);
  if(!newItem) {
    return selectedReport.menu_item_machine_name
  }

  return getReportURLWithoutReportName(reportLinks, newItem) + "/" + newItem.menu_item_machine_name;
}


/**
 * @function getMenuLinksChildren()
 * It takes userAllowedMenuLinks as data and find children of menu items
 * by sending corresponding params equal to items machine name
 */
function getMenuLinksChildren(data, linkGroup, link) {
  let menuItem = [];
  if (data) {
    const linkGroupItem = data.find(e => e.menu_item_machine_name === linkGroup);
    if (linkGroupItem) {
      menuItem = linkGroupItem.children.find(f => f.menu_item_machine_name === link);
    }
  }

  return menuItem;
}

export {
  getSectionExists,
  getParentSection,
  getSectionById,
  getSectionChildren,
  getDestinationReports,
  getFirstURLofModule,
  getReportURLWithoutReportName,
  getMenuLinksChildren,
};
