import { API_URL } from "../../class/constants";
import { FETCHAPI_PARAMS, FETCH_METHOD, accessNestedProperty, fetchAPI } from "../../class/networkUtils";
import { lang } from '../../language/messages_en.js';


function getProfitStackLines(scenarioId, removeAttributes, callback,showCustomizedLoader = false ,section) {
    var query = {
        action: "getProfitStackFields",
        scenario_id: scenarioId,
        removeAttributes: removeAttributes ? removeAttributes : "false"
    }

    var onThenCallback = (data)=>{
        if (data) {
            callback(data);
        }
    }

    var fetchOptions = {
        [FETCHAPI_PARAMS.funcName]: "getProfitStackLines",
        [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
        [FETCHAPI_PARAMS.showLoader]: true,
        [FETCHAPI_PARAMS.showCustomizedLoader]:showCustomizedLoader,
        [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
        [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
        [FETCHAPI_PARAMS.query]: query,
        [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
        [FETCHAPI_PARAMS.screenName]:accessNestedProperty(section,lang.observability)?.screen_name,
        [FETCHAPI_PARAMS.requestDescription]:accessNestedProperty(section,lang.observability)?.requests_description?.get_profit_stack_fields,
    }
    fetchAPI(fetchOptions);
}

function getCalculatedColumns(scenarioId, period, respectExclusions, callback, section) {
    var query = {
        action: "getCalculatedColumns",
        scenario_id: scenarioId,
        timePeriod: period,
        respectExclusions: respectExclusions,    //either from PS mapping (split by transaction) or from main Calc Cols page
    }

    var onThenCallback = (data)=>{
        if (data) {
            callback(data);
        }
    }

    var fetchOptions = {
        [FETCHAPI_PARAMS.funcName]: "getCalculatedColumns",
        [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
        [FETCHAPI_PARAMS.showLoader]: true,
        [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
        [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
        [FETCHAPI_PARAMS.query]: query,
        [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
        [FETCHAPI_PARAMS.screenName]:accessNestedProperty(section,lang.observability)?.screen_name,
        [FETCHAPI_PARAMS.requestDescription]:accessNestedProperty(section,lang.observability)?.requests_description?.get_calculated_columns,
    }

    fetchAPI(fetchOptions);
}


function stageCCNewArch(scenarioId, period, respectExclusions, callback,section) {
    const query = {
        action: "stageCalculatedColumnsNewArch",
        scenario_id: scenarioId,
        periodsData: period,
        respectExclusions: respectExclusions    //either from PS mapping (split by transaction) or from main Calc Cols page
    }

    const onThenCallback = (data)=>{
        if (data) {
            callback(data);
        }
    }

    const fetchOptions = {
        [FETCHAPI_PARAMS.funcName]: "stageCalculatedColumnsNewArch",
        [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
        [FETCHAPI_PARAMS.showLoader]: true,
        [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
        [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
        [FETCHAPI_PARAMS.query]: query,
        [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
        [FETCHAPI_PARAMS.periods]: period,
        [FETCHAPI_PARAMS.screenName]:accessNestedProperty(section,lang.observability)?.screen_name,
        [FETCHAPI_PARAMS.requestDescription]:accessNestedProperty(section,lang.observability)?.requests_description?.stage_calculated_columns
    }

    fetchAPI(fetchOptions);
}

function stageMetricsNewArch(scenarioId, period, callback,section) {
    const query = {
        action: "stageMetricsNewArch",
        scenario_id: scenarioId,
        periodsData: period,
    }

    const onThenCallback = (data)=>{
        if (data) {
            callback(data);
        }
    }

    const fetchOptions = {
        [FETCHAPI_PARAMS.funcName]: "stageMetricsNewArch",
        [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
        [FETCHAPI_PARAMS.showLoader]: true,
        [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
        [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
        [FETCHAPI_PARAMS.query]: query,
        [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
        [FETCHAPI_PARAMS.periods]: period,
        [FETCHAPI_PARAMS.screenName]:accessNestedProperty(section,lang.observability)?.screen_name,
        [FETCHAPI_PARAMS.requestDescription]:accessNestedProperty(section,lang.observability)?.requests_description?.stage_metrics
    }

    fetchAPI(fetchOptions);
}

function stageExclusionNewArch(scenarioId, period, callback,section) {
    const query = {
        action: "stageExclusionNewArch",
        scenario_id: scenarioId,
        periodsData: period,
    }

    const onThenCallback = (data)=>{
        if (data) {
            callback(data);
        }
    }

    const fetchOptions = {
        [FETCHAPI_PARAMS.funcName]: "stageExclusionNewArch",
        [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
        [FETCHAPI_PARAMS.showLoader]: true,
        [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
        [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
        [FETCHAPI_PARAMS.query]: query,
        [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
        [FETCHAPI_PARAMS.periods]: period,
        [FETCHAPI_PARAMS.screenName]:accessNestedProperty(section,lang.observability)?.screen_name,
        [FETCHAPI_PARAMS.requestDescription]:accessNestedProperty(section,lang.observability)?.requests_description?.stage_exclusions
    }

    fetchAPI(fetchOptions);
}

export {getProfitStackLines, getCalculatedColumns, stageCCNewArch, stageMetricsNewArch, stageExclusionNewArch}