import React, { Component } from 'react';
import FilterByPSS from './FilterByPSS';
import { ALL_WIDGETS } from '../../class/constants';
import { getTranslationFile} from '../../class/utils';
import '../../styles/dataModeling.css';
import FilterByGLSource from './FilterByGLSource';

const lang = getTranslationFile();

/**
 *  GLHeader is the header component of the GLCombinations component in the profit stack mapping page
 * @author [Mostafa Haydar]
 * @extends Component
 * **/

class GLHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headerElements:[ALL_WIDGETS.FIELDS.PS_MAPPING.EXCLUDE, ALL_WIDGETS.FIELDS.PS_MAPPING.UNASSIGN,ALL_WIDGETS.FIELDS.PS_MAPPING.FLIP_SIGN, ALL_WIDGETS.FIELDS.PS_MAPPING.FILTER_BY_PSS],
            headerElements2:[ALL_WIDGETS.FIELDS.PS_MAPPING.FILTER_BY_GL_SOURCE, ALL_WIDGETS.FIELDS.PS_MAPPING.EXPORT]
        };

        this.isLoading = false;
    }

    componentDidMount() {
    
    }

    renderHeaderElement(component) {
        let _this = this;
        switch (component) {
            case ALL_WIDGETS.FIELDS.PS_MAPPING.UNASSIGN: {
                return <React.Fragment key={"assign_"+component}>
                    <div id="assign_icon" type="button" title="Unassign Combinations" className={"uk-button-icon "+(_this.props.unassignDisabled? " disabled" :"")} onClick={()=>(_this.props.unassignDisabled? "" :_this.props.updateAssignedStatus(ALL_WIDGETS.FIELDS.PS_MAPPING.UNASSIGN,""))} >
                        <i className={"uk-margin-small-right fa-lg fal fa-minus-circle"} />{lang.accruals.actions.unassign}
                    </div>
                </React.Fragment>
            }
            case ALL_WIDGETS.FIELDS.PS_MAPPING.EXCLUDE: {
                return <React.Fragment key={"include_"+component}>
                    <div id="include_icon" type="button" title="Exclude Combinations" className={"uk-button-icon"+(_this.props.excludeDisabled? " disabled" :"")}  onClick={() =>(_this.props.excludeDisabled? "" : _this.props.updateAssignedStatus(ALL_WIDGETS.FIELDS.PS_MAPPING.EXCLUDE,""))} >
                        <i className="fa-lg fal fa-ban uk-margin-small-right" />{lang.accruals.actions.exclude}
                    </div>
                </React.Fragment>
            }
            case ALL_WIDGETS.FIELDS.PS_MAPPING.FLIP_SIGN: {
                return <React.Fragment key={"flip_sign"+component}>
                    <div id="flip_sign_icon" type="button" title="Flip Sign" className={"uk-button-icon "+(_this.props.flipSignDisabled? " disabled" :"")}  onClick={() =>(_this.props.flipSignDisabled? "" : _this.props.flipSign(undefined, true))} >
                        <i className="fa-lg fal fa-exchange uk-margin-small-right" />{lang.ps_mapping.flipSign}
                    </div>
                </React.Fragment>
            }
            case ALL_WIDGETS.FIELDS.PS_MAPPING.FILTER_BY_PSS: {
                return <div key={"filter_"+component}>
                    <label className="fs-14 uk-margin-xsmall-bottom">{lang.assigned_to_pss_filter_label}</label>
                    <FilterByPSS  onNodeToggle = {this.props.onNodeToggle} showPartiallySelected={true} showDropdown = {this.props.showDropdown} onChange={this.props.onChange} data={this.props.pssFilterStructureData} id={this.props.id} texts={{ placeholder: lang.filter_combinations }} />
                </div>
            }
            case ALL_WIDGETS.FIELDS.PS_MAPPING.FILTER_BY_GL_SOURCE: {
                return <FilterByGLSource  ref={el=>this.filterGLRef = el} key={"filter_by_gl_"+component} resetCheckedCombinations={this.props.resetCheckedCombinations} applyFilter={this.props.applyFilter} clearFilter={this.props.clearFilter} data={this.props.glSourceData} />
            }
            case ALL_WIDGETS.FIELDS.PS_MAPPING.EXPORT: {
                return <div key={"export_"+component} id="Export_Drill_Table" title={lang.header.tooltips.xls} className="uk-button-icon " onClick={this.props.exportTableToExcel}>
                    <i className="fal fa-file-excel fa-lg" aria-hidden="true" />
                    <span className="uk-padding-xsmall-left">{lang.header.titles.xls_short}</span>
                </div>
            }
        }
    }

    renderHeaderElements(headerElements) {
        let _this = this;
        let comps = headerElements.map((value)=> {
            return _this.renderHeaderElement(value);
        });
        return comps
    }

    render() {
        let headerElements = this.renderHeaderElements(this.state.headerElements);
        let headerElements2 = this.renderHeaderElements(this.state.headerElements2);
        return(
            <div className="gl-header-options">
              {headerElements}
              {headerElements2}
              {/* <div className="gl-header-1">{headerElements}</div> */}
              {/* <div className="gl-header-2">{headerElements2}</div> */}
            </div>
        );

    }

}

export default GLHeader;