import { Suspense, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";

// import NewHeader from './NewHeader';
import AppliedFilters from "../../components/AppliedFilters";
import FilterByDropdown from "../../components/FilterByDropdown";
import ManageColumns from "../../components/manageColumns/ManageColumns";
import NewBanner from "../../components/NewBanner";
import { DimensionsDropdowns, Loader } from "../../form/elements";
import Button from "../../newComponents/Button";
import DropDown from "../../newComponents/DropDown";
import Modal from "../../newComponents/Modal";
import SwitchToggle from "../../newComponents/SwitchToggle";
import FilterDialog from "../../sections/filter/FilterDialog";
import { ReportHeader } from "../ReportHeader";

import {
  ALL_FORMATS,
  ALL_WIDGETS,
  BP_QUADRANT_TIER,
  BUTTON_TYPE,
  BUTTON_VARIANT,
  CURRENT_FILTER_COOKIE,
  DIALOG_SIZE,
  DROPDOWN_TYPE,
  FILTER,
  FY_VALUES,
  FormatTypes,
  HEADER_ELEMENT,
  MENU_ITEM,
  PROFILE_COLUMN,
  PSS,
  SAVED_FILTER_ID_COOKIE,
  SCENARIO_STATUS,
  SEGMENTS,
  SIZES,
  VECTOR_GROUPS,
  bridge_config,
  costtype,
  header_comparison_values
} from "../../class/constants";
import { lang } from "../../language/messages_en";
import "../styles/reportBody.css";

import {
    formatAdvancedFilter,
    formatBasicFilter,
    getLocalStorageValueByParameter,
    logout,
    setLocalStorageValueByParameter,
    toggleLoader,
    updateWindowLoading,
} from "../../class/common";
import {
  generateQuarter,
  getPeriodDifference,
  getPeriodFromDate
} from "../../class/date";
import { convertPxToViewport } from "../../class/formatting";
import { saveCookie } from "../../class/jqueries";
import { areObjectsNotEmpty, checkIfMonthsValid, copyObjectValues, findOptionByKey, getSectionExists, profileHasColumnsAccess, tryParse } from "../../class/utils";
import ErosionAndGrowth from "../../ErosionAndGrowth";
import { getNewEntityFilter } from "../../sections/filter/FilterHelperFunctions";
import { ReactComponent as RoundInfoIcon } from "../../styles/images/menu-svgs/info.svg";
import { updateDatasetState, updatePeriodsStatusState, updateVectorState } from "../../actions/scenariosActions";
import { getBridgeOptions, getClientPeriods, getDatasets, getFilterEntities, getPeriodsStatus, getVectors } from "../api/api";
import { updateBridgeOptions } from "../actions/bridgeOptionsActions";
import { useAuthenticated, useCalendarToggle, useLoading } from "../CustomHooks";
import {
  messageDialogContent,
  renderButton,
  renderPeriodCalendar,
  renderTitle,
  tablesToExcel
} from "../functions/componentFunctions";
import { addCorrespondingParenthesisToFilter } from "../functions/filterFunctions";
import { checkBuiltPeriodAvailability, checkIfAtLeastOnePeriodIsGenerated, checkIfSelectedPeriodsAreValid, validatePeriodRange } from "../functions/periodFunctions";
import { disableVectors, enableVectors } from "../functions/vectorFunctions";
import { LoaderSkeleton } from "../LoaderSkeleton";
import { Segment } from "../../components/Segment";

const _modified = " Modified";
const _selectedListProfile = "selectedProfile_list";
const _bestPractice = "BestPractice";
/**
 * @author Sandra Abdelkhalek
 * @description component renders title based on profile selected from {@link ManageColumns}, vectorsdropwodwn, period calendar, best practice, filter, with  {@link ErosionAndGrowth} component , calls isAuthenticated hook to check if user has authentication view the screen
 * @param {*} props 
 * @returns {@link ReportHeader}, {@link ErosionAndGrowth}
 */
const ErosionAndGrowthWrapper = (props) => {
  /**Store */
  const profiles = useSelector((state) => state.profiles);
  const stateProfiles = useSelector((state) => state.stateProfiles);
  const customGroups = useSelector((state) => state.customGroups);
  const profileColumns = useSelector((state) => state.profileColumns);
  const columnProfiles = useSelector((state) => state.columnProfiles);
  const psLinesOptions = useSelector((state) => state.psLinesOptions);
  const quadrantTierOptions = useSelector((state) => state.quadrantTierOptions);
  const bridgeOptionsFromStore = useSelector((state) => state.bridgeOptionsState);


  const userAllowedMenuLinks = props.userAllowedMenuLinks;
  const userSettings = props.userSettings;
  const scenarioState = props.scenarioState;

  /**References & react utility */
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();

  const manageColsOverlay = useRef();
  const dimensionDropdownsRef = useRef();
  const reportRef = useRef();
  const manageColsRef = useRef();
  const appliedFiltersRef = useRef();
  const filterDialRef = useRef();
  const filterByRef = useRef();
  const countRef = useRef(0);

  /**Custom Hooks */
  const isAuthenticated = useAuthenticated(userAllowedMenuLinks);
  const { toggleCalendar } = useCalendarToggle();

  /**State */
  const [periodsStatusState, setPeriodsStatusState] = useState({});
  const [vectorState, setVectorState] = useState({});
  const [datasetState, setDatasetState] = useState({});
  const [bridgeOptionsState, setBridgeOptionsState] = useState(
    bridgeOptionsFromStore.get(scenarioState?.scenario) ? bridgeOptionsFromStore.get(scenarioState?.scenario)[0] : undefined
  );
  const [clientPeriodsState, setClientPeriodsState] = useState([]);

  /**filter Related States */
  const [filterFinal, setFilterFinal] = useState(
    props.history.location.state
      ? props.history.location.state.outsideFilter
        ? JSON.stringify(tryParse(props.history.location.state.outsideFilter).filter)
        || JSON.stringify(tryParse(props.history.location.state.outsideFilter)) || []
        : []
      : []
  );
  const [filterFinalBasic, setFilterFinalBasic] = useState(
    props.history.location.state
      ? props.history.location.state.mainFilterFinalBasic
        ? tryParse(props.history.location.state.mainFilterFinalBasic) || []
        : []
      : []
  );
  const filterSet = useRef(false);

  const [basicFilterEntities, setBasicFilterEntities] = useState([]);
  const [exportQueryFilter, setExportQueryFilter] = useState(undefined);
  const [displayFilter, setDisplayFilter] = useState();
  const [isFilterDisabled, setIsFilterDisabled] = useState(false);
  // const [isAdvancedFilterApplied, setIsAdvancedFilterApplied] = useState();
  const [tempFilter, setTempFilter] = useState();
  const [basicFilterEntitiesState, setBasicFilterEntitiesState] = useState([]);
  const [count, setCount] = useState(0);
  const [basicFilterItem, setBasicFilterItem] = useState();

  /**Header Related States */
  const [headerChangedCounter, setHeaderChangedCounter] = useState(0);
  const [headerElements, setHeaderElements] = useState([]);
  const [profitFormat, setProfitFormat] = useState(MENU_ITEM.FIELDS.EROSION_AND_GROWTH);
  const [reportTitle, setReportTitle] = useState(ALL_WIDGETS.TITLES.PROFIT_LANDSCAPE.VECTOR_ANALYSIS);
  const [manageColumnsProfile, setManageColumnsProfile] = useState();
  const [manageColumnsDefaultProfile, setDefaultManageColumns] = useState();
  const [openLimitAccessDialog, setOpenLimitAccessDialog] = useState(false);
  const [limitAccessMsg, setLimitAccessMsg] = useState("");
  const [showGreyOverLay, setShowGreyOverLay] = useState(true);
  const [isScenarioBannerVisible, setScenarioBannerVisible] = useState(false);
  const [callGo, setCallGo] = useState(false);
  const [dataLength, setDataLength] = useState(0);
  const [isDrilling, setIsDrilling] = useState(false);
  const [openInfoDialog, setOpenInfoDilog] = useState();
  const [infoDialogMsg, setInfoDialogMsg] = useState();
  const [openFilterDialog, setOpenFilterDialog] = useState();

  const [isBridgeReport, setIsBridgeReport] = useState(false);
  const [comparisonValue, setComparisonValue] = useState(props.history?.location?.state?.comparison_value || lang.header.options.comparison[1].value);
  const [selectReportVal, setSelectReportVal] = useState();
  const [isSelectReportDisabled, setSelectReportDisabled] = useState(true);
  const [selectReportDisabledMsg, setSelectReportDisabledMsg] = useState(comparisonValue === BP_QUADRANT_TIER ? lang.select_entity_to_view  : lang.select_entities_to_view );
  const [erosionStates, setErosionStates] = useState([]);

  const [isFromDashboards, setIsFromDashboards] = useState(history?.location?.state?.isFromDashboards);

  const manageColumnsProps = {
    manageColumnsUpdateSections: [
      ALL_WIDGETS.FIELDS.LIST,
      MENU_ITEM.FIELDS.HEATMAP,
      MENU_ITEM.FIELDS.EROSION_AND_GROWTH,
      ALL_WIDGETS.FIELDS.COMPARE_SCENARIOS,
    ],
  };

  const manageColsAccess = {
    [ALL_WIDGETS.FIELDS.MANAGE_USER_COLUMNS]: useMemo(() => getSectionExists(userAllowedMenuLinks, ALL_WIDGETS.FIELDS.MANAGE_USER_COLUMNS), [userAllowedMenuLinks]),
    [ALL_WIDGETS.FIELDS.VIEW_COMPANY_COLUMNS]: useMemo(() => getSectionExists(userAllowedMenuLinks, ALL_WIDGETS.FIELDS.VIEW_COMPANY_COLUMNS), [userAllowedMenuLinks]),
    [ALL_WIDGETS.FIELDS.EDIT_COMPANY_COLUMNS]: useMemo(() => getSectionExists(userAllowedMenuLinks, ALL_WIDGETS.FIELDS.EDIT_COMPANY_COLUMNS), [userAllowedMenuLinks]),
    [ALL_WIDGETS.FIELDS.VIEW_VECTOR_COLUMNS]: useMemo(() => getSectionExists(userAllowedMenuLinks, ALL_WIDGETS.FIELDS.VIEW_VECTOR_COLUMNS), [userAllowedMenuLinks]),
  };

  const loading = useLoading();
  const comparisonOptions = copyObjectValues(lang.header.options.comparison).map(function(item){item.description = item.description?.replaceAll(SEGMENTS.PEAK.label,new Segment().getSegmentObject(SEGMENTS.PEAK.label)?.label); return item});

  /**on screen initialization if user entered url with no access to screen he gets logged out */
  useEffect(() => {
    if (!isAuthenticated) {
      logout();
    }
  }, [isAuthenticated]);

  /** this useEffect makes the scenario dropdown read-only if we are in bridge report */
  useEffect(() => {
      props?.setScenarioDisabled(isBridgeReport);
  }, [isBridgeReport, loading]);

  useEffect(() => {
    if (scenarioState) {
      //when changing scenario, clear all states so that it won't run useEffect when new states are still not updated
      setPeriodsStatusState({});
      setVectorState({});
      setDatasetState({});
      setClientPeriodsState({});
      setShowGreyOverLay(true);
      getPeriodsStatus(scenarioState, dispatch, updatePeriodsStatusState, setPeriodsStatusState, props, profitFormat, userSettings, 12);
      getClientPeriods(undefined, undefined, setClientPeriodsState, profitFormat, params, userSettings);
      getVectors(scenarioState, dispatch, updateVectorState, setVectorState, reportTitle, profitFormat, props, false, filterFinal, userSettings, vectorState, filterDialRef);
      getDatasets(scenarioState, dispatch, updateDatasetState, setDatasetState, props, profitFormat, params, false, userSettings, datasetState);
      getBridgeOptions(scenarioState, dispatch, updateBridgeOptions, bridgeOptionsState, setBridgeOptionsState)
      setScenarioBannerVisible(scenarioState?.scenarioStatus === SCENARIO_STATUS.REVIEW);
    }
  }, [scenarioState]);


  useEffect(() => {
    if (
      Object.keys(periodsStatusState).length > 0 &&
      Object.keys(scenarioState).length > 0 &&
      Object.keys(clientPeriodsState).length > 0 &&
      Object.keys(vectorState).length > 0 &&
      userSettings.user
    ) {
      let headerElements = getHeaderElements();
      setHeaderElements(headerElements);
    }
  }, [
    periodsStatusState,
    clientPeriodsState,
    vectorState,
    manageColumnsProfile,
    showGreyOverLay,
    isDrilling,
    count,
    basicFilterEntities,
    filterFinalBasic,
    headerChangedCounter,
    manageColumnsProfile,
    isSelectReportDisabled,
    selectReportDisabledMsg,
    isBridgeReport,
    filterFinal
  ]);

  useEffect(() => {
    if(history?.location?.state?.isFromDashboards){
        const currentState = history.location.state || {};
        const newState = { ...currentState };
        delete newState.isFromDashboards;
        delete newState.outsideFilter;
        history.replace({
        pathname: history.location.pathname,
        state: newState,
        });
    }
  }, []);

  useEffect(() => {
    if (callGo && !showGreyOverLay) {
      apply();
      setCallGo(false);
      props?.setScenarioDisabled(isBridgeReport);
    }
  }, [callGo, manageColumnsProfile, filterFinal, filterFinalBasic]);

  /** These are the states that the erosion screen has before going to bridge report.
   * We are saving them so that we can retrieve them when clicking on back from bridge report.
   */
  const getErosionStates = () => {
    let tempState = {};
    tempState.bridgeOptionsState = copyObjectValues(bridgeOptionsState);
    tempState.comparisonValue = copyObjectValues(comparisonValue);
    tempState.filterFinal = copyObjectValues(filterFinal);
    tempState.filterFinalBasic = copyObjectValues(filterFinalBasic);
    tempState.manageColumnsProfile = copyObjectValues(manageColumnsProfile);
    tempState.savedFilterData = filterDialRef.current?.state?.savedFilterData;

    let dates = {};
    dates.customStartDate = periodsStatusState.customStartDate;
    dates.customEndDate = periodsStatusState.customEndDate;
    dates.nextCustomStartDate = periodsStatusState.nextCustomStartDate;
    dates.nextCustomEndDate = periodsStatusState.nextCustomEndDate;
    tempState.dates = dates;

    return tempState;
  }

  const handleElementChange = (name, e, fromMount = false, dimIndex = 0) => {
    switch (name) {
      case HEADER_ELEMENT.SELECTION_RANGE: {
        let selectedYears = e.filter(year => year.isSelected);
        let tempState = copyObjectValues(periodsStatusState);
        if (selectedYears.length > 0) {
          let periods = getLocalStorageValueByParameter("periods") ? JSON.parse(getLocalStorageValueByParameter("periods")) : [];
          let sortedSelectedYears = selectedYears.map(p => p.year + p.value).sort();
          let startPeriod = sortedSelectedYears[0];
          let endPeriod = sortedSelectedYears[sortedSelectedYears.length - 1];
          let startDate = new Date(periods.filter(e => e.value === startPeriod)[0].start_date);
          let endDate = new Date(periods.filter(e => e.value === endPeriod)[0].end_date);

          if (!validatePeriodRange(startDate, endDate, comparisonValue, periodsStatusState.builtPeriods)) {
            setInfoDialogOpen(true, lang.insufficient_periods_selection)
            return;
          }
          tempState.nextCustomStartDate = startDate;
          tempState.nextCustomEndDate = endDate;
          tempState.customStartDate = startDate;
          tempState.customEndDate = endDate;

          saveCookie("nextCustomStartDate", startDate);
          saveCookie("nextCustomEndDate", endDate);
          
          setPeriodsStatusState(tempState);
          setShowGreyOverLay(true);

          if(!isBridgeReport) {
            reportRef?.current?.clearSelectedItems(); // clear selectedItems when changing anything in header
          }
        }
        break;
      }
      case HEADER_ELEMENT.VECTOR: {
        var vectorValue = typeof e === "object" ? e.value : e;
        let tempState = copyObjectValues(vectorState);
        saveCookie(HEADER_ELEMENT.VECTOR, vectorValue);
        if (periodsStatusState.nextCustomStartDate && periodsStatusState.nextCustomEndDate &&
          (reportTitle !== ALL_WIDGETS.FIELDS.TOTAL_STACKS &&
            (reportTitle && (!reportTitle.startsWith(ALL_WIDGETS.FIELDS.ENTITY_STACKS) && !reportTitle.startsWith(ALL_WIDGETS.FIELDS.COMPARE_STACKS))))) {
          saveCookie("nextCustomStartDate", periodsStatusState.nextCustomStartDate);
          saveCookie("nextCustomEndDate", periodsStatusState.nextCustomEndDate);
        }

        tempState.nextVectors = copyObjectValues(vectorState.nextVectors);
        tempState.nextVectors[dimIndex] = vectorValue;
        tempState.vectors = copyObjectValues(vectorState.nextVectors);
        tempState.vectors[dimIndex] = vectorValue;
        setVectorState(tempState);
        setShowGreyOverLay(true);

        if(!isBridgeReport) {
          reportRef?.current?.clearSelectedItems(); // clear selectedItems when changing anything in header
        }
        break;
      }
      case HEADER_ELEMENT.COMPARISON: {
        if (!validatePeriodRange(periodsStatusState.nextCustomStartDate, periodsStatusState.nextCustomEndDate, e.value, periodsStatusState.builtPeriods)) {
          setInfoDialogOpen(true, lang.insufficient_periods_selection)
          return;
        }

        if(!isBridgeReport) {
          reportRef?.current?.clearSelectedItems(); // clear selectedItems when changing anything in header
        }
        let isBestPractice = comparisonOptions?.find(f => f.value === e.value)?.type === _bestPractice;
        checkIfRangeIsValid(isBestPractice);

        setComparisonValue(e.value);
        setShowGreyOverLay(true);
        setSelectReportDisabled(true);
        setSelectReportDisabledMsg(e.value === BP_QUADRANT_TIER ? lang.select_entity_to_view  : lang.select_entities_to_view);
        break;
      }
      case HEADER_ELEMENT.SECONDARY_REPORT: {
        setSelectReportVal(e["value"]);
        setBridgeOptionsState(bridgeOptionsState);
        setIsBridgeReport(true);
        setErosionStates(getErosionStates());
        break;
      }
      case HEADER_ELEMENT.BRIDGE: {
        let tempState = {
          bridge: e.value,
          secondaryReport: e.report_id,
          bridgeCustomViewId: e[bridge_config.fields.custom_view_id],
        };
        setBridgeOptionsState(prevState => ({ ...prevState, ...tempState }));
        setShowGreyOverLay(true);
        break;
    }
      case HEADER_ELEMENT.MIX_VECTOR: {
        setVectorState(prevState => ({ ...prevState, mixVector: e.value }));
        setShowGreyOverLay(true);
        break;
      }
      case HEADER_ELEMENT.NAVIGATION_BACK: {
        reportRef?.current?.onBackNavigation();
        break;
      }
      default: break;
    }

    let headerChangedCounterState = headerChangedCounter;
    setHeaderChangedCounter(headerChangedCounterState + 1);
  }

  const tablesToExcelWrapper = () => {
    toggleLoader(true, "tablesToExcel");
    tablesToExcel(reportTitle, userSettings, vectorState, datasetState, scenarioState, reportRef, getExportQueryFilter);
  }

  const setFilterTo = () => {
    let isFromBridge =  history.location.state?.isRedirectedFromBridge;
    let filter = isFromBridge ? history.location.state?.outsideFilter : filterFinal;
    //before saving the filter, save the old value if not previously set
    if(filter && typeof filter !== 'string') {
        filter = JSON.stringify(filter);        //onChangeSavedFilter expects a string and not an array
    }
    let filterSetRef = filterSet.current; 
    if (!filterSetRef && filter?.length > 0) {
      filterSet.current = true;
      filterDialRef?.current?.onChangeSavedFilter(filter, false);
    }
  }

  const manageComparisonTypes = (comparisons, type, disable = true) => {
    if (disable) {
      for (let e in comparisons) {
        if (comparisons[e].type && comparisons[e].type === type) {
          comparisons[e].isDisabled = true;
          comparisons[e].no_quads = true;
          comparisons[e].disableOption = true;
        }
      }
    } else {
      for (let e in comparisons) {
        if (comparisons[e].type && comparisons[e].type === type) {
          comparisons[e].isDisabled = false;
          comparisons[e].no_quads = false;
          comparisons[e].disableOption = false;
        }
      }
    }

    return comparisons;
  };

  /** Returns vectorsOptions to display in dropdown. 
   * It disables/enables vectors  
  */
  const getEditedVectors = (comparisonOptions) => {
    let vectors = vectorState;
    let nextMixVector = vectors.mixVector;
    let comparisonVal = comparisonOptions?.filter((e) => e.value === comparisonValue)[0];

    if (isBridgeReport) {
      if (comparisonVal?.type === _bestPractice) {
        vectors.vectorOptions = disableVectors(vectors.vectorOptions, true);

        if (vectors.vectorOptions.filter((e) => e.value === nextMixVector)[0]?.isDisabled) {
          let i = vectors.vectorOptions.findIndex((e) => !e.isDisabled && e.vector_group === VECTOR_GROUPS.PRODUCT);
          if (i > -1) {
            nextMixVector = vectors.vectorOptions[i].value;
            vectors.mixVector = nextMixVector;
          }
        }
      } else {
        vectors.vectorOptions = copyObjectValues(vectors.originalVectorOptions);
      }
    }

    return vectors;
  };

  const getHeaderElements = () => {
    let sectionsData = getFilterBySectionsData();
    let comparisonVal = copyObjectValues(comparisonValue);

    let isDropDownDisabled = isDrilling;
    let comparisonOptionsVar = comparisonOptions;
    let reportsOptions = [];
    let isBridgeAllowed = getSectionExists(userAllowedMenuLinks, MENU_ITEM.FIELDS.BRIDGE_REPORT);
    let vectors = getEditedVectors(comparisonOptions);

    if (isBridgeAllowed) {
      reportsOptions.push({ label: ALL_WIDGETS.TITLES.PROFIT_LANDSCAPE.BRIDGE_REPORT, value: ALL_WIDGETS.BRIDGE_REPORT });
    }

    let segmentsNotGenerated = !vectors.vectorOptions.filter((e) => e.value === vectors.vectors[0])[0].generated;
    if (segmentsNotGenerated) {
      comparisonOptionsVar = manageComparisonTypes(comparisonOptionsVar, _bestPractice);
    } else {
      comparisonOptionsVar = manageComparisonTypes(comparisonOptionsVar, _bestPractice, false);
    }
    if (comparisonOptionsVar?.filter((e) => e.value === comparisonVal)[0].isDisabled) {
      comparisonVal = comparisonOptionsVar[1].value;
      setComparisonValue(comparisonVal);
    }

    if(reportRef.current?.state.selectedItems?.length > 1) {
      let compObj = comparisonOptionsVar?.find(e=>e.value === BP_QUADRANT_TIER);
      compObj.isDisabled = true;
      compObj.disableOption = true;
      compObj.tooltipText = lang.cannot_choose_bp;
    }

    let isBestPractice = comparisonOptionsVar?.find(f => f.value === comparisonVal)?.type === _bestPractice;
    // screenName changes when we go to Bridge report or get back to erosion page. This values is used to chnage the calendar key to make it rerender with correct highlight and values.
    let screenName = isBridgeReport ? ALL_WIDGETS.TITLES.PROFIT_LANDSCAPE.BRIDGE_REPORT : ALL_WIDGETS.TITLES.PROFIT_LANDSCAPE.VECTOR_ANALYSIS;
    screenName = isBestPractice ? screenName + _bestPractice : screenName; // when we change comparison frpm best practice to time  

    const comparsionOptionLabel = ({ label, value, isDisabled, description, disableOption, tooltipText, no_quads }) => (
      <div className={(isDisabled && !disableOption ? "option-group-header" : "") + " option-padding"}>
        {label}
        {!isDisabled && !disableOption ? (
          <i className="fal fa-info-circle" uk-tooltip={description} style={{ float: "right", color: "white", marginTop: convertPxToViewport(5) }} />
        ) : isDisabled && disableOption && !no_quads ? (
          <i className="fal fa-info-circle black" uk-tooltip={tooltipText} style={{ float: "right", marginTop: convertPxToViewport(5) }} />
        ) : isDisabled && disableOption && no_quads ? (
          <i
            className="fal fa-info-circle black"
            uk-tooltip={lang.segments_not_generated}
            style={{ float: "right", marginTop: convertPxToViewport(5) }}
          />
        ) : (
          ""
        )}
      </div>
    );

    const mixVectorOptionLabel = ({label, isDisabled,isGroupTitle}) => (
      <div className={(isDisabled && isGroupTitle ? "option-group-header" : "") +" option-padding"}>
          {label} {isDisabled && !isGroupTitle ? 
          <i className="fal fa-info-circle" uk-tooltip={lang.segments_not_generated} style={{"float":"right","color":"black","marginTop":convertPxToViewport(5)}} /> : ""}
      </div>
    );

    let headerElements = [];
    let startPeriodMnageCol = getPeriodFromDate(new Date(periodsStatusState.customStartDate));
    let endPeriodManageCol = getPeriodFromDate(new Date(periodsStatusState.customEndDate));
    let manageColumnsProfileName = manageColumnsProfile ? manageColumnsProfile?.name + (manageColumnsProfile?.is_modified ? _modified : "") : undefined;
    let title = !isBridgeReport ? manageColumnsProfileName || ALL_WIDGETS.TITLES.PROFIT_LANDSCAPE.VECTOR_ANALYSIS : ALL_WIDGETS.TITLES.PROFIT_LANDSCAPE.BRIDGE_REPORT;

    headerElements.push(
      <div style={{ display: "flex", width: "100%", alignItems: "center" }} className="first_header_row gap_between_buttons">
        {renderTitle(title)}
        <div
          key={Math.random()}
          uk-tooltip={isDropDownDisabled ? lang.exit_drill_to_proceed : "title:"}
          className={"uk-flex uk-flex-column uk-flex-start"}
        >
          <div key={Math.random()} className={isDropDownDisabled ? " disabled" : ""}>
            <DimensionsDropdowns
              ref={dimensionDropdownsRef}
              disabled={isBridgeReport}
              numberOfDimensions={1}
              dimensionOptions={vectors.vectorOptions}
              vectorDisabled={vectors.vectorDisabled || isBridgeReport}
              excludeOptions={true}
              onChangeDimension={(e, index, fromMount) => handleElementChange(HEADER_ELEMENT.VECTOR, e, fromMount, index)}
              shouldFetchData={false}
              selectedDimensions={vectors.nextVectors}
              startAsEmpty={false}
              dropdownTooltip={isDropDownDisabled ? lang.exit_drill_to_proceed : undefined}
              hideLabel={true}
            />
          </div>
        </div>
        <div uk-tooltip={isDropDownDisabled ? lang.exit_drill_to_proceed : "title:"}>
          <div className={isDropDownDisabled ? "disabled" : ""}>
            <DropDown
              id="comparison-dropdown"
              className="input-width-200 input__dropdown"
              name="comparison"
              value={findOptionByKey(comparisonOptions, comparisonVal)}
              onChange={(e) => handleElementChange(HEADER_ELEMENT.COMPARISON, e)}
              options={comparisonOptions}
              formatOptionLabel={comparsionOptionLabel}
              type={DROPDOWN_TYPE.INPUT}
            />
          </div>
        </div>

        {renderPeriodCalendar(
          periodsStatusState,
          handleElementChange,
          undefined,
          clientPeriodsState.allPeriods,
          toggleCalendar,
          isDropDownDisabled,
          scenarioState,
          isBestPractice,
          isDropDownDisabled ? lang.exit_drill_to_proceed : "",
          screenName
        )}
        {isBridgeReport ? (
          <>
            <div uk-tooltip={isDropDownDisabled ? lang.exit_drill_to_proceed : "title:"}>
              <DropDown
                id="bridge-dropdown"
                className="input-width-200 input__dropdown"
                name="Bridge"
                value={findOptionByKey(bridgeOptionsState.bridgeOptions, bridgeOptionsState.bridge)}
                onChange={(e) => handleElementChange(HEADER_ELEMENT.BRIDGE, e)}
                options={bridgeOptionsState.bridgeOptions}
                formatOptionLabel={bridgeOptionsState}
                type={DROPDOWN_TYPE.INPUT}
                disabled={isDropDownDisabled}
              />
            </div>
            <div uk-tooltip={isDropDownDisabled ? lang.exit_drill_to_proceed : "title:"}>
              <DropDown
                filterOption={(option, searchText) => {
                  return option.label.toLowerCase().includes(searchText.toLowerCase());
                }}
                id="mix-vector-dropdown"
                className="input-width-200 input__dropdown"
                name="Mix Vector"
                value={findOptionByKey(vectors.vectorOptions, vectors.mixVector)}
                onChange={(e) => handleElementChange(HEADER_ELEMENT.MIX_VECTOR, e)}
                options={vectors.vectorOptions}
                disabled={isDropDownDisabled}
                formatOptionLabel={mixVectorOptionLabel}
                type={DROPDOWN_TYPE.INPUT}
              />
            </div>
          </>
        ) : (
          ""
        )}

        {renderButton(lang.modal.buttons.apply, lang.modal.buttons.apply.toLowerCase(), "", "", apply, !showGreyOverLay)}

        {manageColsAccess && !Object.keys(manageColsAccess).every((k) => !manageColsAccess[k]) && vectors?.vectorOptions && !isBridgeReport ? (
          <ManageColumns
            ref={manageColsRef}
            key={HEADER_ELEMENT.ADD_COLUMNS}
            scenarioId={scenarioState.nextScenarios[0]}
            profitFormat={profitFormat}
            approveBulkChange={approveBulkChange}
            user={userSettings.user}
            onSelectProfile={updateManageColumnsProfile}
            onSelectDefaultProfile={onSelectDefaultProfile}
            onChangeProfileData={onChangeProfileData}
            onToggleBoard={onToggleManageColsBoard}
            manageColsAccess={manageColsAccess}
            comparisonScenarioId={scenarioState.nextScenarios[1]}
            vectorObjects={vectors.vectorObjects}
            sectionsProfilesAreValidFor={manageColumnsProps.manageColumnsUpdateSections}
            hideDropdown={false /**isDrilling */}
            vectorOptions={vectors.vectorOptions}
            checkForLimitAccessMessage={checkForLimitAccessMessage}
            vector={vectors.nextVectors[0]}
            outsideColumnProfileId={props?.history?.location?.state ? props?.history?.location?.state?.columnProfileId : undefined}
            report={reportTitle}
            hideArrow={false}
            hideManage={isDrilling}
            updateManageColumnsProfile={updateManageColumnsProfile}
            isCompareScenarios={false}
            dataSet={userSettings.dataset}
            dispatch={dispatch}
            manageColumnsSelectionLimit={userSettings.manageColumnsSelectionLimit}
            selectedRange={startPeriodMnageCol + (startPeriodMnageCol !== endPeriodManageCol ? " to " + endPeriodManageCol : "")}
            selectedFilter={[] /*filterFinal*/}
            profileColumns={profileColumns}
            customGroups={customGroups}
            profiles={profiles}
            columnProfiles={columnProfiles}
            stateProfiles={stateProfiles}
            constraint={"5"}
            characterSizeLimit={userSettings.characterSizeLimit}
            scenarioState={scenarioState}
            useNewAddColumn={userSettings.useNewAddColumn}
          />
        ) : (
          ""
        )}
      </div>
    );

    headerElements.push(
      <div className="second_header_row gap_between_buttons" style={{ display: "flex", width: "100%", padding: "0.41667vw 0", justifyContent: "space-between" }}>
        <div
          className="filter-list-filled uk-display-flex gap_between_buttons"
          uk-tooltip={"title:" + (isDropDownDisabled ? lang.filter_not_supported_drill_mode : "") + ";pos: top-left"}
        >
          <FilterByDropdown
            ref={filterByRef}
            sectionsData={copyObjectValues(sectionsData.data)}
            clearClick={clearClick}
            isClearBtnDisabled={sectionsData.isClearBtnDisabled}
            handleCheckboxChange={handleCheckBoxChange}
            basicFilterCountLimit={userSettings.basicFilterCountLimit}
            switchBasicToAdvanced={switchBasicToAdvanced}
            disabled={isDropDownDisabled}
          />
          <FilterDialog
            id={"secondary_header_" + "erosion"}
            ref={filterDialRef}
            section_id={params.sectionId}
            vectorOptions={vectors.vectorOptions}
            profitFormat={profitFormat}
            mustHideFilter={false}
            categoryToolkit={false}
            onChangeFilter={onChangeFilter}
            quadTierIsAllowed={true}
            isTempScenario={false}
            scenario_id={scenarioState.nextScenarios[0]}
            mustFetchDatasets={false}
            user={userSettings.user}
            isDrilling={false}
            fromOptions={datasetState.datasetOptions}
            datasetOptions_id={datasetState.datasetOptions_id}
            dataSet={datasetState.dataset}
            useCookies={!isBridgeReport}
            parentMessage={""}
            psFilterDisabled={false}
            quadrantsDisabled={isBridgeReport}
            quadrantDefault={false}
            checkIfMonthsValid={checkIfMonthsValid}
            comparisonScenarioId={scenarioState.nextScenarios[1]}
            filterDisplayEditable={true}
            psLinesOptions={psLinesOptions}
            quadrantTierOptions={quadrantTierOptions}
            dispatch={dispatch}
            savedFilterDataState={history?.location?.state?.savedFilterDataState || filterDialRef?.current?.state?.savedFilterData}
            isRedirectionFromStacks={false}
            savedFilter={true}
            fromSecondaryHeader={true}
            savedFilterDisabled={isDropDownDisabled}
            setFilterTo={setFilterTo}
          />
        </div>

        <div style={{ display: "flex", flexDirection: "row", columnGap: "0.42vw" }}>
          {!isBridgeReport ? (
            <div
              className={"uk-padding-remove-left" + (isSelectReportDisabled ? "cursorNotAllowed" : "")}
              id="select_report_div"
              uk-tooltip={isSelectReportDisabled ? selectReportDisabledMsg : "title:"}
            >
              <div style={{ pointerEvents: isSelectReportDisabled ? "none" : "unset" }}>
              <DropDown // to remove comment and comment <Select
                id="select_report"
                className={"button__dropdown"}
                placeholder={"Select Report"}
                name="report"
                value={findOptionByKey(reportsOptions, selectReportVal)}
                onChange={(e) => handleElementChange(HEADER_ELEMENT.SECONDARY_REPORT, e)}
                options={reportsOptions}
                isSearchable={false}
                type={DROPDOWN_TYPE.BUTTON}
                dropDownWidth={140}
                maxMenuHeight={convertPxToViewport(300)}
                disabled={isSelectReportDisabled}
                firstAttr={"#select_report"}
                secondAttr={"#select_report_div"}
              />
              </div>
            </div>
          ) : (
            ""
          )}
          {!isBridgeReport && (
            <Button
              id="Export"
              label={lang.header.titles.xls}
              title={lang.header.tooltips.xls}
              variant={BUTTON_VARIANT.SECONDARY}
              size={SIZES.DEFAULT}
              type={BUTTON_TYPE.DEFAULT}
              disabled={showGreyOverLay}
              onBtnClick={tablesToExcelWrapper}
              leftIcon={<i className="fal fa-file-excel fa-lg" />}
            />
          )}

          {isBridgeReport && (
            <div className="noprint" key={"noprint"}>
              <Button
                label={isDrilling ? lang.navigation.btns.exit_drill : lang.navigation.btns.back}
                variant={BUTTON_VARIANT.SECONDARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                onBtnClick={() => handleElementChange(HEADER_ELEMENT.NAVIGATION_BACK, {})}
              />
            </div>
          )}
        </div>
      </div>
    );

    headerElements.push(
      <div className="third_row_header" style={{ display: "flex", width: "100%" }}>
        <div
          key={Math.random()}
          className={
            "noprint " +
            (displayFilter && ((filterFinalBasic?.length > 0 && filterFinalBasic !== "[]") || (filterFinal?.length > 0 && filterFinal !== "[]"))
              ? "border-third-header "
              : "") +
            "uk-display-block"
          }
        >
          <AppliedFilters
            filterProps={filterFinalBasic}
            filterFinal={filterFinal}
            ref={appliedFiltersRef}
            userAllowedVectors={userSettings.user?.user_allowed_vectors}
            basicFilterCountLimit={userSettings.basicFilterCountLimit}
            handleCheckboxChange={handleBasicFilterCheckBoxChange}
            sectionsData={basicFilterEntities}
            fetchData={filterOnBasicFilter}
            clearFilter={filterDialRef?.current?.clearFilter}
            vectors={vectorState.vectorOptions}
            quadrantsDisabled={isBridgeReport}
            psFilterDisabled={false}
            parentMessage={"" /*props.filterWarningMessage*/}
            saveFilter={filterDialRef?.current?.startSaveFilter}
            clearBasicFilter={clearClick}
            clearFilterFromDialog={filterDialRef?.current?.clearFilter}
            forgetFilter={filterDialRef?.current?.handleOpenForgetFilterDialog}
            className={isDrilling ? "uk-disabled disabled" : ""}
            tooltip={"title:" + (isDrilling ? lang.filter_not_supported_drill_mode : "") + ";pos: top-left"}
            filterIsEditable={filterDialRef?.current?.state?.savedFilterData?.editable}
          />
        </div>
      </div>
    );

    return headerElements;
  };

  /**
   * This function checks if the selected range should still be selected or not, because when we switch between best practice and time comparison, some periods may be valid in
   * time comparison but not in best practice comparison. If the selected range is not valid, we select the last valid period.
   * @param {*} checkForSegmented if true, we check for segmented periods else we check for built periods.
   */
  const checkIfRangeIsValid = (checkForSegmented) => {
    let tempState = checkIfSelectedPeriodsAreValid(periodsStatusState, checkForSegmented)
    setPeriodsStatusState(tempState);
  }

  const setInfoDialogOpen = (isOpen, infoMsg) => {
    setOpenInfoDilog(isOpen);
    setInfoDialogMsg(infoMsg);
  };

  const runApplyValidations = () => {
    if (getPeriodDifference(getPeriodFromDate(periodsStatusState.customStartDate), getPeriodFromDate(periodsStatusState.customEndDate)) > 12) {
      setInfoDialogOpen(true, lang.not_allowed_periods_selection);
      return false;
    }
    return true;
  };

  const apply = () => {
    if (!runApplyValidations()) {
      return;
    }

    reportRef?.current.go();
    setShowGreyOverLay(false);
  };

  const checkIfAtLeastOnePeriodIsGeneratedFunc = () => {
    return checkIfAtLeastOnePeriodIsGenerated(periodsStatusState?.builtPeriods , 12);
  }

  /**ManageColumns Related functiontions */
  const onToggleManageColsBoard = () => {
    manageColsOverlay.current.classList.toggle("uk-hidden");
  };

  const onSelectDefaultProfile = (profile, callback) => {
    profile.is_modified = false;
    setDefaultManageColumns(profile);
    updateManageColumnsProfile(profile, callback);
  };

  const onChangeProfileData = (profile, callback) => {
    updateManageColumnsProfile(profile, callback);
    setCallGo(true);
  };

  const updateManageColumnsProfile = (profile, callback) => {
    let manageColumnsProfile = copyObjectValues(profile);
    manageColumnsProfile.is_modified = profile.is_modified;
    manageColumnsProfile.is_applied = profile.is_applied;
    manageColumnsProfile.name = profile.name;
    manageColumnsProfile.label = profile.name;
    manageColumnsProfile.simplified_columns = profile.simplified_columns || profile.columns;
    setManageColumnsProfile(manageColumnsProfile);
    if (callback) callback();
  };

  const approveBulkChange = (key, element) => {
    if(key === PROFILE_COLUMN.IS_CHECKED) {
        let elementCosttype = element[PSS.DUPLICATE_KEYS.COSTTYPE];
        if(elementCosttype === costtype.calculated) {
            //if calculated, allow only if not percentage
            return ALL_FORMATS[element[PSS.FORMAT_TYPE_ID]] !== FormatTypes.PERCENTAGE;
        } else {
            return ![costtype.attribute, costtype.medians, costtype.ratios, costtype.totalratios, costtype.count].includes(elementCosttype);
        }
    }
  }
  const reselectProfile = () => {
    manageColsRef?.current?.setSelectedProfile(manageColumnsProfile); // to select the selected list in list screen when exiting th drill
    if (sessionStorage.getItem(_selectedListProfile)) {
      // set checkedItems to the original list selected items
      manageColsRef?.current?.setState({
        checkedItems: JSON.parse(sessionStorage.getItem(_selectedListProfile)).simplified_columns,
      });
    }
  };

  /**Limit Access Related Functions */
  const checkForLimitAccessMessage = (data, showModal)=> {
    let noAccessMessage = data.no_access_message;
    if(!!noAccessMessage && showModal && !props.checkedPsLines) {
        setLimitAccessDialogOpen(true, noAccessMessage);
    }
    return !!noAccessMessage;
  }

  const setLimitAccessDialogOpen = (isOpen, msg)=> {
      setLimitAccessMsg(msg)
      setOpenLimitAccessDialog(isOpen);
  }

  /**Filter Related Functions */
  const getExportQueryFilter = (additionalFilter) => {
    if (additionalFilter?.filter && typeof additionalFilter?.filter != "function") {
      additionalFilter = additionalFilter.filter;
    }
    return additionalFilter ? formatQueryFilter(additionalFilter) : exportQueryFilter;
  };

  const formatQueryFilter = (filter) => {
    return filter.find((f) => !f.isBasicFilter)
      ? formatAdvancedFilter(
          filter,
          userSettings.user?.user_allowed_vectors,
          datasetState.datasetOptions,
          vectorState.vectorOptions,
          filterDialRef?.current?.state?.psLinesOptions
        )
      : formatBasicFilter(filter, userSettings?.user?.user_allowed_vectors);
  };

  const switchBasicToAdvanced = () => {
    if (filterByRef?.current) {
      filterByRef.current.handleClose();
    }

    if(!filterFinal) {
      return;
    }
    
    let filterStr = filterFinal;
    let filter =
      typeof filterStr === "string"
        ? JSON.parse(filterStr)
        : filterStr.filter && typeof filterStr.filter !== "function"
        ? filterStr.filter
        : filterStr;

    filter = addCorrespondingParenthesisToFilter(filter);
    if (filter.find((e) => e.isBasicFilter)) {
      filterDialRef?.current?.onChangeSavedFilter(filter, false, true);
    }
  };

  const onChangeFilter = (filterStr, afterDrilling, applyFilter = false, isClear, isSwitchBasic, filterDisplayName) => {
    let filter = typeof filterStr === "string" ? JSON.parse(filterStr) : filterStr;
    // on changing advanced saved filter its name should be removed if cookie has no saved filter since we remove the saved filter from cookie when we do a change on it
    if (
      !filterDisplayName &&
      (isBridgeReport || (getLocalStorageValueByParameter(SAVED_FILTER_ID_COOKIE) === null ||
        !getLocalStorageValueByParameter(SAVED_FILTER_ID_COOKIE) ||
        getLocalStorageValueByParameter(SAVED_FILTER_ID_COOKIE) === "undefined"))
    ) {
      filter.map((item) => {
        item.filter_display_name = undefined;
      });
    }

    if (isSwitchBasic) {
      filter.map(function (item) {
        item.isBasicFilter = false;
      });
    }
    let tempState = {};

    tempState.filterFinal = typeof filter !== "string" ? JSON.stringify(filter) : filter;

    if (isClear) {
      filter = "[]";
      tempState.filterFinalBasic = [];
    } else if (!filter.find((f) => !f.isBasicFilter)) {
      tempState.filterFinalBasic = copyObjectValues(filter).filter((e) => e.field === "number"); //from selecting a saved basic filter
      let filterObjs = copyObjectValues(filter);
      const unique = [...new Set(filterObjs.map((item) => item.vector))]; // [ 'A', 'B']
      for (var e in unique) {
        getFilterEntities(
          { value: unique[e] },
          vectorState,
          setBasicFilterEntities,
          basicFilterEntitiesState,
          profitFormat,
          userSettings,
          scenarioState,
          tempState.filterFinal,
          getCountRef,
          setCount,
          setCountRef
        );
      }
    } else if (applyFilter) {
      // from apply in advanced
      tempState.filterFinalBasic = [];
    }
    tempState.tempFilter = typeof filter !== "string" ? JSON.stringify(filter) : filter;
    let currState = history.state;
    if (!applyFilter) {
      let tempState2 = { tempFilter: filterStr }; //this state represents the filter displayed in the header but not necessarily applied on the data (pending apply())
      if (!isSwitchBasic) {
        tempState2.filterFinalBasic = tempState.filterFinalBasic;
        setFilterFinalBasic(tempState2.filterFinalBasic);
      }
      setTempFilter(filterStr);
      return;
    }

    //check if filter contains quadrant or quadrant/tier filter
    tempState.isAdvancedFilterApplied = isClear ? false : true;
    tempState.exportQueryFilter =
      filterStr === "[]"
        ? undefined
        : formatAdvancedFilter(
            JSON.parse(filterStr),
            userSettings.user?.user_allowed_vectors,
            datasetState.datasetOptions,
            vectorState.vectorOptions,
            filterDialRef?.current?.state?.psLinesOptions
          );

    setFilterFinal(tempState.filterFinal);
    setFilterFinalBasic(tempState.filterFinalBasic);
    setExportQueryFilter(tempState.exportQueryFilter);
    // setIsAdvancedFilterApplied(tempState.isAdvancedFilterApplied);
    setTempFilter(tempState.tempFilter);

    if (isClear) {
      filterStr = "[]";
    }
    if(history && history.location && history.location.state && !isFromDashboards){
      let state = history.location?.state;
      state.outsideFilter = typeof filter !== "string" ? JSON.stringify(filter) : filter;
      state.mainFilter = typeof filter !== "string" ? JSON.stringify(filter) : filter;
      state.filter = typeof filter !== "string" ? JSON.stringify(filter) : filter;
      state.filterFinal = typeof filter !== "string" ? JSON.stringify(filter) : filter;
      state.originalMainFilter = typeof filter !== "string" ? JSON.stringify(filter) : filter;
      state.scenarioState = history?.location?.state?.scenarioState,
      state.callOnMount = history?.location?.state?.callOnMount
      history.push({
        state: state
      })
      setLocalStorageValueByParameter(CURRENT_FILTER_COOKIE, JSON.stringify(filter));
    }
    if (!showGreyOverLay) {
      // apply();
      setCallGo(true);
    }
  };

  const setCountRef = (count) => {
    countRef.current = count;
  };

  const getCountRef = () => {
    return countRef.current;
  };

  const getFilterBySectionsData = () => {
    let isClearBtnDisabled = false;
    let filterFinalBasicState = filterFinalBasic || [];
    let vectors = vectorState.vectorOptions?.filter((f) => f.entityCount <= userSettings.filterVectorCountThreshold || f.isGroupTitle); // remove vectors with entityCount <= threshold and that are not group title;
    if (vectors && filterFinalBasicState) {
      filterFinalBasicState = typeof filterFinalBasicState === "object" ? filterFinalBasicState : JSON.parse(filterFinalBasicState);
      filterFinalBasicState = Array.isArray(filterFinalBasicState) ? filterFinalBasicState : filterFinalBasicState.filter;
      isClearBtnDisabled = filterFinalBasicState.length === 0;
      vectors = vectors.map((item) => ({
        ...item,
        checked: !item.isGroupTitle && filterFinalBasicState.some((e) => e.vector === item.value), // .some() checks if any of the element.vector === item.value
      }));
    }

    // Add suggested section if we have nextVectors and nextVectors' entity count < threshold
    let sectionsJson = [];
    let quarters = [];
    var startQuarter = datasetState.nextStartQuarter?.split("Q")[1];
    var endQuarter = datasetState.nextEndQuarter?.split("Q")[1];
    var startYear = datasetState.nextStartQuarter?.split("Q")[0];
    var endYear = datasetState.nextEndQuarter?.split("Q")[0];
    quarters = generateQuarter(Number(startQuarter), startYear, Number(endQuarter), endYear, true);
    vectors = vectors; // add isDisabled to vectors that are not generated

    let suggestedVectors = vectors?.filter((f) => vectorState.nextVectors?.includes(f.value) && !f.isGroupTitle && !f.isDisabled); // get nextVectors as jsonObject
    suggestedVectors = suggestedVectors?.filter((f) => f.entityCount <= userSettings.filterVectorCountThreshold); // checks if header vectors' entity count <= thereshold
    let hasSuggested = suggestedVectors?.length > 0;
    if (hasSuggested) {
      sectionsJson.push({
        sectionTitle: lang.suggested,
        itemsList: suggestedVectors,
      });
    }

    sectionsJson.push({
      sectionTitle: lang.all,
      itemsList: vectors,
    });
    return { data: sectionsJson, isClearBtnDisabled: isClearBtnDisabled };
  };

  const handleCheckBoxChange = (item) => {
    let filterFinalArr = filterFinal !== "[]" && typeof filterFinal === "string" ? JSON.parse(filterFinal) : filterFinal; // when filterFinal = ""[]"", remove the extra ""
    let isAdvancedFilterApplied =
      filterFinalArr &&
      filterFinalArr !== "[]" &&
      (typeof filterFinalArr === "object" ? filterFinalArr.find((e) => !e.isBasicFilter) : JSON.parse(filterFinalArr).find((e) => !e.isBasicFilter));
    if (isAdvancedFilterApplied) {
      setBasicFilterItem(item);
      openFilterModal();
    } else {
      handleBasicFilterCheckBoxChange(item);
    }
  };

  const filterOnBasicFilter = (basicAppliedFilter) => {
    let filters = copyObjectValues(basicAppliedFilter.filter((e) => e.entities.length));
    filters?.forEach((e) => {
      e.isBasicFilter = true;
      e.filter_display_name = undefined;
      e.entities = e.entities.map((ent) => {
        ent.value = ent.number;
        ent.label = ent.number;
        return ent;
      });
    });
    let filtersByName = copyObjectValues(filters);
    filtersByName?.forEach((e) => {
      e.field = "name";
      e.entities = e.entities.map((ent) => {
        ent.value = ent.name;
        ent.label = ent.name;
        return ent;
      });
      filters.push(e);
    });

    if (!isBridgeReport) {
      localStorage.removeItem(SAVED_FILTER_ID_COOKIE);
      localStorage.removeItem(CURRENT_FILTER_COOKIE);
      setLocalStorageValueByParameter(CURRENT_FILTER_COOKIE, JSON.stringify(filters));
    }
    let filterFinalBasic = copyObjectValues(basicAppliedFilter); //typeof _this.state.filterFinalBasic === "string" ? JSON.parse(_this.state.filterFinalBasic) : _this.state.filterFinalBasic;
    if (filterDialRef?.current) {
      filterDialRef?.current?.setState({
        savedFilterData: {},
      });
    }

    if (filterDialRef?.current?.savedFilterNameInput) {
      filterDialRef.current.savedFilterNameInput.value = "";
    }
    filterFinalBasic?.forEach((e) => {
      e.filter_display_name = undefined;
    });

    filters = addCorrespondingParenthesisToFilter(filters);
    if (!isBridgeReport) {
      localStorage.removeItem(SAVED_FILTER_ID_COOKIE);
      localStorage.removeItem(CURRENT_FILTER_COOKIE);
      setLocalStorageValueByParameter(CURRENT_FILTER_COOKIE, JSON.stringify(filters));
    }
    setFilterFinal(JSON.stringify(filters));
    setFilterFinalBasic(filterFinalBasic);
    setExportQueryFilter(formatBasicFilter(filters, userSettings.user?.user_allowed_vectors));
    if (!showGreyOverLay) {
      // apply();
      setCallGo(true);
    }
  };

  const handleBasicFilterCheckBoxChange = (item, clear, entity, allEntities) => {
    let tempState = {};
    let filter = typeof filterFinalBasic === "string" ? JSON.parse(filterFinalBasic) : filterFinalBasic;
    let initialFilter = copyObjectValues(filter);

    filter = filter || [];
    let removedLine = [];
    let isClear = false;
    let isUnCheck = false;
    if (entity && item) {
      for (let e in filter) {
        if (filter[e].vector === item.vector) {
          if (entity.checked === true) {
            filter[e].entities.push(entity);
          } else {
            filter[e].entities = filter[e].entities.filter((f) => f.value !== entity.value);
          }
        }
      }
    } else if (item && allEntities) {
      for (let e in filter) {
        if (filter[e].vector === item.vector) {
          for (let j in allEntities) {
            filter[e].entities.push(allEntities[j]);
          }
        }
      }
    } else if (item) {
      if (filter?.find((e) => e.vector === item.value)) {
        removedLine = filter.find((e) => e.vector === item.value);
        filter = filter.filter((e) => e.vector !== item.value);
        delete basicFilterEntities[item.value];
        isUnCheck = true;
      } else {
        let selectedEmptyFilter = getNewEntityFilter(
          item.value,
          FILTER.VALUES.FIELD.NUMBER,
          [],
          FILTER.VALUES.FILTER_ROW_TYPE.FILTER,
          lang.ui_filter.dropdowns.and
        );
        selectedEmptyFilter.vectorLabel = item.label;
        filter?.push(selectedEmptyFilter);
      }
    } else if (clear) {
      filter = [];
      tempState.filterFinal = [];
      isClear = true;
      // if (!reportTitle.startsWith(ALL_WIDGETS.FIELDS.ENTITY_STACKS) && !reportTitle.startsWith(lang.compare_stacks)) {
        localStorage.removeItem(SAVED_FILTER_ID_COOKIE);
        localStorage.removeItem(CURRENT_FILTER_COOKIE);
        tempState.exportQueryFilter = formatBasicFilter(filter, userSettings.user?.user_allowed_vectors);
        setExportQueryFilter(tempState.exportQueryFilter);
      // }
    }
    filter = addCorrespondingParenthesisToFilter(filter);
    if (isUnCheck && removedLine.entities.length > 0) {
      tempState.filterFinalBasic = filter;
      let copyFilter = copyObjectValues(filter);
      copyFilter.forEach((e) => {
        if (e.entities) {
          e.isBasicFilter = true;
          e.entities = e.entities.map((ent) => {
            ent.value = ent.number;
            ent.label = ent.number;
            return ent;
          });
        }
      });
      let filtersByName = copyObjectValues(copyFilter);
      filtersByName.forEach((e) => {
        e.field = "name";
        e.entities = e.entities.map((ent) => {
          ent.value = ent.name;
          ent.label = ent.name;
          return ent;
        });
        copyFilter.push(e);
      });
      tempState.filterFinal = JSON.stringify(copyFilter);
      tempState.exportQueryFilter = formatBasicFilter(copyFilter, userSettings.user?.user_allowed_vectors);
    }
    filter.map((item) => {
      item.filter_display_name = undefined;
    });
    tempState.filterFinalBasic = isUnCheck ? JSON.stringify(filter) : filter;
    setFilterFinal(tempState.filterFinal);
    setFilterFinalBasic(tempState.filterFinalBasic);

    if(item && Object.keys(basicFilterEntities).length && basicFilterEntities[item.value]) { 
      basicFilterEntities[item.value].map(entity => entity.checked = false); 
    } 
    
    let countState = countRef.current;
    setCount(countState + 1);
    countRef.current = countState + 1;
    if (filterDialRef?.current) {
      filterDialRef?.current?.setState({
        savedFilterData: {},
      });
    }

    if (filterDialRef?.current?.savedFilterNameInput) {
      filterDialRef.current.savedFilterNameInput.value = "";
    }

    if (isUnCheck && removedLine.entities.length > 0 && !showGreyOverLay) {
      if (!isBridgeReport) {
        localStorage.removeItem(SAVED_FILTER_ID_COOKIE);
        localStorage.removeItem(CURRENT_FILTER_COOKIE);
        setLocalStorageValueByParameter(CURRENT_FILTER_COOKIE, JSON.stringify(tempState.filterFinal));
      }
      let countstate = countRef.current;
      setCount(countstate + 1);
      countRef.current = countState + 1;
      // apply();
      setCallGo(true);
    } else if (!isClear) {
      getFilterEntities(item, vectorState, setBasicFilterEntities, basicFilterEntitiesState, profitFormat, userSettings, scenarioState, tempState.filterFinal, getCountRef, setCount, setCountRef);
    }
  };

  const clearClick = () => {
    handleBasicFilterCheckBoxChange(undefined, true);
    if (!showGreyOverLay) {
      // apply();
      setCallGo(true);
    }
  };

  const openFilterModal = () => {
    setOpenFilterDialog(true);
  };

  const closeFilterModal = () => {
    setOpenFilterDialog(false);
  };

  const filterModalBody = () => {
    return (
      <h4>
        {lang.clear_advanced_filter}
        <br />
        {lang.switching_to_quick_filter}
      </h4>
    );
  };

  const handleBasicFilterAndClose = () => {
    closeFilterModal();
    clearAdvancedFilter(basicFilterItem);
  };

  const clearAdvancedFilter = (basicFilterItem) => {
    filterDialRef?.current?.clearFilter();
    setFilterFinal([]);
    handleBasicFilterCheckBoxChange(basicFilterItem);
  };

  const saveDialogActions = () => {
    return (
      <>
        <Button
          label={lang.modal.buttons.clear_filter}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={handleBasicFilterAndClose}
        />
        <Button
          label={lang.modal.buttons.cancel}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          aria-label="Close"
          onBtnClick={closeFilterModal}
        />
      </>
    );
  };

  /**
   * on back, we set the states the way they were before going to bridge report.
   * They are saved in 'erosionStates' before going to bridge report
   */
  const onBridgeBackClick = () => {
    let filter = erosionStates?.filterFinal;
    setIsBridgeReport(false);
    setShowGreyOverLay(false);
    setBridgeOptionsState(erosionStates?.bridgeOptionsState);
    setComparisonValue(erosionStates?.comparisonValue);
    setFilterFinal(filter);
    setFilterFinalBasic(erosionStates?.filterFinalBasic);
    setVectorState(prevState => ({...prevState, mixVector: vectorState.originalMixVector, vectorOptions: copyObjectValues(vectorState.originalVectorOptions)}));
    setSelectReportVal(undefined);
    setSelectReportDisabled(true);
    setSelectReportDisabledMsg(comparisonValue === BP_QUADRANT_TIER ? lang.select_entity_to_view  : lang.select_entities_to_view);
    setExportQueryFilter(formatQueryFilter(tryParse(filter)));
    filterSet.current = false;

    let periodStates = copyObjectValues(periodsStatusState);
    periodStates.customStartDate = erosionStates?.dates.customStartDate;
    periodStates.customEndDate = erosionStates?.dates.customEndDate;
    periodStates.nextCustomStartDate = erosionStates?.dates.nextCustomStartDate;
    periodStates.nextCustomEndDate = erosionStates?.dates.nextCustomEndDate;
    setPeriodsStatusState(periodStates);

    filterDialRef.current?.setState({
      savedFilterData: erosionStates.savedFilterData,
      useOutsideFilter: false,
      outsideFilter: tryParse(filter),
    })
    // Adding filter to history for inherittance between screens
    history.push({
      state: {
        outsideFilter: filter,
        mainFilter: filter,
        filter: filter,
        filterFinal: filter,
        filterFinalBasic: erosionStates?.filterFinalBasic,
        originalMainFilter: filter,
        scenarioState: history?.location?.state?.scenarioState,
        isRedirectedFromBridge: true
      }
    })

    setLocalStorageValueByParameter(CURRENT_FILTER_COOKIE, JSON.stringify(filter));

  }

  let trackingData = window._pi_getTrackingDataObj();
  trackingData = trackingData === null ? "" : JSON.stringify(trackingData);
  let scenarioLabel = scenarioState?.scenarioObjects?.length > 0 ? scenarioState?.scenarioObjects[0].label : "";

  let showSkeleton = !(
    Object.keys(periodsStatusState).length > 0 &&
    Object.keys(vectorState).length > 0 &&
    Object.keys(scenarioState).length > 0 &&
    ((manageColumnsProfile && manageColsAccess && !Object.keys(manageColsAccess).every((k) => !manageColsAccess[k])) ||
      Object.keys(manageColsAccess).every((k) => !manageColsAccess[k])) &&
    Object.keys(datasetState).length > 0 &&
    headerElements.length > 0
  );


  useEffect(() => {
    updateWindowLoading(showSkeleton, "skeleton-loader");
  }, [showSkeleton])

  return (
    <Suspense fallback={<p id="loading">Loading...</p>}>
      {showSkeleton && <LoaderSkeleton />}
      <Modal
        id={"check-limit-access-dialog"}
        openDialog={openLimitAccessDialog}
        closeClick={() => setLimitAccessDialogOpen(false, "")}
        bodyContent={() => messageDialogContent(limitAccessMsg)}
        dialogActions={() => (
          <Button
            label={lang.modal.buttons.yes}
            variant={BUTTON_VARIANT.PRIMARY}
            size={SIZES.DEFAULT}
            type={BUTTON_TYPE.DEFAULT}
            aria-label="Close"
            onBtnClick={() => setLimitAccessDialogOpen(false, "")}
          />
        )}
        size={DIALOG_SIZE.MEDIUM}
      />
      <Modal
        id={"header-info-dialog"}
        openDialog={openInfoDialog}
        bodyContent={() => <span className="uk-text-large">{infoDialogMsg}</span>}
        dialogActions={() => (
          <Button
            label={lang.modal.buttons.ok}
            variant={BUTTON_VARIANT.PRIMARY}
            size={SIZES.DEFAULT}
            type={BUTTON_TYPE.DEFAULT}
            onBtnClick={() => setInfoDialogOpen(false, "")}
          />
        )}
        closeClick={() => setInfoDialogOpen(false, "")}
        size={DIALOG_SIZE.MEDIUM}
      />
      <Modal
        id={"switch-basic-to-advanced-filter"}
        openDialog={openFilterDialog}
        bodyContent={filterModalBody}
        dialogActions={saveDialogActions}
        size={DIALOG_SIZE.MEDIUM}
        closeClick={closeFilterModal}
      />
      <div ref={manageColsOverlay} id="overlay-manage-columns" className="uk-hidden"></div>

      {/* <iframe name="frame" id="frame_export" style={{ display: "none" }}></iframe> */}
      <div
        id={"main-component-container"}
        className={"main-component-container " + (showSkeleton ? "hidden" : "")}
        style={{ "--banner-height": isScenarioBannerVisible ? "6%" : 0 }}
      >
        <Loader newLoader />
        <div className={isScenarioBannerVisible ? "header-banner-div" : "header-banner-div-hidden"}>
          <NewBanner
            bannerClassName={"header-banner scenario-banner"}
            labelClassName={"header-banner-label scenario-banner-label"}
            icon={<RoundInfoIcon className="info-banner-icon" />}
            label={lang.under_review_scenario_banner + " (" + scenarioLabel + ")"}
            body={
              <Button
                id={"banner-dismiss-btn"}
                label={lang.modal.buttons.dismiss}
                variant={BUTTON_VARIANT.TERTIARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                onBtnClick={() => setScenarioBannerVisible(false)}
              />
            }
          />
        </div>

        <div className="main-report-header noprint">
          <ReportHeader headerElements={headerElements} />
        </div>

        <div className="main_report">
          {headerElements.length > 0 && showGreyOverLay && <div className="overlay-div">{lang.overlay_go_msg}</div>}

          <div id="main_report_container" className="main_report_container">
            {areObjectsNotEmpty([periodsStatusState, vectorState, scenarioState, datasetState]) &&
              profileHasColumnsAccess(manageColumnsProfile, manageColsAccess) &&
              headerElements.length > 0 && (
                <ErosionAndGrowth
                  bridgeOptionsState={bridgeOptionsState}
                  checkForLimitAccessMessage={checkForLimitAccessMessage}
                  clientPeriodsState={clientPeriodsState}
                  exportQueryFilter={getExportQueryFilter}
                  datasetState={datasetState}
                  profitFormat={profitFormat}
                  setDataLength={setDataLength}
                  manageColsAccess={manageColsAccess}
                  reselectProfile={reselectProfile}
                  fetchDataOnMount={isFromDashboards}
                  comparisonValue={comparisonValue}
                  filterDialRef={filterDialRef}
                  filterFinal={filterFinal}
                  isBridgeReport={isBridgeReport}
                  isDrilling={isDrilling}
                  setSelectReportDisabledMsg={setSelectReportDisabledMsg}
                  setSelectReportDisabled={setSelectReportDisabled}
                  setShowGreyOverLay={setShowGreyOverLay}
                  psLinesOptions={psLinesOptions && psLinesOptions.has(scenarioState.scenario) ? psLinesOptions.get(scenarioState.scenario)[0] : []}
                  manageColumnsDefaultProfile={manageColumnsDefaultProfile}
                  manageColumnsProfile={manageColumnsProfile}
                  mixVector={vectorState.mixVector}
                  onBridgeBackClick={onBridgeBackClick}
                  periodsStatusState={periodsStatusState}
                  ref={reportRef}
                  scenarioState={scenarioState}
                  setIsDrilling={setIsDrilling}
                  userAllowedMenuLinks={userAllowedMenuLinks}
                  userSettingsState={userSettings}
                  vectorState={vectorState}
                  checkIfAtLeastOnePeriodIsGenerated={checkIfAtLeastOnePeriodIsGeneratedFunc}
                />
              )}
          </div>
        </div>
      </div>
    </Suspense>
  );
};

export { ErosionAndGrowthWrapper };

