function checkForSpecialChars(str) {
	if(!str) {
		return str;
	}

	return /\W/g.test(str);
}

function checkStartsWithDigit(str) {
	if(!str) {
		return str;
	}

	return /^\d/.test(str);
}

/**
 * extracts digits from a string
 * @param {*} str 
 * returns array
 */
function extractNumber(str, all) {
	if(!str) {
		return str;
	}

	if(all) {
		return str.match(/\d+/g);
	} else {
		return str.match(/\d+/g) ? Number(str.match(/\d+/g)[0]) : null;
	}
}

/**
 * This function takes a string of one word or more, and returns the
 * first letter, either capitalized or not
 * @param {*} str 
 * @param {*} toLowerCase 
 */
function getInitials(str, toLowerCase) {
	let words = "";
	if(Array.isArray(str)) {
		words = str;
	} else {
		words = str.split(/[^0-9A-Za-z]/g);
	}
	
	let returnStr = words.map(word=>{
		let letter = word.charAt(0);
		return letter ? toLowerCase ? letter.toLowerCase() : letter.toUpperCase() : "";
	});

	return returnStr.join("");
}

function replaceSpecialChars(str, replaceChar) {
	if(!str) {
		return str;
	}
	replaceChar = replaceChar || "";

	return str.replace(/\W/g, replaceChar);
}

function trim(str) {
	return str.replace(/ /g, "");
}

function compareBasicStrings(str1, str2) {
	return trim(str1).toLowerCase() === trim(str2).toLowerCase();
}

function getBasicString(str) {
	return trim(str).toLowerCase();
}

function formaliseName(name, splitChar = "_") {
	let words = name.split(splitChar);
	//return words separated by space
	return words.map(wrd=>{
		return wrd.charAt(0).toUpperCase() + wrd.slice(1);	//capitalise first letter and the rest of the word
	}).join(" ").trim();
}

function replaceAllInstancesOf(str, valuesArr, replacement="") {
	for(let val in valuesArr) {
		str = str.replace(valuesArr[val], replacement);
	}

	return str;
}

function isBlank(str){
	return str?.trim() === "";
}

function isStringSurpassingXCharacters(str, x) {
    return typeof str === 'string' && str.length > x;
}

/**
 * Finds the longest string in an array of strings.
 * If the input is not an array or the array is empty, it returns an empty string.
 *
 * @param {string[]} strings - The array of strings to find the longest one in.
 * @returns {string} The longest string in the array. If the array is empty or not an array, it returns an empty string.
 */
function findLongestString(strings) {
    if (!Array.isArray(strings) || strings.length === 0) {
        return "";
    }
    return strings.reduce((longest, current) => {
        return (current?.length || 0) > (longest.length || 0) ? current : longest;
    }, "");
}

/**
 * Finds the longest string in an array of strings based on text width based on font and font size.
 * If the input is not an array or the array is empty, it returns an empty string.
 *
 * @param {string[]} strings - The array of strings to find the longest one in.
 * @returns {string} The longest string in the array. If the array is empty or not an array, it returns an empty string.
 */
function findLongestStringByTextWidth(strings, font) {
    if (!Array.isArray(strings) || strings.length === 0) {
        return "";
    }
    return strings.reduce((longest, current) => {
		current = current.trim();
		longest = longest.trim();
		if(current.length === longest.length) {
			return (getTextWidth(current, font) || 0) > (getTextWidth(longest, font) || 0) ? current : longest;
		} 

		if(current.length > longest.length && getTextWidth(current, font) < getTextWidth(longest, font)) {
			return longest;
		} else if (current.length < longest.length && getTextWidth(current, font) > getTextWidth(longest, font)) {
			return current;
		} else {
			return (current.length || 0) > (longest.length || 0) ? current : longest;
		}
    }, "");
}

/**
 * Calculates the width of the given text when rendered with the specified font.
 * @param {string} text - The text for which the width needs to be calculated.
 * @param {string} font - The font style and size used to render the text.
 * @returns {number} The width of the text when rendered with the specified font.
 */
function getTextWidth(text, font) {
	const canvas = document.createElement("canvas");
	const context = canvas.getContext("2d");
	context.font = font;
	const metrics = context.measureText(text);
	return metrics.width;
};

export {checkForSpecialChars, checkStartsWithDigit, getInitials, replaceSpecialChars, trim, compareBasicStrings, getBasicString, extractNumber,
	formaliseName, replaceAllInstancesOf, isStringSurpassingXCharacters, isBlank, findLongestString, getTextWidth, findLongestStringByTextWidth}