//ACTIONS -----------------------------------------------------
export function updateCustomGroups(customGroups, scenarioId, clear) {
    return {
      type: 'UPDATE_CUSTOM_GROUPS',
      scenarioId: scenarioId,
      customGroups:customGroups,
      clear:clear
    }
  }
  
  export function getCustomGroups() {
    return {
      type: 'GET_CUSTOM_GROUPS'
    }
  }

  export function updateProfiles(profiles, scenarioId, clear) {
    return {
      type: 'UPDATE_PROFILES',
      scenarioId: scenarioId,
      profiles:profiles,
      clear:clear
    }
  }
  
  export function getProfiles() {
    return {
      type: 'GET_PROFILES'
    }
  }

  export function updateColumns(columns, scenarioId, clear) {
    return {
      type: 'UPDATE_COLUMNS',
      scenarioId: scenarioId,
      profileColumns:columns,
      clear:clear
    }
  }
  
  export function getColumns() {
    return {
      type: 'GET_COLUMNS'
    }
  }

  export function updateStateProfiles(stateProfiles, scenarioId, clear) {
    return {
      type: 'UPDATE_STATE_PROFILES',
      scenarioId: scenarioId,
      stateProfiles:stateProfiles,
      clear:clear
    }
  }

    export function updateColumnProfiles(columnProfiles, scenarioId, clear) {
      return {
        type: 'UPDATE_COLUMN_PROFILES',
        scenarioId: scenarioId,
        columnProfiles:columnProfiles,
        clear:clear
      }
    }
  
  