import React, { Component } from 'react';
import { lang } from '../../language/messages_en';
import { DROPDOWN_TYPE, MANAGE_COLUMNS, PROFILE_COLUMN } from '../../class/constants';
import Select from 'react-select';
import { copyObjectValues, findOptionByKey, findOptionByKeyValue } from '../../class/utils';
import Container from './Container.js';
import CalculatedProfitStackLine from './CalculatedProfitStackLine';
import { getEmbeddedChild, updateAllElements, updateElementsWithChildren } from '../../class/array';
import { convertPxToViewport } from '../../class/formatting';
import DropDown from '../../newComponents/DropDown';
import Formula from "./Formula";

const _treeChildren = "children";       //this is the "children" key understood by react-tree-select
const _checked = "checked";             //this is the flag sent by the library that indicates whether or not the selectedNode is selected or deleted

class ManageColumnsFormat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columnType: this.props.type,
            group: "",
            options: [],
            vector: "",
            lineAttribute: "",
            lineAttributeLabel: "",
            selectedProfitStackLine: {},
            profitStackLines: this.props.profileColumns.length > 0 ? findOptionByKeyValue(copyObjectValues(this.props.profileColumns), PROFILE_COLUMN.RETURN_NAME, MANAGE_COLUMNS.GROUPS.profit_stack_line) ?
            updateElementsWithChildren(findOptionByKeyValue(copyObjectValues(this.props.profileColumns), PROFILE_COLUMN.RETURN_NAME, MANAGE_COLUMNS.GROUPS.profit_stack_line)[PROFILE_COLUMN.CHILDREN],
             _treeChildren, PROFILE_COLUMN.CHILDREN, _treeChildren) : [] : []
        }
        this.handleChange = this.handleChange.bind(this);
        this.vectorHandleChange = this.vectorHandleChange.bind(this);
        this.handleChangeOptions = this.handleChangeOptions.bind(this);
        this.lineAttributeHandleChange = this.lineAttributeHandleChange.bind(this);
        this.validateFields = this.validateFields.bind(this);
        this.resetInputs = this.resetInputs.bind(this);
        this.onChange  = this.onChange.bind(this);
        this.formulaRef = React.createRef();
    }

    onChange = (currentNode) => {
        let profitStackLines = this.state.profitStackLines.length > 0 ? this.state.profitStackLines : this.props.profileColumns.length > 0 ? findOptionByKeyValue(copyObjectValues(this.props.profileColumns), PROFILE_COLUMN.RETURN_NAME, MANAGE_COLUMNS.GROUPS.profit_stack_line) ?
        updateElementsWithChildren(findOptionByKeyValue(copyObjectValues(this.props.profileColumns), PROFILE_COLUMN.RETURN_NAME, MANAGE_COLUMNS.GROUPS.profit_stack_line)[PROFILE_COLUMN.CHILDREN],
         _treeChildren, PROFILE_COLUMN.CHILDREN, _treeChildren) : [] : []
        let psLines = updateAllElements(copyObjectValues(profitStackLines), _treeChildren, _checked, false);
        let selectedLine = getEmbeddedChild(psLines, _treeChildren, PROFILE_COLUMN.RETURN_NAME, currentNode[PROFILE_COLUMN.RETURN_NAME]);
        selectedLine[_checked] = currentNode[_checked];

        this.setState({
            selectedProfitStackLine: selectedLine[_checked] ? selectedLine : {},
            profitStackLines: psLines
        });
    };

    handleChange(event, callback) {
        let ops = [];
        var group = event.value
        if(group === lang.manage_columns.group_labels[1]) {
            ops = this.props.lineAttributeOptions;
        } else if(group === lang.manage_columns.group_labels[2]) {
            ops = this.props.vectorsList;
        }

        this.setState({
            group: group,
            options: ops,
            option: "",
            label: "",
            isGroupValid: "",
            isVectorValid:"",
            islineAttributeValid:""
        }, ()=>{
            if(typeof callback === "function") {
                callback();
            }
        })
    }

    vectorHandleChange(event) {
        this.setState({
            vector: event.value,
            isVectorValid: ""
        })
    }

    handleChangeOptions(event, group="") {
        let tempState = {}
        if(group !== lang.manage_columns.group_labels[0]) {
            tempState = {
                option: event.value,
                label: event.label,
                isOptionValid: "",
            };
        } else {
            let psLines = findOptionByKeyValue(this.props.profileColumns, PROFILE_COLUMN.RETURN_NAME, MANAGE_COLUMNS.GROUPS.profit_stack_line);
            psLines = updateAllElements(psLines[PROFILE_COLUMN.CHILDREN], PROFILE_COLUMN.CHILDREN, _checked, false);
            let selectedLine = getEmbeddedChild(psLines, PROFILE_COLUMN.CHILDREN, PROFILE_COLUMN.RETURN_NAME, event.value);
            if(!!selectedLine) {
                selectedLine[_checked] = true;
            }
            tempState = {
                profitStackLines: updateElementsWithChildren(copyObjectValues(psLines), _treeChildren, PROFILE_COLUMN.CHILDREN, _treeChildren),
                selectedProfitStackLine: selectedLine || {}
                // copyObjectValues(psLines).map(ch=>{
                //     ch[_treeChildren] = ch[PROFILE_COLUMN.CHILDREN];   //this is the key that is understood by react-tree-select
                //     delete ch[PROFILE_COLUMN.CHILDREN];
                //     return ch;
                // })
            }
        }
        this.setState(tempState);
    }

    lineAttributeHandleChange(event) {
        this.setState({
            lineAttribute: event.value,
            lineAttributeLabel: event.label,
            islineAttributeValid: ""
        })
    }

    translation() {
        let formula = "";
        let _this = this;
        let vectors = this.props.vectorsList;
        let lineAttributeOptions = this.props.lineAttributeOptions;

        let labels = this.state.group === lang.manage_columns.group_labels[1] ? lineAttributeOptions : vectors;
        let label = labels ? labels.filter(e => e.value === _this.state.label) : [];
        label = label.length > 0 ? label[0].label : _this.state.label;
       
        if (this.props.type === MANAGE_COLUMNS.PER_VECTOR) {
            let vector = vectors.filter(e => e.value === _this.state.vector);
            vector = vector.length > 0 ? vector[0].label : this.state.vector;

            if (this.state.group !== lang.manage_columns.group_labels[0]) {
                formula = label + " Per " + vector;
            } else if (Object.keys(this.state.selectedProfitStackLine).length) {
                formula = this.state.selectedProfitStackLine[PROFILE_COLUMN.NAME] + " Per " + vector;
            }
        } else if (this.props.type === MANAGE_COLUMNS.PER_LINE_ATTRIBUTE) {
            let lineAttribute = lineAttributeOptions ? lineAttributeOptions.filter(f => f.value === _this.state.lineAttributeLabel) : [];
            lineAttribute = lineAttribute.length > 0 ? lineAttribute[0].label : _this.state.lineAttributeLabel;
           
            if (this.state.group !== lang.manage_columns.group_labels[0]) {
                formula = label + " Per " + lineAttribute;
            } else if (Object.keys(this.state.selectedProfitStackLine).length) {
                formula = this.state.selectedProfitStackLine[PROFILE_COLUMN.NAME] + " Per " + lineAttribute;
            }
        }
        return formula;
    }

    validateFields() {
        var result = true;
        let isGroupValid = "";
        let isOptionValid = "";
        let isVectorValid = "";
        let islineAttributeValid = "";

        if(this.props.type !== MANAGE_COLUMNS.VALUES[0]){
            if (this.state.group === undefined || this.state.group === "") {
                isGroupValid = lang.manage_access.group_empty;
                result = false;
            }
            if (this.state.group !== lang.manage_columns.group_labels[0] && this.state.group !== "" && this.state.group !== undefined && (this.state.option === undefined || this.state.option === "")) {
                isOptionValid = lang.manage_access.option_empty;
                result = false;
            }
            if (this.props.type === MANAGE_COLUMNS.PER_VECTOR && (this.state.vector === undefined || this.state.vector === "")) {
                isVectorValid = lang.manage_access.vector_empty;
                result = false;
            }
            if (this.props.type === MANAGE_COLUMNS.PER_LINE_ATTRIBUTE &&(this.state.lineAttribute === undefined || this.state.lineAttribute === "")) {
                islineAttributeValid = lang.manage_access.line_attribute_empty;
                result = false;
            }
        } else {
            if(this.formulaRef.current) {
                result = this.formulaRef.current.isFormulaValid();
            }
        }
        this.setState({
            isGroupValid: isGroupValid,
            isOptionValid: isOptionValid,
            isVectorValid: isVectorValid,
            islineAttributeValid: islineAttributeValid
        })
       return result;
    }

    resetInputs() {
        if(!this.props.profileColumns || this.props.profileColumns.length === 0) {
            return;
        }
        let psLines = findOptionByKeyValue(this.props.profileColumns, PROFILE_COLUMN.RETURN_NAME, MANAGE_COLUMNS.GROUPS.profit_stack_line);
        psLines = updateAllElements(psLines[PROFILE_COLUMN.CHILDREN], PROFILE_COLUMN.CHILDREN, _checked, false);
        this.setState({
            lineAttribute: "",
            vector: "",
            lineAttributeLabel: "",
            label: "",
            isGroupValid: "",
            isOptionValid: "",
            isVectorValid: "",
            islineAttributeValid: "",
            group: "",
            option: "",
            profitStackLines: updateElementsWithChildren(copyObjectValues(psLines), _treeChildren, PROFILE_COLUMN.CHILDREN, _treeChildren),
            selectedProfitStackLine: {}
        },function(){
            if(this.calculatedPslRef){
                this.calculatedPslRef.state.pslFormula = [];
                this.calculatedPslRef?.resetFilterColumns();
                this.forceUpdate();
            }else if(this.formulaRef.current){
                this.formulaRef?.current.resetInputField();
            }
        })
    }

    render() {
        const calculatedColumnDescription = ({label, tooltipText}) => (
            <div className={"uk-flex-between uk-display-flex"}>
                {label} 
                <i className="fal fa-info-circle text-white uk-margin-xsmall-top" 
                    uk-tooltip={!!tooltipText ? tooltipText : lang.manage_columns.NO_DESCRIPTION}/>
            </div>
        );

        const customStyles = {
            placeholder: (styles) => ({ ...styles,  color: "#C6CCD7", fontSize: convertPxToViewport(12), lineHeight: convertPxToViewport(12) }),
            option: (styles) => ({ ...styles, cursor: 'pointer', padding: convertPxToViewport(8)+' 0 '+' '+convertPxToViewport(8)+' '+convertPxToViewport(9) }),
            control: (provided, state) => ({
                ...provided,
                cursor: 'pointer',
                borderColor: '#dcdcdc',
                minHeight: convertPxToViewport(30),
                height: convertPxToViewport(33),
                fontSize: convertPxToViewport(12),
                borderRadius: convertPxToViewport(4),
                borderWidth: convertPxToViewport(1),
            }),
            valueContainer: (provided, state) => ({
                ...provided,
                height: convertPxToViewport(30),
                padding: '0 ' + convertPxToViewport(6)
            }),
        };

        const optionLabel = ({ value, label, isDisabled }) => (
            <div title={label} className={(isDisabled ? "option-group-header" : "")+ " option-padding"}>{label}</div>
        );
        let profitStackLines = this.state.profitStackLines.length > 0 ? this.state.profitStackLines : this.props.profileColumns.length > 0 ? findOptionByKeyValue(copyObjectValues(this.props.profileColumns), PROFILE_COLUMN.RETURN_NAME, MANAGE_COLUMNS.GROUPS.profit_stack_line) ?
        updateElementsWithChildren(findOptionByKeyValue(copyObjectValues(this.props.profileColumns), PROFILE_COLUMN.RETURN_NAME, MANAGE_COLUMNS.GROUPS.profit_stack_line)[PROFILE_COLUMN.CHILDREN],
         _treeChildren, PROFILE_COLUMN.CHILDREN, _treeChildren) : [] : []

        return(
            <div>

                <div>
                    <div className="add_column_second_section_title">
                        {this.props.useNewAddColumn ?
                            <>
                                <div className="manage-columns-subtitle">Create the formula of your new column</div>
                                <div className="manage-columns-heading">Complete your formula using the fields provided below</div>
                            </>
                            :
                            <div className="fs-14">Formula</div>
                        }
                    </div>
                    <div className={!this.props.useNewAddColumn ?"uk-padding-small uk-border uk-border-rounded formula_container_manage_columns":""}>
                    {(this.props.type === MANAGE_COLUMNS.PER_VECTOR) || (this.props.type === MANAGE_COLUMNS.PER_LINE_ATTRIBUTE) ?
                        <div className="formula_container">
                            <div>
                                {/*<label htmlFor={MANAGE_COLUMNS.GROUP_ID}>{lang.manage_columns.titles.group}</label>*/}
                                {/*<Select */}
                                {/*    id={MANAGE_COLUMNS.GROUP_ID} */}
                                {/*    classNamePrefix={"manage-columns-dropdowns"}*/}
                                {/*    value={findOptionByKey(this.props.groups, this.state.group)} */}
                                {/*    onChange={this.handleChange}*/}
                                {/*    options={this.props.groups}*/}
                                {/*    styles={customStyles}*/}
                                {/*    placeholder={lang.manage_columns.titles.group}*/}
                                {/*    maxMenuHeight={convertPxToViewport(190)}*/}
                                {/*    />*/}
                                <DropDown
                                    id={MANAGE_COLUMNS.GROUP_ID}
                                    classNamePrefix={"manage-columns-dropdowns"}
                                    value={findOptionByKey(this.props.groups, this.state.group)}
                                    onChange={this.handleChange}
                                    options={this.props.groups}
                                    placeholder={lang.manage_columns.titles.group}
                                    maxMenuHeight={convertPxToViewport(190)}
                                    type={DROPDOWN_TYPE.INPUT}
                                    disabled={this.props.disabled}
                                />
                                {/* <DropDown  // to remove comment and comment <Select
                                    id={MANAGE_COLUMNS.GROUP_ID} 
                                    classNamePrefix={"manage-columns-dropdowns"}
                                    value={findOptionByKey(this.props.groups, this.state.group)} 
                                    onChange={this.handleChange}
                                    options={this.props.groups}
                                    type={DROPDOWN_TYPE.INPUT}                                    
                                    maxMenuHeight={convertPxToViewport(190)}
                                    /> */}
                                    {this.state.isGroupValid ? <div className="fs-12 red italic">{this.state.isGroupValid}</div> : ""}
                            </div> 
                            {this.state.group !== lang.manage_columns.group_labels[0]?
                                <div>
                                    {/*<label htmlFor={MANAGE_COLUMNS.CHOSEN_GROUP_ID}>{this.state.group}*/}
                                    {/*    {this.state.group.length > 0 ? <sup className="red">*</sup> :""}*/}
                                    {/*</label>*/}
                                    <DropDown
                                        id={MANAGE_COLUMNS.CHOSEN_GROUP_ID} 
                                        classNamePrefix={"manage-columns-dropdowns"}
                                        value={findOptionByKey(this.state.options, this.state.option)} 
                                        onChange={this.handleChangeOptions}
                                        options={this.state.options}
                                        formatOptionLabel={calculatedColumnDescription}
                                        placeholder={lang.manage_columns.select_group + "*"}
                                        maxMenuHeight={convertPxToViewport(300)}
                                        type={DROPDOWN_TYPE.INPUT}
                                        disabled={this.props.disabled}
                                    />
                                    {/* <DropDown // to remove comment and comment <Select
                                        id={MANAGE_COLUMNS.CHOSEN_GROUP_ID} 
                                        classNamePrefix={"manage-columns-dropdowns"}
                                        value={findOptionByKey(this.state.options, this.state.option)} 
                                        onChange={this.handleChangeOptions}
                                        options={this.state.options}
                                        formatOptionLabel={calculatedColumnDescription}
                                        type={DROPDOWN_TYPE.INPUT}
                                        placeholder={lang.manage_columns.select_group}
                                        maxMenuHeight={convertPxToViewport(300)}
                                    /> */}
                                    {this.state.isOptionValid ? <div className="fs-12 red italic">{this.state.isOptionValid}</div> : ""}
                                </div> :
                                <div>
                                    {/*<label htmlFor={MANAGE_COLUMNS.CHOSEN_GROUP_ID}>{this.state.group}<sup className="red">*</sup></label>*/}
                                    <Container texts={{ placeholder: lang.heatmap_configure.select_ps_line }}
                                        data={profitStackLines} onChange={this.onChange} mode={"radioSelect"} className={"dropdown-tree"} disabled={this.props.disabled}
                                    />
                                </div>
                            }
                            {this.props.type === MANAGE_COLUMNS.PER_VECTOR ?
                                <div>
                                    {/*<label htmlFor={MANAGE_COLUMNS.PER_VECTOR_ID}>{lang.manage_columns.per_vector}<sup className="red">*</sup></label>*/}
                                    <DropDown
                                        id={MANAGE_COLUMNS.PER_VECTOR_ID} 
                                        classNamePrefix={"manage-columns-dropdowns"}
                                        value={findOptionByKey(this.props.vectorsList, this.state.vector)} 
                                        onChange={this.vectorHandleChange}
                                        options={this.props.vectorsList}
                                        formatOptionLabel={optionLabel}
                                        placeholder={lang.manage_columns.per_vector + "*"}
                                        maxMenuHeight={convertPxToViewport(300)}
                                        type={DROPDOWN_TYPE.INPUT}
                                        disabled={this.props.disabled}
                                    />
                                    {/* <DropDown  // to remove comment and comment <Select
                                        id={MANAGE_COLUMNS.PER_VECTOR_ID} 
                                        classNamePrefix={"manage-columns-dropdowns"}
                                        value={findOptionByKey(this.props.vectorsList, this.state.vector)} 
                                        onChange={this.vectorHandleChange}
                                        options={this.props.vectorsList}
                                        type={DROPDOWN_TYPE.INPUT}
                                        formatOptionLabel={optionLabel}
                                        placeholder={lang.manage_columns.select_vector}
                                        maxMenuHeight={convertPxToViewport(300)}
                                    /> */}
                                    {this.state.isVectorValid ? <div className="fs-12 red italic">{this.state.isVectorValid}</div> : ""}
                                </div>
                            :
                                <div>
                                    {/*<label htmlFor={MANAGE_COLUMNS.PER_LINE_ATTRIBUTE}>{lang.manage_columns.per_line_attribute}<sup className="red">*</sup></label>*/}
                                    <DropDown
                                        id={MANAGE_COLUMNS.PER_LINE_ATTRIBUTE_ID} 
                                        classNamePrefix={"manage-columns-dropdowns"}
                                        value={findOptionByKey(this.props.lineAttributeOptions, this.state.lineAttribute)} 
                                        onChange={this.lineAttributeHandleChange}
                                        options={this.props.lineAttributeOptions}
                                        formatOptionLabel={calculatedColumnDescription}
                                        placeholder={lang.manage_columns.per_line_attribute + "*"}
                                        maxMenuHeight={convertPxToViewport(300)}
                                        type={DROPDOWN_TYPE.INPUT}
                                        disabled={this.props.disabled}
                                    />
                                    {/* <DropDown  // to remove comment and comment <Select
                                        id={MANAGE_COLUMNS.PER_LINE_ATTRIBUTE_ID} 
                                        classNamePrefix={"manage-columns-dropdowns"}
                                        value={findOptionByKey(this.props.lineAttributeOptions, this.state.lineAttribute)} 
                                        onChange={this.lineAttributeHandleChange}
                                        options={this.props.lineAttributeOptions}
                                        formatOptionLabel={calculatedColumnDescription}
                                        type={DROPDOWN_TYPE.INPUT}
                                        maxMenuHeight={convertPxToViewport(300)}
                                    /> */}
                                    {this.state.islineAttributeValid ? <div className="fs-12 red italic">{this.state.islineAttributeValid}</div> : ""}
                                </div>
                            }
                            <div className="formula-container uk-lightbox-caption">
                                {this.translation()}
                            </div>
                        </div>
                    :
                        <>
                            {
                                this.props.useNewAddColumn ?
                                    <Formula ref={this.formulaRef} scenarioState={this.props.scenarioState} profitStackLineColumns={this.props.profitStackLineColumns} disabled={this.props.disabled} isDrawerOpen={this.props.isDrawerOpen} isEditing={this.props.isEditing}/>
                                    :
                                    <CalculatedProfitStackLine ref={r => this.calculatedPslRef = r} profitStackLineColumns={this.props.profitStackLineColumns}/>
                            }
                        </>
                    }
                    </div>
                </div>
                
            </div>
        )
    }

}

export default ManageColumnsFormat;