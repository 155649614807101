import {advancedFormatCurrency} from "./number.js"
import { MIN_WIDTH_FACTOR, QUADRANTS, VIEWPORT_WIDTH } from "./constants.js";
import { Segment } from "../components/Segment.js";
const $ = require('jquery');

var formatQuadrant = function(v, formatterParams){
    if(!v)
    	return "-";
	let segment = new Segment();
	segment.value = v;
    v = v.toLowerCase();
    var html = "<div class='segment' style='background-color: ";
    html += segment.getSegmentObject(v)?.color+"'";
    html += " >"+segment.getSegmentObject(v)?.label+"</div>";
    return html;
}

var quadrantColorClass = function(v){
    if(!v)
		return "";
	if(v.indexOf("_") >= 0)
		return v.toUpperCase();
	v = v.toLowerCase();
    if (v === QUADRANTS.IOP.name.toLowerCase() || v.indexOf(QUADRANTS.IOP.value) === 0) {
    	return "IOP green";
    } else if (v === QUADRANTS.PT.name.toLowerCase() || v.indexOf(QUADRANTS.PT.value) === 0) {
    	return "PT cyan";
    } else if (v === QUADRANTS.MN.name.toLowerCase() || v.indexOf(QUADRANTS.MN.value) === 0) {
    	return "MN yellow";
    } else if (v === QUADRANTS.CR.name.toLowerCase() || v.indexOf(QUADRANTS.CR.value) === 0) {
    	return "CR orange";
    }
    return "";
}

var formatMoney = function(v, formatterParams) {
	if(!v)
		return "-";
	var html = "";
	var vf = advancedFormatCurrency(Math.abs(v), "", formatterParams.precision, formatterParams.bUnit); 
	if(vf === '-')
		return vf;
	if(!formatterParams.symbol){
		formatterParams.symbol = "$";
	}
	if(v < 0) {
		html += "<div class='red'>";
	}
	else {
		html += "<div >";
	}
	html += (v<0?"-":"")+formatterParams.symbol+vf;
	if(v < 0) {
		html += "</div>";
	}
	else {
		html += "</div>";
	}
	return html;
}


var formatNumber = function(v, formatterParams) {
	if(!v)
		return "-";
	var html = "";
	var vf = advancedFormatCurrency(Math.abs(v), "", formatterParams.precision, formatterParams.bUnit); 
	if(vf === '-')
		return vf;
	if(v < 0) {
		html += "<div class='red'>-";
	}
	else {
		html += "<div >";
	}
	html += vf;
	html += "</div>";
	return html;
}

var formatPercent = function(v, formatterParams) {
	if (!v)
		return "-"
	var html = "";
	var vf = advancedFormatCurrency(Math.abs(v), "", formatterParams.precision, formatterParams.bUnit); 
	if(vf === '-')
		return vf;
	if(v < 0) {
		html += "<div class='red'>-";
	}
	else {
		html += "<div >";
	}
	html += vf;
	if(vf === '-')
		return vf;
	if(v < 0) {
		html += "%</div>";
	}
	else {
		html += "%</div>";
	}
	return html;
}

var formatRatio = function(v, formatterParams) {
	if(!v)
		return "-";
	var vf = advancedFormatCurrency(Math.abs(v*100), "", formatterParams.precision, formatterParams.bUnit); 
	if(vf === '-')
		return vf;
	var html = "";
	if(v < 0) {
		html += "<div class='red'>-";
	}
	else {
		html += "<div >";
	}
	html += vf;
	if(v < 0) {
		html += "%</div>";
	}
	else {
		html += "%</div>";
	}
	return html;
}

var formatVal = function(v, formatter) {
	if(!v)
		return "-";
	if(!formatter)
		return v;
	if(formatter.format === "money") {
		return formatMoney(v, formatter);
	} else if(formatter.format === "number") {
		return formatNumber(v, formatter);
	} else if(formatter.format === "ratio") {
		return formatRatio(v, formatter);
	} else if(formatter.format === "percentage") {
		return formatPercent(v, formatter);
	} else if (formatter.format === "quadrant") {
		return formatQuadrant(v, formatter);
	} else {
		return v;
	}
}

var formatValString = function(v, formatter) {
	if(!v)
		return "";
	if(!formatter)
		return v;
	return $(formatVal(v, formatter)).text();
}

/**
 * Converts value in pixels to viewportWidth (vw), for responsive webapp. It excludes values in low resolutions to be converted to vw.
 * When the type in height, 'isHeight' parameter is filled for the height to stay unchanged in low resolutions.
 * @param {*} valueToConvert value in px
 * @param {*} isHeight boolean
 * @returns the value in vw for high resolutions/ the value in px for low resolutions
 */
const convertPxToViewport = (valueToConvert, isHeight) => {
	let vwValue = (valueToConvert / VIEWPORT_WIDTH)*100;
	if(window.innerWidth < (VIEWPORT_WIDTH * MIN_WIDTH_FACTOR)) {
		return (isHeight !==null && !isHeight) ? (valueToConvert * MIN_WIDTH_FACTOR) + "px" : valueToConvert + "px";
	}
	return vwValue + "vw";
}

/**
 * Converts a value to pixels relatively to the window's inner width. It is basically used for charts/graphs, and excludes low resolutions.
 * @param {*} pxValue the value to convert
 * @returns value in px 
 */
const convertViewportToPx = (pxValue) => {
	if(window.innerWidth < (VIEWPORT_WIDTH * MIN_WIDTH_FACTOR)) {
		return pxValue;
	}
	
	return (convertPxToViewport(pxValue).split('vw')[0] * window.innerWidth) / 100;
}

export {quadrantColorClass, formatMoney, formatRatio, formatPercent, formatQuadrant, formatNumber, formatVal, formatValString, convertPxToViewport, convertViewportToPx};