//ACTIONS -----------------------------------------------------
export function updateLandscapeBubbleObjBeforeBack(bubbleObj, key, clear) {
    return {
      type: "UPDATE_LANDSCAPE_BUBBLE_OBJECT_BEFORE_BACK",
      landscapeBubbleObjBeforeBack: bubbleObj,
      key: key,
      clear: clear,
    };
  }
  
  export function getLandscapeBubbleObjBeforeBack() {
    return {
      type: "GET_LANDSCAPE_BUBBLE_OBJECT_BEFORE_BACK",
    };
  }

  export function updateLandscapeCostHierarchy(obj, key, clear) {
    return {
      type: "UPDATE_LANDSCAPE_COST_HIERARCHY",
      landscapeCostHierarchy: obj,
      key: key,
      clear: clear,
    };
  }
  
  export function getLandscapeCostHierarchy() {
    return {
      type: "GET_LANDSCAPE_COST_HIERARCHY",
    };
  }
  
  export function updateLandscapeBubbleListData(listObj, key, clear) { // the default list in landscape bubble
    return {
      type: "UPDATE_LANDSCAPE_BUBBLE_LIST_DATA",
      landscapeBubbleListData: listObj,
      key: key,
      clear: clear,
    };
  }
  
  export function getLandscapeBubbleListData() {
    return {
      type: "GET_LANDSCAPE_BUBBLE_LIST_DATA",
    };
  }
  