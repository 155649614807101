import { Suspense, useEffect, useRef, useState } from "react";
import { ReportHeader } from "../ReportHeader";
import "../styles/reportBody.css";
import { BUTTON_TYPE, BUTTON_VARIANT, SIZES } from "../../class/constants";
import ErrorBoundary from "../../ErrorBoundary";
import { renderTitle } from "../functions/componentFunctions";
import BuildDetails from "../../components/buildDetails/BuildDetails";
import shortid from "shortid";
import { capitaliseFirstLetterAfterChar } from "../../class/utils";
import { lang } from "../../language/messages_en";
import Button from "../../newComponents/Button";
/**
 * @author Sarah Farjallah
 * @description component renders BuildDetails Title and  {@link BuildDetails} component , calls isAuthenticated hook to check if user has authentication view the screen
 * @param {*} props 
 * @returns {@link ReportHeader}, {@link BuildDetails}
 */
const BuildDetailsWrapper = (props) => {
  const [reportTitle, setReportTitle] = useState(capitaliseFirstLetterAfterChar(lang.build_details.build_details_title + " - Scenario "+ window.scenarioNumber).replace(/_/g, " "));
  const [headerElements, setHeaderElements] = useState([]);


  useEffect(() => {
    let headerElements = getHeaderElements();
    setHeaderElements(headerElements);
  }, []);

  const getHeaderElements = () => {
    let headerElements = [
      <div style ={{display:"flex",justifyContent:"space-between",width: "100%"}}>
        <div>
            {renderTitle(reportTitle)}
        </div>
        <div>
        <span key={shortid.generate()} uk-tooltip={""}>
            <div className="uk-inline">
                <button className="uk-button-icon" type="button">
                    <i style={{ padding: 5 }} className="fal fa-eye-slash fa-lg uk-margin-small-right" aria-hidden="true" />
                    Periods
                    <i className="far fa-chevron-down uk-margin-small-left"></i>
                </button>
                <div uk-dropdown="mode: click">
                </div>
            </div>
        </span>
        <span style={{ marginLeft: 10 }} key={shortid.generate()} uk-tooltip={""}>
            <div className="uk-inline">
                <Button   
                    label="Print"
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.SMALL}
                    type={BUTTON_TYPE.DEFAULT}
                    leftIcon={<i className="fa-lg" uk-icon="print" aria-hidden="true" />}
                />
            </div>
        </span>
        <span style={{marginLeft:10}} key={shortid.generate()} uk-tooltip={""}>
            <div className="uk-inline">
                <Button   
                    label="Refresh"
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.SMALL}
                    type={BUTTON_TYPE.DEFAULT}
                    leftIcon={<i className="fa-lg" uk-icon="refresh" aria-hidden="true" />}
                />
            </div>
        </span>
        </div>
      </div>
    ];
    return headerElements;
  };

  return (
    <>
      <ErrorBoundary>
        <Suspense fallback={<p id="loading">Loading...</p>}>
          <div
            id={"main-component-container"}
            className={"main-component-container"}
          >
            <div className="main-report-header uk-margin-small-top">
              <ReportHeader headerElements={headerElements} />
            </div>
            <div className="main_report">
              <div id="main_report_container" className="main_report_container border-none">
                  <BuildDetails />
              </div>
            </div>
          </div>
        </Suspense>
      </ErrorBoundary>
    </>
  );
};

export { BuildDetailsWrapper };
