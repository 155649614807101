import React from "react";
import { lang } from "../../language/messages_en";
import {vectorSpacing} from "../../class/jqueries";
import Input from "../../newComponents/Input";
import SwitchToggle from "../../newComponents/SwitchToggle";
import CheckBox from "../../newComponents/CheckBox";
import Button from "../../newComponents/Button";
import { BUTTON_TYPE, BUTTON_VARIANT, SIZES } from "../../class/constants";

const $ = require('jquery');
const _customer = "customer";
const _product = "product";
const _operations = "operations";
// * @author [Bassem Arnaout]
class VectorAccess extends React.Component{

    constructor(props) {
        super(props);
        this.state ={
            vectors:[],
            filteredText: "",
            selection: lang.manage_access.select_all,
            always_allow_customer:false,
            always_allow_product:false,
            always_allow_operations:false
        }
        this.showChildren = this.showChildren.bind(this);
        this.setChildren = this.setChildren.bind(this);
        this.checkGroup = this.checkGroup.bind(this);
        this.search = this.search.bind(this);
        this.toggleColumn = this.toggleColumn.bind(this);
    }

    componentDidMount(){
        var selection = false;
        if (this.props.vectors) {
            var obj = this;
            if(this.props.vectors.filter(e=>e.checked == true && e.indeterminate == false).length  === obj.props.vectors.length){
                selection = true;
            }
            this.setState({
                vectors: this.props.vectors,
                always_allow_customer:this.props.always_allow_customer,
                always_allow_product:this.props.always_allow_product,
                always_allow_operations:this.props.always_allow_operations
            },function(){
                if (selection) this.selectAll();
                this.setIndeterminate(this.state.vectors);
                this.props.updateParent(this.extractLeafNodes(this.state.vectors));
            })
        }
    }

    extractLeafNodes(vectors,result = []){
        for(var i = 0, length = vectors.length; i < length; i++){
            if(!vectors[i].children && vectors[i].checked){
              result.push(vectors[i]);
            }else{
              if(vectors[i].children)
                    result = this.extractLeafNodes(vectors[i].children, result);
            }
          }
          return result;
        }
    

    setChildren(show, id, vectors, isCheck=false, subChildren=false, toggle){
        for (var e in vectors) {
            if (vectors[e].id === id || subChildren) {
                if (!isCheck)  vectors[e].visible = show;
                else {
                    vectors[e].checked = show;
                    vectors[e].indeterminate = false;
                }
                if ( toggle !== undefined) vectors[e].visible = false
                if (vectors[e].children && isCheck) {
                    this.setChildren(show, id, vectors[e].children, true, true, toggle)
                }
            } else if (vectors[e].children && vectors[e].children.length > 0) {
                this.setChildren(show, id, vectors[e].children, isCheck, false,toggle);
                if (isCheck && (vectors[e].children.filter(elt=>elt.checked).length > 0 || vectors[e].children.filter(elt=>elt.indeterminate).length > 0)) {
                    if (vectors[e].children.filter(elt=>elt.checked).length === vectors[e].children.length) {
                        $("#checkbox_"+vectors[e].id).prop('indeterminate',false);
                        vectors[e].checked = true;
                        vectors[e].indeterminate = false;
                    } else{
                        vectors[e].indeterminate = true;
                        vectors[e].checked = false;
                    }
                } else if (isCheck) {
                    vectors[e].checked = false;
                    vectors[e].indeterminate = false;
                }
            }
        }
        return vectors;
    }

    showChildren(show, id) {
        var vectors = this.setChildren(show, id, this.state.vectors);
        this.setState({
            vectors: vectors
        },function(){
            this.setIndeterminate(this.state.vectors);
            this.props.updateParent(this.extractLeafNodes(this.state.vectors));
        })
    }

    setIndeterminate(vectors) {
        for (var e in vectors) {
            if (vectors[e].children) {
                this.setIndeterminate(vectors[e].children);
                if (vectors[e].children.filter(elt=>elt.checked).length > 0 || vectors[e].children.filter(elt=>elt.indeterminate).length > 0) {
                    if (vectors[e].children.filter(elt=>elt.checked).length !== vectors[e].children.length) {
                        $("#checkbox_"+vectors[e].id).prop('indeterminate',true);
                        vectors[e].checked = false;
                        vectors[e].indeterminate = true;
                    }
                }else{
                    $("#checkbox_"+vectors[e].id).prop('indeterminate',false);
                }
            }
        }
    }

    checkGroup(check,id, toggle) {
        var vectors = this.setChildren(check, id, this.state.vectors, true, false,toggle);
        this.setState({
            vectors: vectors
        },function(){
           this.setIndeterminate(vectors);
           this.props.updateParent(this.extractLeafNodes(this.state.vectors));
        })
    }

    search(e){
        const _this = this;
        clearTimeout(this.searchTimeout);
        let inputValue = e.target.value;

        this.searchTimeout = setTimeout(()=>{
            _this.setState({
                filteredText: inputValue
            });
        }, 500);
    }

    filterName(id, subChlidren=false, vectors, comp) {
        var obj = comp ? comp.props : this.state;
        for (var e in vectors) {
            if (vectors[e].id === id || subChlidren) {
                if(vectors[e].display_name.toLowerCase().includes(obj.filteredText.toLowerCase())) {
                    return true;
                }
                if(vectors[e].children) {
                    var boolVal = this.filterName(id, true, vectors[e].children, comp) //For Bassm
                    if (!boolVal) continue;
                    else return boolVal;
                    
                }
            }
        }
        return false;
    }

    select(check, vectors) {
        for (var e in vectors) {
            vectors[e].checked = check;
            vectors[e].indeterminate = false
            if (vectors[e].children && vectors[e].children.length >0) {
                this.select(check, vectors[e].children);
            }
        }
        return vectors
    }

    selectAll(){
        if (this.state.selection === lang.manage_access.select_all) {
            var selection = lang.manage_access.deselect_all;
            var vectors = this.select(true, this.state.vectors)
        }else{
            var selection = lang.manage_access.select_all;
            var vectors = this.select(false, this.state.vectors);
        }
        this.setState({
            vectors:vectors,
            selection:selection
        },function(){
            this.setIndeterminate(this.state.vectors);
            this.props.updateParent(this.extractLeafNodes(this.state.vectors));
        })
    }

    renderList(){
        var obj = this;
        var list = [];
         var vectors = this.state.vectors;
        if (vectors) {
            vectors.map((vector, index) => {
                var disabled = obj.state.always_allow_customer && vector.display_name.toLowerCase() === _customer ? "disabled" : obj.state.always_allow_product && vector.display_name.toLowerCase() === _product ? "disabled" 
                    : obj.state.always_allow_operations && vector.display_name.toLowerCase() === _operations ? "disabled" : ""
                if(obj.state.filteredText && obj.state.filteredText !== "" ) {
                    if (vector.display_name.toLowerCase().includes(obj.state.filteredText.toLowerCase()) || obj.filterName(vector.id, false, vectors)) {
                        list.push(<Group name={vector.display_name} visible={vector.visible} checked={vector.checked} key={index} children={vector.children} id={vector.id} parentId={vector.parent_id} showChildren={this.showChildren}  checkGroup={this.checkGroup} indeterminate={vector.indeterminate} filteredText={obj.state.filteredText} filterName={this.filterName} always_allow_customer={this.state.always_allow_customer} always_allow_product={this.state.always_allow_product} always_allow_operations={this.state.always_allow_operations} disabledToggle={disabled}/>)
                    }
                }else{
                    list.push(
                    <div className="uk-display-flex uk-flex-between">
                        <Group name={vector.display_name} visible={vector.visible} checked={vector.checked} key={index} children={vector.children} id={vector.id} parentId={vector.parent_id} showChildren={this.showChildren}  checkGroup={this.checkGroup} indeterminate={vector.indeterminate} filteredText={obj.state.filteredText} filterName={this.filterName} always_allow_customer={this.state.always_allow_customer} always_allow_product={this.state.always_allow_product} always_allow_operations={this.state.always_allow_operations}/>
                        <div className="uk-flex uk-margin-small-top uk-margin-medium-right">  
                            <SwitchToggle checked = {disabled !== ""} name={vector.display_name} id={vector.id} onChange={this.toggleColumn} />
                        </div>
                    </div>
                    )
                }
                });
        }
        return list;
    }

    toggleColumn(e){
        if(e!==null){
            var id  = e.target.id;
            var name =  $("#"+id).attr("name").toLowerCase();
            if($("#"+id).prop("checked")){
              
               this.setState({["always_allow_"+name]:true},function(){
                   this.checkGroup(true,Number(id),true);
                   this.props.updateToggleState(name,true);
                   $("#checkbox_"+id).prop('checked',true);
                   $("#checkbox_"+id).prop('disabled',true);
               })
               
              
            } else{
                this.setState({["always_allow_"+name]:false},function(){
                    this.checkGroup(false,Number(id),false);
                    this.props.updateToggleState(name,false);
                    $("#checkbox_"+id).prop('checked',false);
                    $("#checkbox_"+id).prop('disabled',false);
                })
            }  
        }
    }

    render() {
        var list = this.renderList();
        return (
            <div key={this.props.index} className={"uk-margin-bottom"}>
                <span className="uk-text-xmedium">Select Vectors</span>
                <div className="uk-display-flex align_items_center">
                    <Input id="filter_access" className="uk-input" placeholder="Search Vectors" onChange={this.search} isSearch={true} />
                    {/* <a className="uk-text-nowrap uk-margin-default-left text-link" onClick={()=>{this.selectAll()}}>{this.state.selection}</a> */}
                    <Button
                        label={this.state.selection}
                        variant={BUTTON_VARIANT.TERTIARY}
                        size={SIZES.DEFAULT}
                        type={BUTTON_TYPE.DEFAULT}
                        className="justify-content-start"
                        onBtnClick={()=>{this.selectAll()}}
                    />
                </div>
                <div className="uk-display-flex uk-flex-between uk-margin-default-top">
                    <span className="uk-text-xmedium text-grey">{lang.manage_access.vectors_label}</span>
                    <div>
                        <span className="uk-text-xmedium text-grey">{lang.manage_access.always_allow_label}</span>
                        <i className="fal fa-info-circle uk-margin-xsmall-left uk-cursor-pointer" uk-tooltip={lang.manage_access.always_allow_info_vector} />
                    </div>
                </div>
                <div className="uk-margin-xsmall-top vector-access-group-container">
                    {list}
                </div>
            </div>
        ) 
    }
}

class Group extends React.Component{
    constructor(props) {
        super(props);
    }

    showChildren(show, id, parentId) {
        this.props.showChildren(show,id, parentId)
    }

    checkGroup(check, id,toggle) {
        this.props.checkGroup(check, id,toggle);
    }

    render() {
        var obj = this;
        let indexOfValue = this.props.name ? this.props.name.toLowerCase().indexOf(this.props.filteredText.toLowerCase()) : "";
        let searchValueInName = this.props.name ? this.props.name.substr(indexOfValue, this.props.filteredText.length) : "";
        let isNameOrNumber = this.props.name === "Name" || this.props.name === "Number";
        return (
            <div>
                <div className="uk-display-flex uk-flex-middle">
                    {this.props.children && this.props.children.length > 0 ? 
                        this.props.visible ? 
                            <div onClick={()=>{this.showChildren(false, this.props.id)}} title='Collapse' class={'uk-button-icon transparent-bg'+(this.props["always_allow_"+this.props.name.toLowerCase()]?' disabled':'')}><i class='far fa-chevron-down' /></div>
                        :   <div onClick={()=>{this.showChildren(true,this.props.id)}} title='Expand' class={'uk-button-icon transparent-bg'+(this.props["always_allow_"+this.props.name.toLowerCase()]?' disabled':'')}><i class='far fa-chevron-right'/></div>
                    :""}
                    {this.props.checked ? 
                        <CheckBox id={"checkbox_"+this.props.id} disabled={isNameOrNumber || this.props["always_allow_"+this.props.name.toLowerCase()]} inputAddedClassName={this.props.children && this.props.children.length > 0 ?  "uk-margin-xsmall-left " :''} checked={true}  onChange={()=>{this.checkGroup(false,this.props.id)}} />
                    : this.props.indeterminate ? 
                    <CheckBox id={"checkbox_"+this.props.id} disabled={isNameOrNumber || this.props["always_allow_"+this.props.name.toLowerCase()]} inputAddedClassName={this.props.children && this.props.children.length > 0 ?  "uk-margin-xsmall-left " :''} indeterminate={true}  onChange={()=>{this.checkGroup(false, this.props.id)}} />
                    :
                    <CheckBox id={"checkbox_"+this.props.id} disabled={isNameOrNumber || this.props["always_allow_"+this.props.name.toLowerCase()]}  inputAddedClassName={this.props.children && this.props.children.length > 0 ?  "uk-margin-xsmall-left " :''} checked={false}  onChange={()=>{this.checkGroup(true, this.props.id)}} />
                     }
                     {this.props.filteredText && this.props.filteredText !== ""  && this.props.name.toLowerCase().includes(this.props.filteredText.toLowerCase())? 
                        <span className="uk-text-xmedium uk-margin-xsmall-left">
                            {vectorSpacing(this.props.name.substr(0, indexOfValue))}
                            <span className="search-value-highlight">{vectorSpacing(searchValueInName)}</span>
                            {vectorSpacing(this.props.name.substr(indexOfValue + this.props.filteredText.length))}
                        </span>
                     : 
                        <span className="uk-text-xmedium uk-margin-xsmall-left">{vectorSpacing(this.props.name)}</span>
                    }
                </div>
                {this.props.visible ? 
                    this.props.children.map((child,index) =>{
                        if(obj.props.filteredText && obj.props.filteredText !== "" ) {
                            if (child.vector_name.toLowerCase().includes(obj.props.filteredText.toLowerCase()) || obj.props.filterName(child.id, false, obj.props.children, obj)) {
                                  return <div className={"uk-display-flex " + (child.children && child.children.length > 0 ? "uk-margin-medium-left" : "mrgl70")}><Group name={child.display_name} visible={child.visible} key={index} children={child.children} parentId={child.parent_id} checked={child.checked} id={child.id} showChildren={this.props.showChildren} checkGroup={this.props.checkGroup} indeterminate={child.indeterminate} filteredText={obj.props.filteredText} filterName={this.props.filterName}/></div>
                            }
                        } else{
                            return <div className={"uk-display-flex " + (child.children && child.children.length > 0 ? "uk-margin-medium-left" : "mrgl70")}><Group name={child.display_name} visible={child.visible} key={index} children={child.children} parentId={child.parent_id} checked={child.checked} id={child.id} showChildren={this.props.showChildren} checkGroup={this.props.checkGroup} indeterminate={child.indeterminate} filteredText={obj.props.filteredText} filterName={this.props.filterName}/></div>
                        }
                    })
                : ""} 
            </div>
        ) 
    }
}

export default VectorAccess;