import React, { Component } from 'react';
import {TabulatorFull} from "tabulator-tables";
import UIkit from 'uikit';
import { cleanUpTabulatorColumns, toggleLoader } from '../class/common.js';
import {
    BP_QUADRANT_TIER, comparison_suffixes, FormatTypes, header_comparison_values, IDENTIFIERS, ORDER_VALUES, PREVIOUS_ADJACENT, PSL_RETURN_NAMES, VECTOR_ANALYSIS, VECTOR_STAGING_ATTRIBUTES, YEAR_OVER_YEAR
} from '../class/constants.js';
import { getActualYear, getMonthName, getPeriodTextFromMonth } from '../class/date';
import { formatValString } from '../class/format';
import { convertPxToViewport } from '../class/formatting';
import { exportToExcel, tabulatorExport } from '../class/tabulatorExport.js';
import { copyObjectValues, getTranslationFile } from '../class/utils.js';
import { getVectorAllowedAttributes } from '../sections/manageAccess/ManageAccessUtils';
import '../styles/dataModeling.css';
import { createCheckbox } from '../newComponents/CheckBoxTabulator.js';


const $ = require('jquery');
const lang = getTranslationFile();
const _cnt = "cnt";

const CHECK_TITLE = "checkbox";
const checkboxCellObject = {
    field: CHECK_TITLE,
    visible: true,
    headerSort: false,
    width: convertPxToViewport(100)
};

var VECTOR_ANALYSIS_COLUMNS = [
    // {
	// 	title: VECTOR_ANALYSIS.TITLES.PQS,
	// 	field: VECTOR_ANALYSIS.FIELDS.PQS,
	// 	sorter:"string", 
	// 	visible: false,
    //     width: 100,
    //     headerSort: true,
    //     format_type:FormatTypes.QUADRANT
    // },
	// {
	// 	title: VECTOR_ANALYSIS.TITLES.NAME,
	// 	field: VECTOR_ANALYSIS.FIELDS.NAME,
	// 	sorter:"string", 
	// 	visible: true,
    //     width: 350,
    //     format_type: FormatTypes.TEXT,
    //     headerSort: true,
    //     hozAlign: "left"
    // },
    // {
	// 	title: VECTOR_ANALYSIS.TITLES.KEY,
	// 	field: VECTOR_ANALYSIS.FIELDS.KEY,
	// 	sorter:"string", 
	// 	visible: false,
    //     width: 200,
    //     format_type: FormatTypes.TEXT,
    //     headerSort: true,
    // },
    // {
	// 	title: VECTOR_ANALYSIS.TITLES.NUMBER,
	// 	field: VECTOR_ANALYSIS.FIELDS.NUMBER,
	// 	sorter:"string", 
	// 	visible: true,
    //     width: 100,
    //     format_type: FormatTypes.TEXT,
    //     headerSort: true,
    // }
];


class VectorAnalysisTable extends Component {
    constructor(props) {
		super(props);
		this.state = {
			tableColumns: [],
            tableData: [],
		};
        this.getColumnFormatter = this.getColumnFormatter.bind(this);
        this.getCounterTitleFormatter = this.getCounterTitleFormatter.bind(this);
        this.onCheckBoxChecked = this.onCheckBoxChecked.bind(this);
        this.toggleColumns = this.toggleColumns.bind(this);
        this.getTabulator = this.getTabulator.bind(this);
        this.exportTableToExcel = this.exportTableToExcel.bind(this);
        this.compareObjects = this.compareObjects.bind(this);

        this.selectedItems = [];
        this.selectedNames = [];
        this.checkedDisabled = false;
    }
    

    onCheckBoxChecked(e, cell) {
      let cellData = cell.getData();
      cellData.checked = e.target.checked;
  
      if (!e.target.checked) {
        this.selectedItems = this.selectedItems.filter((f) => f !== cellData[VECTOR_ANALYSIS.FIELDS.KEY]);
        this.selectedNames = this.selectedNames.filter((f) => f !== cellData[VECTOR_ANALYSIS.FIELDS.NAME]);
      } else {
        this.selectedItems.push(cellData[VECTOR_ANALYSIS.FIELDS.KEY]);
        this.selectedNames.push(cellData[VECTOR_ANALYSIS.FIELDS.NAME]);
      }
  
      const isBPQuadrantTier = this.props.comparisonValue === BP_QUADRANT_TIER;
      this.checkedDisabled = isBPQuadrantTier ? this.selectedItems.length === 1 : this.selectedItems.length === 10;
  
      const tooltipText = this.checkedDisabled
        ? isBPQuadrantTier
          ? lang.vector_analysis.disable_checkbox_bp
          : lang.vector_analysis.disable_checkbox
        : undefined;
  
      if (this.checkedDisabled) {
        $("input.chosenEntity:not(:checked)").attr("disabled", "disabled");
        $("input.chosenEntity:not(:checked)").parent().attr("uk-tooltip", tooltipText);
      } else {
        $("input.chosenEntity").removeAttr("disabled");
        $("input.chosenEntity").parent().removeAttr("uk-tooltip");
      }
  
      // Update the counter title
      const itemCount = this.selectedItems.length;
      $("#counter_title").text(`${itemCount} of ${isBPQuadrantTier ? 1 : 10}`);
  
      this.props.setSelectedItems(this.selectedItems, this.selectedNames);
    }

    toggleColumns(columns, show) {
        for (var e in columns) {
            if (show) {
                this.tabulator.showColumn(columns[e])
            } else{
                this.tabulator.hideColumn(columns[e])

            }
        }
    }

	getColumnFormatter(colField, colType) {
		let columnFormatter;
		var obj = this;
        // if(colField.includes(_range) && !colField.includes(DIFFERENCE)) {
        //     columnFormatter = function(cell) {
        //         var p = document.createElement("p");
        //         p.innerHTML = formatValHTML(cell.getRow().getData()[colField.replace(_range,_minimum)], colType) +" - " + formatValHTML(cell.getRow().getData()[colField.replace(_range,_maximum)], colType);
        //         return p;
        //     };
        // }else{
            switch(colField) {
                case CHECK_TITLE:
                columnFormatter = function(cell, formatterParams) {
                    var div = document.createElement("div");
                    var checkbox = createCheckbox();
                    checkbox.name='chosenEntity';
                    checkbox.classList.add('chosenEntity');
                    checkbox.checked = cell.getRow().getData().checked
                    checkbox.onchange = (e) => {
                            obj.onCheckBoxChecked(e,cell);
                    };
                   
                    div.appendChild(checkbox);
                    return div;
                }
                break;
                default:
                columnFormatter = function(cell, formatterParams){
                    return cell.getValue();
                }
                break;
            }
        // }
      
        // if( colField.includes(DIFFERENCE) || colField.includes(SELECTION) || colField.includes(COMPARISON)) {
        //     columnFormatter = function(cell, formatterParams) {
        //         var p = document.createElement("p");
        //         p.innerHTML = formatValHTML(cell.getValue(), FormatTypes.AMOUNT);
        //         if (colField.includes(_range)) {
        //             p.innerHTML = formatValHTML(cell.getRow().getData()[colField.replace(_range,_minimum)], colType) +" - " + formatValHTML(cell.getRow().getData()[colField.replace(_range,_maximum)], colType);
        //         }
        //         return p;
        //     }
        // } else if( colField.toLowerCase() === VECTOR_ANALYSIS.FIELDS.NUMBER.toLowerCase() || colField.toLowerCase() === VECTOR_ANALYSIS.FIELDS.NAME.toLowerCase()){ 
        //     columnFormatter = function(cell, formatterParams) {
        //         var p = document.createElement("p");
        //         p.innerHTML = cell.getValue();
        //         return p;
        //     }	
        // }	
		return columnFormatter;
    }

    getCounterTitleFormatter() {
        let div = document.createElement('div');
        let checkedCountP = document.createElement("p");
        let checkedCount = this.tabulator.getData().filter(e => e.checked).length;
        checkedCountP.innerHTML = checkedCount + (this.props.comparisonValue === BP_QUADRANT_TIER ? ' of 1' : ' of 10');
        checkedCountP.classList.add("uk-text-normal");
        div.appendChild(checkedCountP);
        div.id = "counter_title"
        return div;
    }

    getColumnAccessor() {
      var columnFormatter;
      columnFormatter = function (value, data) {
          return formatValString(value, FormatTypes.QUADRANT);
      }
      return columnFormatter;
  }

    setColumns(columns, data, oldColumns, nextProps) {
        var obj = this;
        if(!nextProps){
            nextProps = obj.props
        }
        columns.forEach(col => {
            if (obj.props.isDashboards) {
                col.headerSort = false;
                col.dontFilter = true;
            }
            if (col.field.includes(VECTOR_ANALYSIS.FIELDS.NAME)) {
                col.alphaLegend = nextProps.getLegendItems()[0];
                col.betaLegend = nextProps.getLegendItems()[1];
                col.cssClass = "name-cell";
                // col.minWidth = convertPxToViewport(300);
            }
            if (col.field.includes(VECTOR_ANALYSIS.FIELDS.NUMBER)) {
                col.cssClass = "number-cell"
            }

            // hide N/A column if vector is not generated
            if(col.field.includes(PSL_RETURN_NAMES.QUADRANT) && !this.props.vectorObj?.generated) {
                col.cssClass = "hidden-column";
            }
            col.formatter= obj.getColumnFormatter(col.field, col.format_type);
            if (col.field !== CHECK_TITLE && col.field !== VECTOR_ANALYSIS.FIELDS.KEY && col.field !== VECTOR_ANALYSIS.FIELDS.NAME && col.field !== VECTOR_ANALYSIS.FIELDS.NUMBER && col.field !== VECTOR_ANALYSIS.FIELDS.PQS) {
                if (col.columns) {
                    let visibilityLength = 0;
                    col.columns.forEach(c => {
                        if (obj.props.isDashboards) {
                            c.headerSort = false;
                            c.hideFilter = true;
                        }
                        if(!obj.props.isDashboards && nextProps.data && nextProps.data.data && nextProps.data.data.length > 0 && nextProps.data.data[0][_cnt] > nextProps.data.data.length){
                            c.hideFilter = true;
                        }
                        let oldVisibility = obj.getOldVisibility(oldColumns, c.field);
                        c.formatter = obj.getColumnFormatter(c.field, c.format_type);
                        if (c.field.toUpperCase().includes(VECTOR_ANALYSIS.TITLES.COMPARISON.toUpperCase()) || c.field.toUpperCase().split("_")[c.field.toUpperCase().split("_").length - 1].includes((VECTOR_ANALYSIS.TITLES.SELECTION.toUpperCase()))) {
                            c.visible = oldVisibility;
                        }
                        if (c.field.endsWith(comparison_suffixes.difference) && ![PSL_RETURN_NAMES.NAME + comparison_suffixes.difference].includes(c.field)) {
                            c.visible = true;
                            c.alwaysVisible = true;
                        }
                        if (c.visible === true) {
                            visibilityLength++;
                        }
                        obj.props.getSymbolsFormatter(c, obj);
                    });
                    col.visibilityLength = visibilityLength;
                }
            }else if (obj.props.comparisonValue !== YEAR_OVER_YEAR && obj.props.comparisonValue !== PREVIOUS_ADJACENT && col.field === VECTOR_ANALYSIS.FIELDS.PQS) {
                col.visible = true;
            }
            if(!obj.props.isDashboards && nextProps.data && nextProps.data.data && nextProps.data.data.length > 0 && nextProps.data.data[0][_cnt] > nextProps.data.data.length){
                col.hideFilter = true;
            }
        });
       
        columns = cleanUpTabulatorColumns(columns, data,obj.refreshFilterDivs, obj.getTabulator, {id: "vector_analysis"});
        if (!obj.props.isDashboards) {
            columns.unshift(checkboxCellObject);
            columns.forEach(col => {
                if (col.field === CHECK_TITLE) {
                    col.titleFormatter = obj.getCounterTitleFormatter;
                    col.cssClass = "checkbox-cell";
                    col.formatter = obj.getColumnFormatter(col.field, col.format_type);
                }
                if([PSL_RETURN_NAMES.QUADRANT_TIER, PSL_RETURN_NAMES.QUADRANT].includes(col.field)){
                  col.accessor = obj.getColumnAccessor(col.field, col, col.title);
                }
                if(col.columns) {
                  col.columns.forEach(c => {
                    if([PSL_RETURN_NAMES.QUADRANT_TIER, PSL_RETURN_NAMES.QUADRANT].includes(c.format_type)){
                      c.accessor = obj.getColumnAccessor(); 
                  }
                  })
                }
            });
        }

        if(nextProps.data && nextProps.data.data && nextProps.data.data.length > 0 && nextProps.data.data[0][_cnt] > nextProps.data.data.length) {
            columns.forEach(col => {
                if(col.headerSort !== false && !obj.props.isDashboards) {
                    if(col.collapsable){
                        if(col.columns){
                            col.columns.forEach(cl=>{
                                cl.headerClick = function(e, column) {
                                    var sortField = column.getTable().getSorters().length > 0 ? obj.tabulator.getSorters()[0].field : "";
                                    var dir = sortField === column.getField() && obj.tabulator.getSorters()[0].dir == "asc" ? "desc" : "asc";
                                    obj.props.setOrder(dir, column.getField());
                                }
                            })
                        }
                    }else{
                        if (!obj.props.isDashboards) {
                            col.headerClick = function(e, column) {
                                var sortField = column.getTable().getSorters().length > 0 ? obj.tabulator.getSorters()[0].field : "";
                                var dir = sortField === column.getField() && obj.tabulator.getSorters()[0].dir == "asc" ? "desc" : "asc";
                                obj.props.setOrder(dir, column.getField());
                            }
                        }
                    }
                }
            });
        }
        this.tabulator.setColumns(columns);

    }

    getTabulator() {
		if(this.tabulator)
			return this.tabulator;
		return null;
    }

    redraw=()=>{ //!! fix ASAP 
        this.tabulator.redraw();
        let _this = this;
        setTimeout(function(){
            $("#vector_analysis"+_this.props.index).find(".inset-parent")[0].click();
            $("#vector_analysis"+_this.props.index).find(".inset-parent")[0].click();
        },100);
    }
    
    compareObjects(columns1, columns2) {
        if(columns1.length !== columns2.length) {
            return false;
        }
        for (var e in columns1) {
            if (columns1[e].title !== columns2[e].title) {
                return false;
            }
        }
        return true;
    }

    toggleForbiddenIdentifierColumns(tabColumns) {
        let columns = copyObjectValues(tabColumns);
        let vectorGroup = this.props.vectorObj[VECTOR_STAGING_ATTRIBUTES.GROUP]
        let allowedAttrs = getVectorAllowedAttributes(this.props.vector, vectorGroup, this.props.user);

        if(allowedAttrs.includes(IDENTIFIERS.NAME)) {
            this.tabulator.showColumn(VECTOR_ANALYSIS.FIELDS.NAME);
        } else {
            this.tabulator.hideColumn(VECTOR_ANALYSIS.FIELDS.NAME);
        }
        if(allowedAttrs.includes(IDENTIFIERS.NUMBER)) {
            this.tabulator.showColumn(VECTOR_ANALYSIS.FIELDS.NUMBER);
        } else {
            this.tabulator.hideColumn(VECTOR_ANALYSIS.FIELDS.NUMBER);
        }

        return columns;
    }

    getOldVisibility = (oldColumns, field) => {
        let visible = false;
        if (!oldColumns) {
            return false;
        } else {
            let columns = Array.from(oldColumns);
            for (let i = 0; i < columns.length; i++) {
                if (columns[i].field !== CHECK_TITLE && columns[i].field !== VECTOR_ANALYSIS.FIELDS.KEY && columns[i].field !== VECTOR_ANALYSIS.FIELDS.NAME && columns[i].field !== VECTOR_ANALYSIS.FIELDS.NUMBER && columns[i].field !== VECTOR_ANALYSIS.FIELDS.PQS) {
                    if (columns[i].columns) {
                        for (let j = 0; j < columns[i].columns.length; j++) {
                            if (columns[i].columns[j].field === field) {
                                visible = columns[i].columns[j].visible ? columns[i].columns[j].visible : false;
                                break;
                            }
                        }
                    }
                }
            }
        }
        return visible
    }

	componentDidUpdate(prevProps) {
        if (this.props.data && !prevProps.data || this.props.data && this.props.data.id !== prevProps.data.id) {
            const _this = this;
            var order = this.props.data && this.props.data.order? this.props.data.order.toLowerCase() : ORDER_VALUES.DESC.toLowerCase();
            if (this.props.data && this.state.data && this.props.data.columns && this.state.data.columns) {
                _this.setColumns(VECTOR_ANALYSIS_COLUMNS.concat(this.props.data.columns), this.props.data.data, _this.tabulator.getColumnDefinitions(),this.props);
                _this.toggleForbiddenIdentifierColumns(this.props.data.columns);
                _this.tabulator.setData(this.props.data.data);
                this.setState({
                    data: this.props.data
                },function(){
                    _this.tabulator.setSort(this.props.sorter? this.props.sorter : this.props.data.profit.toLowerCase() + comparison_suffixes.difference, order);
                    if (prevProps.isDashboards) {
                        _this.redraw();
                    }
                })
            } else {
                this.setState({
                    data: this.props.data
                },function(){
                    _this.setColumns(this.props.data.columns,this.props.data.data,_this.tabulator.getColumnDefinitions(),this.props);
                    _this.toggleForbiddenIdentifierColumns(this.props.data.columns);
                    _this.tabulator.setData(this.props.data.data);
                    _this.tabulator.setSort(this.props.sorter? this.props.sorter : this.props.data.profit.toLowerCase() + comparison_suffixes.difference, order);
                   if (prevProps.isDashboards) {
                    _this.redraw();
                   }
                });
            }
        }
    }
    
    onTabulatorRenderComplete() {
        if (!this.tabulator && $("#select_report_div").hasClass("cursorNotAllowed")) {
            $('input.chosenEntity:checked').prop("checked",false);
            $('input.chosenEntity:checked').removeAttr("checked");
            return;
        }

        
    }

	componentDidMount() {
        var obj = this;

		var options = {
			layout: this.props.isDashboards ? "fitColumns" : "fitData",      //fit columns to width of table
			responsiveLayout: false,  //hide columns that dont fit on the table
			addRowPos: "top",          //when adding a new row, add it to the top of the table
			history: true,             //allow undo and redo actions on the table
            movableColumns: false,     //allow column order to be changed
            autoResize: this.props.isDashboards,
			resizableRows: false,       //allow row order to be changed
            resizableColumns: true,
			selectable: false,
			invalidOptionWarnings: false,
            initialSort: [
                {column: VECTOR_ANALYSIS.FIELDS.PROFIT_DIFFERENCE, dir:"desc"},
            ],            // columnHeaderSortMulti:true,
            reactiveData: true,      //tabulator listens to any change in the data array and updates the table
            virtualDomBuffer: 2000,
            columnHeaderVertAlign:"bottom",
			placeholder: lang.no_data_available,
			height: "100%",
            width: "100%",
            // virtualDom:false, // commented because it wont allow the table to be resized
            renderComplete: obj.onTabulatorRenderComplete,
        }
        if (obj.props.isPaginated) {
            options.pagination = "local";       
            options.paginationSize = 100;
            options.paginationSizeSelector = [10,50,100];
            options.footerElement = "<div id='Vector_analysis_footer'></div>";
   
            options.pageLoaded = function(pageno){
                if(obj.checkedDisabled){
                    $('input.chosenEntity:not(:checked)').attr('disabled', 'disabled');
                    $('input.chosenEntity:not(:checked)').parent().attr("uk-tooltip",obj.props.comparisonValue === BP_QUADRANT_TIER ? lang.vector_analysis.disable_checkbox_bp : lang.vector_analysis.disable_checkbox);
                } else{
                    $('input.chosenEntity').removeAttr('disabled');
                    $('input.chosenEntity').parent().removeAttr("uk-tooltip");
                }
                if(obj.props.comparisonValue === BP_QUADRANT_TIER){
                    $("#counter_title").text(obj.props.selectedItems.length +" of 1");
                } else {
                    $("#counter_title").text(obj.props.selectedItems.length +" of 10");
                }
            };
            options.accessorDownload = function(dataObj){
                let params = {
                    "Report": obj.props.profitFormat,
                    "User": obj.props.user.first_name + " " + obj.props.user.last_name,
                    "Date Run": new Date().getDate() + '-' + getMonthName(new Date().getMonth()) + '-' + new Date().getFullYear(),
                    "Filter": obj.props.exportQueryFilter || "None",
                    "Scenario": obj.props.scenarioObject["scenario_number"],
                }
                // if([YEAR_OVER_YEAR, PREVIOUS_ADJACENT].includes(obj.props.comparisonValue)) {
                   
                    params["Start Period"] = getActualYear(obj.props.customStartDate) + getPeriodTextFromMonth(obj.props.customStartDate);
                    params["End Period"] = getActualYear(obj.props.customEndDate) + getPeriodTextFromMonth(obj.props.customEndDate);
                // } else {
                //     params["Start Quarter"] = obj.props.startQuarter;
                //     params["End Quarter"] = obj.props.endQuarter;
                // }
                return tabulatorExport(dataObj, obj.tabulator, params);
            };
            options.downloadReady = function(fileContents, blob){
                toggleLoader(false, "tablesToExcel");
                return blob; //must return a blob to proceed with the download, return false to abort download
            };
            options.dataFiltered = function(filters, rows) {
                obj.addFooterText(rows);
            }
        }
        obj.tabulator = new TabulatorFull("#vector_analysis"+(obj.props.isDashboards ? obj.props.index :""), options);

        var tableColumns = cleanUpTabulatorColumns(copyObjectValues(VECTOR_ANALYSIS_COLUMNS), null, this.refreshFilterDivs, this.tabulator, {id: "vector_analysis"});
        tableColumns.unshift(checkboxCellObject);
        tableColumns.forEach(col => {
            if (col.field !== VECTOR_ANALYSIS.FIELDS.PQS) {
                col.formatter = obj.getColumnFormatter(col.field, col.format_type);
            }
            if (col.field === CHECK_TITLE) {
                col.titleFormatter = obj.getCounterTitleFormatter;
            }
        });
        this.tabulator.setColumns(tableColumns);
        this.tabulator.setData([]);
        if (this.props.data) {
            this.setColumns(VECTOR_ANALYSIS_COLUMNS.concat(this.props.data.columns),this.props.data.data, this.props.data);
            this.tabulator.setData(this.props.data.data);  
        }
        if (![YEAR_OVER_YEAR, PREVIOUS_ADJACENT].includes(this.props.comparisonValue)) {
            this.tabulator.showColumn(VECTOR_ANALYSIS.FIELDS.PQS);
        }else{
            this.tabulator.hideColumn(VECTOR_ANALYSIS.FIELDS.PQS);
        }
        this.toggleForbiddenIdentifierColumns();

        /** clear selectedItems on mount, to clear it when coming from bridge report */
        this.props.setSelectedItems([], [])
        this.selectedItems= [];
        this.selectedNames = []
    }

    addFooterText=(propsData)=>{
        let data = propsData || [];
        let totalsText = "";
        let iconDiv = document.createElement('div');
        if(data && data.length > 0){
            if (Number(data[0].getData()[_cnt]) > Number(this.props.limit)) {
                iconDiv.classList.add("uk-display-inline-flex");
                iconDiv.id = "icon_div"
                let infoIcon = document.createElement("i");
                infoIcon.classList.add("fal","fa-info-circle", "uk-margin-xsmall-left");
                infoIcon.style.cursor = "pointer";
                $(iconDiv).attr('uk-tooltip', lang.record_limit_exceeded);
                iconDiv.appendChild(infoIcon);
                totalsText ="<span class=\"uk-margin-small-right\">" + formatValString(data.length,FormatTypes.NUMERIC) +" / "+ formatValString((data && data.length > 0 ?  data[0].getData()[_cnt] : "0"),FormatTypes.NUMERIC) + 
                " records </span>"
            }else{
                totalsText ="<span class=\"uk-margin-small-right\">" + formatValString(data.length,FormatTypes.NUMERIC) +" records "+ "</span>"
            }  
        }
        document.getElementById("Vector_analysis_footer").innerHTML ="<p>" + totalsText + "</p>";
        document.getElementById("Vector_analysis_footer").appendChild(iconDiv);
        document.getElementById("Vector_analysis_footer").classList.add("uk-flex", "uk-flex-middle");  

        let icon = document.getElementById("icon_div");
        if (icon && data && data.length > 0 && Number(data[0].getData()[_cnt]) > Number(this.props.limit)) {
            UIkit.tooltip(document.getElementById("Vector_analysis_footer")["children"][1]).show();
        }
    }

    exportTableToExcel(exportOpts) {
        let _this = this
		// var tempSheetName = exportOpts.vector;
		// var mainSheet = tempSheetName.length > 31 ? tempSheetName.substring(0,28) + "..." : tempSheetName;
        let startDate = getActualYear(_this.props.customStartDate) + getPeriodTextFromMonth(_this.props.customStartDate);
        let endDate = getActualYear(_this.props.customEndDate) + getPeriodTextFromMonth(_this.props.customEndDate);
		var sheets = {};
        sheets["Description"] = "#excel_details_table";
        sheets["Data"] = true;
        
        exportOpts.sheets = sheets;
        exportOpts.dataStartRow = 2;
        exportOpts.skipColumn = 1;
        exportOpts.data = this.tabulator.getData();
        exportOpts.isProfitStack = false;

        exportToExcel(exportOpts, this.tabulator, startDate, endDate);
	}
    


	render() {
        let hasFilter = $(".border-third-header").height() !== undefined || this.props.filterBasic?.length;
        let hasBanner = $(".banner-component").height();
        
        return (
            <div id={"erosion-growth"} className={(hasFilter && hasBanner ? "vector-analysis-with-filter-banner" : hasFilter ? "vector-analysis-with-filter" : hasBanner ? "vector-analysis-with-banner" : "")}>
                 <div
                id={"vector_analysis" + (this.props.isDashboards ? this.props.index : "")}
                ref={"vector_analysis" + (this.props.isDashboards ? this.props.index : "")}
                className={"vector_analysis has_grouping " + (this.props.isDashboards && this.props.height === 400 ? "height-400" : "")}
            />
            </div>
           
        );
    }

}

export default VectorAnalysisTable;