//ACTIONS -----------------------------------------------------
export function updateGeographyObjBeforeBack(GeographyObj, key, clear) {
    return {
      type: "UPDATE_GEOGRAPHY_OBJECT_BEFORE_BACK",
      geographyObjBeforeBack: GeographyObj,
      key: key,
      clear: clear,
    };
  }
  
  export function getGeographyObjBeforeBack() {
    return {
      type: "GET_GEOGRAPHY_OBJECT_BEFORE_BACK",
    };
  }

  export function updateGeographyCostHierarchy(obj, key, clear) {
    return {
      type: "UPDATE_GEOGRAPHY_COST_HIERARCHY",
      geographyCostHierarchy: obj,
      key: key,
      clear: clear,
    };
  }
  
  export function getGeographyeCostHierarchy() {
    return {
      type: "GET_GEOGRAPHY_COST_HIERARCHY",
    };
  }
  
  export function updateGeographyListData(listObj, key, clear) { // the default list in landscape bubble
    return {
      type: "UPDATE_GEOGRAPHY_LIST_DATA",
      geographyListData: listObj,
      key: key,
      clear: clear,
    };
  }
  
  export function getGeographyListData() {
    return {
      type: "GET_GEOGRAPHY_LIST_DATA",
    };
  }
  