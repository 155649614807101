import { Segment } from "../../components/Segment";

/**
 * This is a custom tabulator sorter. It takes 2 values and sort them based on their type (either String or Number)
 * @param {String} aValue
 * @param {String} bValue
 * @param {*} aRow
 * @param {*} bRow
 * @param {*} column
 * @param {String} dir this is the sorting direction (asc or desc)
 * @param {JSONObject} sorterParams
 * @returns
 */
function customColumnSorter(aValue, bValue, aRow, bRow, column, dir, sorterParams) {
  if (typeof aValue === "string" && typeof bValue === "string" && (Number.isNaN(Number(aValue)) || Number.isNaN(Number(bValue)))) {
    return aValue.localeCompare(bValue);
  } else {
    return aValue - bValue;
  }
}

/**
 * sort by formatted value instead of real data (drain 1 drain 2 ... instead of dtt, dtb ...)
 * @param {String} a
 * @param {String} b
 * @param {*} aRow
 * @param {*} bRow
 * @param {*} column
 * @param {String} dir this is the sorting direction (asc or desc)
 * @param {*} sorterParams
 * @returns
 */
function segmentsSorter(a, b, aRow, bRow, column, dir, sorterParams) {
  let segment = new Segment();
  let segmentObjectA = segment.getSegmentObject(a);
  let segmentObjectB = segment.getSegmentObject(b);
  const aFormatted = segmentObjectA.label;
  const bFormatted = segmentObjectB.label;
  // Use JavaScript's localeCompare to compare formatted values
  return aFormatted.localeCompare(bFormatted, undefined, { numeric: true });
}

export { customColumnSorter, segmentsSorter };
