import { Box } from "@mui/material";

const NewBanner = ({ label, icon, bannerClassName, labelClassName, body, id }) => {
  return (
    <Box className={bannerClassName} id={id}>
      {icon}
      <span className={labelClassName}>{label}</span>
      {body}
    </Box>
  );
};

export default NewBanner;
