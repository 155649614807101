import { IDENTIFIERS, MANAGE_ACCESS, VECTOR_GROUPS } from "../../class/constants";

const _userAllowedVectors = MANAGE_ACCESS.FIELDS.VECTOR_ACCESS.USER_ALLOWED_VECTORS;

function getVectorAllowedAttributes(vector, vectorGroup, user) {
    let allowedAttrs = [];
    if(!user) {
        return allowedAttrs;
    }
    //apply access limitation on the chosen vector, if not all allowed for the vector's group
    if((vectorGroup === VECTOR_GROUPS.CUSTOMER && !user[_userAllowedVectors][MANAGE_ACCESS.FIELDS.VECTOR_ACCESS.AA_CUSTOMER]) || 
        (vectorGroup === VECTOR_GROUPS.PRODUCT && !user[_userAllowedVectors][MANAGE_ACCESS.FIELDS.VECTOR_ACCESS.AA_PRODUCT]) || 
        (vectorGroup === VECTOR_GROUPS.OPERATIONS && !user[_userAllowedVectors][MANAGE_ACCESS.FIELDS.VECTOR_ACCESS.AA_OPERATIONS])) {
            if(!user[_userAllowedVectors][vector]) {
                //if for any reason the vector is not among present in the vector access control, show both name and number
                // allowedAttrs.push(IDENTIFIERS.NAME);
                // allowedAttrs.push(IDENTIFIERS.NUMBER);
            } else {
                //if always allow is set to false, check the access on the vector level
                if(user[_userAllowedVectors][vector][MANAGE_ACCESS.FIELDS.VECTOR_ACCESS.SHOW_NAME]) {
                    allowedAttrs.push(IDENTIFIERS.NAME);
                }
                if(user[_userAllowedVectors][vector][MANAGE_ACCESS.FIELDS.VECTOR_ACCESS.SHOW_NUMBER]) {
                    allowedAttrs.push(IDENTIFIERS.NUMBER);
                }
            }
    } else if((vectorGroup === VECTOR_GROUPS.CUSTOMER && user[_userAllowedVectors][MANAGE_ACCESS.FIELDS.VECTOR_ACCESS.AA_CUSTOMER]) || 
        (vectorGroup === VECTOR_GROUPS.PRODUCT && user[_userAllowedVectors][MANAGE_ACCESS.FIELDS.VECTOR_ACCESS.AA_PRODUCT]) || 
        (vectorGroup === VECTOR_GROUPS.OPERATIONS && user[_userAllowedVectors][MANAGE_ACCESS.FIELDS.VECTOR_ACCESS.AA_OPERATIONS])) {
        //if always allow is set to true, add both name and number
        allowedAttrs.push(IDENTIFIERS.NAME);
        allowedAttrs.push(IDENTIFIERS.NUMBER);
    }

    return allowedAttrs;
}

export {getVectorAllowedAttributes}