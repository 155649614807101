import React, { Component } from "react";
import { getTranslationFile } from '../../class/utils.js';
import { STAGING_SECTIONS, ENGINE_FILTER, MANAGE_ACCESS,FILTER, BUTTON_VARIANT, SIZES, BUTTON_TYPE, DIALOG_SIZE } from '../../class/constants';
import EngineFilterDialog from "./EngineFilterDialog.js";
import Popup from 'react-popup';
import { convertPxToViewport } from "../../class/formatting.js";
import Button from "../../newComponents/Button.js";
import Modal from "../../newComponents/Modal.js";

const $ = require('jquery');
const UIkit = require('uikit');
const lang = getTranslationFile();
const _fileType = ENGINE_FILTER.KEYS.FILE;
const _column = ENGINE_FILTER.KEYS.COLUMN;
const _function = ENGINE_FILTER.KEYS.FUNCTION;
const _value = ENGINE_FILTER.KEYS.VALUES;
const _empty = lang.ui_filter.dropdowns.engine_filter_functions.empty.value;
const _nempty = lang.ui_filter.dropdowns.engine_filter_functions.not_empty.value;
const _parenthesisBefore = ENGINE_FILTER.KEYS.PARENTHESIS_BEFORE;
const _parenthesisAfter = ENGINE_FILTER.KEYS.PARENTHESIS_AFTER;
const _logicalOperator = ENGINE_FILTER.KEYS.LOGICAL_OPERATOR;
const _field = ENGINE_FILTER.KEYS.FIELD;
const _vector ="vector";
class EngineFilterModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };

        this.openFilterModal = this.openFilterModal.bind(this);
        this.setFilterFromOutside = this.setFilterFromOutside.bind(this);
        this.showFilterModal = this.showFilterModal.bind(this);
        this.hideFilterModal = this.hideFilterModal.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.discardFilterChanges = this.discardFilterChanges.bind(this);
        this.confirmModal = this.confirmModal.bind(this);
        this.clearFilter = this.clearFilter.bind(this);

        this.componentId = "engineFilterModal";
        $("#"+this.componentId).remove();
    }

    /**This function updates the filter data, and then shows the filter modal */
    openFilterModal(filter) {
        if(filter) {
            this.setFilterFromOutside(filter, ()=>{
                if(this.filterDialRef && this.filterDialRef !== null){
                    this.filterDialRef.updateAdvancedData([], false);
                }
                this.showFilterModal();
            });
        }
    }

    setFilterFromOutside(filter, callback=()=>{}) {
        this.filterDialRef.setFilterFromOutside(filter, callback);
    }

    showFilterModal() {
        UIkit.modal('#'+ this.componentId).show();
    }
    
    hideFilterModal() {
        UIkit.modal('#'+ this.componentId).hide();
    }

    validateFilterMetric(){
        var filterObjects = (this.filterDialRef.state.filterRefs);
        if (filterObjects.length >= 2) {
            for (var i =0;  i<filterObjects.length; i++) {
                if (filterObjects[i+1]) {
                    if (filterObjects[i].ref.current.filterObj[_fileType] !== filterObjects[i+1].ref.current.filterObj[_fileType] && 
                        filterObjects[i+1].ref.current.filterObj[_logicalOperator] === 'OR') {
                            // window._profitIsleOpenModal('rejectNotValidFilterModal');
                            this.setRejectFilterModalOpen(true);
                            $(".modal-backdrop").css({'z-index':'1'});
                            return false;
                        }
                }
           
            }
        }
      
        return true;
    }

    applyFilter(){
        var valid = true;
        if (this.props.stagingReport === STAGING_SECTIONS.METRICS_MAPPING) {
            valid = this.validateFilterMetric();
        }
        var filter = [];
        if (valid) {
            for (var e in this.filterDialRef.state.filterRefs) {
                var currFilter = this.filterDialRef.state.filterRefs[e].ref.current.filterObj;
                if (currFilter[_fileType] === "" || currFilter[_column] === "" ||  currFilter[_function] === ""
                    || ((!currFilter[_value] || currFilter[_value].length === 0) && ![_empty, _nempty].includes(currFilter[_function]))
                    || !currFilter[_fileType] || !currFilter[_column]  || !currFilter[_function] 
                    || ((!currFilter[_field] || currFilter[_field] === "") && this.props.stagingReport === MANAGE_ACCESS.NAME)) {
                    this.setInfoDialogOpen(true, lang.empty_filter_dropdown)
                    return false;
                } else {
                    delete currFilter.column_options;
                    delete currFilter.value_options;
                    delete currFilter.function_options;
                    delete currFilter.file_options;
                    delete currFilter.field_options;
                    delete currFilter.fields;
                    currFilter.operator = currFilter[_function];
                    currFilter.value = [_empty, _nempty].includes(currFilter[_function]) ? [] : typeof currFilter[_value] === "string" ? [{value:currFilter[_value],label:currFilter[_value]}] : currFilter[_value];
                    currFilter[_value] =[_empty, _nempty].includes(currFilter[_function]) ? [] : typeof currFilter[_value] !== "object" ? [{value:currFilter[_value],label:currFilter[_value]}] : currFilter[_value];
                    currFilter[_parenthesisBefore] = !currFilter[_parenthesisBefore] ? "" : currFilter[_parenthesisBefore];
                    currFilter[_parenthesisAfter] = !currFilter[_parenthesisAfter] ? "" : currFilter[_parenthesisAfter];
                    currFilter.entities = currFilter[_value];
                    currFilter.vector = currFilter[_column];
                    currFilter.type = _vector;
                    currFilter.filter_row_type = FILTER.VALUES.FILTER_ROW_TYPE.USER_LIMIT_ACCESS;
                    filter.push(currFilter);
                }      
            }
            this.hideFilterModal();
            if(this.props.isFromCostFunction){
                this.props.saveFilterObjectTemp(filter);
            }else{
                this.props.saveFilter(filter);
            }
        }
        
    }

    discardFilterChanges(){
        for (var e in this.filterDialRef.state.filterRefs) {
            this.filterDialRef.state.filterRefs[e].ref.current.state.menuIsOpen = false;
            this.filterDialRef.forceUpdate();
        }
        this.hideFilterModal();
        this.props.discardFilter();
    }

    clearFilter() {
        for (var e in this.filterDialRef.state.filterRefs) {
            if (Number(e) !== 0) {
                this.filterDialRef.state.filterRefs[e].ref.current.removeFilter();
            }else {
                this.filterDialRef.state.filterRefs[e].ref.current.filterObj[_fileType] = "";
                this.filterDialRef.state.filterRefs[e].ref.current.filterObj[_column] = "";
                this.filterDialRef.state.filterRefs[e].ref.current.filterObj[_function] = "";
                this.filterDialRef.state.filterRefs[e].ref.current.filterObj.value = [];
                this.filterDialRef.state.filterRefs[e].ref.current.filterObj.values= [];
                this.filterDialRef.state.filterRefs[e].ref.current.filterObj.field_data_type = "";
                this.filterDialRef.state.filterRefs[e].ref.current.filterObj.parenthesis_before = "";
                this.filterDialRef.state.filterRefs[e].ref.current.filterObj.parenthesis_after = "";
                this.filterDialRef.state.filterRefs[e].ref.current.filterObj.function_options = [];
                this.filterDialRef.state.filterRefs[e].ref.current.filterObj.value_options = [];
                this.filterDialRef.state.filterRefs[e].ref.current.filterObj.field_options = [];
                this.filterDialRef.state.filterRefs[e].ref.current.filterObj.field = "";
                this.filterDialRef.state.filterRefs[e].ref.current.forceUpdate();
            }
        }
        if(this.props.isFromCostFunction){
            this.props.saveFilterObjectTemp([]);
        }else{
            this.props.saveFilter([]);
        }
        this.setConfirmClearOpen(false);

        // close the filter dialog after clearing it
        this.hideFilterModal();
    }

    confirmModal(){
        var clearedFilter = false;
        for (var e in this.filterDialRef.state.filterRefs) {
            if ((!this.filterDialRef.state.filterRefs[e].ref.current.filterObj[_column] || this.filterDialRef.state.filterRefs[e].ref.current.filterObj[_column] === "") || 
                (this.filterDialRef.state.filterRefs[e].ref.current.filterObj[_function] ==="" || !this.filterDialRef.state.filterRefs[e].ref.current.filterObj[_function]) || 
                (this.filterDialRef.state.filterRefs[e].ref.current.filterObj[_value] && this.filterDialRef.state.filterRefs[e].ref.current.filterObj[_value].length === 0 && ![_empty, _nempty].includes(this.filterDialRef.state.filterRefs[e].ref.current.filterObj[_function])
                    || (!this.filterDialRef.state.filterRefs[e].ref.current.filterObj[_value] && ![_empty, _nempty].includes(this.filterDialRef.state.filterRefs[e].ref.current.filterObj[_function]))) ) {
                    clearedFilter = true;
                }
            }
        if (clearedFilter && this.props.stagingReport !== MANAGE_ACCESS.NAME) {
            window._profitIsleOpenModal('rejectClearModal');
            $(".modal-backdrop").css({'z-index':'1'});
        }else{
          this.setConfirmClearOpen(true);
            $(".modal-backdrop").css({'z-index':'1'});
        }
    }

    setInfoDialogOpen = (isOpen, infoMsg) => {
      let _this = this;
      _this.setState({
        openInfoDialog: isOpen,
        infoMsg: infoMsg || this.state.infoMsg
      })
    }

    openInfoDialogActions = () => {
      return (
        <Button
          label={lang.modal.buttons.ok}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => this.setInfoDialogOpen(false)}
        />
      )
    }

  confirmClearDialogActions = () => {
    return (
      <>
        <Button
          label={lang.modal.buttons.confirm}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => this.clearFilter()}
        />
        <Button
          label={lang.modal.buttons.cancel}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => this.setConfirmClearOpen(false)}
        />
      </>
    );
  }

  setConfirmClearOpen = (isOpen) => {
    let _this = this;
    _this.setState({
      openConfirmClearDialog: isOpen,
    })
  }
    setRejectFilterModalOpen = (isOpen) => {
    let _this = this;
    _this.setState({
        rejectFilterModalOpen: isOpen,
    })
  }

    rejectFilterModalActions = () => {
        let _this = this;
        return (
            <div className="uk-display-flex justify-content-end uk-padding-small-bottom">
                <Button
                    label={lang.modal.buttons.ok}
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={() => this.setRejectFilterModalOpen(false)}
                />
            </div>

        )
    }



    render() {
        var containerWidth = this.props.stagingReport === MANAGE_ACCESS.NAME ? "100vw" : "";
        return (
            <div className={""} uk-modal="stack:true; bgClose: false" style={{width:containerWidth}} id={this.componentId}>
                <div className="uk-modal-dialog uk-width-3-5 fs-12" style={{minWidth:convertPxToViewport(700)}} id="filterDialog">
                    <div className={"uk-modal-header uk-padding-remove"}>
                        <div className={"panel-heading"} >
                            <div className={"uk-grid uk-grid-small uk-flex uk-flex-middle"} uk-grid="" >
                                <div className={"uk-width-expand"}>
                                    <div className={"uk-text-lead uk-text-bold uk-flex uk-flex-middle uk-flex-between"} >
                                        {this.props.stagingReport === MANAGE_ACCESS.NAME ?
                                            <span>{lang.manage_access.limit_access}<i className="fs-12 fal fa-info-circle uk-margin-small uk-cursor-pointer" uk-tooltip={lang.manage_access.limit_access_tooltip}></i></span>
                                        :
                                            <div>
                                                { this.props.file ? 
                                                    <span className={"uk-margin-right"}>
                                                        {lang.filter_text.filter_for + this.props.file + (this.props.column ? " - " + this.props.column : "")}
                                                    </span>:
                                                    <span className={"uk-margin-right"}>
                                                        {lang.filter_text.filter + (this.props.label ? " - " + this.props.label : "")}
                                                        <i className="fal fa-info-circle uk-margin-small uk-cursor-pointer" uk-tooltip={"TBD"}></i>
                                                    </span>
                                                }
                                            </div>
                                        }
                                        <Button 
                                            variant={BUTTON_VARIANT.TERTIARY}
                                            size={SIZES.ICON}
                                            type={BUTTON_TYPE.DEFAULT}
                                            className="uk-button-icon close-button uk-margin-remove"
                                            data-dismiss="modal"
                                            id="close-filter-dialog"
                                            leftIcon={<i className="fal fa-times"/>}
                                            onBtnClick={()=>this.discardFilterChanges()}
                                        />
                                    </div>
                                    <span className="red">{this.props.message && this.props.message.length ? this.props.message[0] : ""}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="engineFilter_0">
                        <EngineFilterDialog
                            ref={el => this.filterDialRef = el}
                            stagingReport={this.props.stagingReport}
                            showFileDropdown={this.props.stagingReport === STAGING_SECTIONS.METRICS_MAPPING || this.props.stagingReport === MANAGE_ACCESS.NAME ? true : false}
                            scenario_id={this.props.scenario}
                            compNumber={0}
                            onDropDownChange={this.props.handleFilterRowChange}
                            label={this.props.label}
                            loadEntitiesFunc={this.props.loadEntitiesFunc}
                            fileTypeOptions={this.props.fileTypeOptions}
                            vectorOptions={this.props.vectorOptions}
                            hiddenVectors={this.props.hiddenVectors}
                            periodName={this.props.periodName}
                        />
                    </div>
                    
                    <div id="rejectClearModal" className="uk-modal-page uk-modal fade" role="dialog">
                        <div className="modal-dialog modal-md">
                            <div className="modal-content uk-padding-large uk-padding-remove-bottom">
                                <Button 
                                    variant={BUTTON_VARIANT.TERTIARY}
                                    size={SIZES.ICON}
                                    type={BUTTON_TYPE.DEFAULT}
                                    className="medium-close-button uk-margin-remove"
                                    data-dismiss="modal"
                                    leftIcon={<i className="fal fa-times black"/>}
                                />
                                <h4 className="modal-container uk-text-center">
                                    {lang.ui_filter.messages.empty_filter}
                                </h4>
                                <div className="uk-display-flex justify-content-end uk-padding-small-bottom">
                                    <Button  
                                        label={lang.modal.buttons.ok}
                                        variant={BUTTON_VARIANT.SECONDARY}
                                        size={SIZES.DEFAULT}
                                        type={BUTTON_TYPE.DEFAULT}
                                        data-dismiss="modal"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<div id="rejectNotValidFilterModal" className="uk-modal-page uk-modal fade" role="dialog">*/}
                    {/*    <div className="modal-dialog modal-md">*/}
                    {/*        <div className="modal-content uk-padding-large uk-padding-remove-bottom">*/}
                    {/*            <Button */}
                    {/*                variant={BUTTON_VARIANT.TERTIARY}*/}
                    {/*                size={SIZES.ICON}*/}
                    {/*                type={BUTTON_TYPE.DEFAULT}*/}
                    {/*                className="close-button uk-margin-remove"*/}
                    {/*                data-dismiss="modal"*/}
                    {/*                leftIcon={<i className="fal fa-times"/>}*/}
                    {/*            />*/}
                    {/*            <h4 className="modal-container uk-text-center">*/}
                    {/*                {lang.ui_filter.messages.invalid_filter}*/}
                    {/*            </h4>*/}
                    {/*            <div className="uk-display-flex justify-content-end uk-padding-small-bottom">*/}
                    {/*                <Button  */}
                    {/*                label={lang.modal.buttons.ok}*/}
                    {/*                variant={BUTTON_VARIANT.SECONDARY}*/}
                    {/*                size={SIZES.DEFAULT}*/}
                    {/*                type={BUTTON_TYPE.DEFAULT}*/}
                    {/*                data-dismiss="modal"*/}
                    {/*            />*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/
                        <Modal
                            id={"rejectNotValidFilterModal"}
                            openDialog={this.state.rejectFilterModalOpen}
                            bodyContent={() => <h4>{lang.ui_filter.messages.invalid_filter}</h4>}
                            dialogActions={this.rejectFilterModalActions}
                            closeClick={() => this.setRejectFilterModalOpen(false)}
                            size={DIALOG_SIZE.MEDIUM}
                        />}
                    <div className="uk-display-flex justify-content-between uk-margin-medium-right-left">
                        <a className="text-link uk-margin-medium-left uk-margin-default-bottom"
                        onClick={()=>{this.confirmModal()}}>{lang.modal.buttons.clear}</a>

                        <div className="uk-display-flex uk-margin-default-bottom">
                            <Button  
                                id="cancel-filter"
                                label={lang.modal.buttons.cancel}
                                variant={BUTTON_VARIANT.SECONDARY}
                                size={SIZES.DEFAULT}
                                type={BUTTON_TYPE.DEFAULT}
                                className="uk-margin-default-right"
                                onBtnClick={this.discardFilterChanges}
                            />
                            <Button 
                                id="apply-filter"
                                label={lang.modal.buttons.apply}
                                variant={BUTTON_VARIANT.PRIMARY}
                                size={SIZES.DEFAULT}
                                type={BUTTON_TYPE.DEFAULT}                
                                onBtnClick={this.applyFilter}
                            />
                        </div>
                    </div>
                </div>
            <Modal
              id={"info-dialog"}
              openDialog={this.state.openInfoDialog}
              bodyContent={() => <h4>{this.state.infoMsg}</h4>}
              dialogActions={this.openInfoDialogActions}
              closeClick={() => this.setInfoDialogOpen(false)}
              size={DIALOG_SIZE.MEDIUM}
            />
            <Modal
              id={"engine-filter-confirm-clear-dialog"}
              openDialog={this.state.openConfirmClearDialog}
              closeClick={() => this.setConfirmClearOpen(false)}
              bodyContent={() => <h4>{lang.confirm_clear}</h4>}
              dialogActions={this.confirmClearDialogActions}
              size={DIALOG_SIZE.MEDIUM}
            />
            </div>
        )
    }
}

export default EngineFilterModal;