import React from 'react';

const Label = ({ size = 'medium', value }) => {
    return (
        <span className={`label ${size}`}>
            {value}
        </span>
    );
};

export default Label;
