import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState, useMemo } from 'react';
import { _LOGGED_OUT, getLocalStorageValueByParameter, setLocalStorageValueByParameter } from './class/common';
import { API_URL, BUTTON_TYPE, BUTTON_VARIANT, DIALOG_SIZE, DIALOG_TRANSITION, SIZES } from './class/constants';
import { FETCHAPI_PARAMS, FETCH_METHOD, fetchAPI } from "./class/networkUtils";
import Button from './newComponents/Button';
import Modal from './newComponents/Modal';
import { lang } from './language/messages_en';

const _MILLISECONDS = 60000;
const SHOW_TOAST_MESSAGE = "showToastMessage";
/**
 * @author Sarah Farjallah
 * @description Component that sets time interval to logout the user after x ms defined as props and resets it each time user moves the mouse or the screen loads
 * @returns modal at the bottom right of the screen alerting the user that it will logout in 1 minute with a countdown and 2 options to logout immediately or continue session 
 */
const SessionTimeout = forwardRef((props,ref) => {
  const [events, setEvents] = useState(['click', 'load', 'scroll', 'mousemove', 'keydown']);
  const [second, setSecond] = useState(60);
  const [isOpen, setOpen] = useState(false);
  let isAuthenticated = props.isAuthenticated;
  let warningInactiveInterval = useRef();
  let startTimerInterval = useRef();
  let startTimerAPIInterval = useRef();
  const _UI = "ui";
  const _API = "api";
  const session_timeout_api = useMemo(()=>{ return props.session_timeout_api },[props.session_timeout_api]);
  const session_timeout_ui = useMemo(()=>{ return props.session_timeout_ui },[props.session_timeout_ui])
  useImperativeHandle(ref, () => ({
    clearTimeOut: () => {
     return clearTimeOut();
    }
  }));
  

  /**
   * function updates localStorage for tabs and decrements it 
   */
  // const handleTabClose=()=>{
  //   localStorage.setItem("tabs",Number(localStorage.getItem("tabs"))-1)
  // }

  /**
   * when closing tab call handleTabClose
   */
  // useEffect(()=>{
  //   window.addEventListener('beforeunload', handleTabClose);
  // },[])
  /**
   * checks each xms/5 x: api session timeout set in the database
   * if last request sent time >= 4*(x/5) then the api session is about to time out
   * we send a request to refresh it  
   */
  let checkAPIActivity = () => {
    if (!props.skipApiTimeout) {
      startTimerAPIInterval.current = setInterval(() => {
        let now = new Date();
        let lastRequestSentTime = new Date(getLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime"));
        let sentSince = (now - lastRequestSentTime)/(1000 * 60) % 60;
        if (sentSince >= 4*((Number(session_timeout_api)/5))) {
          refreshAPISession();
        }
      }, (Number(session_timeout_api)/5)*_MILLISECONDS);
    }
  }

  let refreshAPISession = () => {
    let query = {
      action: "refreshSession",
    }
    let onThenCallback = (data) => {
    } 
    let fetchOptions = {
        [FETCHAPI_PARAMS.funcName]: "refreshSession",
        [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
        [FETCHAPI_PARAMS.showLoader]: false,
        [FETCHAPI_PARAMS.path]: API_URL.SESSION,
        [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
        [FETCHAPI_PARAMS.query]: query,
        [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
        [FETCHAPI_PARAMS.log_time]: false, // if we log the time of this request then the dialog will never appear since the last request sent time will always be renewed 
    };
    fetchAPI(fetchOptions);
  }

  const getActiveSince=(attr)=> {
    let now = new Date();
    if (attr === _UI) {
      let lastTimeActiveUI = new Date(getLocalStorageValueByParameter(window.location.host+"_lastTimeActive"));
      let activeSinceUI = now.getTime() - lastTimeActiveUI.getTime();
      return activeSinceUI;
    } else if (attr === _API) {
      let lastTimeActiveAPI = new Date(getLocalStorageValueByParameter(window.location.host+"_lastRequestSentTime"));
      let activeSinceAPI = now.getTime() - lastTimeActiveAPI.getTime();
      return activeSinceAPI;
    }
  }

  /**
   * function waits [x] ms x: defined from db 
   * after x ms have passed without being active 
   * we call warningInactive function
   */
  let checkUIActivity = (timeInterval) => { //after 1 minute of being inactive we'll start the count down for logout

    let timeout = timeInterval || (Number(session_timeout_ui))*_MILLISECONDS;
    startTimerInterval.current = setTimeout(() => {
      let counter = 0; //if sth loadin in the background do not warn user of inactivity
      if(!props.skipApiTimeout){
        Object.keys(window._loading).map(key=>{counter++});
      }
      let activeSinceUI = getActiveSince(_UI);
      let activeSinceAPI = getActiveSince(_API);
	    if (counter == 0 && $(".skeleton-container").length == 0 && (activeSinceUI < (Number(session_timeout_ui))*_MILLISECONDS || (!props.skipApiTimeout && activeSinceAPI < (Number(session_timeout_ui))*_MILLISECONDS))) {
        resetTimer(false, (Number(session_timeout_ui))*_MILLISECONDS - Math.max(activeSinceUI, activeSinceAPI));
      }else if (counter == 0 && $(".skeleton-container").length == 0 ) { // making sure no requests are being sent in dashboard screens and others
        if(props.skipShowDialog){
          setLocalStorageValueByParameter(SHOW_TOAST_MESSAGE,true);
          props.logout();
        }else{
          warnUserBeforLogout();
        }
      } else {
        resetTimer();
      }
    }, timeout);
  };

  let clearTimeOut=()=> {
    events.forEach((event) => {
      window.removeEventListener(event, resetTimer);
    });
    clearTimeout(startTimerInterval.current);
    clearInterval(warningInactiveInterval.current);
    startTimerInterval.current = null;
    warningInactiveInterval.current = null;    
   }


  /**
   * called on triggering events set in state and when user clicks on continue from warning dialog 
   * it clears timeInterval for the timeChecker 
   * and clears timeInterval for warning dialog
   */
  let resetTimer = useCallback((isEvent, timeInterVal) => {
    if ($(".marginBottom-session-dialog").length === 0 || isEvent === true) {
      if ((isEvent && typeof isEvent !== 'boolean') || isEvent === true) {
        setLocalStorageValueByParameter(window.location.host+"_lastTimeActive",new Date());
      }
      if (document.getElementById("session_timer") && document.getElementById("session_timer").attributes && document.getElementById("session_timer").attributes.length > 0) {
        document.getElementById("session_timer").attributes[2].value = 60;
      }
      clearTimeout(startTimerInterval.current);
      clearInterval(warningInactiveInterval.current);
      // warningInactiveInterval.current = null;
      if (isAuthenticated) {
        // timeStamp = moment();
        // sessionStorage.setItem('lastTimeStamp', timeStamp);
      } else {
        clearInterval(warningInactiveInterval.current);
        // sessionStorage.removeItem('lastTimeStamp');
      }
      checkUIActivity(isEvent === false && timeInterVal ? timeInterVal : undefined);
    }
  }, [isAuthenticated]);

  /**
   * on mount add listeners to each event on the screen so that on each event we reset timer for ui session
   * call timeChecker on mount of component
   */
  useEffect(() => { // reset timer each time we trigger an event 
    // if (localStorage.getItem("tabs") == null || Number(localStorage.getItem("tabs")) <=0) {
    //   localStorage.setItem("tabs","1");
    // }
    // localStorage.openpages = Date.now();
    // window.addEventListener('storage', function (e) {
    //     if(e.key == "openpages") {
    //         // Listen if anybody else is opening the same page!
    //         localStorage.page_available = Date.now();
    //     }
    //     // if(e.key == "page_available") {
    //     //   localStorage.setItem("tabs",Number(localStorage.getItem("tabs"))+1);
    //     // }
    // }, false);
    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });
    if (!isOpen) {
      checkAPIActivity();
      checkUIActivity();
      return () => {
          clearTimeout(startTimerInterval.current);
          // clearInterval(warningInactiveInterval.current);
          // startTimerInterval.current = null;
          // warningInactiveInterval.current = null;  
      };
    }
  }, [resetTimer, events, checkUIActivity]);


  useEffect(() => { // on unmount remove timeout and and remove listeners on all events so that no timeouts are created again before the unmount 
      return () => {
        events.forEach((event) => {
          window.removeEventListener(event, resetTimer);
        });
        setEvents([]);
        clearTimeout(startTimerInterval.current);
      };
  }, []);
  
  /**
   * called when user is not being active for [x] ms 
   * it sets an interval of 1 second to count down the seconds left for the user before he gets logged out
   * @param {*} timeString 
   */
  let warnUserBeforLogout = (timeString) => {
    let startFunktime = getLocalStorageValueByParameter(window.location.host+"_lastTimeActive",new Date());
    clearTimeout(startTimerInterval.current);
    let timeLeft = 60;
    events.forEach((event) => {
      window.removeEventListener(event, resetTimer);
    }); 
    let firstRound = true;
    if(!isOpen) {
        setOpen(true);
      }
    warningInactiveInterval.current = setInterval(() => { 
      let activeSinceUI = getActiveSince(_UI);
      let activeSinceAPI = getActiveSince(_API);
      let now = new Date();
      const msInMinute = 60 * 1000;
      let laptopInSleepModeSince =  Math.round((now - new Date(startFunktime))/msInMinute);
      if (laptopInSleepModeSince <= Number(session_timeout_ui)) {
        firstRound = false;
      }else{
        firstRound = true;
      }
      if ((activeSinceAPI >= (Number(session_timeout_ui))*_MILLISECONDS || activeSinceUI >= (Number(session_timeout_ui))*_MILLISECONDS)
         && laptopInSleepModeSince > Number(session_timeout_ui)+1 && firstRound) {
          props.logout();
      }
      if(activeSinceUI < (Number(session_timeout_ui))*_MILLISECONDS || activeSinceAPI < (Number(session_timeout_ui))*_MILLISECONDS) { // if user clicked to logout or continue session from another tab
        if(getLocalStorageValueByParameter(window.location.host+"_"+_LOGGED_OUT) === _LOGGED_OUT) { // if logged out from another tab immediatley logout from this tab
            props.logout();
        }
        refreshUISession(); // refresh ui session 
      }
      if (timeLeft === 60) {
        events.forEach((event) => {
          window.removeEventListener(event, resetTimer);
        }); 
      }
      document.getElementById("session_timer").attributes[2].value= timeLeft;
      timeLeft -= 1; 

      $("#countdown").text(lang.session.session_expired.replace("X", document.getElementById("session_timer").attributes[2].value));
      if (Number(document.getElementById("session_timer").attributes[2].value) === 0 && $(".marginBottom-session-dialog").length > 0) { // only if countdown reached 60 and the dialog is still open we logout // used jquery for performance and refernece issues
        clearInterval(warningInactiveInterval.current);
        // setOpen(false);
        sessionStorage.removeItem('lastTimeStamp');
        props.logout();
      }
    }, 1000); // each 1 second we decrement the time as a warning for the user
  };

  /**
   * called when user click on continue 
   * readd events with their functions callback as listeners 
   * and resets the timer
   */
  const refreshUISession = ()=> {
    clearInterval(warningInactiveInterval.current);
    warningInactiveInterval.current = null;
    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });
    $("#countdown").text(lang.session.session_expired.replace("X", document.getElementById("session_timer").attributes[2].value));
    setCloseDialog();
    setSecond(0);
    resetTimer(true);
    document.getElementById("session_timer").attributes[2].value = 60;
  }

  const dialogActions = () => {
    return (
      <>
          <Button 
            label={lang.session.continue}
            className={"uk-padding-small-right uk-text-medium"}
            variant={BUTTON_VARIANT.PRIMARY}
            size={SIZES.DEFAULT}
            type={BUTTON_TYPE.DEFAULT}
            onBtnClick={refreshUISession}
          />
         <Button 
            label={lang.session.logout}
            variant={BUTTON_VARIANT.SECONDARY}
            size={SIZES.DEFAULT}
            type={BUTTON_TYPE.DEFAULT}
            className="uk-padding-small-right uk-text-medium"
            onBtnClick={() => {
              props.logout()
              setCloseDialog(); 
            }}
        />
      </>
    )
  }

  const sessionModal = () => {
    return (
          <div className="uk-border-rounded uk-padding-xsmall">
              <h5 className="uk-text-large uk-text-center" id="countdown">
                {lang.session.session_expired.replace("X", document.getElementById("session_timer") && document.getElementById("session_timer").attributes ? document.getElementById("session_timer").attributes[2].value : "")}
              </h5>
          </div>    
    )
  }

  const setCloseDialog = () => {
    setOpen(false);
  }

  return (
    <div id = "session_dialog">
      <Modal 
        openDialog={isOpen}
        bodyContent={sessionModal} 
        dialogActions={dialogActions}
        size={DIALOG_SIZE.MEDIUM}
        transition={DIALOG_TRANSITION.BOTTOM}
        removeClose
      />
      <div className="uk-hidden" id="session_timer" value={60}></div>
    </div>
  );
});

export default SessionTimeout;