import { useState } from "react";
import { useImperativeHandle } from "react";
import { forwardRef } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { ReactComponent as ErrorIcon } from "../styles/images/icons/triangle-exclamation-regular.svg";
import { BUTTON_DROPDOWN_VARIANT, SIZES } from "../class/constants";
import { convertPxToViewport } from "../class/formatting";

/**
 * Button dropdown component: It's a button that opens a popover or a div that contains multiple components and not just a list of options
 * @param {string} id Id of the button
 * @param {string} dropId Id of component that opens when we click on this button
 * @param {string} label The label of the button,  (required)
 * @param {string} placeholder The label inside the btn (required)
 * @param {string} capture The description the is underneath the label
 * @param {string} appliedNumber The number that appears in the dropdown next to the placeholder
 * @param {string} className additional classNames
 * @param {string} size Could be "small" or "large" (use constants) (required)
 * @param {string} variant
 * @param {Element} placeholderIcon Adds a left icon before the placeholder text (should be sent as a component and not a string)
 * @param {Boolan} disabled Sets the button to be disbaled
 * @param {function} onBtnClick A function that is called when the button is clicked.
 * @param {function} errorMsg An error mesg to appear under the button.
 * @returns The rendered button dropdown component
 */
const ButtonDropdown = ({
  id = "btn-dropdown",
  dropId,
  label = "",
  placeholder = "",
  capture = "",
  appliedNumber = 0,
  className = "",
  placeholderIcon,
  disabled,
  onBtnClick,
  variant = BUTTON_DROPDOWN_VARIANT.BUTTON,
  size = SIZES.SMALL,
  errorMsg,
  firstAttr,
  secondAttr,
  renderContainerBody,
  tooltip,
  disabledTooltip,
  dropIcon,
  ...props // other props that needs to be added.
},ref) => {
  useImperativeHandle(ref, () => ({
    setConfigDropdownOpen:(val) => {
      setIsDropdownOpen(val);
      $(`#button-drop-${id}`).attr('aria-expanded',String(!isDropdownOpen));
    }
  }));

  const [isFocused, setIsFocused] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  let isFilled = appliedNumber !== 0;
  let btnClassNames = `button-drop button-drop-${variant} btn-drop-${size}`;
  btnClassNames += isFilled ? ` button-drop-${variant}-filled` : "";
  btnClassNames += disabled ? " btn-disabled" : "";
  btnClassNames += " " + className;

  // useEffect(() => {
  //   setIsFocused($(`#${dropId}`).length > 0);
  // });

  useEffect(() => {
    closeConfigureDropdown(firstAttr, secondAttr);
  }, [isDropdownOpen]);

  const closeConfigureDropdown = (firstAttr, secondAttr) => {
    $(document).on("mouseover click", function (event) {
      if ((($(`${firstAttr}:hover`).length === 0) && (secondAttr ? $(`${secondAttr}:hover`).length === 0 : true) && (isDropdownOpen))) {
        if (event.type === "click") {
          setIsDropdownOpen(false);
          $(`#button-drop-${id}`).attr('aria-expanded', String(!isDropdownOpen));
        }
      }
    })

  }
  const showConfigureDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    $(`#button-drop-${id}`).attr('aria-expanded', String(!isDropdownOpen));
  }

  return (
    <>
    <div
        style={{display: "flex", flexDirection: "column", textAlign: "left"}}
        uk-tooltip={disabledTooltip ? disabledTooltip : disabled ? "" : tooltip}
    >
      <span className="fs-default">{label}</span>
      <span className="fs-xs">{capture}</span>
        <button {...props} id={`button-drop-${id}`} className={btnClassNames} onClick={onBtnClick ? onBtnClick : showConfigureDropdown} style={{ height: convertPxToViewport(32), borderWidth: convertPxToViewport(1) }}>
        <span className="button_dropdown_placeholder_container">
        {placeholderIcon && <>{placeholderIcon}</>}
        <div style={{padding:placeholderIcon?"0px 0.42vw":"0 0.42vw 0 0"}}>
          {/* remove minWidth when we migrate to new design */}
          <span className="text-ellipsis" style={{maxWidth: "5.5vw", float: "left"}}>{placeholder}</span> 
          {isFilled && (
            <span
              className={
                (typeof appliedNumber === "string" ? "text-" : "") +
                "number-selection dropdowns-number-selection"
              }
            >
              {appliedNumber}
            </span>
          )}
        </div>

        <span className="" style={{fontSize: convertPxToViewport(16)}}>
          {dropIcon ? dropIcon : <i className="far fa-chevron-down" />}
        </span>
        </span>
      </button>
      {errorMsg && (
        <span className={"errorMsg margin-xxsmall-top"}>
          <ErrorIcon style={{ marginRight: 4 }} />
          {errorMsg}
        </span>
      )}
    </div>
    {isDropdownOpen ? renderContainerBody() : ""}
    </>
  );
};

// ButtonDropdown.defaultProps = {
//   id: "btn-dropdown",
//   label: "",
//   placeholder: "",
//   capture: "",
//   className: "",
//   appliedNumber: 0,
//   disabled: false,
//   size: SIZES.SMALL,
//   variant: BUTTON_DROPDOWN_VARIANT.BUTTON,
//   renderConfigureBody: () => {},
// };

export default forwardRef(ButtonDropdown);
