import React, { Component } from 'react';
import {
  ACCRUALS,
  ALL_WIDGETS, API_URL,
  BUTTON_TYPE,
  BUTTON_VARIANT,
  CALCULATED_COLUMNS,
  DEFINE_COSTCENTER,
  DIALOG_SIZE,
  FormatTypes,
  Formats,
  GLACCOUNTS_FIELDS,
  METRICS_MAPPING,
  PERIOD_STATUS_ENUM,
  PROFILE_COLUMN,
  PS_MAPPING,
  RAW_ITEMS,
  ROW_STATUS,
  SCENARIO_STATUS,
  SIZES,
  STAGE_FIELDS,
  STAGING_SECTIONS,
  VECTOR_MAPPING,
  costtype
} from '../../class/constants.js';
import { FETCHAPI_PARAMS, FETCH_METHOD, fetchAPI } from "../../class/networkUtils";
import {
  addManualChild, capitalizeFirstLetter, copyObjectValues, findOptionByKeyValue, findPslRow, generateActionToken, generateUniqueCostKey,
  generateUniqueIdDB, getSiblings, getTreeLeaves, isDisabledToggleButton, isMappable, logoutIfUnauthenticated, parseBoolean, reOrderLine, renameMatched, retrieveRowByAttr, sortMappedLines, tryParse, updateChildAttr, updateChildAttr2, updateDeletedProfitStackFields
} from '../../class/utils.js';
import GLCombinations from './/GLCombinations.js';
import PSLSection from './/PSLSection.js';
import SimpleTable from './SimpleTable.js';

import shortid from 'shortid';

import { addElements, findIndexOfValue, getEmbeddedChild, getEmbeddedChildren, linearizeHierarchy, prune } from '../../class/array';
import { setLocalStorageValueByParameter, toggleLoader } from "../../class/common";
import { alertAndLogError } from '../../class/jqueries.js';
import { is_aN } from '../../class/number';
import { replaceSpecialChars } from '../../class/string';
import Backdrop from '../../components/sidePanelNew/Backdrop.js';
import SidePanelNew from '../../components/sidePanelNew/SidePanelNew.js';
import { lang } from '../../language/messages_en.js';
import Button from '../../newComponents/Button.js';
import CheckBox from '../../newComponents/CheckBox.js';
import Modal from '../../newComponents/Modal';
import AttributeSetupContainer from './AttributeSetupContainer.js';
import CalculatedSetupContainer from './CalculatedSetupContainer.js';
import ExceptionDriverContainer from './ExceptionDriverContainer.js';
import { deepCompareObjects } from '../../class/utils.js';


const $ = require('jquery');

const baseUrl = process.env.REACT_APP_BASE_URL;
const path = "/DataModeling";
const _metric_configuration = "metric_configuration";
const _metric = "metric";
const _metric_value = "metric_value";
const _calcCol = PS_MAPPING.EXCEPTION_FIELDS.CALCULATED_COL;
const _calcCols = STAGING_SECTIONS.CALCULATED_COLUMNS;
const _vectorName = VECTOR_MAPPING.FIELDS.VECTOR_COLUMN_NAME;
const _displayName = VECTOR_MAPPING.FIELDS.VECTOR_DISPLAY_NAME;
const _id = PS_MAPPING.FIELDS.PSS_ID;
const _leadingID = PS_MAPPING.FIELDS.LEADING_PSS_ID;
const _leadingId = PS_MAPPING.FIELDS.LEADING_PSS_ID;
const _leadingCostKey = PS_MAPPING.FIELDS.LEADING_COSTKEY;
const _name = PS_MAPPING.FIELDS.NAME;
const _costType = PS_MAPPING.FIELDS.COSTTYPE;
const _mapFormula = PS_MAPPING.FIELDS.MAP_FORMULA;
const _returnName = PS_MAPPING.FIELDS.RETURN_NAME;
const _costKey = PS_MAPPING.FIELDS.COST_KEY;
const _parentCostKey = PS_MAPPING.FIELDS.PARENT_COST_KEY;
const _mappingException = PS_MAPPING.FIELDS.MAPPING_EXCEPTION;
const _combinations = PS_MAPPING.FIELDS.FIELDS_COMBINATIONS;
const _deleted = PS_MAPPING.FIELDS.DELETED;
const _percentage = PS_MAPPING.EXCEPTION_FIELDS.PERCENTAGE;
const _isMatched = PS_MAPPING.EXCEPTION_FIELDS.isMatched;
const _variance = lang.pss_map_exception.suffixes.variance;
const _children = PS_MAPPING.FIELDS.CHILDREN;
const _costCenter = PS_MAPPING.FIELDS.COST_CENTER;
const _amount = PS_MAPPING.EXCEPTION_FIELDS.AMOUNT;
const _isExpandable = PROFILE_COLUMN.IS_EXPANDABLE;
const _isExpanded = PROFILE_COLUMN.IS_EXPANDED;
const _level = PROFILE_COLUMN.LEVEL;
const _isInSearchResult = PROFILE_COLUMN.IS_IN_SEARCH_RESULT;
const _all = 'ALL';
const _false = "false";
const _true = "true";
const _driverType = "driver_type";
const NONE = GLACCOUNTS_FIELDS.MAP_EXCEPTION_VALUES.NONE;
const ANCILLARY = GLACCOUNTS_FIELDS.MAP_EXCEPTION_VALUES.ANCILLARY;
const TRANSACTION = GLACCOUNTS_FIELDS.MAP_EXCEPTION_VALUES.TRANSACTION;
const _excluded = "Excluded";
const _unassigned = "Unassigned";
const _attribute = PS_MAPPING.FIELDS.ATTRIBUTE;
const _attributeFunction = PS_MAPPING.FIELDS.ATTRIBUTE_FUNCTION;
const _attributeType = PS_MAPPING.FIELDS.ATTR_TYPE;
const _checked = "checked";
const _label = "label";

const bannerConfig = {
  text: lang.conditions_not_met_banner,
  icon: "fa-minus-circle",
  iconColor: "red",
  body: undefined,
  bgColor: "red",
};
const eligiblePeriod_bannerConfig = {
  text: lang.eligible_period_banner,
  icon: "fa-info-circle",
  iconColor: "orange-color",
  bgColor: "yellow",
  body: 
    <Button
      id={"mapping-banner-btn"}
      label={"Details"}
      variant={BUTTON_VARIANT.TERTIARY}
      size={SIZES.DEFAULT}
      type={BUTTON_TYPE.DEFAULT}
    />
  
};

/**
 *Screen accessed from menu DataModeling->Stage->mapping button on profit stack mapping table
 * @author [Sarah Farjallah]
 * @extends Component
 * **/

class ProfitStackMappingNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: shortid.generate(),
            displayPanel: false,
            toBeDeleted:[],
            isSubmitButtonText: false,
            fieldsCombinationsByCostKeys:[],
            invalidated: false,
            flippedCombinations:[],
            flipChecked: true,
            isChanged: this.props.isChanged,
            notFirstLoad: false,
            drawerOpen: false,
        };
        this.inputRefs = {};
        this.fetchAPI = fetchAPI.bind(this);
        this.customFilter = this.customFilter.bind(this);
    }

    /**
     * discard changes if any and return to stage screen
     * copied from old code
     * @returns
     */
    discardChangesAndGoBack = () => {
        let obj = this;
        clearTimeout();
        setTimeout(function () {
            if (obj.pslSectionRef?.pslTableRef?.state?.isChanged || obj.state.isChanged) {
              obj.setOpenConfirmCancelDialog(true)  
            } else {
                obj.setStagingReport();
            }
        }, 200)        //check if there are modified fields
    }

    /**
     * sets main report to STAGE on click of back
     */
    setStagingReport = () => {
        if (this.props.stagingReport === ALL_WIDGETS.PS_MAPPING_MENU) {
            this.setNotSavedWarning(false);
        } else {
            if (this.pslSectionRef && this.pslSectionRef.pslTableRef && this.pslSectionRef.pslTableRef.state.changed) {
                this.props.setStagingReport(ALL_WIDGETS.FIELDS.STAGE, true);
            } else {
                this.setNotSavedWarning(false);
                this.props.setStagingReport(ALL_WIDGETS.FIELDS.STAGE);
            }
        }
    }

  /**
   * API request te fetch GL, Totals data along with their columns
   */
    getCombinationsData = (fetchAmounts, fromChangePeriods) => {
        let _this = this;
        // let combinations = fetchAmounts? this.GLCombinationsref.GLTableref.tabulator.getData() : [];

        // for(let i =0; i<combinations.length ; i++){
        //     combinations[i][PS_MAPPING.FIELDS.ASSIGNED_TO] = combinations[i][PS_MAPPING.FIELDS.ASSIGNED_TO].toString().replace(",",";");
        // }
        let query = {
            action: "getCombinationsData",
            scenario_id: this.props.scenarioId,
            selectedPeriods: fetchAmounts ? this.props.getSelectedPeriods():[],
            fetchAmounts:fetchAmounts
            // combinations: combinations
        };

        let onThenCallback = (data) => {
            _this.setState({
                notFirstLoad: fromChangePeriods,
                glData: data.gl_data,
                originalGlData: copyObjectValues(data.gl_data),
                glColumns: data.gl_columns,
                totalsData: data.totals_data,
                totalsColumns: data.totals_columns,
                id_totals: shortid.generate(),
                id: shortid.generate(),
                pssFilterStructureData: _this.state.pssFilterStructureData || data.pss_filter_structure_data,
                glSourceData:fetchAmounts ? this.state.glSourceData : data.gl_source_data
            },function(){
                _this.GLCombinationsref.GLTableref.setState({
                    checked: 0,
                    
                })
                _this.GLCombinationsref.setState({
                    selectedNodes: undefined,
                    
                })

            })
        }
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getCombinationsData",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.periods]: fetchAmounts ? this.props.getSelectedPeriods():[],
            [FETCHAPI_PARAMS.screenName]:lang.observability.stage.profit_stack_mapping.screen_name,
            [FETCHAPI_PARAMS.requestDescription]:lang.observability.stage.profit_stack_mapping.requests_description.get_combinations_data
        };
        this.fetchAPI(fetchOptions);
    }



   
    componentDidMount() {
      let _this = this;
       
      if (_this.props.isFromMenu) {
          _this.props.getClientCostTerms();
          _this.props.setStagingReport(ALL_WIDGETS.PS_MAPPING_MENU);
      } else {
          _this.setState({fetchAmounts:false},()=>{
              _this.fetchDataForMapping(_this.state.fetchAmounts);
              _this.getCombinationsData(_this.state.fetchAmounts);
          })
      }

    }

    componentDidUpdate() {
      let _this = this;
      if(_this.GLCombinationsref?.GLTableref?.tabulator?.getData()?.length) {
        let showCombinationsBanner = _this.checkUnassignedLength() || _this.checkUnMappedLength()
        let isNotValidForStaging = !_this.isValidForStaging();
        let showBanner = showCombinationsBanner || isNotValidForStaging;

        _this.props.setShowBanner(showBanner);
        if(showBanner) {
            _this.props.setBannerConfig(showCombinationsBanner ? bannerConfig : eligiblePeriod_bannerConfig)
        }
      }
    }

    componentWillUnmount() {
      this.props.setShowBanner(false);
    }

    /**
     * function send requests to fetch data for the new period
     */
    changePeriod=()=>{
        this.setState({fetchAmounts:true},()=>{
            this.fetchDataForMapping(this.state.fetchAmounts);
            this.getCombinationsData(this.state.fetchAmounts, true);
            this.pslSectionRef.pslTableRef.parseAmount(this.props.getSelectedPeriods(), false);
            // this.props.setChosenPeriods(this.props.getSelectedYears());
        })
    }

    discardonPeriodChange=()=>{
        // this.pslSectionRef.pslTableRef.state.profitStackTableOriginalFields;
        this.pslSectionRef.pslTableRef.setState({
            isChanged: false
        });
        this.state.isChanged = false;
        this.props.onDiscardClick();
        this.changePeriod();
        this.setOpenChangesNotSavedDialog(false)
    }

    /**
     * function is called when user selects a range of periods or one period
     */
     checkForChangesBeforeLoadPeriods = () => {
        let _this = this;
        if ((_this.pslSectionRef && _this.pslSectionRef.pslTableRef && _this.pslSectionRef.pslTableRef.state.isChanged) || _this.props.isChanged) {
          _this.setOpenChangesNotSavedDialog(true)
        }else{
            _this.changePeriod();
        }
    }

    checkAllLines=(e) =>{
        let _this = this;
        let checked = "";
        if(e==="uncheck"){
            checked= false;
        }else{
            checked = e.target.checked
        }
        var data = _this.state.glData;
        let filteredData = _this.GLCombinationsref.GLTableref.tabulator.getData('active');
        let length = filteredData.length;
        let checkedAmount = 0;
        let periods = _this.props.getSelectedPeriods();
        let filteredDataObj = {};
        for(var row in filteredData) { //transform array to map so that we dont keep looping over array in another loop
            filteredDataObj[filteredData[row].raw_file_type_transition_id] = true;
        }
        if (data) {
            data.map(function (item) {
                let found = (filteredDataObj[item.raw_file_type_transition_id] === true); //filteredData.filter(e=> e.raw_file_type_transition_id === item.raw_file_type_transition_id).length > 0;
                if (found) {
                    item["checked"] = checked ? _true : _false;
                }
                checkedAmount += item.checked ? (periods.length > 1 ? Number(item["total"]) : Number(item[periods[0]])) : 0;
            });
        }
        _this.setState({
            glData:data,
        },function(){
            _this.GLCombinationsref.GLTableref.setState({
                checked: checked ? length : 0,
                amount:checkedAmount
            });
            _this.GLCombinationsref.GLTableref.isCheckingAll=true;
            _this.GLCombinationsref.GLTableref.tabulator.replaceData(data);
        });
    }

    /**
     * function sets displayPanel to false or true
     * @param {*} row
     */
     onToggleBoard(){
        let _this = this;
        if (this.state.displayPanel) {
            this.manageDashboardsOverlay.toggleBoard();
        }
        this.setState({
            displayPanel: !this.state.displayPanel,
        },function(){
            if (this.state.displayPanel){
                _this.manageDashboardsOverlay.toggleBoard()
            }
        })
    }
    /**
     * function calculated the fields combination total assigned amount and count to be set as states in Exeption Driver component i=e side panel data
     * @param {*} fieldsCombination
     * @returns
     */
    calculateAssignedAmount = (fieldsCombination)=> {
        let _this = this;
        let periods = this.props.getSelectedPeriods();
        let sum = 0;
        let count = fieldsCombination.length;
        let filteredDataArr = _this.GLCombinationsref.GLTableref.tabulator.getData().filter(elt=>fieldsCombination.includes(elt[PS_MAPPING.FIELDS.FIELDS_COMBINATION]));
        for (var e in fieldsCombination) {
            sum += filteredDataArr && filteredDataArr[e]? periods.length > 1 ? filteredDataArr[e]["total"] ? filteredDataArr[e]["total"]:0
            : filteredDataArr[e][periods[0]] : 0;
        }
        return {sum:sum, count:count};
    }

    /**
     * function called on click of setup in PSLTable sets the row being mapped in state and calls onToggleBoard
     * @param {*} row
     */
    edit=(row, isSubmitButtonText = false)=>{
        /** this block of code is copied from old pss mapping */
        //if the leaf has been previously mapped before, take the data from the mapped 
        let _this = this;
        let mappedLines = _this.pslSectionRef.pslTableRef.state.mappedLines;
        //if the leaf has been previously mapped before, take the data from the mapped list
        let mappedRow = mappedLines.filter(e=>e[_costKey] === row[_costKey]);
        row[PS_MAPPING.FIELDS.DESCRIPTION] = mappedRow.length > 0 ? mappedRow[0][PS_MAPPING.FIELDS.DESCRIPTION]: row[PS_MAPPING.FIELDS.DESCRIPTION];
        let pslLine = mappedRow.length > 0  ? mappedRow[0] : {};
        let cost_term_id =  mappedRow.length > 0  ? mappedRow[0][PS_MAPPING.FIELDS.COST_TERM_ID] : "";

        //fetching the leading PSS id
        let leadingMappedPSLine = mappedLines.filter(e=>e[_costKey] === row[_costKey])[0];
        var leadingPssId = undefined;
        var leadingPssCostKey = undefined;
        var leadingPsLine = undefined;
        if (leadingMappedPSLine) {
            leadingPssId = leadingMappedPSLine[_leadingID];
            leadingPssCostKey = leadingMappedPSLine[_leadingCostKey];
            leadingPsLine = leadingPssId ? mappedLines.filter(e=>e[_id] === leadingPssId)[0] : undefined;
        }
        let fieldsCombination = leadingPsLine ?  leadingPsLine[_combinations] : mappedRow && mappedRow.length > 0 && mappedRow[0][_combinations] ? mappedRow[0][_combinations] : [];
        let calculatedAssignedAmountCount = _this.calculateAssignedAmount(fieldsCombination);

        let unusedCalculatedCols = copyObjectValues(_this.state.calculatedCols);
        let pssSiblings = leadingPssId ? getSiblings(leadingPssId, mappedLines) : [];      //fetching siblings of leading PS Line
        let exceptionPSLOptions = [];
        let psLeaves = getTreeLeaves(_this.pslSectionRef.pslTableRef.tabulator.getData());

        psLeaves.map(line=>{
            let mappedLeaf = findOptionByKeyValue(mappedLines, _costKey, line[_costKey]);
            let isMappedForCC = false;  //this var indicates if the line was added to mapped lines only for toggling the cost center
            if(mappedLeaf && mappedLeaf[_combinations] && !mappedLeaf[_combinations].length && mappedLeaf[_mappingException] === NONE && mappedLeaf[_id] === mappedLeaf[_leadingID] && !mappedLeaf[PS_MAPPING.EXCEPTION_FIELDS.PERCENTAGE]) {
                isMappedForCC = true;
            }

            let isSibling = findOptionByKeyValue(pssSiblings, _costKey, line[_costKey]);
            let isWeird = [costtype.calculated, costtype.attribute].includes(line[_costType]);    //it's also not standard :)
            if(!isSibling && mappedLeaf && mappedLeaf[PS_MAPPING.EXCEPTION_FIELDS.CALCULATED_COL]) {                //if this line is mapped, check if it has a calculated col, if it does, remove it from list of unusued cols
                let tempIndex = findIndexOfValue(unusedCalculatedCols, CALCULATED_COLUMNS.FIELDS.NAME, mappedLeaf[PS_MAPPING.EXCEPTION_FIELDS.CALCULATED_COL]);
                if(tempIndex !== -1) {
                    unusedCalculatedCols.splice(tempIndex, 1);
                }
            }
            if((!!isSibling || !mappedLeaf || isMappedForCC) && isMappable(line) && !isWeird) {
                //if it is not mapped before, or is mapped and is a sibling, add it to leaves for psl options
                line = mappedLeaf || line;   //if mapped line exists (sibling), use it
                line.value = mappedLeaf ? mappedLeaf[_returnName] : line[_returnName];
                line.label = mappedLeaf ? mappedLeaf[_name] : line[_name];

                exceptionPSLOptions.push(copyObjectValues(line));

            }
        });

        //create PS line if not found in list of mapped lines
        pslLine = Object.getOwnPropertyNames(pslLine).length > 0 ? pslLine : {
                [_id]: row[_id], [_costKey]: row[_costKey], [_parentCostKey]: row[_parentCostKey],
                [_name]: row[_name],[_returnName]: row[_returnName],[_costType]: row[_costType],
                [_mapFormula]: row[_mapFormula], [_combinations]: fieldsCombination,[_percentage]: "",
                [PS_MAPPING.FIELDS.RAW_FILE_FIELD_NAME]: "",[PS_MAPPING.FIELDS.MAPPING_EXCEPTION]: NONE,
                [PS_MAPPING.EXCEPTION_FIELDS.ANCILLARY_FILTER]: "",[PS_MAPPING.FIELDS.COST_TERM_ID]: cost_term_id,
                [_deleted]: _false,
                [PS_MAPPING.FIELDS.DESCRIPTION] : row[PS_MAPPING.FIELDS.DESCRIPTION],
                [PS_MAPPING.FIELDS.ATTR_TYPE] : row[PS_MAPPING.FIELDS.ATTR_TYPE],
                [PS_MAPPING.FIELDS.ATTRIBUTE_FUNCTION] : row[PS_MAPPING.FIELDS.ATTRIBUTE_FUNCTION],
                [_attribute] : row[_attribute],

            };

        let calculatedProfitStackFields = this.setProfitStackFields(_this.pslSectionRef.pslTableRef.tabulator.getData(),row);

        _this.setState({
            currentRow:row,
            calculatedAssignedAmountCount:calculatedAssignedAmountCount,
            profitStackFields:_this.pslSectionRef.pslTableRef.tabulator.getData(),
            calculatedProfitStackFields:calculatedProfitStackFields,
            glType: _all,
            pslLine: pslLine || leadingPsLine,
            mappedLine:  copyObjectValues(pslLine),
            pssSiblings: pssSiblings,
            leadingPssId: !leadingPssId ?  row[_id] : leadingPssId,
            leadingPssCostKey: !leadingPssCostKey ?  row[_costKey] : leadingPssCostKey,
            exceptionPSLOptions: exceptionPSLOptions,
            costTerm: leadingPsLine ? leadingPsLine[PS_MAPPING.FIELDS.COST_TERM_ID] : pslLine ? pslLine[PS_MAPPING.FIELDS.COST_TERM_ID] : "",
            unusedCalculatedCols: unusedCalculatedCols,
            isNameValid: "",
            isSubmitButtonText: isSubmitButtonText
        },()=>{
                // _this.onToggleBoard();
                _this.drawerToggleClickHandler();
        })
    }

    setProfitStackFields = (data,rowToExclude) => {
        let tempData = copyObjectValues(data);
        tempData = tempData.filter(e=>![costtype.attribute].includes(e[_costType]))//remove attribute lines from profit stack fields
        tempData = tempData.filter(e=>e[_id] !== rowToExclude[_id]);// exclude calculated line your are setting up in profit stack fields

        tempData = this.setupData(tempData); // add some required properties such as isExpandable,isExpanded,etc..

        const getAllNewLines = (arr,result=[]) => {
            for(var e in arr){
                if(arr[e][_costKey].endsWith("Insert")){
                    result.push(arr[e])
                }
                if(arr[e][_children]){
                    getAllNewLines(arr[e][_children],result);
                }
            }
            return result;
        }
        let newLines = getAllNewLines(tempData);
        for(var e in newLines){
            newLines[e][_costKey] = newLines[e][_costKey].replace("Insert","");
        }

        this.addNumber(tempData); //add number beside each psl line
        return tempData;
    }

    setupData(data, level=0) {
        for (let index in data) {
            let element = data[index];
            element[_level] = level;
            element[_isExpandable] = !!element[_children] && element[_children].length > 0;
            element[_isExpanded] = false;   //all parents are collapsed by default
            element[_isInSearchResult] = true;      //show all elements by default

            if(!!element[_children]) {
                this.setupData(element[_children], level + 1);   //increase the level for children
            }
        }

        return data;
    }

    addNumber(input) {
        var counter = 0;

        function rec(data) {
            if(data){
                data.forEach(function(e) {
                    if (typeof e === 'object' && !Array.isArray(e)) {
                        e.number = counter++;
                        for (var p in e) {
                            if (typeof e[p] === 'object'){
                                rec(e[p]);
                            } 
                        }
                    }
                })
            }
        }
        rec(input)
    }

    /**
     * function resets the inputs in sidePanel
     */
    resetInputs=()=>{
        let _this = this;
        _this.setState({
            isNameValid: "",
            currentRow: undefined,
        },function(){
            // _this.onToggleBoard();
        });
    }

    /**
     * this function check if the name is unique
     * @param {*} name
     * @param {*} data
     * @param {*} costKey
     * @param {*} oldValue
     * @returns
     */
    checkNameExists=(name, data, costKey, oldValue)=>{
        for(var e in data) {
            if (data[e]){
                if(data[e][PS_MAPPING.FIELDS.NAME].toLowerCase().replace(/ /g,"" ) === name.toLowerCase().replace(/ /g,"" ) && data[e].costKey !== costKey){
                    return 1;
                }
            }
        }
    }
    /**
     * fnuction validates if new name is valid or not
     * @param {*} name
     * @param {*} costKey
     * @param {*} oldValue
     * @returns
     */
    validateName=(name, costKey, oldValue)=>{
        let _this = this;
        let firstChar = name[0];
        if(!isNaN(Number(firstChar))) {
            return lang.name_starting_number;
        }
        if (name.replace(/ /g,'') === "") {
            return lang.manage_access.name_empty;
        }
        let nameExists = _this.excContainer?_this.excContainer.checkExistingName(name, _this.pslSectionRef.pslTableRef.tabulator.getData(), costKey, oldValue) : _this.checkNameExists(name, _this.pslSectionRef.pslTableRef.tabulator.getData(), costKey, oldValue);
        if (nameExists === 1){
             return lang.name_used;
        } else if (nameExists === -1){
            return  lang.name_reserved;
        }
        return "";
    }

    /**
     * function copied from old code... finalizes the mappedLines state and reated unmatched if not found and deletes it if its leading was deleted
     * @param {*} mappedLines
     * @param {*} newLeadingPssId
     * @param {*} newLeadingCostKey
     * @param {*} removedIds
     */
    setMappedLines(mappedLines, newLeadingPssId, newLeadingCostKey, removedIds) {
        let _this = this;
        let tempState = {};
        let myVar;
        // _this.onToggleBoard();
        clearTimeout(myVar);
        myVar = setTimeout(function(){
            tempState.toBeDeleted = _this.pslSectionRef.pslTableRef.state.toBeDeleted;
            let data = _this.pslSectionRef.pslTableRef.tabulator.getData();
            let originalMappedLines = _this.pslSectionRef.pslTableRef.state.mappedLines;
            let unmatchedFound = true;
            let matchedLine = {};
            let costKeys = _this.pslSectionRef.pslTableRef.state.costkeys;
            
            //Get old leading names that are used in the new setup (The new setup might not contain any line that was leading before)
            let matchedSiblingsFromNewMappedLines = mappedLines.length ? mappedLines.filter(l => l[PS_MAPPING.FIELDS.PSS_ID] !== newLeadingPssId && l[PS_MAPPING.FIELDS.LEADING_PSS_ID] === newLeadingPssId) : "";
            let matchedSiblingsFromNewMappedLinesPssIds = matchedSiblingsFromNewMappedLines.length ? matchedSiblingsFromNewMappedLines.map(l=> l[PS_MAPPING.FIELDS.PSS_ID]) : [];
            let oldLeadingLines = originalMappedLines.filter(l=> matchedSiblingsFromNewMappedLinesPssIds.includes(l[PS_MAPPING.FIELDS.PSS_ID]) && l[PS_MAPPING.FIELDS.PSS_ID] == l[PS_MAPPING.FIELDS.LEADING_PSS_ID])
            let oldLeadingLineNames = oldLeadingLines.length ? oldLeadingLines.map(l=> l[PS_MAPPING.FIELDS.NAME]) : [];
            
            let ids = _this.pslSectionRef.pslTableRef.state.ids;
            let oldLeadingCostKey = _this.state.pslLine[_costKey];
            let oldUnmatchedCostKeys = mappedLines.filter(e=>e.pssLeadingCostKey === oldLeadingCostKey && e[_isMatched] !== undefined && !e[_isMatched]); //This will return 2 variances if the line was split percentage by ancillary then you decided to change a matched line (not leading) to be transaction
            if(oldUnmatchedCostKeys.length > 1){
                // Delete the unmatched line that has a costkey (New unmatched line will not have a costkey yet)
                oldUnmatchedCostKeys = oldUnmatchedCostKeys.filter(e=> !!e[_costKey]); 
                mappedLines.filter(e=>e[_costKey] === oldUnmatchedCostKeys[0][_costKey]).map(line => {
                    line[_deleted] = true;
                }); //Remove old unmatched line from mappedLines
                prune(data,_children,_costKey,oldUnmatchedCostKeys[0][_costKey]);//Remove old unmatched line from psl table
                tempState.toBeDeleted.push(oldUnmatchedCostKeys[0][_costKey]); //To delete it from profit stack structure if it was saved
            }
            
            if (newLeadingPssId) {      // leading has been removed
                if (originalMappedLines.filter(e=>e[PS_MAPPING.FIELDS.COST_KEY] === oldLeadingCostKey && e[_deleted] ===_false ).length === 0) { // if it has been mapped before then name should stay as is
                    renameMatched(data, oldLeadingCostKey, false);       //renames line mapped to ancillary to name 
                }
            } else {
                newLeadingPssId = _this.state.leadingPssId;
                newLeadingCostKey = _this.state.leadingPssCostKey;
            }
            //renaming deleted lines
            if(removedIds && removedIds.length) {
                for (var e in mappedLines) {
                    if (removedIds.includes(mappedLines[e][_id])) {
                        renameMatched(data, mappedLines[e][_costKey], false);
                    }
                }
            }
            // if matched line doesn't have an unmatched line; an unmatched line should be added
            let level = "";
            var unmatchedline = mappedLines.filter(e=>parseBoolean(e[_isMatched]) === false && e[_leadingID] && e[_leadingID] === newLeadingPssId && e[_deleted] === _false)[0];
            if (unmatchedline) {
                unmatchedFound = getEmbeddedChild(data, _children, _costKey, unmatchedline[_costKey]);
                matchedLine =  getEmbeddedChild(data, _children, _id,newLeadingPssId);
                if(matchedLine && matchedLine !== null){
                    level = matchedLine.level;
                }
            }
            if (!unmatchedFound) {
                var newCostKey = generateUniqueCostKey(costKeys);
                var newId = generateUniqueIdDB(ids);
                var name = unmatchedline[_name].includes(_variance) ? unmatchedline[_name] : unmatchedline[_name] + _variance;
                if(unmatchedline[_name] === _variance){
                    name = mappedLines.filter(e=>e.pssId === unmatchedline.pssLeadingId)[0].name + _variance
                }
               
                var newRow = {
                    [_name] : name,[_costKey]: newCostKey+"Insert" ,
                    [_parentCostKey]: unmatchedline[_parentCostKey], "new": "1",
                    level: level === "" ? unmatchedline["level"] :level,[PS_MAPPING.FIELDS.FILTER]: "", // !check if unmatchedLine has level
                    [_combinations]:[],stagingQuery:"",[_mappingException]: NONE,fileName: "",
                    [_costCenter]: _all,isNotAC: false,[_deleted]: _false,
                    [_returnName]: _this.state.pslLine[_returnName] + replaceSpecialChars(_variance),
                    [_id]: newId.toString(),[ROW_STATUS.FIELD]:ROW_STATUS.VALUES.NEW,
                    [_leadingID]: newLeadingPssId, [_leadingCostKey]: newLeadingCostKey, [_costType]: costtype.standard
                };
                if (_this.state.pslLine[_parentCostKey] === '201' || unmatchedline[_parentCostKey]) {
                    data.push(newRow);
                    data = reOrderLine(data, newLeadingCostKey, newRow);
                } else {
                    addManualChild(unmatchedline.parentCostKey, data, unmatchedline[_name], newLeadingCostKey, newRow); // adds unmatched line to pss
                }
                mappedLines.map(function(item){
                    if (item[_name] === unmatchedline[_name] && item[_deleted] === _false ) {
                        item[_costKey] = newCostKey.toString()+"Insert";
                        item[_id] = newId.toString();
                        item.fieldsCombination= [];
                    }
                })
            }
            //should add mappedSiblings = mappedLines.filter(line => line[_leadingID] === newLeadingPssId) -- and use it instead, not done now bc of patch
            mappedLines.filter(l => l[_leadingID] === newLeadingPssId).forEach(line =>{
                let shouldRename = line[_isMatched] && line[_mappingException] !== NONE && (originalMappedLines.filter(e=>e[PS_MAPPING.FIELDS.COST_KEY] === line[_costKey] && e[_deleted] === _false ).length === 0) ;
                let shouldAddSuffix = line[_mappingException] === NONE ? false : true;    //if none, remove suffix if already there, else do not add it
                if(shouldRename) {
                    renameMatched(data, line[_costKey], shouldAddSuffix); // renames line mapped to ancillary to name + (matched)
                } else if(line[_mappingException] === NONE) {
                    renameMatched(data, line[_costKey], false); // remove the suffixes from the line's name
                }
                if ((line[_deleted] === _true && (line[_isMatched] === false && line[_name].includes(_variance)) || line[PS_MAPPING.FIELDS.ACTUAL_ID] && line[PS_MAPPING.FIELDS.ACTUAL_ID] !== "")) {
                    // if line is not matched and doesn't contain variance then we shouldn't be deleting it from ps table
                    if (line[_isMatched] === false  && line[_name].includes(_variance)) {
                        let leadingLine = originalMappedLines.filter(elt=>elt[_costKey] === line[_leadingCostKey]);
                        let oldLeadingLine = originalMappedLines.filter(elt=>elt[_costKey] === oldLeadingCostKey);
                        if ((oldLeadingLine && oldLeadingLine[0][_name] === line[_name].replace(_variance,""))||(leadingLine && leadingLine[0][_name] === line[_name].replace(_variance,""))) {
                            data = updateDeletedProfitStackFields(data, line[_costKey], "");
                            if(tempState.toBeDeleted){
                                tempState.toBeDeleted.push(line[_costKey]);
                            }
                        }
                    }else{
                        data = updateDeletedProfitStackFields(data, line[_costKey], "");
                        if(tempState.toBeDeleted){
                            tempState.toBeDeleted.push(line[_costKey]);
                        }
                    }
                    
                }
            });
            //deleting accrual line
            for(var leaf in mappedLines) {
                var accrual = mappedLines.filter(l => l[_leadingID] === newLeadingPssId && l[PS_MAPPING.FIELDS.PSS_ID] === mappedLines[leaf][PS_MAPPING.FIELDS.ACTUAL_ID]);
                if (tempState.toBeDeleted && mappedLines[leaf].deleted === _true && accrual && accrual.length > 0) {
                    data = updateDeletedProfitStackFields(data, mappedLines[leaf][_costKey], "");
                    tempState.toBeDeleted.push(mappedLines[leaf][_costKey]);
                }
            }
            // if matched line has been mapped to non ancillary; unmatched line should be deleted and matched line should be renamed
            var line = mappedLines.filter(e=>e[_costKey]  === newLeadingCostKey)[0];
            var unMatchedCostKey = "";
            let unmatchedName = "";
            if(line && parseBoolean(line[_isMatched]) && line[_deleted] === _true ) {
                mappedLines.map(function(item){ // matched line no longer mapped 
                    if (item[_costKey] === line[_costKey]) {
                        item[_name] = item[_name];
                        item[_leadingID] = item[_id];
                        item[_leadingCostKey] = item[_costKey];
                    }
                    if (parseBoolean(item[_isMatched]) === false && item[_leadingID] === line[_leadingID] && (line[_leadingID] !== line[_id])) { // remove unmatched
                        unMatchedCostKey = item[_costKey];
                        unmatchedName = item[_name];
                        item[_deleted] = _true;
                    }
                    if (item[_leadingID] && item[_leadingID] === newLeadingPssId && parseBoolean(item[_isMatched]) === false) {
                        unMatchedCostKey = item[_costKey];
                        unmatchedName = item[_name];
                        item[_deleted] = _true;
                    }
                });
                if (unmatchedName.includes(_variance)) { // distinguish between automatically created unmatched line and selected unmatched line (selected unmatched line shouldnt be deleted when the matched is deleted or its exception driver has beem change) 
                    data = updateDeletedProfitStackFields(data, unMatchedCostKey, "");
                    if(tempState.toBeDeleted){
                        tempState.toBeDeleted.push(unMatchedCostKey);
                    }
                }
                
            }
            //set row_status as 'edited' in data, for all mapped lines that have row_status = 'edited'
            let editedMappedLinesReturnNames = mappedLines.filter(line=>line[ROW_STATUS.FIELD] === ROW_STATUS.VALUES.EDITED && line[_deleted] === _false).map(line=>line[_costKey]);
            for(var retName in editedMappedLinesReturnNames) {
                let editedLine = findPslRow(data, _costKey, editedMappedLinesReturnNames[retName]);
                if(editedLine !== null){
                    editedLine[ROW_STATUS.FIELD] = ROW_STATUS.VALUES.EDITED;
                    data = updateChildAttr(data, editedLine[_costKey], [ROW_STATUS.FIELD], ROW_STATUS.VALUES.EDITED);
                }
            }
            var res = "";
            for (var e in mappedLines) {    //updating cost center and amounts
                if(mappedLines[e][_mappingException] === ANCILLARY) {
                    res = isDisabledToggleButton(mappedLines[e][PS_MAPPING.EXCEPTION_FIELDS.FILE], mappedLines[e], mappedLines, _this.props.allAncillaryColumns, _this.state.exceptionMetricsRaw);
                    mappedLines[e][_costCenter] = res === true ? 'ALL' : mappedLines[e][_costCenter];
                }
                let amount = mappedLines[e][_amount] ? mappedLines[e][_amount].toString() : "0";
                data = updateChildAttr(data, mappedLines[e][_costKey], [PS_MAPPING.FIELDS.AMOUNT], amount);
            }
            mappedLines = mappedLines.filter(function(elt){if (elt[_deleted] === _false) return elt;})
            mappedLines = sortMappedLines(mappedLines);
            _this.pslSectionRef.pslTableRef.setState({mappedLines: mappedLines},function(){
                _this.GLCombinationsref.assignLineFromSetup(mappedLines, _this.state.pslLine, oldLeadingLineNames);
                tempState.isNameValid = "";
                tempState.currentRow = undefined;
                _this.setState(tempState,()=>{
                    // commented for causing issues when  user sets up his line
                    let mappedLine = _this.state.mappedLine;
                    let leadingMappedLine = mappedLines.filter(e=>e.costKey === mappedLine.pssLeadingCostKey)[0] 
                    let subordinateMappedLines =  leadingMappedLine ? mappedLines.filter(e=>e.pssLeadingCostKey === mappedLine.pssLeadingCostKey) : [];
                    
                    if(_this.state.fetchAmounts && (( mappedLine && mappedLine.costCenter && mappedLine.costCenter !== 'ALL') || (leadingMappedLine && leadingMappedLine.costCenter && leadingMappedLine.costCenter !== 'ALL') || subordinateMappedLines.filter(e=>e.costCenter && e.costCenter !== 'ALL').length > 0)){
                        _this.pslSectionRef.pslTableRef.parseAmount(_this.props.getSelectedPeriods(), mappedLines);
                    }
                    _this.pslSectionRef.pslTableRef.tabulator.replaceData(data);
                    if(_this.pslSectionRef.pslTableRef.state.tabulatorFiltered) {
                        _this.pslSectionRef.pslTableRef.filterTableOnLinks();
                        _this.pslSectionRef.pslTableRef.filterTableOnLinks(matchedLine);
                    }
                });
            });
        } ,200) 
    }

    /**
     * function checks if there are filters applied in ancillary and calls fucnion to validate if there's inetrsection of metrics in ancillary lines of same leading ID
     * @param {*} siblings
     * @param {*} callbackMain
     */
    validateIntersectionFilterMetric=(siblings, callbackMain)=> {
        var files = [];
        var  filterLines = [];
        var obj = this;
        for (var e in siblings) {
            if (siblings[e][_isMatched] && siblings[e][_mappingException] === ANCILLARY && siblings[e][PS_MAPPING.EXCEPTION_FIELDS.FILE]) {
                if(siblings[e][_driverType] === _metric) {
                    files.push(siblings[e][PS_MAPPING.EXCEPTION_FIELDS.FILE])
                }
            }
        }
        for(var elt in files){
            var lines = siblings.filter(e=>e[PS_MAPPING.EXCEPTION_FIELDS.FILE] === files[elt] && e[_isMatched]);
            if (lines.length > 1) { // more than one line mapped to same file
                for (var i in lines) {
                    filterLines.push({subType:files[elt], field: lines[i][PS_MAPPING.FIELDS.RAW_FILE_FIELD_NAME], filter:lines[i].ancillaryFilter});
                }
            }
            if(filterLines.length > 0)
                break;
        }
        let callback = ()=>{};
        callback = function(result) {
            if (result === "FAIL") {
                callbackMain(false);
            } else{
                callbackMain(true);
            }
        }
        if (filterLines.length === 0) {
            callbackMain(true);
        } else {
            obj.validatePKsIntersectionMetric(filterLines, callback);
        }
    }
    /**
     * function vaidates intersection of PKs
     * @param {*} filterLines
     * @param {*} callback
     */
    validatePKsIntersection(filterLines, callback) {
        var query = {
            action: "validatePKsIntersection",
            scenario_id: this.props.scenarioId,
            filterLines: filterLines,
            timePeriod: this.props.getSelectedPeriods[0]
        }

        // if(!$(".loading").not(".uk-width-1-3").is(":visible")) {
            toggleLoader(true, "validatePKsIntersection");
        // }
        let onThenCallback = (data) => {
            toggleLoader(false, "validatePKsIntersection");
            if (data && data.result) {
                callback(data.result);
            } else {
                callback(true)
            }
        };
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "validatePKsIntersection",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
        };
        this.fetchAPI(fetchOptions);
    }

    /**
     * function validates if ancillary filters have intersection metrics
     * @param {*} filterLines
     * @param {*} callback
     */
    validatePKsIntersectionMetric=(filterLines, callback)=> {
        var query = {
            action: "validatePKsIntersectionMetric",
            scenario_id: this.props.scenarioId,
            filterLines: filterLines,
            timePeriod: this.props.getSelectedPeriods()[0]
        }

        // if(!$(".loading").not(".uk-width-1-3").is(":visible")) {
            toggleLoader(true, "validatePKsIntersectionMetric");
        // }
        let onThenCallback = (data) => {
            toggleLoader(false, "validatePKsIntersectionMetric");
            if (data && data.result) {
                callback(data.result);
            } else {
                callback(true);
            }
        };
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "validatePKsIntersectionMetric",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
        };
        this.fetchAPI(fetchOptions);
    }

    saveMappedCalculatedPSL(mappedLine, callbackClose) {
        let _this = this;
        let messages = [];
        let myVar;
        clearTimeout(myVar);
        if (_this.state.pslLine[PS_MAPPING.FIELDS.NAME] !== mappedLine[PS_MAPPING.FIELDS.NAME]) {
            messages[PS_MAPPING.FIELDS.NAME] =  _this.validateName(mappedLine[PS_MAPPING.FIELDS.NAME], mappedLine[_costKey],_this.state.pslLine[_name]);
            if (messages[PS_MAPPING.FIELDS.NAME] !== "") {
                _this.setWarning(messages[PS_MAPPING.FIELDS.NAME]);
                return;
            }
        }
        let mappedCK = mappedLine[_costKey];
        let mappedLines = copyObjectValues(_this.pslSectionRef.pslTableRef.state.mappedLines);
        let mapIndex = findIndexOfValue(mappedLines, _costKey, mappedCK);
        mappedLine[ROW_STATUS.FIELD] = ROW_STATUS.VALUES.EDITED;
        if(mapIndex > -1) {
            //already mapped before, update the line in mappedLines array
            mappedLines[mapIndex] = Object.assign({}, mappedLines[mapIndex], mappedLine);
        } else {
            //not mapped before, add it to the mappedLines array
            mappedLines.push(mappedLine);
        }
        _this.pslSectionRef.pslTableRef.setState({mappedLines: mappedLines},function(){
            myVar = setTimeout(function(){
                let tempData = updateChildAttr(_this.pslSectionRef.pslTableRef.tabulator.getData(), mappedLine[_costKey], [_name], mappedLine[_name]);
                _this.pslSectionRef.pslTableRef.tabulator.replaceData(tempData);
                // _this.onToggleBoard();
                if(typeof callbackClose === "function"){
                    callbackClose();
                }
            },200)

        });

    }

    startSaveMappedCalculatedPSL(mappedLine,callbackClose){
        if(!this.calcContainer.calculatedPslRef.formulaDRef.validateFormulaResult(mappedLine[_mapFormula])) {
            this.setInfoDialogOpen(true, lang.formula.invalid_formula)
            return;
        }
        this.saveMappedCalculatedPSL(mappedLine,callbackClose);
        this.pslSectionRef.pslTableRef.tabulator.setData(this.pslSectionRef.pslTableRef.tabulator.getData());
    }

    setWarning=(message)=> {
        this.setState({
            isNameValid: message
        })
    }

    setInfoDialogOpen = (isOpen, msg) => {
      let _this = this;
      _this.setState({
        openInfoDialog: isOpen,
        infoMsg: msg
      })
    }
    /**
     * set changed state to false and sets the invalidate state to true or false (only combinations changes won't set the invalidate to false)
     */
    setNotSavedWarning=(changed, invalidated, psl)=>{
        let _this = this;
        if (psl) {
            _this.pslSectionRef.pslTableRef.setState({
                isChanged: true,
            });
        }
        _this.setState({
            invalidated: _this.state.invalidated ? true : invalidated
        },function(){
            _this.state.isChanged = changed;
            _this.props.setNotSavedWarning(changed);
        });
    }

    /**
     * this function is to save the attribute profit stack line, it fetches when clicking on the save button of the side panel
     * @returns
     */
    saveAttribute=(callbackClose)=>{
        let _this = this;
        let messages = [];
        let mappedLiness = [];
        let myVar;
        clearTimeout(myVar);
        messages[PS_MAPPING.FIELDS.NAME] =  _this.validateName( _this.state.mappedLine[PS_MAPPING.FIELDS.NAME],  _this.state.mappedLine[_costKey], _this.state.currentRow[_name]);
        if (messages[PS_MAPPING.FIELDS.NAME] !== "") {
            _this.setWarning(messages[PS_MAPPING.FIELDS.NAME]);
            return;
        }

        let data = copyObjectValues(_this.pslSectionRef.pslTableRef.tabulator.getData());
        let row = _this.state.mappedLine;

        let attributerow = data.filter(e=> e.costKey === row.costKey)[0];
        // let objMapped = _this.pslSectionRef.pslTableRef.state.mappedLines.filter(e => _this.pslSectionRef.pslTableRef.state.profitStackLineToMap && e[_costKey] === _this.pslSectionRef.pslTableRef.state.profitStackLineToMap[_costKey]);

        attributerow[_attribute] = row[_attribute].value? row[_attribute].value: row[_attribute];

        attributerow[_attributeFunction] = row[_attributeFunction];
        attributerow[_attributeType] = row[_attributeType];
        attributerow[_deleted] = "false";
        attributerow[_costType] = PS_MAPPING.FIELDS.ATTRIBUTE;
        attributerow[_name] = row[PS_MAPPING.FIELDS.NAME];
        attributerow[ROW_STATUS.FIELD] = ROW_STATUS.VALUES.EDITED;
        attributerow[PS_MAPPING.FIELDS.DESCRIPTION] = this.state.mappedLine[PS_MAPPING.FIELDS.DESCRIPTION];
        let mappedCK = attributerow[_costKey];
        mappedLiness = _this.pslSectionRef.pslTableRef.state.mappedLines;
        let mapIndex = findIndexOfValue(mappedLiness, _costKey, mappedCK);

        if(mapIndex > -1) {
            //already mapped before, update the line in mappedLines array
            mappedLiness[mapIndex] = Object.assign({}, mappedLiness[mapIndex], attributerow);
        } else {
            //not mapped before, add it to the mappedLines array
            mappedLiness.push(attributerow);
        }

        _this.pslSectionRef.pslTableRef.setState({
            isChanged: true,
            mappedLines: sortMappedLines(mappedLiness),
            profitStackLineToMap: row,
            onElementChange: false,
            onFunctionChange: false
        }, function(){
            _this.setNotSavedWarning(true)
                myVar = setTimeout(function(){
                    let tempData = updateChildAttr(_this.pslSectionRef.pslTableRef.tabulator.getData(), attributerow[_costKey], [_name], attributerow[_name]);
                    tempData = updateChildAttr(tempData, attributerow[_costKey], ['attribute'], attributerow['attribute']);
                    _this.pslSectionRef.pslTableRef.tabulator.replaceData(tempData);
                    // _this.onToggleBoard();
                    if( typeof callbackClose === "function"){
                        callbackClose();
                    }
                    _this.props.disableHeaderButtons(false);
                    // PI-28344: fixed the attribute dragging issue by refreshing the tabulator original data after setting an "attribute" to the attribute
                    _this.pslSectionRef.pslTableRef.setState({
                        originalData: _this.pslSectionRef.pslTableRef.tabulator.getData()
                    })
                    _this.setState({
                        profitStackFields: _this.pslSectionRef.pslTableRef.tabulator.getData()
                    })
                }, 200);

        });
    }

    /**
     * saves Accrued Line description and name
     * @returns 
     */
    saveAccrualLine(){
        let _this = this;
        let messages = [];
        let mappedLiness = [];
        let myVar;
        let row =  _this.excContainer.state.line;
        clearTimeout(myVar);
        messages[PS_MAPPING.FIELDS.NAME] =  _this.validateName( row[PS_MAPPING.FIELDS.NAME],  _this.state.mappedLine[_costKey], _this.state.currentRow[_name]);
        if (messages[PS_MAPPING.FIELDS.NAME] !== "") {
            _this.excContainer.setWarning(messages[PS_MAPPING.FIELDS.NAME]);
            return;
        }
        let data = copyObjectValues(_this.pslSectionRef.pslTableRef.tabulator.getData());

        let mappedRow = retrieveRowByAttr([row[_costKey]], data, _costKey);
        // let objMapped = _this.pslSectionRef.pslTableRef.state.mappedLines.filter(e => _this.pslSectionRef.pslTableRef.state.profitStackLineToMap && e[_costKey] === _this.pslSectionRef.pslTableRef.state.profitStackLineToMap[_costKey]);
        mappedRow[_name] = row[PS_MAPPING.FIELDS.NAME];
        mappedRow[PS_MAPPING.FIELDS.DESCRIPTION] = row[PS_MAPPING.FIELDS.DESCRIPTION];
        mappedRow[ROW_STATUS.FIELD] = ROW_STATUS.VALUES.EDITED;
        let mappedCK = mappedRow[_costKey];
        mappedLiness = _this.pslSectionRef.pslTableRef.state.mappedLines;
        let mapIndex = findIndexOfValue(mappedLiness, _costKey, mappedCK);
        if(mapIndex > -1) {
            //already mapped before, update the line in mappedLines array
            mappedLiness[mapIndex] = Object.assign({}, mappedLiness[mapIndex], mappedRow);
        } else {
            //not mapped before, add it to the mappedLines array
            mappedLiness.push(mappedRow);
        }
        _this.pslSectionRef.pslTableRef.setState({
            isChanged: true,
            mappedLines: sortMappedLines(mappedLiness),
            profitStackLineToMap: row,
        }, function(){
            _this.setNotSavedWarning(true);
                myVar = setTimeout(function(){
                    let tempData = updateChildAttr(_this.pslSectionRef.pslTableRef.tabulator.getData(), mappedRow[_costKey], [_name], mappedRow[_name]);
                    tempData = updateChildAttr(data, mappedRow[_costKey], [ROW_STATUS.FIELD], ROW_STATUS.VALUES.EDITED);
                    _this.pslSectionRef.pslTableRef.tabulator.replaceData(tempData);
                    // _this.onToggleBoard();
                    _this.props.disableHeaderButtons(false);
                    _this.setState({
                        profitStackFields: _this.pslSectionRef.pslTableRef.tabulator.getData()
                    })
                }, 200);
        });
    }

    // If you setup the line and name it from the setup and not outside, the variance psl name in the dialog after submitting should be [leadingPSLName] Variance 
    fixVarianceNameIfNeeded = (siblings) => {
        let leadingPSL = siblings.filter(sibling => sibling.pssId === sibling.pssLeadingId);
        for(let sibling in siblings) {
            if(siblings[sibling][_isMatched] !== undefined && !parseBoolean(siblings[sibling][_isMatched]) && leadingPSL.length && siblings[sibling].name.trim() === _variance.trim()){
                siblings[sibling].name = leadingPSL[0].name + _variance;
            }
        }
    }

    /**
     * function save for now the name, description, plan tern and exception driver
     */
    startSave=(checked, callbackClose)=> {
        let _this = this;
        _this.setNotSavedWarning(true, true);
        let newLine = _this.excContainer? _this.excContainer.state.line:"";
        let combinationsData = this.GLCombinationsref.GLTableref.tabulator.getData();
        let newName = newLine? newLine[_name] : "";
        let oldName = _this.state.mappedLine[_name];
        if(newName !== oldName){
            if(combinationsData.filter(e=>e[PS_MAPPING.FIELDS.ASSIGNED_TO].includes(oldName))){
                combinationsData.filter(e=>e[PS_MAPPING.FIELDS.ASSIGNED_TO].includes(oldName)).forEach(combination =>{
                    if(combination[PS_MAPPING.FIELDS.ASSIGNED_TO].includes(oldName)){
                        for(var e in combination[PS_MAPPING.FIELDS.ASSIGNED_TO]){
                            if(combination[PS_MAPPING.FIELDS.ASSIGNED_TO][e] === oldName){
                                combination[PS_MAPPING.FIELDS.ASSIGNED_TO][e] = newName;
                                _this.GLCombinationsref.GLTableref.tabulator.updateRow(combination[PS_MAPPING.FIELDS.FIELDS_COMBINATION],combination);
                            }
                        }
                    }
                });
                _this.updateTotals(newName, oldName,undefined);
            }
        }


        if(_this.calcContainer){ //Is Calculated PSL
            let line = _this.calcContainer.state.line;
            this.startSaveMappedCalculatedPSL(line,callbackClose);
            return;
        }
        if(this.state.currentRow.costtype === PS_MAPPING.FIELDS.ATTRIBUTE){// Is attribute PSL
            _this.saveAttribute(callbackClose);
            return;
        }

        if (this.state.currentRow[PS_MAPPING.FIELDS.ACCRUAL_STATUS] ===  ACCRUALS.FIELDS.STATUS_VALUES.ACCRUAL) {
            _this.saveAccrualLine();
            return;
        }
        let callback = ()=>{};
        let messages = [];
        let newMappedLine = _this.excContainer? _this.excContainer.state.line:"";
        let mappedLines = _this.pslSectionRef.pslTableRef.state.mappedLines;
        if (_this.state.mappedLine[PS_MAPPING.FIELDS.NAME] !== newMappedLine[PS_MAPPING.FIELDS.NAME]) {
            messages[PS_MAPPING.FIELDS.NAME] =  _this.validateName(newMappedLine[PS_MAPPING.FIELDS.NAME], newMappedLine[_costKey], _this.state.mappedLine[_name]);
            if (messages[PS_MAPPING.FIELDS.NAME] !== "") {
                _this.setWarning(messages[PS_MAPPING.FIELDS.NAME]);
                return;
            }
        let tempData = updateChildAttr(_this.pslSectionRef.pslTableRef.tabulator.getData(), newMappedLine[_costKey], [_name], newMappedLine[_name]);
            // tempData = updateChildAttr(_this.pslSectionRef.pslTableRef.tabulator.getData(), newMappedLine[_costKey], [PS_MAPPING.FIELDS.DESCRIPTION], newMappedLine[PS_MAPPING.FIELDS.DESCRIPTION]);
        _this.pslSectionRef.pslTableRef.tabulator.replaceData(tempData);
        }
        let mappingException = newMappedLine[_mappingException];
        let mappingData = _this.excContainer.exclDriverRef.getUpdatedExceptionData();
        let siblings = mappingData.data;
        
    
        mappingException = siblings.filter(e=>e.pssId === newMappedLine.pssId).length  ? siblings.filter(e=>e.pssId === newMappedLine.pssId)[0][_mappingException] : newMappedLine[_mappingException]  ;
        
        let siblingMappedLine = siblings.filter(e=>e[_id] === newMappedLine[_id])[0];
        siblingMappedLine[_name] = newMappedLine[_name];
       
        this.fixVarianceNameIfNeeded(siblings);

        let fieldsCombination = _this.state.pslLine[_combinations];
        let removedIds = mappingData.deletedIds;
        callback = function(intersectionFilters, intersectionFiltersMetric) {
            if (!intersectionFilters && intersectionFilters !== undefined ) {
                _this.setInfoDialogOpen(true, lang.intersection_in_filters);
                return;
            }

            if (!intersectionFiltersMetric && intersectionFiltersMetric !== undefined ) {
                _this.setInfoDialogOpen(true, lang.intersection_in_filters);
                return;
            }
  
            let siblingValidation = _this.validateSiblings(siblings);
            if (siblingValidation.emptyVal) {
                _this.setInfoDialogOpen(true, lang.empty_mapped_values);
                return;
            }
            if (!siblingValidation.isMappedPresent) {
                _this.setInfoDialogOpen(true, lang.mapped_line_not_present);
                return;
            }
            if (!siblingValidation.percentagesSum) {
                _this.setInfoDialogOpen(true, lang.percentages_do_not_sum);
                return;
            }
            if (siblingValidation.has0Perc) {
                _this.setInfoDialogOpen(true, lang.percentages_0_perc);
                return;
            }

            let isMappedLineFound = siblings.filter(s => s[_name] === newMappedLine[_name])[0];
            if(!isMappedLineFound) {
                _this.setInfoDialogOpen(true, lang.mapped_line_deleted);
                return;
            }
            let leadingId = siblings[0][_leadingId];
            let leadingCostkey = siblings[0][_leadingCostKey];
            for(var e in siblings) {
                if (siblings[e][_id] === leadingId) { // setting edit flag 
                    siblings[e][ROW_STATUS.FIELD] = ROW_STATUS.VALUES.EDITED;
                    // siblings[e][PS_MAPPING.FIELDS.DESCRIPTION] = newMappedLine[PS_MAPPING.FIELDS.DESCRIPTION];
                    //PI-30986: below statement is wrong and was deleting the fields_combinations field from the leading PSS in case of saving a variance/sibling PSS during Setup, so it has been removed (commented for now)
                    //siblings[e][PS_MAPPING.FIELDS.FIELDS_COMBINATIONS] = JSON.parse(JSON.stringify(fieldsCombination));
                    if (mappingException === NONE) {
                        siblings[e][_isMatched] = undefined;
                        // siblings[e][PS_MAPPING.FIELDS.ACCRUAL_STATUS] = ACCRUALS.FIELDS.STATUS_VALUES.UNDEFINED; 
                    }
                } else { // changing leading id and costkey when changing from none to transaction
                    siblings[e][PS_MAPPING.FIELDS.FIELDS_COMBINATIONS] = [];
                    siblings[e][PS_MAPPING.FIELDS.LEADING_COSTKEY] = leadingCostkey;
                    siblings[e][PS_MAPPING.FIELDS.LEADING_PSS_ID] = leadingId;
                }
                if (siblings[e][PS_MAPPING.FIELDS.COST_KEY] === _this.state.mappedLine[PS_MAPPING.FIELDS.COST_KEY]) {
                    // siblings[e][PS_MAPPING.FIELDS.DESCRIPTION] = $("#metric_description").val();
                }
                if (siblings[e][_costKey] === newMappedLine[_costKey]) {
                    siblings[e][_name] = newMappedLine[_name];
                    siblings[e][_returnName] = newMappedLine[_name];
                    siblings[e][PS_MAPPING.FIELDS.DESCRIPTION]  = newMappedLine[PS_MAPPING.FIELDS.DESCRIPTION];
                }
                siblings[e][ROW_STATUS.FIELD] = ROW_STATUS.VALUES.EDITED;
                if(!siblings[e][_isMatched]) { //For variance line take mapping exception of leading psl 
                    siblings[e][_mappingException] = mappingException;
                }
                siblings[e][PS_MAPPING.FIELDS.COST_TERM_ID] = newMappedLine.cost_term_id;
                siblings[e][_costType] = costtype.standard;
                
            }
            if(removedIds && removedIds.length) {
                for (var e in mappedLines) {
                    if (removedIds.includes(mappedLines[e][_id])) {
                        mappedLines[e][PS_MAPPING.FIELDS.DELETED] = _true; // setting deleted flag to true for deleted lines
                        mappedLines[e][_leadingId] = leadingId; // setting their new leadingID in case it was changed
                        mappedLines[e][_leadingCostKey] = leadingCostkey;
                        mappedLines[e][PS_MAPPING.FIELDS.FIELDS_COMBINATIONS] = [];
                        mappedLines[e][_mappingException] = NONE;
                        mappedLines[e][PS_MAPPING.EXCEPTION_FIELDS.ANCILLARY_FILTER] = "";
                        mappedLines[e][PS_MAPPING.EXCEPTION_FIELDS.CALCULATED_COL] = "";
                    }
                }
            }
            // delete accrual line
            if (mappingException === TRANSACTION) {
                for (var e in mappedLines) {
                    if (mappedLines[e][PS_MAPPING.FIELDS.ACTUAL_ID] && mappedLines[e][PS_MAPPING.FIELDS.ACTUAL_ID] !== ""  && siblings.filter(row=>row[PS_MAPPING.FIELDS.ID] == mappedLines[e][PS_MAPPING.FIELDS.ACTUAL_ID]).length > 0) {
                        mappedLines[e][PS_MAPPING.FIELDS.DELETED] = _true; // setting deleted flag to true for deleted lines
                    }
                }
            }
            let newMappedLines = mappedLines.filter(function(el){ //removing siblings
                if((el[_leadingCostKey] !== _this.state.pslLine[_costKey] || el[_leadingCostKey] === _this.state.pslLine[_costKey] && el[PS_MAPPING.FIELDS.DELETED] === _true )
                    && !findOptionByKeyValue(siblings, _costKey, el[_costKey])) {   //a mapped line might also be present in siblings if mapped by cost center first before being mapped to cpombinations
                    return el;
                }
            });
            let toAppend =[];
            let names = [];
            for(var e in mappedLines) {
                for (var elt in siblings) {
                    if (mappedLines[e][PS_MAPPING.FIELDS.ACTUAL_ID] && mappedLines[e][PS_MAPPING.FIELDS.ACTUAL_ID] == siblings[elt][PS_MAPPING.FIELDS.ID] && mappedLines[e][PS_MAPPING.FIELDS.ACTUAL_ID] !== "" && !names.includes(mappedLines[e].name)) {
                        toAppend.push(mappedLines[e]);
                        names.push(mappedLines[e].name);
                    }
                }
                if (mappedLines[e][PS_MAPPING.FIELDS.ACTUAL_ID] === newMappedLine[_id]) {
                    mappedLines[e][ROW_STATUS.FIELD] = ROW_STATUS.VALUES.EDITED;
                }
            }
            let sib =   copyObjectValues(siblings.concat(toAppend));
            newMappedLines = newMappedLines.concat(siblings);
            _this.setState({
                leadingId: leadingId,
                leadingCostKey: leadingCostkey,
                mappedLinesRef: newMappedLines,
                pssSiblings:sib,
                profitStackLines: siblings.concat(toAppend),
                removedIds: removedIds,
                glData: combinationsData
            });
            if(oldName !== newName){
                _this.updateCombinationsTable(newMappedLines,[])
            }
            _this.setOpenMappingChangesDialog(true)
        }
        if(this.excContainer.exclDriverRef.isTransaction) {
            callback();
        } else {
            let funcCallBack = ()=>{};
            funcCallBack = function(intersectionFiltersMetric) {
                _this.validateIntersectionFilter(siblings,intersectionFiltersMetric, callback);
            }
            _this.validateIntersectionFilterMetric(siblings, funcCallBack)
        }
    }

    /**
     * function called on click of done in modal in side panel
     */
    callSaveMappedAncillary=()=> {
        var _this = this;
        let myVar;
        _this.setOpenMappingChangesDialog(false);
        clearTimeout(myVar);
        myVar = setTimeout(function(){
            if (!_this.state.leadingId) {
                _this.setMappedLines(_this.state.mappedLinesRef, undefined, undefined, _this.state.removedIds);
            } else {
                _this.setMappedLines(_this.state.mappedLinesRef, _this.state.leadingId, _this.state.leadingCostKey, _this.state.removedIds);
            }
        },200)  
        _this.backdropClickHandler();   
   }

    /**
     * copied from old code
     * @param {*} siblings
     * @returns
     */
    validateSiblings(siblings) {
        var _this = this;
        var validation = {emptyVal: false, percentagesSum: false, has0Perc: false,
            isMappedPresent: siblings.filter(row=>row[_id] === _this.state.mappedLine[_id]).length > 0
        }
        let percSum = 0;
        for(var e in siblings) {
            let flag = false;   //use to check if any cond is true
            if (siblings[e][_isMatched] && siblings[e][_mappingException] !== NONE) {
                if(_this.excContainer.exclDriverRef && _this.excContainer.exclDriverRef.isSplitPerc) {
                    if ((this.state.fetchAmounts &&!is_aN(siblings[e][_amount])) || !is_aN(siblings[e][_percentage]) ||
                        !siblings[e][PS_MAPPING.FIELDS.NAME]
                        || ((!siblings[e][PS_MAPPING.EXCEPTION_FIELDS.FILE] || !siblings[e][PS_MAPPING.FIELDS.RAW_FILE_FIELD_NAME]) && siblings[e][_id] === _this.excContainer.exclDriverRef.state.leadingPssID)) {
                        validation.emptyVal = true;
                    }
                } else {
                    let transColumn = siblings[e][PS_MAPPING.EXCEPTION_FIELDS.FILE] || siblings[e][PS_MAPPING.EXCEPTION_FIELDS.CALCULATED_COL];
                    let isTransactionInvalid = siblings[e][_mappingException] === TRANSACTION && !transColumn;
                    let isAncillaryInvalid = siblings[e][_mappingException] === ANCILLARY && (!siblings[e][PS_MAPPING.EXCEPTION_FIELDS.FILE] || !siblings[e][PS_MAPPING.FIELDS.RAW_FILE_FIELD_NAME]);
                    if ((this.state.fetchAmounts && !is_aN(siblings[e][_amount])) || !siblings[e][PS_MAPPING.FIELDS.NAME] || 
                    (_this.excContainer.exclDriverRef && _this.excContainer.exclDriverRef.isOther && (isAncillaryInvalid || isTransactionInvalid))) {
                        validation.emptyVal = true;
                    }
                }

                flag = true;
            } else if (siblings[e][_mappingException] === NONE){
                if (!siblings[e][PS_MAPPING.FIELDS.NAME]) {
                    validation.emptyVal = true;
                }
                flag = true;
                if(siblings[e][_isMatched] !== undefined && !siblings[e][_isMatched]){
                    flag = false;
                }
            }
            let perc = !isNaN(siblings[e][_percentage]) && siblings[e][_percentage]!== "" ? parseFloat(siblings[e][_percentage]) : 0;
            if(flag) {
                if(perc === 0 && _this.excContainer.exclDriverRef.isSplitPerc) {
                    validation.has0Perc = true;
                }
                percSum += perc;
            }
        }
        if(_this.excContainer.exclDriverRef && _this.excContainer.exclDriverRef.isSplitPerc) {
            validation.percentagesSum = percSum === 100;
        } else {
            validation.percentagesSum = true;
        }
        return validation;
    }

    /**
     * function validates if there's intersection in filters of ancillary lines belonging to same leading id
     * @param {*} siblings
     * @param {*} intersectionFiltersMetric
     * @param {*} callbackMain
     */
    validateIntersectionFilter=(siblings, intersectionFiltersMetric, callbackMain)=> {
        var files = [];
        var  filterLines = [];
        var obj = this;
        for (var e in siblings) {
            if (siblings[e][_isMatched] && siblings[e][_mappingException] === ANCILLARY && siblings[e][PS_MAPPING.EXCEPTION_FIELDS.FILE]) {
                if(siblings[e][_driverType] !== _metric) {
                    files.push(siblings[e][PS_MAPPING.EXCEPTION_FIELDS.FILE]);
                }
            }
        }
        for(var elt in files){
            var lines = siblings.filter(e=>e[PS_MAPPING.EXCEPTION_FIELDS.FILE] === files[elt] && e[_isMatched]);
            if (lines.length > 1) { // more than one line mapped to same file
                for (var i in lines) {
                    filterLines.push({subType:files[elt], field: lines[i][PS_MAPPING.FIELDS.RAW_FILE_FIELD_NAME], filter:lines[i].ancillaryFilter});
                }
            }
            if(filterLines.length > 0)
                break;
        }
        let callback = ()=>{};
        callback = function(result) {
            if (result === "FAIL") {
                callbackMain(false,intersectionFiltersMetric);
            } else{
                callbackMain(true, intersectionFiltersMetric);
            }
        }
        if (filterLines.length === 0) {
            callbackMain(true, intersectionFiltersMetric);
        } else {
            obj.validatePKsIntersection(filterLines, callback);
        }
    }

    /**
     * called on clicking on cancel in SidePanel
     */
    startCancel=(callback)=>{
        this.resetInputs();
        if(typeof callback === "function"){
            callback();
        }
    }

    fetchDataForMapping=(fetchAmounts)=>{
        let _this = this;
        let query = {
            action: "fetchDataForMapping",
            selectedPeriods: fetchAmounts ? this.props.getSelectedPeriods(): [],
            scenario_id: this.props.scenarioId,
            fetchAmounts:fetchAmounts
        }
        let onThenCallback = (data) => {
            let arr = data.metrics;
            let exceptionMetrics = [];
            for(var e in arr) {
                exceptionMetrics.push({label:arr[e].metric_display_name , value: arr[e].metric, type:_metric, is_cost_center: arr[e][METRICS_MAPPING.FIELDS.COST_CENTER],
                                        [RAW_ITEMS.SUBTYPE_NAME]: arr[e].metric, [RAW_ITEMS.SUBTYPE_ID]: arr[e].metric, period_status:arr[e].period_status,metric_id:arr[e].metric_id});
                arr[e][_metric_configuration] = typeof arr[e][_metric_configuration] === "string" ? JSON.parse(arr[e][_metric_configuration]) : arr[e][_metric_configuration];
            }
            let calculatedColumns = [];
            for (var e in data.calculatedColumns) {
                calculatedColumns.push({label:data.calculatedColumns[e][CALCULATED_COLUMNS.FIELDS.DISPLAY_NAME] , value:data.calculatedColumns[e][CALCULATED_COLUMNS.FIELDS.DISPLAY_NAME],
                                        usedValue:data.calculatedColumns[e][CALCULATED_COLUMNS.FIELDS.FORMULA],[RAW_ITEMS.FIELD_DATA_TYPE] :Formats.Numeric,
                                       [RAW_ITEMS.SUBTYPE_NAME]: _calcCols, [RAW_ITEMS.SUBTYPE_NAME]: _calcCols, [RAW_ITEMS.FIELD_DATA_TYPE]: FormatTypes.NUMERIC.toUpperCase()
                                    });
            }
            let calculatedCols = data.calculatedColumns.map(cc=>{
                                    cc.label = cc[CALCULATED_COLUMNS.FIELDS.DISPLAY_NAME];
                                    cc.value = cc[CALCULATED_COLUMNS.FIELDS.NAME];
                                    cc[RAW_ITEMS.SUBTYPE_NAME]= _calcCols;
                                    cc[RAW_ITEMS.RAW_FILE_SUBTYPE_ID]= _calcCols;
                                    cc[RAW_ITEMS.FIELD_DATA_TYPE]= FormatTypes.NUMERIC.toUpperCase();
                                    return cc;
            });
            let ancillaryColsAmount = data.ancillaryColsAmount;
            if(!this.state.fetchAmounts){
                ancillaryColsAmount = ancillaryColsAmount.map(e => {
                    e.amount = 0;
                    return e;
                })
            }

            let vectorList = [];
            for (var i in data.vectors) {
                vectorList.push({value:data.vectors[i][_vectorName], label:data.vectors[i][_displayName],
                [RAW_ITEMS.FIELD_DATA_TYPE]: Formats.String, [RAW_ITEMS.SUBTYPE_NAME]: PS_MAPPING.ATTRIBUTE_COLUMNS.VECTORS});
            }
            let exceptionMetricsAmount = exceptionMetrics;
            let metricsInfo = copyObjectValues(exceptionMetrics);
            if (data && data.metricsStagedAmount) {
                for(var i in exceptionMetrics) {
                    if(!this.state.fetchAmounts){
                        exceptionMetricsAmount[i].amount = 0;
                    } else {
                        var currMetric = data.metricsStagedAmount.filter(e => e.metric === exceptionMetricsAmount[i].file_type);
                        exceptionMetricsAmount[i].amount = currMetric.length > 0 ? currMetric[0].amount : 0;
                    }
                    
                }
            }

            let ancillaryFiles = [];
            data.ancillaryFiles.map(function(item) {
                ancillaryFiles.push({label: item[METRICS_MAPPING.FIELDS.RAW_FILE_DISPLAY_SUBTYPE], value: item[METRICS_MAPPING.FIELDS.RAW_FILE_SUBTYPE], raw_file_subtype_id: item[RAW_ITEMS.SUBTYPE_ID], type:"ancillary"});
            });

            _this.setState({
                calculatedCols: calculatedCols,
                exceptionMetrics: exceptionMetricsAmount,
                calculatedColsAmount: data.calculatedColumns,
                calculatedColumns: calculatedColumns,
                exceptionMetricsRaw: exceptionMetrics,
                metricsInfo:metricsInfo,
                ancillaryColsAmount: data.ancillaryColsAmount,
                vectors: data.vectors,
                vectorList: vectorList,
                ancillaryFiles: ancillaryFiles,
                ancillaryColumns: data.columns,
                flippedCombinations: data.flippedCombinations,
                missingPeriodFiles: data.calculatedColsFilesByPeriod.filter(item => item.count === 0).map(item => {return item[CALCULATED_COLUMNS.FIELDS.NAME]}),
            });
        };

        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "fetchDataForMapping",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.periods]:fetchAmounts ? this.props.getSelectedPeriods(): [],
            [FETCHAPI_PARAMS.screenName]:lang.observability.stage.profit_stack_mapping.screen_name,
            [FETCHAPI_PARAMS.requestDescription]:lang.observability.stage.profit_stack_mapping.requests_description.fetch_data_for_mapping

        };
        this.fetchAPI(fetchOptions);
    }


    /**
     * this function renders the body of the side panel based on the type of the psline that we are setting up
     * @returns
     */
    renderSidePanel=()=>{
        return (
            <div key={shortid.generate()}>
            {this.state.currentRow && ![costtype.calculated,costtype.attribute].includes(this.state.currentRow[_costType])&&
                <ExceptionDriverContainer
                    ref={el=>this.excContainer = el}
                    currentRow={this.state.currentRow}
                    ancillaryFiles={this.state.ancillaryFiles}
                    ancillaryColumns={this.state.ancillaryColsAmount}
                    pssSiblings={this.state.pssSiblings || []}
                    exceptionPSLOptions={this.state.exceptionPSLOptions  || []}
                    leadingLine={this.state.pslLine}
                    mappedLine={this.state.mappedLine}
                    isVar={this.state.isVar}
                    scenario={this.props.scenarioId}
                    leadingPssId={this.state.leadingPssId}
                    leadingPssCostKey={this.state.leadingPssCostKey}
                    pslLine={this.state.pslLine}
                    calculatedAssignedAmountCount={this.state.calculatedAssignedAmountCount}
                    allAncillaryColumns={this.props.allAncillaryColumns.concat(this.state.exceptionMetrics)}
                    clientCostTerms={this.props.clientCostTerms}
                    costTerm={this.props.costTerm}
                    costCenter = {this.props.costCenter}
                    calculatedCols={this.state.unusedCalculatedCols}
                    exceptionMetrics={this.state.exceptionMetrics}
                    metricsInfo={this.state.metricsInfo}
                    timePeriod={this.props.getSelectedPeriods()[0]}
                    mappedLines={this.pslSectionRef.pslTableRef.state.mappedLines}
                    getSelectedPeriods = {this.props.getSelectedPeriods}
                    periodDetails={this.props.periodDetails}
                    profitStackFields={this.state.profitStackFields}
                    stagingReport = {this.props.stagingReport}
                    isNameValid={this.state.isNameValid}
                    fetchAmounts = {this.state.fetchAmounts}
                />
             }
            {this.state.currentRow && this.state.currentRow.costtype === PS_MAPPING.FIELDS.ATTRIBUTE && //setup an attribute line
                <AttributeSetupContainer ref={el=>this.attrContainer = el} isNameValid={this.state.isNameValid} currentRow={this.state.currentRow} mappedLine={this.state.mappedLine} onElementChange={this.state.onElementChange}
                calculatedColumns={this.state.calculatedColumns} vectorList={this.state.vectorList} enableSave={this.enableSave}
                mappedLines={this.pslSectionRef.pslTableRef.state.mappedLines} profitStackLineToMap={this.pslSectionRef.pslTableRef.state.profitStackLineToMap}/>
            }
             {this.state.currentRow && [costtype.calculated].includes(this.state.currentRow[_costType]) &&
                <CalculatedSetupContainer
                     ref={el=>this.calcContainer = el}
                    calculatedProfitStackFields = {this.state.calculatedProfitStackFields}
                    currentRow={this.state.currentRow}
                    mappedLine={this.state.mappedLine}
                    pslLine={this.state.pslLine}
                    isNameValid ={this.state.isNameValid}
                />
              }
            </div>
		)
    }

    enableSave=()=>{
        this.setState({
            saveEnabled: true
        })
    }

    /**
     * this function is called from GLcombinations (updateAssignedStatus, flipSign functions), and when setting or clearing a filter (glFilter or psFilter) to reset the checked combinations
     */
    resetCheckedCombinations=(data, skipCheckAll)=>{
        let _this = this;
        _this.setState({
            checked_combinations: [],
            glData: data,
        });
        _this.GLCombinationsref.GLTableref.setState({
            checked_combinations: []
        })
        _this.pslSectionRef.pslTableRef.setState({
            checked_combinations: []
        })
        if(!skipCheckAll){
            _this.checkAllLines("uncheck");
        }
        _this.GLCombinationsref.GLTableref.unCheckCheckAll();
        _this.GLCombinationsref.GLTableref.addFooterText(data);
        _this.sendCheckedLinesToPSLTable([]);
    }


    /**
     * when cheking a line, this function send the checked_combinations to the PSLTable
     * @param {*} checked_combinations
     */
    sendCheckedLinesToPSLTable=(checked_combinations)=>{
        let _this = this;
        _this.refreshPSLTable(checked_combinations);
        _this.setState({
            checked_combinations: checked_combinations
        });
    }
    
    refreshPSLTable(checked_combinations) {
        $(".assignToIcon").css("display", checked_combinations.length > 0 ? "block" : "none");
        $("#PSLTable").removeClass("disableDrag");
        if(checked_combinations.length > 0) {
            $("#PSLTable").addClass("disableDrag");
        }
    }

    /**
     * this function is to replace the main parent name with the new inherited name in the assigned to field
     * @param {*} rowData
     * @param {*} parentName
     */
    inheritAssignTo=(rowData, parentName)=>{
        this.GLCombinationsref.inheritAssignTo(rowData, parentName);
    }


    /**
     * when clicking on "plus" to assign new combination, this function update the assigned status
     * @param {*} pss_action
     * @param {*} rowData
     */
    updateAssignedStatus=(pss_action,row,cell)=>{
        let _this = this;
        let newMappedLines = [];
        cell.getRow().getData()[ROW_STATUS.FIELD] = ROW_STATUS.VALUES.EDITED;
        let mappedLines = _this.pslSectionRef.pslTableRef.state.mappedLines;
        if (mappedLines.filter(e=>e[_costKey] === row[_costKey]).length === 0 ) { //adding new mapped line if the line we're assigning hasn't been mapped before
            newMappedLines =  mappedLines.concat(
               [{
                    [_id]: row[_id], [_costKey]: row[_costKey], [_parentCostKey]: row[_parentCostKey],
                    [_name]: row[_name],[_returnName]: row[_returnName],[_costType]: row[_costType],
                    [_mapFormula]: row[_mapFormula], [_combinations]: [],[_percentage]: "",
                    [PS_MAPPING.FIELDS.RAW_FILE_FIELD_NAME]: "",[PS_MAPPING.FIELDS.MAPPING_EXCEPTION]: NONE,
                    [PS_MAPPING.EXCEPTION_FIELDS.ANCILLARY_FILTER]: "",[PS_MAPPING.FIELDS.COST_TERM_ID]: "",
                    [_deleted]: _false, [_costCenter]: _all, [_leadingCostKey]: row[_costKey] ,
                     [PS_MAPPING.FIELDS.LEADING_PSS_ID]: row[_id]
                }]
            );
        }
        cell.getRow().update({ [ROW_STATUS.FIELD]: ROW_STATUS.VALUES.EDITED});
        _this.pslSectionRef.pslTableRef.setState({
            mappedLines:newMappedLines.length > 0 ? newMappedLines : mappedLines,
            isChanged: true
        },function(){
            _this.props.setNotSavedWarning(true, false);
            let broke = _this.GLCombinationsref.updateAssignedStatus(pss_action,row);
            if(broke !== "true") 
                _this.sendCheckedLinesToPSLTable([]);
        })

    }

    customFilter(data, filterParams){
        // let assigned_to_str = data[PS_MAPPING.FIELDS.ASSIGNED_TO].join(","); //this is because assigned to might contain multiple psl lines
        let assigned_to_str =  data[PS_MAPPING.FIELDS.ASSIGNED_TO]
        return assigned_to_str.some(r=> filterParams.data.includes(r));
    }

    /**
     * function updates the totals in totals table belowe GL Table and totals in psl Table
     * it's called after updating the GL combinatiions (assign, unassign, exclude) from updateAssignedStatus in GL Combinations component
     */
    updateTotals=(newName, oldName,data)=>{

        let _this = this;
        let allFilters = [];
        if(_this.GLCombinationsref.GLTableref.tabulator.getFilters().length > 0){
            allFilters = _this.GLCombinationsref.GLTableref.tabulator.getFilters()
            _this.GLCombinationsref.GLTableref.tabulator.clearFilter();
        }
        let result  = data ? data : _this.GLCombinationsref.GLTableref.tabulator.getData()
        _this.totalsRef.updateData(result, _this.props.getSelectedPeriods());
        if(allFilters.length > 0){
            let pssFilter = allFilters.filter(e=> e.field.valueOf("key") === _this.customFilter);
            if(pssFilter && newName && oldName && newName !== oldName){
                pssFilter.forEach(filter =>{
                    if(filter.type.data){
                        for(var e in filter.type.data){
                            if(filter.type.data[e] === oldName){
                                filter.type.data[e] = newName;
                            }
                        }
                    }
                })
            }
            if(pssFilter.length > 0) {
                _this.GLCombinationsref.GLTableref.tabulator.setFilter(_this.customFilter,{data:pssFilter[0].type.data});
            }
            let sourceGLFilter = allFilters.filter(e=> e.field.valueOf("key") !== _this.customFilter); //glsource filter
            if(sourceGLFilter.length > 0){
                let finalFilter = sourceGLFilter.concat( _this.GLCombinationsref.GLTableref.tabulator.getFilters()); // combining glsource filter with pss filter
                _this.GLCombinationsref.GLTableref.tabulator.setFilter(finalFilter);
            }
        }
    }

    /**
     * function copied from old code removed unmapped line from mapped lines
     * on exlude or assign, this function removes the excecluded or the unassigned line from fields combination
     * @param {*} line
     */
    removeLineFromMappedLines=(lines, mappedLines, selectedRow)=>{
        let _this = this;
        let leadingCK;
        let fieldsCombinationsByCostKeys = _this.state.fieldsCombinationsByCostKeys;
        let checked_combinations = copyObjectValues(_this.state.checked_combinations);
        for(var combination in checked_combinations){
            for (var line in mappedLines) {
                if(mappedLines && mappedLines[line] && mappedLines[line][PS_MAPPING.FIELDS.FIELDS_COMBINATIONS]){
                    let index = mappedLines[line][PS_MAPPING.FIELDS.FIELDS_COMBINATIONS].indexOf(checked_combinations[combination][PS_MAPPING.FIELDS.FIELDS_COMBINATION]);// if exists, don't add it
                    if (index>-1) {
                        mappedLines[line][ROW_STATUS.FIELD] = ROW_STATUS.VALUES.EDITED;
                        leadingCK = mappedLines[line][_leadingCostKey];
                        mappedLines[line][PS_MAPPING.FIELDS.FIELDS_COMBINATIONS].splice(index, 1);
                        fieldsCombinationsByCostKeys.push({costKey:leadingCK, field_combination:checked_combinations[combination][PS_MAPPING.FIELDS.FIELDS_COMBINATION]})
                    }
                }
                if (mappedLines[line][_costKey] === selectedRow[_costKey] || mappedLines[line][_leadingCostKey] === selectedRow[_costKey]) {
                    mappedLines[line][ROW_STATUS.FIELD] = ROW_STATUS.VALUES.EDITED;
                }
            }
            for (var line in mappedLines) {
                if(mappedLines[line][_leadingCostKey] === leadingCK){
                    mappedLines[line][ROW_STATUS.FIELD] = ROW_STATUS.VALUES.EDITED;
                }
            }
        }

        for (var e in lines) {
            fieldsCombinationsByCostKeys.push({costKey:"", field_combination:lines[e][PS_MAPPING.FIELDS.FIELDS_COMBINATION]});

        }
        return mappedLines;
    }

    /**
     * this function take a row, and return it leading costkey
     * @param {*} selectedRow
     * @returns
     */
    getLeadingCostKey=(selectedRow)=>{
        let _this = this;
        let leadingcostKey ="";
        let mappedLines =  _this.pslSectionRef.pslTableRef.state.mappedLines;
        let selectedLine = mappedLines.filter(e=>e.costKey === selectedRow.costKey);
        if(selectedLine[0]){
            if(selectedLine[0].costKey === selectedLine[0].pssLeadingCostKey) {// if leading
                leadingcostKey= selectedLine[0].costKey;
            }else{ // not leading
                leadingcostKey = selectedLine[0].pssLeadingCostKey;
            }
        }
        return leadingcostKey
    }

    /**
     * return state.mappedLines
     * @returns
     */
    getMappedLines=()=>{
        let _this = this;
        return _this.pslSectionRef.pslTableRef.state.mappedLines;
    }

    /**
     * push new filed combination in the mappedlines
     * @param {*} checked_combinations
     * @param {*} selectedRow
     */
    assignLine=(checked_combinations, selectedRow, mappedLines)=>{
        let _this = this;
        let id;
        let fieldsCombinationsByCostKeys = _this.state.fieldsCombinationsByCostKeys;
        let leadingCostKey = _this.getLeadingCostKey(selectedRow);
        leadingCostKey = leadingCostKey === "" ? selectedRow[_costKey] : leadingCostKey; 
        for (var line in mappedLines) {
            for (var combination in checked_combinations){
                if (((mappedLines[line]  && mappedLines[line][_id] === selectedRow[PS_MAPPING.FIELDS.ACTUAL_ID]) || mappedLines[line] && mappedLines[line].costKey === leadingCostKey) && !mappedLines[line][PS_MAPPING.FIELDS.FIELDS_COMBINATIONS].includes(checked_combinations[combination][PS_MAPPING.FIELDS.FIELDS_COMBINATION]) && !mappedLines[line][PS_MAPPING.FIELDS.ACTUAL_ID]) {
                    mappedLines[line][PS_MAPPING.FIELDS.FIELDS_COMBINATIONS].push(checked_combinations[combination][PS_MAPPING.FIELDS.FIELDS_COMBINATION]);
                    mappedLines[line][ROW_STATUS.FIELD] = ROW_STATUS.VALUES.EDITED;
                    id = mappedLines[line][_id];
                    fieldsCombinationsByCostKeys.push({costKey: mappedLines[line][_costKey], field_combination:checked_combinations[combination][PS_MAPPING.FIELDS.FIELDS_COMBINATION]})
                }
                if (mappedLines[line][PS_MAPPING.FIELDS.ACTUAL_ID] === id) {
                    mappedLines[line][ROW_STATUS.FIELD] = ROW_STATUS.VALUES.EDITED;
                }
            }
        }
        return mappedLines;
    }
   
    /**
     * functions setsMappedLines called from updateAssignedStatus in GLCombinations
     * @param {*} mappedLines
     */
    setMappedLinesInPsl=(mappedLines)=>{
        let _this = this;
        // Some subtypes and rawFileFieldName are undefined which cause null pointer exception on api
        mappedLines.map(e=> {
            if(!e.subType){
                e.subType = "";
            }
            if(!e.rawFileFieldName){
                e.rawFileFieldName = "";
            }
        })
        // let data = _this.pslSectionRef.pslTableRef.tabulator.getData();
        _this.pslSectionRef.pslTableRef.setState({
            mappedLines: mappedLines,
            isChanged:true
        },function(){
            _this.props.setNotSavedWarning(true, false);
        });
        if(this.state.fetchAmounts) {
            _this.pslSectionRef.pslTableRef.parseAmount(_this.props.getSelectedPeriods(), mappedLines)
        } else {
            _this.pslSectionRef.pslTableRef.tabulator.replaceData(_this.pslSectionRef.pslTableRef.tabulator.getData());
        }
        for (var e in mappedLines) {
            if (mappedLines[e][ROW_STATUS.FIELD] === ROW_STATUS.VALUES.EDITED) {
                // data = updateChildAttr(data, mappedLines[e][_costKey], [ROW_STATUS.FIELD], ROW_STATUS.VALUES.EDITED);
                updateChildAttr2(_this.pslSectionRef.pslTableRef.tabulator, _this.pslSectionRef.pslTableRef.tabulator.getRows(), mappedLines[e][_costKey], [ROW_STATUS.FIELD], ROW_STATUS.VALUES.EDITED);
            }
        }
    }

    /**
     * copied from profitStackMapping
     * @param {*} data
     * @returns
     */
    checkEmptyCells(data) {
        for(var e in data) {
            if(data[e][_name].replace(/ /g,"" ) === '' && data[e][ROW_STATUS.FIELD] !== ROW_STATUS.VALUES.DELETED) {
                return 1;
            } else{
                if(data[e][_children]) {
                    if(this.checkEmptyCells(data[e][_children].filter((item) => item[ROW_STATUS.FIELD] !== ROW_STATUS.VALUES.DELETED)) !== 1) {
                        continue;
                    } else{
                        return 1;
                    }
                }
            }
        }
    }

    /**
     * copied from profit stack mapping
     */
    checkScenario=(isPeriod, callback)=>{
        let _this = this;
        _this.setOpenChangesNotSavedDialog(false)
        let not_emptyCells = _this.checkEmptyCells(_this.pslSectionRef.pslTableRef.tabulator.getData()) !== 1;
        let hasRedDot = $('div').hasClass('column-red-dot-psl');
        if ( not_emptyCells && !hasRedDot) {
            if(_this.props.scenarioStatus === SCENARIO_STATUS.SANDBOX){
                _this.updateScenario(isPeriod, callback);
            } else {
                this.createNewScenario(isPeriod, callback);
            }
        } else{
            _this.setState({
                warningMessage: !not_emptyCells && hasRedDot ?  lang.profit_stack_map_empty_name +" And "+ lang.profit_stack_map_red_dot : !not_emptyCells ? lang.profit_stack_map_empty_name : lang.profit_stack_map_red_dot ,
            },function(){
                _this.setNotSavedWarning(true);
                this.props.rollBack(this.state.fetchAmounts);
                this.setOpenWarningDialog(true)
                // _this.pslSectionRef.pslTableRef.expandAll();
            })
        }
    }
    /**
     * Preparing transition mapping data for profit stack mapping save
     * Here we are comparing between all old and new combinations and based on this comparison we are filling the edited\deleted combinations 
     * @param {*} originalGLData 
     * @param {*} newGLData 
     * @param {*} mappedLines 
     * @returns 
     */
    initializeTransitionMappingDataForSave = (originalGLData, newGLData, mappedLines) => {
        let transitionMappingData = []
        for(let c in newGLData){
            let newCombinationInfo = newGLData[c];
            let newAssignedTo = newCombinationInfo?.assigned_to;
            let oldCombinationInfo = originalGLData.filter( field => field.fields_combination === newCombinationInfo?.fields_combination);
           
            if(oldCombinationInfo.length){
                let oldAssignedTo = oldCombinationInfo[0]?.assigned_to;
                let mapping = {}
                
                if (!deepCompareObjects(newAssignedTo, oldAssignedTo)) {
                    if(newAssignedTo.includes(_unassigned)){ //If a combination became unassigned
                         mapping = {gl_string: newCombinationInfo.fields_combination, gl_string_status: ROW_STATUS.VALUES.DELETED}
                    } else if(newAssignedTo.includes(_excluded)){//If a combination became excluded
                        mapping = {gl_string:newCombinationInfo.fields_combination, gl_string_status: ROW_STATUS.VALUES.EDITED, is_excluded:true}
                    } else {
                        let newLeadingLine = mappedLines.filter(ml => newAssignedTo.includes(ml.name) && ml.pssId === ml.pssLeadingId); 
                        let oldLeadingLine = mappedLines.filter(ml => oldAssignedTo.includes(ml.name) && ml.pssId === ml.pssLeadingId);
                        let condition = (newLeadingLine.length && oldLeadingLine.length && newLeadingLine[0].name !== oldLeadingLine[0].name) 
                        || [_unassigned,_excluded].some(c => oldAssignedTo.includes(c)) || (newLeadingLine.length && !oldLeadingLine.length)
                        if(condition) { //If the leading line is different in both old and new gl string OR it was unassigned or exlcluded before assigning OR a mappedLine became parent
                            let costKey = newLeadingLine[0].costKey;
                            mapping = {gl_string: newCombinationInfo.fields_combination, cost_key: costKey, gl_string_status: ROW_STATUS.VALUES.EDITED}
                        }
                    }

                    if(mapping.gl_string){
                        transitionMappingData.push(mapping);
                    }
                }
            }
        }

        return transitionMappingData;
    }

    /**
     * Preparing mapped lines for profit stack mapping save 
     * @param {*} mappedLines 
     * @returns 
     */
    initializeMappedLinesDataForSave = (mappedLines) => {
        let mappedLinesReplica = copyObjectValues(mappedLines);
        let deletedMappedLines = this?.pslSectionRef?.pslTableRef?.state?.deletedMappedLines ? this?.pslSectionRef?.pslTableRef?.state?.deletedMappedLines : [];
        mappedLinesReplica = mappedLinesReplica.concat(deletedMappedLines);
        mappedLinesReplica = mappedLinesReplica.filter(ml => ml?.row_status === ROW_STATUS.VALUES.EDITED || (ml.deleted !== undefined && parseBoolean(ml.deleted)));
        mappedLinesReplica.forEach(mappedLine => { //Remove fieldsCombination from mappedLine
           delete mappedLine.fieldsCombination
        })
        return mappedLinesReplica;
    }


    /**
     * copied from profit stack mapping
     * @param {*} manageExclusions 1    2
     */
    updateScenario=(isPeriod, callback)=>{
        var obj = this;
        let actionToken = generateActionToken(10);
        if(obj.pslSectionRef.pslTableRef.tabulator.getFilters().length  > 0){
            obj.pslSectionRef.pslTableRef.tabulator.clearFilter();
            obj.pslSectionRef.pslTableRef.setState({tabulatorFiltered: false}, () => {
                obj.onClearFiltersClick()
            })
        }
        
        let newlyFlipped =  obj.GLCombinationsref.GLTableref.tabulator.getData().filter(e=>e.modified === true && (e.flipped === true || e.flipped === "true"));
        let newlyUnFlipped = obj.GLCombinationsref.GLTableref.tabulator.getData().filter(e=>e.modified === true && (e.flipped === false || e.flipped === "false"));

        let originallyFlipped = obj.state.flippedCombinations;

        newlyFlipped = newlyFlipped.filter(
            flipped =>
                !originallyFlipped.find(original => (original.raw_file_type_transition_id === flipped.raw_file_type_transition_id  && flipped.fields_combination  === original.fields_combination ) )
        );

        let flippedCombinations = copyObjectValues(originallyFlipped);

        let mappedLines = obj.pslSectionRef.pslTableRef.fixInfoForSPMatchedLines(obj.pslSectionRef.pslTableRef.state.mappedLines);
        
        var object = {
            profitStackFields:  JSON.stringify(obj.pslSectionRef.pslTableRef.tabulator.getData()),
            mappedLines:JSON.stringify(obj.initializeMappedLinesDataForSave(mappedLines)),
            transitionMapping: JSON.stringify(obj.initializeTransitionMappingDataForSave(obj.state.originalGlData,obj.state.glData,mappedLines)),
            deletedItems: JSON.stringify(obj.pslSectionRef.pslTableRef.state.toBeDeleted),
            fieldsCombinationCostKeys: obj.state.fieldsCombinationsByCostKeys,
            invalidated: obj.state.invalidated,
            deletedFlippedCombinations:JSON.stringify(newlyUnFlipped),
            flippedCombinations:JSON.stringify(newlyFlipped)
        };

        var query = {
            action: "updateScenario",
            scenario_id: this.props.scenarioId,
            prefix: this.props.prefix,
            scenarioSetting: JSON.stringify(object),
            scenarioSettingName: "profitStack",
            actionToken: actionToken
        }
        console.log("Update Scenario Action Token: " + actionToken);

        let onThenCallback = (result) => {
            if(tryParse(result).result === 'SUCCESS') {
                if(obj.pslSectionRef){
                    obj.pslSectionRef.pslTableRef.tabulator.clearFilter(true);
                    obj.pslSectionRef.pslTableRef.setState({
                        message: lang.saved, 
                        isError: false,
                        isChanged: false
                    },function (){
                        const newlyFlippedProjected = newlyFlipped
                            .map(( {raw_file_type_transition_id, fields_combination} ) =>  ({raw_file_type_transition_id, fields_combination}) )

                        // Update the flipped combinations according to the newly flipped and unflipped combinations
                        flippedCombinations = flippedCombinations.concat(newlyFlippedProjected)
                            .filter(flipped => !newlyUnFlipped.find(unflipped => (unflipped.raw_file_type_transition_id  === flipped.raw_file_type_transition_id  && flipped.fields_combination  === unflipped.fields_combination ) ));

                        // Refresh the flipped combination
                        obj.setState({ flippedCombinations: flippedCombinations });                            
                        if(typeof callback !== "function") { // When you save normally 
                            obj.props.setNotSavedWarning(false);
                            obj.props.fetchUsedAttributes(obj.props.scenarioId);
                            //We need to send those requests after save to get lastest psls row status or it will lead to issues when saving again since it cannot differentiate between new and edited lines (PI-28477)
                            obj.pslSectionRef.pslTableRef.getMappedLines("",obj.state.fetchAmounts, ()=>{
                                obj.pslSectionRef.pslTableRef.getProfitStackFields(true);
                            });
                            
                            obj.setState({
                                isChanged: false
                            })
                        }
                    });
                }
            } else { // In case of failure
                obj.pslSectionRef.pslTableRef.setState({ 
                    message: lang.something_went_wrong_while_save,
                    isError: true
                },function (){
                    if(callback && typeof callback === "function") { 
                        obj.pslSectionRef.pslTableRef.launchToast();
                    } else {
                        if(obj.props.fromStage){
                            obj.props.launchToast()
                        }else{
                            obj.pslSectionRef.pslTableRef.launchToast();
                        }
                    }
                });
                return;
            }
       }

       let onCompleteCallback = () => {
            obj.props.setActionToken(actionToken);
            if(obj.pslSectionRef && !callback && isPeriod){ //This is when you change a period before saving. It saves your changes then changes your period.
                obj.changePeriod();
            } else if(callback && typeof callback === "function") { //This is when going to a report before saving. It saves your changes then goes to a report
                obj.pslSectionRef.pslTableRef.launchToast();
                callback()
            }
            obj.pslSectionRef.pslTableRef.setState({
                deletedMappedLines:[]
            })
            obj.setState({ //After save set original gl data to take the updated gl data
                originalGlData:obj.state.glData
            })
       }


        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "updateScenario",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader] : true,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.onCompleteCallback]: onCompleteCallback,
            [FETCHAPI_PARAMS.screenName]:lang.observability.stage.profit_stack_mapping.screen_name,
            [FETCHAPI_PARAMS.requestDescription]:lang.observability.stage.profit_stack_mapping.requests_description.update_scenario
        }

        fetchAPI(fetchOptions);
    }

    /***
     * copied from profit stack mapping
     */
    createNewScenario(isPeriod, callback){
        var obj = this;
        let actionToken = generateActionToken(10);
        let newlyFlipped =  obj.GLCombinationsref.GLTableref.tabulator.getData().filter(e=>e.flipped === true || e.flipped === "true");
        let originallyFlipped = obj.state.flippedCombinations;
        let newlyUnFlipped = [];
        newlyFlipped.map(function(item, index){
            if (originallyFlipped.filter(e=>e.raw_file_type_transition_id === item.raw_file_type_transition_id).length >0) {
                newlyFlipped.splice(index,1);
            }
        })
        originallyFlipped.map(function(item, index){
            if (newlyFlipped.filter(e=>e.raw_file_type_transition_id === item.raw_file_type_transition_id).length ==0) {
                newlyUnFlipped.push(item);
            }
        })
        
        let mappedLines = obj.pslSectionRef.pslTableRef.state.mappedLines;
        var object = {
            profitStackFields: JSON.stringify(this.state.profitStackFields),
            mappedLines: JSON.stringify(mappedLines),
            deletedItems: JSON.stringify(this.state.toBeDeleted),
            exclusionFilter:  "{\"filter\":"+ JSON.stringify(this.state.filter) +"}",
            excludedAccounts: this.state.accountNumbers,
            accountsType: this.state.accountsType,
            fieldsCombinationCostKeys: obj.state.fieldsCombinationsByCostKeys,
            invalidated: obj.state.invalidated,
            deletedFlippedCombinations:JSON.stringify(newlyUnFlipped),
            flippedCombinations:JSON.stringify(newlyFlipped),
            transitionMapping: JSON.stringify(obj.initializeTransitionMappingDataForSave(obj.state.originalGlData, obj.state.glData, mappedLines)),
        };
        var query = {
            action: "createNewScenario",
            scenario_id: this.props.scenarioId,
            prefix: this.props.prefix,
            scenarioSetting: object,
            selectedStagingPeriods: this.props.selectedPeriod.value,
            scenarioSettingName: "profitStack",
            actionToken:actionToken
        }
        console.log("Create New Scenario Profit Stack Action Token: " + actionToken);
      

        let onThenCallback = () => {
            obj.pslSectionRef.pslTableRef.setState({
                message: lang.scenario_added_to_sandbox, 
                isError: false,
                isChanged: false
            },function (){
                if(obj.props.fromStage){
                    obj.props.launchToast()
                }else{
                    obj.pslSectionRef.pslTableRef.launchToast();
                }
              
            });
            return;
       }

       let onCompleteCallback = () => {
            if(callback && typeof callback === 'function') {
                callback(); // this callback is called when we save the scenario from unsaved changes dialog when changing report
                return;
            }
            obj.props.setActionToken(actionToken);

            if (!isPeriod) {
                obj.props.setStagingReport(ALL_WIDGETS.FIELDS.STAGE, true);
                obj.props.setNotSavedWarning(false);
            } else{
                obj.changePeriod();
            }
       }

        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "createNewScenario",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader] : true,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.onCompleteCallback]:onCompleteCallback,
            [FETCHAPI_PARAMS.screenName]:lang.observability.stage.profit_stack_mapping.screen_name,
            [FETCHAPI_PARAMS.requestDescription]:lang.observability.stage.profit_stack_mapping.requests_description.create_new_scenario
        }
        fetchAPI(fetchOptions);
    }

    /**
     * copied from old code
     * @param {*} rows
     * @param {*} expandAll
     */
    reExpandCollapsedRows(rows, expandAll) {
        let obj = this;
        let allRows = rows || obj.pslSectionRef.pslTableRef.tabulator.getRows();
        allRows.forEach(function(row) {
            let isExpanded = row._row.modules.dataTree.open;
            if((obj.pslSectionRef.pslTableRef.expandedRowsCostkeys.indexOf(row.getData()[_costKey]) !== -1 && (!isExpanded || obj.redrawTable)) ||
                (row.getData()[_costKey] && !isExpanded && expandAll )) {
                //!isExpanded - if row is already expanded, do not retry to expand
                //if redrawTable = true because if a row has been moved, it is expanded in tabulator data, but not in the table, so re-expand anyways
                row.treeExpand();
                obj.reExpandCollapsedRows(row.getTreeChildren(), expandAll);
            }
        })
    }

    /**
     * copied from old code
     * @returns
     */
    generateNewPssFilterStructure = () => {
        return this.pslSectionRef.pslTableRef.tabulator.getData();
    }

    /**
     * This function filters the PSLTable based on links
     * @returns
     */
    onLinkClick = (row) => {
        this.pslSectionRef.pslTableRef.filterTableOnLinks(row,true);
    }

    /**
     * This function clears the focus from the filtered row and enables the other rows
     * @returns
     */
    onClearFiltersClick = () => {
        let obj = this;
        obj.GLCombinationsref.GLTableref.setState({
            isClicked: false
        })
        obj.GLCombinationsref.GLTableref.disableOtherColumns()
    }

     /**
     * This function is executed when you delete any psl line and updates combinations
     * to unassigned in case the psl was deleted and these combinations was assigned to this psl
     * @returns
     */
    updateCombinationsTable = (mappedLines,toBeDeleted, newName, oldName) => {
        let _this = this;
        let pssFilterStructureData = copyObjectValues( _this.GLCombinationsref.state.pssFilterStructureData);
        let newPssFilterStructure = this.generateNewPssFilterStructure();
        let checkedItems = getEmbeddedChildren(pssFilterStructureData, _children, _checked, true);
        for (var e in checkedItems) {
            let item = getEmbeddedChild(newPssFilterStructure, _children, _id, checkedItems[e][_id]);
            if (item && item != null) {
                item[_checked] = true;
            }
        }
        newPssFilterStructure = newPssFilterStructure.filter(e=>e.row_status !== ROW_STATUS.VALUES.NEW);
        newPssFilterStructure = addElements(newPssFilterStructure, _children,_label,_name);
        if (pssFilterStructureData.length > 0) {
            pssFilterStructureData[2][_children] = newPssFilterStructure;
        }
        let combinationsData = this.GLCombinationsref.GLTableref.tabulator.getData();
        if(newName){
            if(combinationsData.filter(e=>e[PS_MAPPING.FIELDS.ASSIGNED_TO].includes(oldName))){
                combinationsData.filter(e=>e[PS_MAPPING.FIELDS.ASSIGNED_TO].includes(oldName)).forEach(combination =>{
                    if(combination[PS_MAPPING.FIELDS.ASSIGNED_TO].includes(oldName)){
                        for(var e in combination[PS_MAPPING.FIELDS.ASSIGNED_TO]){
                            if(combination[PS_MAPPING.FIELDS.ASSIGNED_TO][e] === oldName){
                                combination[PS_MAPPING.FIELDS.ASSIGNED_TO][e] = newName;
                            }
                        }
                    }
                });
            }
        }
        let filteredMappedLines = mappedLines.filter(e=>toBeDeleted.includes(e[_costKey]))
        if(filteredMappedLines.length > 0){
           let fieldsCombinations  = filteredMappedLines[0].fieldsCombination;
           for(var e in fieldsCombinations){
               let fieldsCombination =  fieldsCombinations[e];
               let row = combinationsData.filter(e=>e.fields_combination === fieldsCombination)[0]
               if(row){
                    row[PS_MAPPING.FIELDS.ASSIGNED_TO] = [];
                    row[PS_MAPPING.FIELDS.ASSIGNED_TO].push(PS_MAPPING.PSS_FILTER_LABELS.UNASSIGNED_LABEL);
               }
           }
        }
        this.setState({
            pssFilterStructureData:pssFilterStructureData,
            glData:combinationsData
       },function(){
            _this.GLCombinationsref.GLTableref.tabulator.replaceData(_this.state.glData).then(function(){
                _this.updateTotals(newName, oldName,undefined);
            });
       })
    }

    concatenateTotalsData =(data, totals)=> {
        for(var row in data) {
            let periodName = data[row].period_name;
            data[row][STAGE_FIELDS.STATUS_FIELD] = PERIOD_STATUS_ENUM[data[row][STAGE_FIELDS.STATUS_FIELD]];
            data[row][STAGE_FIELDS.PROFIT_STACK_STATUS_FIELD] = PERIOD_STATUS_ENUM[data[row][STAGE_FIELDS.PROFIT_STACK_STATUS_FIELD]];
            data[row][STAGE_FIELDS.METRIC_STATUS] = PERIOD_STATUS_ENUM[data[row][STAGE_FIELDS.METRIC_STATUS]];
            data[row][STAGE_FIELDS.VECTOR_STATUS] = PERIOD_STATUS_ENUM[data[row][STAGE_FIELDS.VECTOR_STATUS]];
            
            //get totals of this period
            for(var totalRow in totals) {
                totalRow = totals[totalRow];
                if(totalRow.time_periods === periodName) {
                    //append row of totals to the main row if period is matching
                    data[row] = {...data[row], ... totalRow};
                    break;
                }
            }
        }

        return data;
    }

    /**
     * new API request for stage data
     * @returns 
     */
    getStagingData=()=> {
        const obj = this;
        toggleLoader(true, "getStagingData");
        $(".stage-table #stage_button").each((key, e) => $(e).addClass("uk-button-disabled"));

        const query = {
            action: "getStagingData",
            scenario_id: this.props.scenarioId,
            periodsData: obj.props.periodList,
        }
setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
        fetch(`${baseUrl}${path}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(query)})
            .then((response)=>{
                if(response.status === 403) {
                    //this.logout();
                }
                return response.json()
            }).then((data) => {
                if (data.error) {
                  this.setInfoDialogOpen(true, data.error.message);
                } else {
                    data.totals = typeof data.totals === "string" &&  data.totals !== "" ? JSON.parse(data.totals) : data.totals;
                    let allData = this.concatenateTotalsData(data.data, data.totals);
                    let periods = data.data.map(row=>{
                        return {
                            label: row[STAGE_FIELDS.TIME_PERIOD_FIELD],
                            value: row[STAGE_FIELDS.TIME_PERIOD_FIELD],
                            period_name: row[STAGE_FIELDS.TIME_PERIOD_FIELD],
                            client_period_id: row[STAGE_FIELDS.PERIOD_ID],
                            count: row[STAGE_FIELDS.COUNT]
                        }
                    });

                    this.setState({
                        stagingData: allData,
                        stagingDataStatus: copyObjectValues(allData),
                        // isProfitStackMappingAllowed: data.isTransitionCombinationGenerated,
                        // isInvoiceLineQueryAdded: data.isInvoiceLineQueryAdded,
                        periods: periods
                    }, function() {
                        // this.props.setPeriodOptions(periods);
                        toggleLoader(false, "getStagingData");
                    });
                }
            }).catch((error)=>{
                alertAndLogError(error);
                toggleLoader(false, "getStagingData");
            });
    }

    /**
     *   checks if the chosen period in the current scenario is valid for staging
     * @returns 
     */
    isValidForStaging = () =>{
        let _this = this;
        let periodStagingStatus = this.props.stagingDataStatus;
        // let periods = _this.props.getSelectedPeriods();
        // let selectedPeriods = [];
        // let isValid = true;
        // This block of code is to show or hide the banner for the period currently selected
        // for (var e in periodStagingStatus) {
        //     if (periods[0] === periodStagingStatus[e].time_periods){
        //         selectedPeriods.push(periodStagingStatus[e]);
        //         break;
        //     }
        // }
        // for (var x in selectedPeriods) {
		// 	if ( !selectedPeriods[x][DEFINE_COSTCENTER.FIELDS.VALID_GL_STAGING] || !selectedPeriods[x][STAGE_FIELDS.ALL_GL_MAPPED] || /*!isCCStateDefined || */ 
		// 					(selectedPeriods[x][STAGE_FIELDS.METRIC_STATUS].toLowerCase() !== STAGE_FIELDS.STAGED && selectedPeriods[x]["metricsusedcount"] > 0)) {
		// 			return false;		
		// 	}
		// } 
        for (var x in periodStagingStatus) {
			if ( !periodStagingStatus[x][DEFINE_COSTCENTER.FIELDS.VALID_GL_STAGING] || !periodStagingStatus[x][STAGE_FIELDS.ALL_GL_MAPPED] || /*!isCCStateDefined || */ 
							(periodStagingStatus[x] && periodStagingStatus[x][STAGE_FIELDS.METRIC_STATUS] && periodStagingStatus[x][STAGE_FIELDS.METRIC_STATUS].toLowerCase() !== STAGE_FIELDS.STAGED && periodStagingStatus[x]["metricsusedcount"] > 0)) {
					return false;		
			}
		} 
		return true;
    }
    /**
     *   checks if any combination is still unassigned
     * @returns 
     */
    checkUnassignedLength = () =>{
        let _this = this;
        if(_this.GLCombinationsref && (_this.GLCombinationsref.GLTableref.tabulator.getData().filter(e=>e[PS_MAPPING.FIELDS.ASSIGNED_TO][0]=== _unassigned).length > 0
         ||(_this.totalsRef && _this.totalsRef.tabulator.getData() && _this.totalsRef.tabulator.getData().length >0 &&
            _this.totalsRef.tabulator.getData()[0]['unassigned_combinations']))){
            return true
        }
        return false
    }

    /**
     *   checks if any psline is still not mapped
     * @returns 
     */
    checkUnMappedLength = () =>{
      let _this = this;
      let leaves = "";
      let mappedLines = "";
      let allLines = "";
      let allLeaves = "";
      if(this.pslSectionRef ){
          leaves = getTreeLeaves(_this.pslSectionRef.pslTableRef.tabulator.getData());
          allLeaves = leaves.filter(e=>(e['costtype']=== 'attribute' && e['acType']=== undefined) ||( e['costtype']=== 'calculated' && e['acType']=== undefined) ||  e['costtype']=== 'standard'|| e['costtype']=== 'invoicelinetype'); // all lines except default calculated and default attribute
          mappedLines = this.pslSectionRef.pslTableRef.state.mappedLines;
          allLines =  mappedLines.filter(e=>(e['costtype']=== 'attribute' || e['costtype']=== 'standard' || e['costtype']=== 'calculated' || e['costtype'] === 'invoicelinetype')
                                          && (e['costtype']=== 'standard' || leaves.filter(elt=>elt[_name] === e[_name]).length > 0 && leaves.filter(elt=>elt[_name] === e[_name])[0]["acType"] === undefined));
          if((allLeaves.length  === allLines.length) && mappedLines.length > 0 && allLines.filter(e=>e[_leadingCostKey] === e[_costKey] && e[_combinations].length === 0 && ![costtype.calculated,costtype.attribute].includes(e[_costType])).length === 0 ){
              return false
          }
      }
      return true
    }

    discardonScenarioChange = ()=>{
        this.pslSectionRef.pslTableRef.setState({
            isChanged: false
        })
        this.props.onDiscardClick();
        this.props.changeScenario();
        
    }

    updateParent=()=> {
        this.setState(this.state);
    }

    setReorderedTreeStructure = (data) => {
        data = data.filter(e=>![costtype.attribute].includes(e[_costType]));
        let _treeChildren = 'children';
        
        let pssFilterStructureData = copyObjectValues(this.GLCombinationsref.state.pssFilterStructureData);
        let newPssFilterStructure = data;
        
        newPssFilterStructure = addElements(newPssFilterStructure, _treeChildren, _label,_name);
        newPssFilterStructure = addElements(newPssFilterStructure, _treeChildren, _checked,false);
        
        pssFilterStructureData[2][_treeChildren] = newPssFilterStructure;
        this.setState({
            pssFilterStructureData:pssFilterStructureData
        },()=>{
            // this.GLCombinationsref.GLTableref.tabulator.setFilter(this.customFilter,{data:[PS_MAPPING.PSS_FILTER_LABELS.UNASSIGNED_LABEL]}); //setting pss filter
            this.GLCombinationsref.GLTableref.addFooterText( this.GLCombinationsref.GLTableref.tabulator.getData('active'))
        })
    }

    /**
     * function resets amounts in psl table
     * @param {*} mappedLines 
     */
    refreshAmounts=(mappedLines)=>{
        let _this = this;
        _this.pslSectionRef.pslTableRef.refreshAmounts(mappedLines);
    }

/**
 * function gets the flipped combinations (old and new )
 * @returns 
 */
    getFlippedCombinations=()=>{
        let _this = this;
        let flippedComb = [];
        if (_this.GLCombinationsref.GLTableref.tabulator.getData()) {
            _this.GLCombinationsref.GLTableref.tabulator.getData().filter(e=>e.flipped === true || e.flipped === "true").map(function(item){
                flippedComb.push(item[PS_MAPPING.FIELDS.FIELDS_COMBINATION]);
            })
        }
        return flippedComb;
    }

    /**
     * function checks whether a psl line has combinations or not
     */
    checkLineHasCombinations=(psl,pslLineName)=>{
        let _this = this;
        let combinations =_this.GLCombinationsref.GLTableref.tabulator.getData();
        let data= linearizeHierarchy([psl], _children);
        if (psl[_children] && psl[_children].length > 0) {
            for (var elt in data) {
                if (combinations.filter(e=>e[PS_MAPPING.FIELDS.ASSIGNED_TO].includes(data[elt][_name])).length > 0) {
                    return true;
                }
            }
        }
        return combinations.filter(e=>e[PS_MAPPING.FIELDS.ASSIGNED_TO].includes(pslLineName)).length > 0; 
    }

    checkFlipCheckBox=()=>{
        $("#flip_checkbox").prop('checked',!$("#flip_checkbox").prop('checked'))
    }

    proceedFlip=()=>{
        let _this = this;
        let combinations = undefined;
        let row = (_this.pslSectionRef.pslTableRef.rowToBeMoved && _this.pslSectionRef.pslTableRef.rowToBeMoved !== null) ? _this.pslSectionRef.pslTableRef.rowToBeMoved :
                    _this.GLCombinationsref.selectedRow; 
        let data =  copyObjectValues(_this.pslSectionRef.pslTableRef.tabulator.getData())
        if ($("#flip_checkbox").prop('checked')) {
            if (_this.pslSectionRef.pslTableRef.rowToBeMoved && _this.pslSectionRef.pslTableRef.rowToBeMoved !== null) {
                let pslLineName =  _this.pslSectionRef.pslTableRef.rowToBeMoved.getData()[_name];
                combinations =_this.GLCombinationsref.GLTableref.tabulator.getData();
                if (row.getData()[_children] && row.getData()[_children].length > 0) {
                    let combs = [];
                    let data = linearizeHierarchy([row.getData()], _children);
                    for (var elt in data) {
                        combs = combs.concat(combinations.filter(e=>e[PS_MAPPING.FIELDS.ASSIGNED_TO].includes(data[elt][_name]))); 
                    }
                    combs.map(function(item){item.checked = "true"});
                    combinations = combs;
                }else{
                    combinations.filter(e=>e[PS_MAPPING.FIELDS.ASSIGNED_TO].includes(pslLineName)).map(function(item){item.checked = "true"});
                }
            }
            _this.GLCombinationsref.flipSign(combinations, true, row, _this.GLCombinationsref.pss_action, true);
        }else if (!_this.pslSectionRef.pslTableRef.rowToBeMoved || _this.pslSectionRef.pslTableRef.rowToBeMoved === null){
            _this.GLCombinationsref.updateAssignedStatus(_this.GLCombinationsref.pss_action, _this.GLCombinationsref.selectedRow, true);
        }
        if (_this.pslSectionRef.pslTableRef.rowToBeMoved && _this.pslSectionRef.pslTableRef.rowToBeMoved !== null) {
            _this.pslSectionRef.pslTableRef.executeMoveRow(_this.pslSectionRef.pslTableRef.rowToBeMoved,data,_this.pslSectionRef.pslTableRef, _this.state.rowInfo);
        }
        _this.pslSectionRef.pslTableRef.rowToBeMoved = null;
        _this.GLCombinationsref.selectedRow = null;
        _this.GLCombinationsref.pss_action = null;
        _this.setOpenCombinationFlipWarningDialog(false,undefined);
    }

    getPSLData=()=>{
        let _this = this;
        return _this.pslSectionRef.pslTableRef.tabulator.getData();
    }

    unAssignOnLineRemoval = (combinations) => {
        let glCombinationsData = this.GLCombinationsref.GLTableref.tabulator.getData();
        let combinationsToUnassign  = glCombinationsData.filter(e=>combinations.includes(e.fields_combination));
        this.setState({
            chosenCombinations:combinationsToUnassign
        },()=>{                                     
            this.GLCombinationsref.updateAssignedStatus(ALL_WIDGETS.FIELDS.PS_MAPPING.UNASSIGN,"",false,combinationsToUnassign); 
        })
    }

    setOpenDiscardChangesDialog = (isOpen) => {
      let _this = this;
      _this.setState({
        openDiscardChangesDialog: isOpen
      })
    }

    setOpenConfirmCancelDialog = (isOpen) => {
      let _this = this;
      _this.setState({
        openConfirmCancelDialog: isOpen
      })
    }
    setOpenChangesNotSavedDialog = (isOpen) => {
      let _this = this;
      _this.setState({
        openChangesNotSavedDialog: isOpen
      })
    }
    setOpenWarningDialog = (isOpen) => {
      let _this = this;
      _this.setState({
        openWarningDialog: isOpen
      })
    }
    setOpenMappingChangesDialog = (isOpen) => {
      let _this = this;
      _this.setState({
        openMappingChangesDialog: isOpen
      })
    }
  setOpenCombinationFlipWarningDialog = (isOpen,rowInfo) => {
    let _this = this;
    _this.setState({
      openCombinationFlipWarningDialog: isOpen,
      rowInfo:rowInfo
    })
  }

  openCombinationFlipWarningDialogContent = () => {
    return (
      <>
        <div className="uk-display-flex uk-flex-middle pi-warning-background uk-border-rounded uk-padding-xsmall">
          <i className="fa-2x fal fa-exclamation-triangle uk-margin-default-right" />
          <div className="fs-16">{lang.combinationSignageFlipWarning}</div>
        </div>
        <div className="uk-display-flex uk-margin-top">
          <div>
            <CheckBox id="flip_checkbox" defaultChecked={this.state.flipChecked} labelClassName={"fs-16"} labelText={lang.automatically_flip} />
          </div>
        </div>
      </>
    )
  }

  openCombinationFlipWarningDialogActions = () => {
    return (
      <>
        <Button
          id="proceed-flip-btn"
          label={lang.modal.buttons.proceed}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={this.proceedFlip}
        />
        <Button
          label={lang.modal.buttons.cancel}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => {
            this.pslSectionRef.pslTableRef.tabulator.replaceData(this.pslSectionRef.pslTableRef.tabulator.getData());
            this.setOpenCombinationFlipWarningDialog(false,undefined);
          }}
        />
      </>
    )
  }

  openMappingChangesDialogContent = () => {
    return (
      <>
        <h4>Any GL Cost source change will affect below profit stack lines:</h4>
        {this.state.profitStackLines ?
          this.state.profitStackLines.map(function (item) {
            return <div key={item[_id]} className="uk-display-block uk-text-medium">{"- " + item[PS_MAPPING.FIELDS.NAME]}</div>
          })
          : ""}
      </>
    )
  }
  openMappingChangesDialogActions = () => {
    return (
      <Button
        label={"Done"}
        variant={BUTTON_VARIANT.SECONDARY}
        size={SIZES.DEFAULT}
        type={BUTTON_TYPE.DEFAULT}
        onBtnClick={() => { this.callSaveMappedAncillary() }}
      />
    )
  }
  openWarningDialogActions = () => {
    return (
      <Button
        label={lang.modal.buttons.ok}
        variant={BUTTON_VARIANT.SECONDARY}
        size={SIZES.DEFAULT}
        type={BUTTON_TYPE.DEFAULT}
        onBtnClick={() => this.setOpenWarningDialog(false)}
      />
    )
  }
  openChangesNotSavedDialogActions = () => {
    return (
      <>
        <Button
          label={lang.modal.buttons.yes}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => { this.checkScenario(true) }}
        />
        <Button
          label={lang.modal.buttons.no}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => { this.discardonPeriodChange() }}
        />
        <Button
          label={lang.modal.buttons.cancel}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => {
            this.props.rollBack(this.state.fetchAmounts);
            this.setOpenChangesNotSavedDialog(false);
          }}
        />
      </>
    )
  }
  openConfirmCancelDialogActions = () => {
    return (
      <>
        <Button
          label={"Yes"}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={this.setStagingReport}
        />
        <Button
          label={lang.modal.buttons.no}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => {
            this.setNotSavedWarning(true);
            this.setOpenConfirmCancelDialog(false)
          }}
        />
      </>
    )
  }
  openDiscardChangesDialogActions = () => {
    return (
      <>
        <Button
          label={lang.modal.buttons.discard}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => {
            // window.location.reload() UNCOMMENT THIS WHEN MIGRATION OF PS MAPPING IS DONE
            // OR CHANGE IT TO RETURN TO STAGE MAIN SCREEN INSTEAD OF RELOAD
            this.props.setStagingReport();
          }}
        />
        <Button
          label={lang.modal.buttons.cancel}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => {
            this.setNotSavedWarning(true);
            this.setOpenDiscardChangesDialog(false)
          }}
        />
      </>
    )
  }
  openInfoDialogActions = () => {
    return (
      <Button
        label={lang.modal.buttons.ok}
        variant={BUTTON_VARIANT.PRIMARY}
        size={SIZES.DEFAULT}
        type={BUTTON_TYPE.DEFAULT}
        onBtnClick={() => this.setInfoDialogOpen(false, "")}
      />
    )
  }

    backdropClickHandler = () => {
        this.setState({
            drawerOpen: false
        })
    }

    drawerToggleClickHandler = () => {
        let _this = this
        _this.setState({
            drawerOpen: !_this.state.drawerOpen
        })
    }

    render() {
        let _this = this;
        let sidePanelTitle = "";

        if(_this.state.currentRow) {
            sidePanelTitle = _this.state.currentRow[PS_MAPPING.FIELDS.NAME]+" Setup";
            if(![costtype.standard, costtype.invoicelinetype].includes(_this.state.currentRow.costtype)) {
                sidePanelTitle += " - "+ capitalizeFirstLetter(this.state.currentRow.costtype)+" Line";
            }
        }
        
        let buttons = [];
        buttons.push({button:lang.modal.buttons.cancel, isSubmit: false, isDisabled :false, onClick:this.startCancel},
            {button:lang.modal.buttons.submit, isSubmit: true, isDisabled :false}
        );
        let backdrop = "";
        if (this.state.drawerOpen) {
            backdrop = <Backdrop close={this.backdropClickHandler} />;
        }
        return (
            <React.Fragment>
                <div className="psm-container">
                    <div style={{height: "100%", display: "flex", width: "100%", justifyContent: "space-between"}}>
                        <div className="psm-container" style={{width: "55%"}}>
                            <GLCombinations ref={el => this.GLCombinationsref = el} title={lang.gl_combinations} data={this.state.glData} removeLineFromMappedLines={this.removeLineFromMappedLines} getMappedLines={this.getMappedLines}
                                id={this.state.id} columns={this.state.glColumns} getSelectedPeriods={this.props.getSelectedPeriods} checked_combinations={this.state.checked_combinations} getLeadingCostKey={this.getLeadingCostKey}
                                checkAllLines={this.checkAllLines} pssFilterStructureData={this.state.pssFilterStructureData} glSourceData={this.state.glSourceData} sendCheckedLinesToPSLTable={this.sendCheckedLinesToPSLTable} assignLine={this.assignLine}
                                updateTotals={this.updateTotals} onLinkClick={this.onLinkClick} resetCheckedCombinations={this.resetCheckedCombinations} setMappedLines={this.setMappedLinesInPsl}  generateNewPssFilterStructure = {this.generateNewPssFilterStructure} setNotSavedWarning={this.setNotSavedWarning}
                                customFilter={this.customFilter} scenarioObject ={this.props.scenarioObject} user={this.props.user} refreshAmounts={this.refreshAmounts} getPSLData={_this.getPSLData} notFirstLoad={this.state.notFirstLoad}
                                setOpenCombinationFlipWarningDialog={this.setOpenCombinationFlipWarningDialog} pslSectionRef={this.pslSectionRef}
                              />
                            <SimpleTable ref={el => this.totalsRef = el} getSelectedPeriods={this.getSelectedPeriods} columns={this.state.totalsColumns} data={this.state.totalsData} id={this.state.id_totals} title={lang.totals} updateParent={this.updateParent}/>
                        </div>
                        <div className="psm-container" style={{width: "44%"}}>
                            <PSLSection ref={el => this.pslSectionRef = el} inheritAssignTo={this.inheritAssignTo} costCenter={this.props.costCenter} title={lang.profit_stack_line}
                                periods={this.props.periods} selectedPeriod={this.props.selectedPeriod} totals={this.props.totals} allAncillaryColumns={this.props.allAncillaryColumns}
                                scenarioId={this.props.scenarioId} prefix={this.props.prefix} setNotSavedWarning = {this.setNotSavedWarning}
                                scenarioStatus={this.props.scenarioStatus} costKey={this.props.costKey} clientName={this.props.clientName} checked_combinations={this.state.checked_combinations}
                                periodList={this.props.periodList} clearGLTableLinks={this.onClearFiltersClick} clientCostTerms={this.props.clientCostTerms} setConfirmationRequirement={this.props.setConfirmationRequirement}
                                setStagingReport={this.props.setStagingReport} edit={this.edit} exceptionMetricsRaw={this.state.exceptionMetricsRaw} missingPeriodFiles={this.state.missingPeriodFiles}
                                exceptionMetrics={this.state.exceptionMetrics} getSelectedPeriods={this.props.getSelectedPeriods} updateAssignedStatus={this.updateAssignedStatus} checkLineHasCombinations={this.checkLineHasCombinations}
                                showScenarioPopUp={this.props.showScenarioPopUp} updateCombinationsTable={this.updateCombinationsTable} calculatedColsAmount={this.state.calculatedColsAmount} generateNewPssFilterStructure = {this.generateNewPssFilterStructure}
                                updateParent={this.updateParent} setReorderedTreeStructure={this.setReorderedTreeStructure} checkEmptyCells = {this.checkEmptyCells}  getFlippedCombinations={this.getFlippedCombinations} unAssignOnLineRemoval = {this.unAssignOnLineRemoval} fetchAmounts = {this.state.fetchAmounts} 
                                setOpenCombinationFlipWarningDialog={this.setOpenCombinationFlipWarningDialog}
                              />
                        </div>
                        {/* {this.state.displayPanel ?
                            <div id="overlay-dashboards"></div>
                            : ""} */}
                        
                    </div>
                    {/* <SidePanel ref={r=>this.manageDashboardsOverlay=r} onToggleBoard={this.onToggleBoardr} onCancel={this.startCancel} title={sidePanelTitle} */}
                        {/* body={this.renderSidePanel()} onSave={this.startSave} buttonsDisabled={(this.state.mappedLine && this.state.mappedLine[_attribute]!=="") || (this.state.mappedLine && ([costtype.calculated,costtype.standard].includes(this.state.mappedLine[_costType])))?[]:[lang.modal.buttons.save]} isSubmitButtonText={this.state.isSubmitButtonText}/> */}

                    <SidePanelNew
                        // onToggleBoard={this.onToggleBoardr}
                        // onCancel={this.startCancel}
                        title={sidePanelTitle}
                        show={this.state.drawerOpen}
                        close={this.backdropClickHandler}
                        buttons={buttons}
                        xLargeDisplay={true}
                        body={this.renderSidePanel()}
                        onSubmit={this.startSave}
                        // buttonsDisabled={(this.state.mappedLine && this.state.mappedLine[_attribute] !== "") || (this.state.mappedLine && ([costtype.calculated, costtype.standard].includes(this.state.mappedLine[_costType]))) ? [] : [lang.modal.buttons.save]}
                        isSubmitButtonText={this.state.isSubmitButtonText} />
                        {backdrop}
                </div>
            <Modal
              id={"info-dialog"}
              openDialog={this.state.openInfoDialog}
              bodyContent={() => <h4>{this.state.infoMsg}</h4>}
              dialogActions={this.openInfoDialogActions}
              closeClick={() => this.setInfoDialogOpen(false, "")}
              size={DIALOG_SIZE.MEDIUM}
            />
            <Modal
              id={"confirm-cancel-dialog"}
              openDialog={this.state.openConfirmCancelDialog}
              bodyContent={() => <h4>{lang.discard_changes_confirmation}</h4>}
              dialogActions={this.openConfirmCancelDialogActions}
              closeClick={() => this.setOpenConfirmCancelDialog(false)}
              size={DIALOG_SIZE.MEDIUM}
            />
            <Modal
              id={"on-period-change-not-saved-changes-dialog"}
              openDialog={this.state.openChangesNotSavedDialog}
              bodyContent={() => <h4>{lang.stage_unsaved_changes}</h4>}
              dialogActions={this.openChangesNotSavedDialogActions}
              closeClick={() => {
                this.setOpenChangesNotSavedDialog(false);
                this.props.rollBack(this.state.fetchAmounts);
              }}
              size={DIALOG_SIZE.MEDIUM}
            />
            <Modal
              id={"warning-dialog"}
              openDialog={this.state.openWarningDialog}
              bodyContent={() => <h4>{this.state.warningMessage}</h4>}
              dialogActions={this.openWarningDialogActions}
              closeClick={() => this.setOpenWarningDialog(false)}
              size={DIALOG_SIZE.MEDIUM}
            />
            <Modal
              id={"mapping-changes-dialog"}
              openDialog={this.state.openMappingChangesDialog}
              bodyContent={this.openMappingChangesDialogContent}
              dialogActions={this.openMappingChangesDialogActions}
              closeClick={() => this.setOpenMappingChangesDialog(false)}
              size={DIALOG_SIZE.MEDIUM}
            />
            <Modal
              id={"combination-signage-flip-warning-dialog"}
              openDialog={this.state.openCombinationFlipWarningDialog}
              bodyContent={this.openCombinationFlipWarningDialogContent}
              dialogActions={this.openCombinationFlipWarningDialogActions}
              closeClick={() => {
                this.pslSectionRef.pslTableRef.tabulator.replaceData(this.pslSectionRef.pslTableRef.tabulator.getData())
                this.setOpenCombinationFlipWarningDialog(false,undefined)}
              }
              size={DIALOG_SIZE.LARGE}
            />
          </React.Fragment>
        );

    }

}

export default ProfitStackMappingNew;