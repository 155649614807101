/**
 * This file contains functions that return jsx elements to be rendered instead of components,
 * to allow some manipulation before rendering
 */
 import React from 'react';
 import { BUTTON_TYPE, BUTTON_VARIANT, HEADER_ELEMENT, SIZES } from '../class/constants';
 import {convertPxToViewport} from "../class/formatting.js"
import Button from '../newComponents/Button';

let out = document.querySelector(".output");
  
const getSizes = () => {
    let zoom = ((window.outerWidth - 10)
        / window.innerWidth) * 100;
          
    // out.textContent = zoom;
    return zoom;
}

function printContent() {
  $(".vector-analysis-bridge-report").addClass("vector-analysis-bridge-report-print");
  $("#bridge-chart-div").addClass("max_width");
  $("#bridge_report_tables").hide();

  var zoomSize = getSizes();
  if(zoomSize > 70 && zoomSize < 80) {
    $(".vector-analysis-bridge-report").addClass("vector-analysis-bridge-report-print-zoom-80");
  }

  window.print();

  $("#bridge_report_tables").show();
  $(".vector-analysis-bridge-report").removeClass("vector-analysis-bridge-report-print");
  $(".vector-analysis-bridge-report").removeClass("vector-analysis-bridge-report-print-zoom-80");
  $("#bridge-chart-div").removeClass("max_width");
}
 
 function getPrintButton(component) {
     return (
         <div className={component === HEADER_ELEMENT.BRIDGE?"uk-flex uk-flex-right uk-margin-xsmall-bottom uk-flex-right": "uk-flex uk-flex-right uk-margin-xsmall-bottom uk-flex-right uk-hidden"}>
              <Button  
                label="Print Chart"
                variant={BUTTON_VARIANT.SECONDARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                className="noprint uk-height-fit"
                onBtnClick={()=>printContent()}
                leftIcon={<i className="fal fa-print" />}
            />
         </div>
     )
 }
 
 function getProfitIsleHeader(comps) {
     return (
         <div className={"onlyprint uk-width-1-1 uk-flex uk-flex-center uk-flex-column"}>
             <div>
             <img src={"/images/logo.png"} className={"onlyprint"} style={{width: "10vw"}}  />
             </div>
             {comps}           
         </div>
     )
 }
 
 export { getPrintButton, getProfitIsleHeader };