import React from 'react';
import { logout } from "../../class/common.js";
import { API_URL, FilterUpdateType, HEADER_ELEMENT } from '../../class/constants';
import { generatePeriods, monthDiff } from '../../class/date';
import { FETCHAPI_PARAMS, FETCH_METHOD, fetchAPI } from '../../class/networkUtils';
import { copyObjectValues, getSectionExists, getTranslationFile, parseBoolean } from '../../class/utils';
import '../../styles/common.css';
import { Component } from 'react';
const $ = require('jquery');
const lang = getTranslationFile();
const GENERATE_TOKEN = "generate_token";
var tokenInterval = null;
class TableauHolder extends Component  {
    constructor(props) {
        super(props);
		this.initialState = {
            quadrantFilterDisabled: false,
            headerElements: [/*HEADER_ELEMENT.VECTOR, HEADER_ELEMENT.GO*/],
            // headerElements: [],
            reportTitle: props.customReport ? props.customReport.menu_item_display_name : "",
            // hasDefaultHeaderValues: false,
            displayFilter: false,
            filterFinal:"[]",
            legendItems: [],
            periods: [],
            userAllowed: false
        };
        this.state = Object.assign({}, this.state, this.initialState);
        this.isMainReport = { isTableau: true}
        this.logout = logout.bind(this);
        this.fetchAPI = fetchAPI.bind(this);
        this.initialHeight = "";

    }

    componentDidMount() {
        // super.componentDidMount();
        this.getTableauToken();
        let _this = this;
        // tokenInterval = setInterval(()=>{ //generate new token each 5 minutes 
        //     _this.getTableauToken();
        // }, 300000);
     
        $(window).on('resize', () => {
            if(((window.outerWidth - 10) / window.innerWidth) * 100 < 109) {
                document.getElementById("tableauViz")._iframe.style.height = (window.innerHeight - $(".header").height() - 50 - ($(".banner-component").height() ? $(".banner-component").height() : - 30)) + "px";        
            } else {
                document.getElementById("tableauViz")._iframe.style.height = _this.initialHeight
            }
        }) 
    }

    componentDidUpdate(prevProps) {
        // super.componentDidUpdate();
        let _this = this;
        if (prevProps.url !== _this.props.url) {
          // when we switch between tableau reports, we set token to undefined so that we don't render TableauViz until the token is generated
          _this.setState({
            token: undefined
        }, () => {
          _this.getTableauToken();
        });
        }
        if (_this.props.userAllowedMenuLinks && !_this.state.userAllowed) {
            let tableauReportAccess = getSectionExists(_this.props.userAllowedMenuLinks, this.props.match.params.report, true);
            if (!tableauReportAccess) {
                this.logout();
            } else {
                _this.setState({
                    userAllowed: true
                })
            }
        }
        if(_this.props.userAllowedMenuLinks && !(this.props.history.location.state && this.props.history.location.state.isPeriodAllowed)) {
            let allowed_params = this.props.customReport.allowed_params;
            allowed_params = allowed_params ? JSON.parse(allowed_params) : undefined;
        }
      
    }
    componentWillMount() {
        clearInterval(tokenInterval); // clear interval on changing screen 
    }

    componentWillUnmount(){
        clearInterval(tokenInterval); // to not mix between ontervals of other screens
    }

    getVectorsFilters=(sheetFilters)=>{
        let _this = this;
        sheetFilters = sheetFilters || _this.state.sheetFilters;
        let vectors = _this.state.vectorOptions;
        let vectorsFilters = [];
        for (var e in vectors) {
            if (sheetFilters.includes(vectors[e].label.toLowerCase()+"name")){
                if (!vectorsFilters.includes(sheetFilters[e])) {
                    vectorsFilters.push(vectors[e].label.toLowerCase()+"name");
                }
            } 
            if(sheetFilters.includes(vectors[e].label.toLowerCase()+"key")){
                if (!vectorsFilters.includes(sheetFilters[e])) {
                    vectorsFilters.push(vectors[e].label.toLowerCase()+"key");
                }
            } 
            if(sheetFilters.includes(vectors[e].label.toLowerCase()+"number")) {
                if (!vectorsFilters.includes(sheetFilters[e])) {
                    vectorsFilters.push(vectors[e].label.toLowerCase()+"number");
                }
            }
        }
        return vectorsFilters;
    }

    getFinalEntities=(entities, data, originalData)=>{
        let arr = [];
        let finalArr = [];
        for(var i in entities) {
            arr.push(data.filter(elt=>elt.toLowerCase().startsWith(entities[i].toLowerCase())));
            data = originalData;
        }
        for (var i in arr) {
            finalArr = finalArr.concat(arr[i]);
        }
        return finalArr;
    }

    applySheetFilter=(filter, sheet)=>{
        let _this = this;
        let tempState = {};
        let filterData = _this.state.filterData;
        tempState.vectorsFilter =[];
        tempState.psFilter = [];
        for (var e in filter) {
            let entities = [];
            if (filter[e].type === "vector") {
                let originalData = filterData && filterData[0][filter[e].vector.toLowerCase() +filter[e].field.toLowerCase()].split("||");
                let data = copyObjectValues(originalData);
                entities = filter[e].entities.map(function(item){return item.value});
                if (filter[e].function === "NEQ") {
                    sheet.applyFilterAsync(filter[e].vector.toLowerCase() +filter[e].field.toLowerCase(),entities, FilterUpdateType.Replace,{isExcludeMode: true});
                } else if (filter[e].function === "EQ"){
                    sheet.applyFilterAsync(filter[e].vector.toLowerCase() +filter[e].field.toLowerCase(),entities, FilterUpdateType.Replace);
                }  else if (filter[e].function === "SW"){
                    let finalArr = _this.getFinalEntities(entities, data, originalData);
                    sheet.applyFilterAsync(filter[e].vector.toLowerCase() +filter[e].field.toLowerCase(),finalArr, FilterUpdateType.Replace);
                } else if (filter[e].function === "EW"){
                    let finalArr = _this.getFinalEntities(entities, data, originalData);
                    data = data.filter(elt=>elt.toLowerCase().endsWith(entities[0].toLowerCase()));
                    sheet.applyFilterAsync(filter[e].vector.toLowerCase() +filter[e].field.toLowerCase(),finalArr, FilterUpdateType.Replace);
                } else if (filter[e].function === "CT"){
                    let finalArr = _this.getFinalEntities(entities, data, originalData);
                    data = data.filter(elt=>elt.toLowerCase().includes(entities[0].toLowerCase()));
                    sheet.applyFilterAsync(filter[e].vector.toLowerCase() +filter[e].field.toLowerCase(),finalArr, FilterUpdateType.Replace);
                } else if (filter[e].function === "NCT"){
                    let finalArr = _this.getFinalEntities(entities, data, originalData);
                    data = data.filter(elt=>!elt.toLowerCase().includes(entities[0].toLowerCase()));
                    sheet.applyFilterAsync(filter[e].vector.toLowerCase() +filter[e].field.toLowerCase(),finalArr, FilterUpdateType.Replace);
                } else if (filter[e].function === "EMP"){
                    sheet.applyFilterAsync(filter[e].vector.toLowerCase() +filter[e].field.toLowerCase(),[""], FilterUpdateType.Replace);
                }else if (filter[e].function === "NEMP"){
                    sheet.applyFilterAsync(filter[e].vector.toLowerCase() +filter[e].field.toLowerCase(),data, FilterUpdateType.Replace);
                }
                tempState.vectorsFilter.push(filter[e].vector.toLowerCase() +filter[e].field.toLowerCase());
            } else if (filter[e].type === "profit_stack") {
                if (!tempState.psFilter.includes(filter[e].name_in_fact)) {
                    tempState.psFilter.push(filter[e].name_in_fact);
                }
                if (filter[e].function === "GT") {
                    sheet.applyRangeFilterAsync(filter[e].name_in_fact,{min:parseFloat(filter[e].entities)});
                }else if (filter[e].function === "LT") {
                    sheet.applyRangeFilterAsync(filter[e].name_in_fact,{max:parseFloat(filter[e].entities)});
                }else if (filter[e].function === "BTWN") {
                    sheet.applyRangeFilterAsync(filter[e].name_in_fact,{min:parseFloat(filter[e].min),max:parseFloat(filter[e].max)});
                }else if (filter[e].function === "EQ") {//workaround
                    sheet.applyRangeFilterAsync(filter[e].name_in_fact,{min:parseFloat(filter[e].entities)-1,max:parseFloat(filter[e].entities)+1});
                }else if (filter[e].function === "NEQ") {//not doable
                    sheet.applyRangeFilterAsync(filter[e].name_in_fact,{max:parseFloat(filter[e].entities)-1,min:parseFloat(filter[e].entities)+1});
                }else if (filter[e].function === "GOE") {
                    sheet.applyRangeFilterAsync(filter[e].name_in_fact,{min:parseFloat(filter[e].entities)-0.1});
                }else if (filter[e].function === "LOE") {
                    sheet.applyRangeFilterAsync(filter[e].name_in_fact,{max:parseFloat(filter[e].entities)+0.1});
                }
            }
        }
        return tempState;
    }

    fetchFilterVectors=(filter,sheet)=>{
        let _this = this;
        let periodsCount = monthDiff(this.state.customStartDate, this.state.customEndDate) + 1;
        let periods = generatePeriods(this.state.customStartDate, periodsCount);
        let vectors = _this.getVectorsFilters();
        let query = {
            action: "fetch_vectors_filters",
            scenario_id: this.state.scenario,
            periods: periods.join("','"),
            vectors:vectors
        }
        var onThenCallback = (data)=>{
            if (data) {
                _this.setState({
                    filterData: data.data
                },()=>{
                    let tempState = _this.applySheetFilter(filter,sheet);
                    return tempState;
                })
            }
        }
        var fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "fetchVectorsFilters",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: API_URL.PROFIT_LANDSCAPE,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
        }
        _this.fetchAPI(fetchOptions);
        
    }

    go() {
        let periodsCount = monthDiff(this.props.periodsStatusState.customStartDate, this.props.periodsStatusState.customEndDate) + 1;
        let periods = generatePeriods(this.props.periodsStatusState.customStartDate, periodsCount);
        let viz = document.getElementById('tableauViz');
        this.initialHeight = viz._iframe.style.height;
        if((((window.outerWidth - 10) / window.innerWidth) * 100) < 109) {
            viz._iframe.style.height = (window.innerHeight - $(".header").height() - 50 - ($(".banner-component").height() ? $(".banner-component").height() : - 30)) + "px";
        } else {
            viz._iframe.style.height = this.initialHeight
        }
        let sheet = viz.workbook.activeSheet;
            sheet.applyFilterAsync("period",periods, FilterUpdateType.Replace);
    }

    /**
     * sends API Request to Tableau Servlet to get the user tableau token 
     */
    getTableauToken=()=>{
        let _this = this;
        let query = {
            action: GENERATE_TOKEN
        }
        var onThenCallback = (data)=>{
            let token = data.token;
            _this.setState({
                token: token
            });
        }
        var fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: GENERATE_TOKEN,
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.TABLEAU,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
        }
        _this.fetchAPI(fetchOptions)
    }


    render() {      
        let _this = this; 
        return (  
            _this.props.userAllowedMenuLinks && this.state.token ?   
            <div style={{height:"100%", width:"100%"}}>
                {/* <SessionTimeout ref={r=>this.sessionTimeoutRef = r} isAuthenticated={this.state.user && this.headerRef.props.machine_name !== ""} logout={this.logout} session_timeout_ui={this.state.session_timeout_ui} session_timeout_api={this.state.session_timeout_api}/> */}
                <tableau-viz  
                    id="tableauViz" 
                    // height={`${$("#main_report_container").height() - 5}`}
                    // width={'1270'}
                    toolbar='hidden'
                    src={this.props.url}
                    token={this.state.token}>
                </tableau-viz>
            </div>      
            : 
            <div></div>
        )
    }

}

export default TableauHolder;
