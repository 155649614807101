import NewButton from "../../sections/multipleDimensions/entityListDropDown/NewButton";

import { getTranslationFile } from "../../class/utils.js";
import Button from "../../newComponents/Button";
import { BUTTON_TYPE, BUTTON_VARIANT, SIZES } from "../../class/constants";
const lang = getTranslationFile();

const LaneHeader = ({ lane }) => {
  return (
    <div className="lane-header">
      <span className="lane-title">{lane.titleText + " (" + lane.label + ")"}</span>
      {lane.hasButton && (
        <Button 
          label={lane.btnLabel}
          variant={BUTTON_VARIANT.SECONDARY3}
          size={SIZES.SMALL}
          type={BUTTON_TYPE.DEFAULT}
          className={"lane-header-btn " + lane.id}
          disabled={lane.isBtnDisabled}
          onBtnClick={lane.onBtnClick}
        />  
      )}
    </div>
  );
};

export default LaneHeader;
