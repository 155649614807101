import React, { Component } from 'react';
import { capitaliseFirstLetterAfterChar } from '../class/utils';
import { formatValReact } from "../class/format";
import { BUTTON_TYPE, BUTTON_VARIANT, FormatTypes, SIZES, UPLOAD_SECTIONS } from '../class/constants';
import { convertPxToViewport } from '../class/formatting';
import Button from '../newComponents/Button';

const CROSSWALK = UPLOAD_SECTIONS.FIELDS.CROSSWALK;
class SectionFileInfo extends Component {
    constructor(props) {
		super(props);
		this.state = {
            
        };        
    }

    getRowGroupInfoAsHTML(sectionInfo) {
        var expected = sectionInfo.expected < 0 ? 0 : sectionInfo.expected;
        var passed = sectionInfo.passed < 0 ? 0 : sectionInfo.passed;

        var html = '<div class="row-file-info fs-12 pl-50">';
        html += '<div class="section-info-div uk-display-inline-block text-grey">Expected Files: '+ expected +'</div>';
        html += '<div class="section-info-div uk-display-inline-block text-grey">Passed: '+ passed +'</div>';
        html += '</div>';
        return html;
    }

    render() {
        var sectionInfo = this.props.sectionInfo || {};
        let expected = sectionInfo.expected || 0;   //if undefined, then set = 0
        let passed = sectionInfo.passed || 0;

        var value = (expected === 0 || passed === 0 ) ? 0 : (passed > expected ? expected : passed) / expected * 100;
        let rounded = expected === 0 || passed === 0 || value < 0 ? 0 : value;
        var progress = (expected === 0 || passed === 0 ? formatValReact(0, FormatTypes.PERCENTAGE) : formatValReact(rounded, FormatTypes.PERCENTAGE));
        
        expected = expected < 0 ? 0 : expected;
        passed = passed < 0 ? 0 : passed;
        
        return (
            <div className={"w100 uk-display-flex justify-content-between"}>
                <div className="uk-display-flex align-items-center">
                    <div className={"uk-display-flex uk-flex-middle uk-flex-between uk-margin-medium-right-left"} style={{width: convertPxToViewport(280)}}>
                        <h5 className="uk-text-bold uk-display-inline-block">
                            {capitaliseFirstLetterAfterChar(this.props.section).replaceAll("_", " ")}
                        </h5>
                        {this.props.section !== CROSSWALK ? 
                            <div className="progress width-150 uk-display-inline-block uk-margin-remove">
                                <div className="progress-bar progress-bar-success" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{ width: rounded+"%" }}>
                                    <div className="progress-bar-div">{this.props.section !== CROSSWALK ? progress : ""}</div>
                                </div>
                            </div>
                        :""}
                    </div>
                    <div className="section-info-div uk-padding-remove-left text-grey fs-12"> {this.props.section !== CROSSWALK ? "Expected Files:" +expected : ""}</div>
                    <div className="section-info-div text-grey fs-12"> {this.props.section !== CROSSWALK ? "Passed:" + passed : ""}</div>
                </div>
                <div className="align-self-center">
                    <Button 
                        variant={BUTTON_VARIANT.SECONDARY}
                        size={SIZES.DEFAULT}
                        type={BUTTON_TYPE.DEFAULT}
                        label={"Upload"}
                        title = "Upload"
                        className="uk-margin-default-right uk-display-inline"
                        onBtnClick={(e) => this.props.showUploadPopup(e, this.props.section)}
                        leftIcon={ <i className="fal fa-upload uk-margin-small-right" data-toggle='modal' data-backdrop='static' data-keyboard='false' alt='UPLOAD NOW'></i>}
                        disabled = {this.props.isMigrationAccessible!== undefined && !this.props.isMigrationAccessible }
                    />
                    <Button 
                        variant={BUTTON_VARIANT.SECONDARY}
                        size={SIZES.DEFAULT}
                        type={BUTTON_TYPE.DEFAULT}
                        label={"History"}
                        title = "Show History"
                        className="uk-margin-medium-right uk-display-inline"
                        onBtnClick={() => this.props.prepareHistoryPopup(this.props.section)}
                        leftIcon={<i className="fal fa-history" data-toggle="modal"></i>}
                     />
                </div>
            </div>
        );
    }
}

export default SectionFileInfo;