import { Suspense, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";

// import NewHeader from './NewHeader';
import NewBanner from "../../components/NewBanner";
import Button from "../../newComponents/Button";
import ManageAccess from "../../sections/manageAccess/ManageAccess";
import { ReportHeader } from "../ReportHeader";
import "../styles/reportBody.css";

import { ALL_WIDGETS, API_URL, BUTTON_TYPE, BUTTON_VARIANT, SCENARIO_STATUS, SIZES } from "../../class/constants";
import { lang } from "../../language/messages_en";

import { logout } from "../../class/common";
import { parseBoolean } from "../../class/utils";
import ToggleButton from "../../components/sidePanelNew/ToggleButton";
import ErrorBoundary from "../../ErrorBoundary";
import { Loader } from "../../form/elements";
import { ReactComponent as RoundInfoIcon } from "../../styles/images/menu-svgs/info.svg";
import { useAuthenticated } from "../CustomHooks";
import { renderTitle } from "../functions/componentFunctions";
import { FETCHAPI_PARAMS, FETCH_METHOD, fetchAPI } from "../../class/networkUtils";
/**
 * @author Sarah Farjallah
 * @description component renders  ManageAccess  Title with MFA toggle and  {@link ManageAccess} component , calls isAuthenticated hook to check if user has authentication view the screen
 * @param {*} props 
 * @returns {@link ReportHeader}, {@link ManageAccess}
 */
const ManageAccessWrapper = (props) => {
  const { userAllowedMenuLinks } = props;
  const { userSettings } = props;
  const { scenarioState } = props;

  /**References & react utility */
  const reportRef = useRef();
  const toggleRef = useRef();
  const params = useParams();

  /**Custom Hooks */
  const isAuthenticated = useAuthenticated(userAllowedMenuLinks);

  const [reportTitle, setReportTitle] = useState(ALL_WIDGETS.TITLES.SYSTEM.MANAGE_ACCESS);
  const [headerElements, setHeaderElements] = useState([]);
  const [isScenarioBannerVisible, setScenarioBannerVisible] = useState(false);
  const [isMFA, setIsMFA] = useState();

  /**on screen initialization if user entered url with no access to screen he gets logged out */
  useEffect(() => {
    if (!isAuthenticated) {
      logout();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (scenarioState && Object.keys(scenarioState).length > 0) {
      setScenarioBannerVisible(scenarioState?.scenarioStatus === SCENARIO_STATUS.REVIEW);
    }
    let headerElements = getHeaderElements();
    setHeaderElements(headerElements);
  }, [scenarioState]);

  useEffect(() => {
    let headerElements = getHeaderElements();
    setHeaderElements(headerElements);
  }, []);

  const setMFA = (newMfaValue) => {
    let query = {
      action: "setMFA",
      settingValue: newMfaValue,
    };
    let onThenCallback = (data) => {
      sessionStorage.setItem("MFA", data.data);
      // _this.props.refreshManageAccessTable();
    };
    let fetchOptions = {
      [FETCHAPI_PARAMS.funcName]: "setMFA",
      [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
      [FETCHAPI_PARAMS.showLoader]: false,
      [FETCHAPI_PARAMS.path]: API_URL.MANAGE_ACCESS,
      [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
      [FETCHAPI_PARAMS.query]: query,
      [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
      [FETCHAPI_PARAMS.screenName]: lang.observability.output.manage_access.screen_name,
			[FETCHAPI_PARAMS.requestDescription]: lang.observability.output.manage_access.request_description.set_mfa,
    };
    fetchAPI(fetchOptions);
  };

  const onCheckboxChange = (e) => {
    setIsMFA(e.target.checked);
  };

  const getHeaderElements = () => {
    let headerElements = [];
    let toggle_title = "Multi Factor Authentication";
    let info_title =
      "Add an extra layer of security to user accounts by asking users to verify their identity when they enter a username and password";

    headerElements.push(
      <div style ={{display:"flex",justifyContent:"space-between",width: "100%"}}>
        {renderTitle(reportTitle)}
        <ToggleButton
          forwardRef={(el) => {toggleRef}}
          clientId={userSettings.clientId}
          title={toggle_title}
          infoTitle={info_title}
          defaultchecked={
            parseBoolean(sessionStorage.getItem("MFA")) === null || parseBoolean(sessionStorage.getItem("MFA")) === "null"
              ? userSettings.clientMFA
              : parseBoolean(sessionStorage.getItem("MFA"))
          }
          onCheckboxChange={onCheckboxChange}
          isMFA={isMFA}
          fromMFA={true}
          clientMFA={userSettings.clientMFA}
          setMFA={setMFA}
        />
      </div>
    );

    return headerElements;
  };

  let scenarioLabel = scenarioState?.scenarioObjects?.length > 0 ? scenarioState?.scenarioObjects[0].label : "";
  return (
    <>
      <ErrorBoundary>
        <Suspense fallback={<p id="loading">Loading...</p>}>
          <div
            id={"main-component-container"}
            className={"main-component-container"}
            style={{ "--banner-height": isScenarioBannerVisible ? "6%" : 0 }}
          >
            <Loader newLoader />
            <div className={isScenarioBannerVisible ? "header-banner-div" : "header-banner-div-hidden"}>
              <NewBanner
                bannerClassName={"header-banner scenario-banner"}
                labelClassName={"header-banner-label scenario-banner-label"}
                icon={<RoundInfoIcon className="info-banner-icon" />}
                label={lang.under_review_scenario_banner + " (" + scenarioLabel + ")"}
                body={
                  <Button
                    id={"banner-dismiss-btn"}
                    label={lang.modal.buttons.dismiss}
                    variant={BUTTON_VARIANT.TERTIARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={() => setScenarioBannerVisible(false)}
                  />
                }
              />
            </div>
            <div className="main-report-header">
              <ReportHeader headerElements={headerElements} />
            </div>
            <div className="main_report">
              <div id="main_report_container" className="main_report_container">
                {/* {Object.keys(scenarioState).length > 0 ? ( */}
                  <ManageAccess
                    ref={reportRef}
                    userSettingsState={userSettings}
                    userAllowedMenuLinks={userAllowedMenuLinks}
                    scenarioState={scenarioState}
                    history={props.history}
                    match={params.match}
                  />
                {/* ) : (
                  ""
                )} */}
              </div>
            </div>
          </div>
        </Suspense>
      </ErrorBoundary>
    </>
  );
};

export { ManageAccessWrapper };
