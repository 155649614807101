import React, { Component } from 'react';
import {TabulatorFull} from "tabulator-tables"; //import {TabulatorFull} library
import 'jquery';
import { copyObjectValues, getSectionExists} from '../../class/utils.js';
import { ALL_WIDGETS, CHECK_FIELD, FILE_CENSUS_FIELDS, FILE_CENSUS_TITLES, PERIOD_ONWARD} from '../../class/constants';
import Popup from 'react-popup';
import { convertPxToViewport } from '../../class/formatting.js';
import { createCheckbox } from '../../newComponents/CheckBoxTabulator.js';

const $ = require('jquery');

var obj;
const CHECK_TITLE = "";
const checkboxCellObject = {
    title: CHECK_TITLE,
    field: CHECK_FIELD,
    visible: true,
    headerSort: false
}

const columns = [
    {
        title: FILE_CENSUS_TITLES.RAW_FILE_TYPE_ID_TITLE,
        field: FILE_CENSUS_FIELDS.RAW_FILE_TYPE_ID_FIELD,
        visible: false
    },
    {
        title: FILE_CENSUS_TITLES.SECTION_TITLE,
        field: FILE_CENSUS_FIELDS.SECTION_FIELD,
        visible: false
    },
    {
        title: FILE_CENSUS_TITLES.SUB_SECTION_TITLE,
        field: FILE_CENSUS_FIELDS.SUB_SECTION_FIELD,
        headerSort: false
    },
    {
        title: FILE_CENSUS_TITLES.EXPECTED_FILES_TITLE,
        field: FILE_CENSUS_FIELDS.EXPECTED_FILES_FIELD,
        headerSort: false
    },
    {
        title: FILE_CENSUS_TITLES.START_PERIOD_TITLE,
        field: FILE_CENSUS_FIELDS.START_PERIOD_FIELD,
        headerSort: false       
    },
    {
        title: FILE_CENSUS_TITLES.END_PERIOD_TITLE,
        field: FILE_CENSUS_FIELDS.END_PERIOD_FIELD,
        headerSort: false
    },
    {
        title: FILE_CENSUS_TITLES.NAMING_TEMPLATE,
        field: FILE_CENSUS_FIELDS.NAMING_TEMPLATE,
        headerSort: false
    },
    {
        title: FILE_CENSUS_TITLES.DELIMITER,
        field: FILE_CENSUS_FIELDS.DELIMITER,
        headerSort: false
    },
    {
        title: FILE_CENSUS_TITLES.UPDATE_FLAG_TITLE,
        field: FILE_CENSUS_FIELDS.UPDATE_FLAG_FIELD,
        visible: false,
        headerSort: false
    },
    {
        title: FILE_CENSUS_FIELDS.RAW_FILE_SUBTYPE_ID,
        field: FILE_CENSUS_FIELDS.RAW_FILE_SUBTYPE_ID,
        visible: false,
        headerSort: false
    },
    {
        title: FILE_CENSUS_TITLES.INSERT_FLAG_TITLE,
        field: FILE_CENSUS_FIELDS.INSERT_FLAG_FIELD,
        visible: false,
        headerSort: false
    },
    {
        title: "",
        field: FILE_CENSUS_FIELDS.PRIVATE,
        visible: false,
        headerSort: false
    }
]

const onwardPeriod = PERIOD_ONWARD;

/**
 * File Census Section in settings
 * @author [Sarah Farjallah]
 */
class Configuration extends Component {
		
	constructor(props) {
		super(props);
		this.state = {
            periods: [],
            sectionSubTypeIds: [],
            cell:[]
        };
    
        obj = this;
        this.validateFileCensusSections = this.validateFileCensusSections.bind(this);
        this.onCheckBoxChecked = this.onCheckBoxChecked.bind(this);
        this.validatePeriod = this.validatePeriod.bind(this);
        this.getStartEndDates = this.getStartEndDates.bind(this);
        this.checkExistingSubsection = this.checkExistingSubsection.bind(this);
        this.setCellPeriodValue = this.setCellPeriodValue.bind(this);
        this.checkPeriodExists = this.checkPeriodExists.bind(this);
        this.addSubSection = this.addSubSection.bind(this);
        this.makeSubCategoryPrivate = this.makeSubCategoryPrivate.bind(this);

    }

    makeSubCategoryPrivate(section, data, cell, value){
        cell._group.rows[0].data[FILE_CENSUS_FIELDS.PRIVATE] = value;
        for (var e in obj.props.data) {
            if (obj.props.data[e].subsection === section) {
                obj.props.data[e].private = value;
                if (obj.props.data[e].insert_flag !== "1") {
                    obj.props.data[e].update_flag = "1"; //means its an update
                    obj.props.data[e].insert_flag = "0"; //means its an update
                } else {
                    obj.props.data[e].insert_flag = "1"; //means its an insert
                    obj.props.data[e].update_flag = "0"; //means its an insert
                }
                $("#saveSettingsFC").removeClass('disabled');
            }
        }
        this.tabulator.setData(obj.props.data);
    }

    addSubSection(section, data, cell) {
        var subSection = "";
        var rawFileTypeId = "";
        var fileType = "";
        var raw_file_subtype_id = "";
        var privateSubcategory = false;
        if(cell._group.field === "section") {
            rawFileTypeId = cell.getSubGroups()[0]._group.rows[0].data[FILE_CENSUS_FIELDS.RAW_FILE_TYPE_ID_FIELD];
            fileType = cell.getSubGroups()[0]._group.rows[0].data[FILE_CENSUS_FIELDS.RAW_FILE_TYPE_FIELD];
            privateSubcategory = privateSubcategory;
        } else {
            rawFileTypeId = cell._group.rows[0].data[FILE_CENSUS_FIELDS.RAW_FILE_TYPE_ID_FIELD];
            section = data[0].section;
            subSection = data[0].subsection;
            fileType = cell._group.rows[0].data[FILE_CENSUS_FIELDS.RAW_FILE_TYPE_FIELD];
            raw_file_subtype_id = cell._group.rows[0].data[FILE_CENSUS_FIELDS.RAW_FILE_SUBTYPE_ID];
            privateSubcategory = privateSubcategory;
        }

        obj.props.data.push({
            [FILE_CENSUS_FIELDS.RAW_FILE_TYPE_ID_FIELD]: rawFileTypeId,
            [FILE_CENSUS_FIELDS.RAW_FILE_TYPE_FIELD]: fileType,
            [FILE_CENSUS_FIELDS.RAW_FILE_SUBTYPE_ID]: raw_file_subtype_id,
            section: section,
            subsection: subSection,
            expected_files: "",
            start_period: "",
            end_period: "",
            insert_flag: "1",
            update_flag: "0",
            private: privateSubcategory
        });
        this.forceUpdate();
    }

    toggleTable(shouldShow) {
        this.tabulator.setHeight(shouldShow ? convertPxToViewport(360) : 0);
    }

    validateFileCensusSections() {
        var data = this.props.data;
        var text = "";
        for(var e in data) {
            if (data[e].update_flag !== "-1") {
                if (data[e].subsection === "" || data[e].subsection === undefined) {
                    text = "Subcategory can't be empty";
                    break;
                } else if (data[e].expected_files === "" || Number(data[e].expected_files) < 0 ) {
                    text = "Expected Files can't be empty or negative";
                    break;
                } else if (data[e].start_period === "" || data[e].end_period === "" || data[e].start_period === undefined || data[e].end_period === undefined ) {
                    text = "Start and end periods can't be empty";
                    break;
                // } else if (!data[e][FILE_CENSUS_FIELDS.DELIMITER]) {
                //     text = "Delimiter can't be empty";
                //     break;
                } else if (!!data[e][FILE_CENSUS_FIELDS.NAMING_TEMPLATE]){
                     if(!this.isNamingTemplateUnique(data[e][FILE_CENSUS_FIELDS.RAW_FILE_SUBTYPE_ID], data[e][FILE_CENSUS_FIELDS.NAMING_TEMPLATE])) {
                        text = "The naming template should be unique";
                        break;
                    }
                }
            }
            
            if(data[e].expected_files !== undefined) {
                    data[e].expected_files = data[e].expected_files.toString();
            }        
        }
        if (text.length > 0 ) {
            Popup.alert(text);
            return false;
        } else {
            return true;
        }
    }

    onTabulatorRenderComplete(test) {
        if(!this.tabulator)
            return;
    }

    componentDidMount() {
        var obj = this;
        var tabulatorOptions = {
            data: obj.props.data !== undefined ? obj.props.data : [],
            // columns: columns,
			layout: "fitColumns",        //fit columns to width of table
			responsiveLayout: true,     //hide columns that dont fit on the table
			tooltips: true,              //show tool tips on cells
			addRowPos: "top",            //when adding a new row, add it to the top of the table
			history: true,               //allow undo and redo actions on the table
			pagination: false,          //paginate the data
			movableColumns: false,        //allow column order to be changed
			resizableRows: true,        //allow row order to be changed
			selectable: false,
			resizableColumns: false,
			virtualDomBuffer: 800,
			placeholder: "",
			height: "100%",
            width: "100%",
            groupBy: [FILE_CENSUS_FIELDS.SECTION_FIELD, FILE_CENSUS_FIELDS.SUB_SECTION_FIELD],
			groupStartOpen: false,
            groupToggleElement: "header",
            addRowPos: "top",
            groupHeader: function (groupName, count, data, cell) {
                var addBtn = document.createElement("i");
                addBtn.classList.add("fal", "fa-plus-circle", "uk-margin-small-right");
                addBtn.title = "Add new row";
                addBtn.onclick = () => obj.addSubSection(groupName, data, cell);

                var span = document.createElement("span");
                span.innerHTML = groupName;
                var parentDiv = document.createElement("div");
                parentDiv.classList.add("uk-width-full", "tabulator-row-content");
                parentDiv.style.display = "inline-block";

                var div = document.createElement("div");
                div.appendChild(addBtn);
                div.appendChild(span);
                div.classList.add("align-self-center");

                var privateCheckboxDiv = document.createElement("div");
                var p = document.createElement("p");

                privateCheckboxDiv.classList.add("d-inline-flex", "uk-text-small", "uk-margin-medium-right");
                p.innerHTML = "Subcategory is Private";
                p.classList.add("uk-margin-remove", "font-weight-normal");

                var privateCheckbox = createCheckbox();
                privateCheckbox.id = 'privateSubcat';
                privateCheckbox.name = 'chosenEntity';
                privateCheckbox.classList.add('chosenEntity');
                if (cell._group && cell._group.rows[0] && cell._group.rows[0].data && cell._group.rows[0].data[FILE_CENSUS_FIELDS.PRIVATE] === true) {
                    privateCheckbox.checked = cell._group.rows[0].data[FILE_CENSUS_FIELDS.PRIVATE];
                } else {
                    privateCheckbox.checked = false;
                } 
                privateCheckbox.onchange = function (e) {  
                    if (e.target.checked) {
                        obj.makeSubCategoryPrivate(groupName, data, cell, true);
                    } else {
                        obj.makeSubCategoryPrivate(groupName, data, cell, false);
                    }
                };
                var infoIcon = document.createElement("i");
                infoIcon.classList.add("fal", "fa-info-circle", "uk-margin-small-right-left", "align-self-center");
                infoIcon.setAttribute("uk-tooltip", "Making the subcategory private");
                if (cell._group.level !== 0) {
                    privateCheckboxDiv.appendChild(privateCheckbox);
                    privateCheckboxDiv.appendChild(p);
                    privateCheckboxDiv.appendChild(infoIcon);
                }

                parentDiv.appendChild(div);
                parentDiv.appendChild(privateCheckboxDiv);

                return parentDiv;
            },
            dataEdited:function(data){
                
                 $("#saveSettingsFC").removeClass('disabled');
            },
            renderComplete: this.onTabulatorRenderComplete
        }
        
        this.tabulator = new TabulatorFull(this.refs.fileCensusTable, tabulatorOptions);
        this.toggleTable(false);

        $("#saveSettingsFC").addClass('disabled');

        $("#saveSettingsFC").on("click", function(){
            var dataValidated =  obj.validateFileCensusSections();
            if (dataValidated) {
                obj.props.setData(obj.props.data);
            }
        });

        $("#deleteSettingsFC").click(function(e){
            if($(e.currentTarget).hasClass("disabled")) return; //do not proceed if the svg is disabled
            obj.props.deleteSubSection(obj.state.cell);
        });
    }
    
    componentDidUpdate() {
        var tableData = obj.props.data !== undefined ? obj.props.data : [];
        var tableColumns = copyObjectValues(columns);
        tableColumns.unshift(checkboxCellObject);		//add checkbox column as the first column
        tableColumns = this.getTabulatorColumns(tableColumns);	//set column formatters and return column objects
        this.tabulator.setColumns(tableColumns);		//set the columns as the actual columns of the table
        this.tabulator.setData(tableData); //set the data
    }

    getTabulatorColumns(columns) {
        columns = copyObjectValues(columns) || [];
        columns.forEach(col => {
            col.formatter = this.getColumnFormatter(col.title);
        });
        return columns;
    }

    getStartEndDates(period) {
        var periods = obj.props.periods;
        var dates = [];
        for (var e in periods) {
            if (periods[e].value === period) {
                if (period === onwardPeriod){
                    dates.push(onwardPeriod);
                    dates.push(onwardPeriod); 
                }
                dates.push(periods[e]["start_date"]);
                dates.push(periods[e]["end_date"]);
            }
        }
        return dates;
    }

    checkPeriodExists(period) {
        var periods = obj.props.periods;
        var found = false;
        for (var e in periods) {
            if( periods[e].value === period) {
                found = true;
            }
        }
        return found;
    }

    validatePeriod(e, cell, dateFlag) {
        var periodsShouldNotOverlapMsg = "Periods should not overlap other periods";
        var startPShouldBeLessThanEndPMsg = "Start period should be less than end period";
        var periodIsntValidMsg = "Chosen period is not valid";

        var newPeriod = $(e.currentTarget).val();
        if(this.checkPeriodExists(newPeriod)) {
            var obj = this;
            for(let e in obj.props)
            var Dates = this.getStartEndDates(newPeriod);
            var subsection = cell.getRow().getData()[FILE_CENSUS_FIELDS.SUB_SECTION_FIELD];
            var section = cell.getRow().getData()[FILE_CENSUS_FIELDS.SECTION_FIELD];
            var startDate = (Math.round(new Date(Dates[0]).getTime()/1000));
            var endDate = (Math.round(new Date(Dates[1]).getTime()/1000));

            var period = dateFlag === 1 ? cell.getRow().getData()[FILE_CENSUS_FIELDS.END_PERIOD_FIELD] : cell.getRow().getData()[FILE_CENSUS_FIELDS.START_PERIOD_FIELD];
            var periodEndDate =  (Math.round(new Date(this.getStartEndDates(period)[1]).getTime()/1000));

            var data = obj.props.data;
            var startPeriodDate;
            var endPeriodDate;

            for (var elt in data) {
                if (data[elt].subsection === subsection && section === data[elt].section) { // searching for the cell of the edited row
                    if (data[elt].start_period !== cell.getRow().getData()[FILE_CENSUS_FIELDS.START_PERIOD_FIELD]) { // case when another cell of same subsection is found
                        if (data[elt].start_period === newPeriod || data[elt].end_period === newPeriod) {
                            return periodsShouldNotOverlapMsg;
                        } else{
                            startPeriodDate =(Math.round(new Date(obj.getStartEndDates(data[elt].start_period)[0]).getTime()/1000));
                            endPeriodDate = (Math.round(new Date(obj.getStartEndDates(data[elt].end_period)[1]).getTime()/1000));
                            if (data[elt].end_period === onwardPeriod) {
                                if ( startDate >= startPeriodDate || endDate >= startPeriodDate) {
                                    return periodsShouldNotOverlapMsg;
                                }
                            } else if ((startDate < endPeriodDate && startDate > startPeriodDate) || (endDate > startPeriodDate && endDate < endPeriodDate)) {
                                return periodsShouldNotOverlapMsg;
                            } else if (newPeriod === onwardPeriod) {
                                if (obj.getStartEndDates(data[elt].end_period)[1] === onwardPeriod || endPeriodDate >=(Math.round(new Date(obj.getStartEndDates(cell.getRow().getData()[FILE_CENSUS_FIELDS.START_PERIOD_FIELD])[0]).getTime()/1000))) {
                                    return periodsShouldNotOverlapMsg;
                                }
                            } else if (periodEndDate < endPeriodDate && endDate > endPeriodDate) {
                                return periodsShouldNotOverlapMsg;
                            }
                        }
                    }else { // found edited cell
                        startPeriodDate =(Math.round(new Date(obj.getStartEndDates(data[elt].start_period)[0]).getTime()/1000));
                        endPeriodDate = (Math.round(new Date(obj.getStartEndDates(data[elt].end_period)[1]).getTime()/1000));
                        if(dateFlag === 1) {
                            if (startDate >= endPeriodDate) {
                                return startPShouldBeLessThanEndPMsg;
                            }
                        }else {
                            if (endDate <= startPeriodDate) {
                                return startPShouldBeLessThanEndPMsg;
                            }
                        }
                    }
                }
            }
            return "";
        } else{
            return periodIsntValidMsg;
        }
        
    }

    checkExistingSubsection(cell, section, subsection) {
        var data = obj.props.data;
        var count = 0;
        cell.getRow().getData()[FILE_CENSUS_FIELDS.SUB_SECTION_FIELD] = "";
        this.tabulator.replaceData(obj.props.data);
        for (var e in data) {
            if (data[e].section === section) {
                if(data[e].subsection.toLowerCase().replace(/ /g,'') === subsection.toLowerCase().replace(/ /g,'')) {
                    count++;
                }
            }
        }
        if(count > 0) {
            return false;
        }
        return true;
    }

    setCellPeriodValue(e, cell, dateFlag) {
		//this function updates the value of the cell, hence updates the defineDataTable state of <UploadData />
		//because that state is passed down directly to the tabulator as data object, so by altering one, the other
        //is also changed
        if(dateFlag !== undefined && dateFlag !== null) { // changing in expected values or title input
            var message = this.validatePeriod(e, cell, dateFlag);
            if (message.length > 0) {
                cell.setValue(cell.getValue());
                if (dateFlag === 1) {
                    cell.getRow().getData[FILE_CENSUS_FIELDS.START_PERIOD_FIELD] = cell.getValue();
                }else {
                    cell.getRow().getData[FILE_CENSUS_FIELDS.END_PERIOD_FIELD] = cell.getValue();
                }
                Popup.alert(message);
            } else{
                cell.setValue($(e.currentTarget).val());
            }
        } 
        if (cell.getRow().getData()[FILE_CENSUS_FIELDS.UPDATE_FLAG_FIELD] === "-1") {
            cell.getRow().getData()[FILE_CENSUS_FIELDS.INSERT_FLAG_FIELD] = "1"
        }
        cell.getRow().getData()[FILE_CENSUS_FIELDS.UPDATE_FLAG_FIELD] = "1";
        this.tabulator.replaceData(obj.props.data);
    }

    setCellValue(e, cell, subSectionFlag) {
        if(subSectionFlag && subSectionFlag === 1) {
            if (!this.isValidSubsection($(e.currentTarget).val())) { 
                Popup.alert("Only alphanumeric characters are allowed in subsection name.");
                cell.getRow().update({[cell.getColumn().getDefinition().field]:cell.getRow().getData[FILE_CENSUS_FIELDS.SUB_SECTION_FIELD] === undefined ? "" : cell.getRow().getData[FILE_CENSUS_FIELDS.SUB_SECTION_FIELD]});
            } else if (this.checkExistingSubsection(cell, cell.getRow().getData()[FILE_CENSUS_FIELDS.SECTION_FIELD], $(e.currentTarget).val())) { 
                cell.getRow().update({[cell.getColumn().getDefinition().field]:$(e.currentTarget).val()});
            } else if ($(e.currentTarget).val() !== "") { // subsection inserted already exists
                Popup.alert("Subsection already exists.");
                cell.getRow().update({[cell.getColumn().getDefinition().field]:cell.getRow().getData[FILE_CENSUS_FIELDS.SUB_SECTION_FIELD] === undefined ? "" : cell.getRow().getData[FILE_CENSUS_FIELDS.SUB_SECTION_FIELD]});
                return;
            }
        } else {
            cell.setValue($(e.currentTarget).val());
        }
        if (cell.getRow().getData()[FILE_CENSUS_FIELDS.UPDATE_FLAG_FIELD] === "-1") {
            cell.getRow().getData()[FILE_CENSUS_FIELDS.INSERT_FLAG_FIELD] = "1"
        }
        cell.getRow().getData()[FILE_CENSUS_FIELDS.UPDATE_FLAG_FIELD] = "1";
        this.tabulator.replaceData(obj.props.data);
    }

    setInputValue = (columnField, event, cell) => {
        let value = $(event.currentTarget).val().toLowerCase();
        if(columnField === FILE_CENSUS_FIELDS.NAMING_TEMPLATE) {
             if(!this.isNamingTemplateUnique(cell.getRow().getData()[FILE_CENSUS_FIELDS.RAW_FILE_SUBTYPE_ID], value)) {
                Popup.alert("The naming template should be unique");
            }
        }
        cell.setValue(value);
        cell.getRow().getData()[FILE_CENSUS_FIELDS.UPDATE_FLAG_FIELD] = "1";
        this.tabulator.replaceData(obj.props.data);
    }
    
    isNamingTemplateUnique = (subtypeId, template) => {
        
        return !this.props.data.filter(row=>row[FILE_CENSUS_FIELDS.RAW_FILE_SUBTYPE_ID] !== subtypeId && row[FILE_CENSUS_FIELDS.NAMING_TEMPLATE] === template).length;
    }
    
    isValidSubsection(subSection) {
        var pattern = /^[0-9a-zA-Z ]+$/;
        return subSection.match(pattern) !== null;
    }

    onCheckBoxChecked(cell) {
        let section = cell.getTable().element.getAttribute("id").replace("&", "\\&");
        var sectionName = $("#" + section);
        let hasChecked = sectionName.find('input[type=checkbox]:checked').length > 0;
        obj.state.cell = []
        if(hasChecked) {
            obj.state.cell.push(cell);
           $(".file-census-fieldset").find("div[title='Delete']").removeClass("disabled");
        }else{
            $(".file-census-fieldset").find("div[title='Delete']").addClass("disabled");
        }
    }

    getColumnFormatter(colTitle) {
        var columnFormatter;
        var obj = this;
        switch(colTitle) {
            case CHECK_TITLE:
                columnFormatter = function(cell, formatterParams) {
                    var checkbox = createCheckbox();
                    checkbox.name='chosenEntity';
                    checkbox.classList.add('chosenEntity');
                    let option = document.createElement("option");
                    option["raw_file_subtype_id"] = cell.getRow().getData()[FILE_CENSUS_FIELDS.RAW_FILE_SUBTYPE_ID];
                    option["start_period"] = cell.getRow().getData()[FILE_CENSUS_FIELDS.START_PERIOD_FIELD];
                    option["end_period"] = cell.getRow().getData()[FILE_CENSUS_FIELDS.END_PERIOD_FIELD];
                    checkbox.appendChild(option);
                    checkbox.onchange = () => obj.onCheckBoxChecked(cell);
                    return checkbox;
                };
			break;
            case FILE_CENSUS_TITLES.SECTION_TITLE:
                columnFormatter = function(cell, formatterParams) {
                    return cell.getValue();
                };
            break;

            case FILE_CENSUS_TITLES.SUB_SECTION_TITLE:
                columnFormatter = function(cell) {
                    var input = document.createElement("input");
                    input.onblur = (e) => obj.setCellValue(e, cell, 1);
                    if(cell.getRow().getData()[FILE_CENSUS_FIELDS.RAW_FILE_SUBTYPE_ID] != null && cell.getRow().getData()[FILE_CENSUS_FIELDS.RAW_FILE_SUBTYPE_ID] !== "") {
                        input.disabled = "disabled";   
                    }
                    input.value = cell.getValue();
                    return input;
                };
            break;

            case FILE_CENSUS_TITLES.NAMING_TEMPLATE:
            case FILE_CENSUS_TITLES.DELIMITER:
            case FILE_CENSUS_TITLES.EXPECTED_FILES_TITLE:
                columnFormatter = function(cell, formatterParams) {
                    var rowData = cell.getRow().getData();
                    var input = document.createElement("input");
                    input.onblur = (e) => obj.setCellValue(e, cell);

                    if(cell.getValue() === "") {
                        cell.setValue("1");    //expected files should be defaulted to 1
                    }
                    input.value = cell.getValue();
                    input.type="number";
                    input.onkeydown= (e) => { return false };
                    input.min = 1;
                    if(rowData.section === 'P & L'){
                        input.value = "1";
                        input.disabled = true;
                        input.title = "You can only upload 1 file under 1 subcategory for P&L section.";
                    }
                    
                    return input;
                };
            break;

            case FILE_CENSUS_TITLES.START_PERIOD_TITLE:
                columnFormatter = function(cell, formatterParams) {

                    var input = document.createElement("input");
                    input.setAttribute("list", "startPeriodList");
                    input.onblur = (e) => obj.setCellPeriodValue(e, cell, 1);
                    input.value = cell.getValue() === undefined ? "" : cell.getValue();
                    return input;
                };
            break;

            case FILE_CENSUS_TITLES.END_PERIOD_TITLE:
                columnFormatter = function(cell, formatterParams) {
                    var input = document.createElement("input");
                    input.setAttribute("list", "endPeriodList");
                    input.onblur = (e) => obj.setCellPeriodValue(e, cell, 0);
                    input.value = cell.getValue() === undefined ? "" : cell.getValue();
                    return input;
                };
                break;
            default:
                columnFormatter = function(cell, formatterParams) {
                    return cell.getValue();
                };
            break;
        }
        return columnFormatter;  
    }

    render() {
        var periods = this.props.periods;
        var values = [];
        for(var key in periods) {
            var periodName = periods[key]["value"];
            var option = document.createElement("option");
            option.value = periodName;
            option.innerHTML = periodName;
            option["start_date"] = periods[key]["start_date"];
            option["end_date"] = periods[key]["end_date"];
            values.push(option);
        }

        return (
            <div>
                <div id="fileCensusTable" ref="fileCensusTable"/>
                <datalist id="startPeriodList">
                    {values.map(function(item) {
                        if(item.value !== onwardPeriod)
                            return <option value={item.value} key={item.value}>{item.label}</option>
                    })}
                </datalist>
                <datalist id="endPeriodList">
                    {values.map(function(item) {
                        return <option value={item.value} key={item.value}>{item.label}</option>
                    })}
                </datalist>
            </div>
        );
    }
}

export default Configuration;