import React from "react";
import { lang } from "../../language/messages_en";
import Input from "../../newComponents/Input";
import CheckBox from "../../newComponents/CheckBox";
import Button from "../../newComponents/Button";
import { BUTTON_TYPE, BUTTON_VARIANT, SIZES } from "../../class/constants";
import shortid from "shortid";

const $ = require('jquery');

// * @author [Sarah Farjallah]
class Feature extends React.Component{

    constructor(props) {
        super(props);
        this.state ={
            features:[],
            filteredText: "",
            selection: lang.manage_access.select_all
        }
        this.showChildren = this.showChildren.bind(this);
        this.setChildren = this.setChildren.bind(this);
        this.checkGroup = this.checkGroup.bind(this);
        this.search = this.search.bind(this);
        this.setData = this.setData.bind(this);
    }

    setData(data){
        this.setState({
            features: data
        },function(){
            this.setIndeterminate(this.state.features);
            this.forceUpdate();
        })
    }
    
    componentDidMount(){
        var selection =  false;
        if (this.props.features) {
            var obj = this;
            if(this.props.features.filter(e=>e.checked == true && e.indeterminate == false).length  === obj.props.features.length){
                selection = true;
            }
            this.setState({
                features: this.props.features,
                selecion: selection ? lang.manage_access.deselect_all : lang.manage_access.select_all
            },function(){
                if (selection)  this.selectAll();
                this.setIndeterminate(this.state.features);
                this.extractSectionIds(this.state.features)
            })
        }
    }

    extractSectionIds(features, ids=[]){
        for (var e in features) {
            if (features[e].checked || features[e].indeterminate) {
                if(features[e].category === "menu_item"){
                    ids.push({id:features[e].id.toString()});
                }
                if (features[e].children.length >0) {
                    this.extractSectionIds(features[e].children, ids);
                }
            }
        }
        // this.props.updateParent(ids)
    }

    extractFeaturesIds(features, ids=[]){
        for (var e in features) {
            if (features[e].checked || features[e].indeterminate) {
                if(features[e].category === "feature"){
                    ids.push({id:features[e].id.toString()});
                }
                if (features[e].children.length >0) {
                    this.extractFeaturesIds(features[e].children, ids);
                }
            }
        }
        // this.props.updateParent(ids, undefined, true)
    }

    extractCustomReportIds(features, ids=[]){
        for (var e in features) {
            if (features[e].checked || features[e].indeterminate) {
                if(features[e].category === "custom_report"){
                    ids.push({id:features[e].id.toString()});
                }
                if (features[e].children.length >0) {
                    this.extractCustomReportIds(features[e].children, ids);
                }
            }
        }
        // this.props.updateParent(ids, undefined, false, true)
    }

    /**
     * function sets visible to true or false to show/hide children 
     * function sets checked = true/false and sets the parent to either checked/unchecked/indeterminated and sets the children to either checked/unchecked
     * @param {*} show 
     * @param {*} machine_name 
     * @param {*} features 
     * @param {*} isCheck 
     * @param {*} subChildren 
     */
    setChildren(show, machine_name, features, isCheck=false, subChildren=false){
        for (var e in features) {
            if (features[e].machine_name === machine_name || subChildren) {
                if (!isCheck)  
                    features[e].visible = show;
                else {
                    features[e].checked = !features[e].is_disabled && show;
                    features[e].indeterminate = false;
                }
                if (features[e].children && isCheck) {
                    this.setChildren(!features[e].is_disabled && show, machine_name, features[e].children, true, true)
                }
            } else if (features[e].children && features[e].children.length > 0) {
                this.setChildren(!features[e].is_disabled && show, machine_name, features[e].children, isCheck);
                if (isCheck && (features[e].children.filter(elt=>elt.checked).length > 0 || features[e].children.filter(elt=>elt.indeterminate).length > 0)) {
                    if (features[e].children.filter(elt=>elt.checked).length === features[e].children.length) {
                        $("#checkbox_"+features[e].machine_name).prop('indeterminate',false);
                        features[e].checked = true;
                        features[e].indeterminate = false;
                    } else{
                        features[e].indeterminate = true;
                        features[e].checked = false;
                    }
                } else if (isCheck) {
                    features[e].checked = false;
                    features[e].indeterminate = false;
                }
            }
        }
        return features;
    }

    showChildren(show, machine_name) {
        var features = this.setChildren(show, machine_name, this.state.features);
        this.setState({
            features: features
        },function(){
            this.setIndeterminate(this.state.features);
            this.extractSectionIds(this.state.features)
        })
    }


    /**
     * functions sets parents to indeterminate if one the children is checked we used jquery because cant add it in dom as attribute 
     * @param {*} features 
     */
    setIndeterminate(features, condition) {
        var obj = this;
        for (var e in features) {
            if (features[e].children) {
                this.setIndeterminate(features[e].children, condition);
                if (features[e].children.filter(elt=>elt.checked).length > 0 || features[e].children.filter(elt=>elt.indeterminate).length > 0) {
                    if (features[e].children.filter(elt=>elt.checked).length !== features[e].children.length) {
                        $("#checkbox_"+features[e].machine_name).prop('indeterminate',true);
                    }
                }else{
                    $("#checkbox_"+features[e].machine_name).prop('indeterminate',false);
                }
            }
            if (features[e].children.filter(elt=>elt.checked).length > 0 || features[e].children.filter(elt=>elt.indeterminate).length > 0) {
                if (features[e].children.filter(elt=>elt.checked).length !== features[e].children.length) {
                    $("#checkbox_"+features[e].machine_name).prop('indeterminate',true);
                }else{
                    $("#checkbox_"+features[e].machine_name).prop('indeterminate',false);
                }
            }else{
                $("#checkbox_"+features[e].machine_name).prop('indeterminate',false);
            }
            $("#checkbox_"+features[e].machine_name).prop('checked', condition);
        }
    }

    /**
     * function checks and unchecks checkbox 
     * @param {*} check 
     * @param {*} machine_name 
     */
    checkGroup(check,machine_name) {
        var features = this.setChildren(check, machine_name, this.state.features, true);
        this.setState({
            features: features
        },function(){
           this.setIndeterminate(features);
           this.extractSectionIds(this.state.features)
        })
    }

    /**
     * function sets the value of the searched input into state
     * @param {*} e 
     */
    search(e){
        const _this = this;
        clearTimeout(this.searchTimeout);
        let inputValue = e.target.value;

        this.searchTimeout = setTimeout(()=>{
            _this.setState({
                filteredText: inputValue
            });
        }, 500);
    }

    /**
     * functions checks if name contains the search input or one its children contains it
     * @param {*} id 
     * @param {*} subChlidren 
     * @param {*} features 
     * @param {*} comp 
     */
    filterName(id, subChlidren=false, features, comp) {
        var obj = comp ? comp.props : this.state;
        for (var e in features) {
            if (features[e].id === id || subChlidren) {
                if(features[e].display_name.toLowerCase().includes(obj.filteredText.toLowerCase())) {
                    return true;
                }
                if(features[e].children) {
                    var boolVal = this.filterName(id, true, features[e].children, comp) //For Bassm
                    if (!boolVal) continue;
                    else return boolVal;
                }
            }
        }
        return false;
    }

    /**
     * function selects/uselects the whole checkTree 
     * @param {*} check 
     * @param {*} features 
     */
    select(check, features) {
        for (var e in features) {
            if(!features[e].is_disabled) {
                features[e].checked = check;
                features[e].indeterminate = false
                if (features[e].children.length >0) {
                    this.select(check, features[e].children);
                }    
            }
        }
        return features
    }

    /**
     * functions switches between select and unselect label
     */
    selectAll(fromButton){
        let _this = this;
        let condition = fromButton ? _this.state.selection === lang.manage_access.select_all && this.state?.features?.filter(e=>e.checked == true && !e.indeterminate).length  !== this.state?.features?.length: this.state?.features?.filter(e=>e.checked == true && !e.indeterminate).length  === this.state?.features?.length
        if (condition) {
            var selection = lang.manage_access.deselect_all;
            var features = this.select(true, this.state.features)
        }else{
            var selection = lang.manage_access.select_all;
            var features = this.select(false, this.state.features);
        }
        this.setState({
            features:features,
            selection:selection
        },function(){
            this.setIndeterminate(this.state.features, condition)
            this.extractSectionIds(this.state.features);
        })
    }

    renderList(){
        var obj = this;
        var list = [];
        var features = this.state.features.sort((a, b) => (Number(a.item_order) > Number(b.item_order)) ? 1 : -1)
        if (features) {
            features.map((feature, index) => {
                if(!obj.state.filteredText || obj.state.filteredText === "" || feature.display_name.toLowerCase().includes(obj.state.filteredText.toLowerCase()) || obj.filterName(feature.id, false, features)) {
                    list.push(<Group name={feature.display_name} visible={feature.visible} checked={feature.checked} key={index} 
                        children={feature.children} id={feature.id} machine_name={feature.machine_name} parentId={feature.parent_id} showChildren={this.showChildren} 
                        checkGroup={this.checkGroup} indeterminate={feature.indeterminate} filteredText={obj.state.filteredText} 
                        filterName={this.filterName} disabled={feature.is_disabled} />)
                }
            });
        }
        return list;
    }

    render() {
        var list = this.renderList();
        if (this.state?.features?.filter(e=>e.checked == true && !e.indeterminate).length  === this.state?.features?.length && this.state.selection === lang.manage_access.select_all) {
            this.setState({
                selection: lang.manage_access.deselect_all
            })
        }else if(this.state?.features?.filter(e=>e.checked == true && !e.indeterminate).length  !== this.state?.features?.length && this.state.selection === lang.manage_access.deselect_all){
            this.setState({
                selection: lang.manage_access.select_all
            })
        }
        return (
            <div key={this.props.index}>
                <span className="uk-text-xmedium">Select Features</span>
                <div className="uk-display-flex uk-flex-middle">
                    <Input id="filter_access" className="uk-input" placeholder="Search Features" onChange={this.search} isSearch={true} />
                    {/* <a className="uk-text-nowrap uk-margin-default-left text-link" onClick={()=>{this.selectAll()}}>{this.state.selection}</a> */}
                    <Button
                        label={this.state?.features?.filter(e=>e.checked == true && !e.indeterminate).length  === this.state?.features?.length ? lang.manage_access.deselect_all : lang.manage_access.select_all}
                        variant={BUTTON_VARIANT.TERTIARY}
                        size={SIZES.DEFAULT}
                        type={BUTTON_TYPE.DEFAULT}
                        className="justify-content-start"
                        onBtnClick={()=>{this.selectAll(true)}}
                    />
                </div>
                <div /*key={shortid.generate()}*/ className="uk-margin-top feature-access-group-container">
                    {list}
                </div>
            </div>
        ) 
    }
}

class Group extends React.Component{
    constructor(props) {
        super(props);
    }

    showChildren(show, id, parentId) {
        this.props.showChildren(show,id, parentId)
    }

    checkGroup(check, id) {
        this.props.checkGroup(check, id);
    }

    render() {
        var obj = this;
        let indexOfValue = this.props.name ? this.props.name.toLowerCase().indexOf(this.props.filteredText.toLowerCase()) : "";
        let searchValueInName = this.props.name ? this.props.name.substr(indexOfValue, this.props.filteredText.length) : "";
       
        return (
            <div>
                <div  className="uk-display-flex uk-flex-middle">
                    {this.props.children && this.props.children.length > 0 ? 
                        this.props.visible  ? 
                            <div title='Collapse' className='uk-button-icon transparent-bg uk-margin-xsmall-right' onClick={()=>{this.showChildren(false, this.props.machine_name)}}><i className='far fa-chevron-down'/></div>
                        :   <div title='Expand' className='uk-button-icon transparent-bg uk-margin-xsmall-right'  onClick={()=>{this.showChildren(true, this.props.machine_name)}}><i className='far fa-chevron-right'/></div>
                    :""}
                    <CheckBox id={"checkbox_"+obj.props.machine_name} disabled={obj.props.disabled} divAddedClassName={obj.props.children && obj.props.children.length > 0 ? "" : "uk-margin-large-left"} checked={obj.props.checked} indeterminate={obj.props.indeterminate} 
                        onChange={()=>{obj.checkGroup(!obj.props.checked && !obj.props.indeterminate,obj.props.machine_name)}} />
                    {this.props.filteredText && this.props.filteredText !== ""  && this.props.name.toLowerCase().includes(this.props.filteredText.toLowerCase())? 
                        <span className="uk-text-xmedium uk-margin-xsmall-left">
                            {this.props.name.substr(0, indexOfValue)}
                            <span className="search-value-highlight">{searchValueInName}</span>
                            {this.props.name.substr(indexOfValue + this.props.filteredText.length)}
                        </span>
                    : 
                        <span className="uk-text-xmedium uk-margin-xsmall-left">{this.props.name}</span>
                    }
                </div>
                {this.props.visible ? 
                    this.props.children.map((child,index) =>{
                        if(!obj.props.filteredText || obj.props.filteredText === "" || child.display_name.toLowerCase().includes(obj.props.filteredText.toLowerCase()) || obj.props.filterName(child.id, false, obj.props.children, obj)) {
                            return <div className="uk-display-flex uk-margin-medium-left"><Group name={child.display_name} visible={child.visible} key={index} children={child.children} parentId={child.parent_id} checked={child.checked} id={child.id} machine_name={child.machine_name} showChildren={this.props.showChildren} checkGroup={this.props.checkGroup} indeterminate={child.indeterminate} filteredText={obj.props.filteredText} filterName={this.props.filterName} disabled={child.is_disabled || this.props.disabled}/></div>
                        }
                    })
                : ""} 
            </div>
        ) 
    }
}

export default Feature;