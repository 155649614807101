import { useEffect, useState } from "react";
import { BUTTON_TYPE, BUTTON_VARIANT, HEATMAP, SIZES, VECTOR_SEGMENTS_TABS_OPTIONS, VECTOR_TABS_OPTIONS } from "../../class/constants";
import { findOptionByKey } from "../../class/utils";
import Slider, { CustomSelect } from "../../form/elements";
import HMConfigureSelect from "./HMConfigureSelect";
import HMConfigureToggle from "./HMConfigureToggle";
import Button from "../../newComponents/Button";

const _percentage = "_percentage";

const HeatMapConfigure = ({
  defaultConfObj,
  setTempConfObj,
  stacksData,
  defaultPslData,
  defaultStack,
  onStackChange,
  primaryBtnLabel,
  onPrimaryClick,
  id,
}) => {
  const [confObj, setConfObj] = useState(defaultConfObj);
  const [pslData, setPslData] = useState(defaultPslData)

  useEffect(() => {
    setTempConfObj(confObj);
  }, [confObj]);

  useEffect(() => {
    if(JSON.stringify(defaultPslData) !== JSON.stringify(pslData)) {
      setPslData(defaultPslData)
    }
  });

  useEffect(() => {
    // add heatmap-configure class to the arrow of the <Select> component.
    /** this is to close the configure dialog when we click outside of the configure dialog: we need to add this class for all the children */
    $(".scenario-label .heatmap-configure svg").addClass("heatmap-configure");
    $(".scenario-label .heatmap-configure svg path").addClass("heatmap-configure");
  }, [])

  /**
   * Based on a specific key, update the confObj
   * @param {*} key 
   * @param {*} value 
   */
  const fillConfObj = (key, value) => {
    switch (key) {
      case HEATMAP.CONFIGURE_OBJECT.selected_vector_segment:
        setConfObj({ ...confObj, selectedVectorSegment: value });
        break;
      case HEATMAP.CONFIGURE_OBJECT.selected_vector_entity:
        setConfObj({ ...confObj, selectedVectorEntity: value });
        break;
      case HEATMAP.CONFIGURE_OBJECT.threshold:
        setConfObj({ ...confObj, threshold: value });
        break;
      case HEATMAP.CONFIGURE_OBJECT.selected_psl:
        setConfObj({ ...confObj, psl: value });
        break;
      case HEATMAP.CONFIGURE_OBJECT.stack:
        setConfObj({ ...confObj, stack: value });
        break;
    }
  };

  /**
   * Apply click
   */
  const onClick = () => {
    onPrimaryClick(confObj);
  };

  /**
   * Returns the number's div of the slider
   * @param {*} startPoint 
   * @param {*} incrementVal 
   * @param {*} endPoint 
   * @returns 
   */
  const getNumeratedValues = (startPoint, incrementVal, endPoint) => {
    var values = endPoint - startPoint;
    var divs = [];
    for (var i = 0.0; i <= values; i = i + incrementVal) {
      divs.push(
        <div
          className="heatmap-configure uk-display-inline-block uk-cursor-pointer"
          key={i}
          value={i}
          id={`${i.toString().replace(".", "") + _percentage}`}
          onClick={(e) => setThreshold(e.target.getAttribute("value"))}
        >
          {i + "%"} &nbsp; &nbsp;
        </div>
      );
    }
    return divs;
  };

  const setThreshold = (value) => {
    // remove bold text of previous threshold and add it to the new selected value
    $(".numerated-div").find(".bold-text").removeClass("bold-text");
    $("#" + value.toString().replace(".", "") + _percentage).addClass("bold-text");

    fillConfObj(HEATMAP.CONFIGURE_OBJECT.threshold, value);
  };

  return (
    <div id={id} className="heatmap-configure">
      <HMConfigureToggle
        compKey={HEATMAP.CONFIGURE_OBJECT.selected_vector_entity}
        toggleTabLabel={HEATMAP.LABELS.show_vector_entity_by}
        toggleOptions={VECTOR_TABS_OPTIONS}
        defaultTab={confObj[HEATMAP.CONFIGURE_OBJECT.selected_vector_entity]}
        infoLabel={HEATMAP.INFO.doesnt_apply_on_segments}
        onTabChange={fillConfObj}
      />
      <HMConfigureToggle
        compKey={HEATMAP.CONFIGURE_OBJECT.selected_vector_segment}
        toggleTabLabel={HEATMAP.LABELS.show_vector_segment_by}
        toggleOptions={VECTOR_SEGMENTS_TABS_OPTIONS}
        defaultTab={confObj[HEATMAP.CONFIGURE_OBJECT.selected_vector_segment]}
        infoLabel={HEATMAP.INFO.doesnt_apply_on_entities}
        onTabChange={fillConfObj}
      />
      <HMConfigureSelect
        stacksData={stacksData}
        defaultPslData={pslData}
        defaultStack={defaultStack}
        onStackChange={onStackChange}
        fillConfObj={fillConfObj}
      />
      <div className="heatmap-configure uk-margin-bottom">
        <div className="heatmap-configure">
          <label className="heatmap-configure label-configure-landscape">
            {HEATMAP.LABELS.threshold_label}
          </label>
          <i
            className="heatmap-configure fs-12 fal fa-info-circle uk-margin-xsmall-left uk-cursor-pointer"
            uk-tooltip={"title:" + HEATMAP.INFO.threshold_tooltip}
          />
        </div>
        <div
          id="threshold_slider"
          className="threshold-slider uk-padding-xsmall-top"
        >
          <Slider
            id={"slider"}
            className={"heatmap-configure"}
            startPoint={0}
            endPoint={3}
            incrementVal={0.5}
            value={confObj[HEATMAP.CONFIGURE_OBJECT.threshold]}
            setLimit={setThreshold}
            type={"threshold"}
          />
        </div>
        <div className="heatmap-configure numerated-div">
          {getNumeratedValues(0.0, 0.5, 3.0)}
        </div>
      </div>
      <div className="heatmap-configure uk-flex uk-flex-right uk-margin-default-top">
        <Button 
          id={"heatmap-apply-config-button"}
          label= {primaryBtnLabel}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={onClick}
        />
      </div>
    </div>
  );
};
export default HeatMapConfigure;
