import React, { Component } from 'react';
import {TabulatorFull} from "tabulator-tables";
import { findIndexOfValue } from '../../class/array';
import { cleanUpTabulatorColumns, toggleLoader } from '../../class/common';
import { FormatTypes, PS_MAPPING } from '../../class/constants';
import { formatValHTML, formatValString } from '../../class/format';
import { copyObjectValues, getTranslationFile, parseBoolean } from '../../class/utils';
import {replaceSpecialCharacters} from '../../class/jqueries';
import { lang } from '../../language/messages_en';
import { exportToExcel } from '../../class/tabulatorExport';
import { convertPxToViewport } from '../../class/formatting';
import { createCheckbox } from '../../newComponents/CheckBoxTabulator';
const _checked = "checked";
const _flipped = "flipped"

/**
 *  GLTable is a component containing the main table of the GLCombinations component in the profit stack mapping page
 * @author [Mostafa Haydar]
 * @extends Component
 * **/
 const MESSAGES = getTranslationFile();
 const $ = require('jquery');
class GLTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: [],
            data: this.props.data
        };
        this.isCheckingAll = false;
        this.isLoading = false;
    }

    exportTableToExcel = () => {
        
        let exportOpts = {}
        var sheets = {};
        sheets["Description"] = "#excel_details_table";
        sheets["Data"] = true;
        
        exportOpts.sheets = sheets;
        exportOpts.dataStartRow = 0;
        exportOpts.fileName =  "Combinations"
        let data = copyObjectValues(this.tabulator.getData());
        data.map(function(item){ item.sign = parseBoolean(item.flipped) ? PS_MAPPING.TITLES.FLIPPED : "" });
        this.tabulator.replaceData(data);
        exportOpts.data = data; //this.tabulator.getData();
        exportOpts.isProfitStack = false;
        exportOpts.excludeFormating = true;
        exportToExcel(exportOpts, this.tabulator);
    }
    
    addFooterText=(filteredData, fromTabulatorEvent)=>{
        let amount = "";
        let data = filteredData ? filteredData : this.tabulator ? this.tabulator.getData('active') : [];
        let periods = this.props.getSelectedPeriods();
        let totalAmount = 0;
        data.map(function(item){ 
            let rowData = fromTabulatorEvent ? item._row.data : item;
            totalAmount += (periods.length > 1 ? Number(rowData["total"]) : Number(rowData[periods[0]]));
        });
        if(this.state.checked === data.length){
            amount = totalAmount
        } else {
            amount = this.state.amount;
        }
        
        let totalsText = this.state.checked ?  
        ("<span class=\"uk-margin-small-right\">" + (this.state.checked === 0 ? lang.pss_map_exception.totals.total_count : lang.pss_map_exception.totals.selected_count) + ": " + this.state.checked + " of " + data.length + "</span>" + " " + "<span>" + (this.state.checked === 0 ?lang.pss_map_exception.totals.total_amount :lang.pss_map_exception.totals.selected_amount) + ": " + formatValString(amount, FormatTypes.AMOUNT) + "</span>" )
        :("<span class=\"uk-margin-small-right\">" + lang.pss_map_exception.totals.total_count +": "+ data.length + "</span>" + " " + "<span>" + lang.pss_map_exception.totals.total_amount + ": " + formatValString(totalAmount,FormatTypes.AMOUNT) + "</span>" );
        document.getElementById('footerElement').innerHTML = totalsText ;
        document.getElementById('footerElement').classList.add('ps-footer');
        document.getElementById('footerElement').classList.add("uk-flex", "uk-flex-middle");
    }

    componentDidUpdate(prevProps) {
        let obj = this;
        if(this.props.data){ // to fill the element footer in the footer of the combinations tabulator before checking any checkbox
            if(this.tabulator.getFilters().length > 0){
                this.addFooterText(this.tabulator.getData('active'));
            } else if(prevProps.id !== this.props.id){
                this.addFooterText(this.props.data);
            }
        }

        // var tableColumns = copyObjectValues(obj.props.columns);
        // tableColumns = obj.getTabulatorColumns(tableColumns);
        // obj.tabulator.setColumns(tableColumns);

        if ((this.props.id) !== (prevProps.id)) { // data changed on fetch
            this.tabulator.setColumns(this.getTabulatorColumns(this.props.columns));
            this.tabulator.replaceData(this.props.data).then(function(){
                let pssFilter = obj.props.notFirstLoad ? obj.props.assignedToFilters : [PS_MAPPING.PSS_FILTER_LABELS.UNASSIGNED_LABEL];
                if(pssFilter.length > 0){
                    obj.tabulator.setFilter(obj.props.customFilter,{data:pssFilter}); //setting pss filter
                }
                obj.props.applyFilter(obj.props.selectedCol, '', obj.props.cols)//setting gl filter
                //run code after table has been successfuly updated
            })
            .catch(function(error){
                //handle error loading data
            });
        }
        // if (obj.state.clickedCombination !== undefined){
        //     obj.tabulator.scrollToRow(obj.state.clickedCombination,"center",false);
        // }
    }
    
    componentDidMount() {
      let _this = this;
      var options = {
        index: PS_MAPPING.FIELDS.FIELDS_COMBINATION,
        layout: "fitDataColumns",      //fit columns to width of table
        responsiveLayout: false,  //hide columns that dont fit on the table
        addRowPos: "top",          //when adding a new row, add it to the top of the table
        history: true,             //allow undo and redo actions on the table
        pagination: "local",       //paginate the data
        paginationSize: 50,
        paginationSizeSelector: [10, 50, 100],
        movableColumns: true,     //allow column order to be changed
        autoResize: true,
        resizableRows: false,       //allow row order to be changed
        selectable: false,
        resizableColumns: true,
        invalidOptionWarnings: false,
        virtualDomBuffer: 4000,
        layoutColumnsOnNewData: true,
        width: "100%",
        height: "100%",
        footerElement: "<div id='footerElement'></div>",
        reactiveData: true,
        renderComplete: this.onTabulatorRenderComplete,
        tooltips: function (column) {
          return column._cell.value;
        },
        dataFiltered: function (filters, rows) {
          if (_this.tabulator && _this.tabulator.getData() && _this.state.checked_combinations && _this.state.checked_combinations.length > 0 && ($("#check_all").prop("indeterminate") !== false || $("#check_all").prop("checked") !== false) && !_this.isCheckingAll) {
            setTimeout(() => {
              _this.props.resetCheckedCombinations(_this.props.data);
            }, 10);
          }
          _this.isCheckingAll = false;
          _this.addFooterText(rows, true);
        },
        downloadReady: function (fileContents, blob) {
          toggleLoader(false, "tablesToExcel");
          return blob; //must return a blob to proceed with the download, return false to abort download
        }
      }

      _this.tabulator = new TabulatorFull(_this.refs.mainTable, options);
      var tableColumns = copyObjectValues(_this.props.columns);
      tableColumns = _this.getTabulatorColumns(tableColumns);	//set column formatters and return column objects
      _this.tabulator.setColumns(tableColumns);		//set the columns as the actual columns of the table

      var data = _this.props.data;
      _this.tabulator.setData(data);
    }

    /**
     * adding formatter for the header column title
     */
    addTooltipTitleFormatter = (cell, tooltipMessage) => {
        var div = document.createElement("div");

        var span = document.createElement("span");
        span.classList.add("wrapped-title");
        span.innerHTML = typeof cell === "string" ? cell : cell.getValue();

        var el = document.createElement("i");
        el.classList.add("fal", "fa-info-circle", "uk-margin-xsmall-left", "uk-cursor-pointer");
        el.setAttribute("uk-tooltip", tooltipMessage);

        div.appendChild(span);
        div.appendChild(el);

        return div;
    }

    getTabulatorColumns(columns) {
        let _this = this;
       
        let assigedToAccessor = function(value, data, type, params, column){
            return value.toString();
        }
      
        columns = copyObjectValues(columns) || [];
        columns = cleanUpTabulatorColumns(columns, null, this.refreshFilterDivs, this.tabulator, { id: "GLTable" });
        columns.forEach(col => {
            col.formatter = this.getColumnFormatter(col.field);
            if (col.field === PS_MAPPING.FIELDS.SIGN) {
                col.titleFormatter = (cell) => _this.addTooltipTitleFormatter(PS_MAPPING.TITLES.SIGN, "title: " + lang.ps_mapping.sign_tooltip);
            }
            if (col.field === PS_MAPPING.FIELDS.CHECK) {
                col.titleFormatter = function () {
                    let div = document.createElement("div");
                    let checkbox = createCheckbox();
                    checkbox.checked = _this.state.checked > 0 ? true : false;
                    checkbox.indeterminate = _this.state.checked > 0 && _this.state.checked < _this.tabulator.getData('active').length ? true: false;
                    checkbox.id = 'check_all';
                    checkbox.onclick = function (e) {
                        let checkedComb = e.target.checked ? _this.tabulator.getData('active') : [];
                        _this.setState({
                            checked_combinations: checkedComb
                        }, function () {
                            _this.props.setButtonsStatus(_this.state.checked_combinations);
                            _this.props.sendCheckedLinesToPSLTable(_this.state.checked_combinations);
                            _this.props.checkAllLines(e);
                        })
                    };
                    div.appendChild(checkbox);
                    // let span = document.createElement("span");
                    // span.classList.add("uk-text-bold", "uk-margin-small-left");
                    // span.innerHTML = col.title;
                    // div.appendChild(span);

                    return div;
                }
                col.cssClass = "check-cell";
                col.download = false;
            }
            if (col.field === PS_MAPPING.FIELDS.FIELDS_COMBINATION) {
                col.cssClass = "combinations-cell";
                col.resizable = false;
                // col.width = 350;
            }
            if (col.field === PS_MAPPING.FIELDS.ASSIGNED_TO) {
                col.width = '35%';
                col.cssClass = "assign-to-col leftAlign uk-padding-large-left uk-padding-remove-top uk-padding-remove-bottom uk-flex-inline uk-flex-middle";
                col.accessorDownload = assigedToAccessor;
                col.sorter = 'string';
                
            }
            if (col.field === PS_MAPPING.FIELDS.SIGN) {
                col.sorter = _this.signSorter;
            }
            if (['total', this.props.getSelectedPeriods().toString()].includes(col.field)) {
                col.cssClass ='total-col';
                // col.frozen = false;
            }
           
        });
        return columns;
    }

    signSorter(a, b, aRow, bRow, column, dir, sorterParams) {
        // Access the 'flipped' field values for each row
        let flippedA = aRow.getData().flipped;
        let flippedB = bRow.getData().flipped;
    
        // Sort based on 'flipped' field values
        if (flippedA === flippedB) {
            return 0;
        }
        return flippedA > flippedB ? 1 : -1; // Ascending order; reverse if dir is "desc"
    }
    

    onTabulatorRenderComplete(test) {
        if(!this.tabulator)
            return;
    }

    /**
     * to increment and decrement the number of selected entities, and calculate amounts on checkbox click
     * @param {*} cell 
     * @param {*} is_checked 
     */
    onCheck(cell, is_checked) {
        let _this = this;
        let checked_combinations = _this.state.checked_combinations || [];
        let periods = this.props.getSelectedPeriods();
        let checked = this.state.checked? Number(this.state.checked) : 0;
        let amount = this.state.checked > 0 && this.state.amount? Number(this.state.amount) : 0;
        var rowData = cell.getRow().getData();
        let index = findIndexOfValue(checked_combinations, PS_MAPPING.FIELDS.FIELDS_COMBINATION, rowData[PS_MAPPING.FIELDS.FIELDS_COMBINATION]);
        if (is_checked) {
            checked++;
            amount +=  periods.length > 1 ? (rowData["total"] ? Number(rowData["total"]):0) : Number(rowData[periods[0]]);
            rowData["checked"]="true";
            if(index === -1) {
                checked_combinations.push(rowData);
            }
        }else{
            checked--;
            amount -=  periods.length > 1 ? (rowData["total"] ? Number(rowData["total"]) :0) : Number(rowData[periods[0]]);
            rowData["checked"]="false";
            if(index > -1) {
                checked_combinations.splice(index, 1);
            }
        }
        var filteredData = this.tabulator.getData('active');
        let totalAmount = 0;
        this.tabulator.getData().map(function(item){totalAmount += (periods.length > 1 ? Number(item["total"]) : Number(item[periods[0]])) })
        this.setState({
            checked_combinations: checked_combinations,
            checked: checked,
            clickedCombination: cell.getRow().getData()[PS_MAPPING.FIELDS.FIELDS_COMBINATION],
            amount: amount,
            TotalsText: checked === 0 ? (lang.pss_map_exception.totals.total_count +" : "+ filteredData.length + " " + 
            lang.pss_map_exception.totals.total_amount + " : " + formatValString(totalAmount, FormatTypes.AMOUNT))
            : (lang.pss_map_exception.totals.total_count + " : " + checked + " of " + filteredData.length + " " +
            lang.pss_map_exception.totals.total_amount + " : " + formatValString(amount, FormatTypes.AMOUNT))
        },function(){
            _this.props.setButtonsStatus(_this.state.checked_combinations);
            _this.addFooterText(filteredData);
            _this.props.sendCheckedLinesToPSLTable(_this.state.checked_combinations);
            if(checked === filteredData.length){
                $("#check_all").prop('indeterminate',false);
                $("#check_all").prop('checked',true);
            } else if(checked === 0){
                _this.unCheckCheckAll();
            } else if(checked < filteredData.length){
                $("#check_all").prop('checked',false);
                $("#check_all").prop('indeterminate',true);
            }
        });
    }


    /**
     * on link filtering, this funtion is to disable the other column
     */
    disableOtherColumns=(position)=>{
        let obj = this;
        if(position === undefined || (position === this.state.index && 
        ((this.tabulator.getRows()[position+1] && $(this.tabulator.getRows()[position+1].getElement()).hasClass("disabled-row")) // if the user clicks on the same line more than once (filter, unfilter, filter)
        || (this.tabulator.getRows()[position-1] && $(this.tabulator.getRows()[position-1].getElement()).hasClass("disabled-row"))))){
            obj.tabulator.getRows('active').forEach(row =>{
                $(row.getElement()).removeClass("disabled-row");
                $(row.getElement()).css({"background-color": "#ffffff"});
            });
        }else {
            obj.tabulator.getRows('active').forEach(row =>{
                if (row.getPosition() === position) {
                    $(row.getElement()).removeClass("disabled-row");
                    $(row.getElement()).css({"background-color": "#f3f3f3"});
                } else {
                    $(row.getElement()).addClass("disabled-row");
                }
            });
        }
    }

    /**
     * change the state of the check all to unchecked
     */
    unCheckCheckAll=()=>{
        $("#check_all").prop('indeterminate',false);
        $("#check_all").prop('checked',false)
    }


    getColumnFormatter(colName) {
        var obj = this;
        switch(colName){
            case PS_MAPPING.FIELDS.CHECK:
                    columnFormatter = function(cell, formatterParams) {
                        var pMain = document.createElement("div");
                        var checkbox = createCheckbox();
                        checkbox.checked = cell.getRow().getData()["checked"] === "true";
                        checkbox.id = cell.getRow().getData().raw_file_type_transition_id+"_pss";
                        checkbox.name='chosenEntity';
                        checkbox.classList.add('chosenEntity', 'leftAlign');
                        checkbox.onclick = function() {
                            obj.onCheck(cell, checkbox.checked)
                        };
                        pMain.appendChild(checkbox);
                        return pMain;
                    };
                break;
                case PS_MAPPING.FIELDS.FIELDS_COMBINATION:
                    columnFormatter = function(cell, formatterParams) {
                        var pMain = document.createElement("div");
                        var dropdown = document.createElement("div");
                        dropdown.innerHTML = "Subcategory: " + cell.getRow().getData()["raw_file_type"];
                        dropdown.style.width = "fit-content";
                        var button = document.createElement("button");
                        var combination = cell.getValue();
                        button.innerHTML = combination;
                        button.classList.add("text-link", "text-remove-decoration", "combinations-button");
                        button.setAttribute("type", "button");
                        button.title = formatValString(combination, cell.getColumn().getDefinition().format_type);
                        if (cell.getRow().getData()["raw_file_type"] && cell.getRow().getData()["raw_file_type"] !== "") {
                            dropdown.setAttribute('uk-dropdown', "pos: right; mode: click");
                            dropdown.classList.add("uk-dropdown", "combinations-dropdown");
                        }
                        pMain.appendChild(button)
                        pMain.appendChild(dropdown);
                        return pMain;
                    };
                break;
                case PS_MAPPING.FIELDS.SIGN:
                    columnFormatter = function(cell, formatterParams) {
                        var pMain = document.createElement("div");
                        let icon = document.createElement('i');
                        icon.classList.add('far','fa-exchange','uk-hidden');
                        if(parseBoolean(cell.getRow().getData()[_flipped])) {
                            icon.classList.remove('uk-hidden');
                        }
                        icon.id = replaceSpecialCharacters(cell.getRow().getData()[PS_MAPPING.FIELDS.FIELDS_COMBINATION]);
                        pMain.appendChild(icon);
                        return pMain;
                    };
                break;
            case PS_MAPPING.FIELDS.ASSIGNED_TO:
                columnFormatter = function (cell, formatterParams) {
                    const pMain = document.createElement("div");
                    const span = document.createElement("span");
                    span.innerHTML = cell.getValue().join(",");

                    pMain.classList.add("assign-to-cell", "uk-flex-inline", "uk-flex-middle");

                    if (span.innerHTML !== PS_MAPPING.PSS_FILTER_LABELS.EXCLUDED_LABEL && span.innerHTML !== PS_MAPPING.PSS_FILTER_LABELS.UNASSIGNED_LABEL) {
                        const linkIcon = document.createElement("i");
                        linkIcon.classList.add("link-icon", "fa-lg", "fal", "fa-link", "width-30", "d-inherit", "uk-margin-default-right");
                        linkIcon.classList.add("uk-button-icon", "dots-button", "transparent-bg");
                        linkIcon.setAttribute("uk-tooltip", MESSAGES.profit_stack_link_filter_tooltip);

                        linkIcon.onclick = () => {
                            let position = cell.getRow().getPosition();
                            obj.disableOtherColumns(position);
                            if (obj.state.index === position){
                                obj.props.onLinkClick(cell.getValue()[0]);
                            }

                            obj.setState({
                                index: position,
                                isClicked: obj.state.index === position ? !obj.state.isClicked : true,
                                clickedCombination: cell.getRow().getData()[PS_MAPPING.FIELDS.FIELDS_COMBINATION],
                            });
                            if (obj.state.clickedAssignedToData !== cell.getValue()[0]){
                                obj.setState({
                                    clickedAssignedToData: cell.getValue()[0]
                                })
                                obj.props.onLinkClick(obj.state.clickedAssignedToData);
                            }
                        }
                        pMain.appendChild(linkIcon);
                    }

                    $(cell.getRow().getElement()).css({"background-color": "#ffffff"});
                    $(cell.getRow().getElement()).removeClass("disabled-row");
                    if (obj.state.isClicked) {
                        if (obj.state.index === cell.getRow().getPosition()) {
                            $(cell.getRow().getElement()).css({"background-color": "#f3f3f3"});
                        } else {
                            $(cell.getRow().getElement()).addClass("disabled-row");
                        }
                    }

                    pMain.appendChild(span);
                    return pMain;
                };
                break;
            default:
                var columnFormatter = function(cell, formatterParams) {
                    var p = document.createElement("p");
                    if(obj.isLoading) {
                        p = document.createElement("p");
                        let img = document.createElement("i");
                        img = document.createElement("img");
                        img.src = '/images/FhHRx.gif';
                        img.style.width=convertPxToViewport(15);
                        img.style.height=convertPxToViewport(15);
                        p.appendChild(img);
                    } else {
                        var amount = cell.getValue();
                        p.innerHTML = formatValHTML(amount, cell.getColumn().getDefinition().format_type);
                        p.title = formatValString(amount, cell.getColumn().getDefinition().format_type);
                        if(Number(amount) < 0) {
                            p.classList.add("red");
                        }
                    }
                    return p;
                }
                break;
        }
        return columnFormatter;
    }

    render() {
        return(
            <div id="GLTable" ref="mainTable"/>
        );

    }

}

export default GLTable;