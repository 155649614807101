import React, { Component } from 'react';
import AsyncSelect from 'react-select/async';
import {components} from 'react-select';
import { findIndexOfValue, moveIndex } from '../class/array';
import { copyObjectValues, getTranslationFile } from '../class/utils';
import Button from '../newComponents/Button';
import { BUTTON_TYPE, BUTTON_VARIANT, SIZES } from '../class/constants';

const $ = require("jquery");
const lang = getTranslationFile();

class ScrollList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [],    //these two states are duplicate, because the default behavior is to remove an option one it is unchecked so it is removed from both
            selectedOptions: [],     //the only reason they were kept is to make an easy transition in case this default behavior needed to change at some point
            visible: this.props.defaultVisible ? true : false,
            enableApply: false,     //"Apply" button disabled by default
        }

        this.onSelectOption = this.onSelectOption.bind(this);
        this.loadOptions = this.loadOptions.bind(this);
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.applyChanges = this.applyChanges.bind(this);
        this.resetToDefault = this.resetToDefault.bind(this);
        this.getSelectedOptions = this.getSelectedOptions.bind(this);
    }

    // static getDerivedStateFromProps(props, state) {
    //     let tempState = {}
    //     if(!deepCompareObjects(props.options, state.options)) {
    //         let options = props.options.map(opt=>{      //making sure options are always in object structure
    //             return typeof opt === "string" ? {label: opt, value: opt} : opt
    //         });
    //         options = rearrangeOptions(options);
    //         tempState.options = options;
    //     }

    //     if(!deepCompareObjects(props.selectedOptions, state.selectedOptions)) {
    //         tempState.selectedOptions = props.selectedOptions.map(opt=>{      //making sure options are always in object structure
    //             return typeof opt === "string" ? {label: opt, value: opt} : opt
    //         });
    //     }

    //     if(!Object.keys(tempState).length) {
    //         return null;    //update nothing if there is nothing to update
    //     }
    //     return tempState;
    // }

    init() {
        let options = this.props.options.map(opt=>{      //making sure options are always in object structure
            return typeof opt === "string" ? {label: opt, value: opt} : opt
        });
        options = this.rearrangeOptions(options);

        let selectedOptions = this.props.selectedOptions.map(opt=>{      //making sure options are always in object structure
            return typeof opt === "string" ? {label: opt, value: opt} : opt
        });
        selectedOptions = this.rearrangeOptions(selectedOptions);

        this.setState({
            options: copyObjectValues(options),
            selectedOptions: copyObjectValues(selectedOptions),
            defaultOptions: copyObjectValues(this.props.defaultOptions)     //these are the default options that are filled only on mount
        });
    }

    show() {
        this.setState({
            visible: true
        });
    }

    hide(tempState={}) {
        tempState.visible = false;
        this.setState(tempState);
    }

    rearrangeOptions(options) {
        if(this.props.disabledOptions) {
            this.props.disabledOptions.forEach(opt => {
                moveIndex(options, findIndexOfValue(options, "value", opt), 0);     //reorder options so that the disabled ones are in the beginning of the list
            });
        }

        return options;
    }

    checkAddOption(list, option) {
        if(list.map(opt=>opt.value).includes(option.value)) {
            return list;     //option is already selected, do not duplicate
        }
        list.unshift(option);
        return list;
    }

    checkDeleteOption(list, option) {
        let index = findIndexOfValue(list, "value", option.value);
        if(index > -1) {
            list.splice(index, 1);
        }
        return list;
    }

    onSelectOption(option) {
        let selectedOptions = this.checkAddOption(this.state.selectedOptions, option);

        let options = this.checkAddOption(this.state.options, option);
        options = this.rearrangeOptions(options);

        this.setState({
            enableApply: true,
            selectedOptions: selectedOptions,
            options: options
        });
    }

    onUncheck(option) {
        let selectedOptions = this.checkDeleteOption(this.state.selectedOptions, option);
        let options = this.checkDeleteOption(this.state.options, option);
        
        this.setState({
            enableApply: true,
            options: options,
            selectedOptions: selectedOptions
        });
    }

    loadOptions(value, callback) {
        const _this = this;
        
        if(this.props.loadOptions) {
            clearTimeout(this.loadOptionsTimeout);
            this.loadOptionsTimeout = setTimeout(function(){
                _this.props.loadOptions(value, function(data){
                    if(typeof callback === "function") {
                        callback(data);
                    }
                });
            }, 300);
        }
    }

    componentDidMount() {
        this.init();
    }

    applyChanges() {
        let tempState = {};
        tempState.enableApply = false;
        this.hide(tempState);
        this.props.onApply(this.state.selectedOptions);
        $("#x-axis-entity").removeClass("background-dark-grey");
        $("#y-axis-entity").removeClass("background-dark-grey");
    }

    resetToDefault() {
        const _this = this;
        this.setState({
            selectedOptions: this.state.defaultOptions,
            options: this.state.defaultOptions,
        }, ()=>{
            _this.applyChanges();
        });
    }

    getSelectedOptions() {
        return this.state.selectedOptions;
    }

    render() {
        const top = this.props.position ? this.props.position.top : 0;
        const left = this.props.position ? this.props.position.left : 0;

        const searchIndicator = props => {
            return (
                <components.DropdownIndicator {...props}>
                    <i className="fal fa-search fa-lg"></i>
                </components.DropdownIndicator>
            );
        }

        if(!this.state.visible) {
            return <React.Fragment></React.Fragment>
        }
        let inDisabledOptions ="";
        let maxExceeded= this.state.selectedOptions.length >= this.props.maxCheckableOptions;
        return (
            <div className="scroll-list-container width-350" style={{top: top, left: left}}>
                <div className="uk-width-1-1 uk-flex uk-flex-between">
                    <AsyncSelect
                        className="width-250"
                        value=""
                        options={this.state.options}
                        onChange={(option)=>this.onSelectOption(option)}
                        placeholder={lang.heatmap_configure.entities.search_entities}
                        components={{DropdownIndicator: searchIndicator}}   //overwrites the chevron-down component with a search icon
                        styles={{   //removes the "|" separator
                            indicatorSeparator: ()=>{}
                        }}
                        isDisabled={this.state.selectedOptions.length === this.props.maxCheckableOptions}
                        loadOptions={this.loadOptions}
                    />
                    <div className="uk-flex uk-flex-around" style={{flexDirection: "column"}}>
                        <h6>{this.state.selectedOptions.length + "/" + this.props.maxCheckableOptions}</h6>
                    </div>
                </div>
                {this.state.options.length ?
                    <div className="uk-height-1-1 uk-overflow-auto height-200 uk-margin-small-top-bottom uk-section-default">
                        <ul className="uk-width-1-1">
                            {this.state.options.map(opt=>{
                                inDisabledOptions = this.props.disabledOptions && this.props.disabledOptions.includes(opt.value)
                                return(
                                    <li className="uk-width-1-1 uk-flex uk-padding-xsmall" key={"entity-option-"+opt.value}>
                                        <input type="checkbox" className={inDisabledOptions /*&& maxExceeded*/? "disabled":""}
                                            checked={(inDisabledOptions && !maxExceeded)? false : this.state.selectedOptions.map(ent=>ent.value).includes(opt.value)} onChange={()=>this.onUncheck(opt)}/>
                                        <h6 className={(inDisabledOptions /*&& maxExceeded*/? "disabled " :"") + " uk-margin-xsmall-left width-max-90" }>{opt.label}</h6>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                :"" }

                <div className="uk-flex uk-flex-between">
                    <a className="uk-text-decoration-underline uk-margin-default-left uk-text-xmedium uk-cursor-pointer"
                        onClick={this.resetToDefault}>{lang.modal.buttons.reset_to_default}
                    </a>
                    <Button 
                        label={lang.modal.buttons.apply}
                        variant={BUTTON_VARIANT.PRIMARY}
                        size={SIZES.DEFAULT}
                        type={BUTTON_TYPE.DEFAULT} 
                        disabled={this.state.enableApply}                            
                        onBtnClick={this.applyChanges}
                    />
                </div>
            </div>
        );
    }
}

export default ScrollList;