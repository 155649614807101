import React, {useEffect, useRef, forwardRef, useImperativeHandle, useState} from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";
// import * as am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const $ = require("jquery");

const ModelCoherenceChart = (props, ref) => {
    const chartRef = useRef(null);
    useEffect(()=>{
        if(props.chartData){
            renderChart(props.chartData);
        }
    },[props.chartData])


    const renderChart = (data) => {
        am5.disposeAllRootElements();
        let root = am5.Root.new("chartdiv");
        root._logo.dispose();

        var chart = root.container.children.push(am5radar.RadarChart.new(root, {
            panX: false,
            panY: false,
            startAngle: 180,
            endAngle: 360
        }));

        var axisRenderer = am5radar.AxisRendererCircular.new(root, {
            innerRadius: -40
        });

        axisRenderer.grid.template.setAll({
            stroke: root.interfaceColors.get("background"),
            visible: true,
            strokeOpacity: 0.8
        });

        var xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
            maxDeviation: 0,
            min: 0,
            max: 100,
            strictMinMax: true,
            renderer: axisRenderer
        }));

        var axisDataItem = xAxis.makeDataItem({});

        var clockHand = am5radar.ClockHand.new(root, {
            pinRadius: am5.percent(20),
            radius: am5.percent(100),
            bottomWidth: 40
        })

        var bullet = axisDataItem.set("bullet", am5xy.AxisBullet.new(root, {
            sprite: clockHand
        }));

        xAxis.createAxisRange(axisDataItem);

        var label = chart.radarContainer.children.push(am5.Label.new(root, {
            fill: am5.color(0xffffff),
            centerX: am5.percent(50),
            textAlign: "center",
            centerY: am5.percent(50),
            fontSize: "3em"
        }));

        axisDataItem.set("value", 50);
        bullet.get("sprite").on("rotation", function () {
            var value = axisDataItem.get("value");
            var text = Math.round(axisDataItem.get("value")).toString();
            var fill = am5.color(0x000000);
            xAxis.axisRanges.each(function (axisRange) {
                if (value >= axisRange.get("value") && value <= axisRange.get("endValue")) {
                    fill = axisRange.get("axisFill").get("fill");
                }
            })

            label.set("text", Math.round(value).toString());

            clockHand.pin.animate({ key: "fill", to: fill, duration: 500, easing: am5.ease.out(am5.ease.cubic) })
            clockHand.hand.animate({ key: "fill", to: fill, duration: 500, easing: am5.ease.out(am5.ease.cubic) })
        });

        axisDataItem.animate({
            key: "value",
            to: data.percentage,
            duration: 500,
            easing: am5.ease.out(am5.ease.cubic)
        });

        chart.bulletsContainer.set("mask", undefined);

        let bandsData = [{
            color: "#ff5858",
            lowScore: 0,
            highScore: data.low
        }, {
            color: "#ffc982",
            lowScore: data.low,
            highScore: data.medium
        }, {
            color: "#6cd95b",
            lowScore:data.medium,
            highScore: 100
        }]

        am5.array.each(bandsData, function (data) {
            var axisRange = xAxis.createAxisRange(xAxis.makeDataItem({}));

            axisRange.setAll({
                value: data.lowScore,
                endValue: data.highScore
            });

            axisRange.get("axisFill").setAll({
                visible: true,
                fill: am5.color(data.color),
                fillOpacity: 0.8
            });

            axisRange.get("label").setAll({
                inside: true,
                radius: 15,
                fontSize: "0.9em",
                fill: root.interfaceColors.get("background")
            });
        });
        return () => {
            root.dispose();
        };
        
    }

    return (
        <div  ref={chartRef}  id="chartdiv"></div>
    );
};

export default forwardRef(ModelCoherenceChart);
