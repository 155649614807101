const defaultQTs = [];
const defaultPSLLINES = new Map();

function quadrantTierOptions(state=defaultQTs, action) { //reducer
    switch (action.type) {
        case "UPDATE_QTS":
        return [
            {
            name: action.quadrantTierOptions
            }
        ];
        case "GET_QTS":
          return state;
        default:
        return state;
    }
}

function psLinesOptions(state=defaultPSLLINES, action) { //reducer
    switch (action.type) {
        case "UPDATE_PSLLINES_OPTIONS":
        let pslLinesOptionsMap = new Map(state);
        if (action.scenarioId) {
            pslLinesOptionsMap.set(action.scenarioId, action.psLinesOptions);
        }
        return pslLinesOptionsMap;
        case "GET_PSLLINES_OPTIONS":
          return state;
        default:
        return state;
    }
}
  
export {quadrantTierOptions, psLinesOptions};