export function updateVectorEntities(vectorEntities, scenarioId, clear) {
  return {
    type: 'UPDATE_VECTOR_ENTITIES',
    vectorEntities: vectorEntities,
    scenarioId: scenarioId,
    clear: clear

  }
}

export function getVectorEntities() {
  return {
    type: 'GET_VECTOR_ENTITIES'
  }
}