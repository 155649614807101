const defaultGeographyObjBeforeBack = [];
const defaultGeographyListData = [];
const defaultGeographyCostHierarchy = [];

function geographyObjBeforeBack(state = defaultGeographyObjBeforeBack, action) {
  switch (action.type) {
    case "UPDATE_GEOGRAPHY_OBJECT_BEFORE_BACK":
      if (action.clear) {
        let geographyObjBeforeBackHashMap = new Map();
        return geographyObjBeforeBackHashMap;
      }
      let geographyObjBeforeBackHashMap = new Map(state);
      geographyObjBeforeBackHashMap.set(action.key, action.geographyObjBeforeBack);
      return geographyObjBeforeBackHashMap;
    case "GET_GEOGRAPHY_OBJECT_BEFORE_BACK":
      return state;
    default:
      return state;
  }
}

function geographyCostHierarchy(state = defaultGeographyCostHierarchy, action) {
  switch (action.type) {
    case "UPDATE_GEOGRAPHY_COST_HIERARCHY":
      if (action.clear) {
        let geographyCostHierarchy = new Map();
        return geographyCostHierarchy;
      }
      let geographyCostHierarchy = new Map(state);
      geographyCostHierarchy.set(action.key, action.geographyCostHierarchy);
      return geographyCostHierarchy;
    case "GET_GEOGRAPHY_COST_HIERARCHY":
      return state;
    default:
      return state;
  }
}

function geographyListData(state = defaultGeographyListData, action) {
  switch (action.type) {
    case "UPDATE_GEOGRAPHY_LIST_DATA":
      if (action.clear) {
        let geographyListDataMap = new Map();
        return geographyListDataMap;
      }
      let geographyListDataMap = new Map(state);
      geographyListDataMap.set(action.key, action.geographyListData);
      return geographyListDataMap;
    case "GET_GEOGRAPHY_LIST_DATA":
      return state;
    default:
      return state;
  }
}

export { geographyObjBeforeBack, geographyCostHierarchy, geographyListData };
