const defaulDrillListData = [];
const defaultListData = [];
const defaultListObj = [];

function drillListData(state = defaulDrillListData, action) {
  switch (action.type) {
    case "UPDATE_DRILL_LIST_DATA":
      if (action.clear) {
        let drillListDataMap = new Map();
        return drillListDataMap;
      }
      let drillListDataMap = new Map(state);
      drillListDataMap.set(action.key, action.drillListData);
      return drillListDataMap;
    default:
      return state;
  }
}

function listData(state = defaultListData, action) {
  switch (action.type) {
    case "UPDATE_LIST_DATA":
      let listDataMap = new Map(state);
      listDataMap.set(action.key, action.listData);
      return listDataMap;
    default:
      return state;
  }
}
function listObjBeforeBack(state = defaultListObj, action) {
  switch (action.type) {
    case "UPDATE_LIST_OBJ_BEFORE_BACK":
      let listObjData = new Map(state);
      listObjData.set(action.key, action.listObjBeforeBack);
      return listObjData;
    default:
      return state;
  }
}

export { drillListData, listData, listObjBeforeBack };