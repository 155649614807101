import Gradient from 'javascript-color-gradient';
import 'jquery';
import 'jqueryui';
import React, { Component } from "react";
import { interpolateFromTo } from "../../class/colorInterpolation";
import { ALL_REPORTS, ALL_WIDGETS, BUTTON_TYPE, BUTTON_VARIANT, CONFIG_SETTINGS, costtype, DROPDOWN_TYPE, FormatTypes, MENU_ITEM, QUADRANTS, SEGMENTS_TITLES, SELECTED_VECTOR_VIEWS, session_storage_keys, SIZES, VECTOR_ANALYSIS } from "../../class/constants";
import { formatValString } from "../../class/format";
import { convertPxToViewport } from "../../class/formatting";
import { getSectionExists, getTranslationFile } from "../../class/utils";
import { deepCompareObjects } from '../../class/utils.js';
import { lang } from '../../language/messages_en';
import '../../styles/heatMap.css';
import EntitiesDropDown from "./entityListDropDown/EntitiesDropDown";
import NewButton from './entityListDropDown/NewButton';
import DropDown from '../../newComponents/DropDown';
import Button from '../../newComponents/Button';
import { Segment } from '../../components/Segment';

const $ = require("jquery");
const Inflector = require('inflected');

const ENTITY = 'Entity';
const THRESHOLD = "threshold";
const dimensionBackground = "#D3D3D3";
const totalBackground = "#F3F3F3";
const isSVG = true;
var columnTooltip = "";
const ENTITY_COUNT1 = "entityCount";
const ENTITY_COUNT2 = "entity2Count";
const ENTITY_LINES = "linesCount";
const _netrevenue = "netrevenue";
const _lines = "lines";
const MESSAGES = getTranslationFile();

const _entityGroups = MESSAGES.heatmap_configure.view_groups;
const _groupedEntities = MESSAGES.heatmap_configure.entities.grouped_entities;
const tooltipHeight = 300;
const tooltipWidth = 450;
class HeatMapTable extends Component {
    constructor(props) {
        super(props);
        this.state ={
            data: [],
            // threshold : props.threshold,
            profitStackLines: [],
            dimensionsLabel: [],
            rowView: props.vectorOptions && props.vectorOptions.filter(e => e.value === props.firstDimension)[0]?.generated_quarters === 'ALL_NOT_GENERATED' ? _entityGroups.entity.value : sessionStorage.getItem(session_storage_keys.heatmap_row_view) || _entityGroups.default.value,
            columnView: props.vectorOptions && props.vectorOptions.filter(e => e.value === props.secondDimension)[0]?.generated_quarters === 'ALL_NOT_GENERATED' ? _entityGroups.entity.value : sessionStorage.getItem(session_storage_keys.heatmap_column_view) || _entityGroups.default.value,
            threshold: props.threshold || sessionStorage.getItem(session_storage_keys.heatmap_threshold) || 0,
            showEntitiesDropDown: false,
            showColumnEntitiesDropDown: false
        };
        this.getTableCell = this.getTableCell.bind(this);
        this.getTotalCell = this.getTotalCell.bind(this);
        this.getDimensionCell = this.getDimensionCell.bind(this);
        this.getNumeratedValues = this.getNumeratedValues.bind(this);
        this.setLimit = this.setLimit.bind(this);
        this.showTooltip = this.showTooltip.bind(this);
        this.showDetailsTooltip = this.showDetailsTooltip.bind(this);
        this.hideDetailsTooltip = this.hideDetailsTooltip.bind(this);
        this.setTableData = this.setTableData.bind(this);
        this.handleChangeDefaultLine = this.handleChangeDefaultLine.bind(this);
        this.handleChangeView = this.handleChangeView.bind(this);
        this.getConfigureData = this.getConfigureData.bind(this);
        // this.applyChanges = this.applyChanges.bind(this);

        this.stateChanged = false;
    }

    componentDidMount() {
        let _this = this;
        // $('.detailsTooltip').hide();
        // $(".totalsTooltip").hide();
        if(this.props.data){
            this.setTableData(this.props.data);
            this.stateChanged = true;
        }
        $(document).on("click", this.hideDetailsTooltip);
    }


    componentWillUnmount() {
        $(document).off("click", this.hideDetailsTooltip);
    }

    componentDidUpdate(prevProps) {
        if((prevProps.data && this.props.data && JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data))){
            this.setTableData(this.props);
            this.stateChanged = false;
        }
        if(prevProps.threshold !== this.props.threshold ) {
            this.setState({
                threshold: this.props.threshold
            });
        }
        if(prevProps.pss_default_line !== this.props.pss_default_line ) {
            this.setState({
                pss_default_line: this.props.pss_default_line
            });
        }

        if(JSON.stringify(this.props.firstDimOrder) !== JSON.stringify(prevProps.firstDimOrder)) {
            this.setState({
                firstDimOrder: prevProps.firstDimOrder
            })
        }
        if(JSON.stringify(this.props.secondDimOrder) !== JSON.stringify(prevProps.secondDimOrder)) {
            this.setState({
                secondDimOrder: prevProps.secondDimOrder
            })
        }
       
    }

    static getDerivedStateFromProps(nextProps, state) {
        var props = nextProps || state;
        var tempState={};

        if (props.data !== undefined && props.data !== state.data) {
            tempState = {
                data: props.data,
                threshold: props.threshold === "" ? 0 : props.threshold
            }
            tempState.pss_default_line = props.ps_default_line;
            tempState.rowView = props.vectorOptions && props.vectorOptions.filter(e => e.value === props.firstDimension)[0]?.generated_quarters === 'ALL_NOT_GENERATED' ? _entityGroups.entity.value : sessionStorage.getItem(session_storage_keys.heatmap_row_view) || _entityGroups.default.value;
            tempState.columnView = props.secondDimension && props.vectorOptions && props.vectorOptions.filter(e => e.value === props.secondDimension)[0].generated_quarters === 'ALL_NOT_GENERATED' ? _entityGroups.entity.value : sessionStorage.getItem(session_storage_keys.heatmap_column_view) || _entityGroups.default.value;
            tempState.threshold = sessionStorage.getItem(session_storage_keys.heatmap_threshold) || props.threshold;
        }
        if (props.profitStackLines !== state.profitStackLines) {
            tempState.profitStackLines = props.profitStackLines;
            tempState.shown_ps_line = props.ps_default_line;
        }
        if (props.ps_default_line !== state.ps_default_line) {
            tempState.shown_ps_line = props.ps_default_line;
        }

        return tempState;
    }

    shouldComponentUpdate(nextProps, nextState) {
        let shouldUpdate = nextProps.data && !deepCompareObjects(nextProps.data, this.props.data) || this.stateChanged || nextState.threshold !== this.state.threshold || nextProps.threshold !== this.props.threshold 
        || nextState.showRowEntitiesDropDown !== this.state.showRowEntitiesDropDown || nextState.showColumnEntitiesDropDown !== this.state.showColumnEntitiesDropDown || nextState.pss_default_line !== this.state.pss_default_line || nextProps.pss_default_line !== this.props.pss_default_line || JSON.stringify(this.props.tempSelectedEntitiesObj) !== JSON.stringify(nextProps.tempSelectedEntitiesObj);
        return shouldUpdate;
    }
    
    setTableData(nextProps) {
        var tempState = {};
        var props = nextProps;
        if (props.data !== undefined && props.data !== this.state.data) {
            tempState = {
                data: props.data,
                threshold: this.props.threshold === "" ? 0 : this.props.threshold
            }
            tempState.pss_default_line = props.ps_default_line;
            tempState.rowView = sessionStorage.getItem(session_storage_keys.heatmap_row_view) || _entityGroups.default.value;
            tempState.columnView = sessionStorage.getItem(session_storage_keys.heatmap_column_view) || _entityGroups.default.value;
            tempState.threshold = sessionStorage.getItem(session_storage_keys.heatmap_threshold) || props.threshold;
        }
        if (props.profitStackLines !== this.state.profitStackLines) {
            tempState.profitStackLines = props.profitStackLines;
            tempState.shown_ps_line = props.ps_default_line;
        }
        

        this.setState(tempState);
    }

    /**
     * Returns an array of gradient colors from startColor to endColor
     * @param {*} startColor 
     * @param {*} endColor 
     * @param {*} midpoint number of colors to get
     * @returns 
     */
    getGradientColors = (startColor, endColor, midpoint) => {
        return new Gradient().setColorGradient(startColor, endColor).setMidpoint(midpoint).getColors();
    }

    generateColorsForPositiveValues = (startColor, endColor, colorVal) => {
        let _this = this;
        let colors = _this.getGradientColors(endColor, startColor, 5);
        let hasContrast = _this.props.colorCodes[CONFIG_SETTINGS.HEATMAP_POSITIVE_START_COLOR_CODE] === startColor;
        let color = "";
        let fontColor = "black";
                
        if (parseFloat(colorVal) < 0.02) {
            color = interpolateFromTo(colors[0],colors[1], parseFloat(colorVal*50));
        } else if (parseFloat(colorVal) < 0.05) {
            color = interpolateFromTo(colors[1],colors[2], parseFloat(colorVal*20));
            fontColor = hasContrast ? "white" : "black";
        } else if (parseFloat(colorVal) < 0.1) {
            color = interpolateFromTo(colors[2],colors[3], parseFloat(colorVal*10));
            fontColor = hasContrast ? "white" : "black";
        } else {
            color = interpolateFromTo(colors[3],colors[4], parseFloat(colorVal));
            fontColor = hasContrast ? "white" : "black";
        }

        return {color: color, fontColor: fontColor};
    }

    generateColorsForNegativeValues = (startColor, endColor, colorVal) => {
        let _this = this;
        let colors = _this.getGradientColors(endColor, startColor, 6);
        let hasContrast = _this.props.colorCodes[CONFIG_SETTINGS.HEATMAP_POSITIVE_START_COLOR_CODE] === startColor;
        let color = "";
        let fontColor = "black";

        if (parseFloat(colorVal) > -0.01) {
            color = interpolateFromTo(colors[0],colors[1], Math.abs(parseFloat(colorVal*100)));
        } else if (parseFloat(colorVal) > -0.02) {
            color = interpolateFromTo(colors[1],colors[2], Math.abs(parseFloat(colorVal*50)));
            fontColor = hasContrast ? "white" : "black";
        } else if (parseFloat(colorVal) > -0.05) {
            color = interpolateFromTo(colors[2],colors[3], Math.abs(parseFloat(colorVal*20)));
            fontColor = hasContrast ? "white" : "black";
        } else if (parseFloat(colorVal) > -0.1) {
            color = interpolateFromTo(colors[3],colors[4], Math.abs(parseFloat(colorVal*10)));
            fontColor = hasContrast ? "white" : "black";
        } else {
            color = interpolateFromTo(colors[4],colors[5], Math.abs(parseFloat(colorVal)));
            fontColor = hasContrast ? "white" : "black";
        }

        return {color:color, fontColor: fontColor};
    }

    getTableCell(cell, isSVG, threshold, index, rowVec, col, isRevenueOrCalculated){
        var _this = this;
        let colorCodes = _this.props.colorCodes;
        var value = cell ? cell.defaultLinePerc : 0;     //show the value of the chosen psl, or if it is undefined, show the value of the default psl
        var color = colorCodes[CONFIG_SETTINGS.HEATMAP_NEUTRAL_COLOR_CODE];
        let fontColor = "black";

        var bold = "";
        if (parseFloat(value) < -5 || parseFloat(value) > 15) {
            bold = "bold";
        }
        var displayValue = formatValString(value, FormatTypes.PERCENTAGE);
        var colorVal = Math.abs(value) > 100 ? value/Math.abs(value) : value/100;

        if ((threshold === 0 || (threshold !== 0 && Math.abs(value) > threshold)) && displayValue !== "-") {
            if(!isRevenueOrCalculated) { // if the stack is default and the selected line is any line net revenue or calculated
                if (parseFloat(value) > 0) {
                    let startNegativeColor = colorCodes[CONFIG_SETTINGS.HEATMAP_NEGATIVE_START_COLOR_CODE];
                    let endNegativeColor = colorCodes[CONFIG_SETTINGS.HEATMAP_NEGATIVE_END_COLOR_CODE];
                    
                    color = _this.generateColorsForPositiveValues(startNegativeColor, endNegativeColor, colorVal).color;
                    fontColor = _this.generateColorsForPositiveValues(startNegativeColor, endNegativeColor, colorVal).fontColor;
                } else {
                    let startPositiveColor = colorCodes[CONFIG_SETTINGS.HEATMAP_POSITIVE_START_COLOR_CODE];
                    let endPositiveColor = colorCodes[CONFIG_SETTINGS.HEATMAP_POSITIVE_END_COLOR_CODE];
                    
                    color = _this.generateColorsForNegativeValues(startPositiveColor, endPositiveColor, colorVal).color;
                    fontColor = _this.generateColorsForNegativeValues(startPositiveColor, endPositiveColor, colorVal).fontColor;
                }
            } else { // if custom stack or is default stack and the selected line is net revenue or calculated
                if (parseFloat(value) > 0) {
                    let startPositiveColor = colorCodes[CONFIG_SETTINGS.HEATMAP_POSITIVE_START_COLOR_CODE];
                    let endPositiveColor = colorCodes[CONFIG_SETTINGS.HEATMAP_POSITIVE_END_COLOR_CODE];
                    
                    color = _this.generateColorsForPositiveValues(startPositiveColor, endPositiveColor, colorVal).color;
                    fontColor = _this.generateColorsForPositiveValues(startPositiveColor, endPositiveColor, colorVal).fontColor;
                } else {
                    let startNegativeColor = colorCodes[CONFIG_SETTINGS.HEATMAP_NEGATIVE_START_COLOR_CODE];
                    let endNegativeColor = colorCodes[CONFIG_SETTINGS.HEATMAP_NEGATIVE_END_COLOR_CODE];
    
                    color = _this.generateColorsForNegativeValues(startNegativeColor, endNegativeColor, colorVal).color;
                    fontColor = _this.generateColorsForNegativeValues(startNegativeColor, endNegativeColor, colorVal).fontColor;
                }
            }
        } else {
            displayValue = "-";
            color = colorCodes[CONFIG_SETTINGS.HEATMAP_NEUTRAL_COLOR_CODE];
        }
        var cursor = displayValue === "-" ? "" : "pointer";  
        if(isSVG) {
            return  (
                <td style={{backgroundColor:color, position:"relative", fontWeight:bold, cursor:cursor, color: fontColor}} onClick={(e)=>{_this.showDetailsTooltip(e, cell, rowVec, col, cursor)}} key={"td_"+index}>
                    {/* <svg xmlns="http://www.w3.org/2000/svg" version="1.1" 
                    style={{width: "100%", height: "100%", position: "absolute", left: "0px", top: "0px", zIndex: "-1"}}>
                    <rect width="100%" height="100%" fill={color}></rect>
                    </svg> */}
                    {displayValue}
                </td>
            );
        }
        return  (
            <td style={{backgroundColor:color, textAlign:"center", fontWeight:bold, cursor:cursor, color: fontColor}} onClick={(e)=>{_this.showDetailsTooltip(e, cell, rowVec, col, cursor)}} key={"td_"+index}>
                {displayValue}
            </td>
        );
    }

    getTotalCell(cell, isSVG, index, isBold, tooltip=undefined){
        var value = cell ? cell.total : 0;
        var color = totalBackground;
        var bold = isBold ? "800" : "";
        var redClass = value < 0 ? "red" : "";
        // if(isSVG) {
        //     return  (        
        //         <td uk-tooltip={tooltip} style={{position: "relative", fontWeight: bold}} key={"key_"+index} className={redClass}>
        //             <svg xmlns="http://www.w3.org/2000/svg" version="1.1" 
        //             style={{width: "100%", height: "100%", position: "absolute", left: "0px", top: "0px", zIndex: "-1"}}>
        //             <rect width="100%" height="100%" fill={color}></rect>
        //             </svg>
        //             {formatValString(value, FormatTypes.PERCENTAGE)}
        //         </td>
        //     );
        // }
        return  (
            <td uk-tooltip={tooltip} style={{backgroundColor: color, textAlign:"center", fontWeight: bold}} key={"key_"+index} className={redClass}>
                {formatValString(value, FormatTypes.PERCENTAGE)}
            </td>
        );
    }

    getDimensionCell(value, isSVG, isCol, onClick, index=""){
        let segment = new Segment();
        let segmentObj = segment.getSegmentObject(value);
        var color = value === "Total" ? dimensionBackground : segmentObj?.color; //this.getQuadrantColor(value, dimensionBackground);
        let qtStyles = {overflowWrap: "anywhere"};
        let isEntity = ((isCol && this.props.addEntitiesDialog_column) || (!isCol && this.props.addEntitiesDialog_row)) && onClick;

        if (!isEntity) { // draw QT background
            qtStyles = { ...qtStyles, color: segmentObj?.textColor, backgroundColor: color};
        }
        var div = <div style={qtStyles} className={" segment uk-text-wrap uk-text-normal bold-text" + (!isEntity ? "" : " heatmap_cell")}>{segmentObj?.label || value}</div>;
        var th = <th style={{ backgroundColor: color, color: segmentObj?.textColor, textAlign: "-webkit-center" }} key={"key_" + value}>
            {div}
        </th>;
        if (onClick) {
            th = <th className='title-cells' index={index} style={{ cursor: "pointer", textAlign: "-webkit-center", backgroundColor: dimensionBackground }} key={"key_" + value} onClick={(e) => { this.showTooltip(e, value, isCol) }}>
                {div}
            </th>
        
        }
        return (th);
    }
    
    showTooltip(e, vectorRow, col) {
        var obj = this;

        /**** Calculating tooltip top & left for totals tooltip */
        const isRowTitle = !col;
        const cellRect = e.currentTarget.getBoundingClientRect();
        let containerRect = document.getElementById('main_report_container').getBoundingClientRect();
        let tableRect = document.getElementById('heatMapTable').getBoundingClientRect();

        if($('#heatMapTable').width() > $('#main_report_container').width()) {
          containerRect = tableRect;
        }

        let tooltipTop, tooltipLeft, arrowTop, arrowLeft;
        if (isRowTitle) {
          // For row titles, keep left constant but vary top
          tooltipLeft = cellRect.width;
          tooltipTop = cellRect.top - containerRect.top - (cellRect.height / 2) ;
          arrowTop = tooltipHeight / 2;  // adjust based on your tooltip height
          arrowLeft = 0;
        } else {
          // For column titles, keep top constant but vary left
          tooltipTop = cellRect.top - containerRect.top + (cellRect.height);
          tooltipLeft = cellRect.left - containerRect.left + (cellRect.width / 2);
          arrowTop = 0;
          arrowLeft = tooltipWidth / 2;  // adjust based on your tooltip width
        }

        $(".totalsTooltip").css({
          top: tooltipTop,
          left: tooltipLeft
      });
      /**** End Calculating tooltip top & left for totals tooltip */

        $(".totalsTooltip").hide();
        $(".detailsTooltip").hide();
    
        if (!col) { // called from first dimension entities cells 
            var entity = this.state.data.filter(e=>e[obj.props.firstDimension+ENTITY].value === vectorRow)[0][obj.props.firstDimension+ENTITY];
            this.stateChanged = true;
            this.setState({
                entityCount: entity ? entity[ENTITY_COUNT1] : 0,
                entityLines: entity ? entity[ENTITY_LINES] : 0,
                entityperc: entity ? (Number(entity[ENTITY_COUNT1]) / Number(entity["entity1CountTotal"]))*100 : 0,
                entityLinesPerc: entity ? entity.linesperc :0,
                isColumnTooltip: false,
                arrowTop: arrowTop,
                arrowLeft: arrowLeft
            });

          
        } else { // called from header second dimension entities cells
            var entity = this.state.data.filter(e=>e[obj.props.firstDimension+ENTITY].value === '')[0][vectorRow];
            this.stateChanged = true;
            this.setState({
                entityCount: entity ? entity[ENTITY_COUNT2] : 0,
                entityLines: entity ? entity[ENTITY_LINES] :0,
                entityperc: entity ? (Number(entity[ENTITY_COUNT2]) / Number(entity["entity2CountTotal"]))*100 : 0,
                entityLinesPerc: entity? entity.linesperc : 0,
                isColumnTooltip: true,
                arrowTop: arrowTop,
                arrowLeft: arrowLeft
            });
        }
        $(".totalsTooltip").show();
        if (col && $(".totalsTooltip").offset().top > $(e.currentTarget).offset().top) {
            $(".totalsTooltip").removeClass("arrow-top");
            $(".totalsTooltip").addClass( "arrow-top");
        } else {
            $(".totalsTooltip").removeClass("arrow-top");

        }
    }

    hideDetailsTooltip(e) {
        columnTooltip = "";
        let node = $(e.target);
        if (!(node.is("td") && node.parents("table.heatMapTable") 
            || (node.hasClass('__placeholder') || node.hasClass("dropdown_placholder") || node.hasClass('__control') || node.hasClass('__value-container') || node.hasClass('__single-value') 
        || node.hasClass('__indicator') || node.hasClass('fa-chevron-down') || node.hasClass('css-8mmkcg') || node.hasClass('header-table') || node.is('path') || node.hasClass('tooltip-footer-container')))) {   //do not hide if clicking on a different cell
             $(".detailsTooltip").hide();  // to remove comment
        }
        if(!(node.is("div") && node.parent().is(".title-cells"))) {   //do not hide if clicking on a different cell
            $(".totalsTooltip").hide();
        }
    }
    
    showDetailsTooltip (e, cell, rowVect, col, cursor) {
        if (cursor === "") {
            return;
        }
        var _this = this;

        /**** Calculating tooltip top & left */
        let isTableHeightLargerThanContainer = $('#heatMapTable').height() > $('#main_report_container').height();
        let isTableWidthLargerThanContainer = $('#heatMapTable').width() > $('#main_report_container').width();

        const cellRect = e.target.getBoundingClientRect();
        let containerRect = document.getElementById('main_report_container').getBoundingClientRect();
        let tableRect = document.getElementById('heatMapTable').getBoundingClientRect();

        let arrowTopOffset = isTableHeightLargerThanContainer && isTableWidthLargerThanContainer ? 110 : 130;
        const leftScroll = document.getElementsByClassName('heatMap-container')[0].scrollLeft;
        const scrollWidth = document.getElementsByClassName('heatMap-container')[0].scrollWidth;
        const clientWidth = containerRect.width;
        let rigthScroll = scrollWidth - clientWidth - leftScroll;
        rigthScroll = rigthScroll < 0 ? 0 : rigthScroll;

        let arrowDirection;
        let relativeLeft = cellRect.left - containerRect.left + cellRect.width + leftScroll;
       
        if (relativeLeft + tooltipWidth > containerRect.right) {
          arrowDirection = "right";
          relativeLeft = relativeLeft - cellRect.width - 260;
        } else{
          arrowDirection = "left";
        }

        if(isTableHeightLargerThanContainer) {
          containerRect = tableRect;
        }

        let relativeTop = cellRect.top - containerRect.top;
        let arrowTop = cellRect.height / 2
        
        if (relativeTop + tooltipHeight > containerRect.height) {
          arrowTop =  (cellRect.top - tooltipHeight - arrowTopOffset) + (cellRect.height / 2);
          relativeTop = containerRect.height - tooltipHeight;
        }
        /**** End of Calculating tooltip top & left */


        let arr = []
        arr.push({value:_this.props.firstDimension, label:_this.props.firstDimensionLabel},{value:_this.props.secondDimension,label:_this.props.secondDimensionLabel});
        _this.setState({
            dimensionsLabel: arr
        })
        
        var evt = $(e.currentTarget);
        $(".totalsTooltip").hide();
        $(".detailsTooltip").show();
        this.stateChanged = true;
        this.setState({
            firstDimensionEntity: rowVect,
            secondDimensionEntity: col,
            profit: cell ? cell.machineValue : "-",
            profitPerc: cell ? formatValString(cell.machineValuePerc, FormatTypes.PERCENTAGE) !== "" ? formatValString(cell.machineValuePerc, FormatTypes.PERCENTAGE): "-" : "-",
            defaultLine: cell? cell.defaultLine ? cell.defaultLine : "" :"",
            defaultLinePerc: cell ? cell.defaultLine ? formatValString(cell.defaultLinePerc, FormatTypes.PERCENTAGE) !== "" ? formatValString(cell.defaultLinePerc, FormatTypes.PERCENTAGE): "-" : "" : "-",
            revenue: cell ? cell.revenue : "-",
            revenuePerc: cell ? formatValString(cell.revenueperc, FormatTypes.PERCENTAGE) !== "" ? formatValString(cell.revenueperc, FormatTypes.PERCENTAGE): "-" : "-",
            entityFirstDimCount: cell ? cell[ENTITY_COUNT1] : "-",
            entitySecondDimCount: cell ? cell[ENTITY_COUNT2] : "-",
            entityFirstDimCountPerc: cell ? formatValString((Number(cell[ENTITY_COUNT1]) / Number(cell["entity1CountTotal"]))*100, FormatTypes.PERCENTAGE) !== "" ? formatValString((Number(cell[ENTITY_COUNT1]) / Number(cell["entity1CountTotal"]))*100, FormatTypes.PERCENTAGE) : "-" : "-",
            entitySecondDimCountPerc: cell ? formatValString((Number(cell[ENTITY_COUNT2])/ Number(cell["entity2CountTotal"]))*100, FormatTypes.PERCENTAGE) !== "" ? formatValString((Number(cell[ENTITY_COUNT2])/ Number(cell["entity2CountTotal"]))*100, FormatTypes.PERCENTAGE): "-" : "-",
            entityLines: cell ? cell[ENTITY_LINES] : "-",
            entityLinesPerc: cell ? formatValString(cell.linesperc, FormatTypes.PERCENTAGE)!== "" ? formatValString(cell.linesperc, FormatTypes.PERCENTAGE) : "-" : "-",
            arrowDirection: arrowDirection,
            arrowTop: arrowTop,
          },function(){
            // if (evt.offset().left - ($(".detailsTooltip").width()*2) < 0) {
                // $(".detailsTooltip").css({
                //     top: evt.offset().top + evt.height() - ($(".detailsTooltip").height() / 2.5),
                //     left: evt.offset().left + evt.outerWidth() + 10
                // });
            //     $(".detailsTooltip").addClass("arrow-left");
            // } else {
            //     $(".detailsTooltip").css({
            //         top: evt.offset().top + evt.height() - ($(".detailsTooltip").height() / 2.5),
            //         left: evt.offset().left - $(".detailsTooltip").width() - 47
            //     });
            //     $(".detailsTooltip").removeClass("arrow-left");
            // }
            $(".detailsTooltip").css({
              top: relativeTop,
              left: relativeLeft
          });
          

        });
        if(this.props.setClickedCell) {
            this.props.setClickedCell(rowVect, col, cell);
        }
    }
    
    showSliderPopUp() {
        $(".threshold-slider-div").toggle();
        if ($(".threshold-slider-div")[0].style.display === "block") {
            $(".thresh-div").addClass("background-dark-grey");
        } else{
            $(".thresh-div").removeClass("background-dark-grey");
        }
    }

    setLimit(value) {
        this.stateChanged = true;
        this.setState({
            threshold: value
        }, function() {
            $('.totalsTooltip').hide();
            $('.detailsTooltip').hide();
        });
        // this.props.setThreshold(value);
    }

    getNumeratedValues(startPoint, incrementVal, endPoint) {
        var values = endPoint - startPoint;
        var divs = [];
        for (var i=0.0; i<=values; i=i+incrementVal) {
            divs.push(<div className="uk-display-inline-block" key = {i} id={i.toString().replace('.','')+"_percentage"}>{i +"%"}&nbsp; &nbsp;</div>);
        }
        return divs;
    }

    handleChangeDefaultLine(option) {
        if(option !== null) {
            this.stateChanged = true;
            // sessionStorage.setItem(session_storage_keys.heatmap_ps_line, JSON.stringify(option));   //save the chosen line in sessionStorage
            this.setState({
                pss_default_line: option
            })
        }
    }

    handleChangeView(option, axis) {
        let tempState = {};
        if(axis === "row") {
            tempState.rowView = option.value;
            // sessionStorage.setItem(session_storage_keys.heatmap_row_view, option.value);
        } else {
            tempState.columnView = option.value;
            // sessionStorage.setItem(session_storage_keys.heatmap_column_view, option.value);
        }
        this.stateChanged = true;
        this.setState(tempState);
    }

    getConfigureData() {
        return {
            psLine: this.state.pss_default_line,
            rowView: this.state.rowView,
            columnView: this.state.columnView
        }
    }
    
    // applyChanges() {
    //     $(".thresh-div").removeClass("background-dark-grey");
    //     $("#configure_dialog.heat-map").hide();      //hide the configure dialog
    //     this.setState({
    //         shown_ps_line: this.state.pss_default_line      //setting the new ps line shown in the table header
    //     });
    //     sessionStorage.setItem(session_storage_keys.heatmap_ps_line, JSON.stringify(this.state.pss_default_line));   //save the chosen line in sessionStorage
    //     sessionStorage.setItem(session_storage_keys.heatmap_row_view, this.state.rowView);
    //     sessionStorage.setItem(session_storage_keys.heatmap_column_view, this.state.columnView);
    //     sessionStorage.setItem(session_storage_keys.heatmap_threshold, this.state.threshold);
        
    //     this.props.fetchData(this.state.pss_default_line, {rowView: this.state.rowView, columnView: this.state.columnView});
    // }

    simplifyTitleName = (name) => {
        switch(name) {
            case SELECTED_VECTOR_VIEWS.QUADRANT_TIER:
                return "QT";
            case SEGMENTS_TITLES.PROFIT_TIERS.variableName:
                return SEGMENTS_TITLES.PROFIT_TIERS.abv;
            case SEGMENTS_TITLES.PROFIT_SEGMENTS.value:
                return SEGMENTS_TITLES.PROFIT_SEGMENTS.abv;
            case ENTITY.toLowerCase():
                return "";
        }
    }

    getTooltipDimLabel = (dimType, dimValue) => {
      let segment = new Segment();

      switch (dimType) {
        case _entityGroups.quadrant_tier.value:
        case SEGMENTS_TITLES.PROFIT_TIERS.variableName:
        case SEGMENTS_TITLES.PROFIT_SEGMENTS.value:
          return segment.getSegmentObject(dimValue)?.label;
        default:
          return dimValue;
      }
    }

    render(){
        var obj = this;
        var data = this.props.data || [];
        var html = [];
        var header = [];
        var cellIndex = 0;
           
        if(data && data.length > 0) {
            var colsTotal = this.props.colsTotal;
            var rowsTotal = this.props.rowsTotal;
            var firstDimOrder = this.props.firstDimOrder.length > 0 ? this.props.firstDimOrder : this.state.firstDimOrder;
            var secondDimOrder = this.props.secondDimOrder.length > 0 ?  this.props.secondDimOrder : this.state.secondDimOrder;
            
            var cells = [];                
            secondDimOrder.forEach(function(col) {
                cellIndex++;
                cells.push(obj.getDimensionCell(col.col, isSVG, true, obj.showTooltip, cellIndex)); // setting header with entity titles
            });
            // var numeratedThresholdValues = this.getNumeratedValues(0.0,0.5,3.0);
            cells.push(obj.getDimensionCell("Total", isSVG, false)); //setting last column header
            var svgBackground = "";
            if(isSVG) {
                svgBackground = <svg xmlns="http://www.w3.org/2000/svg" version="1.1" 
                    style={{width: "100%", height: "100%", position: "absolute", left: "0px", top: "0px", zIndex: "-1"}}>
                    <rect width="100%" height="100%" fill={"white"}></rect>
                </svg>;
            }
            header.push(
                <tr key="firstHeaderKey">
                    <th rowSpan="2" style={{backgroundColor: dimensionBackground}}>
                        {svgBackground}
                        <span className="fs-12">{this.props.firstDimensionLabel + " " + this.simplifyTitleName(obj.props.dim1Type)}</span>
                        {this.props.addEntitiesDialog_row && (
                          <EntitiesDropDown
                            showEntitiesDropDown={this.state.showRowEntitiesDropDown}
                            id={"row-select-entities"}
                            columns={this.props.rowColumns}
                            entities={this.props.rowEntities}
                            limitEntities={this.props.limitEntities}
                            tempSelectedEntitiesObj={this.props.tempSelectedEntitiesObj}
                            entityDropDownClick={this.props.entityDropDownClick}
                            setSelectedEntities={this.props.setSelectedEntities}
                            setGroupByValue={this.props.setGroupByValue}
                            apply={this.props.applyThenfetchData}
                            resetToDefault={this.props.resetToDefault}
                            isChanged={this.props.tempSelectedEntitiesObj.isChangedRow}
                            isApplyDisabled={this.props.tempSelectedEntitiesObj.isRowApplyDisabled}
                          />
                        )}
                    </th>
                    <th colSpan={Object.keys(this.props.secondDimensionEntities).length} style={{backgroundColor: dimensionBackground}}> 
                        {svgBackground}
                        <div className="row uk-margin-remove uk-flex uk-flex-middle">
                            <div className="uk-flex uk-flex-middle col-md-3 col-md-offset-5 fs-12">
                                {this.props.secondDimensionLabel + " " + this.simplifyTitleName(this.props.dim2Type)}
                                {this.props.addEntitiesDialog_column && (
                                  <EntitiesDropDown
                                    showEntitiesDropDown={this.state.showColumnEntitiesDropDown}
                                    id={"column-select-entities"}
                                    columns={this.props.colColumns}
                                    entities={this.props.columnEntities}
                                    limitEntities={this.props.limitEntities}
                                    tempSelectedEntitiesObj={this.props.tempSelectedEntitiesObj}
                                    entityDropDownClick={this.props.entityDropDownClick}
                                    setSelectedEntities={this.props.setSelectedEntities}
                                    setGroupByValue={this.props.setGroupByValue}
                                    apply={this.props.applyThenfetchData}
                                    resetToDefault={this.props.resetToDefault}
                                    isChanged={this.props.tempSelectedEntitiesObj.isChangedColumn}
                                    isApplyDisabled={this.props.tempSelectedEntitiesObj.isColumnApplyDisabled}  
                                  />
                                )}
                            </div>
                            <div className="col-md-4 uk-text-normal fs-12 uk-padding-xsmall-right text-align-end">{MESSAGES.contour_map_configure_message}<span className="uk-text-bold">&nbsp;{MESSAGES.contour_map_configure_message_bold.replace('%T%', obj.props.threshold).replace('%L%', obj.state.shown_ps_line ?  obj.state.pss_default_line.label : "")}</span></div>
                        </div>
                    </th>
                    <th style={{backgroundColor: dimensionBackground}}>{svgBackground}</th>
                    {/* <th className="uk-display-flex uk-flex-right w100 uk-border-unset">
                        {svgBackground}
                        <div className="uk-button-icon transparent-bg dark-bg uk-text-normal uk-margin-small-right thresh-div" onClick={this.showSliderPopUp}>
                            <span>{MESSAGES.heatmap_configure.configure}</span>
                            <i className='far fa-chevron-down uk-margin-small-left' />
                        </div>
                        <div id="configure_dialog" className="threshold-slider-div heat-map">
                            {/* Row vector view settings 
                            <div className="uk-display-flex uk-padding-xxsmall-bottom uk-flex-middle">
                                <span className="fs-14">{MESSAGES.heatmap_configure.row_vector_view}</span>
                            </div>
                            <div uk-tooltip={this.props.vectorOptions.filter(e => e.value === this.props.firstDimension)[0].generated_quarters === 'ALL_NOT_GENERATED' ? lang.quadrants_not_generated : false}>
                            <CustomSelect
                                className="input-width-250 uk-cursor-pointer uk-padding-xsmall-bottom"
                                value={findOptionByKey(this.props.viewOptions, this.state.rowView)}
                                onChange={(option) => this.handleChangeView(option, "row")}
                                options={this.props.viewOptions}
                                formatOptionLabel={optionLabel}
                                isDisabled={this.props.vectorOptions.filter(e => e.value === this.props.firstDimension)[0].generated_quarters === 'ALL_NOT_GENERATED' ? true : false }
                            />
                            </div>
                            {/* Column vector view settings 
                            <div className="uk-display-flex uk-padding-xxsmall-bottom uk-flex-middle">
                                <span className="fs-14">{MESSAGES.heatmap_configure.column_vector_view}</span>
                            </div>
                            <div uk-tooltip={this.props.secondDimension && this.props.vectorOptions.filter(e => e.value === this.props.secondDimension)[0].generated_quarters === 'ALL_NOT_GENERATED' ? lang.quadrants_not_generated : false }>
                            {/* <CustomSelect
                                className="input-width-250 uk-cursor-pointer uk-padding-xsmall-bottom"
                                value={findOptionByKey(this.props.viewOptions, this.state.columnView)}
                                onChange={(option) => this.handleChangeView(option, "column")}
                                options={this.props.viewOptions}
                                formatOptionLabel={optionLabel}
                                isDisabled={this.props.secondDimension && this.props.vectorOptions.filter(e => e.value === this.props.secondDimension)[0].generated_quarters === 'ALL_NOT_GENERATED' ? true : false }
                            /> */}
                            {/* </div> */}
                            {/* Profit Stack Line 
                            <div className="uk-display-flex uk-padding-xxsmall-bottom uk-flex-middle">
                                <span className="fs-14">{MESSAGES.heatmap_configure.select_ps_line}</span>
                                <i className="fal fa-info-circle uk-margin-small-left" uk-tooltip={MESSAGES.psl_message} />
                            </div>
                            <CustomSelect
                                id="select-set"
                                className="input-width-250 uk-cursor-pointer uk-padding-xsmall-bottom"
                                value={this.state.pss_default_line}
                                onChange={(option) => this.handleChangeDefaultLine(option)}
                                options={this.state.profitStackLines}
                                formatOptionLabel={optionLabel}
                            />
                            {/* Threshold 
                            <div className="uk-display-flex uk-padding-xxsmall-bottom uk-flex-middle">
                                <span className="fs-14">{MESSAGES.heatmap_configure.threshold}</span>
                                <i className="fal fa-info-circle uk-margin-small-left" uk-tooltip={MESSAGES.threshold_message} />
                            </div>
                            <div id="threshold_slider" className="threshold-slider">
                                <Slider ref={el=>this.threshRef = el} id={"slider"} startPoint={0} endPoint={3} incrementVal={0.5} value={obj.state.threshold}
                                    setLimit={this.setLimit} type={THRESHOLD}/>
                            </div>
                            <div className="numerated-div">
                                {numeratedThresholdValues}
                            </div>
                            <button className="uk-button-primary uk-margin-small-top uk-float-right" onClick={()=>this.applyChanges()}>{MESSAGES.modal.buttons.apply}</button>
                        </div>
                    </th> */}
                </tr>
            );
            header.push(<tr key ="headerKey">{cells}</tr>);

            let revenueCostKey = this.props.revenueCostKey;
            let isRevenue = revenueCostKey ? this.state.shown_ps_line.path.startsWith(revenueCostKey + ",") : false;
            let isCalculated = this.state.shown_ps_line.costtype === costtype.calculated;
            let isDefaultStack = obj.props.profitStackViewId === 0;
            let isRevenueOrCalculated = isRevenue || isCalculated || !isDefaultStack;
            var index = 100;
            cellIndex = 0;
            firstDimOrder.forEach(function(row) {
                cells = [];
                cellIndex++;
                var key = "row_"+row.rowVec+"_Header";
                cells.push(obj.getDimensionCell(row.rowVec, isSVG, false, obj.showTooltip, cellIndex)); // setting first column with rowVectors eg: IOP:1 ...
                let currRowArr = data.filter(e=>e[obj.props.firstDimension+ENTITY] && e[obj.props.firstDimension+ENTITY].value === row.rowVec);
                var currRow = currRowArr.length > 0 ? currRowArr[0] : undefined;
                secondDimOrder.forEach(function(col) {
                    index++;
                    let value = currRow ? currRow[col.col] : 0;
                    cells.push(obj.getTableCell(value, isSVG, obj.state.threshold, index, row.rowVec, col.col, isRevenueOrCalculated));
                });
                var currTotal = rowsTotal.filter(e => e.rowVec === row.rowVec)[0];
                cells.push(obj.getTotalCell(currTotal, isSVG, ++index, true));
                html.push(<tr key ={key}>{cells}</tr>);
            });
    
            var sum1 = 0;
            let cellsTotals = [];
            cellsTotals.push(obj.getDimensionCell("Total", isSVG, false));
 
            secondDimOrder.forEach(function(col) {
                var currTotal = colsTotal.filter(e => e.col === col.col)[0];
                cellsTotals.push(obj.getTotalCell(currTotal, isSVG, ++index, true));
                sum1 += currTotal.total;
            });
            cellsTotals.push(obj.getTotalCell({ "total": sum1}, isSVG, ++index, true, "This number represents the total percentage of all cells within the map taking into consideration filters if any are defined."));//setting sum in last cell of last row and last column
            html.push(<tr key ="totalKey">{cellsTotals}</tr>);
        }
        if (html.length === 0 && this.props.data?.length === 0) {
            html.push(<h1 className="uk-margin-xlarge-top heatmap-center">No Data Available</h1>);
        }
        // $('body').append($('.totalsTooltip'));
        // $('body').append($('.detailsTooltip'));

        let firstDimensionEntity = this.state.firstDimensionEntity || "";
        let secondDimensionEntity = this.state.secondDimensionEntity || "";
        let firstEntityDisplay = this.getTooltipDimLabel(this.props.dim1Type, firstDimensionEntity);
        let secondEntityDisplay = this.getTooltipDimLabel(this.props.dim2Type, secondDimensionEntity);
        let isColumnTooltip = this.state.isColumnTooltip;
        let disabled = (firstEntityDisplay === (VECTOR_ANALYSIS.FIELDS.NA_NAME)) && (!this.props.addEntitiesDialog_row || !this.props.addEntitiesDialog_column) || (secondEntityDisplay === (VECTOR_ANALYSIS.FIELDS.NA_NAME)) && (!this.props.addEntitiesDialog_row || !this.props.addEntitiesDialog_column);
        let sectionExists = getSectionExists(this.props.userAllowedMenuLinks, MENU_ITEM.FIELDS.MANAGE_STACKS);
        let oldSectionExists = getSectionExists(this.props.userAllowedMenuLinks, ALL_WIDGETS.FIELDS.VIEW_STACK);
        sectionExists = sectionExists || oldSectionExists;
        return(
            <>
              <div className="totalsTooltip">
              <div className="hm-tooltip-arrow" style={{ top: `${this.state.arrowTop}px`, left: `${this.state.arrowLeft}px` }}></div>
                  <table>
                      <tbody>
                      <tr>
                          <th className='header-table'>Summary</th>
                          <th className='header-table'>Amount</th>
                          <th className='header-table'>% Total</th>
                      </tr>

                          <tr>
                              <td>Total Lines</td>
                              <td className="rightPadding">{formatValString(obj.state.entityLines, FormatTypes.BASIS_POINT)}&nbsp;</td>
                              <td className="rightPadding">{formatValString(obj.state.entityLinesPerc, FormatTypes.PERCENTAGE)}&nbsp;</td>
                          </tr>
                          <tr>
                              <td>{isColumnTooltip ?obj.props.secondDimensionLabel :obj.props.firstDimensionLabel} Count</td>
                              <td className="rightPadding">{isColumnTooltip ?formatValString(obj.state.entityCount, FormatTypes.BASIS_POINT) :formatValString(obj.state.entityCount, FormatTypes.BASIS_POINT)}</td>
                              <td className="rightPadding">{isColumnTooltip ?formatValString(obj.state.entityperc, FormatTypes.PERCENTAGE) : formatValString(obj.state.entityperc, FormatTypes.PERCENTAGE)}</td>

                          </tr>
                      </tbody>
                  </table>
              </div>
              <div className="detailsTooltip">
              <div className={`hm-tooltip-arrow ${this.state.arrowDirection}`} style={{ top: `${this.state.arrowTop}px` }} ></div>
                  <div className="dimension_text uk-padding-xxsmall-bottom">{obj.props.firstDimensionLabel +" " + Inflector.singularize(SEGMENTS_TITLES[obj.props.dim1Type?.toUpperCase()]?.label) +": " + firstEntityDisplay}</div>
                  <div className="dimension_text uk-padding-xxsmall-bottom">{obj.props.secondDimensionLabel + " " + Inflector.singularize(SEGMENTS_TITLES[obj.props.dim2Type?.toUpperCase()]?.label) +": " + secondEntityDisplay}</div>
                  <table>
                      <tbody>
                      <tr>
                          <th className='header-table'>Summary</th>
                          <th className='header-table'>Amount</th>
                          <th className='header-table'>% Total</th>
                      </tr>
                      { obj.state.defaultLine && obj.state.pss_default_line && obj.state.pss_default_line.value !== obj.props.machineValue.value 
                              && obj.state.pss_default_line.nameInFact !== this.props.defaultRevenue?.name_in_fact && obj.state.pss_default_line.value !== _lines?
                              <tr>
                                  <td>{obj.state.pss_default_line.label}</td>
                                  <td className="uk-padding-small-right">{formatValString(obj.state.defaultLine, obj.state.pss_default_line.format ? obj.state.pss_default_line.format : FormatTypes.AMOUNT)}</td>
                                  <td>{obj.state.defaultLinePerc }</td>
                              </tr>
                          :""}
                          {obj.props.machineValue ? 
                              <tr>
                                  <td>{obj.props.machineValue.label}</td>
                                  <td className="uk-padding-small-right">{formatValString(obj.state.profit, FormatTypes.AMOUNT)}</td>
                                  <td>{obj.state.profitPerc }</td>
                              </tr>
                          :""}
                          
                          <tr>
                              <td>Revenue</td>
                              <td className="uk-padding-small-right">{formatValString(obj.state.revenue, FormatTypes.AMOUNT)}</td>
                              <td>{obj.state.revenuePerc}</td>
                          </tr>
                          <tr>
                              <td>Lines</td>
                              <td className="uk-padding-small-right">{formatValString(obj.state.entityLines, FormatTypes.BASIS_POINT)}</td>
                              <td>{obj.state.entityLinesPerc}</td>
                          </tr>
                          <tr>
                              <td>{"Count " + obj.props.firstDimensionLabel}</td>
                              <td className="uk-padding-small-right">{formatValString(obj.state.entityFirstDimCount, FormatTypes.BASIS_POINT)}</td>
                              <td>{obj.state.entityFirstDimCountPerc}</td>
                          </tr>
                          <tr>
                              <td>{"Count " + obj.props.secondDimensionLabel}</td>
                              <td className="uk-padding-small-right">{formatValString(obj.state.entitySecondDimCount, FormatTypes.BASIS_POINT)}</td>
                              <td>{obj.state.entitySecondDimCountPerc}</td>
                          </tr>
                      </tbody>
                  </table>
                  {/* {firstEntityDisplay !== _groupedEntities || secondEntityDisplay !== _groupedEntities ?  //this condition should be removed when the filter is moved to session storage */}
                  {/* <React.Fragment> */}
                      <div className="uk-display-flex uk-flex-around uk-padding-xsmall-top-bottom">
                          {/* <button className="text-link-dark-bg" onClick={()=>this.props.onTooltipBtnClick(this.props.firstDimension)}>{MESSAGES.COMMON.LIST +" "+ this.props.firstDimensionLabel}</button> */}
                          {/* <button className="text-link-dark-bg" onClick={()=>this.props.onTooltipBtnClick(this.props.secondDimension)}>{MESSAGES.COMMON.LIST +" "+ this.props.secondDimensionLabel}</button> */}
                      </div>
                      {/* <div className="uk-display-flex uk-flex-center">
                          <button className="text-link-dark-bg" onClick={()=>this.props.runExtendedProfitStack(0, true,  ALL_WIDGETS.FIELDS.SELECT_STACK)}>{MESSAGES.ProfitStack}</button>
                      </div> */}
                  {/* </React.Fragment> */}
                  {/* :""} */}
                  <div className="tooltip-footer-container">
                    <div uk-tooltip={disabled ? "title:" + lang.not_supported_for_na : "title:"}>
                      <DropDown 
                          id="select-list"
                          className="button__dropdown"
                          disabled={disabled}
                          value={""}
                          name="row-column"
                          type={DROPDOWN_TYPE.BUTTON}
                          options={this.state.dimensionsLabel}
                          onChange={(e)=>{this.props.onTooltipBtnClick(e.value)}}
                          placeholder={lang.header.placeholders.select_list}
                          menuPlacement={"auto"}
                          menuWidth = {200}
                          dropDownWidth = {130}
                          firstAttr={"#select-list"}
                      />
                    </div>
                    {sectionExists?
                  <div uk-tooltip={disabled ? "title:" + lang.not_supported_for_na : "title:"}>
                        <Button 
                          label={lang.header.placeholders.view_stack}
                          variant={BUTTON_VARIANT.PRIMARY}
                          size={SIZES.DEFAULT}
                          type={BUTTON_TYPE.DEFAULT}
                          className={""}
                          disabled={disabled}
                          onBtnClick={()=>this.props.runExtendedProfitStack(obj.props.profitStackViewId, true,  ALL_WIDGETS.FIELDS.VIEW_STACK)}
                      /> 
                  </div> : ""}
                  </div>
              </div>
              <table id={"heatMapTable"} className="heatMapTable">
                <thead>
                  {header}
                </thead>
                <tbody>
                  {html}
                </tbody>
              </table>
            </>
        );
       
    }
}

export default HeatMapTable;