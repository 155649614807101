const defaultCustomGroups = [];
const defaultProfiles = [];
const defaultColumns = [];
const stateProfilesArr = [];
const columnProfilesArr = [];

function customGroups(state=defaultCustomGroups, action) { //reducer
    switch (action.type) {
        case "UPDATE_CUSTOM_GROUPS":
            if (action.customGroups.length === 0) {
                let customGroupsHM = new Map();
                return customGroupsHM;
            }
            let customGroupsHM = new Map(state);
            customGroupsHM.set(action.scenarioId, action.customGroups);
        return customGroupsHM;
        case "GET_CUSTOM_GROUPS":
          return state;
        default:
        return state;
    }
}

function profiles(state=defaultProfiles, action) { //reducer
    switch (action.type) {
        case "UPDATE_PROFILES":
            if (action.profiles.length === 0) {
                let profilesHM = new Map();
                return profilesHM;
            }
            let profilesHM = new Map(state);
            profilesHM.set(action.scenarioId, action.profiles);
        return profilesHM;
        case "GET_PROFILES":
          return state;
        default:
        return state;
    }
}

function profileColumns(state=defaultColumns, action) { //reducer
    switch (action.type) {
        case "UPDATE_COLUMNS":
            if (action.profileColumns.length === 0) {
                let columnsHM = new Map();
                return columnsHM;
            }
            let columnsHM = new Map(state);
            columnsHM.set(action.scenarioId, action.profileColumns);
        return columnsHM;
        case "GET_COLUMNS":
          return state;
        default:
        return state;
    }
}

function stateProfiles(state=stateProfilesArr, action) { //reducer
    switch (action.type) {
        case "UPDATE_STATE_PROFILES":
            let columnsHM = new Map(state);
            columnsHM.set(action.scenarioId, action.stateProfiles);
        return columnsHM;
        case "GET_STATE_PROFILES":
          return state;
        default:
        return state;
    }
}

function columnProfiles(state=columnProfilesArr, action) { //reducer
    switch (action.type) {
        case "UPDATE_COLUMN_PROFILES":
            let columnsHM = new Map(state);
            columnsHM.set(action.scenarioId, action.columnProfiles);
        return columnsHM;
        case "GET_COLUMN_PROFILES":
          return state;
        default:
        return state;
    }
}

  
export {customGroups, profiles, profileColumns, stateProfiles, columnProfiles};