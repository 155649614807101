import PropTypes from "prop-types";
import React, { useState } from "react";
import { IconTitle } from "../../templateLayout/IconTitle";
import "./accordion.css";

const Accordion = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const onItemClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="accordion">
      {items?.map((item, index) => (
        <div key={`accordion-item-${index}`} className={`accordion-item ${item.contentComponent ? "expandable" : ""}`}>
          {item.contentComponent ? (
            <>
              <AccordionTitleComponent key={`accordion-title-item-${index}`} item={item.titleObj} isExpanded={index === activeIndex} onClick={() => onItemClick(index)} />
              <div key={item?.titleObj?.menu_item_machine_name} className={`accordion-content-container ${index === activeIndex ? "active" : ""}`}>
                <div className="content-container">
                  <div className="content">{item.contentComponent}</div>
                </div>
              </div>
            </>
          ) : (
            <div className="non-expandable-item">{item.titleComponent}</div>
          )}
        </div>
      ))}
    </div>
  );
};

const AccordionTitleComponent = ({ item, isExpanded, onClick }) => {
  return (
    <div className={`accordion-title-container`} onClick={onClick}>
      <IconTitle
        title={item?.menu_item_display_name}
        subtitle={item?.description}
        titleStyle={{ color: "#000000", fontSize: "0.74vw", fontWeight: 600, lineHeight: "normal" }}
        subtitleStyle={{ color: "rgba(98, 98, 98, 0.70)", fontSize: "0.63vw", lineHeight: "120%" }}
        titleContainerStyle={{ justifySelf: "center", userSelect: "none", width: "80%" }}
        iconTitleContainerStyle={{ width: "100%", cursor: "pointer", userSelect: "none" }}
        machine_name={item?.menu_item_machine_name}
        svgStyle={{ userSelect: "none", width: "2.14vw", backgroundColor: "#5F60C8", height: "2.14vw", position: "relative" }}
        icon={
          <>
            <img src={`/images/menu-svgs/${item.menu_item_machine_name}.svg`} alt="" />
            <div className="chevron-style">
              <img alt={""} src={`/images/menu-svgs/${isExpanded ? "chevron-up" : "chevron-down"}.svg`} />
            </div>
          </>
        }
      />
    </div>
  );
};

Accordion.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

AccordionTitleComponent.propTypes = {
  item: PropTypes.object.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
export default Accordion;
