import { useEffect, useState } from "react";
import { CONFIGURE_GL_ACCOUNT_RANGES  } from "../../class/constants";
import Input from "../../newComponents/Input";
import { Icon } from "@mui/material";
import { ReactComponent as Trash } from "../../styles/images/icons/trash-regular.svg";
import { hasLettersAndSpecialCharacters } from "../../class/utils";
import { removeSpacing } from "../../class/jqueries";


const ConfigureGlAccountRangesRow = (props) => {

    const [disableStart, setDisableStart] = useState(false);
    const [disableEnd, setDisableEnd] = useState(false);
    const [ranges, setRanges] = useState({});
    const [fromMount, setFromMount] = useState(false);
    const ACCOUNT_START_RANGE = CONFIGURE_GL_ACCOUNT_RANGES.KEYS.ACCOUNT_START_RANGE;
    const ACCOUNT_END_RANGE = CONFIGURE_GL_ACCOUNT_RANGES.KEYS.ACCOUNT_END_RANGE;
    const ID = props.count;
    

    useEffect(() => {
        if(props.data){
            let rangesState = {
                [ACCOUNT_START_RANGE]: props.data[ACCOUNT_START_RANGE],
                [ACCOUNT_END_RANGE]: props.data[ACCOUNT_END_RANGE]
            };
            if(props.data[ACCOUNT_START_RANGE + "_disabled"]){
                rangesState[ACCOUNT_START_RANGE + "_disabled"] = props.data[ACCOUNT_START_RANGE + "_disabled"]
            }
            if(props.data[ACCOUNT_END_RANGE + "_disabled"]){
                rangesState[ACCOUNT_END_RANGE + "_disabled"] = props.data[ACCOUNT_END_RANGE + "_disabled"]
            }
            setRanges(rangesState);
            if(hasLettersAndSpecialCharacters(props.data[ACCOUNT_START_RANGE])){
                if(!disableStart && hasLettersAndSpecialCharacters(props.data[ACCOUNT_END_RANGE])){
                    setDisableEnd(true);
                }
            }
            setFromMount(true);
        }
    }, [props.data]);


    useEffect(() => {
        if(!fromMount){
            props.setRowRanges(ranges, props.count);
        }
    }, [ranges]);


    const onInputChange = (field, e) => {
        setFromMount(false);
        let inputValue = removeSpacing(e.target.value);
        if(inputValue[0] == "-"){
            inputValue = inputValue.slice(1);
        }
        let prevInputHasChar = hasLettersAndSpecialCharacters(ranges[field]);
        let currInputHasChar = hasLettersAndSpecialCharacters(inputValue);
        if(currInputHasChar){
            setRanges({
                [ACCOUNT_START_RANGE]: inputValue,
                [ACCOUNT_END_RANGE]: inputValue,
                [ACCOUNT_START_RANGE + "_disabled"]: field === ACCOUNT_END_RANGE ? true : false,
                [ACCOUNT_END_RANGE + "_disabled"]: field === ACCOUNT_START_RANGE ? true : false
              });
        } else {
            if(prevInputHasChar && inputValue === ""){
                setRanges({
                    [ACCOUNT_START_RANGE]: inputValue,
                    [ACCOUNT_END_RANGE]: inputValue,
                    [ACCOUNT_START_RANGE + "_disabled"]: false,
                    [ACCOUNT_END_RANGE + "_disabled"]: false
                  });
            } else {
                setRanges({...ranges,
                    [field]: inputValue,
                    [ACCOUNT_START_RANGE + "_disabled"]: false,
                    [ACCOUNT_END_RANGE + "_disabled"]: false
                  });
            }
            
        } 
        
    }

    return (
        <div style={{display: "flex", columnGap:10}}>
            <Input
                className="form-control"
                id={"start-range-" + ID}
                name= {ACCOUNT_START_RANGE}
                type="text"
                autoComplete="off"
                value={ranges[ACCOUNT_START_RANGE]}
                disabled={ranges[ACCOUNT_START_RANGE+"_disabled"]}
                onChange={(e)=>{onInputChange(ACCOUNT_START_RANGE, e)}}
            />
            <Input
                className="form-control"
                id={"end-range-" + ID}
                name={ACCOUNT_END_RANGE}
                type="text"
                autoComplete="off"
                value={ranges[ACCOUNT_END_RANGE]}
                disabled={ranges[ACCOUNT_END_RANGE+"_disabled"]}
                onChange={(e)=>{onInputChange(ACCOUNT_END_RANGE, e)}}
            />
            {props.rowCount > 1 ? 
                <Trash onClick={()=>{props.removeRow(ID)}} className="uk-cursor-pointer icon-btn"/>                    
            :<Trash className="uk-cursor-pointer icon-btn disabled"/>  }
            
        </div>
    );
};

export default ConfigureGlAccountRangesRow;

















