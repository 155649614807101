import React,{Component} from "react";
import '../../styles/common.css';
import '../../styles/format.css';
import {Format} from '../../class/format';
import { CustomSelect } from '../../form/elements.js';
import "jqueryui";
import { getTranslationFile, findOptionByKey } from "../../class/utils";
import {alertAndLogError} from "../../class/jqueries";
import {DROPDOWN_TYPE, FORMAT_SETTINGS, FormatTypes, INFO_MESSAGE} from "../../class/constants.js";
import { convertPxToViewport } from "../../class/formatting";

import { setLocalStorageValueByParameter } from "../../class/common";
import Input from "../../newComponents/Input";
import DropDown from "../../newComponents/DropDown";
import RadioButton from "../../newComponents/RadioButton";
const $ = require("jquery");
const MESSAGES = getTranslationFile();


class FormatDisplay extends Component{
    constructor(props){
        super(props);

        this.userParams = [
            FORMAT_SETTINGS.AMOUNT_DECIMALS,
            FORMAT_SETTINGS.AMOUNT_MULTIPLIER,
            FORMAT_SETTINGS.RATIO_DECIMALS,
            FORMAT_SETTINGS.PERCENTAGE_DECIMALS,
            FORMAT_SETTINGS.NUMERIC_DECIMALS,
            FORMAT_SETTINGS.NUMERIC_MULTIPLIER
        ];
        this.clientParams = JSON.parse(JSON.stringify(this.userParams));
        this.clientParams.push(FORMAT_SETTINGS.CURRENCY_SIGN);
        this.clientParams.push(FORMAT_SETTINGS.CURRENCY_SIGN_POSITION);
        this.clientParams.push(FORMAT_SETTINGS.PERCENTAGE_SIGN_POSITION);
        this.clientParams.push(FORMAT_SETTINGS.NEGATIVE_VALUES_FORMAT);
        this.clientParams.push(FORMAT_SETTINGS.NEGATIVE_COLORING);
        this.clientParams.push(FORMAT_SETTINGS.NEGATIVE_COLOR);

        this.state = {
            loading:true
        };

        this.multiplierOptions = [{label:"None", value:"N/A", isDropDown:true}, {label:"Thousands", value:"K", isDropDown:true},{label:"Millions", value:"M", isDropDown:true},{label:"Billions", value:"B", isDropDown:true}];
        this.currencyOptions = [{label:"US Dollars ($)", value:"$", isDropDown:true},{label:"Euro (€)", value:"€", isDropDown:true},{label:"British Pound (£)", value:"£", isDropDown:true}, {label:"Chinese Yen (¥)", value:"¥", isDropDown:true}]

        this.format = new Format(this.state,function(){});


        this.getFormatChanges = this.getFormatChanges.bind(this);
        this.handleChangeFunction = this.handleChangeFunction.bind(this);
        this.fetchUserSettings = this.fetchUserSettings.bind(this);
        this.fetchClientSettings = this.fetchClientSettings.bind(this);
        this.setFields = this.setFields.bind(this);
        this.renderFormatCard = this.renderFormatCard.bind(this);
        this.renderFormatCardDecimals = this.renderFormatCardDecimals.bind(this);
        this.renderFormatCardMultiplier = this.renderFormatCardMultiplier.bind(this);
        this.getUserParamKeys = this.getUserParamKeys.bind(this);
        this.getClientParamKeys = this.getClientParamKeys.bind(this);
        this.adjustRatioDefaults = this.adjustRatioDefaults.bind(this);
        const comp = this;
        document.addEventListener("format_change", function(){
            if(comp._ismounted) {
                comp.state.lastUpdated = new Date().valueOf();
                comp.setFields();
            }
        });

    }

    getUserParamKeys(){
        this.userParams = [
            FORMAT_SETTINGS.AMOUNT_DECIMALS,
            FORMAT_SETTINGS.AMOUNT_MULTIPLIER,
            FORMAT_SETTINGS.RATIO_DECIMALS,
            FORMAT_SETTINGS.PERCENTAGE_DECIMALS,
            FORMAT_SETTINGS.NUMERIC_DECIMALS,
            FORMAT_SETTINGS.NUMERIC_MULTIPLIER];
        for(var att in this.ratio_sub_types){
            this.userParams.push(FORMAT_SETTINGS.RATIO_DECIMALS+":"+this.ratio_sub_types[att].key);
        }
        return this.userParams;
    }

    getClientParamKeys(){
        this.clientParams = [
            FORMAT_SETTINGS.AMOUNT_DECIMALS,
            FORMAT_SETTINGS.AMOUNT_MULTIPLIER,
            FORMAT_SETTINGS.RATIO_DECIMALS,
            FORMAT_SETTINGS.PERCENTAGE_DECIMALS,
            FORMAT_SETTINGS.NUMERIC_DECIMALS,
            FORMAT_SETTINGS.NUMERIC_MULTIPLIER,
            FORMAT_SETTINGS.CURRENCY_SIGN,
            FORMAT_SETTINGS.CURRENCY_SIGN_POSITION,
            FORMAT_SETTINGS.PERCENTAGE_SIGN_POSITION,
            FORMAT_SETTINGS.NEGATIVE_VALUES_FORMAT,
            FORMAT_SETTINGS.NEGATIVE_COLORING,
            FORMAT_SETTINGS.NEGATIVE_COLOR];
        for(var att in this.ratio_sub_types){
            this.clientParams.push(FORMAT_SETTINGS.RATIO_DECIMALS+":"+this.ratio_sub_types[att].key);
        }
        return this.clientParams;
    }


    setFields(callback){
        let ratio_sub_types = [];
        for(var att in this.state.sub_types){
            let s = this.state.sub_types[att];
            if(!s.returnname || !s.name)
                continue;
            ratio_sub_types.push({key:s.returnname, name:window._format.replaceCurrency(s.name)});
        }
        this.ratio_sub_types = ratio_sub_types;
        this.state.fields = [
            {
                name: MESSAGES.FORMATTING.RATIO.NAME,
                machine_name: MESSAGES.FORMATTING.RATIO.MACHINE_NAME,
                sample: MESSAGES.FORMATTING.RATIO.SAMPLE,
                format_type: FormatTypes.RATIO,
                children:ratio_sub_types,
                decimals: true,
                multiplier: false
            }, {
                name: MESSAGES.FORMATTING.AMOUNT.NAME,
                machine_name: MESSAGES.FORMATTING.AMOUNT.MACHINE_NAME,
                sample: MESSAGES.FORMATTING.AMOUNT.SAMPLE,
                format_type: FormatTypes.AMOUNT,
                decimals: true,
                multiplier: true
            }, {
                name: MESSAGES.FORMATTING.PERCENTAGE.NAME,
                machine_name: MESSAGES.FORMATTING.PERCENTAGE.MACHINE_NAME,
                sample: MESSAGES.FORMATTING.PERCENTAGE.SAMPLE,
                format_type: FormatTypes.PERCENTAGE,
                decimals: true,
                multiplier: false
            }, {
                name: MESSAGES.FORMATTING.NUMERIC.NAME,
                machine_name: MESSAGES.FORMATTING.NUMERIC.MACHINE_NAME,
                sample:MESSAGES.FORMATTING.NUMERIC.SAMPLE,
                format_type: FormatTypes.NUMERIC,
                decimals: false,
                multiplier: true
            }
        ];
        this.getUserParamKeys();
        this.getClientParamKeys();
        this.setState(this.state,function(){
            if(typeof callback === 'function'){
                callback();
            }
        });
    }

    adjustRatioDefaults(){
        for(var i  in this.ratio_sub_types){
            var sub_type = this.ratio_sub_types[i];
            var att = FORMAT_SETTINGS.RATIO_DECIMALS+":"+sub_type.key;
            if(this.state[att] === undefined){
                this.state[att] = this.state[FORMAT_SETTINGS.RATIO_DECIMALS];
            }
        }
    }

    fetchUserSettings(callback){
        this.clientParams = this.getClientParamKeys();
        this.userParams = this.getUserParamKeys();
        this.state["sub_types"] = window._format.sub_types;
        const comp = this;
        this.setFields(function(){
            for(let att in comp.clientParams){
                comp.state[comp.clientParams[att]] = window._format[comp.clientParams[att]];
            }
            for(let att in comp.userParams){
                comp.state[comp.userParams[att]] = window._format[comp.userParams[att]];
            }
            comp.adjustRatioDefaults();
            comp.oldState = JSON.parse(JSON.stringify(comp.state));
            comp.format.setData(comp.state);
            if(typeof callback === 'function') {
                callback();
            }
        });
    }

    fetchClientSettings(callback){
        console.log("format:", "fetchClientSettings");
        this.state.loading  = true;
        var baseUrl = process.env.REACT_APP_BASE_URL;
        var path = "/format";
        const comp = this;
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      this.setState(this.state, function(){
            fetch(`${baseUrl}${path}`, {body:JSON.stringify({action:"getClientFormatting"}), method:"POST", mode:'cors', credentials:'include'})
                .then((response)=>{
                    return response.json()})
                .then((data)=>{
                    comp.getUserParamKeys();
                    comp.getClientParamKeys();
                    for(let att in data){
                        comp.state[att] = data[att];
                    }
                    for(let att in comp.clientParams){
                        comp.state[comp.clientParams[att]] = data[comp.clientParams[att]];
                    }
                    for(let att in comp.userParams){
                        comp.state[comp.userParams[att]] = data[comp.userParams[att]];
                    }
                    comp.setFields(function() {
                        comp.adjustRatioDefaults();
                        comp.oldState = JSON.parse(JSON.stringify(comp.state));
                        comp.format.setData(comp.state);
                        comp.state.loading = false;
                        comp.setState(comp.state, callback);
                    });

                    // comp.setState(comp.state, callback);
                })
                .catch((error)=>{
                    alertAndLogError(error);
                    callback();
                    comp.state.loading = false;
                });
        });
    }

    handleChangeFunction(elemMachineName, valueObj, callback){
        const comp = this;
        var value = "";
        if(valueObj.isDropDown === true) {
            value = valueObj.value;
        } else {
            value = $(valueObj.currentTarget).val();
        }

        if (comp.state[elemMachineName] !== value) {
            comp.state[elemMachineName] = value;
        }
        this.format.setData(comp.state);
        comp.setState(comp.state, function () {
            if (typeof callback === 'function') {
                callback(value);
            }
        });
    }

    getFormatChanges(){
        var data = {};
        if(this.props.isUser){
            this.getUserParamKeys();
            for(let i = 0; i < this.userParams.length; i++) {
                let param = this.userParams[i];
                if(this.state[param] !== this.oldState[param]){
                    data[param] = this.state[param];
                }
            }
        } else {
            this.getClientParamKeys();
            for(let i = 0; i < this.clientParams.length; i++) {
                let param = this.clientParams[i];
                if(this.state[param] !== this.oldState[param]){
                    data[param] = this.state[param];
                }
            }
        }
        return data;
    }

    componentDidMount() {
        this._jquery_init = false;
        this._ismounted = true;
        const comp = this;
        if(this.props.isUser){
            this.state.loading = true;
            this.setState(this.state,function(){
                comp.fetchUserSettings(function(){
                    comp.state.loading = false;
                    comp.setState(comp.state);
                });
            });
        } else {
            this.state.loading = true;
            this.setState(this.state,function(){
                comp.fetchClientSettings(function(){
                    comp.state.loading = false;
                    comp.setState(comp.state);
                });
            });
        }
    }
    componentWillUnmount() {
        this._ismounted = false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const comp = this;
        if(!this._jquery_init){
            $('.formatDisplayContainer input.spinner').each(function(i, elem){
                var changeFunk = function(ev){
                    comp.handleChangeFunction($(elem).attr("elemMachineName"), {currentTarget:elem});
                };
                $(elem).spinner({
                    step:1,min:0,max:Number(comp.props.format_max_decimal),
                    change:changeFunk,
                    stop:changeFunk
                });
            });
            this.__jquery_init = true;
        }
    }

    renderFormatCardDecimals(elem, title, expand){
        const comp = this;
        return <div key={"dec_"+elem.machine_name+(elem.sub_type?":"+elem.sub_type:"")} className={"format-card-body "+(expand?"uk-width-expand":"")}>
                <div>
                    <div className="format-card-title">
                        {title?title:MESSAGES.FORMATTING.decimals} <i className="far fa-info-circle" uk-tooltip={INFO_MESSAGE}/>
                    </div>
                    <div className="format-card-content">
                        <Input type={"text"}
                               elemmachinename={"format_"+elem.machine_name + "_decimals"+(elem.sub_type?":"+elem.sub_type:"")}
                               className="spinner"
                               value={comp.state["format_"+elem.machine_name + "_decimals"+(elem.sub_type?":"+elem.sub_type:"")]}
                               onChange={(ev)=>{
                                   if($(ev.currentTarget).val() > Number(comp.props.format_max_decimal)){
                                       $(ev.currentTarget).val(Number(comp.props.format_max_decimal));
                                   } else if($(ev.currentTarget).val() < 0){
                                       $(ev.currentTarget).val(0);
                                   }
                                   if($(ev.currentTarget).val() >=0 && $(ev.currentTarget).val() <=Number(comp.props.format_max_decimal)){
                                       // nothing
                                   } else{
                                       $(ev.currentTarget).val(0);
                                   }
                               }}
                            // onClick={(e)=>comp.handleChangeFunctionElem( e)}
                            // onKeyPress={(e)=>comp.handleChangeFunctionElem( e)}
                        />
                    </div>
                    {elem.sub_type?<div>
                    <span className={"sample"}>
                            <span style={{whiteSpace:"nowrap"}}>{comp.format.formatValReact(elem.sample, elem.format_type+":"+elem.sub_type)}</span>
                            <span style={{marginLeft: convertPxToViewport(10), whiteSpace:"nowrap"}} >{comp.format.formatValReact(-elem.sample, elem.format_type+":"+elem.sub_type)}</span>
                        </span>
                    </div>:""}
                </div>
            </div>;
    }

    renderFormatCardMultiplier(elem, title, expand){
        const comp = this;
        return <div key={"mul_"+elem.machine_name+(elem.sub_type?":"+elem.sub_type:"")} className={"format-card-body "+(expand?"uk-width-expand":"")}>
            <div>
                <div className="format-card-title">
                    {title?title:MESSAGES.FORMATTING.multiplier} <i className="far fa-info-circle" uk-tooltip={INFO_MESSAGE}/>
                </div>
                <div className="format-card-content">
                    <DropDown
                        className="uk-width-small input__dropdown"
                        value={findOptionByKey(comp.multiplierOptions, comp.state["format_"+elem.machine_name + "_multiplier"+(elem.sub_type?":"+elem.sub_type:"")])}
                        options={comp.multiplierOptions}
                        onChange={(valueObj)=>comp.handleChangeFunction("format_"+elem.machine_name + "_multiplier"+(elem.sub_type?":"+elem.sub_type:""), valueObj)}
                        type={DROPDOWN_TYPE.INPUT}
                    />
                </div>
                {elem.sub_type?<div>
                    <span className={"sample"}>
                            <span style={{whiteSpace:"nowrap"}}>{comp.format.formatValReact(elem.sample, elem.format_type+":"+elem.sub_type)}</span>
                            <span style={{marginLeft:convertPxToViewport(10), whiteSpace:"nowrap"}} >{comp.format.formatValReact(-elem.sample, elem.format_type+":"+elem.sub_type)}</span>
                        </span>
                </div>:""}
            </div>
            </div>;
    }

    renderFormatCard(elem){
        const comp = this;
        return <div key={elem.name} className={(elem.children&&(elem.showDetailsDecimals||elem.showDetailsMultiplier))?"uk-width-1-1":""} >
            <div className={"format-card"}>
            <div className="format-card-head">{elem.name}  <i className="far fa-info-circle" uk-tooltip={INFO_MESSAGE}/>
                <span>
                    <span style={{marginRight:convertPxToViewport(10)}}>Sample:</span>
                    <span>{comp.format.formatValReact(elem.sample, elem.format_type)}</span>
                    <span style={{marginLeft:convertPxToViewport(10)}} >{comp.format.formatValReact(-elem.sample, elem.format_type)}</span>
                </span>
            </div>
            {elem.decimals?
                <div className={(elem.children?" uk-width-1-1 uk-grid uk-grid-small uk-flex align-items-end":"uk-display-inline-block")} uk-grid={elem.children?"":null} >
                    {this.renderFormatCardDecimals(elem)}
                    {elem.children?<React.Fragment>
                        {elem.showDetailsDecimals?<div className={"format-card-details align-self-center padding-top-18px"}><div className="format-card-content">
                                <a className={"details-but"} onClick={function(){elem.showDetailsDecimals = false;comp.setState(comp.state);}}>Hide Details</a>
                            </div></div>:
                            <div className={"format-card-details align-self-end"}><div className="format-card-content">
                                <a className={"details-but"} onClick={function(){elem.showDetailsDecimals = true;comp._jquery_init = false;comp.setState(comp.state);}}>Show Details</a>
                            </div></div>}
                        {elem.showDetailsDecimals ? elem.children.map(function (sub_type) {
                            let obj = {
                                name: elem.name + " (" + sub_type.name + ")",
                                sample: elem.sample,
                                format_type: elem.format_type + ":" + sub_type.key,
                                decimals: elem.decimals,
                                multiplier: elem.multiplier,
                                machine_name: elem.machine_name,
                                sub_type: sub_type.key
                            };
                        return comp.renderFormatCardDecimals(obj, sub_type.name);
                        }):""}
                    </React.Fragment>:""}
            </div>:""}

            {elem.multiplier?
                <div className={(elem.children?" uk-width-1-1 uk-grid uk-grid-small uk-flex align-items-end":"uk-display-inline-block")} uk-grid={elem.children?"":null} >
                    {this.renderFormatCardMultiplier(elem)}
                    {elem.children?<React.Fragment>
                        {elem.showDetailsMultiplier?<div className={"format-card-details"}><div className="format-card-content">
                            <a className={"details-but"} onClick={function(){elem.showDetailsMultiplier = false;comp.setState(comp.state);}}>Hide Details</a>
                            </div></div>:
                            <div className={"format-card-details"}><div className="format-card-content">
                                <a className={"details-but"} onClick={function(){elem.showDetailsMultiplier = true;comp._jquery_init = false;comp.setState(comp.state);}}>Show Details</a>
                            </div></div>}
                        {elem.showDetailsMultiplier ? elem.children.map(function (sub_type) {
                            let obj = {
                                name: elem.name + " (" + sub_type.name + ")",
                                sample: elem.sample,
                                format_type: elem.format_type + ":" + sub_type.key,
                                decimals: elem.decimals,
                                multiplier: elem.multiplier,
                                machine_name: elem.machine_name,
                                sub_type: sub_type.key
                            };
                        return comp.renderFormatCardMultiplier(obj, sub_type.name);
                        }):""}
                    </React.Fragment>:""}
            </div>:""}

            {/*{elem.children?*/}
            {/*<div className={"uk-width-1-1 uk-grid uk-grid-small uk-flex align-items-end uk-margin-remove-top"} uk-grid={""} >*/}
            {/*    <div className={"format-card-body"}>*/}
            {/*                    /!*<span className={"sample"}>*!/*/}
            {/*                    /!*    <span style={{marginRight:"10px"}}>Sample:</span>*!/*/}
            {/*                    /!*    <span>{comp.format.formatValReact(elem.sample, elem.format_type)}</span>*!/*/}
            {/*                    /!*    <span style={{marginLeft:"10px"}} >{comp.format.formatValReact(-elem.sample, elem.format_type)}</span>*!/*/}
            {/*                    /!*</span>*!/*/}
            {/*    </div>*/}
            {/*    <div className={"format-card-details"}>*/}
            {/*    </div>*/}
            {/*    <React.Fragment>*/}
            {/*        {(elem.showDetailsDecimals||elem.showDetailsMultiplier) ? elem.children.map(function (sub_type) {*/}
            {/*            return <div key={"sample_"+sub_type.key} className={"format-card-body"}>*/}
            {/*                    <span className={"sample"}>*/}
            {/*                        <span style={{whiteSpace:"nowrap"}}>{comp.format.formatValReact(elem.sample, elem.format_type+":"+sub_type.key)}</span>*/}
            {/*                        <span style={{marginLeft:"10px", whiteSpace:"nowrap"}} >{comp.format.formatValReact(-elem.sample, elem.format_type+":"+sub_type.key)}</span>*/}
            {/*                    </span>*/}
            {/*            </div>*/}
            {/*        }):""}*/}
            {/*    </React.Fragment>*/}
            {/*</div>:""}*/}
        </div>
        </div>;
    }


    render(){
        const comp = this;
        if(this.state.loading) {
            return <React.Fragment>
                Loading...
            </React.Fragment>
        }
        return <div className={"formatDisplayContainer uk-grid uk-grid-small uk-child-width-1-2 uk-grid-match"} uk-grid={""}>
            {!this.props.isUser?
            <div className={"uk-width-1-1"}>
                <div className="format-card">
                    <div className="format-card-head">General Formatting <i className="far fa-info-circle" uk-tooltip={INFO_MESSAGE}/>
                    </div>
                    <div className={"uk-width-1-1 uk-grid uk-grid-small uk-flex align-items-end"} uk-grid={""} >
                        <div className={"format-card-body-larger"}>

                            <div>
                                <div className="format-card-title">
                                    Negative Values Color <i className="far fa-info-circle" uk-tooltip={INFO_MESSAGE}/>
                                </div>
                                <div className="format-card-content">
                                    <div className={"uk-flex uk-flex-row"}>
                                        <div>
                                            <RadioButton onChange={function(ev){
                                                    comp.handleChangeFunction("format_negative_coloring", ev);
                                                }} checked={this.state.format_negative_coloring==="enabled"} value={"enabled"} label={"Red"}
                                            />
                                        </div>
                                        <div>
                                            <RadioButton onChange={function(ev){
                                                    comp.handleChangeFunction("format_negative_coloring", ev);
                                                }} checked={this.state.format_negative_coloring==="disabled"}  value={"disabled"} label={"Black"}
                                            /> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"format-card-body-larger"}>
                            <div>
                                <div className="format-card-title">
                                    Negative Values Display <i className="far fa-info-circle" uk-tooltip={INFO_MESSAGE}/>
                                </div>
                                <div className="format-card-content">
                                    <div className={"uk-flex uk-flex-row"}>
                                        <div>
                                            <RadioButton onChange={function(ev){
                                                    comp.handleChangeFunction("format_negative_values_format", ev);
                                                }} checked={this.state.format_negative_values_format==="minus"} value={"minus"} label={"Minus"}
                                            /> 
                                        </div>
                                        <div>
                                            <RadioButton onChange={function(ev){
                                                    comp.handleChangeFunction("format_negative_values_format", ev);
                                                }} checked={this.state.format_negative_values_format==="parentheses"} value={"parentheses"} label={"Parentheses"}
                                            /> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"format-card-body-larger"}>
                            <div>
                                <div className="format-card-title">
                                    Currency <i className="far fa-info-circle" uk-tooltip={INFO_MESSAGE}/>
                                </div>
                                <div className="format-card-content">
                                    <DropDown
                                        className="uk-width-1-1 input__dropdown"
                                        value={findOptionByKey(comp.currencyOptions, comp.state["format_currency_sign"])}
                                        options={comp.currencyOptions}
                                        onChange={(valueObj)=>comp.handleChangeFunction("format_currency_sign", valueObj)}
                                        type={DROPDOWN_TYPE.INPUT}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={"format-card-body-larger"}>
                            <div>
                                <div className="format-card-title">
                                    Currency Position <i className="far fa-info-circle" uk-tooltip={INFO_MESSAGE}/>
                                </div>
                                <div className="format-card-content">
                                    <div className={"uk-flex uk-flex-row"}>
                                        <div>
                                            <RadioButton onChange={function(ev){
                                                comp.handleChangeFunction("format_currency_sign_position", ev);
                                            }}
                                                   checked={this.state.format_currency_sign_position==="left"}
                                                   value={"left"} label={"Left"}
                                            /> 
                                        </div>
                                        <div>
                                            <RadioButton  onChange={function(ev){
                                                comp.handleChangeFunction("format_currency_sign_position", ev);
                                            }}
                                                   checked={this.state.format_currency_sign_position==="right"}
                                                   value={"right"} label = {"Right"}
                                            /> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"format-card-body-larger"}>
                            <div>
                                <div className="format-card-title">
                                    Percentage Position <i className="far fa-info-circle" uk-tooltip={INFO_MESSAGE}/>
                                </div>
                                <div className="format-card-content">
                                    <div className={"uk-flex uk-flex-row"}>
                                        <div>
                                            <RadioButton onChange={(ev)=>{comp.handleChangeFunction("format_percentage_sign_position", ev)}}
                                                   checked={this.state.format_percentage_sign_position==="left"}
                                                   value={"left"} label = "Left"
                                            /> 
                                        </div>
                                        <div>
                                            <RadioButton onChange={(ev)=>{comp.handleChangeFunction("format_percentage_sign_position", ev)}}
                                                   checked={this.state.format_percentage_sign_position==="right"}
                                                   value={"right"} label={"Right"}
                                            /> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>:""}
            {comp.state.fields.map(comp.renderFormatCard)}
        </div>;
    }
}


export default FormatDisplay;