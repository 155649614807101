import React, { Component } from "react";
import PropTypes from "prop-types";
import { CrashComponent } from "./templateLayout/CrashComponent";
import { logUIError } from "./class/networkUtils";

export default class ErrorBoundary extends Component {
  state = {
    error: "",
    errorInfo: "",
    hasError: false,
  };
  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }
  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.log({ error, errorInfo });
    logUIError(error);
    logUIError(errorInfo+"");
    this.setState({ errorInfo });
  }
  render() {
    // next code block goes here
    return this.state.hasError ? (
      <CrashComponent />
    ) : (
      this.props.children
    );
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};
