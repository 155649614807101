import { Button, Icon, Popover, Typography } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import "../../styles/filterBy.css";
// import { ReactComponent as MultipleFilters } from "../styles/images/icons/multiplefilters.svg";

import SearchableDropdown from "../SearchableDropdown";
import { lang } from "../../language/messages_en";
import { BUTTON_VARIANT } from "../../class/constants";

const FilterByCategory = ({sectionsData, applyFilter}, ref) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [checkedItems, setCheckedItems] = useState([]);
  const [appliedFilter, setAppliedFilter] = useState([]);
  const [number, setNumber] = useState("");

  const open = Boolean(anchorEl);

  useImperativeHandle(ref, () => ({
    resetFilter: () => {
        resetFilter();
    }
  }));


  /**
   * Opens filter dialog
   * @param {*} event
   */
  const handleClick = (event) => {
    if (anchorEl === null) {
      setAnchorEl(event.currentTarget);
    } else {
      handleClose();
    }
  };

  /**
   * Closes filter dialog
   */
  const handleClose = () => {
    setAnchorEl(null);
    $('.popover-root-filterby').empty();
  };

  useEffect(() => {

    applyFilter(appliedFilter);
    $('.popover-root-filterby').empty();
}, [appliedFilter]);


/** 
 * handle incrementing and decremnting 
 * of number shown on filter button
 */ 
    
useEffect(() => {
    setNumber(checkedItems.length);
}, [checkedItems]);


  /**
   * On checkbox change function
   * @param {*} item
   */

  const handleCheckboxChange = (event) => {
    event.checked = !event.checked

    // Add or remove the value from the checkedItems array
    if (event.checked) {
      setCheckedItems([...checkedItems, event]);
    } else {
      setCheckedItems(checkedItems.filter((item) => item.value !== event.value));
    }
  };

  const saveFilter = () => {
    setAppliedFilter(checkedItems);
    handleClose();
  };

  useEffect(() => {
    closePopover();
    setCheckedItems(appliedFilter);
    let appliedFilterList = appliedFilter?.map( e => e.value);
    if(sectionsData && sectionsData[0] && sectionsData[0].itemsList){
        sectionsData[0].itemsList.map(e =>{
        e.checked = appliedFilterList.includes(e.value);
        return e;
        });
}   $('.popover-root-filterby').empty();
  }, [anchorEl]);

  const resetFilter = () => {
    setCheckedItems([]);
    setAppliedFilter([]);
    if(sectionsData && sectionsData[0] && sectionsData[0].itemsList){
        sectionsData[0].itemsList.map(e =>{
        e.checked = false;
        return e;
        });
    }
  }

  const closePopover = () => {
    $(document).on("mouseover click", function (event) {
      if (
        $("#filter-by-popover:hover").length === 0 &&
        $("#filter-by-btn:hover").length === 0
      ) {
        if (event.type === "click") {
          handleClose();
        }
      }
    });
  };
  const id = open ? 'filter-by-popover' : undefined;
  return (
    <div style={{position:"relative"}}>
      <Button
        id="filter-by-btn"
        onClick={handleClick}
        className={number ? "filter-category-selected" : "filter-by-category-button"}
        variant="contained"
        disableElevation
        disableRipple
      >
        <Icon
          className={"far fa-filter uk-margin-small-right"}
          sx={{ fontSize: "1.3rem" }}
        />
        {lang.monthly_build.upload_files.filter_by_category}
        {number?
          <Typography className="number-selection">{number}</Typography>
        :""}
        <Icon className={"far fa-chevron-down uk-margin-small-left"} />
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        container={anchorEl?.parentNode}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        TransitionProps={{ timeout: 0 }} // remove transition animation
        PaperProps={{
          sx: { minWidth: "18.23vw"}
        }}
        classes={{ paper: "popover-filterby popover-filterby-category", root: "popover-root-filterby" }}
        disableScrollLock // disable putting overflox hidden on body
      >
        <SearchableDropdown
          isSearchable={false}
          hasHeader={false}
          sectionsData={sectionsData}
          handleCheckboxChange={handleCheckboxChange}
          isFilterBy={false}
          primaryButtonProps={{
            className: "uk-margin-small",
            text: lang.modal.buttons.apply,
            variant: BUTTON_VARIANT.SECONDARY3,
            direction: "column",
            style: {width: "95%"},
            clickEvent: saveFilter,
            id: "apply-filter-category-button"
          }}
        />
      </Popover>
    </div>
  );
};

export default forwardRef(FilterByCategory);
