import { Suspense, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";

// import NewHeader from './NewHeader';
import NewBanner from "../../components/NewBanner";
import { ReportHeader } from "../ReportHeader";
import "../styles/reportBody.css";

import { ALL_WIDGETS, API_URL, BUTTON_TYPE, BUTTON_VARIANT, SCENARIO_STATUS, SIZES } from "../../class/constants";

import { logout } from "../../class/common";
import ErrorBoundary from "../../ErrorBoundary";
import { Loader } from "../../form/elements";
import { useAuthenticated } from "../CustomHooks";
import { renderTitle } from "../functions/componentFunctions";
import UploadData from "../../sections/UploadData";
/**
 * @author Mostafa Haydar
 * @description component renders UploadData Title and  {@link UploadData} component , calls isAuthenticated hook to check if user has authentication view the screen
 * @param {*} props 
 * @returns {@link ReportHeader}, {@link UploadData}
 */
const UploadDataWrapper = (props) => {
  const { userAllowedMenuLinks } = props;
  const { userSettings } = props;
  const { scenarioState } = props;

  /**References & react utility */
  const reportRef = useRef();
  const toggleRef = useRef();
  const params = useParams();

  /**Custom Hooks */
  const isAuthenticated = useAuthenticated(userAllowedMenuLinks);

  const [reportTitle, setReportTitle] = useState(ALL_WIDGETS.TITLES.DATA_MODELING.UPLOAD_DATA);
  const [headerElements, setHeaderElements] = useState([]);
  const [isMFA, setIsMFA] = useState();

  /**on screen initialization if user entered url with no access to screen he gets logged out */
  useEffect(() => {
    if (!isAuthenticated) {
      logout();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    let headerElements = getHeaderElements();
    setHeaderElements(headerElements);
  }, []);

  const getHeaderElements = () => {
    let headerElements = [
      <div style ={{display:"flex",justifyContent:"space-between",width: "100%"}}>
        {renderTitle(reportTitle)}
      </div>
    ];
    return headerElements;
  };

  return (
    <>
      <ErrorBoundary>
        <Suspense fallback={<p id="loading">Loading...</p>}>
          <div
            id={"main-component-container"}
            className={"main-component-container"}
            // style={{ "--banner-height": 0 }}
          >
            <div className="main-report-header uk-margin-small-top">
              <ReportHeader headerElements={headerElements} />
            </div>
            <div className="main_report">
              <div id="main_report_container" className="main_report_container border-none">
                  <UploadData
                    ref={reportRef}
                    userAllowedSections={userAllowedMenuLinks}
                    userSettingsState={userSettings}
                  />
              </div>
            </div>
          </div>
        </Suspense>
      </ErrorBoundary>
    </>
  );
};

export { UploadDataWrapper };
