var isNewDesign = true;
function createCheckbox() {
    var checkbox = document.createElement('INPUT');
    checkbox.type = 'checkbox';
    if (isNewDesign){
        checkbox.classList.add('input-checkbox-default');
    } 
    return checkbox;
}

export {createCheckbox};