import React from "react";
import { lang } from "../../language/messages_en";
import SwitchToggle from "../../newComponents/SwitchToggle";
import CheckBox from "../../newComponents/CheckBox"
const $ = require('jquery');

// * @author [Rafah Cheaib]
class ProfitStackAccess extends React.Component{

    constructor(props) {
        super(props);
        this.state={
            restrictions:[],
            allowed:false
        }
        this.extractCheckedItems = this.extractCheckedItems.bind(this);
        this.check = this.check.bind(this);
    }

    componentDidMount(){
        this.setState({
            restrictions: this.props.restrictions,
            allowed: this.props.alwaysAllowPSS ? this.props.alwaysAllowPSS : false
        },function(){
            this.allowAccess(this.state.allowed);
            this.extractCheckedItems();
        })
    }

    extractCheckedItems(){
        var ids=[];
        var restrictions = this.state.restrictions;
        for (var e in restrictions) {
            if (restrictions[e].checked) {
                ids.push({id:restrictions[e].restriction_id.toString()});
            }
        }
        this.props.updateParent(ids, this.state.allowed);
    }

    check(type,check) {
        var restrictions = this.state.restrictions;
        for (var e in restrictions) {
            if (restrictions[e].restriction_type === $(type.target).attr('id')) {
                restrictions[e].checked = check;
            }
        }
        this.setState({
            restrictions: restrictions
        },function(){
            this.extractCheckedItems()
        })
    }

    allowAccess(allow){
        var restrictions = this.state.restrictions;
        for (var e in restrictions) {
            restrictions[e].disabled = allow;
            restrictions[e].checked = allow ? false : restrictions[e].checked;
        }
        this.setState({
            allowed: allow,
            restrictions:restrictions
        },function(){
            this.extractCheckedItems()
        })
    }

    renderList(){
        var list = [];
        var data = this.state.restrictions;
        for (var e in data) {
            var _class = data[e].disabled ? "disabled" : "";
            list.push(
                <div className="uk-display-flex uk-flex-middle uk-margin-default-bottom">
                    {data[e].checked ? 
                        <CheckBox disabled = {_class !== ""}  id={data[e].restriction_type} checked = {true} onChange={(evt)=>{this.check(evt, false)}}/>
                    :  <CheckBox disabled = {_class !== ""}  id={data[e].restriction_type} checked={false} onChange={(evt)=>{this.check(evt, true)}}/>
                    }
                    <label for="Corporate" className="fs-14 uk-margin-xsmall-left">{data[e].display_name}</label>
                </div>
            )
        }
        return list;
    }

    render() {
        var list = this.renderList();
        return (
            <div>
                <div className="uk-display-flex justify-content-between uk-margin-default-bottom" style={{flexDirection: "column", gap: "0.41667vw"}}>
                    <span className="uk-text-xmedium">{lang.manage_access.profit_stack_access}</span>
                    <div className="uk-flex-inline uk-flex-middle">
                        <SwitchToggle id= {"allow_access"} checked={this.state.allowed} onChange={(e)=>this.allowAccess(!this.state.allowed)}/>
                        <span className="fs-14 uk-margin-xsmall-left">{lang.manage_access.allow_all_lines}</span>
                        <i className="fal fa-info-circle uk-margin-xsmall-left uk-cursor-pointer" uk-tooltip={lang.manage_access.always_allow_info}/>
                    </div> 
                </div> 
                {list}

               
            </div>
        ) 
    }
}

export default ProfitStackAccess;