// CustomChatBot.js
import React, { useEffect } from 'react';
import { FETCH_METHOD, fetchAPI, FETCHAPI_PARAMS } from "../../class/networkUtils";

function CustomChatBot(props) {
    // Define styles as constants
    const chatBotButtonStyles = {
        size: "60px",
        bgcolor: "#00bfa5",
        color: "#ffffff",
        bgcolorHover: "#008e76",
        borderColor: "transparent",
        borderWidth: "0px",
        borderStyle: "none",
        borderRadius: "50%",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    };
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const chainlitUrl = process.env.REACT_APP_CHATBOT_APP_BASE_URL;

    useEffect(() => {
        let cleanupFunc;

        const initializeChatBot = async () => {
            const token = await getAccessToken(baseUrl, chainlitUrl);
            cleanupFunc = loadCopilotWidget(chainlitUrl, token);
        };

        initializeChatBot();

        // Add the event listener for disabling 'p' hotkey
        const keydownListener = function(event) {
            if (event.key === 'p' || event.key === 'P') {
                // Stop the event from reaching other listeners (disables the hotkey)
                event.stopImmediatePropagation();
                // Do NOT prevent the default action (allows typing 'p' normally)
                // event.preventDefault(); // Do not include this line
            }
        };

        document.addEventListener('keydown', keydownListener, true); // Use capture phase to intercept the event early

        return () => {
            if (cleanupFunc) {
                cleanupFunc();
            }
            // Clean up the event listener when component unmounts
            document.removeEventListener('keydown', keydownListener, true);
        };
    }, []);

    // Function to check and hide the watermark
    const checkAndHideWatermark = () => {
        const widget = document.querySelector('#chainlit-copilot');
        const shadowRoot = widget && widget.shadowRoot;
        const watermarkDiv = shadowRoot && shadowRoot.querySelector('div.watermark');
        if (watermarkDiv) {
            watermarkDiv.style.display = 'none';
        }
        setTimeout(checkAndHideWatermark, 100);
    };

    // Function to get sessionId
    const getSessionId = () => {
        return new Promise((resolve, reject) => {
            let query = { action: "getSessionId" };
            let onThenCallback = (data) => {
                if (data?.sessionId) {
                    resolve(data.sessionId); // Resolve the promise with the sessionId
                } else {
                    reject(new Error('Failed to get sessionId'));
                }
            };
            let fetchOptions = {
                [FETCHAPI_PARAMS.funcName]: "getSessionId",
                [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
                [FETCHAPI_PARAMS.showLoader]: true,
                [FETCHAPI_PARAMS.path]: "/get-session-id",
                [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
                [FETCHAPI_PARAMS.query]: query,
                [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            };
            fetchAPI(fetchOptions);
        });
    };

    // Function to get chatbotVectorsInfo
    const getCloudRunToken = () => {
        return new Promise((resolve, reject) => {
            let query = { action: "getChatbotAccessToken" };
            let onThenCallback = (data) => {
                if (data?.accessToken) {
                    resolve(data.accessToken); // Resolve the promise
                } else {
                    reject(new Error('Failed to get chatbotAccessToken'));
                }
            };
            let fetchOptions = {
                [FETCHAPI_PARAMS.funcName]: "getChatbotAccessToken",
                [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
                [FETCHAPI_PARAMS.showLoader]: true,
                [FETCHAPI_PARAMS.path]: "/get-chat-bot-access-token",
                [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
                [FETCHAPI_PARAMS.query]: query,
                [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            };
            fetchAPI(fetchOptions);
        });
    };

    // Function to get access token from the /custom-auth endpoint
    const getAccessToken = async (baseUrl, chainlitUrl) => {
        try {
            // Fetch the access token from the new endpoint
            const cloudRunToken = await getCloudRunToken();
            // Now use the access token to authenticate the existing API call
            const sessionId = await getSessionId();
            const requestBody = {
                sessionId: sessionId,
                url: `${baseUrl}/chat-bot-get-vector`,
                authority: baseUrl,
                origin: window.location.origin,
                firstName: props.userSettings.user.first_name,
                userId: props.userSettings.user.userId,
                clientId: props.userSettings.clientId,
            };

            // Make the POST request to your custom-auth endpoint
            const authResponse = await fetch(`${chainlitUrl}/custom-auth`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': `Bearer ${cloudRunToken}`, // Use the access token here
                },
                credentials: 'include',
                body: JSON.stringify(requestBody),
            });

            const data = await authResponse.json();
            if (authResponse.ok) {
                return data.token;
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    };
    const addCustomStyles = () => {
        if (document.querySelectorAll('#chainlit-copilot')?.length > 1) {
            document.body.removeChild(document.querySelectorAll('#chainlit-copilot')[1])
        }
        const widget = document.querySelector('#chainlit-copilot');
        const shadowRoot = widget && widget.shadowRoot;
        const input = shadowRoot && shadowRoot.querySelector('div textarea#copilot-chat-input');
        if (input) {
            input.style.fontSize = 'small';
            input.style.fontFamily = 'Lato';
            input.parentElement.style.paddingBottom = '0px';
        }
        const svgs = shadowRoot && shadowRoot.querySelectorAll('div button svg:not([data-testid="CloseIcon"])');
        if (svgs?.length > 0) {
            svgs.forEach((svg) => {
                svg.style.width = '20px'; // Set desired width
                svg.style.height = '20px'; // Set desired height
            });
        }
        const chatContainer = shadowRoot && shadowRoot.querySelectorAll("div .markdown-body");
        if (chatContainer?.length > 0) {
            chatContainer.forEach((chatCoontainer) => {
                chatCoontainer.style.fontSize = 'small';
            });
        }
        const chatTable = shadowRoot && shadowRoot.querySelectorAll("div table");
        if (chatTable?.length > 0) {
            chatTable.forEach((chatTable) => {
                chatTable.style.fontSize = 'small';
            });
        }
        const tableHeaders = shadowRoot && shadowRoot.querySelectorAll("div table th");
        if (tableHeaders?.length > 0) {
            tableHeaders.forEach((th) => {
                th.style.fontSize = 'small';
            });
        }
        const tableTds = shadowRoot && shadowRoot.querySelectorAll("div table td");
        if (tableTds?.length > 0) {
            tableTds.forEach((td) => {
                td.style.fontSize = 'small';
            });
        }
        const tooltips = shadowRoot && shadowRoot.querySelectorAll("div .MuiTooltip-popper div");
        if (tooltips?.length > 0) {
            tooltips.forEach((tooltip) => {
                tooltip.style.fontSize = 'small';
                tooltip.textContent = tooltip.textContent.replace("(P)","");
            });
        }

        const buttons = shadowRoot && shadowRoot.querySelectorAll('div .MuiStack-root .MuiIconButton-root');
        if (buttons?.length > 0 && buttons?.length === 7) {
            buttons.forEach((button) => {
                button.style.display = 'none';
            });
        }

        const dialogs = shadowRoot && shadowRoot.querySelectorAll('div #alert-dialog-description');
        if (dialogs?.length > 0) {
            dialogs.forEach((dialog) => {
                dialog.style.fontSize = 'small';
                dialog.style.width = '31.25vw';
                dialog.style.maxHeight = '19.27vh';

            });
        }
        const dialogButtons = shadowRoot && shadowRoot.querySelectorAll('div .MuiDialog-paper button');
        if (dialogButtons?.length ===  2) {
            const secondaryButton = dialogButtons[0];
            const primaryButton = dialogButtons[1];
            primaryButton.style.background = "#08155e";
            primaryButton.style.border = "none";
            primaryButton.style.color = "#ffffff";
            primaryButton.style.fontSize = "0.72917vw";
            primaryButton.style.lineHeight = "24px";
            primaryButton.style.padding = "0px 0.83333vw";
            secondaryButton.style.background = "#ffffff";
            secondaryButton.style.border = "none";
            secondaryButton.style.color = "#08155e";
            secondaryButton.style.outline = "1px solid #e4e4e455e";
            secondaryButton.style.fontSize = "0.72917vw";
            secondaryButton.style.lineHeight = "24px";
            secondaryButton.style.padding = "0px 0.83333vw";
        }
        const recorderSVG = shadowRoot && shadowRoot.querySelectorAll('div .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-1itwfbm');
        if(recorderSVG?.length > 0) {
            recorderSVG[0].style.color='rgb(0, 191, 165)'
        }
        setTimeout(addCustomStyles, 100);
    };

    // Function to load the Copilot script and initialize the widget
    const loadCopilotWidget = (chainlitUrl, token) => {
        // Load the Copilot script
        const script = document.createElement('script');
        script.src = `${chainlitUrl}/chainlit/copilot/index.js`;
        script.async = true;

        script.onload = () => {
            addCustomStyles();
            if (token) {
                // Initialize the Copilot widget with the token and styles
                window.mountChainlitWidget({
                    chainlitServer: `${chainlitUrl}/chainlit`,
                    accessToken: token,
                    theme: "light",
                    fontFamily: "Arial, sans-serif",
                    button: {
                        imageUrl: "https://profitisle.com/wp-content/uploads/2024/08/cropped-favicon-192x192.png",
                        style: chatBotButtonStyles,
                    },
                });

                // Start hiding the watermark
                checkAndHideWatermark();
            }
        };

        document.body.appendChild(script);

        // Cleanup function to remove the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    };

    return null; // This component doesn't render anything visible
}

export default CustomChatBot;
