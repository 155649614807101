const defaultUserSettings = [];
const defaultUserSections = [];

function userSettings(state=defaultUserSettings, action) { //reducer
    switch (action.type) {
        case "UPDATE_USERSETTINGS":
        return [
            {
            name: action.settings
            }
        ];
        case "GET_USERSETTINGS":
          return state;
        default:
        return state;
    }
}

function userSections(state=defaultUserSections, action) { //reducer
    switch (action.type) {
        case "UPDATE_USERSECTIONS":
        return [
            {
            name: action.userSections
            }
        ];
        case "GET_USERSECTIONS":
          return state;
        default:
        return state;
    }
}

  
export {userSettings, userSections};