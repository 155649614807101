import React, { Component } from "react";
import FormatDisplay from "./FormatDisplay.js";
import { checkIfSectionIdExists, toggleLoader, setLocalStorageValueByParameter } from "../../class/common";
import { API_URL, BUTTON_TYPE, BUTTON_VARIANT, DIALOG_SIZE, SIZES } from "../../class/constants.js";

import "../../styles/common.css";
import "../../styles/format.css";
import "jqueryui";
import { convertPxToViewport } from "../../class/formatting";
import Button from "../../newComponents/Button.js";
import { lang } from "../../language/messages_en.js";
import Modal from "../../newComponents/Modal.js";
import { ReactComponent as Settings } from "../../styles/images/menu-svgs/settings.svg";
import { toggleFullScreenLoader } from "../../templateLayout/functions/componentFunctions.js";

const UIKit = require("uikit");
const $ = require("jquery");


class FormatPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.clickReset = this.clickReset.bind(this);
    this.clickSave = this.clickSave.bind(this);
    this.clickCancel = this.clickCancel.bind(this);
  }

  clickReset() {
    var params = {};
    params.action = "resetUserFormatting";
    var path = API_URL.FORMAT;
    const comp = this;
    this.state.loading = true;
    const baseUrl = process.env.REACT_APP_BASE_URL;
    toggleLoader(true, "clickReset");
    setLocalStorageValueByParameter(window.location.host + "_" + "lastRequestSentTime", new Date());
    this.setState(this.state, function () {
      fetch(`${baseUrl}${path}`, {
        body: JSON.stringify(params),
        method: "POST",
        mode: "cors",
        credentials: "include",
      })
        .then(function (response) {
          if (response.status === 403) {
            comp.state.loading = false;
            window.location.reload();
            return;
          }
          return response.json();
        })
        .then(function (data) {
          if (!data) {
            return;
          }
          if (data.error) {
            comp.state.loading = false;
            toggleLoader(false, "clickReset");
            comp.setOpenErrorPopup(true, data.error.message);
            return;
          }
          comp.state.loading = false;
          window.location.reload();
        })
        .catch((error) => {
          comp.setOpenErrorPopup(true, JSON.stringify(error));
          toggleLoader(false, "clickReset");
        });
    });
  }

  clickSave() {
    var params = this.formatDisplay.getFormatChanges();
    params.action = "setUserFormatting";
    var path = API_URL.FORMAT;
    const comp = this;
    this.state.loading = true;
    const baseUrl = process.env.REACT_APP_BASE_URL;
    toggleFullScreenLoader(true, "clickSave");

    setLocalStorageValueByParameter(window.location.host + "_" + "lastRequestSentTime", new Date());

    this.setState(this.state, function () {
      fetch(`${baseUrl}${path}`, {
        body: JSON.stringify(params),
        method: "POST",
        mode: "cors",
        credentials: "include",
      })
        .then(function (response) {
          if (response.status === 403) {
            comp.state.loading = false;
            window.location.reload();
            return;
          }
          return response.json();
        })
        .then(function (data) {
          if (!data) {
            return;
          }
          if (data.error) {
            comp.state.loading = false;
            toggleFullScreenLoader(false, "clickSave");

            comp.setOpenErrorPopup(true, data.error.message);
            return;
          }
          comp.state.loading = false;
          comp.props.historyPushAndReload();
        })
        .catch((error) => {
          comp.setOpenErrorPopup(true, JSON.stringify(error));
          toggleFullScreenLoader(false, "clickSave");

        });
    });
  }

  clickCancel() {
    this.setOpenPopup(false);
    this.setOpenErrorPopup(false);
    if (this && this !== null && this.formatDisplay && this.formatDisplay !== null)
    this.formatDisplay.fetchUserSettings();
  }

  popupContent = () => {
    return (
      <FormatDisplay ref={(el) => (this.formatDisplay = el)} isUser={true} format_max_decimal={this.props.format_max_decimal} />
    );
  };

  popupActionsButtons = () => {
    return (
      <>
        <Button
          label={"Reset"}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={this.clickReset}
        />
        <Button
          label={lang.modal.buttons.apply}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={this.clickSave}
        />
        <Button
          label={lang.modal.buttons.cancel}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={this.clickCancel}
        />
      </>
    );
  };

  popupErrorActionsButtons = () => {
    return (
      <Button
        label={lang.modal.buttons.ok}
        variant={BUTTON_VARIANT.SECONDARY}
        size={SIZES.DEFAULT}
        type={BUTTON_TYPE.DEFAULT}
        onBtnClick={this.clickCancel}
      />
    );
  };

  setOpenPopup = (isOpen) => {
    let _this = this;
    _this.setState({
      openPopup: isOpen,
    });
  };

  setOpenErrorPopup = (isOpen, errorMsg) => {
    let _this = this;
    _this.setState({
      openErrorPopup: isOpen,
      errorPopupMsg: errorMsg,
    });
  };

  render() {
    var sectionExists = checkIfSectionIdExists(this.props.userAllowedMenuLinks, "formatting");
    // if (!sectionExists) {
    //   return "";
    // }
    return (
      <>
        {this.props.isNewHeader ? (
          <Settings onClick={() => this.setOpenPopup(true)} className="uk-cursor-pointer" />
        ) : (
          <div
            className={"subtle--btn-primary"}
            onClick={() => {
              // this.props.showHideSettings();
              this.setOpenPopup(true);
            }}
          >
            <i className="far fa-sliders-h"></i>
            <span className="uk-padding-xsmall-left">Formatting</span>
          </div>
        )}

        <Modal
          id={"formatting-popup"}
          title={"Formatting"}
          dialogActions={this.popupActionsButtons}
          bodyContent={this.popupContent}
          openDialog={this.state.openPopup}
          closeClick={this.clickCancel}
          size={DIALOG_SIZE.XLARGE}
        />
        <Modal
          id={"formatting-popup-error"}
          dialogActions={this.popupErrorActionsButtons}
          bodyContent={() => <h4>{this.state.errorPopupMsg}</h4>}
          openDialog={this.state.openErrorPopup}
          size={DIALOG_SIZE.SMALL}
          closeClick={() => this.setOpenErrorPopup(false, "")}
        />
      </>
    );
  }
}

export default FormatPopup;
