import React, { Component } from 'react';
import { fetchAPI, FETCHAPI_PARAMS, FETCH_METHOD } from '../../class/networkUtils';
import { ALL_WIDGETS, DASHBOARDS, HEADER_ELEMENT,COLUMN_PROFILE, API_URL, MANAGE_ACCESS, MANAGE_COLUMNS, BUTTON_VARIANT, SIZES, BUTTON_TYPE, DIALOG_SIZE, MENU_ITEM } from '../../class/constants';
import { capitaliseFirstLetterAfterChar, capitalizeFirstLetter, copyObjectValues, getSectionExists, getTranslationFile, tryParse, parseBoolean } from '../../class/utils';
import {findIndexOfValue} from '../../class/array';
import '../../styles/manageColumns.css';
import {DropDownMenu, Tabs, ToggleTab} from '../../form/elements.js';
import SidePanel from '../../components/SidePanel';
import { FormComponent } from '../../form/FormElements';
import  ManageColumns  from '../../components/manageColumns/ManageColumns';
import UIkit from 'uikit';
import Button from '../../newComponents/Button';
import Modal from '../../newComponents/Modal';
import CategoryWidgets from './CategoryWidgets';
import SidePanelNew from '../../components/sidePanelNew/SidePanelNew';
import Backdrop from '../../components/sidePanelNew/Backdrop';
import { getOutputScreenName } from '../../class/common';
const lang = getTranslationFile();
const $ = require('jquery');

const _creatorName = DASHBOARDS.CREATOR_NAME;
const _visibilityOptions = COLUMN_PROFILE.VISIBILITY_OPTIONS;
const _isSaved = COLUMN_PROFILE.IS_SAVED;
const _isCompanyDefault = DASHBOARDS.IS_COMPANY_DEFAULT;
const _isUserDefault = DASHBOARDS.IS_USER_DEFAULT;
const _dashboardId = DASHBOARDS.ID;
const _dashboardName = DASHBOARDS.NAME;
const _visibility = DASHBOARDS.VISIBILITY;
const _profileGroup = DASHBOARDS.PROFILE_GROUP;
const _description = DASHBOARDS.DESCRIPTION;
const _isSystem = MANAGE_ACCESS.FIELDS.IS_SYSTEM;
const _column =  DASHBOARDS.CONFIG_FIELD.COLUMN;
const _profile =  DASHBOARDS.CONFIG_FIELD.PROFILE;
const dashboardComp = "- Manage Dashboards"
class ManageDashboards extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
            displayPanel: false,
            access: {
                manageCompanyDashboardsAccess: this.props.manageCompanyDashboardsAccess[MENU_ITEM.FIELDS.CREATE_EDIT_COMPANY_DASHBOARDS],
                manageUserDashboardsAccess: this.props.manageUserDashboardsAccess[MENU_ITEM.FIELDS.CREATE_EDIT_USER_DASHBOARDS],
            },
            visibilityOptions: [
                { label: capitalizeFirstLetter(lang.manage_columns.visibility_options.user), value: lang.manage_columns.visibility_options.user, iconClass: "fa-lg fal fa-user" },
                { label: capitalizeFirstLetter(lang.manage_columns.visibility_options.company), value: lang.manage_columns.visibility_options.company, iconClass: "fa-lg fal fa-users" }],
            VisibilitySelected: DASHBOARDS.VISIBILITY_OPTIONS.USER,
            currentDashboard: { [DASHBOARDS.VISIBILITY]: DASHBOARDS.VISIBILITY_OPTIONS.USER },
            dashboardTitle: lang.dashboards.create_dashboard,
            drawerOpen:false
        }

        this.state = Object.assign({}, this.state, this.initialState);
        this.onToggleBoard = this.onToggleBoard.bind(this);
        this.saveDashboard = this.saveDashboard.bind(this);
        this.resetInputs = this.resetInputs.bind(this);
        this.handleDashBoardChange = this.handleDashBoardChange.bind(this);
        this.getDashboardOptions = this.getDashboardOptions.bind(this);
        this.discardAndClose = this.discardAndClose.bind(this);
        this.deleteDashboard = this.deleteDashboard.bind(this);
        this.confirmDeleteDashboard = this.confirmDeleteDashboard.bind(this);
        this.editDashboard = this.editDashboard.bind(this);
        this.makeDashboardDefault =  this.makeDashboardDefault.bind(this);
        this.startSaveDashboard = this.startSaveDashboard.bind(this);
        this.checkVisibility = this.checkVisibility.bind(this);

        this.inputRefs = {};
    }
    
    init() {
        this.fetchDashboards();
    }

    /**
     * called from outside component
     * @param {*} dashBoardWidgets 
     */
    updateDashboardWidgets(dashBoardWidgets) {
        let _this = this;
        _this.setState({
            dashBoardWidgets: dashBoardWidgets
        })
    }

    callFetchWidgets = ()=> {
        const _this = this;
        const query = {
            action: "fetchWidgets"
        };
        let onThenCallback = (data)=>{
            if (data && data.data) {
                _this.setState({
                    dashBoardWidgets: data.dashBoardWidgets,
                                });
            }
        }
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "fetchWidgets",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.DASHBOARD,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.requestDescription]: lang.observability.output.configurations.fetch_widgets,
            [FETCHAPI_PARAMS.screenName]: getOutputScreenName(window.location.href)+ " " + dashboardComp,
        }
        fetchAPI(fetchOptions);
    }

    resetInputs(){
        const _this = this;
        let currentDashboard = copyObjectValues(this.state.currentDashboard);
        currentDashboard[DASHBOARDS.NAME_ID] = '';
        currentDashboard[DASHBOARDS.NAME] = '';
        currentDashboard[DASHBOARDS.DESCRIPTION_ID] = '';
        currentDashboard[DASHBOARDS.DESCRIPTION] = '';
        currentDashboard[DASHBOARDS.ID] = undefined;
        currentDashboard[DASHBOARDS.MACHINE_NAME] = '';
        currentDashboard[DASHBOARDS.VISIBILITY] = DASHBOARDS.VISIBILITY_OPTIONS.USER;
        this.setState({
            currentDashboard: currentDashboard,
            isNameValid: "",
            VisibilitySelected: DASHBOARDS.VISIBILITY_OPTIONS.USER,
            isEditing: false,
            isClone: false,
            visibiliyChecked: undefined
        });

        Object.keys(this.inputRefs).forEach(key => {
            if (!!_this.inputRefs[key]) {
                _this.inputRefs[key].reset();
            }
        })
    }

    componentDidMount() {
        this.init();
        // $(document).on("click", "#overlay-manage-dashboards", this.discardAndClose);
        let _this = this;
        // $(document).on("click",function(e){ // closing manage dashboard menu drop down when a value is inserted in the input field and clicking outside 
        //     if (e.target.parentElement.parentElement.parentElement.id !== "split_manage_columns_dropdown_container" && e.target.id !== "input_field" && _this.dropDownMenuRef && _this.dropDownMenuRef.menuRef && _this.dropDownMenuRef.menuRef.state && _this.dropDownMenuRef.menuRef !== null) {
        //         // _this.dropDownMenuRef.menuRef.setIsFocused(false);
        //         // _this.dropDownMenuRef.menuRef.setInputValue("");
        //         // _this.dropDownMenuRef.menuRef.forceUpdate();
        //         _this.closeDropDown()
        //     }
        // })
    }

    componentWillUnmount() {
        $(document).off("click", this.discardAndClose);
    }

    shouldComponentUpdate(){
        return true;
    }

    discardAndClose() {
        if(this.state.displayPanel) {
            // this.onToggleBoard();
        } else {
            this.discardChanges(this.toggleBoard);
            // this.cancel(this.toggleBoard);    
        }
    }

    discardChanges(callback) {
            if(typeof callback === "function") {
            callback();
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props.scenarioId !== prevProps.scenarioId){
            this.init();
        }
    }

    getDashboardOptions(dashboards) {
        var _this = this;
        let dashboardOptions = [];
        let sorter = _dashboardName;
        let editUserDBsAllowed = getSectionExists(this.props.userAllowedMenuLinks, MENU_ITEM.FIELDS.CREATE_EDIT_USER_DASHBOARDS) || getSectionExists(this.props.userAllowedMenuLinks, ALL_WIDGETS.MANAGE_USER_DASHBOARDS);
        let editCompDBsAllowed = getSectionExists(this.props.userAllowedMenuLinks, MENU_ITEM.FIELDS.CREATE_EDIT_COMPANY_DASHBOARDS) || getSectionExists(this.props.userAllowedMenuLinks, ALL_WIDGETS.MANAGE_COMPANY_DASHBOARDS);

        dashboardOptions.push({label: capitalizeFirstLetter(_visibilityOptions.COMPANY), isDisabled: true, isProfileGroup: true, [_visibility]: _visibilityOptions.COMPANY, faClass:"text-black fa-lg fal fa-users"});
        dashboardOptions = dashboardOptions.concat(dashboards.filter(dashb=>dashb[_visibility] === _visibilityOptions.COMPANY && dashb[_isCompanyDefault] === true).map(dashb=>{
            dashb.label = dashb[_dashboardName];
            dashb.value = dashb[_dashboardId];
            dashb[_creatorName] = "";
            dashb.editAllowed = false;
            return dashb;
        }));
        if(getSectionExists(this.props.userAllowedMenuLinks, MENU_ITEM.FIELDS.VIEW_COMPANY_DASHBOARDS) || getSectionExists(this.props.userAllowedMenuLinks, ALL_WIDGETS.COMPANY_DASHBOARDS)){
            dashboardOptions = dashboardOptions.concat(dashboards.filter(dashb=>dashb[_visibility] === _visibilityOptions.COMPANY && dashb[_isCompanyDefault] === false).sort((a, b) => a[sorter].localeCompare(b[sorter])).
            map(dashb=>{
                    dashb.label = dashb[_dashboardName];
                    dashb.value = dashb[_dashboardId];
                dashb.editAllowed = (dashb[_isCompanyDefault] && !parseBoolean(_this.props.user.is_system)) || !editCompDBsAllowed ? false: true;
                    return dashb;
                }));
        }
        if(getSectionExists(this.props.userAllowedMenuLinks, MENU_ITEM.FIELDS.DASHBOARDS) || getSectionExists(this.props.userAllowedMenuLinks, ALL_WIDGETS.USER_DASHBOARDS)){
            dashboardOptions.push({label: capitalizeFirstLetter(_visibilityOptions.USER), isDisabled: true, isProfileGroup: true,  [_visibility]: _visibilityOptions.USER, faClass:"text-black fa-lg fal fa-user"});
            dashboardOptions = dashboardOptions.concat(dashboards.filter(dashb=>dashb[_visibility] === _visibilityOptions.USER).sort((a, b) => a[sorter].localeCompare(b[sorter])).map(dashb=>{
                dashb.label = dashb[_dashboardName];
                dashb.value = dashb[_dashboardId];
                dashb.editAllowed = editUserDBsAllowed;
                return dashb;
            }));
        }

        return dashboardOptions;
    }

    fetchDashboards(dashboard) {
        if (!this.props.scenarioId) {
            return;
        }
        const _this = this;
        var query = {
            action: "fetchDashboards",
            scenario_id: this.props.scenarioId,
        }
        let isEditUserDashBoardsAllowed = getSectionExists(_this.props.userAllowedMenuLinks, MENU_ITEM.FIELDS.CREATE_EDIT_USER_DASHBOARDS) || getSectionExists(_this.props.userAllowedMenuLinks, ALL_WIDGETS.MANAGE_USER_DASHBOARDS);
        let isEditCompanyDashBoardsAllowed = getSectionExists(_this.props.userAllowedMenuLinks, MENU_ITEM.FIELDS.CREATE_EDIT_COMPANY_DASHBOARDS) || getSectionExists(_this.props.userAllowedMenuLinks, ALL_WIDGETS.MANAGE_COMPANY_DASHBOARDS);
       
        let onThenCallback = (data) => {
            if (data && data.data) {
                let dashboards = copyObjectValues(tryParse(data.data));
                var originalDashboards = (JSON.stringify(tryParse(data.data)));
                dashboards.forEach(dash => dash[_isSaved] = true);
                let dashBoardWidgets = data.dashBoardWidgets;
                let companyDefaultDashboardId = dashboards.find(col => col[_isCompanyDefault])? dashboards.filter(col => col[_isCompanyDefault])[0][_dashboardId]:"";
                let userDefaultDashboard = dashboards.find(col => col[_isUserDefault])? dashboards.filter(col => col[_isUserDefault])[0]:"";
                let defaultId = dashboards.filter(col => col[_isUserDefault]).length > 0 ? dashboards.filter(col => col[_isUserDefault])[0][_dashboardId] : companyDefaultDashboardId;
                let dashboardOptions = _this.getDashboardOptions(dashboards);

                let tempState = {
                    dashboards: dashboards,           //data as fetched from API
                    originalDashboards: originalDashboards,           //data as fetched from API
                    dashboardOptions: dashboardOptions,     //data after adding label and value to each element
                    companyDefaultDashboardId: companyDefaultDashboardId,
                    defaultDashboardId: !!userDefaultDashboard ? userDefaultDashboard[_dashboardId] : companyDefaultDashboardId,
                    dashboard_access: { isEditUserDashBoardsAllowed: isEditUserDashBoardsAllowed, isEditCompanyDashBoardsAllowed: isEditCompanyDashBoardsAllowed },
                    // defaultDashboardId:defaultDashboardId
                    dashBoardWidgets: dashBoardWidgets,
                }

                if (dashboard) {
                    _this.setState(tempState)
                    _this.callFetchWidgets();
                    _this.props.onSelectDashBoard(dashboard, true);
                } else {
                    _this.setDefaultDashBoardAsSelectedDashBoard(dashboards, defaultId, tempState);
                }
                //TODO for now we're setting the default dashboard = company dashboard; this should be changed to the actual default dashboard for each user and will be done in upcoming stories
            }
        }

        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "fetchDashboards",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: dashboard ? true : false,
            [FETCHAPI_PARAMS.path]: API_URL.DASHBOARD,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.requestDescription]: lang.observability.output.configurations.fetch_dashboards,
            [FETCHAPI_PARAMS.screenName]: getOutputScreenName(window.location.href)+ " " + dashboardComp,
        }

        fetchAPI(fetchOptions);
    }

    setDefaultDashBoardAsSelectedDashBoard(dashboards, defaultDashboardId, tempState, fromDelete=false) {
        if(dashboards && defaultDashboardId) {
            let tempDashBoard = dashboards.filter(prf=>prf[_dashboardId] === defaultDashboardId)[0];
            if(fromDelete) {
                // this.profileBeforeChanges = copyObjectValues(tempProfile);// when deleting from main component replace the initial value
                // this.applyProfileChanges(copyObjectValues(tempProfile), true, tempState, fromDelete);
            } else {
                tempState.selectedDashboard = tempDashBoard;
                this.setState(tempState,function(){
                    this.props.onSelectDashBoard(tempDashBoard);
                });
            }
        } else {
            this.setState(tempState);
        }
    }

    closeDropDown=()=>{
        let _this = this;
        _this.dropDownMenuRef.menuRef.setState({
            isFocused:false,
            inputValue:""
        });
        _this.dropDownMenuRef.menuRef.forceUpdate();
        if ( _this.dropDownMenuRef.menuRef?.dropdownRef?.current?.getDropDown()) {
            _this.dropDownMenuRef.menuRef?.dropdownRef?.current?.showDropdown();
        }
    }

    handleDashBoardChange(option) {
        let _this = this;
        this.setState({
            selectedDashboard:option
        },function(){
            _this.closeDropDown();
            this.props.onSelectDashBoard(option);
        })
    }

    hideSaveProfileDialog() {
        // UIkit.modal('#' + this.saveModalId).hide();
    }

    // cancelSave() {
    //     this.customProfileInputRef.value = "";
    //     this.setState({
    //         ["tempSaved"+_profileName]: undefined,
    //         ["tempSaved"+_visibility]: undefined,
    //         isProfileNameUnique: true,
    //         messageNotMatch: "",
    //         isVisibilityValid: false
    //     });
    // }

    launchToast() {
        $("#toastManageDashboard").addClass("show");
        setTimeout(function(){
            $("#toastManageDashboard").removeClass("show");
        }, 4000);
    }

    editDashboard(evt, dashboard) {
        let _this = this;
        evt.stopPropagation();
        // _this.closeDropDown();
        _this.drawerToggleClickHandler();
        let id = this.state.dashboards.filter(e=>e[DASHBOARDS.NAME] === dashboard[DASHBOARDS.NAME] && e[DASHBOARDS.VISIBILITY] === dashboard[DASHBOARDS.VISIBILITY])[0][DASHBOARDS.ID];
        dashboard[DASHBOARDS.NAME_ID] = dashboard[DASHBOARDS.NAME];
        dashboard[DASHBOARDS.DESCRIPTION_ID] = dashboard[_description] || "";
        dashboard[DASHBOARDS.ID] = id;
        dashboard[DASHBOARDS.MACHINE_NAME] = dashboard[DASHBOARDS.NAME].toLowerCase().replace(" ","_");
        this.setState({
            currentDashboard: copyObjectValues(dashboard),
            isEdit: true,
            isEditing: true
        })
    }

    cloneDashboard = (evt, dashboard) => {
        let _this = this;
        // _this.closeDropDown();
        let clonedDashboard = copyObjectValues(dashboard);
        _this.drawerToggleClickHandler(lang.dashboards.clone_dashboard);

        dashboard[DASHBOARDS.NAME] = this.incrementDashboardName(dashboard[DASHBOARDS.NAME]);
        dashboard[DASHBOARDS.NAME_ID] = dashboard[DASHBOARDS.NAME];
        dashboard[DASHBOARDS.LABEL] = dashboard[DASHBOARDS.NAME_ID];
        dashboard[DASHBOARDS.DESCRIPTION_ID] = dashboard[_description] || "";
        dashboard[DASHBOARDS.MACHINE_NAME] = dashboard[DASHBOARDS.NAME].toLowerCase().replace(" ", "_");
        dashboard[_isCompanyDefault] = false;
        dashboard[_isUserDefault] = false;

        this.setState({
            currentDashboard: copyObjectValues(dashboard),
            clonedDashboard: copyObjectValues(clonedDashboard),
            dashboards: copyObjectValues(this.state.dashboards),
            isClone: true,
            isEditing: true
        }, () => {
            this.checkVisibilityAccess(dashboard);
        })
    }

    /**
     * Increments name by checking the max id of the dashboard with the same name
     * @param {*} name 
     * @returns originalName (count)
     */
    incrementDashboardName = (name) => {
        let counter = 0;
        let originalName = name.substr(0, name.indexOf('(')) !== "" ? name.substr(0, name.indexOf('(')).trim() : name;
        let duplicateNames = this.state.dashboards.filter(f => (f[DASHBOARDS.NAME].substr(0, f[DASHBOARDS.NAME].indexOf('(')) !== "" ? f[DASHBOARDS.NAME].substr(0, f[DASHBOARDS.NAME].indexOf('(')).trim() : f[DASHBOARDS.NAME]) ===originalName);

        if (duplicateNames.length > 0) {
            // get the dashboard with max id 
            let greatestIdObj = duplicateNames.reduce((prev, current) =>
                (Number(prev[DASHBOARDS.NAME].replace(originalName,"").replace("(","").replace(")","").replace(/ /g,'')) > Number(current[DASHBOARDS.NAME].replace(originalName,"").replace("(","").replace(")","").replace(/ /g,''))) ? prev : current)

            counter = greatestIdObj[DASHBOARDS.NAME].replaceAll(/\)/g, "").split("(")[1];
            counter = !isNaN(counter) ? Number(counter) + 1 : 1;
        }

        return originalName + " (" + counter + ")";
    }

    checkVisibility() {
        let _this = this;
        if (!_this.state.isEditing) {
            return true;
        }
        let lists = [];
        let dashboardId = _this.state.currentDashboard[DASHBOARDS.ID];
        let widgets = this.state.dashBoardWidgets.filter(elt => elt[DASHBOARDS.ID] === dashboardId && (elt[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.LIST || elt[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.OTHER_COLUMNS_METRIC || elt[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.VECTOR_ANALYSIS));
        if (dashboardId && this.state.currentDashboard[DASHBOARDS.VISIBILITY] === DASHBOARDS.VISIBILITY_OPTIONS.COMPANY) {
            for (var e in widgets) {
                let config = tryParse(widgets[e][DASHBOARDS.WIDGET.CONFIGURATION]);
                let widget_type = widgets[e][DASHBOARDS.WIDGET.WIDGET_TYPE];
                if (widget_type === DASHBOARDS.WIDGET_TYPES.LIST) {
                    let profileId = config[COLUMN_PROFILE.ID];
                    let profiles = this.manageColumnsRef2.state.columnProfiles;
                    let profile = profiles.filter(p => p[COLUMN_PROFILE.ID] === profileId);
                    if (profile.length > 0) {
                        if (profile[0][COLUMN_PROFILE.VISIBILITY] === COLUMN_PROFILE.VISIBILITY_OPTIONS.USER) {
                            if (!lists.includes(widgets[e][DASHBOARDS.NAME] + ": " + profile[0][COLUMN_PROFILE.NAME])) {
                                lists.push(widgets[e][DASHBOARDS.NAME] + ": " + profile[0][COLUMN_PROFILE.NAME]);

                            }
                        }
                    }
                } else if (widget_type === DASHBOARDS.WIDGET_TYPES.OTHER_COLUMNS_METRIC) {
                    let column = config[_column];
                    if (column[COLUMN_PROFILE.VISIBILITY] === COLUMN_PROFILE.VISIBILITY_OPTIONS.USER) {
                        if (!lists.includes(widgets[e][DASHBOARDS.NAME] + ": " + column[MANAGE_COLUMNS.COLUMN_NAME])) {
                            lists.push(widgets[e][DASHBOARDS.NAME] + ": " + column[MANAGE_COLUMNS.COLUMN_NAME]);

                        }
                    }
                } else if (widget_type === DASHBOARDS.WIDGET_TYPES.VECTOR_ANALYSIS) {
                    let profileIdEG = config[COLUMN_PROFILE.ID];
                    let profilesEG = this.manageColumnsRef3.state.columnProfiles;
                    let profileEG = profilesEG.filter(p => p[COLUMN_PROFILE.ID] === profileIdEG);
                    if (profileEG.length > 0) {
                        if (profileEG[0][COLUMN_PROFILE.VISIBILITY] === COLUMN_PROFILE.VISIBILITY_OPTIONS.USER) {
                            if (!lists.includes(widgets[e][DASHBOARDS.NAME] + ": " + profileEG[0][COLUMN_PROFILE.NAME])) {
                                lists.push(widgets[e][DASHBOARDS.NAME] + ": " + profileEG[0][COLUMN_PROFILE.NAME]);
                            }
                        }
                    }
                }

            }
        }
        if (lists.length === 0) {
            return true;
        }
        return lists;
    }

    /**
     * Function to check if the user has access to create/clone a user/company dashboard and set the dashboard's visibilty based on user's access
     * @param {*} dashboard 
     */
    checkVisibilityAccess = (dashboard) => {
        let visibility = this.state.access.manageCompanyDashboardsAccess ? DASHBOARDS.VISIBILITY_OPTIONS.COMPANY : DASHBOARDS.VISIBILITY_OPTIONS.USER;
        let hasEitherAccess = !this.state.access.manageCompanyDashboardsAccess || !this.state.access.manageUserDashboardsAccess;
        this.setVisibility(hasEitherAccess ? visibility : dashboard[DASHBOARDS.VISIBILITY], DASHBOARDS.VISIBILITY);
    }

    startSaveDashboard(checked,callback) {
        let visibiliyChecked = this.checkVisibility();
        if (visibiliyChecked === true) {
            if (this.state.isClone) {
                this.cloneDashboardOnSave();
            } else {
                this.checkVisibilityAccess(this.state.currentDashboard);
                this.saveDashboard();
            }
            if(typeof callback === "function"){
                callback();
            }
        } else {
            let visibiliyCheckedMessage = lang.dashboards.messages.visibility_check;
            this.setState({
                visibiliyChecked: visibiliyChecked === true ? undefined : visibiliyCheckedMessage,
                visibilityCheckedlist: visibiliyChecked === true ? [] : visibiliyChecked
            })
        }
    }

    saveDashboard(){
        if($('#name_id').val().toLowerCase() === "" /*|| this.state.isNameValid*/){
            this.setState({
                saveDisabled: true,
            });
        }
        if(this.state.isNameValid){
            return;
        }
        if(this.state.currentDashboard[DASHBOARDS.NAME_ID]==="" || this.state.currentDashboard[DASHBOARDS.NAME_ID]=== undefined){
            this.setState({
                isNameValid: lang.dashboards.messages.name_is_empty
            });
            return;
        }
        const _this = this;
        let query = {
            action: "saveNewDashboard",
            currentDashboard: this.state.currentDashboard,
            scenario_id: this.props.scenarioId
        };

        let onThenCallback = (data) => {
            let tempState = {}
            if(data[DASHBOARDS.ID] !== 0) {
                tempState.message = _this.state.currentDashboard[DASHBOARDS.ID] ? lang.dashboards.messages.dashboard_edited_successfully : lang.dashboards.messages.dashboard_created_successfully;
                tempState.isError = false;

                let currentDashboard = copyObjectValues(_this.state.currentDashboard);
                currentDashboard[DASHBOARDS.ID] =_this.state.currentDashboard[DASHBOARDS.ID] ? _this.state.currentDashboard[DASHBOARDS.ID] : data[DASHBOARDS.ID];
                currentDashboard[DASHBOARDS.CREATOR_NAME] = this.props.user.first_name + " " + this.props.user.last_name;
                currentDashboard[DASHBOARDS.NAME] = this.state.currentDashboard.name_id;
                currentDashboard[DASHBOARDS.CREATED_BY] = Number(this.props.user.userId);
                currentDashboard[_description] = this.state.currentDashboard.description_id;
                currentDashboard[_isCompanyDefault] = currentDashboard[_isCompanyDefault] || false;
                currentDashboard[_isUserDefault] = currentDashboard[_isUserDefault] || false;

                let dashboards = copyObjectValues(_this.state.dashboards);
                if (_this.state.currentDashboard[DASHBOARDS.ID]) {
                    dashboards.splice(findIndexOfValue(dashboards, DASHBOARDS.ID, _this.state.currentDashboard[DASHBOARDS.ID]), 1);
                }
                dashboards.push(currentDashboard);
                // _this.onToggleBoard();

                tempState.currentDashboard = currentDashboard;
                tempState.dashboardOptions = _this.getDashboardOptions(dashboards);
                tempState.dashboards = copyObjectValues(dashboards);
                _this.props.onSelectDashBoard(currentDashboard);
            } else {
                tempState.message = lang.execution_error_two;
                tempState.isError = true;
            }
            tempState.isEdit = false;
            _this.setState(tempState,function(){
                _this.launchToast();
                let dashId = _this.state.currentDashboard[DASHBOARDS.ID] ? _this.state.currentDashboard[DASHBOARDS.ID] : data[DASHBOARDS.ID]
                _this.setDefaultDashBoardAsSelectedDashBoard(_this.state.dashboardOptions, dashId, tempState);
            })
        }

        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "saveDashboard",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.DASHBOARD,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.requestDescription]: lang.observability.output.configurations.save_dashboard,
            [FETCHAPI_PARAMS.screenName]: getOutputScreenName(window.location.href)+ " " + dashboardComp,
        };
        fetchAPI(fetchOptions);
    }

    cloneDashboardOnSave = () => {
        const _this = this;
        // if (_this.state.isNameValid) {
        //     return;
        // }
        if (_this.state.currentDashboard[DASHBOARDS.NAME_ID] === "" || _this.state.currentDashboard[DASHBOARDS.NAME_ID] === undefined) {
            _this.setState({
                isNameValid: lang.dashboards.messages.name_is_empty
            });
            return;
        }

        let query = {
            action: "cloneDashboard",
            currentDashboard: _this.state.currentDashboard,
            scenario_id: _this.props.scenarioId
        };

        let onThenCallback = (data) => {
            let tempState = {}
            if (data[DASHBOARDS.ID] !== 0) {
                tempState.message = lang.dashboards.messages.dashboard_cloned_successfully;
                tempState.isError = false;

                let currentDashboard = copyObjectValues(_this.state.currentDashboard);
                currentDashboard[DASHBOARDS.ID] = data[DASHBOARDS.ID];
                currentDashboard[DASHBOARDS.CREATOR_NAME] = _this.props.user.first_name + " " + _this.props.user.last_name;
                currentDashboard[DASHBOARDS.NAME] = _this.state.currentDashboard.name_id;
                currentDashboard[DASHBOARDS.CREATED_BY] = Number(_this.props.user.userId);
                currentDashboard[_description] = _this.state.currentDashboard.description_id;
                currentDashboard[_isCompanyDefault] = currentDashboard[_isCompanyDefault] || false;
                currentDashboard[_isUserDefault] = currentDashboard[_isUserDefault] || false;
            
                let dashboards = copyObjectValues(_this.state.dashboards);
                dashboards.push(currentDashboard);

                tempState.dashboardOptions = _this.getDashboardOptions(dashboards);
                tempState.dashboards = copyObjectValues(dashboards);
                tempState.isClone = false
                _this.fetchDashboards(currentDashboard);

            } else {
                tempState.message = lang.execution_error_two;
                tempState.isError = true;
            }

            _this.setState(tempState, function () {
                // _this.onToggleBoard();
                _this.launchToast();
                // update the selectedDashboard = currentdashboard after clone
                let dashId = data[DASHBOARDS.ID];
                _this.setDefaultDashBoardAsSelectedDashBoard(_this.state.dashboardOptions, dashId, tempState);
            });
        }

        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "cloneDashboardOnSave",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.DASHBOARD,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.requestDescription]: lang.observability.output.configurations.clone_dashboard,
            [FETCHAPI_PARAMS.screenName]: getOutputScreenName(window.location.href)+ " " + dashboardComp,
        };
        fetchAPI(fetchOptions);
    }

    deleteDashboardDialogContent = () => {
        let _this = this;
        let dashboardToBeDeletedId = _this.state.dashboardToBeDeletedId;
        if(!dashboardToBeDeletedId) {return;}

        let dashboardToBeDeleted = _this.state.dashboards.filter(dshb => dshb[_dashboardId] === dashboardToBeDeletedId)[0];

        return (
            <div>
                <div className="uk-display-flex uk-flex-middle pi-warning-background uk-border-rounded uk-padding-xsmall">
                    <i className="fa-2x fal fa-exclamation-triangle uk-margin-default-right" />
                    <div className="fs-16">{lang.dashboards.messages.confirm_delete_dashboard}</div>
                </div>
                <div key="b" className="uk-flex-center uk-padding-large fs-14">
                    <div className="uk-margin-default-bottom">
                        <span className="text-grey col-md-3">{lang.manage_columns.titles.name}</span>
                        <span>{dashboardToBeDeleted[DASHBOARDS.NAME]}</span>
                    </div>
                    <div className="uk-margin-default-bottom">
                        <span className="text-grey col-md-3">{lang.manage_columns.titles.created_by}</span>
                        <span>{dashboardToBeDeleted[DASHBOARDS.CREATOR_NAME]}</span>
                    </div>
                    <div className="uk-margin-default-bottom">
                        <span className="text-grey col-md-3">{lang.manage_columns.titles.visibility}</span>
                        <span>
                            {dashboardToBeDeleted[DASHBOARDS.VISIBILITY] === _visibilityOptions.COMPANY ?
                                <i title="By Company" className="fa-lg fal fa-users uk-margin-small-right"></i>
                                : dashboardToBeDeleted[DASHBOARDS.VISIBILITY] === _visibilityOptions.USER ?
                                    <i title="You" className="fa-lg fal fa-user uk-margin-small-right"></i>
                                    : ""}
                        </span>
                    </div>
                </div>
            </div>
        )
    }
    deleteDashboardDialogActions = () => {
        let _this = this;
        let dashboardToBeDeletedId = _this.state.dashboardToBeDeletedId;

        return (
            <>
                <Button 
                    label={lang.modal.buttons.delete}
                    variant={BUTTON_VARIANT.PRIMARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={() => {
                        _this.deleteDashboard(dashboardToBeDeletedId);
                        _this.setDeleteDashboardDialogOpen(false, undefined);
                    }}
                />
                <Button 
                    label={lang.modal.buttons.cancel}
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={() => _this.setDeleteDashboardDialogOpen(false, undefined)}
                />
            </>
        )
    }
    setDeleteDashboardDialogOpen = (isOpen, dashboardToBeDeletedId) => {
        let _this = this;
        _this.setState({
            openDeleteProfileDialog: isOpen,
            dashboardToBeDeletedId: dashboardToBeDeletedId
        })
    }

    confirmDeleteDashboard(dashboardId) {
        const _this = this;
        _this.setDeleteDashboardDialogOpen(true, dashboardId);
    }

    deleteDashboard(dashboardId){
        const _this = this;
        let query = {
            action: "deleteDashboard",
            dashboardId: dashboardId,
            scenario_id: this.props.scenarioId
        };
        let onThenCallback = (data)=>{
            let message = null;
            let isError = null;

            let dashboards = copyObjectValues(_this.state.dashboards);
            let originalDashboards = copyObjectValues(JSON.parse(_this.state.originalDashboards));
            if(data.success) {
                dashboards.splice(findIndexOfValue(dashboards, DASHBOARDS.ID, dashboardId), 1);
                originalDashboards.splice(findIndexOfValue(originalDashboards, DASHBOARDS.ID, dashboardId), 1);
                message = lang.dashboards.messages.dashboard_deleted_successfully;
                isError = false;
            } else {
                message = lang.execution_error_two;
                isError = true;
            }
            _this.setState({
                message: message,
                isError: isError,
                dashboards: dashboards,
                originalDashboards: JSON.stringify(originalDashboards),
                dashboardOptions: _this.getDashboardOptions(dashboards),
            },function(){
                _this.launchToast();
                if(this.state.selectedDashboard[DASHBOARDS.ID] === dashboardId){
                    let companyDefaultDashboard =  this.state.dashboards.filter(col=>col[_isCompanyDefault])[0];
                    let defaultDashboard = dashboards.filter(col=>col[_isUserDefault]).length > 0 ? dashboards.filter(col=>col[_isUserDefault])[0] : companyDefaultDashboard;
                    _this.makeDashboardDefault(null, defaultDashboard, true);
                }
            })
        }
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "deleteDashboard",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.DASHBOARD,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.requestDescription]: lang.observability.output.configurations.delete_dashboard,
            [FETCHAPI_PARAMS.screenName]: getOutputScreenName(window.location.href)+ " " + dashboardComp,
        };
        fetchAPI(fetchOptions);        
    }

    makeDashboardDefault(e, dashboard,fromDelete = false) {

        const _this = this;
        _this.closeDropDown();
        let query = {
            action: "saveDashboardAsDefault",
            dashboardId: dashboard[_dashboardId],
            scenario_id: this.props.scenarioId
        }

        let onThenCallback = (data) => {
            let message = null;
            let isError = null;
            var tempState = {};
            if(data.success) {
                dashboard[_isUserDefault] = true;
                message = fromDelete ? lang.dashboards.messages.dashboard_deleted_successfully : lang.dashboards.messages.dashboard_make_default_successfully;
                isError = false;
                tempState = {
                    defaultDashboardId: dashboard[_dashboardId],
                    dashboards:this.getUpdatedDashboards(dashboard,true, dashboard[_dashboardId])
                };
                tempState.dashboardOptions = _this.getDashboardOptions(tempState.dashboards);
            } else {
                message = lang.execution_error_two;
                isError = true;
            }
            _this.setState({
                message: message,
                isError: isError
            },function(){
                if(!fromDelete){
                    _this.launchToast();
                }
                _this.setDefaultDashBoardAsSelectedDashBoard(tempState.dashboards, dashboard[_dashboardId], tempState);
            });
        }

        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "makeDashboardDefault",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.config,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: API_URL.DASHBOARD,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.requestDescription]: lang.observability.output.configurations.make_default,
            [FETCHAPI_PARAMS.screenName]: getOutputScreenName(window.location.href)+ " " + dashboardComp,
        }
        fetchAPI(fetchOptions);
        var element = document.getElementById('manageDashboardDropDown');
        UIkit.dropdown(element).hide();
    }

    getUpdatedDashboards(newDashboard ,isAdded, dashboardId) {
        let dashboards = copyObjectValues(this.state.dashboards);
        let currentIndex = findIndexOfValue(dashboards, _dashboardId, dashboardId);
        if(!!newDashboard && newDashboard[_isUserDefault]) {
            dashboards.map(prf=>prf[_isUserDefault] = false);     //reset flag for other profiles
        }
        if(currentIndex > -1) {
            //remove it to either re-add it (if added), change its index (if visibility changed), or not at all (if deleted)
            dashboards.splice(findIndexOfValue(dashboards, _dashboardId, dashboardId), 1);
        }

        if(isAdded) {   //when saving a new profile
            let newIndex = -1;
            if(currentIndex > -1) {
                newIndex = currentIndex;        //re-insert at same index
            } else if(newDashboard[_visibility] === _visibilityOptions.COMPANY) {
                newIndex = findIndexOfValue(dashboards, _visibility, _visibilityOptions.USER);    //insert as last option before "user"
            } else {
                newIndex = dashboards.length;     //insert at the end of the array
            }
            dashboards.splice(newIndex, 0, newDashboard);    //append at previous index or end of array if not found
        }

        return dashboards;
    }

    onToggleBoard(evt, dashboardTitle) {
        const _this = this;
        let hide = false;
        if (this.state.displayPanel) {
            // this.manageDashboardsOverlay.toggleBoard();
            hide = true;
            dashboardTitle = lang.dashboards.create_dashboard;
            this.resetSaveButtonState();

            if (this.state.isClone) {
                this.setState({
                    currentDashboard: copyObjectValues(this.state.clonedDashboard),
                    selectedDashboard: copyObjectValues(this.state.clonedDashboard),
                    dashboardOptions: this.getDashboardOptions(copyObjectValues(this.state.dashboards))
                })
            }
        }

        this.setState({
            displayPanel: !hide,
            isEdit: false,
            dashboardTitle: dashboardTitle ? dashboardTitle : this.state.dashboardTitle
        }, () => {
            if (!hide) {
                // _this.manageDashboardsOverlay.toggleBoard();
            }
        })
        this.resetInputs();
    }

    toggleBoard() {
        //only toggle a css class that will handle the open/close of the menu
        if (this && this.boardRef) {
            this.boardRef.classList.toggle("is-board-open");
            let willShow = this.boardRef.classList.contains("is-board-open");
            if(typeof this.props.onToggleBoard === "function") {
                this.props.onToggleBoard();
            }
            if(willShow) {
                this.initialCheckAll = this.state.allChecked;
                this.profileBeforeChanges = copyObjectValues(this.state.selectedProfile);
            }
        }
    }

    resetSaveButtonState = () =>{
        this.setState({
            saveDisabled: false,
            message: ""
        })
    }

    isDashboardNameValid = (currentDashboard, attribute, attrVal) => {
        let _this = this;
        let message = "";

        if ($('#name_id').val().toLowerCase() === "") {
            message = lang.dashboards.messages.name_is_empty
            this.setState({
                message: message
            })
        }

        let name = attribute === "name_id" ? attrVal : $('#name_id').val().toLowerCase().trim()
        let visibilitytoCheck = attribute === "name_id" ? currentDashboard.visibility : attribute === 'visibility' ? attrVal : _this.state.isClone ? _this.state.VisibilitySelected : this.state.currentDashboard[DASHBOARDS.VISIBILITY];
        
        if (attrVal === "" &&  attribute === "name_id") {
            message = lang.dashboards.messages.name_is_empty
        }
        if (visibilitytoCheck === _visibilityOptions.USER) {
            if (this.state.isClone) {
                if (_this.state.dashboards && _this.state.dashboards.filter(e => e.created_by.toString() === _this.props.user.userId && e.visibility === visibilitytoCheck
                && e.name.toLowerCase() === name && e[DASHBOARDS.ID] !== currentDashboard[DASHBOARDS.ID]).length > 0) {
                    message = lang.dashboards.messages.name_already_used
                }
            } else if (_this.state.dashboards && _this.state.dashboards.filter(e => e.created_by && e.created_by.toString() === _this.props.user.userId 
                && e.visibility ===  currentDashboard.visibility && e.name.toLowerCase() === name && e.pi_dashboard_id !== currentDashboard.pi_dashboard_id).length > 0) {
                message = lang.dashboards.messages.name_already_used
            }
        }

        if (visibilitytoCheck === _visibilityOptions.COMPANY) {
            if (this.state.isClone) {
                if (_this.state.dashboards && _this.state.dashboards.filter(e => e.visibility === visibilitytoCheck && e.name.toLowerCase() === name && e[DASHBOARDS.ID] !== currentDashboard[DASHBOARDS.ID]).length > 0) {
                    message = lang.dashboards.messages.name_already_used
                }
            } else if (_this.state.dashboards && _this.state.dashboards.filter(e => e.visibility === visibilitytoCheck && e.name.toLowerCase() === name && e.pi_dashboard_id !== currentDashboard.pi_dashboard_id).length > 0) {
                message = lang.dashboards.messages.name_already_used
            }
        }
        
        return message
    }
    backdropClickHandler = () => {
        this.setState({
            drawerOpen: false
        })
    }

    drawerToggleClickHandler = (title) => {
        let _this = this
        _this.setState({
            drawerOpen: !_this.state.drawerOpen,
            dashboardTitle: title ? title : lang.dashboards.create_dashboard,           
        })
        _this.closeDropDown();
        this.resetInputs();
    }
    /**
     * function updates the dashbaord being created or edited 
     */
    updateFields=(eventOrValue, attr, id)=> {
        let _this = this;
        let currentDashboard = _this.state.currentDashboard;
        let isNameValid = ""
        let message = "";
        if(attr === DASHBOARDS.NAME_ID || attr === DASHBOARDS.DESCRIPTION_ID){
            message = _this.isDashboardNameValid(currentDashboard, attr, $(eventOrValue.currentTarget).val().toLowerCase().trim())

            if (message === "") {
                currentDashboard[DASHBOARDS.MACHINE_NAME]= $(eventOrValue.currentTarget).val().toLowerCase().replace(" ","_");
                currentDashboard[DASHBOARDS.MACHINE_NAME] = currentDashboard[DASHBOARDS.MACHINE_NAME].trim()
            }
        }
        if (message === "") {
            currentDashboard[attr] = $(eventOrValue.currentTarget).val();
        }
        _this.setState({
            isNameValid : message !== "",
            currentDashboard: currentDashboard,
            saveDisabled: message != "" ? true : false,
            message: message != "" ? message : ""
        })
    }

    setVisibility(visibility,attr) {
        let currentDashboard = this.state.currentDashboard;
        let accessChanged = false;
        if (currentDashboard[DASHBOARDS.VISIBILITY] === _visibilityOptions.COMPANY && visibility.toLowerCase() === _visibilityOptions.USER) {
            accessChanged = true;
        }
        currentDashboard[DASHBOARDS.VISIBILITY] = visibility.toLowerCase();
        let message = this.isDashboardNameValid(currentDashboard, attr, currentDashboard[DASHBOARDS.VISIBILITY])
        this.setState({
            isNameValid : message !== "",
            VisibilitySelected:visibility.toLowerCase(),
            currentDashboard: currentDashboard,
            saveDisabled: message != "" ? true : false,
            message: message != "" ? message : ""
        },function(){
            let visibiliyChecked = this.checkVisibility();
            let visibiliyCheckedMessage = lang.dashboards.messages.visibility_check;
            currentDashboard.accessChanged = accessChanged
            currentDashboard[DASHBOARDS.NAME_ID] = message === "" ? $("#"+DASHBOARDS.NAME_ID).val() : "";
            this.setState({
                visibiliyChecked: visibiliyChecked === true ? undefined : visibiliyCheckedMessage,
                visibilityCheckedlist: visibiliyChecked === true ? [] : visibiliyChecked,
                currentDashboard: currentDashboard
            })
        })
    }

    renderBody() {
        return (
            <div>
                <div className="uk-width-1-1 uk-margin-xmedium-top-bottom">
                    <label htmlFor={DASHBOARDS.NAME_ID} className="fs-14 uk-margin-xsmall-bottom">{lang.dashboards.titles.name}<span className="input_required_text">{"(Required)"}</span></label>
                    <FormComponent tag="input" ref={r => this.inputRefs[DASHBOARDS.NAME_ID] = r} id={DASHBOARDS.NAME_ID} className="uk-input" placeholder={lang.dashboards.titles.name}
                        onChange={(e) => { this.updateFields(e, DASHBOARDS.NAME_ID, this.state.currentDashboard[DASHBOARDS.ID]) }} value={this.state.currentDashboard[DASHBOARDS.NAME_ID]}
                    />
                    {this.state.saveDisabled ? <div className="fs-12 red italic">{this.state.message}</div> : ""}
                    {this.state.isWidgetNameValid && this.state.isWidgetNameValid !== "" ? <div className="fs-12 red italic">{this.state.isWidgetNameValid}</div> : ""}
                </div>
                <div className="uk-margin-xmedium-bottom">
                    <label htmlFor={DASHBOARDS.DESCRIPTION_ID} className="fs-14 uk-margin-xsmall-bottom">{lang.dashboards.titles.description}<span className="input_required_text_disabled">{"(Optional)"}</span></label>
                    <FormComponent tag="textarea" ref={r => this.inputRefs[DASHBOARDS.DESCRIPTION_ID] = r} id={DASHBOARDS.DESCRIPTION_ID} className="uk-textarea" placeholder={lang.dashboards.titles.description}
                        value={this.state.currentDashboard[DASHBOARDS.DESCRIPTION_ID]} onChange={(e) => { this.updateFields(e, DASHBOARDS.DESCRIPTION_ID) }} />
                </div>
                {this.state.access.manageCompanyDashboardsAccess && this.state.access.manageUserDashboardsAccess ?
                    <div className="uk-margin-default-top">
                        <h5 className="uk-margin-xsmall-bottom">{lang.manage_columns.titles.visibility}</h5>
                        <ToggleTab options={this.state.visibilityOptions} onSelectTab={(visibility) => this.setVisibility(visibility,DASHBOARDS.VISIBILITY)}
                            defaultValue={this.state.currentDashboard[DASHBOARDS.VISIBILITY] ? capitalizeFirstLetter(this.state.currentDashboard[DASHBOARDS.VISIBILITY]) : ""}
                        />
                    </div>
                    : ""}
                {this.state.visibiliyChecked ?
                    <div className="fs-12 red italic" style={{ display: "inline-grid" }}>
                        <span className={"uk-text-medium"}>{this.state.visibiliyChecked + (this.state.isClone ? "." : ":")}</span>
                        {!this.state.isClone ?
                            this.state.visibilityCheckedlist.map(function (item) {
                                return <span className={"uk-text-medium"}>{item}</span>
                            }) : ""
                        }
                    </div> : ""}
            </div>
        )
    }

    render() {
        let _this = this;
        let isCreateDashboardsAllowed = getSectionExists(_this.props.userAllowedMenuLinks,MENU_ITEM.FIELDS.CREATE_EDIT_USER_DASHBOARDS) || getSectionExists(_this.props.userAllowedMenuLinks, MENU_ITEM.FIELDS.CREATE_EDIT_COMPANY_DASHBOARDS) || getSectionExists(_this.props.userAllowedMenuLinks,ALL_WIDGETS.MANAGE_USER_DASHBOARDS) || getSectionExists(_this.props.userAllowedMenuLinks,ALL_WIDGETS.MANAGE_COMPANY_DASHBOARDS);
        let isManageCurrentDashboardsAllowed = _this.state.selectedDashboard && (
                                        ((getSectionExists(_this.props.userAllowedMenuLinks,MENU_ITEM.FIELDS.CREATE_EDIT_USER_DASHBOARDS) || getSectionExists(_this.props.userAllowedMenuLinks,ALL_WIDGETS.MANAGE_USER_DASHBOARDS)) && _this.state.selectedDashboard[_visibility] === _visibilityOptions.USER) || 
                                        ((getSectionExists(_this.props.userAllowedMenuLinks, MENU_ITEM.FIELDS.CREATE_EDIT_COMPANY_DASHBOARDS) || getSectionExists(_this.props.userAllowedMenuLinks,ALL_WIDGETS.MANAGE_COMPANY_DASHBOARDS)) && _this.state.selectedDashboard[_visibility] === _visibilityOptions.COMPANY
                && (!_this.state.selectedDashboard[_isCompanyDefault] || (_this.props.user && parseBoolean(_this.props.user[_isSystem])))
            ));
            let buttons = [];
            buttons.push(
                {button:lang.modal.buttons.save, isSubmit: true, isDisabled :this.state.message},
                {button:lang.modal.buttons.cancel, isSubmit: false, isDisabled :false, onClick:this.backdropClickHandler}
            );
            let backdrop = "";
            if (this.state.drawerOpen) {
                backdrop = <Backdrop close={this.backdropClickHandler} />;
            }

        return (
          <React.Fragment>
            <div id="toastManageDashboard" className="toast toast-success">
              <div id="desc">
                <i
                  className={
                    "fa-lg fas uk-margin-small-right " +
                    (this.state.isError
                      ? "fa-minus-circle uk-text-primary"
                      : "fa-check-circle greenText")
                  }
                  aria-hidden="true"
                ></i>
                {this.state.message}
              </div>
            </div>
            {/* {this.state.displayPanel ? ( */}
              <div className="index">
                {/* <div id="overlay-manage-dashboards"></div> */}
                {/* <SidePanel
                  ref={(r) => (this.manageDashboardsOverlay = r)}
                  onToggleBoard={this.onToggleBoard}
                  onCancel={this.onToggleBoard}
                  title={this.state.dashboardTitle}
                  body={this.renderBody()}
                  onSave={this.startSaveDashboard}
                  buttonsDisabled={
                    this.state.visibiliyChecked || this.state.saveDisabled
                      ? [lang.modal.buttons.save]
                      : ""
                  }
                  dashboardSave={this.state.saveDisabled}
                  resetSaveButtonState={this.resetSaveButtonState}
                /> */}

                <SidePanelNew
                //   onToggleBoard={this.onToggleBoard}
                //   onCancel={this.onToggleBoard}
                  title={this.state.isEdit? lang.dashboards.edit_dashboard : this.state.dashboardTitle}
                  body={this.renderBody()}
                  show={this.state.drawerOpen}
                  close={this.backdropClickHandler}
                  onSubmit={this.startSaveDashboard}
                  buttons={buttons}
                //   buttonsDisabled={
                //     this.state.visibiliyChecked || this.state.saveDisabled
                //       ? [lang.modal.buttons.save]
                //       : ""
                //   }
                //   dashboardSave={this.state.saveDisabled}
                //   resetSaveButtonState={this.resetSaveButtonState}
                />
                {backdrop}
              </div>
            {/* // ) : (
            //   ""
            // )} */}
            <DropDownMenu
              ref={(el) => (this.dropDownMenuRef = el)}
              tooltip={
                isCreateDashboardsAllowed
                  ? ""
                  : lang.dashboards.cant_create_dashboards
              }
              groupField={_profileGroup}
              displayName={_creatorName}
              isButtonDisabled={isCreateDashboardsAllowed ? "" : " disabled"}
              value={this.state.selectedDashboard}
              options={this.state.dashboardOptions}
              title={capitaliseFirstLetterAfterChar(
                HEADER_ELEMENT.CREATE_DASHBOARDS
              ).replace(/_/g, " ")}
              onChange={this.handleDashBoardChange}
              toggleBoard={() => this.drawerToggleClickHandler()}
              onDelete={this.confirmDeleteDashboard}
              idKey={DASHBOARDS.ID}
              onEdit={this.editDashboard}
              onMakeDefault={this.makeDashboardDefault}
              idMadeAsDefault={this.state.defaultDashboardId}
              placeholder={
                lang.manage_columns.text.search_dashboards_placeholder
              }
              constraint={"4"}
            />
            {isManageCurrentDashboardsAllowed ? (
              <div className="uk-margin-default-left">
                <Button
                  id={HEADER_ELEMENT.ADD_WIDGET}
                  label={capitaliseFirstLetterAfterChar(HEADER_ELEMENT.ADD_WIDGET).replace(/_/g, " ")}
                  variant={BUTTON_VARIANT.SECONDARY}
                  size={SIZES.DEFAULT}
                  type={BUTTON_TYPE.DEFAULT}
                  style={{width: "100%", padding: "0 20px"}}
                  leftIcon={<i className="far fa-plus-circle fa-lg"/>}
                  onBtnClick={() => this.props.setAddWidgetdDialogOpen(true)}
                />
              </div>
            ) : (
              ""
            )}
            {isCreateDashboardsAllowed ? (
              <div className="uk-margin-default-left">
                <Button  
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.ICON}
                    type={BUTTON_TYPE.DEFAULT}
                    className="uk-button-icon"
                    leftIcon={<i className="fa-2x fal fa-ellipsis-v" />}
                />
                <div
                  id="manageDashboardDropDown"
                  uk-dropdown="mode: click;"
                  className={"uk-dropdown uk-dropdown-bottom-right"}
                >
                  <div>
                    {this.state.selectedDashboard && (this.state.selectedDashboard[_isUserDefault] || (this.state.selectedDashboard[_isCompanyDefault] && !this.state.dashboards?.find(e=>e[_isUserDefault])))? "" :
                        <Button  
                            label={lang.dashboards.make_default}
                            variant={BUTTON_VARIANT.TERTIARY}
                            size={SIZES.DEFAULT}
                            type={BUTTON_TYPE.DEFAULT}
                            className={"uk-button-icon dots_option"}
                            style={{width: "100%", padding: "0 20px"}}
                            leftIcon={<i className="fal fa-check-circle fa-lg black" />}
                            onBtnClick={(e) => this.makeDashboardDefault(e, this.state.selectedDashboard )} 
                        />
                    }
                    {isManageCurrentDashboardsAllowed &&
                    !this.state.selectedDashboard[_isCompanyDefault] ? (
                        <Button  
                            label={lang.dashboards.edit_dashboard}
                            variant={BUTTON_VARIANT.TERTIARY}
                            size={SIZES.DEFAULT}
                            type={BUTTON_TYPE.DEFAULT}
                            className={"uk-button-icon dots_option"}
                            style={{width: "100%", padding: "0 20px"}}
                            leftIcon={<i className="fal fa-edit fa-lg" />}
                            onBtnClick={(e) => this.editDashboard(e, this.state.selectedDashboard )} 
                        />
                    ) : (
                      ""
                    )}
                    <Button  
                        label={lang.dashboards.clone_dashboard}
                        variant={BUTTON_VARIANT.TERTIARY}
                        size={SIZES.DEFAULT}
                        type={BUTTON_TYPE.DEFAULT}
                        style={{width: "100%", padding: "0 20px"}}
                        className={"uk-button-icon dots_option"}
                        leftIcon={<i className="fal fa-clone fa-lg " />}
                        onBtnClick={(e) => this.cloneDashboard(e, copyObjectValues(this.state.selectedDashboard))} 
                    />
                    {isManageCurrentDashboardsAllowed &&
                    !this.state.selectedDashboard[_isCompanyDefault] ? (
                        <Button  
                            label={lang.dashboards.delete_dashboard}
                            variant={BUTTON_VARIANT.TERTIARY}
                            size={SIZES.DEFAULT}
                            type={BUTTON_TYPE.DEFAULT}
                            style={{width: "100%", padding: "0 20px"}}
                            className={"uk-button-icon"}
                            leftIcon={<i className="fal fa-trash-alt fa-lg" />}
                            onBtnClick={() => this.confirmDeleteDashboard(this.state.selectedDashboard[DASHBOARDS.ID])} 
                        />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="uk-hidden">
              <ManageColumns
                ref={(r) => (this.manageColumnsRef2 = r)}
                key={HEADER_ELEMENT.ADD_COLUMNS}
                scenarioId={this.props.scenarioId}
                user={this.props.user}
                manageColsAccess={this.props.manageColsAccess}
                hideDropdown={false}
                companyDefaultIsDefault={true}
                profitFormat={ALL_WIDGETS.FIELDS.LIST}
                updateStateFromParent={this.updateStateFromParent}
                constraint={"3"}
              />
            </div>

            <div className="uk-hidden">
              <ManageColumns
                ref={(r) => (this.manageColumnsRef3 = r)}
                key={HEADER_ELEMENT.ADD_COLUMNS}
                scenarioId={this.props.scenarioId}
                user={this.props.user}
                manageColsAccess={this.props.manageColsAccess}
                hideDropdown={false}
                companyDefaultIsDefault={true}
                profitFormat={MENU_ITEM.FIELDS.EROSION_AND_GROWTH}
                updateStateFromParent={this.updateStateFromParent}
                constraint={"11"}
              />
            </div>

            <div
              ref={(r) => (this.boardRef = r)}
              id="manage-columns-board"
              className="uk-padding-modal-container"
            >
            <Modal 
                id={"delete-dashboard-dialog"}
                title={lang.modal.buttons.delete_dashboard}
                openDialog={this.state.openDeleteProfileDialog}
                bodyContent={this.deleteDashboardDialogContent}
                dialogActions={this.deleteDashboardDialogActions}
                closeClick={() => this.setDeleteDashboardDialogOpen(false, undefined)}
                size={DIALOG_SIZE.LARGE}
            />
            </div>
          </React.Fragment>
        );
    }
}

export default ManageDashboards