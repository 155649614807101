import React, { Component } from 'react';
import { BUTTON_TYPE, BUTTON_VARIANT, CONFIGURE_SECTIONS, FY_VALUES, SIZES } from '../../class/constants';
import { vectorSpacing } from '../../class/jqueries';
import { lang } from '../../language/messages_en';
import '../../styles/common.css';
import Button from '../../newComponents/Button';
import Input from '../../newComponents/Input';
import CheckBox from '../../newComponents/CheckBox';
import SwitchToggle from '../../newComponents/SwitchToggle';
const _displayName = "display_name";
const _vector = "Vector";
const $ = require('jquery');
/**
 *
 * Periods Drop down in Build Screens
 * @author [Sarah Farjallah]
 */

class PeriodsDropDown extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filteredText:""
        };

        this.checkAll = this.checkAll.bind(this);
        this.handleChange = this.handleChange.bind(this);
        
    }

    componentDidMount() {
        var periods = this.props.periods;
        var defaultStatus = this.props.defaultStatus ? this.props.defaultStatus : true;
        for(var e in periods) {
            periods[e].checked = periods[e].checked === undefined ? !periods[e].disabled ? defaultStatus : false : periods[e].checked;
        }
        this.forceUpdate();
    }

    /**
     * this function is fetched when hiding the dropdown, it resets the filteredText and the value of the input "filterText"
     */
    refreshSearch=()=>{
        this.setState({
            filteredText :""
        })
    }

    checkAll(show) {
        var obj = this;
        var periodstoConcat = [];
        var periods = this.props.periods || [];
        for(var e in periods) {
            if (!periods[e]["disabled"]) {
                if (periods[e].checked && !show) {
                    periods[e].checked = show;
                    if(this.props.defaultStatus === undefined) {
                        obj.handleChange(periods[e].value, show)
                    } else {
                        periodstoConcat.push(periods[e].value);
                    }
                } else if (!periods[e].checked && show) {
                    periods[e].checked = show;
                    if(this.props.defaultStatus === undefined) {
                        obj.handleChange(periods[e].value, show)
                    } else {
                        periodstoConcat.push(periods[e].value);
                    }
                }
                
            }
        }
        if(this.props.defaultStatus !== undefined) {
            obj.handleChange(periodstoConcat, show, true)
        }

    }


    handleChange(periodName, show, isArr) {
        var periodstoConcat = [];
        var periods = this.props.periods;
        if(!isArr) {
            for(var e in periods) {
                if (periods[e]["value"] === periodName && !periods[e]["disabled"]) {
                    periods[e].checked = show;
                    $("#toggle_button"+periods[e].value).prop('checked',show)
                    
                }else if (periods[e]["value"] === periodName && periods[e]["disabled"]){
                    return
                }
            }
        }else{ //!! if isArr is set to true the periodName if of type array and need to toggle them
            for(var e in periodName) {
                if (!periods[e]["disabled"]) {
                    periods[e].checked = show;
                    $("#toggle_button"+periods[e].value).prop('checked',show)
                }
            }
        }
        
        periodstoConcat.push(periodName);
        if (this.props.defaultStatus !== undefined && !isArr) {
            this.props.funkName(periodstoConcat, show);
        } else{
            this.props.funkName(periodName, show);
        }
        this.forceUpdate();
    }

    search(e) {
        this.setState({
            filteredText: e.target.value
        })
    }

    render() {
        var obj = this;
        var periods = this.props.periods;
        var disable = true;
        var disableHide = true;
        var countChecked = 0;
        for (var e in periods) {
            if (periods[e].checked) {
                disable = false;
                countChecked++;
            }else{
                disableHide = false
            }
        }
        var disabled = this.props.is_default !== undefined ? this.props.is_default ? " uk-button-disabled " : disableHide ? " uk-button-disabled " : "" : "";
        var disabledHide = this.props.is_default !== undefined ? this.props.is_default ? " uk-button-disabled " : disable ? " uk-button-disabled " : "" : "";
        var marginClass = " uk-margin-small-left";
        return (
            <React.Fragment>
                {/* <i className="search-icon fa-lg fal fa-search text-light-grey"></i> */}
                <Input id="filterText"  autoComplete="off" className = "input-default" isSearch={true} value={this.state.filteredText? this.state.filteredText :""} placeholder={this.props.placeHolderText +"   " +(obj.props.report === lang.COMMON.LIST? "" :countChecked + "/" + (periods ? periods.length : 0))} onChange={(e)=>{this.search(e)}}>
                </Input>
                <div className = "div-periods-drop-down">
                    {this.props.periods ? 
                    this.props.periods.map(function(item){
                        if(obj.props.report === lang.COMMON.LIST){
                            item.checked = true;
                            if(item.isGroupTitle){
                                item.disabled = true;
                            }
                            // item.label = vectorSpacing(item.label.replace(/ /g,''));
                        }
                        if(obj.state.filteredText && obj.state.filteredText !== "" ) {
                            if(item["label"].toLowerCase().includes(obj.state.filteredText.toLowerCase())) {
                                if (item.checked && !item.disabled) {
                                    return <div className={"uk-flex " + (obj.props.report === lang.COMMON.LIST? " drop-down-item " :" uk-margin-xsmall-bottom uk-margin-small-right uk-margin-small-left ")} key={item["value"]} onClick={()=>{if (obj.props.report === lang.COMMON.LIST) obj.handleChange(item["value"], false)}}>
                                                {obj.props.report !== lang.COMMON.LIST ?
                                                    <SwitchToggle isDisabled={item.disabled}  id={"toggle_button" + item.value} name="FY" value={FY_VALUES.ON} checked={true} onChange={() => { obj.handleChange(item["value"], false) }}/>
                                                :""
                                                }
                                                <p className={"fs-14 " + marginClass}>{item["label"] === _displayName ? _vector : item["label"]}</p>
                                            </div>
                                } else {
                                    return <div className={"uk-flex " + (obj.props.report === lang.COMMON.LIST? " drop-down-item-disabled " :" uk-margin-xsmall-bottom uk-margin-small-right uk-margin-small-left ")} key={item["value"]}>
                                                {obj.props.report !== lang.COMMON.LIST ?
                                                    <SwitchToggle isDisabled={item.disabled} id={"toggle_button" + item.value} name="FY" value={item.checked?FY_VALUES.ON:FY_VALUES.OFF} checked={item.checked} onChange={() => { obj.handleChange(item["value"], true) }}/>
                                                    :""
                                                }
                                                <p className={"fs-12 uk-text-bold"+ marginClass}>{item["label"] === _displayName ? _vector : item["label"]}</p>
                                            </div>
                                }
                            }
                            
                        } else{
                            if (item.checked && !item.disabled) {
                                return <div className={"uk-flex " + (obj.props.report === lang.COMMON.LIST? " drop-down-item " :" uk-margin-xsmall-bottom uk-margin-small-right uk-margin-small-left ")} key={item["value"]} onClick={()=>{if (obj.props.report === lang.COMMON.LIST) obj.handleChange(item["value"], false)}}>
                                            {obj.props.report !== lang.COMMON.LIST ?
                                                <SwitchToggle isDisabled={item.disabled} id={"toggle_button" + item.value} checked={true} name="FY" value={FY_VALUES.ON} onChange={() => { obj.handleChange(item["value"], false) }} />
                                            :""}
                                            <p className={"fs-14 " + marginClass}>{item["label"] === _displayName ? _vector : item["label"]}</p>
                                        </div>
                            } else {
                                return <div className={"uk-flex "+ (obj.props.report === lang.COMMON.LIST? " drop-down-item-disabled " :" uk-margin-xsmall-bottom uk-margin-small-right uk-margin-small-left ")} key={item["value"]}>
                                            {obj.props.report !== lang.COMMON.LIST ?
                                                <SwitchToggle isDisabled={item.disabled} id={"toggle_button" + item.value} name="FY" value={item.checked?FY_VALUES.ON: FY_VALUES.OFF} checked={item.checked} onChange={() => { obj.handleChange(item["value"], true) }} />
                                            :""}
                                            <p className={"fs-12 uk-text-bold" + marginClass}>{item["label"] === _displayName ? _vector : item["label"]}</p>
                                        </div>
                            }
                        }
                        
                    })
                    : ""}  
                </div>
                {this.props.report !== lang.COMMON.LIST ? 
                    <div className="btn-dropdown-container uk-flex uk-flex-middle">
                        {this.props.showText?
                            <Button  
                                label={this.props.showText}
                                variant={BUTTON_VARIANT.SECONDARY}
                                size={SIZES.DEFAULT}
                                type={BUTTON_TYPE.DEFAULT}
                                className="btn-dropdown"
                                disabled={disabled !== ""}
                                onBtnClick={()=>{this.checkAll(true)}}
                            />
                            :""
                        }
                        {this.props.hideText?
                            <Button  
                                id="uncheck-all"
                                label={this.props.hideText}
                                variant={BUTTON_VARIANT.SECONDARY}
                                size={SIZES.DEFAULT}
                                type={BUTTON_TYPE.DEFAULT}
                                className="btn-dropdown uk-margin-xsmall-left"
                                disabled={disabledHide !== ""}
                                onBtnClick={()=>{this.checkAll(false)}}
                            />
                            :""
                        }
                    </div>
                :""}
                {this.props.is_default === false ? <div className="btn-dropdown-message">{this.props.message}</div> : ""}
            </React.Fragment>
        );
    }
}


export default PeriodsDropDown;