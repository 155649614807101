import { Checkbox, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Typography,} from "@mui/material";
import { useState, useRef, useLayoutEffect, useEffect, forwardRef, useImperativeHandle } from "react";
import { generate } from "shortid";
import CheckBox from "../newComponents/CheckBox";

const CheckableList = ({ itemsList, handleCheckboxChange, extraInfo, // to show extra info next to list labe (ie: vector number)
checkAll, scrollProps, limitReached, ...props }, ref) => {
  useImperativeHandle(ref, () => ({
    setLimitReached: (isReached) => {
      setIsLimitReached(isReached);
     }
  }));

  const [isLimitReached, setLimitReached] = useState(limitReached);

  /**
   * Setting limitReached in a ref from props instead of state to avoid re-rendering the component to avoid having scrolling issues.
   * Setting it in a state in this component because I want to rerender the checkbox element without having scrolling issues.
   */
  const setIsLimitReached = (isReached) => {
    setLimitReached(isReached);
    props.setLimitReached(isReached)
  }

  const [checked, setChecked] = useState([0]);
  let containerRef = useRef({});
  const [scroll, setScroll] = useState(scrollProps || 0)
  const handleToggle = (value) => () => {
    var currentIndex = checked.indexOf(value);
    var newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);

    if (handleCheckboxChange) {
      handleCheckboxChange(value, containerRef.current.scrollTop);
    }
  };

  useEffect(()=>{
    if (containerRef.current) {
      containerRef.current.scrollTop = scroll;
    }
  },[])

  let zoom = ((window.outerWidth - 10) / window.innerWidth) * 100;
  return (
    <List sx={{ width: "100%", p: 0, maxHeight: zoom > 109 ? "35vh" : "38vh", overflow: "auto" }} ref={containerRef}>
      {itemsList?.map((item) => {
        const labelId = `checkbox-list-label-${item.value}`;
        if (item.isGroupTitle) {
          return (
            <ListSubheader
              key={Math.random()}
              sx={{
                fontSize: "0.8vw",
                fontWeight: "bold",
                lineHeight: 3,
                backgroundColor: "#F3F3F3",
                fontFamily: "'Open Sans', Arial, sans-serif",
                letterSpacing: "normal",
              }}
            >
              {item.label}
            </ListSubheader>
          );
        }
        return (
          <div key={Math.random()} className={"checkable-list " + (isLimitReached && !item.checked ? "disabled" : "")} >
            <div key={Math.random()} className="checkable-list-item uk-flex row uk-margin-xxmedium-top uk-margin-xxmedium-bottom">
              <div key={Math.random()} className="checkable-list-item-div uk-display-flex col-md-10" onClick={handleToggle(item)}>
                <div className="checkable-list-item-checkbox-div uk-margin-small-left">
                  <CheckBox id={Math.random()} disabled={isLimitReached && !item.checked} checked={checkAll ? checkAll : item.checked} 
                    labelClassName={"uk-margin-small-left uk-text-overflow-ellipsis"} labelId={labelId} labelText={item.label} />
                </div>
              </div>
              {extraInfo ? <div key={Math.random()} className="extra-info-text uk-float-right col-md-2 uk-margin-small-right">{item[extraInfo] !== item.label?item[extraInfo] : ""}</div> : null}
            </div>
            <Divider />
          </div>
        );
      })}
    </List>
  );
};

export default forwardRef(CheckableList);
