import React, { Component } from 'react';
import { auth } from './firebase/firebase.js';
import { sendPasswordResetEmail } from 'firebase/auth';
import './styles/login.css';
import HttpsRedirect from 'react-https-redirect';
import Popup from 'react-popup';
import './styles/popup.css';
import { Email, ResetPasswordBtn, CancelBtn, Loader } from './form/elements.js';
import { waitBeforeReload } from './class/utils';
import { UI_ACTIONS } from './class/constants';
import LogoPI from './LogoPI.js';

const $ = require('jquery');

const ERROR_USER_NOT_FOUND = "auth/user-not-found";

class ResetPassword extends Component {

	constructor(props) {
		super(props);
		this.resetPassword = this.resetPassword.bind(this);
		this.cancel = this.cancel.bind(this);
	}

  resetPassword(e){
		e.preventDefault();
		var emailAddress = $('#Email').val();
		
		//we set a timeout because if the user pressed 'enter' it would trigger a 'keyup' listener set in waitBeforeReload()
		setTimeout(function(){
			sendPasswordResetEmail(auth, emailAddress)
			.then(function() {
				window._pi_stopTrackingAndCheckAction(UI_ACTIONS.RESET_PWD);
				Popup.alert("Reset password email sent to " + emailAddress + "."); {/* NOT USED */}
				waitBeforeReload(60000,"/");
			}).catch(function(error) {
				if (error.code.toLowerCase() === ERROR_USER_NOT_FOUND) {
					Popup.alert("Reset password email sent to " + emailAddress + "."); {/* NOT USED */}
				} else {
					Popup.alert(error.message); {/* NOT USED */}
				}
				waitBeforeReload(60000,"/");
			});
		}, 200)  
	}

	cancel(){
		window.location.href = "/";
	}

	componentDidMount() {
		window._pi_stopTrackingAndCheckAction(UI_ACTIONS.FORGOT_PWD);
	}

	render() {	
		return (
			<HttpsRedirect>
			<div className="main-body" >
			<Popup />
				<header className="login-header">
		    		<LogoPI />
		    	</header>
		    	<main id="body">
			    	<section className="container reset-pass-container">
					<div id="loginForm" className="login-form mrgt5 mrgb10">
						<h3>Forgot your password?</h3>
						<h6>We'll help you reset and get back on track.</h6>
						<form onSubmit={this.resetPassword}>
						    <section className="mrgt30 mrgb15">
						        <Email/>
						        <div className="row mrgt30 rememberBtn">
									<div className="col-12">
										<div className="row rememberBtn">
											<ResetPasswordBtn onClick = {this.resetPassword}/>
											<CancelBtn onClick ={this.cancel}/>
						                </div>
										<Loader/>
						              </div>
						        </div>
						    </section>
						</form>      
					</div>
				    </section>
		    	</main>
		    	{/*<Footer class="footer-fixed" />*/}
			</div>
			</HttpsRedirect>
		);
	}

}

export default ResetPassword;
