import React, { Component } from 'react';
import Popup from 'react-popup';
import shortid from 'shortid';
import {TabulatorFull} from "tabulator-tables"; //import {TabulatorFull} library

import { cleanUpTabulatorColumns, toggleLoader, setLocalStorageValueByParameter } from '../class/common.js';
import { ALL_WIDGETS, API_URL, FormatTypes, SCENARIO_ACTION, SCENARIO_FIELDS, SCENARIO_STATUS, SCENARIO_TITLES ,SCENARIO_TYPE, ALL_REPORTS, BUTTON_VARIANT, SIZES, BUTTON_TYPE, DIALOG_SIZE, MENU_ITEM, BUILD_DETAILS} from '../class/constants';
import { formatValHTML } from "../class/format";
import { alertAndLogError } from '../class/jqueries.js';
import { deleteItemFromSession, FETCH_METHOD, fetchAPI, FETCHAPI_PARAMS } from '../class/networkUtils';
import { copyObjectValues, getSectionExists, getTranslationFile,generateActionToken} from '../class/utils.js';
import ConfigureBudget from '../components/budgeting/ConfigureBudget';
import { updatePsLinesOptions } from '../actions/filterActions';
import { lang } from '../language/messages_en';
import { updateColumnProfiles, updateCustomGroups, updateStateProfiles } from '../actions/manageColumnsActions';
import { updateDatasetOptions, updateDatasetState, updatePeriodsStatus, updatePeriodsStatusState, updateProfitstackHierarchyByStack, updatePsViews, updatePsViewsState, updateScenarioState, updateScenarios, updateTiers, updateVectorState } from '../actions/scenariosActions';
import '../styles/manageScenario.css';
import Button from '../newComponents/Button';
import Input from '../newComponents/Input';
import CheckBox from '../newComponents/CheckBox';
import { getTableButton, getTableIconButton, getTablePlaceholder } from '../newComponents/tabulatorComponents';
import Modal from '../newComponents/Modal';
import { redirectToPSLineDefinitionsScreen, redirectToVectorDefinitionsScreen, redirectToModelCoherenceScreen } from '../templateLayout/functions/componentFunctions.js';

const MESSAGES = getTranslationFile();
const $ = require('jquery');

const baseUrl = process.env.REACT_APP_BASE_URL;
const path = API_URL.DATA_MODELING;
const SUCCESS = "success";

const SCENARIO_COLUMNS =[
    {   field: SCENARIO_FIELDS.ADD_ROW,
        width: '8%',
        headerSort: false,
    },
    {
        field: SCENARIO_FIELDS.NUMBER,
        title: SCENARIO_TITLES.NUMBER,
        format_type: FormatTypes.TEXT,
        sorter:"number",
        sorterParams: {alignEmptyValues:"bottom"},
    },
    {
        title: SCENARIO_TITLES.NAME,
        field: SCENARIO_FIELDS.NAME,
        format_type: FormatTypes.TEXT,
        sorter:"string",
        headerSort: true,
        sorterParams:{alignEmptyValues:"bottom"},
    },
    {
        title: SCENARIO_TITLES.CREATED_BY,
        field: SCENARIO_FIELDS.CREATED_BY,
        format_type: FormatTypes.TEXT,
        sorter:"string",
        sorterParams:{alignEmptyValues:"bottom"},
    },
    {
        title: SCENARIO_TITLES.LAST_MODIFIED_BY,
        field: SCENARIO_FIELDS.LAST_MODIFIED_BY,
        format_type: FormatTypes.TEXT,
        sorter:"string",
        sorterParams:{alignEmptyValues:"bottom"},
    },
    {
        title: SCENARIO_TITLES.CREATED_DATE,
        field: SCENARIO_FIELDS.CREATED_DATE,
        paddingTop: 10,
        minWidth: '14%',
        formatter:"date",
        sorter:"string",
        sorterParams:{alignEmptyValues:"bottom"},
    },
    {
        title: SCENARIO_TITLES.STATUS,
        field: SCENARIO_FIELDS.STATUS,
        formatter:"headerselect",
        sorterParams:{alignEmptyValues:"bottom"},
    },
    {
        title: SCENARIO_TITLES.PROGRESS,
        field: SCENARIO_FIELDS.PROGRESS,
        formatter: "progress",
        headerSort:false
    },
    {
        title: SCENARIO_TITLES.ICON,
        field: SCENARIO_FIELDS.ICON,
        headerSort:false,
        cssClass: "uk-padding-remove",
        width: '2%'
    },
    {
        title: SCENARIO_TITLES.ACTIONS,
        field: SCENARIO_FIELDS.ACTIONS,
        headerSort:false,
        width: '3%'
    }
];

const STAGE = ALL_WIDGETS.FIELDS.STAGE;

/**
 *
 * Manage Scenarios/Publish in Engine UI/Menu
 * @author [Sarah Farjallah]
 */

class ManageScenarios extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scenariosData : [],
            isCreateNew: false,
            manageScenarioView: ALL_WIDGETS.FIELDS.MANAGE_SCENARIOS,    //default
            cloneWithBuiltDataNewArch:false
        };

        this.getScenariosData = this.getScenariosData.bind(this);
        this.getColumnFormatter = this.getColumnFormatter.bind(this);
        this.addScenario = this.addScenario.bind(this);
        this.createScenarioFromScratch = this.createScenarioFromScratch.bind(this);
        this.updateScenario = this.updateScenario.bind(this);
        this.addNewRowTitleFormatter = this.addNewRowTitleFormatter.bind(this);
        this.addTooltipTitleFormatter = this.addTooltipTitleFormatter.bind(this);
        this.inputOnclick = this.inputOnclick.bind(this);
        this.openNewScenarioModal = this.openNewScenarioModal.bind(this);
        this.checkForExistingName = this.checkForExistingName.bind(this);
        this.renameScenario = this.renameScenario.bind(this);
        this.changeCheckboxesOnClone = this.changeCheckboxesOnClone.bind(this);
        this.uncheckBoxesOnCancel = this.uncheckBoxesOnCancel.bind(this);
        this.launchToast = this.launchToast.bind(this);
    }

    addScenario() {
        let obj = this;
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth()+1;
        let yyyy = today.getFullYear();
        let newScenarioId = Math.max.apply(Math, this.state.scenariosData.map(function(o) { return o.scenario_id; }));
        let newScenario = {scenario_id: newScenarioId + 1, name: obj.scenarioId, created_by: obj.props.user, created_date: yyyy + "-" + mm + "-" + dd,
                            status: SCENARIO_STATUS.SANDBOX, staged_periods:"", assigned_cost_periods:"", computed_quarters:"",
                            actions:"", progress:""
                        };

        let data = copyObjectValues(this.state.scenariosData);
        data.push(newScenario);
        this.setState({
            scenariosData: data,
            scenariosData_id: shortid.generate()
        })
    }

    openNewScenarioModal(isNew, cell, action) {
        var obj = this;
        $("#input-required").val(""); //EMPTYING THE INPUT
        this.isNameValid = "";
        this.scenarioStatus = cell && cell.getData() ? cell.getRow().getData()[SCENARIO_FIELDS.STATUS] : "";
        this.scenarioId = cell && cell.getData() ? cell.getRow().getData()[SCENARIO_FIELDS.ID] : "";
        this.scenarioNumber = cell && cell.getData() ? cell.getData()[SCENARIO_FIELDS.NUMBER] : "";
        this.scenarioName = cell && cell.getData() ? cell.getData()[SCENARIO_FIELDS.NAME] : "";
        this.userHasSandbox = this.state.scenariosData.filter(e => e[SCENARIO_FIELDS.LAST_MODIFIED_BY] === obj.props.user.first_name + " " + obj.props.user.last_name && e[SCENARIO_FIELDS.STATUS] === SCENARIO_STATUS.SANDBOX).length > 0;
        if ([SCENARIO_ACTION.CLONE,SCENARIO_ACTION.CLONE_NEW_ARCH,SCENARIO_ACTION.CLONE_AS_BUDGETING].includes(action)) {
            this.successMsg = cell && cell.getData() ? MESSAGES.success_scenario_clone.replace("{x}", cell.getData()[SCENARIO_FIELDS.NUMBER]) : "";
        }

        this.inputOnclick();
        this.setState({
            isCreateNew: isNew,
            action: action,
        }, ()=>{
            this.setscenarioNameDialogOpen(true)
        })
    }
    /**
     * removes data related to the scenario from the redux store
     * @param {*} obj
     * @param {*} scenario_id
     */
    clearStoreForScenario(obj, scenario_id) {
        if (obj.props.dispatch) {
            obj.props.dispatch(updateDatasetOptions([],scenario_id));
            obj.props.dispatch(updatePeriodsStatus([],scenario_id));
            obj.props.dispatch(updatePeriodsStatusState([],scenario_id + "_12"));
            obj.props.dispatch(updateTiers([],scenario_id));
            obj.props.dispatch(updateDatasetState([],scenario_id + "_12"));
            obj.props.dispatch(updateVectorState([],scenario_id));
            obj.props.dispatch(updateScenarios([]));
            obj.props.dispatch(updateScenarioState({}));
            obj.props.dispatch(updatePsViews([],scenario_id));
            obj.props.dispatch(updatePsViewsState([],scenario_id));
            obj.props.dispatch(updatePsLinesOptions([],scenario_id));
            obj.props.dispatch(updateProfitstackHierarchyByStack([], undefined, true));
            obj.props.dispatch(updateCustomGroups([],scenario_id));
            obj.props.dispatch(updateStateProfiles([],scenario_id+"_"+ALL_REPORTS.LIST_TOP,true));
            obj.props.dispatch(updateColumnProfiles([],scenario_id+"_"+ALL_REPORTS.LIST_TOP,true));
        }
    }

    configureBudgetScenario = (cell) => {
        this.props.goToConfigureBudget();
        let token = generateActionToken(5);
        this.setState({
            budgetScenarioId: cell && cell.getData() ? cell.getRow().getData()[SCENARIO_FIELDS.ID] : "",
            budgetScenarioName: cell && cell.getData() ? cell.getData()[SCENARIO_FIELDS.NAME] : "",
            token:token
        })
    }

    renameScenario() {
        let obj = this;
        if (this.isNameValid !== "") {
            return;
        }
        if ($("#input-required").val() === "") {
            $("#input-required").addClass("uk-form-danger");
            return;
        }
        obj.clearStoreForScenario(obj, obj.scenarioId);
        let query = {
            action: "renameScenario",
            name: $("#input-required").val(),
            oldScenario: this.scenarioId
        }


        let onThenCallback = () => {
            obj.getScenariosData();
        }

        let onCompleteCallback = () => {
            this.setscenarioNameDialogOpen(false);
        }

        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "renameScenario",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader] : true,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.onCompleteCallback]: onCompleteCallback,
            [FETCHAPI_PARAMS.screenName]:lang.observability.manage_scenarios.screen_name,
            [FETCHAPI_PARAMS.requestDescription]:lang.observability.manage_scenarios.requests_description.rename_scenario
        }

        fetchAPI(fetchOptions);
    }

    createScenarioFromScratch() {
        if (this.isNameValid !== "") {
            return;
        }
        if ($("#input-required").val() === ""){
            $("#input-required").addClass("uk-form-danger");
        } else {
            if (this.state.scenariosData.length +1 > this.props.scenarios_limit) {
                this.setInfoDialogOpen(true, MESSAGES.scenarios_limit_exceeded);
                return;
            }
            let obj = this;
            if (obj.props.dispatch ) {
                obj.props.dispatch(updateScenarios([]));
                obj.props.dispatch(updateScenarioState({}));
            }
            let query = {
                action: "createScenarioFromScratch",
                name: $("#input-required").val() === "" ? "UNNAMED" :  $("#input-required").val()
            }

            let onThenCallback = (data) => {
                if (data.message) {
                    this.setInfoDialogOpen(true, data.message);
                    obj.getScenariosData();
                } else if (data.error) {
                    this.setInfoDialogOpen(true, data.error);
                }
            }

            let onCompleteCallback = () => {
                obj.setState({
                    isCreateNew: false
                }, ()=>{
                    this.setscenarioNameDialogOpen(false)
                })
            }

            let fetchOptions = {
                [FETCHAPI_PARAMS.funcName]: "createScenarioFromScratch",
                [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
                [FETCHAPI_PARAMS.showLoader] : true,
                [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
                [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
                [FETCHAPI_PARAMS.query]: query,
                [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
                [FETCHAPI_PARAMS.onCompleteCallback]: onCompleteCallback,
                [FETCHAPI_PARAMS.screenName]:lang.observability.manage_scenarios.screen_name,
                [FETCHAPI_PARAMS.requestDescription]:lang.observability.manage_scenarios.requests_description.create_scenario_from_scratch
            }

            fetchAPI(fetchOptions);
        }
    }

    confirmUpdateScenario(cell, e, action, scenarioType) {
        let _this = this;
        let status = "";
        let message = "";
        let successMessage = "";
        let scenarioId = cell.getData()[SCENARIO_FIELDS.ID];
        let callback = () => {
            _this.updateScenario(cell,scenarioType);
            _this.setConfirmDialogOpen(false, "")
        };

        switch(action) {

            case SCENARIO_ACTION.PUBLISH: {
                message = MESSAGES.confirm_scenario_publish;
                successMessage = MESSAGES.success_scenario_publish;
                status = SCENARIO_STATUS.PUBLISHED;
            }
            break;

            case SCENARIO_ACTION.REVIEW: {
                message = MESSAGES.confirm_scenario_review;
                successMessage = MESSAGES.success_scenario_review;
                status = SCENARIO_STATUS.REVIEW;
            }
            break;

            case SCENARIO_ACTION.RENAME: {
                message = MESSAGES.confirm_scenario_rename;
                successMessage = MESSAGES.success_scenario_rename;
                status = cell.getRow().getData()[SCENARIO_FIELDS.STATUS];
            }
            break;

            case SCENARIO_ACTION.DELETE: {
                message = MESSAGES.confirm_scenario_delete;
                successMessage = MESSAGES.success_scenario_delete;
                status = cell.getRow().getData()[SCENARIO_FIELDS.STATUS];
            }
            break;

            case SCENARIO_ACTION.CHECKOUT: {
                message = MESSAGES.confirm_scenario_checkout.replace("{y}", cell.getData()[SCENARIO_FIELDS.NAME]);
                successMessage = MESSAGES.success_scenario_checkout;
                status = SCENARIO_STATUS.SANDBOX;
            }
            break;

            default:
            break;
        }

        this.scenarioStatus = status;
        this.scenarioId = scenarioId;
        this.successMsg = successMessage.replace("{x}", cell.getData()[SCENARIO_FIELDS.NUMBER]);
        this.action = action;
        this.setState(this.state);
        this.setConfirmDialogOpen(true, message.replace("{x}", cell.getData()[SCENARIO_FIELDS.NUMBER]), callback);
    }

    updateScenario(cell, scenarioType) {
        var obj = this;
        var status = this.scenarioStatus;
        var scenario_id = this.scenarioId;
        var successMessage = this.successMsg;
        // removing  data related to scenarios from redux store 
        this.clearStoreForScenario(this, scenario_id);

        var query = {
            action: "updateScenarioStatus",
            scenario_id: scenario_id,
            status: status,
            name: cell ? cell.getRow().getData()[SCENARIO_FIELDS.NAME] : "",
            isBudgeting : scenarioType === SCENARIO_TYPE.BUDGETING
        }

        if (this.action && this.action === SCENARIO_ACTION.DELETE) {
            query.action = "deleteScenario";
        }

        let onThenCallback = (data) => {
            if(data && data.result.toLowerCase() === SUCCESS) {
                obj.launchToast(true, successMessage);
                obj.getScenariosData();
                deleteItemFromSession("getScenarios");
            }
        }

        let onErrorCallback = () => {
            obj.launchToast(false);
        }

        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "updateScenario",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader] : true,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.onErrorCallback]: onErrorCallback,
            [FETCHAPI_PARAMS.screenName]:lang.observability.manage_scenarios.screen_name,
            [FETCHAPI_PARAMS.requestDescription]:lang.observability.manage_scenarios.requests_description.update_scenario_status
        }

        fetchAPI(fetchOptions);
    }

    launchToast(success, message) {
        $("#toastManageScenario i")[0].classList.add("fa-lg","fas","uk-margin-small-right");
        if(success === true) {
            $("#toastManageScenario i")[0].classList.add("fa-check-circle","greenText");
            $("#toastManageScenario #message").text(message);
        } else {
            $("#toastManageScenario i")[0].classList.add("fa-minus-circle","uk-text-primary");
            $("#toastManageScenario #message").text(MESSAGES.something_went_wrong);
        }
        $("#toastManageScenario").addClass("show");
        setTimeout(function(){
            $("#toastManageScenario").removeClass("show");
        }, 4000);
    }


    cloneScenario(isBudgeting) {
        let cloneWithBuiltDataNewArch;
        if (this.isNameValid !== "") {
            return;
        }
        if (this.state.scenariosData.length +1 > this.props.scenarios_limit) {
            this.setInfoDialogOpen(true, MESSAGES.scenarios_limit_exceeded);
            return;
        }
        if ($("#input-required").val() === "") {
            $("#input-required").addClass("uk-form-danger");
            return;
        }

        cloneWithBuiltDataNewArch = this.state.cloneWithBuiltDataNewArch;

        var scenario_id = this.scenarioId;
        var successMessage = this.successMsg;

        var obj = this;
        if (obj.props.dispatch) {
            obj.props.dispatch(updateScenarios([]));
            obj.props.dispatch(updateScenarioState({}));
        }
        let query = {
            action: "cloneScenario",
            scenario_id: scenario_id,
            name: $("#input-required").val() === "" ? "UNNAMED" : $("#input-required").val(),
            cloneWithBuiltDataNewArch:!isBudgeting ? cloneWithBuiltDataNewArch : false,
            isBudgeting:isBudgeting
        }
        setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
        this.setscenarioNameDialogOpen(false)


        let onThenCallback = (data) => {
            if (data && data.data === "SUCCESS") {
                obj.setscenarioNameDialogOpen(false)

                obj.launchToast(true, successMessage);
                obj.getScenariosData();
            } else {
                obj.launchToast(false, "Something went wrong! please contact your system adminstration");
            }
        }

        let onErrorCallback = () => {
            obj.launchToast(false);
        }

        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "cloneScenario",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader] : true,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.onErrorCallback]: onErrorCallback,
            [FETCHAPI_PARAMS.screenName]:lang.observability.manage_scenarios.screen_name,
            [FETCHAPI_PARAMS.requestDescription]:lang.observability.manage_scenarios.requests_description.clone_scenario
        }

        fetchAPI(fetchOptions);

        this.setState({
            cloneWithBuiltDataNewArch:false
        })
    }

    addNewRowTitleFormatter(cell, params) {
        let btn = getTableButton(
          "Add scenario",
          ["uk-text-normal"],
          ["uk-button-icon", "transparent-bg", "dark-bg"],
          [
            "far",
            "fa-plus-circle",
            "fa-lg",
            "add-manage-scenarios-plus-top",
            "uk-hidden",
            "uk-margin-small-right"
          ],
          "left",
          "Add new scenario"
        );

        btn.style.width = "max-content"
        btn.onclick = params.funkName;
        return btn;
      }

    getColumnFormatter(colField) {
        var columnFormatter;
        var obj = this;

        switch(colField) {
            case SCENARIO_FIELDS.NAME:
                columnFormatter = function(cell) {
                    var span = document.createElement("span");
                    var rowData = cell.getRow().getData();
                    var p = document.createElement("p");
                    p.textContent = cell.getValue();
                    span.appendChild(p);
                    if (rowData.unassigned_cost_status == BUILD_DETAILS.STATUS.VARIANCE){
                        var icon = document.createElement("i");
                        icon.classList.add("uk-margin-small-left", "uk-cursor-pointer", "fas", "fa-exclamation", "pi-text-yellow");
                        icon.setAttribute("uk-tooltip", BUILD_DETAILS.STATUS.VARIANCE);
                        icon.onclick = () => {
                            obj.fetchBuildDetails(rowData);
                        }
                        span.appendChild(icon);
                    }
                    
                    return span;
                };
                break;
            case SCENARIO_FIELDS.ICON:
            columnFormatter = function(cell, formatterParams) {
                var rowData = cell.getRow().getData();
                var dropdownButton = document.createElement("div");
                var dropdownContainer = document.createElement("div");
                dropdownButton.classList.add("uk-button-icon");
                dropdownContainer.setAttribute("uk-dropdown", "mode: click");

                var iconButtonContainer = document.createElement("div");
                var iconButton = getTableIconButton(["fa-info","fal","fa-lg"], ["uk-button-icon","transparent-bg"])
                iconButtonContainer.classList.add("uk-inline");

                var vectorDefinitionDiv = getTableButton(
                    "Vector Definitions",
                    [],
                    ["justify-content-start", "info-icons-dropdown"],
                    ["fal", "fa-external-link", "fs-14"],
                    "right",
                    "Vector Definitions"
                  );
                vectorDefinitionDiv.style.width = "100%";
                vectorDefinitionDiv.onclick = ((event) => redirectToVectorDefinitionsScreen(event, obj.formatScenarioObject(rowData)));

                var profitStackLineDefinitionDiv = getTableButton(
                    "Profit Stack Line Definitions",
                    [],
                    ["justify-content-start", "info-icons-dropdown"],
                    ["fal", "fa-external-link",  "fs-14"],
                    "right",
                    "Profit Stack Line Definitions"
                  );
                profitStackLineDefinitionDiv.style.width = "100%";
                profitStackLineDefinitionDiv.onclick = ((event) => redirectToPSLineDefinitionsScreen(event, obj.formatScenarioObject(rowData)));

                var modelCoherenceDiv = getTableButton(
                    "Model Coherence - Beta",
                    [],
                    ["justify-content-start", "info-icons-dropdown"],
                    ["fal", "fa-external-link",  "fs-14"],
                    "right",
                    "Model Coherence - Beta"
                );
                modelCoherenceDiv.style.width = "100%";
                modelCoherenceDiv.onclick = ((event) => redirectToModelCoherenceScreen(event, obj.formatScenarioObject(rowData)));

                if(obj.state.isListofVectorDefinitionsAllowed){
                    dropdownContainer.appendChild(vectorDefinitionDiv);
                }
                if(obj.state.isListofProfitStackLineDefinitionsAllowed){
                    dropdownContainer.appendChild(profitStackLineDefinitionDiv);
                }
                if(obj.state.isModelCoherenceAllowed){
                    dropdownContainer.appendChild(modelCoherenceDiv);
                }

                dropdownContainer.style.width = "11vw";
                iconButtonContainer.appendChild(iconButton);
                iconButtonContainer.appendChild(dropdownContainer);

                if((rowData[SCENARIO_FIELDS.STATUS] === SCENARIO_STATUS.REVIEW || rowData[SCENARIO_FIELDS.STATUS] === SCENARIO_STATUS.PUBLISHED) && (obj.state.isListofVectorDefinitionsAllowed ||obj.state.isListofProfitStackLineDefinitionsAllowed )) {
                    return iconButtonContainer;
                }
            };
            break;

			case SCENARIO_FIELDS.ACTIONS:
			    columnFormatter = function(cell, formatterParams) {

			    // actions three dotted dropdown button container
                var dropdownButton = document.createElement("div");
                var dropdownContainer = document.createElement("div");
                dropdownButton.classList.add("uk-button-icon");
                dropdownContainer.setAttribute("uk-dropdown", "mode: click");

                // three dotted button
                var dotsButtonContainer = document.createElement("div");
                var dotsButton = getTableIconButton(["fa-2x", "fal", "fa-ellipsis-v"], ["uk-button-icon"]);
                dotsButtonContainer.classList.add("uk-inline");
                dropdownContainer.style.width = "max-content";

                // publish scenario button
                var publishIconDiv = getTableButton(
                    MESSAGES.manage_scenario_publish,
                    [],
                    ["justify-content-start", "manage_stacks_dropdown_button"],
                    ["fal", "fa-share-square", "uk-margin-small-right", "uk-margin-small-left"],
                    "left",
                    MESSAGES.manage_scenario_publish_long
                );
                publishIconDiv.style.width = "100%"
                publishIconDiv.onclick = (e) => obj.confirmUpdateScenario(cell, e, SCENARIO_ACTION.PUBLISH);


                // checkout scenario button
                var checkoutIconDiv = getTableButton(
                    MESSAGES.manage_scenario_checkout,
                    [],
                    ["justify-content-start", "manage_stacks_dropdown_button"],
                    ["far", "fa-reply", "uk-margin-small-right", "uk-margin-small-left"],
                    "left",
                    MESSAGES.manage_scenario_checkout_long
                );
                checkoutIconDiv.style.width = "100%";
                checkoutIconDiv.onclick = (e) => obj.confirmUpdateScenario(cell, e, SCENARIO_ACTION.CHECKOUT);

                // send scenario for review button
                var reviewIconDiv = getTableButton(
                    MESSAGES.manage_scenario_review,
                    [],
                    ["justify-content-start", "manage_stacks_dropdown_button"],
                    ["fal", "fa-share", "uk-margin-small-right", "uk-margin-small-left"],
                    "left",
                    MESSAGES.manage_scenario_review_long
                );
                reviewIconDiv.style.width = "100%";
                reviewIconDiv.onclick = (e) => obj.confirmUpdateScenario(cell, e, SCENARIO_ACTION.REVIEW);

                // rename scenario button
                var renameIconDiv = getTableButton(
                    MESSAGES.manage_scenario_rename,
                    [],
                    ["justify-content-start", "manage_stacks_dropdown_button"],
                    ["fal","fa-pen", "uk-margin-small-right", "uk-margin-small-left"],
                    "left",
                    MESSAGES.manage_scenario_rename_long
                );
                renameIconDiv.style.width = "100%";
                renameIconDiv.onclick = (e) => obj.openNewScenarioModal(false, cell, SCENARIO_ACTION.RENAME);

                // clone scenario new arch button
                var cloneIconDivNA = getTableButton(
                    MESSAGES.manage_scenario_clone_new_arch,
                    [],
                    ["justify-content-start", "manage_stacks_dropdown_button"],
                    ["far","fa-clone", "uk-margin-small-right", "uk-margin-small-left"],
                    "left",
                    MESSAGES.manage_scenario_clone_new_arch
                );
                cloneIconDivNA.style.width = "100%";
                cloneIconDivNA.onclick = (e) => obj.openNewScenarioModal(false, cell, SCENARIO_ACTION.CLONE_NEW_ARCH);

                // clone scenario as budgeting button
                var cloneBudgetingIconDiv = getTableButton(
                    MESSAGES.manage_scenario_clone_as_budgeting,
                    [],
                    ["justify-content-start", "manage_stacks_dropdown_button"],
                    ["far", "fa-clone", "uk-margin-small-right", "uk-margin-small-left"],
                    "left",
                    MESSAGES.manage_scenario_clone_as_budgeting
                );
                cloneBudgetingIconDiv.style.width = "100%";
                cloneBudgetingIconDiv.onclick = (e) => obj.openNewScenarioModal(false, cell, SCENARIO_ACTION.CLONE_AS_BUDGETING);

                 // configure budgeting scenario
                var configureBudgetingIconDiv = getTableButton(
                    MESSAGES.manage_scenario_configure_budgeting,
                    [],
                    ["justify-content-start", "manage_stacks_dropdown_button"],
                    ["fal","fa-cog", "uk-margin-small-right", "uk-margin-small-left"],
                    "left",
                    MESSAGES.manage_scenario_configure_budgeting
                );
                configureBudgetingIconDiv.style.width = "100%";
                configureBudgetingIconDiv.onclick = (e) => obj.configureBudgetScenario(cell);

                // delete scenario button
                var deleteIconDiv = getTableButton(
                    MESSAGES.manage_scenario_delete,
                    [],
                    ["justify-content-start", "manage_stacks_dropdown_button"],
                    ["fal", "fa-trash-alt", "uk-margin-small-right", "uk-margin-small-left"],
                    "left",
                    MESSAGES.manage_scenario_delete_long
                );
                deleteIconDiv.style.width = "100%";
                deleteIconDiv.onclick = (e) => obj.confirmUpdateScenario(cell, e, SCENARIO_ACTION.DELETE, rowData[SCENARIO_FIELDS.SCENARIO_TYPE]);

                var rowData = cell.getRow().getData();
                if(rowData[SCENARIO_FIELDS.STATUS] === SCENARIO_STATUS.REVIEW) {
                    dropdownContainer.appendChild(publishIconDiv);
                }
                if (!obj.state.isPublishScenarioAllowed) {
                    publishIconDiv.classList.add("uk-hidden");
                }
                if(rowData[SCENARIO_FIELDS.STATUS] === SCENARIO_STATUS.REVIEW && (obj.state.isCheckoutScenarioAllowed || obj.props.user.first_name + " " +obj.props.user.last_name === rowData[SCENARIO_FIELDS.CREATED_BY])) {
                    dropdownContainer.appendChild(checkoutIconDiv);
                }
                if(rowData[SCENARIO_FIELDS.STATUS] === SCENARIO_STATUS.SANDBOX || rowData[SCENARIO_FIELDS.STATUS] === SCENARIO_STATUS.PUBLISHED) {
                    dropdownContainer.appendChild(reviewIconDiv);
                }
                dropdownContainer.appendChild(renameIconDiv);

                if(rowData[SCENARIO_FIELDS.SCENARIO_TYPE] !== SCENARIO_TYPE.BUDGETING){
                    dropdownContainer.appendChild(cloneIconDivNA);
                    dropdownContainer.appendChild(cloneBudgetingIconDiv);
                }
                if(rowData[SCENARIO_FIELDS.SCENARIO_TYPE] === SCENARIO_TYPE.BUDGETING){
                    dropdownContainer.appendChild(configureBudgetingIconDiv);
                }
                var owner = rowData[SCENARIO_FIELDS.LAST_MODIFIED_BY] == obj.props.user.first_name + " " + obj.props.user.last_name;

                if (!obj.state.isUpdateScenarioAllowed && !owner) {
                    renameIconDiv.classList.add("uk-hidden");
                    deleteIconDiv.classList.add("uk-hidden");
                }

                if(owner && rowData[SCENARIO_FIELDS.STATUS] === SCENARIO_STATUS.SANDBOX){
                    cloneIconDivNA.classList.add("uk-hidden");
                }

                if(!obj.state.isBudgetingAllowed){
                    cloneBudgetingIconDiv.classList.add("uk-hidden");
                }

                if(!owner || (owner && rowData[SCENARIO_FIELDS.STATUS] === SCENARIO_STATUS.REVIEW)) {
                    reviewIconDiv.classList.add("uk-hidden");
                }

                if (!owner && rowData[SCENARIO_FIELDS.STATUS] === SCENARIO_STATUS.SANDBOX) {
                    reviewIconDiv.classList.add("uk-hidden");
                }

                if(!owner && rowData[SCENARIO_FIELDS.STATUS] === SCENARIO_STATUS.SANDBOX) {
                    renameIconDiv.classList.add("uk-hidden");
                }

                if(rowData[SCENARIO_FIELDS.STATUS] !== SCENARIO_STATUS.PUBLISHED) {
                    dropdownContainer.appendChild(deleteIconDiv);
                }

                dotsButtonContainer.appendChild(dotsButton);
                dotsButtonContainer.appendChild(dropdownContainer);
                return dotsButtonContainer;
			};
            break;

            case SCENARIO_FIELDS.CREATED_DATE:
                    columnFormatter = function(cell, formatterParams) {
                        return cell.getValue() ? cell.getValue().split("T")[0] : "";
                    };
            break;

            case SCENARIO_FIELDS.PROGRESS:
                    columnFormatter = function(cell, formatterParams) {

                        var div = document.createElement('div');
                        div.classList.add("progress", "uk-margin-remove", "width-90");
                        div.style.backgroundColor = "#E5E5E5";

                        var progressTooltip = document.createElement('div');
                        progressTooltip.setAttribute("uk-drop", "pos: bottom-center; animation: false");
                        progressTooltip.classList.add("toggleTooltipDiv");

                        var progressDiv = document.createElement("div");
                        progressDiv.classList.add("progress-bar", "progress-bar-success", "progress-bar-animated","uk-display-inline-block");
                        var progressTextDiv = document.createElement('div');
                        progressTextDiv.classList.add("width-90");
                        progressDiv.appendChild(progressTextDiv);
                        div.appendChild(progressDiv);
                        div.style.backgroundColor = "#E5E5E5";
                        var percent = Number(cell.getRow().getData()[SCENARIO_FIELDS.STAGED_PERIODS]) + Number(cell.getRow().getData()[SCENARIO_FIELDS.COMPUTED_QUARTERS]) + Number(cell.getRow().getData()[SCENARIO_FIELDS.ASSIGNED_COSTS_PERIODS]);
                        progressTextDiv.innerHTML = formatValHTML((percent/3), FormatTypes.PERCENTAGE);
                        progressDiv.style.width = parseFloat(percent/3) + "%";

                        var progressContainer = document.createElement("div");
                        progressContainer.classList.add("uk-inline");
                        progressContainer.appendChild(div);
                        progressContainer.appendChild(progressTooltip);

                        var rowData = cell.getRow().getData();
                        var periodsPerc = rowData[SCENARIO_FIELDS.STAGED_PERIODS];
                        var assignedPerc = rowData[SCENARIO_FIELDS.ASSIGNED_COSTS_PERIODS];
                        var computedPerc = rowData[SCENARIO_FIELDS.COMPUTED_QUARTERS];
                        var tooltipHTML = obj.getProgressTooltipHTML(periodsPerc, assignedPerc, computedPerc);
                        progressTooltip.innerHTML = tooltipHTML;

                        return progressContainer;
                    };
                break;

			default:
                columnFormatter = function(cell, formatterParams) {
                    return cell.getValue();
                }
			break;
		}

		return columnFormatter;
    }

    /**
     * Format the scenario object before redirecting to vector definitions/PSL definitions
     * @param {JSONObject} scenario
     * @returns formated scenario
     */
    formatScenarioObject = (scenario) => {
      var scenarioObj = {};
      scenarioObj.client_model_scenario_id = scenario.client_model_scenario_id;
      scenarioObj.label = scenario.scenario_number + "-" + scenario.scenario_status;
      scenarioObj.scenario_name = scenario.name;
      scenarioObj.scenario_number = scenario.scenario_number;
      scenarioObj.scenario_type = scenario.scenario_type;
      scenarioObj.value = scenario.scenario_id;

      return scenarioObj;
    }

    getProgressTooltipHTML(periodsPerc, assignedCostPerc, computedPerc) {
        return "<div class='d-inline-grid fs-12'>" +
            "<div class='uk-display-flex uk-flex-between'>" +
            "<span>Periods Staged</span>" +
            "<span>"+ formatValHTML(periodsPerc, FormatTypes.PERCENTAGE) +"</span>" +
            "</div>" +
            "<div class='uk-display-flex uk-flex-between'>" +
            "<span class='uk-margin-default-right'>Periods With Assigned Costs</span>" +
            "<span>"+ formatValHTML(assignedCostPerc, FormatTypes.PERCENTAGE) +"</span>" +
            "</div>" +
            "<div class='uk-display-flex uk-flex-between'>" +
            "<span>Computed Quarters</span>" +
            "<span>"+ formatValHTML(computedPerc, FormatTypes.PERCENTAGE) +"</span>" +
            "</div>" +
            "</div>"
    }

    inputOnclick(e){
        $("#input-required").removeClass("uk-form-danger");
        if (e) {
            var value = $(e.currentTarget).val();
            this.checkForExistingName(value);
        }
    }

    checkForExistingName(name) {
        if (name) {
            var message = "";
            var data = this.tabulator.getData();
            var found = data.filter(e=>e[SCENARIO_FIELDS.NAME].toLowerCase() === name.toLowerCase());
            if (found && found.length > 0) {
                message = MESSAGES.invalid_scenario_name.replace('{x}',found[0][SCENARIO_FIELDS.NAME]);
            }
            this.isNameValid = message;
            this.forceUpdate();
        }
    }

    setTableColumns() {
        const _this = this;
        var tableColumns = cleanUpTabulatorColumns(copyObjectValues(SCENARIO_COLUMNS), null, this.refreshFilterDivs, this.tabulator, {id: "scenariosTable"});
        tableColumns.find(e => e.field === SCENARIO_FIELDS.STATUS).headerFilterParams.values = ['SANDBOX','REVIEW','PUBLISHED'];

        tableColumns.forEach(col => {
            if (col.field ===  SCENARIO_FIELDS.ADD_ROW) {
                col.titleFormatter = _this.addNewRowTitleFormatter;
                col.titleFormatterParams = { funkName: ()=>_this.openNewScenarioModal(true, undefined, SCENARIO_ACTION.ADD), obj: this.state };
            }
            if ([SCENARIO_FIELDS.ASSIGNED_COSTS_PERIODS,SCENARIO_FIELDS.STAGED_PERIODS,SCENARIO_FIELDS.COMPUTED_QUARTERS].includes(col.field)) {
                col.titleFormatter = (cell) => _this.addTooltipTitleFormatter(cell);
            }
            col.formatter = _this.getColumnFormatter(col.field, col.formatter);
        });

        this.tabulator.setColumns(tableColumns);
    }


    componentDidMount() {
        let obj = this;
        var isUpdateScenarioAllowed = obj.props.userAllowedSections ? getSectionExists(obj.props.userAllowedSections, MENU_ITEM.FIELDS.UPDATE_SCENARIO) : false;
        var isPublishScenarioAllowed = obj.props.userAllowedSections ? getSectionExists(obj.props.userAllowedSections, MENU_ITEM.FIELDS.PUBLISH_SCENARIO) : false;
        var isCheckoutScenarioAllowed = obj.props.userAllowedSections ? getSectionExists(obj.props.userAllowedSections, MENU_ITEM.FIELDS.CHECK_OUT_SCENARIO) : false;
        var isListofVectorDefinitionsAllowed = obj.props.userAllowedSections ? getSectionExists(obj.props.userAllowedSections, MENU_ITEM.FIELDS.VECTOR_DEFINITIONS) : false;
        var isListofProfitStackLineDefinitionsAllowed = obj.props.userAllowedSections ? getSectionExists(obj.props.userAllowedSections, ALL_WIDGETS.FIELDS.PROFIT_STACK_LINE_DEFINITIONS) : false;
        var isModelCoherenceAllowed = obj.props.userAllowedSections ? getSectionExists(obj.props.userAllowedSections, ALL_WIDGETS.FIELDS.MODEL_COHERENCE) : false;
        var isBudgetingAllowed =  obj.props.userAllowedSections ? getSectionExists(obj.props.userAllowedSections, ALL_WIDGETS.BUDGETING) : false;
        this.setState({
            isUpdateScenarioAllowed: isUpdateScenarioAllowed,
            isPublishScenarioAllowed: isPublishScenarioAllowed,
            isCheckoutScenarioAllowed: isCheckoutScenarioAllowed,
            isListofVectorDefinitionsAllowed:isListofVectorDefinitionsAllowed,
            isListofProfitStackLineDefinitionsAllowed:isListofProfitStackLineDefinitionsAllowed,
            isModelCoherenceAllowed:isModelCoherenceAllowed,
            isBudgetingAllowed:isBudgetingAllowed
        });

        this.getScenariosData();
        let options = {
            layout:"fitColumns",      //fit columns to width of table
            responsiveLayout:true,  //hide columns that dont fit on the table
            tooltips:true,            //show tool tips on cells
            addRowPos:"top",          //when adding a new row, add it to the top of the table
            history:true,             //allow undo and redo actions on the table
            pagination:false, //"local",       //paginate the data
            movableColumns:false,     //allow column order to be changed
            selectable:false,
            movableRows:false,
            resizableColumns:false,
            autoResize:true,
            resizableRows:false,       //allow row order to be changed
            virtualDom:false,
            placeholder:MESSAGES.no_data_available,
            height:"100%", //($(window).height() - $("#To_Scroll_List").offset().top-20)+"px",
            width:"100%",
            renderComplete:this.onTabulatorRenderComplete,
        }
        this.tabulator = new TabulatorFull(this.refs.mainTable, options);
        this.tabulator.placeholder = getTablePlaceholder("Add Exclusions", "manage-scenarios-add-row")
        this.setTableColumns();
        this.tabulator.setSort(SCENARIO_FIELDS.NUMBER, "desc");

        $("#manage-scenarios-add-row").click(function(){
            $(".manage-scenarios-plus-top").removeClass("uk-hidden");
            obj.openNewScenarioModal(true, undefined, SCENARIO_ACTION.ADD);
        });
    }

    addTooltipTitleFormatter(cell){
        let div = document.createElement("div");

        let p = document.createElement("p");
        p.innerHTML = cell.getValue();
        // p.classList.add('width-25',"uk-margin-small-left");
        // $(p).css("overflow-wrap","break-word");
        let el = document.createElement("i");
        el.classList.add("far", "fa-info-circle", "uk-margin-xsmall-left", "uk-cursor-pointer");
        el.setAttribute("uk-tooltip", cell.getValue());

        p.appendChild(el);
        div.appendChild(p);

        return div;
    }

    getScenariosData() {
        let _this = this;
        let query = {
            action: "getScenariosData"
        }

        let onThenCallback = (data) => {
            _this.setState({
                scenariosData: data.scenarios,
                scenariosData_id: shortid.generate()
            },function(){
                $(".add-manage-scenarios-plus-top").removeClass("uk-hidden");
            });
        }

        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getScenariosData",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader] : true,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]:lang.observability.manage_scenarios.screen_name,
            [FETCHAPI_PARAMS.requestDescription]:lang.observability.manage_scenarios.requests_description.get_scenarios_data
        }

        fetchAPI(fetchOptions);
    }

    changeCheckboxesOnClone(option){
        let cloneWithBuiltDataNewArch = this.state.cloneWithBuiltDataNewArch;
        if(!option){
            if(!cloneWithBuiltDataNewArch){
                cloneWithBuiltDataNewArch = true;
            } else {
                cloneWithBuiltDataNewArch = false;
            }
            this.setState({
                cloneWithBuiltDataNewArch: cloneWithBuiltDataNewArch
            });
        }
    }

    fetchBuildDetails=(rowData)=> {
        let _this = this;
        var query = {
          action: "fetchBuildDetails",
          scenario_id: rowData.scenario_id
        };

        let onThenCallback = (data) => {
            if (data) {
                _this.openBuildDetails(rowData.scenario_number, data);
            }
        };
    
        let fetchOptions = {
          [FETCHAPI_PARAMS.funcName]: "fetchBuildDetails",
          [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
          [FETCHAPI_PARAMS.showLoader]: true,
          [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
          [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
          [FETCHAPI_PARAMS.query]: query,
          [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
          [FETCHAPI_PARAMS.screenName]:lang.observability.manage_scenarios.screen_name,
          [FETCHAPI_PARAMS.requestDescription]:lang.observability.manage_scenarios.requests_description.fetch_build_details
        };
        fetchAPI(fetchOptions);
    }

    openBuildDetails=(scenarioNumber, data)=>{
        let bWindows = window.open(window.location.origin + "/build_details");
        bWindows.buildDetailsData = data;
        bWindows.scenarioNumber = scenarioNumber;
    }

    uncheckBoxesOnCancel() {
        this.setState({
            cloneWithBuiltDataNewArch: false,
            openScenarioNameDialog: false
        })
    }

    componentDidUpdate(nextProps, prevState) {
        if(this.state.manageScenarioView === ALL_WIDGETS.FIELDS.MANAGE_SCENARIOS && prevState.scenariosData_id !== this.state.scenariosData_id) {
            this.tabulator.setData(this.state.scenariosData);
        }
        if (nextProps.userAllowedSections && !this.props.userAllowedSections || JSON.stringify(nextProps.userAllowedSections) !== JSON.stringify(this.props.userAllowedSections)) {
            let obj = this;

            var isUpdateScenarioAllowed = obj.props.userAllowedSections ? getSectionExists(obj.props.userAllowedSections, MENU_ITEM.FIELDS.UPDATE_SCENARIO) : false;
            var isPublishScenarioAllowed = obj.props.userAllowedSections ? getSectionExists(obj.props.userAllowedSections, MENU_ITEM.FIELDS.PUBLISH_SCENARIO) : false;
            var isCheckoutScenarioAllowed = obj.props.userAllowedSections ? getSectionExists(obj.props.userAllowedSections, MENU_ITEM.FIELDS.CHECK_OUT_SCENARIO) : false;
            var isListofVectorDefinitionsAllowed = obj.props.userAllowedSections ? getSectionExists(obj.props.userAllowedSections, MENU_ITEM.FIELDS.VECTOR_DEFINITIONS) : false;
            var isBudgetingAllowed =  obj.props.userAllowedSections ? getSectionExists(obj.props.userAllowedSections, ALL_WIDGETS.BUDGETING) : false;
            var isListofProfitStackLineDefinitionsAllowed = obj.props.userAllowedSections ? getSectionExists(obj.props.userAllowedSections, ALL_WIDGETS.FIELDS.PROFIT_STACK_LINE_DEFINITIONS) : false;
            var isModelCoherenceAllowed = obj.props.userAllowedSections ? getSectionExists(obj.props.userAllowedSections, ALL_WIDGETS.FIELDS.MODEL_COHERENCE) : false;
            this.setState({
                isUpdateScenarioAllowed: isUpdateScenarioAllowed,
                isPublishScenarioAllowed: isPublishScenarioAllowed,
                isCheckoutScenarioAllowed: isCheckoutScenarioAllowed,
                isListofVectorDefinitionsAllowed:isListofVectorDefinitionsAllowed,
                isListofProfitStackLineDefinitionsAllowed:isListofProfitStackLineDefinitionsAllowed,
                isModelCoherenceAllowed:isModelCoherenceAllowed,
                isBudgetingAllowed:isBudgetingAllowed
            },function(){
                this.tabulator.replaceData(this.tabulator.getData());
            });
        }

        if(this.props.isConfigureBudgetScreen !== nextProps.isConfigureBudgetScreen) {

        }
    }

    static getDerivedStateFromProps(nextProps, state) {
        if (state.userAllowedSections !== nextProps.userAllowedSections) {
            var isUpdateScenarioAllowed = nextProps.userAllowedSections? getSectionExists(nextProps.userAllowedSections, MENU_ITEM.FIELDS.UPDATE_SCENARIO) : false;
            var isPublishScenarioAllowed = nextProps.userAllowedSections ? getSectionExists(nextProps.userAllowedSections, MENU_ITEM.FIELDS.PUBLISH_SCENARIO) : false;
            var isCheckoutScenarioAllowed = nextProps.userAllowedSections ? getSectionExists(nextProps.userAllowedSections, MENU_ITEM.FIELDS.CHECK_OUT_SCENARIO) : false;
            var isListofVectorDefinitionsAllowed =  nextProps.userAllowedSections ? getSectionExists( nextProps.userAllowedSections, MENU_ITEM.FIELDS.VECTOR_DEFINITIONS) : false;
            var isListofProfitStackLineDefinitionsAllowed = nextProps.userAllowedSections ? getSectionExists(nextProps.userAllowedSections, ALL_WIDGETS.FIELDS.PROFIT_STACK_LINE_DEFINITIONS) : false;
            var isModelCoherenceAllowed = nextProps.userAllowedSections ? getSectionExists(nextProps.userAllowedSections, ALL_WIDGETS.FIELDS.isModelCoherenceAllowed) : false;

           return ({
                isUpdateScenarioAllowed: isUpdateScenarioAllowed,
                isPublishScenarioAllowed: isPublishScenarioAllowed,
                isCheckoutScenarioAllowed: isCheckoutScenarioAllowed,
                isListofVectorDefinitionsAllowed:isListofVectorDefinitionsAllowed,
                isListofProfitStackLineDefinitionsAllowed:isListofProfitStackLineDefinitionsAllowed,
                isModelCoherenceAllowed:isModelCoherenceAllowed
            },function(){
                this.tabulator.replaceData(this.tabulator.getData());
            });
        }
        return null;
    }

    onTabulatorRenderComplete(test) {
        if(!this.tabulator)
            return;
    }

    confirmDialogContent = () => {
        return <h4>{this.state.confirmMsg}</h4>
    }

    confirmDialogActions = () => {
        return (
            <>
                <Button
                    label={MESSAGES.modal.buttons.confirm}
                    variant={BUTTON_VARIANT.PRIMARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={this.state.confirmCallback}
                />
                <Button
                    label={MESSAGES.modal.buttons.cancel}
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={() => this.setConfirmDialogOpen(false, "")}
                />
            </>
        )
    }

    setConfirmDialogOpen = (isOpen, msg, callback=()=>{}) => {
        let _this = this;
        _this.setState({
            openConfirmDialog: isOpen,
            confirmMsg: msg,
            confirmCallback: callback
        })
    }

    scenarioNameDialogContent = () => {
        var title = "";
        var message = "";

        if (this.state.action === SCENARIO_ACTION.ADD) {
            title = "Add New Scenario";
            message = MESSAGES.manage_scenario_confirm_create_scenario;
        } else if (this.state.action === SCENARIO_ACTION.RENAME) {
            title = "Rename Scenario " + this.scenarioNumber +" - " + this.scenarioName;
        } else if (this.state.action === SCENARIO_ACTION.CLONE_NEW_ARCH) {
            title = "Clone Scenario";
            message = MESSAGES.confirm_scenario_clone.replace("{x}", this.scenarioNumber).replace('{y}', this.scenarioName);
        }  else if (this.state.action === SCENARIO_ACTION.CLONE_AS_BUDGETING) {
            title = "Clone as Budgeting";
            message = MESSAGES.confirm_scenario_clone.replace("{x}", this.scenarioNumber).replace('{y}', this.scenarioName);
        }
        return (
            <div className="uk-display-flex uk-flex-column">
            { this.userHasSandbox && this.state.action !== SCENARIO_ACTION.RENAME &&
                <div className="uk-display-flex uk-flex-middle">
                    <i className="fa-3x fal fa-exclamation-triangle uk-margin-default-right" />
                    <div className="uk-text-xmedium">{message}</div>
                </div>
            }
            <label className="uk-padding-large-top uk-padding-small-bottom">
                <span className="fs-14">Scenario Name<sup className="red">*</sup></span>
                <Input id="input-required" className="form-control width-200 uk-margin-xsmall-top uk-margin-default-bottom"
                    placeholder="Name your scenario"
                    onChange={(e)=>this.inputOnclick(e)}
                />
                {this.isNameValid !== "" &&
                <div>
                    <p id="calc-col-warn" className="red italic uk-margin-top uk-margin-large-right">{this.isNameValid ? this.isNameValid.split(". ")[0]+"." : ""}</p><br></br>
                    <p id="calc-col-warn" className="red italic uk-margin-top uk-margin-large-right">{this.isNameValid ?  this.isNameValid.split(". ")[1] : ""}</p>
                    </div>
                }

                {this.state.action === SCENARIO_ACTION.CLONE_NEW_ARCH &&
                    <div>
                        <div>
                            <CheckBox id="clone-with-built-data" className="uk-margin-xsmall-right" labelText={MESSAGES.clone_with_built_data}
                                checked={this.state.cloneWithBuiltDataNewArch} onChange={() => this.changeCheckboxesOnClone(undefined)}/>
                        </div>
                    </div>
                }
            </label>
        </div>
        )
    }
    scenarioNameDialogActions = () => {
        let _this = this;
        let functionToUse = () => {};
        let buttonTitle = "";

        if (this.state.action === SCENARIO_ACTION.ADD) {
            buttonTitle = "Create";
            functionToUse = function () {
                _this.createScenarioFromScratch();
            }
        } else if (this.state.action === SCENARIO_ACTION.RENAME) {
            buttonTitle = "Rename";
            functionToUse = function () {
                _this.renameScenario();
            }
        } else if (this.state.action === SCENARIO_ACTION.CLONE_NEW_ARCH) {
            buttonTitle = "Clone Scenario";
            functionToUse = function () {
                _this.cloneScenario(false);
            }
        }  else if (this.state.action === SCENARIO_ACTION.CLONE_AS_BUDGETING) {
            buttonTitle = "Clone as Budgeting";
            functionToUse = function () {
                _this.cloneScenario(true);
            }
        }

        return (
            <>
                <Button
                    label={buttonTitle}
                    variant={BUTTON_VARIANT.PRIMARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={functionToUse}
                />
              <Button
                    label={lang.modal.buttons.cancel}
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={this.uncheckBoxesOnCancel}
                />
            </>
        )
    }

    setscenarioNameDialogOpen = (isOpen) => {
        let _this = this;
        _this.setState({
            openScenarioNameDialog: isOpen
        })

    }

    setInfoDialogOpen = (isOpen, infoMsg) => {
      let _this = this;
      _this.setState({
        openInfoDialog: isOpen,
        infoMsg: infoMsg
      })
    }

    openInfoDialogActions = () => {
      return (
        <Button
          label={lang.modal.buttons.ok}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => this.setInfoDialogOpen(false, "")}
        />
      )
    }

    render() {
        var obj = this;
        let report = this.state.report;
        var title = "";

        if (this.state.action === SCENARIO_ACTION.ADD) {
            title = "Add New Scenario";
        } else if (this.state.action === SCENARIO_ACTION.RENAME) {
            title = "Rename Scenario " + this.scenarioNumber +" - " + this.scenarioName;
        } else if (this.state.action === SCENARIO_ACTION.CLONE_NEW_ARCH) {
            title = "Clone Scenario";
        }  else if (this.state.action === SCENARIO_ACTION.CLONE_AS_BUDGETING) {
            title = "Clone as Budgeting";
        }
        return(
            <React.Fragment>
                <div style={{height: "100%"}}>
                    <div style={{height: "100%"}} className={!this.props.isConfigureBudgetScreen? "uk-hidden":""}>
                        <ConfigureBudget ref={el => this.configureBudgetRef = el} budgetScenarioId={this.state.budgetScenarioId} budgetScenarioName={this.state.budgetScenarioName} allTypeColumns={this.props.allTypeColumns} allPeriods={this.props.allPeriods} periods={this.props.periods} allNumericTransactionColumns={this.props.allNumericTransactionColumns} allBudgetingColumns = {this.props.allBudgetingColumns} token={this.state.token}/>
                    </div>
                    <div style={{height: "100%"}} className={this.props.isConfigureBudgetScreen ? "uk-hidden":""}>
                        <div className="scenarios-table">
                            <div id = "scenariosTable" ref="mainTable"></div>
                        </div>
                        <div id="toastManageScenario" className="toast toast-success">
                            <div id="desc"><i aria-hidden="true"></i><span id="message"/></div>
                        </div>
                    </div>
                </div>
                <Modal
                    id={"manage-scenarios-confirm-dialog"}
                    openDialog={this.state.openConfirmDialog}
                    bodyContent={this.confirmDialogContent}
                    dialogActions={this.confirmDialogActions}
                    closeClick={() => this.setConfirmDialogOpen(false, "")}
                    size={DIALOG_SIZE.MEDIUM}
                />
                <Modal
                    id={"scenario-name-dialog"}
                    title={title}
                    openDialog={this.state.openScenarioNameDialog}
                    bodyContent={this.scenarioNameDialogContent}
                    dialogActions={this.scenarioNameDialogActions}
                    closeClick={() => this.setscenarioNameDialogOpen(false)}
                    size={DIALOG_SIZE.MEDIUM}
                />
                <Modal
                  id={"info-dialog"}
                  openDialog={this.state.openInfoDialog}
                  bodyContent={() => <h4>{this.state.infoMsg}</h4>}
                  dialogActions={this.openInfoDialogActions}
                  closeClick={() => this.setInfoDialogOpen(false)}
                  size={DIALOG_SIZE.MEDIUM}
                />
            </React.Fragment>
        );
    }
}

export default ManageScenarios;