import { formatValString } from "./format";
import { lang } from "../language/messages_en";
import { FormatTypes, VECTOR_ANALYSIS, FORMAT_SETTINGS } from "./constants";
import { vectorSpacing } from "./jqueries";
import { capitalizeFirstLetter } from "./utils";
import React from "react";
import { convertPxToViewport, convertViewportToPx } from "./formatting";

const AmCharts = require("@amcharts/amcharts3-react");
const $ = require('jquery');
const UIkit = require('uikit');
function setChartData(data, unit, bridgeLabel,callback,mixVector, unique_id, fromWidget) {
    var value = "";
    var dataProvider = appendDataObjects(data, unit, bridgeLabel, mixVector);

    let alphaSymbol = "α";
    let betaSymbol = "β";
    value = "[[balloonValue]]";
    
    var chart = AmCharts.makeChart( unique_id, {
        "type": "serial",
        "theme": "light",
        "fontSize": convertViewportToPx(12),
        "dataProvider": dataProvider,

        "balloon": {
            "maxWidth" : convertPxToViewport(220),
            "horizontalPadding" : convertViewportToPx(10),
        },

        "startDuration": 1,

        "graphs": [ {
            "balloonText": "<span style='color:[[color]]'>[[category]]</span><br><b>"+value+"</b>",
            "colorField": "color",
            "fillAlphas": 1,
            "labelText": value,
            "lineColor": "#BBBBBB",
            "openField": "open",
            "type": "column",
            "valueField": "close",
            "showBalloon": false,
        } ],

        "trendLines": [ {
            "dashLength": 0,
            "finalCategory": "Income B",
            "finalValue": 11.13,
            "initialCategory": "Income A",
            "initialValue": 11.13,
            "lineColor": "#888888"
        }, {
            "dashLength": 0,
            "finalCategory": "Expenses A",
            "finalValue": 15.81,
            "initialCategory": "Income B",
            "initialValue": 15.81,
            "lineColor": "#888888"
        }, {
            "dashLength": 0,
            "finalCategory": "Expenses B",
            "finalValue": 12.92,
            "initialCategory": "Expenses A",
            "initialValue": 12.92,
            "lineColor": "#888888"
        }, {
            "dashLength": 0,
            "finalCategory": "Revenue",
            "finalValue": 8.64,
            "initialCategory": "Expenses B",
            "initialValue": 8.64,
            "lineColor": "#888888"
        } ],
        
        "columnWidth": convertPxToViewport(0.8),
        "valueAxes": [ {
            "title": "Amount (" + window._format[FORMAT_SETTINGS.CURRENCY_SIGN] + ")", 
            minMaxMultiplier: 1.03, 
            "autoGridCount": window.innerHeight > 2000 ? false : true,
            "fontSize": convertViewportToPx(14),
            "titleFontSize": convertViewportToPx(14)
         },
        ],
        "categoryField": "name",
        "categoryAxis": {
            "gridCount":5,
            "gridPosition": "start",
            "axisAlpha": 0,
            "gridAlpha": 0.1,
            "gridThickness": 0,
            "autoWrap": true,
            "labelColorField": "labelColor",
            "labelFunction": function(valueText, serialDataItem, categoryAxis) {
                let [first, ...second] = valueText.split(" ")
                second = second.join(" ")

                if (first === "Comparison") {
                    valueText = alphaSymbol + " " + second;
                } else if (first === "Selection") {
                    valueText = betaSymbol + " " + second;
                }

                return valueText;
            },
            "fontSize": convertViewportToPx(10)
        },
        "export": {
            "enabled": true,
            "menu": [ {
                "class": "export-main",
                "menu": [ {
                    "label": "Print",
                    "format": "PRINT",
                }]
            }]
        },
       

    });

    $("#"+unique_id).prepend("<img src='/images/logo.png' alt='Profitisle' id='chartPiLogo'/>");

    addListeners(chart,callback,fromWidget);
}

/**
 * this function is to call the hover and handleover functions on componentdidmount and componentdidupdate
 * @param {*} chart 
 * @param {*} callback 
 */
function addListeners(chart,callback,fromWidget) {
    var categoryAxis = chart.categoryAxis;
    categoryAxis.addListener("rollOverItem", handleOver);
    categoryAxis.addListener("clickItem", callback);
    chart.addListener("clickGraphItem", callback);
    if(!fromWidget){
        chart.addListener("rollOverGraphItem", hover);
    }
}

/**
 * add listener to on hover over items in the chart axis
 * @param {*} event 
 */
function hover(event) {
    event.item.columnGraphics.setAttr('cursor','pointer')
}

/***
 * add listener to on handleOver over items in the chart axis
 */
function handleOver(event) {
    let data = event.chart.dataProvider.filter(e=>e.name === event.value).length > 0 ? event.chart.dataProvider.filter(e=>e.name === event.value)[0].description : "";
    if(typeof(data)!== 'string'){
        data=JSON.stringify(data);
    }
    if(data){
        event.target.setAttr("cursor", "pointer");
        event.target.setAttr("uk-tooltip", data);
    }else{
        event.target.setAttr("cursor", "default");
    }
    UIkit.tooltip(event.target.node).show(); 
}

function appendDataObjects(data, unit, bridgeLabel, mixVector) {
  
    var dataProvider = [];

    for(var element in data) {
        element = data[element];
        var formatType = FormatTypes.AMOUNT;

        if (unit === "%") {
            formatType = FormatTypes.PERCENTAGE;
        }
        var val = formatValString(Math.abs(element.value), formatType, true);

        var tempObj = {};
        if(element.name.toLowerCase().indexOf(VECTOR_ANALYSIS.TITLES.COMPARISON.toLowerCase()) === 0) {
            tempObj.name = VECTOR_ANALYSIS.TITLES.COMPARISON + " " + capitalizeFirstLetter(bridgeLabel);
        } else if(element.name.toLowerCase().indexOf(VECTOR_ANALYSIS.TITLES.SELECTION.toLowerCase()) === 0) {
            tempObj.name = VECTOR_ANALYSIS.TITLES.SELECTION + " " + capitalizeFirstLetter(bridgeLabel);
        } else {
            tempObj.name = element.name;
        }
        tempObj.balloonValue = element.value < 0 ? "-" + val : val;
        tempObj.open = element.openValue;
        tempObj.close = element.closeValue;
        tempObj.color = element.color;
        tempObj.labelColor = element.labelColor;
        if(element.description !== ""){
            tempObj.description = element.description
        }else{
            switch(element.name){
                case lang.bridge_report.title.price:{
                    tempObj.description = lang.bridge_report.description.price_desc;
                    break;
                }
                case lang.bridge_report.title.cogs:{
                    tempObj.description = lang.bridge_report.description.cogs_desc;
                    break;
                }
                case lang.bridge_report.title.volume:{
                    tempObj.description = lang.bridge_report.description.volume_desc;
                    break;
                }
                case lang.bridge_report.title.mix:{
                    tempObj.description = lang.bridge_report.description.mix_desc;
                    break;
                }
                case lang.bridge_report.title.returns:{
                    tempObj.description = lang.bridge_report.description.returns_desc;
                    break;
                }
                case lang.bridge_report.title.new_sales:{
                    tempObj.description = lang.bridge_report.description.new_sales_desc.replace("[mix vector]",vectorSpacing(mixVector));
                    break;
                }
                case lang.bridge_report.title.no_sales:{
                    tempObj.description = lang.bridge_report.description.no_sales_desc.replace("[mix vector]",vectorSpacing(mixVector));
                    break;
                }
                default:
                    break;
            }
        }
        dataProvider.push(tempObj);
    }

    return dataProvider;
}

// function appendDataObjectsNew(data, unit) {
//     var dataProvider = [];
//     let counter = 0;
//     let previousBarValue = 0;
//     let dataLength = Object.keys(data).length;
    
//     for(var barName in data) {
//         let barValue = data[barName];
//         var formatType = FormatTypes.AMOUNT;

//         var tempObj = {};
//         tempObj.name = barName;
//         if (unit === "%") {
//             formatType = FormatTypes.PERCENTAGE;
//         }
//         var val = formatValString(Math.abs(barValue), formatType, true);
//         tempObj.balloonValue = barValue < 0 ? "-" + val : val;

//         tempObj.open = counter === 0 ? 0 : previousBarValue;
//         tempObj.close = counter === dataLength ? 0 : previousBarValue + barValue;
//         tempObj.color = "#FF0505";
//         previousBarValue = tempObj.close;
//         counter++;

//         dataProvider.push(tempObj);
//     }

//     return dataProvider;
// }

export default setChartData;
