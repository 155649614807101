import React, { Component } from 'react';
import '../styles/dataModeling.css';
import {TabulatorFull} from "tabulator-tables"; //import {TabulatorFull} library
import { copyObjectValues, getTranslationFile, capitaliseFirstLetterAfterChar, deepCompareObjects } from '../class/utils.js';
import {
	DATATYPE_ENUM, STAGE_FIELDS, INFO_MESSAGE, STAGING_SECTIONS,
	SCENARIO_STATUS, COSTCENTER_STATE_STATUS, COSTCENTER_STATE_STATUS_PREDICTED, COSTCENTER_STATE_STATUS_NOT_DEFINED, ALL_WIDGETS
} from '../class/constants.js';
import { cleanUpTabulatorColumns } from '../class/common.js';
import { findIndexOfValue } from '../class/array';
import { convertPxToViewport } from '../class/formatting';

const $ = require('jquery');

const CHECK_TITLE = "";
const CHECK_FIELD = "checkbox";
const lang = getTranslationFile();

const checkboxCellObject = {
    title: CHECK_TITLE,
    field: CHECK_FIELD,
    visible: true,
    headerSort: false,
	width: '3%',
	formatter: 'checkbox'
}

class StageDataTable extends Component {
    constructor(props) {
		super(props);
		this.state = {
			tableColumns: [],
			tableData: [],
		};
		
		this.onTabulatorRenderComplete = this.onTabulatorRenderComplete.bind(this);
		this.addTooltipTitleFormatter = this.addTooltipTitleFormatter.bind(this);
	}

	getProgressDiv(percent) {
		var parentDiv = document.createElement('div');
		parentDiv.classList.add("uk-display-inline-flex");

		var div = document.createElement('div');
		div.classList.add("progress");
		div.classList.add("width-90");
		div.style.backgroundColor = "#E5E5E5";

		var progressDiv = document.createElement("div");
		progressDiv.classList.add("progress-bar", "progress-bar-success", "progress-bar-animated");
		div.appendChild(progressDiv);

		div.children[0].innerHTML = Math.round(percent) + "%";
		div.children[0].style.width = percent + "%";

		div.style.cursor = "pointer";

		var iconDiv = document.createElement("div");
		var infoIcon = document.createElement("i");
		infoIcon.classList.add("fal","fa-info-circle", "uk-margin-xsmall-left");
		infoIcon.style.cursor = "pointer";
		$(infoIcon).attr('uk-tooltip', INFO_MESSAGE);
		iconDiv.appendChild(infoIcon);

		parentDiv.appendChild(div);
		parentDiv.appendChild(iconDiv);

		return parentDiv;
	}

	getDetailDiv(table) {
		var div = document.createElement("div");
		div.classList.add("text-link");
		if (table === STAGING_SECTIONS.VECTOR_MAPPING+"_table" || STAGING_SECTIONS.PROFIT_STACK_MAPPING+"_table") {
			div.classList.add("disabled")	
		}
		div.innerHTML = "More";
		return div;
	}

	getInvoiceLineDetailDiv(cell) {
		var obj = this;

		var div = document.createElement("div");
		div.classList.add("text-link");
		div.innerHTML = "More";
		// if (!cell.getRow().getData()["validForInvoiceLineStaging"]) {
			div.classList.add("disabled")	
		// } else {
			// div.classList.remove("disabled")	
		// }

		// div.onclick = () => {
		// 	obj.props.setStagingReport(STAGING_SECTIONS.VIEW_MORE);
		// 	obj.props.getInvoiceLineDetail(cell.getRow().getData()[STAGE_FIELDS.TIME_PERIOD_FIELD]);
		// 	obj.props.setChosenPeriod({value:cell.getRow().getData()[STAGE_FIELDS.TIME_PERIOD_FIELD], label:cell.getRow().getData()[STAGE_FIELDS.TIME_PERIOD_FIELD]});
		// }
		return div;
	}

	getColumnFormatter(colField) {
		var obj = this;
		var columnFormatter;

		switch (colField) {
			case CHECK_FIELD:
				columnFormatter = function (cell, formatterParams) {
					var checkbox = document.createElement("INPUT");
					checkbox.type = 'checkbox';
					checkbox.name = 'chosenEntity';
					checkbox.classList.add('chosenEntity', 'uk-cursor-pointer');
					var rowData = cell.getRow().getData();
					rowData[CHECK_FIELD] = false;
					if (cell.getTable().element.id === STAGING_SECTIONS.PROFIT_STACK_MAPPING + "_table") {
						let isCCStateDefined =  [COSTCENTER_STATE_STATUS.DEFINED].includes(cell.getRow().getData()[STAGE_FIELDS.COST_CENTER_STATUS]);
						checkbox.disabled = !rowData["validForGLStaging"] || !rowData[STAGE_FIELDS.ALL_GL_MAPPED] || (!isCCStateDefined) || 
							(rowData && rowData[STAGE_FIELDS.METRIC_STATUS] && rowData[STAGE_FIELDS.METRIC_STATUS].toLowerCase() !== STAGE_FIELDS.STAGED && rowData["metricsusedcount"] > 0);
					} else if (cell.getTable().element.id === STAGING_SECTIONS.TRANSACTION_MAPPING + "_table") {
						checkbox.disabled = !rowData["validForInvoiceLineStaging"];
					} else if (cell.getTable().element.id === STAGING_SECTIONS.VECTOR_MAPPING + "_table") {
						checkbox.disabled = rowData[STAGE_FIELDS.STATUS_FIELD] !== undefined ? rowData[STAGE_FIELDS.STATUS_FIELD].toUpperCase() === "STAGED" ? false : true : false;
					} else {
						// var invoiceLineStaged = rowData[STAGE_FIELDS.STATUS_FIELD] !== undefined ? (rowData[STAGE_FIELDS.STATUS_FIELD].toUpperCase() === "STAGED") : false;
						checkbox.disabled = !rowData["validForMetricStaging"] ;
					};

					if (checkbox.disabled) {
						checkbox.title = lang.stage_tables_alerts.define_expected;
						if (STAGING_SECTIONS.VECTOR_MAPPING + "_table" === cell.getTable().element.id) {
							checkbox.title = lang.stage_tables_alerts.stage_invoiceline;
						} else if (STAGING_SECTIONS.METRICS_MAPPING + "_table" === cell.getTable().element.id) {
							checkbox.title = rowData["validForMetricStaging"] ? lang.stage_tables_alerts.stage_invoiceline : lang.stage_tables_alerts.define_expected;
						} else if (cell.getTable().element.id === STAGING_SECTIONS.PROFIT_STACK_MAPPING + "_table") {
							var missingGLFiles = !rowData["validForGLStaging"] ? 1 : 0;
							var missingTDFiles = !rowData["validForInvoiceLineStaging"] ? 1 : 0;
							if(missingGLFiles || missingTDFiles) {
								checkbox.title = lang.stage_tables_alerts.define_expected;
							} else if (!rowData[STAGE_FIELDS.ALL_GL_MAPPED]) {
								checkbox.title = lang.stage_tables_alerts.map_lines;
							} else if (rowData[STAGE_FIELDS.METRIC_STATUS].toLowerCase() !== STAGE_FIELDS.STAGED && rowData["metricsusedcount"] > 0) {
								checkbox.title = lang.not_staged_metric.replace('line','');
							}
						}
					}
						
					checkbox.onchange = function () {
						rowData[CHECK_FIELD] = !rowData[CHECK_FIELD];
						var enabled = cell.getTable().getData().filter(el => el[CHECK_FIELD] === true).length > 0 && obj.props.scenarioStatus === SCENARIO_STATUS.SANDBOX;
						if (enabled) {
							$(this).closest(".stage-table").find("#stage_button").removeClass("uk-button-disabled");
							$(this).closest(".stage-table").find("#stage_new_button").removeClass("uk-button-disabled");
							$(this).closest(".stage-table").find("#stage_button_new_arch3").removeClass("uk-button-disabled");
						} else {
							$(this).closest(".stage-table").find("#stage_button").addClass("uk-button-disabled");
							$(this).closest(".stage-table").find("#stage_new_button").addClass("uk-button-disabled");
							$(this).closest(".stage-table").find("#stage_button_new_arch3").addClass("uk-button-disabled");
						}
					}
					return checkbox;
				};
				break;
			case STAGE_FIELDS.PROGRESS_FIELD:
				columnFormatter = function (cell) {
					if (cell.getTable().element.id === STAGING_SECTIONS.TRANSACTION_MAPPING + "_table") {
						return obj.getInvoiceLineDetailDiv(cell);
					} else {
						return obj.getDetailDiv(cell.getTable().element.id);

					}
				}
				break;
			case STAGE_FIELDS.COMPLETED_METRICS:
				columnFormatter = function (cell) {
					return obj.getDetailDiv();
				}
				break;

			case STAGE_FIELDS.VECTOR_STATUS:
				columnFormatter = function (cell) {
					var row = cell.getRow().getData();
					var div = document.createElement('div');
					var p = document.createElement('p');
					var icon = document.createElement("i");
					if (row[STAGE_FIELDS.VECTOR_STATUS]) {
						p.textContent = capitaliseFirstLetterAfterChar(row[STAGE_FIELDS.VECTOR_STATUS].toLowerCase().replace('_', ' '));
					}
					icon.classList.add("fas", "uk-margin-small-right");
					icon.title = "";
					if (row[STAGE_FIELDS.VECTOR_STATUS] && row[STAGE_FIELDS.VECTOR_STATUS].toLowerCase() === STAGE_FIELDS.STAGED) {
						icon.classList.add("fa-check");
					} else if (row["not_staged"] !== "-" && row["staged"] !== "-" &&
						Number(row["staged"]) > 0 && Number(row["not_staged"]) > 0) {
						icon.classList.add("fa-clock");
					} else {
						icon.classList.add("fa-times");
					}
					div.appendChild(icon);
					div.appendChild(p);
					return div;
				}
				break;
			case STAGE_FIELDS.METRIC_STATUS:
				columnFormatter = function (cell) {
					var row = cell.getRow().getData();
					var div = document.createElement('div');
					var p = document.createElement('p');
					var icon = document.createElement("i");
					p.textContent = capitaliseFirstLetterAfterChar(row[STAGE_FIELDS.METRIC_STATUS].toLowerCase().replace('_', ' '));
					icon.classList.add("fas", "uk-margin-small-right");
					icon.title = "";
					if (row[STAGE_FIELDS.METRIC_STATUS].toLowerCase() === STAGE_FIELDS.STAGED) {
						icon.classList.add("fa-check");
					} else {
						icon.classList.add("fa-times");
					}
					div.appendChild(icon);
					div.appendChild(p);
					return div;
				}
				break;
			case STAGE_FIELDS.STATUS_FIELD:
				columnFormatter = function (cell) {
					var row = cell.getRow().getData();
					var div = document.createElement('div');
					var p = document.createElement('p');
					var icon = document.createElement("i");
					p.textContent = capitaliseFirstLetterAfterChar(row[STAGE_FIELDS.STATUS_FIELD].toLowerCase().replace('_', ' '));
					icon.classList.add("fas", "uk-margin-small-right");
					icon.title = "";
					if (row[STAGE_FIELDS.STATUS_FIELD].toLowerCase() === STAGE_FIELDS.STAGED) {
						icon.classList.add("fa-check");
					} else {
						icon.classList.add("fa-times");
					}
					div.appendChild(icon);
					div.appendChild(p);
					return div;
				}
				break;
			case STAGE_FIELDS.PROFIT_STACK_STATUS_FIELD:
				columnFormatter = function (cell) {
					var row = cell.getRow().getData();
					var div = document.createElement('div');
					var p = document.createElement('p');
					var icon = document.createElement("i");
					p.textContent = capitaliseFirstLetterAfterChar(row[STAGE_FIELDS.PROFIT_STACK_STATUS_FIELD].toLowerCase().replace('_', ' '));
					icon.classList.add("fas", "uk-margin-small-right");
					icon.title = "";
					if (row[STAGE_FIELDS.PROFIT_STACK_STATUS_FIELD].toLowerCase() === STAGE_FIELDS.STAGED) {
						icon.classList.add("fa-check");
					} else {
						icon.classList.add("fa-times");
					}
					div.appendChild(icon);
					div.appendChild(p);
					return div;
				}
				break;

				case STAGE_FIELDS.STAGED: 
					columnFormatter = function (cell) {
						var div = document.createElement('div');
						div.textContent = cell.getValue();
						if (cell.getRow().getData()["staged_metrics"] && cell.getRow().getData()["staged"] && cell.getRow().getData()["not_staged"] !== '0') {
							div.setAttribute('uk-tooltip', cell.getRow().getData()["staged_metrics"].split(",").join(', </br>'))
						}
						return div;
					}
					break;

					case STAGE_FIELDS.NOT_STAGED: 
					columnFormatter = function (cell) {
						var div = document.createElement('div');
						div.textContent = cell.getValue();
						if (cell.getRow().getData()["not_staged_metrics"] && cell.getRow().getData()["not_staged"] && cell.getRow().getData()["not_staged"] !== '0') {
							div.setAttribute('uk-tooltip', cell.getRow().getData()["not_staged_metrics"].split(",").join(', </br>'))
						}
						return div;
					}
					break;

					case STAGE_FIELDS.INVALID: 
					columnFormatter = function (cell) {
						var div = document.createElement('div');
						div.textContent = cell.getValue();
						if (cell.getRow().getData()["invalid_metrics"] && cell.getRow().getData()["invalid"] && cell.getRow().getData()["invalid"] !== '0') {
							div.setAttribute('uk-tooltip', cell.getRow().getData()["invalid_metrics"].split(",").join(', </br>'))
						}
						return div;
					}
					break;

			default:
				break;
		}
		
		return columnFormatter;
	}

	    /* TODO(enhancement): replace document.onclick */
		createDocumentClick(rowPosition) {
			setTimeout(() => {
				document.onmouseover = () =>
				{
					if ($("#toggleTooltipCostCenter_" + rowPosition).css("display") !== "none") {
						$("#toggleTooltipCostCenter_"+rowPosition).css("display", "none");
					} else {
						$("#toggleTooltipCostCenter_"+rowPosition).css("display", "block");
					}
					setTimeout(() => {
						document.onmouseover = () => {}
					}, 100);
				}
			}, 100);
		}

	getDatatypeOptionsEnum() {
		return Object.freeze(DATATYPE_ENUM);
	}

	getTabulatorColumns(columns) {
		columns = columns || [];
		
		columns.forEach(col => {
			if ([STAGE_FIELDS.PROGRESS_FIELD, CHECK_FIELD].indexOf(col.formatter) !== -1 || [STAGE_FIELDS.METRIC_STATUS, STAGE_FIELDS.VECTOR_STATUS, STAGE_FIELDS.STATUS_FIELD, STAGE_FIELDS.PROFIT_STACK_STATUS_FIELD, STAGE_FIELDS.COST_CENTER_STATUS, STAGE_FIELDS.STAGED, STAGE_FIELDS.INVALID, STAGE_FIELDS.NOT_STAGED, CHECK_FIELD].indexOf(col.field) > -1) {
				col.formatter = this.getColumnFormatter(col.field);
			}
        });
        return columns;
	}

	shouldComponentUpdate(nextProps) {
		var shouldUpdate = false;
        shouldUpdate = (!!nextProps.tableData && !deepCompareObjects(nextProps.tableData, this.tabulator.getData())) || nextProps.costCenterStateData ? true : false;

        return shouldUpdate;
	}
	
	addTooltipTitleFormatter(cell, tooltipMessage){
        var div = document.createElement("div");

        var p = document.createElement("p");
        p.innerHTML = cell.getValue();

        var el = document.createElement("i");
        el.classList.add("fal", "fa-info-circle", "uk-margin-xsmall-left", "uk-cursor-pointer");
        el.setAttribute("uk-tooltip", tooltipMessage);

        p.appendChild(el);
        div.appendChild(p);

        return div;
	}  
	
	editTextAndAddTooltipTitleFormatter(cell, tooltipMessage, text) {
		var div = document.createElement("div");
		div.title = "";
		
        var p = document.createElement("p");
        p.innerHTML = cell.getValue().replace("CostCenter", text);

        var el = document.createElement("i");
        el.classList.add("fal", "fa-info-circle", "uk-margin-xsmall-left", "uk-cursor-pointer");
        el.setAttribute("uk-tooltip", tooltipMessage);

        p.appendChild(el);
        div.appendChild(p);

        return div;
	}
	
	
	componentDidUpdate() {
		var isDataDifferent = !deepCompareObjects(this.props.tableData, this.tabulator.getData());
		
		if(this.props.tableData && isDataDifferent) {
			this.tabulator.setData(this.props.tableData);
		}

		//to remove the on hover title for cost center status column
		$("[tabulator-field='cost_center_status']").each(function () {
			this.title = "";
		});
	}

	onTabulatorRenderComplete(test) {
		if(!this.tabulator)
			return;
	}

	setTabulatorColumns = ()=>{
		var obj = this;
		//setup tabulator
		var options = {
			layout: "fitColumns",      //fit columns to width of table
			responsiveLayout: false,  //hide columns that dont fit on the table
			// tooltips: true,            //show tool tips on cells
			addRowPos: "top",          //when adding a new row, add it to the top of the table
			history: true,             //allow undo and redo actions on the table
			pagination: false, //"local",       //paginate the data
			// paginationSize:1000,         //allow 7 rows per page of data
			movableColumns: false,     //allow column order to be changed
			autoResize: false,
			resizableRows: false,       //allow row order to be changed
			selectable: false,
			resizableColumns: true,
			// responsiveLayout:"collapse",
			// initialSort:initialSort,
			virtualDomBuffer: 800,
			placeholder: "",
			renderComplete:this.onTabulatorRenderComplete,
			invalidOptionWarnings: false,
			tooltips:function(column){
				return column._cell.value;
			}
		}
		this.tabulator = new TabulatorFull(this.refs.mainTable, options);
		//setting table columns
		var tableColumns = copyObjectValues(this.props.tableColumns);
		if(this.props.showCheckBox){
			tableColumns.unshift(checkboxCellObject);		//add checkbox column as the first column
		}
		let costCenterFieldIndex = findIndexOfValue(tableColumns, "field", STAGE_FIELDS.COST_CENTER_STATUS);
		if(costCenterFieldIndex > -1) {
			tableColumns.splice(costCenterFieldIndex, 1);
		}
		//set column formatters and return column objects
		tableColumns = cleanUpTabulatorColumns(tableColumns, null, this.refreshFilterDivs, this.tabulator, {id: this.props.id});
		//settings the columns again to replace the checkbox column
		tableColumns = this.getTabulatorColumns(tableColumns);

		tableColumns.forEach(col => {
			var columnField = col.field;
			var columnFieldMessage = "";
			if (columnField !== STAGE_FIELDS.PROGRESS_FIELD && columnField !== CHECK_FIELD && this.props.id === STAGING_SECTIONS.PROFIT_STACK_MAPPING+"_table") {
				columnFieldMessage = "profit_stack_" + columnField;
				if (columnField === STAGE_FIELDS.COST_CENTER_STATUS) {
					col.titleFormatter = (cell) => obj.editTextAndAddTooltipTitleFormatter(cell, lang[columnFieldMessage], obj.props.chosenCostCenter);
				} else {
					col.titleFormatter = (cell) => obj.addTooltipTitleFormatter(cell, lang[columnFieldMessage]);
				}
			} else if (columnField !== STAGE_FIELDS.PROGRESS_FIELD && columnField !== CHECK_FIELD && this.props.id === STAGING_SECTIONS.METRICS_MAPPING+"_table") {
				columnFieldMessage = "metrics_"+columnField;
                col.titleFormatter = (cell) => obj.addTooltipTitleFormatter(cell, lang[columnFieldMessage]);
			} else if (this.props.id === STAGING_SECTIONS.TRANSACTION_MAPPING+"_table" && columnField === STAGE_FIELDS.TIME_PERIOD_FIELD ) {
				col.titleFormatter = (cell) => obj.addTooltipTitleFormatter(cell, INFO_MESSAGE);
			} else if (this.props.id === STAGING_SECTIONS.VECTOR_MAPPING+"_table" && [STAGE_FIELDS.TIME_PERIOD_FIELD, STAGE_FIELDS.VECTOR_STATUS].includes(columnField) ) {
				col.titleFormatter = (cell) => obj.addTooltipTitleFormatter(cell, INFO_MESSAGE);
			}
		});
		
		this.tabulator.setColumns(tableColumns);
	}

	componentDidMount() {
		var obj = this;
		obj.setTabulatorColumns();
	}

	render() {
		
		return(
			<div id={this.props.id} ref="mainTable"/>
		);

	}

}

export default StageDataTable;