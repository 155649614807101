import { ClickAwayListener } from "@mui/material";
import { useRef, useState } from "react";
import { ReactComponent as ChevronRight } from "../../styles/images/chevron-right.svg";
import SearchableDropdown from "../SearchableDropdown";

const DrillOptionDropdown = ({ title, options, isSearchable = false, optionClick, allowSegmentationIcon, type }) => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef();

  const handleMouseEnter = () => {
    if(!open){
      setOpen(true);
    }
  };

    /**
   * Closes the dropdown when mouse leaves the entire dropdown area
   */
    const handleMouseLeave = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.relatedTarget)) {
        setOpen(false);
      }
    };

  const handleClick = () =>{
    setOpen((prev) => !prev);
  }

  const handleClickAway = () => {
    setOpen(false);
  };

  const onOptionClick = (option) => {
    if (typeof optionClick === "function"){
      optionClick(option, type);
    }
    handleClick();
  };

  const renderBody = () => {
    return (
      <div className="drill-option-dropdown-body">
        <SearchableDropdown
          id={"drill-option-dropdown-" + title}
          className="drill-option-dropdown-searchable-dropdown"
          isSearchable={isSearchable}
          removeClearBtn
          sectionsData={[{ itemsList: options }]}
          onOptionClick={onOptionClick}
          searchCriterias={["label"]}
          useSimpleList={true}
          allowSegmentationIcon = {allowSegmentationIcon}
        />
      </div>
    );
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div ref={dropdownRef} className="drill-option-container"
           onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <div className={"drill-option justify-content-between"} onClick={handleClick}>
          <span>{title}</span>
          <ChevronRight className="right-icon" />
        </div>
        {open ? renderBody() : null}
      </div>
    </ClickAwayListener>
  );
};

export default DrillOptionDropdown;