import React from 'react';
import { useEffect, useState } from "react";
import { BUTTON_TYPE, BUTTON_VARIANT, DROPDOWN_TYPE, HEADER_ELEMENT, SIZES } from "../class/constants";
import { findOptionByKey } from "../class/utils";
import Button from "../newComponents/Button";
import DropDown from "../newComponents/DropDown";
import { ReactComponent as EditIcon } from "../styles/images/icons/edit-square.svg";
import { lang } from "../language/messages_en";

/**
 * This component represents the scenario shown in the left hand menu.
 * It handles changing the scenario.
 * @param {*} isReadOnly if scenario can not be changed, the edit button will not be available (default value = false) 
 * @param {*} scenarioOptions scenario dropdown options
 * @param {*} defaultScenarioId default selected scenario ID
 * @returns 
 */
const ScenarioContainer = ({ isReadOnly = false, scenarioOptions, defaultScenarioId, saveScenario }) => {  
  const [isInEditMode, setInEditMode] = useState(false); // if isInEditMode = true, it means that the user is seing a dropdown to change the scenario
  const [selectedScenario, setselectedScenario] = useState(scenarioOptions?.find(f => f.value === defaultScenarioId));

  const saveSelectedScenario = () => {
    saveScenario(selectedScenario);
    setInEditMode(false)
  }

  useEffect(()=>{
    setselectedScenario(scenarioOptions?.find(f => f.value === defaultScenarioId));
  },[scenarioOptions, defaultScenarioId])

  
  return (
    <div className="scenario-div">
      <label className="scenario-label">{lang.scenario}</label>
      {isInEditMode && !isReadOnly ? (
        <>
        <div id = "scenario-select">
          <DropDown
              id="select-set"
              data-testid="select-set"
              className="scenario-dropdown input__dropdown"
              name={HEADER_ELEMENT.SCENARIO}
              value={findOptionByKey(scenarioOptions, selectedScenario?.value)}
              onChange={(val) => setselectedScenario(val)}
              options={scenarioOptions}
              type={DROPDOWN_TYPE.INPUT}
              firstAttr={"#scenario-select"}
            />
        </div>
          
          <Button
            data-testid="save-scenario"
            variant={BUTTON_VARIANT.SECONDARY}
            size={SIZES.ICON}
            type={BUTTON_TYPE.DEFAULT}
            onBtnClick={saveSelectedScenario}
            leftIcon={<i className="fal fa-save fa-lg"></i>}
          />
        </>
      ) : (
        <>
          <label data-testid="label-scenario" className="scenario-value"  value={selectedScenario?.label || "1"}>{selectedScenario?.label}</label>
            <Button
              data-testid="edit-scenario"
              variant={BUTTON_VARIANT.SECONDARY}
              size={SIZES.ICON}
              type={BUTTON_TYPE.DEFAULT}
              onBtnClick={() => setInEditMode(true)}
              leftIcon={<EditIcon />}
              style={{ opacity: isReadOnly ? 0 : 1, pointerEvents: isReadOnly ? "none" : "unset", display: "grid" }}
            />
        </>
      )}
    </div>
  );
};

export default ScenarioContainer;
