import React from 'react'
import NewButton from '../../sections/multipleDimensions/entityListDropDown/NewButton'
import './configure.css'
import Button from "../../newComponents/Button";
import { BUTTON_TYPE, BUTTON_VARIANT, SIZES } from '../../class/constants';


export default function Configure(props) {
  return (
    <div id={props.id} className={props.className} style={props.style}>
      {props.body}
      <Button 
        id={`${props.id}-button`}
        label={props.buttonText}
        variant={BUTTON_VARIANT.PRIMARY}
        size={SIZES.DEFAULT}
        type={BUTTON_TYPE.DEFAULT}
        className={props.classNameButtonText}
        onBtnClick={props.onButtonClick}
      />
    </div>
  )
}
