import {closestCorners, DndContext, PointerSensor, useSensor, useSensors} from "@dnd-kit/core";
import {arrayMove, SortableContext, verticalListSortingStrategy} from "@dnd-kit/sortable";
import React, {useEffect, useState} from "react";
import {DraggableOptionRow} from "./DraggableOptionRow";

const DraggableList = ({options, updateOptions}) => {
    const [internalOptions, setInternalOptions] = useState(options); // The component's internal state that holds the current order of options.

    /**
     * This useEffect hook ensures that the internalOptions state is updated whenever
     * the options prop changes.
     * This is useful for cases where the parent component updates the options list and expects
     * the DraggableList component to reflect these changes.
     */
    useEffect(() => {
        setInternalOptions(options);
    }, [options]);

    /**
     * A helper function that takes an id and returns the index of the option with that id in the internalOptions array.
     * This function is used to determine the current position of an item when handling drag-and-drop events.
     * @param {*} id
     * @returns the index of the option
     */
    const getOptionsPosition = (id) => internalOptions.findIndex((task) => task.id === id);

    /**
     * This function is called when a drag action ends.
     * If over is null (meaning the item was not dropped over another valid item) or if the id of active is the same as over (meaning the item was dropped back on itself),
     * the function returns early without making any changes.
     * Otherwise, the function calculates the original and new positions of the dragged item using getOptionsPosition.
     * The updated options are then set to the internal state (internalOptions).
     * If updateOptions is provided, it is called with the updated list to notify the parent component of the new order.
     * @param {*} event Contains information about the drag event, including active (the item being dragged) and over (the item being dragged over).
     * @returns
     */
    const handleDragEnd = (event) => {
        const {active, over} = event;
        if (!over || active.id === over.id) {
            return;
        }

        setInternalOptions((internalOptions) => {
            const originalPos = getOptionsPosition(active.id);
            const newPos = getOptionsPosition(over.id);

            //arrayMove: Moves the item from its original position to the new position.
            const updatedOptions = arrayMove(internalOptions, originalPos, newPos);
            updatedOptions.map((item, index) => {
                item.order = index + 1;
                return item;
            });
            // Call updateOptions if provided to notify parent of the new order
            if (updateOptions) {
                updateOptions(updatedOptions);
            }

            return updatedOptions;
        });
    };

    /**
     * useSensors: A hook used to define sensors for detecting drag-and-drop interactions.
     * useSensor: Configures a specific sensor, in this case, the PointerSensor, which detects pointer (mouse or touch) interactions.
     * activationConstraint: Configures how the sensor activates. Here, the sensor requires a pointer movement of at least 5 pixels
     before initiating a drag operation. This prevents accidental drags and ensures that the user intends to move an item.
     */
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 5,
            },
        })
    );

    return (
        <div>
            {/* DndContext Wraps the entire component tree that should be drag-and-drop enabled. It provides the necessary context for managing drag-and-drop state and behavior. */}
            <DndContext sensors={sensors} onDragEnd={handleDragEnd} collisionDetection={closestCorners}>
                {/* SortableContext Defines a sortable area and provides the necessary context to its children to make them sortable */}
                <SortableContext items={internalOptions} strategy={verticalListSortingStrategy}>
                    <DraggableOptionRow options={internalOptions}/>
                </SortableContext>
            </DndContext>
        </div>
    );
};

export default DraggableList;