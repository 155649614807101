import React, { Component } from "react";
import { DASHBOARDS, HEADER_ELEMENT, IDENTIFIERS, VECTOR_GROUPS, TOTAL_COMPANY, BUTTON_VARIANT, SIZES, BUTTON_TYPE, ALL_WIDGETS, MENU_ITEM } from "../../class/constants";
import { capitaliseFirstLetterAfterChar, getSectionExists, parseBoolean } from "../../class/utils";
import { getVectorAllowedAttributes } from '../manageAccess/ManageAccessUtils';
import Button from "../../newComponents/Button";

const Inflector = require('inflected');
/**
 * dashboards main screen
 * @author [Bassem Aranout]
 */

const BRIDGE = 'bridge';
class CategoryWidgets extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }

        this.renderWidgets = this.renderWidgets.bind(this);
        this.onAddWidget = this.onAddWidget.bind(this);
    }

    onAddWidget(e){
        this.props.onAddWidget(e);
    }

   renderWidgets(){
    let accessAllowed = true;
    let allowedBridgeReport = getVectorAllowedAttributes(TOTAL_COMPANY, VECTOR_GROUPS.OPERATIONS, this.props.user);
    if (!allowedBridgeReport.includes(IDENTIFIERS.NAME) && !allowedBridgeReport.includes(IDENTIFIERS.NUMBER)) {
        accessAllowed =  false;
    }
    let accessAllowedPS = getSectionExists(this.props.userAllowedMenuLinks, ALL_WIDGETS.FIELDS.STACKS_MOM) || getSectionExists(this.props.userAllowedMenuLinks, ALL_WIDGETS.FIELDS.STACKS_YTD) || getSectionExists(this.props.userAllowedMenuLinks, ALL_WIDGETS.FIELDS.STACKS_YOY);
    let accessAllowedErosion = getSectionExists(this.props.userAllowedMenuLinks,MENU_ITEM.FIELDS.EROSION_AND_GROWTH);
    let accessAllowedList = getSectionExists(this.props.userAllowedMenuLinks,MENU_ITEM.FIELDS.LIST);
    let accessAllowedBridgeReport = getSectionExists(this.props.userAllowedMenuLinks,MENU_ITEM.FIELDS.BRIDGE_REPORT);

    if(!this.props.widgets){
        return "";
    } else if(this.props.widgets.length === 0) {
        return "";
    } else {
        // let enabledWidgetTypes = [DASHBOARDS.WIDGET_TYPES.LIST, DASHBOARDS.WIDGET_TYPES.VECTOR_METRIC, DASHBOARDS.WIDGET_TYPES.PROFIT_STACK_LINE_METRIC, DASHBOARDS.WIDGET_TYPES.PROFIT_STACKS, DASHBOARDS.WIDGET_TYPES.OTHER_COLUMNS_METRIC, DASHBOARDS.WIDGET_TYPES.VECTOR_ANALYSIS, DASHBOARDS.WIDGET_TYPES.LINE_ATTRIBUTE];
       return (
           this.props.widgets[DASHBOARDS.CATEGORY.CHILDREN].map((widget,index) => {
                if (widget[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.BRIDGE_REPORT && !accessAllowed) {
                    return "";
                }
                if (widget[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.PROFIT_STACKS && !accessAllowedPS) {
                    return "";
                }
                if (widget[DASHBOARDS.WIDGET.WIDGET_TYPE] === MENU_ITEM.FIELDS.EROSION_AND_GROWTH && !accessAllowedErosion) {
                    return "";
                }
                if (widget[DASHBOARDS.WIDGET.WIDGET_TYPE] === MENU_ITEM.FIELDS.LIST && !accessAllowedList) {
                    return "";
                }
                if (widget[DASHBOARDS.WIDGET.WIDGET_TYPE] === BRIDGE && !accessAllowedBridgeReport) {
                    return "";
                }
               return(
                    <div key={index} className="uk-flex uk-flex-between uk-padding-xsmall-top-bottom pi-separator-bottom">
                        <div className="uk-flex">
                            <img className="" src={"/images/widgets/"+widget[DASHBOARDS.WIDGET.WIDGET_TYPE]+".svg"} alt={widget[DASHBOARDS.WIDGET.SECTION_NAME]}/>
                            {widget[DASHBOARDS.WIDGET.DESCRIPTION_ID] ?
                                <div className="fs-14 uk-margin-default-left">
                                    <div className="uk-text-bolder">{widget[DASHBOARDS.WIDGET.SECTION_NAME]}</div>
                                    <div>{widget[DASHBOARDS.WIDGET.DESCRIPTION_ID]}</div>
                                </div> 
                                :
                                <div className="fs-14 uk-margin-default-left">
                                    <div className="uk-text-bolder">{widget[DASHBOARDS.WIDGET.PARENT_SECTION_NAME]}</div>
                                    <div>{widget[DASHBOARDS.WIDGET.SECTION_NAME]}</div>
                                </div> 
                            }
                        </div>
                        <div className={parseBoolean(widget[DASHBOARDS.WIDGET.IS_ACTIVE]) ? "" :"disabled"}>
                        <Button 
                            id="stage_mapping_button" 
                            label={capitaliseFirstLetterAfterChar(HEADER_ELEMENT.ADD_WIDGET).replace(/_/g, " ")}
                            variant={BUTTON_VARIANT.SECONDARY}
                            size={SIZES.DEFAULT}
                            type={BUTTON_TYPE.DEFAULT}
                            key={HEADER_ELEMENT.CREATE_DASHBOARDS} 
                            widgetType={widget[DASHBOARDS.WIDGET.WIDGET_TYPE]}
                            category={Inflector.singularize(this.props.widgets[DASHBOARDS.CATEGORY.DISPLAY_NAME])} 
                            name={widget[DASHBOARDS.WIDGET.SECTION_NAME]}
                            machine_name={this.props.widgets[DASHBOARDS.CATEGORY.MACHINE_NAME]}
                            widget_category_section_id={widget[DASHBOARDS.WIDGET.SECTION_ID]}
                            leftIcon={<i className={"fal fa-plus fa-lg"} />}
                            onBtnClick={(e)=>{this.onAddWidget(e)}}
                        /> 
                        </div>
                    </div>
               )
           }))
        }
   }

    render() {
        var renderWidgets = this.renderWidgets();
        return (
            <div className="">
             {renderWidgets}
           </div>
        );
    }
}

export default CategoryWidgets;
