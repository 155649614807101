import { MANAGE_COLUMNS, PROFILE_COLUMN, PSS } from "./constants";
import {updateData} from "./utils";

function formatPslLines(pslLines) {
    let className = "heatmap-configure";
    return pslLines.map(psl => {
      className = "heatmap-configure ";
      if (psl?.children) {
        let pslObj = formatPslObject(psl, className);
        pslObj.children = formatPslLines(psl.children)
        return pslObj;
      } else {
        if (psl) {
          return formatPslObject(psl, className);
        }else{
          return  formatPslObject({}, className);
        }
      }
    })
  }

  function   formatAttributes(attributes){
    return attributes.map(attr => {
      return formatAttributeObject(attr);
    })
  }

  function formatPslObject(psl, className) {
    return { label: psl[PSS.NAME], value: psl[PSS.RETURN_NAME], path: psl.path, format: psl.format, nameInFact: psl.name_in_fact, costtype: psl.costtype, className: className }
  }

  function formatAttributeObject(psl) { 
    return { label: psl[PSS.NAME], value: psl[PSS.RETURN_NAME], nameInFact: psl.name_in_fact }
  }

function checkDefaultPsl(pslLines, firstPsl) {
  return pslLines.map(psl => {
    if (psl.value === firstPsl || !firstPsl) {
      updateData(pslLines, psl);
    }
    if (psl.children) {
      checkDefaultPsl(psl.children, firstPsl)
    }
    return psl;
  })
}

function fetchAndFormatFirstLevelPsl(data, pslObject) {
  let psLines = formatPslLines(data);
  pslObject.psLines = checkDefaultPsl(psLines, data[0].returnname || undefined);
}


export {formatPslLines, formatAttributeObject, formatPslObject, formatAttributes, checkDefaultPsl, fetchAndFormatFirstLevelPsl}
  