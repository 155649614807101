import React, { Component } from 'react';
import { CustomSelect } from '../../form/elements.js';
import { copyObjectValues, getTranslationFile, findOptionByKey, extractValueFromObjects } from '../../class/utils';
import { getObjectAsArray, debounce, alertAndLogError } from '../../class/jqueries';
import { UPLOAD_SECTIONS, Formats, API_URL, BUTTON_VARIANT, SIZES, BUTTON_TYPE, DROPDOWN_TYPE, DIALOG_SIZE } from "../../class/constants.js";
import Popup from 'react-popup';
import { moveIndex } from '../../class/array';

import { setLocalStorageValueByParameter } from '../../class/common.js';
import Input from '../../newComponents/Input.js';
import Button from '../../newComponents/Button.js';
import DropDown from '../../newComponents/DropDown.js';
import Modal from '../../newComponents/Modal.js';
import { FETCH_METHOD, fetchAPI, FETCHAPI_PARAMS } from '../../class/networkUtils.js';
const $ = require('jquery');
const lang = getTranslationFile();


const _ct = lang.ui_filter.dropdowns.functions.contains.value;
const _nct = lang.ui_filter.dropdowns.functions.not_contains.value;
const _eq = lang.ui_filter.dropdowns.functions.equals.value;
const _neq = lang.ui_filter.dropdowns.functions.not_equals.value;
const _gt = lang.ui_filter.dropdowns.functions.greater.value;
const _lt = lang.ui_filter.dropdowns.functions.less.value;
const _empty = lang.ui_filter.dropdowns.functions.empty.value;
const _nempty = lang.ui_filter.dropdowns.functions.not_empty.value;

const stringFunctionOptions = getObjectAsArray(lang.ui_filter.dropdowns.functions, Formats.String.toLowerCase(), "value_type");
const numericFunctionOptions = getObjectAsArray(lang.ui_filter.dropdowns.functions, Formats.Numeric.toLowerCase(), "value_type");

class EngineFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: [],
            filterRowRefs: [],
            // numberOfRows: this.props.filter ? JSON.parse(this.props.filter.replaceAll("'","\"")).filter.length : 1,
            numberOfRows: this.props.filter ? JSON.parse(this.props.filter).filter.length : 1,
        };
        this.displayFilterRows = this.displayFilterRows.bind(this);
        this.collectAndSaveFilter = this.collectAndSaveFilter.bind(this);
        this.resetModal = this.resetModal.bind(this);
        this.addNewFilterRow = this.addNewFilterRow.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.deleteFilterRow = this.deleteFilterRow.bind(this);
        this.getRowsFilter = this.getRowsFilter.bind(this);
        this.clear = this.clear.bind(this);
        this.setFilter = this.setFilter.bind(this);
        this.removeFilterRow = this.removeFilterRow.bind(this);
    }

    clear(){
        this.state.filterRowRefs.map(function(item, key) {
            item.current.clearValues();
            item.current.rowValue = {fields:'',operator:'',value:''};
        });
        this.forceUpdate();
    }

    displayFilterRows() {
        var temp = [];
        var obj = this;
        // var tempFilter = typeof(this.props.filter) === 'string' && this.props.filter.length ? JSON.parse(this.props.filter.replaceAll("'","\"")) : this.props.filter;
        var tempFilter = typeof(this.props.filter) === 'string' && this.props.filter.length ? JSON.parse(this.props.filter) : this.props.filter;
        tempFilter = tempFilter ? tempFilter.filter : "";
        if(!tempFilter || tempFilter.length === 0) {
            // tempFilter = typeof(this.state.filter) === 'string' ? JSON.parse(this.state.filter.replaceAll("'","\"")) : this.state.filter;
            tempFilter = typeof(this.state.filter) === 'string' ? JSON.parse(this.state.filter) : this.state.filter;
            tempFilter = tempFilter.filter;
        }
        var parsedFilter = tempFilter && tempFilter.length ? tempFilter : [];
        var rowsFilter = this.getRowsFilter();
        var displayedFilter = this._mounted ? rowsFilter : parsedFilter;//parsedFilter.length && parsedFilter.length >= rowsFilter.length ? parsedFilter : rowsFilter;
        var OPERATOR_OPTIONS = [];

        if(this.props.fields !== null) {
            // this.props.fields.map(function(item, key) {  //dynamically fill the refs object, and send the reference to the child

            if(displayedFilter && displayedFilter.length > this.state.numberOfRows) {
                this.state.numberOfRows = displayedFilter.length;
            }

            if(this.state.numberOfRows < 1) {
                this.state.numberOfRows = 1;
            }

            for (var key = 0; key < this.state.numberOfRows; key++) {
                let field = displayedFilter[key] ? displayedFilter[key].field : ""; 
                let operator = displayedFilter[key] ? displayedFilter[key].operator : ""; 
                let value = displayedFilter[key] ? displayedFilter[key].value : "";
            
                if (extractValueFromObjects(numericFunctionOptions, "value").includes(operator)) {
                    OPERATOR_OPTIONS = numericFunctionOptions;
                } else if (extractValueFromObjects(stringFunctionOptions, "value").includes(operator)) {
                    OPERATOR_OPTIONS = stringFunctionOptions;
                }
                if (field === "") {
                    var values = [];
                } else {
                    var values = typeof value === "object" ? value : [{label:value, value:value}];
                }

                var dateUpdated = Date.now();

                obj.state.filterRowRefs[key] = obj.state.filterRowRefs[key] || React.createRef();
                temp.push(<FilterRow fields={obj.props.fields} field={field} operators={OPERATOR_OPTIONS} operator={operator} value={value} 
                    saveFilter={obj.saveChildFilter} key={"key"+key} ref={obj.state.filterRowRefs[key]} rowNumber={key} isProfitStackMapping={this.props.isProfitStackMapping}
                    deleteFilterRow={obj.deleteFilterRow} dateUpdated={dateUpdated} user={this.props.user}  metric={this.props.metric}
                    tableName={this.state.tableName} values={values} timePeriod={this.props.timePeriod} configure={this.props.configure} scenarioId={this.props.scenarioId}/>);
            }
        }
        
        return temp;
    }

    removeFilterRow(filterRow) {
        var obj = this;
        this.state.filterRowRefs.forEach(function(item, key) {
            let tempRowNumber = Number(key);//.replace(FILTER_ROW_REF_PREFIX, ""));
            if (tempRowNumber === obj.state.filterRowRefs.length -1) {
                return;
            }
            if(filterRow.field_value.nodeValue === item.value && filterRow.field.nodeValue === item.field) {
               obj.state.filterRowRefs.splice(key, 1);
               obj.state.numberOfRows--;
            }
        });
        obj.forceUpdate();
    }

    deleteFilterRow(rowNumber) {
        var obj = this;
        this.state.filterRowRefs.forEach(function(item, key) {
            let tempRowNumber = Number(key);//.replace(FILTER_ROW_REF_PREFIX, ""));
            if (tempRowNumber === obj.state.filterRowRefs.length -1 && tempRowNumber === 0) {
                // delete obj.state.filterRowRefs[key];    //delete the last filter row
                // obj.state.numberOfRows--;       //reduce the number of filter rows by 1
                return;
            }

            // if(tempRowNumber === Number(rowNumber) || tempRowNumber > Number(rowNumber)) {
            //     obj.state.filterRowRefs[key] = obj.state.filterRowRefs[FILTER_ROW_REF_PREFIX + (tempRowNumber + 1)];
            // }
            if(tempRowNumber === Number(rowNumber)) {
               obj.state.filterRowRefs.splice(key, 1);
               obj.state.numberOfRows--;
            }
        });
        this.forceUpdate();
    }

    setFilter(filter) {
        this.setState({
            filter: filter
        })
    }

    getRowsFilter() {
        var obj = this;
        var rowsFilter = [];

        this.state.filterRowRefs.forEach(function(item, key) {
            let row = obj.state.filterRowRefs[key].current.rowValue;
            rowsFilter.push({field: row.field, operator: row.operator, value: row.value});
        });

        return rowsFilter;
    }

    resetModal() {
        var obj = this;
        // var parsedFilter = obj.props.filter && obj.props.filter.length ? JSON.parse(this.props.filter.replaceAll("'","\"")).filter : [];
        var parsedFilter = obj.props.filter && obj.props.filter.length ? JSON.parse(this.props.filter).filter : [];
        if (Array.isArray(parsedFilter)) {
            if(parsedFilter.length === 0) {
                this.state.filterRowRefs.forEach(function(item, key) {
                    obj.state.filterRowRefs[key].current.onResetState();
                });
            } else {
                this.state.filterRowRefs.map(function(item, key) {
                    //for each filter row, get the corresponding saved filter, if there is none, delete it
                    let rowValue = obj.state.filterRowRefs[key].current.rowValue;
                    let savedFilter = parsedFilter.filter(row => row.field === rowValue.field && row.operator === rowValue.operator && row.value === rowValue.value);
    
                    if(!savedFilter || savedFilter.length !== 0) {
                        //remove the unsaved row
                        delete obj.state.filterRowRefs[key];
                        obj.state.numberOfRows--;
                    } else {
                        item.current.rowValue = {field: parsedFilter[key].field, operator: parsedFilter[key].operator, value: parsedFilter[key].value}; 
                    }
                });
            }
        }

        obj.props.onCancelFilter();
    }

    /**
     * @param {*} filter 
    **/
    validateFilterRow(filter){
        if(!filter || !filter["field"] || !filter["operator"] || !filter["value"] || (filter["value"].length === 0 && [_empty, _nempty].indexOf(filter["operator"]) === -1)){
            return false;
        }

        return true;
    };

    collectAndSaveFilter() {
        var obj = this;
        var filterFinal = [];
        var validFilter = true;
        //for all the children added, request the data of each one and save it
        this.state.filterRowRefs.forEach(function (item, key) {
            if(obj.validateFilterRow(item.current.rowValue)) {
                //checking if value is not empty for all operators other than empty and not empty
                if ((obj.state.filterRowRefs[key].current.rowValue.value !== "" && [_empty, _nempty].indexOf(obj.state.filterRowRefs[key].current.rowValue.operator) === -1)
                    || [_empty, _nempty].indexOf(obj.state.filterRowRefs[key].current.rowValue.operator) > -1) {
                    filterFinal.push(obj.state.filterRowRefs[key].current.rowValue);
                }
            } else {
                validFilter = false;
                obj.setInfoDialogOpen(true, "Please enter a valid filter");
            }

        });

        if(validFilter) {
            this.setState({
                filter: filterFinal
            }, function() {
                $("#submit_GL #submit-btn").removeClass("disabled");
                this.props.setFilter(filterFinal, this.props.ancillary);
            });
        }
    }

    addNewFilterRow() {
        this.setState({
            numberOfRows: ++this.state.numberOfRows
        })
    }

    clearFilter() {
        var obj = this;
        // this.state.filterRowRefs.forEach(function(key) {
        //     delete obj.state.filterRowRefs[key];
        //     obj.state.numberOfRows--;
        // });
        this.state.filterRowRefs = [];
        this.state.numberOfRows = 1;
        this.collectAndSaveFilter();
    }

    componentDidMount() {
        this._mounted = true;
    }

    setInfoDialogOpen = (isOpen, infoMsg) => {
      let _this = this;
      _this.setState({
        openInfoDialog: isOpen,
        infoMsg: infoMsg
      })
    }
  
    openInfoDialogActions = () => {
      return (
        <Button
          label={lang.modal.buttons.ok}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => this.setInfoDialogOpen(false, "")}
        />
      )
    }

    render() {
        var filterRows = this.displayFilterRows();
        var buttonTitle = "Apply";
        return(
            <section className="modal fade" id={this.props.modalId ? this.props.modalId : "filterEngineModal"} role="dialog">
                <div className="modal-dialog modal-md custom-modal">
                    <div className="modal-content w50">
                        <div className="modal-body">
                        <Button 
                            variant={BUTTON_VARIANT.TERTIARY}
                            size={SIZES.ICON}
                            type={BUTTON_TYPE.DEFAULT}
                            className="close-button uk-margin-remove"
                            aria-label="Close"
                            data-dismiss="modal"
                            leftIcon={<i className="fal fa-times" />}
                            onBtnClick={()=>{this.resetModal()}}
                        />
                            <div>
                                <span className="uk-text-xxlarge">Filter</span><i className="far fa-info-circle uk-margin-xsmall-right uk-margin-xsmall-left" uk-tooltip={this.props.configure ? lang.configure_cost_function_filter_message : lang.profit_stack_map_filter_title} />
                            </div>
                            <div className="uk-button-icon uk-margin-small-top-bottom">
                                <i className="far fa-plus-circle fa-lg" title="Add new row" onClick={this.addNewFilterRow}/>
                                <span className="uk-margin-small-left" onClick={this.addNewFilterRow}>Add row</span>
                            </div>
                            <div>
                                {filterRows}
                            </div>
                            <div className="uk-float-right uk-margin-small-right">
                            </div>
                            <div className="uk-display-inline-flex justify-content-between w100 uk-margin-default-right-left uk-margin-top">
                                <div id="clearFilter" className="text-link" data-dismiss="modal" onClick={this.clearFilter}>Clear</div>
                                <div id="engine_filter_scenario" className="uk-margin-default-right-left">
                                    <Button 
                                        label={lang.modal.buttons.cancel}
                                        variant={BUTTON_VARIANT.SECONDARY}
                                        size={SIZES.DEFAULT}
                                        type={BUTTON_TYPE.DEFAULT}
                                        className="uk-margin-default-right"
                                        data-dismiss="modal"
                                        onBtnClick={this.resetModal}
                                    />
                                    <Button  
                                        id="saveFilter"
                                        label={buttonTitle}
                                        variant={BUTTON_VARIANT.PRIMARY}
                                        size={SIZES.DEFAULT}
                                        type={BUTTON_TYPE.DEFAULT}  
                                        data-dismiss="modal"
                                        onBtnClick={this.collectAndSaveFilter}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                  id={"info-dialog"}
                  openDialog={this.state.openInfoDialog}
                  bodyContent={() => <h4>{this.state.infoMsg}</h4>}
                  dialogActions={this.openInfoDialogActions}
                  closeClick={() => this.setInfoDialogOpen(false)}
                  size={DIALOG_SIZE.MEDIUM}
                />
            </section>
        );
    }
}

const OPERATOR_ATTR_NAME = "operator";
const FIELD_ATTR_NAME = "field";
const VALUE_ATTR_NAME = "value";

class FilterRow extends Component {
    constructor(props) {
        super(props)
        this.state = {
            rowNumber: this.props.rowNumber,
            values: this.props.values || []
        };
        
        this.setValue = this.setValue.bind(this);
        this.onResetState = this.onResetState.bind(this);
        this.appendValue = this.appendValue.bind(this);
        this.getKeyValues = this.getKeyValues.bind(this);
        this.getAncillaryValues = this.getAncillaryValues.bind(this);
        this.toggleValuesEnablement = this.toggleValuesEnablement.bind(this);
        this.clearValues = this.clearValues.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        
        this.rowValue = {
            field: this.props.field,
            operator: this.props.operator,
            value: this.props.value
        }

        this.operators = this.props.operators && this.props.operators.length > 0 ? this.props.operators : [];

        if (this.operators === "" || this.operators.length === 0) {
            if (extractValueFromObjects(stringFunctionOptions, "value").includes(this.props.operator)) {
                this.operators = stringFunctionOptions;
                $('').val(this.props.value);
            } else if (extractValueFromObjects(numericFunctionOptions, "value").includes(this.props.operator)) {
                this.operators = numericFunctionOptions;
                $('').val(this.props.value);
            }
        }

        this.dateUpdated = Date.now();
    }

    clearValues(){
        this.setState({
            values: []
        })
    }

    componentDidMount() {
        this.fetchFilterEntities(null, this.rowValue);
    }

    fetchFilterEntities(e, tempRowVal, inputVal) {
        var _this = this;
        this.clearValues(); //empty the values before fetching again
        var value = e !== null && e.value ? e.value : tempRowVal.field;
        var operator = tempRowVal.operator ? tempRowVal.operator : "";
        var type = e !== null && e.type ? e.type : [_lt, _gt].includes(operator.toLowerCase()) ? Formats.Numeric.toLowerCase() : Formats.String.toLowerCase();

        this.isLoading = true;
        if (this.props.configure && type.toLowerCase() !== Formats.Numeric.toLowerCase()) {
            if (operator !== "" && tempRowVal !== undefined) {
                this.getKeyValues(value, inputVal);
            }
        } else if (this.props.isProfitStackMapping || this.props.isCalculatedCols) {
            _this.getAttributeValues(value, type, inputVal);
        } else if (!this.props.configure){
            this.getAncillaryValues(value, this.props.metric);
        }
    }

    setValue(attrName, e, isMulti) {
        var tooltip = this.state.tooltip;
        var tempRowVal = copyObjectValues(this.rowValue);
        this.values = [];
        if (e === null && attrName === "value") {
            tempRowVal.value = "";
        }
        if(e && e.currentTarget && e.currentTarget.name === "value") {
            //the user just changed the input field
            tempRowVal.value = e.value;   //save the value
        } else if (e !== null) {
            if (attrName === OPERATOR_ATTR_NAME) {
                tempRowVal.value = []; //to clear the values after choosing operator
                tempRowVal.operator = e.value;     //save the operator
                this.fetchFilterEntities(tempRowVal.field, tempRowVal); //sending field, row and operator
                
                if (e.value !== "In" && e.value !== "Not In") {
                    tooltip = lang.engine_filter_set_value_error;
                } else {
                    tooltip = lang.engine_filter_set_value_condition;
                }
            } else if(attrName === FIELD_ATTR_NAME) {
                tempRowVal.field = e.value;
                tempRowVal.operator = "";
                tempRowVal.value = [];

                if ([Formats.String.toLowerCase(), Formats.Boolean.toLowerCase()].indexOf(e.type.toLowerCase()) > -1) {
                    this.operators = stringFunctionOptions;
                } else if ([Formats.Numeric.toLowerCase(), Formats.DateTime.toLowerCase(), Formats.Timestamp.toLowerCase()].indexOf(e.type.toLowerCase()) > -1) {
                    this.operators = numericFunctionOptions;
                }

                this.fetchFilterEntities(e, tempRowVal); //sending field and row
                
            } else if(attrName === VALUE_ATTR_NAME) {
                var values = [];
                var arrayOfValues = [];
                e.map(labelValObj =>{
                    if(!labelValObj.value && !labelValObj.label){
                        labelValObj.value = "";
                        labelValObj.label = "";
                    }
                });
                e.map(labelValObj=>{
                    if(labelValObj.value.includes(",")) {
                        let tempValues = labelValObj.value.split(",");
                        tempValues.map(stringVal=>{
                            arrayOfValues.push({label: stringVal.trim(), value: stringVal.trim(), new:labelValObj.new});
                        });
                    } else {
                        arrayOfValues.push(labelValObj);
                    }
                });
                tempRowVal.value = arrayOfValues;   //save the value
                
                if (!isMulti) {
                    this.state.values = [this.state.values[0]];
                } else {
                    this.state.values.map(function(item, key){
                        values.push(item);
                    });
    
                    values = this.toggleValuesEnablement(values);
                }
            }
        }
        
        this.isLoading = true;
        this.setState({
            tooltip: tooltip,
        });

        this.rowValue = tempRowVal;
        this.dateUpdated = Date.now();

        if(this.props.setRowFormula) {
            this.props.setRowFormula();
        }
    }

    getKeyValues(key, inputVal) {
        var fieldObj =  this.props.fields.filter(function(e){ return e.value === key});
        var value = ["Name", "Number"].indexOf(key) !== -1 || fieldObj.length !== 0 ? fieldObj[0]['type'] === "numeric" ? key : key + "Key" :"";
        var dataBody = { 
            action: "getKeyValues",
            scenario_id: this.props.scenarioId,
            attribute: value,
            timePeriod: this.props.timePeriod.value,
            input_value: inputVal
        };
        const baseUrl = process.env.REACT_APP_BASE_URL;
        const path = API_URL.DATA_MODELING;
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      fetch(`${baseUrl}${path}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(dataBody)})
            .then((response)=>{    
                if(response.status === 403) {
                }
                return response.json()})
            .then((data)=>{
                if(data.data !== null) {
                    var values = [];
                    data.data.map(function(item){
                        values.push({label:item.attribute, value:item.attribute})
                    });
                    values = this.toggleValuesEnablement(values, inputVal);
                    this.setState({
                        values: values 
                    }, function () {
                        this.isLoading = false;
                    });
                }

            }).catch((error)=>{
                alertAndLogError(error);
                this.hideLoaderMsg();
            });
    }

    getAttributeValues(attrName, fieldDataType, inputVal) {
        if(!attrName) {
            return;
        }
        var obj = this;
        var dataBody = { 
            action: "getAttributeValues",
            scenario_id: this.props.scenarioId,
            attribute: attrName,
            fileType: this.props.isCalculatedCols ? UPLOAD_SECTIONS.FIELDS.TRANSACTION_DATA : UPLOAD_SECTIONS.FIELDS.GENERAL_LEDGER,
            isDefaultField: this.props.isCalculatedCols ? false : true,
            field_dataType: fieldDataType,
            input_value: inputVal,
        };

        let onThenCallback = (data) => {
            if(data.data !== null){
                var values=[];
                data.data.map(function(item, index){
                    if(obj.props.isCalculatedCols && index > 50) {
                        return false;
                    }
                    values.push({label:item.attribute, value:item.attribute})
                });
                values = this.toggleValuesEnablement(values, inputVal);
                
                obj.setState({
                    values: values 
                }, function () {
                    obj.isLoading = false;
                });
            }
        }

        let onErrorCallback = () => {
            this.hideLoaderMsg();
        }

        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getAttributeValues",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: dataBody,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.onErrorCallback]: onErrorCallback,
            [FETCHAPI_PARAMS.screenName]:lang.observability.stage.exclusions_mapping.screen_name,
            [FETCHAPI_PARAMS.requestDescription]:lang.observability.stage.exclusions_mapping.requests_description.get_attribute_values
        }
        
        fetchAPI(fetchOptions);
    }

    getAncillaryValues(key, metric, inputVal) {
        var dataBody = { 
            action: "getAncillaryValues",
            scenario_id: this.props.scenarioId,
            attribute: key,
            metric: metric,
            timePeriod: this.props.timePeriod,
            fields: JSON.stringify(this.props.fields),
            input_value: inputVal
        };
        const baseUrl = process.env.REACT_APP_BASE_URL;
        const path = API_URL.DATA_MODELING;
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      fetch(`${baseUrl}${path}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(dataBody)})
            .then((response)=>{    
                if(response.status === 403) {
                }
                return response.json()})
            .then((data)=>{
                if(data.data !== null){
                    var values=[];
                    data.data.map(function(item){
                        values.push({label:item.attribute, value:item.attribute})
                    });
                    values = this.toggleValuesEnablement(values, inputVal);
                    this.setState({
                        values: values
                    }, function () {
                        this.isLoading = false;
                    });
                    
                } else if (data.ERROR) {
                    this.clearValues() 
                }

            }).catch((error)=>{
                alertAndLogError(error);
                this.hideLoaderMsg();
            });
    }

    componentDidUpdate() {
        let readFromParent = this.props.dateUpdated > this.dateUpdated;
        let field = readFromParent ? this.props.field : this.rowValue.field;
        let operator = readFromParent ? this.props.operator : this.rowValue.operator;
        let value = readFromParent ? this.props.value : this.rowValue.value;
        
        this.rowValue = {
            field: field,
            operator: operator,
            value: value
        }
    }

    onResetState(deleteRow) {
        this.rowValue = { field: '', value: '', operator: ''};

        if(deleteRow) {
            this.props.deleteFilterRow(this.state.rowNumber);
        }
    }

    updateInput(e) {
        this.rowValue.value = $(e.currentTarget).val(); 
        this.forceUpdate();
    }

    toggleEntitiesMenu(toggle) {
        if(toggle === undefined) {
            toggle = !this.state.menuIsOpen;    //if toggle not sent, revert menuIsOpen
        }

        if(toggle !== this.state.menuIsOpen) {  //if true while already opened, or false while already closed, do not rerender
            this.setState({
                menuIsOpen: toggle
            });
        }
    }

    onInputChange(inputVal, params) {
        var _this = this;
        this.isLoading = true;

        clearTimeout(this.entitiesTimeout);
        this.entitiesTimeout = setTimeout(function () {
            _this.fetchFilterEntities(null, _this.rowValue, inputVal);
        }, 300);
    }

    hideLoaderMsg() {
        this.isLoading = false;
        this.setState(this.state);
    }

    appendValue(inputVal, params) {
        var comp = this;
        var values = [];
        let originalValues = copyObjectValues(this.state.values);
        originalValues.map(function(item, key){
            values.push(item.value);
        });

        if(params.action === "input-change") {
            debounce(function () {
                if ([_ct, _nct].indexOf(comp.rowValue.operator) > -1) {
                    comp.state.menuIsOpen = true;
                }
            }, 300);
        } else if(params.action === "set-value") {
            originalValues = [];
            this.state.values.forEach((item, key)=>{
                if(!item.new) {
                    item.isDisabled = [_ct, _nct].includes(this.rowValue.operator);     //disabled true if operator is contains or not contains
                    originalValues.push(item);
                }
            });

        } else if (params.action === "menu-close") {
            this.toggleEntitiesMenu(false);
        }

        if (inputVal !== ""){
            if(!values.includes(inputVal) && [_ct, _nct].includes(this.rowValue.operator)) {
                if (this.state.values[0] && this.state.values[0].new === true) {
                    originalValues.shift(); //deleting the old value inserted so that not every input inserted is added as a separate value
                }
                originalValues.unshift({label:inputVal, value:inputVal, new:true, isDisabled: false}); //add the value typed by the user
            } else if(values.includes(inputVal)){
                originalValues = moveIndex(originalValues, values.indexOf(inputVal), 0);
                originalValues[0].isDisabled = false;
            }
        }

        this.isLoading = false;
        this.setState({
            values: originalValues
        });
    }

    toggleValuesEnablement(values, inputVal) {
        var returnValues = false;
        if(values) {
            returnValues = true;
        } else {
            values = this.state.values;
        }

        if ([_ct, _nct,_eq,_neq].indexOf(this.rowValue.operator) > -1) {
           
            if([_ct, _nct].indexOf(this.rowValue.operator) > -1){
                values.map(item => {
                    item.isDisabled = true;
                });
            }
            else {
                values.map(item => {
                    item.isDisabled = false;
                });
            }
            
            if(inputVal) {
                values.unshift({value: inputVal, label: inputVal, isDisabled: false});
            }
        } 

        if (returnValues) {
            return values;
        } else {
            this.setState(this.state, function () {
                this.isLoading = false;
            });
        }
    }

    render() {
        var comp = this;
        var valueSelectOptions =  this.state.values && this.state.values.length > 0 ? this.state.values : this.props.values && this.props.values.length > 0 ? this.props.values : [];
        return (
            <div className="uk-display-flex justify-content-between align-items-center uk-margin-small-top-bottom">
                <DropDown
                    name="field"
                    placeholder="Field"
                    onChange={(e)=>this.setValue(FIELD_ATTR_NAME, e, false)}
                    options={this.props.fields && this.props.fields.length > 0 ? this.props.fields : []}
                    className="uk-display-inline-block w30 input__dropdown"
                    value={findOptionByKey(this.props.fields, this.rowValue.field)}
                    type = {DROPDOWN_TYPE.INPUT}
                />
                <DropDown
                    name="operator"
                    placeholder="Operator"
                    onChange={(e)=>this.setValue(OPERATOR_ATTR_NAME, e, false)}
                    options={this.operators}
                    className="uk-display-inline-block w30 input__dropdown"
                    value={findOptionByKey(this.operators, this.rowValue.operator)}
                    type = {DROPDOWN_TYPE.INPUT}

                />
                {[_gt, _lt].includes(this.rowValue.operator) && this.rowValue.value.length !== 0 ?
                    <Input type="text" className="form-control"  style={{width:"172px"}} onChange={(e) => this.updateInput(e)} value={this.rowValue.value[0].value}/>
                :                
                    <DropDown
                        name="value"
                        placeholder="Value"
                        onChange={(e) => this.setValue(VALUE_ATTR_NAME, e, true)}
                        options={valueSelectOptions}
                        className="uk-display-inline-block w30 input__dropdown"
                        value={this.rowValue.value}
                        onMenuOpen={() => {
                            if(this.rowValue.value.length || (!this.rowValue.value.length && !valueSelectOptions.length)) {
                                this.fetchFilterEntities(null, this.rowValue);
                            }
                        }}
                        onInputChange={(inputVal, params) => this.onInputChange(inputVal, params)}
                        isMulti
                        isSearchable
                        countOptions={3}
                        closeMenuOnSelect={[_ct, _nct].indexOf(comp.rowValue.operator) > -1 ? true : false}
                        tabSelectsValue={true}
                        disabled={[_empty, _nempty].indexOf(this.rowValue.operator) > -1 ? true : false}
                        isLoading={this.isLoading}
                        loadingMessage={()=>{ return lang.filter_text.fetching_results;}}
                        noOptionsMessage={()=>{ return this.isLoading ? lang.filter_text.fetching_results : lang.filter_text.no_results_found;}}
                        type = {DROPDOWN_TYPE.INPUT}

                    />
                }
                <div className="uk-button-icon transparent-bg" onClick={()=>this.onResetState(true)}>
                    <i className="fal fa-trash-alt fa-lg" />
                </div>
            </div>
        );
    }
}


export {EngineFilter, FilterRow};