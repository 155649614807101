import React, { Component } from 'react';
import Popup from 'react-popup';
import ConfigureVector from './configureData/ConfigureVectors.js';
import ConfigureCostFunctions from './configureData/ConfigureCostFunctions.js';
import { FETCHAPI_PARAMS, FETCH_METHOD, fetchAPI } from '../class/networkUtils';
import { saveCookie} from '../class/jqueries';
import {
    CONFIGURE_SECTIONS,
    SCENARIO_ID_COOKIE_NAME,
    GENERATE_QUADRANTS,
    DM_SET_REPORT_ACTIONS,
    SCENARIO_STATUS,
    ALL_WIDGETS,
    HEADER_ELEMENT,
    VECTOR_STAGING_ATTRIBUTES,
    API_URL,
    SIDE_PANEL_BUILD,
    BUILD_DETAILS,
    METRICS_MAPPING,
    FY_VALUES,
    DATATYPE_ENUM,
    FISCAL_YEAR,
    GENERATE_STATUS,
    BUTTON_VARIANT,
    SIZES,
    BUTTON_TYPE,
    DROPDOWN_TYPE,
    DIALOG_SIZE,
    BUILD_SCENARIO_ID, BUILD_STATUS, IS_BUILD_RUNNING,
    BUILD_PROGRESS_STEPS
} from '../class/constants.js';
import { Tabs, CustomizedLoaderWaitingMessage, ConfigureLoader, CircleLoader ,CustomSelect} from '../form/elements.js';
import { copyObjectValues, getTranslationFile, getSectionExists, findOptionByKey, parseBoolean, convertFirstLetterToUpperCase, capitalizeFirstLetter, capitaliseFirstLetterAfterChar } from '../class/utils.js';
import { logout, setLocalStorageValueByParameter } from '../class/common.js';

import '../styles/dataModeling.css';
import '../styles/configureQuadrants.css';
import '../styles/common.css';
import ConfigureQuadrants from './configureData/ConfigureQuadrants.js';
import SidePanelNew from '../components/sidePanelNew/SidePanelNew.js';
import Backdrop from '../components/sidePanelNew/Backdrop.js';
import ToggleButton from '../components/sidePanelNew/ToggleButton.js';
import '../components/sidePanelNew/sidePanelNew.css'
import '../styles/colors.css'
import PeriodsDropDown from './buildData/PeriodsDropDown.js';
import { getPeriodQuarter } from '../class/date.js';
import Button from '../newComponents/Button.js';
import Dropdown from '../newComponents/DropDown.js';
import Modal from '../newComponents/Modal.js';
import {updateScenarios, updateScenarioState} from "../actions/scenariosActions";

const $ = require('jquery');
const lang = getTranslationFile();
const MESSAGES = getTranslationFile();

const CONFIGURE = ALL_WIDGETS.CONFIGURE;
const CONFIGURE_SECTIONS_ARRAY = Object.values(CONFIGURE_SECTIONS.FIELDS);
var CONFIGURE_STEPS = [{title: CONFIGURE_SECTIONS.TITLES.INTRODUCTION},
     {title: CONFIGURE_SECTIONS.TITLES.CONFIG_COST_FUNCTIONS}, 
     {title: CONFIGURE_SECTIONS.TITLES.CONFIG_VECTORS}, 
     {title: CONFIGURE_SECTIONS.TITLES.CONFIG_QUADRANTS}];
var FAQS = lang.configure_intro_faqs;
const _actions = DM_SET_REPORT_ACTIONS;
const _sandbox = "SANDBOX";
const _scenario_status = "scenario_status";
const EXPAND_ALL = "Expand All";
const COLLAPSE_ALL = "Collapse All";
const DEFAULT_TOGGLE_STATES = {
    IS_FORCE_ASSIGN: false,
    IS_GENERATED_SEGMENTS: true,
}
const IS_GENERATE_QUADRANTS_IN_SMART_BUILD = false;
var showMsg = false;
class ConfigureData extends Component {
    constructor(props) {
		super(props);
		this.state = {
            user: this.props.user,
            idToken: this.props.idToken,
            report: CONFIGURE_SECTIONS.FIELDS.INTRODUCTION,
            clientId: this.props.clientId,
            profitStackStructure: [],
            runPrevious: false,
            expand: false,
            viewMode: true,
            tabSelected: CONFIGURE_SECTIONS.TITLES.CONFIG_COST_FUNCTIONS,
            tabsOptions: [CONFIGURE_SECTIONS.TITLES.CONFIG_COST_FUNCTIONS, CONFIGURE_SECTIONS.TITLES.CONFIG_VECTORS, CONFIGURE_SECTIONS.TITLES.CONFIG_QUADRANTS],
            isChanged: false,
            // isReviewOrPublishedEdited: false,
            isChangedCostFunction:false,
            isChangedVectors:false,
            isChangedQuadrants:false,
            drawerOpen: false,
            quarters:[],
            isGenerateSegments: DEFAULT_TOGGLE_STATES.IS_GENERATED_SEGMENTS,
            isForceAssign: DEFAULT_TOGGLE_STATES.IS_FORCE_ASSIGN,
            profitValue:true,
            revenueValue:true
        };
        // this.timeoutInterval = setInterval(this.toggleLoaders,1000);
        this.saveVectors = this.saveVectors.bind(this);
        this.logout = logout.bind(this);
        this.onChangeTimePeriod = this.onChangeTimePeriod.bind(this);
        this.goToBuild = this.goToBuild.bind(this);
        this.troubleshootCostFunction = this.troubleshootCostFunction.bind(this);
        this.setChosenPeriod = this.setChosenPeriod.bind(this);
        this.saveDataAndGoToBuild = this.saveDataAndGoToBuild.bind(this);
        this.showSavePopUp = this.showSavePopUp.bind(this);
        this.expandAll = this.expandAll.bind(this);
        this.launchToast = this.launchToast.bind(this);
        this.fetchAPI = fetchAPI.bind(this);
        this.scenarioId = null;
        this.interval = null;
       
    }
    /** Changes drawer state on click */
    drawerToggleClickHandler = () => {
        this.setState({
            drawerOpen: !this.state.drawerOpen
        })
    }
    /** close drawer */
    backdropClickHandler = () => {
        this.setState({
            drawerOpen: false
        })
    }

    onChangeTimePeriod(e) {
        this.setState({
            timePeriod: e.value
        })
        if(this.childRef) {
            this.childRef.onChangeTimePeriod(e)
        }
    }

    expandAll(expand) {
        this.setState({
            expand: expand
        },function(){
            // this.forceUpdate();
            if(this.childRef) {
                this.childRef.expandAll(expand);
            }
        })
    }

    launchToast(saved) {
        let _this = this;
        _this.setState({
            message: saved ? lang.saved : lang.not_saved,
            isError: saved? false: true,
            viewMode: true
        },function(){
            _this.childRef.tabulator.replaceData(_this.childRef.tabulator.getData());
            _this.childRef.reExpandCollapsedRows();
            _this.enableItems();
            $("#toastConfigureData").addClass("show");
            setTimeout(function(){
                $("#toastConfigureData").removeClass("show");
            }, 4000);
        })
    }

    checkHasChange = () => {
        return (this.state.isChangedVectors && this.state.tabSelected === CONFIGURE_SECTIONS.TITLES.CONFIG_VECTORS) ||
            (this.state.isChangedCostFunction && this.state.tabSelected === CONFIGURE_SECTIONS.TITLES.CONFIG_COST_FUNCTIONS) ||
            (this.state.isChangedQuadrants && this.state.tabSelected === CONFIGURE_SECTIONS.TITLES.CONFIG_QUADRANTS);
    }

    enableItems() {
        $("#PeriodRange").removeClass("uk-hidden");
        $("#period_label").removeClass("uk-hidden");
        if (this.childRef && this.childRef instanceof ConfigureCostFunctions) {
            this.childRef.setTableColumns();
            this.childRef.tabulator.replaceData(this.childRef.tabulator.getData());
            this.childRef.reExpandCollapsedRows();
            this.childRef.disableValidateButton(this.props.selectedPeriod)
        }
    }

    updateEditedVectors() {
        let _this = this;
        var query = {
            action: "updatedEditedVectors",
            editedVectors:JSON.stringify(this?.vectorsRef?.state?.editedVectors)
        }
        let onThenCallback = (data) => {
            if(this?.vectorsRef?.state?.editedVectors){
                _this.vectorsRef.setState({
                    editedVectors:[]
                });
            }
        } 
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "updateEditedVectors",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
        };
        
        _this.fetchAPI(fetchOptions);
        
    }

    updateQuarterCount = (isForceAssign) => {
        let _this = this;
        let periods = _this.state.periodsToBuild?.filter(e=> e!== undefined)
        let quarterToGenerate =  periods?.length? [...new Set(
            periods.map(period => {
                let quarter = getPeriodQuarter(period);
                let otherPeriods = _this.state.buildDetailsData.finalRes.filter(e=>e.dataset ===quarter && e.period!==period).map(e=>e.period);
                let notBuilt = _this.state.buildDetailsData.finalRes.filter(e=> otherPeriods.includes(e.period) && e.status === BUILD_DETAILS.STATUS.NOT_BUILT && !_this.state.periodsToBuild.includes(e.period));
                if(notBuilt.length ===0 && otherPeriods.length === 2){
                    if(isForceAssign){
                        return quarter;
                    }else{
                        if(!IS_GENERATE_QUADRANTS_IN_SMART_BUILD){
                            return quarter;
                        }else if(_this.state.buildDetailsData.finalRes.filter(e=>e.dataset === quarter)[0].quadrant_status !== GENERATE_STATUS.GENERATED){
                            return quarter;
                        }
                    }
                }
            })
        )].filter(e=>e):"";
        _this.setState({
            quarters: quarterToGenerate,
        }); 
    }

    trackBuildProgress=(fromMount)=>{
        let _this = this;
        let count = 0;
        if(fromMount && !parseBoolean(sessionStorage.getItem(IS_BUILD_RUNNING))) {
            return;
        }
        sessionStorage.setItem(BUILD_SCENARIO_ID, _this.scenarioId);
        this.interval = setInterval(()=>{
            let doneRequests = 0;
            let totalRequests = 0;
            let runningRequests = "";
            let query = {
                action: "trackBuildProgress",
                scenario_id: _this.scenarioId,
            }

            let onThenCallback = (data) => {
                let succeededJobs = data.SucceededJobs.length > 0 ? copyObjectValues(data.SucceededJobs) : [lang.build_progress_currently_running_job.preparing_data[0]]
                let currentJob = convertFirstLetterToUpperCase(succeededJobs?.pop().replaceAll("_"," ").toLowerCase());
                if (lang.build_progress_currently_running_job.preparing_data.includes(currentJob)) {
                    currentJob = BUILD_PROGRESS_STEPS.PREPARING_DATA;
                } else if (lang.build_progress_currently_running_job.assigning_amounts.includes(currentJob)){
                    currentJob = BUILD_PROGRESS_STEPS.ASSIGNING_AMOUNTS;
                } else if (lang.build_progress_currently_running_job.generating_data.includes(currentJob)){
                    currentJob = BUILD_PROGRESS_STEPS.GENERATING_DATA;
                } else if (currentJob === lang.build_progress_currently_running_job.generating_segments){
                    currentJob = BUILD_PROGRESS_STEPS.GENERATING_SEGMENTS;
                } else if (currentJob === lang.build_progress_currently_running_job.build_done){
                    currentJob = BUILD_PROGRESS_STEPS.BUILD_COMPLETE;
                }
                currentJob = capitaliseFirstLetterAfterChar(currentJob.replaceAll("_"," ").toLowerCase(), " ");
                doneRequests = data.SucceededJobs?data.SucceededJobs.length:0;
                totalRequests = data.TotalJobs;
                runningRequests = currentJob;
                let status = data.status;
                
                let percentage = 100*(doneRequests/totalRequests);
                if(!['','0'].includes(runningRequests)){
                    _this.props.setRunningJob(runningRequests);
                }
                if(status !=="canceling" && !isNaN(percentage)){
                    _this.props.setBuildPerc(percentage);
                }
                if(status === "running" && percentage!== undefined){
                    _this.props.setShowCircleLoader(true);
                }
                if(count > 1000 || status === "failed" || status === "succeeded" || status === "canceling" || !parseBoolean(sessionStorage.getItem(IS_BUILD_RUNNING))) {
                    let callback = () => {
                        let stateCallBack = () => {
                            if(status === BUILD_STATUS.SUCCEEDED) {
                                _this.props.launchAppToast(true);
                            } else if(status === BUILD_STATUS.FAILED) {
                                _this.props.launchAppToast(false);
                            }
                            _this.props.setShowCircleLoader(false);
                            _this.forceUpdate()
                        }
                        let tempState = {};
                        tempState.isForceAssign = DEFAULT_TOGGLE_STATES.IS_FORCE_ASSIGN;
                        tempState.isGenerateSegments = DEFAULT_TOGGLE_STATES.IS_GENERATED_SEGMENTS;
                        tempState.reason_val = undefined;
                        if(status === "succeeded") {
                            _this.props.dispatch(updateScenarioState({}));
                            _this.props.dispatch(updateScenarios([]));
                            tempState.isSendForReview = true;
                            _this.setState(tempState);
                            setTimeout(() => {
                                _this.props.getScenarios(stateCallBack, true, false); //To Show Scenario Under Review
                            }, 1000); //Delay this request a bit for scenario to be set to review in API
                        } else if (status === "failed"){
                            _this.setState(tempState,()=> { stateCallBack() });
                        } else if (['canceling','canceled'].includes(status)){
                            _this.props.setShowCircleLoader(false);
                        }
                        // sessionStorage.setItem("isBuildRunning",false);
                        _this.props.setShowCircleLoader(false);
                        sessionStorage.setItem(BUILD_STATUS.STATUS,status === BUILD_STATUS.RUNNING && percentage === 100 ? BUILD_STATUS.SUCCEEDED : status);
                        clearInterval(_this.interval);
                    }
                    _this.fetchBuildDetails(false,true,callback);
                }
            }

            let onErrorCallback = () => {
                _this.props.setShowCircleLoader(false);
                clearInterval(_this.interval);
            }

            let fetchOptions = {
                [FETCHAPI_PARAMS.funcName]: "trackBuildProgress",
                [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
                [FETCHAPI_PARAMS.showLoader]: false,
                [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
                [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
                [FETCHAPI_PARAMS.query]: query,
                [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
                [FETCHAPI_PARAMS.onErrorCallback]: onErrorCallback,
                [FETCHAPI_PARAMS.screenName]:lang.observability.configure_build.screen_name,
                [FETCHAPI_PARAMS.requestDescription]:lang.observability.configure_build.requests_description.track_build_progress
              };
            count++;
            _this.fetchAPI(fetchOptions);
        }, 10000);
    }


    getVectorsData =()=>{
        let _this = this;
        let vectorsData = {};
        let vectorsList = _this.vectorsRef.state.dataParent.filter(function (el) {
            return el.generate_quads === true && el.visibility === true
        });
        
        vectorsList.forEach(row=>{
                let vectorName = row[GENERATE_QUADRANTS.FIELDS.VECTOR];
                let vectorId = row[GENERATE_QUADRANTS.FIELDS.VECTOR_ID]
                let quarters = _this.state.quarters? _this.state.quarters.map(el=>{return el}):""
                vectorsData[vectorName] = vectorsData[vectorName] || {};    //if object doesnt exist, create it
                vectorsData[vectorName]["id"] = vectorId;
                vectorsData[vectorName]["quarters"] = [];
                if(quarters){
                    quarters.forEach(quarter=>{
                        vectorsData[vectorName]["quarters"].push(quarter);
                    })
                }
        })
        return vectorsData
    }

    runFullBuild = (sendEmail, callback) => {
        let _this = this;
        if (typeof callback === 'function') {
            callback();
        }
        if(_this.state.periodsToBuild.length ===0){
            _this.setOpenEmptyPeriodsDialog(true);
            return;
        }
        _this.props.setBuildPerc(0);
        let scenarioObj = findOptionByKey(this.props.scenarioList, Number(this.props.scenarioId));
        setLocalStorageValueByParameter("scenarioForBuild",_this.props.scenarioId);
        let periodsAvailable = this.state.buildDetailsData.finalRes.filter(e=> parseBoolean(e.is_ready_for_build) && this.state.periodsToBuild.includes(e.period));
        let readyPeriods = this.state.isForceAssign ? periodsAvailable : periodsAvailable.filter(e=> e.status === BUILD_DETAILS.STATUS.NOT_BUILT)
        // removing  data related to scenarios from redux store 
        let periodsData = periodsAvailable.map((period) => {
            if(_this.state.periodsApplicableForBuild.includes(period.period)){
                return period.period;
            }
        }).filter(e=>e);
        let query = {
            action: "runFullBuild",
            periodsData:periodsData,
            sendEmail: sendEmail,
            scenarioNumber: scenarioObj.scenario_number,
            userName:this.state.user.first_name,
            userEmail:this.state.user.email,
            scenario_id: _this.props.scenarioId,
            isForceAssign: this.state.isForceAssign,
            isGenerateSegments: this.state.isGenerateSegments,
            vectorsData: _this.getVectorsData(),
            reasonForBuild: _this.state.reason_val ? lang.manage_build.reason_for_build_options.filter(e=>e.value ===_this.state.reason_val)[0].label : "",
            isMonthlyBuild: false
        }
        let onCompleteHttpStatusCodeCallback = (httpStatusCode) => {
            if(httpStatusCode === 409){
                this.showBuildAlreadyRunningPopUp();
            }
        }
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "runFullBuild",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onCompleteHttpStatusCodeCallback]: onCompleteHttpStatusCodeCallback,
            [FETCHAPI_PARAMS.periods]:periodsData,
            [FETCHAPI_PARAMS.screenName]:lang.observability.configure_build.screen_name,
            [FETCHAPI_PARAMS.requestDescription]:lang.observability.configure_build.requests_description.run_full_build
        };
        
        _this.fetchAPI(fetchOptions);
        _this.props.setShowCircleLoader(true);
        _this.trackBuildProgress(false);
        _this.props.setRunningJob(capitaliseFirstLetterAfterChar(BUILD_PROGRESS_STEPS.PREPARING_DATA.replaceAll("_"," ").toLowerCase(), " "));

    }

    generateSegments = (callback) => {
        let _this = this;

        if (typeof callback === "function") {
            callback();
        }
        if(_this.state.isForceAssign && _this.state.periodsToBuild.length ===0){
            _this.setOpenEmptyPeriodsDialog(true);
            return;
        }
        _this.props.setBuildPerc(0);
        let scenarioObj = findOptionByKey(this.props.scenarioList, Number(this.props.scenarioId));
        setLocalStorageValueByParameter("scenarioForBuild",_this.props.scenarioId);
        let periodsAvailable = this.state.buildDetailsData.finalRes.filter(e=> parseBoolean(e.is_ready_for_build) && this.state.periodsToBuild.includes(e.period));
        // removing  data related to scenarios from redux store
        let query = {
            action: "generateSegments",
            periodsData: periodsAvailable.map((period) => {
                if (_this.state.periodsApplicableForBuild.includes(period.period)) {
                    return period.period;
                }
            }).filter(e => e),
            sendEmail: false,
            scenarioNumber: scenarioObj.scenario_number,
            userName:this.state.user.first_name,
            userEmail:this.state.user.email,
            scenario_id: _this.props.scenarioId,
            isForceAssign: this.state.isForceAssign,
            isGenerateSegments: this.state.isGenerateSegments,
            vectorsData: _this.getVectorsData(),
            reasonForBuild: _this.state.reason_val ? lang.manage_build.reason_for_build_options.filter(e=>e.value ===_this.state.reason_val)[0].label : ""
        }
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "generateSegments",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
        };

        _this.fetchAPI(fetchOptions);
    }


    /**
     * when pressing on save at any tab
     * @param {*} switchAfter  param to call changeTab function from inside the child component
     */
    startSave = (switchAfter,openModalAfter,newScenario,switchCallback, changeScenarioCallback)=>{
        let _this = this;
        _this.setOpenBackConfirmationDialog(false)
        var saveBtnCallback = ()=>{};
        switch(this.state.tabSelected) {
            case CONFIGURE_SECTIONS.TITLES.CONFIG_COST_FUNCTIONS:
                    saveBtnCallback = (typeArg) => {
                        // if(_this.props.scenarioStatus === _sandbox) {
                            _this.childRef.checkBeforeSaveCostFunction(false, typeArg, switchAfter,openModalAfter,newScenario);
                        // }
                    };
            break;
            case CONFIGURE_SECTIONS.TITLES.CONFIG_VECTORS:
                saveBtnCallback = () => {
                   _this.vectorsRef.saveTable(switchAfter,openModalAfter);
                };
            break;
            case CONFIGURE_SECTIONS.TITLES.CONFIG_QUADRANTS:
                saveBtnCallback = () => {
                    _this.resetTabsData(CONFIGURE_SECTIONS.TITLES.CONFIG_QUADRANTS);
                    _this.quadrantsRef.saveQuadrantConfiguration(switchAfter,openModalAfter);
                };
            break;
        }
        if(this.state.tabSelected === CONFIGURE_SECTIONS.TITLES.CONFIG_QUADRANTS &&  _this.childRef){
            if(_this.quadrantsRef.validateRevenue()){
                saveBtnCallback();
                _this.saveClicked = true;
            } 
        }
        else if(this.state.tabSelected === CONFIGURE_SECTIONS.TITLES.CONFIG_VECTORS && _this.vectorsRef){
            if(_this.vectorsRef.validateConfigureVectors()){
                saveBtnCallback();
                _this.saveClicked = true;
            } 

        } else {
            saveBtnCallback();
            _this.saveClicked = true;
        }

        if(switchCallback && typeof switchCallback === 'function'){
            switchCallback()
        }
        if(changeScenarioCallback && typeof changeScenarioCallback === 'function'){
            changeScenarioCallback()
        }
       
        
    }

    goToBuild() {
        saveCookie(SCENARIO_ID_COOKIE_NAME, this.props.scenarioId);
        this.props.goToBuild();
    }

    onChangeScenario(option) {
        let _this = this;
        this.resetMultipleRequestsData(["getCostFunction", "getVectorList", "getQuadrantConfiguration", "getProfitStackLines","getVectorPeriodStatus","getStagedPeriods"],()=>{
            if(_this.childRef && !_this.childRef.getVectorPeriodStatusFetched && !_this.getTabsData("getVectorPeriodStatus")){
                _this.childRef.getVectorPeriodStatus();
            }
            if(_this.childRef && !_this.getTabsData("getStagedPeriods") && !_this.childRef.getStagedPeriodsFetched) {
                _this.childRef.getStagedPeriods();
            }
        })
        _this.fetchBuildDetails();
        _this.updateQuarterCount();
        _this.vectorsRef.getVectorList()
        _this.quadrantsRef.getProfitStackLines();
        _this.childRef.getCostFunction();
        
    }
    
    setChosenPeriod(e){
        this.resetTabsData(CONFIGURE_SECTIONS.TITLES.CONFIG_COST_FUNCTIONS)
        this.childRef.setChosenPeriod(e)
    }

    /**
     * @function componentWillUnmount()
     * if unmounted clear interval inside trackBuildProgress function
     **/
    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    componentDidMount() {
        if($("#loading").is(":visible") ) {
            showMsg =true
        } else {
            showMsg =false
        }
        //hiding the period drop down list in header
        // $("#PeriodRange").addClass("uk-hidden");
        $("#period_label").addClass("uk-hidden");
        //enabling the scenario drop down list
        $("#scenarioList-header").removeClass("disabled");
        // this.props.setReportTitle("configure & Build");
        // toggleLoader(false, 'dataModelingUserNotAuthenticated');   //hiding loader set in DataModeling.js render when user isn't authenticated yet}
        $('loading').hide();
        this.scenarioId = copyObjectValues(this.props.scenarioId);
        this.updateQuarterCount();
        this.fetchBuildDetails();
        this.trackBuildProgress(true);
        this.childRef.getStagedPeriods();
        this.childRef.getFields();
        this.childRef.getMetricsPeriodStatus();
        this.vectorsRef.getVectorList();
        // let reports = this.props.reportsHeaderElements;
        // reports[ALL_WIDGETS.CONFIGURE] = [HEADER_ELEMENT.SCENARIO,HEADER_ELEMENT.PERIOD,HEADER_ELEMENT.HEADER_BUTTON_MANAGE_BUILD];
        // this.props.updateConfigureHeaderElements(reports,ALL_WIDGETS.CONFIGURE);
    }

    fetchBuildDetails=(showLoader=true,buildDone = false,callback)=> {
        let _this = this;
        var query = {
          action: "fetchBuildDetails",
          scenario_id: _this.props.scenarioId
        };
        let onThenCallback = (data) => {
            if (data) {
                _this.setState({
                    buildDetailsData: data,
                    periodsApplicableForBuild: data.periodsApplicableForBuild,
                    generatedSegments: data.generatedSegments,
                    periodsToBuild: data.finalRes.map(item => item.period)//by default all the periods are selected
                });
                if(buildDone) {
                    if(_this.periodRef && _this.periodRef !== null){
                        _this.periodRef.checkAll(true);
                    }
                    if(typeof callback === "function") {
                        callback();
                    }
                } else {
                    this.enableManageBuilds(true);
                }
            } else if (data.ERROR) {
                this.setInfoDialogOpen(true, "There's something wrong with your query.");
            }
        };
    
        let fetchOptions = {
          [FETCHAPI_PARAMS.funcName]: "fetchBuildDetails",
          [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
          [FETCHAPI_PARAMS.showLoader]: showLoader,
          [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
          [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
          [FETCHAPI_PARAMS.query]: query,
          [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
          [FETCHAPI_PARAMS.screenName]:lang.observability.configure_build.screen_name,
        [FETCHAPI_PARAMS.requestDescription]:lang.observability.configure_build.requests_description.fetch_build_details
        };
        _this.fetchAPI(fetchOptions);
    }
  

    saveVectors(savedVectors){
        this.setState({
            savedVectors: savedVectors
        }, function(){
            this.forceUpdate();
        })
    }

    saveDataAndGoToBuild() {
        this.childRef.saveAndGoToBuild(true);
    }

    troubleshootCostFunction(costKey, timePeriod, updateData) {
        this.props.troubleshootCostFunction(costKey, timePeriod, updateData);
    }

    showSavePopUp(param) {
        this.setState({
            message: param? lang.saved : lang.not_saved,
            isError: param? false : true,
        },function(){
            $("#toastConfigureData").addClass("show");
            setTimeout(function(){
                $("#toastConfigureData").removeClass("show");
            }, 4000);
        })
    }

    showBuildAlreadyRunningPopUp(param) {
        $("#toastBuildAlreadyRunningOnScenario").addClass("show");

        setTimeout(function(){
            $("#toastBuildAlreadyRunningOnScenario").removeClass("show");
        }, 4000);
    }

    componentDidUpdate(prevProps){
        let _this = this;
        if((this.props.selectedPeriod !== prevProps.selectedPeriod) && this.vectorsRef  && !this.vectorsRef.getVectorListFetched  && !this.getTabsData("getVectorList")) {
            this.vectorsRef.getVectorList();
        }
        if((this.props.selectedPeriod !== prevProps.selectedPeriod || this.props.scenarioChanged) && this.childRef ){
            if(this.childRef && !this.childRef.getStagedPeriodsFetched  && !this.getTabsData("getStagedPeriods")){
                this.childRef.getStagedPeriods();
            }
            if(this.childRef && !this.childRef.getFieldsFetched && !this.getTabsData("getFields")){
                this.childRef.getFields();
            }
            if(this.childRef && !this.childRef.getMetricsPeriodStatusFetched && !this.getTabsData("getMetricsPeriodStatus")){
                this.childRef.getMetricsPeriodStatus();
            }
            // if((this.childRef && !this.childRef.getVectorPeriodStatusFetched && !this.getTabsData("getVectorPeriodStatus"))){
            //     this.childRef.getVectorPeriodStatus();
            // }
            // if((this.childRef && !this.getTabsData("getCostFunction") && !this.childRef.getCostFunctionFetched)) {
            //     this.childRef.getCostFunction();
            // }
        }
        if(this.quadrantsRef && !this.quadrantsRef.getProfitStackLinesFetched && !this.quadrantsRef.getQuadrantConfigurationFetched && this.quadrantsRef.state.revenueValue && this.quadrantsRef.state.profitValue &&  !this.state.isChangedQuadrants && !this.getTabsData("getQuadrantConfiguration") && !this.getTabsData("getProfitStackLines") && !Object.keys(window._loading).includes("saveQuadrantConfiguration")){
            this.quadrantsRef.getProfitStackLines();
        }
        // setTimeout(function(){
        //     _this.props.setIsLoaderShown($(".configure-loading").is(":visible"));
        // }, 200);
    }

    /**
     * when pressing on configure or cancek, to enter the cnfigure mode and exit it
     * @param {*} configure 
     */
    configure =(configure)=>{
        let _this = this;
        this.setState({
            viewMode: !configure,
            isChangedCostFunction: false
        }, function(){
            if(this.childRef) {
                if (!this.state.viewMode) {
                    // $("#PeriodRange").addClass("uk-hidden");
                    $("#period_label").addClass("uk-hidden");
                    this.childRef.setTableColumns();
                    this.childRef.tabulator.replaceData(this.childRef.tabulator.getData());
                    this.childRef.reExpandCollapsedRows();
                } else {
                    this.enableItems();
                }
            }
            _this.props.setViewMode(!configure);
        })
    }

    /**
     * change the state of isChanged when doing changes on any tab
     * @param {*} param 
     */
    setIsChanged =(param, isEdit = false, revenueValue=true, profitValue=true)=>{
        let _this = this;
        // if(_this.props.scenarioStatus === _sandbox){
            if(isEdit || _this.state.isEdit) {
                _this.setState({
                    isEdit: isEdit
                });
            }

            _this.setState({
                revenueValue: revenueValue,
                profitValue: profitValue
            });

            if(isEdit || (_this.state.isChangedVectors !== param && _this.state.tabSelected == CONFIGURE_SECTIONS.TITLES.CONFIG_VECTORS)){
                _this.setState({
                    isChangedVectors: param,
                });
            }else if(_this.state.isChangedQuadrants !== param && _this.state.tabSelected == CONFIGURE_SECTIONS.TITLES.CONFIG_QUADRANTS){
                _this.setState({
                    isChangedQuadrants: param,
                });
            }else if(_this.state.isChangedCostFunction !== param && _this.state.tabSelected == CONFIGURE_SECTIONS.TITLES.CONFIG_COST_FUNCTIONS){
                _this.setState({
                    isChangedCostFunction: param,
                });
            } 
        // }else {
        //     if(_this.state.isReviewOrPublishedEdited !== param){
        //         _this.setState({
        //             isReviewOrPublishedEdited: param
        //         });
        //     }
        // }
    }

    getNextTab = () =>{
        return this.state.nextTab
    }


    saveAndOpenManageBuild=()=>{
        
    }

    /**
     * when changing the selected tab
     * @param {*} tab 
     */
    changeTab = (tab) =>{
        let _this = this;
        let isEdit = _this.state.isEdit
        if(_this.state.isChangedVectors && _this.state.tabSelected == CONFIGURE_SECTIONS.TITLES.CONFIG_VECTORS && !_this.isSubset(Object.keys(window._loading),["updateVectors"])){
            if(_this.vectorsRef){
                let orderedData = _this.vectorsRef.tabulator.getData()
                _this.vectorsRef.setState({
                    dataParent: orderedData
                })
            }
            //added nextTab state to keep track of the current tab and the destination tab
            _this.setState({
                nextTab: tab,
            });
            _this.setOpenChangesNotSavedDialog(true)
        }else if(_this.state.isChangedQuadrants && this.state.tabSelected == CONFIGURE_SECTIONS.TITLES.CONFIG_QUADRANTS && !this.isSubset(Object.keys(window._loading),["saveQuadrantConfiguration"])){
            _this.setState({
                nextTab: tab,
            });
            _this.setOpenChangesNotSavedDialog(true)        
        } else if(_this.state.isChangedCostFunction && this.state.tabSelected == CONFIGURE_SECTIONS.TITLES.CONFIG_COST_FUNCTIONS){
            _this.setState({
                nextTab: tab,
            });
            _this.setOpenChangesNotSavedDialog(true)
        }else{
           if(!_this.childRef || !_this.childRef.tabulator || !_this.childRef.tableBuilt){
                _this.timeOut = setTimeout(()=>{
                    _this.changeToTab(tab,isEdit);
                },7000);
           } else {
                if(_this.state.tabSelected === CONFIGURE_SECTIONS.TITLES.CONFIG_QUADRANTS) {
                    _this.resetChanges(() => _this.changeToTab(tab, isEdit));
                } else {
                    _this.changeToTab(tab, isEdit);
                }
           }
        }
    }

    changeToTab=(tab,isEdit)=>{
       clearInterval(this.timeOut);
        let _this = this;
        _this.setState({
            tabSelected: tab,
            isChangedVectors: !isEdit ? false : true, 
            isChangedQuadrants:false,
            isChangedCostFunction:false,
            // isReviewOrPublishedEdited: false,
            viewMode: true
        },()=>{
           _this.configure(false);
            // let reports = _this.props.reportsHeaderElements;
            if(_this.state.tabSelected == CONFIGURE_SECTIONS.TITLES.CONFIG_VECTORS){
                _this.vectorsRef.enableSave(_this.vectorsRef.getVectors())
            }
            if(_this.state.tabSelected !== CONFIGURE_SECTIONS.TITLES.CONFIG_COST_FUNCTIONS ){
                // reports[ALL_WIDGETS.CONFIGURE] = [HEADER_ELEMENT.SCENARIO]
            } else {
                // reports[ALL_WIDGETS.CONFIGURE] = [HEADER_ELEMENT.SCENARIO,HEADER_ELEMENT.PERIOD]
            }
           
            // if(!(reports && reports[CONFIGURE].includes(HEADER_ELEMENT.HEADER_BUTTON_MANAGE_BUILD))){
            //      reports[ALL_WIDGETS.CONFIGURE].push(HEADER_ELEMENT.HEADER_BUTTON_MANAGE_BUILD);
            // }
            // _this.props.updateConfigureHeaderElements(reports,ALL_WIDGETS.CONFIGURE);
            _this.props.setTabSelected(tab);
        });
    }
    saveAndChangeTab = () =>{
        this.setOpenChangesNotSavedDialog(false)
        this.startSave(true);
        // this.setState({
        //     isChanged: false,
        //     viewMode: true
        // },function(){
        //     // this.changeTab(this.state.nextTab);
        // });
    }
    saveAndOpenModal = () =>{
        this.setOpenMonthlyBuildChangesNotSavedDialog(false)
        this.startSave(false,true);
    }

    discardAndOpenBuild =() =>{
        this.setState({
            isChangedVectors: false, 
            isChangedQuadrants:false,
            isChangedCostFunction:false,
            // isReviewOrPublishedEdited: false,
            isEdit: false,
        },function(){
            // let callback = () => {/*opendialog*/};
            // this.resetChanges(callback);
            this.resetChanges();
            this.setOpenMonthlyBuildChangesNotSavedDialog(false)
            
        });
        this.drawerToggleClickHandler()
    }

    discardAndChangeTab =() =>{
        this.setState({
            isChangedVectors: false, 
            isChangedQuadrants:false,
            isChangedCostFunction:false,
            // isReviewOrPublishedEdited: false,
            isEdit: false
        },function(){
            let callback = () => {this.changeTab(this.state.nextTab)};
            this.resetChanges(callback);
            this.setOpenChangesNotSavedDialog(false)
        });
    }

    rollBack = () =>{
        let _this = this;
        _this.setOpenChangesNotSavedDialog(false);
        if(_this.vectorsRef &&_this.state.tabSelected === CONFIGURE_SECTIONS.TITLES.CONFIG_VECTORS){
            _this.vectorsRef.tabulator.replaceData(copyObjectValues(_this.vectorsRef.state.dataParent));
        }
    }
/**
 * opens Modal on manage build click if changes not saved
 */
    onManageBuildClick = () => {
        let _this = this
        if (_this.state.isChangedVectors && _this.state.tabSelected == CONFIGURE_SECTIONS.TITLES.CONFIG_VECTORS
            || _this.state.isChangedQuadrants && _this.state.tabSelected == CONFIGURE_SECTIONS.TITLES.CONFIG_QUADRANTS
            || _this.state.isChangedCostFunction && _this.state.tabSelected == CONFIGURE_SECTIONS.TITLES.CONFIG_COST_FUNCTIONS) {
              this.setOpenMonthlyBuildChangesNotSavedDialog(true)
        } else {
            _this.drawerToggleClickHandler()
        }
    }

    showBackConfirmation=()=>{
        if(this.state.isChangedCostFunction){
            this.setOpenBackConfirmationDialog(true)
        }
        // else if(this.state.isReviewOrPublishedEdited){
        //     window._profitIsleOpenModal("discardChanges");
        // }
        else {
            this.configure(false);
        }
    }

    /**
     * reset changes before leaving the configure mode
     */
    resetAndContinue=()=>{
        let _this = this;
        _this.setState({
            isChangedCostFunction: false,
            isChangedVectors: false,
            isChangedQuadrants: false,
            // isReviewOrPublishedEdited: false
        },function(){
            _this.setOpenBackConfirmationDialog(false)
            _this.resetChanges(); 
        })
        _this.configure(false);
    }

    resetChanges = (callback) =>{
        let _this = this;
        _this.setState({
            isChangedCostFunction: false,
            isChangedVectors: false,
            isChangedQuadrants: false,
            // isReviewOrPublishedEdited: false,
            isEdit:false
        },function(){
          _this.setOpenResetDialog(false);
            if(_this.childRef && _this.state.tabSelected === CONFIGURE_SECTIONS.TITLES.CONFIG_COST_FUNCTIONS){
                _this.childRef.tabulator.replaceData(copyObjectValues(_this.childRef.state.originalData)).then(()=>{
                    if(typeof callback === "function"){
                        callback();
                    }
                });
            }else if(_this.quadrantsRef && _this.state.tabSelected === CONFIGURE_SECTIONS.TITLES.CONFIG_QUADRANTS){
                let originalProfitValue =  _this.quadrantsRef.state.originalProfitValue;
                let originalRevenueValue =  _this.quadrantsRef.state.originalRevenueValue;
                let originalProfitStackTreeStructure =  _this.quadrantsRef.state.originalProfitStackTreeStructure;
                _this.setState({
                    profitValue:originalProfitValue,
                    revenueValue:originalRevenueValue
                });
                _this.quadrantsRef.setState({
                    profitStackTreeStructure:originalProfitStackTreeStructure,
                    profitValue:originalProfitValue,
                    revenueValue:originalRevenueValue
                },()=>{
                    if(typeof callback === "function"){
                        callback();
                    }
                })
            } else if(_this.vectorsRef && this.state.tabSelected === CONFIGURE_SECTIONS.TITLES.CONFIG_VECTORS){
                let dataParent = copyObjectValues(_this.vectorsRef.state.originalDataParent);
                let isEdited = dataParent.filter(e=>e[VECTOR_STAGING_ATTRIBUTES.IS_EDITED]);
                for(let e in isEdited){
                    isEdited[e][VECTOR_STAGING_ATTRIBUTES.IS_EDITED] = false;
                }
                let vectorRef = _this.vectorsRef;
                vectorRef.setState({
                    dataParent : dataParent
                },function(){
                    if(/*_this.props.scenarioStatus === _sandbox &&*/ vectorRef.state.editedVectors?.length > 0){
                        _this.updateEditedVectors()
                    }
                    vectorRef.tabulator.replaceData(copyObjectValues(vectorRef.state.dataParent)).then(()=>{
                        if(typeof callback === "function"){
                            callback();
                        }
                    });
                });
                // _this.vectorsRef.getVectorList();
                // _this.vectorsRef.getVectors(true);
            }
             
        })
    }

    /**
     * this function is to save the data of the tabs after the first load, so when reloading these tabs, no need to refetch the data from the api
     * @param {*} tab 
     */
    setTabsData=(request, data)=>{
        let _this = this;
        let tempState={};
        tempState.tabsData = _this.state.tabsData || {};
        tempState.tabsData[request] = data;
        if(data === undefined){
            tempState.isChangedCostFunction = false;
            tempState.isChangedVectors = false;
            tempState.isChangedQuadrants = false;
            // tempState.isReviewOrPublishedEdited = false;
        }
        _this.setState(tempState)
    }

    /**
     * reset multiple specific request
     * @param {*} requests 
     */
    resetMultipleRequestsData=(requests,callback)=>{
        let tempState={};
        tempState.tabsData = this.state.tabsData || {};
        for(var request in requests){
            tempState.tabsData[requests[request]] = undefined;
        }
        tempState.isChangedCostFunction = false;
        tempState.isChangedVectors = false;
        tempState.isChangedQuadrants = false;
        // tempState.isReviewOrPublishedEdited = false;
        this.setState(tempState,()=>{
            if(typeof callback === "function"){
                callback();
            }
        });
        
        
    }

    /**
     * to get the saved data to be refetched
     * @param {*} tab 
     * @returns 
     */
    getTabsData=(request)=>{
        let _this = this;
        return _this.state.tabsData ? _this.state.tabsData[request] : undefined;
    }


    /**
     * this function triggers when saving or changing scenario to reset data from state.tabsData
     */
    resetTabsData=(tab)=>{
        if(tab === CONFIGURE_SECTIONS.TITLES.CONFIG_COST_FUNCTIONS){
            this.setTabsData("getCostFunction", undefined);
        }else if(tab === CONFIGURE_SECTIONS.TITLES.CONFIG_VECTORS){
            this.setTabsData("getVectorList", undefined);
        }else if(tab === CONFIGURE_SECTIONS.TITLES.CONFIG_QUADRANTS){
            this.resetMultipleRequestsData(["getQuadrantConfiguration","getProfitStackLines"]);
        }
    }
    
    toggleLoaders=()=>{
        if($("#loading").is(":visible") ) {
            showMsg =true
        } else {
            showMsg =false
        }
        let loaderConfigureCostFunctionCondition = this.getTabsData("getStagedPeriods")  && this.getTabsData("getCostFunction") &&  this.getTabsData("getVectorPeriodStatus") 
        && this.getTabsData("getMetricsPeriodStatus") && this.getTabsData("getFields") && this.props.selectedPeriod ;
        let loaderVectorsCondition = this.getTabsData("getVectorList")
        let loaderQuadrantCondition =  this.getTabsData("getQuadrantConfiguration");
        if(loaderConfigureCostFunctionCondition && this.state.tabSelected === CONFIGURE_SECTIONS.TITLES.CONFIG_COST_FUNCTIONS) {
            if(!this.isSubset(Object.keys(window._loading),["getStagedPeriods","getCostFunction","getVectorPeriodStatus","getMetricsPeriodStatus","getFields"])){
                $(".configure-loading").hide();
                $('.customized-loader-waiting-message').hide();
            }  
        } else if(loaderVectorsCondition &&  this.state.tabSelected === CONFIGURE_SECTIONS.TITLES.CONFIG_VECTORS){
            if(!this.isSubset(Object.keys(window._loading),["getVectorList"])){
                $(".configure-loading").hide();
                $('.customized-loader-waiting-message').hide();
            } 
           
        } else if (loaderQuadrantCondition && this.state.tabSelected === CONFIGURE_SECTIONS.TITLES.CONFIG_QUADRANTS){
            if(!this.isSubset(Object.keys(window._loading),["getQuadrantConfiguration","getProfitStackLines","saveQuadrantConfiguration"])){
                $(".configure-loading").hide();
                $('.customized-loader-waiting-message').hide();
            } 
        } else {
           
            if(this.state.tabSelected === CONFIGURE_SECTIONS.TITLES.CONFIG_VECTORS && !this.isSubset(Object.keys(window._loading),["updateVectors"]) ){
                $('.customized-loader-waiting-message').show();
            } else {
                $('.customized-loader-waiting-message').hide();
            }
            // $(".configure-loading").show();
        }
    }

    isSubset(array1, array2) {
        // returns true if array2 is a subset of array1
      
        return array2.every(function (element) {
          return array1.includes(element);
        });
    }

    setValidation=(message)=>{
      this.setOpenValidationDialog(true, message);

    }

    onBuildDetailsClick=()=>{
        localStorage.setItem("tabs",Number(localStorage.getItem("tabs"))+1);
        let scenarioObj = findOptionByKey(this.props.scenarioList, Number(this.props.scenarioId));
        let bWindows = window.open(window.location.origin + "/build_details");
        bWindows.buildDetailsData = this.state.buildDetailsData;
        bWindows.scenarioNumber = scenarioObj.scenario_number;
    }

    /**
     * on checking force assign
     */
    onCheckboxChange=(e)=>{
        this.setState({
            isForceAssign: e.target.checked
        });
        this.updateQuarterCount(e.target.checked);
    }
    onGenerateQuadrantsOn=(e)=>{
        this.setState({
            isGenerateSegments:e.target.checked,
        });
    }

    /**
     * show the periods drop down when clicking on select period
     * @param {*} evt 
     */
    showPeriodsComp=(evt)=>{
        let eventTarget = $(evt.currentTarget);
        let forceAssignCond = getSectionExists(this.props.userAllowedSections, ALL_WIDGETS.FORCE_ASSIGN_COST) && parseBoolean(this.props.user.is_system);

        this.setState({
            showPeriodsComp:true
        },()=>{
            $("#periods_comp_configure_data").removeClass("uk-hidden");
            $("#periods_comp_configure_data").css({
                top: (eventTarget.offset().top + ($("#periods_comp_configure_data").height()/8) ),
                left: forceAssignCond ? '50%' : '5%'//$(evt.currentTarget).offset().left
            });
        })
    }

    /**
     * add and remove selected periods, by default all the periods are selected
     * @param {*} period 
     * @param {*} show 
     */
    addPeriodsToBuild=(periods, show)=>{
        let _this = this;
        let periodsToBuild = [...new Set(this.state.periodsToBuild )] || [];
        if(periods.length>1){// if select all or deselect all
            if(show){//select all
                periods.forEach(function(period){
                    periodsToBuild.push(period);
                });
            }else{//deselect all
                periodsToBuild=[];
            }
        }else{
            let period = periods[0];
            if(show && !periodsToBuild.includes(period)){
                periodsToBuild.push(period);
            }else if(periodsToBuild.includes(period)){
                periodsToBuild.splice(periodsToBuild.findIndex(el => el === period), 1);
            }
        }
        this.setState({
            periodsToBuild:periodsToBuild
        },()=>{
            _this.updateQuarterCount();
        })
    }
    enableManageBuilds = (value) => {
        this.props.enableManageBuilds(value);
    }

    handleChange = (type,e) => {
        if(e !== null){
            if(type === SIDE_PANEL_BUILD.DROPDOWNS.REASON_FOR_BUILD){
                this.setState({
                    reason_val:e.value
                })
            }
        }
    }
    
    body(vectorsWithoutGroups, quadrantsConfiguration){
        let _this = this;
        let scenarioStatus = findOptionByKey(this.props.scenarioList, Number(this.props.scenarioId))[_scenario_status];
        if(!this.state.buildDetailsData || !this.state.periodsApplicableForBuild){
            return"";
        }
        let periodsAvailable = this.state.buildDetailsData.finalRes.filter(e=> parseBoolean(e.is_ready_for_build) && this.state.periodsApplicableForBuild.includes(e.value));
        let readyPeriods = this.state.isForceAssign ? periodsAvailable : periodsAvailable.filter(e=>  e.status === BUILD_DETAILS.STATUS.NOT_BUILT) //not built + ready to be built
    
        let generatedSegments = this.state?.generatedSegments?.map(e => e.period);
        let readySegments = this.state.isForceAssign? periodsAvailable : periodsAvailable.filter(e=> !(generatedSegments?.includes(e.value)));
        let readySegmentsCount = readySegments?.length || 0;
        if(generatedSegments.includes(periodsAvailable[periodsAvailable?.length -3]?.period) && !this.state.isForceAssign){ // if the 3rd period (in ascending order) is generated, we must not take into consedration the count of the first 2 periods in segments generation
            readySegmentsCount -= 2;
        }
        let costAssignmentText = lang.manage_build.cost_assignment_text.replace("[x]", (readyPeriods.length));
        // let quartersToGenerate = [...new Set(
        //     _this.state.periodsToBuild.map(period => {
        //         let quarter = getPeriodQuarter(period);
        //         // let otherPeriods = getOtherPeriodsFromQuarter(quarter,period);
        //         let otherPeriods = _this.state.buildDetailsData.finalRes.filter(e=>e.dataset ===quarter && e.period!==period).map(e=>e.period);
        //         let notBuilt = _this.state.buildDetailsData.finalRes.filter(e=> otherPeriods.includes(e.period) && e.status === BUILD_DETAILS.STATUS.NOT_BUILT && !_this.state.periodsToBuild.includes(e.period));
        //         if(notBuilt.length ===0 && otherPeriods.length===2){
        //             if(this.state.isForceAssign){
        //                 return quarter;
        //             }else{
        //                 if(!IS_GENERATE_QUADRANTS_IN_SMART_BUILD){
        //                     return quarter;
        //                 } else if(_this.state.buildDetailsData.finalRes.filter(e=>e.dataset === quarter)[0].quadrant_status !== GENERATE_STATUS.GENERATED){
        //                     return quarter;
        //                 }
        //             }
        //         }
        //     })
        // )].filter(e=>e);
        let quadrantsText = lang.manage_build.quadrants_text.replace("[x]",( 
            readySegmentsCount
        ))
        let periodsForDropDown = this.state.buildDetailsData && this.state.periodsApplicableForBuild?
            this.state.buildDetailsData.finalRes.map(period => {
                if(this.state.periodsApplicableForBuild.includes(period.period)){
                    period.is_active = parseBoolean(period.is_ready_for_build)? FISCAL_YEAR.PERIOD_STATUS_VALUES.ACTIVE : FISCAL_YEAR.PERIOD_STATUS_VALUES.INACTIVE;
                    period.disabled = !parseBoolean(period.is_ready_for_build);
                    period.label = period.period;
                    period.value = period.period;
                    return period;
                }
            }).filter(e=>e!== undefined)
        :[]
        let forceAssignCond = getSectionExists(this.props.userAllowedSections, ALL_WIDGETS.FORCE_ASSIGN_COST) && parseBoolean(this.props.user.is_system);

        return (
            <div className="inner-container-padding ">
                <div className="uk-flex uk-flex-between uk-flex-middle">
                    {/* <div>
                        <h4 className="info-hover uk-text-bold">
                            {SIDE_PANEL_BUILD.TITLES.MANAGE_BUILD_TITLE}
                            <i className="fal fa-info-circle uk-margin-small-left info-icon fs-12" uk-tooltip={SIDE_PANEL_BUILD.INFO.MANAGE_BUILD_INFO} />
                        </h4>
                    </div> */}
                    {/* <Button  
                        variant={BUTTON_VARIANT.TERTIARY}
                        size={SIZES.ICON}
                        type={BUTTON_TYPE.DEFAULT}
                        className="close-button uk-margin-remove"
                        aria-label="Close"
                        leftIcon={<span aria-hidden="true"><i className="fal fa-times"></i></span>}
                        onBtnClick={this.backdropClickHandler} 
                    /> */}
                </div>
                {vectorsWithoutGroups.length>0?
                    <div className="build-banner-text banner-text pi-background-light-red">
                        <i className="warning-banner red fa-lg fas uk-margin-small-right fa-minus-circle" aria-hidden="true"></i>
                        <span className="fs-14">{lang.build_button_disabled.no_group}</span>
                    </div>
                : ""
                    // <div className="banner-text pi-background-light-yellow">
                    //     <i className="warning-banner fa-lg fas uk-margin-small-right fa-exclamation-circle" aria-hidden="true"></i>
                    //     <span className="fs-14">6 active periods are still not eligible to be built.</span>
                    // </div>
                }
                {!quadrantsConfiguration ?
                    <div className="build-banner-text banner-text pi-background-light-red">
                        <i className="warning-banner red fa-lg fas uk-margin-small-right fa-minus-circle" aria-hidden="true"></i>
                        <span className="fs-14">{lang.build_button_disabled.no_quadrants.replace("segments","Segments")}</span>
                    </div>
                : ""
                }
                <div className="toggle-manage-build ">
                    <div className='build-status-title'>
                        <span className="fs-16 uk-text-bold">
                            Build Status
                        </span>
                        <span style={{ marginLeft: 10 }} className="fs-16">
                            <a onClick={() => this.onBuildDetailsClick()} style={{ color: "blue" }}>Details</a>
                        </span>
                    </div>
                      <div className='build-status-body uk-grid uk-child-width-1-1@s fs-14'> 
                        <div className='uk-width-1-3'>
                            <div className='uk-margin-small-bottom'>{lang.manage_build.cost_assignment_label}</div>
                            <div className='uk-margin-small-bottom'>{capitalizeFirstLetter(lang.manage_build.quadrants_label)}</div>
                            <div>{lang.header.titles.scenario}</div>
                        </div>
                        <div className='width-1-2'>
                            <div className='italic uk-text-light uk-margin-small-bottom'> {costAssignmentText.replace("[s]", readyPeriods?.length === 1 ? "" : "s").replace("[are]",  readyPeriods?.length === 1 ? "is" : "are")} </div>
                            <div className='italic uk-text-light uk-margin-small-bottom'> {quadrantsText.replace("[s]", readySegmentsCount === 1 ? "" : "s").replace("[are]",  readySegmentsCount === 1 ? "is" : "are")} </div>
                            <div className='italic uk-text-light capitalize'>{scenarioStatus?scenarioStatus.toLowerCase():""}</div>
                        </div>
                    </div>
                </div>
                <div className="toggle-manage-build">
                    <div className='build-actions-title uk-text-bold'>
                        <span className="fs-16">
                            {SIDE_PANEL_BUILD.TITLES.BUILD_ACTION_TITLE}
                        </span>
                    </div>

                        <div className="margin-toggle info-hover uk-display-flex">
                            {forceAssignCond?
                                <ToggleButton forwardRef={(el) => {
                                    this.toggleRef = el
                                }} title={SIDE_PANEL_BUILD.TOGGLES.FORCE_ASSIGN_COST}
                                              infoTitle={lang.switch_assign_cost}
                                              onCheckboxChange={this.onCheckboxChange}
                                              defaultchecked={this.state.isForceAssign}/>
                                : ""
                            }
                                <span id="select_periods" className={"fs-12 uk-text-decoration-underline uk-display-flex uk-flex-middle " + (forceAssignCond ? "hide-columns-btn" : "hide-columns-btn-force-assign")+(this.state.periodsToBuild && this.state.periodsToBuild.length ===0? " red":"")} onClick={(evt) => { this.showPeriodsComp(evt) }}>{MESSAGES.selectPeriods}</span>

                            {this.state.showPeriodsComp?
                                <div className="hide-columns-dropdown" id="periods_comp_configure_data">
                                    <PeriodsDropDown ref={el=>this.periodRef = el} funkName={this.addPeriodsToBuild} periods={periodsForDropDown}
                                    placeHolderText={MESSAGES.find_period} showText={MESSAGES.select_all} hideText={MESSAGES.unselect_all} 
                                    defaultStatus={true} is_default={""} message={MESSAGES.default_message}
                                    report={ALL_WIDGETS.FORCE_ASSIGN_COST} />
                                </div>
                                :""
                            }

                        </div>

                    <div className="uk-flex uk-flex-right uk-margin-default-top uk-flex-between" style={{alignItems:"baseline"}}>
                    <div className="margin-toggle">
                        <ToggleButton forwardRef={(el) => { this.toggleRef = el }} /*infoTitle={"title:TitleTest2"}*/ title={SIDE_PANEL_BUILD.TOGGLES.GENERATE_QUADRANTS} defaultchecked={this.state.isGenerateSegments} onGenerateQuadrantsOn={this.onGenerateQuadrantsOn} />
                    </div>
                    </div>
                    <div className="margin-toggle">
                    <p className="fs-14 info-hover">{SIDE_PANEL_BUILD.DROPDOWNS.REASON_FOR_BUILD}</p> 
                    <Dropdown
                            id="reason-for-build-dropdown"
                            className="input-width-200 input__dropdown"
                            name="reason-for-build"
                            value={findOptionByKey(MESSAGES.manage_build.reason_for_build_options,this.state.reason_val)}
                            onChange={(e) => this.handleChange(SIDE_PANEL_BUILD.DROPDOWNS.REASON_FOR_BUILD, e)}
                            options={MESSAGES.manage_build.reason_for_build_options}
                            type={DROPDOWN_TYPE.INPUT}
                        />
                    </div>
                </div>
            </div>
        );
    }

    changesNotSavedDialogActions = () => {
      return (
        <>
          <Button 
            id="create-scenario" 
            label={lang.modal.buttons.yes}
            variant={BUTTON_VARIANT.PRIMARY}
            size={SIZES.DEFAULT}
            type={BUTTON_TYPE.DEFAULT}                         
            onBtnClick={this.saveAndChangeTab}
        />
              <Button 
            label={lang.modal.buttons.no}
            variant={BUTTON_VARIANT.SECONDARY}
            size={SIZES.DEFAULT}
            type={BUTTON_TYPE.DEFAULT}
            onBtnClick={this.discardAndChangeTab}
        />
          <Button 
            label={lang.modal.buttons.cancel}
            variant={BUTTON_VARIANT.SECONDARY}
            size={SIZES.DEFAULT}
            type={BUTTON_TYPE.DEFAULT}
            onBtnClick={this.rollBack}
        />
        </>
      )
    }
  monthlyBuildchangesNotSavedDialogActions = () => {
    return (
      <>
        <Button
          id="create-scenario"
          label={lang.modal.buttons.yes}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={this.saveAndOpenModal}
        />
        <Button
          label={lang.modal.buttons.no}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={this.discardAndOpenBuild}
        />
        <Button
          label={lang.modal.buttons.cancel}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => this.setOpenMonthlyBuildChangesNotSavedDialog(false)}
        />
      </>
    )
  }
  resetDialogActions = () => {
    return (
      <>
        <Button
          label={lang.modal.buttons.discard}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={this.resetChanges}
        />
        <Button
          label={lang.modal.buttons.cancel}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => this.setOpenResetDialog(false)}
        />
      </>
    )
  }
  backConfirmationDialogActions = () => {
    return (
      <>
        <Button
          id="create-scenario"
          label={lang.modal.buttons.yes}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => this.startSave()}
        />
        <Button
          label={lang.modal.buttons.no}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={this.resetAndContinue}
        />
        <Button
          label={lang.modal.buttons.cancel}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => this.setOpenBackConfirmationDialog(false)}
        />
      </>
    )
  }

  emptyPeriodsDialogActions = () => {
    return (
      <Button
        label={lang.modal.buttons.ok}
        variant={BUTTON_VARIANT.SECONDARY}
        size={SIZES.DEFAULT}
        type={BUTTON_TYPE.DEFAULT}
        onBtnClick={() => this.setOpenEmptyPeriodsDialog(false)}  
      />
    )
  }

  setOpenChangesNotSavedDialog = (isOpen) => {
    let _this = this;
    _this.setState({
      openChangesNotSavedDialog: isOpen
    })
  }
  setOpenBackConfirmationDialog = (isOpen) => {
    let _this = this;
    _this.setState({
      openBackConfirmationDialog: isOpen
    })
  }

  setOpenMonthlyBuildChangesNotSavedDialog = (isOpen) => {
    let _this = this;
    _this.setState({
      openMonthlyBuildChangesNotSavedDialog: isOpen
    })
  }
  setOpenResetDialog = (isOpen) => {
    let _this = this;
    _this.setState({
      openResetDialog: isOpen
    })
  }
  setOpenEmptyPeriodsDialog = (isOpen) => {
    let _this = this;
    _this.setState({
      openEmptyPeriodsDialog: isOpen
    })
  }
  setOpenValidationDialog = (isOpen, message) => {
    let _this = this;
    _this.setState({
      openValidationDialog: isOpen,
      validation: message
    })
  }
  validationDialogActions = () => {
    return (
      <Button
        id="close-btn"
        label={lang.modal.buttons.ok}
        variant={BUTTON_VARIANT.SECONDARY}
        size={SIZES.DEFAULT}
        type={BUTTON_TYPE.DEFAULT}
        onBtnClick={() => this.setOpenValidationDialog(false)}
      />
    )
  }

  setInfoDialogOpen = (isOpen, msg) => {
    let _this = this;
    _this.setState({
      openInfoDialog: isOpen, 
      infoMsg: msg
    })
  }

  openInfoDialogActions = () => {
    return (
      <Button
        label={lang.modal.buttons.ok}
        variant={BUTTON_VARIANT.PRIMARY}
        size={SIZES.DEFAULT}
        type={BUTTON_TYPE.DEFAULT}
        onBtnClick={() => this.setInfoDialogOpen(false)}
      />
    )
  }

	render() {
        var comp = this;
        if(this.state.report === CONFIGURE_SECTIONS.FIELDS.INTRODUCTION) {
            $("#select-set").removeClass("disabled");
            this.props.setScenarioDisabled(false);
        } else {
            $("#select-set").addClass("disabled");
            this.props.setScenarioDisabled(true);
        }
        this.toggleLoaders();
        let backdrop = "";
        if (this.state.drawerOpen) {
            backdrop = <Backdrop close={this.backdropClickHandler} />;
        }
        var container = $("#periods_comp_configure_data");
        $(document).click(function(e) {
            var container2 = $("#select_periods");
            // if the target of the click isn't the container nor a descendant of the container
            if ((!container.is(e.target) && container.has(e.target).length === 0) && (container2[0] && e.target && container2[0].id !== e.target.id)) {
                container.addClass("uk-hidden");
            }
        });
        let scenarioStatus = findOptionByKey(this.props.scenarioList, Number(this.props.scenarioId))[_scenario_status];
        let vectors= comp.getTabsData("getVectorList");
        let vectorsWithoutGroups = vectors? vectors.filter(e=>e.vector_group === "" || !e.vector_group):"";
        let quadrants = comp.getTabsData("getQuadrantConfiguration");
        let quadrantsConfiguration = quadrants ? quadrants.data.length > 0 : false;
        let periodsAvailable = comp?.state?.buildDetailsData?.finalRes?.filter(e=> parseBoolean(e.is_ready_for_build) && comp?.state?.periodsApplicableForBuild?.includes(e.value))
        let readyPeriods =  periodsAvailable;
    
        let isBuildDisabled;
        const { isForceAssign, reason_val } = this.state;
        if(!readyPeriods?.length || scenarioStatus !== _sandbox || vectorsWithoutGroups.length > 0 || !quadrantsConfiguration ||
            (!isForceAssign && readyPeriods.length === 0) ||
            !reason_val){
            isBuildDisabled = true;
        } else {
            isBuildDisabled = false;
        }
		let buildButtonMessage = scenarioStatus !== _sandbox? lang.build_button_disabled.scenario_not_sandbod:
        (!readyPeriods?.length ? lang.build_button_disabled.file_prerequiste_validation: readyPeriods.length===0 && !comp.state.isForceAssign ?lang.build_button_disabled.already_built:!this.state.reason_val?lang.build_button_disabled.reason_not_selected :"");
        let buttons = [];
        buttons.push({button:lang.modal.buttons.build, isSubmit: true, hoverMessage: buildButtonMessage, isDisabled :isBuildDisabled});
        buttons.push({button:lang.modal.buttons.generate_segments, isSubmit: true, hoverMessage: buildButtonMessage, isDisabled :false, onClick:this.generateSegments});

        return(
            <div>
                <SidePanelNew body={this.body(vectorsWithoutGroups, quadrantsConfiguration)} onSubmit={this.runFullBuild} buttons={buttons} show={this.state.drawerOpen} close={this.backdropClickHandler} addNotify={true} title={SIDE_PANEL_BUILD.TITLES.MANAGE_BUILD_TITLE} columnButtonsDisplayReverse={true} />
                {backdrop}
                <Popup />
                <ConfigureLoader/>
               {showMsg && <CustomizedLoaderWaitingMessage message={lang.configure_vectors_loader_msg}/>}
                <div id="toastConfigureData" className="toast toast-success">
                    <div id="desc"><i className={"fa-lg fas" +(this.state.isError ? " fa-minus-circle uk-text-primary" : " fa-check-circle  uk-margin-small-right greenText" )}aria-hidden="true"></i>{this.state.message}</div>
                </div>
                <div id="toastBuildAlreadyRunningOnScenario" className="toast toast-fail">
                    <div id="desc">
                        <i className={"fa-lg fas uk-margin-small-right fa-minus-circle uk-text-primary"} aria-hidden="true"></i>
                        <span>{lang.build_progress_jobs.build_already_running_on_scenario}</span>
                    </div>
                </div>
                <div className="dm-parent-container configure-page">
                    <div className="uk-margin-default-bottom">
                        <Tabs ref={(el) => (this.configureDataTabsRef = el)} activeTab ={!this.state.tabSelected ? CONFIGURE_SECTIONS.TITLES.CONFIG_COST_FUNCTIONS : this.state.tabSelected} onChange={this.changeTab} tabs={this.state.tabsOptions}/>
                    </div>
                   {![CONFIGURE_SECTIONS.TITLES.CONFIG_QUADRANTS].includes(this.state.tabSelected) ?
                    <div className="configure-pagination">
                        <div className="d-inline-flex">
                            <div id="configure_back">
                            { this.state.tabSelected === CONFIGURE_SECTIONS.TITLES.CONFIG_COST_FUNCTIONS && !this.state.viewMode ?
                                <Button  
                                    id="Configure_Back_Button"
                                    label={lang.navigation.btns.back}
                                    variant={BUTTON_VARIANT.SECONDARY}
                                    size={SIZES.DEFAULT}
                                    type={BUTTON_TYPE.DEFAULT}
                                    onBtnClick={this.showBackConfirmation}
                                />
                                : ""
                            }
                            </div>
                            { this.state.tabSelected === CONFIGURE_SECTIONS.TITLES.CONFIG_COST_FUNCTIONS ? 
                                this.state.expand ?
                                    <Button 
                                        id="Expand_PSS"
                                        label={EXPAND_ALL}
                                        title={EXPAND_ALL}
                                        variant={BUTTON_VARIANT.SECONDARY}
                                        size={SIZES.DEFAULT}
                                        type={BUTTON_TYPE.DEFAULT}
                                        className="uk-margin-medium-left"
                                        leftIcon={<i className={"fal fa-plus-square fa-lg"} />}
                                        onBtnClick={()=>{this.expandAll(false)}}
                                    />
                                    :  
                                    <Button 
                                        id="Expand_PSS"
                                        label={COLLAPSE_ALL}
                                        title={COLLAPSE_ALL}
                                        variant={BUTTON_VARIANT.SECONDARY}
                                        size={SIZES.DEFAULT}
                                        type={BUTTON_TYPE.DEFAULT}
                                        className="uk-margin-medium-left"
                                        leftIcon={<i className={"fal fa-minus-square fa-lg"} />}
                                        onBtnClick={()=>{this.expandAll(true)}}
                                    />
                                : ""
                            }
                        </div>
                        <div className="d-inline-flex">
                             { this.state.viewMode && this.state.tabSelected === CONFIGURE_SECTIONS.TITLES.CONFIG_COST_FUNCTIONS ?
                                <React.Fragment>
                                    <Button 
                                        id="Configure_Configure_Button"
                                        label={lang.navigation.btns.config}
                                        variant={BUTTON_VARIANT.SECONDARY}
                                        size={SIZES.DEFAULT}
                                        type={BUTTON_TYPE.DEFAULT}
                                        onBtnClick={()=> {this.configure(true)}}
                                    />
                                </React.Fragment>
                                : ""
                            }
                            <div id ="configure_next">
                            { ![CONFIGURE_SECTIONS.TITLES.CONFIG_COST_FUNCTIONS].includes(this.state.tabSelected) || (!this.state.viewMode && this.state.tabSelected === CONFIGURE_SECTIONS.TITLES.CONFIG_COST_FUNCTIONS) ?
                                <div className="uk-display-flex">
                                    {((this.state.isChangedVectors /*|| this.state.isReviewOrPublishedEdited*/) && this.state.tabSelected === CONFIGURE_SECTIONS.TITLES.CONFIG_VECTORS) || (this.state.isChangedCostFunction && this.state.tabSelected === CONFIGURE_SECTIONS.TITLES.CONFIG_COST_FUNCTIONS) ?
                                    <div className="uk-margin-small-right uk-margin-xsmall-top">
                                        <span className="fs-12 uk-display-flex uk-flex-middle"><span className="fs-12 red italic">{lang.manage_columns.text.changes_not_saved}</span>{!this.state.thresholdApplied ? <a className={"uk-margin-default-left"} uk-tooltip={ "title:"+lang.discard_unsaved_changes+"; pos: bottom"} onClick= {() => this.setOpenResetDialog(true)}>{lang.modal.buttons.reset}</a> : ""}</span>
                                    </div>
                                    :""}
                                    <div className="" 
                                    uk-tooltip={/*this.props.scenarioStatus !== _sandbox && ![CONFIGURE_SECTIONS.TITLES.CONFIG_VECTORS].includes(this.state.tabSelected)
                                    ? lang.non_editable_scenario
                                    : */(!this.state.isChangedCostFunction && this.state.tabSelected === CONFIGURE_SECTIONS.TITLES.CONFIG_COST_FUNCTIONS) || ((!this.state.isChangedVectors /*&& !this.state.isReviewOrPublishedEdited*/) && this.state.tabSelected === CONFIGURE_SECTIONS.TITLES.CONFIG_VECTORS)|| /*(this.props.scenarioStatus !== _sandbox && ![CONFIGURE_SECTIONS.TITLES.CONFIG_VECTORS].includes(this.state.tabSelected)) ||*/ this.props.scenarioId === undefined 
                                        ? lang.fiscal_calendar.messages.changes_saved : null}>
                                            <Button 
                                                id="Configure_Next_Button" 
                                                label={lang.navigation.btns.save}
                                                variant={BUTTON_VARIANT.PRIMARY}
                                                size={SIZES.DEFAULT}
                                                type={BUTTON_TYPE.DEFAULT}
                                                uk-tooltip={this.props.scenarioId === undefined ? MESSAGES.no_scenarios_available : ""}
                                                onBtnClick={()=>this.startSave()}
                                                disabled={(!this.state.isChangedCostFunction && this.state.tabSelected === CONFIGURE_SECTIONS.TITLES.CONFIG_COST_FUNCTIONS) || ((!this.state.isChangedVectors /*&& !this.state.isReviewOrPublishedEdited*/) && this.state.tabSelected === CONFIGURE_SECTIONS.TITLES.CONFIG_VECTORS) /*|| (this.props.scenarioStatus !== _sandbox && ![CONFIGURE_SECTIONS.TITLES.CONFIG_VECTORS].includes(this.state.tabSelected))*/ || this.props.scenarioId === undefined}
                                            />
                                    </div>
                                </div>
                                :""
                            }
                            </div> 
                          
                        </div>
                    </div> : ""}
                    
                    {comp.props && comp.props.selectedPeriod ? 
                        <div className={this.state.tabSelected === CONFIGURE_SECTIONS.TITLES.CONFIG_VECTORS ? "" : "uk-hidden"}>
                           
                            <ConfigureVector ref={el => this.vectorsRef = el} showSavePopUp={this.showSavePopUp} onPropertyChange={this.saveVectors} 
                                scenarioId={this.props.scenarioId} user={this.props.user} timePeriod={this.state.timePeriod} version={this.state.version}
                                modelId={this.state.modelId} tableName={this.state.tableName} modelDataSet={this.state.modelDataSet}
                                goToBuild={this.goToBuild} scenarioStatus={this.props.scenarioStatus} setIsChanged={this.setIsChanged} getNextTab={this.getNextTab}
                                changeTab={this.changeTab} getTabsData={this.getTabsData} setTabsData={this.setTabsData} resetTabsData={this.resetTabsData} launchToast={this.launchToast}
                                resetMultipleRequestsData={this.resetMultipleRequestsData} periods={this.props.periods} userAllowedSections={this.props.userAllowedSections} isChanged = {this.state.isChangedVectors} setValidation={this.setValidation}
                                drawerToggleClickHandler={this.drawerToggleClickHandler} dispatch ={this.props.dispatch} generateSegmentVectorsLimit={this.props.generateSegmentVectorsLimit} /> 
                        </div>
                    : ""}
                    {comp.props && comp.props.selectedPeriod ? 
                        <div className={this.state.tabSelected === CONFIGURE_SECTIONS.TITLES.CONFIG_COST_FUNCTIONS ? "" : "uk-hidden"}>
                             {/* {!loaderConfigureCostFunctionCondition  && this.state.tabSelected === CONFIGURE_SECTIONS.TITLES.CONFIG_COST_FUNCTIONS ?<Loader fullContainer={false}/> : */}
                                <ConfigureCostFunctions id="configCostFunction" ref={el => this.childRef = el} showSavePopUp={this.showSavePopUp}
                                    troubleshootCostFunction={this.troubleshootCostFunction} user={comp.props.user} projectId={this.props.project_id} 
                                    scenarioId={this.props.scenarioId} clientId={this.props.clientId} clientName={this.props.clientName} costCenter={this.props.costCenter}
                                    tablePrefix={this.props.tablePrefix} goToBuild={this.goToBuild} selectedPeriod={this.props.selectedPeriod} 
                                    scenarioStatus={this.props.scenarioStatus} viewMode={this.state.viewMode} launchToast={this.launchToast} periods={this.props.periods} changed={false} 
                                    setIsChanged={this.setIsChanged} getNextTab={this.getNextTab} changeTab={this.changeTab} getTabsData={this.getTabsData} 
                                    setTabsData={this.setTabsData} resetTabsData={this.resetTabsData}  resetMultipleRequestsData={this.resetMultipleRequestsData}
                                    configure={this.configure} drawerToggleClickHandler={this.drawerToggleClickHandler} startChangeScenario={this.props.startChangeScenario} enableManageBuilds = {this.enableManageBuilds}/>
                        </div>
                    : ""}
                    {/* {this.state.tabSelected === CONFIGURE_SECTIONS.TITLES.CONFIG_QUADRANTS ?  */}
                        <div className={"configureQuadrantsContainer "+(this.state.tabSelected === CONFIGURE_SECTIONS.TITLES.CONFIG_QUADRANTS ? "":"uk-hidden") }>  
                            <div className="configureQuadrantsBorder">
                                <div className="uk-display-flex saveConfigureQuadrants">
                                        {this.state.isChangedQuadrants && this.state.tabSelected === CONFIGURE_SECTIONS.TITLES.CONFIG_QUADRANTS ?
                                        <div className="uk-margin-small-right uk-margin-xsmall-top">
                                            <span className="fs-12 uk-display-flex uk-flex-middle"><span className="fs-12 red italic">{lang.manage_columns.text.changes_not_saved}</span><a className={"uk-margin-default-left"} uk-tooltip={ "title:"+lang.discard_unsaved_changes+"; pos: bottom"} onClick= { () => this.setOpenResetDialog(true)}>{lang.modal.buttons.reset}</a></span>
                                        </div>
                                        :""}
                                        <div className="" uk-tooltip={/*this.props.scenarioStatus !== _sandbox? lang.non_editable_scenario:*/ !this.state.profitValue || !this.state.revenueValue ? lang.fill_all_period_fields : !this.state.isChangedQuadrants ? lang.fiscal_calendar.messages.changes_saved : null}>
                                            <Button 
                                                id="Configure_Next_Button" 
                                                label={lang.navigation.btns.save}
                                                variant={BUTTON_VARIANT.PRIMARY}
                                                size={SIZES.DEFAULT}
                                                type={BUTTON_TYPE.DEFAULT}
                                                uk-tooltip={this.props.scenarioId === undefined ? MESSAGES.no_scenarios_available : ""}
                                                onBtnClick={()=>this.startSave()}
                                                disabled={!this.state.isChangedQuadrants || !this.state.profitValue || !this.state.revenueValue /*|| this.props.scenarioStatus !== _sandbox*/ || this.props.scenarioId === undefined}
                                            />
                                        </div>
                                </div>
                                <ConfigureQuadrants ref={el => this.quadrantsRef = el} showSavePopUp={this.showSavePopUp} goToBuild={this.goToBuild}
                                scenarioId={this.props.scenarioId} scenarioStatus={this.props.scenarioStatus} setIsChanged={this.setIsChanged} getNextTab={this.getNextTab} 
                                changeTab={this.changeTab} getTabsData={this.getTabsData} setTabsData={this.setTabsData} resetTabsData={this.resetTabsData}
                                resetMultipleRequestsData={this.resetMultipleRequestsData} setValidation={this.setValidation} launchToast={this.launchToast} drawerToggleClickHandler={this.drawerToggleClickHandler}/>
                            </div>
                        </div>
                    {/* : ""} */}
                </div>
                <Modal
                 id={"change-tab-changes-not-saved-dialog"}
                 openDialog={this.state.openChangesNotSavedDialog}
                 bodyContent={() => <h4>{lang.dashboards.messages.want_to_save_first}</h4>}
                 dialogActions={this.changesNotSavedDialogActions}
                 closeClick={() => this.setOpenChangesNotSavedDialog(false) }     
                 size={DIALOG_SIZE.MEDIUM}
                />
                <Modal
                 id={"monthly-build-changes-not-saved-dialog"}
                 openDialog={this.state.openMonthlyBuildChangesNotSavedDialog}
                 bodyContent={() => <h4>{lang.dashboards.messages.want_to_save_first}</h4>}
                 dialogActions={this.monthlyBuildchangesNotSavedDialogActions}
                 closeClick={() => this.setOpenMonthlyBuildChangesNotSavedDialog(false)}     
                 size={DIALOG_SIZE.MEDIUM}
                />
                <Modal
                 id={"reset-dialog"}
                 openDialog={this.state.openResetDialog}
                 bodyContent={() => <h4>{lang.discard_unsaved_changes}</h4>}
                 dialogActions={this.resetDialogActions}
                 closeClick={() => this.setOpenResetDialog(false)}     
                 size={DIALOG_SIZE.MEDIUM}
                />
                <Modal
                 id={"back-confirmation-dialog"}
                 openDialog={this.state.openBackConfirmationDialog}
                 bodyContent={() => <h4>{lang.dashboards.messages.want_to_save_first}</h4>}
                 dialogActions={this.backConfirmationDialogActions}
                 closeClick={() => this.setOpenBackConfirmationDialog(false)}     
                 size={DIALOG_SIZE.MEDIUM}
                />
                <Modal
                 id={"empty-periods-dialog"}
                 openDialog={this.state.openEmptyPeriodsDialog}
                 bodyContent={() => <h4>{lang.dashboards.messages.select_one_period}</h4>}
                 dialogActions={this.emptyPeriodsDialogActions}
                 closeClick={() => this.setOpenEmptyPeriodsDialog(false)}     
                 size={DIALOG_SIZE.MEDIUM}
                />
                <Modal
                 id={"validation-dialog"}
                 openDialog={this.state.openValidationDialog}
                 bodyContent={() => <h4>{this.state.validation}</h4>}
                 dialogActions={this.validationDialogActions}
                 closeClick={() => this.setOpenValidationDialog(false)}     
                 size={DIALOG_SIZE.MEDIUM}
                />
                <Modal
                  id={"info-dialog"}
                  openDialog={this.state.openInfoDialog}
                  bodyContent={() => <h4>{this.state.infoMsg}</h4>}
                  dialogActions={this.openInfoDialogActions}
                  closeClick={() => this.setInfoDialogOpen(false)}
                  size={DIALOG_SIZE.MEDIUM}
                />
          </div>
                    
		);
	}

}

export default ConfigureData;