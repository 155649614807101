// import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import React, { useState } from "react";
import { useEffect } from "react";
import { ReactComponent as CloseIcon } from "../styles/images/icons/close.svg";
import { DialogActions, DialogContent } from "@mui/material";
import Button from "./Button";
import {
  BUTTON_TYPE,
  BUTTON_VARIANT,
  DIALOG_TRANSITION,
  SIZES,
} from "../class/constants";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const TransitionBottom = React.forwardRef(function TransitionBottom(
  props,
  ref
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const Modal = ({
  title,
  openDialog=false,
  bodyContent,
  dialogActions,
  closeClick,
  tooltipText,
  id,
  size,
  transition = DIALOG_TRANSITION.TOP,
  additionalBodyClassName = "",
  removeClose = false,
  center = false
}) => {
  const [open, setOpen] = useState(openDialog);
  const dialogClass = `dialog dialog-${size}`;
  const transitionComp = transition === DIALOG_TRANSITION.TOP ? Transition : TransitionBottom;

  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  const handleClose = () => {
    setOpen(false); 
  }

  return (
    <Dialog
      id={id}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={transition === DIALOG_TRANSITION.BOTTOM ? "marginBottom-session-dialog" : "marginBottom"}
      TransitionComponent={transitionComp}
      TransitionProps={{ exit: false }}
      PaperProps={{ className: dialogClass, sx: {overflowY: "unset"} }}
      disableEnforceFocus={true}
      sx={{overflowY: "unset", zIndex: 99999, top: center ? "30%" : ""}}
    >
      {!removeClose && (
          <div style={{ display: "flex", alignSelf: "flex-end" }}>
            <Button
              variant={BUTTON_VARIANT.TERTIARY}
              size={SIZES.ICON}
              type={BUTTON_TYPE.DEFAULT}
              leftIcon={<CloseIcon />}
              aria-label="close"
              onBtnClick={closeClick || handleClose}
            />
          </div>
      )}
      {title && (
        <DialogTitle id="alert-dialog-title" sx={{ p: 0 }}>
          <span className={"dialog-title"}>
            {title}
            {tooltipText && (
              <i
                className="fal fa-info-circle fs-12 uk-margin-small-left uk-cursor-pointer"
                uk-tooltip={"title:" + tooltipText}
              />
            )}
          </span>
        </DialogTitle>
      )}
      <div className={"dialog-body " + additionalBodyClassName}>
        <DialogContent sx={{ p: 0, overflowY: "unset" }}>{bodyContent()}</DialogContent>
      </div>
      <DialogActions className="dialog-buttons" sx={{ p: 0 }}>
        {dialogActions()}
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
