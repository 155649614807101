import { getLocalStorageValueByParameter } from "../class/common";
import { SEGMENTS, SEGMENTS_TIERS, CLIENT_PROFIT_SEGMENT_DICTIONARY } from "../class/constants";
import { tryParse } from "../class/utils";
export class Segment {
  constructor() {
    this.value = "";
  }

  setValue(value) {
    this.value = value;
  }

  getValue(value) {
    this.value = value;
  }

  getSegmentObject(value) {
    var clientProfitSegmentDictionary = tryParse(getLocalStorageValueByParameter(CLIENT_PROFIT_SEGMENT_DICTIONARY));
    switch (value?.toString()?.toLowerCase()?.trim()) {
      case SEGMENTS.PEAK.label.toLowerCase():
      case SEGMENTS.PEAK.value:
      case SEGMENTS.PEAK.abv:
      case SEGMENTS.PEAK.abv.toLowerCase():
        return {
          color: "rgba(33, 161, 12, 1)",
          label: clientProfitSegmentDictionary[SEGMENTS.PEAK.label],
          originalLabel: SEGMENTS.PEAK.label,
          value: SEGMENTS.PEAK.value,
          name: SEGMENTS.PEAK.label,
          bubbleColor: "rgba(33, 161, 12, 1)",
          textColor: "white",
          legendColor: "#5DC6F3",
          trendColor:"rgba(33, 161, 12, 1)"
        };

      case SEGMENTS.DRAIN.value:
      case SEGMENTS.DRAIN.label.toLowerCase():
      case SEGMENTS.DRAIN.abv:
      case SEGMENTS.DRAIN.abv.toLowerCase():
        return {
          color: "rgba(206, 16, 16, 1)",
          label:clientProfitSegmentDictionary[SEGMENTS.DRAIN.label],
          value: SEGMENTS.DRAIN.value,
          originalLabel: SEGMENTS.DRAIN.label,
          name: SEGMENTS.DRAIN.label,
          bubbleColor: "rgba(206, 16, 16, 1)",
          textColor: "white",
          legendColor: "#030451",
          trendColor:"rgba(206, 16, 16, 1)"
        };

      case SEGMENTS.FLAT.label.toLowerCase():
      case SEGMENTS.FLAT.value:
      case SEGMENTS.FLAT.abv:
      case SEGMENTS.FLAT.abv.toLowerCase():
        return {
          color: "rgba(255, 169, 57, 1)",
          label: clientProfitSegmentDictionary[SEGMENTS.FLAT.label],
          value: SEGMENTS.FLAT.value,
          name: SEGMENTS.FLAT.label,
          originalLabel: SEGMENTS.FLAT.label,
          bubbleColor: "rgba(255, 169, 57, 1)",
          textColor: "white",
          legendColor: "#5F60C8",
          trendColor:"rgba(255, 169, 57, 1)"
        };

      case SEGMENTS_TIERS.PEAK_1.label.toLowerCase():
      case SEGMENTS_TIERS.PEAK_1.value:
        return {
          color: "rgba(14, 101, 0, 1)",
          label: SEGMENTS_TIERS.PEAK_1.label.replace(SEGMENTS.PEAK.label, clientProfitSegmentDictionary[SEGMENTS.PEAK.label]),
          value: SEGMENTS_TIERS.PEAK_1.value,
          name: SEGMENTS_TIERS.PEAK_1.label,
          originalLabel: SEGMENTS_TIERS.PEAK_1.label,
          bubbleColor: "rgba(14, 101, 0, 1)",
          textColor: "white",
        };

      case SEGMENTS_TIERS.PEAK_2.label.toLowerCase():
      case SEGMENTS_TIERS.PEAK_2.value:
        return {
          color: "rgba(33, 161, 12, 1)",
          label: SEGMENTS_TIERS.PEAK_2.label.replace(SEGMENTS.PEAK.label, clientProfitSegmentDictionary[SEGMENTS.PEAK.label]),
          value: SEGMENTS_TIERS.PEAK_2.value,
          name: SEGMENTS_TIERS.PEAK_2.label,
          originalLabel: SEGMENTS_TIERS.PEAK_2.label,
          bubbleColor: "rgba(33, 161, 12, 1)",
          textColor: "white",
        };

      case SEGMENTS_TIERS.PEAK_3.label.toLowerCase():
      case SEGMENTS_TIERS.PEAK_3.value:
        return {
          color: "rgba(108, 217, 91, 1)",
          label: SEGMENTS_TIERS.PEAK_3.label.replace(SEGMENTS.PEAK.label, clientProfitSegmentDictionary[SEGMENTS.PEAK.label]),
          value: SEGMENTS_TIERS.PEAK_3.value,
          name: SEGMENTS_TIERS.PEAK_3.label,
          originalLabel: SEGMENTS_TIERS.PEAK_3.label,
          bubbleColor: "rgba(108, 217, 91, 1)",
          textColor: "white",
        };

      case SEGMENTS_TIERS.PEAK_4.label.toLowerCase():
      case SEGMENTS_TIERS.PEAK_4.value:
        return {
          color: "rgba(135, 241, 118, 1)",
          label: SEGMENTS_TIERS.PEAK_4.label.replace(SEGMENTS.PEAK.label, clientProfitSegmentDictionary[SEGMENTS.PEAK.label]),
          value: SEGMENTS_TIERS.PEAK_4.value,
          name: SEGMENTS_TIERS.PEAK_4.label,
          originalLabel: SEGMENTS_TIERS.PEAK_4.label,
          bubbleColor: "rgba(135, 241, 118, 1)",
          textColor: "white",
        };

      case SEGMENTS_TIERS.DRAIN_1.label.toLowerCase():
      case SEGMENTS_TIERS.DRAIN_1.value:
        return {
          color: "rgba(165, 0, 0, 1)",
          label: SEGMENTS_TIERS.DRAIN_1.label.replace(SEGMENTS.DRAIN.label, clientProfitSegmentDictionary[SEGMENTS.DRAIN.label]),
          value: SEGMENTS_TIERS.DRAIN_1.value,
          name: SEGMENTS_TIERS.DRAIN_1.label,
          originalLabel: SEGMENTS_TIERS.DRAIN_1.label,
          bubbleColor: "rgba(165, 0, 0, 1)",
          textColor: "white",
        };

      case SEGMENTS_TIERS.DRAIN_2.label.toLowerCase():
      case SEGMENTS_TIERS.DRAIN_2.value:
        return {
          color: "rgba(206, 16, 16, 1)",
          label: SEGMENTS_TIERS.DRAIN_2.label.replace(SEGMENTS.DRAIN.label, clientProfitSegmentDictionary[SEGMENTS.DRAIN.label]),
          value: SEGMENTS_TIERS.DRAIN_2.value,
          name: SEGMENTS_TIERS.DRAIN_2.label,
          originalLabel: SEGMENTS_TIERS.DRAIN_2.label,
          bubbleColor: "rgba(206, 16, 16, 1)",
          textColor: "white",
        };

      case SEGMENTS_TIERS.DRAIN_3.label.toLowerCase():
      case SEGMENTS_TIERS.DRAIN_3.value:
        return {
          color: "rgba(255, 88, 88, 1)",
          label: SEGMENTS_TIERS.DRAIN_3.label.replace(SEGMENTS.DRAIN.label, clientProfitSegmentDictionary[SEGMENTS.DRAIN.label]),
          value: SEGMENTS_TIERS.DRAIN_3.value,
          name: SEGMENTS_TIERS.DRAIN_3.label,
          originalLabel: SEGMENTS_TIERS.DRAIN_3.label,
          bubbleColor: "rgba(255, 88, 88, 1)",
          textColor: "white",
        };

      case SEGMENTS_TIERS.DRAIN_4.label.toLowerCase():
      case SEGMENTS_TIERS.DRAIN_4.value:
        return {
          color: "rgba(255, 152, 152, 1)",
          label: SEGMENTS_TIERS.DRAIN_4.label.replace(SEGMENTS.DRAIN.label, clientProfitSegmentDictionary[SEGMENTS.DRAIN.label]),
          value: SEGMENTS_TIERS.DRAIN_4.value,
          name: SEGMENTS_TIERS.DRAIN_4.label,
          originalLabel: SEGMENTS_TIERS.DRAIN_4.label,
          bubbleColor: "rgba(255, 152, 152, 1)",
          textColor: "white",
        };

      case SEGMENTS_TIERS.FLAT_1.label.toLowerCase():
      case SEGMENTS_TIERS.FLAT_1.value:
        return {
          color: "rgba(233, 132, 0, 1)",
          label: SEGMENTS_TIERS.FLAT_1.label.replace(SEGMENTS.FLAT.label, clientProfitSegmentDictionary[SEGMENTS.FLAT.label]),
          value: SEGMENTS_TIERS.FLAT_1.value,
          name: SEGMENTS_TIERS.FLAT_1.label,
          originalLabel: SEGMENTS_TIERS.FLAT_1.label,
          bubbleColor: "rgba(233, 132, 0, 1)",
          textColor: "white",
        };

      case SEGMENTS_TIERS.FLAT_2.label.toLowerCase():
      case SEGMENTS_TIERS.FLAT_2.value:
        return {
          color: "rgba(255, 169, 57, 1)",
          label: SEGMENTS_TIERS.FLAT_2.label.replace(SEGMENTS.FLAT.label, clientProfitSegmentDictionary[SEGMENTS.FLAT.label]),
          value: SEGMENTS_TIERS.FLAT_2.value,
          name: SEGMENTS_TIERS.FLAT_2.label,
          originalLabel: SEGMENTS_TIERS.FLAT_2.label,
          bubbleColor: "rgba(255, 169, 57, 1)",
          textColor: "white",
        };

      case SEGMENTS_TIERS.FLAT_3.label.toLowerCase():
      case SEGMENTS_TIERS.FLAT_3.value:
        return {
          color: "rgba(255, 201, 130, 1)",
          label: SEGMENTS_TIERS.FLAT_3.label.replace(SEGMENTS.FLAT.label, clientProfitSegmentDictionary[SEGMENTS.FLAT.label]),
          value: SEGMENTS_TIERS.FLAT_3.value,
          name: SEGMENTS_TIERS.FLAT_3.label,
          originalLabel: SEGMENTS_TIERS.FLAT_3.label,
          bubbleColor: "rgba(255, 201, 130, 1)",
          textColor: "white",
        };

      case SEGMENTS_TIERS.FLAT_4.label.toLowerCase():
      case SEGMENTS_TIERS.FLAT_4.value:
        return {
          color: "rgba(255, 224, 183, 1)",
          label: SEGMENTS_TIERS.FLAT_4.label.replace(SEGMENTS.FLAT.label, clientProfitSegmentDictionary[SEGMENTS.FLAT.label]),
          value: SEGMENTS_TIERS.FLAT_4.value,
          name: SEGMENTS_TIERS.FLAT_4.label,
          originalLabel: SEGMENTS_TIERS.FLAT_4.label,
          bubbleColor: "rgba(255, 224, 183, 1)",
          textColor: "white",
        };
      case "n/a":
        return {
          color: "#cdd1d0",
          label: "N/A",
          isHidden: true,
          value: "n/a",
          name: "N/A",
          bubbleColor: "",
          textColor: "black",
        };
      default:
        return {
          color: "#fff",
          label: undefined,
          value: undefined,
          name: undefined,
          bubbleColor: "",
          textColor: "black",
        };
    }
  }
}
