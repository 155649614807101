import React, { Component } from "react";
import DateRange from "./DateRange";
import TileYear from "./TileYear";
import { getActualYear } from "../../class/date";
import { between } from "../../class/common";
import { convertPxToViewport } from "../../class/formatting";
import { PERIOD } from "../../class/constants";

class TileCalendarContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      years: props.newHeader ? props.years : this.props.years(),
      nextStartTile: this.props.nextStartTile
        ? [this.props.nextStartTile]
        : undefined,
      nextEndTile: this.props.nextEndTile
        ? [this.props.nextEndTile]
        : undefined,
      leftTiles: [],
      rightTiles: [],
      count: 1
    };

    this.splitValue = props.type === PERIOD.QUARTER ? "Q" : "P";
  }

  /**
   * Initializing the selected tiles and the disabled tiles
   */
  componentDidMount() {
    let years = this.state.years;
    let nextStartTile = this.state.nextStartTile;
    let nextEndTile = this.state.nextEndTile;
    let startYear = this.getStartYear()
    let endYear = startYear + 1;

    let firstSelection = this.createSelectionObject(nextStartTile);
    let lastSelection = this.createSelectionObject(nextEndTile);

    this.updateCurrentSelectedYears(startYear, endYear);
    let selectedTiles = this.updateSelections(
      years,
      firstSelection,
      lastSelection
    );
    let finalTiles = this.initDisableTiles(selectedTiles);

    this.setState({ years: finalTiles });
  }


  // Function to create a selection object
  createSelectionObject = (tile) => {
    let selection = {};
    selection.year = tile ? parseInt(tile[0].substring(0, 4)) : getActualYear(new Date());
    selection.value = tile ? tile[0].substring(4) : "";
    selection.isSelected = !!tile;
    selection.isDisabled = !!tile;
    return selection;
  };

  /**
   * Function that returns the start year of the current selected tile
   * @returns {*}
   */
  getStartYear = () => {
    let nextStartTile = this.state.nextStartTile;
    let startYear = nextStartTile
      ? Number(nextStartTile[0].split(this.splitValue)[0])
      : getActualYear(new Date());

    startYear = this.props.isYearsLimited && startYear > this.props.minYear ? startYear - 1 : startYear
    return startYear;
  };

  /**
   * Function that updates the left, right tiles according to the selected year
   * @param startYear
   * @param endYear
   */
  updateCurrentSelectedYears = (startYear, endYear) => {
    let years = this.state.years;

    let leftTiles = years.filter((y) => y.year === startYear);
    let rightTiles = years.filter((y) => y.year === endYear);

    this.setState({
      leftTiles: leftTiles,
      rightTiles: rightTiles,
    });
  };

  /**
   * Called in Tile component, handles the selection of a tile
   * If selection is already made, the selection is cleared
   * @param tile
   */
  handleSelect = (tile, e) => {
    let years = this.state.years;
    let selectedyears;
    let clickCount = this.state.count;

    if (typeof this.props.onSelectCallback === "function") {
      if (clickCount === 2) {
        years.filter(f => f.value === tile.value && tile.year === f.year).map(m => m.isSelected = true);
        selectedyears = years.filter((year) => year.isSelected);

        if (selectedyears.length === 2) {
          let firstSelection = selectedyears[0];
          let lastSelection = selectedyears[1];
          years = this.updateSelections(years, firstSelection, lastSelection);
          years = this.initDisableTiles(years);
        }
        
        this.updateTilesInHeader(years);
        this.setState({ years: years, count: 1 }, () => this.props.onSelectCallback());
        return; 
      } 
      this.setState({ count: clickCount + e.detail });
      for (let year of years) { year.isSelected = false; } //deselect all
      years.filter(f => f.value === tile.value && tile.year === f.year).map(m => m.isSelected = true);
      
    } else {
      selectedyears = years.filter((year) => year.isSelected);
      if (selectedyears.length >= 2) {
        for (let year of years) { year.isSelected = false; }
      }

      years.forEach((year) => {
        if (year.year === tile.year && year.value === tile.value) {
          year.isSelected = !year.isSelected;
        }
      });
      selectedyears = years.filter((year) => year.isSelected);

      if (selectedyears.length === 2) {
        let firstSelection = selectedyears[0];
        let lastSelection = selectedyears[1];
        years = this.updateSelections(years, firstSelection, lastSelection);
        years = this.initDisableTiles(years);
      }
    }

    this.updateTilesInHeader(years);
    this.setState({ years: years });
  };

  /**
   * Function that updates the tiles in between first and last selection
   * @param years
   * @param firstSelection
   * @param lastSelection
   * @returns {*}
   */
  updateSelections = (years, firstSelection, lastSelection) => {
    for (let year of years) {
      if (between(year.year, firstSelection.year, lastSelection.year)) {
        if (firstSelection.year === lastSelection.year) {
          if (between(year.value, firstSelection.value, lastSelection.value)) {
            year.isSelected = true;
          }
        } else if (year.year === firstSelection.year) {
          if (year.value >= firstSelection.value) {
            year.isSelected = true;
          }
        } else if (year.year === lastSelection.year) {
          if (year.value <= lastSelection.value) {
            year.isSelected = true;
          }
        } else {
          year.isSelected = true;
        }
      } else {
        year.isSelected = false;
      }
    }
    return years;
  };

  /**
   * Function that calls handleElementChange in header to update first and last tile
   * @param years
   */
  updateTilesInHeader = (years) => {
    this.props.handleElementChange(years);
  };

  /**
   * Function that disable quarters if 4 quarters are selected
   * *Not used*
   * @param years
   * @returns {*}
   */
  disableQuarters = (years) => {
    let selectedyears = years.filter((quarterYear) => quarterYear.isSelected);

    if (selectedyears.length >= 4) {
      for (let quarterYear of years) {
        if (!quarterYear.isSelected) {
          if (
            this.props.isTileDisabled(quarterYear.year + quarterYear.quarter, this.props.periodsToCheck, this.props.checkSegmentGeneration, this.props.typeOfCheck)
          ) {
            quarterYear.isDisabled = true;
          }
        }
      }
    } else {
      for (let quarterYear of years) {
        quarterYear.isDisabled = false;
      }
    }

    years = this.initDisableTiles(years);
    return years;
  };

  /**
   * Function that initializes the not generated tiles
   * @param years
   * @returns {*}
   */
  initDisableTiles = (years) => {
    for (let year of years) {
      if (this.props.isTileDisabled(year.year, year.value, this.props.periodsToCheck,this.props.checkSegmentGeneration, this.props.typeOfCheck)) {
        year.isDisabled = true;
      }
    }
    if(typeof this.props.updateTiles === 'function') {
      years = this.props.updateTiles(years, this.props?.periodsToCheck, this.props?.checkSegmentGeneration);
    }
    return years;
  };

  render() {
    return (
      <table id="period_range_table" className="calendar-table" style={{ display: this.props.newHeader ? "none" : "table" }}>
        <DateRange
          getStartYear={this.getStartYear}
          updateCurrentSelectedYears={this.updateCurrentSelectedYears}
          minYear={this.props.minYear}
          maxYear={this.props.maxYear}
        />
        <thead style={{ display: "inline-flex" }}>
          <TileYear
            tiles={this.state.leftTiles}
            handleSelect={this.handleSelect}
            tileContent={this.props.tileContent}
            columnNumber={this.props.columnNumber}
          />
          <span style={{ color: "white", width: convertPxToViewport(10) }} />
          <TileYear
            tiles={this.state.rightTiles}
            handleSelect={this.handleSelect}
            tileContent={this.props.tileContent}
            columnNumber={this.props.columnNumber}
          />
        </thead>
      </table>
    );
  }
}

export default TileCalendarContainer;
