export function updateQTs(quadrantTierOptions) {
    return {
      type: 'UPDATE_QTS',
      quadrantTierOptions:quadrantTierOptions
    }
}

export function getQTs() {
    return {
      type: 'GET_QTS'
    }
}

export function updatePsLinesOptions(psLinesOptions, scenarioId, clear) {
    return {
      type: 'UPDATE_PSLLINES_OPTIONS',
      psLinesOptions:psLinesOptions,
      scenarioId: scenarioId,
      clear: clear
    }
}

export function getPsLinesOptions() {
    return {
      type: 'GET_PSLLINES_OPTIONS'
    }
}