import { createTheme } from "@mui/material";
import * as React from "react";
import { lang } from "../../../language/messages_en";
import SwitchToggle from "../../../newComponents/SwitchToggle";

export default function NewToggleButton(props) {
    const theme = createTheme({
        components: {
            MuiTypography: {
                defaultProps:{classes:"close-dropdown"},
                styleOverrides: {
                    root: {
                        fontSize: "0.55vw",
                        pointerEvents: "none"
                    }
                },
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    root: {
                        marginRight: 0,
                        marginLeft: "11px",
                        height: "15px"
                    }
                }
            },
            MuiSwitch: {
                defaultProps:{className:"close-dropdown"},
                styleOverrides: {
                    root: {
                        display: "-webkit-inline-flex",
                        // marginLeft: "0.95vw"

                    },
                    switchBase: {
                        // Controls default (unchecked) color for the thumb
                        color: "#ffffff"
                    },
                    colorPrimary: {
                        "&.Mui-checked": {
                            // Controls checked color for the thumb
                            color: "#ffffff",
                        }
                    },
                    thumb: {
                        width: "15px",
                        height: "15px",
                        marginTop: "0.14vw",
                        borderRadius: "50%",
                        marginLeft: "3px",
                    },
                    track: {
                        // Controls default (unchecked) color for the track
                        opacity: 1,
                        backgroundColor: "#bbbbbb",
                        ".Mui-checked.Mui-checked + &": {
                            // Controls checked color for the track
                            opacity: 1,
                            backgroundColor: "#0f61fc"
                        }
                    }
                }
            },
            MuiTooltip: {
                defaultProps:{className:"close-dropdown"},
                styleOverrides: {
                    tooltip: {
                        fontSize: "0.5vw",
                        top: "-0.4vw",
                    }
                }
            }
        }
    });

    const changeToggle=()=>{
        props.setGroupByValue(!props.value, props.isRow);
    }

    return (
      <div className="close-dropdown" style={{ display: "flex", alignItems: "center", columnGap: "0.5vw", paddingRight: "1.5vw" }}>
        <SwitchToggle
          id={props.id}
          addedClassName="close-dropdown"
          isDisabled={props.disableToggle}
          checked={props.value}
          value={props.value}
          tooltip={"title:" + props.title}
          onChange={() => {
            changeToggle();
          }}
        />
        <span className="close-dropdown" style={{ fontFamily: "Lato", lineHeight: 1.5, letterSpacing: "0.00938em", fontSize: "0.55vw" }}>
          {lang.toggle_label_entity}
        </span>
      </div>
    );
}
