import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import Input from "../../newComponents/Input";
import Container from "../manageColumns/Container";
import {copyObjectValues, pushArrayIndicesToRemove, removeByIndicesFromArray, tryParse} from "../../class/utils";
import {FETCH_METHOD, fetchAPI, FETCHAPI_PARAMS} from "../../class/networkUtils";
import {SUPPLY_CHAIN_SETUP} from "../../class/constants";
import {linearizeHierarchy} from "../../class/array";
import "./bucketConfiguration.css";
import { lang } from "../../language/messages_en";
import { isStringSurpassingXCharacters } from "../../class/string";
import { getOutputScreenName } from "../../class/common";

const BucketsConfiguration = (props, ref) => {
    useImperativeHandle(ref, () => ({
        saveBucketConf: (callback) => {
            saveBucketConfiguration(callback);
        },
    }));

    const [bucketConfiguration, setBucketConfiguration] = useState(props.bucketsConfiguration?.buckets);
    const [originalBucketConfiguration, setOriginalBucketConfiguration] = useState(props.bucketsConfiguration?.buckets);
    
    const [calculatedBucketConfiguration, setCalculatedBucketConfiguration] = useState(props.bucketsConfiguration?.calculatedBuckets);
    const [originaCalculatedBucketConfiguration, setOriginalCalculatedBucketConfiguration] = useState(props.bucketsConfiguration?.calculatedBuckets);
    let optionsSelected = [];

    const [errorMessage, setErrorMessage] = useState({});
    
    useEffect(() => {
      let hasCalculatedBucketsChanged = JSON.stringify(calculatedBucketConfiguration) !== JSON.stringify(originaCalculatedBucketConfiguration);
      let hasBucketsChanged = JSON.stringify(bucketConfiguration) !== JSON.stringify(originalBucketConfiguration);

      if (hasBucketsChanged || hasCalculatedBucketsChanged) {
          props.setHasUnsavedChanges(true);
      }
    }, [bucketConfiguration, originalBucketConfiguration, originaCalculatedBucketConfiguration, calculatedBucketConfiguration]);

    /**
     * this useEffect is used if the dropdown is opened and we click on save
     */
    useEffect(() => {
        let hasBucketsChanged = JSON.stringify(bucketConfiguration) !== JSON.stringify(originalBucketConfiguration);
        if (props.fromSaveButton && hasBucketsChanged) {
            saveBucketConfiguration();
        }
    }, [props.fromSaveButton, bucketConfiguration]);

    useEffect(() => {
        checkIfPSLineExists()
    }, [props.psLines]);

    const checkIfPSLineExists = () => {
        let psLines = linearizeHierarchy(props.psLines);
        let linearizedPslines = psLines.map((m) => {
            return m.value
        })
        let bucketProfitStackLines = originalBucketConfiguration?.map((m) => {
            return m.profit_stack_lines
        })
        let bucketProfitStackLinesString = String(bucketProfitStackLines).split(',').filter(value => value !== '')
        for (let e of bucketProfitStackLinesString) {
            if (!linearizedPslines.includes(e)) {
                props.setHasUnsavedChanges(true);
            }
        }
    }

    const updatePSLData = (bucketPSLineArray, psLines, bucketId) => {
        let pslData = copyObjectValues(psLines);
        let psLinesSelected = [];
        bucketConfiguration.map(bucket => {
            if (bucket.bucket_id !== bucketId)
                psLinesSelected = psLinesSelected.concat(bucket.profit_stack_lines.split(","));
        });
        pslData = pslData.filter(e=> !psLinesSelected.includes(e.value));
        pslData.forEach(psl => {
            psl.checked = bucketPSLineArray?.includes(psl.value);
            if (psl.children) {
                psl.children = updatePSLData(bucketPSLineArray, psl.children, bucketId);
            }
        });
        return pslData;
    }

    const setPSLinesData = (bucket) => {
        let bucketPSLine = bucket.profit_stack_lines.split(',');
        return updatePSLData(bucketPSLine, props.psLines, bucket.bucket_id);

    }

    function updateBucketById(id, updatedObject) {
        setBucketConfiguration(prevConfig => (
            prevConfig.map(bucket => {
                if (bucket.bucket_id === id) {
                    return { ...bucket, ...updatedObject };
                }
                return bucket;
            })
        ));
        optionsSelected = [];
    }

    /**
     * This function will linearize all psLine then
     * linearize the specific psline that matches the currentNode
     * and return an array of values,
     * it returns the values array excluding the value of the selected one
     * @param {*} currentNode
     * @return
     */
    const getValuesArrayExcludingCurrentNode = (currentNode) => {
        let linearizedPSL = linearizeHierarchy(props.psLines);
        let commonPSL = linearizedPSL.filter(f => f.value === currentNode.value);
        let commonPSLLinearized = linearizeHierarchy(commonPSL);
        let valuesArray = commonPSLLinearized.map((m) => {
            return m.value
        })
        return valuesArray.filter(f => f !== currentNode.value);
    }

    const removeChildrenPSLinesByArrayId = (currentNode, bucket) => {
        for (let e of bucketConfiguration) {
            if (e.bucket_id !== bucket.bucket_id) {
                let pslArray = e.profit_stack_lines.split(",");
                let indicesToRemove = [];
                pushArrayIndicesToRemove(indicesToRemove, pslArray, getValuesArrayExcludingCurrentNode(currentNode));
                removeByIndicesFromArray(indicesToRemove, pslArray)
                e.profit_stack_lines = pslArray.join(',');
            }
        }
    }

    /**
     * check if option is removed by clicking x from the dropdown input (checked is false and dropdown is closed)
     * @param {*} currentNode
     * @param {*} bucket
     * @param {*} updatedBucket
     */
    const removeSelectedOptionFromInput = (currentNode, bucket, updatedBucket) => {
        if (!currentNode.checked && $(".bucket_dropdown .dropdown-trigger.top").length === 0) {
            updateBucketById(bucket.bucket_id, updatedBucket);
        }
    }

    const handlePSLineChange = (currentNode, selectedNodes, bucket) => {
        let optionArray = [];
        let selectedNodePSLs = selectedNodes.map(m => m.value);
        let pslReturnNamesString = selectedNodePSLs.join(',');
        bucket.profit_stack_lines = pslReturnNamesString;
        let updatedBucket = {...bucket, profit_stack_lines: pslReturnNamesString};
        if (currentNode.checked) {
            removeChildrenPSLinesByArrayId(currentNode, bucket)
        }
        sessionStorage.setItem("bucketOption", JSON.stringify([updatedBucket]));
        removeSelectedOptionFromInput(currentNode, bucket, updatedBucket)
    }


    const saveBucketConfiguration = (callback) => {
        const query = {
            action: "saveBucketsConfiguration",
            bucketConfiguration: JSON.stringify(bucketConfiguration),
            calculatedBucketConfiguration: JSON.stringify(calculatedBucketConfiguration)
        };

        const onThenCallback = (data) => {
            let savedBuckets = [];

            if (data.success) {
                savedBuckets = props.bucketsConfiguration;
                savedBuckets.buckets = bucketConfiguration;
                savedBuckets.calculatedBuckets = calculatedBucketConfiguration
                setOriginalBucketConfiguration(bucketConfiguration);
                setOriginalCalculatedBucketConfiguration(calculatedBucketConfiguration)
                props.setHasUnsavedChanges(false);
                props.setFromSaveButton(false);
                sessionStorage.removeItem("bucketOption");
            }

            props.updateConfigurationObject(!!data.success, SUPPLY_CHAIN_SETUP.TABS_DETAILS.BUCKETS.NAME, savedBuckets);
            if(callback && typeof callback === 'function') {
                callback();
            }
        };

        const fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "saveBucketsConfiguration",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: SUPPLY_CHAIN_SETUP.API_URLS.SAVE_BUCKETS,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.requestDescription]: lang.observability.output.supply_chain.save_buckets,
            [FETCHAPI_PARAMS.screenName]: getOutputScreenName(window.location.href),
        };

        fetchAPI(fetchOptions);
    };

    /**
     * Updates calculatedBuckets state and sets the new value to the corresponding calculated bucket.
     * We find the calculted bucket by machineName since the machineName is unique.
     * @param {*} machineName (ex: transactional_cogs, total_landed_cost)
     * @param {*} updatedObject the calculated bucket object
     */
    const updateCalculatedBucketByMachineName = (machineName, updatedObject) => {
      setCalculatedBucketConfiguration(prevConfig => (
        prevConfig.map(bucket => {
            if (bucket.machine_name === machineName) {
                return { ...bucket, ...updatedObject };
            }
            return bucket;
        })
      ));
    }

    /**
     * Handle changing calculated buckets' input value in the input component.
     * It limits the user from entering more than 20 characters and adds a validation message
     * @param {*} e event
     * @param {*} selectedBucket the calculated bucket that we are changing
     */
    const handleCalculatedInputChange = (e, selectedBucket) => {
      let bucketMachineName = selectedBucket.machine_name;
      
      if(isStringSurpassingXCharacters(e.target?.value, props?.userSettings?.characterSizeLimit)){
        setErrorMessage({...errorMessage, [bucketMachineName]: lang.long_name.replace("%X%", props?.userSettings?.characterSizeLimit)})
        return;
      }

      setErrorMessage({...errorMessage, [bucketMachineName]: ""})
      let updatedBucket = {...selectedBucket, value: e.target.value};
      updateCalculatedBucketByMachineName(bucketMachineName, updatedBucket);
    }

    /**
     * update and remove selected options from other dropdowns when closing the dropdown to avoid rerendering dropdowns
     * and prevent opened nodes from closing on selection
     * Using sessionStorage to check the last selection if a dropdown is opened, and we click on save,
     * since the onDropdownBlur does not fire before the save click event and the changes are not applied yet
     */
    const onDropdownBlur = () => {
        let optionsSelected = tryParse(sessionStorage.getItem("bucketOption"));
        if (optionsSelected?.length > 0) {
            updateBucketById(optionsSelected[0].bucket_id, optionsSelected[0]);
        }
    }

    /**
     * In case the user inputs an empty string and clicks outside of the input, 
     * the input will be filled with the originally saved value, because the user can't save an empty value.
     * This case is for the calculated buckets.
     * @param {*} e event
     * @param {*} selectedBucket the calculated bucket that we removed focus from
     * @returns 
     */
    const handleCalculatedBucketsEmptyValues = (e,  selectedBucket) => {
      let bucketMachineName = selectedBucket.machine_name;
      setErrorMessage({...errorMessage, [bucketMachineName]: ""});

      if(e.target.value !== "") {
        return;
      }
    
      let originalBucketValue = copyObjectValues(originaCalculatedBucketConfiguration)?.find(f => f.machine_name === bucketMachineName)?.value;
      let updatedBucket = {...selectedBucket, value: originalBucketValue};
      updateCalculatedBucketByMachineName(bucketMachineName, updatedBucket);
    }

    const renderBucketsBody = () => {
        return (
            <div className="buckets_body">
                <div className="bucket_title_container">
                    <div className="first_bucket_title">
                        {lang.supply_chain_set_up.buckets.title_first_section}
                    </div>
                    <div className="second_bucket_title">
                        {lang.supply_chain_set_up.buckets.title_second_section}
                    </div>
                </div>
                {copyObjectValues(bucketConfiguration)?.map((bucket, index) => (
                    <div className="buckets_input_dropdown_container" key={index}>
                        <div className="buckets_input">
                            <Input key={index} value={bucket.bucket_name} disabled={true} className="bucket_single_input"></Input>
                        </div>
                        <div className={"bucket_dropdown"}>
                            <Container
                                data={setPSLinesData(bucket)}
                                onChange={(currentNode, selectedNodes) => handlePSLineChange(currentNode, selectedNodes, bucket)}
                                mode={"hierarchical"}
                                className={"dropdown-tree multi-select-drop-down " + (bucket.profit_stack_lines ? "hidden_placeholder" : "")}
                                onBlur={onDropdownBlur}
                                // clearSearchOnChange={true}
                            />
                        </div>
                    </div>
                ))}
                <hr />
                <div className="calculated-buckets-container"> 
                  {calculatedBucketConfiguration?.map((bucket, index) => (
                    <div key={index} className="calculated-bucket">
                      <span className="calculated-bucket-title">{bucket.title}</span>
                      <Input 
                        defaultValue={bucket.value} 
                        value={bucket.value}
                        className="calculated-bucket-input" 
                        type="text" 
                        onBlur={(e) => handleCalculatedBucketsEmptyValues(e, bucket)}
                        onChange={(e) => handleCalculatedInputChange(e, bucket)} 
                      />
                      <span className='calculated-bucket-error red italic'>{errorMessage[bucket.machine_name]}</span>
                    </div>
                  ))}
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="buckets_body_container">
                {renderBucketsBody()}
            </div>
        </>
    );
};

export default forwardRef(BucketsConfiguration);
