/**
 * File contains all functions dynamically used to access redux store to retreive/store data 
 */

import { updatePsLinesOptions, updateQTs } from "../actions/filterActions";
import { updateColumnProfiles, updateCustomGroups, updateProfiles, updateStateProfiles } from "../actions/manageColumnsActions";
import { updateDatasetOptions, updatePeriodsStatus, updateProfitstackHierarchyByStack, updatePsViews, updateScenarioState, updateScenarios, updateTiers,
     updatePeriodsStatusState, updatePsViewsState, updateVectorState, updateDatasetState} from "../actions/scenariosActions";
import { updateUserSections, updateUserSettings } from "../actions/userSettingsActions";
import { updateUserSettings as updateClientUserSettings } from "../templateLayout/actions/userSettingsActions";
import { updateUserSectionsData as updateClientUserSections, updateUserSectionsData} from "../templateLayout/actions/userSectionsActions";
import { updateBridgeOptions } from "../templateLayout/actions/bridgeOptionsActions";

function getItemFromStore(key, comp, hashMapKey) {
    let data = comp && comp.props && comp.props[key];
    if (hashMapKey && data ) {
        if (data.length === 0) {
            return undefined;
        } else if (!data.has(hashMapKey)) {
            return undefined;
        }
        return data.get(hashMapKey)[0];
    }
    if (data && data.length > 0 && data[0].name && data[0].name.length > 0 ) {
        return data[0].name[0];
    }
    return undefined;
}

function setItemInStore(callback, data, comp) {
    if (comp.props.dispatch) {
        comp.props.dispatch(callback([data]));
    }
}

function clearStoreForSwitchedClient(_this) {
    _this.props.dispatch(updateScenarioState({}));
    _this.props.dispatch(updateScenarios([]));
    _this.props.dispatch(updateQTs([]));
    _this.props.dispatch(updateUserSettings([]));
    _this.props.dispatch(updateUserSections([]));
    _this.props.dispatch(updateUserSectionsData([]));
    _this.props.dispatch(updateProfiles([],0));
    _this.props.dispatch(updateCustomGroups([],undefined,true));
    _this.props.dispatch(updateDatasetOptions([],undefined,true));
    _this.props.dispatch(updatePeriodsStatus([],undefined,true));
    _this.props.dispatch(updateTiers([],undefined,true));
    _this.props.dispatch(updatePsViews([],undefined,true));
    _this.props.dispatch(updatePsLinesOptions([],undefined,true));
    _this.props.dispatch(updateProfitstackHierarchyByStack([], undefined, true));
    _this.props.dispatch(updateStateProfiles([],undefined,true));
    _this.props.dispatch(updateColumnProfiles([],undefined,true));
}

function clearStoreWhenSwitchingClient(dispatch) {
  dispatch(updateScenarios([]));
  dispatch(updateScenarioState({}));
  dispatch(updateQTs([]));
  dispatch(updateClientUserSections([]));
  dispatch(updateClientUserSettings([]));
  dispatch(updateProfiles([], 0));
  dispatch(updateCustomGroups([], undefined, true));
  dispatch(updateDatasetOptions([], undefined, true));
  dispatch(updatePeriodsStatus([], undefined, true));
  dispatch(updateTiers([], undefined, true));
  dispatch(updatePsViews([], undefined, true));
  dispatch(updatePsLinesOptions([], undefined, true));
  dispatch(updateProfitstackHierarchyByStack([], undefined, true));
  dispatch(updateStateProfiles([], undefined, true));
  dispatch(updateColumnProfiles([], undefined, true));
  dispatch(updateBridgeOptions([], undefined, true));
  dispatch(updatePsViewsState([], undefined, true));
  dispatch(updateVectorState([], undefined, true));
  dispatch(updateDatasetState([], undefined, true));
  dispatch(updatePeriodsStatusState([], undefined, true));

}
function clearStoreByScenario (dispatch, scenarioId) {
  dispatch(updateScenarios([]));
  dispatch(updateScenarioState({}));
  dispatch(updateProfiles([], scenarioId));
  dispatch(updateCustomGroups([], scenarioId));
  dispatch(updateDatasetOptions([], scenarioId));
  dispatch(updatePeriodsStatus([], scenarioId));
  dispatch(updateTiers([], scenarioId));
  dispatch(updatePsViews([], scenarioId));
  dispatch(updatePsLinesOptions([], scenarioId));
  dispatch(updateProfitstackHierarchyByStack([], scenarioId));
  dispatch(updateStateProfiles([], scenarioId));
  dispatch(updateColumnProfiles([], scenarioId));
  dispatch(updateBridgeOptions([], scenarioId));
  dispatch(updatePsViewsState([], scenarioId));
  dispatch(updateVectorState([], scenarioId));
  dispatch(updateDatasetState([], scenarioId));
  dispatch(updatePeriodsStatusState([], scenarioId));

}

  export {getItemFromStore, setItemInStore, clearStoreForSwitchedClient, clearStoreWhenSwitchingClient, clearStoreByScenario}