import { useEffect, useState } from "react";
import HttpsRedirect from "react-https-redirect";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import Popup from 'react-popup';

import { toggleLoader } from "../../class/common";
import { API_URL, BUTTON_VARIANT, SIZES, BUTTON_TYPE,MFA_REPORTS, DIALOG_SIZE } from "../../class/constants";
import { removeAllCookies } from "../../class/jqueries";
import firebase, { auth } from "../../firebase/firebase";
import { lang } from '../../language/messages_en';
import {multiFactor, PhoneAuthProvider, RecaptchaVerifier, signInWithEmailAndPassword, signOut} from 'firebase/auth';
import "../../styles/login.css";
import "../../styles/popup.css";
import Button from "../../newComponents/Button";
import Modal from "../../newComponents/Modal";
import {onEnterClick} from "../../class/utils";

const $ = require("jquery");
 

const PhoneNumberAdd = (props) => {
  $(window).on('beforeunload', function(){
    signOut(auth);
});
  let [phoneNumber, setPhoneNumber] = useState();
  var reCaptchaVerifier ;

  const [isInfoDialogOpen, showInfoDialog] = useState(false);
  const [dialogInfoMsg, setDialogInfoMsg] = useState("");
  const setInfoDialogOpen = (isOpen, infoMsg) => {
    showInfoDialog(isOpen);
    setDialogInfoMsg(infoMsg)
   }
 
   const openInfoDialogActions = () => {
     return (
       <Button
         label={lang.modal.buttons.ok}
         variant={BUTTON_VARIANT.PRIMARY}
         size={SIZES.DEFAULT}
         type={BUTTON_TYPE.DEFAULT}
         onBtnClick={() => showInfoDialog(false)}
       />
     )
   }

  useEffect(() => {
    setPhoneNumber(props.phone);
    auth.useDeviceLanguage();
    reCaptchaVerifier = new RecaptchaVerifier("goToVerify", {
      'size': "invisible",
      'callback': (response) => {
        // reCAPTCHA solved, allow sending sms.
        sendVerificationCode();
      },
    }, auth);
    reCaptchaVerifier.render();
    window.addEventListener("keydown",onEnterEvent);
  }, []);

  const sendVerificationCode = () => {
    let phoneNumber = $(".getPhone").val().trim();
    signInWithEmailAndPassword(auth, props.email, props.password)
    // auth.signInWithEmailAndPassword(props.email, props.password)
    .catch(function (error) {
      console.log(error);
    }).then(function(){

      const multiFactorUser = multiFactor(auth.currentUser);
      multiFactorUser.getSession()
      .then(function (multiFactorSession) {
        // Send verification code
        const phoneAuthProvider = new PhoneAuthProvider(auth);
        // var phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
        var phoneInfoOptions = {
          phoneNumber: phoneNumber,
          session: multiFactorSession,
        };
        return phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          reCaptchaVerifier
        );
      })
      .then(function (verificationId) {
        props.codeChange(verificationId);
        props.setPhone(phoneNumber);
        props.onNextClick("", MFA_REPORTS.PHONE_NUMBER);
      })
      .catch(function () {
        grecaptcha.reset();
        setInfoDialogOpen(true, lang.mfa.code_not_sent);
        $("#form").addClass("disabled");
        setTimeout(function () {
          $("#form").removeClass("disabled");
        }, 6000);
      });
    })
  };

  const onEnterEvent = (event) => {
    // If the user presses the "Enter" key on the keyboard
    onEnterClick(event,"goToVerify");
}
    
  return (
    <HttpsRedirect>
      <main id="body">
        <section className="container login-container">
          <div id="phoneNumberForm" className="login-form mrgt5 mrgb10">
            <span className="set_password_title">{lang.mfa.phone_number.title}</span>
            <div className="mrgt10 for_container_text">
              For <b>{props.email}</b>
            </div>
            <div className="mfa_text_container">
            <span className="mfa_text">{lang.mfa.phone_number.sub_title}</span>
            </div>
            <section className="mrgt30 mrgb15" id="form">
              <div className="mrgb20">
                <label className="col-12 mrgl0 mfa_input_label">Phone number</label>
                <div className="col-12">
                  <PhoneInput
                    placeholder="Phone number"
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                    countryCodeEditable= {false}
                    autoComplete="off"
                    enableSearch={true}
                    inputClass="input_phone_number getPhone"
                    dropdownClass="dropdown_phone_number"
                    masks={{ lb: ".. ... ..." }}
                    preferredCountries={["lb", "us"]}
                    country="us"
                    isValid={(value, country) => {
                      if ((value.length-country.dialCode.length) < 8 ) {
                        $("#goToVerify").addClass("disabled");
                        if (value != country.dialCode) {
                          return lang.mfa.validation;
                        } else {
                          return true;
                        }
                      } else {
                        $("#goToVerify").removeClass("disabled");
                        return true;
                      }
                    }}
                  />
                </div>
              </div>
              <div className="mrgt30 ">
                <div className="col-12">
                  <Button
                    label = {lang.mfa.phone_number.button}
                    variant={BUTTON_VARIANT.PRIMARY}
                    size={SIZES.LARGE}
                    type={BUTTON_TYPE.DEFAULT}
                    // disabled={true} //remove disable SAS
                    className={"max_width sign-in-button"}
                    id="goToVerify"
                  />
                  {props.isUnified ? 

                    <Button
                      variant={BUTTON_VARIANT.TERTIARY}
                      size={SIZES.LARGE}
                      type={BUTTON_TYPE.DEFAULT}
                      className={"max_width mrgt10"}
                      id="goToLogin"
                      onBtnClick={()=>{window.location.reload()}}
                      label={lang.mfa.phone_number.back}
                    /> : ""}
                </div>
              </div>
            </section>
          </div>
        </section>
      </main>
      <Modal
        id={"info-dialog"}
        openDialog={isInfoDialogOpen}
        bodyContent={() => <h4>{dialogInfoMsg}</h4>}
        dialogActions={openInfoDialogActions}
        closeClick={() => showInfoDialog(false)}
        size={DIALOG_SIZE.MEDIUM}
      />
    </HttpsRedirect>
  );
};

export default PhoneNumberAdd;
