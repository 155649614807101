import React, { Component } from 'react';
import '../styles/dataModeling.css';
import {TabulatorFull} from "tabulator-tables"; //import {TabulatorFull} library
import { copyObjectValues, getTranslationFile } from '../class/utils.js';
import { FISCAL_YEAR, TABULATOR_BUTTON } from '../class/constants.js';
import { cleanUpTabulatorColumns } from '../class/common.js';
import Popup from 'react-popup';
import { lang } from '../language/messages_en';
import { convertPxToViewport } from '../class/formatting';
import { logUIError } from '../class/networkUtils';
import { getTableButton, getTableIcon } from '../newComponents/tabulatorComponents';
import { createDropdown } from '../newComponents/DropdownTabulator';

const $ = require('jquery');

const MESSAGES = getTranslationFile();

/**
 * Fiscal Calendar Table
 * @author [Sarah Farjallah]
 */

class periodTable extends Component {
    constructor(props) {
		super(props);
		this.state = {
			tableColumns: [],
			tableData: [],
		};
		this.savedRowNumber = -1;
		this.onTabulatorRenderComplete = this.onTabulatorRenderComplete.bind(this);
        this.toggleTable = this.toggleTable.bind(this);
        this.setCellValue = this.setCellValue.bind(this);
        this.showButtons = this.showButtons.bind(this);
        this.validatePeriodRow = this.validatePeriodRow.bind(this);
        this.getLastPeriod = this.getLastPeriod.bind(this);
        this.getOldestPeriod = this.getOldestPeriod.bind(this);

    }

    setCellValue(e, field, cell) {
        cell.getRow().getData()[field] = e.currentTarget.value;
    }

    showButtons() {
        var table = this.tabulator.getData();
        var validated = true;
        for (var e in table) {
            if (table[e][FISCAL_YEAR.FIELDS.ROW_STATUS] === FISCAL_YEAR.ROW_STATUS_VALUES.EDITED) {
                validated = false;
                break
            }
        }
        if (validated) {
            $("#add_period").show();
            $("#add_period_12").show();
        }
    }
     isValidDate(d) {
        return d instanceof Date && !isNaN(d);
    }

    validateIfOverlappingPeriods = (periods,selectedPeriod) => {
        let d = new Date(selectedPeriod[FISCAL_YEAR.FIELDS.START_DATE]+"T23:00:00");
        let startDate = selectedPeriod[FISCAL_YEAR.FIELDS.START_DATE] !== ""  ? this.isValidDate(d) ?  d: new Date(selectedPeriod[FISCAL_YEAR.FIELDS.START_DATE]) :"";
        let endDate = selectedPeriod[FISCAL_YEAR.FIELDS.END_DATE] !== "" ?  this.isValidDate(new Date(selectedPeriod[FISCAL_YEAR.FIELDS.END_DATE]+"T23:00:00")) ? new Date(selectedPeriod[FISCAL_YEAR.FIELDS.END_DATE]+"T23:00:00") : new Date(selectedPeriod[FISCAL_YEAR.FIELDS.END_DATE]) :"";
        let periodName =  selectedPeriod[FISCAL_YEAR.FIELDS.PERIOD];
        let index = "";
        for(let e in periods){
            let name = periods[e][FISCAL_YEAR.FIELDS.PERIOD];
            if(name === periodName){
                index = e;
                continue;
            }
            let period_start_date = new Date(periods[e][FISCAL_YEAR.FIELDS.START_DATE]);
            let period_end_date  = new Date(periods[e][FISCAL_YEAR.FIELDS.END_DATE]);
            if((startDate >= period_start_date && startDate<= period_end_date) || (endDate>=period_start_date && endDate <=period_end_date)){
                return false;
            }
        }
        if(index !== 0){//Not first period edited
            let dEnd = new Date(periods[0][FISCAL_YEAR.FIELDS.END_DATE]+"T23:00:00");
            let dStart = new Date(periods[periods.length-1][FISCAL_YEAR.FIELDS.START_DATE]+"T23:00:00");
            let endDate1 = selectedPeriod[FISCAL_YEAR.FIELDS.START_DATE] !== ""  ? this.isValidDate(dEnd) ?  dEnd: new Date(periods[0][FISCAL_YEAR.FIELDS.END_DATE]) :"";
            let startDate1 = selectedPeriod[FISCAL_YEAR.FIELDS.END_DATE] !== "" ?  this.isValidDate(dStart) ? dStart : new Date(selectedPeriod[FISCAL_YEAR.FIELDS.START_DATE]) :"";
        
            let period_end_date  = endDate1; //new Date(periods[0][FISCAL_YEAR.FIELDS.END_DATE]); //First Period
            let period_start_date = startDate1; //new Date(periods[periods.length-1][FISCAL_YEAR.FIELDS.START_DATE]);// Last Period
            if(startDate > period_end_date || endDate > period_end_date || startDate < period_start_date || endDate < period_start_date){
                return false;
            }
        }
        
        return true;
    }

    /**
     * function will validate the dates of period taking into consideration the last period
     * @param {*} row 
     * @param {*} table 
     */
    validatePeriodRow(row, table) {
        let periods = this.tabulator.getData();
        var startDate = row[FISCAL_YEAR.FIELDS.START_DATE] !== "" ? new Date(row[FISCAL_YEAR.FIELDS.START_DATE]) : "";
        var endDate = row[FISCAL_YEAR.FIELDS.END_DATE] !== "" ?  new Date(row[FISCAL_YEAR.FIELDS.END_DATE]+"T23:00:00") : "";
        var validated = true;
        var period = row[FISCAL_YEAR.FIELDS.PERIOD];
        var year = row[FISCAL_YEAR.FIELDS.YEAR];
        var quarter = row[FISCAL_YEAR.FIELDS.QUARTER];
        var foundDup = table.filter(e=>e[FISCAL_YEAR.FIELDS.PERIOD] === period && e[FISCAL_YEAR.FIELDS.YEAR] === year);
        //checking for empty fields
        if (row[FISCAL_YEAR.FIELDS.YEAR] === "" || row[FISCAL_YEAR.FIELDS.PERIOD] === "" ||
            row[FISCAL_YEAR.FIELDS.QUARTER] === "" || row[FISCAL_YEAR.FIELDS.START_DATE] === "" 
            || row[FISCAL_YEAR.FIELDS.END_DATE] === "") {
                  this.props.setInfoDialogOpen(true, MESSAGES.fill_all_period_fields)
                 validated = false;
                 return validated;
        } else if(startDate > endDate) { // startDate greater than endat
            this.props.setInfoDialogOpen(true, MESSAGES.start_data_less_than_end_date)
            validated = false;
            return validated;
        }else if (foundDup && foundDup.length > 1){ //duplicate periods
            this.props.setInfoDialogOpen(true, MESSAGES.enter_non_existing_period)
            validated = false;
            return validated;
        }else { // complex checks
            var lastPeriod = this.getOldestPeriod(table); //oldest period in data
            var lastYear = lastPeriod[FISCAL_YEAR.FIELDS.YEAR];
            var year = row[FISCAL_YEAR.FIELDS.YEAR];
            var previousPeriodRow = this.getLastPeriod(year, period, table, lastYear);

            if (lastPeriod) { //checking if newly created period is greater than the first period created
                var previousYear = lastPeriod[FISCAL_YEAR.FIELDS.YEAR];
                var previousPeriod = lastPeriod[FISCAL_YEAR.FIELDS.PERIOD];
                var previousQuarter = lastPeriod[FISCAL_YEAR.FIELDS.QUARTER];
                if (Number(year) < Number(previousYear)) {
                  this.props.setInfoDialogOpen(true, MESSAGES.current_period_less_than_previous);
                    validated = false;
                    return validated;
                }
                if (Number(year) === Number(previousYear) && Number(quarter.split("Q")[1]) < Number(previousQuarter.split("Q")[1])) {
                  this.props.setInfoDialogOpen(true, MESSAGES.current_period_less_than_previous);
                    validated = false;
                    return validated;
                }
                if (Number(year) === Number(previousYear) && Number(quarter.split("Q")[1]) === Number(previousQuarter.split("Q")[1]) &&
                    Number(period.split("P")[1]) < Number(previousPeriod.split("P")[1])) {
                      this.props.setInfoDialogOpen(true, MESSAGES.current_period_less_than_previous);
                    validated = false;
                    return validated;
                }
            } 
            if(previousPeriodRow === 0) {
                return true; 
            } else if (previousPeriodRow && previousPeriodRow.length > 0) { // checking if there's intersection in dates between the newly created period and the previous one
                var preStartDate = previousPeriodRow[0][FISCAL_YEAR.FIELDS.START_DATE] !== "" ? new Date(previousPeriodRow[0][FISCAL_YEAR.FIELDS.START_DATE]+"T23:00:00") : "";
                var preEndDate = previousPeriodRow[0][FISCAL_YEAR.FIELDS.END_DATE] !== "" ? new Date(previousPeriodRow[0][FISCAL_YEAR.FIELDS.END_DATE]+"T23:00:00") : "";
                if (startDate >= preStartDate && startDate <= preEndDate || startDate <= preStartDate || endDate <= preEndDate ) {
                    let trackingData = window._pi_getTrackingDataObj();
                    trackingData.pifi = "PI-22572";
                    trackingData.condition = "(startDate >= preStartDate && startDate <= preEndDate || startDate <= preStartDate || endDate <= preEndDate )";
                    trackingData.datevalues = ("startDate:", startDate,  "preStartDate:", preStartDate, "preEndDate: ", preEndDate, "endDate", endDate );
                    logUIError(trackingData);
                    this.props.setInfoDialogOpen(true, MESSAGES.enter_valid_dates);
                    validated = false;
                    return validated;
                } else if (endDate <= preEndDate || endDate <= startDate) {
                    let trackingData = window._pi_getTrackingDataObj();
                    trackingData.pifi = "PI-22572";
                    trackingData.condition = "(endDate <= preEndDate || endDate <= startDate)";
                    trackingData.datevalues = ("startDate:", startDate, "preEndDate: ", preEndDate, "endDate", endDate );
                    logUIError(trackingData);
                    this.props.setInfoDialogOpen(true, MESSAGES.enter_valid_dates);
                    validated = false;
                    return validated;
                } else if(!this.validateIfOverlappingPeriods(periods,row)){
                    let trackingData = window._pi_getTrackingDataObj();
                    trackingData.pifi = "PI-22572";
                    trackingData.condition = "!this.validateIfOverlappingPeriods(periods,row)";
                    trackingData.datevalues = ("periods:", periods, "row: ", row);
                    logUIError(trackingData);
                    this.props.setInfoDialogOpen(true, MESSAGES.enter_valid_dates);
                    validated = false;
                    return validated;
                }
            }
        }
        return true;
    }
/**
 * function gets oldest year in data
 * @param {*} data 
 */
    getOldestPeriod(data) {
        var periodRow = data[data.length -1];
        // for (var e in data) {
        //     if(Number(data[e][FISCAL_YEAR.FIELDS.YEAR]) < Number(periodRow[FISCAL_YEAR.FIELDS.YEAR])) {
        //         periodRow = data[e];
        //     }
        // }
        return periodRow;
    }

    /**
     * recursive function that gets the last period defined before the new row
     * @param {*} year 
     * @param {*} period 
     * @param {*} data 
     * @param {*} lastYear 
     */
    getLastPeriod(year, period, data, lastYear) {
        if (period === "P01") { // if period is P1 then we need to decrement year
            year = (Number(year) -1).toString();
            period = "P12";
            data.filter(e=>e[FISCAL_YEAR.FIELDS.YEAR] === year);
            if (Number(year) < Number(lastYear)) { //if year decremented is less than the oldest period in the data then no need to keep on searching 
                return 0;
            }else {
                var value = data.filter(e=>e[FISCAL_YEAR.FIELDS.YEAR] === year && e[FISCAL_YEAR.FIELDS.PERIOD] === period);
                if (value.length > 0) {
                    return value;
                }
                else {
                    var value = this.getLastPeriod(year, period, data, lastYear); // get period right before the period chosen
                    if (value && value.length >0 ) {
                        return value;
                    }
                }
            }
           
        } else {
            if (Number(year) < Number(lastYear)) {//if year decremented is less than the oldest period in the data then no need to keep on searching 
                return 0;
            }else {
                period = (Number(period.split("P")[1]) -1);
                period = period <=9 ? "P0"+(period.toString()) : "P"+(period.toString());
                var value = data.filter(e=>e[FISCAL_YEAR.FIELDS.YEAR] === year && e[FISCAL_YEAR.FIELDS.PERIOD] === period);
                if (value.length > 0) {
                    return value;
                }else {
                    var value = this.getLastPeriod(year, period, data, lastYear); // get period right before the period chosen
                    if (value && value.length > 0) {
                        return value;
                    }
                }
            }
           
        }
    }

    /**
     *  save the position of thw row we clicked on
     * @param {*} cell 
     */
    saveClickPosition=(cell)=>{
        let _this = this;
        if(_this.savedRowNumber >=0){// hide the previous drill icon -if exists-
            $("#actions_"+_this.savedRowNumber).css("visibility", "hidden");
        }
        let rowNumber = cell.getRow().getPosition();
        $("#actions_"+rowNumber).css("visibility", "visible");
        this.savedRowNumber = rowNumber;
    }
    
	getColumnFormatter(colField) {
		var obj = this;
		var columnFormatter;
        switch(colField) {
            case FISCAL_YEAR.FIELDS.YEAR:
				columnFormatter = function (cell) {	
                    if ((cell.getRow().getData()[FISCAL_YEAR.FIELDS.ROW_STATUS] && cell.getRow().getData()[FISCAL_YEAR.FIELDS.ROW_STATUS] !== FISCAL_YEAR.ROW_STATUS_VALUES.EDITED)) {
                        let div = document.createElement("div");
                        div.innerHTML = cell.getRow().getData()[colField];
                        return div;
                    }else {
                        let div = document.createElement("div");
                        let select = createDropdown(["form-control-90","height-25"]);
                        select.style.cursor = "pointer";
                        // select.classList.add("form-control-90");
                        // select.classList.add("height-25");
                        select.style.display = "inline-block";
                        select.title = "Choose a year";
                        select.onchange = (e)=> {obj.setCellValue(e, colField, cell)};
                        var year = cell.getRow().getData()[FISCAL_YEAR.FIELDS.YEAR];
                        var currentYear = new Date().getFullYear();
                        var years =[currentYear, currentYear-1, currentYear-2, currentYear-3];
                        if(year > currentYear){
                            years.unshift(year);
                        }
                        if (years.length > 0) {
                            for (var key in years) {
                                let option = document.createElement("option");
                                option.value = years[key];
                                option.innerHTML = years[key];
                                select.appendChild(option);
                            }
                            select.value =  cell.getRow().getData()[colField] !== "" ?  cell.getRow().getData()[colField] : "";
                        }
                        div.appendChild(select);         
                        return div;
                    }
                    
				}
				break;
			case FISCAL_YEAR.FIELDS.PERIOD:
				columnFormatter = function(cell) {
                    if ((cell.getRow().getData()[FISCAL_YEAR.FIELDS.ROW_STATUS] && cell.getRow().getData()[FISCAL_YEAR.FIELDS.ROW_STATUS] !== FISCAL_YEAR.ROW_STATUS_VALUES.EDITED)) {
                        let div = document.createElement("div");
                        div.innerHTML = cell.getRow().getData()[colField];
                        return div;
                    }else {
                        let div = document.createElement("div");
                        let select = createDropdown(["form-control-90","height-25"]);
                        select.style.cursor = "pointer";
                        // select.classList.add("form-control-90");
                        // select.classList.add("height-25");
                        select.style.display = "inline-block";
                        select.title = "Choose a period";
                        select.onchange = (e)=> {obj.setCellValue(e,colField, cell)};
                        var periods = FISCAL_YEAR.FIELDS.PERIODS_VALUES;
                        if (periods.length > 0) {
                            for (var key in periods) {
                                let option = document.createElement("option");
                                option.value = periods[key];
                                option.innerHTML = periods[key];
                                select.appendChild(option);
                            }
                            select.value = cell.getRow().getData()[colField] ? cell.getRow().getData()[colField] : "";
                        }
                        div.appendChild(select);         
                        return div;
                    }
                    
				}
				break;

			case FISCAL_YEAR.FIELDS.START_DATE:
				columnFormatter = function(cell) {
                    if ((cell.getRow().getData()[FISCAL_YEAR.FIELDS.ROW_STATUS] && cell.getRow().getData()[FISCAL_YEAR.FIELDS.ROW_STATUS] !== FISCAL_YEAR.ROW_STATUS_VALUES.EDITED)) {
                        let div = document.createElement("div");
                        div.innerHTML = cell.getRow().getData()[colField].split("T")[0];
                        return div;
                    } else {
                        let div = document.createElement('div');
                        let calendar = document.createElement('input');
                        calendar.classList.add("width-90-prcnt");
                        calendar.type='date';
                        calendar.onchange = (e) => {obj.setCellValue(e, colField, cell)};
                        calendar.value = cell.getRow().getData()[colField] && cell.getRow().getData()[colField] !== "" ? (cell.getRow().getData()[colField]).split("T")[0] :"";
                        calendar.innerHTML = cell.getRow().getData()[colField] && cell.getRow().getData()[colField] !== "" ? (cell.getRow().getData()[colField]).split("T")[0]: ""
                        div.appendChild(calendar);
                        return div;
                    }
				}
			break;
			case FISCAL_YEAR.FIELDS.END_DATE:
				columnFormatter = function(cell) {
                    if ((cell.getRow().getData()[FISCAL_YEAR.FIELDS.ROW_STATUS] && cell.getRow().getData()[FISCAL_YEAR.FIELDS.ROW_STATUS] !== FISCAL_YEAR.ROW_STATUS_VALUES.EDITED)) {
                        let div = document.createElement("div");
                        div.innerHTML = cell.getRow().getData()[colField].split('T')[0];
                        return div;
                    } else {
                        let div = document.createElement('div');
                        let calendar = document.createElement('input');
                        calendar.classList.add("width-90-prcnt");
                        calendar.type='date';
                        calendar.onchange = (e) => {obj.setCellValue(e, colField, cell)};
                        calendar.value = cell.getRow().getData()[colField] && cell.getRow().getData()[colField] !== "" ? cell.getRow().getData()[colField].split("T")[0] :"";
                        calendar.innerHTML = cell.getRow().getData()[colField] &&   cell.getRow().getData()[colField] !== "" ? cell.getRow().getData()[colField].split("T"[0]) : ""
                        div.appendChild(calendar);
                        return div;
                    }
                }
				break;
				case FISCAL_YEAR.FIELDS.QUARTER:
					columnFormatter = function(cell) {
                        if ((cell.getRow().getData()[FISCAL_YEAR.FIELDS.ROW_STATUS] && cell.getRow().getData()[FISCAL_YEAR.FIELDS.ROW_STATUS] !== FISCAL_YEAR.ROW_STATUS_VALUES.EDITED)) {
                            let div = document.createElement("div");
                            div.innerHTML = cell.getRow().getData()[colField];
                            return div;
                        } else {
                            let div = document.createElement("div");
                            let select = createDropdown(["form-control-90","height-25"]);
                            select.style.cursor = "pointer";
                            // select.classList.add("form-control-90");
                            // select.classList.add("height-25");
                            select.style.display = "inline-block";
                            select.title = "Choose a quarter";
                            select.onchange = (e)=> {obj.setCellValue(e, colField, cell)};
                            var quarters = FISCAL_YEAR.FIELDS.QUARTERS_VALUES;
                            if (quarters.length > 0) {
                                for (var key in quarters) {
                                    let option = document.createElement("option");
                                    option.value = quarters[key];
                                    option.innerHTML = quarters[key];
                                    select.appendChild(option);
                                }
                                select.value = cell.getRow().getData()[colField] ? cell.getRow().getData()[colField] : "";
                            }
                            div.appendChild(select);         
                            return div;
                        }
                    }					
                break;
                case FISCAL_YEAR.FIELDS.ACTIONS:
				columnFormatter = function(cell) {
                    if ((cell.getRow().getData()[FISCAL_YEAR.FIELDS.ROW_STATUS] && cell.getRow().getData()[FISCAL_YEAR.FIELDS.ROW_STATUS] === FISCAL_YEAR.ROW_STATUS_VALUES.EDITED)) {
                        let div = document.createElement("div");

                        var ticIcon = getTableIcon(['fas','fa-check','uk-margin-xsmall','black'])
                        div.appendChild(ticIcon);
                        ticIcon.onclick = (e)=>{
                            if (obj.validatePeriodRow(cell.getRow().getData(), cell.getTable().getData())) {
                                cell.getRow().getData()[FISCAL_YEAR.FIELDS.PERIOD_APPLY_STATUS] = FISCAL_YEAR.PERIOD_APPLY_VALUES.APPLIED;
                                cell.getRow().getData()[FISCAL_YEAR.FIELDS.ROW_STATUS] = FISCAL_YEAR.ROW_STATUS_VALUES.ADDED;
                                obj.tabulator.setData(cell.getTable().getData());
                                obj.props.savePreviousVersion(copyObjectValues(cell.getTable().getData()));
                                obj.showButtons();
                            }
                        };

                        var discardIcon = getTableIcon(['fas','fa-times','uk-margin-xsmall','black'])
                        discardIcon.onclick = (e)=>{
                            obj.props.discardChange(cell.getRow().getData());
                            obj.showButtons();
                        };
                        div.appendChild(discardIcon);
                        return div;
                    } else {
                        let rowNumber = cell.getRow().getPosition();
                        /* actions three dotted dropdown button container*/
                        let dropdownContainer = document.createElement("div");
                        dropdownContainer.id = "dropDown_"+rowNumber;
                        dropdownContainer.setAttribute("uk-dropdown", "mode: click");
                        dropdownContainer.classList.add("posRight");
        
                        /* three dotted button*/
                        let dotsButtonContainer = document.createElement("div");
                        let dotsButton = getTableIcon(["fa-2x", "fal", "fa-ellipsis-v", "uk-margin-small-bottom", "uk-button-icon"]);
                        dotsButton.id = "actions_"+rowNumber;
                        dotsButton.style.visibility = "hidden";
                        dotsButtonContainer.classList.add("uk-inline");
                        dotsButtonContainer.onclick = ()=> {obj.saveClickPosition(cell)};// save the position of thw row we clicked on
                        /*edit button*/
                        let editIconDiv = getTableButton(
                            lang.manage_columns.titles.edit,
                            [],
                            ["justify-content-start"],
                            ["fa-lg", "fal","fa-edit" ,"uk-margin-small-right", "uk-margin-small-left"],
                            "left",
                            lang.manage_columns.titles.edit
                        );
                        editIconDiv.style.width = "100%";
                        editIconDiv.onclick = ()=>{
                                cell.getRow().getData()[FISCAL_YEAR.FIELDS.ROW_STATUS] = FISCAL_YEAR.ROW_STATUS_VALUES.EDITED;
                                obj.tabulator.replaceData(cell.getTable().getData());
                            };

                        /* activate or deactivate button*/
                        let isActive = cell.getRow().getData()[FISCAL_YEAR.FIELDS.ACTIVE_STATUS] === FISCAL_YEAR.PERIOD_STATUS_VALUES.ACTIVE;
                        let title = isActive?lang.manage_columns.titles.deactivate: lang.manage_columns.titles.activate;
                        let iconClass = isActive? "fa-times-circle" : "fa-check-circle";
                        let activateContainerDiv = document.createElement("div");
                        let activateIconDiv = getTableButton(
                            title,
                            [],
                            ["justify-content-start"],
                            ["fa-lg", "fal", iconClass, "uk-margin-small-right", "uk-margin-small-left", "black"],
                            "left",
                            title
                        );
                        activateIconDiv.style.width = "100%";
               
                        if(!isActive && obj.tabulator.getData().filter(e=>e.is_active === "ACTIVE").length >= Number(obj.props.active_period_limit)){
                            activateIconDiv.classList.add("disabled");
                            activateContainerDiv.setAttribute('uk-tooltip',lang.fiscal_calendar.messages.activate_limit_reached);
                            activateContainerDiv.classList.add("opacity-80");
                        }
                       
                        activateIconDiv.onclick = () =>{
                            if(!isActive){
                                cell.getRow().getData()[FISCAL_YEAR.FIELDS.ACTIVE_STATUS] = FISCAL_YEAR.PERIOD_STATUS_VALUES.ACTIVE;
                                obj.tabulator.replaceData(cell.getTable().getData());
                                obj.props.savePreviousVersion(copyObjectValues(cell.getTable().getData()));
                            }else{
                                obj.props.showDeletePopup(cell.getRow().getData())
                            }
                        };
                        
                        if(cell.getRow().getData()[FISCAL_YEAR.FIELDS.ACTIVE_STATUS] === FISCAL_YEAR.PERIOD_STATUS_VALUES.INACTIVE){
                            $(cell.getRow().getElement()).addClass("disabled-row");
                        }
                        dropdownContainer.appendChild(editIconDiv);
                        activateContainerDiv.appendChild(activateIconDiv);
                        dropdownContainer.appendChild(activateContainerDiv);
        
                        dotsButtonContainer.appendChild(dotsButton);
                        dotsButtonContainer.appendChild(dropdownContainer);
                        return dotsButtonContainer;
                    }
                }
				break;
			default:
				break;
		}
		
		return columnFormatter;
	}

    addListenersOnHover=(rowNumber)=>{
        if(document.getElementById("actions_"+ rowNumber) && document.getElementById("actions_"+rowNumber).parentElement){
            document.getElementById("actions_"+rowNumber).parentElement.parentElement.parentElement.addEventListener("mouseover", event => {
                $("#actions_"+rowNumber).css("visibility", "visible");
            });
            document.getElementById("actions_"+ rowNumber).parentElement.parentElement.parentElement.addEventListener("mouseout", event => {
                if(rowNumber !== this.savedRowNumber){// dont hide the three dots on mouse out if it is opened
                    $("#actions_"+rowNumber).css("visibility", "hidden");
                }
            });
        }
        
    }

    /**
     * to show the three dots on hover
     * @returns 
     */
    addListeners=()=>{
        if(!this.tabulator){
            return;
        }
        let _this = this;
        if(_this.tabulator && _this.tabulator.getRows()){// don't add listener to the not rendered rows (not rendered row don't have parent element)
            _this.tabulator.getRows().forEach(row =>{
                 _this.addListenersOnHover(row.getPosition());
            });
        }
    }

    componentWillUnmount() {
        let _this = this;
        document.removeEventListener('mouseout', _this.addListenersOnHover());
        document.removeEventListener('mouseover', _this.addListenersOnHover());
    }

	getTabulatorColumns(columns) {
        let _this = this;
		columns = columns || [];
		columns.forEach(col => {
            col.headerClick = function(e, column) {
                setTimeout(() => {
                    _this.addListeners();
                }, 50);
            }
			col.formatter = this.getColumnFormatter(col.field);
            if(col.field === FISCAL_YEAR.FIELDS.ACTIONS){
                col.cssClass = "rightAlign";
            }
            if(col.field === FISCAL_YEAR.FIELDS.YEAR){
                col.cssClass = "uk-margin-large-left";
            }
        });
        return columns;
	}

	shouldComponentUpdate(nextProps) {
		var shouldUpdate = false;
        shouldUpdate = nextProps.tableData && (JSON.stringify(nextProps.tableData) !== JSON.stringify(this.tabulator.getData())) ? true : false;

        return shouldUpdate;
	}
	
	
	componentDidUpdate(prevProps) {
		var tableColumns = copyObjectValues(this.props.tableColumns);
		var tableData = this.props.tableData;
		this.tabulator.setData(tableData);
		
		//set column formatters and return column objects
		tableColumns = cleanUpTabulatorColumns(tableColumns, null, this.refreshFilterDivs, this.tabulator, {id: prevProps.id});

		//settings the columns again to replace the checkbox column
		tableColumns = this.getTabulatorColumns(tableColumns);
		
		this.tabulator.setColumns(tableColumns);
        this.addListeners();

        if(this.props.tableData && JSON.stringify(this.props.tableData) !== JSON.stringify(this.state.tableData)) {
			this.state.tableData = this.props.tableData;
		}
	}

	onTabulatorRenderComplete(test) {
		if(!this.tabulator)
            return;
    }
    
    toggleTable(shouldShow) {
        this.tabulator.setHeight(shouldShow ? convertPxToViewport(360, true) : 0);
    }

	componentDidMount() {
        let _this = this;
		//setup tabulator
		var options = {
			layout: "fitColumns",      //fit columns to width of table
			responsiveLayout: false,  //hide columns that dont fit on the table
			addRowPos: "top",          //when adding a new row, add it to the top of the table
			history: true,             //allow undo and redo actions on the table
			pagination: false, //"local",       //paginate the data
			movableColumns: false,     //allow column order to be changed
			autoResize: true,
			resizableRows: false,       //allow row order to be changed
			selectable: false,
			resizableColumns: true,
			virtualDomBuffer: 800,
			placeholder: "Press on the add period button on the top right of this table to add your first period",
			renderComplete:this.onTabulatorRenderComplete,
            scrollVertical:function(){
                _this.addListeners();
            },
            invalidOptionWarnings: false,
            height: convertPxToViewport(650, true),
            width:"100%",
			// tooltips:function(column){
			// 	return column._cell.value;
			// }
		}
        this.tabulator = new TabulatorFull(this.refs.mainTable, options);
        // if (this.props.id === "period_main_table") {
        //     this.toggleTable(false);
        // }
        this.props.setLimits();
	}

    /**
     * function that trigerrs on every click on the screen, if the target of the click is outside the three dots, hide them 
     * @param {*} e 
     * @param {*} _this 
     */
    hideThreeDots=(e, _this)=>{
        let container = $("#dropDown_"+this.savedRowNumber);
        if ((!container.is(e.target) && container.has(e.target).length === 0) && (container[0] && e.target && e.target.parentElement && e.target.parentElement.children[1] && container[0].id !== e.target.parentElement.children[1].id)) {
            if(this.savedRowNumber >=0){
                $("#actions_"+_this.savedRowNumber).css("visibility", "hidden");// when clicking outside the three dots, hide them 
                this.savedRowNumber = -1;
            }
        }
    }


	render() {
        let _this = this;
        $(document).click(function(e) {
            _this.hideThreeDots(e, _this);
        });

		return(
			<div id={this.props.id} ref="mainTable" style={{border: "0.052vw solid rgb(206, 206, 206)"}} />
		);

	}

}

export default periodTable;