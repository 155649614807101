import { getTranslationFile } from "../../class/utils";

const lang = getTranslationFile();

const uploadRejectedMessage = (file) => {
    var elem = file?.invalid;
    var range = file?.start_period !== file?.end_period ? file?.start_period + " to " + file?.end_period : file?.start_period ;
    console.log(elem);
    if(elem){
        switch(elem.toString()) {
            case lang.monthly_build.rejected_upload_messages.data_source_is_not_defined:
                return lang.monthly_build.rejected_upload_messages.reply_data_source_is_not_defined;
            case lang.monthly_build.rejected_upload_messages.period_bad_format:
                return lang.monthly_build.rejected_upload_messages.period_bad_format;
            case lang.monthly_build.rejected_upload_messages.periods_are_not_within_defined_range_in_data_source:
                return lang.monthly_build.rejected_upload_messages.reply_periods_are_not_within_defined_range_in_data_source + range;
            case lang.monthly_build.rejected_upload_messages.encryption_key_is_not_correct:
                return lang.monthly_build.rejected_upload_messages.reply_encryption_key_is_not_correct;
            case lang.monthly_build.rejected_upload_messages.crosswalk_not_configured:
                return lang.monthly_build.rejected_upload_messages.crosswalk_not_configured;
            case lang.monthly_build.rejected_upload_messages.crosswalk_period_not_matching:
                return lang.monthly_build.rejected_upload_messages.crosswalk_period_not_matching;
        }
    }
}



export {uploadRejectedMessage};