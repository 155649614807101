import { convertPxToViewport } from "../class/formatting";

const OptionsList = ({ options, className }) => {
  const renderList = () => {
    let renderList = [];
    options?.forEach((opt) => {
      let color = opt.color 
      renderList.push(
        <div key={"div-" + opt.value}>
          <li className="uk-flex uk-flex-middle uk-padding-xxsmall">
            <div
              className="colored-circle uk-margin-small-right"
              style={opt.color ? { backgroundColor: opt.color } : {}}
            >
            </div>
            <div style={{width: convertPxToViewport(20), height: convertPxToViewport(20),  marginRight:convertPxToViewport(10), display: "inline-block", verticalAlign:"middle"}} className={"onlyprint-inline"} >
              <svg width={convertPxToViewport(20)} height={convertPxToViewport(20)}>
                  <rect width={convertPxToViewport(20)} height={convertPxToViewport(20)} style={{"fill":color}} />
              </svg>
            </div>
            <label htmlFor="option-list" className="fs-14 no-events-cells">
              {opt.label}
            </label>
          </li>
        </div>
      );
    });

    return renderList;
  };

  return (
    <ul className={"toggle-options-container uk-flex uk-flex-center uk-flex-column uk-width-auto@m " + className}>
      {renderList()}
    </ul>
  );
};

export default OptionsList;
