import React, { Component } from 'react';
import { getTranslationFile, copyObjectValues,capitaliseFirstLetterAfterChar, removeDuplicatesObjects, deepCompareObjects } from '../../class/utils';
import { CALCULATED_COLUMNS as CALC_COLS, FORMULA_ELEMENT_TYPE, STAGING_SECTIONS_TITLES,STAGING_SECTIONS,ALL_WIDGETS, ENGINE_FILTER, ROW_STATUS, RAND, ROW_NUMBER, BUTTON_VARIANT, SIZES, BUTTON_TYPE, DIALOG_SIZE, SCENARIO_STATUS} from '../../class/constants';
import ControlButtons from './ControlButtons';
import FormulaDrop from './FormulaDrop';
import FormulaCondition from './FormulaCondition';
import { addSimpleTooltipIcon } from '../../class/common';
import FileColumnsContainer from './FileColumnsContainer';
import { checkForSpecialChars, checkStartsWithDigit } from '../../class/string';
import { deleteEmptyIndices } from '../../class/array';
import Input from '../../newComponents/Input';
import Button from '../../newComponents/Button';
import Modal from '../../newComponents/Modal';
import RadioButton from '../../newComponents/RadioButton';

const $ = require('jquery');
const lang = getTranslationFile();
const UIkit = require('uikit');
const _empty = lang.ui_filter.dropdowns.functions.empty.value;
const _nempty = lang.ui_filter.dropdowns.functions.not_empty.value;


const _file = ENGINE_FILTER.KEYS.FILE;
const _column = ENGINE_FILTER.KEYS.COLUMN;
const _function = ENGINE_FILTER.KEYS.FUNCTION;
const _values = ENGINE_FILTER.KEYS.VALUES;
const _fieldDataType = ENGINE_FILTER.KEYS.FIELD_DATA_TYPE;
const _rawFileSubtypeId = ENGINE_FILTER.KEYS.RAW_FILE_SUBTYPE_ID;
const _dataFileType = ENGINE_FILTER.KEYS.DATA_FILE_TYPE;
const _fileType = ENGINE_FILTER.KEYS.FILE_TYPE;

const SAMPLE_CONDITION_OBJ = {
    filters: [],
    result: []
};

class ColumnFormula extends Component {
    constructor(props) {
        super(props);

        this.appendToFormula = this.appendToFormula.bind(this);
        this.setRowFormula = this.setRowFormula.bind(this);
        this.addCondition = this.addCondition.bind(this);
        this.setFormulaIndex = this.setFormulaIndex.bind(this);
        this.deleteLastFormulaItem = this.deleteLastFormulaItem.bind(this);
        this.resetFormulaChanges = this.resetFormulaChanges.bind(this);
        this.deleteFormula = this.deleteFormula.bind(this);
        this.deleteCondition = this.deleteCondition.bind(this);
        this.extractFormulaColumns = this.extractFormulaColumns.bind(this);
        this.getUnusedColumns = this.getUnusedColumns.bind(this);
        this.validateFormula = this.validateFormula.bind(this);
        this.validateFormulaFilters = this.validateFormulaFilters.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFilterRowChange = this.handleFilterRowChange.bind(this);
        this.getIsColumnUsed = this.getIsColumnUsed.bind(this);
        this.showMessage = this.showMessage.bind(this);
        this.validateColName = this.validateColName.bind(this);
        this.initFormula = this.initFormula.bind(this);
        this.updateFileName = this.updateFileName.bind(this);
        this.updateColumnName = this.updateColumnName.bind(this);

        this.conditionRefs = [];
        this.finalFormula = {
            result: [],
            conditions: []
        };

        this.isAdvanced = false;
        this.colName = "";
        this.colDescription = "";
        this.isNameValid = "";
        this.fileColumnsRef = null;

        let initialState = this.initFormula();
        this.state = Object.assign({}, {
            formulaColumns: [],
            filterValues: {},
            calcColsData: [],
            fileOptions: [],
            columnOptions: []
        }, initialState);
    }

    deleteLastFormulaItem(conditionNumber) {
        if(conditionNumber !== undefined) {
            this.finalFormula.conditions[conditionNumber].result.pop();
        } else {
            this.finalFormula.result.pop();
        }
        this.setState(this.state);
    }

    resetFormulaChanges(conditionNumber) {
        var tempResult = [];
        //read from the unchanged formula, if the condition doesnt exist in the unchanged obj,
        //it's a new one, reset it by emptying it, hence "[]"
        var resultToLoop = conditionNumber !== undefined ? this.finalFormula_unchanged.conditions[conditionNumber] ?
        this.finalFormula_unchanged.conditions[conditionNumber].result : [] : this.finalFormula_unchanged.result;
        resultToLoop.map((item)=>{
            tempResult.push(item);
        });

        if(conditionNumber !== undefined) {
            this.finalFormula.conditions[conditionNumber].result = tempResult;
        } else {
            this.finalFormula.result = tempResult;
        }
        this.setState(this.state);
    }

    deleteFormula(conditionNumber) {
        if(conditionNumber !== undefined) {
            this.finalFormula.conditions[conditionNumber].result = [];
        } else {
            this.finalFormula.result = [];
        }
        this.setState(this.state);
    }

    addCondition() {
        this.finalFormula.conditions.push(copyObjectValues(SAMPLE_CONDITION_OBJ));
        this.conditionRefs.push({[RAND]: Math.random()});
        this.setState(this.state);
    }

    deleteCondition(conditionNumber) {
        this.finalFormula.conditions.splice(conditionNumber, 1);
        this.conditionRefs.splice(conditionNumber, 1);
        this.setRowFormula(false);

        this.setState(this.state);  //to rerender and update DOM
    }

    extractFormulaColumns(fromMount) {
        let formulaColumns = [];
        if(this.finalFormula.conditions && this.finalFormula.conditions.length) {
            this.finalFormula.conditions.map(cond=>{
                if(cond.result.length) {
                    cond.result.map(el=>{
                        if(el.type === FORMULA_ELEMENT_TYPE.COLUMN) {
                            formulaColumns.push(el)
                        }
                    });
                }
            })
        }

        if(this.finalFormula.result) {
            this.finalFormula.result.map(el=>{
                if(el.type === FORMULA_ELEMENT_TYPE.COLUMN) {
                    formulaColumns.push(el)
                }
            })
        }
        
        for (var e in formulaColumns){
            formulaColumns[e][CALC_COLS.FIELDS.COLUMN_FIELDS.COLUMN_INDEX] = formulaColumns[e][CALC_COLS.FIELDS.COLUMN_FIELDS.COLUMN_INDEX].toString();
        }

        formulaColumns = removeDuplicatesObjects(formulaColumns);

        //rearrange them to the same order they were in originally to maintain the column index
        let finalCols = [];
        formulaColumns.forEach(col=>{
            let colIndex = col[CALC_COLS.FIELDS.COLUMN_FIELDS.COLUMN_INDEX];
            let arrIndex = Number(colIndex) - 1;
            if(finalCols[arrIndex]) {
                //splice inserts at a certain index, only if the length of the array is greater than this index
                finalCols.splice(Number(colIndex) - 1, 0, col);
            } else {
                finalCols[arrIndex] = col;
            }
        });
        /*  after the previous forEach, if the user had two columns defined, and used the one at index 1 in the formula,
            the one at index 0 would be discarded cz it's not used. So formulaColumns would be [empty, {}] which would cause
            wrong behaviours. This is why after filling the previous array with cols exactly as saved, we have to clean it
        */

        if(fromMount) {
            deleteEmptyIndices(finalCols);      //delete the empty indices if any
            finalCols.forEach((col, i)=>{       //update the colIndex attribute to reflect the correct index and not the old one
                col[CALC_COLS.FIELDS.COLUMN_FIELDS.COLUMN_INDEX] = i + 1;
                if(col[CALC_COLS.FIELDS.COLUMN_FIELDS.FILE] && col[CALC_COLS.FIELDS.COLUMN_FIELDS.COLUMN]) {
                    col[CALC_COLS.FIELDS.COLUMN_FIELDS.FILE][ROW_NUMBER] = i;
                    col[CALC_COLS.FIELDS.COLUMN_FIELDS.COLUMN][ROW_NUMBER] = i;
                }
            });
        }
        
        return finalCols;
    }

    getUnusedColumns() {
        let usedColumns = this.extractFormulaColumns();
        usedColumns = usedColumns.map(col=>{
            return col[CALC_COLS.FIELDS.COLUMN_FIELDS.COLUMN] ? col[CALC_COLS.FIELDS.COLUMN_FIELDS.COLUMN].label : undefined;
        });

        let createdColumns = this.fileColumnsRef.state.data.map(col=>{
            return col[CALC_COLS.FIELDS.COLUMN_FIELDS.COLUMN] ? col[CALC_COLS.FIELDS.COLUMN_FIELDS.COLUMN].label : undefined;
        });

        let unusedColumns = [];
        createdColumns.map(col=>{
            if(!usedColumns.includes(col)) {
                unusedColumns.push(col);
            }
        });
        
        return unusedColumns;
    }

    /**
     * this function replaces componentWillMount cz it will be deprecated,
     * also, having getDerivedStateFromProps present prevents the call to willMount
     */
    initFormula() {
        let _this = this;
        let tempFormula = copyObjectValues(this.props.operatedColumn[CALC_COLS.FIELDS.FORMULA]);
        this.finalFormula = tempFormula && tempFormula.conditions && tempFormula.result ? tempFormula : this.finalFormula;
        this.finalFormula_unchanged = copyObjectValues(this.finalFormula);
        this.colName = this.props.operatedColumn[CALC_COLS.FIELDS.DISPLAY_NAME] || "";
        this.colDescription = this.props.operatedColumn[CALC_COLS.FIELDS.DESCRIPTION] || "";

        //updating conditionRefs length
        this.finalFormula.conditions.forEach(()=>{
            _this.conditionRefs.push({[RAND]: Math.random()});
        })

        let colMappingType = "";
        if(this.props.operatedColumn[CALC_COLS.FIELDS.MAPPING_TYPE]) {
            //cond if the obj already has a mapping type attribute
            colMappingType = this.props.operatedColumn[CALC_COLS.FIELDS.MAPPING_TYPE];
        } else if(this.finalFormula.conditions.length > 0 && !deepCompareObjects(this.finalFormula.conditions[0], SAMPLE_CONDITION_OBJ)) {
            //cond if the obj has more than one condition, or at least its condition is not an empty sample
            colMappingType = CALC_COLS.VALUES.MAPPING_TYPE.advanced;
        } else {
            colMappingType = CALC_COLS.VALUES.MAPPING_TYPE.basic;
        }
        this.isAdvanced = colMappingType === CALC_COLS.VALUES.MAPPING_TYPE.advanced;

        //extracting array of columns from all the formula results
        let formulaColumns = this.extractFormulaColumns(true);

        let tempState = {};
        tempState.formulaColumns = formulaColumns;

        return tempState;
    }


    static getDerivedStateFromProps(props, state) {
        let tempState = {};
        let update = false;
        if(props.calcColsData && !deepCompareObjects(props.calcColsData, state.calcColsData)) {
            let data = copyObjectValues(props.calcColsData);
            tempState.calcColsData = data;
            update = true;
        }

        if(props.calculatedColsFileOptions && !deepCompareObjects(props.calculatedColsFileOptions, state.fileOptions)) {
            let fileOptions = copyObjectValues(props.calculatedColsFileOptions);
            fileOptions.push({label: STAGING_SECTIONS_TITLES.CALCULATED, value: STAGING_SECTIONS.CALCULATED_COLUMNS});
            tempState.fileOptions = fileOptions;
            update = true;
        }

        if(update) {
            return tempState;
        }
        return null;    //do not update state
    }

    validateColName(value) {
        var _this = this;
        if(checkForSpecialChars(value) || checkStartsWithDigit(value)) {
            return lang.name_validation.invalid_name;
        }

        let isDuplicate = false;
        this.state.calcColsData.forEach((col, index)=>{
            if(col[CALC_COLS.FIELDS.DISPLAY_NAME].toLowerCase() === value.toLowerCase() && _this.props.rowPosition !== index && col[ROW_STATUS.FIELD] !== ROW_STATUS.VALUES.DELETED) {
                isDuplicate = true;
                return;
            }
        });
        
        if (isDuplicate) {
            return lang.name_validation.already_used;
        }

        return true;
    }

    updateFileName(e, onReset) {
        let basicFormula = copyObjectValues(this.finalFormula.result);
        let advancedFormula = copyObjectValues(this.finalFormula.conditions);

        basicFormula.map(item => {
            if(item.colIndex === e.rowNumber+1) {
                item[CALC_COLS.FIELDS.COLUMN_FIELDS.FILE][CALC_COLS.FIELDS.COLUMN_FIELDS.LABEL] = e.label;
                item[CALC_COLS.FIELDS.COLUMN_FIELDS.FILE][CALC_COLS.FIELDS.COLUMN_FIELDS.VALUE] = e.value;
                item[CALC_COLS.FIELDS.COLUMN_FIELDS.FILE][_dataFileType] = e[_dataFileType];
                item[CALC_COLS.FIELDS.COLUMN_FIELDS.FILE][_rawFileSubtypeId] = e[_rawFileSubtypeId];
                if(!onReset) {
                    item[CALC_COLS.FIELDS.COLUMN_FIELDS.COLUMN][CALC_COLS.FIELDS.COLUMN_FIELDS.LABEL] = "";
                    item[CALC_COLS.FIELDS.COLUMN_FIELDS.COLUMN][CALC_COLS.FIELDS.COLUMN_FIELDS.VALUE] = "";
                }
            }
        })
        this.finalFormula.result = basicFormula;

        advancedFormula.forEach((cond, condIndex)=>{
            cond.result.forEach(item=>{
                if(item[CALC_COLS.FIELDS.COLUMN_FIELDS.TYPE] === FORMULA_ELEMENT_TYPE.COLUMN && Number(item[CALC_COLS.FIELDS.COLUMN_FIELDS.COLUMN_INDEX]) === e.rowNumber+1) {
                    item[CALC_COLS.FIELDS.COLUMN_FIELDS.FILE][CALC_COLS.FIELDS.COLUMN_FIELDS.LABEL] = e.label;
                    item[CALC_COLS.FIELDS.COLUMN_FIELDS.FILE][CALC_COLS.FIELDS.COLUMN_FIELDS.VALUE] = e.value;
                    item[CALC_COLS.FIELDS.COLUMN_FIELDS.FILE][_dataFileType] = e[_dataFileType];
                    item[CALC_COLS.FIELDS.COLUMN_FIELDS.FILE][_rawFileSubtypeId] = e[_rawFileSubtypeId];
                    if(!onReset) {
                        item[CALC_COLS.FIELDS.COLUMN_FIELDS.COLUMN][CALC_COLS.FIELDS.COLUMN_FIELDS.LABEL] = "";
                        item[CALC_COLS.FIELDS.COLUMN_FIELDS.COLUMN][CALC_COLS.FIELDS.COLUMN_FIELDS.VALUE] = "";
                    }
                }
            })
        })
        this.finalFormula.conditions = advancedFormula;
        onReset = false;
        this.setState(this.state);
    }

    updateColumnName(e, onReset) {
        let basicFormula = copyObjectValues(this.finalFormula.result);
        let advancedFormula = copyObjectValues(this.finalFormula.conditions);

        basicFormula.map(item => {
            if(item.colIndex === e.rowNumber+1) {
                item[CALC_COLS.FIELDS.COLUMN_FIELDS.COLUMN][CALC_COLS.FIELDS.COLUMN_FIELDS.LABEL] = e.label;
                item[CALC_COLS.FIELDS.COLUMN_FIELDS.COLUMN][CALC_COLS.FIELDS.COLUMN_FIELDS.VALUE] = e.value;
                item[CALC_COLS.FIELDS.COLUMN_FIELDS.COLUMN][_fieldDataType] = e[_fieldDataType];
                item[CALC_COLS.FIELDS.COLUMN_FIELDS.COLUMN][_fileType] = e[_fileType];
                item[CALC_COLS.FIELDS.COLUMN_FIELDS.COLUMN][_rawFileSubtypeId] = e[_rawFileSubtypeId];
                item[CALC_COLS.FIELDS.COLUMN_FIELDS.VALUE] = e.value;
            }
            return item;
        })
        this.finalFormula.result = basicFormula;

        advancedFormula.forEach((cond, condIndex)=>{
            cond.result.forEach(item=>{
                if(item[CALC_COLS.FIELDS.COLUMN_FIELDS.TYPE] === FORMULA_ELEMENT_TYPE.COLUMN && Number(item[CALC_COLS.FIELDS.COLUMN_FIELDS.COLUMN_INDEX]) === e.rowNumber+1) {
                    item[CALC_COLS.FIELDS.COLUMN_FIELDS.COLUMN][CALC_COLS.FIELDS.COLUMN_FIELDS.LABEL] = e.label;
                    item[CALC_COLS.FIELDS.COLUMN_FIELDS.COLUMN][CALC_COLS.FIELDS.COLUMN_FIELDS.VALUE] = e.value;
                    item[CALC_COLS.FIELDS.COLUMN_FIELDS.COLUMN][_fieldDataType] = e[_fieldDataType];
                    item[CALC_COLS.FIELDS.COLUMN_FIELDS.COLUMN][_fileType] = e[_fileType];
                    item[CALC_COLS.FIELDS.COLUMN_FIELDS.COLUMN][_rawFileSubtypeId] = e[_rawFileSubtypeId];
                    item[CALC_COLS.FIELDS.COLUMN_FIELDS.VALUE] = e.value;
                }   
            })
        })
        this.finalFormula.conditions = advancedFormula;
        onReset = false;
        this.setState(this.state);
    }

    handleChange(attr, e, callback, onReset) {
        var _this = this;
        let value = "";

        if(e.target) {
            value = $(e.target).val();
        } else {
            value = e.value;
        }

        switch(attr) {
            case CALC_COLS.FIELDS.DISPLAY_NAME:
                let isNameValid = this.validateColName(value);
                if(typeof isNameValid === "string") {
                    this.isNameValid = isNameValid;
                } else {
                    this.isNameValid = "";
                }
                this.colName = value;
                this.forceUpdate();
                break;
                
            case CALC_COLS.FIELDS.DESCRIPTION:
                this.colDescription = value;
                break;

            case CALC_COLS.FIELDS.MAPPING_TYPE:
                this.isAdvanced = value === CALC_COLS.VALUES.MAPPING_TYPE.advanced;
                if(this.isAdvanced && !this.finalFormula.conditions.length) {
                    this.addCondition();
                } else if(!this.isAdvanced){
                    this.finalFormula.conditions.forEach((condIndex)=>{
                        _this.deleteCondition(condIndex);   //deleting all conditions
                    })
                } else {
                    this.setState(this.state);
                }
                break;

            case CALC_COLS.FIELDS.COLUMN_FIELDS.FILE:
                if(value === STAGING_SECTIONS.CALCULATED_COLUMNS) {
                    let _this = this;
                    //set column options as calculated columns from the table
                    let columnOptions = this.state.calcColsData.filter(c=>c[CALC_COLS.FIELDS.DISPLAY_NAME] !== _this.colName).map(col=>{
                        let obj = {};
                        obj.label = col[CALC_COLS.FIELDS.DISPLAY_NAME];
                        obj.value = col[CALC_COLS.FIELDS.DISPLAY_NAME];
                        return obj;
                    });
                    callback(columnOptions);
                } else {
                    this.props.getAllFileTypeColumns({section: "", subSection: e.value}, undefined, undefined, undefined, undefined, undefined, callback);
                }
                
                this.updateFileName(e, onReset);
                break;
                
            case CALC_COLS.FIELDS.COLUMN_FIELDS.COLUMN:
                this.updateColumnName(e, onReset);
                break;
        }
    }

    appendToFormula(value, type, className, file, column, colIndex) {
        //define which result is being filled
        let formulaIndex = this.state.formulaIndex;     //using selected formula
        var resultFilled = formulaIndex !== undefined ? this.finalFormula.conditions[formulaIndex].result : this.finalFormula.result;
        //take a copy of the value of this result in order to manipulate it
        var result = copyObjectValues(resultFilled);
        result.push({
            [CALC_COLS.FIELDS.COLUMN_FIELDS.TYPE]: type,
            [CALC_COLS.FIELDS.COLUMN_FIELDS.VALUE]: value,
            [CALC_COLS.FIELDS.COLUMN_FIELDS.CLASS]: className,
            [CALC_COLS.FIELDS.COLUMN_FIELDS.FILE]: file,
            [CALC_COLS.FIELDS.COLUMN_FIELDS.COLUMN]: column,
            [CALC_COLS.FIELDS.COLUMN_FIELDS.COLUMN_INDEX]: colIndex
        });

        if(formulaIndex !== undefined) {
            this.finalFormula.conditions[formulaIndex].result = result;
        } else {
            this.finalFormula.result = result;
        }
        this.forceUpdate();
    }

    setFormulaIndex(formulaIndex) {
        this.setState({
            formulaIndex: formulaIndex
        })
    }

    showMessage(message, onConfirmCB) {
      this.setInfoDialogOpen(true, message, onConfirmCB)
    }

    getIsColumnUsed(rowNumber) {
        let returnObj = {
            isUsed: false,
            formulaIndex: -1,
            isMainFormula: false
        }

        this.finalFormula.result.forEach(item=>{
            if(item[CALC_COLS.FIELDS.COLUMN_FIELDS.TYPE] === FORMULA_ELEMENT_TYPE.COLUMN && Number(item[CALC_COLS.FIELDS.COLUMN_FIELDS.COLUMN_INDEX]) === rowNumber) {
                returnObj.isUsed = true;
                returnObj.isMainFormula = true;
                return;
            }
        });

        if(!returnObj.isUsed) {
            this.finalFormula.conditions.forEach((cond, condIndex)=>{
                cond.result.forEach(item=>{
                    if(item[CALC_COLS.FIELDS.COLUMN_FIELDS.TYPE] === FORMULA_ELEMENT_TYPE.COLUMN && Number(item[CALC_COLS.FIELDS.COLUMN_FIELDS.COLUMN_INDEX]) === rowNumber) {
                        returnObj.isUsed = true;
                        returnObj.formulaIndex = condIndex;
                        return;
                    }
                })
            })
        }

        return returnObj;
    }

    validateFormula() {
        let _this = this;
        let validation = {};
        validation.filtersValidated = this.validateFormulaFilters();
        if(!validation.filtersValidated) {
            return validation.filtersValidated;
        }

        //validate that all columns used in the formula exist
        validation.columnsValidated = this.validateFormulaColumns(this.finalFormula.result);
        if(validation.columnsValidated) {
            this.finalFormula.conditions.forEach(cond=>{
                validation.columnsValidated = _this.validateFormulaColumns(cond.result);
                if(!validation.columnsValidated) {
                    return validation.columnsValidated;
                }
            });
        }

        return validation;
    }

    validateFormulaColumns(formulaResult) {
        let columnsValidated = true;
        var createdColumns = this.fileColumnsRef.state.data;
        formulaResult.forEach(item=>{
            if(item[CALC_COLS.FIELDS.COLUMN_FIELDS.TYPE] === FORMULA_ELEMENT_TYPE.COLUMN) {
                let colIndex = item[CALC_COLS.FIELDS.COLUMN_FIELDS.COLUMN_INDEX];
                let arrIndex = Number(colIndex) - 1;
                let _fileKey = CALC_COLS.FIELDS.COLUMN_FIELDS.FILE;
                let _columnKey = CALC_COLS.FIELDS.COLUMN_FIELDS.COLUMN;
                let fileValue = item[_fileKey] ? item[_fileKey].value : "";
                let columnValue = item[_columnKey] ? item[_columnKey].value : "";

                if(!createdColumns[arrIndex]){
                    //if the created cols and used col have different indices
                    columnsValidated = false;
                    return columnsValidated;
                }
                if(!createdColumns[arrIndex][_fileKey] || createdColumns[arrIndex][_fileKey].value !== fileValue) {
                    //if the column dropdown file is not the same as the one at the same index in the formula
                    columnsValidated = false;
                    return columnsValidated;
                }
                if(!createdColumns[arrIndex][_columnKey] || createdColumns[arrIndex][_columnKey].value !== columnValue) {
                    //if the column dropdown column is not the same as the one at the same index in the formula
                    columnsValidated = false;
                    return columnsValidated;
                }
            }
        });

        return columnsValidated;
    }

    validateFormulaFilters() {
        var obj = this;
        var isValidated = true;

        //validate that all filter rows are completely filled
        Object.keys(obj.conditionRefs).forEach(key=>{
            if(!obj.conditionRefs[key]) {return;}
            var filterRefs = obj.conditionRefs[key].ref.filterDialRef.state.filterRefs;
            if (filterRefs.length === 0) {
                isValidated = false;
                return;
            }
            //extract condition filters
            Object.keys(filterRefs).forEach(key2=>{
                if(!filterRefs[key2].ref.current) {return;}
                let tempRowVal = filterRefs[key2].ref.current.filterObj;

                //any operator different than empty or not empty and the value is not specified
                if ([_empty, _nempty].indexOf(tempRowVal[_function]) === -1 && (tempRowVal[_values] === "" || !tempRowVal[_values].length)) {
                    isValidated = false;
                    return;
                }

                Object.keys(tempRowVal).forEach(key3=>{
                    if([_file, _column, _function, _values].includes(key3) && (!tempRowVal[key3] || !tempRowVal[key3].length)) {
                        if (key3 === _values && [_empty, _nempty].includes(tempRowVal[_function])) {
                            //values are allowed to be empty only in case function is "empty" or "not empty"
                            return;
                        }
                        isValidated = false;
                        return;
                    }
                })

                if(!isValidated){
                    return;
                }
            });

            if(isValidated) {
                isValidated = this.formulaDRef.validateFormulaResult(obj.finalFormula.conditions[key].result);
            }

            if(!isValidated) {
                return;
            }
        });

        if(isValidated) {
            isValidated = this.formulaDRef.validateFormulaResult(this.finalFormula.result);
        }

        return isValidated;
    }

    setRowFormula(setInParentComponent) {
        //this function only deletes the options before setting the formula bc there
        //is no need to send this huge amnont of data to the api
        var _this = this;
        function deleteOptions(filterRow) {
            Object.keys(filterRow).forEach(key=> {
                if(key.includes("options")) {
                    delete filterRow[key];
                }
            });
            return filterRow;
        }

        //fetch conditions and results from children
        Object.keys(this.conditionRefs).forEach(key=>{
            if(!_this.conditionRefs[key]) {return;}
            var filterRefs = _this.conditionRefs[key].ref.filterDialRef.state.filterRefs;
            var conditionFilters = [];

            //extract condition filters
            Object.keys(filterRefs).forEach(key2=>{
                if(!filterRefs[key2].ref.current) {return;}
                let filterRow = filterRefs[key2].ref.current.filterObj;
                filterRow = deleteOptions(filterRow);
                conditionFilters.push(filterRow);
            });

            //saving the current condition
            _this.finalFormula.conditions[key].filters = conditionFilters;
        });
        
        if(setInParentComponent) {
            let unusedColumns = this.getUnusedColumns();
            let formulaValidation = this.validateFormula();
            if(!formulaValidation.filtersValidated || !formulaValidation.columnsValidated || !this.colName || !this.colName.length || this.isNameValid !== "" || unusedColumns.length) {
                var message = "";
                var callback = null;
                if(!formulaValidation.filtersValidated) {
                    message = lang.calc_cols.fill_condition_correctly_err;
                } else if(!formulaValidation.columnsValidated) {
                    message = lang.calc_cols.column_formula_mismatch;
                } else if(!this.colName || !this.colName.length) {
                    message = lang.calc_cols.missing_name;
                } else if(this.isNameValid !== "") {
                    message = this.isNameValid;
                } else if(unusedColumns.length) {
                    if(unusedColumns.includes(undefined)) {
                        message = lang.calc_cols.empty_columns;
                    } else {
                        message = unusedColumns.join(", ") + (unusedColumns.length > 1 ? lang.unused_columns_will_be_deleted_many : lang.unused_columns_will_be_deleted_single);
                        callback = ()=>{
                            _this.props.setRowFormula(_this.finalFormula, _this.colName, _this.colDescription, _this.isAdvanced);
                        };
                    }
                }
                
                this.setInfoDialogOpen(true, message, callback)
                return;
            }
            this.props.setRowFormula(this.finalFormula, this.colName, this.colDescription, this.isAdvanced);
        }
        var rightTile = capitaliseFirstLetterAfterChar(ALL_WIDGETS.FIELDS.STAGE)+ " " + STAGING_SECTIONS_TITLES.TRANSACTION + " - " + capitaliseFirstLetterAfterChar(STAGING_SECTIONS.CALCULATED_COLUMNS.split("_")[0]) + " " + capitaliseFirstLetterAfterChar(STAGING_SECTIONS.CALCULATED_COLUMNS.split("_")[1])
        $(".filter-control-titles").find("p").text(rightTile)
        $("#header-next").removeClass("disabled");
    }
    
    handleFilterRowChange(object, attribute, rowIndex, conditionIndex, valueObj, inputValue, callback) {
        var tempState = this.state.filterValues;
        tempState[conditionIndex] = tempState[conditionIndex] || {};
        tempState[conditionIndex][rowIndex] = tempState[conditionIndex][rowIndex] || {};
        var tempRow = tempState[conditionIndex][rowIndex];
        switch (attribute){
            case _file:
                tempRow[_file] = valueObj[_rawFileSubtypeId];
                this.props.getAllFileTypeColumns({section:"", subSection:valueObj.value}, undefined, conditionIndex, undefined, undefined,  rowIndex, callback);
                break;
            case _column:
                tempRow[_column] = valueObj.value;
                //fetching the values of this column
                this.props.getColumnValues(tempRow[_file], valueObj[_fieldDataType], tempRow[_column], conditionIndex, valueObj[_function], rowIndex, "", callback);
                break;
        }
    }

    handleConditionRef(condIndex, formulaConditionRef) {
        if(formulaConditionRef) {
            this.conditionRefs[condIndex] = this.conditionRefs[condIndex] ? this.conditionRefs[condIndex] : {};
            this.conditionRefs[condIndex].ref = formulaConditionRef;
        }
    }

    getFileName(fileName) {
        this.setState({
            fileName: fileName,
            columnName: ""
        })
    }

    getColName(columnName) {
        this.setState({
            columnName: columnName
        })
    }

    setInfoDialogOpen = (isOpen, infoMsg, callback=() => {}) => {
      let _this = this;
      _this.setState({
        openInfoDialog: isOpen,
        modalMessage: infoMsg,
        onSubmitCallback: callback
      })
    }

  openInfoDialogActions = () => {
    return (
      <>
        {this.state.onSubmitCallback &&
          <Button
            label={lang.modal.buttons.continue}
            variant={BUTTON_VARIANT.PRIMARY}
            size={SIZES.DEFAULT}
            type={BUTTON_TYPE.DEFAULT}
            onBtnClick={() => { 
              this.state.onSubmitCallback();
              this.setInfoDialogOpen(false)
            }}
          />
        }
        <Button
          label={this.state.onSubmitCallback ? lang.modal.buttons.cancel : lang.modal.buttons.ok}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => this.setInfoDialogOpen(false)}
        />
      </>


    )
  }

    render() {
        var _this = this;
        var isUsedInMapping = this.props.operatedColumn[CALC_COLS.FIELDS.IS_USED_IN_MAPPING];
        var conditions = [];
        if(this.isAdvanced) {
            for(var c = this.finalFormula.conditions.length; c > 0; c--) {
                let condIndex = c - 1;
                conditions.push(
                    <FormulaCondition ref={r => _this.handleConditionRef(condIndex, r)} appendToFormula={_this.appendToFormula}
                        key={"formulaConditionRow_" + _this.conditionRefs[condIndex][RAND]}
                        scenarioId={_this.props.scenarioId} conditionsTotal={_this.finalFormula.conditions.length}
                        conditionNumber={condIndex} conditionObject={_this.finalFormula.conditions[condIndex]}
                        deleteLastFormulaItem={_this.deleteLastFormulaItem} deleteFormula={_this.deleteFormula}
                        setFormulaIndex={_this.setFormulaIndex} resetFormulaChanges={_this.resetFormulaChanges}
                        deleteCondition={_this.deleteCondition} setRowFormula={_this.setRowFormula}
                        handleFilterRowChange={_this.handleFilterRowChange} fileOptions={_this.state.fileOptions}
                        columnOptions={_this.props.numericColumns}
                    />
                );
            }
        }

        return(
            <div id={"calculated-column-container"} style={{height: "100%", display: "grid", gridAutoRows: "auto"}}>
                <div className="mapping-header">
                    <span className="uk-text-bold uk-text-xmedium uk-display-flex uk-flex-middle">{lang.calc_cols.calculated_column_title}</span>
                    {addSimpleTooltipIcon("TBD")}
                </div>
                <div style={{height: "100%", overflow: "auto", position: "relative"}}>
                  <div className="uk-display-flex uk-flex-between uk-padding-xmedium-top uk-padding-xmedium-bottom uk-padding-large-left uk-padding-large-right">
                      <div className="uk-display-flex uk-flex-column">
                          <label className="fs-14 uk-margin-xsmall-bottom">{CALC_COLS.TITLES.NAME}{addSimpleTooltipIcon("TBD")}</label>
                          <div>
                              <Input type="text" className="uk-input width-250 uk-margin-xmedium-bottom" placeholder={lang.calc_cols.placeholder_name} disabled={isUsedInMapping || [SCENARIO_STATUS.PUBLISHED,SCENARIO_STATUS.REVIEW].includes(this.props.scenarioStatus)}
                                  defaultValue={this.colName} onBlur={(e)=>this.handleChange(CALC_COLS.FIELDS.DISPLAY_NAME, e)} title={isUsedInMapping ? lang.calc_cols.col_used_in_mapping :""}/>&nbsp;
                              {this.isNameValid !== "" ? 
                                  <p id="calc-col-warn" className="red italic uk-margin-default-left">{this.isNameValid}</p>
                              : ""}
                          </div>
                          <label className="fs-14 uk-margin-xsmall-bottom">{CALC_COLS.TITLES.DESCRIPTION}{addSimpleTooltipIcon("TBD")}</label>
                          <textarea className={"uk-textarea textarea-width-250 input-default"} placeholder={lang.calc_cols.placeholder_description}
                              defaultValue={this.colDescription} onBlur={(e)=>this.handleChange(CALC_COLS.FIELDS.DESCRIPTION, e)}/>
                      </div>
                      <div className="uk-display-flex">
                          <label className="fs-14 uk-display-flex uk-height-fit align-items-center uk-cursor-pointer">
                              <RadioButton className="uk-margin-remove" checked={!this.isAdvanced} value={CALC_COLS.VALUES.MAPPING_TYPE.basic} onChange={(e)=>this.handleChange(CALC_COLS.FIELDS.MAPPING_TYPE, e)}
                                  label={CALC_COLS.VALUES.MAPPING_TYPE.basic}/>
                              {/* <span className="uk-margin-small-left">{CALC_COLS.VALUES.MAPPING_TYPE.basic}</span> */}
                          </label>
                          <label className="fs-14 uk-display-flex uk-height-fit align-items-center uk-margin-medium-left uk-cursor-pointer">
                              <RadioButton className="uk-margin-remove " checked={this.isAdvanced} value={CALC_COLS.VALUES.MAPPING_TYPE.advanced}
                                  onChange={(e)=>this.handleChange(CALC_COLS.FIELDS.MAPPING_TYPE, e)} label={CALC_COLS.VALUES.MAPPING_TYPE.advanced}/>
                              {/* <span className="uk-margin-small-left">{CALC_COLS.VALUES.MAPPING_TYPE.advanced}</span> */}
                          </label>
                      </div>
                  </div>
                  {/* div containing files and columns, control buttons and formula */}
                  <div className="uk-padding-small uk-margin-default-right-left uk-margin-xmedium-bottom uk-border uk-border-rounded ">
                    <div className="fs-14 uk-text-bold uk-margin-xmedium-bottom">{lang.formula.title}{addSimpleTooltipIcon(lang.formula.tooltip)}</div>
                    <div className="uk-flex uk-flex-between">
                        <FileColumnsContainer ref={r=>this.fileColumnsRef=r} fileOptions={this.state.fileOptions}
                            data={this.state.formulaColumns}
                            getIsColumnUsed={this.getIsColumnUsed} showMessage={this.showMessage} deleteFormula={this.deleteFormula}
                            handleChange={this.handleChange} getFileName={this.getFileName} getColName={this.getColName}
                        />
                        
                        <ControlButtons appendToFormula={this.appendToFormula} isCalcCols={true} />
                    </div>

                    {this.isAdvanced ?
                         <Button  
                            label={"Add Condition"}
                            variant={BUTTON_VARIANT.SECONDARY}
                            size={SIZES.DEFAULT}
                            type={BUTTON_TYPE.DEFAULT}
                            className={"uk-margin-default-top"}
                            leftIcon={<i className="fa-lg far fa-plus-circle uk-margin-small-right" aria-hidden="true"/>}
                            onBtnClick={this.addCondition} 
                        />
                    :""}
                    {conditions}
                    
                    <FormulaDrop ref={r => this.formulaDRef = r} appendToFormula={this.appendToFormula}
                        formula={this.finalFormula.result} isInnerComp={false} setFormulaIndex={this.setFormulaIndex}
                        deleteLastFormulaItem={this.deleteLastFormulaItem} resetFormulaChanges={this.resetFormulaChanges}
                        deleteFormula={this.deleteFormula} hasConditions={this.isAdvanced} report={STAGING_SECTIONS.CALCULATED_COLUMNS}
                        fileName={this.state.fileName} colName={this.state.columnName}
                    />
                </div>
                </div>
                <div className="uk-display-flex mapping-footer" style={{paddingLeft: "0.8vw"}}>
                    <Button 
                        label={"Submit"}
                        variant={BUTTON_VARIANT.PRIMARY}
                        size={SIZES.DEFAULT}
                        type={BUTTON_TYPE.DEFAULT}  
                        className="uk-margin-default-right"
                        onBtnClick={()=>{this.setRowFormula(true)}}
                    />
                    <Button 
                        label={lang.modal.buttons.cancel}
                        variant={BUTTON_VARIANT.SECONDARY}
                        size={SIZES.DEFAULT}
                        type={BUTTON_TYPE.DEFAULT}
                        data-dismiss="modal"
                        onBtnClick={()=>{this.props.hideFormula()}}
                    />
                </div>

                {/* <div uk-modal="stack:true;" id="messageModal">
                    <div className="modal-dialog modal-md" >
                        <div className="modal-content">
                            <div className="mrgb40">
                                <Button 
                                    variant={BUTTON_VARIANT.TERTIARY}
                                    size={SIZES.ICON}
                                    type={BUTTON_TYPE.DEFAULT}
                                    className="medium-close-button "
                                    aria-label="Close"
                                    data-dismiss="modal"
                                    leftIcon={<i className="fal fa-times black" />}
                                    onBtnClick={this.hideModal}
                                />
                            </div>
                            <div className="modal-body">
                                <h4 className="modal-container uk-text-center message">
                                    <span dangerouslySetInnerHTML={{ __html: this.state.modalMessage }} />
                                </h4>
                                <div className="d-flex justify-content-end uk-margin-default-right-left">
                                    <div>
                                    <Button 
                                        label={this.state.onSubmitCallback ? lang.modal.buttons.cancel : lang.modal.buttons.ok}
                                        variant={BUTTON_VARIANT.SECONDARY}
                                        size={SIZES.DEFAULT}
                                        type={BUTTON_TYPE.DEFAULT}
                                        className="uk-margin-default-right"
                                        data-dismiss="modal"
                                        onBtnClick={this.hideModal}
                                    />
                                    </div>
                                    {this.state.onSubmitCallback ?
                                        <div>
                                            <Button 
                                                label={lang.modal.buttons.continue}
                                                variant={BUTTON_VARIANT.PRIMARY}
                                                size={SIZES.DEFAULT}
                                                type={BUTTON_TYPE.DEFAULT}  
                                                className="uk-modal-close"
                                                onBtnClick={()=>{this.state.onSubmitCallback()}}
                                            />
                                        </div>
                                    :""}
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <Modal
                 id={"message-dialog"}
                 openDialog={this.state.openInfoDialog}
                 bodyContent={() => <h4>{this.state.modalMessage}</h4>}
                 dialogActions={this.openInfoDialogActions}
                 closeClick={() => this.setInfoDialogOpen(false)}
                 size={DIALOG_SIZE.MEDIUM}
                />
            </div>
        );
    }
}


export default ColumnFormula;