import { Skeleton } from "@mui/material";

const LoaderSkeleton = () => {

    return (
        <div className="skeleton-loader">
            <div className="header-skeleton-loader">
                <div>
                    <Skeleton variant="text" sx={{ fontSize: '2rem' }} width={125} height={25} animation="wave"/>
                </div>
                <div>
                    <Skeleton variant="rounded" width={130} height={25} animation="wave"/>
                </div>
                <div>
                    <Skeleton variant="rounded" width={130} height={25} animation="wave"/>
                </div>
                <div>
                    <Skeleton variant="rounded" width={90} height={28} animation="wave"/>
                </div>
            </div>
            <div>
                <Skeleton variant="rectangular" animation="wave"  sx={{ bgcolor: '#d0cece', height: "100%"}}/>
            </div>
        </div>
       
    );
}

export {LoaderSkeleton}