import { Step, StepLabel, Stepper } from "@mui/material";
import { useEffect, useState, useImperativeHandle, forwardRef } from "react";
import { StepIcon } from "@material-ui/core";
import { ReactComponent as CheckedIcon } from "../../../../styles/images/icons/checked.svg";
import { ReactComponent as FlagIcon } from "../../../../styles/images/icons/flag.svg";
import { BUTTON_TYPE, BUTTON_VARIANT, DETAILED_TIE_OUT, SIZES, TIE_OUT_PL_GL } from "../../../../class/constants";
import Input from "../../../../newComponents/Input";
import Button from "../../../../newComponents/Button";
import { lang } from "../../../../language/messages_en";
import NewBanner from "../../../NewBanner";
const DetailedTieOutModal = forwardRef((props, ref) => {

    const [activeStep, setActiveStep] = useState(0);
    const [formattedValue, setFormattedValue] = useState({});
    const [tieOutValue, setTieOutValue] = useState({});
    const [selected, setSelected] = useState({});
    const [tieOutCalculation, setTiedOutCalculation] = useState(null);


    useImperativeHandle(ref, () => ({
        next:()=>{
            return next();
        }
    }));

    useEffect(() => {
        const selectedValue = steps[activeStep]["value"];
        setSelected(selectedValue);
        props.setActiveStep(activeStep);
      }, [activeStep]);

    const CustomStepIcon = ({ active, completed, step, icon }) => {
        if (icon && completed) {
          return <FlagIcon />;
        }
        if (icon) {
          return icon;
        }
      
        if (completed) {
          return <CheckedIcon />;
        }
        return <StepIcon icon={step} completed={completed} active={active} />; // Render the default icons for other states
      };

    const steps = [
        {
            value: "revenue",
            label : "P&L Revenue tie out",
            numb: 1,
            display_name:"revenue"
        },
        {
            value: "cogs",
            label : "P&L COS tie out",
            numb: 2,
            display_name:"Cost of sales"
        },
        {
            value: "opex",
            label : "P&L Opex tie out",
            numb: 3,
            icon: <FlagIcon />,
            display_name:"Opex"
        }
    ]

    const next = () => {
        if(tieOutCalculation && tieOutCalculation.tieOutResult !== TIE_OUT_PL_GL.NOT_TIED_OUT){
            let plCogs = Number(tieOutValue["cogs"]) || 0;
            let plRevenue =  Number(tieOutValue["revenue"]) || 0;
            let plOpex =  Number(tieOutValue["opex"]) || 0;
            const tieOutFromModalValue = plRevenue - (plCogs + plOpex);
            setTiedOutCalculation(null);
            let activeStepCont = activeStep + 1;
            if (activeStepCont < steps.length){
                setActiveStep(activeStepCont)
            } else {
                props.setOpenDialog(false);
                props.setTieOutFromModalValue(tieOutFromModalValue);
            }
        } else {
            props.setModalProceedError(true);
        }
    }

    const formatAndUpdateInput = (value, formatAndReturn) => {
        const isNegative =  String(value).includes("-");
        const cleanedValue = String(value).replace(/[^\d.]/g, '');
        const parts = cleanedValue.split('.');
        let integerPart = parts[0] || '';
        const decimalPart = parts[1] || '';
        let formatted = '';
        for (let i = 0; i < integerPart.length; i++) {
            if(i === 0){
                formatted += '$';
            }
            if (i > 0 && (integerPart.length - i) % 3 === 0) {
                formatted += ',';
            }
            formatted += integerPart[i];
        }
        if (cleanedValue.includes('.')){
            formatted += '.';
        }
        if (decimalPart !== ''){
            formatted += decimalPart;
        }
        if(isNegative){
            formatted = '-'+ formatted;
        }
        let tieOutValueState = isNegative ? "-" + cleanedValue : cleanedValue;
        const updatedTieOut = {
            ...tieOutValue,
            [selected]: tieOutValueState
        }
        const updatedFormattedValue = {
            ...formattedValue,
            [selected]: formatted
        }
        if(formatAndReturn){
            return formatted;
        }
        setFormattedValue(updatedFormattedValue);
        setTieOutValue(updatedTieOut);
    };

    const onInputChange = (e) => {
        formatAndUpdateInput(e.target.value);
        setTiedOutCalculation(null);
        if(e.target.value !== "" && e.target.value !== "-"){
            props.setDetailedTieOutErrorMessageParam(lang.monthly_build.tie_out_pl_gl.no_tie_out_error_message);
        } else {
            props.setDetailedTieOutErrorMessageParam(lang.monthly_build.tie_out_pl_gl.no_value_error_message);
        }
    };

    const calculateTieOutLogic = () => {
        props.setDetailedTieOutErrorMessageParam(lang.monthly_build.tie_out_pl_gl.no_value_error_message);
        const data = props.data;
        const keyOfSelected = (Object.keys(data.glAccountsAmount)).filter(e => e.toLocaleLowerCase().startsWith(selected.toLocaleLowerCase()))
        let plAmount = tieOutValue[selected];

        if (plAmount !== undefined && plAmount !== "" && plAmount !== "-") {
            let tieOutThreshold = data.tieoutThresholds.detailedTieoutThreshold;
            let glAmounts = data.glAccountsAmount[keyOfSelected];
            let tieOutDelta = Math.floor(glAmounts - plAmount);
            let tieOutPercentage = parseFloat(((glAmounts / plAmount) * 100).toFixed(2));
            if (glAmounts == 0 && plAmount == 0) {
                tieOutPercentage = 100;
            }
            let tieOutDifference = Math.abs(100 - tieOutPercentage);
            let tieOutResult = tieOutDifference === 0 ? TIE_OUT_PL_GL.TIED_OUT : tieOutDifference <= tieOutThreshold ? TIE_OUT_PL_GL.NEARLY_TIED_OUT : TIE_OUT_PL_GL.NOT_TIED_OUT;
            let tieOutCalculationObj = {
                tieOutDelta,
                tieOutResult,
                tieOutPercentage,
            }
            setTiedOutCalculation(tieOutCalculationObj);
            if (tieOutCalculationObj && tieOutCalculationObj.tieOutResult !== TIE_OUT_PL_GL.NOT_TIED_OUT) {
                props.setModalProceedError(false);
            }
        } else {
            props.setModalProceedError(true);
        }
    };

    const renderBanner = () => {
        const keyOfSelected = (Object.keys(props.data.glAccountsAmount)).filter(e => e.toLocaleLowerCase().startsWith(selected.toLocaleLowerCase()))
        let formattedProfitGLAmount = formatAndUpdateInput(props.data.glAccountsAmount[keyOfSelected], true);
        let formattedTieOutDelta = formatAndUpdateInput(tieOutCalculation.tieOutDelta, true);
        let tieOutMessage = lang.monthly_build.tie_out_pl_gl.modal.banner_messages[tieOutCalculation.tieOutResult]
        ?.replaceAll("<EBITDA>", steps[activeStep]["display_name"])
        .replaceAll("<GL EBITDA>", formattedProfitGLAmount)
        .replaceAll("<Tie out Δ value>", formattedTieOutDelta)
        .replaceAll("<Tie out %>", Math.abs(tieOutCalculation.tieOutPercentage) == Infinity ? 0 : tieOutCalculation.tieOutPercentage)

        let messageWithLineBreaks = props.formatMessage(tieOutMessage, tieOutCalculation.tieOutResult);

        let tiedOutBody = (
            <div className="p-text">
                {messageWithLineBreaks}
                {tieOutCalculation.tieOutResult !== TIE_OUT_PL_GL.TIED_OUT && (
                    <div className="uk-flex uk-flex-center uk-flex-column">
                        <Button
                            id={"export-gl-accounts-btn"}
                            label="Export GL accounts"
                            variant={BUTTON_VARIANT.SECONDARY}
                            size={SIZES.DEFAULT}
                            type={BUTTON_TYPE.DEFAULT}
                            className="uk-margin-small-top"
                            onBtnClick={props.fetchDetailedTieOutExportData}
                        />
                        <Button
                            id={"go-to-upload-files-btn"}
                            label="Go to upload files"
                            variant={BUTTON_VARIANT.SECONDARY}
                            size={SIZES.DEFAULT}
                            type={BUTTON_TYPE.DEFAULT}
                            onBtnClick={props.goToUpload}
                            className="uk-margin-small-top"
                        />
                    </div>
                )}
            </div>
        );
        let banner =
            <NewBanner
                id={"banner-detailed-" + TIE_OUT_PL_GL.BANNER_CLASS_NAME[tieOutCalculation.tieOutResult]}
                bannerClassName={TIE_OUT_PL_GL.BANNER_CLASS_NAME[tieOutCalculation.tieOutResult] +" tie-out-banner"}
                icon={props.bannerIcon[tieOutCalculation.tieOutResult]}
                body={tiedOutBody}
            />

        return banner;
    }

    return (
        
        <div>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((step) => (
                    <Step key={step.label} id={"label-" + step.numb}>
                        <StepLabel
                            StepIconComponent={({ active, completed, icon }) => (
                                <CustomStepIcon
                                    active={active}
                                    completed={completed}
                                    icon={step.icon}
                                    step={icon}
                                />)}>
                            {step.label}
                        </StepLabel>
                    </Step>
                ))}
        </Stepper>

        <div className="detail-modal-body large-padding-top">
                    <h4> {(lang.monthly_build.tie_out_pl_gl.modal.input_label).replace("[PERIOD]", props.period).replace("[CATEGORY]",steps[activeStep]["display_name"])}</h4>
                    <div className="detail-modal-pair">
                        <Input
                            className="detail-modal-input"
                            id={"category-value"}
                            step=".01"
                            name= {"category-value"}
                            autoComplete="off"
                            value={formattedValue[selected]||''}
                            onChange={(e)=>{onInputChange(e)}}
                        />
                        <Button
                            id={"detailed-tie-out-popup-btn"}
                            label="Tie Out"
                            variant={BUTTON_VARIANT.SECONDARY}
                            size={SIZES.DEFAULT}
                            type={BUTTON_TYPE.DEFAULT}
                            disabled = {false}
                            className ={"detail-modal-btn"}
                            onBtnClick = {calculateTieOutLogic}
                        />
                    </div>
                </div>
                { tieOutCalculation ? renderBanner() : ""}            
        </div>
    );
});

export default DetailedTieOutModal;

