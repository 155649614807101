import React, { useEffect, forwardRef, useImperativeHandle } from "react";
import {TabulatorFull} from "tabulator-tables"; //import {TabulatorFull} library
import { cleanUpTabulatorColumns } from "../../../../class/common";
import { copyObjectValues, capitaliseFirstLetterAfterChar, capitalizeFirstLetter } from "../../../../class/utils";
import { FormatTypes, GL_STRINGS_REVIEW, PS_MAPPING } from "../../../../class/constants";
import { formatValHTML } from "../../../../class/format";
import { lang } from "../../../../language/messages_en";
import { ReactComponent as Branch } from "../../../../styles/images/icons/code-branch-regular-1.svg";
import {ReactComponent as Flipped} from "../../../../styles/images/icons/retweet-compare.svg";



const GlStringsReviewTable = (props, ref) => {

    let tabulator;
    
    const { data,lstTransitionColumnNames, transitionDisplayMap } = props;
    const { goToCard } = props;
    const id = "gl-strings-review";
    const _excluded = PS_MAPPING.PSS_FILTER_LABELS.EXCLUDED_LABEL;
    const _unAssigned = PS_MAPPING.PSS_FILTER_LABELS.UNASSIGNED_LABEL;
    
    const transitionedColumns = data.unassignedCombinations ? (
        data.unassignedCombinations[0].combinationTransitionColumns ? data.unassignedCombinations[0].combinationTransitionColumns?.map((column) => {
        const key = Object.keys(column)[0];
        const title = capitalizeFirstLetter((key === "state" ? data.clientCostCenterDisplayName : key).toLowerCase().replace(/_/g, " "), " ");
        return {
          title: title,
          field: key,
          vertAlign: "middle",
          minWidth: 100
        };
      }) : []
    ) : [];
    const commonColumns = [
        {
            title: GL_STRINGS_REVIEW.COLUMNS.TITLES.AMOUNT,
            field: GL_STRINGS_REVIEW.COLUMNS.FIELDS.AMOUNT,
            headerHozAlign:"right",
            hozAlign:"right",
            cssClass:"full-width-toggle",
            vertAlign: "middle",
            minWidth: 100
        },
        {
            title: GL_STRINGS_REVIEW.COLUMNS.TITLES.ASSIGNED_TO,
            field: GL_STRINGS_REVIEW.COLUMNS.FIELDS.ASSIGNED_TO,
            headerHozAlign:"left",
            vertAlign: "middle",
            width: "30%"
        },
        {
            title: GL_STRINGS_REVIEW.COLUMNS.TITLES.ACTION,
            field: GL_STRINGS_REVIEW.COLUMNS.FIELDS.ACTION,
            headerSort:false,
            headerHozAlign:"center",
            hozAlign:"Center",
            vertAlign: "middle",
            minWidth: 100,
            width:"5%"
        }
    ]

    const assignedToSorter = (a, b) => {
        // Use JavaScript's localeCompare to compare formatted values
        let valueOfA = typeof a[0] === "string" ? "Not assigned" :  a[0].name;
        let valueOfB = typeof b[0] === "string" ? "Not assigned" :  b[0].name;
        return valueOfA.toLowerCase().localeCompare(valueOfB.toLowerCase(), undefined, { numeric: false });
      };

    const columns = [...transitionedColumns, ...commonColumns];
    useEffect(() => {
        if(data){
            tableWrap();
        }
    }, [data]);


    const tableWrap = () => {
        const transformedData = data.unassignedCombinations.map(item => {
            const combinedColumns = {};
            item.combinationTransitionColumns?.forEach(column => {
                const key = Object.keys(column)[0];
                combinedColumns[key] = column[key];
            });
            return { ...item, ...combinedColumns, combinationTransitionColumns: undefined };
        });
        const paginate = transformedData.length > 9;
        let options = {
            data: transformedData,
            width: "100%",
            layout: "fitColumns", //fit columns to width of table
            // columnHeaderSortMulti:false,
            // index: FIELDS.COST_CENTER,
            pagination: paginate ? "local" : false,       //paginate the data
            paginationSize: paginate ? 10 : false,
            paginationSizeSelector: paginate ? [10, 50, 100] : false,
            footerElement: paginate ? "<div class='uk-margin-xsmall-top' id='existing_footer'></div>" : false
        }
        var tableColumns = copyObjectValues(columns);
        tableColumns = cleanUpTabulatorColumns(tableColumns, null, "", tabulator, { id: id });
        tableColumns = getTabulatorColumns(tableColumns);
        options.columns = tableColumns;
        tabulator = new TabulatorFull("#" + id, options);
        if(lstTransitionColumnNames) {
            tabulator.setSort(lstTransitionColumnNames[0], "asc");
        }
    }

    const getTabulatorColumns=(columns)=> {
		columns = columns || [];
		columns.forEach(col => {
			col.formatter = getColumnFormatter(col.field);
            if(col.field === GL_STRINGS_REVIEW.COLUMNS.FIELDS.ASSIGNED_TO){
                col.sorter = assignedToSorter;
            }

        });
        return columns;
	}

    const getColumnFormatter=(colField)=> {
		let columnFormatter;
		switch (true) {
            case lstTransitionColumnNames?.includes(colField):
                columnFormatter = function (cell) {
                    var rowData = cell.getRow().getData();
                    var div = document.createElement("div")
                    div.style.display = "flex";
                    div.style.flexDirection = "column";
                    div.style.gap = "0.1vw";
                    var span = document.createElement("span");
                    span.innerText = cell.getValue();
                    div.append(span);
                    if(colField in transitionDisplayMap){
                        var span2 = document.createElement("span");
                        span2.innerText = rowData[transitionDisplayMap[colField]];
                        span2.classList.add("fs-10");
                        span2.style.fontFamily = "Lato";
                        div.append(span2);
                    }
                    return div;
                }
                break;
            case colField === GL_STRINGS_REVIEW.COLUMNS.FIELDS.AMOUNT:
                columnFormatter = function (cell) {
                    var span = document.createElement("span");
                    var span2 = document.createElement("span");
                    var rowData = cell.getRow().getData();
                    var icon = document.createElement("i");
                    
                    if (rowData.flipped) {
                        icon.classList.add("far", "fa-retweet","uk-margin-small-right");
                        icon.setAttribute("uk-tooltip", "Flipped");
                        span.appendChild(icon);
                    }

                    span2.innerHTML = formatValHTML(cell.getValue(), FormatTypes.AMOUNT);
                    span.classList.add("uk-flex", "align-items-center");
                    span.appendChild(span2);
                    // span.style.height = "0px" // to align content in center verticaly
                    return span;
                }
                break;
            case colField === GL_STRINGS_REVIEW.COLUMNS.FIELDS.ASSIGNED_TO:
                columnFormatter = function (cell) {
                    let cellValue = cell.getValue();
                    if (cellValue.includes(_unAssigned)) {
                        return lang.monthly_build.setup_profit_stacks.unassigned_review_cell_stmt;
                    } else if(cellValue.includes(_excluded)){
                        return _excluded;
                    } else {
                        let div = document.createElement("div");
                        let title = document.createElement("div");
                        title.classList.add("fs-14","uk-text-bold");
                        let path = document.createElement("div");
                        path.classList.add("fs-10");
                        let rowData = cell.getRow().getData();

                        const pslInfo = cellValue[0];

                        title.innerText = pslInfo.name;
                        path.innerText = rowData.path;

                        let isCostCenter= pslInfo.costCenter && !["ALL",""].includes(pslInfo.costCenter);
                        if(isCostCenter){
                            let icon = document.createElement("i");
                            icon.classList.add("far", "fa-code-branch", "icon-legend-table");
                            icon.setAttribute("uk-tooltip", "Cost Center Based");
                            title.append(icon);
                        }

                        div.append(title);
                        div.append(path);

                        return div;
                    }
                }
                break;
            case colField === GL_STRINGS_REVIEW.COLUMNS.FIELDS.ACTION:
                columnFormatter = function (cell) {
                    let div = document.createElement("div");
                    div.classList.add("uk-align-center");
                    let button = document.createElement("button");
                    let rowData = cell.getRow().getData();
                    button.classList.add("custom-btn", "btn-size-small");
                    if(rowData.assigned_to.includes("Unassigned")){
                        button.classList.add("btn-default-primary");
                        button.innerHTML = "Assign";
                    } else {
                        button.classList.add("btn-default-secondary");
                        button.innerHTML = "Edit";
                    }
                    // button.value = cell.getValue();
                    button.onclick = () => {
                        goToCard(cell.getValue());
                    }
                    div.style.height = "0px" // to align content in center verticaly
                    div.append(button);

                    return div;
                }
                break;
			default:
                columnFormatter = function(cell) {
                    return formatValHTML(cell.getValue(), FormatTypes.TEXT);  
                }
            break;
        }
		return columnFormatter;
    }

    return (
        <div>
            <div id={id} className="large-margin-bottom table-new" />
            <span>
                <Branch className="icon-legend"/>
                <span>{lang.monthly_build.setup_profit_stacks.cc_base}</span>
            </span>
            <span className="uk-margin-left">
                <Flipped className="icon-legend"/>
                <span>{lang.monthly_build.setup_profit_stacks.flipped}</span>
            </span>
        </div>
    );
}

export default forwardRef(GlStringsReviewTable);