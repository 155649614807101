import React, { Component } from 'react';
import '../../styles/dataModeling.css';
import {TabulatorFull} from "tabulator-tables"; //import {TabulatorFull} library
import { getTranslationFile, capitalizeFirstLetter, copyObjectValues, findOptionByKey, parseBoolean } from '../../class/utils.js';
import { FOCUS_ACCRUALS, FormatTypes, ACCRUALS, ORDER_VALUES ,FISCAL_YEARS, DROPDOWN_TYPE,  BUTTON_VARIANT, SIZES, BUTTON_TYPE} from '../../class/constants.js';
import { cleanUpTabulatorColumns, getRandomColor} from '../../class/common.js';
import { formatValHTML, formatValString } from '../../class/format';
import LineChart from '../../components/charts/LineChart';
import CostClassification from '../../components/CostClassification';
import { CustomSelect } from '../../form/elements.js';
import { lang } from '../../language/messages_en';
import 'jqueryui';
import { getNumericValue } from '../../class/number';
import { convertPxToViewport } from '../../class/formatting';
import Button from '../../newComponents/Button';
import Dropdown from '../../newComponents/DropDown';
import RadioButton from '../../newComponents/RadioButton';

const $ = require('jquery');
const MESSAGES = getTranslationFile();

const prediction_config = lang.accruals.prediction_config;
const _input = "input";     //refers to nothing, only used to disable "Simulate" button
const _lineChartLengendTitle = "legendTitle";

const EVALUATE_COLUMNS = [
    {   
        title: FOCUS_ACCRUALS.TITLES.PERIOD,
        field: FOCUS_ACCRUALS.FIELDS.PERIOD,
        format_type: FormatTypes.TEXT,
        headerSort:false,
        dontFilter:true
    },
    {   
        title: FOCUS_ACCRUALS.TITLES.COST,
        field: FOCUS_ACCRUALS.FIELDS.COST,
        format_type: FormatTypes.AMOUNT,
        headerSort:false,
        dontFilter:true
    },
    {   
        title: FOCUS_ACCRUALS.TITLES.FY_PROJECTION,
        field: FOCUS_ACCRUALS.FIELDS.FY_PROJECTION,
        format_type: FormatTypes.AMOUNT,
        headerSort:false,
        dontFilter:true
    },
    {   
        title: FOCUS_ACCRUALS.TITLES.VARIANCE,
        field: FOCUS_ACCRUALS.FIELDS.VARIANCE,
        format_type: FormatTypes.PERIOD,
        headerSort:false,
        dontFilter:true
    },
    {   
        title: FOCUS_ACCRUALS.TITLES.CALCULATED_ACCRUAL,
        field: FOCUS_ACCRUALS.FIELDS.CALCULATED_ACCRUAL,
        format_type: FormatTypes.AMOUNT,
        headerSort:false,
        dontFilter:true
    }
];

const PREDICT_COLUMNS = [
    {
        title: FOCUS_ACCRUALS.TITLES.PERIOD,
        field: FOCUS_ACCRUALS.FIELDS.PERIOD,
        format_type: FormatTypes.TEXT,
        headerSort:false,
        dontFilter:true
    },
    {
        title: FOCUS_ACCRUALS.TITLES.REFERENCE_FY_BUILD,
        field: FOCUS_ACCRUALS.FIELDS.FY_PROJECTION,     //on predict, the FY BUILD values are shown in this column
        format_type: FormatTypes.TEXT,
        headerSort:false,
        dontFilter:true
    },
    {
        title: FOCUS_ACCRUALS.TITLES.RATIO_PERC,
        field: FOCUS_ACCRUALS.FIELDS.RATIO_PERC,
        format_type: FormatTypes.TEXT,
        headerSort:false,
        cssClass: "ratio-percentage-column",
        dontFilter:true
    },
    {
        title: FOCUS_ACCRUALS.TITLES.FY_PROJECTION,
        field: FOCUS_ACCRUALS.FIELDS.PREDICTED_FY_PROJECTTION,
        format_type: FormatTypes.AMOUNT,
        headerSort:false,
        cssClass: "full-year-build-column",
        dontFilter:true
    },
    {
        title: FOCUS_ACCRUALS.TITLES.COST,
        field: FOCUS_ACCRUALS.FIELDS.COST,
        format_type: FormatTypes.AMOUNT,
        headerSort:false,
        dontFilter:true
    },
    {
        title: FOCUS_ACCRUALS.TITLES.VARIANCE,
        field: FOCUS_ACCRUALS.FIELDS.PREDICTED_VARIANCE,
        format_type: FormatTypes.PERIOD,
        headerSort:false,
        dontFilter:true
    },
    {
        title: FOCUS_ACCRUALS.TITLES.CALCULATED_ACCRUAL,
        field: FOCUS_ACCRUALS.FIELDS.PREDICTED_CALCULATED_ACCRUAL,
        format_type: FormatTypes.AMOUNT,
        headerSort:false,
        dontFilter:true
    }
];

class FocusAccrual extends Component {
    constructor(props) {
		super(props);
        this.state = {
            showActions: false,
            chartAxes: {
                x: {field: [FOCUS_ACCRUALS.FIELDS.PERIOD], title: FOCUS_ACCRUALS.TITLES.PERIOD},
                y: {title: FOCUS_ACCRUALS.TITLES.AMOUNT}
            },
            chartLines: [
                { field: FOCUS_ACCRUALS.FIELDS.COST, title: FOCUS_ACCRUALS.TITLES.AMOUNT, [_lineChartLengendTitle]: FOCUS_ACCRUALS.TITLES.COST, color: "#FCD202", bulletShape: "square", format: FormatTypes.AMOUNT},
                { field: FOCUS_ACCRUALS.FIELDS.FY_PROJECTION, title: FOCUS_ACCRUALS.TITLES.PERIOD, [_lineChartLengendTitle]: FOCUS_ACCRUALS.TITLES.REFERENCE_FY_BUILD, color: "#FF6600", bulletShape: "round", format: FormatTypes.AMOUNT },
                { field: FOCUS_ACCRUALS.FIELDS.PREDICTED_FY_PROJECTTION, title: FOCUS_ACCRUALS.TITLES.PERIOD, [_lineChartLengendTitle]: FOCUS_ACCRUALS.TITLES.FY_PROJECTION, color: "green", bulletShape: "round", format: FormatTypes.AMOUNT}
            ],
            configOptions: {
                type: [
                    {label: prediction_config.options.type.historical.title, value: prediction_config.options.type.historical.value},
                    {label: prediction_config.options.type.manual.title, value: prediction_config.options.type.manual.value},
                    {label: prediction_config.options.type.metric.title, value: prediction_config.options.type.metric.value, isDisabled: true},
                ],
                ref_fiscal_year: [
                    {label: prediction_config.options.ref_fiscal_year.previous.title, value: prediction_config.options.ref_fiscal_year.previous.value},
                ].concat(props.fiscalYears),
            },
            configValues: {
                type: props.configValues ? props.configValues[FOCUS_ACCRUALS.CONFIG_FIELDS.PREDICT_TYPE] || prediction_config.options.type.historical.value : prediction_config.options.type.historical.value,
                ref_fiscal_year: props.configValues ? props.configValues[FOCUS_ACCRUALS.CONFIG_FIELDS.PREDICT_REF_YEAR] || prediction_config.options.ref_fiscal_year.previous.value : prediction_config.options.ref_fiscal_year.previous.value,
                is_by_year: props.configValues ? props.configValues[FOCUS_ACCRUALS.CONFIG_FIELDS.IS_BY_YEAR] : true,   //props might be "false" which leads to fallback on "true"
                ratio: !props.isEvaluation && props.data.length && props.configValues[FOCUS_ACCRUALS.CONFIG_FIELDS.IS_BY_YEAR] ? props.data[0].ratio : props.configValues ? props.configValues[FOCUS_ACCRUALS.CONFIG_FIELDS.RATIO] || 100 : 100
            },
            simulateEnabled: false,
            counter: 0,
            notBuiltYears: props.fiscalYears.filter(year=> parseBoolean(year[FISCAL_YEARS.all_built]) === false).map(year=>{return year[FISCAL_YEARS.fiscal_year]})
        };

        this.onTabulatorRenderComplete = this.onTabulatorRenderComplete.bind(this);
        this.actionClick = this.actionClick.bind(this);
        this.updateSimulationData = this.updateSimulationData.bind(this);
        this.getTableData = this.getTableData.bind(this);
        this.getUpdatedConfigData = this.getUpdatedConfigData.bind(this);

        this.isEvaluation = this.props.isEvaluation;
        this.isPrediction = !this.isEvaluation;
        this.isHistorical = this.state.configValues.type === prediction_config.options.type.historical.value;

        this.isFetchingData = false;
        this.isRefYearBuilt = this.checkIsRefYearBuilt(this.state.configValues.ref_fiscal_year);
        this.actualPreviousYear = "";
    }

    getTabulatorColumns(columns) {
        var obj = this;
		columns = columns || [];
		columns.forEach(col => {
            col.formatter = this.getColumnFormatter(col.field);
            var columnField = col.field;
            if ([FOCUS_ACCRUALS.FIELDS.FY_PROJECTION].includes(columnField)) {
                col.titleFormatter = (cell) => obj.addReferenceYearTitleFormatter(cell);
            }
        });
        return columns;
    }

    checkIsRefYearBuilt(ref_fiscal_year){
        if(this.state.notBuiltYears.includes(ref_fiscal_year)) {
            return false;
        }
        return true;
    }
    
    getColumnFormatter(colField) {
        const _this = this;
       
		var columnFormatter;
		switch (colField) {

            case FOCUS_ACCRUALS.FIELDS.PERIOD:
                columnFormatter = function(cell, formatterParams) {
                    var p = document.createElement('p');
                    p.innerHTML = formatValHTML(cell.getRow().getData()[FOCUS_ACCRUALS.FIELDS.PERIOD] , FormatTypes.TEXT);
                    $(cell.getElement()).css({"background-color":"#D4D4D4"});
                    $(cell.getElement()).css({"font-weight":"bold"});
                    return p;
                }
           break;

            case FOCUS_ACCRUALS.FIELDS.COST:
                columnFormatter = function(cell, formatterParams) {
                        var p = document.createElement('p');
                        if(_this.isFetchingData) {
                            let img = document.createElement("img");
                            img.src = '/images/FhHRx.gif';
                            img.style.width = convertPxToViewport(15);
                            img.style.height = convertPxToViewport(15);
                            img.id = "small_loader";
                            p.appendChild(img);
                        } else {
                            p.innerHTML = formatValHTML(cell.getRow().getData()[FOCUS_ACCRUALS.FIELDS.COST] , FormatTypes.AMOUNT);
                        }
                        return p;
                    }
            break;
            case FOCUS_ACCRUALS.FIELDS.FY_PROJECTION:
                columnFormatter = function(cell, formatterParams) {
                    var p = document.createElement('p');
                    if(_this.isFetchingData) {
                        let img = document.createElement("img");
                        img.src = '/images/FhHRx.gif';
                        img.style.width = convertPxToViewport(15);
                        img.style.height = convertPxToViewport(15);
                        img.id = "small_loader";
                        p.appendChild(img);
                    } else {
                        p.innerHTML = _this.isRefYearBuilt ? formatValHTML(cell.getValue() , FormatTypes.AMOUNT) : "Not Built";
                    }
                    return p;
                }
            break;
            case FOCUS_ACCRUALS.FIELDS.PREDICTED_FY_PROJECTTION:
                columnFormatter = function(cell, formatterParams) {
                    if(_this.state.configValues.type === prediction_config.options.type.manual.value) {
                        var spinner = document.createElement("input");
                        spinner.classList.add("spinner-input");
                        spinner.setAttribute("jqueryui", "spinner");
                        spinner.style.width = "100%";
                        spinner.step = 1;
                        spinner.value = formatValString(cell.getValue() || 0, FormatTypes.AMOUNT);
                        spinner.onblur = ()=>{_this.handlePredictionConfigChange(_input, {value: $(spinner).val()}, cell.getRow())};
                        spinner.ondblclick = (e)=>{ e.stopPropagation() };

                        return spinner;
                    } else {
                        var p = document.createElement('p');
                        if(_this.isFetchingData) {
                            let img = document.createElement("img");
                            img.src = '/images/FhHRx.gif';
                            img.style.width = convertPxToViewport(15);
                            img.style.height = convertPxToViewport(15);
                            img.id = "small_loader";
                            p.appendChild(img);
                        } else {
                            p.innerHTML = _this.isRefYearBuilt || !_this.isHistorical ? formatValHTML(cell.getValue() , FormatTypes.AMOUNT) : "N/A";
                        }
                        return p;
                    }
                }
            break;
            case FOCUS_ACCRUALS.FIELDS.RATIO_PERC:
                columnFormatter = function(cell) {
                    if(_this.isHistorical && !_this.state.configValues.is_by_year) {
                        var spinner = document.createElement("input");
                        spinner.classList.add("spinner-input");
                        spinner.setAttribute("jqueryui", "spinner");
                        spinner.style.width = "100%";
                        spinner.step = 1;
                        spinner.value = formatValString(cell.getValue() || 0, FormatTypes.PERCENTAGE);
                        spinner.onblur = ()=>{_this.handlePredictionConfigChange(_input, {value: $(spinner).val()}, cell.getRow())};
                        spinner.ondblclick = (e)=>{ e.stopPropagation() };
                        return spinner;
                    } else {
                        var p = document.createElement('p');
                        if(_this.isFetchingData) {
                            let img = document.createElement("img");
                            img.src = '/images/FhHRx.gif';
                            img.style.width = convertPxToViewport(15);
                            img.style.height = convertPxToViewport(15);
                            img.id = "small_loader";
                            p.appendChild(img);
                        } else {
                            p.innerHTML = _this.isRefYearBuilt || _this.isHistorical ? formatValHTML(cell.getValue(), FormatTypes.PERCENTAGE) : "N/A";
                        }
                        return p;
                    }
                }
            break;
            case FOCUS_ACCRUALS.FIELDS.PREDICTED_VARIANCE:
            case FOCUS_ACCRUALS.FIELDS.VARIANCE:
                columnFormatter = function(cell, formatterParams) {
                        var p = document.createElement('p');
                        if(_this.isFetchingData) {
                            let img = document.createElement("img");
                            img.src = '/images/FhHRx.gif';
                            img.style.width = convertPxToViewport(15);
                            img.style.height = convertPxToViewport(15);
                            img.id = "small_loader";
                            p.appendChild(img);
                        } else {
                            p.innerHTML = _this.isRefYearBuilt || !_this.isHistorical ? formatValHTML(cell.getValue(), FormatTypes.PERCENTAGE) : "N/A";
                        }
                        return p;
                    }
            break;
            case FOCUS_ACCRUALS.FIELDS.PREDICTED_CALCULATED_ACCRUAL:
            case FOCUS_ACCRUALS.FIELDS.CALCULATED_ACCRUAL:
                columnFormatter = function(cell, formatterParams) {
                    var p = document.createElement('p');
                    if(_this.isFetchingData) {
                        let img = document.createElement("img");
                        img.src = '/images/FhHRx.gif';
                        img.style.width = convertPxToViewport(15);
                        img.style.height = convertPxToViewport(15);
                        img.id = "small_loader";
                        p.appendChild(img);
                    } else {
                        p.innerHTML = _this.isRefYearBuilt || !_this.isHistorical ? formatValHTML(cell.getValue(), FormatTypes.AMOUNT) : "N/A";
                    }
                    return p;
                }
            break;

			default:
                columnFormatter = function(cell, formatterParams) {
                    return cell.getValue();
                } 
                break;
            }
		
		return columnFormatter;
    }

    /**
     * do not update same state in multiple functions, use one function,
     * easier for debugging
     * @param {*} data 
     * @param {*} tempState 
     */
    updateTableData(data, tempState={}, callback=()=>{}) {
        
        tempState.tableData = data;
        var tableColumns = copyObjectValues(this.isPrediction ? PREDICT_COLUMNS : EVALUATE_COLUMNS);		
		//settings the columns again to replace the checkbox column
		tableColumns = this.getTabulatorColumns(tableColumns);

        this.setState(tempState, ()=>{
            this.tabulator.setColumns(tableColumns);
            this.tabulator.replaceData(this.state.tableData);
            callback();
        });
    }

    /**
     * for use from external component, do not access state directly
     */
    getTableData() {
        return this.state.tableData;
    }

    /**
     * returns the config data to the parent on clicking submit
     */
    getUpdatedConfigData() {
        let tempObj = {
            [FOCUS_ACCRUALS.CONFIG_FIELDS.PREDICT_TYPE]: this.state.configValues.type,
            [FOCUS_ACCRUALS.CONFIG_FIELDS.PREDICT_REF_YEAR]: this.state.configValues.ref_fiscal_year,
            [FOCUS_ACCRUALS.CONFIG_FIELDS.IS_BY_YEAR]: this.state.configValues.is_by_year,
            [FOCUS_ACCRUALS.CONFIG_FIELDS.RATIO]: this.state.configValues.ratio,
            [FOCUS_ACCRUALS.CONFIG_FIELDS.ACCRUAL_PERIOD_DATA]: this.getTableData().map(row=>{
                row[FOCUS_ACCRUALS.CONFIG_FIELDS.PERIOD_NUMBER] = row[FOCUS_ACCRUALS.FIELDS.PERIOD].slice(4,7);
                row[FOCUS_ACCRUALS.CONFIG_FIELDS.AMOUNT] = row[FOCUS_ACCRUALS.FIELDS.PREDICTED_FY_PROJECTTION];
                return row;
            })
        }

        return tempObj;
    }

    getActualRefYear(refYear = this.state.configValues.ref_fiscal_year) {
        return refYear === prediction_config.options.ref_fiscal_year.previous.value ? this.actualPreviousYear : refYear;
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.data && this.props.dataUpdated_id !== prevProps.dataUpdated_id) {
            this.isFetchingData = false;
            this.updateTableData(this.props.data);
        }
        this.actualPreviousYear = Number(this.props.fiscalYear[FISCAL_YEARS.fiscal_year]) - 1;
    }

    componentDidMount() {
        const _this = this;

        var options = {
		    layout: "fitColumns",      //fit columns to width of table
            responsiveLayout: false,  //hide columns that dont fit on the table
            tooltips: false,            //show tool tips on cells
            addRowPos: "top",          //when adding a new row, add it to the top of the table
            history: true,             //allow undo and redo actions on the table
            pagination: false,          //paginate the data
            movableColumns: false,     //allow column order to be changed
            selectable: false,
            movableRows: false,
            resizableColumns: false,
            autoResize: true,
            dataTreeBranchElement: false, //hide branch element
            virtualDomBuffer: 8000,
            placeholder: MESSAGES.no_data_available,
            width: "100%",
            height: "100%",
            renderComplete: this.onTabulatorRenderComplete,
            // index: ACCRUALS.FIELDS.COSTKEY,
            reactiveData:true,      //tabulator listens to any change in the data array and updates the table
            initialSort: [
                {column: FOCUS_ACCRUALS.FIELDS.PERIOD, dir: ORDER_VALUES.ASC.toLowerCase()},
            ],
        }

		//setting table columns
		var tableColumns = copyObjectValues(this.isPrediction ? PREDICT_COLUMNS : EVALUATE_COLUMNS);
		
		//set column formatters and return column objects
		tableColumns = cleanUpTabulatorColumns(tableColumns, null, this.refreshFilterDivs, this.tabulator, {id:"evaluate_accruals"});
        // tableColumns.find(e => e.field === ACCRUALS.FIELDS.STATUS).headerFilterParams.values = [ACCRUALS.FIELDS.STATUS_VALUES.ACCRUED,ACCRUALS.FIELDS.STATUS_VALUES.EXCLUDED, ACCRUALS.FIELDS.STATUS_VALUES.UNDEFINED,];

		//settings the columns again to replace the checkbox column
		tableColumns = this.getTabulatorColumns(tableColumns);
        options.columns = tableColumns;
        this.tabulator = new TabulatorFull(this.refs.mainTable, options);
        if(this.props.data) {
            this.updateTableData(this.props.data);
        }
        $(".parent-container").mouseup(function (e) {
            if (e.target.id !== "evaluate_status" && ($(e.target).parents().prevObject[0].id !== "evaluate_status" && $(e.target).parents("#evaluate_status").length === 0)
                && e.target.id !== "action_buttons_container" && ($(e.target).parents().prevObject[0].id !== "action_buttons_container" && $(e.target).parents("#action_buttons_container").length === 0)
                && _this.state.showActions) {
                _this.showActions()  //to hide the actions div
            }
        });

    }

    onTabulatorRenderComplete() {
		if(!this.tabulator) {
            return;
        }

        $(".spinner-input").spinner();
        $(".ui-spinner").css("background-color", "#FFF");
    }

    showActions() {
        this.setState({
            showActions: !this.state.showActions
        })
    }

    actionClick(status, action, data) {
        this.setState({
            showActions: false
        }, function () {
            this.props.applyAction(status, action, data);
        })
    }

    updateSimulationData() {
        this.state.counter = this.state.counter+ 1;
        var chartLines = [];
        if(this.state.counter === 1){
            chartLines = copyObjectValues(this.state.chartLines);
            chartLines[2][_lineChartLengendTitle] = FOCUS_ACCRUALS.TITLES.FY_PROJECTION + " " + this.state.counter;
        } else{
            chartLines = copyObjectValues(this.state.chartLines);
        }
        chartLines.push({ field: FOCUS_ACCRUALS.FIELDS.PREDICTED_FY_PROJECTTION+this.state.counter, title: FOCUS_ACCRUALS.TITLES.PERIOD, [_lineChartLengendTitle]: FOCUS_ACCRUALS.TITLES.FY_PROJECTION + " " + (this.state.counter+1), color: getRandomColor(), bulletShape: "round", format: FormatTypes.AMOUNT});

        var originalData = this.state.chartData ? copyObjectValues(this.state.chartData) : copyObjectValues(this.props.data);
        for(var e in this.tabulator.getData()){
            originalData[e][FOCUS_ACCRUALS.FIELDS.PREDICTED_FY_PROJECTTION+this.state.counter] = this.tabulator.getData()[e][FOCUS_ACCRUALS.FIELDS.PREDICTED_FY_PROJECTTION];
        }
       

        this.setState({
            simulateEnabled: false,
            chartLines: chartLines,
            chartData: originalData
        });
    }

    handlePredictionConfigChange(configType, valueObj, row) {
        const _this = this;
        let tempState = {};
        let tempConfigValues = copyObjectValues(this.state.configValues);
        let data = copyObjectValues(this.tabulator.getData());
        switch(configType) {
            case prediction_config.titles.type: {
                tempConfigValues.type = valueObj.value;
                this.isHistorical = tempConfigValues.type === prediction_config.options.type.historical.value;
                tempState.simulateEnabled = this.isRefYearBuilt;
                break;
            }

            case prediction_config.titles.ref_fiscal_year: {
                tempConfigValues.ref_fiscal_year = valueObj.value;
                this.isRefYearBuilt = this.checkIsRefYearBuilt(tempConfigValues.ref_fiscal_year);
                this.isFetchingData = this.isRefYearBuilt;  //if built, request will be sent
                tempState.simulateEnabled = this.isRefYearBuilt;
                // if(!this.isRefYearBuilt) {
                //     data.map(rowData => {
                //         let predictedData = this.props.calculatePeriodPredictedData(0, rowData[FOCUS_ACCRUALS.FIELDS.COST], 
                //             _this.isHistorical ? rowData[FOCUS_ACCRUALS.FIELDS.RATIO_PERC]: rowData[FOCUS_ACCRUALS.FIELDS.PREDICTED_FY_PROJECTTION], _this.isHistorical);
                //         rowData[FOCUS_ACCRUALS.FIELDS.RATIO_PERC] = _this.isHistorical ? rowData[FOCUS_ACCRUALS.FIELDS.RATIO_PERC] : "N/A";
                //         rowData[FOCUS_ACCRUALS.FIELDS.PREDICTED_FY_PROJECTTION] = !_this.isHistorical ? rowData[FOCUS_ACCRUALS.FIELDS.PREDICTED_FY_PROJECTTION] : "N/A";
                //         rowData[FOCUS_ACCRUALS.FIELDS.PREDICTED_CALCULATED_ACCRUAL] = _this.isHistorical ? predictedData[FOCUS_ACCRUALS.FIELDS.PREDICTED_CALCULATED_ACCRUAL] : "N/A";
                //         rowData[FOCUS_ACCRUALS.FIELDS.PREDICTED_VARIANCE] = _this.isHistorical ? predictedData[FOCUS_ACCRUALS.FIELDS.PREDICTED_VARIANCE] : "N/A";
                //         return rowData;
                //     });
                // }
                break;
            }
            
            case prediction_config.titles.is_by_year: {
                tempConfigValues.is_by_year = $(valueObj.target).val() === prediction_config.options.is_by_year.by_year.value;
                //when selecting by year, set ratios for all periods equal to the main inputted ratio
                if(tempConfigValues.is_by_year) {
                    data.map(rowData => {
                        let predictedData = this.props.calculatePeriodPredictedData(rowData[FOCUS_ACCRUALS.FIELDS.FY_PROJECTION], rowData[FOCUS_ACCRUALS.FIELDS.COST], _this.state.configValues.ratio);
                        rowData[FOCUS_ACCRUALS.FIELDS.RATIO_PERC] = _this.state.configValues.ratio;
                        rowData[FOCUS_ACCRUALS.FIELDS.PREDICTED_FY_PROJECTTION] = predictedData[FOCUS_ACCRUALS.FIELDS.PREDICTED_FY_PROJECTTION];
                        rowData[FOCUS_ACCRUALS.FIELDS.PREDICTED_CALCULATED_ACCRUAL] = predictedData[FOCUS_ACCRUALS.FIELDS.PREDICTED_CALCULATED_ACCRUAL];
                        rowData[FOCUS_ACCRUALS.FIELDS.PREDICTED_VARIANCE] = predictedData[FOCUS_ACCRUALS.FIELDS.PREDICTED_VARIANCE];
                        return rowData;
                    });
                }
                break;
            }

            case prediction_config.titles.ratio: {
                tempState.simulateEnabled = this.isRefYearBuilt;
                tempConfigValues.ratio = getNumericValue($(valueObj.target).val());
                data.map(rowData => {
                    let predictedData = this.props.calculatePeriodPredictedData(rowData[FOCUS_ACCRUALS.FIELDS.FY_PROJECTION], rowData[FOCUS_ACCRUALS.FIELDS.COST], tempConfigValues.ratio);
                    rowData[FOCUS_ACCRUALS.FIELDS.RATIO_PERC] = tempConfigValues.ratio;
                    rowData[FOCUS_ACCRUALS.FIELDS.PREDICTED_FY_PROJECTTION] = predictedData[FOCUS_ACCRUALS.FIELDS.PREDICTED_FY_PROJECTTION];
                    rowData[FOCUS_ACCRUALS.FIELDS.PREDICTED_CALCULATED_ACCRUAL] = predictedData[FOCUS_ACCRUALS.FIELDS.PREDICTED_CALCULATED_ACCRUAL];
                    rowData[FOCUS_ACCRUALS.FIELDS.PREDICTED_VARIANCE] = predictedData[FOCUS_ACCRUALS.FIELDS.PREDICTED_VARIANCE];
                    return rowData;
                });
                valueObj.currentTarget.value = formatValString(tempConfigValues.ratio, FormatTypes.PERCENTAGE);
                break;
            }

            case _input: {
                tempState.simulateEnabled = this.isRefYearBuilt;
                let key = this.isHistorical ? FOCUS_ACCRUALS.FIELDS.RATIO_PERC : FOCUS_ACCRUALS.FIELDS.PREDICTED_FY_PROJECTTION;
                let rowData = row.getData();
                let predictedData = this.props.calculatePeriodPredictedData(rowData[FOCUS_ACCRUALS.FIELDS.FY_PROJECTION], rowData[FOCUS_ACCRUALS.FIELDS.COST], valueObj.value, this.isHistorical);
                let rowIndex = row.getPosition();
                data[rowIndex][key] = getNumericValue(valueObj.value);
                data[rowIndex][FOCUS_ACCRUALS.FIELDS.RATIO_PERC] = predictedData[FOCUS_ACCRUALS.FIELDS.RATIO_PERC];
                data[rowIndex][FOCUS_ACCRUALS.FIELDS.PREDICTED_FY_PROJECTTION] = predictedData[FOCUS_ACCRUALS.FIELDS.PREDICTED_FY_PROJECTTION];
                data[rowIndex][FOCUS_ACCRUALS.FIELDS.PREDICTED_CALCULATED_ACCRUAL] = predictedData[FOCUS_ACCRUALS.FIELDS.PREDICTED_CALCULATED_ACCRUAL];
                data[rowIndex][FOCUS_ACCRUALS.FIELDS.PREDICTED_VARIANCE] = predictedData[FOCUS_ACCRUALS.FIELDS.PREDICTED_VARIANCE];
            }
        }
        tempState.configValues = tempConfigValues;

        this.updateTableData(data, tempState, ()=>{
            if(configType === prediction_config.titles.ref_fiscal_year) {
                if(_this.isRefYearBuilt) {
                    _this.props.fetchNewFYCost();
                } //else do not send request cz it will not return any data
            }
        });
    }

    addReferenceYearTitleFormatter(cell){
        var div = document.createElement("div");
        var p = document.createElement("p");
        var title = this.getActualRefYear();
        p.innerHTML = FOCUS_ACCRUALS.TITLES.REFERENCE_FY_BUILD.replace("Reference", title);
        div.appendChild(p);
        return div;
    }  

    render() {
                
        let chartLines = this.state.chartLines;
        var title = this.getActualRefYear();
        chartLines[1][_lineChartLengendTitle] = FOCUS_ACCRUALS.TITLES.REFERENCE_FY_BUILD.replace("Reference", title);

       var rowDataName = this.props.rowData !== undefined ? this.props.rowData[ACCRUALS.FIELDS.NAME] : "";
       var rowDataRsquarred = !!this.props.rowData && this.props.rowData[ACCRUALS.FIELDS.BE_ACCRUED] && !isNaN(this.props.rowData[ACCRUALS.FIELDS.BE_ACCRUED]) ? this.props.rowData[ACCRUALS.FIELDS.BE_ACCRUED] : "";
        
        var rowDataStatus = this.props.rowData !== undefined ? this.props.rowData[ACCRUALS.FIELDS.STATUS] : "";
        const likeLinessToBeAccrued = <React.Fragment>
                                        <div>{ACCRUALS.TITLES.BE_ACCRUED +"= "}</div>
                                        <div>{formatValString(rowDataRsquarred, FormatTypes.PERCENTAGE)}</div>
                                    </React.Fragment>
		return(
            <div className={"dm-parent-container"}  style={{height: "100%"}}>
                <div id="evaluateLine" className="uk-display-inline-flex uk-width-1-1" style={{height: "100%"}}>
                    <div id="evaluateLineMainTable" className="mapping-parent">
                        <div className={"uk-border uk-position-relative uk-overflow-auto"} style={{height: "100%"}}>
                            <div className="mapping-header uk-flex-between">
                                <div className="display-contents uk-flex-middle">
                                    <div className="uk-display-inline-flex uk-flex-middle">
                                        <span className="uk-text-bold uk-text-xmedium uk-display-flex uk-flex-middle">{(this.isEvaluation ? MESSAGES.accruals.titles.main_table.evaluate : MESSAGES.accruals.titles.main_table.predict) +" - " + rowDataName}</span>
                                        {this.isEvaluation ?
                                            <i className="fs-12 fal fa-info-circle uk-margin-small-left uk-cursor-pointer" uk-tooltip={MESSAGES.accruals.info.main_table.evaluate} />
                                        : ""}
                                    </div>
                                    <div id="evaluate_action_buttons_container" className="uk-flex-middle uk-display-inline-flex">
                                        {this.isEvaluation ?
                                            <React.Fragment>
                                                <div id="evaluate_change_status" className="uk-margin-small-right" style={{padding: convertPxToViewport(10) +' 0'}}>{MESSAGES.accruals.actions.change_status}</div>
                                                <div id="evaluate_status" className="uk-button-icon text-grey uk-margin-small-right" onClick={() => this.showActions()}>
                                                    {rowDataStatus ? capitalizeFirstLetter(rowDataStatus.toLowerCase()):""}
                                                    <i className="uk-margin-xmedium-left fa-2x fal fa-ellipsis-v text-black" />
                                                </div>
                                            </React.Fragment>
                                            :
                                            <div>
                                                <Button 
                                                    variant={BUTTON_VARIANT.TERTIARY}
                                                    size={SIZES.DEFAULT}
                                                    type={BUTTON_TYPE.DEFAULT}
                                                    className="uk-button-icon uk-margin-medium-right"
                                                    label={MESSAGES.accruals.actions.simulate}
                                                    leftIcon={<i className="fal fa-calculator-alt uk-margin-small-right" />}
                                                    onBtnClick={this.updateSimulationData} 
                                                    disabled={!this.state.simulateEnabled}
                                                />
                                            </div>
                                        }
                                        { this.state.showActions ? 
                                            <div id="action_buttons_container" className="evaluateAccrualActionContainer">
                                                { [ACCRUALS.FIELDS.STATUS_VALUES.ACCRUED, ACCRUALS.FIELDS.STATUS_VALUES.UNDEFINED].includes(rowDataStatus) ?
                                                   <Button
                                                        id="exclude_icon"
                                                        label={MESSAGES.accruals.actions.exclude}
                                                        variant={BUTTON_VARIANT.SECONDARY}
                                                        size={SIZES.DEFAULT}
                                                        type={BUTTON_TYPE.DEFAULT}
                                                        title="Exclude"
                                                        leftIcon={<i className="fa-lg fal fa-file-minus" />}
                                                        onBtnClick={()=>{this.actionClick(ACCRUALS.FIELDS.STATUS_VALUES.EXCLUDED, undefined, this.props.rowData)}}
                                                        className="uk-padding-small-right uk-cursor-pointer" 
                                                   />
                                                   :""}
                                                { rowDataStatus === ACCRUALS.FIELDS.STATUS_VALUES.EXCLUDED ?
                                                     <Button 
                                                        id="include_icon"
                                                        label={MESSAGES.accruals.actions.include}
                                                        variant={BUTTON_VARIANT.SECONDARY}
                                                        size={SIZES.DEFAULT}
                                                        type={BUTTON_TYPE.DEFAULT}
                                                        title="Include"
                                                        leftIcon={<i className="fa-lg fal fa-file-plus" />}
                                                        onBtnClick={()=> {this.actionClick(ACCRUALS.FIELDS.STATUS_VALUES.UNDEFINED, ACCRUALS.ACTIONS.INCLUDE, this.props.rowData) }}
                                                        className="uk-float-right uk-padding-small-right uk-cursor-pointer" 
                                                     />
                                                : ""}
                                                { rowDataStatus === ACCRUALS.FIELDS.STATUS_VALUES.EXCLUDED || rowDataStatus === ACCRUALS.FIELDS.STATUS_VALUES.UNDEFINED ?
                                                    <Button 
                                                        id="accrue_icon"
                                                        label={MESSAGES.accruals.actions.accrue}
                                                        variant={BUTTON_VARIANT.SECONDARY}
                                                        size={SIZES.DEFAULT}
                                                        type={BUTTON_TYPE.DEFAULT}
                                                        title="Accrue"
                                                        leftIcon={<i className="fa-lg fal fa-money-check-edit-alt" />}
                                                        onBtnClick={()=> {this.actionClick(ACCRUALS.FIELDS.STATUS_VALUES.ACCRUED, undefined, this.props.rowData) }}
                                                        className="uk-float-right uk-padding-small-right uk-cursor-pointer" 
                                                    />
                                                : ""}
                                                { rowDataStatus === ACCRUALS.FIELDS.STATUS_VALUES.ACCRUED ?
                                                     <Button 
                                                        id="accrue_icon"
                                                        label={MESSAGES.accruals.actions.remove_accrual}
                                                        variant={BUTTON_VARIANT.SECONDARY}
                                                        size={SIZES.DEFAULT}
                                                        type={BUTTON_TYPE.DEFAULT}
                                                        title="Accrue"
                                                        leftIcon={<i className="fa-lg fal fa-ban " />}
                                                        onBtnClick={()=> {this.actionClick(ACCRUALS.FIELDS.STATUS_VALUES.UNDEFINED, ACCRUALS.ACTIONS.REMOVE_ACCRUAL, this.props.rowData)}}
                                                        className="uk-float-right uk-padding-small-right uk-cursor-pointer" 
                                                     />
                                                : ""}
                                            </div>
                                        : "" }
                                    </div>
                                </div>
                            </div>
                            <div className="focus-accrual-table uk-padding-small">
                                { this.isPrediction ?
                                    <div id="prediction_config_div">
                                        <div className="uk-display-flex uk-flex-middle uk-margin-xmedium-bottom">
                                            <h5 className="width-150">{prediction_config.titles.type}</h5>
                                            <Dropdown
                                                id="predict-config-type"
                                                className="uk-width-small uk-cursor-pointer input__dropdown"
                                                value={findOptionByKey(this.state.configOptions.type, this.state.configValues.type)}
                                                onChange={(chosenOpt) => this.handlePredictionConfigChange(prediction_config.titles.type, chosenOpt)}
                                                options={this.state.configOptions.type}
                                                type={DROPDOWN_TYPE.INPUT}
                                            />
                                        </div>
                                        <div className="uk-display-flex uk-flex-middle uk-margin-xmedium-bottom">
                                            <h5 className="width-150">{prediction_config.titles.ref_fiscal_year}</h5>
                                            <Dropdown
                                                id="predict-config-type"
                                                className="uk-width-small uk-cursor-pointer input__dropdown"
                                                value={findOptionByKey(this.state.configOptions.ref_fiscal_year, this.state.configValues.ref_fiscal_year)}
                                                onChange={(chosenOpt) => this.handlePredictionConfigChange(prediction_config.titles.ref_fiscal_year, chosenOpt)}
                                                options={this.state.configOptions.ref_fiscal_year}
                                                type={DROPDOWN_TYPE.INPUT}
                                            />
                                        </div>
                                        { this.isHistorical ?
                                            <div className="uk-display-flex uk-flex-middle uk-margin-xmedium-bottom">
                                                <h5 className="width-150">{prediction_config.titles.ratio}</h5>
                                                <div className="uk-flex uk-flex-middle uk-cursor-pointer">
                                                    <RadioButton id="byYear" className="uk-margin-remove" value={prediction_config.options.is_by_year.by_year.value} checked={this.state.configValues.is_by_year}
                                                            onChange={(e)=>this.handlePredictionConfigChange(prediction_config.titles.is_by_year, e)} label={prediction_config.options.is_by_year.by_year.title}/>
                                                    {/* <label htmlFor="byYear" className="uk-margin-default-left fs-14">{prediction_config.options.is_by_year.by_year.title}</label> */}
                                                </div>
                                                <div className={"uk-flex uk-flex-middle uk-margin-medium-left width-90 "+(!this.state.configValues.is_by_year ? "disabled":"")}>
                                                    <input className="uk-margin-xsmall-left spinner-input" jqueryui="spinner" defaultValue={formatValString(this.state.configValues.ratio, FormatTypes.PERCENTAGE)}
                                                        step={1} disabled={!this.state.configValues.is_by_year}
                                                        onBlur={(e)=>this.handlePredictionConfigChange(prediction_config.titles.ratio, e)}/>
                                                </div>
                                                <div className="uk-flex uk-flex-middle uk-margin-medium-left uk-cursor-pointer">
                                                    <RadioButton id="byPeriod" className="uk-margin-remove"value={prediction_config.options.is_by_year.by_period.value} checked={!this.state.configValues.is_by_year}
                                                            onChange={(e)=>this.handlePredictionConfigChange(prediction_config.titles.is_by_year, e)} label={prediction_config.options.is_by_year.by_period.title}/>
                                                    {/* <label htmlFor="byPeriod" className="uk-margin-default-left fs-14">{prediction_config.options.is_by_year.by_period.title}</label> */}
                                                </div>
                                            </div>
                                        :""}
                                    </div>
                                :""}
                                <div className="accrualCalc">
                                    <div id="evaluate_accruals" ref="mainTable" />
                                </div>
                            </div>
                        </div>
                    </div>
                        { this.props.data.length > 0  ? 
                            <React.Fragment>
                                <div id="" className={"accounts-chart-parent uk-position-relative uk-overflow-auto uk-margin-remove-bottom"} >
                                    <div className="mapping-header justify-content-between">
                                        <span className="uk-text-bold uk-text-xmedium uk-display-flex uk-flex-middle">{MESSAGES.psl_config.title}</span>
                                    </div>
                                    <CostClassification costClassification={this.props.costClassification} costCenter={this.props.costCenter} metricFields = {this.props.metricFields}
                                        glCosts={this.props.glCosts} rowData={this.props.rowData} periods={this.props.periods} showFYData={true} vectorOptions={this.props.vectorOptions}
                                    />
                                
                                    <div className="mapping-header justify-content-between">
                                        <span className="uk-text-bold uk-text-xmedium uk-display-flex uk-flex-middle">{MESSAGES.accruals.titles.chart}
                                            <i className="fs-12 fal fa-info-circle uk-margin-small-left uk-cursor-pointer" uk-tooltip={MESSAGES.accruals.info.chart} />
                                        </span>
                                    </div>
                                    <LineChart ref={el => this.lineChartRef = el} axes={this.state.chartAxes} lines={chartLines} conclusionText={likeLinessToBeAccrued}
                                        data={this.state.chartData || this.props.data} isEvaluation={this.props.isEvaluation}
                                        showOverlay={!this.checkIsRefYearBuilt(this.state.configValues.ref_fiscal_year)} hideZoomBar
                                        overlayText={lang.accruals.accrual_chart_overlay_message.replace("$not_built_years$", this.getActualRefYear())}
                                    />
                                </div>
                            </React.Fragment>
                            :
                            <div className="uk-display-flex uk-margin-small-top">
                                <span className="no-data-message">{MESSAGES.no_data_available}</span>
                            </div>
                        }
                </div>
            </div>
		);

	}

}

export default FocusAccrual;