import React, { Component } from 'react';

class Tile extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let tile = this.props.tile ? this.props.tile : "";
        let buttonClassName = tile.isSelected ? "calendar-quarter selected-quarter" : "calendar-quarter";
        let label = this.props.tileContent(tile) ? this.props.tileContent(tile) : <p>{tile.value}</p>

        return (
            <button
                className={buttonClassName}
                disabled={tile.isDisabled}
                onClick={(e) => this.props.handleSelect(tile, e)}
            >
                {label}
            </button>
        )
    }
}

export default Tile;