let db;

// Open or create the database
export function openDatabase() {
  return new Promise((resolve, reject) => {
    const request = window.indexedDB.open('my-database', 1);

    request.onupgradeneeded = function (event) {
      db = event.target.result;
      if (!db.objectStoreNames.contains('storeName')) {
        // Create an object store called 'storeName' with keyPath 'id'
        db.createObjectStore('storeName', { keyPath: 'id'});
      }
    };

    request.onsuccess = function (event) {
      db = event.target.result;
      resolve(db);
    };

    request.onerror = function (event) {
      reject('Error opening database: ' + event.target.errorCode);
    };
  });
}

// Add item to the store
export function addItem(data) {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(['storeName'], 'readwrite');
    const store = transaction.objectStore('storeName');
    const request = store.add(data);

    request.onsuccess = function () {
      resolve('Item added successfully');
    };

    request.onerror = function (event) {
      reject('Error adding item: ' + event.target.errorCode);
    };
  });
}

// Get all items from the store
export function getAllItemsFromIndexedDB() {
  return new Promise((resolve, reject) => {
    if (db) {
      const transaction = db.transaction(['storeName'], 'readonly');
      const store = transaction.objectStore('storeName');
      const request = store.getAll();
  
      request.onsuccess = function (event) {
        resolve(event.target.result);
      };
  
      request.onerror = function (event) {
        reject('Error fetching items: ' + event.target.errorCode);
      };
    }
   
  });
}

// Delete item from the store
export function deleteItem(id) {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(['storeName'], 'readwrite');
    const store = transaction.objectStore('storeName');
    const request = store.delete(id);

    request.onsuccess = function () {
      resolve('Item deleted successfully');
    };

    request.onerror = function (event) {
      reject('Error deleting item: ' + event.target.errorCode);
    };
  });
}

export function getItemFromIndexedDB(key) {
  return new Promise((resolve, reject) => {
    if (db) {
      const transaction = db.transaction(['storeName'], 'readonly');
      const store = transaction.objectStore('storeName');
      const request = store.get(key);
  
      request.onsuccess = function (event) {
        resolve(event.target.result);
        return event.target.result;
      };
  
      request.onerror = function (event) {
        reject('Error fetching item: ' + event.target.errorCode);
      };
    }
    
  });
}

export function clearIndexedDB() {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(['storeName'], 'readwrite');
    const store = transaction.objectStore('storeName');
    const request = store.clear();

    request.onsuccess = function () {
      resolve('All Items deleted successfully');
    };

    request.onerror = function (event) {
      reject('Error deleting items: ' + event.target.errorCode);
    };
  });
}


export function closeDatabase() {
    if (db) {
      db.close();
      console.log('Database connection closed');
    }
  }
