import React, { Component } from 'react';
import { convertPxToViewport } from '../class/formatting';
import CheckBox from '../newComponents/CheckBox';
import RadioButton from '../newComponents/RadioButton';
import { Segment } from './Segment';

class ToggleOptionsList extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    onChangeOption(option, e) {
        if(this.props.onChange) {
            this.props.onChange(option, e.target.checked);
        }
    }

    renderOptionsList() {
        const _this = this;
        let optionsList = [];
        let maxSize = 55;
        let propOptions = this.props.options || [];     //to avoid a type error when having the props undefined
        propOptions.forEach(option=>{
            let color = option.color;
            let values = _this.props.values ? Array.isArray(_this.props.values) ? _this.props.values : [_this.props.values] : [];
            let isChecked = values ? values.map(valOption=>valOption.value).includes(option.value) : false;
            let labelText = new Segment().getSegmentObject(option.label)?.label || option.label;
            optionsList.push(
                <div key={"div-"+option.value} className="uk-flex uk-flex-middle" title={option.label}>
                    {!!_this.props.isRadio?
                        <div /*key={"div-"+option.value}*/>
                            <li className="uk-flex uk-flex-middle uk-padding-xxsmall">
                                <RadioButton id={"option-list"} onChange={(e)=>this.onChangeOption(option, e)} checked={isChecked} className="uk-margin-small-right noprint" label={option.label}/>
                            </li>
                        </div>
                        :
                        <div>
                            <li className="uk-flex uk-flex-middle uk-padding-xxsmall">
                                <CheckBox id={"option-list"+option.label} onChange={(e)=>this.onChangeOption(option, e)} checked={isChecked} inputAddedClassName = {"noprint"}
                                    labelClassName={"options-list fs-14 no-events-cells uk-text-overflow-ellipsis"} labelText={labelText} />
                                <div className={"colored-circle uk-margin-small-left"} style={option.color ? {backgroundColor:option.color} : {}}></div>
                            </li>
                        </div>
                    }
                    <div style={{width: convertPxToViewport(20), height: convertPxToViewport(20),  marginRight:convertPxToViewport(10), display: "inline-block", verticalAlign:"middle"}} className={"onlyprint-inline"} >
                        <svg width={convertPxToViewport(20)} height={convertPxToViewport(20)}>
                            <rect width={convertPxToViewport(20)} height={convertPxToViewport(20)} style={{"fill":color}} />
                        </svg>
                    </div>
            </div>
            );
        })

        return optionsList;
    }

    render() {
        const options = this.renderOptionsList();
        return (
            <ul className={(this.props.isChartFullScreen ? "chart-maximized " : "") + "toggle-options-container uk-flex uk-flex-center uk-flex-column uk-width-auto@m " + this.props.className} style={{ width: this.props.isChartFullScreen ? "unset" : "100%"}} >
                {options}
            </ul>
        );
    }
}

export default ToggleOptionsList;