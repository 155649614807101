import cookie from 'react-cookies';
import {getTranslationFile, findOptionByKey, extractValueFromObjects} from './utils.js';
import Popup from 'react-popup';
// import '../styles/popup.css';
import { comparison_suffixes, ENGINE_FILTER, MAX_RECORDS_ALLOWED, ROW_NUMBER, VECTOR_STAGING_ATTRIBUTES } from './constants.js';
import { convertPxToViewport } from './formatting.js';

const $ = require('jquery');
const MESSAGES = getTranslationFile();

const _empty = MESSAGES.ui_filter.dropdowns.functions.empty.value;
const _nempty = MESSAGES.ui_filter.dropdowns.functions.not_empty.value;
var _nct = MESSAGES.ui_filter.dropdowns.functions.not_contains.value;
var _ct = MESSAGES.ui_filter.dropdowns.functions.contains.value;
var _neq = MESSAGES.ui_filter.dropdowns.functions.not_equals.value;
const _metric = "metric";
const _calculatedColumn = "calculated_column";
const OPERATORS = ['CONTAINS', 'DOES NOT CONTAIN', _ct, _nct];

function debounce(fn, duration) {
    clearTimeout(window._timer);
    window._timer = setTimeout(fn, duration);
} 
// Hiding drill items
function hideDrillItem() {
  $(".to-hide-on-drill").hide();
}

// Showing drill items
function showDrillItem(tableRef) {
  if(tableRef) {
    $(tableRef).find(".to-hide-on-drill").show();
  } else {
    $(".to-hide-on-drill").show();
  }
}

function getPSTier(tier, isDrillingParam) {
  var isDrilling = $("#isDrilling").val();
  var previousTier = $("#Old_Tier").text().replaceAll(" ", "");

  if (isDrillingParam || isDrilling === '1' || cookie.load('isDrilling')) {
    return previousTier;
  }else{
    return tier;
  }
}



// Clears the drilldown section
function destroyDrillSections() {
  // Hiding drill elements
  $('#To_Scroll_Top').hide();
  $('#DrillDown').hide();
  $('#To_Scroll_List_Drill').hide();
  $('#To_Scroll_List').show();

  // Destroying tr element to refill later on and avoid duplicates
  $("#Table_Top").find("tbody tr").each( function() {
    if(!$(this).hasClass("last")) {
      $(this).remove();
    }
  });
}

function removeAllCookies(removeFilter=false) {
  cookie.remove('filterBox', {path: '/'});
  cookie.remove('profitFormat', {path: '/'});
  cookie.remove('FY', {path: '/'});
  cookie.remove('tier', {path: '/'});
  cookie.remove('isDrilling', {path: '/'});
  cookie.remove('entityFilter', {path: '/'});
  cookie.remove('defLimit',{path:'/'});
  cookie.remove('vector',  {path: '/'});
  cookie.remove("nextStartQuarter",  {path: '/'});
  cookie.remove("nextEndQuarter",  {path: '/'});
  cookie.remove("nextCustomStartDate",  {path: '/'});
  cookie.remove("nextCustomEndDate",  {path: '/'});
  cookie.remove("fy",  {path: '/'});
  cookie.remove('period',  {path: '/'});
  cookie.remove("FY",  {path: '/'});
  cookie.remove('version',  {path: '/'});
  cookie.remove('dataSet',{path: '/'});
  cookie.remove('tablePrefix',{path: '/'});
  if(removeFilter){
    localStorage.removeItem('current_filter', {path : '/'});
    localStorage.removeItem('current_saved_filter_id', {path : '/'});
    localStorage.removeItem('filterFinal', {path: '/'});
    localStorage.removeItem('saved_filter', {path: '/'});
    localStorage.removeItem('saved_filter_id', {path: '/'});
  }
}

function saveCookie(myCookie, value) {
  const expires = new Date()
  expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 14); 
  cookie.save(myCookie, value, {path: '/', expires: expires, maxAge: 4680});
}

function readCookie(cookieName) {
  var value = cookie.load(cookieName, {path:"/"});
  try {
    return JSON.parse(value);
  } catch (err) {
    return value;
  } 
}

function removeCookie(cookieName) {
  cookie.remove(cookieName, {path:"/"});
}

function removeCookies(cookieNames) {
    cookieNames.forEach(cookieName=>{
        removeCookie(cookieName);
    })
}

function saveAllCookies(format, filter,FY,tier,dataSet){
    saveCookie('filterFinal', filter, {path: "/"});
    saveCookie('profitFormat', format);
    saveCookie('FY',FY);
    saveCookie('vector',tier);
}


function removeSpacing(str){
 return str.replace(/\s/g,'');
}

function vectorSpacing(vector) {
  var obj = "";
  if (vector && vector !== null && vector !== "" ) {
    obj = vector.replace(/([A-Z]+)/g, ' $1').replace(/[^0-9](?=[0-9])/,'$& ').trim()
  } 
  
  return obj;
}

function resetDrillSet() {
  $("input[name=tier]").addClass("form-control-header-tier");
  $("#Old_Tier").text($("input[name=tier]").val());
}

// Getting limit from main filter
function getLimit() {
  var val = $('#limit').val();
  return  (!val || !val.length) ? readCookie('defLimit') ? Number(readCookie('defLimit')) :100 : val;
}

function setLimit(value) {
  $('#limit').val(value);
}

function getDrillRowsLimit(){
   var  val = $('#DrillRows').val();
   return  val;
}

function setDrillRowsLimit(value){
   $('#DrillRows').val(value);
}


function hideProfitStackLink() {
  // hideProfitStackBtn();
}

function showProfitStackLink() {
  // showProfitStackBtn();
}

function getDefaultCookieValue(cookieName, defaultValue) {
    var cookieValue = readCookie(cookieName);
    if([undefined, "undefined", "null"].includes(cookieValue)) {
        return defaultValue;
    } else {
        return cookieValue;
    }
}

function removeCursor (id){
  var isExpanded = "";
  $('.Select-control').click(function(){
    isExpanded =  $('#'+id).attr('aria-expanded');
   if(isExpanded === "true"){
    $('#'+id).css({'text-indent':''});
   } else {
    $('#'+id).css({'text-indent':'-9999999em'});
  }});
}

function replaceSpecialCharacters(string, newChar) {
  if (string) {
    if(newChar) {
      return string.replace(/\W/g, newChar);
    } else {
      return string.replace(/\W/g,"");
    }
  }
}

String.prototype.replaceAll = function(search, replacement) {
  var target = this;
  return target.replace(new RegExp(search, 'g'), replacement);
};

function replaceAll(str, map){
  for(var key in map){
      str = str.replaceAll(key, map[key]);
  }
  return str;
}

function isInputEmpty(text) {
  return !text.trim().length;
}

function isValidEmailAddress(emailAddress) {
  return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(emailAddress)
}

function isValidName(name) {
  var pattern = /^[a-z ,.'-]+$/i;
  return pattern.test(name);
}

function isValidMobileNumber(number) {
  var pattern = /^\+[0-9\s\-\(\)]+$/;
  if(number.match(pattern) && number.length >= 11) {
    return true;
  }
  else {
    return false;
  }
}

function isValidPassword(password) {
  //according to firebase, a password should be at least 6 chars long
  if(password && password.length >= 6) {
    return true;
  }

  return false;
}

function prepareLogo(domain, clientName) {
  // if(domain.match("localhost")) {
  //   return 'local';
  // }
  let url = clientName || domain;
  var clearHttp = url.replace("https://", '').replace('.profitisle.com', '').replace(/[0-9]/g,'').replace(/[A-Za-z]+\./,"");
  return clearHttp;
}

function splitOnUpperCaseChar(str){
  var splittedArray = str.split(/(?=[A-Z])/);
  var reportToDisplay = '';
  for( var i=0 ; i<splittedArray.length ; i++){
    reportToDisplay += splittedArray[i] + ' ';
  }
  return reportToDisplay;
}

function alertAndLogError(error) {
  // Popup.alert("An internal error has occured. Please try again later.");
  // waitBeforeReload(60000);
  // $(".loading").hide();
  console.log(error);
}

function alertError(error) {
  if(typeof error === "object" && error.message) {
    Popup.alert(error.message);
  } else {
    Popup.alert(error);
  }
  $(".loading").hide();
}

 function validateVectors(data){
  for(var i =0; i< data.length; i++){
    if(data[i].status === 'ENABLED' || data[i].status === 'SUGGESTED'){
      if(data[i][VECTOR_STAGING_ATTRIBUTES.GROUP] === ""){
         return false;
      }
    }
  }
  return true;
 }



  function translateRuleStringToObject (ruleString) {
    var rule = [];
    if(ruleString === "[]") {
      //directly coming empty from the API
      return rule;
    }
    var rulesArr = ruleString.split(",");

    rulesArr.forEach(element => {
      var row = element.split(":");
      var ruleRow = {};
      
      //push rule vector
      ruleRow.ruleVector = row[0];
      //push rule condition (without 'Metric.' if it contains it)
      ruleRow.ruleCondition = row[1].replace("Metric.","");
      
      rule.push(ruleRow);
    });

    return rule;
  }

function validateFields(){
	var format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
	var metricName = $("#Metric_Name").val(); 

	if(metricName === '' || format.test(metricName)) {
		alert ("Please enter a valid Metric Name");
		return false;
	} else {
		return true; 
	}
}

function addRowNumberToOptions(arr, rowNumber) {
    //we use JSON.parse(JSON.stringify(obj)) to make a clone of the object keys and values,
    //and not just make a copy of its reference

    var temp = [];
    for(var obj in arr) {
        obj = JSON.parse(JSON.stringify(arr[obj]));
        obj[ROW_NUMBER] = rowNumber;

        temp.push(obj);
    }
    return temp;
}

function addIndexNumberToOptions(arr, indexNumber) {
  //we use JSON.parse(JSON.stringify(obj)) to make a clone of the object keys and values,
  //and not just make a copy of its reference

  var temp = [];
  for(var obj in arr) {
      obj = JSON.parse(JSON.stringify(arr[obj]));
      obj.indexNumber = indexNumber;

      temp.push(obj);
  }
  return temp;
}

function setTypePassword(e) {
    var input = $("#" + e.currentTarget.id);
    input.attr('type', 'password');
    input.attr('autoComplete', 'new-password');
}

function extractCostKeys(data, costKeys) {
    for(var e in data) {
        if(data[e]["children"] && data[e]["children"].length > 0) {
            costKeys.push(data[e]["costKey"]);
            costKeys = extractCostKeys(data[e]["children"], costKeys);
        } else {
            costKeys.push(data[e]["costKey"]);
        }
    }
    return costKeys
}
function formatConditionValues(value, operator, strField) {
  var values = value;
  var toBeUsedValues = [];
var field = "";

//when operator is empty or not empty, the value field will be ""
  operator !== "GT"  && operator !== "LT" && values !== "" ? values.forEach(function(val, key){
    toBeUsedValues[key] = val.value.toLowerCase();    //setting everything to lower case so that the filter becomes case insensitive
      
      if(isNaN(parseFloat(val.value))) {    //if a value is not a number, then the column in DB is not numeric, so we can set it to Lower()
          field = "LOWER("+ strField +")";
      }
  }) : typeof values === "object" ? values.forEach(function(val, key){
    toBeUsedValues[key] = val.value.toLowerCase();    //setting everything to lower case so that the filter becomes case insensitive
      
      if(isNaN(parseFloat(val.value))) {    //if a value is a number, then the column in DB is  numeric
          field = "("+ strField +")";
      }
  }) : toBeUsedValues[0]= value
  
  field = field === "" ? strField : field;
  var neg = operator.toLowerCase().includes("not") || [_nct, _neq].includes(operator.toUpperCase()) ? " NOT" : "";
  var finalValue = "";
  if (!OPERATORS.includes(operator.toUpperCase())) {
     finalValue = field + neg;
     finalValue += operator.indexOf("Equal") !== -1 || operator.indexOf("EQ") !== -1 ? " IN ('" + toBeUsedValues.join("','") + "')"
        : operator.indexOf("GT") !== -1 ?  " > " + toBeUsedValues.join(" OR " + field + " > ")
         : operator.indexOf("LT") !== -1 ? " < " + toBeUsedValues.join(" OR " + field + " < ")
           : operator === _empty ? " IS  NULL OR " +field + "=''"
           : operator === _nempty ? " IS NOT NULL AND " +field + "!=''"
           : "";
            
  } else {
    var final = toBeUsedValues.map(function(item) { return field + " LIKE '%" + item.trim() + "%'" }).join(" OR ");
    finalValue = neg + "(" + final +")";
  }

  return "("+finalValue+")";
}


function translateFilter(filter, order) {
  var finalFilter = [];
  if(order === true) {
      filter = orderFiltersByAttribute(filter, "field");
      var keys = Object.keys(filter);
      for(var field in keys) {
          field = keys[field];
          var sameFieldFilter = filter[field];
          var tempFinalFilter = [];
          for(var i =0; i< sameFieldFilter.length; i++) {
              if((sameFieldFilter[i].value !== '' && [_empty, _nempty].indexOf(sameFieldFilter[i].operator) === -1) || [_empty, _nempty].indexOf(sameFieldFilter[i].operator) > -1) {
                  tempFinalFilter.push(formatConditionValues(sameFieldFilter[i].value, sameFieldFilter[i].operator, sameFieldFilter[i].field));
              }
          }
          finalFilter.push("("+ tempFinalFilter.join(" OR ") +")");
      }
  } else {
      for(let i =0; i< filter.length; i++) {
          if(filter[i].value !== '') {
              finalFilter.push(formatConditionValues(filter[i].value, filter[i].operator, filter[i].field));
          }
      }
  }
  return finalFilter.join(" AND ");
}

function formatRule(vector, condition, is_cost_center, costCenter) {
    var value="";
    var append = vector === "InvoiceLine" ? ":"+condition : condition === "Count" ? ":"+condition : !is_cost_center ? ":Metric."+condition : ":Metric."+condition+":Center."+costCenter;
    value = vector+append; 
    return value;
}

function translateRule(rule, costCenter) {
    var finalRule = [];
    for(var i =rule.length-1; i>=0; i--) {
        if(rule[i].vector !== '') {
            finalRule.push(formatRule(rule[i].vector, rule[i].condition, rule[i].is_cost_center, costCenter));
        }
    }

    return finalRule.join(",");
}

function reTranslateRule(rule, costCenter) {
    var ruleObj = rule.split(",");
    var ruleFinal = [];
    if(rule) {
        for(var i=ruleObj.length-1; i>=0; i--) {
          if (ruleObj[i].includes(":")) {
            var vector = ruleObj[i].split(":")[0];
            var condition = ruleObj[i].split(":")[1].replace("Metric.","");
            var cell = {"vector":vector,"condition":condition}
            if (condition.includes("Center")) {
                condition = condition.replace("Center.","").replace(costCenter,"");
                cell = {"vector":vector,"condition":condition, "is_cost_center":true}
            }
            cell = {"vector":vector,"condition":condition}
            ruleFinal.push(cell);
          }
        }
    }
  return JSON.stringify({rule:ruleFinal})
}

function translateFormulaToString(formula, orderByField) {
  const _column = ENGINE_FILTER.KEYS.COLUMN;
  const _function = ENGINE_FILTER.KEYS.FUNCTION;
  const _values = ENGINE_FILTER.KEYS.VALUES;

	var stringFormula = "";
	var finalFormula = [];
	if(formula === "") {
		return stringFormula;
	} else if(typeof formula === "string") {
		formula = JSON.parse(formula);
	}
	
	if(formula.conditions && formula.result) {
        //setting up conditions and filters
        var hasConditions = formula.conditions.length ? true : false;
        var stringFunctionOptions = getObjectAsArray(MESSAGES.ui_filter.dropdowns.functions, "string", "value_type");
        var numericFunctionOptions = getObjectAsArray(MESSAGES.ui_filter.dropdowns.functions, "numeric", "value_type");

        stringFormula = hasConditions ? "CASE " : "";
        formula.conditions.forEach(cond=>{
            var orderedFilters = orderFiltersByAttribute(cond.filters, orderByField);
            var sameAttrRows = [];
            Object.keys(orderedFilters).forEach(key=>{
                var translatedFilterRows = [];
                orderedFilters[key].forEach(row => {
                    var tempOperator = "";
                    if(extractValueFromObjects(stringFunctionOptions).includes(row[_function].toUpperCase())) {
                        tempOperator = findOptionByKey(stringFunctionOptions, row[_function].toUpperCase());
                    } else {
                        tempOperator = findOptionByKey(numericFunctionOptions, row[_function].toUpperCase());
                    }
                  tempOperator = tempOperator ? tempOperator["label"].toLowerCase() : "";
                    if (typeof row[_values] === "string") {
                        translatedFilterRows.push(row[_column]+" "+ tempOperator  + row[_values] !== "" ? " '" +row[_values]+"'"  : "");
                    } else if (typeof row[_values] === "object" && [_ct, _nct].indexOf(row[_function]) > -1) {
                        translatedFilterRows.push(row[_column]+" "+ tempOperator +" '" + row[_values].map(v=>{return v.value}).join("','") +"'");
                    } else {
                        var rowValues = [];
                        row[_values].map((r)=> {
                            rowValues.push(r.value);
                        }) 
                        if (rowValues.length > 0)
                          translatedFilterRows.push(row[_column]+" IN ('" + rowValues.join("','") +")'");
                    }
                })
                if (translatedFilterRows.length > 0) 
                  sameAttrRows.push("("+ translatedFilterRows.join(" OR ") +")");
            });

            var conditionResult = [];
            cond.result.forEach((row)=> {
                conditionResult.push(row.value);
            });
            finalFormula.push("WHEN " + sameAttrRows.join(" AND ") + " THEN " + conditionResult.join(" "));
        });
    
        //setting up outer result
        var mainResult = [];
        formula.result.forEach((row)=> {
            mainResult.push(row.value);
        });

        stringFormula += finalFormula.join(" ");
        stringFormula += hasConditions ? " ELSE " : "";
        stringFormula += mainResult.join(" ");
	} else {
		formula.forEach((row, index)=> {
			stringFormula += index > 0 ? " " : "";
			stringFormula += row.value;
		});
	}

	return stringFormula;
}

function orderFiltersByAttribute(filters, attribute) {
	var finalFilters = {};
	for(var f in filters) {
		if(!finalFilters[filters[f][attribute]]) {
			finalFilters[filters[f][attribute]] = [];
		}
		finalFilters[filters[f][attribute]].push(filters[f]);
	}

	return finalFilters;
}

function getObjectAsArray(array, type, filterBy) {
    if (filterBy === "" || filterBy === null) {
        filterBy = "value_type";
    }
    var funcs = [];
    var keys = Object.keys(array);
    keys.filter(i=>{
        let option = array[i];
        if(option[filterBy] === type) {
            funcs.push(option);
        }
    });

    return funcs;
}

function toggleEnableHeaderNext(enable) {
    if(enable) {
        $("#header-next").removeClass("uk-button-disabled");
    } else {
        $("#header-next").addClass("uk-button-disabled");
    }
}

function toggleHeaderBack(enable) {
  if(enable) {
      $("#header-back").removeClass("uk-button-disabled");
  } else {
      $("#header-back").addClass("uk-button-disabled");
  }
}

function isOdd(field) {
    if (typeof field === 'string') {
        field = JSON.parse(field);
    }
    if (field.length > 1) {
        if (field.length % 2 === 0) {
            return true;
        }
    }
    return false;
}

function extractInitials(name) {
    var parts = name.split(' ')
    var initials = ''
    for (var i = 0; i < parts.length; i++) {
      if (parts[i].length > 0 && parts[i] !== '') {
        initials += parts[i][0]
      }
    }
    return initials
}

function arrangeAncillaryFiles(data) {
    function compare(a, b) {
        // Use toUpperCase() to ignore character casing
        const valueA = a.label ? a.label.toUpperCase() : "";
        const valueB = b.label ? b.label.toUpperCase() : "";
        
        let comparison = 0;
        if (valueA > valueB) {
            comparison = 1;
        } else if (valueA < valueB) {
            comparison = -1;
        }
        return comparison;
    }
  
    var fileNamesWithTitles = [];
    if (data && data.length > 0) {
        var ancillaryFiles = data.filter(e => ![_metric,_calculatedColumn].includes(e.type));
        var metricFiles = data.filter(e => e.type === _metric);
        var calculatedColumns = data.filter(e => e.type === _calculatedColumn)
        
        if (ancillaryFiles.length > 0) {
            fileNamesWithTitles.push({ value: "ancillary_files", label: "Ancillary Files", isDisabled: true});
            fileNamesWithTitles = fileNamesWithTitles.concat(ancillaryFiles.sort(compare));
        }
        
        if (metricFiles.length > 0) {
            fileNamesWithTitles.push({ value: "metrics", label: "Metrics", isDisabled: true});
            fileNamesWithTitles = fileNamesWithTitles.concat(metricFiles.sort(compare));
        } 

        if(calculatedColumns.length > 0) {
          fileNamesWithTitles.push({ value: "calculated_columns", label: "Calculated Columns", isDisabled: true});
          fileNamesWithTitles = fileNamesWithTitles.concat(calculatedColumns.sort(compare));
        }
        
    }

    return fileNamesWithTitles;
}

function getCleanReturnName(retName) {
    retName = retName.replace(comparison_suffixes.reference, "").replace(comparison_suffixes.comparison, "").replace(comparison_suffixes.difference, "").replace(comparison_suffixes.selection, "");
    return retName;
}

function getPeriodNumber(period, withPrefix=false) {
    return (withPrefix ? "P" : "") + period.split("P")[1];
}

function getPeriodYear(period) {
    return Number(period.split("P")[0]);
}

/**
 * @function changeHeaderWidthOnOverFlow()
 * Change Header width based on the overflow state using params sent from
 * @function checkIfContainerHasOverflow()
 */

function changeHeaderWidthOnOverFlow(overFlow, elementToModify, defaultWidth, modifiedWidth, horizontalOverflow, horizontalWidth) {
    if (horizontalOverflow && overFlow) {
        elementToModify.css("width", horizontalWidth);
        elementToModify.css("minWidth", horizontalWidth);
        elementToModify.css("maxWidth", horizontalWidth);
    }
    if (overFlow && !horizontalOverflow) {
        elementToModify.css("width", modifiedWidth);
        elementToModify.css("minWidth", modifiedWidth);
        elementToModify.css("maxWidth", modifiedWidth);
    }
    if (!overFlow && !horizontalOverflow) {
        elementToModify.css("width", defaultWidth);
        elementToModify.css("minWidth", defaultWidth);
        elementToModify.css("maxWidth", defaultWidth);
    }

}

export { setLimit, setDrillRowsLimit, getLimit, getDrillRowsLimit, 
  hideProfitStackLink, showProfitStackLink, resetDrillSet, destroyDrillSections, hideDrillItem, showDrillItem, getPSTier, 
  vectorSpacing, getDefaultCookieValue, debounce, removeAllCookies ,
  saveAllCookies, saveCookie, readCookie, removeCookie, removeCookies, removeSpacing, prepareLogo, replaceSpecialCharacters, replaceAll, isValidEmailAddress, isValidMobileNumber, isValidPassword,
  removeCursor, splitOnUpperCaseChar,alertError, alertAndLogError, validateVectors, validateFields, addRowNumberToOptions,
  setTypePassword,  translateRuleStringToObject, extractCostKeys, formatRule, translateRule,
  reTranslateRule, translateFormulaToString, orderFiltersByAttribute, getObjectAsArray, addIndexNumberToOptions,
  toggleEnableHeaderNext, toggleHeaderBack, isOdd, extractInitials, arrangeAncillaryFiles, getCleanReturnName, getPeriodNumber, getPeriodYear ,isValidName, 
  isInputEmpty, changeHeaderWidthOnOverFlow, translateFilter };
