import React from 'react';
import Popup from 'react-popup';

import { Component } from 'react';
import { setLocalStorageValueByParameter, toggleLoader } from "./class/common.js";
import { API_URL, BUTTON_TYPE, BUTTON_VARIANT, MENU_ITEM, MODELING_SETTINGS, PERIOD_ONWARD, SIZES } from './class/constants.js';
import { alertAndLogError } from './class/jqueries.js';
import { copyObjectValues, getSectionExists, shallowCompare } from './class/utils.js';
import Button from './newComponents/Button';
import Input from './newComponents/Input';
import FormatDisplay from './sections/format/FormatDisplay.js';
import Configuration from './sections/settings/Configuration.js';
import FiscalCalendar from './sections/settings/FiscalCalendar.js';
import './styles/common.css';
import './styles/header.css';

const $ = require('jquery');
const UIKit = require("uikit");


const ALL_FIELDSETS = {
    LANGUAGE_CURRENCY: "language-currency",
    CLIENT_INDUSTRY: "client-industry",
    FISCAL_CALENDAR: "fiscal-calendar",
    CHANNEL_VOLUME: "channel-volume",
    UI_FORMATTING: "ui-formatting",
    DEFINE_PV: "define-pv",
    CLIENT_COST_CENTER: "client-cost-center",
    FILE_CENSUS: "file-census",
    ACCESS_GROUPS: "access-groups"
}

const fileCensusRef = React.createRef();
const baseUrl = process.env.REACT_APP_BASE_URL;
const UPLOAD = "/datamodeling/Upload_Data";
class Settings extends Component {
		
	constructor(props) {
        super(props);
        //use the state inherited from the parent (Header class) and add to it the state object defined locally
        this.state = {
            tablesShowing: {
                    [ALL_FIELDSETS.LANGUAGE_CURRENCY]: false,
                    [ALL_FIELDSETS.CLIENT_INDUSTRY]: false,
                    [ALL_FIELDSETS.FISCAL_CALENDAR]: false,
                    [ALL_FIELDSETS.CHANNEL_VOLUME]: false,
                    [ALL_FIELDSETS.UI_FORMATTING]: false,
                    [ALL_FIELDSETS.DEFINE_PV]: false,
                    [ALL_FIELDSETS.CLIENT_COST_CENTER]: false,
                    [ALL_FIELDSETS.FILE_CENSUS]: false,
                    [ALL_FIELDSETS.ACCESS_GROUPS]: false
            },
            menu: false,
            subTypeActivities: [],
        };

        this.getFileCensusSectionsData = this.getFileCensusSectionsData.bind(this);
        this.saveFileCensusSections = this.saveFileCensusSections.bind(this);
        this.setData = this.setData.bind(this);
        this.deleteSubSection = this.deleteSubSection.bind(this);
        this.formatData = this.formatData.bind(this);
        this.deleteSubSectionActivity = this.deleteSubSectionActivity.bind(this);
        this.saveSetting = this.saveSetting.bind(this);
        this.saveFormatting = this.saveFormatting.bind(this);
        this.resetFormatting = this.resetFormatting.bind(this);
        this.resetFiscalPeriods = this.resetFiscalPeriods.bind(this);
        this.saveFiscalPeriods = this.saveFiscalPeriods.bind(this);
    }
    
  componentDidMount() {
    let _this = this;
    _this.getPageData();
  }

    getPageData = () => {
        this.getFileCensusSectionsData();
        this.getClientCostCenter();
        this.getIndustries();
    }
    
    toggleSection(e, section) {
        let tablesShowing = copyObjectValues(this.state.tablesShowing);
        let isShowing = tablesShowing[section];

        if(section === ALL_FIELDSETS.FILE_CENSUS) {
            fileCensusRef.current.toggleTable(!isShowing);
        } else if (section === ALL_FIELDSETS.FISCAL_CALENDAR && this.fiscalCalendarRef) {
            this.fiscalCalendarRef.toggleTable(!isShowing);
        } else if([ALL_FIELDSETS.CLIENT_COST_CENTER, ALL_FIELDSETS.CLIENT_INDUSTRY].indexOf(section) !== -1) {
            if(isShowing) {
                $(".settings-container").find("div." + section + "-fieldset .toggleSettingDiv").hide();
            } else {
                $(".settings-container").find("div." + section + "-fieldset .toggleSettingDiv").css("display", "flex");
            }
        } else if (isShowing) {
            $(".settings-container").find("div." + section + "-fieldset .toggleSettingDiv").hide();
        } else {
            $(".settings-container").find("div." + section + "-fieldset .toggleSettingDiv").show();
            $("#addAccessGroupModal").hide();
        }

        tablesShowing[section] = !isShowing; //reverse the value and resave it
        this.setState({
            tablesShowing: tablesShowing
        })
    }

    getFileCensusSectionsData() {
        toggleLoader(true, 'getFileCensusSectionsData');
        const query = {
            action: "getFileCensusSectionsData"
        }
        setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
        const obj = this;
        fetch(`${baseUrl}${API_URL.DATA_MODELING}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(query)})
            .then((response)=>{    
                if(response.status === 403) {
                    // this.logout();                  
                }
                return response.json()})
            .then((data)=>{
                if (data) {
                    this.setState({
                        fileCensusData: obj.formatData(data.data)
                    });
                }
            }).catch((error)=>{
                alertAndLogError(error);
            }).then(()=>{
                toggleLoader(false, 'getFileCensusSectionsData');
            });
    } 

    formatData(data) {
        for(let e in data) {
            if(data[e].subsection === undefined) {
                data[e].subsection = data[e].section;
                data[e].update_flag = "-1"
            }
            if(data[e].end_period === undefined && data[e].start_period !== undefined) {
                data[e].end_period = PERIOD_ONWARD;
            }else if( data[e].start_period === undefined && data[e].end_period === undefined) {
                    data[e].update_flag = "-1"
            }
            if(data[e].expected_files === undefined) {
                data[e].expected_files = "";
            }
        }
        return data;
    }

    deleteSubSection(cell) {
        toggleLoader(true, 'deleteSubSection');
        const obj = this;
        let section = cell[0].getTable().element.getAttribute("id").replace("&", "\\&");
        let sectionName = $("#" + section);
        let subTypeActivities = [];
        let data = obj.state.fileCensusData;
        let checkedIds = [];
        let checkedSection = sectionName.find('input[type=checkbox]:checked');

        for (let i = 0; i < checkedSection.length; i++) { // building array with ids and start and end periods to be deleted 
            let firstCheckedSection = checkedSection[i].children && checkedSection[i].children[0] ? checkedSection[i].children[0] : null;
            checkedIds.push(firstCheckedSection !== null && firstCheckedSection["raw_file_subtype_id"] ? firstCheckedSection["raw_file_subtype_id"] : -1);
            subTypeActivities.push({
                subtypeId: firstCheckedSection !== null && firstCheckedSection["raw_file_subtype_id"]  ? firstCheckedSection["raw_file_subtype_id"] : -1 , 
                start_period: firstCheckedSection !== null && firstCheckedSection["start_period"] ?  firstCheckedSection["start_period"] : "",
                end_period: firstCheckedSection !== null && firstCheckedSection["end_period"] ? firstCheckedSection["end_period"] : ""});
        }
        this.setState({
            subTypeActivities: subTypeActivities
        });

        for (let e in data) {
            data[e].raw_file_subtype_id = data[e].raw_file_subtype_id === undefined ? -1 : data[e].raw_file_subtype_id;
            if((data[e].insert_flag === "1" || data[e].update_flag === "1")) {
                for (let act in subTypeActivities) {
                    if(subTypeActivities[act].start_period !== data[e].start_period && subTypeActivities[act].end_period !== data[e].end_period && subTypeActivities[act] !== data[e].raw_file_subtype_id) {
                        toggleLoader(false, 'deleteSubSection');
                        window._profitIsleOpenModal('deleteActivityModal');
                        return;         
                    }
                }
            }
        }
        this.deleteSubSectionActivity();    
       
    }

    deleteSubSectionActivity() {
        toggleLoader(true, 'deleteSubSectionActivity');

        window._profitIsleCloseModal('deleteActivityModal');            
        const obj = this;
        let query = {
            action: "deleteSubSectionActivity",
            subTypeActivities: JSON.stringify(this.state.subTypeActivities)
        }
        let myUrl = baseUrl+"/DataModeling";
        setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
        $.ajax({
            url: myUrl, 
            type: 'POST',
            async: true, 
            crossDomain:true, 
            xhrFields: { withCredentials: true }, 
            data:JSON.stringify(query), 
            success:function(result, response, xhr){
                if(xhr.status === 403) {
                    this.logout();
                }
                result = JSON.parse(result);
                if(result.status === "FAIL") {
                    Popup.alert(result.ERROR);
                }else{
                    obj.getFileCensusSectionsData();
                    window.location.reload();
                }
            },complete:function(){
                toggleLoader(false, 'deleteSubSectionActivity');
                toggleLoader(false, 'deleteSubSection');
            }
        });
    }

    setData(data) {
        this.setState({
            fileCensusData: data
        },function(){
            this.saveFileCensusSections();
        });
    }

    saveFileCensusSections() {
        toggleLoader(true, 'saveFileCensusSections');
        const obj = this;
        let query = {
            action: "saveFileCensusSections",
            fileCensusSections: obj.state.fileCensusData
        }

        let myUrl = baseUrl+"/DataModeling";
        setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
        $.ajax({
            url: myUrl, 
            type: 'POST',
            async: true, 
            crossDomain:true, 
            xhrFields: { withCredentials: true }, 
            data:JSON.stringify(query), 
            complete: function () {
                toggleLoader(false, 'saveFileCensusSections');
                window.location.reload();
            }
        });
    }

    saveFormatting(){
        let params = this.formatDisplay.getFormatChanges();
        const comp = this;
        params.action = "setClientFormatting";
        let path = API_URL.FORMAT;
        const baseUrl = process.env.REACT_APP_BASE_URL;
        toggleLoader(true, 'saveFormatting');
        setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
        this.setState(this.state, function() {
            fetch(`${baseUrl}${path}`, {
                body: JSON.stringify(params),
                method: 'POST',
                mode: 'cors',
                credentials: 'include'
            })
            .then(function (response) {
                if (response.status === 403) {
                    window.location.reload();
                    return;
                }
                return response.json();
            })
            .then(function (data) {
                if (!data) {
                    return;
                }
                if (data.error) {
                    UIKit.modal.alert(data.error.message);
                    $('.uk-modal .uk-modal-dialog').addClass('modal-sm');
                    return;
                }
                comp.formatDisplay.fetchClientSettings(function(){
                    toggleLoader(false, 'saveFormatting');
                });

            })
            .catch((error) => {
                UIKit.modal.alert(JSON.stringify(error));
                $('.uk-modal .uk-modal-dialog').addClass('modal-sm');
            }).then(()=>{
                toggleLoader(false, 'saveFormatting');
            });
        });
    }    

    resetFiscalPeriods() {
        $("#add_period").show();
        $("#add_12_period").show();
        if(this.fiscalCalendarRef) {
            this.fiscalCalendarRef.periodTableRef.tabulator.setData(this.props.clientPeriodsState?.fiscalOriginalPeriods);
            this.fiscalCalendarRef.state.data = this.props.clientPeriodsState?.fiscalOriginalPeriods;
            this.fiscalCalendarRef.forceUpdate();
        }
    }

    saveFiscalPeriods() {
            toggleLoader(true, 'saveFiscalPeriods');
            let data = [];
            if(this.fiscalCalendarRef) {
                data = this.fiscalCalendarRef.periodTableRef.tabulator.getData();
            }
            let query = {
                action: "saveFiscalPeriods",
                fiscalPeriods: data
            }

            let myUrl = baseUrl+"/DataModeling";
            setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
            $.ajax({
                url: myUrl, 
                type: 'POST',
                async: true, 
                crossDomain:true, 
                xhrFields: { withCredentials: true }, 
                data:JSON.stringify(query), 
                complete: function () {
                    toggleLoader(false, 'saveFiscalPeriods');
                    window.location.reload();
                }
            });
    }

    resetFormatting(){
        let params = {};
        params.action = "resetClientFormatting";
        let path = API_URL.FORMAT;
        const baseUrl = process.env.REACT_APP_BASE_URL;
        const comp = this;
        toggleLoader(true, 'resetFormatting');
        setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
        fetch(`${baseUrl}${path}`, {
            body: JSON.stringify(params),
            method: 'POST',
            mode: 'cors',
            credentials: 'include'
        })
            .then(function (response) {
                if (response.status === 403) {
                    window.location.reload();
                    return;
                }
                return response.json();
            })
            .then(function (data) {
                if (!data) {
                    return;
                }
                if (data.err) {
                    UIKit.modal.alert(data.err);
                   $('.uk-modal .uk-modal-dialog').addClass('modal-sm');
                    return;
                }
                comp.formatDisplay.fetchClientSettings(function () {
                    toggleLoader(false, 'resetFormatting');
                });
            })
            .catch((error) => {
                UIKit.modal.alert(JSON.stringify(error));
                $('.uk-modal .uk-modal-dialog').addClass('modal-sm');
            }).then(() => {
                toggleLoader(false, 'resetFormatting');
            });
    }

    saveSetting(changedSetting) {
        if(changedSetting === ALL_FIELDSETS.UI_FORMATTING) {
            this.saveFormatting();
            return;
        }

        toggleLoader(true, 'saveSetting');
        let path = "";
        
        let query = {}

        let action = "";
        if(changedSetting === ALL_FIELDSETS.CLIENT_INDUSTRY) {
            action = "saveClientIndustry";
            path = API_URL.ADMINISTRATION;
            query.clientIndustry = this.state.clientIndustry;
        } else if(changedSetting === ALL_FIELDSETS.CLIENT_COST_CENTER) {
            action = "saveCostCenter";
            path = API_URL.USER_SETTINGS;
            query.costCenter = this.state.chosenCostCenter;
        }
        setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
        query.action = action;
        const comp = this;
        fetch(`${baseUrl}${path}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(query)})
            .then((response)=>{    
                if(response.status === 403) {
                    this.logout();                  
                }
                return response.json()})
            .then((data) => {
                if (!data) {
                    return;
                }
                if (data.err) {
                    UIKit.modal.alert(data.err);
                    return;
                }
                comp.formatDisplay.fetchClientSettings(function(){
                    toggleLoader(false, 'saveSetting');
                });
            })
            .catch((error) => {
                alertAndLogError(error);
            }).then(() => {
                toggleLoader(false, 'saveSetting');
            });
    }

    getClientCostCenter() {
        toggleLoader(true, 'getClientCostCenter');
        const query = {
            action: "getClientCostCenter"
        }
        setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
        fetch(`${baseUrl}${API_URL.USER_SETTINGS}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(query)})
            .then((response)=>{    
                if(response.status === 403) {
                    this.logout();                  
                }
                return response.json()})
            .then((data) => {
                toggleLoader(false, 'getClientCostCenter');
                let costCenterTemp = data?.data ? data.data[0].setting_value : data?.setting_value;
                this.setState({
                    chosenCostCenter: costCenterTemp
                })
            })
            .catch((error) => {
                alertAndLogError(error);
            }).then(() => {
                toggleLoader(false, 'getClientCostCenter');
            });
    }

    getIndustries() {
        toggleLoader(true, 'getIndustries');
        const query = {
            action: "getIndustries"
        }
        setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
        fetch(`${baseUrl}${API_URL.ADMINISTRATION}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(query)})
            .then((response)=>{    
                if(response.status === 403) {
                    this.logout();
                }
                return response.json()})
            .then((data) => {
                let clientIndustry = data.data.find(el => el["client_id"] !== undefined);

                this.setState({
                    allIndustries: data.data,
                    clientIndustry: clientIndustry ? clientIndustry["industry_name"] : ""
                });
            })
            .catch((error) => {
                alertAndLogError(error);
            }).then(() => {
                toggleLoader(false, 'getIndustries');
            });
    }

    setChosenSetting(attr, e) {
        let value = $(e.currentTarget).val();
        let tempState = {};

        if(attr === ALL_FIELDSETS.CLIENT_COST_CENTER) {
            tempState.chosenCostCenter = value;
        } else if(attr === ALL_FIELDSETS.CLIENT_INDUSTRY) {
            tempState.clientIndustry = value;
        }

        this.setState(tempState);
    }
    
    goToUploadScreen = () => {
        window.location.pathname = UPLOAD;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return shallowCompare(this, nextProps, nextState);
    }


    render() {
        let prevUrl = "";
        let fiscalCalendarAccess =  this.props.userAllowedMenuLinks ? getSectionExists(this.props.userAllowedMenuLinks, MODELING_SETTINGS.FIELDS.PERIODS) : false;////////////////
        let allIndustries = this.state.allIndustries ? this.state.allIndustries.map(function(item) {
            return <option key={item["industry_name"]}>{item["industry_name"]}</option>
        }) : [];
        if(window.history.state && window.history.state!==null ){
            if(window.history.state.prevUrl && window.history.state.prevUrl !==null){
                prevUrl = window.history.state.prevUrl;
            }
        }

        return (
            <>
            <div className={this.state.showMultipleClientsComponent?'uk-hidden':""}>
                <Popup />
                <header className="header">
                    <section id="Filter_control" className="filter-control">
                        <div className="filter-control-titles">
                            {
                                prevUrl.includes("Upload") ?
                                <Button  
                                    id="header-back"
                                    label={"Back"}
                                    variant={BUTTON_VARIANT.SECONDARY}
                                    size={SIZES.DEFAULT}
                                    type={BUTTON_TYPE.DEFAULT}
                                    className="uk-margin-default-right"
                                    onBtnClick={this.goToUploadScreen}
                                />
                                :""
                            }
                        </div>
                    </section>
                </header>
                <div className="parent-container">
                    <div className="settings-container">
                        <div className={ALL_FIELDSETS.LANGUAGE_CURRENCY + "-fieldset"}>
                            {this.state.tablesShowing[ALL_FIELDSETS.LANGUAGE_CURRENCY] ? <div className="uk-button-icon uk-margin-medium-left" onClick={(e) => this.toggleSection(e, ALL_FIELDSETS.LANGUAGE_CURRENCY)}><i className="far fa-chevron-down fa-2x"></i></div> : <div className="uk-button-icon uk-margin-medium-left" onClick={(e) => this.toggleSection(e, ALL_FIELDSETS.LANGUAGE_CURRENCY)}><i className="far fa-chevron-right fa-2x"></i></div>}
                            <fieldset>
                                <legend className="uk-text-medium uk-margin-xsmall-left">Language / Currency</legend>
                                <div className="toggleSettingDiv">
                                    <table className={ALL_FIELDSETS.LANGUAGE_CURRENCY + "-table"}>
                                        <thead>
                                            <tr>
                                                <th>Language</th>
                                                <th>Currency</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>English</td>
                                                <td>$</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </fieldset>
                        </div>
                        <div className= {ALL_FIELDSETS.CLIENT_INDUSTRY + "-fieldset"}>
                            {this.state.tablesShowing[ALL_FIELDSETS.CLIENT_INDUSTRY] ? <div className="uk-button-icon uk-margin-medium-left" onClick={(e) => this.toggleSection(e, ALL_FIELDSETS.CLIENT_INDUSTRY)}><i className="far fa-chevron-down fa-2x"></i></div> : <div className="uk-button-icon uk-margin-medium-left" onClick={(e) => this.toggleSection(e, ALL_FIELDSETS.CLIENT_INDUSTRY)}><i className="far fa-chevron-right fa-2x"></i></div>}
                            <fieldset>
                                <legend className="uk-text-medium uk-margin-xsmall-left">Industry
                                    <div title="Save" id="saveSettingsCC" className="uk-display-inline-block action-icon pull-right" onClick={()=>this.saveSetting(ALL_FIELDSETS.CLIENT_INDUSTRY)}>
                                        <i className={"far fa-save uk-margin-small-right upload-data-fa"} alt="Save"></i>
                                        <span>Save</span>
                                    </div>
                                </legend>
                                <div className={ALL_FIELDSETS.CLIENT_INDUSTRY + " uk-padding-small toggleSettingDiv"}>
                                    <h6 className="uk-margin-small-left">Industry:</h6>
                                    <select id="client-industry"
                                        className="uk-margin-small-left uk-width-small"
                                        name="client-industry" 
                                        onChange={(e)=>this.setChosenSetting(ALL_FIELDSETS.CLIENT_INDUSTRY, e)}
                                        value={this.state.clientIndustry}>
                                            {allIndustries}
                                    </select>
                                </div>
                            </fieldset>
                        </div>
                        <div className={ALL_FIELDSETS.FISCAL_CALENDAR + "-fieldset"}>
                            {this.state.tablesShowing[ALL_FIELDSETS.FISCAL_CALENDAR] ? <div className="uk-button-icon uk-margin-medium-left" onClick={(e) => this.toggleSection(e, ALL_FIELDSETS.FISCAL_CALENDAR)}><i className="far fa-chevron-down fa-2x"></i></div> : <div className="uk-button-icon uk-margin-medium-left" onClick={(e) => this.toggleSection(e, ALL_FIELDSETS.FISCAL_CALENDAR)}><i className="far fa-chevron-right fa-2x"></i></div>}
                            <fieldset>
                                <legend className="uk-text-medium uk-margin-xsmall-left">Fiscal Calendar
                                    <div title="Save" className="uk-display-inline-block action-icon pull-right" onClick={()=>this.saveFiscalPeriods()}>
                                        <i className={"far fa-save uk-margin-small-right upload-data-fa"} alt="Save"></i>
                                        <span>Save</span>
                                    </div>
                                    <div title="Reset" className="uk-display-inline-block action-icon pull-right" onClick={()=>this.resetFiscalPeriods()}>
                                    <i className={"fal fa-undo-alt uk-cursor-pointer uk-margin-small-right"} alt="Reset"></i>
                                        <span>Reset</span>
                                    </div>
                                </legend>
                                <div className="toggleSettingDiv">
                                </div>
                                <div className="">
                                    {fiscalCalendarAccess ? 
                                    <FiscalCalendar ref={el=> this.fiscalCalendarRef = el} fiscalCalendarAccess={fiscalCalendarAccess} machineName={this.props.userSettings?.machine_name} periods={this.props.clientPeriodsState?.fiscalPeriods} clientId={this.props.userSettings?.clientId}  setData={this.setData}/>
                                    :""}
                                </div>
                            </fieldset>
                        </div>
                        <div className= {ALL_FIELDSETS.CHANNEL_VOLUME + "-fieldset"}>
                            {this.state.tablesShowing[ALL_FIELDSETS.CHANNEL_VOLUME] ? <div className="uk-button-icon uk-margin-medium-left" onClick={(e) => this.toggleSection(e, ALL_FIELDSETS.CHANNEL_VOLUME)}><i className="far fa-chevron-down fa-2x"></i></div> : <div className="uk-button-icon uk-margin-medium-left" onClick={(e) => this.toggleSection(e, ALL_FIELDSETS.CHANNEL_VOLUME)}><i className="far fa-chevron-right fa-2x"></i></div>}
                            <fieldset>
                                <legend className="uk-text-medium uk-margin-xsmall-left">Channel/Volume</legend>
                                <div className="toggleSettingDiv">

                                </div>
                            </fieldset>
                        </div>
                        <div className={ALL_FIELDSETS.UI_FORMATTING + "-fieldset"}>
                            {this.state.tablesShowing[ALL_FIELDSETS.UI_FORMATTING] ? <div className="uk-button-icon uk-margin-medium-left" onClick={(e) => this.toggleSection(e, ALL_FIELDSETS.UI_FORMATTING)}><i className="far fa-chevron-down fa-2x"></i></div> : <div className="uk-button-icon uk-margin-medium-left" onClick={(e) => this.toggleSection(e, ALL_FIELDSETS.UI_FORMATTING)}><i className="far fa-chevron-right fa-2x"></i></div>}
                            <fieldset>
                                <legend className="uk-text-medium uk-margin-xsmall-left">Number Formatting
                                    <div title="Save" className="uk-display-inline-block action-icon pull-right" onClick={()=>this.saveSetting(ALL_FIELDSETS.UI_FORMATTING)}>
                                        <i className={"far fa-save uk-margin-small-right upload-data-fa"} alt="Save"></i>
                                        <span>Save</span>
                                    </div>
                                    <div title="Reset" className="uk-display-inline-block action-icon pull-right" onClick={()=>this.resetFormatting()}>
                                    <i className={"fal fa-undo-alt uk-cursor-pointer uk-margin-small-right"} alt="Reset"></i>
                                        <span>Reset</span>
                                    </div>
                                </legend>
                                <div className="toggleSettingDiv uk-padding-small">
                                    <FormatDisplay ref={el => this.formatDisplay = el} isUser={false}/>
                                </div>
                            </fieldset>
                        </div>
                        <div className={ALL_FIELDSETS.DEFINE_PV + "-fieldset"}>
                            {this.state.tablesShowing[ALL_FIELDSETS.DEFINE_PV] ? <div className="uk-button-icon uk-margin-medium-left" onClick={(e) => this.toggleSection(e, ALL_FIELDSETS.DEFINE_PV)}><i className="far fa-chevron-down fa-2x"></i></div> : <div className="uk-button-icon uk-margin-medium-left" onClick={(e) => this.toggleSection(e, ALL_FIELDSETS.DEFINE_PV)}><i className="far fa-chevron-right fa-2x"></i></div>}
                            <fieldset>
                                <legend className="uk-text-medium uk-margin-xsmall-left">Define Profit Vectors</legend>
                                <div className="toggleSettingDiv">

                                </div>
                            </fieldset>
                        </div>
                        <div className= {ALL_FIELDSETS.CLIENT_COST_CENTER + "-fieldset"}>
                            {this.state.tablesShowing[ALL_FIELDSETS.CLIENT_COST_CENTER] ? <div className="uk-button-icon uk-margin-medium-left" onClick={(e) => this.toggleSection(e, ALL_FIELDSETS.CLIENT_COST_CENTER)}><i className="far fa-chevron-down fa-2x"></i></div> : <div className="uk-button-icon uk-margin-medium-left" onClick={(e) => this.toggleSection(e, ALL_FIELDSETS.CLIENT_COST_CENTER)}><i className="far fa-chevron-right fa-2x"></i></div>}
                            <fieldset>
                                <legend className="uk-text-medium uk-margin-xsmall-left">Cost Center
                                    <div title="Save" id="saveSettingsCC" className="uk-display-inline-block action-icon pull-right" onClick={()=>this.saveSetting(ALL_FIELDSETS.CLIENT_COST_CENTER)}>
                                        <i className={"far fa-save uk-margin-small-right upload-data-fa"} alt="Save"></i>
                                        <span>Save</span>
                                    </div>
                                </legend>
                                <div className={ALL_FIELDSETS.CLIENT_COST_CENTER + " uk-padding-small toggleSettingDiv"}>
                                    <h6 className="uk-margin-small-left">Cost Center:</h6>
                                    <Input
                                        id="cost-center"
                                        className="uk-margin-small-left width-105"
                                        name="cost-centers" 
                                        placeholder="Please input a cost center"
                                        defaultValue={this.state.chosenCostCenter}
                                        onBlur={(e)=>this.setChosenSetting(ALL_FIELDSETS.CLIENT_COST_CENTER, e)}
                                    />
                                </div>
                            </fieldset>
                        </div>
                        <div className={ALL_FIELDSETS.FILE_CENSUS + "-fieldset"}>
                            {this.state.tablesShowing[ALL_FIELDSETS.FILE_CENSUS] ? <div className="uk-button-icon uk-margin-medium-left" onClick={(e) => this.toggleSection(e, ALL_FIELDSETS.FILE_CENSUS)}><i className="far fa-chevron-down fa-2x"></i></div> : <div className="uk-button-icon uk-margin-medium-left" onClick={(e) => this.toggleSection(e, ALL_FIELDSETS.FILE_CENSUS)}><i className="far fa-chevron-right fa-2x"></i></div>}
                            <fieldset>
                                <legend className="uk-text-medium uk-margin-xsmall-left">File Census
                                    <div title="Save" id="saveSettingsFC" className="uk-display-inline-block action-icon pull-right">
                                        <i className={"far fa-save uk-margin-small-right upload-data-fa"} alt="Save"></i>
                                        <span>Save</span>
                                    </div>
                                    <div title="Delete" id = "deleteSettingsFC" className="uk-display-inline-block action-icon disabled pull-right">
                                        <i className={"fal fa-trash-alt uk-margin-small-right upload-data-fa"} alt="Delete"></i>
                                        <span>Delete</span>
                                    </div>
                                </legend>
                                <div className="">
                                    <Configuration ref={fileCensusRef} machineName={this.props.userSettings?.machine_name} periods={this.props.clientPeriodsState?.periods}
                                    clientId={this.props.userSettings?.clientId} data={this.state.fileCensusData} setData={this.setData}
                                    deleteSubSection={this.deleteSubSection} userAllowedMenuLinks={this.props.userAllowedMenuLinks}/>
                                </div>
                            </fieldset>
                        </div>
                        <div id="deleteActivityModal" className="modal fade" role="dialog">
                        <div className="modal-dialog modal-lg">
                            <div className={"modal-content uk-padding"}>
                                <Button 
                                    variant={BUTTON_VARIANT.TERTIARY}
                                    size={SIZES.ICON}
                                    type={BUTTON_TYPE.DEFAULT}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    leftIcon={<span aria-hidden="true">&times;</span>}
                                />
                                <h4 className="mrgb10">You have unsaved changes. Are you sure you want to proceed?</h4>
                                <Button 
                                    label={"YES"}
                                    variant={BUTTON_VARIANT.PRIMARY}
                                    size={SIZES.DEFAULT}
                                    type={BUTTON_TYPE.DEFAULT}  
                                    data-dismiss="modal"
                                    className=" uk-margin-top"
                                    onBtnClick={this.deleteSubSectionActivity}
                                />
                                 <Button 
                                    label={"NO"}
                                    variant={BUTTON_VARIANT.SECONDARY}
                                    size={SIZES.DEFAULT}
                                    type={BUTTON_TYPE.DEFAULT}
                                    className="uk-align-right uk-margin-top"
                                    data-dismiss="modal"
                                />
                            </div>
                        </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            </>
        );
    }
    
}

export default Settings;