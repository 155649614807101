import { getCurrency } from "./common";
import { FORMAT_TYPE_NAMES } from "./constants";

function numberCommaFormat(number, round) { 
	if(numberIsZero(number)){
        return "-";
    }
	return Math.round(number, round).toLocaleString(navigator.language, { minimumFractionDigits: round });
}


function round(number, decimals) {
    if(numberIsZero(number)){
        return "-";
    }
    return Number(Math.round(number + 'e' + decimals) + 'e-' + decimals);
}

function formatNumber(number){
    if(numberIsZero(number)){
        return "-";
    }
	return number;
}


function numberIsZero(number){
    if(isNaN(parseFloat(number)) || Math.abs(parseFloat(number)) === 0){
        return true
    } else return false;
}

/*
 * advancedFormatCurrency
 * num: number to format 
 * unit: unit of currency 
 * precision [0->10] of number, 
 * bUnit (M,K,B) as in millions, 1000s and Billions 
 * numericFormatName (English/US or European/Continental)
 */
function advancedFormatCurrency(num, unit, precision, bUnit, numericFormatName) { 
    /* Parameters double checking */
    if (!unit) unit = "";
    if (!precision || precision < 0) precision = 0;
    if (precision > 6) precision = 6;
    if (!bUnit) bUnit = "N/A";
    if (!num) return "-";
    if(isNaN(num) || num === Infinity || num === -Infinity){
        return "-";
    }

    var isNegative = num < 0;
    num = Math.abs(num);
    /* bUnit arrays */
    var bUnits = ["M", "B", "K"];
    var bUnitsNum = [1000 * 1000, 1000 * 1000 * 1000, 1000];
    
    var ord = bUnits.indexOf(bUnit);
    
    if (ord >= 0) {
        num /= bUnitsNum[ord];
        bUnit = bUnits[ord];
    } else { 
        bUnit = ""; 
    }

    /* rounding up to desired precision */
    num = num.toFixed(precision);
    if(parseFloat(num) === 0){
        return "-";
    }
    /* adding commas on thousands */
    var parts = num.toString().split(".");
    var ret;
    if(numericFormatName === FORMAT_TYPE_NAMES.EU){
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        ret = parts.join(",");
    }else{
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        ret = parts.join(".");
    }
    /* adding bUnit to the end */
    ret += bUnit;
    /* adding unit to the beginning */
    ret = (isNegative?"-":"")+unit + ret;
    return ret;
}

function getNumericValue(value) {
    return value.toString().replace(/[^\d.-]/g,"");
}

/**
 * a function that checks if a value is a number or not,
 * it is needed cz isNaN("") returns true
 * @param {*} value 
 */
function is_aN(value) {
    return !["", undefined, null, true, false].includes(value) && !isNaN(value);
}

function isDigit(digit) {
    return /[0-9]+/.test(digit);
}

/**
 * this function returns $50K, if amount is 50000, $50M if amount is 50000000 ...
 * @param {*} text 
 * @returns 
 */
function abbreviateNumber(text, isPercentage) {
    const value = parseFloat(text?.replace(/,/g, ""));
    let currency = getCurrency();
    if (isNaN(value)) {
        return currency+"0";
    }
    if(isPercentage){
        return value + "%";
    }
    const suffixes = [
        { threshold: 1e12, suffix: "T" },  // Trillion
        { threshold: 1e9, suffix: "B" },   // Billion
        { threshold: 1e6, suffix: "M" },   // Million
        { threshold: 1e3, suffix: "k" }    // Thousand
    ];
    for (const suffix of suffixes) {
        if (value >= suffix.threshold) {
            const formattedValue = (value / suffix.threshold);
            return currency+`${formattedValue}${suffix.suffix}`;
        }
    }
    return currency+`${value}`;
}

export {numberCommaFormat, round, formatNumber, advancedFormatCurrency, getNumericValue, is_aN, isDigit, abbreviateNumber};