import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import './AddColumn.css'
import Container from "./Container";
import {linearizeHierarchy} from "../../class/array";
import {useSelector} from "react-redux";
import Button from "../../newComponents/Button";
import {BUTTON_TYPE, BUTTON_VARIANT, costtype, MANAGE_COLUMNS, SIZES} from "../../class/constants";
import {lang} from "../../language/messages_en";
import {getFormulaText, validateFormulaResult} from "./FormulaFunctions";

const Formula = (props, ref) => {
    useImperativeHandle(ref, () => ({
        createFormula: () => {
            return createFormula(inputRef.current?.childNodes);
        },
        resetInputField: () =>{
            resetInputField();
        },
        isFormulaEmpty: () =>{
            return isFormulaEmpty()
        },
        isFormulaValid: () => {
            return formulaValidationMessage === "";
        },
        addValuesToInput: (array) =>{
            addValuesToInput(array)
        },
        setFormulaValidation: () =>{
            setFormulaValidation()
        }
    }));

    const psLinesOptions = useSelector(state=>state.psLinesOptions);
    let psLines = psLinesOptions && psLinesOptions.has(props.scenarioState.scenario) ? psLinesOptions.get(props.scenarioState.scenario)[0] : []
    const [formulaValidationMessage, setFormulaValidationMessage] = useState("");
    const [formulaValidationAttribute, setFormulaValidationAttribute] = useState("");
    const [suggestionPsLines, setSuggestionPsLines] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [linearizedPSLines, setLinearizedPSLines] = useState();
    const [psLinesTreeData, setPsLinesTreeData] = useState();
    const [formulaObj, setFormulaObj] = useState({}); // For selected dropdown options
    const [formulaValueArray, setFormulaValueArray] = useState([]); // For selected dropdown options

    const inputRef = useRef(null);  // For selected dropdown options
    const inputFocusedRef = useRef(null);
    const formulaTextContent = useRef();  // For selected dropdown options
    const typedTextSuggestion = useRef();  // For selected dropdown options
    const suggestionsPerPage = 5; // Number of suggestions to show per page
    const indexOfLastSuggestion = currentPage * suggestionsPerPage; // Last index of suggestions for the current page
    const indexOfFirstSuggestion = indexOfLastSuggestion - suggestionsPerPage; // First index
    const currentSuggestions = suggestionPsLines?.slice(indexOfFirstSuggestion, indexOfLastSuggestion)
    const totalPages = Math.ceil(suggestionPsLines?.length / suggestionsPerPage); // Total number of pages

    /**
     * This `useEffect` hook listens for clicks outside the input field to handle focus and placeholder behavior.
     *
     * Actions:
     * 1. Focus the input if the click is inside the `dropdown radio-select`.
     * 2. Update input focus state if the click is inside specific UI elements (e.g., node labels, buttons).
     * 3. Remove the input's placeholder if it contains child nodes.
     */
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (typeof(event?.target?.parentElement?.className) === "string" && event?.target?.parentElement?.className?.includes("dropdown radio-select")) {
                // Focus the input field if the parent has the class 'dropdown radio-select'
                inputRef.current.focus();
                inputFocusedRef.current = false; // Update input focused state
            } else if (inputRef.current && (typeof(event?.target?.parentElement?.className) === "string" 
                    && typeof(event?.target?.className) === "string" 
                    && (event?.target?.parentElement?.className?.includes("dropdown radio-select")
                || event?.target?.className?.includes('node-label')
                || event?.target?.parentElement?.className?.includes('node tree')
                || event?.target?.className?.includes('line_span')
                || event?.target?.className?.includes('suggestion_text')
                || event?.target?.className?.includes('suggestion_text_value_container')
                || event?.target?.className?.includes("pagination_buttons")
                || event?.target?.className?.includes("btn-default-tertiary")
                || event?.target?.className?.includes("btn-disabled"))
            )) {
                inputFocusedRef.current = false;
            }
            if (inputRef.current.childNodes.length > 0) {
                inputRef.current.removeAttribute('data-placeholder');
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (psLines.length > 0) {
            psLines = psLines.filter(f=>f.costtype !== costtype.attribute);
            setLinearizedPSLines(linearizeHierarchy(psLines, "children"));
            delete psLines[0].checked;
            setPsLinesTreeData(psLines);
        }
    }, [psLines])

    useEffect(() => {
        if (props.isEditing) {
            inputRef.current.removeAttribute('data-placeholder');
            setFormulaValidationMessage("");
            setFormulaValidationAttribute("")
        }else{
            inputRef.current.setAttribute('data-placeholder', lang.manage_columns.text.start_typing_formula);
        }
    }, [props.isDrawerOpen, props.isEditing])

    const isFormulaEmpty = () => {
        return inputRef.current.innerHTML === '' || inputRef.current?.innerHTML === null;
    };

    const resetInputField = () => {
        inputRef.current.innerHTML = ''; // This removes all child nodes

        setSuggestionPsLines(); // Reset to initial suggestions
        setFormulaValueArray([])
        setFormulaObj({})
        typedTextSuggestion.current = ''; // Clear the text in the ref
        formulaTextContent.current = ''; // Clear formula text if needed

    };

    const handleKeyDown = (e) => {
        if (e.key === 'Backspace') {
            // Check if the content is empty and there are labels to remove
            if (inputRef.current.innerText.trim() === "") {
                // If empty, remove the last label
                setSuggestionPsLines([]);
                e.preventDefault(); // Prevent default backspace behavior
            }
        }
    };

    const addValuesToInput = (formulaValuesArray) =>{
        const inputElement = inputRef.current;

        // Clear existing content in the input field
        inputElement.innerHTML = '';

        // Iterate through the formulaValuesArray to create elements
        formulaValuesArray.forEach(valueObj => {
            let newElement;

            // Check the type of the value and create appropriate elements
            if (!valueObj.type) {
                // Create a label span
                newElement = document.createElement('span');
                newElement.innerText = valueObj.display_name; // The label name to display
                newElement.className = 'label_add_column';
                newElement.contentEditable = false;
                newElement.setAttribute('returnName', valueObj.returnName);
                newElement.setAttribute('type', 'label');
            } else if ((valueObj.type === 'operator' || valueObj.type === 'control') && isNaN(valueObj.returnName)) {
                // Create an operator or control span
                newElement = document.createElement('span');
                newElement.innerText = valueObj.display_name; // Operator or control character
                newElement.className = valueObj.type === 'operator' ? 'operator_span' : 'control_span';
                newElement.contentEditable = false;
                newElement.setAttribute('returnName', valueObj.returnName);
                newElement.setAttribute('type', valueObj.type === 'operator' ? 'operator' : 'control');
            } else {
                // If it's just a text node (number or plain text)
                newElement = document.createTextNode(valueObj.display_name);
            }

            // Append the created element to the input field
            inputElement.appendChild(newElement);
        });
    }

    const createFormulaLabel = (formulaValueObj, element) => {
        let filteredElement = linearizedPSLines.filter(f => f.value === element.getAttribute("returnname"));
        // formulaValueObj.value = String(linearizedPSLines.findIndex(f => f.value === element.getAttribute("returnname")) + 1);
        formulaValueObj.display_name = filteredElement[0].label;
        formulaValueObj.formula = filteredElement[0].nameInFact;
        formulaValueObj.class = "";
        formulaValueObj.returnName = element.getAttribute("returnname");
    }

    const createTextNumberFormula = (formulaValueObj, element) => {
        // formulaValueObj.value = element.textContent.trim();
        formulaValueObj.display_name = element.textContent.trim();
        formulaValueObj.formula = element.textContent.trim();
        formulaValueObj.returnName = element.textContent.trim();
        formulaValueObj.type = "control";
        formulaValueObj.class = "control-buttons";
    }

    const createControlFormula = (formulaValueObj, element) => {
        // formulaValueObj.value = element.getAttribute("returnname");
        formulaValueObj.display_name = element.getAttribute("returnname");
        formulaValueObj.formula = element.getAttribute("returnname");
        formulaValueObj.returnName = element.getAttribute("returnname");
        formulaValueObj.type = "control";
        formulaValueObj.class = "control-buttons";
    }

    const createOperatorFormula = (formulaValueObj, element) => {
        // formulaValueObj.value = element.getAttribute("returnname");
        formulaValueObj.display_name = element.getAttribute("returnname");
        formulaValueObj.formula = element.getAttribute("returnname");
        formulaValueObj.returnName = element.getAttribute("returnname");
        formulaValueObj.type = "operator";
        if (element.getAttribute("returnname") === "+") {
            formulaValueObj.class = "fa-plus";
        } else if (element.getAttribute("returnname") === "-") {
            formulaValueObj.class = "fa-minus";
        } else if (element.getAttribute("returnname") === "/") {
            formulaValueObj.class = "fa-divide";
        } else if (element.getAttribute("returnname") === "*") {
            formulaValueObj.class = "fa-times uk-text-emphasis";
        }
    }

    const createFormula = (elements) => {
        setFormulaValueArray([]);
        for (let element of elements) {
            let formulaValueObj = {};
            if (element.className === 'label_add_column') {
                createFormulaLabel(formulaValueObj, element);
            } else if (element.nodeType === Node.TEXT_NODE && /^\d+(\.\d+)?$/.test(element.textContent.trim())) { //Check for text number and dots
                createTextNumberFormula(formulaValueObj, element);
            } else {
                if (element.className) {
                    if (element.className === "control_span") {
                        createControlFormula(formulaValueObj, element);
                    } else if (element.className === "operator_span") {
                        createOperatorFormula(formulaValueObj, element)
                    }
                }
            }
            formulaValueArray.push(formulaValueObj);
            formulaObj.formula = formulaValueArray
        }
        if (Object.keys(formulaObj).length === 0) {
            return "";
        }
        return JSON.stringify(formulaObj);
    }

    const handleFocus = () => {
        inputFocusedRef.current = true ;
    };

    /**
     * When input loses focus
     */
    const handleBlur = () => {
        if (!inputFocusedRef.current) {
            inputRef.current.focus(); // Keep focus on the input
        }
    };

    const setFormulaValidation = () => {
        setFormulaValidationMessage(lang.manage_columns.text.complex_formula);
        setFormulaValidationAttribute(MANAGE_COLUMNS.COMPLEX_FORMULA);
    }

    const checkFormulaIncomplete = () => {
        let inputValuesArray = Array.from(inputRef.current.childNodes);
        let openParenthesesLength = inputValuesArray.filter(f => f && typeof f.getAttribute === 'function' && f.getAttribute("type") === "open-control")?.length;
        let closedParenthesesLength = inputValuesArray.filter(f => f && typeof f.getAttribute === 'function' && f?.getAttribute("type") === "close-control")?.length;
        if ((openParenthesesLength > closedParenthesesLength)
            || (typeof inputValuesArray[inputValuesArray.length - 1].getAttribute === 'function' && inputValuesArray[inputValuesArray.length - 1].getAttribute("type") === "operator")) {
            return true
        }
    }

    const handleFormulaValidation = () => {
        try {
            // Step 1: Extract formula text from nodes
            const formulaText = getFormulaText(Array.from(inputRef.current.childNodes));

            // Step 2: Store formulaText in a ref or state
            formulaTextContent.current = formulaText;

            // Step 3: Validate the formula result
            const isValid = validateFormulaResult(formulaTextContent.current);

            // Step 4: Set messages based on validation
            if (isValid) {
                setFormulaValidationMessage("");
                setFormulaValidationAttribute(MANAGE_COLUMNS.CORRECT_FORMULA);
            } else {
                if (checkFormulaIncomplete()) {
                    setFormulaValidationMessage(lang.manage_columns.text.incomplete_formula);
                    setFormulaValidationAttribute(MANAGE_COLUMNS.INCOMPLETE_FORMULA)
                } else {
                    setFormulaValidationMessage(lang.manage_columns.text.wrong_formula);
                    setFormulaValidationAttribute(MANAGE_COLUMNS.WRONG_FORMULA);
                }
            }

            return isValid;
        } catch (error) {
            setFormulaValidationMessage(lang.manage_columns.text.wrong_formula);
            setFormulaValidationAttribute(MANAGE_COLUMNS.WRONG_FORMULA);
            return false;
        }
    };

    /**
     * @function keepCursorInPosition this function is used to keep the input cursor where it is
     * and avoid placing it at the start when adding a label to the input
     * @param label
     */
    const keepCursorInPosition = (label) => {
        // Get the current selection and range
        const selection = window.getSelection();
        if (selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            // Insert the new label at the current cursor position
            range.insertNode(label);
            // Move the cursor to the end of the inserted label
            range.collapse(false);
            selection.removeAllRanges();
            selection.addRange(range);
        }
    }

    /**
     * @function handlePSLinChange this function is used to add selected option from the dropdown into a span
     * and add related classNames and attributes
     * @param e
     */
    const handlePSLineChange = (e) => {
        // Add the selected option from the dropdown to the labels
        const newLabel = document.createElement('span');
        newLabel.innerText = e.label;
        newLabel.className = 'label_add_column'; // Add a class for styling if needed
        newLabel.contentEditable = false;
        newLabel.setAttribute("returnName",`${e.value}`);
        newLabel.setAttribute("type",'label');

        keepCursorInPosition(newLabel);

        handleFormulaValidation();
    };
    /**
     * @function handleSuggestionClick creates a new span to add the clicked option to input
     * and removes the search value used to find this suggestion
     * by removing text or element that is not a span
     * and if the element is not a number and a dot
     * @param line
     */
    const handleSuggestionClick = (line) => {
        const newLabel = document.createElement('span');
        newLabel.innerText = line.label;
        newLabel.className = 'label_add_column'; // Add a class for styling if needed
        newLabel.contentEditable = false;
        newLabel.setAttribute("returnName",`${line.value}`);
        newLabel.setAttribute("type","label");

        //remove text written for search
        Array.from(inputRef.current.childNodes).forEach(node => {
            // Check if the node is not a span element
            if (node.nodeType === Node.TEXT_NODE || (node.nodeType === Node.ELEMENT_NODE && node.tagName !== 'SPAN')) {
                // Remove the node
                const nodeText = node.textContent.trim();
                if (!/^[\d.]+$/.test(nodeText)) {
                    inputRef.current.removeChild(node);
                }
            }
        });
        // Get the current selection and range in the contentEditable div
        keepCursorInPosition(newLabel);
        // Update the labels state (if needed)
        handleFormulaValidation();
        // Clear the suggestion list or input value if needed
        setSuggestionPsLines([]);
        setCurrentPage(1);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    /**
     * @function handleInputChange this function handles input change
     * 1.sets a placeholder or removes it
     * 2.Add text to typed text in case it is not a label or not a text
     * 3.Remove numbers and dots form typed text
     * 4.Add a span and related attributes if it is an operator or control ex: "(", ")","+"
     * 5.Remove the text related to an operator since we are adding it to a span to avoid having an operator duplicated
     * 6Use typed text to filter and show suggestions
     * @param e
     */
    const handleInputChange = (e) => {
        const inputElement = inputRef.current;
        if (inputElement.childNodes.length === 0) {
            inputElement.setAttribute('data-placeholder', lang.manage_columns.text.start_typing_formula);
        } else {
            inputElement.removeAttribute('data-placeholder');
        }
        // Get all child nodes (including text nodes and elements like span)
        let typedText = '';

        //remove label elements
        inputElement.childNodes.forEach(node => {
            if ((node.nodeType === Node.TEXT_NODE) || (node.nodeType === Node.ELEMENT_NODE && !node.classList.contains('label_add_column'))) {
                // If it's a text node, add the text to the result
                let nodeText = node.textContent.trim();
                // Regex to check if the string contains only digits
                // Remove numbers from the node text
                nodeText = nodeText.replace(/[\d.]+/g, ''); // Removes all digits and periods
                // Add the modified text to typedText (now without numbers)
                typedText += nodeText;
            }
        });

        const operatorRegex = /[+\-*/()]/;
        const containsOperator = operatorRegex.test(e.nativeEvent.data);

        if (containsOperator) {
            const operatorSpan = document.createElement('span');
            operatorSpan.innerText = e.nativeEvent.data;
            if (e.nativeEvent.data === "(" || e.nativeEvent.data === ")") {
                if (e.nativeEvent.data === "(") {
                    operatorSpan.setAttribute("type", "open-control");
                } else {
                    operatorSpan.setAttribute("type", "close-control");
                }
                operatorSpan.className = 'control_span'; // Class for styling the operators
            } else {
                operatorSpan.className = 'operator_span'; // Class for styling the operators
                operatorSpan.setAttribute("type", "operator");
            }
            operatorSpan.contentEditable = false;
            operatorSpan.setAttribute("returnName", `${e.nativeEvent.data}`);

            keepCursorInPosition(operatorSpan);
            //To fix case when typing net rev( so the "(" won't be duplicated or replace net rev
            Array.from(inputRef.current.childNodes).forEach(node => {
                if (node.nodeType === Node.TEXT_NODE) {
                    // Remove only operator characters from the text node
                    node.textContent = node.textContent.replace(/[+\-*/()]/g, '').trim();

                    // Remove the node if it's empty after removing operators
                    if (!node.textContent) {
                        inputRef.current.removeChild(node);
                    }
                }
            });
        }

        typedText = typedText.replace(/[+\-*/()]/g, '').trim();
        typedTextSuggestion.current = typedText.trim()
        setCurrentPage(1);
        if (typedText.trim().length > 2) {
            setSuggestionPsLines(linearizedPSLines.filter(f => f.label.toLowerCase().includes(typedText.trim().toLowerCase())));
        } else {
            setSuggestionPsLines([]);
        }
        handleFormulaValidation();
    };

    return (
        <div className="formula_suggestion_validation_container">
            <div className="add_column_formula_container">
                {props.profitStackLineColumns ?
                    <Container
                        data={psLinesTreeData}
                        onChange={(e) => handlePSLineChange(e)}
                        mode={"radioSelect"}
                        className={
                            "x-axis landscape-configure heatmap-configure dropdown-tree filter_dialog_container"
                        }
                        disabled={props.disabled}
                    />
                    : ""}
                <div
                    className="input-container"
                    data-placeholder={lang.manage_columns.text.start_typing_formula}
                    contentEditable={!props.disabled}
                    ref={inputRef}
                    onInput={handleInputChange}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                />
            </div>
            {currentSuggestions?.length > 0 ?
                <div className="formula_suggestion_container">
                    {currentSuggestions.map((line, index) => (
                        <div className="suggestion_text_value_container" onClick={() => handleSuggestionClick(line)}>
                            <span className="suggestion_text">Suggestion</span>
                            <span key={index} className="line_span">{line.label}</span>
                        </div>
                    ))}
                    <div className="pagination-controls">
                        <span className="pagination_text">{currentPage} of {totalPages > 0 ? totalPages : 1}</span>
                        <div className="pagination_buttons">
                            <Button
                                variant={BUTTON_VARIANT.TERTIARY}
                                size={SIZES.ICON}
                                type={BUTTON_TYPE.DEFAULT}
                                className="uk-flex uk-flex-center uk-flex-middle"
                                leftIcon={<i className="far fa-chevron-left"/>}
                                onBtnClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1 || suggestionPsLines.length === 0}
                            />
                            <Button
                                variant={BUTTON_VARIANT.TERTIARY}
                                size={SIZES.ICON}
                                type={BUTTON_TYPE.DEFAULT}
                                className="uk-flex uk-flex-center uk-flex-middle"
                                leftIcon={<i className="far fa-chevron-right"/>}
                                onBtnClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages || totalPages === 0}
                            />
                        </div>
                    </div>
                </div>
                : null}
            {inputRef.current?.childNodes.length > 0 ?
                <span className="formula_validation_container">
                <div className="formula_validation_image" formula_validation={formulaValidationAttribute}></div>
                <div className="formula_validation_text">{formulaValidationMessage}</div>
            </span>
                : ""}
        </div>
    );
};

export default forwardRef(Formula);