//ACTIONS -----------------------------------------------------
export function updatePeriodsStatus(periodsStatus, key, clear) {
    return {
      type: 'UPDATE_PERIODSSTATUS',
      key: key,
      periodsStatus:periodsStatus,
      clear: clear
    }
  }
  
  export function getPeriodsStatus() {
    return {
      type: 'GET_PERIODSSTATUS'
    }
  }

  export function updateDatasetOptions(datasetOptions, scenarioId, clear) {
    return {
      type: 'UPDATE_DATASETOPTIONS',
      scenarioId: scenarioId,
      datasetOptions:datasetOptions,
      clear: clear
    }
  }

  export function updateDatasetState(datasets, scenarioId, clear) {
    return {
      type: 'UPDATE_DATASET_STATE',
      scenarioId: scenarioId,
      datasetState:datasets,
      clear: clear
    }
  }
  
  export function getDatasetOptions() {
    return {
      type: 'GET_DATASETS'
    }
  }

  export function updateTiers(tiers, scenarioId, clear) {
    return {
      type: 'UPDATE_TIERS',
      scenarioId: scenarioId,
      tiers:tiers,
      clear: clear
    }
  }

  export function updateVectorState(tiers, scenarioId, clear) {
    return {
      type: 'UPDATE_VECTORS_STATE',
      scenarioId: scenarioId,
      vectorState:tiers,
      clear: clear
    }
  }
  
  export function getTiers() {
    return {
      type: 'GET_TIERS'
    }
  }
  
  export function updateScenarios(scenarios) {
    return {
      type: 'UPDATE_SCENARIOS',
      scenarios:scenarios,
    }
  }
  
  export function getScenarios() {
    return {
      type: 'GET_SCENARIOS'
    }
  }

  export function updatePsViews(customViewData, scenarioId, clear) {
    return {
      type: 'UPDATE_CUSTOM_VIEW_DATA',
      customViewData:customViewData,
      scenarioId: scenarioId,
      clear: clear
    }
  }

  export function updatePsViewsState(customViewData, scenarioId, clear) {
    return {
      type: 'UPDATE_CUSTOM_VIEW_DATA_STATE',
      customViewDataState:customViewData,
      scenarioId: scenarioId,
      clear: clear
    }
  }
  
  export function getPsViews() {
    return {
      type: 'GET_CUSTOM_VIEW_DATA'
    }
  }

  export function updateProfitstackHierarchyByStack(ps, scenarioId, clear) {
    return {
      type: 'UPDATE_PS_HIERARCHY',
      ps:ps,
      scenarioId: scenarioId,
      clear:clear
    }
  }
  
  export function getProfitStackHierarchyByStack() {
    return {
      type: 'GET_PROFIT_STACK_HIERARCHY_BY_STACK'
    }
  }

  export function updateProfitFormats(formats, scenarioId) {
    return {
      type: 'UPDATE_FORMATS',
      formats:formats,
      scenarioId: scenarioId,
    }
  }

  export function updateScenarioState(scenarioState) {
    return {
      type: 'UPDATE_SCENARIO_STATE',
      scenarioState:scenarioState
    }
  }

    export function updatePeriodsStatusState(periodsStatusState, key, clear) {
      return {
        type: 'UPDATE_PERIODS_STATUS_STATE',
        periodsStatusState:periodsStatusState,
        key :key,
        clear: clear
      }
  }