import React, { Component } from 'react';
import { DROPDOWN_TYPE, PS_MAPPING } from '../../class/constants';
import { findOptionByKey } from '../../class/utils';
import { CustomSelect, ToggleTab } from '../../form/elements';
import { FormComponent } from '../../form/FormElements';
import { lang } from '../../language/messages_en';
import { arrangeData } from '../filter/FilterHelperFunctions';
import DropDown from '../../newComponents/DropDown';

const $ = require('jquery');
const _attributeFunctions= {
    COUNT: "count",
    SUM: "sum"
}
const _costKey = PS_MAPPING.FIELDS.COST_KEY;
const _attribute = PS_MAPPING.FIELDS.ATTRIBUTE;

/**
 *  this component is called from the new profit stack mapping page when the user is setting up a calculated profit stack line
 * @author [Mostafa Haydar]
 * @extends Component
 * **/

class AttributeSetupContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: [],
            data: this.props.data,
            mappedLine: this.props.mappedLine,
            isNameValid: this.props.isNameValid
        };
        this.inputRefs = {};
        this.isLoading = false;
    }

    /**
     *  this function is called when the name, description, attribute or attribute type changes when setting up a attribute ps line
     * @param {*} e 
     * @param {*} type 
     * @param {*} reset 
     */
    updateMappedRow=(e, type, reset)=>{
        let tempState = {};
        let _this = this;
        let hasChanged = false;
        let mappedLine = _this.props.mappedLine;
        if (type === PS_MAPPING.FIELDS.DESCRIPTION) {
            mappedLine[PS_MAPPING.FIELDS.DESCRIPTION] = $(e.currentTarget).val();
        }else if (type === PS_MAPPING.FIELDS.NAME) {
            mappedLine[PS_MAPPING.FIELDS.NAME] = $(e.currentTarget).val();
            tempState.isNameValid = ""
        }else if (type === _attribute){
            hasChanged = true;
            mappedLine[_attribute] = e
            tempState.attributeType = e.file_type;
            mappedLine[PS_MAPPING.TITLES.ATTR_TYPE] = e.file_type;
            tempState.attributeValue = e.label;
            tempState.changed = hasChanged;
            tempState.parsedAmount = null;
            tempState.onElementChange =  true;
            _this.props.enableSave();
        }else { // sum or count toggle
            mappedLine[PS_MAPPING.FIELDS.ATTRIBUTE_FUNCTION] = e;
            tempState.attributeFunction = e;
        }
        tempState.mappedLine = mappedLine;
        this.setState(tempState);
    }

    componentDidMount() {
    
    }

    render() {
        let obj = this;
        let attributeFields =  this.props.calculatedColumns && this.props.calculatedColumns.length > 0 ? this.props.vectorList.concat(this.props.calculatedColumns) : this.props.vectorList;
        attributeFields = attributeFields && attributeFields.length > 0 ? arrangeData(attributeFields) : [];
        let objMapped = obj.props.mappedLines? obj.props.mappedLines.filter(e => obj.props.profitStackLineToMap && e[_costKey] === obj.props.profitStackLineToMap[_costKey]):"";
        let attrValue = objMapped? this.props.onElementChange && this.state.attributeValue ? findOptionByKey(attributeFields, this.state.attributeValue) : objMapped && objMapped[0] && objMapped[0][_attribute] && objMapped[0][_attribute] !== "" ? findOptionByKey(attributeFields, objMapped[0][_attribute]) : "":"";
        let type = [ {label: PS_MAPPING.TYPE.COUNT.COUNT_TITLE, value: PS_MAPPING.TYPE.COUNT.COUNT_FIELD},{label: PS_MAPPING.TYPE.SUM.SUM_TITLE, value:PS_MAPPING.TYPE.SUM.SUM_FIELD}];
        const optionLabel = ({ value, label, isDisabled }) => (// styles for the titles in the attribute types dropdown (Vectors and Calculated columns)
            <div title={label} className={(isDisabled ? "option-group-header" : "")+ " option-padding"}>{label}</div>
        );
        let notVector = this.state.mappedLine && this.state.mappedLine[PS_MAPPING.FIELDS.ATTRIBUTE_FUNCTION] && obj.state.mappedLine[PS_MAPPING.FIELDS.ATTR_TYPE] !== PS_MAPPING.ATTRIBUTE_COLUMNS.VECTORS;
        let mappedLine = this.state.mappedLine;
        if(!notVector){
            mappedLine[PS_MAPPING.FIELDS.ATTRIBUTE_FUNCTION] = _attributeFunctions.COUNT;
        }
        return (
            <div className="uk-margin-medium-top uk-height-fit">
                    <div className="uk-margin-xmedium-bottom">
                        <label htmlFor="Name" className="uk-text-xmedium">{lang.manage_access.NAME}<sup className="red">*</sup></label>
                        <FormComponent tag="input" id={PS_MAPPING.FIELDS.NAME} ref={r => this.inputRefs[PS_MAPPING.FIELDS.NAME] = r} className="uk-input" placeholder={lang.psl_placeholders.attribute_line_name}
                        onChange={(e)=>{this.updateMappedRow(e, PS_MAPPING.FIELDS.NAME)}} value={this.state.mappedLine? this.state.mappedLine[PS_MAPPING.FIELDS.NAME] : this.props.currentRow ? this.props.currentRow[PS_MAPPING.FIELDS.NAME] : ""}
                        />
                        {this.state.isNameValid && this.state.isNameValid !== "" ? <div id="calc-col-warn" className="fs-12 red italic">{this.state.isNameValid}</div> : ""}
                    </div>
                    <div className="uk-margin-xmedium-bottom">
                        <label htmlFor="Description" className="uk-text-xmedium">{lang.manage_access.DESCRIPTION}</label>
                        <FormComponent tag="textarea" id={PS_MAPPING.DESCRIPTION} ref={r => this.inputRefs[PS_MAPPING.DESCRIPTION] = r} className="uk-textarea" placeholder={lang.psl_placeholders.attribute_line_description}
                        onChange={(e)=>{this.updateMappedRow(e, PS_MAPPING.FIELDS.DESCRIPTION)}} value={this.state.mappedLine? this.state.mappedLine[PS_MAPPING.FIELDS.DESCRIPTION] : this.props.currentRow ? this.props.currentRow[PS_MAPPING.FIELDS.DESCRIPTION] : ""}
                        />
                    </div>
                    <div className="width-250 uk-display-inline-block">
                        <label className="uk-text-xmedium uk-margin-xsmall-bottom" htmlFor="select-set-attribute">{lang.profit_stack_mapping_attribute_select_title}<sup className="red">*</sup></label>
                        <DropDown
                            id="select-set-attribute"
                            className="width-250 uk-cursor-pointer input__dropdown"
                            name={"attribute-select"}
                            value={obj.state.mappedLine? findOptionByKey(attributeFields, obj.state.mappedLine[_attribute]): attrValue}
                            onChange={(option)=>this.updateMappedRow(option, _attribute)}
                            options={attributeFields}
                            placeholder={lang.ps_mapping.select_vector_or_calculated_line}
                            formatOptionLabel={optionLabel}
                            type={DROPDOWN_TYPE.INPUT}
                            />
                    </div>
                    <div className={"mrgl50 uk-display-inline-flex"+( !obj.state.changed && obj.state.mappedLine[PS_MAPPING.FIELDS.ATTR_TYPE] === PS_MAPPING.ATTRIBUTE_COLUMNS.VECTORS? " disabled":"")} style={{marginTop: '43px'}}>
                        <ToggleTab options={type} onSelectTab={(order) => this.updateMappedRow(order, PS_MAPPING.FIELDS.ATTRIBUTE_FUNCTION)}
                            defaultValue={( notVector? mappedLine[PS_MAPPING.FIELDS.ATTRIBUTE_FUNCTION] : _attributeFunctions.COUNT)}
                        />
                    </div>
                </div>
        );

    }

}

export default AttributeSetupContainer;