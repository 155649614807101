import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { useEffect, useRef, useState } from "react";
import { copyObjectValues } from "../class/utils";
import { formatValNumber } from "../class/format";
import { CURRENCY, FormatTypes } from "../class/constants";
import { abbreviateNumber } from "../class/number";
import { lang } from "../language/messages_en";
import { Segment } from "../components/Segment";
import { getCurrency } from "../class/common";
/**
 * @author Mostafa Haydar
 * @description component that renders the total landed cost trend chart (when clickiing on view trend) 
 * @param {*} props 
 * @returns 
 */
const TLCLandscapeTrendChart = (props) => {
    const chartRef = useRef(null);

    useEffect(() => {
        renderTrendChart();
    }, [props.trendData, props.trendViewType]);

    const formatRowValues = (row, entityPrefix = '') => {
        row["percentage_per_revenue"+entityPrefix] = formatValNumber(row["percentage_per_revenue"+entityPrefix], FormatTypes.PERCENTAGE);
        row["percentage_per_cogs"+entityPrefix] = formatValNumber(row["percentage_per_cogs"+entityPrefix], FormatTypes.PERCENTAGE);
        row["value"+entityPrefix] = row["value"+entityPrefix];
    }

    /**
     * Formatting bar chart data (amount & percentage) to match the format selected in format popup
     * @param {*} data 
     * @returns 
     */
    const formatTrendChartData = (data) => {
        if(!data?.length) {
            return [];
        }
        
        const finalData = copyObjectValues(data);
        const entities = props.entities || [];
        const hasEntities = entities.length > 0;
    
        finalData.forEach(row => {
            if(hasEntities){
                entities.forEach(entity => {
                    const entityPrefix = "_"+entity.value;
                    formatRowValues(row, entityPrefix);
                });
            }else{
                formatRowValues(row);            
            }
        });
    
        return finalData;
    };

    const renderText = (isPercentageOfRevenue, isPercentage)=>{
        let text = lang.trend_types.find(e=>e.value === props.trendViewType.value)?.label;
        if(text){
            if(isPercentageOfRevenue){
                text = text.replace("%REVENUE%",props.revenueReturnName?.name);
            }else if(!isPercentage){// is amount
                text = text.replace("("+CURRENCY+")","");
            }
        }
        return text;
    }

    const renderTrendChart=()=>{
        let isPercentageOfRevenue = props.trendViewType.value === lang.trend_types[0].value;
        let isPercentageOfCogs = props.trendViewType.value === lang.trend_types[1].value;
        let isPercentage = isPercentageOfRevenue || isPercentageOfCogs;

        var chart = am4core.create(chartRef.current, am4charts.XYChart);
        chart.data = formatTrendChartData(props.trendData);
        chart.logo.disabled = true;

        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "period";
        categoryAxis.renderer.minGridDistance = 20;
        categoryAxis.renderer.grid.template.stroke = am4core.color("#f0f0f0"); // Horizontal grid lines color
        categoryAxis.renderer.grid.template.strokeOpacity = 0.7; 
        categoryAxis.renderer.labels.template.paddingTop = 10;
        categoryAxis.renderer.labels.template.fontSize = 12; // Set font size for category axis labels

        // Create a custom label function for the category axis
        categoryAxis.renderer.labels.template.adapter.add("horizontalCenter", function(padding, target) {
            if (target.dataItem?.index === categoryAxis.dataItems.length - 1) {
                // Make the horizontalCenter right for the last period
                return "right";
            }
            // Middle other periods
            return "middle";
        });

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        // Customize number formatting for value axis labels
        valueAxis.renderer.labels.template.adapter.add("text", (text) => {
            return abbreviateNumber(text, isPercentage);
        });
        valueAxis.renderer.grid.template.stroke = am4core.color("#f0f0f0"); // Vertical grid lines color
        valueAxis.renderer.grid.template.strokeOpacity = 0.7;
        valueAxis.renderer.labels.template.fontSize = 12; // Set font size for category axis labels
        valueAxis.renderer.labels.template.paddingBottom = 20;

        const gradient = new am4core.LinearGradient();
        gradient.rotation = 90; // Set rotation to 90 degrees for vertical gradient
        gradient.addColor(am4core.color("#fcfafa"));
        gradient.addColor(am4core.color("#dedede"));
        gradient.percentColors = [
            { percent: 0, color: am4core.color("#fcfafa") }, // 0% at min value
            { percent: 100, color: am4core.color("#dedede") }, // 100% at max value
        ];
        
        // Create series
        if(props.entities?.length>0){
            props.entities.forEach(entity=>{
                let valueY = props.trendViewType.value + "_" + entity.value;
                renderSeries(chart, valueY, isPercentage, isPercentageOfRevenue, gradient, categoryAxis, entity);
            })
        }else{
            renderSeries(chart, props.trendViewType.value, isPercentage, isPercentageOfRevenue, gradient, categoryAxis);
        }
        chart.xAxes.values.forEach((axis) => {
            axis.tooltip.disabled = true;
        });
        chart.yAxes.values.forEach((axis) => {
            axis.tooltip.disabled = true;
        });
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.lineX.disabled = false; // Disable vertical cursor line
        chart.cursor.lineY.disabled = true; // Disable horizontal cursor line
        
        if(!props?.entities?.length){
            chart.cursor.snapToSeries = [];
            chart.series.each(series => {
                chart.cursor.snapToSeries.push(series);
            });
        }
        categoryAxis.startLocation = 0.5;
        categoryAxis.endLocation = 0.5;
        return () => {
            chart.dispose();
        };
    };

    const renderSeries = (chart, valueY, isPercentage, isPercentageOfRevenue, gradient, categoryAxis, entity=null) =>{
            let isProfitSegment = lang.TLC_SELECTION.LENSES[1].value === props.lense;
            let color = entity?.legendColor;
            if(isProfitSegment){
                entity = new Segment().getSegmentObject(entity.label);
                color = entity.trendColor;
            }
            let series = chart.series.push(new am4charts.LineSeries());
            series.dataFields.valueY = valueY;
            series.dataFields.categoryX = "period";
            series.strokeWidth = 2;
            series.fillOpacity = 0.5;
            series.stroke = am4core.color(color || "#5F60C8"); // Set stroke color for the line
            series.fill = gradient;

            let bullet = series.bullets.push(new am4charts.CircleBullet());
            bullet.circle.radius = 5;
            bullet.circle.strokeWidth = 2;
            bullet.circle.fill = am4core.color("#FFFFFF"); // Set the fill color to white
            // Create default state (invisible)
            let defaultState = bullet.states.create("default");
            defaultState.properties.opacity = 0;

            // Create hover state (visible)
            let hoverState = bullet.states.create("hover");
            hoverState.properties.opacity = 1;
            bullet.setState("default");

            bullet.events.on("over", function(event) {
                let dataItem = event.target.dataItem;
                let category = dataItem.categoryX;
                let bulletPosition = categoryAxis.categoryToPosition(category);
                if (bulletPosition <= 0.5) {// for the first 6 periods
                    series.tooltip.dx = 8;  // Tooltip to the right
                    series.tooltip.pointerOrientation = "left"
                } else {// last period
                    series.tooltip.pointerOrientation = "right";
                    series.tooltip.dx = -8; // Tooltip to the left
                }
            });
            
            series.tooltip.getFillFromObject = false;
            series.tooltip.background.fill = am4core.color("#ffffff");
            let sign = isPercentage ?"%" :getCurrency();
            let text = renderText(isPercentageOfRevenue, isPercentage);
            series.tooltipHTML = entity? entityTooltipHTML(text, sign, valueY, entity, color):tooltitHTML(text, sign, valueY);
    }

    const tooltitHTML=(text, sign, valueY) =>{
        return(`
            <div>
                <div><p class="trend_tooltip_text_title">${text}</p></div>
                <div><p class="trend_tooltip_text_value">${sign === getCurrency() ? sign : ""}{%VALUE%}${sign !== getCurrency() ? sign : ""}</p></div>
            </div>
            `.replace("%VALUE%",valueY)
        )
    }

    const entityTooltipHTML=(text, sign, valueY, entity, color)=>{
        let selectedLense = props.lenses.find(e=>e.value ===props.lense);
        return (`
            <div class="uk-display-flex">
                <div>
                    <div><p class="trend_tooltip_text_title">${selectedLense.abv || selectedLense.label}</p></div>
                    <div><p class="trend_tooltip_text_value" style="color:${color}">${entity.label}</p></div>
                </div>
                <div class="vertical-seperator uk-margin-small-left"></div>
                <div class="uk-margin-small-left">
                    <div><p class="trend_tooltip_text_title">${text}</p></div>
                    <div><p class="trend_tooltip_text_value">${sign === getCurrency() ? sign : ""}{%VALUE%}${sign !== getCurrency() ? sign : ""}</p></div>
                </div>
            </div>
        `.replace("%VALUE%",valueY)
        );
    }

    return (
        <>
            <div ref={chartRef} className="trend_chart"></div>
        </>
    );
}
export { TLCLandscapeTrendChart };
