import React, { useState } from 'react'
import { useRef } from 'react';
import { useEffect } from 'react';
import {TabulatorFull} from "tabulator-tables";
import { cleanUpTabulatorColumns } from '../../../class/common';
import { FormatTypes, FORMAT_TYPE, HEATMAP, BUTTON_VARIANT, SIZES, BUTTON_TYPE } from '../../../class/constants';
import { formatValHTML, formatValString } from '../../../class/format';
import { convertViewportToPx } from '../../../class/formatting';
import { replaceSpecialCharacters } from '../../../class/jqueries';
import { copyObjectValues, tryParse } from '../../../class/utils';
import { lang } from '../../../language/messages_en';
import './entityList.css';
import NewButton from './NewButton';
import NewToggleButton from './NewToggleButton';
import Button from '../../../newComponents/Button';
import { createCheckbox } from '../../../newComponents/CheckBoxTabulator';



export default function EntitieNewTabulator(props) {
    const [limitReached, setLimitReached] = useState(false)
    const [entitiesNumber, setEntitiesNumber] = useState(0)
    const [rows, setRows] = useState(0)
    const [toggleOn, setToggleOn] = useState(false)
    let table  = useRef();
    let globaltop = useRef(null);
    let nextglobaltop = useRef(null);
    function addClass(data){
        let tabulatorchildren = data;
        for (var i=0; i<tabulatorchildren.length; i++) {
            tabulatorchildren[i].classList.add("close-dropdown");
            if (tabulatorchildren[i].children) {
                addClass(tabulatorchildren[i].children);
            }
        }
    }
    useEffect(() => {
        var options = {
            layout: "fitColumns",      //fit columns to width of table
            // data: tabledata,
            reactiveData: true,
            // index: "entities",
            height: convertViewportToPx(250),
            zIndex: 3,
            width: "200px",
            resizableColumns: false,
            virtualDomBuffer: 800,
            headerSort: false,
            scrollVertical:function(top){
                globaltop.current = top
            },
            cellClick: function (e, cell) {
                if (!limitReached && $(e.srcElement) && $(e.srcElement).attr('id') &&  $(e.srcElement).attr('id').includes('checkbox_icon')) {
                    let checkboxValue = e.currentTarget.children[0].checked;
                    let selection = table.current.getData()
                    selection.find(f => f.entities === cell.getRow().getData().entities).check = checkboxValue;
                    if(props.id === "column-select-entities"){
                        props.setSelectedEntities(false,cell.getRow().getData().entities, checkboxValue);
                    }
                    if(props.id === "row-select-entities"){
                        props.setSelectedEntities(true,cell.getRow().getData().entities, checkboxValue);
                    }
                    setRows(selection.filter(f => f.check).length);
                    table.current.replaceData(selection);
                    // setToggleButtonOnEnabled(table.current.getData());
                    $("#"+props.id).addClass("close-dropdown");

                }
            },
        }

        if (!table.current && props.columns) {
            table.current = new TabulatorFull("#"+props.id, options);
            table.current.setColumns(getTabulatorColumns(props.columns));
            table.current.setData(tryParse(props.entities));
            let tabulatorchildren = document.getElementById(props.id);
            tabulatorchildren = (tabulatorchildren && tabulatorchildren.children) || [];
            addClass(tabulatorchildren);
        }
        let defaultSelection = table.current.getData()
        setRows(defaultSelection.filter(f => f.check).length);

        setEntitiesNumber(table.current.getData().length)
    }, [])

    useEffect(()=>{
        let filter = []
        filter = table.current.getFilters(true)
        nextglobaltop.current = globaltop.current;
        if (filter.length === 0) {
            // table.current.setColumns(getTabulatorColumns(props.columns));
        }
        table.current.replaceData(table.current.getData());
        // table.current.getData().forEach(e=>{
        //     table.current.updateRow(e.entities, e);
        // })
        $('#' + props.id + ' .tabulator-tableholder').animate({
            scrollTop: nextglobaltop.current
        }, 50)
        
        if (filter.length > 0) {
            table.current.setFilter(filter)
        }
        let tabulatorchildren = document.getElementById(props.id);
        tabulatorchildren = (tabulatorchildren && tabulatorchildren.children) || [];
        addClass(tabulatorchildren);
        },[JSON.stringify(props.tempSelectedEntitiesObj)])

    useEffect(()=>{
        table.current.clearFilter(true);
        table.current.setColumns(getTabulatorColumns(props.columns));
        table.current.setData(tryParse(props.entities));
        let tabulatorchildren = document.getElementById(props.id);
        tabulatorchildren = (tabulatorchildren && tabulatorchildren.children) || [];
        addClass(tabulatorchildren);
    },[props.entities])

    function getTabulatorColumns(columns) {
        columns = copyObjectValues(columns) || [];
        columns.forEach(col => {
            col.bold = true;
            col.addClass = "close-dropdown"
            if(col.field === "revenue") {
                // col.width = convertViewportToPx(110);
                col.dontFilter = true;
                col.cssClass = "close-dropdown";
            }
            if(col.field === "entities") { // constant
                col.cssClass = "close-dropdown";
                // col.width = convertViewportToPx(140);
            }
        });

        columns = cleanUpTabulatorColumns(columns, null, null, table, { id: props.id });

        columns.forEach(col => {
            col.formatter = getColumnFormatter(col.field, col.format_type);
            if (col.field === "check") {// remove titleFormatter if not needed
                col.titleFormatter = function () {
                    let div = document.createElement("div");
                    let checkbox = createCheckbox();
                    div.appendChild(checkbox);
                    let span = document.createElement("span");
                    span.classList.add("uk-text-bold", "uk-margin-small-left", "close-dropdown");
                    span.innerHTML = col.title;
                    div.appendChild(span);
                    return div;
                }
                col.cssClass = "close-dropdown";
                col.download = false;
                col.minWidth = convertViewportToPx(30);
                col.width = convertViewportToPx(30);
            }
        });
        return columns;
    }

    const getTempSelectedEntitiedObj=()=>{
        return props.tempSelectedEntitiesObj;
    }

    const getColumnFormatter = (colName, colType, colField) => {
        var columnFormatter;
        switch (colName) {
            case HEATMAP.COLUMNS.CHECK:
                columnFormatter = function (cell, formatterParams) {
                    let tempSelectedEntitiesObj = getTempSelectedEntitiedObj();
                    let rowNumber = cell.getRow().getPosition();
                    cell.getElement().classList.add("dt-body-center", "to-hide-on-drill", "no-sort");
                    cell.getColumn().getElement().style.opacity = "0"; //keep column title, just hide it from UI
                    var checkbox = createCheckbox();
                    checkbox.checked = cell.getRow().getData().check;
                    checkbox.name = 'chosenEntity';
                    checkbox.id = 'checkbox_icon_' + rowNumber;
                    checkbox.classList.add('chosenEntity', 'close-dropdown');
                    if ((table.current.getData().filter(f => f.check).length >= props.limitEntities -1 && !checkbox.checked && 
                        ((props.id === "column-select-entities" && tempSelectedEntitiesObj.groupByColumn === true && !checkbox.checked) 
                            || (props.id === "row-select-entities" && tempSelectedEntitiesObj.groupByRow === true && !checkbox.checked)
                        ) 
                        || 
                        (props.id === "column-select-entities" && tempSelectedEntitiesObj.groupByColumn === false && table.current.getData().filter(f => f.check).length === props.limitEntities && !checkbox.checked) ||
                        (props.id === "row-select-entities" && tempSelectedEntitiesObj.groupByRow === false && table.current.getData().filter(f => f.check).length === props.limitEntities) && !checkbox.checked)
                     ) {
                        checkbox.setAttribute("uk-tooltip", "Entity limit reached.");
                        checkbox.setAttribute('disabled', 'disabled');
                    } else {
                        checkbox.removeAttribute("uk-tooltip");
                        checkbox.removeAttribute('disabled');
                    }

                    return checkbox;
                };
                break;

            case HEATMAP.COLUMNS.ENTITIES:
                columnFormatter = function (cell, formatterParams) {
                    cell.getElement().classList.add("close-dropdown");
                    return cell.getValue() ;

                };
                break;

            case HEATMAP.COLUMNS.REVENUE:
                columnFormatter = function (cell, formatterParams) {
                    cell.getElement().classList.add("close-dropdown");
                    return formatValString(cell.getRow().getData().revenue, FormatTypes.AMOUNT);;
                };
                break;
            default:
                columnFormatter = function (cell, formatterParams) {
                    return cell.getValue();
                }
                break;
        }
        // }
        return columnFormatter;
    }

    const resetToDefault = () =>{
        let isRow = props.id === "row-select-entities"
        props.resetToDefault(true,isRow);
        table.current.setData(tryParse(props.entities))
        let defaultSelection = table.current.getData()
        setRows(defaultSelection.filter(f => f.check).length);
        table.current.clearFilter(true);
        table.current.setColumns(getTabulatorColumns(props.columns));
    }

    let isDisableToggle = props.id === "column-select-entities" ? props.tempSelectedEntitiesObj.toggleColumn : props.tempSelectedEntitiesObj.toggleRow
    let tooltip = isDisableToggle ? props.id === "column-select-entities" ? props.tempSelectedEntitiesObj.tooltipMessageColumn : props.tempSelectedEntitiesObj.tooltipMessageRow : "";
    return (
        <>
            {/* {props.entityDropDownClick ? */}
            <div id={'entityListContainer'+props.id} className='entityListContainer close-dropdown'>
                <div className='entityList close-dropdown'>
                    <div className='select-entities-info-label close-dropdowm'>
                        <i className='close-dropdown'>{lang.you_can_select_upto.replace("[limit]", props.limitEntities)}</i>
                    </div>
                    <div id={props.id}></div>
                    <div className='table-footer close-dropdown'>
                        <span className='records-text close-dropdown'>{table.current?.getData().filter(f => f.check).length + " of "+ (props.entities && props.entities.length)+" records"}
                            <NewToggleButton title={tooltip} isRow = {props.id !== "column-select-entities"} value = {props.id === "column-select-entities" ? props.tempSelectedEntitiesObj.groupByColumn : props.tempSelectedEntitiesObj.groupByRow} 
                                setGroupByValue={props.setGroupByValue} toggleOn={toggleOn} disableToggle={isDisableToggle} id={props.id}/> 
                        </span>
                        <div className='dropdown-footer-container close-dropdown'>
                            <span className={"reset-text close-dropdown" + (props.isChanged ? "" : " disabled")} onClick={() => resetToDefault()}>{lang.modal.buttons.reset_to_default}</span>
                            <div uk-tooltip={props.isApplyDisabled ? lang.at_least_select_one :  "title:"} className='close-dropdown'>
                                <Button 
                                label={lang.modal.buttons.apply}
                                variant={BUTTON_VARIANT.PRIMARY}
                                size={SIZES.DEFAULT}
                                type={BUTTON_TYPE.DEFAULT}
                                disabled={props.isApplyDisabled}
                                className={props.isApplyDisabled ? "close-dropdown" : ""}
                                onBtnClick={(e) => props.apply(props.id !== "column-select-entities", e)}
                            />  
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
            {/* : ""} */}
        </>
    )
}
