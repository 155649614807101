import React, { Component } from 'react';
import { getTranslationFile, deepCompareObjects, copyObjectValues } from '../../class/utils';
import { CustomSelect } from '../../form/elements.js';
import { addRowNumberToOptions } from '../../class/jqueries';
import { addSimpleTooltipIcon } from '../../class/common';
import { BUTTON_TYPE, BUTTON_VARIANT, CALCULATED_COLUMNS as CALC_COLS, DROPDOWN_TYPE, Formats, RAW_ITEMS, SIZES, STAGING_SECTIONS } from '../../class/constants';
import Button from '../../newComponents/Button';
import DropDown from '../../newComponents/DropDown';

const $ = require('jquery');
const lang = getTranslationFile();

const _file = CALC_COLS.FIELDS.COLUMN_FIELDS.FILE;
const _column = CALC_COLS.FIELDS.COLUMN_FIELDS.COLUMN;

class FileColumnsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [{}],  //empty row by default
            fileOptions: [],
            formula: copyObjectValues(this.props.formula) || [],
        };

        this.addColumn = this.addColumn.bind(this);
        this.setDraggable = this.setDraggable.bind(this);
        this.startDeleteColumn = this.startDeleteColumn.bind(this);
        this.deleteColumn = this.deleteColumn.bind(this);
    }

    componentDidMount() {
        let _this = this;
        this.setDraggable();

        //fetch the options for the columns by calling handle change for the files
        this.state.data.forEach(el=>{
            if(el[_file]) {     //if no file is chosen, no need to proceed
                _this.handleChange(_file, el[_file], true, true);
            }
        });
    }

    static getDerivedStateFromProps(props, state) {
        let tempState = {};
        let update = false;
        if(props.fileOptions && !deepCompareObjects(props.fileOptions, state.fileOptions)) {
            tempState.fileOptions = props.fileOptions;
            update = true;
        }

        //data is always passed, so check length
        if(props.data && props.data.length && !deepCompareObjects(props.data, state.data) && (!state.data || !state.data.length || deepCompareObjects(state.data[0], {}))) {
            tempState.data = props.data;
            update = true;
        }

        if(update) {
            return tempState;
        }
        return null;    //do not update state
    }

    addColumn() {
        let data = copyObjectValues(this.state.data);
        data.push({});  //add empty row in the data array

        this.setState({
            data: data,
        }, this.setDraggable);
    }

    handleChange(attr, valueObj, fromDidMount, onReset) {
        let _this = this;
        let rowNum = valueObj["rowNumber"]
        let data = copyObjectValues(this.state.data);

        let callback = () => {};

        if(attr === _file) {
            if(!fromDidMount) {
                data[rowNum][_column] = "";  //empty the column dropdown when changing the file
            }

            let fileValue = valueObj.value;
            callback = (options)=>{
                if(fileValue !== STAGING_SECTIONS.CALCULATED_COLUMNS) {
                    //if _file === calc_cols, set options as they are, else, filter the numeric ones
                    options = options.filter(e=>e[RAW_ITEMS.FIELD_DATA_TYPE].toUpperCase() === Formats.Numeric);
                }
                _this.setState({
                    [fileValue+"_columnOptions"]: options,
                });
            }
        }

        this.props.handleChange(attr, valueObj, callback, onReset);
        data[rowNum][attr] = valueObj;  //set the new value

        this.setState({
            data: data
        }, function() {
            //if filled both file and column, call draggable to make this row draggable
            if(data[rowNum][_file] && data[rowNum][_column]) {
                this.setDraggable();
            }
        });
    }

    startDeleteColumn(e) {
        let rowNum = $(e.currentTarget).attr("rownumber");
        let isUsedObj = this.props.getIsColumnUsed(Number(rowNum)+1);     //because the actual column index dragged is = rowNum + 1 (text of the <p> in render)
        let _this = this;

        if(isUsedObj.isUsed) {
            this.props.showMessage(lang.calc_cols.used_column, ()=> {
                _this.deleteColumn(rowNum);
                if(isUsedObj.isMainFormula) {
                    _this.props.deleteFormula();
                } else {
                    _this.props.deleteFormula(isUsedObj.formulaIndex);
                }
            });
        } else {
            this.deleteColumn(rowNum);
        }
    }

    deleteColumn(rowNum) {
        let data = copyObjectValues(this.state.data);
        
        data.splice(rowNum, 1);

        //update the indices of all the columns after they have been re-arranged
        data.forEach((col, i)=>{
            if(col[CALC_COLS.FIELDS.COLUMN_FIELDS.FILE]) {
                col[CALC_COLS.FIELDS.COLUMN_FIELDS.FILE]["rowNumber"] = i;
            }
            if(col[CALC_COLS.FIELDS.COLUMN_FIELDS.COLUMN]) {
                col[CALC_COLS.FIELDS.COLUMN_FIELDS.COLUMN]["rowNumber"] = i;
            }
        });

        this.setState({
            data: data,
        }, this.setDraggable);
    }

    setDraggable() {
        var obj = this;
        var draggableOptions = {
            // start: function( event, ui ) {
            //   ui.position = {top: 0, left: 0}
            //     let $node = $(event.target);
            //     // obj.$clone = $node.clone();
            //     $node.removeClass("uk-position-none");
            //     // $node.css("position","absolute");          //freeing the space reserved by the original node that is being dragged
            //     // $node.after(obj.$clone);                //immediately replacing the emptied space with a clone of the original node
            // },
        
            // cursorAt: {top : 0},
            scroll: true,
            helper: 'clone',
            // appendTo: 'body',
            containment: '#calculated-column-container',
            revert: "invalid",
            
        };

        $(".draggable-col-index").draggable(draggableOptions);
    }

    render() {
        var renderedCols = [];
        for(let i=0; i < this.state.data.length; i++) {
            let rowFile = this.state.data[i] ? this.state.data[i][_file] || "" : "";
            let rowColumn = this.state.data[i] ? this.state.data[i][_column] || "" : "";
            let fileValue = rowFile === "" ? "" : rowFile.value;
            renderedCols.push(
                <React.Fragment>
                    {i === 0 ?
                        <div className="uk-flex" key={i}>
                            <div className="width-200 uk-margin-large-left uk-margin-xsmall-bottom">{lang.calc_cols.mapping.columns_container.file_name}{addSimpleTooltipIcon(lang.calc_cols.mapping.columns_container.file_name_tooltip)}</div>
                            <div className="width-200 uk-margin-xmedium-left uk-margin-xsmall-bottom">{lang.calc_cols.mapping.columns_container.column_name}{addSimpleTooltipIcon(lang.calc_cols.mapping.columns_container.column_name_tooltip)}</div>
                        </div>
                    :""}

                    <div className="uk-flex align-items-center uk-margin-xmedium-bottom" key={"row_" + i + "_"+ fileValue + "_" + rowColumn.value}>
                        {/* save file and column details in the node because it will transfer them to the formula */}
                        {/* CALC_COLS.FIELDS.COLUMN_FIELDS.FILE -- CALC_COLS.FIELDS.COLUMN_FIELDS.COLUMN -- if changing value of these consts change the attr name in the below div*/}
                        <div className={"border-square uk-grab "+ (rowFile && rowFile !=="" && rowColumn && rowColumn !==""?"draggable-col-index":"disabled-border-square")}
                            file={JSON.stringify(rowFile)} column={JSON.stringify(rowColumn)}>
                            <span>{i + 1}</span>
                        </div>
                        <DropDown 
                            className="width-200 uk-margin-xmedium-left input__dropdown"
                            value={rowFile || ""}
                            options={addRowNumberToOptions(this.state.fileOptions, i)}
                            onChange={(e)=>this.handleChange(_file, e)}
                            type={DROPDOWN_TYPE.INPUT}
                        />
                        <DropDown 
                            className="width-200 uk-margin-xmedium-left input__dropdown"
                            value={rowColumn || ""}
                            options={addRowNumberToOptions(this.state[fileValue+"_columnOptions"], i)}
                            onChange={(e)=>this.handleChange(_column, e)}
                            type={DROPDOWN_TYPE.INPUT}
                        />
                        {this.state.data.length > 1 ?
                            <div className="uk-button-icon uk-margin-default-left" rownumber={i} onClick={(e)=>this.startDeleteColumn(e)}>
                                <li className="fal fa-trash-alt fa-lg uk-cursor-pointer" />
                            </div>
                        :""}
                    </div>
                </React.Fragment>
            )
        }
        
        return(
            <div className="calc-cols-columns-container uk-border uk-border-rounded uk-padding-xmedium-left">
                <div className="fs-14 uk-text-bold uk-margin-xmedium-bottom">{lang.calc_cols.mapping.columns_container.title}{addSimpleTooltipIcon(lang.calc_cols.mapping.columns_container.tooltip)}</div>
                <Button 
                    variant={BUTTON_VARIANT.TERTIARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    className="uk-margin-xmedium-bottom"
                    leftIcon={<i className="fa-lg far fa-plus-circle" aria-hidden="true"></i>}
                    onBtnClick={this.addColumn}
                    label={lang.calc_cols.mapping.columns_container.add_column}
                />
                {renderedCols}
            </div>
        );
    }
}


export default FileColumnsContainer;