import { React, useEffect, useRef, useState } from "react";
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { ReactComponent as CheckPointSuccess } from "../../../../styles/images/icons/timeline-section-success.svg";
import { ReactComponent as CheckPointProgress } from "../../../../styles/images/icons/timeline-section-progress.svg";
import { ReactComponent as CheckPointWaiting } from "../../../../styles/images/icons/timeline-section-waiting.svg";
import { ReactComponent as CheckPointFail } from "../../../../styles/images/icons/timeline-section-fail.svg";
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { getTranslationFile } from "../../../../class/utils";
import { CURRENT_BUILD_STATUS } from "../../../../class/constants";
import "./BuildProgressBar.css";

const lang = getTranslationFile();
const messages = lang.monthly_build.build_scenario.build_progress;

const BuildProgressBar = (props) => {
    
    const positionOfLoaderRef = useRef(0);

    const {
        buildSteps,
        activebuildStep,
        buildPercentage,
        currBuildStatus,
        setOpenRestartBuildModal,
        lastSucceededJob
    } = props;

    useEffect(() => {
        if(currBuildStatus === CURRENT_BUILD_STATUS.RUNNING){
            highlightStepConnector();
            setLoaderPosition();
        } else if(currBuildStatus === CURRENT_BUILD_STATUS.FAILED){
            highlightFailedStepConnector();
        }
    });

    function highlightFailedStepConnector() {
        for (let index = 0; index <= activebuildStep; index++) {
            $("#step-" + (index) + " .MuiStepConnector-root").removeClass("Mui-active");
            $("#step-" + (index) + " .MuiStepConnector-root").removeClass("Mui-completed");
            $("#step-" + (index) + " .MuiStepConnector-root").addClass("Mui-fail");
      }
      $("#step-" + (activebuildStep+1) + " .MuiStepConnector-root").addClass("Mui-half-fail");
    }


    function highlightStepConnector() {
        for (let index = 0; index <= buildSteps.length; index++) {
            $("#step-" + (index) + " .MuiStepConnector-root").removeClass("Mui-active");
            if(index <= activebuildStep){
                $("#step-" + (index) + " .MuiStepConnector-root").addClass("Mui-completed");
            } else {
                $("#step-" + (index) + " .MuiStepConnector-root").addClass("Mui-inactive");
            }
        }
        $("#step-" + (activebuildStep+1) + " .MuiStepConnector-root").addClass("Mui-active");
        let position = positionOfLoaderRef.current * 100;
        $(".Mui-active .MuiStepConnector-line").css('borderImage', `linear-gradient(to right, #343F7B ${position}%, #eaeaf0 ${position}%) 1 0 stretch`);        
      }

    const QontoConnector = styled(StepConnector)(() => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
          top: '9px',
          left: 'calc(-50% + 5px)',
          right: 'calc(50% + 5px)',
          zIndex: -1
        },
        [`&.${stepConnectorClasses.active}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            borderImage: currBuildStatus === CURRENT_BUILD_STATUS.SUCCEEDED ?
             'linear-gradient(to right, #91d4c2 100%, #eaeaf0 0% ) 1 0 stretch' : ""
          },
          

        },
        [`&.Mui-fail`]: {
            [`& .${stepConnectorClasses.line}`]: {
              borderColor: '#EF93A3',
            },
          },
        [`&.${stepConnectorClasses.completed}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#91d4c2',
          },
        },
        [`&.Mui-half-fail`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderImage: 'linear-gradient(to right, #ef93a3 50%, #eaeaf0 50% ) 1 0 stretch',
            },
          },
        [`& .${stepConnectorClasses.line}`]: {
          borderColor: '#eaeaf0',
          borderTopWidth: 3,
          borderRadius: 1,
        },
      }));
      
      const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
        ...(ownerState.active && {
          color: '#343F7B',
        }),
        '& .StepIcon-circle svg': {
          width: 18,
          height: 18,
          borderRadius: '50%',
          backgroundColor: 'currentColor',
        },
      }));

      function stepIcon(active, completed, stepIndex ) {
        const inProgress = currBuildStatus === CURRENT_BUILD_STATUS.RUNNING && stepIndex == activebuildStep;
        const failedStep = (currBuildStatus === CURRENT_BUILD_STATUS.FAILED && stepIndex <= activebuildStep);
        const isSucceededOrCompleted = currBuildStatus === CURRENT_BUILD_STATUS.SUCCEEDED || completed || stepIndex < activebuildStep;
      
        let IconComponent;
        if(failedStep){
            IconComponent = <CheckPointFail />;
        } else if (isSucceededOrCompleted) {
            IconComponent = <CheckPointSuccess />;
        } else if (inProgress) {
            IconComponent = <CheckPointProgress />;
        } else {
            IconComponent = <CheckPointWaiting />;
        }
      
        return (
          <QontoStepIconRoot ownerState={{ active }} className="StepIcon-circle">
            {IconComponent}
          </QontoStepIconRoot>
        );
      }

    const renderHeader = () => {
        switch (currBuildStatus) {
            case CURRENT_BUILD_STATUS.NOT_STARTED:
                return (<>
                    <div className="dialog-title">{messages.start_build}</div>
                    <div className="text-grey uk-text-unbold fs-16">
                        <span>{messages.pre_build_message}</span>
                    </div>
                </>);

            case CURRENT_BUILD_STATUS.RUNNING:
                return (<>
                    <div className="dialog-title">{buildSteps[activebuildStep].label}<img src='/images/3dots.gif' className="height-20 uk-margin-small-left"/></div>
                    <div className="text-grey uk-text-unbold fs-16">
                        <span>{messages.build_percentage_message[0]}</span>
                        <span>{buildPercentage}</span>
                        <span>{messages.build_percentage_message[1]}</span>
                    </div>
                </>);

            case CURRENT_BUILD_STATUS.SUCCEEDED:
                return (<>
                    <div className="dialog-title">{buildSteps[activebuildStep].label}</div>
                    <div className="text-grey uk-text-unbold fs-16">
                        <span>{messages.build_success_header}</span>
                    </div>
                </>);


            case CURRENT_BUILD_STATUS.FAILED:
                return (<>
                    <div className="dialog-title">{messages.build_failed}</div>
                    <div className="text-grey uk-text-unbold fs-16">
                        <span>{messages.build_fail_header}</span>
                    </div>
                </>);
        }
    }

    const renderFooter = () => {
        switch (currBuildStatus) {
            case CURRENT_BUILD_STATUS.NOT_STARTED:
                return messages.changes_display_footer;

            case CURRENT_BUILD_STATUS.RUNNING:
                return messages.feel_free_footer;

                case CURRENT_BUILD_STATUS.FAILED:
                    return(<>
                        <span>{messages.build_fail_footer[0]}</span>
                        <span className="uk-text-decoration-underline uk-cursor-pointer uk-text-bold	" onClick={() => setOpenRestartBuildModal(true)}>{messages.build_fail_footer[1]}</span>
                        <span>{messages.build_fail_footer[2]}</span>
                    </>)

            case CURRENT_BUILD_STATUS.SUCCEEDED:
                return(<>
                    <span>{messages.build_success_footer[0]}</span>
                    <span className="uk-text-decoration-underline uk-cursor-pointer uk-text-bold	" onClick={() => setOpenRestartBuildModal(true)}>{messages.build_success_footer[1]}</span>
                    <span>{messages.build_success_footer[2]}</span>
                </>)
        }
    }

    const setLoaderPosition = () => {
        
        for (const [key] of Object.entries(lang.build_progress_jobs_new)) {
            let allSubSteps = lang.build_progress_jobs_new[key];
            if(allSubSteps.includes(lastSucceededJob)){
                let subStepsCount = allSubSteps.length;
                let succeededStepIndex = allSubSteps.indexOf(lastSucceededJob);
                let positionOfLoader = (succeededStepIndex + 1) / subStepsCount; // add 1 becaunse index start from 0
                positionOfLoaderRef.current = positionOfLoader == 1 ? 0 : positionOfLoader;
            }            
          }
    }

    return (
        <div className="large-gap-flex">
            <div className="small-gap-flex">
                {renderHeader()}
            </div>
            
            <div>
                <Stepper className="build-progress-bar" alternativeLabel activeStep={activebuildStep} connector={<QontoConnector />}>
                {buildSteps.map((step, index) => (
                    <Step key={step.id} id={"step-"+index}>
                        <StepLabel className={currBuildStatus !== CURRENT_BUILD_STATUS.RUNNING ? "text-grey" : "not-text-grey"} StepIconComponent = {(active) => (stepIcon(active, step.complete, step.numb))}>
                            {step.label}
                        </StepLabel>
                    </Step>
                ))}
                </Stepper>
            </div>

            <div className="text-light-blue uk-text-unbold fs-14">
                {renderFooter()}
            </div>
        </div>
        
    );
};

export default BuildProgressBar;
