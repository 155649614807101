var isNewDesign = false;
function createDropdown(oldClass) {
    var select = document.createElement("select");
    if (isNewDesign){
        select.classList.add('tabulator-dropdown');
    }else{
        for (let classes of oldClass) {
            select.classList.add(classes);
        }
    } 
    return select;
}

export {createDropdown};