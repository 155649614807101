import React from "react";
import LoginUnified from "./LoginUnified";
import "./styles/login.css";
import "./styles/popup.css";

const Login = () => {
  return (
      <LoginUnified /> 
  );
}

export default Login;