import {forwardRef, useImperativeHandle} from "react";
import Button from "../../../newComponents/Button";

const Card = (props,ref) => {

    const {title} = props;
    const {body,isMultipleCards,id,addButton,width, isExistingAssignments} = props;
    
    useImperativeHandle(ref, () => ({
        print: (param) => {
            return console.log(param);
        }
    }));
    let cardStyle = {};
    let cardBodyStyle = {};
    if(isExistingAssignments){
        cardStyle.height = "7vw";
        cardStyle.marginBottom = "1vw";
        cardStyle.gap = "0vw";
        cardStyle.padding  = "0.5vw";
        cardBodyStyle.minHeight = "6vw";
    }
    return (
        <div id={id} style ={cardStyle} className="card">
           <div style={{display:"flex",flexDirection:"row"}}>
                <div className="uk-text-bold fs-16"> {title} </div>
                {addButton?.addButtonCondition && 
                     <div style={{position: "absolute",left:(width- 166) + "px"}}>
                        <Button
                            label={addButton?.label}
                            variant={addButton?.variant}
                            size={addButton?.size}
                            type={addButton?.type}
                            rightIcon={addButton?.icon}
                            onBtnClick={()=>addButton?.onBtnClick()}
                            disabled={addButton?.disabled}
                            className={addButton?.className}
                        />
                    </div>
                }
           </div>
           <div style={cardBodyStyle} className={ !isMultipleCards ? 'card-body' : 'card-container'}> {body} </div> 
        </div>
    );
};

export default forwardRef(Card);
