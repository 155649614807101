import React, {useEffect, useRef, forwardRef, useImperativeHandle, useState} from 'react';
import {TabulatorFull} from "tabulator-tables";

import {getCostTooltipData, setLocalStorageValueByParameter, toggleLoader} from '../class/common.js';
import {
    ALL_WIDGETS,
    API_URL,
    BUTTON_TYPE,
    BUTTON_VARIANT,
    COST_FUNCTIONS_FIELDS,
    DIALOG_SIZE,
    PS_MAPPING,
    SIZES,
    VECTOR_ANALYSIS,
    costtype
} from '../class/constants.js';
import {convertPxToViewport} from '../class/formatting';
import {FETCHAPI_PARAMS, FETCH_METHOD, fetchAPI} from '../class/networkUtils.js';
import {exportToExcel, tabulatorExport} from '../class/tabulatorExport.js';
import {capitalizeFirstLetter} from '../class/utils.js';
import {customFilterEditor, getExpandCollapseButtons} from '../newComponents/tabulatorComponents';
import { ColorLabel } from '../ColorLabel.js';
import Button from '../newComponents/Button.js';
import Modal from '../newComponents/Modal.js';
import { lang } from '../language/messages_en.js';
import { linearizeHierarchy } from '../class/array.js';
const $ = require("jquery");

const baseUrl = process.env.REACT_APP_BASE_URL;
const _children = PS_MAPPING.FIELDS.CHILDREN;
const _hidden = " (Hidden)";

const ModelCoherenceTable = (props, ref) => {
    const [data, setData]  = useState([]);
    const [openMoreDetailsDialog, setOpenMoreDetailsDialog]  = useState(false);
    const [selectedRow, setSelectedRow]  = useState({});
    const headerValue = useRef("")
    const isExpanded = useRef(false)

    useImperativeHandle(ref, () => ({
        expandAll: () => {
            return expandAll();
        },
        collapseAll: () => {
            return collapseAll();
        },
        getModelCoherenceData: (scenario) => {
            return getModelCoherenceData(scenario);
        },
        exportTableToExcel: (options, allRows) => {
            return exportTableToExcel(options, allRows);
        },
    }));

    const tabulatorRef = useRef(null);

    useEffect(() => {
        const options = {
            layout: "fitColumns",
            responsiveLayout: false,
            addRowPos: "top",
            history: true,
            pagination: false,
            movableColumns: false,
            autoResize: false,
            resizableColumns: false,
            resizableRows: false,
            selectable: false,
            dataTree: true,
            dataTreeChildField: _children,
            dataTreeStartExpanded: function (row){
                if (headerValue.current !== "" && (hasMatchingChild(row.getData(), headerValue.current))) {
                    return true;
                } else {
                    return isExpanded.current;
                }
            },
            dataTreeRowExpanded: function (row, level) {
                row.getData().expanded = true;
            },
            dataTreeRowCollapsed: function (row, level) {
                row.getData().expanded = false;
            },
            dataTreeElementColumn: PS_MAPPING.FIELDS.NAME,
            dataTreeCollapseElement: "<button id='expanded_tabulator_arrow'></button>",
            dataTreeExpandElement: "<button id='collapsed_tabulator_arrow'></button>",
            dataTreeChildIndent: 15,
            dataTreeBranchElement: false,
            invalidOptionWarnings: false,
            reactiveData: true,
            virtualDomBuffer: 9000,
            columnHeaderVertAlign: "bottom",
            placeholder: "No data available",
            height: "100%",
            width: "100%",
            renderComplete: () => {
            },
            accessorDownload: (dataObj) => {
                const data = linearize(dataObj.data, [], "children");
                dataObj.data = data;
                return tabulatorExport(dataObj, tabulatorRef.current);
            },
            downloadReady: (fileContents, blob) => {
                toggleLoader(false, "tablesToExcel");
                return blob;
            }
        };
        tabulatorRef.current = new TabulatorFull("#modelCoherenceTable", options);
        return () => {
            tabulatorRef.current?.destroy();
        };
    }, []);


    const getColumnFormatter = (colField) => {
        let columnFormatter = "";
        switch (colField) {
            case PS_MAPPING.FIELDS.NAME:
                columnFormatter = function (cell, formatterParams) {
                    var rowData = cell.getRow().getData();
                    var p = document.createElement("p");
                    var name = cell.getValue();
                    // p.textContent = (!cell.getRow().getData()[_children] || cell.getRow().getData()[_children].length === 0) && (!cell.getRow().getData()[COST_FUNCTIONS_FIELDS.SHOW_CHILDREN] && cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_TYPE] !== costtype.calculated && cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_TYPE] !== costtype.attribute) && rowData['level'] !== 1 ? cell.getValue() + _hidden : cell.getValue();
                    p.title = (!cell.getRow().getData()[_children] || cell.getRow().getData()[_children].length === 0) && (!cell.getRow().getData()[COST_FUNCTIONS_FIELDS.SHOW_CHILDREN] && cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_TYPE] !== costtype.calculated && cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_TYPE] !== costtype.attribute) && rowData['level'] !== 1 ? cell.getValue() + _hidden : cell.getValue();
                    if (cell.getRow().getData()["level"] !== 1) { // moving each child level to the right to appear as a child of its parent
                        var pixels = (cell.getRow().getData()["level"] - 1) * 20;
                        $(p).css("padding-left", convertPxToViewport(pixels));
                    }
                    if (rowData['level'] === 1) {
                        $(cell.getRow().getElement()).css({"background-color": "#f3f3f3"});
                        $(cell.getRow().getElement()).css({"border-color": "#DCDCDC"});
                    } else if (rowData["level"] === 2) {
                        $(cell.getRow().getElement()).css({"background-color": "rgba(202, 202, 202, 0.5)"});
                        $(cell.getRow().getElement()).css({"border-color": "#c6c6c6"});
                    } else {
                        $(cell.getRow().getElement()).css({"background-color": "rgb(202, 202, 202, 0.8)"});
                        $(cell.getRow().getElement()).css({"border-color": "#cacaca"});
                    }
                    if (headerValue.current !== "" && name.toLowerCase().includes(headerValue.current)) { // Convert name to lowercase for case-insensitive comparison
                        var index = name.toLowerCase().indexOf(headerValue.current); // Convert name to lowercase for case-insensitive comparison
                        var highlightedName = name.substring(0, index) +
                            '<span class="highlight_search_result">' + name.substring(index, index + headerValue.current.length) + '</span>' +
                            name.substring(index + headerValue.current.length);
                        p.innerHTML = highlightedName;
                    } else {
                        p.innerHTML = name;
                    }
                    if(props.costFunctionsData.costClassification && props.costFunctionsData.costClassification.filter(e => e.returnname === rowData[PS_MAPPING.FIELDS.RETURN_NAME]).length > 0) {
                        p.classList.add("psl-title", "uk-cursor-pointer");
                        $(p).on("click", () => setMoreDetailsDialogOpen(true, rowData));
                    }
                    return p;
                }
                break;
            case "accuracy":
                columnFormatter = function (cell) {
                    var value = cell.getValue();
                    let label = new ColorLabel();
                    let labelObj = label.getLabelObject(value);
                    label.value = value;
                    let highlighted = "";
                    if (headerValue.current !== "" && value.toLowerCase().includes(headerValue.current)) { // Convert name to lowercase for case-insensitive comparison
                        var index = value.toLowerCase().indexOf(headerValue.current); // Convert name to lowercase for case-insensitive comparison
                        var highlightedName = value.substring(0, index) +
                            '<span class="highlight_search_result-label" style="background-color:'+labelObj?.highLightColor+'">' + value.substring(index, index + headerValue.current.length) + '</span>' +
                            value.substring(index + headerValue.current.length);
                            highlighted = highlightedName;
                    } else{
                        highlighted = labelObj?.label
                    }
                    var html = "<div class='segment' style='background-color: ";
                    html += labelObj?.color;
                    html += "; color: " + labelObj?.textColor +"'"
                    html += " > <span >"+highlighted+"</span> </div>";
                    return html;
                    }
                break;
            case "accuracy_percentage":
                columnFormatter = function (cell) {
                    return cell.getValue();
                }
                break;
        }

        return columnFormatter;
    }

    const exportTableToExcel = (exportOpts) => {
        const data = linearize(tabulatorRef.current.getData(), [], "children");
        const fileName = capitalizeFirstLetter(props.reportTitle);
        const sheetName = fileName.length > 31 ? fileName.substring(0, 28) + "..." : fileName;

        exportOpts.data = data;
        exportOpts.fileName = fileName;
        exportOpts.sheets = {[sheetName]: true};
        exportOpts.isProfitStack = false;

        exportToExcel(exportOpts, tabulatorRef.current);
        trackingExportExcelClick();
    };

    const trackingExportExcelClick = () => {
        fetchAPI({
            [FETCHAPI_PARAMS.funcName]: "trackingExportExcelClick",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: {action: "trackingExportExcelClick"}
        });
    };

    const linearize = (arr, resultArr, childrenField) => {
        arr.forEach(row => {
            row[VECTOR_ANALYSIS.FIELDS.NAME] = appendExportUnderScore(row[VECTOR_ANALYSIS.FIELDS.NAME], row.level);
            resultArr.push(row);
            if (row[childrenField] && row[childrenField].length > 0 && row.expanded) {
                resultArr = linearize(row[childrenField], resultArr, childrenField);
            }
        });
        return resultArr;
    };

    const appendExportUnderScore = (name, level) => {
        return "_".repeat(level) + name;
    };

    const setColumnsForTable = (columns, data) => {
        const formattedColumns = columns.map(col => {
            const updatedCol = {
                ...col,
                formatter: getColumnFormatter(col.field),
                headerSort: false,
                cssClass: "no-cursor",
                minWidth: 120,
            };
            if (col.field === PS_MAPPING.FIELDS.NAME) {
                updatedCol.headerFilter = customFilterEditor;
                updatedCol.headerFilterParams = {
                    func: (headerValue) => { callSearch(headerValue); },
                    getResultCount: getSearchResultCount,
                    collapseOnClearSearch: collapseAll,
                };
                updatedCol.headerFilterFunc = () => true;
            }
            return updatedCol;
        });

        tabulatorRef.current.setColumns(formattedColumns);
        tabulatorRef.current.setData(data);
    };

    const getModelCoherenceData = (scenarioId) => {
        const query = {
            action: "getModelCoherenceData",
            scenario_id: scenarioId,
            accessGroupSection: ALL_WIDGETS.FIELDS.MODEL_COHERENCE
        };
        setLocalStorageValueByParameter(window.location.host + "_lastRequestSentTime", new Date());

        let onThenCallback = (data) => {
            if (data.data?.length > 0) {
                setColumnsForTable(data.columns, data.data);
                setData(data.data);
                props.setChartData(data?.overallCoherence);
            } else {
                tabulatorRef.current.replaceData([]);
            }
        }

        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getModelCoherenceData",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.email]: props.user_email,
            [FETCHAPI_PARAMS.machine_name]: props.machine_name,
            [FETCHAPI_PARAMS.profitFormat]: ALL_WIDGETS.FIELDS.MODEL_COHERENCE,
            [FETCHAPI_PARAMS.scenarioId]: props.scenario,
            [FETCHAPI_PARAMS.requestDescription]: lang.observability.output.configurations.get_model_coherence_data,
            [FETCHAPI_PARAMS.screenName]: props.report_name,
        }
        fetchAPI(fetchOptions);
    };

    const callSearch = (headerValue) => {
        let rows = tabulatorRef.current.getRows();
        searchInNestedRows(rows, headerValue);
    }

    const searchInNestedRows = (dataRows, headerValueVal) => {
        headerValue.current = headerValueVal;
        tabulatorRef.current.replaceData(tabulatorRef.current.getData());
    }

    const getSearchResultCount = () => {
        let dataState = tabulatorRef.current.getData();
        let linearizedData = linearizeHierarchy(dataState, _children);
        let resultFound = linearizedData.filter(f=>f.name.toLowerCase().includes(headerValue.current.toLowerCase().trim()) || f.accuracy.toLowerCase().includes(headerValue.current.toLowerCase().trim()));
        return resultFound.length;

    }

    const hasMatchingChild = (row, headerValue, fromChildren) => {
        if ((row.accuracy.toLowerCase().includes(headerValue.toLowerCase().trim()) || row.name.toLowerCase().includes(headerValue.toLowerCase().trim())) && fromChildren) {
            return true;
        }
        let children = row.children;
        if (children) {
            for (var e in children) {
                if (hasMatchingChild(children[e], headerValue, true)) {
                    return true;
                }
            }
        }
    }

    /**
     * function calls @function expandCollapseAllTabData, changes the state of isExpanded and replaces data in tabulator
     */
    const collapseAll=(fromSearch)=> {
        let dataState = expandCollapseAllTabData(tabulatorRef.current.getData(), false);
        isExpanded.current = false;
        tabulatorRef.current.replaceData(dataState);
        if (fromSearch) {
            props.callCollapse();
        }
    }

    /**
     * function applies a recursive loop that modifies the expanded flag and sets it equal to the valus of isExpanded sent as a parameter
     */
    const expandCollapseAllTabData=(dataState, isExpanded)=>{
        dataState.forEach((item) => {
            item.expanded = isExpanded;
            if (item.children && item.children.length > 0 && isExpanded) {
              item.children = expandCollapseAllTabData(item.children, isExpanded);
            }
        });
        return dataState;
    }

    /**
     * function calls @function expandCollapseAllTabData, changes the state of isExpanded and replaces data in tabulator
     */
    const expandAll=()=>{
        let dataState = expandCollapseAllTabData(tabulatorRef.current.getData(), true);
        isExpanded.current = true;
        tabulatorRef.current.replaceData(dataState);
    }

    const moreDetailsDialogContent = () => {
        let rowData = selectedRow;

        if(!rowData) { return; }

        return getCostTooltipData(props.costFunctionsData.costClassification,
                props.costFunctionsData.glCosts,props.costFunctionsData.client_costcenter,
                rowData, props.periodsList,
                props.vectorOptions,
                props.costFunctionsData.metricFields,
                props.costFunctionsData.hiddenVectors, true,true, true
            );
    }

    const moreDetailsDialogActions = () => {
        return (
            <Button
                label={"Close"}
                variant={BUTTON_VARIANT.SECONDARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                onBtnClick={() => setMoreDetailsDialogOpen(false, undefined)}
            />
        )
    }

    const setMoreDetailsDialogOpen = (isOpen, row) => {
        setOpenMoreDetailsDialog(isOpen);
        setSelectedRow(row);
    }

    return (
        <div className="model_coherence_table_container">
            <div id="modelCoherenceTable"/>
            <Modal
                id={"stacks-more-details-dialog"}
                title={lang.psl_tooltip.profit_stack_line + (selectedRow?.name)}
                openDialog={openMoreDetailsDialog}
                bodyContent={moreDetailsDialogContent}
                dialogActions={moreDetailsDialogActions}
                closeClick={() => setMoreDetailsDialogOpen(false, undefined)}
                size={DIALOG_SIZE.XLARGE}
            />
        </div>
    );
};

export default forwardRef(ModelCoherenceTable);
