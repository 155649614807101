import { prepareLogo } from "../class/jqueries";
import { useMemo } from "react";

const HeaderLogo = React.memo((props) => {

  const returnLogo = (clientUrl) => {
    return clientUrl ? "/images/client/" + prepareLogo(window.location.host, clientUrl) + ".png" : "";
  }
  const imgUrl = useMemo(() => returnLogo(props.clientUrl), [props.clientUrl]);
  return (
    <div key="logo-1">
      <img
        src={props.clientLogo}
        className="navigation-header-logo"
        alt="logo"
      ></img>
    </div>
  );
});

export default HeaderLogo;
