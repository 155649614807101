/**
 * this file should contain all the common requests that relates to dataModeling screens
 */

import {
    ALL_WIDGETS,
    API_URL,
    FISCAL_YEARS,
    Formats,
    MENU_ITEM,
    PS_MAPPING,
    RAW_ITEMS,
    SCENARIOS,
    SCENARIO_TYPE,
    SIDE_PANEL_BUILD,
    UPLOAD_SECTIONS,
    PERIOD,
    BUILD_STATUS, BUILD_SCENARIO_ID, IS_BUILD_RUNNING, SCENARIO_STATUS,
    FISCAL_YEAR
} from "../../class/constants";
import { lang } from "../../language/messages_en";

import { FETCHAPI_PARAMS, FETCH_METHOD, accessNestedProperty, fetchAPI } from "../../class/networkUtils";
import {logout} from "./api";
import { convertFirstLetterToUpperCase, findOptionByKey, getSectionExists, parseBoolean, convertLocalToEasternTZ, copyObjectValues } from "../../class/utils";
import cookie from "react-cookies";
import { setLocalStorageValueByParameter } from "../../class/common";

const MANAGE_SCENARIOS = ALL_WIDGETS.FIELDS.MANAGE_SCENARIOS;
const COMPARE_SCENARIOS = ALL_WIDGETS.FIELDS.COMPARE_SCENARIOS;
const getEngineScenarios = (userAllowedMenuLinks, reportTitle, engineScenarioState, callback, isBuild, setEngineScenarioState, showLoader=true,history,section) => {
    // const _this = this;
    let query = {
        action: "getScenarios",
        engine: true,
    }
    reportTitle = reportTitle?.toLowerCase().replace(/ /g,'_');
    let isManageScenarios = reportTitle === MANAGE_SCENARIOS;
    let isCompareScenarios = reportTitle === COMPARE_SCENARIOS;

    //upload section has a special section_retun_name, it doesnt comply with this condition
    query.accessGroupSection = reportTitle === ALL_WIDGETS.UPLOAD ? "" : reportTitle;
    let isReviewallowed = userAllowedMenuLinks ? getSectionExists(userAllowedMenuLinks, MENU_ITEM.FIELDS.REVIEW_SCENARIOS) : false;
    let isBudgetingAllowed = userAllowedMenuLinks ? getSectionExists(userAllowedMenuLinks, ALL_WIDGETS.BUDGETING) : false;
    var onThenCallback = (data)=>{
        if(data && data.scenarios && data.scenarios.length > 0){
            var scenarios = {};
            var scenarioList = [];
            var defaultScenario = "";
            var tempState = {};
            data.scenarios.map(function(item){
                if(defaultScenario === "" && item[SCENARIOS.F.STATUS] === lang.SCENARIOS.STATUS.PUBLISHED) {
                    defaultScenario = {
                        value:item[SCENARIOS.F.ID],
                        label:item[SCENARIOS.F.NUMBER]+"-"+item[SCENARIOS.F.STATUS],
                        [SCENARIOS.F.ID]: item[SCENARIOS.F.ID],
                        [SCENARIOS.F.NUMBER]: item[SCENARIOS.F.NUMBER],
                        [SCENARIOS.F.STATUS]: item[SCENARIOS.F.STATUS],
                        [SCENARIOS.F.NAME]: item[SCENARIOS.F.NAME],
                        [SCENARIOS.F.TYPE]: item[SCENARIOS.F.TYPE]
                    };
                    tempState.scenarioStatus = defaultScenario[SCENARIOS.F.STATUS];
                }
                
                if(item[SCENARIOS.F.STATUS] === lang.SCENARIOS.STATUS.SANDBOX) {
                    defaultScenario = {
                        value:item[SCENARIOS.F.ID],
                        label:item[SCENARIOS.F.NUMBER]+"-"+item[SCENARIOS.F.STATUS],
                        [SCENARIOS.F.ID]: item[SCENARIOS.F.ID],
                        [SCENARIOS.F.NUMBER]: item[SCENARIOS.F.NUMBER],
                        [SCENARIOS.F.STATUS]: item[SCENARIOS.F.STATUS],
                        [SCENARIOS.F.NAME]: item[SCENARIOS.F.NAME],
                        [SCENARIOS.F.TYPE]: item[SCENARIOS.F.TYPE]
                    };
                    tempState.scenarioStatus =  defaultScenario[SCENARIOS.F.STATUS]
                }

                if(defaultScenario === "" && item[SCENARIOS.F.STATUS] === lang.SCENARIOS.STATUS.REVIEW) {
                    defaultScenario = {
                        value:item[SCENARIOS.F.ID],
                        label:item[SCENARIOS.F.NUMBER]+"-"+item[SCENARIOS.F.STATUS],
                        [SCENARIOS.F.ID]: item[SCENARIOS.F.ID],
                        [SCENARIOS.F.NUMBER]: item[SCENARIOS.F.NUMBER],
                        [SCENARIOS.F.STATUS]: item[SCENARIOS.F.STATUS],
                        [SCENARIOS.F.NAME]: item[SCENARIOS.F.NAME],
                        [SCENARIOS.F.TYPE]: item[SCENARIOS.F.TYPE]
                    };
                    tempState.scenarioStatus =  defaultScenario[SCENARIOS.F.STATUS]
                }

                if (isBuild) {
                    defaultScenario = engineScenarioState?.scenarioObjects[0];
                    tempState.scenarioStatus = engineScenarioState?.scenarioObjects[0];
                }
                scenarios[item[SCENARIOS.F.ID]]={scenario_status: item[SCENARIOS.F.STATUS]};
                if (!isReviewallowed) {
                    if(item[SCENARIOS.F.STATUS].toUpperCase() === SCENARIO_STATUS.PUBLISHED) {
                        scenarioList.push({
                            value:item[SCENARIOS.F.ID],
                            label:item[SCENARIOS.F.NUMBER]+"-"+item[SCENARIOS.F.STATUS]+"-"+item[SCENARIOS.F.NAME],
                            [SCENARIOS.F.ID]: item[SCENARIOS.F.ID],
                            [SCENARIOS.F.NUMBER]: item[SCENARIOS.F.NUMBER],
                            [SCENARIOS.F.STATUS]: item[SCENARIOS.F.STATUS],
                            [SCENARIOS.F.NAME]: item[SCENARIOS.F.NAME],
                            [SCENARIOS.F.TYPE]: item[SCENARIOS.F.TYPE]
                        });
                    }
                }else{
                    scenarioList.push({
                        value:item[SCENARIOS.F.ID],
                        label:item[SCENARIOS.F.NUMBER]+"-"+item[SCENARIOS.F.STATUS]+"-"+item[SCENARIOS.F.NAME],
                        [SCENARIOS.F.ID]: item[SCENARIOS.F.ID],
                        [SCENARIOS.F.NUMBER]: item[SCENARIOS.F.NUMBER],
                        [SCENARIOS.F.STATUS]: item[SCENARIOS.F.STATUS],
                        [SCENARIOS.F.NAME]: item[SCENARIOS.F.NAME],
                        [SCENARIOS.F.TYPE]: item[SCENARIOS.F.TYPE]
                    });
                }
            });
            if(isCompareScenarios){
              scenarioList = scenarioList.filter(sc=>sc.scenario_status !== SCENARIO_STATUS.SANDBOX);
            }
            if(!isBudgetingAllowed){
                scenarioList = scenarioList.filter(sc=>sc.scenario_type !== SCENARIO_TYPE.BUDGETING || sc.scenario_status == SCENARIO_STATUS.SANDBOX);
            }
            
            tempState.scenarioList = scenarioList;
            if(!isManageScenarios) {
                tempState.scenario = engineScenarioState?.scenario || defaultScenario;
                tempState.scenarios = [tempState.scenario];
                tempState.scenarioObjects = [findOptionByKey(scenarioList, tempState.scenario)];
                if(!isCompareScenarios){
                    tempState.nextScenarios = tempState.scenarios;
                }
                tempState.nextScenarioObjects = tempState.scenarioObjects;
            }
            setEngineScenarioState(tempState);
            // _this.setState(tempState, function () {
            //     if(callback && typeof callback === 'function') {
            //         callback();
            //     }
            // });
            if (isBuild) { // condition added for when sending a scenario for review is set as the selected scenario in drop down
                defaultScenario =  tempState.scenarioObjects[0];
            }
            // let cookieFromConfigure = readCookie(SCENARIO_ID_COOKIE_NAME);  //read cookie saved in Configure
            // if(cookieFromConfigure) {
            //     removeCookie(SCENARIO_ID_COOKIE_NAME); //delete it if it was defined
            //     _this.handleElementChange(HEADER_ELEMENT.SCENARIO, {value: Number(cookieFromConfigure)}, true);
            // } else if(!isManageScenarios && !isCompareScenarios){
            //     _this.handleElementChange(HEADER_ELEMENT.SCENARIO, defaultScenario);
            // }
        } else {
            setEngineScenarioState([]);
            // _this.setState(tempState, function () {
            //     if(callback && typeof callback === 'function') {
            //         callback();
            //     }
            // });
        }
        if (callback && typeof callback === 'function') {
            callback();
        }
    };
    
    var fetchOptions = {
        [FETCHAPI_PARAMS.funcName]: "getScenarios",
        [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.config,
        [FETCHAPI_PARAMS.showLoader]: showLoader,
        [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
        [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
        [FETCHAPI_PARAMS.query]: query,
        [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
        [FETCHAPI_PARAMS.useStore]: false,
        [FETCHAPI_PARAMS.dataKey]: "scenarios",
        [FETCHAPI_PARAMS.screenName]:accessNestedProperty(section,lang.observability)?.screen_name,
        [FETCHAPI_PARAMS.requestDescription]:accessNestedProperty(section,lang.observability)?.requests_description?.get_scenarios
    }

    fetchAPI(fetchOptions);
}
const trackBuildProgressOnFirstLoad=(userSettings, cb, trackBuildInterval, launchAppToast)=>{
  if(!userSettings.sandboxScenarioId) {
      return;
  }
  let count = 0;

    let doneRequests = 0;
    let totalRequests = 0;
    let runningRequests = "";
    let query = {
        action: "trackBuildProgress",
        scenario_id: userSettings.sandboxScenarioId,
    }
    sessionStorage.setItem(BUILD_SCENARIO_ID, userSettings.sandboxScenarioId);

    let onThenCallback = (data) => {
        let jobs = convertFirstLetterToUpperCase(data.RunningJobs.toString().replace("_"," ").toLowerCase());
        if (lang.build_progress_jobs.assigning_costs.includes(jobs)) {
          jobs = SIDE_PANEL_BUILD.ACTIONS.ASSIGNING_COST;
        } else if (jobs === lang.build_progress_jobs.generating_segments){
          jobs = SIDE_PANEL_BUILD.ACTIONS.GENERATING_SEGMENTS;
        }
        doneRequests = data.SucceededJobs?data.SucceededJobs.length:0;
        totalRequests = data.TotalJobs;
        runningRequests = jobs;
        let status = data.status;
        let percentage = 100*(doneRequests/totalRequests);
        if(data.isEngineRunRunning && status !== "failed") {
          if(jobs !== ''){
            sessionStorage.setItem("runningRequests",runningRequests)
          }
          if(status !=="canceling"){
              sessionStorage.setItem("buildPerc",percentage)
          }
          if(status === "running" && percentage !== undefined){
              sessionStorage.setItem(IS_BUILD_RUNNING,true)
          }
        } else {
          sessionStorage.removeItem("runningRequests");
          sessionStorage.removeItem("buildPerc");
          sessionStorage.removeItem(IS_BUILD_RUNNING);
        }
          cb(trackBuildInterval, launchAppToast);
      }

    let fetchOptions = {
        [FETCHAPI_PARAMS.funcName]: "trackBuildProgress",
        [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
        [FETCHAPI_PARAMS.showLoader]: false,
        [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
        [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
        [FETCHAPI_PARAMS.query]: query,
        [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
      };
    count++;
    fetchAPI(fetchOptions);
}

  const getAllFileTypeColumns = (scenarioType, engineScenarioState, setAllFileTypeColumns,section) => {
    let query = {
        action: "getAllFileTypeColumns",
        scenario_id: engineScenarioState?.scenario?.value,
        fileType: JSON.stringify([UPLOAD_SECTIONS.FIELDS.MASTER_DATA, UPLOAD_SECTIONS.FIELDS.TRANSACTION_DATA, UPLOAD_SECTIONS.FIELDS.ANCILLARY_FILES, UPLOAD_SECTIONS.FIELDS.GENERAL_LEDGER,UPLOAD_SECTIONS.FIELDS.BUDGETING_DATA])
    }

    var onThenCallback = (data)=>{
        data = data.data;
        let datacolumns = data.filter(e=>e[RAW_ITEMS.TYPE_NAME] !== UPLOAD_SECTIONS.FIELDS.ANCILLARY_FILES 
            && e[RAW_ITEMS.TYPE_NAME] !== UPLOAD_SECTIONS.FIELDS.GENERAL_LEDGER 
            && e[RAW_ITEMS.TYPE_NAME] !== UPLOAD_SECTIONS.FIELDS.BUDGETING_DATA);
        let allAncillaryColumns = data.filter(e=>e[RAW_ITEMS.TYPE_NAME] === UPLOAD_SECTIONS.FIELDS.ANCILLARY_FILES);
        let allNumericTransactionColumns = data.filter(e=>e[RAW_ITEMS.TYPE_NAME] === UPLOAD_SECTIONS.FIELDS.TRANSACTION_DATA && e[RAW_ITEMS.FIELD_DATA_TYPE] === Formats.Numeric && e[RAW_ITEMS.NAME] !== "");
        let allActualGLColumnFilter = [];
        let allBudgetingColumns = data.filter(e=>e[RAW_ITEMS.TYPE_NAME] === UPLOAD_SECTIONS.FIELDS.BUDGETING_DATA);
        let allActualGLColumns =  data.filter(e=> e.sub_category_module === SCENARIO_TYPE.NORMAL && e[RAW_ITEMS.TYPE_NAME] === UPLOAD_SECTIONS.FIELDS.GENERAL_LEDGER)
        for (var e in allActualGLColumns) {
            allActualGLColumnFilter.push({[RAW_ITEMS.TYPE_NAME]: allActualGLColumns[e][RAW_ITEMS.TYPE_NAME], label:allActualGLColumns[e][RAW_ITEMS.SUBTYPE_DISPLAY_NAME], value:allActualGLColumns[e][RAW_ITEMS.SUBTYPE_NAME], [RAW_ITEMS.SUBTYPE_ID]:allActualGLColumns[e][RAW_ITEMS.SUBTYPE_ID]})
        }
        let glSubtypes = [...new Map(allActualGLColumnFilter.map(item =>
            [item[RAW_ITEMS.SUBTYPE_ID], item])).values()];
        let allTypeColumns = data;
        if(scenarioType && scenarioType === SCENARIO_TYPE.NORMAL){
            allTypeColumns = allTypeColumns.filter(e=> e.data_file_type !== UPLOAD_SECTIONS.FIELDS.GENERAL_LEDGER || e.sub_category_module === SCENARIO_TYPE.NORMAL);
        }
        let tempState = {};
        tempState.dataColumns = datacolumns;
        tempState.allAncillaryColumns = allAncillaryColumns;
        tempState.allTypeColumns = allTypeColumns;
        tempState.allNumericTransactionColumns = allNumericTransactionColumns;
        tempState.allBudgetingColumns = allBudgetingColumns;
        tempState.allActualGLColumns = allActualGLColumns;
        tempState.glSubtypes = glSubtypes;
        setAllFileTypeColumns(tempState);
    }
    var fetchOptions = {
        [FETCHAPI_PARAMS.funcName]: "getAllFileTypeColumns",
        [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.config,
        [FETCHAPI_PARAMS.showLoader]: true,
        [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
        [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
        [FETCHAPI_PARAMS.query]: query,
        [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
        [FETCHAPI_PARAMS.useStore]: false,
        [FETCHAPI_PARAMS.dataKey]: "",
        [FETCHAPI_PARAMS.screenName]:accessNestedProperty(section,lang.observability)?.screen_name,
        [FETCHAPI_PARAMS.requestDescription]:accessNestedProperty(section,lang.observability)?.requests_description?.get_all_file_type_columns
    }  
    fetchAPI(fetchOptions);
}

const getPeriodsEngineStatus  = (scenarioState, setPeriodsStatusState, props, profitFormat, userSettings,section) => {
    if (!scenarioState || !scenarioState.scenario) return;
    var tempState = {};
    var query = {
        action: "getPeriodsStatus",
        scenario_id: scenarioState.scenario.value,
        isEngine: true,
    };
    var onThenCallback = (data) => {
        if (data.data) {
            var periods = [];
            var endDate = undefined;
            data.data.map(function (item) {
                periods.push({
                    value: item[PERIOD.PERIOD_NAME],
                    label: item[PERIOD.PERIOD_NAME],
                    start_date: item[PERIOD.START_DATE],
                    end_date: item[PERIOD.END_DATE],
                    period_id: item[PERIOD.PERIDO_ID],
                    count: item[PERIOD.COUNT],
                    is_built: item[PERIOD.IS_BUILT],
                    quarter: item.quarter,
                    generated: item.generated,
                });
                if (item[PERIOD.IS_BUILT] && !endDate) {
                    //last built period to be selected by default
                    endDate = new Date(item[PERIOD.END_DATE]);
                }
            });
            tempState.builtPeriods = periods;
            let nextCustomStartDateCookie = cookie.load("nextCustomStartDate");
            let nextCustomEndDateCookie = cookie.load("nextCustomEndDate");
            endDate = endDate ? endDate : convertLocalToEasternTZ(new Date(periods[0][PERIOD.END_DATE]));
            if (nextCustomStartDateCookie && nextCustomEndDateCookie) {
                tempState.customStartDate = new Date(nextCustomStartDateCookie.replaceAll('"', ""));
                tempState.customEndDate = new Date(nextCustomEndDateCookie.replaceAll('"', ""));
            } else {
                // let startDatePeriod =  convertLocalToEasternTZ(new Date(period[PERIOD.START_DATE]));
                let isFromDashoard = false; //this.props.history.location.state && this.props.history.location.state.inheritedPeriod
                let startDate = new Date(
                    endDate.getFullYear() + "-" + (endDate.getMonth() + 1 < 10 ? "0" : "") + (endDate.getMonth() + 1) + "-01T00:00"
                );
                tempState.customStartDate = startDate;
                tempState.customEndDate = new Date(
                    endDate.getFullYear() +
                    "-" +
                    (endDate.getMonth() + 1 < 10 ? "0" : "") +
                    (endDate.getMonth() + 1) +
                    "-" +
                    ((endDate.getDate() < 10 ? "0" : "") + endDate.getDate()) +
                    "T00:00"
                ); // Date format should be YYYY-MM-ddTHH:mm
            }

            tempState.nextCustomStartDate = tempState.customStartDate;
            tempState.nextCustomEndDate = tempState.customEndDate;
            tempState.actuallyBuiltPeriods = tempState.builtPeriods.filter((p) => p[PERIOD.IS_BUILT] === true);
            setPeriodsStatusState(tempState);
        }
    };

    var fetchOptions = {
        [FETCHAPI_PARAMS.funcName]: "getPeriodsStatus",
        [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.config,
        [FETCHAPI_PARAMS.showLoader]: true,
        [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
        [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
        [FETCHAPI_PARAMS.query]: query,
        [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
        // [FETCHAPI_PARAMS.onCompleteCallback]: onCompleteCallback,
        [FETCHAPI_PARAMS.email]: userSettings.user.email,
        [FETCHAPI_PARAMS.machine_name]: userSettings.machine_name,
        [FETCHAPI_PARAMS.profitFormat]: profitFormat,
        [FETCHAPI_PARAMS.logout]: logout,
        [FETCHAPI_PARAMS.screenName]:accessNestedProperty(section,lang.observability)?.screen_name,
        [FETCHAPI_PARAMS.requestDescription]:accessNestedProperty(section,lang.observability)?.requests_description?.get_periods_status
    };
    let comp = {props:props};
    fetchAPI(fetchOptions, comp);
};

  /**
   * @function getFiscalYears()
   * @description fetch fiscal years and select the first completed year
   */
  const getFiscalYears = (scenariosState, setFiscalYearsOptions, userSettings, profitFormat, callback,section) => {
    if (!scenariosState?.scenario) {
      // for new clients with no scenarios
      return;
    }
    var query = {
      action: "getFiscalYears",
      scenario_id: scenariosState.scenario?.value,
      isEngine: true,
    };

    var onThenCallback = (data) => {
      var allFiscalYears = [];
      if (data) {
        let selectedYearIndex = -1;
        data.fiscalYears.map(function (year, key) {
          if (parseBoolean(year[FISCAL_YEARS.is_complete]) && selectedYearIndex === -1) {
            selectedYearIndex = key;
          }

          allFiscalYears.push({
            value: year[FISCAL_YEARS.fiscal_year],
            label: year[FISCAL_YEARS.fiscal_year],
            [FISCAL_YEARS.fiscal_year]: year[FISCAL_YEARS.fiscal_year],
            [FISCAL_YEARS.all_built]: year[FISCAL_YEARS.all_built],
            [FISCAL_YEARS.is_complete]: year[FISCAL_YEARS.is_complete],
          });
        });

        setFiscalYearsOptions(allFiscalYears);
        if (allFiscalYears.length > 0 && typeof callback  === "function") {
          callback(allFiscalYears[selectedYearIndex]);
        }
      }
    };

    var fetchOptions = {
      [FETCHAPI_PARAMS.funcName]: "getFiscalYears",
      [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
      [FETCHAPI_PARAMS.showLoader]: true,
      [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
      [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
      [FETCHAPI_PARAMS.query]: query,
      [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
      [FETCHAPI_PARAMS.email]: userSettings.user.email,
      [FETCHAPI_PARAMS.machine_name]: userSettings.machine_name,
      [FETCHAPI_PARAMS.profitFormat]: profitFormat,
      [FETCHAPI_PARAMS.screenName]:accessNestedProperty(section,lang.observability)?.screen_name,
      [FETCHAPI_PARAMS.requestDescription]:accessNestedProperty(section,lang.observability)?.requests_description?.get_fiscal_years
      
    };

    fetchAPI(fetchOptions);
  };

  const getClientCostTerms = (setClientCostTerms, profitFormat,section) => {
    let query = {
      action: "getClientCostTerms",
    };

    var onThenCallback = (data) => {
      let emptyOption = [{ value: "Not Defined", label: "Not Defined", cost_term_id: "", psLabel: "" }];
      let options = data.data;
      options.map(function (item) {
        item.value = item.cost_term_value;
        item.label = item.cost_term_value;
        item.cost_term_id = item.cost_term_id.toString();
        item.psLabel = item.cost_term_value.charAt(0).toUpperCase();
      });

      setClientCostTerms(emptyOption.concat(options));
    };
    var fetchOptions = {
      [FETCHAPI_PARAMS.funcName]: "getClientCostTerms",
      [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
      [FETCHAPI_PARAMS.showLoader]: true,
      [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
      [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
      [FETCHAPI_PARAMS.query]: query,
      [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
      [FETCHAPI_PARAMS.profitFormat]: profitFormat,
      [FETCHAPI_PARAMS.screenName]:accessNestedProperty(section,lang.observability)?.screen_name,
      [FETCHAPI_PARAMS.requestDescription]:accessNestedProperty(section,lang.observability)?.requests_description?.get_client_cost_terms
    };
    fetchAPI(fetchOptions);
  };


  const fetchUsedAttributes = (scenarioId, setUsedAttrs, profitFormat,section) => {
    let query = {
      action: "fetchUsedAttributes",
      scenario_id: scenarioId,
    };

    var onThenCallback = (data) => {
      if (data.data) {
        var tempState = {};

        tempState.usedAttrs_calc = data.data.filter(
          (attr) =>
            attr[PS_MAPPING.FIELDS.ATTR_TYPE] === PS_MAPPING.ATTR_TYPE_VALUES.CALC ||
            attr[PS_MAPPING.FIELDS.ATTR_TYPE] === PS_MAPPING.ATTR_TYPE_VALUES.CALC + "_columns"
        );
        tempState.usedAttrs_vec = data.data.filter(
          (attr) =>
            attr[PS_MAPPING.FIELDS.ATTR_TYPE] === PS_MAPPING.ATTR_TYPE_VALUES.VEC ||
            attr[PS_MAPPING.FIELDS.ATTR_TYPE] === PS_MAPPING.ATTR_TYPE_VALUES.VEC + "s"
        );
        setUsedAttrs(tempState);
      }
    };

    var fetchOptions = {
      [FETCHAPI_PARAMS.funcName]: "fetchUsedAttributes",
      [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
      [FETCHAPI_PARAMS.showLoader]: true,
      [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
      [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
      [FETCHAPI_PARAMS.query]: query,
      [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
      [FETCHAPI_PARAMS.profitFormat]: profitFormat,
      [FETCHAPI_PARAMS.screenName]:accessNestedProperty(section,lang.observability)?.screen_name,
      [FETCHAPI_PARAMS.requestDescription]:accessNestedProperty(section,lang.observability)?.requests_description?.fetch_used_attributes,
    };
    fetchAPI(fetchOptions);
  };

// const troubleshootCostFunctionFromApi = (scenarioId, costCenter, costKey, timePeriod, updateData) => {
//     toggleLoader(true, 'troubleshootCostFunction');
//
//     var dataBody = {
//         action: "troubleshootCostFunction",
//         scenario_id: scenarioId,
//         costCenter: costCenter,
//         costKey: costKey,
//         timePeriod: timePeriod
//     };
//     setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
//     fetch(`${baseUrl}${path}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(dataBody)})
//         .then((response)=>{
//             if(response.status === 403) {
//                 logout();
//             }
//             return response.json();
//         }).then((data) => {
//         toggleLoader(false, 'troubleshootCostFunction');
//
//         if (data) {
//             updateData(costKey, timePeriod, data);
//         }
//     })
//         .catch((error)=>{
//             alertAndLogError(error);
//             toggleLoader(false, 'troubleshootCostFunction');
//         });
// }

const troubleshootCostFunctionFromApi = (scenarioId, costCenter, costKey, timePeriod, updateData) => {

    var query = {
        action: "troubleshootCostFunction",
        scenario_id: scenarioId,
        costCenter: costCenter,
        costKey: costKey,
        timePeriod: timePeriod
    };
    var onThenCallback = (data) => {
        if (data) {
            updateData(costKey, timePeriod, data);
        }
    };

    var fetchOptions = {
        [FETCHAPI_PARAMS.funcName]: "troubleshootCostFunction",
        [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
        [FETCHAPI_PARAMS.showLoader]: true,
        [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
        [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
        [FETCHAPI_PARAMS.query]: query,
        [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
    };
    fetchAPI(fetchOptions);
}

const trackBuildProgressInInterval = (trackBuildInterval, launchAppToast) => {
    let count = 0;

     trackBuildInterval.current = setInterval(() => {
        let doneRequests = 0;
        let totalRequests = 0;
        let query = {
            action: "trackBuildProgress",
            scenario_id: sessionStorage.getItem(BUILD_SCENARIO_ID)
        }

        let onThenCallback = (data) => {
            let jobs = convertFirstLetterToUpperCase(data.RunningJobs.toString().replace("_"," ").toLowerCase());
            if (lang.build_progress_jobs.assigning_costs.includes(jobs)) {
              jobs = SIDE_PANEL_BUILD.ACTIONS.ASSIGNING_COST;
            } else if (jobs === lang.build_progress_jobs.generating_segments){
              jobs = SIDE_PANEL_BUILD.ACTIONS.GENERATING_SEGMENTS;
            }
            doneRequests = data.SucceededJobs ? data.SucceededJobs.length : 0;
            totalRequests = data.TotalJobs;
            let status = data.status;
            let percentage = 100 * (doneRequests / totalRequests);
            doneRequests = data.SucceededJobs?data.SucceededJobs.length:0;
            totalRequests = data.TotalJobs;
            let runningRequests = jobs;
          
            if(jobs !== ''){
                sessionStorage.setItem("runningRequests",runningRequests)
            }
            if(status !=="canceling"){
                sessionStorage.setItem("buildPerc",percentage)
            }
            if(status === "running" && percentage !== undefined){
                sessionStorage.setItem(IS_BUILD_RUNNING,true)
            }
            if (count > 1000 || percentage === 100 || status === BUILD_STATUS.FAILED || status === BUILD_STATUS.SUCCEEDED || status === BUILD_STATUS.CANCELING || !parseBoolean(sessionStorage.getItem(IS_BUILD_RUNNING))) {
                if (status === BUILD_STATUS.SUCCEEDED && sessionStorage.getItem(IS_BUILD_RUNNING) !== null) {
                    launchAppToast(true);
                } else if (status === BUILD_STATUS.FAILED && sessionStorage.getItem(IS_BUILD_RUNNING) !== null) {
                    launchAppToast(false);
                }
                status = status === BUILD_STATUS.RUNNING && percentage === 100 ? BUILD_STATUS.SUCCEEDED : status;
                sessionStorage.removeItem(BUILD_SCENARIO_ID);
                sessionStorage.setItem(BUILD_STATUS.STATUS, status === BUILD_STATUS.RUNNING && percentage === 100 ? BUILD_STATUS.SUCCEEDED : status);
                sessionStorage.setItem(IS_BUILD_RUNNING,false)
                clearInterval(trackBuildInterval.current);
            }
        }

        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "trackBuildProgress",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
        };
        count++;
        fetchAPI(fetchOptions);
    }, 10000);
}

const getClientPeriods = (fromSave, switchAfter, setClientPeriodsState, profitFormat, params, userSettings, getPeriodState, callback, switchCallback) => {
  var tempState = {};
  var query = {
    action: "getClientPeriods",
  };

  var onThenCallback = (data) => {
    if (data) {
      var periods = [];
      var allPeriods = [];
      var periodList = [];
      var periodDetails = [];
      data.periods.map(function (item) {
        allPeriods.push({
          [FISCAL_YEAR.FIELDS.ACTIVE_STATUS]:
            item[FISCAL_YEAR.FIELDS.ACTIVE_STATUS],
          value: item["period_name"],
          label: item["period_name"],
          start_date: item["start_date"],
          end_date: item["end_date"],
          period_id: item["client_period_id"],
          count: item["count"],
          quarter: item["quarter"],
          year: item["year"],
        });
        if (
          item[FISCAL_YEAR.FIELDS.ACTIVE_STATUS] ===
          FISCAL_YEAR.PERIOD_STATUS_VALUES.ACTIVE
        ) {
          periods.push({
            [FISCAL_YEAR.FIELDS.ACTIVE_STATUS]:
              item[FISCAL_YEAR.FIELDS.ACTIVE_STATUS],
            value: item["period_name"],
            label: item["period_name"],
            start_date: item["start_date"],
            end_date: item["end_date"],
            period_id: item["client_period_id"],
            count: item["count"],
            quarter: item["quarter"],
          });
          periodList.push(item["period_name"]);
          periodDetails[item["period_name"]] = {
            start_date: item["start_date"],
            end_date: item["end_date"],
            [FISCAL_YEAR.FIELDS.ACTIVE_STATUS]:
              item[FISCAL_YEAR.FIELDS.ACTIVE_STATUS],
          };
        }
      });
      let periodsForUploadedFiles = periods
        ? periods.filter((e) => e["count"] > 0)
        : [];
      tempState.allPeriods = allPeriods;
      tempState.periodList = periodList;
      tempState.periods = periods; //this state holds the values of all periods regardless of their status
      tempState.selectedPeriod =
        params.report &&
          params.report.toLowerCase() === ALL_WIDGETS.FIELDS.STAGE
          ? periodsForUploadedFiles.length > 0
            ? periodsForUploadedFiles[0]
            : periods[0]
          : periods[0];
      if (
        params.report &&
        params.report.toLowerCase() === ALL_WIDGETS.FIELDS.CONFIGURE
      ) {
        tempState.selectedPeriod = sessionStorage.getItem("selectedPeriod")
          ? sessionStorage.getItem("selectedPeriod")
          : periods[0];
      }
      tempState.periodDetails = periodDetails;
      tempState.fiscalPeriods = data.periods;
      tempState.fiscalOriginalPeriods = copyObjectValues(data.periods);
      tempState.periodsForUploadedFiles = periodsForUploadedFiles;
      //for filling the dropdown, because dataModeling uses a different state
      tempState.periodOptions = periods;
      tempState.nextPeriod = tempState.selectedPeriod;
      if(getPeriodState && typeof getPeriodState === "function") {
        tempState.period = null;
      }
      
      if(fromSave && callback && typeof callback === 'function'){
          callback(tempState,switchAfter);
      }
      setClientPeriodsState(tempState);
      setLocalStorageValueByParameter("periods", JSON.stringify(periods));

      if(switchCallback && typeof switchCallback === 'function'){
        switchCallback()
      }
    }
  };

  var fetchOptions = {
    [FETCHAPI_PARAMS.funcName]: "getClientPeriods",
    [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
    [FETCHAPI_PARAMS.showLoader]: true,
    [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
    [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
    [FETCHAPI_PARAMS.query]: query,
    [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
    // [FETCHAPI_PARAMS.onCompleteCallback]: onCompleteCallback,
    [FETCHAPI_PARAMS.email]: userSettings.user.email,
    [FETCHAPI_PARAMS.machine_name]: userSettings.machine_name,
    [FETCHAPI_PARAMS.profitFormat]: profitFormat,
    [FETCHAPI_PARAMS.useSession]: false, // setting this to false so that the engine & output don't read the same values
    [FETCHAPI_PARAMS.logout]: logout,
  };
  fetchAPI(fetchOptions);
};

export {
    getAllFileTypeColumns,
    getEngineScenarios,
    getFiscalYears,
    getPeriodsEngineStatus,
    troubleshootCostFunctionFromApi,
    getClientCostTerms,
    fetchUsedAttributes,
    trackBuildProgressOnFirstLoad ,
    trackBuildProgressInInterval,
    getClientPeriods
};
  