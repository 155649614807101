import {API_URL, ALL_WIDGETS, PSS,  FISCAL_YEAR} from "../../class/constants.js";
import { getTranslationFile,  getSectionExists, copyObjectValues, tryParse } from '../../class/utils.js';
import { FETCH_METHOD, fetchAPI, FETCHAPI_PARAMS } from "../../class/networkUtils.js";

const lang = getTranslationFile();

var updateDataKeys = function(data, dataKeys, key){
    var currConfig = data.data_config[key]; 

    if(currConfig.hasChildren === true) {
        data.data_keys.forEach(function eachKey(k) { 
            var kConfig = data.data_config[k];
            if(kConfig.parentPslKey === currConfig.pslKey) {
                dataKeys.push(kConfig.returnName);
                if(kConfig.hasChildren === true) {
                    dataKeys = updateDataKeys(data, dataKeys, kConfig.returnName);
                }
            }
        });
    }
    return dataKeys;    
}

var loadCustomViewData = function (silent, callback) {
    silent = silent || false;
    if(Array.isArray(this.state.views_data)) {
        return;
    }
    var comp = this;
    var tempState = {};
    var params = {
        user_email: this.state.user.email,
        url: window.location.href,
        section_id: this.props.match.params.sectionId,
        action:"listProfitStackViews",
        filter:'{"filter":'+this.state.filterFinal+'}',
        vector:this.state.vector,
        scenario_id: this.state.scenario,
        allowed: this.state.profitFormat === _customViews ? false : true
    };
    let  scenario_id = this.state.scenario;
    var onThenCallback = (data) => {
        if(data.error){
            if(data.error.level === 3){
                data = {can_edit:false, data:[]};
            } else {
                UIKit.modal.alert("Could not load data: "+data.error.message);
                return;
            }
        }
        if (comp.props.dispatch) {
            comp.props.dispatch(updatePsViews([data],scenario_id));
        }
        tempState.views_data = data;
        tempState.views_data.id = shortid.generate();
        tempState.canEdit = data.can_edit;
        tempState.profitStackViews = [{value:0, label:'Default Profit Stack', tooltipText:'', is_valid: true}];

        let disabledViewMsg = null;
        if(!!comp.state.scenarioObjects && comp.state.scenarioObjects[0]) {
            disabledViewMsg = lang.disabled_custom_view;
        }

        for(var i = 0; i < tempState.views_data.data.length; i++){
            let isValid = parseBoolean(tempState.views_data.data[i][PROFIT_STACK_VIEW.IS_VALID]);
            tempState.profitStackViews.push({
                value: tempState.views_data.data[i]["custom_stack_id"],
                label: tempState.views_data.data[i][PROFIT_STACK_VIEW.NAME],
                tooltipText: isValid ? tempState.views_data.data[i][PROFIT_STACK_VIEW.DESCRIPTION] : disabledViewMsg,
                is_valid: isValid,
                isDisabled: !isValid,
                saved_filter_id: tempState.views_data.data[i][PROFIT_STACK_VIEW.FILTER_ID],
                visibility: tempState.views_data.data[i][MANAGE_STACKS.FIELDS.VISIBILITY]
            });
        }

        for(var i = 0; i < tempState.views_data.data.length; i++){
            if(tempState.views_data.data[i][PROFIT_STACK_VIEW.ID] === comp.state.current_view_id) {
                if(comp.props.setCurrentView) {
                    comp.props.setCurrentView(tempState.views_data.data[i], true);
                }
            }
        }
        // if we are changing the selected stack to the default one because the selected stack is not compatible with the selected scenario
        if(comp.state.nextProfitStackViewId !== 0 && tempState.profitStackViews.filter(e=>e.value === comp.state.nextProfitStackViewId).length === 0){
            tempState.nextProfitStackViewId = 0;
            tempState.shouldShowWarning = true;
        }else{
            tempState.shouldShowWarning = false;
        }
        comp.setState(tempState);

        if(typeof callback === "function"){
            callback();
        }
    };
    var onCompleteCallback = ()=>{};
    if(this.awaitOtherLists) {
        onCompleteCallback = () => {
            comp.awaitOtherLists("loadCustomViewData", tempState);
        }
    }

    var fetchOptions = {
        [FETCHAPI_PARAMS.funcName]: "loadCustomViewData",
        [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.config,
        [FETCHAPI_PARAMS.showLoader]: !silent,
        [FETCHAPI_PARAMS.path]: API_URL.MANAGE_STACKS,
        [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
        [FETCHAPI_PARAMS.query]: params,
        [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
        [FETCHAPI_PARAMS.onCompleteCallback]: onCompleteCallback,
        [FETCHAPI_PARAMS.useSession]: false,
        [FETCHAPI_PARAMS.useStore]: true,
        [FETCHAPI_PARAMS.dataKey]: "customViewData",
        [FETCHAPI_PARAMS.scenarioId]: scenario_id,

    }

    if(this) {
        if(!this.fetchAPI) {
            this.fetchAPI = fetchAPI.bind(this);
        }
        this.fetchAPI(fetchOptions);
    } else {
        fetchAPI(fetchOptions);
    }
}

var loadSecondaryReports = function (callback) {
    const _this = this;
    let tempState={};
    let reportsToAdd = [];
    if(_this.isVectorAnalysis || _this.isDashboards || (_this.isMainReport && _this.isMainReport.isDashboards)){
        if (_this.state.isBridgeAllowed) {
            reportsToAdd.push({label:ALL_WIDGETS.TITLES.PROFIT_LANDSCAPE.BRIDGE_REPORT, value:ALL_WIDGETS.BRIDGE_REPORT});
        }
    }

    tempState.secondaryReports = reportsToAdd;
    this.setState(tempState);
}

var loadBridgeOptions = function (callback) {
    const _this = this;
    let tempState={};
    let tooltipText = "";
    let query = {
        action: "getBridgeOptions",
        scenario_id: this.state.scenario
    }

    let onThenCallback = (data)=>{
        if(data){ 
            let bridgeData = data.reports;
            let config = data.quadrantConfig;
            let secondaryReports = copyObjectValues(_this.state.secondaryReports);
            // let bridgeObject = secondaryReports.filter(e=>e.value === ALL_WIDGETS.BRIDGE_REPORT)[0];
            let pss_fields = tryParse(data.pss_fields);
            let calculated_lines = pss_fields.filter(e=>e.costtype === 'calculated' && e.format_type_id !== "5");
            var bridgeOptions = [];
            bridgeData.map(function(item){
                bridgeOptions.push({label:item[PSS.NAME], value:item[PSS.RETURN_NAME], report_id: item.report_id, custom_view_id:item.custom_stack_id})
            });
            if(_this.isVectorAnalysis){
                tooltipText = lang.bridge_configurations.no_bridge_configurations;
            }
            if(bridgeOptions.length === 0){ //if no cofigurations are set then disable option
                // bridgeObject.isDisabled = true;
                // bridgeObject.tooltipText = tooltipText;
                tempState.secondaryReports = secondaryReports;
            } else {
                let newBridgeOptions = [];
                for(var i in bridgeOptions){
                    if(calculated_lines.filter(e=>e.returnName === bridgeOptions[i].value).length > 0){ //check if configured calculated lines are compatible with selected scenario
                        newBridgeOptions.push(bridgeOptions[i]); 
                    } 
                }
                if(newBridgeOptions.length > 0){
                    tempState.bridgeOptions = newBridgeOptions;
                    let bridgeObj = config.length > 0 && newBridgeOptions.length > 0 && newBridgeOptions.filter(e=>e.value === config[0][PSS.RETURN_NAME]).length > 0 ? 
                    newBridgeOptions.filter(e=>e.value === config[0][PSS.RETURN_NAME])[0] :newBridgeOptions.length > 0 ?  newBridgeOptions[0] : undefined;
                    tempState.bridge = bridgeObj.value;
                    tempState.nextBridgeObj = bridgeObj;
                    tempState.bridgeCustomViewId = bridgeObj.custom_view_id;
                    tempState.originalBridge = bridgeObj.value;
                    tempState.nextBridgeCustomViewId = bridgeObj.custom_view_id;
                    tempState.nextSecondaryReport = bridgeObj.report_id;
                    tempState.secondaryReport = bridgeObj.report_id;
                    tempState.nextBridge = tempState.bridge;
                    // bridgeObject.isDisabled = false;
                    // bridgeObject.tooltipText = "";
                    tempState.secondaryReports = secondaryReports;
                } else { //if nothing is compatible with selected scenario then disable option
                    // bridgeObject.isDisabled = true;
                    // bridgeObject.tooltipText = tooltipText;
                    tempState.secondaryReports = secondaryReports;
                }
            }
        }
    }

    let onCompleteCallback = ()=>{
        if(this.awaitOtherLists)
        _this.awaitOtherLists("loadBridgeOptions", tempState);
    }
    
    let fetchOptions = {
        [FETCHAPI_PARAMS.funcName]: "getBridgeOptions",
        [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
        [FETCHAPI_PARAMS.showLoader]: true,
        [FETCHAPI_PARAMS.path]: API_URL.PROFIT_VECTORS,
        [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
        [FETCHAPI_PARAMS.query]: query,
        [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
        [FETCHAPI_PARAMS.onCompleteCallback]: onCompleteCallback,
    }
    fetchAPI(fetchOptions);
    
}

/**
 * suggests end date from the star date
 * @param {*} startDate 
 * @returns 
 */
const suggestEndDate = (startDate) => {
    let date = new Date(startDate+"T23:00:00");
    date.setMonth(date.getMonth() + 1);
    date.setDate(date.getDate() - 1);
    let year =  date.getFullYear();
    let month = date.getMonth()+1 < 10 ? "0"+ (date.getMonth()+1) :  date.getMonth()+1;
    let day = date.getDate() < 10 ? "0"+ (date.getDate()) :  date.getDate();
    return year +"-"+month+"-"+day;
}

/**
 * suggests new period from previous period
 * @param {*} prevPeriod 
 * @returns 
 */
const suggestNewPeriod = (prevPeriod) => {
    let year = prevPeriod.split("P")[0];
    let period = prevPeriod.split("P")[1];
    if (period === "12" || period === "13") {
        year = Number(year) + 1;
        return year + FISCAL_YEAR.FIELDS.PERIODS_VALUES[0];
    } else {
        if ((Number(period)) >= 9) {
            return year + "P"+((Number(period))+1).toString();
        } else {
            return year + "P0"+((Number(period))+1).toString();
        }
    }
}


export { loadSecondaryReports, loadBridgeOptions,suggestEndDate, suggestNewPeriod, loadCustomViewData}