import React, { Component } from 'react';
import { CustomSelect } from '../../form/elements.js';
import { getTranslationFile, findOptionByKey, copyObjectValues, tryParse, findOptionByKeyValue, deepCompareObjects, parseBoolean, sortMappedLines } from '../../class/utils';
import { GLACCOUNTS_FIELDS, FormatTypes, PS_MAPPING, ROW_NUMBER, RAND, RAW_ITEMS, ROW_STATUS, ENGINE_FILTER, STAGING_SECTIONS, CALCULATED_COLUMNS, BUTTON_VARIANT, SIZES, BUTTON_TYPE, DROPDOWN_TYPE, NONE } from '../../class/constants';
import { addRowNumberToOptions, getObjectAsArray, arrangeAncillaryFiles } from '../../class/jqueries';
import EngineFilterModal from '../filter/EngineFilterModal';
import PSLExceptionMapping from './PSLExceptionMapping';
import { replaceSpecialChars } from '../../class/string';
import { getNumericValue, is_aN } from '../../class/number';
import { getHighestKey, findIndexOfValue } from '../../class/array';
import { formatValString } from '../../class/format';
import { convertPxToViewport } from '../../class/formatting.js'
import Button from '../../newComponents/Button.js';
import DropDown from '../../newComponents/DropDown.js';

const $ = require('jquery');
const lang = getTranslationFile();

const _id = PS_MAPPING.FIELDS.PSS_ID;
const _leadingID = PS_MAPPING.FIELDS.LEADING_PSS_ID;
const _costkey = PS_MAPPING.FIELDS.COST_KEY;
const _leadingCostkey = PS_MAPPING.FIELDS.LEADING_COSTKEY;
const _pslName = PS_MAPPING.EXCEPTION_FIELDS.PSL_NAME;
const _entity = PS_MAPPING.EXCEPTION_FIELDS.ENTITY;
const _percentage = PS_MAPPING.EXCEPTION_FIELDS.PERCENTAGE;
const _amount = PS_MAPPING.EXCEPTION_FIELDS.AMOUNT;
const _isMatched = PS_MAPPING.EXCEPTION_FIELDS.isMatched;
const _file = PS_MAPPING.EXCEPTION_FIELDS.FILE;
const _column = PS_MAPPING.FIELDS.RAW_FILE_FIELD_NAME;
const _ancillaryFilter = PS_MAPPING.EXCEPTION_FIELDS.ANCILLARY_FILTER;
const _combinations = PS_MAPPING.FIELDS.FIELDS_COMBINATIONS;
const _name = PS_MAPPING.FIELDS.NAME;
const _matchedCostKey = PS_MAPPING.FIELDS.MATCHED_COSTKEY;
const _returnName = PS_MAPPING.FIELDS.RETURN_NAME;
const _mappingException = PS_MAPPING.FIELDS.MAPPING_EXCEPTION;
const _calcCol = PS_MAPPING.EXCEPTION_FIELDS.CALCULATED_COL;
const _variance = lang.pss_map_exception.suffixes.variance;
const _type = "type";
const _driverType = "driver_type";
const _metric = "metric";
const _ancillary = "ancillary";
const _filter = "filter";
const _calculatedColumn = "calculated_column"
const _transaction = GLACCOUNTS_FIELDS.MAP_EXCEPTION_VALUES.TRANSACTION;
const _none = GLACCOUNTS_FIELDS.MAP_EXCEPTION_VALUES.NONE;
 
const empty_file_column_row = {
    [_file]: "",
    [_column]: "",
    [RAND]: Math.random()
}

const empty_transaction_column_row = {
    [_calcCol]: "",
    [RAND]: Math.random()
}

const _fileEngine = ENGINE_FILTER.KEYS.FILE;
const _columnEngine = ENGINE_FILTER.KEYS.COLUMN;
const _function = ENGINE_FILTER.KEYS.FUNCTION;
const _valueOptions = ENGINE_FILTER.KEYS.VALUE_OPTIONS;
const _columnOptions = ENGINE_FILTER.KEYS.COLUMN_OPTIONS;
const _fileOptions = ENGINE_FILTER.KEYS.FILE_OPTIONS;
const _functionOptions = ENGINE_FILTER.KEYS.FUNCTION_OPTIONS;
const _fieldDataType = ENGINE_FILTER.KEYS.FIELD_DATA_TYPE;
const _logicalOperator = ENGINE_FILTER.KEYS.LOGICAL_OPERATOR;

var updateAncCols = false;
var updateCalcCols = false;
var periodUpdated = false;

class ExceptionDriver extends Component {
    constructor(props) {
        super(props);

        let tempData = this.initalizeDataFromProps();
        let leadingPssID = this.props.leadingPssId; //mappedLine[_id];
        let leadingPssCostKey = this.props.leadingPssCostKey; //mappedLine[_id];
        let mappedPssID = this.props.mappedLine[_id];

        let totalParsedAmnt = 0;
        tempData.map(item=>{ 
            if(item[_file] !== "" || item[_percentage] !== "") { 
                totalParsedAmnt += is_aN(item[_amount]) && item[_isMatched] !== false ? parseFloat(item[_amount]) : 0;
            }
        });

        this.state = {
            exceptionValue: GLACCOUNTS_FIELDS.MAP_EXCEPTION_VALUES.NONE,
            exceptionData: tempData,   //data of the current component
            filterFileLabel: "",
            filterColLabel: "",
            allAncillaryColumns: [],
            mappedPssID: mappedPssID,
            leadingPssID: leadingPssID,
            leadingPssCostKey: leadingPssCostKey,
            formulaColumns: [],
            filterValues: {},
            calcColsData: [],
            fileOptions: [],
            columnOptions: [],
            totalParsedAmnt: totalParsedAmnt,
            assignedAmount: 0,
            combinationsCount: 0,
            parsedAmounts: [],
            exceptionPSLOptions: this.props.exceptionPSLOptions,
            firstRender: true,   //this state is used to check if calling setExceptionData for the first time to set options
            ancillaryColumns_unchanged: [],
            costTerm: this.props.costTerm,
            mapExceptionList: [
                {label:lang.pss_map_exception.labels.none, value: GLACCOUNTS_FIELDS.MAP_EXCEPTION_VALUES.NONE},
                {label:lang.pss_map_exception.labels.other, value: GLACCOUNTS_FIELDS.MAP_EXCEPTION_VALUES.OTHER}
            ],
            timePeriod: this.props.timePeriod
        };
        
        this.setAssignedAmount = this.setAssignedAmount.bind(this);
        this.onChangeException = this.onChangeException.bind(this);
        this.setExceptionData = this.setExceptionData.bind(this);
        this.splitPercentage = this.splitPercentage.bind(this);
        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.addRow = this.addRow.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.handleLeadingLineRemoval = this.handleLeadingLineRemoval.bind(this);
        this.showFilter = this.showFilter.bind(this);
        this.handleChangeAttribute = this.handleChangeAttribute.bind(this);
        this.getUpdatedExceptionData = this.getUpdatedExceptionData.bind(this);
        this.handleFilterRowChange = this.handleFilterRowChange.bind(this);
        this.setFilterAttrOptions = this.setFilterAttrOptions.bind(this);
        this.saveFilter = this.saveFilter.bind(this);
        this.discardFilter = this.discardFilter.bind(this);
        this.reflectPercentageChange = this.reflectPercentageChange.bind(this);
        this.initalizeDataFromProps = this.initalizeDataFromProps.bind(this);
        this.onChangeCostTerm = this.onChangeCostTerm.bind(this);
        this.onChangeTimePeriod = this.onChangeTimePeriod.bind(this);

        this.isNone = this.state.exceptionValue === GLACCOUNTS_FIELDS.MAP_EXCEPTION_VALUES.NONE;
        this.isOther = [GLACCOUNTS_FIELDS.MAP_EXCEPTION_VALUES.ANCILLARY,GLACCOUNTS_FIELDS.MAP_EXCEPTION_VALUES.TRANSACTION].includes(this.state.exceptionValue);
      

        this.isMultipleOther = this.isOther && tempData.filter(e=>(!!e[_file] && !!e[_column]) && e[_isMatched] !== false).length > 1; //when reading from DB, rows who have files and cols must be more than 1
        this.isSplitPerc = this.getNumberOfNotUnmatched(tempData) > 1 && !this.isMultipleOther; //the matched rows must be more than 1, and without files nor cols
        

        this.ancillaryColumns = {};
        this.metricFilterOptions_unchanged = [
            {column: this.props.costCenter, field_data_type: "string", raw_file_subtype_id:"metric", raw_field_name:"cost_center", value:"cost_center", label: this.props.costCenter},
            {column: "Metric Vector", field_data_type:"string", raw_file_subtype_id:"metric", raw_field_name:"vector_key", value:"vector_key", label: "Metric Vector"},
            {column:"Period", field_data_type:"string", raw_file_subtype_id:"metric", raw_field_name:"time_period", value:"time_period", label: "Period"},
            {column:"Metric Value", field_data_type:"numeric", raw_file_subtype_id:"metric", raw_field_name:"metric_value", value:"metric_value", label: "Metric Value"}
        ];
    }

    static getDerivedStateFromProps(props, state) {
        var tempState = {};
        var metricColumns = copyObjectValues(props.exceptionMetrics)
        //updating list of all ancillary columns
        if(props.ancillaryColumns && !deepCompareObjects(props.ancillaryColumns, state.allAncillaryColumns)) {
            tempState.allAncillaryColumns = props.ancillaryColumns.map(item=>{
                item.value = item[PS_MAPPING.FIELDS.RAW_FILE_FIELD_NAME_DB];
                item.name =  item[RAW_ITEMS.NAME]
                item.label = item[PS_MAPPING.FIELDS.RAW_FILE_FIELD_NAME_DB];
                return item;
            }).concat(metricColumns.map(item=>{
                item.value = "metric_value";
                item.name = "metric";
                item[RAW_ITEMS.SUBTYPE_NAME] = item[RAW_ITEMS.SUBTYPE_NAME];
                item.label = "Metric Value";
                return item;
            }));
        }

        if((!tempState.allAncillaryColumns || !tempState.allAncillaryColumns.length) && metricColumns && metricColumns.length) {
            //if there are no ancillary columns for this client, but there are metrics created from transaction
            tempState.allAncillaryColumns = metricColumns.map(item=>{
                item.value = "metric_value";
                item.name = "metric";
                item[RAW_ITEMS.SUBTYPE_NAME] = item[RAW_ITEMS.SUBTYPE_NAME];
                item.label = "Metric Value";
                return item;
            })
        }

        if(props.ancillaryFiles && props.ancillaryFiles.length && !deepCompareObjects(props.ancillaryColumns, state.ancillaryColumns_unchanged)) {
            tempState.ancillaryColumns_unchanged = props.ancillaryColumns;
            updateAncCols = true;
        }

        return tempState;
    }

    componentDidUpdate(prevProps) {
        var _this = this;
        if(updateAncCols && this.isOther) {
            updateAncCols = false;
            let tempExceptionData = copyObjectValues(this.state.exceptionData);
            tempExceptionData.forEach((item, i)=>{
                if(item[_isMatched] && (item[_file] || item[_calcCol])) {
                    this.handleChangeAttribute(_file, {value:item[_file], [ROW_NUMBER]: i,[_mappingException]:item[_mappingException]}, true);
                    if ((!item[_ancillaryFilter] || item[_ancillaryFilter] === "") && ![_transaction,_none].includes(item[_mappingException])) {
                        var tempRow={};
                        tempRow[_fileEngine] = item[_file];
                        var options = [];
                        if (item[_driverType] === _metric) {
                            options = copyObjectValues(this.metricFilterOptions_unchanged);
                        } else {
                            options = _this.props.allAncillaryColumns.filter(e=>e[RAW_ITEMS.SUBTYPE_NAME] === item[_file]);       
                            options.map(function(el){
                                el.value = el.raw_field_name;
                                el.label = el.raw_field_name;
                            });
                        }
                     
                        tempRow[_columnOptions] = options; //setting column options
                        tempRow[ENGINE_FILTER.KEYS.RAW_FILE_SUBTYPE_ID] = options[0].raw_file_subtype_id; //setting column options
                        item[_ancillaryFilter] = [tempRow];
                    }
                    item.originalAncillaryFilter = copyObjectValues(item[_ancillaryFilter]);
                }
                if (item[_ancillaryFilter] && item[_ancillaryFilter] !== "") {
                    let emptyFilter = {"filter":[{}]};
                    var filter = tryParse(item[_ancillaryFilter], emptyFilter);
                    filter = typeof item[_ancillaryFilter] === "string" ? filter[_filter] : filter;
                    if(filter.length > 0 && !filter[0].entities){ //Make sure to send parseAmount request only when you have chosen entities in your filter
                        filter = [];
                    }
                    this.saveFilter(filter, true, i);
                }
                return item;
            });
            
            periodUpdated = false;  //update here bc cannot be updated inside the loop, cond won't be satisfied more than once
            this.setExceptionData(tempExceptionData);
        }
    }

    onChangeTimePeriod(period) {
        periodUpdated = true;
        this.forceUpdate();
    }

    /**this function is used to set the assigned amount from outside
     * this component, since in GLAccountList, the assignedAmount state
     * is updated passively and the component is not refreshing
     */
    setAssignedAmount(amount, count) {
        amount = is_aN(amount) ? parseFloat(amount) : 0;

        if(count !== this.state.combinationsCount || amount !== this.state.assignedAmount) {
            let tempState = {
                assignedAmount: amount,
                combinationsCount: count
            };
            
            //if multiple anc, reset percentages to be recalculated
            let data = this.state.exceptionData;
            let newExceptionData = this.isMultipleOther || this.isTransaction ? this.resetAllAmounts(this.resetAllPercentages(data)) : this.isSplitPerc ? this.resetAllAmounts(data) : data;
            this.setExceptionData(newExceptionData, tempState);
        }
    }

    /**
     * resetting the percentage will allow it to be
     * updated from the amount in setExceptionData
     * @param {*} data 
     */
    resetAllPercentages(data) {
        data.map(item=>{
            item[_percentage] = "";
            return item;
        });

        return data;
    }

    /**
     * resetting the amount will allow it to be
     * updated from the percentage in setExceptionData
     * @param {*} data 
     */
    resetAllAmounts(data) {
        data.map(item=>{
            item[_amount] = "";
            return item;
        });

        return data;
    }

    initalizeDataFromProps(tempState, reset = false, callback) {
        var tempData = this.props.pssSiblings.length && !reset ? this.props.pssSiblings : this.props.mappedLine !== null ? [this.props.mappedLine] : [];
        tempData = copyObjectValues(tempData);  //canceled changes would be saved to props.siblings without this line
        if(tempData.length > 0) {
            tempData.map(item=>{
                item[_leadingID] = reset ? Object.keys(item).length > 0  ? item[_id] : "" : this.props.leadingPssId !== null ?  this.props.leadingPssId : "";  //set mappedLine pss id as leading id for all siblings if not yet set
                if(item[_mappingException] === GLACCOUNTS_FIELDS.MAP_EXCEPTION_VALUES.ANCILLARY ){
                    delete item[_calcCol];
                }
                item[RAND] = Math.random();
                return item;
            });
        }


        this.isMultipleOther = this.isOther && tempData.filter(e=>((!!e[_file] && !!e[_column]) || !!e[_calcCol]) && e[_isMatched] !== false).length > 1;
        this.isSplitPerc = this.isOther && this.getNumberOfNotUnmatched(tempData) > 1 && !this.isMultipleOther;
       
        if(!!tempState) {
            if(reset) {
                tempState.leadingPssID = this.props.mappedLine[_id];
                tempState.leadingPssCostKey = this.props.mappedLine[_costkey];
            }
            this.setExceptionData(tempData, tempState, callback);
        } else {
            return tempData;
        }
    }

    getNumberOfNotUnmatched(data) {
        data = data || this.state.exceptionData;
        return data.filter(row=>row[_isMatched] !== false && (!row[_name] || row[_name])).length;
    }

    reflectPercentageChange(rowNumber, newPercentage, data, leadingId) {
        data = this.updateAllPercentages(rowNumber, newPercentage, data, leadingId);
        data = this.resetAllAmounts(data);
        return data;
    }

    updateAllPercentages(rowNumber, newPerc, data, leadingId) {
        if(!data || !Array.isArray(data)) {
            data = copyObjectValues(this.state.exceptionData);
        }
        newPerc = parseFloat(newPerc);

        let mappedIndex = findIndexOfValue(data, _id, this.state.mappedPssID);
        let unmatchedIndex = findIndexOfValue(data, _isMatched, false);
        let percDifference = newPerc - parseFloat(data[rowNumber][_percentage]);       //difference between old and new values of percentage for the modified row
        //percentage has been increased
        while(percDifference > 0) {
            let highestIndex = getHighestKey(data, _percentage, [rowNumber, unmatchedIndex]);   //changes with every iteration
            let highestPercentage = data[highestIndex][_percentage];

            if(percDifference >= highestPercentage) {
                if(highestPercentage === 0) {   //prevention from infinite loop
                    break;
                }

                //set amount of this row to 0
                data[highestIndex][_percentage] = 0;    //nullify percentage of highest index
                //subtract the difference in the highest row's percentage from the difference that still has to be subtracted from other rows
                percDifference -= highestPercentage;
            } else if(percDifference < highestPercentage) {
                data[highestIndex][_percentage] -= percDifference;      //subtract this difference from the highest percentage
                percDifference = 0;
            }
        }

        //percentage has been decreased
        if(percDifference < 0) {
            if(rowNumber === mappedIndex) {
                let leadingLineIndex = findIndexOfValue(data, _id, leadingId);
                data[leadingLineIndex][_percentage] = (parseFloat(data[leadingLineIndex][_percentage]) + Math.abs(percDifference)).toString(); // add % difference to the leading line
            } else {
                let splitPercentage = data[mappedIndex][_percentage]
                if(typeof splitPercentage === "string"){
                    data[mappedIndex][_percentage] = Number(splitPercentage);
                }
                data[mappedIndex][_percentage] += Math.abs(percDifference);     //add this difference in % to the mapped line
            }
        }
        data[rowNumber][_percentage] = newPerc;     //update percentage of modified row
        return data;
    }

    onChangeFile = (file, option) => {
        if(this.isSplitPerc && !this.isNone && option.type === _calculatedColumn){
            this.isSplitPerc = false;
            //Reset everything that existed then enter handleChangeAttribute after reset is done
            this.onChangeException({label:lang.pss_map_exception.labels.other, value: GLACCOUNTS_FIELDS.MAP_EXCEPTION_VALUES.OTHER},true,true,()=>{
                this.handleChangeAttribute(file,option,false);
            });
        } else {
            this.handleChangeAttribute(file,option);
        }
    }

    onChangeException(valueObj, reset = true, ignore = false,callback) {
        if(this.state.exceptionValue === valueObj.value && !ignore) {
            return; //stop if user chose the same value
        }
        if(this.excMappingRef && this.excMappingRef!== null && this.excMappingRef.treeOptions && this.excMappingRef.treeOptions.length > 0){
            this.excMappingRef.treeOptions =  [];
        }
        if(this.excMappingRef && this.excMappingRef!== null && this.excMappingRef.state.treeOptions && this.excMappingRef.state.treeOptions.length > 0){
            this.excMappingRef.setState({treeOptions:[]});
        }

        this.isNone = valueObj.value === GLACCOUNTS_FIELDS.MAP_EXCEPTION_VALUES.NONE;
       
        this.isOther = [GLACCOUNTS_FIELDS.MAP_EXCEPTION_VALUES.ANCILLARY,GLACCOUNTS_FIELDS.MAP_EXCEPTION_VALUES.TRANSACTION].includes(valueObj.value) || valueObj.value === GLACCOUNTS_FIELDS.MAP_EXCEPTION_VALUES.OTHER;

        let tempState = {exceptionValue: this.isOther ? GLACCOUNTS_FIELDS.MAP_EXCEPTION_VALUES.OTHER : valueObj.value};
        
        //remove the mapping of the deleted line from the options
        if(reset) {
            let leadingId = this.state.exceptionData[0][_leadingID];
            if(leadingId) {
                let siblingsIds = this.state.exceptionData.map(line => line[_id]).filter(id => id !== leadingId);
                let options = this.state.exceptionPSLOptions;
                options.forEach(opt=>{
                    if(siblingsIds.includes(opt[_id])) {
                        opt = this.deleteLineMapping(opt);
                    }
                });
                tempState.exceptionPSLOptions = options;
            }
        }
        /** */

        this.initalizeDataFromProps(tempState, reset, callback);     //reset data on changing exception driver value

        if(typeof this.props.onChangeExceptionDriver === "function") {
            this.props.onChangeExceptionDriver(valueObj.value, PS_MAPPING.FIELDS.MAPPING_EXCEPTION, reset);
        }
    }

    // set type & mappingException based on type coming from option
    setTypeInExceptionData = (type,row) => {
        if(type) {
            if(type === _ancillary){
                row[_type] = _ancillary;
                row[_mappingException] = GLACCOUNTS_FIELDS.MAP_EXCEPTION_VALUES.ANCILLARY;
            } else if( type === _metric){
                row[_type] = _metric;
                row[_mappingException] = GLACCOUNTS_FIELDS.MAP_EXCEPTION_VALUES.ANCILLARY;
            } else if(type === _calculatedColumn){
                row[_type] = _calculatedColumn;
                row[_mappingException] = GLACCOUNTS_FIELDS.MAP_EXCEPTION_VALUES.TRANSACTION;
            }
        }
    }

    handleChangeAttribute(attr, option, fromDidMount) {
        let _this = this;
        let tempState = {};

        //if coming from percentage or amount blur event, construct option object to read as coming from dropdown change
        if(option.currentTarget && option.currentTarget instanceof Node) {
            option = {
                [ROW_NUMBER]: Number($(option.currentTarget).attr("rownumber") || -1),
                value: parseFloat(getNumericValue($(option.currentTarget).val() || 0))
            };
        }

        let rowNumber = option[ROW_NUMBER];   //extract rowNumber
        let tempExceptionData = copyObjectValues(this.state.exceptionData); //get current data in state
        var tempRow = {};
        rowNumber = !tempExceptionData[rowNumber] ? 0 : rowNumber;
        switch(attr) {
            case _file:
                if(!fromDidMount) {
                    tempExceptionData[rowNumber][_column] = "";     //empty the column value when changing the file
                }
           
                this.ancillaryColumns[option.value] = this.state.allAncillaryColumns.filter(item=>item[RAW_ITEMS.SUBTYPE_NAME] === option.value);
                if (tempExceptionData[rowNumber][_ancillaryFilter] && tempExceptionData[rowNumber][_ancillaryFilter].length > 0 && fromDidMount) {
                    tempExceptionData[rowNumber].originalAncillaryFilter = copyObjectValues(tempExceptionData[rowNumber][_ancillaryFilter]);
                } else {
                    // updating file drop down value in engine filter dialog
                    tempRow[_fileEngine] = option.value;
                    var options = [];
                    if(option.type && option.type !== _calculatedColumn){
                        if(option.type === _metric) {
                            options = [
                                {column: this.props.costCenter, field_data_type: "string", raw_file_subtype_id:"metric", raw_field_name:"cost_center"},
                                {column: "Metric Vector", field_data_type:"string", raw_file_subtype_id:"metric", raw_field_name:"vector_key"},
                                {column:"Period", field_data_type:"string", raw_file_subtype_id:"metric", raw_field_name:"time_period" },
                                {column:"Metric Value", field_data_type:"numeric", raw_file_subtype_id:"metric", raw_field_name:"metric_value" }];
                             tempRow[ENGINE_FILTER.KEYS.RAW_FILE_SUBTYPE_ID] = _metric;
                        } else {
                            options = _this.props.allAncillaryColumns.filter(e=>e[RAW_ITEMS.SUBTYPE_NAME] === option.value);
                            tempRow[ENGINE_FILTER.KEYS.RAW_FILE_SUBTYPE_ID] = option.raw_file_subtype_id ? option.raw_file_subtype_id : options[0].raw_file_subtype_id; //setting column options
                        }
                        options.map(function(item){
                            item.value = item.raw_field_name;
                            item.label = item.column;
                        });
                    }
                    
                    _this.filterModalRef.filterDialRef.state.filterRefs=[];
                    _this.filterModalRef.filterDialRef.updateAdvancedData([]);
                    tempRow[_columnOptions] = options; //setting column options

                    this.setTypeInExceptionData(option.type, tempExceptionData[rowNumber])
                    
                    if ( tempExceptionData[rowNumber][_mappingException]!== _transaction) {
                        tempExceptionData[rowNumber].originalAncillaryFilter = [tempRow];
                        tempExceptionData[rowNumber][_ancillaryFilter] = [tempRow]
                    }
                }
                break;

            case _column:
                //update amount and percentage when choosing column
                if (option.name === _metric) {
                    tempExceptionData[rowNumber][_driverType] = _metric;
                } else{
                    tempExceptionData[rowNumber][_driverType] = _ancillary;
                }
                if(!this.isSplitPerc) {
                    tempExceptionData[rowNumber][_amount] = parseFloat(option[_amount]);
                }

                if(!this.isMultipleOther) {
                    tempState.totalParsedAmnt = parseFloat(option[_amount]);
                }
                break;

            case _pslName:
                tempExceptionData[rowNumber][_pslName] = option[_name];

                delete option[_amount];       //these options are ps lines, and if mapped before,
                delete option[_percentage];   //they should not retain their old amounts or percentages
                delete option[_file];         //neither files or columns
                delete option[_column];

                if(tempExceptionData[rowNumber][_id] === this.state.leadingPssID) {
                    tempExceptionData = this.handleLeadingLineRemoval(tempExceptionData, false);
                }
                if(!tempExceptionData[rowNumber][_mappingException] && _this.isSplitPerc) {
                    
                    if(this.isOther){
                        let leadingPSL = tempExceptionData.filter(e=>e[_id] === this.state.leadingPssID);
                        tempExceptionData[rowNumber][_mappingException] = leadingPSL.length ? leadingPSL[0][_mappingException] : "";
                    } else if(this.isNone){
                        tempExceptionData[rowNumber][_mappingException] = _none;
                    }
                }
                option[_mappingException] =  tempExceptionData[rowNumber][_mappingException];
                tempExceptionData[rowNumber] = Object.assign({}, tempExceptionData[rowNumber], option);     //updating the attributes of the old line with the values of the new one
                delete tempExceptionData[rowNumber][_calcCol];
                break;

            case _amount:
                //update percentage
                let totalAmnt = this.isOther && this.isSplitPerc ? this.state.totalParsedAmnt : this.state.assignedAmount;
                let tempPerc = option.value / totalAmnt * 100; //updating percentage from amount
                tempExceptionData = this.reflectPercentageChange(rowNumber, tempPerc, tempExceptionData);
                break;

            case _percentage:
                tempExceptionData = this.reflectPercentageChange(rowNumber, option.value, tempExceptionData);
                break;

            case _ancillaryFilter:
                if(this.isSplitPerc) {
                    tempExceptionData = this.resetAllAmounts(tempExceptionData);
                    
                    // if(!this.isMultipleAnc) {
                        tempState.totalParsedAmnt = option.value;    //set total parsed amnt when choosing column
                    // }
                } else if(this.isMultipleOther) {
                    tempExceptionData = this.resetAllPercentages(tempExceptionData);
                    tempExceptionData[rowNumber][_amount] = option.value;
                } else {
                    tempExceptionData[rowNumber][_amount] = option.value;
                }
                break;
        }
        
        if(![_percentage,_ancillaryFilter].includes(attr)) {
            tempExceptionData[rowNumber][attr] = option.value;    //update state data
        }

        if(this.isOther) {
            tempState.totalParsedAmnt = 0;
            tempExceptionData.forEach(row=>{
                let rowCol = findOptionByKeyValue(_this.ancillaryColumns[row[_file]], PS_MAPPING.FIELDS.RAW_FILE_FIELD_NAME_DB, row[_column]);
                if(rowCol) {
                    tempState.totalParsedAmnt += parseFloat(rowCol[_amount]);
                }
            });
        }

        this.setExceptionData(tempExceptionData, tempState);
    }

    setMappingExceptionBasedOnType = (type,row) => {
        if(type) {
            if(_ancillary,_metric.includes(type)){
                row[_mappingException] = GLACCOUNTS_FIELDS.MAP_EXCEPTION_VALUES.ANCILLARY
            } else if(type === _calculatedColumn){
                row[_mappingException] = GLACCOUNTS_FIELDS.MAP_EXCEPTION_VALUES.TRANSACTION;
            }
        }
    }

    /**This function should be used from outside the component to get the
     * updated data from this component
     */
    getUpdatedExceptionData() {
        var _this = this;
        var finalData = [];
        this.state.exceptionData.map(item=>{
            let oldItem = findOptionByKeyValue(_this.props.pssSiblings, _id, item[_id]) || item;

            oldItem = Object.assign({}, oldItem, item);    //updating oldItem obj with values from item obj
            _this.setMappingExceptionBasedOnType(oldItem.type,oldItem); //This is required to update pssSiblings mapping exception based on type
            oldItem[_amount] = is_aN(item[_amount]) ? item[_amount].toString() : "";
            oldItem[_percentage] = item[_isMatched] === false ? "" : !this.isSplitPerc ? "" :  item[_percentage].toString(); //! added this recently 
            oldItem[_isMatched] = item[_isMatched];
            oldItem[_leadingID] = _this.state.leadingPssID;
            oldItem[_leadingCostkey] = _this.state.leadingPssCostKey;
            oldItem[ROW_STATUS.FIELD] = ROW_STATUS.VALUES.EDITED;
            oldItem[PS_MAPPING.FIELDS.DELETED] = "false";
            oldItem[_id] = oldItem[_id] ? oldItem[_id].toString() : "" ;
            oldItem[_column] = this.isOther ? item[_column] : undefined;
            oldItem[_file] = this.isOther ? item[_file] : undefined;
            oldItem.filter = _this.props.filter;
            oldItem[_calcCol] = item[_calcCol];
            oldItem[_isMatched] = item[_isMatched];
            oldItem[_driverType] = this.isOther ? item[_driverType] : undefined;
            oldItem[PS_MAPPING.FIELDS.COST_CENTER] = item[PS_MAPPING.FIELDS.COST_CENTER] ? item[PS_MAPPING.FIELDS.COST_CENTER] : "ALL";
            if(oldItem[_mappingException] !== _none && this.isNone){
                oldItem[_mappingException] = _none;
                delete oldItem[_isMatched];
                delete oldItem[_driverType];
                delete oldItem[_file];
            }
           

            var emptyFilter = {"filter":[{}]};
            var definedFilter = ![undefined,""].includes(item[_ancillaryFilter]);
            var jsonFilter = definedFilter ? typeof item[_ancillaryFilter] === "string" ? tryParse(item[_ancillaryFilter], emptyFilter).filter : item[_ancillaryFilter] : emptyFilter.filter[0];
            var isEmptyFilter = definedFilter ? !jsonFilter[0][_columnEngine] || jsonFilter[0][_columnEngine] === "" : true;

            oldItem[_ancillaryFilter] = this.isOther && definedFilter && !isEmptyFilter ? "{\"filter\":"+JSON.stringify(jsonFilter)+"}" : "";     
            //deleting attributes used only for UI rendering and functionality, no need to store them in DB
            delete oldItem[RAND];
            delete oldItem[_pslName];   //pslName is a key only used on UI
            delete oldItem.label;       //label and value are only used for the options
            delete oldItem.value;
            delete oldItem[_entity];

            if(oldItem.type && oldItem.type === _calculatedColumn){
                oldItem[_calcCol] = oldItem[_file]
            }

            finalData.push(oldItem);
        });

        //filling out PSS ids of lines that were mapped, and are no longer
        let removedIds = [];
        _this.props.pssSiblings.map(oldItem=>{
            let item = findOptionByKeyValue(_this.state.exceptionData, _id, oldItem[_id]);

            if(!item) {
                //the item was in list of ps lines that were received as siblings, but its mapping was removed
                removedIds.push(oldItem[_id]);
            }
        })

        return {data: finalData, deletedIds: removedIds};
    }

    splitPercentage() {
        //update flag of multiple ancillary or split perc
        this.isSplitPerc = true;
        this.isMultipleOther = false;

        this.excMappingRef.resetPSOptions();   //reset options in PSLines options dropdown to remove mapped line from options
        this.addRow();
    }

    handleButtonClick() {
        //update flag of multiple ancillary or split perc
        if(this.isOther) {
            this.isMultipleOther = true;
            this.isSplitPerc = false;
        }
        
        this.addRow();
    }

    addRow() {
        let tempExceptionData = copyObjectValues(this.state.exceptionData);
        let emptyRow = {};
        let tempState = {};

        if(this.isMultipleOther) {
            emptyRow = copyObjectValues(empty_file_column_row);
            this.excMappingRef.resetPSOptions();
        } else if(this.isSplitPerc) {
            emptyRow = {};

            if(this.isOther) {  //copy file and column from matched row
                let leadingPssID = this.state.leadingPssID;
                let matchedRow = tempExceptionData.filter(r=>r[_id] === leadingPssID)[0];
                // emptyRow[_percentage] = 1;
                // emptyRow[_amount] = matchedRow[_amount] * emptyRow[_percentage] / 100;

                //update matched row amount, deduct the amount given to the new line
                // matchedRow[_amount] = matchedRow[_amount] - emptyRow[_amount];
                if(this.getNumberOfNotUnmatched(tempExceptionData) === 1) {
                    matchedRow[_percentage] = 100;
                }
            }
        }
        
        emptyRow[RAND] = Math.random();
        emptyRow[_returnName] = "";
        tempExceptionData.push(emptyRow);
        this.setExceptionData(tempExceptionData, tempState);
    }

    removeLeadingFromOptions() {
        let tempOptions = [];
        let leadingLine = findOptionByKeyValue(this.state.exceptionData, _id, this.state.leadingPssID);
        let leadingName = leadingLine[_name];
        let leadingCostKey = leadingLine[_matchedCostKey];

        this.state.exceptionPSLOptions.map((opt, i)=>{
            if(opt[_name] === leadingName) {     //it's previously matched row, remove (matched) from its name
                opt[_name] = leadingName;
            } else if(opt[_isMatched] === false && opt[_leadingCostkey] === leadingCostKey) {  //it's previously unmatched row, remove from options
                return;
            }

            tempOptions.push(opt);
        });

        return tempOptions;
    }

    deleteLineMapping(line) {
        if(!line) return null;

        line[_name] = line[_name].replace(_variance,"");
        line[_combinations] = [];
        delete line[_leadingID];
        delete line[_leadingCostkey];
        delete line[_file];
        delete line[_column];
        delete line[_ancillaryFilter];
        delete line[_mappingException];
        delete line[_amount];
        delete line[_percentage];
        delete line[_isMatched];

        return line;
    }

    deleteRow(e) {
        let _this  = this;
        let rowNumber = $(e.currentTarget).attr("rownumber");
        let tempExceptionData = copyObjectValues(this.state.exceptionData);
        //before deleting the row, set its percentage to 0 by mimicking a
        //change percentage action so that it is added to the percentage of another row
        
        tempExceptionData = this.reflectPercentageChange(rowNumber, 0, tempExceptionData);
        
        let deletedId = this.state.exceptionData[rowNumber][_id];
        let isDeletingLeading = deletedId === this.state.leadingPssID;

        //set mapped line as leading line
        let tempState = {
            refreshOptions: true,
        };
        if(isDeletingLeading) {
            tempExceptionData = this.handleLeadingLineRemoval(tempExceptionData, true, tempState);
        }

        tempExceptionData.splice(rowNumber, 1);
        let numOfRows = this.getNumberOfNotUnmatched(tempExceptionData);
        if(numOfRows <= 1) {
            //when deleting rows that are NOT "unmatched", update flags so that hidden buttons are re-shown
            this.isMultipleOther = false;
            this.isSplitPerc = false;
        }
      
        _this.setExceptionData(tempExceptionData, tempState);
    }
    
    /**
     * This function handles changing or removing the leading ps line
     * @param {*} tempExceptionData 
     * @param {*} isDeleted 
     * @param {*} tempState 
     */
    handleLeadingLineRemoval(tempExceptionData, isDeleted, tempState={}) {        
        let mappedLine = findOptionByKeyValue(tempExceptionData, _id, this.state.mappedPssID);
        let leadingLine = findOptionByKeyValue(tempExceptionData, _id, this.state.leadingPssID);
        mappedLine[_combinations] = leadingLine[_combinations];     //move combinations from previous leading line to mapped line

        if(this.isOther && !this.isMultipleOther) {
            //if multipleAnc, each row would have its own file, column and filter
            mappedLine[_file] = leadingLine[_file];     //move file from previous leading line to mapped line
            mappedLine[_column] = leadingLine[_column];     //move column from previous leading line to mapped line
            mappedLine[_ancillaryFilter] = leadingLine[_ancillaryFilter];     //move ancillary filter from previous leading line to mapped line
            mappedLine.originalAncillaryFilter = "";     //not needed
        }

        let unmatchedIndex = findIndexOfValue(tempExceptionData, _isMatched, false);
        if(unmatchedIndex > -1 && tempExceptionData[unmatchedIndex][_name].includes(_variance)) {
            tempExceptionData.splice(unmatchedIndex, 1);
        }
        
        let shouldSetState = Object.keys(tempState).length === 0;
        tempState.leadingPssID = this.props.mappedLine[_id];
        tempState.leadingPssCostKey = this.props.mappedLine[_costkey];
        tempState.exceptionPSLOptions = this.removeLeadingFromOptions();    //has to be called before changing the leading ID

        if(shouldSetState) {
            this.setState(tempState);
        }

        return tempExceptionData;
    }

    getLabelOf(newExceptionData, attr, rowNumber) {
        newExceptionData = newExceptionData || this.state.exceptionData;

        switch (attr) {
            case _file:
                return findOptionByKey(this.props.ancillaryFiles, newExceptionData[rowNumber][_file]).label;

            case _column:
                return findOptionByKey(this.state.allAncillaryColumns, newExceptionData[rowNumber][_column]).label;

            default:
                return "";
        }
    }

    //#region filter functions
    discardFilter() {
        var _this = this;
        var emptyFilter = {filter:[]};
        var filter = copyObjectValues(_this.state.exceptionData[this.state.rowNumber][_ancillaryFilter]);
        var checkFilter = tryParse(_this.state.exceptionData[_this.state.rowNumber].originalAncillaryFilter, emptyFilter);
        checkFilter = Array.isArray(checkFilter.filter) ? checkFilter.filter : checkFilter;
        if (checkFilter.length === 0 || checkFilter === "" || ( checkFilter && !checkFilter[0][_columnEngine])) {
            _this.filterModalRef.filterDialRef.state.filterRefs=[];
            _this.filterModalRef.filterDialRef.updateAdvancedData([]);
        }
        _this.state.exceptionData[_this.state.rowNumber][_ancillaryFilter] = copyObjectValues(_this.state.exceptionData[_this.state.rowNumber].originalAncillaryFilter);
        var options = _this.props.allAncillaryColumns.filter(e=>e && e[RAW_ITEMS.SUBTYPE_NAME] === filter[0][_fileEngine]);
        if (_this.state.exceptionData[_this.state.rowNumber][_ancillaryFilter] === "" || ( typeof _this.state.exceptionData[_this.state.rowNumber][_ancillaryFilter] === 'object' && _this.state.exceptionData[_this.state.rowNumber][_ancillaryFilter].length === 0)) {
            _this.state.exceptionData[_this.state.rowNumber][_ancillaryFilter]= [{[_fileEngine]:filter[0][_fileEngine] ,
                [_columnOptions]: options, raw_file_subtype_id: filter[0].raw_file_subtype_id}];
        }
        _this.forceUpdate();
    }

    saveFilter(filter, flag, rowNum) {
        var _this = this;
        //callback to be called when data is received from the api
        var callback = (newParsedAmount)=>{
            let option = {
                value: newParsedAmount,
                [ROW_NUMBER]: _this.state.rowNumber || rowNum
            }
            var obj = {rowNumber: flag ? Number(rowNum) : Number(_this.state.rowNumber), amount: newParsedAmount};
            var parsedAmounts = _this.state.parsedAmounts;
            var newParsedAMounts = [];
            for (var e in parsedAmounts) {
                if (parsedAmounts[e].rowNumber !== Number(_this.state.rowNumber)) {
                    newParsedAMounts.push(parsedAmounts[e]);
                }
            }
            newParsedAMounts.push(obj);
            _this.state.parsedAmounts = newParsedAMounts;
            _this.handleChangeAttribute(_ancillaryFilter, option);
        }
        let exceptionMetrics = this.props.exceptionMetrics;
        if (flag && filter.length >0) {
            if (this.state.rowNumber) {
                if (this.state.exceptionData[rowNum][_driverType] === _metric) { // to be replace with metric id 
                   let metricId = exceptionMetrics.filter(e=>e.value === this.state.exceptionData[this.state.rowNumber][_file])[0].metric_id;
                   this.state.exceptionData[this.state.rowNumber][_ancillaryFilter][0][_fileEngine];
                    this.props.parseMetricAmount(filter,this.state.exceptionData[this.state.rowNumber][_file], this.state.exceptionData[this.state.rowNumber][_column],metricId,callback);
                } else {
                    this.props.parseAncillaryColumnAmount(filter, this.state.exceptionData[this.state.rowNumber][_file], this.state.exceptionData[this.state.rowNumber][_column],callback);
                }
            }else{
                if (this.state.exceptionData[rowNum][_driverType] === _metric) { // to be changed to driver
                    if (!!filter[0].column) {
                        let metricId = exceptionMetrics.filter(e=>e.value === this.state.exceptionData[rowNum][_file])[0].metric_id;
                        this.props.parseMetricAmount(filter, this.state.exceptionData[rowNum][_file], this.state.exceptionData[rowNum][_column],metricId,callback);
                    }
                } else {
                    this.props.parseAncillaryColumnAmount(filter, this.state.exceptionData[rowNum][_file], this.state.exceptionData[rowNum][_column],callback);
                }
            }
        }
        if (filter.length > 0 && !flag) {
            this.state.exceptionData[this.state.rowNumber][_ancillaryFilter] = filter;
            this.state.exceptionData[this.state.rowNumber].originalAncillaryFilter = filter;
            if (this.state.exceptionData[this.state.rowNumber][_ancillaryFilter][0][RAW_ITEMS.SUBTYPE_ID] === _metric) {
                let metricId = exceptionMetrics.filter(e=>e.value ===this.state.exceptionData[this.state.rowNumber][_ancillaryFilter][0][_fileEngine])[0].metric_id;
                this.props.parseMetricAmount(filter,this.state.exceptionData[this.state.rowNumber][_ancillaryFilter][0][_fileEngine],this.state.exceptionData[this.state.rowNumber][_column],metricId,callback);
            } else{
                this.props.parseAncillaryColumnAmount(filter,this.state.exceptionData[this.state.rowNumber][_ancillaryFilter][0][_fileEngine],this.state.exceptionData[this.state.rowNumber][_column],callback);
            }
        } else  if (!flag) {
            var filter = copyObjectValues(this.state.exceptionData[this.state.rowNumber][_ancillaryFilter]);
            filter = typeof filter === 'string' && filter !== "" ? tryParse(filter).filter : typeof filter === 'object' && typeof filter.filter !== 'function' ? filter.filter : filter ;
            if (this.state.exceptionData[this.state.rowNumber][_driverType] === _metric) {
                options = [
                    {column: this.props.costCenter, field_data_type: "string", raw_file_subtype_id:"metric", raw_field_name:"cost_center"},
                    {column: "Metric Vector", field_data_type:"string", raw_file_subtype_id:"metric", raw_field_name:"vector_key"},
                    {column:"Period", field_data_type:"string", raw_file_subtype_id:"metric", raw_field_name:"time_period" },
                    {column:"Metric Value", field_data_type:"numeric", raw_file_subtype_id:"metric", raw_field_name:"metric_value" }];
                    options.map(function(el){
                        el.value = el.raw_field_name;
                        el.label = el.column;
                    });
            } else {
                var options = this.props.allAncillaryColumns.filter(e=>e[RAW_ITEMS.SUBTYPE_NAME] === this.state.exceptionData[this.state.rowNumber][_file]);
            }
            this.state.exceptionData[this.state.rowNumber][_ancillaryFilter] = [{[_fileEngine]:this.state.exceptionData[this.state.rowNumber][_file] ,
                [_columnOptions]: options, raw_file_subtype_id: filter[0].raw_file_subtype_id}];;
            this.state.exceptionData[this.state.rowNumber].originalAncillaryFilter = [{[_fileEngine]:filter[0][_fileEngine] ,
                [_columnOptions]: filter[0][_columnOptions], raw_file_subtype_id: options[0].raw_file_subtype_id}];
                if (this.state.exceptionData[this.state.rowNumber][_driverType] === _metric) {
                    let metricId = exceptionMetrics.filter(e=>e.value ===this.state.exceptionData[this.state.rowNumber][_file])[0].metric_id;
                    this.props.parseMetricAmount([], this.state.exceptionData[this.state.rowNumber][_file], this.state.exceptionData[this.state.rowNumber][_column],metricId, callback);
                }else {
                    this.props.parseAncillaryColumnAmount([], this.state.exceptionData[this.state.rowNumber][_file], this.state.exceptionData[this.state.rowNumber][_column], callback);
                }
        }
        this.forceUpdate();
    }

    showFilter(e) {
        let _this = this;
        let rowNumber = $(e.currentTarget).attr("rownumber");
        var filter = this.state.exceptionData[rowNumber][_ancillaryFilter] !== "" && typeof(this.state.exceptionData[rowNumber][_ancillaryFilter]) === "string" ? JSON.parse(this.state.exceptionData[rowNumber][_ancillaryFilter].replace(/'/g,'"')).filter : this.state.exceptionData[rowNumber][_ancillaryFilter]
        for (var elt in filter) {
            if (filter[elt][_columnEngine]) {
                var functionOptions = getObjectAsArray(lang.ui_filter.dropdowns.engine_filter_functions, filter[elt][_fieldDataType].toLowerCase(), "value_type");
                var options = [];
                if (filter[elt][RAW_ITEMS.SUBTYPE_ID] === "metric") {
                    options = [{column: this.props.costCenter, field_data_type: "string", raw_file_subtype_id:"metric", raw_field_name:"cost_center"},
                    {column: "Metric Vector", field_data_type:"string", raw_file_subtype_id:"metric", raw_field_name:"vector_key"},
                    {column:"Period", field_data_type:"string", raw_file_subtype_id:"metric", raw_field_name:"time_period" },
                    {column:"Metric Value", field_data_type:"numeric", raw_file_subtype_id:"metric", raw_field_name:"metric_value" }];
                    options.map(function(item){
                        item.value = item.raw_field_name;
                        item.label = item.column;
                    });
                }else {
                    options = _this.props.allAncillaryColumns.filter(e=>e[RAW_ITEMS.SUBTYPE_NAME] === filter[elt][_fileEngine]);
                    options.map(function(item){
                        item.value = item.raw_field_name;
                        item.label = item.raw_field_name;
                    });
                }
                filter[elt][_columnOptions] = options;
                filter[elt][_functionOptions] = functionOptions;
                let callback = ()=>{};
                callback = function(values) {
                    _this.setFilterAttrOptions(elt, _valueOptions, values);
                }
                this.props.getColumnValues(filter[elt].raw_file_subtype_id, filter[elt][_fieldDataType], filter[elt][_columnEngine],
                     rowNumber, filter[elt][_function], Number(elt), "", callback);
            }
        }
        _this.filterModalRef.openFilterModal(filter);
        this.setState({
            filterFileLabel: this.getLabelOf(this.state.exceptionData, _file, rowNumber),
            filterColLabel: this.getLabelOf(this.state.exceptionData, _column, rowNumber),
            rowNumber: rowNumber
        }, function () {
            this.forceUpdate();
        });
    }
    //#endregion

    sortPSLines(data) {
        let _this = this;
        let returnData = [];
        let mappedIndex = -1;
        let unmatchedIndex = -1;
        // let isMatched = data.filter(e=>e[PS_MAPPING.FIELDS.PSS_ID] === _this.state.mappedPssID)[0][_isMatched];
        // if (!isMatched) {
        //     return data;
        // }
        data.map((row, i)=>{
            if(row[_id] === _this.state.mappedPssID) {
                mappedIndex = i;    //do not insert mapped line at this point
            } else if(row[_isMatched] === false) {
                unmatchedIndex = i;    //do not insert unmatched line at this point
            } else {
                returnData.push(row);   //insert all other lines
            }
        });

        if(mappedIndex > -1) {
            returnData.unshift(data[mappedIndex]);  //insert mapped line at the beginning
        }
        if(unmatchedIndex > -1) {
            returnData.push(data[unmatchedIndex]);  //insert unmatched line at the end
        }

        return returnData;
    }

    /**
     * centralise where the data is actually being edited
     * N.B: exceptionData state should not be modified outside this function,
     * when an update is required, just call it
     * @param {*} data 
     * @param {*} tempState -- state to set at the same time as the new data, to avoid setting the state twice
     * @param {*} callback 
     */
    setExceptionData(data, tempState, callback) {
        data = data || this.state.exceptionData;
        if(!Array.isArray(data)) {
            data = [data];
        }

        tempState = tempState || {};

        let _this = this;
        let tempData = copyObjectValues(data);
        let assignedAmnt = is_aN(tempState.assignedAmount) ? tempState.assignedAmount : this.state.assignedAmount;
        let totalParsedAmnt = is_aN(tempState.totalParsedAmnt) ? tempState.totalParsedAmnt : this.state.totalParsedAmnt;
        let combinationsCount = is_aN(tempState.combinationsCount) ? tempState.combinationsCount : this.state.combinationsCount;
        let leadingID = tempState.leadingPssID ? tempState.leadingPssID : this.state.leadingPssID;
        let leadingCostKey = tempState.leadingPssCostKey ? tempState.leadingPssCostKey : this.state.leadingPssCostKey;
        let shouldResetPSOptions = false;

        let leadingLine = this.isOther && this.isSplitPerc ? data.filter(row=>row[_id] === leadingID)[0] : {};  //only needed for ancillary split
        let leadingLineIndex = this.isOther && this.isSplitPerc ? findIndexOfValue(data, _id, leadingID) : -1;  //only needed for ancillary split

        let unmatchedIndex = -1;
        let unmatchedRow = {};
        let parsedAmnt = 0;
        if(this.isNone) {
            if(!this.isSplitPerc) {
                //create row for NONE exception driver  -- NO SPLIT %
                let tempObjNone = copyObjectValues(this.props.mappedLine);  //tempObj is the leading psl
                tempObjNone[_name] = tempObjNone[_name] ? tempObjNone[_name].replace(_variance, "") : tempObjNone[_name];
                tempObjNone[_pslName] = tempObjNone[_name];
                tempObjNone[_percentage] = !!combinationsCount ? 100 : "";
                tempObjNone[_amount] = parseFloat(assignedAmnt);  //for NONE, the parsed amount = assigned amount
                tempObjNone[RAND] = Math.random();
                tempObjNone[ROW_STATUS.FIELD] = ROW_STATUS.VALUES.NEW;
                delete tempObjNone[_calcCol];

                tempData = [tempObjNone];
            } else {
                //create all split% rows    --  NONE EXCEPTION DRIVER
                data.map((row, i)=>{
                    let tempObj = copyObjectValues(row);
                    tempObj[_name] = tempObj[_name] ? tempObj[_name].replace(_variance, "") : tempObj[_name];
                    tempObj[_pslName] = tempObj[_name];
                    tempObj[_percentage] = is_aN(tempObj[_percentage]) ? parseFloat(tempObj[_percentage]) : data.length === 1 ? 100 : row[_leadingCostkey] = row[_costkey] ?  100 : 0;
                    tempObj[_amount] = is_aN(tempObj[_amount]) ? parseFloat(tempObj[_amount]) : is_aN(assignedAmnt) ? parseFloat(assignedAmnt * tempObj[_percentage] / 100) : 0;
                    tempObj[_leadingID] = leadingID;
                    tempObj[_leadingCostkey] = leadingCostKey;
                    tempObj[RAND] = tempObj[RAND] || Math.random();
                    
                    tempData[i] = tempObj;
                });
            }
        } else if(this.isOther) {
            //create all ANCILLARY MATCHED rows
            let hasColumnUndefined = false;
            data.map((row, i)=>{
                let file = row[_file];
                let fileEntity =  row[_driverType] === 'metric' && _this.props.metricsInfo.filter(e=> e.value === file).length ?  _this.props.metricsInfo.filter(e=> e.value === file)[0].label : file;
                let column = row[_column];
                let fileColumn = fileEntity +" ("+ column + ")";
                let hasFile = !!(file && file.length) || !!(row[_calcCol] && row[_calcCol].length)
                let hasColumn = !!(column && column.length) || row[_mappingException] === _transaction;
                if(parseBoolean(row[_isMatched]) === false) { //when created on the UI and not yet saved in DB, unmatched has isMatched=false
                    unmatchedIndex = i;
                    unmatchedRow = row;
                    
                    return;
                }

                if(!_this.isSplitPerc && (!hasFile || !hasColumn)) {
                    hasColumnUndefined = true;  //without this flag, unmatched would be created before choosing column
                }
                
                let sameFileCols = _this.state.allAncillaryColumns.filter(e=>e[RAW_ITEMS.SUBTYPE_NAME] === row[_file]);
                let currentCol = findOptionByKey(sameFileCols, row[_column]);
                if(!hasColumnUndefined && row[_name]) {
                    shouldResetPSOptions = true;
                    if (_this.props.mappedLines.filter(elt=>elt[_costkey] === row[_costkey]).length>0) {
                        row[_name] = row[_name];
                    }
                }
             
                let tempObj = copyObjectValues(row);
                if(_this.isSplitPerc) {

                    //update amount based on percentage
                    var ancillaryFilter = tryParse(leadingLine[_ancillaryFilter], "");  //if splitting in ancillary, split rows do not have a filter, only the leading line does
                    ancillaryFilter = typeof leadingLine[_ancillaryFilter] === "string" ? ancillaryFilter.filter : ancillaryFilter;
                    var useFilter = !!(ancillaryFilter && ancillaryFilter[0] && ancillaryFilter[0][_columnEngine]);

                    tempObj[_percentage] = is_aN(tempObj[_percentage]) ? parseFloat(tempObj[_percentage]) : _this.getNumberOfNotUnmatched(data) === 1 ? 100 : 0;
                    let rowAmount = useFilter && _this.state.parsedAmounts.length ? _this.state.parsedAmounts.filter(e=>e[ROW_NUMBER] === leadingLineIndex)[0][_amount] : totalParsedAmnt;
                    tempObj[_amount] = is_aN(rowAmount) ? parseFloat(rowAmount) * tempObj[_percentage] / 100 : 0;
                } else {
                    //update percentage based on amount
                    var ancillaryFilter = tryParse(tempObj[_ancillaryFilter], "");
                    ancillaryFilter = typeof tempObj[_ancillaryFilter] === "string" ? ancillaryFilter.filter : ancillaryFilter;
                    var useFilter = !!(ancillaryFilter && ancillaryFilter[0] && ancillaryFilter[0][_columnEngine]);
                    if (useFilter) {
                        tempObj[_amount] = _this.state.parsedAmounts.length > 0 &&  _this.state.parsedAmounts.filter(e=>e[ROW_NUMBER] === i)[0] && _this.state.parsedAmounts.filter(e=>e[ROW_NUMBER] === i)[0][_amount] ? _this.state.parsedAmounts.filter(e=>e[ROW_NUMBER] === i)[0][_amount] : tempObj[_amount];
                    } else {
                        tempObj[_amount] = is_aN(tempObj[_amount]) ? parseFloat(tempObj[_amount]) : is_aN(currentCol[_amount]) ? parseFloat(currentCol[_amount]) : 0;
                        if(tempObj[_mappingException] === _transaction){
                            let selectColName =  tempObj[_file] || tempObj[_calcCol]; 
                            let selectedCalcCol = findOptionByKeyValue(_this.props.calculatedCols, _calcCol, selectColName);
                            tempObj[_amount] = selectedCalcCol ? parseFloat(selectedCalcCol[CALCULATED_COLUMNS.FIELDS.TOTAL_AMOUNT] || 0) : 0;
                        }
                        
                    }
                    tempObj[_percentage] = is_aN(assignedAmnt) ? assignedAmnt === 0 ? 0 : (tempObj[_amount] / assignedAmnt * 100) : 0;
                }
                
                tempObj[_leadingCostkey] = leadingCostKey;
                tempObj[_leadingID] = leadingID;    //updating leading ID here, so in case leading line was deleted, all other lines' leading id becomes the mapped id
                tempObj[_pslName] = tempObj[_name];
                tempObj[_entity] = fileColumn;
                tempObj[RAND] = tempObj[RAND] || Math.random();
                tempObj[_isMatched] = tempObj[_isMatched] ? tempObj[_isMatched] : true;

                parsedAmnt += parseFloat(tempObj[_amount]);
                tempData[i] = tempObj;
            });

            //ANCILLARY UNMATCHED ROW
            if(!hasColumnUndefined) {  //check if containing matched columns, add extra UNMATCHED row
                //tempObj is either the already existing unmatched row, or the leading psl, copying and updating it here
                var tempObjVar = unmatchedIndex > -1 ? copyObjectValues(unmatchedRow) : copyObjectValues(this.props.mappedLine);
              
                if(unmatchedIndex === -1 && !tempObjVar[_name].includes(_variance)) {
                    shouldResetPSOptions = true;
                    tempObjVar[_name] = tempObjVar[_name] + _variance;    //updating name if not yet updated
                    tempObjVar[_returnName] += replaceSpecialChars(_variance);
                    tempObjVar[_driverType] = _ancillary;
                }

                tempObjVar[_pslName] = tempObjVar[_name];
                tempObjVar[_amount] = assignedAmnt - parsedAmnt;    //important to be set before percentage, always has the same calculation
                tempObjVar[_percentage] = assignedAmnt ? (tempObjVar[_amount] / assignedAmnt * 100) : 0;        //always based on the amount
                tempObjVar[RAND] = Math.random();
                tempObjVar[_isMatched] = false;
                tempObjVar[ROW_STATUS.FIELD] = ROW_STATUS.VALUES.NEW;
                
                //we are creating an additional ps line unmatched, set id and costkey to ""
                tempObjVar[_id] = unmatchedIndex > -1 ? tempObjVar[_id] : "";
                tempObjVar[_costkey] = unmatchedIndex > -1 ? tempObjVar[_costkey] : "";
                tempObjVar[_leadingID] = leadingID;
                tempObjVar[_leadingCostkey] = leadingCostKey;
                tempObjVar[_combinations] = [];

                if(unmatchedIndex > -1) {
                    tempData[unmatchedIndex] = tempObjVar;
                } else {
                    tempData.push(tempObjVar);
                }
            }
        } 
        
        let _1stOrder = tempData.map(row=>{return row[_returnName]});
        tempState.exceptionData = !(_this.isSplitPerc && _this.isNone) ? this.sortPSLines(tempData) : tempData;
        let _2ndOrder = tempState.exceptionData.map(row=>{return row[_returnName]});

        //if order changes, reset options
        if(!deepCompareObjects(_1stOrder, _2ndOrder) || this.state.firstRender) {
            shouldResetPSOptions = true;
            tempState.firstRender = false;
        }

        this.setState(tempState, function() {
            if(typeof callback === "function") {
                callback(data);
            }
            
            if(shouldResetPSOptions || tempState.refreshOptions) {
                _this.excMappingRef.resetPSOptions();   //reset options in PSLines options dropdown
            }

        });
    }

    onChangeCostTerm(option) {
        this.setState({
            costTerm:option.cost_term_id
        })
        this.props.onChangeCostTerm(option.cost_term_id, PS_MAPPING.FIELDS.COST_TERM_ID);
    }

    setFilterAttrOptions(rowIndex, attribute, options, subType) {
        var _this = this;
        if(!this.filterModalRef.filterDialRef.state.filterRefs[rowIndex] || !this.filterModalRef.filterDialRef.state.filterRefs[rowIndex].ref.current) {
            return;
        }
        if(attribute === _valueOptions) {
            this.filterModalRef.filterDialRef.state.filterRefs[rowIndex].ref.current.isLoading = false;
        }
        if (attribute === _fileOptions) {
            this.filterModalRef.filterDialRef.state.filterRefs[rowIndex].ref.current.filterObj[ENGINE_FILTER.KEYS.RAW_FILE_SUBTYPE_ID] = subType;
        }
        this.filterModalRef.filterDialRef.state.filterRefs[rowIndex].ref.current.filterObj[attribute] = options;
        let menuIsOpen = this.filterModalRef.filterDialRef.state.filterRefs[rowIndex].ref.current.state.menuIsOpen;
        this.filterModalRef.filterDialRef.state.filterRefs[rowIndex].ref.current.refresh(function() {
            if(menuIsOpen) {
                _this.filterModalRef.filterDialRef.state.filterRefs[rowIndex].ref.current.selectRef.focus();
            }
        });
    }


    handleFilterRowChange(object, attribute, rowIndex, conditionIndex, valueObj, inputValue, tempExceptionData) {
        var _this = this;
        if(_this.state.exceptionData[_this.state.rowNumber][_ancillaryFilter] && _this.state.exceptionData[_this.state.rowNumber][_ancillaryFilter]["filter"] && typeof _this.state.exceptionData[_this.state.rowNumber][_ancillaryFilter]["filter"] !== "function"){
            _this.state.exceptionData[_this.state.rowNumber][_ancillaryFilter] = copyObjectValues(_this.state.exceptionData[_this.state.rowNumber][_ancillaryFilter]["filter"]);
        }
        if (typeof this.state.exceptionData[_this.state.rowNumber][_ancillaryFilter] === "string" && _this.state.exceptionData[_this.state.rowNumber][_ancillaryFilter] !== "") {
            _this.state.exceptionData[_this.state.rowNumber][_ancillaryFilter] = tryParse(_this.state.exceptionData[_this.state.rowNumber][_ancillaryFilter]).filter;
        }
        var tempRow =_this.state.exceptionData[_this.state.rowNumber][_ancillaryFilter] !== "" ? _this.state.exceptionData[_this.state.rowNumber][_ancillaryFilter][rowIndex] : {};
        let callback = ()=>{};
        var tempRow = {};

        if(typeof _this.state.exceptionData[_this.state.rowNumber][_ancillaryFilter] === "string") {
            _this.state.exceptionData[_this.state.rowNumber][_ancillaryFilter] = tryParse(_this.state.exceptionData[_this.state.rowNumber][_ancillaryFilter]);
        }
        
        switch (attribute){
            case _columnEngine: // column change
                //setting the function options
                tempRow =_this.state.exceptionData[_this.state.rowNumber][_ancillaryFilter] !== "" ? _this.state.exceptionData[_this.state.rowNumber][_ancillaryFilter][rowIndex] : {};
                var functionOptions = getObjectAsArray(lang.ui_filter.dropdowns.engine_filter_functions, valueObj[_fieldDataType].toLowerCase(), "value_type");
                _this.setFilterAttrOptions(rowIndex, _functionOptions, functionOptions);
                _this.state.exceptionData[_this.state.rowNumber][_ancillaryFilter][rowIndex][_fieldDataType] = valueObj[_fieldDataType];
                _this.state.exceptionData[_this.state.rowNumber][_ancillaryFilter][rowIndex][_functionOptions] = functionOptions;
                _this.state.exceptionData[_this.state.rowNumber][_ancillaryFilter][rowIndex][_columnEngine] = valueObj.value;
                callback = function(values) {
                    //setting the value options
                    _this.setFilterAttrOptions(rowIndex, _valueOptions, values);
                    _this.state.exceptionData[_this.state.rowNumber][_ancillaryFilter][rowIndex][_valueOptions] = values;
                }
                if (tempRow[_fieldDataType].toLowerCase() !== 'numeric')
                _this.props.getColumnValues(tempRow.raw_file_subtype_id, tempRow[_fieldDataType], tempRow[_columnEngine], _this.state.rowNumber, tempRow[_function], rowIndex, "", callback, tempRow[_fileEngine]);
                break;

            case _logicalOperator: // to enable column drop down when adding new row 
                if (_this.state.exceptionData[_this.state.rowNumber][_ancillaryFilter] !== "") {
                    if(typeof _this.state.exceptionData[_this.state.rowNumber][_ancillaryFilter].filter === "object"){
                        _this.state.exceptionData[_this.state.rowNumber][_ancillaryFilter] = _this.state.exceptionData[_this.state.rowNumber][_ancillaryFilter].filter;
                    }
                }
        
                tempRow =_this.state.exceptionData[_this.state.rowNumber][_ancillaryFilter] !== "" ?  _this.state.exceptionData[_this.state.rowNumber][_ancillaryFilter][rowIndex] : {};
                var options = [];
                
                if(_this.state.exceptionData[_this.state.rowNumber][_driverType] !== _metric) {
                    options = _this.props.allAncillaryColumns.filter(e=>e[RAW_ITEMS.SUBTYPE_NAME] === tempRow[_fileEngine]);
                    options.map(function(el){
                        el.value = el.raw_field_name;
                        el.label = el.raw_field_name;
                    });
                } else {
                    options = copyObjectValues(this.metricFilterOptions_unchanged);
                }
                tempRow[_columnOptions] = options;
                _this.setFilterAttrOptions(rowIndex+1, _fileOptions, this.props.ancillaryFiles, tempRow.raw_file_subtype_id);
                _this.setFilterAttrOptions(rowIndex+1, _fileEngine, tempRow[_fileEngine], tempRow.raw_file_subtype_id);
                _this.setFilterAttrOptions(rowIndex+1, _columnOptions, tempRow[_columnOptions]);
                if (!this.state.exceptionData[_this.state.rowNumber][_ancillaryFilter][rowIndex+1]) {
                    this.state.exceptionData[_this.state.rowNumber][_ancillaryFilter].push(
                        {[_fileEngine]:  tempRow[_fileEngine], [_columnOptions]:tempRow[_columnOptions], raw_file_subtype_id: tempRow.raw_file_subtype_id, [_fileOptions]: this.props.ancillaryFiles}
                    );
                }
        }
    }

    /**
     * This function gets all cc used in setup of a psl
     * @returns 
     */
    getUsedCalculatedColumnsInSetUp = () => {
        let exceptionData = copyObjectValues(this.state.exceptionData);
        let usedCalc = []
        exceptionData.map((item,i) => {
            let columnName = item[_file] || item[_calcCol];
            if(item[_mappingException] === _transaction && item[_isMatched]) {
                let usedCalcObj = {index: i, columnName: columnName}
                usedCalc.push(usedCalcObj);
            }
        })
        return usedCalc;
    }
    /**
     * Based on used calculated columns in setup i am excluding them from options 
     * @param {*} fileOptions 
     * @param {*} usedCalculatedColumns 
     * @param {*} index 
     * @returns 
     */
    excludeCalculatedColumnsInOptions = (fileOptions,usedCalculatedColumns,index) => {
        let files = copyObjectValues(fileOptions);
        let calculatedColumnOptions = files.filter(e=>e[_type] === _calculatedColumn);
        let otherFiles =  files.filter(e=>e[_type] !== _calculatedColumn);
        for(let usedCalc in usedCalculatedColumns){
            let i = usedCalculatedColumns[usedCalc].index;
            if(i !== index){
                let columnName = usedCalculatedColumns[usedCalc].columnName;
                calculatedColumnOptions = calculatedColumnOptions.filter(e=> e[_calcCol] !==  columnName);
            }
        }
        
        return otherFiles.concat(calculatedColumnOptions);
    }
     
    render() {
        let _this = this;
        let buttonTitle = lang.pss_map_exception.button.add_more;
        
        //filling out files and column in array
        let filesAndColumns = [];
        
        const optionLabel = ({value, label, amount}) => {
            //when drawing each dropdown, fetch the file to which the columns belong, and take the amount from there
            let amnt = formatValString(amount, FormatTypes.AMOUNT);
            return <div title={label}>{label}<span className="amnt-opt-span">{"("+amnt+")"}</span></div>
        }

        if(this.isOther) {
            let exceptionMetrics = this.props.exceptionMetrics;
            exceptionMetrics.map(item=> { //value is wrong and label is being undefined after second rerender
                if(!item.label){
                    item.label = item.file_type;
                }
                item.value = item.file_type;
            })
            //Adding calculated cols with ancillary/metric 
            let calculatedCols = copyObjectValues(this.props.calculatedCols);
            calculatedCols.map(item => item.type = _calculatedColumn);
            let allFiles = this.props.ancillaryFiles.concat(exceptionMetrics).concat(calculatedCols);
            let ancillaryFiles = arrangeAncillaryFiles(allFiles);


            const fileOptionLabel = ({ value, label, isDisabled  }) => (
                <div title={label} className={(isDisabled ? "option-group-header option-padding" : "") + " uk-text-overflow-ellipsis"}>
                    {label}
                </div>
            );
            var emptyFilter = {"filter":[{}]};
            _this.state.exceptionData.map((row, i)=>{
                let usedCCInSetUp = this.getUsedCalculatedColumnsInSetUp();
                let files = this.excludeCalculatedColumnsInOptions(copyObjectValues(ancillaryFiles), usedCCInSetUp, i);
                let isTransaction = ([_transaction].includes(_this.state.exceptionData[i][_mappingException]) || ( _this.state.exceptionData[i][_type] && _this.state.exceptionData[i][_type] === _calculatedColumn));
                var definedFilter = ![undefined,""].includes(row[_ancillaryFilter]);
                var jsonFilter = definedFilter ? typeof row[_ancillaryFilter] === "object" ? row[_ancillaryFilter][0] : tryParse(row[_ancillaryFilter], emptyFilter).filter[0] : emptyFilter.filter[0];
                var isEmptyFilter = definedFilter ? !jsonFilter[_columnEngine] || jsonFilter[_columnEngine] === "" : true;

                var filterStatement = !isEmptyFilter ? lang.pss_map_exception.button.edit_filter : lang.pss_map_exception.button.add_filter;
                var filterTextLinkColor = definedFilter ? !isEmptyFilter ? "red" : "" : "uk-disabled text-grey";
                var cursorNotAllowed = definedFilter ? !isEmptyFilter ? "red " : "" : "cursorNotAllowed ";
                let isHidden = isTransaction ? "visibility-none" : "";
                var isMappedRow = row[_id] && row[_id] === _this.state.mappedPssID;
                var isLeadingRow = row[_id] && row[_id] === _this.state.leadingPssID;

                if(row[_isMatched] === false || (_this.isSplitPerc && !isLeadingRow)) return;   //render the file and column of the leading ps line

                //this is the index of the row that has a file and column, if split, it should be the leading only, else normal index
                let rowFile = _this.state.exceptionData[i][_file] || _this.state.exceptionData[i][_calcCol];
                let unstagedMetric = false;
                let periods = typeof _this.props.timePeriod === "object" ? this.props.timePeriod : [this.props.timePeriod];
                if (_this.state.exceptionData[i][_driverType] === _metric && exceptionMetrics.filter(r=>r.value === rowFile).length > 0) {
                    for (var elt in periods) {
                        if (exceptionMetrics.filter(r=>r.value === rowFile)[0].period_status[periods[elt]] && exceptionMetrics.filter(r=>r.value === rowFile)[0].period_status[periods[elt]].toUpperCase() !== 'STAGED') {
                            unstagedMetric = true;
                            break;
                        }
                    }
                }
                ancillaryFiles.map(item => {
                    if(item.type === "metric"){
                        item.label = this.props.metricsInfo.filter(e => e.value === item.value).length ? this.props.metricsInfo.filter(e => e.value === item.value)[0].label :  item.label;
                    }
                })
                // var unstagedMetric = _this.state.exceptionData[i][_driverType] === _metric && _this.props.exceptionMetrics.filter(r=>r.value === rowFile).length > 0
                //         && _this.props.exceptionMetrics.filter(r=>r.value === rowFile)[0].period_status[_this.props.timePeriod].toUpperCase() !== 'STAGED';
                filesAndColumns.push(
                    <div className="exception-driver-delete-empty-space uk-margin-small-bottom uk-display-inline-flex uk-flex-middle justify-content-end" key={"file_col_"+_this.state.exceptionData[i][RAND]}>
                        <div className="uk-margin-small-left width-150">
                            <DropDown
                                className="uk-display-inline-block uk-width-small input__dropdown"
                                classNamePrefix="exception-driver"
                                name="ancillary-files"
                                value={findOptionByKey(files, rowFile)}
                                options={addRowNumberToOptions(files, i)}
                                formatOptionLabel={fileOptionLabel}
                                onChange={(option)=>_this.onChangeFile(_file, option)}
                                type={DROPDOWN_TYPE.INPUT}
                            />
                        </div>
                         <div className="uk-margin-small-left width-150">
                            <DropDown
                                className="uk-display-inline-block uk-width-small input__dropdown"
                                classNamePrefix="exception-driver"
                                name="ancillary-columns" 
                                value={_this.ancillaryColumns ? findOptionByKey(_this.ancillaryColumns[rowFile], _this.state.exceptionData[i][_column]) : ""}
                                options={_this.ancillaryColumns ? addRowNumberToOptions(_this.ancillaryColumns[rowFile], i) : []}
                                onChange={(option)=>_this.handleChangeAttribute(_column, option)}
                                formatOptionLabel={optionLabel}
                                type={DROPDOWN_TYPE.INPUT}
                                disabled={isTransaction}

                            />
                        </div>
                        <div className="uk-margin-small-left uk-display-inline-block">
                        { unstagedMetric ?
                            <div style={{width: convertPxToViewport(11)}}><i className="fas fa-exclamation pi-text-yellow fa-lg" uk-tooltip={lang.not_staged_metric}></i></div> : 
                            <div style={{width: convertPxToViewport(7)}}></div>
                        }
                        </div>
                        
                        <div className={ cursorNotAllowed + isHidden + " uk-margin-default-left text-link"}>
                            <a className={filterTextLinkColor} rownumber={i} onClick={_this.showFilter}>{filterStatement}</a>
                        </div>
                        {!isMappedRow && this.isMultipleOther ?
                            <div className="uk-margin-default-left uk-button-icon transparent-bg" rownumber={i} onClick={_this.deleteRow}>
                                <i className="fal fa-trash-alt fa-lg"></i>
                            </div>
                        : <div className="if-empty-hide uk-margin-xsmall-left"></div>}
                    </div>
                );
            });
        }
        
        return (
            <React.Fragment>
                    {(this.isOther && !this.isSplitPerc) ?
                    <Button 
                        variant={BUTTON_VARIANT.SECONDARY}
                        size={SIZES.MEDIUM}
                        type={BUTTON_TYPE.DEFAULT}
                        className="uk-button-icon uk-margin-xmedium-bottom"
                        label={buttonTitle}
                        data-dismiss="modal"
                        leftIcon={ <i className="fa-lg far fa-plus-circle uk-margin-small-right" aria-hidden="true"></i>}
                        onBtnClick={this.handleButtonClick}
                    />
                    : ""}
                    <div id="exception-titles" className="uk-display-inline-flex uk-width-1-1 justify-content-between" style={{paddingRight:convertPxToViewport(145)}}>
                        <div className="fs-14 width-150 uk-display-inline-flex align-items-center">
                            {lang.pss_map_exception.titles.exception_driver}
                        </div>    
                        {this.isOther ?
                            <React.Fragment>
                                <div>
                                    <div className="fs-14 width-150 uk-margin-small-left uk-display-inline-flex align-items-center">{lang.pss_map_exception.titles.file}</div>
                                    <div className="fs-14 width-150 uk-margin-small-left uk-display-inline-flex align-items-center">{lang.pss_map_exception.titles.column}</div>
                                </div>
                            </React.Fragment>
                        :""}
                    </div>
                
                    <div id ="exception-values" className="uk-display-block">
                        <div className="uk-display-inline-flex uk-flex-column pi-position-relative w100 uk-margin-xsmall-top" style={{paddingBottom: convertPxToViewport(35)}}>
                            <DropDown
                                id="select-map-exception"
                                className="width-150 uk-position-absolute input__dropdown"
                                name="map-exception-list" 
                                placeholder="None"
                                value={findOptionByKey(this.state.mapExceptionList, this.state.exceptionValue)}
                                options={this.state.mapExceptionList}
                                onChange={(option)=>this.onChangeException(option)}
                                type={DROPDOWN_TYPE.INPUT}
                            />
                            { this.isOther ?
                                filesAndColumns
                            :""}
                        </div>   
                    </div>
                    
                    <PSLExceptionMapping 
                        ref={r=>this.excMappingRef=r}
                        isNone={this.isNone}
                        isOther={this.isOther}
                        isSplitPerc={this.isSplitPerc}
                        isMultipleOther={this.isMultipleOther}
                        exceptionData={sortMappedLines(this.state.exceptionData)}
                        exceptionPSLOptions={this.state.exceptionPSLOptions}
                        assignedAmount={this.state.assignedAmount}
                        combinationsCount={this.state.combinationsCount}
                        leadingPssID={this.state.leadingPssID}
                        mappedPssID={this.state.mappedPssID}
                        handleChangeAttribute={this.handleChangeAttribute}
                        splitPercentage={this.splitPercentage}
                        deleteRow={this.deleteRow}
                        profitStackFields = {this.props.profitStackFields}
                        stagingReport = {this.props.stagingReport}
                        mappedLines={this.props.mappedLines}
                    />

                    <EngineFilterModal 
                        ref={el => this.filterModalRef = el}
                        scenario={this.props.scenario}
                        file={this.state.filterFileLabel}
                        column={this.state.filterColLabel}
                        filter={this.filter}
                        handleFilterRowChange={this.handleFilterRowChange}
                        discardFilter={this.discardFilter}
                        saveFilter={this.saveFilter}
                        clearFilter={this.clearFilter}
                        stagingReport={STAGING_SECTIONS.PROFIT_STACK_MAPPING}
                    />
            </React.Fragment>
        );
    }
}


export default ExceptionDriver;