import React, { Component } from "react";
import '../styles/manageColumns.css';
import '../styles/manageColumns.css';
import { getTranslationFile } from '../class/utils';
import { BUTTON_TYPE, BUTTON_VARIANT, MANAGE_ACCESS, SIZES } from "../class/constants";
import { convertPxToViewport } from "../class/formatting";
import Button from "../newComponents/Button";
import Input from "../newComponents/Input";
import CheckBox from "../newComponents/CheckBox";
/**
 * Dynamic List Panel section to be used with all fields sent as props
 * @author [Mostafa Haydar]
 */
const $ = require('jquery');
const lang = getTranslationFile();

class SidePanel extends Component {
    constructor(props) {
        super(props);
        this.state={
        }

    }

    renderPanel(inputs, buttons, notifyByEmailBool, title){
        let elementPanel = [];
        elementPanel = inputs.map((item, index) =>{
            return(
                <div>
                    <div key={"div"+index} style={{marginTop: convertPxToViewport(20)}}>
                        <h4>{item.name}</h4>
                        {item.type==="input"?
                        <Input key={"Panel"+index} style={{width:item.size === "full"? '80%' : '40%', height: convertPxToViewport(40)}} value={item.name}></Input>
                        : <select key={"Panel"+index} style={{width:item.size === "full"? '80%' : '40%', height: convertPxToViewport(40)}} value={item.name} ></select>
                        }
                    </div>
                </div>
            );
        });
        return elementPanel;
    }

    toggleBoard(close=false) {
        if (!close) {
            this.boardRef.classList.toggle("is-board-open");   
            $("h4").css("text-align", "left");  
        } else{
            let willShow = this.boardRef.classList.contains("is-board-open");
            if (willShow) {
                this.boardRef.classList.toggle("is-board-open");
            }
        }
    }

    render() {
        return (
            <div ref={r=>this.boardRef=r} id="manage-columns-board" className={`${this.props.largeDisplay ? "manage-columns-board-large" : ""} uk-border uk-padding-modal-container`}>
                <div className={`${this.props.tab === MANAGE_ACCESS.USER || this.props.hideCancel ? "" : "uk-overflow-auto uk-height-1-1"} inner-container-padding`}>
                    <div className="uk-flex uk-flex-between uk-flex-middle">
                        <div>
                            <h4 className="manage-columns-title">
                                {this.props.title}
                                {this.props.showTooltipMessage ? 
                                    <i className="fal fa-info-circle uk-margin-small-left info-icon" uk-tooltip={this.props.showTooltipMessage} /> 
                                    : ""}
                            </h4>   
                        </div>
                        <Button  
                            variant={BUTTON_VARIANT.TERTIARY}
                            size={SIZES.ICON}
                            type={BUTTON_TYPE.DEFAULT}
                            className="close-button uk-margin-remove"
                            onBtnClick={this.props.onCancel} 
                            aria-label="Close"
                            leftIcon={<span aria-hidden="true"><i className="fal fa-times"></i></span>}
                        />
                    </div>
                    <div>
                        {this.props.body}
                    </div>
                </div>
                <div className="uk-flex uk-flex-right uk-margin-default-top">
                    {this.props.tab === MANAGE_ACCESS.USER && this.props.uid === undefined
                        ? 
                        <label className={this.props.fromManageAccess ? "uk-flex-inline uk-flex-middle uk-margin-small-right uk-cursor-pointer uk-width-1-1 disabled" : "uk-flex-inline uk-flex-middle uk-margin-small-right uk-cursor-pointer uk-width-1-1"} htmlFor="notify-by-email">
                            <CheckBox id={"notify-by-email"} onChange={(e)=>this.props.updateUserFields(e,lang.manage_access.NOTIFY_USER)} 
                                checked={true} labelText={lang.manage_access.notify_by_email} disabled={true} labelClassName={"fs-14 uk-margin-xsmall-right"} />
                        </label> 
                        :
                        ""
                    }
                     <Button 
                        id="manage-columns-cancel" 
                        label={lang.modal.buttons.cancel}
                        variant={BUTTON_VARIANT.SECONDARY}
                        size={SIZES.DEFAULT}
                        type={BUTTON_TYPE.DEFAULT}
                        className="uk-modal-close uk-margin-default-right"
                        onBtnClick={this.props.onCancel}
                    />
                    <Button 
                        label={this.props.isSubmitButtonText ? (lang.modal.buttons.submit): this.props.isApplyButtonText ? lang.modal.buttons.apply : lang.modal.buttons.save}
                        variant={BUTTON_VARIANT.PRIMARY}
                        size={SIZES.DEFAULT}
                        type={BUTTON_TYPE.DEFAULT}
                        disabled={this.props.buttonsDisabled && this.props.buttonsDisabled.includes(lang.modal.buttons.save)}
                        onBtnClick={(this.props.buttonsDisabled && this.props.buttonsDisabled.includes(lang.modal.buttons.save)?undefined:()=>this.props.onSave())}
                    />
                </div>
            </div>  
        );
    }
}

export default SidePanel;
