import React, { Component } from "react";
import "../../styles/common.css";
import VectorDefinitionsTable from "./VectorDefinitionsTable.js";

class VectorDefinitions extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <VectorDefinitionsTable
        ref={(el) => (this.childRef = el)}
        user_email={this.props.userSettingsState?.user?.email}
        user={this.props.userSettingsState?.user}
        userAllowedSections={this.props?.userAllowedMenuLinks}
        scenario={this.props.scenarioState?.scenario}
        scenariosData={this.props.scenarioState?.scenarioObjects[0]}
        reportTitle={this.props?.reportTitle}
      />
    );
  }
}

export default VectorDefinitions;
