import React, { useEffect, useState } from "react";
import HttpsRedirect from "react-https-redirect";
import { toggleLoader } from "../../class/common";
import { BUTTON_TYPE, BUTTON_VARIANT, MFA_REPORTS, SIZES } from "../../class/constants";
import { saveCookie } from "../../class/jqueries";
import firebase, { auth } from "../../firebase/firebase";
import { Loader } from "../../form/elements";
import { lang } from '../../language/messages_en';
import {browserSessionPersistence, multiFactor, PhoneAuthProvider, PhoneMultiFactorGenerator, RecaptchaVerifier, setPersistence, signOut, signInWithEmailAndPassword, getMultiFactorResolver, AuthErrorCodes} from 'firebase/auth';
import LogoPI from "../../LogoPI";
import "../../styles/login.css";
import Button from "../../newComponents/Button";
import Input from "../../newComponents/Input";
import {onEnterClick} from "../../class/utils";

const $ = require('jquery');
var reCaptchaVerifier2;
const VerifyCode = (props) => {

  const [sentCodeId, setCodeId] = useState();
  const [last2Num, set2LastNum] = useState();
  const [hiddenCountry, setHiddenCountry] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [verificationId, setVerificationId] = useState()
  const [resolver, setResolver] = useState()
 

  useEffect(() => {
    $(window).on('beforeunload', function(){
     signOut(auth);
    });
    const hiddenCountry = props.phone.slice(0, -2).replace(/[^0-9 ]/g, '').replace(/\S/g, "X"); //show the number hidden but formatted
    setHiddenCountry(hiddenCountry);
    const last2Str = String(props.phone).slice(-2);
    set2LastNum(last2Str);
    $("#resend").addClass("disabled");
    setTimeout(()=> {
      $("#resend").removeClass("disabled");
    }, 62000);
    auth.useDeviceLanguage();
    reCaptchaVerifier2 = new RecaptchaVerifier("resend", {
      'size': "invisible",
      'callback': (response) => {
        reSendVerificationCode();
      },
    }, auth);
    reCaptchaVerifier2.render();
    window.addEventListener("keydown",onEnterEvent);
    $('#verification-code').focus();
  },[]);

  const onEnterEvent = (event) => {
    // If the user presses the "Enter" key on the keyboard
    onEnterClick(event,"verify");
  }

    const focusInput = () => {
    $('#verification-code').on('blur', function () {
      var blurEl = $(this);
      setTimeout(function () {
        blurEl.focus()
      }, 10);
    });
    }

    const reSendVerificationCode = () => {
      setErrorMessage("");
      $("#verification-code").text("");
      $("#verification-code").val("");
      $('#verification-code').blur();
      const phoneAuthProvider = new PhoneAuthProvider(auth);
      if (props.fromLogin){
        saveCookie("user_email", props.email);
        setPersistence(auth, browserSessionPersistence)
        .then((result) => {          
          signInWithEmailAndPassword(auth, props.email, props.password)
          .catch(function (error) {
            // Handle Errors here
              if (error.code === AuthErrorCodes.MFA_REQUIRED) {
                var resolver = getMultiFactorResolver(auth, error);
              var phoneInfoOptions = {
                multiFactorHint: resolver.hints[0],
                session: resolver.session,
              };
             
              // Send SMS verification code
              phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, reCaptchaVerifier2)
                .then(function (verificationId) {
                  setVerificationId(verificationId)
                  setResolver(resolver)
							})
            }
          })
        })
      } else {
        let phoneNumber = props.phone;
        const multiFactorUser = multiFactor(auth.currentUser);
        multiFactorUser.getSession()
          .then(function (multiFactorSession) {
            // Send verification code
           
            var phoneInfoOptions = {
              phoneNumber: phoneNumber,
              session: multiFactorSession,
            };
            return phoneAuthProvider.verifyPhoneNumber(
              phoneInfoOptions,
              reCaptchaVerifier2
            );
          })
          .then(function (verificationId) {
            setCodeId(verificationId);
          })
          .catch(function () {
            grecaptcha.reset();
          });
        }
      $("#resend").addClass("disabled");
      setTimeout(()=> {
        $("#resend").removeClass("disabled");
      }, 62000);
    };

    const confirmCode = () => {
      setErrorMessage("");
      toggleLoader(true, "verification");
      var verificationCode = $("#verification-code").val().trim();
      if (props.fromLogin && !props.isReEnroll){
        var verificationId2 = verificationId ? verificationId : props.verificationId;
        var resolver2 = resolver ? resolver : props.resolver;
        const cred = PhoneAuthProvider.credential(verificationId2, verificationCode);

        const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
        // Complete sign-in.
        resolver2.resolveSignIn(multiFactorAssertion)
        .then(function (userCredential) {
          // User successfully signed in with the second factor phone number.
          props.setVerified(true);
          if(props.goToMultiClients){
            props.goToMultiClients();
          }
          // toggleLoader(false, "verification");
        }).catch(function (error){
          setErrorMessage(lang.mfa.code_error);
          grecaptcha.reset();
          toggleLoader(false, "verification");
        })
      } else { //enroll
        const multiFactorUser = multiFactor(auth.currentUser);
        var verificationId1 = sentCodeId ? sentCodeId : props.verificationId;
        const phoneAuthCredential = PhoneAuthProvider.credential(verificationId1, verificationCode);
        var multiFactorAssertion =  PhoneMultiFactorGenerator.assertion(phoneAuthCredential);
        multiFactorUser.enroll(multiFactorAssertion, 'My personal phone number')
          .then(function () {
            if(!props.isReEnroll){
              props.onNextClick("", MFA_REPORTS.VERIFY_CODE);
            } else {
              props.setVerified(true);
            }
            $(window).off('beforeunload');
            if(!props.fromLogin && !props.firstSignin){
              signOut(auth);
            }
            if(props.goToMultiClients){
              props.goToMultiClients();
            }
          })
          .catch(function (error) { 
            setErrorMessage(lang.mfa.code_error);
            grecaptcha.reset();
            toggleLoader(false, "verification");
          });
        }
        $('#verification-code').focus();
      }
      

    const startBack = () => {
      if(props.isUnified && props.fromLogin) {
        window.location.reload();
      } else {
        props.onBackClick("", MFA_REPORTS.VERIFY_CODE);
        props.setPhone(props.phone);
      }
    }

  return (
    <HttpsRedirect>
      <Loader/>
      <main id="body" className="main-body">
      <header className="login-header">
        <LogoPI />
        </header>
        <section className="container login-container">
          <div id="phoneNumberForm" className="login-form mrgt5 mrgb10">
          {props.fromLogin && !props.isUnified ? "" : 
                  <Button 
                      id="header-back"
                      label={lang.mfa.back}
                      variant={BUTTON_VARIANT.TERTIARY}
                      size={SIZES.DEFAULT}
                      type={BUTTON_TYPE.DEFAULT}
                      className="verify_back"
                      onBtnClick={startBack}
                      leftIcon={<i class="fas fa-arrow-left"></i>} 
                  />
                 }
            <span className="verify_code_title">{lang.mfa.verify_code.title}</span>
            <div className="mrgt10 for_container_text">For <b>{props.email}</b></div>
            <section className="mrgb15">
            <div className="uk-text-meta verify_code_text">
              {lang.mfa.verify_code.sub_title}{hiddenCountry+last2Num}{lang.mfa.verify_code.sub_title_2} 
            </div>
              <div className="">
                <label className="col-12 mrgl0">{lang.mfa.verification_code_label}</label>
                <div className="col-12">
                  <Input className="" placeholder="Verification Code" id="verification-code" type={'text'} autoFocus={true} onBlur={focusInput()}></Input>
                  <p className="text-danger">{errorMessage}</p>
                </div>
              </div>
              <div className="verify_resend_container">
                <div className="col-12">
                <Button 
                      id="header-back"
                      label={lang.mfa.verify}
                      variant={BUTTON_VARIANT.PRIMARY}
                      size={SIZES.LARGE}
                      type={BUTTON_TYPE.DEFAULT}
                      className="max_width"
                      onBtnClick={confirmCode}
                  />
                 <div title="Resend" className="resend_code" >
                    {/* <button id="resend">{lang.mfa.resend}</button> */}
                    <Button 
                      id="resend"
                      label={lang.mfa.resend}
                      variant={BUTTON_VARIANT.TERTIARY}
                      size={SIZES.LARGE}
                      type={BUTTON_TYPE.DEFAULT}
                      className="max_width"
                      // onBtnClick={confirmCode}
                  />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </main>
    </HttpsRedirect>
  );
};

export default VerifyCode;
