import {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import DraggableList from "../../draggable/DraggableList";
import {
    copyObjectValues,
    getColumnName,
    getDistinctOptions,
    getFormattedDraggableOptions,
    sortArrayOfObjects
} from "../../../class/utils";
import {column_suffixes_values, MANAGE_COLUMNS, PROFILE_COLUMN} from "../../../class/constants";

const ColumnsReorder = ({checkedItems, profileColumns, setHasUnsavedChanges, setProfileModified, setOrderedColumns}, ref) => {
    useImperativeHandle(ref, () => ({
        getOrderedOptions: () => {
            return internalCheckedItems;
        },
    }));

    const [internalCheckedItems, setInternalCheckedItems] = useState(checkedItems);
    const selectedVectorObjects = checkedItems?.filter((f) => f.column_return_name === MANAGE_COLUMNS.SELECTED_VECTOR_MACHINE_NAME);

    useEffect(() => {
        setInternalCheckedItems(checkedItems);
    }, [checkedItems]);

    const updateNewCheckedItemsOptions = (updatedOptions = []) => {
        updatedOptions.map((item, index) => {
            item.column_order = item.order;
            return item;
        });
        let finalOptions = updatedOptions.concat(selectedVectorObjects);
        setInternalCheckedItems(finalOptions);
        setOrderedColumns(finalOptions);
        setHasUnsavedChanges(true)
        setProfileModified(true);
    };
    /**
     * Add prefix before column name in Reorder Tab
     * @param item
     * @return {string}
     */
    const getPrefix = (item) => {
        const obj = {
            "maximum": column_suffixes_values.maximum.label,
            "minimum": column_suffixes_values.minimum.label,
            "range": column_suffixes_values.range.label,
            "average": column_suffixes_values.average.label,
            "median": column_suffixes_values.median.label
        };
        if (obj[item.machine_name] === undefined) {
            return "";
        }
        return obj[item.machine_name] + " ";
    }
    /**
     * Add suffix before column name in Reorder Tab
     * @param item
     * @return {string}
     */
    const getSuffix = (item) => {
        const obj = {"per_total": column_suffixes_values.per_total.label, "per_selection": column_suffixes_values.per_selection.label};

        if (obj[item.machine_name] === undefined) {
            return "";
        }

        return obj[item.machine_name];
    }

    const getFinalFormattedOptions = () => {
        let options = copyObjectValues(internalCheckedItems)?.filter((f) => f.column_return_name !== MANAGE_COLUMNS.SELECTED_VECTOR_MACHINE_NAME);
        let vectorsArray = options.filter((f) => f.type === MANAGE_COLUMNS.VECTOR_MACHINE_NAME);
        let nonVectorsArray = options.filter((f) => f.type !== MANAGE_COLUMNS.VECTOR_MACHINE_NAME);
        if (vectorsArray.length > 0) {
            let distinctVectorsArray = getDistinctOptions(vectorsArray, MANAGE_COLUMNS.COLUMN_RETURN_NAME);
            options = nonVectorsArray.concat(distinctVectorsArray);
        }
        options = getFormattedDraggableOptions(
            options.map((item) => {
                item.column_name = getPrefix(item) + getColumnName(item.column_return_name, profileColumns, checkedItems) + getSuffix(item);
                return item;
            }),
            MANAGE_COLUMNS.COLUMN_NAME,
            PROFILE_COLUMN.COLUMN_ORDER
        );
        options = sortArrayOfObjects(options, "order", "asc");
        return options;
    };

    return <DraggableList options={getFinalFormattedOptions()} updateOptions={updateNewCheckedItemsOptions}/>;
};

export default forwardRef(ColumnsReorder);