const defaultUserSettings = new Map();
const defaultUserSections = new Map();

function userSettings(state=defaultUserSettings, action) { //reducer
    switch (action.type) {
        case "UPDATE_USERSETTINGS":
            return {...state, userSettings:action.userSettings}
        case "GET_USERSETTINGS":
          return state;
        default:
        return state;
    }
}

function userSectionsData(state=defaultUserSections, action) { //reducer
    switch (action.type) {
        case "UPDATE_USERSECTIONSDATA":
            return {...state, userSectionsData:action.userSections}
        case "GET_USERSECTIONS":
          return state;
        default:
        return state;
    }
}
  
export {userSettings, userSectionsData};