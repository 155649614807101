import { data } from "jquery";
import React, { Component } from "react";
import {MANAGE_ACCESS, ROLE_ACCESS} from "../class/constants";
import {lang} from "../language/messages_en"
import Input from "../newComponents/Input";
import CheckBox from "../newComponents/CheckBox";
/**
 * Dynamic role Access component to be used with all fields sent as props
 * @author [Mostafa Haydar]
 */

class RoleAccess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filteredText:"",
            accessGroupsData: this.props.accessGroupsData
        }
        this.search=this.search.bind(this);
        this.extractIds = this.extractIds.bind(this);
    }

    componentDidMount(){
        this.setState({
            accessGroupsData: this.props.accessGroupsData
        })
    }

    search(e){
        const _this = this;
        clearTimeout(this.searchTimeout);
        let inputValue = e.target.value;

        this.searchTimeout = setTimeout(()=>{
            _this.setState({
                filteredText: inputValue
            });
        }, 500);
    }

    checkAccess(checked,name){
        var data = this.state.accessGroupsData;
        for(var e in data){
            if(data[e].access_group === name){
                data[e].checked=checked;
            }
        }
        this.setState({
            accessGroupsData:data
        })
    }

    extractIds(){
        var accessGroups = this.state.accessGroupsData;
        var ids = [];
        for (var e in accessGroups) {
            if (accessGroups[e].checked) {
                ids.push({id:accessGroups[e].id.toString()});
            }
        }
        return ids;
    }

    render() {
        var obj = this;
        let tooltipText = "";
        var groups = this.state.accessGroupsData.sort((a, b) => ((a[MANAGE_ACCESS.FIELDS.ACCESS_GROUP]) > (b[MANAGE_ACCESS.FIELDS.ACCESS_GROUP])) ? 1 : -1)
        return (
            <div>
                <Input id={lang.manage_access.SELECT_ACCESS_GROUP_ID} onChange={this.search} className="uk-input" placeholder={lang.manage_access.SEARCH_ACCESS_GROUP} isSearch = {true}></Input>
                <div className="role_table_container">
                    <div className="uk-flex uk-flex-between uk-margin-default-top border-bottom-light-grey uk-text-xmedium text-grey role_table_header">
                        <span style={{marginLeft:"0.4vw"}} className="uk-flex-inline width-200">{ROLE_ACCESS.ACCESS_GROUP}</span>
                        <span className="width-75 uk-flex uk-flex-center">{ROLE_ACCESS.FEATURES}</span>
                        <span className="width-75 uk-flex uk-flex-center">{ROLE_ACCESS.PROFITSTACK}</span>
                        <span className="width-75 uk-flex uk-flex-center">{ROLE_ACCESS.VECTOR}</span>
                    </div>
                    <div className="role-container">
                    { groups && groups.map(function(item,index){
                        tooltipText = "Access Group&#58 "
                        tooltipText += item.access_group
                        tooltipText += " <br> Description&#58 "
                        tooltipText += item.description === ""? "None" : item.description;
                        tooltipText += "<br> Type&#58" +item.type;
                        tooltipText += " <br> status&#58 " + item.status;
                            return ( 
                            <div>
                                {obj.state.filteredText && obj.state.filteredText !== "" ?
                                    item.access_group.toLowerCase().includes(obj.state.filteredText.toLowerCase()) &&
                                    item.status === MANAGE_ACCESS.ACTIVE || (item.status !== MANAGE_ACCESS.ACTIVE && item.checked) ?
                                    <div className="uk-flex uk-flex-between uk-flex-middle role-access-group-padding border-bottom-light-grey role_table_row">
                                        <div className="uk-flex uk-flex-middle width-200">
                                            <CheckBox checked={item.checked} onChange={()=>{obj.checkAccess(!item.checked,item.access_group)}} /> 
                                            <span key={"list-item-"+index}></span>
                                            {item['status']===MANAGE_ACCESS.ACTIVE?
                                                <span className="uk-margin-xsmall-left access-group-name-max-width role_table_name" uk-tooltip={tooltipText}>
                                                    {item.access_group.substr(0, item.access_group.toLowerCase().indexOf(obj.state.filteredText.toLowerCase()))}
                                                    <span className="search-value-highlight">{item.access_group.substr(item.access_group.toLowerCase().indexOf(obj.state.filteredText.toLowerCase()), obj.state.filteredText.length)}</span>
                                                    {item.access_group.substr(item.access_group.toLowerCase().indexOf(obj.state.filteredText.toLowerCase()) + obj.state.filteredText.length)}
                                                </span>
                                            :
                                                <span className="uk-margin-xsmall-left access-group-name-max-width role_table_name" uk-tooltip={tooltipText}
                                                style = {{textDecoration: 'line-through', color:'red'}}>
                                                    {item.access_group.substr(0, item.access_group.toLowerCase().indexOf(obj.state.filteredText.toLowerCase()))}
                                                    <span className="search-value-highlight">{item.access_group.substr(item.access_group.toLowerCase().indexOf(obj.state.filteredText.toLowerCase()), obj.state.filteredText.length)}</span>
                                                    {item.access_group.substr(item.access_group.toLowerCase().indexOf(obj.state.filteredText.toLowerCase()) + obj.state.filteredText.length)}
                                                </span>
                                            }
                                        </div>
                                        <div className="width-75 uk-flex uk-flex-center">
                                            {item.type.split(",").indexOf(ROLE_ACCESS.FEATURES)>-1 ?
                                            item.checked ? <i className="fa-lg fal fa-check text-blue"></i> : <i className="fa-lg fal fa-check text-grey"></i>
                                            : ""}
                                        </div>
                                        <div className="width-75 uk-flex uk-flex-center">
                                            {item.type.split(",").indexOf(ROLE_ACCESS.PROFITSTACK1)>-1 || item.type.split(",").indexOf(ROLE_ACCESS.PROFITSTACK2)>-1?
                                            item.checked ? <i className="fa-lg fal fa-check text-blue"></i> : <i className="fa-lg fal fa-check text-grey"></i>
                                            : ""}
                                        </div>
                                        <div className="width-75 uk-flex uk-flex-center">
                                            {item.type.split(",").indexOf(ROLE_ACCESS.VECTOR)>-1 ?
                                            item.checked ? <i className="fa-lg fal fa-check text-blue"></i> : <i className="fa-lg fal fa-check text-grey"></i>
                                            : ""}
                                        </div>
                                    </div>
                                    : ""
                                : 
                                item.status === MANAGE_ACCESS.ACTIVE || (item.status !== MANAGE_ACCESS && item.checked) ?  
                                <div className="uk-flex uk-flex-between uk-flex-middle role-access-group-padding border-bottom-light-grey role_table_row">
                                    <div className="uk-flex uk-flex-middle width-200">
                                        <CheckBox checked={item.checked} onChange={()=>{obj.checkAccess(!item.checked,item.access_group)}}/>
                                        <span key={"list-item-"+index}></span>
                                        <span uk-tooltip={tooltipText}>
                                            {item.status=== MANAGE_ACCESS.ACTIVE ?
                                            <span className="uk-margin-xsmall-left access-group-name-max-width role_table_name">{item.access_group}</span>
                                            : item.checked ?
                                                <span className="uk-margin-xsmall-left access-group-name-max-width role_table_name" style={{textDecoration: 'line-through', color:'red'}}>{item.access_group}</span>
                                                :""
                                            }
                                        </span>
                                    </div>
                                    <div className="width-75 uk-flex uk-flex-center">
                                        {item.type.split(",").indexOf(ROLE_ACCESS.FEATURES)>-1 ?
                                        item.checked ? <i className="fa-lg fal fa-check text-blue"></i> : <i className="fa-lg fal fa-check text-grey"></i>
                                        : ""}
                                    </div>
                                    <div className="width-75 uk-flex uk-flex-center">
                                        {item.type.split(",").indexOf(ROLE_ACCESS.PROFITSTACK1)>-1 || item.type.split(",").indexOf(ROLE_ACCESS.PROFITSTACK2)>-1 ?
                                        item.checked ? <i className="fa-lg fal fa-check text-blue"></i> : <i className="fa-lg fal fa-check text-grey"></i>
                                        : ""}
                                    </div>
                                    <div className="width-75 uk-flex uk-flex-center">
                                        {item.type.split(",").indexOf(ROLE_ACCESS.VECTOR)>-1 ?
                                        item.checked ? <i className="fa-lg fal fa-check text-blue"></i> : <i className="fa-lg fal fa-check text-grey"></i>
                                        : ""}
                                    </div>
                                </div>
                                : ""
                                }
                            </div>
                            );
                    })}
                    </div>
                 </div>
             </div>
        );
    }
}

export default RoleAccess;