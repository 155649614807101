import React, {Component} from 'react';

class DateRange extends Component {
    constructor(props) {
        super(props);

        let startYear = this.props.getStartYear();
        let endYear = startYear + 1;

        this.state = {
            startYear: startYear,
            endYear: endYear,
            isLeftArrowDisabled: startYear <= props.minYear,
            isRightArrowDisabled: endYear >= props.maxYear
        }
    }

    /**
     * Handles the click on arrows,to increment or decrement date
     * Disables arrows if max or min year reached
     * @param isIncrement
     */
    updateDateRange = (isIncrement) => {

        let startYear = this.state.startYear;
        let endYear = this.state.endYear;

        let isLeftArrowDisabled = false;
        let isRightArrowDisabled = false;

        if (isIncrement) {
            startYear = startYear + 1;
            endYear = endYear + 1;
            isRightArrowDisabled = endYear >= this.props.maxYear;
        } else {
            startYear = startYear - 1;
            endYear = endYear - 1;
            isLeftArrowDisabled = startYear <= this.props.minYear
        }

        this.setState({
            startYear: startYear,
            endYear: endYear,
            isLeftArrowDisabled: isLeftArrowDisabled,
            isRightArrowDisabled: isRightArrowDisabled
        })

        this.props.updateCurrentSelectedYears(startYear, endYear);

    }

    render() {
        let startYear = this.state.startYear;
        let endYear = this.state.endYear;

        return (
            <thead>
            <tr>
                <th className="calendar-header uk-flex uk-flex-between fs-14" colSpan="2">
                    <button className="arrows-container-quarter"
                            disabled={this.state.isLeftArrowDisabled}
                            onClick={() => this.updateDateRange(false)}>
                        <i className="far fa-angle-left uk-cursor-pointer fa-lg"/>
                    </button>
                    <span>{startYear}</span>
                    <span> </span>
                    <span>{endYear}</span>
                    <button className="arrows-container-quarter"
                            disabled={this.state.isRightArrowDisabled}
                            onClick={() => this.updateDateRange(true)}>
                        <i className="far fa-angle-right uk-cursor-pointer fa-lg"/>
                    </button>
                </th>
            </tr>
            </thead>
        )
    }
}

export default DateRange;