import React, { Component } from 'react';
import PSLTable from './PSLTable';
/**
 *  PSLSection is a component in the profit stack mapping page, it contains the PSLTable and the PLAudit components
 * @author [Mostafa Haydar]
 * @extends Component
 * **/

class PSLSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: [],
            data: this.props.data
        };

        this.isLoading = false;
    }

    componentDidMount() {
    
    }

    updateCombinationsTable = (mappedLines,toBeDeleted, newName, oldName)=>{
        this.props.updateCombinationsTable(mappedLines,toBeDeleted, newName, oldName)
    }
   
    render() {
        return (
            <PSLTable ref={el => this.pslTableRef = el} costCenter={this.props.costCenter} title={this.props.title} periods={this.props.periods}
                selectedPeriod={this.props.selectedPeriod} totals={this.props.totals} allAncillaryColumns={this.props.allAncillaryColumns}
                scenarioId={this.props.scenarioId} prefix={this.props.prefix} setNotSavedWarning={this.props.setNotSavedWarning} checkLineHasCombinations={this.props.checkLineHasCombinations}
                scenarioStatus={this.props.scenarioStatus} costKey={this.props.costKey} clientName={this.props.clientName} checked_combinations={this.props.checked_combinations}
                periodList={this.props.periodList} clearGLTableLinks={this.props.clearGLTableLinks} clientCostTerms={this.props.clientCostTerms} setConfirmationRequirement={this.props.setConfirmationRequirement}
                setStagingReport={this.props.setStagingReport} edit={this.props.edit} exceptionMetricsRaw={this.props.exceptionMetricsRaw} updateAssignedStatus={this.props.updateAssignedStatus}
                exceptionMetrics={this.props.exceptionMetrics} inheritAssignTo={this.props.inheritAssignTo} getSelectedPeriods={this.props.getSelectedPeriods} showScenarioPopUp={this.props.showScenarioPopUp}
                missingPeriodFiles={this.props.missingPeriodFiles} updateCombinationsTable={this.updateCombinationsTable} calculatedColsAmount={this.props.calculatedColsAmount} getFlippedCombinations={this.props.getFlippedCombinations}
                updateParent ={this.props.updateParent} setReorderedTreeStructure={this.props.setReorderedTreeStructure} checkEmptyCells = {this.props.checkEmptyCells}  unAssignOnLineRemoval = {this.props.unAssignOnLineRemoval} fetchAmounts = {this.props.fetchAmounts}
                setOpenCombinationFlipWarningDialog={this.props.setOpenCombinationFlipWarningDialog}
              />
        );

    }

}

export default PSLSection;