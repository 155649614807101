import React, {Component} from "react";
import { getTranslationFile } from '../../class/utils.js';
import { STAGING_SECTIONS, ENGINE_FILTER, CONFIGURE_SECTIONS } from "../../class/constants.js";
import '../../styles/common.css';
import "../../styles/filter.css";
import 'jqueryui'

const MESSAGES = getTranslationFile();

/**
 * 
 * @author [Lara Jannoun]
 */
class AdvancedFilterDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    allowDrop(ev) {
        ev.preventDefault();
    }
      
    drag(ev) {
        ev.dataTransfer.setData("text", ev.target.id);
    }
      
    drop(ev) {
        ev.preventDefault();

        /* If you use DOM manipulation functions, their default behaviour it not to 
        copy but to alter and move elements. By appending a ".cloneNode(true)", 
        you will not move the original element, but create a copy. */
        var data = ev.dataTransfer.getData("text");

        var nodeCopy = document.getElementById(data) ? document.getElementById(data).cloneNode(true) : null;
        if (nodeCopy !== null) {
            var text = nodeCopy ? nodeCopy.innerHTML : "";
            nodeCopy.classList.add("draggedDiv", "remove-cursor");
            nodeCopy.classList.remove("highlight", "uk-grab");
            
            if (text.replace(/ /g,"")  === ")") {
                ev.target.after(nodeCopy);
            } else if (text.replace(/ /g,"")  === "("){
                ev.target.before(nodeCopy);
            }
        }
            
    }

    renderAdvancedFilter() {
        var advancedData =  this.props.getTextFilter().replace(/\(/g, " (").replace(/\)/g, " )");
        advancedData = advancedData !== "" ? advancedData.trim().split(" ") : "";

        var draggableText = [];
        for (var i = 0; i < advancedData.length; i++) {
            let char = advancedData[i];
            if (char.match(/[A-Z]/i)) {
                draggableText.push(<div className="uk-text-center" key={"operator"+i}><button id={"operator"+i} className="border-button width-fit-cont" onClick={(e)=>this.props.switchOperatorInAdvancedFilter(e)}>{" "+char+" "}</button></div>);
            } else {
                draggableText.push(<div id={"char" + i} key={"char" + i} className={(char.match(/[0-9]/g) ? "border-circle uk-padding-xsmall-left-right "
                    : char.match(/[()]/g) ? "remove-cursor " : "")
                    + "uk-margin-xsmall-right uk-margin-xsmall-left uk-display-flex"}
                    onDrop={this.drop} onDragOver={this.allowDrop}> {" "+char+" "}</div>)
            }
        }

        var html = [];
        html.push(<div id='advanced-filter-draggable' key={'draggable-'+Math.random()} className={'uk-hidden uk-margin-auto'}>
                <div id='advanced-text-left' className={'uk-text-small uk-text-center uk-margin-xsmall uk-width-3-7'}>{MESSAGES.advanced_filter_draggable_text}</div>
                <div id='left-bracket' className={'uk-grab uk-margin-small-right highlight'} draggable='true' onDragStart={this.drag}> ( </div>
                <div id='right-bracket' className={'uk-grab highlight'} draggable='true' onDragStart={this.drag}> ) </div>
                <div id="advanced-text-right" className={'uk-text-center advanced-filter-rect uk-margin-xsmall uk-display-flex'}>
                    {draggableText}
                </div>
            <i id='advanced-filter-check' className={'fas fa-check uk-margin-small black uk-cursor-pointer highlight-icon'} onClick={()=>this.props.submitAdvancedFilter()}></i>
                <i id='advanced-filter-revert' className={'fal fa-times uk-margin-small black uk-cursor-pointer highlight-icon'} onClick={()=>this.props.revertAdvancedFilter()}></i>
            </div>);

        return html;
    }

    render() {
        var filterText = this.props.getTextFilter();
        var tempFilters = this.props.getFilterValidationStatus();
        var hideOrNotText = "";
        if (this.props.stagingReport === STAGING_SECTIONS.PROFIT_STACK_MAPPING || this.props.stagingReport === CONFIGURE_SECTIONS.FIELDS.CONFIG_COST_FUNCTIONS) {
            hideOrNotText = tempFilters && tempFilters.filter && tempFilters.filter.length > 0 && tempFilters.filter[0][ENGINE_FILTER.KEYS.COLUMN] ? "uk-hidden" : "";
        } else {
            hideOrNotText = tempFilters && tempFilters.filter && tempFilters.filter.length > 0 && tempFilters.filter[0][ENGINE_FILTER.KEYS.FILE] ? "uk-hidden" : "";
        }

        var hideOrNotFilter = hideOrNotText.length > 0 ? "uk-display-flex" : "uk-hidden";
        this.props.advancedFilterShowHide(hideOrNotText, hideOrNotFilter);
        var showExclusionInfo = this.props.stagingReport === STAGING_SECTIONS.MAPPING_TIE_OFF;

        this.advancedFilter = <div id="filter-table-advanced" className={"advanced-filter-parent-rect uk-width-auto uk-display-flex"}>
            <div id="advanced-text" className={hideOrNotText + " uk-text-medium uk-margin-xsmall"}>{MESSAGES.advanced_filter_text}</div>
            <div id="advanced-filter" className={hideOrNotFilter + " uk-margin-auto"}>
                <div className="uk-text-center uk-margin-xsmall">Filter Formula
                    {showExclusionInfo ? <i className="far fa-info-circle uk-margin-small-left" uk-tooltip={MESSAGES.mapping_tie_off_filter_formula}></i> : ""}
                </div>
                <div className="fs-12 uk-text-center advanced-filter-rect uk-margin-xsmall lightgray-background-color">{filterText}</div>
                <div className="text-link uk-text-center uk-margin-xsmall" onClick={() => this.props.reRenderFilter()}>Advanced Filter</div>
            </div>
            {this.renderAdvancedFilter()}
        </div>

        return this.advancedFilter;
    }
}

export default AdvancedFilterDialog;