import React, { Component } from "react";
import Tile from "./Tile";

class TileYear extends Component {
  constructor(props) {
    super(props);
  }

  tilesList = () => {
    return this.props.tiles.map((tile) => {
      return (
        <td key={tile.year+tile.value}>
          <Tile
            tile={tile}
            handleSelect={this.props.handleSelect}
            tileContent={this.props.tileContent}
          />
        </td>
      );
    });
  };

  tilesTable = () => {
    let size = this.props.columnNumber;
    let tiles = this.tilesList();
    let i = 0;
    let min = 0;
    let max = size;
    let content = [];

    while (max < this.props.tiles.length) {
      content.push(<tr key={String(this.props.tiles[i].value + this.props.tiles[i].year)} className="uk-flex uk-flex-center">{tiles.slice(min, max)}</tr>);
      min = max;
      max = min + size;
      i++;
    }
    content.push(<tr key={String(this.props.tiles[i] && this.props.tiles[i].value + this.props.tiles[i].year)} className="uk-flex uk-flex-center">{tiles.slice(min)}</tr>);

    return content;
  };

  render() {
    return (
      <tbody>
        {this.tilesTable()}
      </tbody>
    );
  }
}

export default TileYear;
