export function updateDrillListData(listObj, key, clear) {
  return {
    type: "UPDATE_DRILL_LIST_DATA",
    drillListData: listObj,
    key: key,
    clear: clear,
  };
}

export function getDrillListData() {
  return {
    type: "GET_DRILL_LIST_DATA",
  };
}

export function updateListData(listObj, key, clear) {
  return {
    type: "UPDATE_LIST_DATA",
    listData: listObj,
    key: key,
    clear: clear,
  };
}
export function updateListObjBeforeBack(listObj, key, clear) {
  return {
    type: "UPDATE_LIST_OBJ_BEFORE_BACK",
    listObjBeforeBack: listObj,
    key: key,
    clear: clear,
  };
}