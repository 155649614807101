import React, { Component } from 'react';
import '../../styles/common.css';
import ProfitStackLineDefinitionsTable from '../../tables/ProfitStackLineDefinitionsTable.js';

const profit_stack_line_definitions = "profit_stack_line_definitions";

class ProfitStackLineDefinitions extends Component {
  constructor(props) {
    super(props);
  }


  render() {
    return (
      <ProfitStackLineDefinitionsTable
        ref={(el) => (this.pageComponent = el)}
        user_email={this.props.userSettingsState?.user?.email}
        user={this.props.userSettingsState?.user}
        userAllowedSections={this.props?.userAllowedMenuLinks}
        scenario={this.props.scenarioState?.scenario}
        scenariosData={this.props.scenarioState?.scenariosList}
        reportTitle={this.props?.reportTitle}
        tableId={profit_stack_line_definitions}
        periods={this.props.periodsStatusState?.builtPeriods}
        vectorOptions={this.props.vectorState?.vectorOptions}
        hiddenVectors={this.props.vectorState?.hiddenVectors}
      />
    );
  }
}

export default ProfitStackLineDefinitions;
