import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import '../../styles/common.css';
import './ModelCoherence.css'
import ModelCoherenceTabulator from "../../tables/ModelCoherenceTabulator";
import ModelCoherenceChart from '../../components/charts/ModelCoherenceChart';

const model_coherence = "model_coherence";

const ModelCoherence = (props, ref) => {

    const [chartData, setChartData] = useState();

    useImperativeHandle(ref, () => ({
        expandAll:()=>{
            return pageComponent?.current?.expandAll();
        },
        collapseAll:()=>{
            return pageComponent?.current?.collapseAll();
        },
        getModelCoherenceData:(scenario)=>{
            return pageComponent?.current?.getModelCoherenceData(scenario);
        },
        exportTableToExcel:(options, allRows)=>{
          return pageComponent?.current?.exportTableToExcel(options, allRows);
        }
    }));
  const pageComponent = useRef();

  return (
    <div className="model_coherence_container">
        <ModelCoherenceTabulator
            ref={pageComponent}
            user_email={props.userSettingsState?.user?.email}
            user={props.userSettingsState?.user}
            machineName={props.userSettingsState?.machine_name}
            userAllowedSections={props?.userAllowedMenuLinks}
            scenario={props.scenarioState?.scenario}
            scenariosData={props.scenarioState?.scenariosList}
            reportTitle={props?.reportTitle}
            tableId={model_coherence}
            periods={props.periodsStatusState?.builtPeriods}
            vectorOptions={props.vectorState?.vectorOptions}
            hiddenVectors={props.vectorState?.hiddenVectors}
            costFunctionsData={props.costFunctionsData}
            callCollapse={props.callCollapse}
            setChartData={setChartData}
        />
        <ModelCoherenceChart chartData={chartData}/>
      </div>
    );
}

export default forwardRef(ModelCoherence);
