import React, { Component } from 'react';
import { getTranslationFile } from '../class/utils.js';
import CostCenterTable from './/CostCenterTable';
import Button from '../newComponents/Button.js';
import { BUTTON_TYPE, BUTTON_VARIANT, SIZES } from '../class/constants.js';
import {ReactComponent as Plus} from "../styles/images/icons/plus-regular.svg"

/** @author Sarah Farjallah 
 *  Definition side panel of cost center
 *  Component renders the table of defintion for cost centers
 */
const lang = getTranslationFile();
const $ = require('jquery');
class CostCenterDefinition extends Component {
    constructor(props) {
        super(props);
        this.state = {
            view: lang.COST_CENTER_VIEW.PERIODS.value,
            errorMessage: ""
        }
    }

    enableSelectState = (enabled) => {
        if (enabled) {
            $("#select_state").show();
            $("#add_state").hide();
        } else {
            $("#select_state").hide();
            $("#add_state").show();
        }
    }

    render() {
        return (
            <div className='cost_center_body'>
                <div style={{ height: '1vw' }}>
                    {this.state.errorMessage && this.state.errorMessage !== "" ?
                        <span className="red"> {this.state.errorMessage}</span>
                        : ""
                    }
                </div>
                <div id="select_state" type="button" title="Select State" className={"uk-button-icon uk-float-right uk-margin-bottom uk-margin-right uk-dispaly-none"} onClick={() => { this.props.openSelectStateModal() }}>
                    <i className={"uk-margin-small-right fa-lg fal fa-tasks"} />{lang.manage_cost_centers.select_state}
                </div>
                <Button
                    id="add-new-state-btn"
                    label={lang.manage_cost_centers.add_state}
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={() => {this.props.openNewStateModal()}}
                    leftIcon={<Plus/>}
                    className={"uk-margin-bottom"}
                />

                <div className="uk-margin-top table_container" >
                    <CostCenterTable ref={el => this.ccTabRef = el} data={this.props.data} columns={this.props.columns} id={this.props.id} pullPreviousState={this.props.pullPreviousState} view={this.props.view}
                        index={this.props.index} pagination={true} isCostCenterNew={this.props.isCostCenterNew} costCenterStates={this.props.costCenterStates} user={this.props.user}
                        isCostCenterStateDifferentThanPrevious={this.props.isCostCenterStateDifferentThanPrevious} period={this.props.period} statesMap={this.props.statesMap} enableSelectState={this.enableSelectState} 
                        isBulkdefine={this.props.isBulkdefine} isCCDefinition={this.props.isCCDefinition}/>
                </div>

            </div>

        )
    }
}



export default CostCenterDefinition