//ACTIONS -----------------------------------------------------
export function updateHeatmapObjBeforeBack(hmObj, key, clear) {
  return {
    type: "UPDATE_HEATMAP_OBJECT_BEFORE_BACK",
    heatmapObjBeforeBack: hmObj,
    key: key,
    clear: clear,
  };
}

export function getHeatmapObjBeforeBack() {
  return {
    type: "GET_HEATMAP_OBJECT_BEFORE_BACK",
  };
}

export function updateHeatmapListData(listObj, key, clear) {
  return {
    type: "UPDATE_HEATMAP_LIST_DATA",
    heatmapListData: listObj,
    key: key,
    clear: clear,
  };
}

export function geHeatmapListData() {
  return {
    type: "GET_HEATMAP_LIST_DATA",
  };
}
