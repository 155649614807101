const defaultDatasetState =new Map();
const defaultVectorsState = new Map();
const defaultScenariosState = [];
const defaultCustomViewDataState = new Map();
const defaultProfitStackByCostHierarchyState =[];
const defaultScenarioState={};
const defaultPeriodStatusState=new Map();
const defaultPeriodsSettings = [];
const defaultDatasetOptions = [];
const defaultTiers = [];
const defaultScenarios = [];
const defaultCustomViewData = [];
const defaultProfitStackByCostHierarchy =[];
const defaultProfitFormats=[];

function periodsStatus(state=defaultPeriodsSettings, action) { //reducer
    switch (action.type) {
        case "UPDATE_PERIODSSTATUS":
            if (action.clear) {
                let periodsSettingsHashMap = new Map();
                return periodsSettingsHashMap;
            }
            let periodsSettingsHashMap = new Map(state);
            periodsSettingsHashMap.set(action.key, action.periodsStatus);
        return periodsSettingsHashMap;
        case "GET_PERIODSSTATUS":
          return state;
        default:
        return state;
    }
}

function datasetOptions(state=defaultDatasetOptions, action) { //reducer
    switch (action.type) {
        case "UPDATE_DATASETOPTIONS":
            if (action.clear) {
                let datasetOptionsMap = new Map();
                return datasetOptionsMap;
            }
            let datasetOptionsMap = new Map(state);
            datasetOptionsMap.set(action.scenarioId, action.datasetOptions);
        return datasetOptionsMap;
        case "GET_DATASETS":
          return state;
        default:
        return state;
    }
}

function datasetState(state=defaultDatasetState, action) { //reducer
    switch (action.type) {
        case "UPDATE_DATASET_STATE":
            if (action.clear) {
                let datasetOptionsMap = new Map();
                return datasetOptionsMap;
            }
            let datasetOptionsMap = new Map(state);
            datasetOptionsMap.set(action.scenarioId, action.datasetState);
        return datasetOptionsMap;
        case "GET_DATASETS":
          return state;
        default:
        return state;
    }
}

function tiers(state=defaultTiers, action) { //reducer
    switch (action.type) {
        case "UPDATE_TIERS":
            if (action.clear) {
                let tiersMap = new Map();
                return tiersMap;
            }
            let tiersMap = new Map(state);
            tiersMap.set(action.scenarioId, action.tiers);
        return tiersMap;
        case "GET_TIERS":
          return state;
        default:
        return state;
    }
}

function vectorState(state=defaultVectorsState, action) { //reducer
    switch (action.type) {
        case "UPDATE_VECTORS_STATE":
            if (action.clear) {
                let tiersMap = new Map();
                return tiersMap;
            }
            let tiersMap = new Map(state);
            tiersMap.set(action.scenarioId, action.vectorState);
        return tiersMap;
        default:
        return state;
    }
}

function scenarios(state=defaultScenarios, action) { //reducer
    switch (action.type) {
        case "UPDATE_SCENARIOS":
            return [
                {
                name: action.scenarios
                }
            ];
        case "GET_SCENARIOS":
          return state;
        default:
        return state;
    }
}

function customViewDataState(state=defaultCustomViewDataState, action) { //reducer
    switch (action.type) {
        case "UPDATE_CUSTOM_VIEW_DATA_STATE":
            if (action.clear) {
                let cvdMap = new Map();
                return cvdMap;
            }
            let cvdMap = new Map(state);
            cvdMap.set(action.scenarioId, action.customViewDataState);
        return cvdMap;
        case "GET_CUSTOM_VIEW_DATA":
          return state;
        default:
        return state;
    }
}

function customViewData(state=defaultCustomViewData, action) { //reducer
    switch (action.type) {
        case "UPDATE_CUSTOM_VIEW_DATA":
            if (action.clear) {
                let cvdMap = new Map();
                return cvdMap;
            }
            let cvdMap = new Map(state);
            cvdMap.set(action.scenarioId, action.customViewData);
        return cvdMap;
        case "GET_CUSTOM_VIEW_DATA":
          return state;
        default:
        return state;
    }
}
  
function profitStackByCostHierarchy(state=defaultProfitStackByCostHierarchy, action) { //reducer
    switch (action.type) {
        case "UPDATE_PS_HIERARCHY":
            if(action.clear && !action.scenarioId) {
                let cvdMap = new Map();
                return cvdMap;
            }
            let cvdMap = new Map(state);
            cvdMap.set(action.scenarioId, action.ps);
        return cvdMap;
        case "GET_PS_HIERARCHY":
          return state;
        default:
        return state;
    }
}

function profitFormats(state=defaultProfitFormats, action) { //reducer
    switch (action.type) {
        case "UPDATE_FORMATS":
            let cvdMap = new Map(state);
            cvdMap.set(action.scenarioId, action.formats);
        return cvdMap;
        case "GET_FORMATS":
          return state;
        default:
        return state;
    }
}

function scenarioState(state=defaultScenarioState, action) {
    switch (action.type) {
        case "UPDATE_SCENARIO_STATE":
            return {...state, scenarioState:action.scenarioState}
        default:
        return state;
    }
}

function periodsStatusState(state=defaultPeriodStatusState, action) {
    switch (action.type) {
        case "UPDATE_PERIODS_STATUS_STATE":
            if (action.clear) {
                let cvdMap = new Map();
                return cvdMap;
            }
            let cvdMap = new Map(state);
            cvdMap.set(action.key, action.periodsStatusState);
            return cvdMap;
        default:
        return state;
    }
}
export {periodsStatus, datasetOptions, tiers, scenarios, customViewData, profitStackByCostHierarchy, profitFormats,
         scenarioState, periodsStatusState, customViewDataState, vectorState, datasetState};