import React, { Component } from 'react';
import {TabulatorFull} from "tabulator-tables"; //import {TabulatorFull} library
import { copyObjectValues, getTranslationFile } from '../../class/utils.js';
import {
    ALL_WIDGETS, VIEW_MORE_INVOICE_LINE_FIELDS, VIEW_MORE_INVOICE_LINE_TITLES, FormatTypes
} from '../../class/constants';
import { formatValString } from '../../class/format.js';
import { toggleLoader } from '../../class/common.js';

const $ = require('jquery');
const MESSAGES = getTranslationFile();

var VIEW_MORE_COLUMNS = [
    {
        title: VIEW_MORE_INVOICE_LINE_TITLES.NAME,
        field: VIEW_MORE_INVOICE_LINE_FIELDS.NAME,
    },
    {
        title: VIEW_MORE_INVOICE_LINE_TITLES.TOTAL,
        field: VIEW_MORE_INVOICE_LINE_FIELDS.TOTAL,
        formatter:"plaintText"
    },
    {
        title: VIEW_MORE_INVOICE_LINE_TITLES.MAXIMUM,
        field: VIEW_MORE_INVOICE_LINE_FIELDS.MAXIMUM,
    },
    { 
        title: VIEW_MORE_INVOICE_LINE_TITLES.MINIMUM, 
        field: VIEW_MORE_INVOICE_LINE_FIELDS.MINIMUM,
    },
    { 
        title: VIEW_MORE_INVOICE_LINE_TITLES.TYPE, 
        field: VIEW_MORE_INVOICE_LINE_FIELDS.TYPE,
        visible: false
    },
    { 
        title: '', 
        field: VIEW_MORE_INVOICE_LINE_FIELDS.DATATYPE,
        visible: false
    },
];

class ViewMoreInvoiceLine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            exc_values: [],
            values: []
        };

        this.setStagingReport = this.setStagingReport.bind(this);

        this.redrawTable = false;
    }

    setStagingReport() {
        if(this.state.changed){
            this.props.setStagingReport(ALL_WIDGETS.FIELDS.STAGE, true);
        } else {
            this.props.setStagingReport(ALL_WIDGETS.FIELDS.STAGE);
        }
    }

    getTabulatorColumns(columns) {
        columns = copyObjectValues(columns) || [];
        columns.forEach(col => {
            col.formatter = this.getColumnFormatter(col.field);
        });
        return columns;
    }

    getColumnFormatter(colField) {
        var columnFormatter;

        switch (colField) {
            case VIEW_MORE_INVOICE_LINE_FIELDS.TOTAL:
            case VIEW_MORE_INVOICE_LINE_FIELDS.MAXIMUM:
            case VIEW_MORE_INVOICE_LINE_FIELDS.MINIMUM:
                columnFormatter = function (cell, formatterParams) {
                    var p = document.createElement("p");

                    var value = ";"
                    if (cell.getRow().getData()[VIEW_MORE_INVOICE_LINE_FIELDS.DATATYPE] === 'NUMERIC') {
                        value = formatValString(cell.getValue(), FormatTypes.AMOUNT);
                        if (cell.getValue() < 0) {
                            p.classList.add("red");
                        }
                    } else {
                        value = formatValString(cell.getValue(), FormatTypes.TEXT);
                    }
                    p.innerHTML = value;
                    return p;
                }
                break;
            default:
                columnFormatter = function(cell, formatterParams) {
                    return cell.getValue();
                }
            break;
        }
        return columnFormatter;  
    }
    
    componentDidUpdate(prevProps) {
        var tableColumns = copyObjectValues(VIEW_MORE_COLUMNS);

        //table columns
        tableColumns = this.getTabulatorColumns(tableColumns);	//set column formatters and return column objects
        this.table.setColumns(tableColumns);		//set the columns as the actual columns of the table

        //table data
        this.table.setData(prevProps.invoiceLineDetails);

        if(prevProps.selectedPeriod.value !== this.props.selectedPeriod.value) {
            prevProps.getInvoiceLineDetail();
        }
    }

    componentWillUnmount() {
        $("#header-next").removeClass("uk-hidden");
    }

    componentDidMount() {
        toggleLoader(true, "viewMoreInvoiceLine");
        var obj = this;
        $("#header-next").unbind('click');
        $("#header-back").unbind('click');
        //showing the period drop down list in header
        $("#periodList-header").removeClass("uk-hidden");
        $("#periodList-header").removeClass("disabled");

        //disabling the scenario drop down list
        $("#scenarioList-header").addClass("disabled");

        //adding the back and apply buttons to header
        // $("#header-buttons").removeClass("uk-hidden");
        $("#header-title p").text(MESSAGES.mapping_tie_off);
        $("#header-title-icon").removeClass("uk-hidden");
        
        $("#header-back").click(function (e) {
            //hiding the period drop down list in header
            $("#periodList-header").addClass("uk-hidden");
            // $("#header-buttons").addClass("uk-hidden");

            //enabling the scenario drop down list
            $("#scenarioList-header").removeClass("disabled");
            obj.setStagingReport();
        });

        $("#header-next").addClass("uk-hidden");

        //table columns
        var tableColumns = this.getTabulatorColumns(VIEW_MORE_COLUMNS);	

        var options = {
            layout: "fitColumns",      //fit columns to width of table
            data: [],
            columns: tableColumns, //set the columns as the actual columns of the table
            groupBy: VIEW_MORE_INVOICE_LINE_FIELDS.TYPE,
            responsiveLayout: false,  //hide columns that dont fit on the table
            tooltips: true,            //show tool tips on cells
            addRowPos: "top",          //when adding a new row, add it to the top of the table
            history: true,             //allow undo and redo actions on the table
            pagination: false,          //paginate the data
            movableColumns: false,     //allow column order to be changed
            selectable: false,
            height: "100%",
            resizableColumns: false,
            autoResize: true,
            virtualDomBuffer: 800,
            width: "100%",
            placeholder: MESSAGES.no_data_available,
            reactiveData: true,      //tabulator listens to any change in the data array and updates the table
            tooltips: function(column){
                return column._cell.value;
            }, renderComplete: function() {
                toggleLoader(false, "viewMoreInvoiceLine");
            }
        }

        obj.table = new TabulatorFull("#invoiceLineDetails", options);
    }

    render() {
        return (
            <div>
                <div id="invoiceLineDetails-parent" className="uk-display-inline-flex uk-width-1-1">
                    <div className="mapping-parent-more">
                        <div id="invoiceLineDetails" ref="invoiceLineDetails" />
                    </div> 
                </div>
            </div>
        );
    }
}

export default ViewMoreInvoiceLine;