
import React from 'react';

import Button from '../../../../newComponents/Button';
import { BUTTON_TYPE, BUTTON_VARIANT, SIZES } from '../../../../class/constants';
const  CardBodyNavigation = (props) => {

  const {cards,activeCard,handleCardChange,leftNavStyle,rightNavStyle} = props;

  return (
    <>
       {(cards.length > 1 && activeCard !== 0) && <div style={leftNavStyle}>
        <Button 
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          className={"navigation-button"}
          onBtnClick={()=>{handleCardChange(-1,cards)}}
          centeredIcon={<i className="fa fa-chevron-left" aria-hidden="true"></i>}
        />
       </div>}
        {cards}
        {(cards.length > 1 && activeCard !== cards.length - 1) &&  <div style={rightNavStyle}>
        <Button 
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          className={"navigation-button"}
          onBtnClick={() => {handleCardChange(1,cards)}}
          centeredIcon={<i className="fa fa-chevron-right" aria-hidden="true"></i>}
        />
       </div>}
    </>
  );
}

export default CardBodyNavigation;
