import {AlwaysOnSampler, TraceIdRatioBasedSampler, WebTracerProvider} from '@opentelemetry/sdk-trace-web';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { BatchSpanProcessor } from '@opentelemetry/sdk-trace-base';
import { Resource } from '@opentelemetry/resources';

const { trace }  = require("@opentelemetry/api");
const name = 'default';
const version = '20240124t164259';
const tracer = trace.getTracer(name, version);

const provider = new WebTracerProvider({
    resource: new Resource({
        'service.name': 'UI project'
    }),
    sampler: new AlwaysOnSampler()
});

if (process.env.REACT_APP_OPENTELEMETRY_COLLECTOR_TRACE_API_URL) {
    const exporter = new OTLPTraceExporter({
        concurrencyLimit: 20,
        url: process.env.REACT_APP_OPENTELEMETRY_COLLECTOR_TRACE_API_URL
    });
    
    provider.addSpanProcessor(new BatchSpanProcessor(exporter, {
        // // The maximum queue size. After the size is reached spans are dropped.
        // maxQueueSize: 100,
        // // The maximum batch size of every export. It must be smaller or equal to maxQueueSize.
        // maxExportBatchSize: 10,
        // // The interval between two consecutive exports
        // scheduledDelayMillis: 500,
        // // How long the export can run before it is cancelled
        // exportTimeoutMillis: 30000,
    }));
} else {
    provider.addSpanProcessor(new BatchSpanProcessor());
}

trace.setGlobalTracerProvider(provider);
provider.register();

export default tracer;
