import { useState } from "react";
import { ClickAwayListener } from "@mui/material";
import { Box } from "@mui/system";
import Button from "../newComponents/Button";
import {
  ALL_WIDGETS,
  BUTTON_TYPE,
  BUTTON_VARIANT,
  MENU_ITEM,
  MENU_ITEMS_SVG_COLOR,
  SIZES,
} from "../class/constants";
import { lang } from "../language/messages_en";
import { ReactComponent as BentoMenu } from "../styles/images/menu-svgs/main_menu.svg";
import { getFirstURLofModule } from "../class/acl";

const SwitchToMenu = (props) => {
  const [open, setOpen] = useState(false);

  const toggleBentoMenu = () => {
    setOpen(!open);
  };

  const closeBentoMenu = () => {
    setOpen(false);
  };

  const goToLanding = () => {
    toggleBentoMenu();
    props.goToLandingPage();
  };

  const renderMenuItems = () => {
    return (
      <div className="switchToMenu">
        <div className="switchToTextContainer">
          <div className="switchToText">{lang.menu.switch_to}</div>
          <Button
            label={lang.menu.profit_isle_start}
            title={lang.menu.profit_isle_start}
            variant={BUTTON_VARIANT.TERTIARY}
            size={SIZES.DEFAULT}
            type={BUTTON_TYPE.DEFAULT}
            className="uk-button-icon justify-content-start "
            onBtnClick={goToLanding}
            rightIcon={<img src={"/images/menu-svgs/arrow-right.svg"} />}
          />
        </div>
        <div className="menuItemsContainer">
          {props.userAllowedMenuLinks?.map(function (item) {
            if ((item.children.length > 0 || item.menu_item_machine_name === ALL_WIDGETS.DASHBOARDS) &&
              item[MENU_ITEM.COLUMNS.CATEGORY] === MENU_ITEM.FIELDS.MENU_ITEM) {
              return (
                <div className="switchToMenuGroup" key={item.menu_item_machine_name}>
                  <div className="menu-group-links-container">
                    <div
                      key={item.menu_item_machine_name}
                      id={item.menu_item_machine_name}
                      className="switchToMenuLinkGroup"
                      type={item.type}
                      parent_machine_name={item.menu_item_machine_name}
                      machine_name={item.menu_item_machine_name}
                      redirect_only={item.children.length == 0 ? "true" : undefined}
                      onClick={(e) => {
                        props.goToReport(e);
                      }}
                      highlighted={window.location.href.includes(item[MENU_ITEM.COLUMNS.MENU_ITEM_MACHINE_NAME]) + ""}
                      url={item.url ? item.url : ("/" + getFirstURLofModule(item))}
                    >
                      <div
                        className="svgContainer"
                        style={{
                          backgroundColor: MENU_ITEMS_SVG_COLOR[item.menu_item_category?.toLowerCase()],
                          pointerEvents: "none",
                        }}
                      >
                        <img src={"/images/menu-svgs/" + item.menu_item_machine_name + ".svg"} style={{ pointerEvents: "none" }} />
                      </div>
                      <div className="menuGroupLink" style={{ pointerEvents: "none" }}>
                        <div className="menuGroupLinkText" style={{ pointerEvents: "none" }}>
                          {item.menu_item_display_name}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  };

  return (
    <ClickAwayListener onClickAway={closeBentoMenu}>
      <Box sx={{ position: "relative" }}>
        <BentoMenu
          onClick={toggleBentoMenu}
          className="uk-cursor-pointer"
          style={{ width: "1.66667vw", height: "1.66667vw" }}
        />
        {open ? renderMenuItems() : null}
      </Box>
    </ClickAwayListener>
  );
};

export { SwitchToMenu };
