/**
 * @function getFormulaText
 * Used to extract text from formula
 * @param childNodes
 * @return {string}
 */
function getFormulaText(childNodes){
    let formulaText = '';

    childNodes.forEach((element) => {
        if (element.nodeType === 1) { // Element node
            if (element?.getAttribute("type") === "label") {
                formulaText += "(" + Math.random() + ")";
            } else {
                formulaText += element.textContent.trim(); // Trimming whitespace
            }
        } else if (element.nodeType === 3) { // Text node
            const textContent = element.textContent.trim();
            const numberOrDotRegex = /^[0-9.]+$/; // Check if it contains only numbers or dots

            if (numberOrDotRegex.test(textContent)) {
                formulaText += textContent;
            } else {
                throw new Error("Invalid formula");
            }
        }
    });

    return formulaText;
};

/**
 * @function validateFormulaResult
 * Used to evaluate formula and return true if it is finite and a number
 * @param formulaText
 * @return {boolean}
 */
function validateFormulaResult(formulaText) {
    try {
        const result = eval(formulaText);
        return isFinite(result) && typeof result === 'number';
    } catch (error) {
        return false;
    }
};

export {getFormulaText, validateFormulaResult}