import React, { useEffect, useMemo } from "react";
import "./styles/landingPage.css";
import { useDispatch } from "react-redux";
import {
  ALL_WIDGETS,
  BUTTON_TYPE,
  BUTTON_VARIANT,
  CUSTOM_REPORT,
  DIALOG_SIZE, IMAGE_TYPE,
  MENU_ITEM,
  SHOW_RELEASE_MEASSAGE,
  SIZES
} from "../class/constants";
import { lang } from "./../language/messages_en";
import Button from "../newComponents/Button";
import Carousel from "../newComponents/Carousel";
import { useState } from "react";
import { getImagesSignedUrls, updateShowAgain } from "./api/api";
import { updateUserSettings } from "./actions/userSettingsActions";
import {copyObjectValues, parseBoolean, tryParse} from "../class/utils";
import { ReactComponent as MessageSmile } from "../styles/images/icons/message-smile-feedback.svg";
import Modal from "../newComponents/Modal";
import { IconTitle } from "./IconTitle";
import Accordion from "../components/accordion/Accordion";

const LandingPage = (props) => {
  const memoizedReleaseBanner = useMemo(() => props.userSettings?.release_message, [props.userSettings?.release_message]);
  const memoizedShowFeedback = useMemo(() => props.userSettings?.show_provide_feedback, [props.userSettings?.show_provide_feedback]);
  const dispatch = useDispatch();
  const { userSettings } = props;
  const [learnMorePopup, setLearnMorePopup] = useState(false)
  const [showAgain, setShowAgain] = useState(props.userSettings?.user?.show_release_message)
  const [acknowledgementValue, setAcknowledgementValue] = useState(props.userSettings?.acknowledgementDialog ? tryParse(props.userSettings?.acknowledgementDialog) : null)
  const [showAcknowledgement, setShowAcknowledgement] = useState(acknowledgementValue?.visible ? parseBoolean(acknowledgementValue?.visible) : null);
  const [showReleasePopup, setShowReleasePopup] = useState((acknowledgementValue?.content && acknowledgementValue?.content?.length > 0) ? false : props.userSettings?.user?.show_release_message);

  
  const openReleasePopup = (open, fromLearnMore) => {

    if (open === false && !learnMorePopup) {
      let tempState = copyObjectValues(props.userSettings);
      updateShowAgain(props.userSettings, showAgain);
      tempState.user.show_release_message = showAgain;
      dispatch(updateUserSettings(tempState));
      sessionStorage.setItem(SHOW_RELEASE_MEASSAGE, false);
    }


    setShowReleasePopup(open);
    setLearnMorePopup(fromLearnMore);
  }

  const onCheckBoxChecked = () =>{
    setShowAgain(!showAgain)
  }

  const goToFeedbackURL = () =>{
    window.open(props.userSettings?.provide_feedback_url)
  }

  const acknowledgementModalBody = () => {
    return (
        <div style={{fontSize:"0.729vw"}}>
          {acknowledgementValue.content}
        </div>
    );
  };

  const acknowledgementModalActions = () => {
    return (
        <Button
            label={"Acknowledge"}
            variant={BUTTON_VARIANT.PRIMARY}
            size={SIZES.LARGE}
            type={BUTTON_TYPE.DEFAULT}
            onBtnClick={handleCloseAcknowledgementModal}
        />
    )
  }

  const handleCloseAcknowledgementModal = () =>{
      sessionStorage.setItem("showAcknowledgeModal", "false");
      setShowAcknowledgement(false);
      setShowReleasePopup(showAgain)
  }

  const getMenuItemsAccordionContent = (linkGroup) => {
    const menuItemsContent = [];
  
    linkGroup.children.forEach((item) => {
      if (isParentGroup(item)) {
        const contents = getMenuItemsContent(item.children);
        menuItemsContent.push({ titleObj: item, contentComponent: contents });
      } else if (item.menu_item_type === "link") {
        menuItemsContent.push({
          titleComponent: (
            <IconTitle
              key={item.menu_item_machine_name}
              containerClassName={"tooltip-menu-items"}
              title={item.menu_item_display_name}
              subtitle={item[MENU_ITEM.COLUMNS.MENU_ITEM_DESCRIPTION]}
              titleStyle={{ color: "#000000", fontSize: "0.74vw", fontWeight: 600, lineHeight: "normal" }}
              subtitleStyle={{ color: "rgba(98, 98, 98, 0.70)", fontSize: "0.63vw", lineHeight: "120%" }}
              svgStyle={{ width: "2.14vw", height: "2.14vw", backgroundColor: "#5F60C8" }}
              titleContainerStyle={{ width: "80%" }}
              type={item.menu_item_type}
              parent_machine_name={item.parent_machine_name}
              machine_name={item.menu_item_machine_name}
              iconTitleContainerStyle={{ width: "100%", cursor: "pointer" }}
              redirect_only="true"
              is_custom_report={`${item.category === CUSTOM_REPORT}`}
              is_tableau={`${item.is_tableau}`}
              menu_item_machine_name={`${item.menu_item_machine_name}`}
              icon={<img src={"/images/menu-svgs/" + getIconImageName(item) + ".svg"}/>}
              url={item.url}
              onClick={(e) => {
                props.goToReport(e);
              }}
            />
          )
        });
      }
    });
  
    return menuItemsContent;
  };

  const isParentGroup = (item) => {
    const isItemFeature = item[MENU_ITEM.COLUMNS.CATEGORY] === MENU_ITEM.FIELDS.FEATURE;
    const doesItemHasChildren = item.children?.length > 0 && item.children.some(child => child[MENU_ITEM.COLUMNS.CATEGORY] !== MENU_ITEM.FIELDS.FEATURE);
    return !isItemFeature && doesItemHasChildren;
  };
  
  const getMenuItemsContent = (children) => {
    return children.map((child) => (
      <IconTitle
        key={child.menu_item_machine_name}
        containerClassName={"tooltip-menu-items-children"}
        title={child.menu_item_display_name}
        subtitle={child[MENU_ITEM.COLUMNS.MENU_ITEM_DESCRIPTION]}
        titleStyle={{ color: "#5F60C8", fontSize: "0.74vw", fontWeight: 600, lineHeight: "normal" }}
        subtitleStyle={{ color: "#AAABB0", fontSize: "0.63vw", lineHeight: "120%" }}
        svgStyle={{ width: "2.14vw", height: "2.14vw", border: "0.05vw solid #5F60C8" }}
        titleContainerStyle={{ width: "80%" }}
        type={child.menu_item_type}
        parent_machine_name={child.parent_machine_name}
        machine_name={child.menu_item_machine_name}
        iconTitleContainerStyle={{ width: "100%", cursor: "pointer" }}
        redirect_only={"true"}
        is_custom_report={`${child.category === CUSTOM_REPORT}`}
        is_tableau={`${child.is_tableau}`}
        menu_item_machine_name={`${child.menu_item_machine_name}`}
        url={child.url}
        icon={<img src={"/images/menu-svgs/" + getIconImageName(child) + ".svg"}/>}
        onClick={(e) => {
          props.goToReport(e);
        }}
      />
    ));
  };

  /**
   * Returns the icon image based on category and type
   * @param {*} item 
   * @returns 
   */
  const getIconImageName = (item) => {
    if (item.menu_item_category === "CONFIGURATION") {
      return "configuration";
    } else if (item.category === CUSTOM_REPORT) {
      return "custom-report";
    } else if (item.menu_item_category === "ADMINISTRATION") {
      return "general_settings_menu";
    } else {
      return item[MENU_ITEM.COLUMNS.MENU_ITEM_MACHINE_NAME];
    }
  };

  const renderMenuItemsTooltip = (linkGroup) => {
    return (
      <div className="menu-items-tooltip-div">
        <div style={{ display: "flex", flexDirection: "column", rowGap: "0.2vw"}}>
          <span className="menu-items-tooltip-title">{linkGroup.menu_item_display_name}</span>
          <span className="menu-items-tooltip-description">{linkGroup[MENU_ITEM.COLUMNS.MENU_ITEM_DESCRIPTION]}</span>
        </div>
        <Accordion key={linkGroup?.menu_item_machine_name} items={getMenuItemsAccordionContent(linkGroup)} />
      </div>
    );
  };

  return (
    <>
      {(sessionStorage.getItem(SHOW_RELEASE_MEASSAGE) === 'false' && !learnMorePopup ? false : showReleasePopup && memoizedReleaseBanner && !props.switchClient) ?
    <div className="release_popup_container">
      <div className="release_popup_backdrop"></div>
      <Carousel className={"release_carousel"} openPopup={openReleasePopup} onCheckBoxChecked={onCheckBoxChecked} checked={!showAgain} learnMorePopup={learnMorePopup} images={props.imagesSignedUrls.filter(f=>f.imageType===IMAGE_TYPE.RELEASE_NOTES)}/>
    </div>
    :""}
      <div className="landing-page-contents">
        <div className="upper-container">
        <div className="title_reports_container">
          <div className="welcomeContainer">
            <IconTitle
                title={lang.menu.hi_text + props.userSettings?.user?.first_name}
                subtitle={lang.menu.welcome_message_subtitle}
                titleStyle={{color: "#FFF", fontSize: "0.8vw", fontWeight: 600}}
                subtitleStyle={{color: "#AAABB0", fontSize: "0.75vw", lineHeight: "120%"}}
                svgStyle={{padding: 0}}
                titleContainerStyle={{justifySelf: "center"}}
                iconTitleContainerStyle={{alignItems:"center"}}
                icon={<img src={"/images/menu-svgs/island-icon.svg"} style={{maxWidth: "unset"}}/>}
            />
            <div className="LoginTextFeedbackContainer">
              {memoizedShowFeedback ? (
                <Button
                  label={lang.modal.buttons.provide_feedback}
                  variant={BUTTON_VARIANT.TERTIARY}
                  size={SIZES.DEFAULT}
                  type={BUTTON_TYPE.DEFAULT}
                  className="provide_feedback_button"
                  leftIcon={<MessageSmile />}
                  onBtnClick={() => goToFeedbackURL()}
                />
              ) : (
                ""
              )}
            </div>
            {memoizedReleaseBanner && (
              <IconTitle
                title={props.userSettings?.release_message}
                subtitle={props.userSettings?.release_message_description}
                icon={
                  <>
                    <span style={{position: "absolute", zIndex:1, color:"white", fontWeight:600}}>NEW</span>
                    <img src={"/images/menu-svgs/new-release.svg"}/>
                  </>
                }
                titleStyle={{color: "#FFF", fontSize: "0.8vw", fontWeight: 600}}
                subtitleStyle={{color: "#AAABB0", fontSize: "0.75vw", lineHeight: "120%"}}
                titleContainerStyle={{justifySelf: "center"}}
                svgStyle={{padding: 0}}
                iconTitleContainerStyle={{alignItems:"center", cursor:"pointer"}}
                onClick={() => openReleasePopup(true, true)}
              />
            )}
          </div>
          <div className="upper-menu-items-container">
            <div className="report_group_container">
            {props.userAllowedMenuLinks.map((item) => {
              if (item[MENU_ITEM.COLUMNS.CATEGORY] === "menu_item" && item.menu_item_category !== "ADMINISTRATION" && (item.children?.length > 0 || item.menu_item_machine_name === 'dashboards')) {
                return ( 
                  <IconTitle
                  key={item.menu_item_machine_name}
                  title={item.menu_item_display_name}
                  subtitle={item[MENU_ITEM.COLUMNS.MENU_ITEM_DESCRIPTION]}
                  titleStyle={{color: "#FFF", fontSize: "0.8vw", fontWeight: 600 }}
                  subtitleStyle={{color: "#AAABB0", fontSize: "0.75vw", lineHeight: "120%"}}
                  svgStyle={{width: "3.8vw", height: "3.8vw", backgroundColor: "#5F60C8"}}
                  titleContainerStyle={{maxWidth: "10vw"}}
                  iconTitleContainerStyle={{alignItems:"start", cursor:"pointer" }}
                  tooltipOnHover={item.menu_item_machine_name === 'dashboards' ? undefined : renderMenuItemsTooltip(item)}
                  type={item.menu_item_type}
                  parent_machine_name={item.menu_item_machine_name}
                  machine_name={item.menu_item_machine_name}
                  url={item.url ? item.url : "/" + item.menu_item_machine_name + "/" + item.menu_item_machine_name}
                  onClick={item.menu_item_machine_name === 'dashboards' ? (e) => {
                    props.goToReport(e)
                  } : () => {}}
                  icon={<img src={"/images/menu-svgs/" + item.menu_item_machine_name + ".svg"}/>}
                  hasHoverEffect
                />
              );
            }
            })}
              {parseBoolean(props.userSettings?.add_module) && props.userSettings?.add_module_url ?
                  <IconTitle
                      title={lang.menu.add_module_title}
                      subtitle={lang.menu.add_module_subtitle}
                      titleStyle={{color: "#FFF", fontSize: "0.8vw", fontWeight: 600}}
                      subtitleStyle={{color: "#AAABB0", fontSize: "0.75vw", lineHeight: "120%"}}
                      iconTitleContainerStyle={{alignItems:"start", cursor:"pointer"}}
                      svgStyle={{
                        width: "3.8vw",
                        height: "3.8vw",
                        backgroundColor: "#1A1A21",
                        border: "0.15vw dashed rgba(255, 255, 255, 0.50)",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      titleContainerStyle={{maxWidth: "10vw"}}
                      onClick={() => {
                        window.open(props.userSettings?.add_module_url)
                      }}
                      icon={<img src={"/images/menu-svgs/add_module.svg"}/>}
                      /> :
                  ""}
            </div>
          </div>
        </div>
        </div>
        <div className="lower-container">
          <div className="title_reports_container">
            <div className="lower-menu-items-container">
              <div className="report_group_container">
          {props.userAllowedMenuLinks.map((item) => {
            if (item[MENU_ITEM.COLUMNS.CATEGORY] === "menu_item" && item.menu_item_category === "ADMINISTRATION" && item.children?.length > 0) {
              return (
                <IconTitle
                    key={item.menu_item_machine_name}
                    title={item.menu_item_display_name}
                    subtitle={item[MENU_ITEM.COLUMNS.MENU_ITEM_DESCRIPTION]}
                    icon={<img src={"/images/menu-svgs/" + item.menu_item_machine_name + ".svg"}/>}
                    tooltipOnHover={renderMenuItemsTooltip(item)}
                    titleStyle={{color: "black", fontSize: "0.8vw", fontWeight: 600}}
                    subtitleStyle={{color: "#AAABB0", fontSize: "0.75vw", lineHeight: "120%"}}
                    svgStyle={{width: "3.8vw", height: "3.8vw", backgroundColor: "#E5E5E5"}}
                    iconTitleContainerStyle={{alignItems:"start", cursor:"pointer"}}
                    titleContainerStyle={{maxWidth: "10vw"}}
                    type={item.menu_item_type}
                    parent_machine_name={item.menu_item_machine_name}
                    machine_name={item.menu_item_machine_name}
                    url={item.url ? item.url : "/" + item.menu_item_machine_name + "/" + item.menu_item_machine_name}
                    hasHoverEffect
                />    
              );
            }
          })}
          </div>
          </div>
          </div>
        </div>
      </div>
      {acknowledgementValue && acknowledgementValue.content.length > 0 && !props.switchClient ?
          <Modal
              id={"acknowledgement-dialog"}
              openDialog={sessionStorage.getItem("showAcknowledgeModal") === "false" ? false : showAcknowledgement}
              title={acknowledgementValue.title}
              bodyContent={acknowledgementModalBody}
              dialogActions={acknowledgementModalActions}
              size={DIALOG_SIZE.SMALL}
              removeClose={true}
              center={true}
          /> : ""}
    </>
  );
};

export { LandingPage };
