import { StepIcon } from "@material-ui/core";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import { ReactComponent as CheckedIcon } from "../../styles/images/icons/checked.svg";
// import { ReactComponent as CheckedIcon } from "../../styles/images/icons/checked-stepper-2.svg";
import { ReactComponent as FlagIcon } from "../../styles/images/icons/flag.svg";
import "./customStepper.css";
import StepperContainer from "./StepperContainer";
import { param } from "jquery";
import { CURRENT_BUILD_STATUS } from "../../class/constants";

const CustomStepIcon = ({ active, completed, step, icon }) => {
  if (icon && completed) {
    return <FlagIcon />;
  }
  if (icon) {
    return icon;
  }

  if (completed) {
    return <CheckedIcon />;
  }
  return <StepIcon icon={step} completed={completed} active={active} />; // Render the default icons for other states
};

const CustomStepper = ({ steps, handleStepComplete, refreshRender, setUpProfitStacksRef, BuildStepRef, getStepsState, showRollbackButton, setOpenConfirmRevert, setCanProceed, getCurrBuildStatus, previousPeriod}, ref) => {

  const [activeStep, setActiveStep] = useState(0);
  const [firstUnCompletedIndex, setFirstUnCompletedIndex] = useState([]);
  const [progressBarClickable, setProgressBarClickable] = useState(true);
  const stepperContainerRef = useRef();

  let prevStep = steps.find((f) => f.numb === activeStep);

  useImperativeHandle(ref, () => ({
    nextBtnClick: (areChangesSaved, skip) => {
      handleNext(areChangesSaved, skip);
    },
    handleBack:(proceed)=>{
      handleBack(proceed);
    },
    handleShowErrorsDialog: () =>{
      stepperContainerRef?.current?.showErrorsDialog();
    },
    hideErrorsDialog: () =>{
      stepperContainerRef?.current?.hideErrorsDialog();
    },
    setActiveStep: (step) =>{
        setActiveStepParam(step)
    },
    setProgressBarClickable:(param)=>{
        setProgressBarClickableParam(param);
    },
    callbackAfterCancelBuild:(param) => {
        callbackAfterCancelBuild(param);
    }
  }));

  useEffect(() => {
  }, [refreshRender]);

  useEffect(() => {
    highlightStepConnector();
  }, [activeStep]);

    useEffect(() => {
        if(steps){
            const result = steps.map(({ completed, numb }) => ({ completed, numb }));

            // Sort the array so that completed steps come before incomplete steps
             const sortedSteps = result.sort((a, b) => a.numb - b.numb);

             // Find the first Uncompleted step
             setFirstUnCompletedIndex(sortedSteps.findIndex(step => !step.completed));
        }
    }, [steps]);

  /**
   * go to the next step
   */
  function handleNext(areChangesSaved, skip) {
    if (prevStep.proceedClick && !skip) {
      prevStep.proceedClick(() => {
        setActiveStep(activeStep + 1);
        handleStepComplete(activeStep);
      }, areChangesSaved);
    } else {
      setActiveStep(activeStep + 1);
      handleStepComplete(activeStep);
    }
  }

  const setActiveStepParam = (step) => { // redirect to step
    setActiveStep(step);
  }

    const setProgressBarClickableParam = (param) => {
        setProgressBarClickable(param);
    }

    // const setHasNextButtonParam = (param) => {
    //     setHasNextButton(param);
    // }

  /**
   * This function changes the color of the step connector.
   * In MUI, each connector is for the next step and not the prev step, so in their logic, the connector of 
   * step 2 is the one on the left and step 1 does not have a connector.
   * In this function, we reversed the logic based on if the user press next or back.
   * @param {*} isNext 
   */
  function highlightStepConnector() {
    for (let index = 0; index <= steps.length; index++) {
        if(index <= activeStep){
            $("#label-" + (index) + " .MuiStepConnector-root .MuiStepConnector-line").addClass("active-connector");
            $("#label-" + (index) + " .MuiStepConnector-root .MuiStepConnector-line").removeClass("inactive-connector");
        } else {
            $("#label-" + (index) + " .MuiStepConnector-root .MuiStepConnector-line").removeClass("active-connector");
        }
    }
   
  }

  /**
   * go back to previous step
   */
  const handleBack = (proceed) => { 
    if(BuildStepRef?.current?.currentSubStep() > 0 ){ //to handle substep in build step
        BuildStepRef?.current?.handleSubStepBack();
    } else if(typeof proceed !== "boolean" && setUpProfitStacksRef?.current?.getCanSaveProgress()){ // to handle back and save in setup profit stack
        setUpProfitStacksRef?.current.handleBack("back");
    } else { // the normal scenario of back, get back in main stepper
        const currBuildStatus = getCurrBuildStatus ? getCurrBuildStatus() : false;
        if(currBuildStatus === CURRENT_BUILD_STATUS.RUNNING){
            BuildStepRef?.current?.setOpenCancelBuildModal(true, "back");
        } else {
            goBack();
        }
    }
  };

  const callbackAfterCancelBuild = (redirectTo) => {
    if(redirectTo == "back"){
        goBack();
    } else {
        redirectTostep(redirectTo);
    }
  }

  const goBack = () => {
        stepperContainerRef?.current?.hideErrorsDialog();
        setActiveStep(activeStep - 1);
        showNextButton();
  }

  /**
   * On mount, give step 2 connector .active-connector class and other connectors .inactive-connector
   */
  useEffect(() => {
    $("#label-" + (activeStep + 1) + " .MuiStepConnector-root .MuiStepConnector-line").addClass("active-connector");
    $("[id^='label-']:not(#label-" + (activeStep + 1) + ") .MuiStepConnector-root .MuiStepConnector-line").addClass("inactive-connector");
  }, []);

    const handleRedirectTostep = (stepIndex) => {
        
        if(progressBarClickable){ // a condition to check if the progress bar can be clickable before performing any logic, this condition can be affected if there is a build or a publish running
            // Check if the index matches any of the conditions
            if ( stepIndex === firstUnCompletedIndex || steps.some(step => step.numb === stepIndex && step.completed) ) {
                const currBuildStatus = getCurrBuildStatus ? getCurrBuildStatus() : false;
                if(currBuildStatus === CURRENT_BUILD_STATUS.RUNNING){
                    BuildStepRef?.current?.setOpenCancelBuildModal(true, stepIndex);
                } else {
                    redirectTostep(stepIndex);
                }
            }
        }
    }

    const redirectTostep = (stepIndex) => {
        setActiveStep(stepIndex);
        getStepsState();
        showNextButton();
        setCanProceed(true);
    }

    const showNextButton = () => {
        let nextButton = document.getElementById("monthly-build-next");
        if(nextButton){
            nextButton.style.display = "block";
        }
    }

  let currStep = steps.find((f) => f.numb === activeStep);
  let nextBtnText = activeStep === 0 ? "Start" : currStep?.saveAndProceed ? "Save and proceed" : "Proceed"
//   const cssClasses = progressBarClickable ? "uk-cursor-pointer" : "cursorNotAllowed";
  return (
    <Box sx={{ width: "90%" }}>
      <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
      <Stepper activeStep={activeStep} alternativeLabel sx={{width:"86%"}} className="main-stepper">
        {steps.map((step) => (
            
          <Step key={step.label} id={"label-" + step.numb} sx={{padding:"0"}}>

            <StepLabel
              id={"step-" + step.label}
              sx={{alignItems:"start"}}
              StepIconComponent={({ active, completed, icon }) => (
                <span className={progressBarClickable && (firstUnCompletedIndex == step.numb || step.completed) ? "uk-cursor-pointer" : "cursorNotAllowed"} onClick={() => handleRedirectTostep(step.numb)}>
                    <CustomStepIcon
                    active={active}
                    completed={step.completed}
                    icon={step.icon}
                    step={icon}
                    />
                </span>
              )}
            >
              <span
                className={progressBarClickable && (firstUnCompletedIndex == step.numb || step.completed) ? "uk-cursor-pointer" : "cursorNotAllowed"}
                onClick={() => handleRedirectTostep(step.numb)}>
                    {step.label}
              </span>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <StepperContainer
        body={currStep.content}
        hasBack={activeStep !== 0}
        nextBtnText={nextBtnText}
        hasNextBtn={activeStep !== steps.length - 1}
        nextBtnClassName={currStep.nextBtnClassName}
        errorMsgClassName={currStep.errorMsgClassName}
        nextBtnClick={handleNext}
        backBtnClick={handleBack}
        hasErrors={currStep.hasErrors}
        errorMsg={currStep.errorMsg}
        ref={stepperContainerRef}
        showRollbackButton={showRollbackButton}
        setOpenConfirmRevert={setOpenConfirmRevert}
        previousPeriod={previousPeriod}
        isSkippable={currStep.isSkippable}
        skip={currStep.skip}
      />
      </div>
    </Box>
  );
};

export default forwardRef(CustomStepper) ;
