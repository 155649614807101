import CloseIcon from '@mui/icons-material/Close';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import React, { useEffect, useState ,useImperativeHandle} from 'react';
import { API_URL, BUTTON_TYPE, BUTTON_VARIANT, DIALOG_SIZE, SIZES } from '../../class/constants';
import { parseBoolean } from '../../class/utils';
import { fetchAPI, FETCHAPI_PARAMS, FETCH_METHOD } from '../../class/networkUtils';
import '../../styles/common.css';
import "../newDialog.css";
import './sidePanelNew.css';
import { lang } from '../../language/messages_en';
import SwitchToggle from '../../newComponents/SwitchToggle';
import Modal from '../../newComponents/Modal';
import Button from '../../newComponents/Button';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default React.forwardRef(function ToggleButton({ title, infoTitle, onCheckboxChange, defaultchecked, onGenerateQuadrantsOn, isMFA, fromMFA, clientMFA, setMFA,forwardRef},ref) {

    const [toggleOn, setToggleOn] = useState(defaultchecked);
    const [open, setOpen] = useState(false);
    let TURN_ON_MFA_TEXT = lang.new_dialog.turn_on_mfa;
    let TURN_OFF_MFA_TEXT = lang.new_dialog.turn_off_mfa;

    useEffect(() => {
        if (!fromMFA) {
            setToggleOn(defaultchecked);
        }
    })
    const renderToggle = () => {
        return toggleOn;
    }
    
    // useImperativeHandle(forwardRef, () => ({
    //     renderToggle: () => {
    //      return renderToggle();
    //     }
    //   }));
    forwardRef(fromMFA ? renderToggle() : null);

    // const setMFA = () => {
    //     let query = {
    //         action: "setMFA",
    //         settingValue:toggleOn,
    //     }
    //     let onThenCallback = (data) => {
    //         sessionStorage.setItem("MFA",data.data)
    //         if (!parseBoolean(sessionStorage.getItem("MFA"))) {
    //             $('.resetMFA_container').attr("uk-tooltip", "Multi-factor authentication (MFA) is disabled")
    //             $('.resetMFA_container_icon').addClass("disabled")
    //             $('.resetMFA_container_text').addClass("disabled")
    //         } else {
    //             $('.resetMFA_container').removeAttr("uk-tooltip")
    //             $('.resetMFA_container_icon').removeClass("disabled")
    //             $('.resetMFA_container_text').removeClass("disabled")
    //         }
    //     } 
    //     let fetchOptions = {
    //         [FETCHAPI_PARAMS.funcName]: "setMFA",
    //         [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
    //         [FETCHAPI_PARAMS.showLoader]: false,
    //         [FETCHAPI_PARAMS.path]: API_URL.MANAGE_ACCESS,
    //         [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
    //         [FETCHAPI_PARAMS.query]: query,
    //         [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
    //     };
    //     fetchAPI(fetchOptions);
    // }

    const onCheck = (e) => {
        if(fromMFA){
            setOpen(true);
            return;
        }
        if (onCheckboxChange) {
            onCheckboxChange(e);
            setOpen(true);
        }
        setToggleOn(!toggleOn)
    }

    const onCheckQuadrants = (e) => {
        if (onGenerateQuadrantsOn) {
            onGenerateQuadrantsOn(e);
        }
        if(!fromMFA){
            setToggleOn(!toggleOn);
        }
    }

    // const handleClose = () => {
    //     setOpen(false);
    //     setToggleOn(!toggleOn)
    // };

    // const onDisagree = () => {
    //     setOpen(false);
    //     setToggleOn(false);
    // }
    // const onAgree = () => {
    //     setOpen(false);
    //     setToggleOn(toggleOn);
    //     setMFA();
    // }
    // const onCancelKeepOn = () => {
    //     setOpen(false);
    //     setToggleOn(true);
    // }
    const bodyContent = () =>{
        if(isMFA){
            return(
                <div className='pi-warning-background uk-border-rounded uk-padding-small'>
                    <i className="fa-2x fal fa-exclamation-triangle uk-margin-default-right" />
                    <DialogContentText id="alert-dialog-description" fontSize={"0.83vw"} className="text">
                        {TURN_ON_MFA_TEXT}
                    </DialogContentText>
                </div>

            )

        }else{
           return(
                <div className='pi-warning-background uk-border-rounded uk-padding-small'>
                    <i className="fa-2x fal fa-exclamation-triangle uk-margin-default-right" />
                    <DialogContentText id="alert-dialog-description" fontSize={"0.83vw"} className="text">
                        {TURN_OFF_MFA_TEXT}
                    </DialogContentText>
                </div>
           );
        }
    }

    const dialogActions = () => {
        return (
            <>
                <Button 
                    label={toggleOn? "Turn Off" : "Turn On"}
                    variant={BUTTON_VARIANT.PRIMARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    aria-label="Close"
                    onBtnClick={onAgree}
                />
                <Button 
                    label={"Cancel"}
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    aria-label="Close"
                    onBtnClick={isMFA? onDisagree : onCancelKeepOn}
                />
            </>
        )
    }

    const handleClose = () => {
        setOpen(false);
        // if(toggleOn != null)
        //     setToggleOn(!toggleOn)
    };
    const onDisagree = () => {
        setOpen(false);
        // setToggleOn(false);
    }
    const onAgree = () => {
        let newToggleValue = !toggleOn;
        setOpen(false);
        setToggleOn(newToggleValue);
        setMFA(newToggleValue);
    }
    const onCancelKeepOn = () => {
        setOpen(false);
        // setToggleOn(true);
    }

    return (
        <>
            {fromMFA && 
            <Modal 
                id={"mfa-dialog"}
                title={title}
                tooltipText={infoTitle}
                bodyContent={bodyContent} 
                openDialog={open}
                closeClick={handleClose}
                dialogActions={dialogActions}
                size={DIALOG_SIZE.MEDIUM}
            />
            }
        <div className="uk-flex uk-flex-middle">
            <SwitchToggle onChange={(e)=>{onCheck(e);onCheckQuadrants(e)}} checked={toggleOn} />
            <p className="fs-14 info-hover uk-margin-xsmall-left">{title}
            </p>
            {infoTitle?
                <i className="fal fa-info-circle uk-margin-small-left " uk-tooltip={infoTitle} />
                :""
            }
        </div>
        </>
    )
})
