import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { EXPANDED, HUGGED } from "../../class/constants";
import { copyObjectValues } from "../../class/utils";
import { lang } from "../../language/messages_en";
import RadioButton from "../../newComponents/RadioButton";
import PropTypes from "prop-types";

const ColumnAppearance = ({ profile, appearancesArr, tempAppearanceValue, setHasUnsavedChanges, setProfileModified }, ref) => {
  useImperativeHandle(ref, () => ({
    getSelectedAppearance: () => {
      return columnAppearance;
    },
  }));

  const [columnAppearance, setColumnAppearance] = useState(tempAppearanceValue || profile?.column_appearance || EXPANDED);

  useEffect(() => {
    if (tempAppearanceValue) {
      // this is used when we change the tabs and the changes are still not applied.
      setColumnAppearance(tempAppearanceValue);
    } else if(profile.column_appearance) {
      setColumnAppearance(profile.column_appearance);
    }
  }, [profile, tempAppearanceValue]);

  const updateAppearance = (e) => {
    setColumnAppearance(e.target.value);
    if(setHasUnsavedChanges) {
        setHasUnsavedChanges(true);
    }
    if(setProfileModified) {
        setProfileModified(true);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span className="tab-subtitle">{lang.manage_columns.select_how_view_columns}</span>
      {copyObjectValues(appearancesArr)?.map((appearance, index) => (
        <React.Fragment key={index}>
          <hr style={{ margin: "0" }} />
          <div className="appearance-option-container" key={index}>
            <RadioButton
              label={appearance.title}
              value={appearance.value}
              checked={columnAppearance?.toLowerCase() === appearance.value}
              onChange={updateAppearance}
              labelClassName={"appearance-option-label"}
            />
            <span className="appearance-option-description">{appearance?.description}</span>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default forwardRef(ColumnAppearance);
