

export function getUserSections() {
  return {
    type: 'GET_USERSECTIONS'
  }
}


export function updateUserSectionsData(userSections) {
  return {
    type: 'UPDATE_USERSECTIONSDATA',
    userSections:userSections,
  }
}