import React, { Component } from 'react';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import { copyObjectValues } from '../../class/utils';

class Container extends Component {
    constructor(props){
      super(props)
      this.state = { data: copyObjectValues(props.data) }
    }
  
    componentDidUpdate = (prevProps) => {
        if(prevProps !== this.props && (prevProps.id !== this.props.id || JSON.stringify(this.props.data) !== JSON.stringify(this.state.data))){
          this.setState({ data: copyObjectValues(this.props.data) })
      }
      return null;
    }
    // shouldComponentUpdate = (nextProps) => {
    //   return JSON.stringify(nextProps.data) !== JSON.stringify(this.state.data)
    // }
  
    render() {
      const { data, ...rest } = this.props
      return (
        <DropdownTreeSelect keepTreeOnSearch={true} keepChildrenOnSearch={true} data={copyObjectValues(this.props.data)} {...rest}  />
      )
    }
  }

  export default Container;