import React, { Component } from 'react';
import { getTranslationFile } from '../../class/utils';
import { getObjectAsArray } from '../../class/jqueries';
import FormulaDrop from './FormulaDrop';
import { STAGING_SECTIONS, ENGINE_FILTER } from '../../class/constants';
import EngineFilterDialog from '../filter/EngineFilterDialog';

const lang = getTranslationFile();
const _file = ENGINE_FILTER.KEYS.FILE;
const _column = ENGINE_FILTER.KEYS.COLUMN;

const _function = ENGINE_FILTER.KEYS.FUNCTION;
const _rawFileSubtypeId = ENGINE_FILTER.KEYS.RAW_FILE_SUBTYPE_ID;
const _fieldDataType = ENGINE_FILTER.KEYS.FIELD_DATA_TYPE;
const _columnOptions = ENGINE_FILTER.KEYS.COLUMN_OPTIONS;
const _functionOptions = ENGINE_FILTER.KEYS.FUNCTION_OPTIONS;
const _valueOptions = ENGINE_FILTER.KEYS.VALUE_OPTIONS;

class FormulaCondition extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formula: [],
        };

        this.setFilterAttrOptions = this.setFilterAttrOptions.bind(this);
        this.handleFilterRowChange = this.handleFilterRowChange.bind(this);
    }

    setFilterAttrOptions(rowIndex, attribute, options) {
        var _this = this;
        if(!this.filterDialRef?.state.filterRefs[rowIndex] || !this.filterDialRef?.state.filterRefs[rowIndex].ref.current) {
            return;
        }
        if(attribute === _valueOptions) {
            this.filterDialRef.state.filterRefs[rowIndex].ref.current.isLoading = false;
        }
        this.filterDialRef.state.filterRefs[rowIndex].ref.current.filterObj[attribute] = options;
        let menuIsOpen = this.filterDialRef.state.filterRefs[rowIndex].ref.current.state.menuIsOpen;
        this.filterDialRef.state.filterRefs[rowIndex].ref.current.refresh(function() {
            if(menuIsOpen) {
                _this.filterDialRef.state.filterRefs[rowIndex].ref.current.selectRef.focus();
            }
        });
    }

    componentDidUpdate() {
        var obj = this;
        if(this.props.conditionObject.filters.length) {
            this.props.conditionObject.filters.forEach((condFilter, rowIndex) =>{
                if(!obj.filterDialRef.state.filterRefs[rowIndex]) return;
                let filterObj = obj.filterDialRef.state.filterRefs[rowIndex].ref.current.filterObj;

                //if no file options, do nothing cz file options are passed as props
                // if(!filterObj[_fileOptions] || !filterObj[_fileOptions].length) {
                //     obj.setFilterAttrOptions(rowIndex, _fileOptions, obj.props.fileOptions);
                // }

                if(!filterObj[_columnOptions] || !filterObj[_columnOptions].length) {
                    let valueObj = {
                        value: condFilter[_file],
                        [_rawFileSubtypeId]: condFilter[_rawFileSubtypeId]
                    };
                    //set file value to fetch column options
                    obj.handleFilterRowChange(undefined, _file, rowIndex, obj.props.conditionNumber, valueObj, "");
                }

                if(!filterObj[_valueOptions] || !filterObj[_valueOptions].length || !filterObj[_functionOptions] || !filterObj[_functionOptions].length) {
                    let valueObj = {
                        value: condFilter[_column],
                        [_function]: condFilter[_function],
                        [_fieldDataType]: condFilter[_fieldDataType]
                    }
                    //set column to fetch functions and values
                    obj.handleFilterRowChange(undefined, _column, rowIndex, obj.props.conditionNumber, valueObj, "");
                }
            });
        }
    }

    componentDidMount() {
        this.filterDialRef.setFilterFromOutside(this.props.conditionObject.filters);
    }

    handleFilterRowChange(object, attribute, rowIndex, conditionIndex, valueObj, inputValue) {
        let _this = this;
        let callback = ()=>{};
        switch (attribute){
            case _file:
                callback= (options) => {
                    //setting the file options
                    _this.setFilterAttrOptions(rowIndex, _columnOptions, options);
                };
            break;

            case _column:
                //setting the function options
                var functionOptions = getObjectAsArray(lang.ui_filter.dropdowns.engine_filter_functions, valueObj[_fieldDataType].toLowerCase(), "value_type");
                this.setFilterAttrOptions(rowIndex, _functionOptions, functionOptions);

                callback = function(values) {
                    //setting the column options
                    _this.setFilterAttrOptions(rowIndex, _valueOptions, values);
                }
                break;
        }

        this.props.handleFilterRowChange(object, attribute, rowIndex, conditionIndex, valueObj, inputValue, callback);
    }

    render() {
        var i = this.props.conditionNumber;
        return(
            <div className="condition-container uk-border uk-border-rounded uk-position-relative">
                <div className="formula-header">
                    <div className="uk-display-flex uk-flex-bottom fs-14 uk-text-bold uk-margin-default-bottom">{lang.calc_cols.condition}&nbsp;{this.props.conditionNumber + 1}</div>
                    {this.props.conditionsTotal > 1 ?
                        <div className="uk-button-icon transparent-bg uk-margin-xsmall-bottom" onClick={()=>this.props.deleteCondition(this.props.conditionNumber)}>
                            <i className="fal fa-trash-alt fa-lg fs-16" />
                        </div>
                    :""}
                </div>
                <div className="vector-engine-filter">
                    <div className="vectors-filter-container" id={"engineFilter_"+i}>
                        <EngineFilterDialog
                            stagingReport={STAGING_SECTIONS.CALCULATED_COLUMNS}
                            showFileDropdown={true}
                            ref={el => this.filterDialRef = el}
                            fileTypeOptions={this.props.fileOptions}
                            onDropDownChange={this.handleFilterRowChange}
                            rowIndex={i}
                            scenario_id={this.props.scenario}
                            conditionIndex={this.props.conditionNumber}
                            compNumber={i} //1 for exclusions, 2 for vectors
                        />
                    </div>
                </div>

                <FormulaDrop  ref={el=>this.formulaRef = el} appendToFormula={this.props.appendToFormula}
                    formula={this.props.conditionObject.result} isInnerComp={true} conditionNumber={this.props.conditionNumber}
                    setFormulaIndex={this.props.setFormulaIndex} resetFormulaChanges={this.props.resetFormulaChanges}
                    deleteLastFormulaItem={this.props.deleteLastFormulaItem} deleteFormula={this.props.deleteFormula}
                    hasConditions={this.props.conditionObject.filters.length > 0}
                />
            </div>
        );
    }
}


export default FormulaCondition;