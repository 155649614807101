import Button from "@mui/material/Button";
import { React, useState } from "react";
import EntitieNewTabulator from "./EntitieNewTabulator";
import { ClickAwayListener } from "@material-ui/core";
import { Box } from "@mui/material";

export default function ButtonSizes(props) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const applyClick = (isRow) => {
    if(props.apply){

      props.apply(isRow);

    } 
    handleClick();
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box sx={{ position: "relative", display: "grid" }}>
        <div style={{ alignItems: "flex-start", display: "flex", justifySelf: "center", flexDirection: "column" }}>
          <Button
            id={props.id + "_button"}
            className={"select-entity-btn select-entities-btn " + props.id}
            size="small"
            sx={{ color: "black" }}
            onClick={handleClick}
          >
            Select Entities
            <i class={"select-entity-btn select-entity-chevron far fa-chevron-down uk-margin-small-left " + props.id} aria-hidden="true"></i>
          </Button>

          <div className={props.id + (open ? "" : " hide-drop")}>
            <EntitieNewTabulator
              id={props.id}
              columns={props.columns}
              entities={props.entities}
              limitEntities={props.limitEntities}
              tempSelectedEntitiesObj={props.tempSelectedEntitiesObj}
              apply={applyClick}
              setSelectedEntities={props.setSelectedEntities}
              setGroupByValue={props.setGroupByValue}
              resetToDefault={props.resetToDefault}
              isChanged={props.isChanged}
              isApplyDisabled={props.isApplyDisabled}
            />
          </div>
        </div>
      </Box>
    </ClickAwayListener>
  );
}
