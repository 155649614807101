import React, { useState, useEffect } from 'react';
import ToastPortal from '../portals/ToastPortal';
import uiManager from '../components/UIManager';

const ToastListener = () => {
  const [toastState, setToastState] = useState({ message: '', show: false });
  useEffect(() => {
    const handleToast = ({ message, show }) => {
      setToastState({ message, show });
    };

    // Listen to toast events
    uiManager.on('toast', handleToast);

    return () => {
      uiManager.off('toast', handleToast); // Cleanup on unmount
    };
  }, []);

  return (
    <ToastPortal
      message={toastState.message}
      show={toastState.show}
      onClose={() => uiManager.hideToast()} // Hide toast when close button is clicked
    />
  );
};

export default ToastListener;
