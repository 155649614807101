import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import './Style/HoverableList.css';
import { ReactComponent as LeftMenuIcon } from "../../styles/images/icons/left-arrow.svg";

const HoverableList = forwardRef((props,ref) => {

    const [lisHovered, setListHovered] = useState(false);

    const { title, periods, controlListVisibilty } = props;

    useEffect(() => {
        if(!lisHovered){ // when we stop hovering the list, call function in parent component
            controlListVisibilty()
        }
    }, [lisHovered]);

    useImperativeHandle(ref, () => ({
        isLisHovered:()=>{
            return lisHovered;
        }
    }));

    return (
        <div className="hover-list"
            onMouseEnter={() => setListHovered(true)}
            onMouseLeave={() => setListHovered(false)}
        >
            <LeftMenuIcon className='list-left-arrow'/>
            <div className="rectangle-list">
                <div className="rectangle-list-title">
                    {title}
                </div>
                <div className="rectangle-list-border"></div>
                <div className="rectangle-list-content">
                    {periods.map((period, index) => (
                        <React.Fragment key={index}>
                            <div className="rectangle-list-name">
                                {period}
                            </div>
                            {index < periods.length - 1 && <div className="rectangle-list-border-light"></div>}
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    );
});

export default HoverableList;
