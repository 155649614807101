import React, { Component } from 'react';
import '../../styles/dataModeling.css';
import {TabulatorFull} from "tabulator-tables"; //import {TabulatorFull} library
import { copyObjectValues, getTranslationFile, tryParse, capitalizeFirstLetter, parseBoolean } from '../../class/utils.js';
import {ACCRUALS, FormatTypes, costtype, FISCAL_YEARS, GLACCOUNTS_FIELDS, PS_MAPPING} from '../../class/constants.js';
import {getProfitStackLines} from '../stageData/CommonRequests';
import { cleanUpTabulatorColumns, getLeafNodes} from '../../class/common.js';
import { formatValHTML } from "../../class/format";
import { replaceSpecialChars } from '../../class/string';
import { convertPxToViewport, convertViewportToPx } from '../../class/formatting';
import { createCheckbox } from '../../newComponents/CheckBoxTabulator';
import { getExpandCollapseButtons, getTableButton, getTableIconButton } from '../../newComponents/tabulatorComponents';

const $ = require('jquery');

const CHECK_TITLE = "";
const CHECK_FIELD = "checkbox";
const lang = getTranslationFile();

const MAPPING_EXCEPTION = PS_MAPPING.FIELDS.MAPPING_EXCEPTION;
const ACCRUAL_SUFFIX = "- Accrual";
const ACTUAL_SUFFIX = "- Actual";
const _returnName = PS_MAPPING.FIELDS.RETURN_NAME;
const _transaction = GLACCOUNTS_FIELDS.MAP_EXCEPTION_VALUES.TRANSACTION;
const _ancillary = GLACCOUNTS_FIELDS.MAP_EXCEPTION_VALUES.ANCILLARY;

const checkboxCellObject = {
    title: CHECK_TITLE,
    field: CHECK_FIELD,
    visible: true,
    headerSort: false,
	width: convertViewportToPx(50),
    formatter: 'checkbox'
}

var ACCRUALS_COLUMNS = [
    {
        title: "",
        field: "expand",
        headerSort: false,
        width: convertViewportToPx(125)
    },
    {   
        title: ACCRUALS.TITLES.ID,
        field: ACCRUALS.FIELDS.COSTKEY,
        format_type: FormatTypes.TEXT,
        headerSort: false,
        hozAlign: "left",
        dontFilter: true,
        width: convertViewportToPx(75)
    },
    {   
        title: "",
        field: ACCRUALS.FIELDS.ID,
        format_type: FormatTypes.NUMERIC,
        headerSort: false,
        visible: false,
        dontFilter: true,
    },
    {   
        title: "",
        field: ACCRUALS.FIELDS.ACTUAL_ID,
        format_type: FormatTypes.NUMERIC,
        headerSort: false,
        visible: false,
        dontFilter: true
    },
    {   
        title: ACCRUALS.TITLES.NAME,
        field: ACCRUALS.FIELDS.NAME,
        format_type: FormatTypes.TEXT,
        widthGrow:2,
        hozAlign: "left",
        headerSort: false,
        dontFilter: true

    },
    {   
        title: ACCRUALS.TITLES.FY_COST,
        field: ACCRUALS.FIELDS.FY_COST,
        headerSort: false,
        dontFilter: true,

    },
    {   
        title: "",
        field: ACCRUALS.FIELDS.PARENT_COST_KEY,
        headerSort: false,
        dontFilter: true,
        visible: false

    },
    {   
        title: ACCRUALS.TITLES.FY_COST_PERC,
        field: ACCRUALS.FIELDS.FY_COST_PERC,
        headerSort: false,
        dontFilter: true,

    },
    {   
        title: ACCRUALS.TITLES.BE_ACCRUED,
        field: ACCRUALS.FIELDS.BE_ACCRUED,
        headerSort: false,
        widthGrow: 2,
        widthShrink: 0,
        dontFilter: true

    },
    {   
        title: ACCRUALS.TITLES.STATUS,
        field: ACCRUALS.FIELDS.STATUS,
        headerSort:false,
        formatter:"headerselect"

    },
    {   
        title: ACCRUALS.TITLES.ACTIONS,
        field: ACCRUALS.FIELDS.ACTIONS,
        format_type: FormatTypes.TEXT,
        headerSort:false,
        dontFilter:true,
        cssClass:"actions-cell"
    },
    {   
        title: "",
        field: ACCRUALS.FIELDS.RULE,
        format_type: FormatTypes.TEXT,
        headerSort:false,
        dontFilter:true,
        visible: false
    },
    {   
        title: "",
        field: ACCRUALS.FIELDS.FILTER,
        format_type: FormatTypes.TEXT,
        headerSort:false,
        dontFilter:true,
        visible: false
    },
    {   
        title: "",
        field: ACCRUALS.FIELDS.JSON_FILTER,
        format_type: FormatTypes.TEXT,
        headerSort:false,
        dontFilter:true,
        visible: false
    },
    {   
        title: "",
        field: ACCRUALS.FIELDS.COST_CENTER,
        visible: false
    },
];
/**
 * Accruals Table in manageAccruals Screen
 * @author [Sarah Farjallah]
 */
class DefineAccrualsTable extends Component {
    constructor(props) {
		super(props);
		this.state = {
			tableColumns: [],
            tableData: [],
            toBeDeleted:[]
        };

        this.getProfitStackFields= this.getProfitStackFields.bind(this);
        this.formatData = this.formatData.bind(this);
        this.setChosenFiscalYear = this.setChosenFiscalYear.bind(this);
        this.enableActionButtons = this.enableActionButtons.bind(this);
        this.enableChildren = this.enableChildren.bind(this);
        this.modifyStatus = this.modifyStatus.bind(this);
        this.addManualChild = this.addManualChild.bind(this);
        this.handleAccruedLine = this.handleAccruedLine.bind(this);
        this.generateUniqueCostKey = this.generateUniqueCostKey.bind(this);
        this.getRandomCostkey = this.getRandomCostkey.bind(this);
        this.removeAccrualLine = this.removeAccrualLine.bind(this);
        this.findAccrual = this.findAccrual.bind(this);
        this.returnChildrenCountByStatus = this.returnChildrenCountByStatus.bind(this);
        this.getPearsonCorrelation = this.getPearsonCorrelation.bind(this);
        this.onChangeScenario = this.onChangeScenario.bind(this);
        
        //if cost data not available, show loader (it might be already there when user remounts this table after coming back from evaluate/predict screen)
        this.isFetchingFYData = !this.props.costFYData;
	}

    returnDataCountByStatus() {
        var data = getLeafNodes(this.tabulator.getData());
        var counts = { excluded: 0, accrued:0, notdefined:0 };
        var countsAggregated = this.returnChildrenCountByStatus(data, counts);
        
        return [countsAggregated.accrued, countsAggregated.excluded, countsAggregated.notdefined];
    }

    returnChildrenCountByStatus(data, counts) {
        for(var i in data) {
            var row = data[i];
            if(row.children !== undefined && row.children.length > 0) {
                var childrenCounts = this.returnChildrenCountByStatus(row.children, counts);
            } else {
                if(row[ACCRUALS.FIELDS.STATUS] === ACCRUALS.FIELDS.STATUS_VALUES.EXCLUDED) {
                    counts.excluded++;
                }
                if(row[ACCRUALS.FIELDS.STATUS] === ACCRUALS.FIELDS.STATUS_VALUES.ACCRUED) {
                    counts.accrued++;
                }
                if(row[ACCRUALS.FIELDS.STATUS] === undefined || row[ACCRUALS.FIELDS.STATUS] === ACCRUALS.FIELDS.STATUS_VALUES.UNDEFINED) {
                    counts.notdefined++;
                }        
            }
        }

        return counts;
    }

    setChosenFiscalYear(option) {
        this.isFetchingFYData = true;
        $(".fy_cost_loader").removeClass("uk-hidden");
        $(".fy_cost_value").addClass("uk-hidden");
        // this.tabulator.redraw();
    }

    addManualChild(costKey, data, actualId, childCostKey, newRow, status) {
        for (var e in data) {
            if (data[e][ACCRUALS.FIELDS.COSTKEY] === costKey) {
                var dataChildren = data[e][ACCRUALS.FIELDS.CHILDREN];
                var index = "";
                for(var elt in dataChildren){
                    if(!dataChildren[elt][ACCRUALS.FIELDS.NAME].includes(ACTUAL_SUFFIX) && dataChildren[elt][ACCRUALS.FIELDS.ID] === actualId) {
                        dataChildren[elt][ACCRUALS.FIELDS.NAME] = dataChildren[elt][ACCRUALS.FIELDS.NAME] + ACTUAL_SUFFIX;
                        dataChildren[elt][ACCRUALS.FIELDS.ROW_STATUS] = ACCRUALS.FIELDS.ROW_STATUS_VALUES.EDITED;
                        dataChildren[elt][ACCRUALS.FIELDS.STATUS] = status
                        index = elt;
                        break;
                    }
                }
                data[e][ACCRUALS.FIELDS.CHILDREN].splice(Number(index)+1, 0, newRow);
            } else if (data[e][ACCRUALS.FIELDS.CHILDREN]) {
                this.addManualChild(costKey, data[e][ACCRUALS.FIELDS.CHILDREN], actualId, childCostKey, newRow, status);
            }
        }
    }

    checkSelectedCheckboxes(data){
        var filteredData = data.filter(elt=>elt.checked && elt.checked === "true");
        if(filteredData.length === 0){
            this.props.disableAllButtons(true);
        }
    }

    enableChildren(data) {
        for (var elt in data) {
            if (data[elt].children && data[elt].children.length > 0) {
                this.enableChildren(data[elt].children);
            } else {
                if ($("#chosenEntity-" + data[elt][ACCRUALS.FIELDS.COSTKEY]).prop("checked")) {
                    this.props.disableSpecificButtons(data[elt][ACCRUALS.FIELDS.STATUS]);
                }
            }
        }

    }

    getRandomCostkey() {
        var costkey = Math.floor(Math.random()*1000) + 1;
        return costkey === 201 ? this.getRandomCostkey() : costkey;
    }

    generateUniqueCostKey() {
        var generatedId = "";
        do{
            var inPSFields = 0;
            generatedId = this.getRandomCostkey();
            for(var i = 0 ; i < this.state.costkeys.length; i++){
                if(Number(this.state.costkeys[i].costkey.replace("Insert","")) === generatedId) {
                    inPSFields = -1;
                    break;
                } 
            }
                        
            if(inPSFields === 0)
                inPSFields = 1;

        } while(inPSFields <= 0)
        this.state.costkeys.push({costkey:generatedId.toString()});
        return generatedId;
    }

    removeAccrualLine(costKey, data, actualId, childCostKey, deletedCostKeys) {
        for (var e in data) {
             if (data[e][ACCRUALS.FIELDS.COSTKEY] === costKey) {
                 var dataChildren = data[e].children;
                for(var elt in dataChildren){
                     if(dataChildren[elt][ACCRUALS.FIELDS.NAME].includes(ACTUAL_SUFFIX) && dataChildren[elt][ACCRUALS.FIELDS.ID] === actualId) {
                        dataChildren[elt][ACCRUALS.FIELDS.NAME] = dataChildren[elt][ACCRUALS.FIELDS.NAME].replace(ACTUAL_SUFFIX,"");
                        dataChildren[elt][ACCRUALS.FIELDS.ROW_STATUS] = ACCRUALS.FIELDS.ROW_STATUS_VALUES.EDITED;
                        dataChildren[elt][ACCRUALS.FIELDS.ACCRUE_STATUS] = ACCRUALS.FIELDS.ROW_STATUS_VALUES.REMOVED;
                    }
                     if (dataChildren[elt][ACCRUALS.FIELDS.NAME].includes(ACCRUAL_SUFFIX) && dataChildren[elt][ACCRUALS.FIELDS.ACTUAL_ID] && dataChildren[elt][ACCRUALS.FIELDS.ACTUAL_ID] === actualId) {
                        dataChildren[elt].deleted = "true";
                        dataChildren[elt][ACCRUALS.FIELDS.ROW_STATUS] = ACCRUALS.FIELDS.ROW_STATUS_VALUES.DELETED;
                        deletedCostKeys.push(dataChildren[elt][ACCRUALS.FIELDS.COSTKEY]);
                     }
                 }
            } 
            if (data[e].children) {
                this.removeAccrualLine(costKey, data[e].children, actualId, childCostKey, deletedCostKeys);
            }
        }
    }

    findAccrual(data, parentCostKey, actualId) {
        for (var e in data) {
            if (data[e][ACCRUALS.FIELDS.COSTKEY] === parentCostKey) {
                for (var elt in data[e].children) {
                    if (data[e].children[elt][ACCRUALS.FIELDS.ACTUAL_ID] === actualId) {
                        return true;
                    }
                }
            } else if (data[e].children) {
                if (this.findAccrual(data[e].children, parentCostKey, actualId) === true ) {
                    return true;
                } else {
                    continue;
                };
            }
        }
        return false;
    }

    handleAccruedLine(actualLine, data, remove, status) {
        var toBeDeletd = this.state.toBeDeleted;
        if (remove) {
            this.removeAccrualLine(actualLine[ACCRUALS.FIELDS.PARENT_COST_KEY], data, actualLine[ACCRUALS.FIELDS.ID], actualLine[ACCRUALS.FIELDS.COSTKEY], toBeDeletd);
            this.setState({
                toBeDeletd: toBeDeletd
            })
        } else{
            var newCostKey = this.generateUniqueCostKey(this.state.profitStackFields);
            var name = actualLine[ACCRUALS.FIELDS.NAME].includes(ACTUAL_SUFFIX) ? actualLine[ACCRUALS.FIELDS.NAME].replace(ACTUAL_SUFFIX,"") + ACCRUAL_SUFFIX : actualLine[ACCRUALS.FIELDS.NAME] + ACCRUAL_SUFFIX;
            // var accrualLine = this.findAccrual(data,actualLine[ACCRUALS.FIELDS.PARENT_COST_KEY].toString(), actualLine[ACCRUALS.FIELDS.ID].toString())
            // if (!accrualLine) {
                var newRow = {
                    [ACCRUALS.FIELDS.NAME] : name,
                    [ACCRUALS.FIELDS.COSTKEY]: newCostKey.toString() ,
                    [ACCRUALS.FIELDS.PARENT_COST_KEY]: actualLine[ACCRUALS.FIELDS.PARENT_COST_KEY].toString(),
                    [ACCRUALS.FIELDS.ROW_STATUS]: ACCRUALS.FIELDS.ROW_STATUS_VALUES.NEW,
                    level: actualLine.level,
                    config: actualLine.config,
                    [ACCRUALS.FIELDS.FILTER]: actualLine[ACCRUALS.FIELDS.FILTER],
                    rule: actualLine[ACCRUALS.FIELDS.RULE],
                    jsonFilter: actualLine[ACCRUALS.FIELDS.JSON_FILTER],
                    fieldsCombination:[],
                    [MAPPING_EXCEPTION]: actualLine.mappingException ? actualLine.mappingException : 'NONE',
                    fileName: "",
                    isNotAC: false,
                    deleted: "false",
                    [ACCRUALS.FIELDS.COSTTYPE] : "accrued",
                    costCenter: actualLine[ACCRUALS.FIELDS.COST_CENTER],
                    returnname: actualLine[_returnName] + replaceSpecialChars(ACCRUAL_SUFFIX),
                    [ACCRUALS.FIELDS.ACTUAL_ID]: actualLine[ACCRUALS.FIELDS.ID],
                    [ACCRUALS.FIELDS.STATUS]: ACCRUALS.FIELDS.STATUS_VALUES.ACCRUAL,
                    new:"1"
                };
                if (actualLine[ACCRUALS.FIELDS.PARENT_COST_KEY] === '201') {
                    data.push(newRow);
                } else{
                    this.addManualChild(actualLine[ACCRUALS.FIELDS.PARENT_COST_KEY], data, actualLine[ACCRUALS.FIELDS.ID], actualLine[ACCRUALS.FIELDS.COSTKEY], newRow, status); // adds unmatched line to pss
                }
            // }
        }  

        // this.tabulator.setData(data);
    }
    
    onChangeScenario(option) {
        this.getProfitStackFields();

    }

    modifyStatus(status, data, originalData, action, fromEvaluateData) {
        for (var elt in data) {
            if (data[elt].children && data[elt].children.length > 0) {
                this.modifyStatus(status,data[elt].children, originalData, action, fromEvaluateData);
            } else {
                if ($("#chosenEntity-"+data[elt][ACCRUALS.FIELDS.COSTKEY]).prop('checked')) {
                    let oldStatus = data[elt][ACCRUALS.FIELDS.STATUS];
                    data[elt][ACCRUALS.FIELDS.STATUS] = status;
                    data[elt][ACCRUALS.FIELDS.ROW_STATUS] = ACCRUALS.FIELDS.ROW_STATUS_VALUES.EDITED;
                    if (oldStatus === ACCRUALS.FIELDS.STATUS_VALUES.ACCRUED && ( status === ACCRUALS.FIELDS.STATUS_VALUES.EXCLUDED || action === ACCRUALS.ACTIONS.REMOVE_ACCRUAL)) {
                        this.handleAccruedLine(data[elt], originalData, true, status);
                    }
                    $("#chosenEntity-"+data[elt][ACCRUALS.FIELDS.COSTKEY]).prop('checked',false) ;
                    if (status === ACCRUALS.FIELDS.STATUS_VALUES.ACCRUED) {
                        this.handleAccruedLine(data[elt], originalData, false, status);
                    } 
                } else if (fromEvaluateData && data[elt]["costKey"] === fromEvaluateData["costKey"]) {
                    let oldStatus = data[elt][ACCRUALS.FIELDS.STATUS];
                    data[elt][ACCRUALS.FIELDS.STATUS] = status;
                    data[elt][ACCRUALS.FIELDS.ROW_STATUS] = ACCRUALS.FIELDS.ROW_STATUS_VALUES.EDITED;
                    if (oldStatus === ACCRUALS.FIELDS.STATUS_VALUES.ACCRUED && ( status === ACCRUALS.FIELDS.STATUS_VALUES.EXCLUDED || action === ACCRUALS.ACTIONS.REMOVE_ACCRUAL)) {
                        this.handleAccruedLine(data[elt], originalData, true, status);
                    }
                    if (status === ACCRUALS.FIELDS.STATUS_VALUES.ACCRUED) {
                        this.handleAccruedLine(data[elt], originalData, false, status);
                    }
                }
            }
        }
    }

    modifyStatusPerRow(status,action,rowData,originalData){
        var oldStatus = rowData[ACCRUALS.FIELDS.STATUS];
        rowData[ACCRUALS.FIELDS.STATUS] = status;
        rowData[ACCRUALS.FIELDS.ROW_STATUS] = ACCRUALS.FIELDS.ROW_STATUS_VALUES.EDITED;
        originalData = this.tabulator.getData();
        if (oldStatus === ACCRUALS.FIELDS.STATUS_VALUES.ACCRUED && ( status === ACCRUALS.FIELDS.STATUS_VALUES.EXCLUDED || action === ACCRUALS.ACTIONS.REMOVE_ACCRUAL)) {
            this.handleAccruedLine(rowData, originalData, true, status);
        }
        if (status === ACCRUALS.FIELDS.STATUS_VALUES.ACCRUED) {
            this.handleAccruedLine(rowData, originalData, false, status);
        }
        this.props.setDataProvider(this.returnDataCountByStatus());
        this.tabulator.replaceData(originalData);
        this.props.setChangedStatus(true);
        this.props.disableAllButtons(true)

    }

    enableActionButtons(e, cell) {
        this.props.disableAllButtons(false);

        let hasChecked = $(".chosenEntity:checked").length > 0;
        cell.getData().checked = e.target.checked ? "true" : "false";
        // var data = this.tabulator.getData();
        // this.checkSelectedCheckboxes(getLeafNodes(data));
        if(hasChecked) {
            this.enableChildren(this.tabulator.getData());
        } else {
            this.props.disableAllButtons(true);
        }
    }

    checkIfActionButtonShouldBeDisabled = (rowData) => {
        let _this = this;
        let isDisabled = false;
        let mappedLines = _this.props?.mappedLines;
        let pssId = rowData?.pssId;
        let line  = mappedLines?.filter(psl => psl.pssId === pssId)?.[0];

        if(!!line) {
            let pssLeadingId = line?.pssLeadingId;
            if(pssLeadingId){
                let siblings = mappedLines?.filter(psl => psl.pssLeadingId === pssLeadingId);
                let containsTransaction = siblings?.filter(e=>e[MAPPING_EXCEPTION] === _transaction)?.length > 0;
                let containsAncillary = siblings?.filter(e=>e[MAPPING_EXCEPTION] === _ancillary)?.length > 0;
                isDisabled = containsTransaction && containsAncillary && !!line.is_matched && !parseBoolean(line.is_matched)
            }
        }
       
        return isDisabled;
    }

	getColumnFormatter(colField) {
		var _this = this;
		var columnFormatter;
		switch (colField) {
            case CHECK_FIELD:
                columnFormatter = function(cell, formatterParams) {
                    var rowData = cell.getRow().getData();
                    if(!rowData["children"] || rowData["children"].length === 0) {
                        var div = document.createElement('div');
                        var checkbox = createCheckbox();
                        checkbox.name='chosenEntity';
                        checkbox.id='chosenEntity-' + rowData[ACCRUALS.FIELDS.COSTKEY];
                        checkbox.classList.add('chosenEntity');
                        if(rowData[MAPPING_EXCEPTION] === _transaction) {
                            checkbox.setAttribute("disabled", "");
                            div.setAttribute('uk-tooltip', lang.manage_accruals_disabled_line);
                        }
                        if(_this.checkIfActionButtonShouldBeDisabled(rowData)){
                            checkbox.setAttribute("disabled", "");
                            div.setAttribute('uk-tooltip', lang.manage_accruals_disabled_line_two);
                        }
                        div.appendChild(checkbox);
                        checkbox.onchange = (e) => {
                            _this.enableActionButtons(e, cell);
                        };
                        return div;
                    }
                };
                break;
                case ACCRUALS.FIELDS.NAME:
                    columnFormatter = function(cell,formatterParams) {
                        var rowData = cell.getRow().getData();
                        var p = document.createElement("p");
                        let element = $(cell.getRow().getElement());
                        p.textContent = cell.getValue();
                        if(rowData["level"] !== 1) {
                            var pixels = (rowData["level"]-1)*20;
                            $(p).css("padding-left", convertPxToViewport(pixels));
                        }
                        if (rowData['level'] === 1) {
                            element.css({"background-color": "#f3f3f3"});
                            element.css({"border-color":"#DCDCDC"});
                        } else if (rowData["level"] === 2){
                            element.css({"background-color": "rgba(202, 202, 202, 0.5)"});
                            element.css({"border-color":"#DCDCDC"});
                        } else {
                            element.css({"background-color": "rgb(202, 202, 202, 0.8)"});
                            element.css({"border-color":"#cacaca"});
                        }
                        if (rowData[ACCRUALS.FIELDS.STATUS] === ACCRUALS.FIELDS.STATUS_VALUES.ACCRUAL) {
                            element.css({"display":"none"});
                        }
                        if ([costtype.calculated,costtype.attribute].includes(rowData[ACCRUALS.FIELDS.COSTTYPE])) {
                            element.css({"display":"none"});
                        }
                        return p;
                    };
                    break;
                    case ACCRUALS.FIELDS.FY_COST:
                    columnFormatter = function(cell,formatterParams) {
                        var rowData = cell.getRow().getData();
                        var div = document.createElement("div");
                        if(!rowData.children || rowData.children.length === 0) {
                            let img = document.createElement("img");
                            img.src = '/images/FhHRx.gif';
                            img.style.width = convertPxToViewport(15);
                            img.style.height = convertPxToViewport(15);
                            img.id = "small_loader";
                            img.classList.add("fy_cost_loader");
                            div.appendChild(img);

                            var p = document.createElement("p");
                            p.innerHTML = formatValHTML(rowData[ACCRUALS.FIELDS.FY_COST], FormatTypes.AMOUNT);
                            p.classList.add("fy_cost_value");
                            if(!_this.isFetchingFYData) {
                                img.classList.add("uk-hidden");
                            } else {
                                p.classList.add("uk-hidden");
                            }
                            div.appendChild(p);
                        }
                        return div;
                    };
                    break;
                    case ACCRUALS.FIELDS.FY_COST_PERC:
                        columnFormatter = function(cell,formatterParams) {
                            var rowData = cell.getRow().getData();
                            var div = document.createElement("div");
                            if(!rowData.children || rowData.children.length === 0) {
                                let img = document.createElement("img");
                                img.src = '/images/FhHRx.gif';
                                img.style.width = convertPxToViewport(15);
                                img.style.height = convertPxToViewport(15);
                                img.id = "small_loader";
                                img.classList.add("fy_cost_loader");
                                div.appendChild(img);

                                var p = document.createElement("p");
                                var amount = formatValHTML(rowData[ACCRUALS.FIELDS.FY_COST_PERC], FormatTypes.PERCENTAGE)
                                if (rowData[ACCRUALS.FIELDS.FY_COST_PERC] >= Number(_this.props.fyThreshold) &&
                                    rowData[ACCRUALS.FIELDS.BE_ACCRUED] > Number(_this.props.rThreshold)) {
                                    p.classList.add('greenText')
                                }
                                p.innerHTML = amount;
                                p.classList.add("fy_cost_value");
                                if(!_this.isFetchingFYData) {
                                    img.classList.add("uk-hidden");
                                } else {
                                    p.classList.add("uk-hidden");
                                }
                                div.appendChild(p);
                            }
                            return div;
                        };
                    break;
                    case ACCRUALS.FIELDS.BE_ACCRUED:
                        columnFormatter = function(cell,formatterParams) {
                            var div = document.createElement("div");
                            var rowData = cell.getRow().getData();
                            if(!rowData.children || rowData.children.length === 0) {
                                let img = document.createElement("img");
                                img.src = '/images/FhHRx.gif';
                                img.style.width = convertPxToViewport(15);
                                img.style.height = convertPxToViewport(15);
                                img.id = "small_loader";
                                img.classList.add("fy_cost_loader");
                                div.appendChild(img);

                                var p = document.createElement("p");
                                var amount = formatValHTML(rowData[ACCRUALS.FIELDS.BE_ACCRUED], FormatTypes.PERCENTAGE);
                                if (rowData[ACCRUALS.FIELDS.FY_COST_PERC] >= Number(_this.props.fyThreshold) &&
                                    rowData[ACCRUALS.FIELDS.BE_ACCRUED] > Number(_this.props.rThreshold)) {
                                    p.classList.add('greenText');
                                }
                                p.innerHTML = amount;
                                p.classList.add("fy_cost_value");
                                if(!_this.isFetchingFYData) {
                                    img.classList.add("uk-hidden");
                                } else {
                                    p.classList.add("uk-hidden");
                                }
                                div.appendChild(p);
                            }
                            return div;
                        };
                        break;
                    case ACCRUALS.FIELDS.STATUS:
                    columnFormatter = function(cell,formatterParams) {
                        var rowData = cell.getRow().getData();
                        var iconContainer = document.createElement("div");
                        if(!rowData.children || rowData.children.length === 0) {
                            var div = document.createElement("div");
                            if (rowData[ACCRUALS.FIELDS.STATUS] === ACCRUALS.FIELDS.STATUS_VALUES.ACCRUED
                                && (rowData[ACCRUALS.FIELDS.BE_ACCRUED] < Number(_this.props.rThreshold) ||
                                    rowData[ACCRUALS.FIELDS.FY_COST_PERC] < Number(_this.props.fyThreshold)) ) {
                                        var icon = document.createElement("i");
                                        icon.classList.add("fa-lg","fas","fa-exclamation","pi-text-yellow");
                                        icon.style.paddingRight = convertPxToViewport(10);
                                        icon.setAttribute("uk-tooltip",lang.manage_accruals_warning_message);
                                iconContainer.classList.add("uk-flex-inline", "uk-flex-middle")
                                iconContainer.appendChild(icon);
                            }
                            if(rowData[ACCRUALS.FIELDS.STATUS] === ACCRUALS.FIELDS.STATUS_VALUES.UNDEFINED) {
                                div.classList.add('disabled');
                            }

                            if(rowData[ACCRUALS.FIELDS.STATUS]) {
                                div.innerHTML = capitalizeFirstLetter(rowData[ACCRUALS.FIELDS.STATUS].toLowerCase());
                            }
                            iconContainer.append(div);
                        }
                        return iconContainer;
                    };
                    break;
                    case ACCRUALS.FIELDS.ACTIONS:
                        columnFormatter = function(cell,formatterParams) {
                            var rowData = cell.getRow().getData();
                            const isCompleteYear = _this.props.fiscalYear ? _this.props.fiscalYear[FISCAL_YEARS.is_complete] : true;
                            // actions three dotted dropdown button container
                            
                            var dropdownContainer = document.createElement("div");
                            dropdownContainer.setAttribute("uk-dropdown", "mode: click; pos:bottom-right");

                            var line = document.createElement("hr");
                            line.classList.add("uk-margin-xsmall");

                            // three dotted button
                            var dotsButtonContainer = document.createElement("div");
                            var dotsButton = getTableIconButton(["fa-2x", "fal", "fa-ellipsis-v"], ["uk-button-icon", "transparent-bg", "dark-bg"]);
                            dotsButtonContainer.classList.add("uk-inline");

                            // Evaluate button
                            var evaluateIconDiv = getTableButton(
                                lang.accruals.actions.evaluate,
                                [],
                                ["justify-content-start", "manage_stacks_dropdown_button"],
                                ["fa-lg","fal", "fa-search-dollar", "uk-margin-small-right"],
                                "left",
                                lang.accruals.actions.evaluate_long
                            );
                            evaluateIconDiv.style.width = "100%";
                            
                            evaluateIconDiv.onclick = () => {
                                _this.props.showEvaluation(rowData);
                            }                         

                            // Predict button
                            var predictIconDiv = getTableButton(
                                lang.accruals.actions.predict,
                                [],
                                ["justify-content-start", "manage_stacks_dropdown_button"],
                                ["uk-text-xlarge", "fal", "fa-lightbulb-dollar", "uk-margin-small-right"],
                                "left",
                                lang.accruals.actions.predict_long
                            );     
                            predictIconDiv.style.width = "100%";

                            predictIconDiv.onclick = () => {
                                _this.props.showPrediction(rowData);
                            }

                            // Exclude button
                            var excludeIconDiv = getTableButton(
                                lang.accruals.actions.exclude,
                                [],
                                ["justify-content-start", "manage_stacks_dropdown_button"],
                                ["fa-lg", "fal", "fa-file-minus", "uk-margin-small-right"],
                                "left",
                                lang.accruals.actions.exclude_long
                            );
                            excludeIconDiv.style.width = "100%";
                            excludeIconDiv.onclick = () => {
                                _this.modifyStatusPerRow(ACCRUALS.FIELDS.STATUS_VALUES.EXCLUDED,undefined,rowData,_this.tabulator.getData())
                            }

                            // Include button
                            var includeIconDiv = getTableButton(
                                lang.accruals.actions.include,
                                [],
                                ["justify-content-start", "manage_stacks_dropdown_button"],
                                ["fa-lg", "fal", "fa-file-plus", "uk-margin-small-right"],
                                "left",
                                lang.accruals.actions.include_long
                            );
                            includeIconDiv.style.width = "100%";
                            includeIconDiv.onclick = () => {
                                _this.modifyStatusPerRow(ACCRUALS.FIELDS.STATUS_VALUES.UNDEFINED, ACCRUALS.ACTIONS.INCLUDE,rowData,_this.tabulator.getData());
                            }

                            // Accrue button
                             var accrueIconDiv = getTableButton(
                                lang.accruals.actions.accrue,
                                [],
                                ["justify-content-start", "manage_stacks_dropdown_button"],
                                ["fa-lg","fal", "fa-money-check-edit-alt", "uk-margin-small-right"],
                                "left",
                                lang.accruals.actions.accrue_long
                            );
                            accrueIconDiv.style.width = "100%";
                            accrueIconDiv.onclick = () => {
                                _this.modifyStatusPerRow(ACCRUALS.FIELDS.STATUS_VALUES.ACCRUED,undefined,rowData,_this.tabulator.getData());
                            }
                            

                             // Remove Accrual button
                             var removeAccrualIconDiv = getTableButton(
                                lang.accruals.actions.remove_accrual,
                                [],
                                ["justify-content-start", "manage_stacks_dropdown_button"],
                                ["fa-lg","fal", "fa-ban", "uk-margin-small-right"],
                                "left",
                                lang.accruals.actions.remove_accrual_long
                            );
                            removeAccrualIconDiv.style.width = "100%";
                            removeAccrualIconDiv.onclick = () => {
                                _this.modifyStatusPerRow(ACCRUALS.FIELDS.STATUS_VALUES.UNDEFINED,ACCRUALS.ACTIONS.REMOVE_ACCRUAL,rowData,_this.tabulator.getData());
                            }

                            if(!rowData.children || rowData.children.length === 0) {

                                if(_this.isFetchingFYData) {
                                    evaluateIconDiv.classList.add('disabled');
                                    predictIconDiv.classList.add('disabled');
                                } else{
                                    evaluateIconDiv.classList.remove('disabled');
                                    predictIconDiv.classList.remove('disabled');
                                }
                                if(rowData[MAPPING_EXCEPTION] === GLACCOUNTS_FIELDS.MAP_EXCEPTION_VALUES.TRANSACTION){
                                    dotsButton.classList.add('disabled');
                                    dotsButtonContainer.classList.add('cursorNotAllowed');
                                    dotsButtonContainer.setAttribute('uk-tooltip', lang.manage_accruals_disabled_line);
                                }
                                
                                if(_this.checkIfActionButtonShouldBeDisabled(rowData)){
                                    dotsButton.classList.add('disabled');
                                    dotsButtonContainer.classList.add('cursorNotAllowed');
                                    dotsButtonContainer.setAttribute('uk-tooltip', lang.manage_accruals_disabled_line_two);
                                }

                                if(isCompleteYear) {
                                    predictIconDiv.classList.add('disabled');
                                } else {
                                    evaluateIconDiv.classList.add('disabled');
                                }

                                dropdownContainer.appendChild(evaluateIconDiv);
                                dropdownContainer.appendChild(predictIconDiv);
                                dropdownContainer.appendChild(line);
                                if(rowData[ACCRUALS.FIELDS.STATUS] === ACCRUALS.FIELDS.STATUS_VALUES.ACCRUED || rowData[ACCRUALS.FIELDS.STATUS] === ACCRUALS.FIELDS.STATUS_VALUES.UNDEFINED){
                                    dropdownContainer.appendChild(excludeIconDiv);
                                }
                                if(rowData[ACCRUALS.FIELDS.STATUS] === ACCRUALS.FIELDS.STATUS_VALUES.EXCLUDED){
                                    dropdownContainer.appendChild(includeIconDiv);
                                }
                                if(rowData[ACCRUALS.FIELDS.STATUS] === ACCRUALS.FIELDS.STATUS_VALUES.EXCLUDED || rowData[ACCRUALS.FIELDS.STATUS] === ACCRUALS.FIELDS.STATUS_VALUES.UNDEFINED){
                                    dropdownContainer.appendChild(accrueIconDiv);
                                }
                                if(rowData[ACCRUALS.FIELDS.STATUS] === ACCRUALS.FIELDS.STATUS_VALUES.ACCRUED){
                                    dropdownContainer.appendChild(removeAccrualIconDiv);
                                }
                                dotsButtonContainer.appendChild(dotsButton);
                                dotsButtonContainer.appendChild(dropdownContainer);
                            }
                            return dotsButtonContainer;
                        };
                        break;

			default:
                columnFormatter = function(cell, formatterParams) {
                    return cell.getValue();
                }
                break;
            }

		return columnFormatter;
    }
    
    formatData(psData, data) {
        var data = data && data.length === 0 ? [] : data;

        for (var e in psData) {
            if (psData[e].children && psData[e].children.length > 0) {
                this.formatData(psData[e].children, data, false);
            } else {
                var total = 0;

                var dataFiltered = data.filter(row=>row.costKey === psData[e][ACCRUALS.FIELDS.COSTKEY]);
                var cost = dataFiltered.length === 0 ? 0 : dataFiltered[0][ACCRUALS.FIELDS.COST_AMOUNT];
                data.map(el => total += parseFloat(el[ACCRUALS.FIELDS.COST_AMOUNT]));
                
                var costKeys = data.filter(row => row.costKey === psData[e][ACCRUALS.FIELDS.COSTKEY]);
               
                var actualValues = data && data.length > 0 && costKeys.length > 0 && costKeys[0] && costKeys[0][ACCRUALS.FIELDS.COST_AMOUNT_ARR] ? costKeys[0][ACCRUALS.FIELDS.COST_AMOUNT_ARR].map(Number) : "";
                var predictedValues = data && data.length > 0 && costKeys.length > 0 && costKeys[0] ? costKeys[0][ACCRUALS.FIELDS.PROJECTION_AMOUNT_ARR].map(Number) : "";
             
                var rSquared = Math.abs(Math.pow(this.getPearsonCorrelation(predictedValues,actualValues),2));
                psData[e][ACCRUALS.FIELDS.FY_COST] = cost;
                psData[e][ACCRUALS.FIELDS.FY_COST_PERC] = parseFloat(cost)/parseFloat(total)*100;
                psData[e][ACCRUALS.FIELDS.BE_ACCRUED] = (1-rSquared)*100;
            }
        }
    }


    getPearsonCorrelation(x, y) {
        var shortestArrayLength = 0;
         
        if(x.length == y.length) {
            shortestArrayLength = x.length;
        } else if(x.length > y.length) {
            shortestArrayLength = y.length;
            console.error('x has more items in it, the last ' + (x.length - shortestArrayLength) + ' item(s) will be ignored');
        } else {
            shortestArrayLength = x.length;
            console.error('y has more items in it, the last ' + (y.length - shortestArrayLength) + ' item(s) will be ignored');
        }
      
        var xy = [];
        var x2 = [];
        var y2 = [];
      
        for(var i=0; i<shortestArrayLength; i++) {
            xy.push(x[i] * y[i]);
            x2.push(x[i] * x[i]);
            y2.push(y[i] * y[i]);
        }
      
        var sum_x = 0;
        var sum_y = 0;
        var sum_xy = 0;
        var sum_x2 = 0;
        var sum_y2 = 0;
      
        for(var i=0; i< shortestArrayLength; i++) {
            sum_x += x[i];
            sum_y += y[i];
            sum_xy += xy[i];
            sum_x2 += x2[i];
            sum_y2 += y2[i];
        }
      
        var step1 = (shortestArrayLength * sum_xy) - (sum_x * sum_y);
        var step2 = (shortestArrayLength * sum_x2) - (sum_x * sum_x);
        var step3 = (shortestArrayLength * sum_y2) - (sum_y * sum_y);
        var step4 = Math.sqrt(step2 * step3);
        var answer = step1 / step4;
      
        return answer;
    }

	getTabulatorColumns(columns) {
		columns = columns || [];
		columns.forEach(col => {
			col.formatter = this.getColumnFormatter(col.field);
        });
        return columns;
	}

	addTooltipTitleFormatter(cell){
        var div = document.createElement("div");
        var p = document.createElement("p");
        p.innerHTML = cell.getValue();

        var el = document.createElement("i");
        el.classList.add("fal", "fa-info-circle", "uk-margin-small-left", "uk-cursor-pointer");
        el.setAttribute("uk-tooltip", "TBD");

        p.appendChild(el);
        div.appendChild(p);

        return div;
    }  

    getProfitStackFields() {
        const _this = this;
        var callback = (data) => {
            if (data) {
                var pss = tryParse(data.data);
                var _costkeys = JSON.parse(data.costkeys).costkeys;
                _this.setState({
                    profitStackFields: pss,
                    profitStackTableOriginalFields: copyObjectValues(pss),
                    costkeys: _costkeys
                },function(){
                    let pssData = copyObjectValues(_this.state.profitStackFields);
                    if(_this.props.costFYData) {
                        _this.formatData(pssData, _this.props.costFYData);
                    }
                    _this.tabulator.setData(pssData);
                    _this.props.setDataProvider(this.returnDataCountByStatus());
                });
            }
        }
        getProfitStackLines(this.props.scenarioId, "true", callback,false,lang.observability.manage_accruals.key);
    }
	

	onTabulatorRenderComplete(test) {
		if(!this.tabulator)
            return;
    }

    componentDidUpdate(prevProps) {
        if(prevProps.dataUpdated_id !== this.props.dataUpdated_id) {
            this.isFetchingFYData = false;
            $(".fy_cost_loader").addClass("uk-hidden");
            $(".fy_cost_value").removeClass("uk-hidden");
            var tabData = this.tabulator.getData();
            this.formatData(tabData, this.props.costFYData);
            this.tabulator.setData(tabData);
        }
    }

	componentDidMount() {
        var obj = this;

        var childrenFieldName = "children";
		//setup tabulator
		var options = {
		    layout: "fitColumns",      //fit columns to width of table
            responsiveLayout: false,  //hide columns that dont fit on the table
            tooltips: true,            //show tool tips on cells
            addRowPos: "top",          //when adding a new row, add it to the top of the table
            history: true,             //allow undo and redo actions on the table
            pagination: false,          //paginate the data
            movableColumns: false,     //allow column order to be changed
            selectable: false,
            movableRows: false,
            resizableColumns: false,
            autoResize: false,
            dataTree: true,
            dataTreeChildField: childrenFieldName,
            dataTreeStartExpanded: true,
            dataTreeFilter:true,
            dataTreeElementColumn: "expand",
            dataTreeCollapseElement: getExpandCollapseButtons(false),//"<div title='Collapse' class='uk-button-icon transparent-bg dark'><i class='far fa-chevron-down' /></div>",
            dataTreeExpandElement: getExpandCollapseButtons(true),//"<div title='Expand' class='uk-button-icon transparent-bg dark'><i class='far fa-chevron-right' /></div>",
            dataTreeChildIndent: 40,
            dataTreeBranchElement: false, //hide branch element
            virtualDomBuffer: 30000,
            virtualDom:true,
            placeholder: lang.no_data_available,
            width: "100%",
            height: "100%",
            renderComplete: this.onTabulatorRenderComplete,
            index: ACCRUALS.FIELDS.COSTKEY,
            reactiveData:true,      //tabulator listens to any change in the data array and updates the table
        }

		//setting table columns
		var tableColumns = copyObjectValues(ACCRUALS_COLUMNS);
		tableColumns.unshift(checkboxCellObject);		//add checkbox column as the first column
		
		//set column formatters and return column objects
		tableColumns = cleanUpTabulatorColumns(tableColumns, null, this.refreshFilterDivs, this.tabulator, {id:"define_accruals", childrenFieldName: childrenFieldName});
        tableColumns.find(e => e.field === ACCRUALS.FIELDS.STATUS).headerFilterParams.values = [ACCRUALS.FIELDS.STATUS_VALUES.ACCRUED,ACCRUALS.FIELDS.STATUS_VALUES.EXCLUDED, ACCRUALS.FIELDS.STATUS_VALUES.UNDEFINED,];

		//settings the columns again to replace the checkbox column
		tableColumns = this.getTabulatorColumns(tableColumns);

		tableColumns.forEach(col => {
			var columnField = col.field;
            if ([ACCRUALS.FIELDS.BE_ACCRUED, ACCRUALS.FIELDS.FY_COST_PERC].includes(columnField)) {
                col.titleFormatter = (cell) => obj.addTooltipTitleFormatter(cell);
            }
        });
        options.columns = tableColumns;
        this.tabulator = new TabulatorFull(this.refs.mainTable, options);
        this.getProfitStackFields();
    }

	render() {
		
		return(
      <div id="define_accruals" ref="mainTable" />
    
		);

	}

}

export default DefineAccrualsTable;