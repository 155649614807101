const defaulLandscapeBubbleObjBeforeBack = [];
const defaultLandscapeBubbleListData = [];
const defaultLandscapeCostHierarchy = [];

function landscapeBubbleObjBeforeBack(state = defaulLandscapeBubbleObjBeforeBack, action) {
  switch (action.type) {
    case "UPDATE_LANDSCAPE_BUBBLE_OBJECT_BEFORE_BACK":
      if (action.clear) {
        let landscapeBubbleObjBeforeBackHashMap = new Map();
        return landscapeBubbleObjBeforeBackHashMap;
      }
      let landscapeBubbleObjBeforeBackHashMap = new Map(state);
      landscapeBubbleObjBeforeBackHashMap.set(action.key, action.landscapeBubbleObjBeforeBack);
      return landscapeBubbleObjBeforeBackHashMap;
    case "GET_LANDSCAPE_BUBBLE_OBJECT_BEFORE_BACK":
      return state;
    default:
      return state;
  }
}

function landscapeCostHierarchy(state = defaultLandscapeCostHierarchy, action) {
  switch (action.type) {
    case "UPDATE_LANDSCAPE_COST_HIERARCHY":
      if (action.clear) {
        let landscapeCostHierarchy = new Map();
        return landscapeCostHierarchy;
      }
      let landscapeCostHierarchy = new Map(state);
      landscapeCostHierarchy.set(action.key, action.landscapeCostHierarchy);
      return landscapeCostHierarchy;
    case "GET_LANDSCAPE_COST_HIERARCHY":
      return state;
    default:
      return state;
  }
}

function landscapeBubbleListData(state = defaultLandscapeBubbleListData, action) {
  switch (action.type) {
    case "UPDATE_LANDSCAPE_BUBBLE_LIST_DATA":
      if (action.clear) {
        let landscapeBubbleListDataMap = new Map();
        return landscapeBubbleListDataMap;
      }
      let landscapeBubbleListDataMap = new Map(state);
      landscapeBubbleListDataMap.set(action.key, action.landscapeBubbleListData);
      return landscapeBubbleListDataMap;
    case "GET_LANDSCAPE_BUBBLE_LIST_DATA":
      return state;
    default:
      return state;
  }
}

export { landscapeBubbleObjBeforeBack, landscapeBubbleListData, landscapeCostHierarchy };
