import React, { Component } from 'react'
import BuildDetailsTable from './BuildDetailsTable'
export default class BuildDetails extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      data:[]
    };
    
    this.state = Object.assign({}, this.state, this.initialState);
    this.isMainReport = { isBuildDetails: true }
  }
   


  render() {
    return (
      window.buildDetailsData?
      <div>
        <BuildDetailsTable data={window.buildDetailsData ? window.buildDetailsData.finalRes : ""} />
        </div>
      : ""
    )
  }
}
