import React from "react"

/**
 * Emit a custom event in our application. You can listen to events using the custom hook useListener
 * @param eventName The event name to listen to
 * @param eventData custom data sent with the event
 */
export function emitEventBase(eventName, eventData) {
  if (typeof window !== "undefined") {
    // window check used for unit test
    let event = new CustomEvent(eventName, { detail: eventData })
    return document.getElementById("root")?.dispatchEvent(event)
  }
}

/**
 * Custom Hook to listen for events (dispatched/emitted by emitEvent)
 * @param eventName the event name to listen to
 * @param listener the callback (listener function that receives the event with its data)
 */
export function useListenerBase(eventName, listener, dependencies) {
  React.useEffect(() => {
    document
      .getElementById("root")
      ?.addEventListener(eventName, listener, false)
    return function cleanup() {
      document
        .getElementById("root")
        ?.removeEventListener(eventName, listener, false)
    }
  }, dependencies)
}

export const emitEvent = (eventName, eventData) =>
  emitEventBase(eventName, eventData)

export const useListener = (eventName, listener, dependencies) =>
  useListenerBase(eventName, listener, dependencies)

