import { INPUT_VARIANT} from "../class/constants";
import { ReactComponent as MagnifyIcon } from "../styles/images/icons/magnify-input.svg";
import {useEffect} from "react";
/**
 * Input component
 * @param {string} id Id of the input
 * @param {string} placeholder The placeholder of the input,  (required)
 * @param {string} className additional classNames
 * @param {string} variant Could be "default" or "error" (use constants) (required)
 * @param {Boolan} disabled Sets the input to be disbaled
 * @param {Boolan} isSearch add search icon if input is for search
 * @returns The rendered button component
 */
const Input = ({
  id,
  placeholder,
  className,
  variant,
  disabled,
  isSearch,
  ...props // other props that needs to be added. like: aria-label
}) => {
  let inputClassNames = `input-default`; // Add classname based on size, type & variant
  inputClassNames += disabled ? " input-default-disabled " : "";
  inputClassNames += " " + className;
  let isSearchOld = isSearch ? true : false
  let style = props.notFullWidth ? {} : {width:"100%"};

  useEffect(() => {
    document.getElementById(id).addEventListener('keypress', function(event) {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    });
  }, []);


  return (
    //remove width when migrating to v3
    <div style={style} className={isSearchOld ? "input_search_container" : "input_container"}>
      {isSearchOld ?<span className="input_search">
        {/* <i class="fa-lg fal fa-search text-light-grey" aria-hidden="true"></i> */}
        <MagnifyIcon />
        </span>
      : ""}
      <input id={id} className={inputClassNames} placeholder={placeholder} {...props}>
      </input>
    </div>
  );
};

Input.defaultProps = {
  id: "input",
  placeholder: "",
  className: "",
  variant: INPUT_VARIANT.DEFAULT,
  disabled: false,
  type: "text"
};

export default Input;
