import React, { Component } from 'react';
import { deepCompareObjects, copyObjectValues } from '../../class/utils';
import { formatValHTML, formatValNumber, formatValString } from '../../class/format';
import { FormatTypes, VIEWPORT_WIDTH } from '../../class/constants';
import shortid from 'shortid';
import { convertPxToViewport, convertViewportToPx } from '../../class/formatting';
import { Segment } from '../Segment';

const AmCharts = require('@amcharts/amcharts3-react');
const _formatted = "formatted";

const $ = require('jquery');
const perc = "perc";
const perc_tot = "perc_tot";

/**
 * This is an example of the structure in which this class must receive its props
 - the axes of the graph" 
    chartAxes: {
        x: {field: x_axis_field, title: "X Axis Title"},
        y: {title: "Y Axis Title"}  //doesn't require a field attribute, it's values are read from the charts
    }

 - the lines displayed on the graph, may have as many lines as desired, each with a unique field value
    chartLines: [
        { field: line_1_field, title: "Title 1", legendTitle: "Legend Title 1", color: "#FCD202", bulletShape: "square", format: FormatTypes.AMOUNT},
        { field: line_2_field, title: "Title 2", legendTitle: "Legend Title 2", color: "#FCD202", bulletShape: "round", format: FormatTypes.AMOUNT}
    ]

 - the data of the chart: an array of objects, each containing all the fields of the chart lines, and the field of the x axis:
    data: [
        {x_axis_field: "value", line_1_field: "value", line_2_field: "value"},  //each of those objects is a vertical line going through x axis on value of x_axis_field
        {x_axis_field: "value", line_1_field: "value", line_2_field: "value"},  //and having data points on each of the other keys
        {x_axis_field: "value", line_1_field: "value", line_2_field: "value"}
    ]
 */
class LineChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chartDivId: "chart-" + shortid.generate(),
            lines: []
        }
    }

    static getDerivedStateFromProps(props, state) {
       
        let tempState = {}
        if(props.refreshAxes || !deepCompareObjects(props.axes, state.axes)) {
            tempState.axes = copyObjectValues(props.axes);
            tempState.axes_id = shortid.generate();
        }

        if(!deepCompareObjects(props.data, state.data)) {
            tempState.data = copyObjectValues(props.data);
            tempState.data_id = shortid.generate();
        }

        if(!deepCompareObjects(props.lines, state.lines)) {
            tempState.lines = copyObjectValues(props.lines);
            tempState.lines_id = shortid.generate();
        }


        return tempState;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(nextState.axes_id !== this.state.axes_id || nextState.data_id !== this.state.data_id || nextState.lines_id !== this.state.lines_id || nextProps.showOverlay !== this.props.showOverlay) {
            return true;
        }
        return false;
    }

    componentDidMount() {
        this.init();
    }

    init() {
        let tempState = {};
        tempState.yAxisPosition = this.props.yAxisPosition || "left";   //default on the left
        tempState.axes = this.props.axes || {
            x: {field: "x", title: "X axis title"},
            y: {title: "Y axis title"},     //no need for field since values will be read from the graph lines fields
        }
        var lines = [];
        if(this.props.isEvaluation){
            lines = this.props.lines.splice(2,1); //Remove FY Build Line
        } else {
            lines = this.props.lines;
        }
        tempState.lines = copyObjectValues(lines) || [{ field: "line1", title: "Line 1" }];
        tempState.data = [];

        this.setState(tempState);
    }

    renderChart(data, lines=this.state.lines) {
        var provider = [];
        let defaultFormat = this.props.stackConfigObj && (this.props.stackConfigObj.amountType === perc || this.props.stackConfigObj.amountType === perc_tot) ? FormatTypes.PERCENTAGE : FormatTypes.AMOUNT;

        for (var i = 0; i < data.length; i++){
            var tempDataPoint = {};
            tempDataPoint[this.state.axes.x.field] = data[i][this.state.axes.x.field];
            lines.forEach(graphLine => {
                let field = graphLine.field.replaceAll("$","_");
                tempDataPoint[graphLine.field] = data[i][graphLine.field];
                tempDataPoint[_formatted +"_"+ field] = formatValHTML(data[i][graphLine.field], (defaultFormat === FormatTypes.PERCENTAGE ? defaultFormat : graphLine.format || defaultFormat));
            });
    
            provider.push(tempDataPoint);
        }

        let chartOptions = {
            type: "serial",
            dataProvider: provider,
        };

        chartOptions.balloon = {
            maxWidth : convertPxToViewport(220),
            horizontalPadding : convertViewportToPx(10),
        }
        
        let graphs = [];
        lines.forEach(graphLine => {
            let field = graphLine.field.replaceAll("$","_");
            let balloonText = "<b><span style='font-size:"+ convertPxToViewport(10) +";'>[["+ _formatted +"_"+ field +"]]</span></b>";
            if(graphLine.balloonData) {     //extra data to be shown in the balloon text must be added to an array and passed inside the chart lines objects
                if(graphLine.balloonData[0]=== null){
                    graphLine.balloonData[0] = graphLine.title
                }
                let tempData = graphLine.balloonData.map(item=>{
                    return "<b class='chart-balloon-span'><span style='font-size:"+ convertPxToViewport(10) +"'>"+ (new Segment().getSegmentObject(item)?.label || item) +"</span></b>"
                })
                balloonText = tempData.join("<br>") +"<br>"+ balloonText;
            }
            
            let tempGraphObj = {
                balloonText: balloonText,
                bullet: graphLine.bulletShape || "square",
                bulletSize: convertViewportToPx(8),
                lineAlpha: 1,
                lineColor: graphLine.color || "#FCD202",
                valueField: graphLine.field || "",
                title: graphLine.title || "",
                lineThickness: convertViewportToPx(1)
            }
            graphs.push(tempGraphObj);
        });
        chartOptions.graphs = graphs;
        chartOptions.precision = 2;
        // chartOptions.numberFormatter = {
        //     "numberFormat": 
        // }

        //set big numbers multipliers
        if(this.props.bigNumbersMultipliers) {
            chartOptions.usePrefixes = true;
            chartOptions.prefixesOfBigNumbers = [{"number":1e+3,"prefix":"k"},{"number":1e+6,"prefix":"M"},{"number":1e+9,"prefix":"B"},{"number":1e+12,"prefix":"T"},{"number":1e+15,"prefix":"Z"}];
        }
        
        // show/hide zoom bar
        if(!this.props.hideZoomBar) {
            chartOptions.chartScrollbar = {
                "scrollbarHeight":2,
                "offset":-1,
                "backgroundAlpha":0.1,
                "backgroundColor":"#888888",
                "selectedBackgroundColor":"#67b7dc",
                "selectedBackgroundAlpha":1
            };
        }

        chartOptions.categoryField = this.state.axes.x.field;
        chartOptions.categoryAxis = {
            title: this.state.axes.x.title,
            fontSize: convertViewportToPx(11)
        };

        chartOptions.valueAxes = [{
            "id": "v1",
            "axisAlpha": 0,
            "position": this.state.yAxisPosition,
            "title": this.breakTitle(),
            autoGridCount: false,
            fontSize: convertViewportToPx(11),
            "labelFunction": function(value, valueText, valueAxis) {
                return formatValNumber(value, defaultFormat); // format y axis value to follow the applied formatting
            },
        }];
    
        this.chart = AmCharts.makeChart(this.state.chartDivId, chartOptions);
        
    }

    /**
     * breaks axis label title
     */
    breakTitle = () => {
        let titleElemnts = this.state.axes.y.title.split(" ");

        if (titleElemnts.length < 5) {
            return this.state.axes.y.title;
        }

        let title = "";
        for (var i = 0; i < titleElemnts.length; i++) {
            title += titleElemnts[i] + " ";
            if (i === (Math.round(titleElemnts.length / 2) - 1))
                title += "\n";
        }
        return title;
    }

    componentDidUpdate() {
        this.renderChart(this.state.data);
    }

    render() {
        let chartHeight = this.props.height !== undefined ? convertPxToViewport(this.props.height - 70, true) : convertPxToViewport(280, true);
        let chartWidth = Number.isNaN(this.props.width/VIEWPORT_WIDTH) ? (this.props.width) : convertPxToViewport(this.props.width);
     
        return (
            <div id="lineChart" style={{width: chartWidth, height: chartHeight}} className={this.props.className}>
                { this.props.showOverlay ? 
                    <div className="linechart-overlay">
                        <div className="linechart-overlay-text">
                            {this.props.overlayText}
                        </div>
                    </div>
                :""}
                <div id={this.state.chartDivId} className="print-overflow-auto print-height-full" style={{ width: convertPxToViewport(1200), height: chartHeight }} /> 
                
                <div className="evaluate-legend-container uk-grid uk-margin-remove-left">
                    {this.props.conclusionText ?
                        <div className="uk-text-xmedium uk-display-flex uk-margin-xmedium-bottom">
                            {typeof this.props.conclusionText === "string" ?
                                <div>{this.props.conclusionText}</div>
                            :
                                this.props.conclusionText
                            }
                        </div>
                    :"" }

                    {!this.props.hideLegend ? this.state.lines.map(graphLine=>{
                        return (
                            <div key={"graphline-legend-" + (graphLine.legendTitle || graphLine.title)} className={"uk-margin-large-left uk-display-flex uk-text-xmedium"}>
                                <div className={`${graphLine.bulletShape}-icon uk-margin-default-right`} style={{backgroundColor: graphLine.color, width: convertPxToViewport(15), height: convertPxToViewport(15), margin: convertPxToViewport(5)}}></div>
                                <p>{graphLine.legendTitle || graphLine.title}</p>
                            </div>
                        )
                    })
                    :""
                    }
                </div> 
            </div>
        );
    }
}

export default LineChart;