import React, { Component } from 'react';
import { CustomSelect } from '../../form/elements.js';
import { getTranslationFile, findOptionByKey, parseBoolean } from '../../class/utils.js';
import { RAW_ITEMS, UPLOAD_SECTIONS, STAGING_SECTIONS, METRICS_MAPPING, DROPDOWN_TYPE, BUTTON_VARIANT, SIZES, BUTTON_TYPE, DIALOG_SIZE } from '../../class/constants';
import { arrangeFileNames } from '../filter/FilterHelperFunctions.js';
import Popup from 'react-popup';
import DropDown from '../../newComponents/DropDown.js';
import Button from '../../newComponents/Button.js';
import Modal from '../../newComponents/Modal.js';
    
const $ = require('jquery');
const MESSAGES = getTranslationFile();

// const _fileType = ENGINE_FILTER.KEYS.FILE;
const cost_center= "COST CENTER";
var tempObject ={};
/**
 * Metrics Configuration - each row component
 * 
 * @author [Lara Jannoun]
 */
class ValueColumnRow extends Component{
    constructor(props) {
        super(props);
        this.state = {
            fileNames: this.props.fileNames,
            index: this.props.rowIndex
        }
        this.handleChange = this.handleChange.bind(this);
        this.removeValueColumnRow = this.removeValueColumnRow.bind(this);
        this.filterByType = this.filterByType.bind(this);
        this.setConfigurationObject = this.setConfigurationObject.bind(this);
        this.setDraggable = this.setDraggable.bind(this);  
    }

    removeValueColumnRow() {
        this.props.removeValueColumnRow(this.props.rowIndex);
    }

    filterByType(type, arr) {
        var output=[];
        var stringOutput = [];
        var metricConfigurationObject = this.state.metricConfigurationObject;
        if (type === RAW_ITEMS.FIELD_NAME) {
            if(metricConfigurationObject[RAW_ITEMS.SUBTYPE_NAME] !== STAGING_SECTIONS.CALCULATED_COLUMNS) {
                for(var i in arr) {
                    if (arr[i][RAW_ITEMS.FIELD_DATA_TYPE].toUpperCase() === 'NUMERIC') {
                        output.push({value:arr[i][RAW_ITEMS.FIELD_NAME], label:arr[i][RAW_ITEMS.FIELD_NAME], [RAW_ITEMS.SUBTYPE_ID]:arr[i][RAW_ITEMS.SUBTYPE_ID],
                            [RAW_ITEMS.SUBTYPE_NAME]: arr[i][RAW_ITEMS.SUBTYPE_NAME], [RAW_ITEMS.TYPE_NAME]: arr[i][RAW_ITEMS.TYPE_NAME], [RAW_ITEMS.FIELD_DATA_TYPE]: arr[i][RAW_ITEMS.FIELD_DATA_TYPE],
                            [RAW_ITEMS.DATA_FIELD]: arr[i][RAW_ITEMS.DATA_FIELD]
                        });
                    } else if (arr[i][RAW_ITEMS.FIELD_DATA_TYPE].toUpperCase() === 'STRING') {
                        stringOutput.push({value:arr[i][RAW_ITEMS.FIELD_NAME], label:arr[i][RAW_ITEMS.DATA_FIELD] && arr[i][RAW_ITEMS.DATA_FIELD] !== "" ? arr[i][RAW_ITEMS.FIELD_NAME]+" ("+arr[i][RAW_ITEMS.DATA_FIELD]+")" : arr[i][RAW_ITEMS.FIELD_NAME], [RAW_ITEMS.SUBTYPE_ID]:arr[i][RAW_ITEMS.SUBTYPE_ID],
                        [RAW_ITEMS.SUBTYPE_NAME]: arr[i][RAW_ITEMS.SUBTYPE_NAME], [RAW_ITEMS.TYPE_NAME]: arr[i][RAW_ITEMS.TYPE_NAME], [RAW_ITEMS.FIELD_DATA_TYPE]: arr[i][RAW_ITEMS.FIELD_DATA_TYPE], [RAW_ITEMS.DATA_FIELD]: arr[i][RAW_ITEMS.DATA_FIELD]}) 
                    }
                }
            } else {
                output = arr;
            }
            stringOutput.push({value:'none', label:'None', [RAW_ITEMS.SUBTYPE_ID]:'none',
            [RAW_ITEMS.SUBTYPE_NAME]: 'none', [RAW_ITEMS.TYPE_NAME]: 'none', [RAW_ITEMS.FIELD_DATA_TYPE]: 'none',
            [RAW_ITEMS.DATA_FIELD]: 'none'})
            metricConfigurationObject.columns = output.filter(e=>e.value!==undefined);
            metricConfigurationObject.stringOutput = stringOutput.filter(e=>e.value!== undefined);

            this.setState({
                metricConfigurationObject: metricConfigurationObject
            })
        }

        return metricConfigurationObject;
    }

    handleChange(type, e) {
        var _this = this;
        var tempObj = this.state.metricConfigurationObject;
        var _in = false;
        if (e !== null) {
            if (type === RAW_ITEMS.SUBTYPE_ID) {
                var resultOnChangeOnUsedRow = this.props.handleChangeOnUsedRow(this.props.rowIndex);
                if (!resultOnChangeOnUsedRow) {
                    if (!!tempObj) {
                        tempObj[RAW_ITEMS.TYPE_NAME] = e[RAW_ITEMS.TYPE_NAME];
                        tempObj[RAW_ITEMS.SUBTYPE_ID] = e[RAW_ITEMS.SUBTYPE_ID];
                        tempObj[RAW_ITEMS.SUBTYPE_DISPLAY_NAME] = e[RAW_ITEMS.SUBTYPE_DISPLAY_NAME];
                        tempObj[RAW_ITEMS.SUBTYPE_NAME] = e.value;
                        tempObj[RAW_ITEMS.FIELD_NAME] = "";
                        tempObj["vector-column"] = undefined;
                    } else {
                        tempObj = {};
                        tempObj[RAW_ITEMS.SUBTYPE_ID] = e[RAW_ITEMS.SUBTYPE_ID];
                        tempObj[RAW_ITEMS.TYPE_NAME] = e[RAW_ITEMS.TYPE_NAME];
                        tempObj[RAW_ITEMS.SUBTYPE_DISPLAY_NAME] = e[RAW_ITEMS.SUBTYPE_DISPLAY_NAME];
                        tempObj[RAW_ITEMS.SUBTYPE_NAME] = e.value;
                    }
                    if (tempObj[RAW_ITEMS.TYPE_NAME] === UPLOAD_SECTIONS.FIELDS.ANCILLARY_FILES) {
                        var columns = this.props.allTypeColumns.filter(el => el[RAW_ITEMS.SUBTYPE_ID] === tempObj[RAW_ITEMS.SUBTYPE_ID]);
                        var costCenterCol = columns.filter(el => el[RAW_ITEMS.DATA_FIELD].toUpperCase() === cost_center).length > 0;
                        if (!costCenterCol && parseBoolean(this.props.mappedMetric[METRICS_MAPPING.FIELDS.COST_CENTER])) {
                            tempObject = tempObj;
                            _in = true;
                            this.props.setOpenConfirmToggleOffDialog(true)
                        } else if(!costCenterCol && !parseBoolean(this.props.mappedMetric[METRICS_MAPPING.FIELDS.COST_CENTER])){
                            this.disableBranch = true;
                            this.props.handleRowChange(this.disableBranch); // true for disabling the branch
                        } else if(costCenterCol) {
                            this.disableBranch = false;
                            this.props.handleRowChange(this.disableBranch); // true for disabling the branch
                        }
                    }else{
                        this.disableBranch = false;
                    }
                }
            } else if (type === RAW_ITEMS.FIELD_NAME) {
                var resultOnChangeOnUsedRow = this.props.handleChangeOnUsedRow(this.props.rowIndex,true);
                var result = _this.props.checkIfRowExistsMultipleTimes(this.props.rowIndex, e);
                if (result && !resultOnChangeOnUsedRow) {
                    this.setInfoDialogOpen(true, MESSAGES.metrics_duplicate_value_columns);
                    return;
                } else if(!resultOnChangeOnUsedRow){
                    tempObj[RAW_ITEMS.FIELD_NAME] = e.value;
                    tempObj[RAW_ITEMS.FIELD_DATA_TYPE] = e[RAW_ITEMS.FIELD_DATA_TYPE];
                }

            }
        }
        tempObj = tempObj ? tempObj : tempObject;
        this.props.updateMetricObjects(tempObj, this.props.rowIndex, undefined, _in, type, this.disableBranch);
        this.setDraggable();
    }
    

    setConfigurationObject(obj) {
        this.props.setOpenConfirmToggleOffDialog(false)
        this.setState({
            metricConfigurationObject: obj
        },function(){
            this.props.updateMetricObjects(obj, this.props.rowIndex);
        })
    }

    setDraggable() {
        // var obj = this;
        var draggableOptions = {
            // start: function( event, ui ) {
            //     let $node = $(event.target);
            //     obj.$clone = $node.clone();
            //     $node.removeClass("uk-position-none");
            //     $node.css("position","absolute");          //freeing the space reserved by the original node that is being dragged
            //     $node.after(obj.$clone);                //immediately replacing the emptied space with a clone of the original node
            // },
            // stop: function(event, ui) {
            //     //if dragged and dropped outside the formula div
            //     let $node = $(ui.helper[0]);
            //     $node.remove();   //removing dragged node since it was immediately replaced
            //     obj.$clone.draggable(draggableOptions);
            //     obj.$clone.show();  //show hidden clone node
            // },
            scroll: false,
            cursor: "grab",
            helper: 'clone',
            appendTo: 'body',
            containment: 'window',
            revert: "invalid",
        };

        $(".draggable-col-index, .draggable-numpd-btn").draggable(draggableOptions);
    }

    componentDidMount() {
        let _this = this;
        _this.setDraggable();     
    }

    // stage - metrics - mapping 
    componentDidUpdate(nextProps) {
        let _this = this;
        if (nextProps !== this.props) {
            if (nextProps.fileNames && nextProps.fileNames.length > 0)
                this.setState({
                    fileNames: nextProps.fileNames
                })

            if (nextProps.metricConfigurationRows[this.props.rowIndex].current && nextProps.metricConfigurationRows[this.props.rowIndex].current !== null && nextProps.metricConfigurationRows[this.props.rowIndex].current.state.metricConfigurationObject) {
                var metricConfigurationObject = nextProps.metricConfigurationRows[this.props.rowIndex].current.state.metricConfigurationObject;
                var rowFile = metricConfigurationObject ? metricConfigurationObject[RAW_ITEMS.SUBTYPE_NAME] : "";

                this.setState({
                    metricConfigurationObject: nextProps.metricConfigurationRows[this.props.rowIndex].current.state.metricConfigurationObject
                }, function () {
                    var columns = [];
                    if (rowFile !== "") {
                        if (this.state.metricConfigurationObject[RAW_ITEMS.SUBTYPE_NAME] === STAGING_SECTIONS.CALCULATED_COLUMNS) {
                            columns = _this.props.calculatedCols;
                        } else {
                            columns = nextProps.allTypeColumns.filter(row => row[RAW_ITEMS.SUBTYPE_ID] === this.state.metricConfigurationObject[RAW_ITEMS.SUBTYPE_ID]);
                        }
                        this.filterByType(RAW_ITEMS.FIELD_NAME, columns);
                    }
                })
            }
        }

        this.setDraggable();  
    }

    getEmptyMetricSample() {
        this.props.getEmptyMetricSample();
        this.forceUpdate();
    }

    setInfoDialogOpen = (isOpen, infoMsg) => {
      let _this = this;
      _this.setState({
        openInfoDialog: isOpen,
        infoMsg: infoMsg
      })
    }
  
    openInfoDialogActions = () => {
      return (
        <Button
          label={MESSAGES.modal.buttons.ok}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => this.setInfoDialogOpen(false, "")}
        />
      )
    }
    render() {

        var metricConfigurationObject = this.state.metricConfigurationObject ? this.state.metricConfigurationObject : this.props.getEmptyMetricSample();

        var rowFile =  metricConfigurationObject ? metricConfigurationObject[RAW_ITEMS.SUBTYPE_NAME] : "";
        var rowColumn =  metricConfigurationObject ? metricConfigurationObject[RAW_ITEMS.FIELD_NAME] : "";

        var fileNamesWithTitles = arrangeFileNames(this.state.fileNames);
        const optionLabel = ({ label, isDisabled }) => (
            <div title={label} className={(isDisabled ? "option-group-header" : "")+ " option-padding"}>{label}</div>
        );

        const columnOptionLabel = ({ name, label, data_file_type }) => (
            <div title={label} className="Select-option">
                 {(label) + ((name && name !== "" && [UPLOAD_SECTIONS.FIELDS.GENERAL_LEDGER, UPLOAD_SECTIONS.FIELDS.TRANSACTION_DATA, UPLOAD_SECTIONS.FIELDS.ANCILLARY_FILES, UPLOAD_SECTIONS.FIELDS.TRANSACTION_DATA].includes(data_file_type)) ? " (" + name + ")" : "")}
            </div>
        );
        
        return (
            
            <div className="uk-flex uk-flex-middle uk-margin-xsmall-bottom" key={"row_" + this.props.rowIndex + "_"+ rowFile + "_" + rowColumn}>
                <div className="uk-margin-xsmall-left mrgr6">
                    <div className={`${(rowFile && rowFile !=="" && rowColumn && rowColumn !== "" ? "draggable-col-index uk-grab" : "disabled uk-disabled")} border-square`}
                        file={JSON.stringify(rowFile)} column={JSON.stringify(rowColumn)}>
                        <span>{this.props.rowIndex+1}</span>
                    </div>
                </div>
            
                <DropDown
                    id="select-vector"
                    className="uk-display-inline-block input-width-200 uk-margin-small-left dropdown-margin-right input__dropdown"
                    name="file-name" 
                    placeholder="File Name"
                    value={metricConfigurationObject && rowFile !== "" ? findOptionByKey(this.state.fileNames, rowFile) : ""}
                    options={fileNamesWithTitles}
                    onChange={(e) => { this.handleChange(RAW_ITEMS.SUBTYPE_ID, e) }}
                    formatOptionLabel={optionLabel}
                    type={DROPDOWN_TYPE.INPUT}
                />
                <DropDown
                    id="select-vector"
                    className="uk-display-inline-block input-width-200 uk-margin-default-right input__dropdown"
                    name="value-column" 
                    placeholder="Value Column"
                    options={metricConfigurationObject && metricConfigurationObject.columns ? metricConfigurationObject.columns : []}
                    value={metricConfigurationObject && rowColumn !== ""  ? findOptionByKey(metricConfigurationObject.columns, rowColumn) : ""}
                    onChange={(e) => { this.handleChange(RAW_ITEMS.FIELD_NAME, e) }}
                    formatOptionLabel={columnOptionLabel}
                    type={DROPDOWN_TYPE.INPUT}
                />
                <img alt='' src='/images/FhHRx.gif' width="15" height='15' className={"value-loader-" + this.props.rowIndex+1 + " uk-hidden uk-margin-xsmall-left"} />
                <div className={"uk-margin-xsmall-top align-items-center uk-display-flex uk-button-icon transparent-bg"}
                    onClick={() => this.removeValueColumnRow()}>
                    <i className="fa-lg fal fa-trash-alt uk-cursor-pointer" />
                </div>
                <Modal
                  id={"info-dialog"}
                  openDialog={this.state.openInfoDialog}
                  bodyContent={() => <h4>{this.state.infoMsg}</h4>}
                  dialogActions={this.openInfoDialogActions}
                  closeClick={() => this.setInfoDialogOpen(false)}
                  size={DIALOG_SIZE.MEDIUM}
                />
            </div>
        );
    }
}

export default ValueColumnRow;