import React, { Component } from 'react';
import { lang } from '../language/messages_en.js';
import { vectorSpacing } from '../class/jqueries.js';
import { BUTTON_TYPE, BUTTON_VARIANT, FormatTypes, PROFILE_COLUMN, PSL_RETURN_NAMES, SIZES } from '../class/constants.js';
import { formatValReact } from '../class/format.js';
import { event } from 'jquery';
import BridgeDrillTable from '../tables/BridgeDrillTable.js';
import Button from '../newComponents/Button.js';
import { concatenateStrings } from '../class/utils.js';
import TabulatorWrapper from "../Items/TabulatorWrapper";
import DrillCardWrapper from "./DrillCardWrapper";
const $ = require('jquery');

class DrillCard extends Component {
    constructor(props){
        super(props)
        this.state = {
            isTableExpanded:true

        }
        this.ref = React.createRef();
        this.ref3 = React.createRef();
    }

    /**
     * function also called from outside of this component
     * @param {*} expaned 
     */
    expandTable=(expaned)=>{
        let obj = this;
        document.getElementById("Card_Wrapperdiv"+ obj.props.index).style.backgroundColor= "#ffffff";
            this.setState({
                isTableExpanded: expaned ? expaned : !this.state.isTableExpanded
            },()=>{
                if (!this.state.isTableExpanded) {
                    $("#bridge_drill_table .tabulator-tableholder").addClass("fixedFooter1");
                }else {
                    $("#bridge_drill_table .tabulator-tableholder").removeClass("fixedFooter1");
                }
            });
    }
    /**
     * functions to simulate on hover
     * @param {*} expaned 
     */
   onhoverOverlay=(expaned)=>{
       let obj = this
       expaned = this.state.isTableExpanded;
        if(expaned === false){
            document.getElementById("Card_Wrapperdiv"+ obj.props.index).style.backgroundColor= "#f3f3f3";
        }
   }
   onleaveOverlay=(expaned)=>{
    let obj = this
    expaned = this.state.isTableExpanded;
     if(expaned === false){
         document.getElementById("Card_Wrapperdiv"+ obj.props.index).style.backgroundColor= "#ffffff";
     }
}

    render() {
        let _this = this;
        // let name = this.props.name || "";
        // let number = this.props.cardsData[this.props.index].data[0][PROFILE_COLUMN.NUMBER] || "";
        let allSelectedVectors = [];
        allSelectedVectors.push(this.props.tier);
        if(this.props.otherSelectedVectorsData?.length) {
          allSelectedVectors = allSelectedVectors.concat(this.props.otherSelectedVectorsData.map(m => m.label));
        }
        
        return (
            <DrillCardWrapper>
                <div id={"Card_Wrapperdiv"+ this.props.index} className="uk-width-full " onMouseEnter={() => this.onhoverOverlay()} onMouseLeave={() => this.onleaveOverlay()}>
                    <div className="drill-card-table-wrapper">
                        {this.props.isDrillBridgeReport?
                            <BridgeDrillTable ref={this.ref3} id={"table-list"} columns={this.props.drill_data.columns} bridge={this.props.bridge}
                                comparisonValue={this.props.comparisonValue} user={this.props.user}
                                scenarioObject={this.props.scenarioObject}
                                mixVector={this.props.mixVector} vector={this.props.vector} vectorObj={this.props.vectorObj}
                                customStartDate={this.props.startPeriod}
                                customEndDate={this.props.endPeriod} startQuarter={this.props.startQuarter}
                                endQuarter={this.props.endQuarter}
                                profitFormat={this.props.reportTitle} exportQueryFilter={this.props.exportQueryFilter}
                                data={{data:this.props.cardsData[this.props.index].data, columns:this.props.drill_data.columns}}
                                chartData={[]} bridgeName={this.props.bridgeName} barLabel={this.props.barLabel}
                                getLegendItems={this.props.getLegendItems} getSymbolColor={this.props.getSymbolColor}
                                getSymbolsTooltipFormatter={this.props.getSymbolsTooltipFormatter}
                                getSymbolsFormatter={this.props.getSymbolsFormatter}
                                getToolTipMessage={this.props.getToolTipMessage}
                                event={this.props.event} allowDrill={true} vectorOptions={this.props.vectorOptions} 
                                parentId={"To_Scroll_List"} isTableFullScreen={this.props.isTableFullScreen}
                                isDrillBridgeReport={true} addNewCard={this.props.addNewCard} setIsDrilling={this.props.setIsDrilling}
                            />
                        :
                        <TabulatorWrapper ref={this.ref} parentId={"To_Scroll_Top"} id={"Table_Top_" + this.props.index} data={this.props.card} index = {this.props.index} cardsData ={this.props.cardsData}
                            parentTableReport={this.props.parentTableReport} columnFiltering={this.props.columnFiltering} headerSort={this.props.headerSort} setIsDrilling={this.props.setIsDrilling}  isDrilling={this.props.isDrilling}
                            user={this.props.user} profitFormat={this.props.profitFormat} vectors={this.props.periods} submitDrill={this.props.submitDrill} setNextTier={this.props.setNextTier}
                            filterHeaderCSSSelector={this.props.filterHeaderCSSSelector} onColumnMoved={this.props.onColumnMoved} FY={this.props.FY} clearDrillingData={this.props.clearDrillingData}
                            scenario_id={this.props.scenario_id} drillBack={this.props.drillBack} scenarioNumber={this.props.scenarioNumber} dataset={this.props.dataset} addNewCard={this.props.addNewCard} 
                            isDashBoards={this.props.isDashBoards} isPaginated={this.props.isPaginated} setCardsData={this.props.setCardsData} saveChosenEntities={this.props.saveChosenEntities} allowDrill={true} 
                            records_limit= {this.props.records_limit} setDrillTabulatorPageSize={this.props.setDrillTabulatorPageSize} profitStackSelectedEntities={this.props.profitStackSelectedEntities || []}
                            setColumnWidthState={this.props.setColumnWidthState} columnsWidths={this.props.columnsWidths}
                            setDrillCardWidthState={this.props.setDrillCardWidthState} drillCardWidths={this.props.drillCardWidths}
                            setDrillListWidthState={this.props.setDrillListWidthState} drillListWidths={this.props.drillListWidths} exitDrill={this.props.exitDrill} hasDrillOption={this.props.hasDrillOption}
                            useAppearanceList={this.props?.useAppearanceList} drillCardProfile={this.props.drillCardProfile}
                          />
                        }
                    </div>
                      {/* {this.renderVectorDetailsDiv(this.props.qt, name, number, number, "_expanded")}
                      {this.props.otherSelectedVectorsData.map(vector => {
                        return this.renderVectorDetailsDiv(vector?.segment, vector?.name, vector?.number, vector?.tier_key, "_expanded")
                      })} */}
                    {/* <span className={this.state.isTableExpanded ? "hide-details" : "vectors-details-conainer uk-flex uk-flex-middle uk-margin-small-left fs-14 uk-width-full"} onClick={() => this.expandTable()}> */}
                      {/* <div className="vector-details"> */}
                        {/* {this.props.qt ? formatValReact(this.props.qt, FormatTypes.QUADRANT) : ""}
                        <div style={{textWrap: "nowrap"}}>{name}</div>
                        <div style={{textWrap: "nowrap"}}>{number}</div> 
                      </div> */}
                      {/* {this.renderCollapsedCardDetails()} */}
                      {/* <>
                        {this.props.otherSelectedVectorsData?.length > 0 &&
                          this.props.otherSelectedVectorsData.map((vector, index) => {
                            return (
                              <>
                                {index <=2 ? 
                                <div className="vector-details" key={vector.tier_key}>
                                  {vector?.segment ? <div>{formatValReact(vector?.segment, FormatTypes.QUADRANT)}</div> : ""}
                                  {vector?.name ? <div>{vector?.name}</div> : ""}
                                  {vector?.number ? <div>{vector?.number}</div> : ""}
                                </div> : ""
                                }
                              </>
                            );
                        })}
                        {this.props.otherSelectedVectorsData?.length > 2 ? 
                          <div style={{padding: "0.16vw 0.42vw", backgroundColor: "#E4E4E4", borderRadius: "0.16vw", fontWeight: "800", color: "#999999"}}>
                            {"+" + (this.props.otherSelectedVectorsData?.length - 3)}
                          </div> 
                        : ""}
                      </> */}
                  {/* </span> */}
                </div >
                
            </DrillCardWrapper>
        );
    }
}


export default DrillCard