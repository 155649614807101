
import {DEFAULT_DATE_FORMAT_UPLOAD, DEFINE_FIELDS, Formats ,RAW_FILE, RAW_ITEMS, UPLOAD_FIELDS} from './constants.js';
const DEFAULT_DATA_VALUE = "Other";

/**
     * returns row being edited
     * @param {*} tableData 
     * @param {*} datatype 
     * @param {*} dataypeId 
     * @param {*} rawFileFieldId 
     * @returns 
     */
 function getDataRowDataType(tableData, datatype, dataypeId, rawFileFieldId) {
	for (var row in tableData) {
		row = tableData[row];
		if(row[DEFINE_FIELDS.DATA_TYPE_FIELD] === datatype && row[DEFINE_FIELDS.DATA_TYPE_ID_FIELD] === dataypeId && row[DEFINE_FIELDS.RAW_SUBTYPE_FIELD_ID_FIELD] === rawFileFieldId) {
			return row;
		}
	}
	return "";
}

function getDataRowOfFieldName(tableData, requiredFieldName) {
	for (var row in tableData) {
		row = tableData[row];
		if(row[DEFINE_FIELDS.FIELD_NAME_FIELD].toLowerCase() === requiredFieldName.toLowerCase()) {
			return row;
		}
	}
	return "";
}

function setDefineFileColumnNames(allFileColumns, tableColumns, distinctValuesAndCount, defineTableData, rawFileId, dataTypesFields, fileSamples) {
	var tableData = [];
	var shouldReadFromDefineData = defineTableData.length > 0;
	for(var fileColumnIndex in allFileColumns) {  
		var fileColumn = allFileColumns[fileColumnIndex].trim();
		var tempRow = {};
		for(var tableRow in tableColumns) {
			tableRow = tableColumns[tableRow];
			if(tableRow.field === DEFINE_FIELDS.FIELD_NAME_FIELD) {
				tempRow[DEFINE_FIELDS.FIELD_NAME_FIELD] = fileColumn;
			} else if (tableRow.field === DEFINE_FIELDS.DISTINCT_VALUES_FIELD) {
				let num = getDataRowOfFieldName(defineTableData, fileColumn)[DEFINE_FIELDS.DISTINCT_VALUES_FIELD];
				let denom = getDataRowOfFieldName(defineTableData, fileColumn)[RAW_FILE.TOTAL_COUNT];
				tempRow[DEFINE_FIELDS.DISTINCT_VALUES_FIELD] = num + "/" + denom;
				if(!shouldReadFromDefineData || num === undefined) {
					//read from distinct values if the file isn't defined
					var fileColIndex = getFileColumnIndex(distinctValuesAndCount, fileColumn);
					if(fileColIndex !== -1) {
						num = distinctValuesAndCount[fileColIndex][fileColumn];
						denom = distinctValuesAndCount[fileColIndex][RAW_FILE.TOTAL_COUNT];    
						tempRow[DEFINE_FIELDS.DISTINCT_VALUES_FIELD] = num + "/" + denom;
					} else {
						tempRow[DEFINE_FIELDS.DISTINCT_VALUES_FIELD] = "";
					}
				}
			} else if (tableRow.field === DEFINE_FIELDS.SAMPLES_FIELD && !shouldReadFromDefineData) {
				tempRow[tableRow.field] = fileSamples.filter(e=>e.field === fileColumn)[0].value;
			} else if(shouldReadFromDefineData) {
				tempRow[tableRow.field] = getDataRowOfFieldName(defineTableData, fileColumn)[tableRow.field] || "";
			} else {
				tempRow[tableRow.field] = "";
			}
		}
		tempRow[DEFINE_FIELDS.ROW_NUMBER_FIELD] = fileColumnIndex; //add rowIndex column in the table
		tempRow[UPLOAD_FIELDS.RAW_FILE_ID_FIELD] = rawFileId;
		tempRow[DEFINE_FIELDS.DATA_TYPE_FIELD] = tempRow[DEFINE_FIELDS.DATA_TYPE_FIELD] === "" ? dataTypesFields[0]["datatype"] : tempRow[DEFINE_FIELDS.DATA_TYPE_FIELD];
		tempRow[DEFINE_FIELDS.DATA_TYPE_ID_FIELD] = tempRow[DEFINE_FIELDS.DATA_TYPE_ID_FIELD] === "" ? dataTypesFields[0][DEFINE_FIELDS.DATA_TYPE_ID_FIELD]  : tempRow[DEFINE_FIELDS.DATA_TYPE_ID_FIELD];
		tempRow[DEFINE_FIELDS.DATA_FIELD] = tempRow[DEFINE_FIELDS.DATA_FIELD] === "" ? DEFAULT_DATA_VALUE : tempRow[DEFINE_FIELDS.DATA_FIELD];
		var row = getDataRowDataType(defineTableData,tempRow[DEFINE_FIELDS.DATA_TYPE_FIELD],tempRow[DEFINE_FIELDS.DATA_TYPE_ID_FIELD],tempRow[DEFINE_FIELDS.RAW_SUBTYPE_FIELD_ID_FIELD]);
		tempRow[DEFINE_FIELDS.MAX_FIELD] = row[DEFINE_FIELDS.MAX_FIELD] !== undefined ? row[DEFINE_FIELDS.MAX_FIELD].toString() : "*";
		tempRow[DEFINE_FIELDS.MIN_FIELD] = row[DEFINE_FIELDS.MIN_FIELD] !== undefined ? row[DEFINE_FIELDS.MIN_FIELD].toString() : "*";
		tempRow[DEFINE_FIELDS.MAX_LENGTH_FIELD] = row[DEFINE_FIELDS.MAX_LENGTH_FIELD] !== undefined ? row[DEFINE_FIELDS.MAX_LENGTH_FIELD].toString() : "0" ;
		tempRow[DEFINE_FIELDS.MIN_LENGTH_FIELD] = row[DEFINE_FIELDS.MIN_LENGTH_FIELD] !== undefined ? row[DEFINE_FIELDS.MIN_LENGTH_FIELD].toString() : "0";
		tempRow[DEFINE_FIELDS.DECIMAL_FIELD] = row[DEFINE_FIELDS.DECIMAL_FIELD] !== undefined ? row[DEFINE_FIELDS.DECIMAL_FIELD].toString() : "9";
		tempRow[DEFINE_FIELDS.TRAIL_OR_LEAD_FIELD] = row[DEFINE_FIELDS.TRAIL_OR_LEAD_FIELD] !== undefined ? row[DEFINE_FIELDS.TRAIL_OR_LEAD_FIELD].toString() : "";
		tempRow[DEFINE_FIELDS.IS_TRAILING_FIELD] = row[DEFINE_FIELDS.IS_TRAILING_FIELD] !== undefined ?  row[DEFINE_FIELDS.IS_TRAILING_FIELD].toString() : "true";
		tempRow[DEFINE_FIELDS.TRUE_INPUT_FIELD] = row[DEFINE_FIELDS.TRUE_INPUT_FIELD] !== undefined ? row[DEFINE_FIELDS.TRUE_INPUT_FIELD].toString() : "";
		tempRow[DEFINE_FIELDS.FALSE_INPUT_FIELD] = row[DEFINE_FIELDS.FALSE_INPUT_FIELD] !== undefined ? row[DEFINE_FIELDS.FALSE_INPUT_FIELD].toString() : "";
		tempRow[DEFINE_FIELDS.DATE_FORMAT_FIELD] = row[DEFINE_FIELDS.DATE_FORMAT_FIELD] !== undefined ? row[DEFINE_FIELDS.DATE_FORMAT_FIELD].toString() : DEFAULT_DATE_FORMAT_UPLOAD;
		tempRow[DEFINE_FIELDS.FORMAT_FIELD] = row[DEFINE_FIELDS.FORMAT_FIELD] !== undefined ? row[DEFINE_FIELDS.FORMAT_FIELD].toString() : "(0-0)  **";
		tempRow[DEFINE_FIELDS.CURRENCY_DATE] = row[DEFINE_FIELDS.CURRENCY_DATE];
		tempRow[DEFINE_FIELDS.CURRENCY_FIELD_NAME] = row[DEFINE_FIELDS.CURRENCY_FIELD_NAME];
		tempRow[DEFINE_FIELDS.CURRENCY_SUBTYPE_ID] = row[DEFINE_FIELDS.CURRENCY_SUBTYPE_ID];
		tempRow[DEFINE_FIELDS.STATIC] = row[DEFINE_FIELDS.STATIC];
		tempRow[RAW_ITEMS.SUBTYPE_NAME] = row[RAW_ITEMS.SUBTYPE_NAME];
		tempRow[DEFINE_FIELDS.DEFINED_SUBTYPE_ID] = row[DEFINE_FIELDS.DEFINED_SUBTYPE_ID] ? row[DEFINE_FIELDS.DEFINED_SUBTYPE_ID].toString() : undefined;
		tableData.push(tempRow);
	}
	return tableData;
}

function getFileColumnIndex(data, col) {
    let retIndex = -1;
    data.map((item, index)=>{
        if(Object.keys(item).indexOf(col) !== -1) {
            retIndex = index;
            return;
        }
    });
    return retIndex;
}

function getSelectedSections(tableData){
    var selectedSections = [];
    for (var row in tableData) {
        row = tableData[row];
            selectedSections.push(row[DEFINE_FIELDS.DATA_FIELD]);
    }
    return selectedSections;
}

/**
* This function updates the table row in the tableData object and
* fills it with the corresponding "Data Type" and "Description"
* values when the user changes the value of the "Data" dropdown
* @param {*} cell 
* @param {*} optionIndex 
*/
  function updateTableRow(cell, optionIndex, defineTableData, dataTypesFieldsVar, mandatorySectionFields_uneditableVar) {
       var rowIndex = cell.getRow().getData()[DEFINE_FIELDS.ROW_NUMBER_FIELD];   //save the index of the row to be edited
       var dataTypesKey = dataTypesFieldsVar;
       for (var row in defineTableData) {
           if (Number(row) === Number(rowIndex)) {     //edit the requested row
               if(optionIndex === -1) {
                   //if the option index didn't change (still = -1), means the user didn't 
                   //choose any of the mandatory fields so it must be the "Other" option
                   defineTableData[row][DEFINE_FIELDS.DATA_FIELD] = DEFAULT_DATA_VALUE;
                   defineTableData[row][DEFINE_FIELDS.DATA_TYPE_FIELD] = ""; //reset the value of the datatype after the data is set to "Other"
                   defineTableData[row][DEFINE_FIELDS.DATA_TYPE_ID_FIELD] = dataTypesKey[0][DEFINE_FIELDS.DATA_TYPE_ID_FIELD];; //reset the value of the datatypeId after the data is set to "Other"
                   defineTableData[row][DEFINE_FIELDS.DATA_TYPE_FIELD] = dataTypesKey[0]["datatype"]; //reset the value of the datatype after the data is set to "Other"
                   defineTableData[row][DEFINE_FIELDS.DATA_TYPE_ID_FIELD] = dataTypesKey[0][DEFINE_FIELDS.DATA_TYPE_ID_FIELD]; //reset the value of the datatypeId after the data is set to "Other"
               } else {
                   defineTableData[row][DEFINE_FIELDS.DATA_FIELD] = mandatorySectionFields_uneditableVar[optionIndex][DEFINE_FIELDS.DATA_FIELD];
                   defineTableData[row][DEFINE_FIELDS.DATA_TYPE_FIELD] = mandatorySectionFields_uneditableVar[optionIndex][DEFINE_FIELDS.DATA_TYPE_FIELD];
                   defineTableData[row][DEFINE_FIELDS.DESCRIPTION_FIELD] = mandatorySectionFields_uneditableVar[optionIndex][DEFINE_FIELDS.DESCRIPTION_FIELD];
                   defineTableData[row][DEFINE_FIELDS.DATA_TYPE_ID_FIELD] = mandatorySectionFields_uneditableVar[optionIndex][DEFINE_FIELDS.DATA_TYPE_ID_FIELD];
               }
           }
       }
       return defineTableData;
   }

     /**
     * enables submit button if definition changed
     * @param {*} oldDefinition 
     * @param {*} newDefinition 
     */
     function compareDefinition(oldDefinition, newDefinition) {
        if (oldDefinition === newDefinition) {
            //disable submit button
            $("#define-submit").addClass("uk-button-disabled");
        } else {
            //enable submit button
            $("#define-submit").removeClass("uk-button-disabled");
        }
    }

    function validateFormatsPopUP(format, values) {
        var text = "";
        $("#trueInput").removeClass("emptyField");
        $("#max").removeClass("emptyField");
        $("#min").removeClass("emptyField");
        $("#maxLength").removeClass("emptyField");
        $("#minLength").removeClass("emptyField");
        $('#trailOrLead').removeClass("emptyField");
        $("#decimal").removeClass("emptyField");
        $('#dateFormat').removeClass("emptyField");

        switch (format) {
            case Formats.String:
                if (Number($("#maxLength").val()) < 0 || Number($("#minLength").val()) < 0) {
                    text+= "- Make sure max length and min length are greater than or equal zero./";
                    $("#maxLength").addClass("emptyField");
                    $("#minLength").addClass("emptyField");
                }
                if (Number($("#maxLength").val()) < Number($("#minLength").val())) {
                    text+= "- Make sure max length is greater than min length./";
                    $("#maxLength").addClass("emptyField");
                    $("#minLength").addClass("emptyField");
                }
                if ($('#trailOrLead').val() !== "") {
                    var regex = new RegExp("^[a-zA-Z0-9]+$");
                    if (!$('#trailOrLead').val().match(regex)) {
                        text+= "- Make sure trail or lead character is alphanumeric./";
                        $('#trailOrLead').addClass("emptyField"); 
                    }
                }
                if (($("#maxLength").val()).toString().split(".").length > 1 ) {
                    text+= "- Make sure max length is integer./";
                    $("#maxLength").addClass("emptyField");
                }
                if (($("#minLength").val()).toString().split(".").length > 1) {
                    text+= "- Make sure min length is integer./";
                    $("#minLength").addClass("emptyField");
                } 
            break;

            case Formats.Numeric:
                    if (Number($("#max").val()) < Number($("#min").val())) {
                        text+= "- Make sure max is greater than min ./";
                        $("#min").addClass("emptyField");
                        $("#max").addClass("emptyField");
                    }
                    if ($("#decimal").val() < 0) {
                        text+= "- Make sure decimal value is greater or equal to zero./";
                        $("#decimal").addClass("emptyField");
                    }
                    if (($("#max").val()).toString().split(".").length > 1) {
                        if ($("#max").val().toString().split(".")[1].length >  $("#decimal").val()) {
                            text+= "- Make sure decimals in max field are equal or less than the decimal value./";
                            $("#max").addClass("emptyField");
                        }
                    }
                    if ($("#min").val().toString().split(".").length > 1) {
                        if ($("#min").val().toString().split(".")[1].length >  $("#decimal").val()) {
                            text+= "- Make sure decimals in min field are equal or less than the decimal value./";
                            $("#min").addClass("emptyField");
                        }
                    }
                break;

            case Formats.Boolean:
                if (values.creditBoolValues.length === 0) {
                    text+= "- Make sure to fill true inputs./";
                }
            break;
         }
        return text;
    }

export { getDataRowDataType, getDataRowOfFieldName, getFileColumnIndex, setDefineFileColumnNames, getSelectedSections, updateTableRow,
         compareDefinition, validateFormatsPopUP};