import React, { Component } from 'react';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import { copyObjectValues } from '../../class/utils';
import Container from '../../components/manageColumns/Container';

class FilterByPSS extends Component {
    constructor(props){
        super(props)
     
    }
    assignObjectPaths = (obj, stack) => {
        Object.keys(obj).forEach(k => {
          const node = obj[k];
          if (node && typeof node === "object") {
            node.path = stack ? `${stack}.${k}` : k;
            this.assignObjectPaths(node, node.path);
          }
        });
      };


    render() {
        if(this.props.data && this.props.data !== null)
            this.assignObjectPaths(this.props.data)
      return (
        <Container onNodeToggle = {this.props.onNodeToggle} showDropdown = {this.props.showDropdown} showPartiallySelected={this.props.showPartiallySelected} onChange={this.props.onChange} data={this.props.data} id={this.props.id} texts={this.props.texts} className={"dropdown-tree"}/>
      )
    }
  }

  export default FilterByPSS;