import React, { Component } from 'react';
import { CustomSelect } from '../../form/elements.js';
import { getTranslationFile, findOptionByKey } from '../../class/utils.js';
import { RAW_ITEMS, UPLOAD_SECTIONS, STAGING_SECTIONS, DROPDOWN_TYPE } from '../../class/constants';
import { convertPxToViewport } from '../../class/formatting.js';
import DropDown from '../../newComponents/DropDown.js';

const MESSAGES = getTranslationFile();

/**
 * Metrics Configuration - joins
 * 
 * @author [Lara Jannoun]
 */
class MetricColumn extends Component{
    constructor(props) {
        super(props);
        this.state = {
        }
        this.getDisplayName = this.getDisplayName.bind(this);
    }

    handleChange(e) {  
        var tempObj = this.state.metricColumnObject;
        if(e !== null) {
            if (this.state.metricColumnObject !== undefined) {
                tempObj["vector-column"] = e.value;
            } else{
                tempObj = {};
                tempObj["vector-column"] = e.value;
            } 
        }
        this.setState({
            metricColumnObject: tempObj
        },function(){
            this.props.updateJoin(this.props.rowIndex, e.value);
        });       
    }

    componentDidUpdate(nextProps, nextState) {
        if (this.state.metricColumnObject) {
            if (nextProps.metricColumnObject["vector-column"] !== this.state.metricColumnObject["vector-column"]) {
                this.state.metricColumnObject["vector-column"] = nextProps.metricColumnObject["vector-column"];
                this.setState(this.state);
            }
        }
    }

    getDisplayName(name) {
        var arr= this.props.fileTypes;
        var _filter = arr.filter(e=>e[RAW_ITEMS.SUBTYPE_NAME] === name);
        var displaName = _filter.length > 0 ? _filter[0][RAW_ITEMS.SUBTYPE_DISPLAY_NAME] : "";
        return displaName;
    }

    render() {
        var vectorColumn = this.state.metricColumnObject && this.state.metricColumnObject["vector-column"] ? this.state.metricColumnObject["vector-column"]
            : this.props.metricColumnObject && this.props.metricColumnObject["vector-column"] ? this.props.metricColumnObject["vector-column"] : "";
        var tdMdMappingMessage = MESSAGES.metrics_mapping_md_td.replace('{x}', this.props.metricColumnObject[RAW_ITEMS.SUBTYPE_DISPLAY_NAME]).replace('{y}',this.props.mappedMetric["display_name"]);
        var file = "";
        if (!this.props.metricColumnObject[RAW_ITEMS.SUBTYPE_DISPLAY_NAME]) {
            file = this.getDisplayName(this.props.metricColumnObject[RAW_ITEMS.SUBTYPE_NAME])
        }
        if([STAGING_SECTIONS.CALCULATED_COLUMNS].includes(this.props.metricColumnObject[RAW_ITEMS.SUBTYPE_NAME])) {
            return "";
        }      

        return (
            <div className="metric-joins-child uk-display-inline-flex uk-margin-small-bottom uk-margin-small-top pi-align-items-baseline" key={"metricColumnRow-"+Math.random()}>
                { [UPLOAD_SECTIONS.FIELDS.MASTER_DATA, UPLOAD_SECTIONS.FIELDS.TRANSACTION_DATA].includes(this.props.metricColumnObject[RAW_ITEMS.TYPE_NAME]) ?
                        <span className="fs-14 metric-vector-label-width">{tdMdMappingMessage}</span>
                        :
                        <React.Fragment>
                        <span className="fs-14 metric-vector-label-width uk-display-flex align-items-center">{MESSAGES.metrics_mapping_join_column_match} {this.props.metricColumnObject[RAW_ITEMS.SUBTYPE_DISPLAY_NAME] ?this.props.metricColumnObject[RAW_ITEMS.SUBTYPE_DISPLAY_NAME]  : file}{"?"}</span>
                        <div className="uk-display-inline-flex uk-flex-column">
                            <DropDown
                                id="select-vector-column"
                                className="input-width-250 input__dropdown "
                                name="file-vector-column"
                                placeholder="Choose vector column"
                                value={vectorColumn ? findOptionByKey(this.props.metricColumnObject.stringOutput ? this.props.metricColumnObject.stringOutput : this.props.stringOutput , vectorColumn)  : ""}
                                options={this.props.metricColumnObject.stringOutput ? this.props.metricColumnObject.stringOutput : this.props.stringOutput ? this.props.stringOutput :[]}
                                onChange={(e)=>{this.handleChange(e)}}
                                maxMenuHeight={convertPxToViewport(120)}
                                type={DROPDOWN_TYPE.INPUT}
                            />
                            <div className="uk-margin-xsmall-top">
                                { this.props.showLoader ?
                                <div className="d-inline-block align-self-center auditMessage" id="dots_loader">
                                    <span className="uk-padding-xsmall-right fs-12 text-black">{MESSAGES.performing_match_analysis}</span>
                                    <img alt="loading" src='/images/3dots.gif' className="height-20" />
                                </div>
                                :
                                this.props.metricValidation && this.props.metricValidation !== "" ? 
                                    <span id="calc-col-warn" className="red italic">{this.props.metricValidation[this.props.rowIndex]}</span>
                                : ""
                                }
                            </div>
                        </div>
                        </React.Fragment>
                }
            </div>
        );
    }
}

export default MetricColumn;