import { lang } from "../language/messages_en";
const CrashComponent = () => {
  return (
    <div style={{ height: "100%" }}>
      <div className="crash-screen">
        <div>
          <i className="fa-5x fal fa-exclamation-circle" />
        </div>
        <h4 style={{fontFamily:'Lato', fontSize:'18px', fontWeight:'900'}}>Oops !</h4>
        <h2 style={{fontFamily:'Lato', fontSize:'14px', fontWeight:'700'}}>{lang.something_went_wrong_crash}</h2>
        <h3 style={{fontFamily:'Lato', fontSize:'14px', fontWeight:'700'}}>{lang.retry}</h3>
      </div>
    </div>
  );
};

export { CrashComponent };
