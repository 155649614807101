import Input from "../../newComponents/Input";

function ConfirmPasswordInputField({handleValidation, handlePasswordChange, confirmPasswordValue, confirmPasswordError}){
    return (
        <>
     <div className="form-group my-3">
        <Input type="password" value={confirmPasswordValue}  onChange={handlePasswordChange} onKeyUp={handleValidation} name="confirmPassword" placeholder="Password" /*className="form-control password-input-width"*/ />
        <p className="text-danger">{confirmPasswordError}</p>
    </div>
        </>
    )
}

export default ConfirmPasswordInputField;