import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import PropTypes from 'prop-types';
import {forwardRef, useImperativeHandle, useRef} from "react";
import {FormatTypes, STAGE_TITLES, TLC_LANDSCAPE} from "../class/constants";
import { formatValNumber } from "../class/format";
import {copyObjectValues, tryParse} from "../class/utils";
import {Segment} from "../components/Segment";
import { getCurrency } from "../class/common";

/**
 * @author Mostafa Haydar
 * @description component that renders the total landed cost bar chart
 * @param {*} props
 * @returns
 */
const TLCLandscapeBarChart =  forwardRef((props,ref) =>  {
  const chartRef = useRef(null);
  useImperativeHandle(ref, () => ({
    renderBarChart:(data)=>{
      renderBarChart(data);
    },
    renderStackedChart:(data)=>{
      renderStackedChart(data);
    }
}));

  const formatDataByEntities = (entities, row) => {
    entities.map(entity => {
      let entityAmount = row[`amount_${entity.value}`]
      let entityPercentage = row[`percentage_${entity.value}`]
      let entityValAmount = formatValNumber(entityAmount, FormatTypes.AMOUNT);
      entityValAmount = entityValAmount.replace(getCurrency(),"");
      let entityValPercentage = formatValNumber(entityPercentage, FormatTypes.PERCENTAGE);
      row[`amount_${entity.value}`] = entityValAmount;
      row[`percentage_${entity.value}`] = entityValPercentage;
      return entity;
    })
  }

  /**
   * Formatting bar chart data (amount & percentage) to match the format selected in format popup
   * @param {*} data 
   * @returns 
   */
  const formatBarChartData = (data) => {
    if (!data?.length) {
      return [];
    }

    let finalData = copyObjectValues(data);
    let entities = copyObjectValues(props.entities);
    finalData.map(row => {
      let valAmount = formatValNumber(row.amount, FormatTypes.AMOUNT);
      valAmount = valAmount.replace(getCurrency(),"");
      let valPerc = formatValNumber(row.percentage, FormatTypes.PERCENTAGE);
      if (entities.length > 0) {
        formatDataByEntities(entities, row);
      }
      row.amount = valAmount;
      row.percentage = valPerc;
      return row;
    })
    return finalData;
  }

  const buildSeries = (chart, fill, data) => {
    chart.data = formatBarChartData(data);
    chart.strokeOpacity = 0;
    if (chart.logo) {
      chart.logo.disabled = true;
    }

    // x axis
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "bucket";
    categoryAxis.renderer.minGridDistance = 50;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.labels.template.disabled = true;
    categoryAxis.renderer.line.strokeWidth = 0;

    // y axis
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.renderer.line.strokeOpacity = 0;
    //to disable zero-line
    valueAxis.renderer.baseGrid.disabled = true;
    //This is useful in the situations where we might want to "pad" actual series in the chart from getting too close to the edge.
    valueAxis.extraMin = 0.3;

    let columnSeries = chart.series.push(new am4charts.ColumnSeries());
    columnSeries.dataFields.categoryX = "bucket";
    columnSeries.dataFields.valueY = "value";
    columnSeries.dataFields.openValueY = "open";

    let columnTemplate = columnSeries.columns.template;
    columnTemplate.strokeOpacity = 0;
    columnTemplate.column.cornerRadiusTopLeft = 4; // Adjust corner radius as needed
    columnTemplate.column.cornerRadiusTopRight = 4; // Adjust corner radius as needed
    columnTemplate.column.cornerRadiusBottomLeft = 4; // Adjust corner radius as needed
    columnTemplate.column.cornerRadiusBottomRight = 4;
    columnTemplate.column.fill = fill;
    // Apply drop shadow to the columns
    
    columnTemplate.column.filters.push(new am4core.DropShadowFilter());
    columnTemplate.column.filters.getIndex(0).blur = 3;
    columnTemplate.column.filters.getIndex(0).opacity = 0.25;
    columnTemplate.column.adapter.add("pixelHeight", function (height, target) {
        return height + 2;
    });

    return columnSeries;
  }

  const buildTitles = (columnTemplate, isEntity) => {
    let titleContainer = columnTemplate.createChild(am4core.Container);
    titleContainer.dy = isEntity ? 60 : 90;
    titleContainer.layout = "vertical";
    titleContainer.valign = "bottom";
    titleContainer.align = "center";
    titleContainer.contentAlign = "center";

    let bucketTitleLabel = titleContainer.createChild(am4core.Label);
    bucketTitleLabel.wrap = true;
    bucketTitleLabel.align = "center";
    bucketTitleLabel.layout = "vertical";
    bucketTitleLabel.textAlign = "middle";
    bucketTitleLabel.paddingBottom = 5;
    bucketTitleLabel.text = "[bold font-size: 0.78vw ]{bucket}[/]";

    let bucketAmountLabel = titleContainer.createChild(am4core.Label);
    bucketAmountLabel.align = "center";
    bucketAmountLabel.textAlign = "middle";
    bucketAmountLabel.paddingBottom = 5;
    bucketAmountLabel.text = "[bold font-size: 0.73vw fill: {color}]"+getCurrency()+"{amount}[/]";

    let bucketPercLabel = titleContainer.createChild(am4core.Label);
    bucketPercLabel.align = "center";
    bucketPercLabel.textAlign = "middle";
    bucketPercLabel.paddingBottom = 5;
    bucketPercLabel.text = "[font-size: 0.63vw fill: {color}]{percentage}%[/]";
    return columnTemplate;
  }

  const addNameAndColorToEntities = (entitiesObject, nameAttribute, colorAttribute) => {
    entitiesObject.map(entity => {
      let translatedSegment = new Segment().getSegmentObject(entity.label);
      let isSegment = !!translatedSegment[nameAttribute];
      entity.name = translatedSegment[nameAttribute] || entity[nameAttribute];
      entity.fill = isSegment ? translatedSegment[colorAttribute] : entity[colorAttribute];
      return entity;
    });
    return entitiesObject;
  }

  const buildTooltip = (columnSeries)=> {
    let tooltipObject = copyObjectValues(props.entities);
    if (props.lense !== TLC_LANDSCAPE.SUB_TABS.TOTAL) {
      tooltipObject = addNameAndColorToEntities(tooltipObject, "label","bubbleColor").toReversed();
    }
    let revenueReturnName = props.view === TLC_LANDSCAPE.TABS.MARGIN_VIEW ? props.revenueReturnName.name : STAGE_TITLES.COGS_TITLE
    columnSeries.tooltip.getFillFromObject = false;
    columnSeries.tooltip.fitPointerToBounds = true;
    columnSeries.tooltip.background.fill = am4core.color("rgba(255,255,255,0)");
    columnSeries.tooltip.background.strokeWidth = 4;
    columnSeries.tooltip.background.stroke = am4core.color("rgba(204,204,204,0)");
    columnSeries.tooltip.background.cornerRadius = 5;
    columnSeries.tooltip.pointerOrientation = "horizontal";
    columnSeries.tooltip.label.interactionsEnabled = true;
    columnSeries.tooltip.keepTargetHover = true;
    columnSeries.columns.template.tooltipHTML = JSON.stringify(tooltipObject);
    columnSeries.columns.template.adapter.add("tooltipHTML", function (json, ev) {
      let entities = tryParse(json);
      const renderTooltipSections = () => {
        if (props.lense !== TLC_LANDSCAPE.SUB_TABS.TOTAL) {
          return entities.map(entity => {
                return `
              <div class="separator_tootlip"></div>
              <div class="first_container_element elements">
                  <div class="second_section_element_container">
                      <div class="second_section_container_title">${entity.name}: <span class="second_section_value"><p>`+getCurrency()+`</p>{amount_${entity.value}}</span></div>
                  </div>
                  <div style="width:100%; background-color: transparent; border-radius: 1.5625vw;">
                      <div style="min-width: 8%; width: {percentage_${entity.value}}%;  max-width: 100%;  background-color: ${entity.fill}; border-radius: 1.5625vw; height: 0.208vw;"></div>
                  </div>
                  <div class="second_section_container_title">% of ${entity.name} ${revenueReturnName}: <span style="color: ${entity.fill}">{percentage_${entity.value}}%</span>
                  </div>
              </div>
              `;
              }
          ).join('');
        }
      }
        return `
      <div class="ui-card">
      <div class="ui-card-first-section">
            <div class="bucket_title_tooltip">{bucket}</div>` +
            (ev.dataItem.dataContext.bucket_description ? `<div class="bucket_description_tooltip">{bucket_description}</div>` : `<div class="uk-hidden"></div>`) + `
            <div class="amount_container_tootltip"><div class="amount">Amount</div><div class="amount_value_tooltip"><p>`+getCurrency()+`</p>{amount}</div></div>
            <div class="separator_tootlip"></div>
            <div class="percentage_container_tootltip"><div class="percentage">% of ${revenueReturnName}</div>{percentage}%</div>
            </div>
            <div class = "ui-card-second-section">
           ` + renderTooltipSections() + `
            </div>
            <button id="view_trend"> View Trend</button>
        </div>
      `;
    })

    columnSeries.tooltip.events.on("hit", function(event) {
      var tooltip = event.target;
      var dataItem = tooltip.dataItem;
      if (event.event.target.id === "view_trend") {
        props.viewTrend({value:dataItem._dataContext.bucketMachineName, label:dataItem._dataContext.bucket});
      }
    });
  }

  const buildLegend = (chart, entities) => {
    chart.legend = new am4charts.Legend();
    chart.legend.useDefaultMarker = true;
    let legend = chart.legend;
    legend.parent = chart.chartContainer;
    legend.itemContainers.template.togglable = false;
    legend.itemContainers.template.paddingTop = 0;
    legend.itemContainers.template.paddingBottom = 0;
    legend.itemContainers.template.marginLeft = -15;
    legend.itemContainers.template.dx = 25;
    legend.labels.template.fontSize = 12;
    legend.labels.template.dx = -15;
    legend.labels.template.fontWeight = "500";
    legend.labels.template.fontFamily = "Lato, sans-serif";
    legend.position = "bottom";
    legend.contentAlign = "left";
    legend.background.fill = am4core.color("#000");
    legend.background.fillOpacity = 0;
    let marker = legend.markers.template.children.getIndex(0);
    marker.cornerRadius(12, 12, 12, 12);
    marker.width = 10;
    marker.height = 10;
    marker.strokeWidth = 2;
    marker.dy = 6;
    marker.strokeOpacity = 1;
    let entitiesObject = copyObjectValues(entities)
    legend.data = addNameAndColorToEntities(entitiesObject, "label","legendColor");
    chart.legend = legend;
  }

  const renderBarChart = (data) => {
    let chart = am4core.create(chartRef.current, am4charts.XYChart);
    let columnSeries = buildSeries(chart, "#5F60C8", data);
    buildTitles(columnSeries.columns.template);
    buildTooltip(columnSeries);
    
    return () => {
      chart.dispose();
    };
  };

  const buildStackChartTitle = (root, series) => {
    // Create labels
    let tooltip = am5.Tooltip.new(root, {
      getFillFromSprite: false,
      getStrokeFromSprite: false,
      autoTextColor: false,
      getLabelFillFromSprite: true,
      keepTargetHover: true,
      background: am5.Rectangle.new(root, {
        fill: "transparent",
        fillOpacity: 1,
      }),
      pointerOrientation:"horizontal",
      // dx:30
    });
    series.bullets.push(function () {
      let container = am5.Container.new(root, {
          background: am5.Rectangle.new(root, {
              fill: am5.color(0xff5599),
              fillOpacity: 0,
          })
      });
      // Adding the tooltip to the container
      container.set("tooltip", tooltip);
      container.get("tooltip").label.set("interactive", true);
      buildStackedTooltip(container, true);

      let titleLabel = am5.Label.new(root, {
        text: "{bucket}",
        fontSize: 12,
        fontWeight: 'bold',
        fontFamily: 'Lato, sans-serif',
        dy: 10,
        centerX: am5.p50,
        maxWidth: 140, // Adjust max width as needed
        oversizedBehavior: "wrap-no-break",
        textAlign: "center",
        populateText: true
      });
      container.children.push(titleLabel);

      let amountLabel = am5.Label.new(root, {
        text: getCurrency()+"{amount}",
        fontSize: 11,
        fontWeight: 'bold',
        fontFamily: 'Lato, sans-serif',
        dy: 40,
        centerX: am5.p50,
        populateText: true
      });
      // Add adapter to set the fill color
      amountLabel.adapters.add("fill", function (fill, target) {
        let dataItem = target.dataItem;
        if (dataItem) {
          return am5.color(dataItem.dataContext.color);
        }
        return fill;
      });
      container.children.push(amountLabel);

      let percentageLabel = am5.Label.new(root, {
        text: "{percentage}%",
        fontSize: 10,
        fontWeight: 'normal',
        fontFamily: 'Lato, sans-serif',
        dy: 57,
        centerX: am5.p50,
        populateText: true
      });

      // Add adapter to set the fill color
      percentageLabel.adapters.add("fill", function (fill, target) {
        let dataItem = target.dataItem;
        if (dataItem) {
          return am5.color(dataItem.dataContext.color);
        }
        return fill;
      });
      container.children.push(percentageLabel);

      //fixing label position for negative values
      container.adapters.add("centerY", function (centerY, target) {
        am5.array.each(target.dataItem.bullets, function (bullet) {
          if (target.dataItem.dataContext.amount < "0") {
            bullet.set("locationY", 1);
          } else {
            bullet.set("locationY", 0);
          }
        });
      });

      return am5.Bullet.new(root, {
        locationY: 0,
        sprite: container
      });
    });
  }

  const buildStackedTooltip = (tooltipTarget, forLabels) => {
    let revenueReturnName = props.view === TLC_LANDSCAPE.TABS.MARGIN_VIEW ? props.revenueReturnName.name : STAGE_TITLES.COGS_TITLE
    let tooltipObject = copyObjectValues(props.entities);
    if (props.lense !== TLC_LANDSCAPE.SUB_TABS.TOTAL) {
      tooltipObject = addNameAndColorToEntities(tooltipObject, "label", "bubbleColor").toReversed();
    }
    let tooltipHoverTarget = forLabels ? tooltipTarget : tooltipTarget.columns.template
    tooltipHoverTarget.adapters.add("tooltipHTML", function (html, target) {
      let entities = tooltipObject;
      const renderTooltipSections = () => {
        if (props.lense !== TLC_LANDSCAPE.SUB_TABS.TOTAL) {
          return entities.map(entity => {
                return `
              <div class="separator_tootlip"></div>
              <div class="first_container_element elements">
                  <div class="second_section_element_container">
                      <div class="second_section_container_title">${entity.name}: <span class="second_section_value"><p>`+getCurrency()+`</p>{amount_${entity.value}}</span></div>
                  </div>
                  <div style="width:100%; background-color: transparent; border-radius: 1.5625vw;">
                      <div style="min-width: 8%; width: {percentage_${entity.value}}%;  max-width: 100%;  background-color: ${entity.fill}; border-radius: 1.5625vw; height: 0.208vw;"></div>
                  </div>
                  <div class="second_section_container_title">% of ${entity.name} ${revenueReturnName}: <span style="color: ${entity.fill}">{percentage_${entity.value}}%</span>
                  </div>
              </div>
              `;
              }
          ).join('');
        }
      }

      let dataItem = target.dataItem;
      if (dataItem) {
        return `<div class="ui-card">
                <div class="ui-card-first-section">
                  <div class="bucket_title_tooltip">{bucket}</div>${dataItem.dataContext.bucket_description ? `<div class="bucket_description_tooltip">{bucket_description}</div>` : `<div class="uk-hidden"></div>`}
                      <div class="amount_container_tootltip"><div class="amount">Amount</div><div class="amount_value_tooltip"><p>`+getCurrency()+`</p>{amount}</div></div>
                        <div class="separator_tootlip"></div>
                           <div class="percentage_container_tootltip"><div class="percentage">% of ${revenueReturnName}</div>{percentage}%</div>
                           <div class = "ui-card-second-section">
           ${renderTooltipSections()}
            </div>
                </div>
                      <button id="view_trend"> View Trend</button>
                     </div>`;
      }

    });
    tooltipTarget?.getTooltip()?.events.on("pointerover", function (ev) {
      ev.target.label.events.on("click", function (e) {
        if (e.originalEvent.target.id === "view_trend") {
          props.viewTrend({value:ev.target.label.dataItem.dataContext.bucketMachineName, label:ev.target.label.dataItem.dataContext.bucket});
        }
      })
    })
  }

  const addStackedChartLegend = (chart, series, notStacked, legend) => {
    if (!notStacked) {
      legend.markers.template.setAll({
        width: 10,
        height: 10,
      });
      legend.valueLabels.template.set("forceHidden", true);
      legend.markerRectangles.template.setAll({
        cornerRadiusTL: 10,
        cornerRadiusTR: 10,
        cornerRadiusBL: 10,
        cornerRadiusBR: 10,
      });
      legend.labels.template.setAll({
        fontSize: 12,
        fontFamily: "Lato, sans-serif",
      });
      // Ensure there are no other shadow settings affecting the legend markers
      legend.markerRectangles.template.adapters.add("shadowColor", function () {
        return am5.color(0xffffff);
      });
      legend.data.push(series);
    }
  }

  const renderStackedChart = (data) => {
    am5.disposeAllRootElements();
    let root = am5.Root.new("chartdiv1");
    root._logo.dispose();
    let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          wheelY: "zoomX",
          layout: root.verticalLayout,
          paddingLeft: 0
        }));
    // chart.data = formatBarChartData(data);

    // Create axes
    let xRenderer = am5xy.AxisRendererX.new(root, {
      cellStartLocation: 0.1,
      cellEndLocation: 0.9,
      minGridDistance: 70,
      minorGridEnabled: true
    });

    let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: "bucketMachineName",
      renderer: xRenderer,
      tooltip: am5.Tooltip.new(root, {})
    }));

    xRenderer.grid.template.setAll({
      location: 1
    });

    xAxis.data.setAll(formatBarChartData(data));

    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      calculateTotals: true,
      extraMax: 0.05,
      extraMin: 0.2,
      renderer: am5xy.AxisRendererY.new(root, {
        strokeOpacity: 0
      })
    }));

    xRenderer.grid.template.set("forceHidden", true);
    xRenderer.labels.template.set("forceHidden", true);

    let yRenderer = yAxis.get("renderer");
    yRenderer.grid.template.set("forceHidden", true);
    yRenderer.labels.template.set("forceHidden", true);

    let legend = chart.children.push(am5.Legend.new(root, {
      centerX: am5.p0,
      // x: am5.p50,
      useDefaultMarker: true,
      clickTarget: "none",
      shadowOpacity:0,
    }));
    // Add series
    function makeSeries(entity, field, openField, total, borderTop, notStacked) {

      let tooltip = am5.Tooltip.new(root, {
        getFillFromSprite: false,
        getStrokeFromSprite: false,
        autoTextColor: false,
        getLabelFillFromSprite: true,
        keepTargetHover: true,
        background: am5.Rectangle.new(root, {
          fill: "transparent",
          fillOpacity: 1,
        }),
        pointerOrientation:"horizontal",
        // dx:30
      });

      let series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: entity.label,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: field,
        openValueYField: openField,
        categoryXField: "bucketMachineName",
        clustered: false,
        maskBullets: false,
      }));
      series.columns.template.setAll({
        width: am5.percent(100),
      });

      series.set("tooltip", tooltip);
      series.get("tooltip").label.set("interactive", true);
      buildStackedTooltip(series);
      let isSegment = !!new Segment().getSegmentObject(entity.label).label;

      if(borderTop){
        series.columns.template.adapters.add("cornerRadiusTL", function(radius, target) {
          return 5;
        });
        series.columns.template.adapters.add("cornerRadiusTR", function(radius, target) {
          return 5;
        });
      }
      if (total) {
        series.columns.template.adapters.add("cornerRadiusBR", function(radius, target) {
          return 5;
        });
        series.columns.template.adapters.add("cornerRadiusBL", function(radius, target) {
          return 5;
        });
        // Creating Labels
        buildStackChartTitle(root, series);
      }
      if (!notStacked) {
        let fillColor = isSegment ? new Segment().getSegmentObject(entity.label).legendColor : entity.legendColor
        series.set("fill", fillColor);
        series.columns.template.setAll({
          strokeOpacity: 0,
          shadowColor: am5.color(0x808080),
          shadowBlur: 10,
          shadowOffsetX: 4,
          shadowOffsetY: -1
        });
        addStackedChartLegend(chart, series, notStacked, legend);
        series.columns.template.setAll({
          minHeight:2
        });
      } else {
        series.set("fill", "rgb(100, 137, 234)");
        series.columns.template.setAll({
          strokeOpacity: 0,
          shadowColor: am5.color(0x808080),
          shadowBlur: 10,
          shadowOffsetX: 4,
          shadowOffsetY: 3
        });
      }
      series.set("stroke", "rgba(95,96,200,0)");
      series.data.setAll(formatBarChartData(data));
    }

    makeSeries("", "value", "open", true, true, true);

    chart.appear(1000, 100);

    let entities = props.entities;
    entities?.map((entity, index) => {
      makeSeries(entity, "value_"+entity.value, "open_"+entity.value, index === 0, index === entities.length - 1);
    })
    return () => {
      root.dispose();
    };
  }

  return <div ref={chartRef} id="chartdiv1" style={{ width: "100%", height: "100%" }}></div>;
});
export { TLCLandscapeBarChart };

TLCLandscapeBarChart.propTypes = {
  view: PropTypes.string.isRequired,
};