import React, { Component } from 'react';

import { ALL_WIDGETS, BUTTON_TYPE, BUTTON_VARIANT, CROSSWALK_FIELDS, DROPDOWN_TYPE, FILE_CENSUS_FIELDS, Formats, PERIOD_ONWARD, RAW_ITEMS, ROW_STATUS, SIZES, UPLOAD_FIELDS } from '../class/constants.js';
import { copyObjectValues, findOptionByKey, getSectionExists } from '../class/utils';
import { getTranslationFile } from '../class/utils.js';
import ToggleButton from '../components/sidePanelNew/ToggleButton.js';
import Button from '../newComponents/Button';
import DropDown from '../newComponents/DropDown';
import Link from './/Link';


const lang = getTranslationFile();
const PERIOD_ID = "data-period_id";
const $ = require('jquery');
const IS_PRIMARY = "is_primary";
const IS_OLD_ARCH = "is_old_arch";


/**
 * Link Definition
 * 
 * @author [Sarah Farjallah]
 */
class LinkDefinition extends Component {
    constructor(props) {
		super(props);
		this.state = {
            numberOfIterations: 0,
            linksRef:[],
            startPeriod: undefined,
            endPeriod: undefined,
            useOutSideLinks: false,
            isOldArch: false,
            readMatchingNewArch: true
        };

        this.addLink = this.addLink.bind(this);
        this.deleteLink = this.deleteLink.bind(this);
        this.getFiles = this.getFiles.bind(this);
        this.filterColumns = this.filterColumns.bind(this);
        this.isDefinedFile = this.isDefinedFile.bind(this);
        this.reload = this.reload.bind(this);
        this.index = "";
    }

	componentDidUpdate(prevProps) {
        var tempState = {};
        if (this.props.clientPeriods && this.props.clientPeriods.length > 0 &&  prevProps.clientPeriods !== this.props.clientPeriods) {
            tempState.clientPeriods = this.props.clientPeriods;
            var periodsEnd = copyObjectValues(this.props.clientPeriods);
            periodsEnd.unshift({value: PERIOD_ONWARD, label: PERIOD_ONWARD, "data-period_id":0});
            tempState.clientPeriodsEnd = periodsEnd;
            this.setState(tempState);
        } 
        if (this.props.sectionInfo  &&  prevProps.sectionInfo !== this.props.sectionInfo) {
            tempState.sectionInfo = this.props.sectionInfo;
            var fileTypes = this.getFiles(this.props.fileTypes, this.props.sectionInfo);
            this.setState({
                fileTypes: fileTypes,
                sectionInfo: this.props.sectionInfo
            });
        }
        if(this.props.userAllowedSections && !prevProps.userAllowedSections){
            this.setState({
                readMatchingNewArch:true
            })
        }
    }

    isDefinedFile(subType, fileType, sections) {
        if (sections) {
            for (var e in sections) {
                var currSectionInfo = this.props.getSectionStatus(sections[e]);
                if (currSectionInfo[fileType][subType].status === "true") {
                    return true;
                }
            }
            return false;
        }
    }

    getFiles(files, sectionInfo) {
        var output = [];
        for(var i in files) {
            if (this.isDefinedFile(files[i][RAW_ITEMS.FILE_SUBTYPE], files[i][RAW_ITEMS.SUBTYPE_NAME], sectionInfo)) {
                output.push({value:files[i][RAW_ITEMS.FILE_SUBTYPE], label:files[i][RAW_ITEMS.FILE_SUBTYPE_DISPLAY], 
                    [RAW_ITEMS.SUBTYPE_ID]:files[i][RAW_ITEMS.SUBTYPE_ID],[RAW_ITEMS.SUBTYPE_NAME]: files[i][RAW_ITEMS.SUBTYPE_NAME],
                    [RAW_ITEMS.TYPE_NAME]: files[i][RAW_ITEMS.SUBTYPE_NAME],
                    [RAW_ITEMS.SUBTYPE_DISPLAY_NAME]: files[i][RAW_ITEMS.SUBTYPE_DISPLAY_NAME],[FILE_CENSUS_FIELDS.SUB_CATEGORY_MUDULE]: files[i][FILE_CENSUS_FIELDS.SUB_CATEGORY_MUDULE]
                 });
            }
 
        }
        return output;
    }

    filterColumns(fileType) {
        var data = this.props.dataColumns.filter(e=>e[RAW_ITEMS.SUBTYPE_NAME] === fileType);
        let fields = [];  
        data.map(function(item) { 
            if(item[RAW_ITEMS.FIELD_DATA_TYPE].toUpperCase() === Formats.STRING) {
                fields.push({ 
                    label: item[RAW_ITEMS.FIELD_NAME],
                    value: item[RAW_ITEMS.FIELD_NAME],
                    file_type: item[RAW_ITEMS.SUBTYPE_NAME],
                    raw_file_subtype_id: item[RAW_ITEMS.SUBTYPE_ID],
                    field_data_type:item[RAW_ITEMS.FIELD_DATA_TYPE]
                });
            }
        });
        return fields;
    }
    
    deleteLink(index, callback) {
        if(this.state.linksRef[index].linkObj.raw_crosswalk_field_id !== "") {
            this.index = index;
            this.props.setDeleteDialogOpen(true);
        } else {
            callback();
        }
    }

    hideCrosswalkLink = () => {
        let index = this.index;
        this.state.linksRef[index].setState({[ROW_STATUS.FIELD]: ROW_STATUS.VALUES.DELETED});
        this.state.linksRef.splice(index, 1);
        this.reload();
        this.props.setDeleteDialogOpen(false);
    }
  

    addLink() {
        this.setState({
            numberOfIterations: this.state.numberOfIterations + 1,
        })
    }

	componentDidMount() {
        var tempState = {};
        if (this.props.clientPeriods && this.props.clientPeriods.length > 0) {
            tempState.clientPeriods = this.props.clientPeriods;
            var periodsEnd = copyObjectValues(this.props.clientPeriods);
            periodsEnd.unshift({value: PERIOD_ONWARD, label: PERIOD_ONWARD, "data-period_id":0});
            tempState.clientPeriodsEnd = periodsEnd;
            this.setState(tempState);
        } 
        if (this.props.sectionInfo) {
            tempState.sectionInfo = this.props.sectionInfo;
            var fileTypes = this.getFiles(this.props.fileTypes, this.props.sectionInfo);
            this.setState({
                fileTypes: fileTypes,
                sectionInfo: this.props.sectionInfo
            });
        }
    }
    
    setLinks(data) {
        let _this = this;
        this.setState({
            linksData: data,
            numberOfIterations: data.length
        }, ()=>{
            _this.setLinksData();
        })
    }

    /**
     * setting the data of each individual link
     */
    setLinksData() {
        let _this = this;
        var startPeriod = undefined;
        var endPeriod = undefined;

        this.state.linksRef.forEach((ref, index)=>{
            ref.setData(_this.state.linksData[index]);
            startPeriod = _this.state.linksData[index][CROSSWALK_FIELDS.START_PERIOD];
            endPeriod = _this.state.linksData[index][CROSSWALK_FIELDS.END_PERIOD] ;
    
        });
        
        if (startPeriod) {
            var startPeriodObj = this.props.clientPeriods.filter(el=>el[PERIOD_ID] === startPeriod)[0];
            var endPeriodObj = endPeriod === undefined ? {value: PERIOD_ONWARD, label: PERIOD_ONWARD, "data-period_id":0}: this.props.clientPeriods.filter(el=>el[PERIOD_ID] === endPeriod)[0];
            endPeriod = endPeriod ? endPeriod : PERIOD_ONWARD;
            this.setState({
                startPeriod: startPeriod,
                startPeriodObject: startPeriodObj,
                endPeriod: endPeriod,
                endPeriodObject: endPeriodObj
            })
        }
    }

    setPeriod(e, isSource) {
        if (e !== null){
            let tempState = {};
            if(isSource) {
                tempState.startPeriod = e.value;
                tempState.startPeriodObject = e;
                $("#select-link-start-period").find('div:first').removeClass("uk-form-danger");
            } else {
                tempState.endPeriod = e.value;
                tempState.endPeriodObject = e;
                $("#select-link-end-period").find('div:first').removeClass("uk-form-danger");
            }
            this.setState(tempState);
        }
    }

    getLinksData() {
        let data = [];
        let _this = this;
        this.state.linksRef.forEach(ref=>{
            let tempObj = copyObjectValues(ref.linkObj);
            tempObj[ROW_STATUS.FIELD] = ref.state[ROW_STATUS.FIELD];
            tempObj[CROSSWALK_FIELDS.START_PERIOD] = _this.state.startPeriod;
            tempObj[CROSSWALK_FIELDS.END_PERIOD] = _this.state.endPeriod;
            tempObj[CROSSWALK_FIELDS.START_PERIOD_ID] = _this.state.startPeriodObject ?_this.state.startPeriodObject[PERIOD_ID] : tempObj[CROSSWALK_FIELDS.START_PERIOD_ID];
            tempObj[CROSSWALK_FIELDS.END_PERIOD_ID] = _this.state.endPeriodObject ? _this.state.endPeriodObject[PERIOD_ID] : tempObj[CROSSWALK_FIELDS.END_PERIOD_ID];
            tempObj[CROSSWALK_FIELDS.CROSSWALK_SUBTYPE] = _this.props.crosswalkFile[UPLOAD_FIELDS.FILE_SUBTYPE];
            tempObj[CROSSWALK_FIELDS.CROSSWALK_FILE_ID] = _this.props.crosswalkFile[UPLOAD_FIELDS.RAW_FILE_ID_FIELD];
            tempObj[IS_PRIMARY] = tempObj[IS_PRIMARY];
            tempObj[IS_OLD_ARCH] = false;

            
            delete tempObj[CROSSWALK_FIELDS.SOURCE_COLUMNS];
            delete tempObj[CROSSWALK_FIELDS.TARGET_COLUMNS];
            data.push(tempObj);
        });

        return data;
    }

    handleLinkRef(ref) {
        if(ref != null) {
            this.state.linksRef[ref.props.index] = ref;
        }
    }

    checkLinks() {
        var links = this.state.linksRef.filter(el=>el.state[ROW_STATUS.FIELD] !== ROW_STATUS.VALUES.DELETED);
        if (this.state.numberOfIterations > 0) {
            if (this.state.numberOfIterations > this.state.linksRef.length) {
                return 1;
            } else {
                return links.length
            }
        } else{
            return 0;
        }        
    }

    reload(){
        this.forceUpdate();
    }

    displayLinks() {
        var iterations = this.state.numberOfIterations;
        var temp = [];
        var obj = this;
        for (var i = 0; i < iterations; i++) {
            temp.push(<Link key={"link_"+i} deleteLink={this.deleteLink} index={i} fileTypes={obj.state.fileTypes} 
                        dataColumns={obj.props.dataColumns} ref={r => this.handleLinkRef(r)}
                        linkObj={this.state.linksRef[i]  ? this.state.linksRef[i] :{}}
                        showMatchingEntityModal={this.props.showMatchingEntityModal} setMatchingEntityData={this.props.setMatchingEntityData}
                        updateLinkObject={this.updateLinkObject} crosswalkSubcategory={this.props.crosswalkSubcategory}
                        crosswalkFile={this.props.crosswalkFile} crosswalkColumns={this.props.crosswalkColumns}
                        selectedStartPeriod={this.state.startPeriod} selectedEndPeriod={this.state.endPeriod} reload={this.reload}
                        clientPeriods={this.state.clientPeriods} startPeriodObject={this.state.startPeriodObject} endPeriodObject={this.state.endPeriodObject}
                        readMatchingNewArch={this.state.readMatchingNewArch} userAllowedSections = {this.props.userAllowedSections} />);
        }
        return temp; 
    }



	render() {
		var links = this.displayLinks();
       
		return(
            <div className="define-link-container">
                <div className="mapping-header uk-flex-between">
                    <Button  
                        label={lang.UPLOAD.add_link}
                        variant={BUTTON_VARIANT.TERTIARY}
                        size={SIZES.DEFAULT}
                        type={BUTTON_TYPE.DEFAULT}
                        leftIcon={<i className="far fa-plus-circle fa-lg uk-margin-small-right" />}
                        onBtnClick={()=>{this.addLink()}} 
                    />
                    <div className="uk-flex-inline uk-flex-middle">
                        <label htmlFor="select-uploadPeriodStart" className="fs-14">{lang.UPLOAD.start_period}<sup className="red">*</sup></label>
                        <DropDown
                            id="select-link-start-period"
                            className="width-150 uk-margin-default-left input__dropdown"
                            name="uploadedFilePeriodStart"
                            placeholder="Select start period"
                            value={findOptionByKey(this.state.clientPeriods, this.state.startPeriod)}
                            options={this.state.clientPeriods}
                            onChange={(e)=>{this.setPeriod(e, true)}}
                            type={DROPDOWN_TYPE.INPUT}
                        />
                        <label htmlFor="select-uploadPeriodEnd" className="fs-14 uk-margin-default-right uk-margin-medium-left">{lang.UPLOAD.end_period}<sup className="red">*</sup></label>
                        <DropDown
                            id="select-link-end-period"
                            className="width-150 uk-margin-medium-right input__dropdown"
                            name="uploadedFilePeriodStart"
                            placeholder="Select end period"
                            value={findOptionByKey(this.state.clientPeriodsEnd, this.state.endPeriod)}
                            options={this.state.clientPeriodsEnd}
                            onChange={(e)=>{this.setPeriod(e, false)}}
                            type={DROPDOWN_TYPE.INPUT}
                        />
                    </div>
                </div>
                <div className={links.length === 0  || this.checkLinks() === 0  ?  "uk-margin-xlarge-top uk-text-large uk-text-center" : ""} >
                    {links.length > 0 && this.checkLinks() > 0 ?
                        "" : lang.UPLOAD.not_defined_crosswalk
                    }
                </div>
                <div className={links.length === 0 || this.checkLinks() === 0? "uk-text-large uk-text-center" : ""} >
                    {links.length > 0 && this.checkLinks() > 0?
                        "" : lang.UPLOAD.add_crosswalk
                    }
                </div>
                {links.length > 0 && this.checkLinks() > 0 ?
                    <div>
                        <div className="mapping-header">
                            <div className="sourceTarget w100 height100">
                                <div className="source-cell uk-display-inline-flex uk-flex-middle uk-flex-center uk-text-bold define-border height100">{lang.define_crosswalk_table.source}<i className="fal fa-info-circle uk-margin-small-left" uk-tooltip={lang.UPLOAD.source_message}/></div>
                                <div className="target-cell uk-display-inline-flex uk-flex-middle uk-flex-center uk-text-bold height100">{lang.define_crosswalk_table.target}<i className="fal fa-info-circle uk-margin-small-left" uk-tooltip={lang.UPLOAD.target_message}/></div>
                            </div>
                        </div>
                        <div className="uk-display-block mapping-header">
                            <table className="w100 height100 define-crosswalk">
                                <thead>
                                    <tr className="sourceTarget">
                                        <td className="uk-text-bold sourceTargetTr uk-margin-small-top w14">{lang.define_crosswalk_table.subcategory}<i className="fal fa-info-circle uk-margin-small-top uk-margin-xsmall-left" uk-tooltip={lang.UPLOAD.source_subcategory}/></td>
                                        <td className="uk-text-bold sourceTargetTr uk-margin-small-top w14">{lang.define_crosswalk_table.subcategory_column}<i className="fal fa-info-circle uk-margin-small-top uk-margin-xsmall-left" uk-tooltip={lang.UPLOAD.source_column}/></td>
                                        <td className="uk-text-bold sourceTargetTr uk-margin-small-top w14">{lang.define_crosswalk_table.crosswalk_column}<i className="fal fa-info-circle uk-margin-small-top uk-margin-xsmall-left" uk-tooltip={lang.UPLOAD.source_crosswalk}/></td>
                                        <td className="uk-text-bold sourceTargetTr uk-margin-small-top w6">{lang.define_crosswalk_table.entity_matching}<i className="fal fa-info-circle uk-margin-small-top uk-margin-xsmall-left" uk-tooltip={lang.UPLOAD.source_entity_matching}/></td>
                                        <td className="uk-text-bold sourceTargetTr uk-margin-small-top w14">{lang.define_crosswalk_table.subcategory}<i className="fal fa-info-circle uk-margin-small-top uk-margin-xsmall-left" uk-tooltip={lang.UPLOAD.target_subcategory}/></td>
                                        <td className="uk-text-bold sourceTargetTr uk-margin-small-top w14">{lang.define_crosswalk_table.subcategory_column}<i className="fal fa-info-circle uk-margin-small-top uk-margin-xsmall-left" uk-tooltip={lang.UPLOAD.target_column}/></td>
                                        <td className="uk-text-bold sourceTargetTr uk-margin-small-top w14">{lang.define_crosswalk_table.crosswalk_column}<i className="fal fa-info-circle uk-margin-small-top uk-margin-xsmall-left" uk-tooltip={lang.UPLOAD.target_crosswalk}/></td>
                                        <td className="uk-text-bold sourceTargetTr uk-margin-small-top w6">{lang.define_crosswalk_table.entity_matching}<i className="fal fa-info-circle uk-margin-small-top uk-margin-xsmall-left" uk-tooltip={lang.UPLOAD.target_entity_matching}/></td>
                                        <td className="uk-text-bold sourceTargetTr uk-margin-small-top w4">{lang.define_crosswalk_table.action}</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {links}
                                </tbody>
                            </table>
                        </div>
                    </div>
                :""}
            </div>
		);

	}

}

export default LinkDefinition;