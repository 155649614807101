import {API_URL, CLIENT_ID_STORAGE, LOG_LEVEL, LOG_OBJ_KEYS, SPAN, USER_EMAIL_STORAGE, ACTION_ID_STORAGE} from './constants';
import { toggleLoader, setLocalStorageValueByParameter, getLocalStorageValueByParameter } from './common';
import { alertAndLogError } from './jqueries';
import { copyObjectValues, tryParse } from './utils';

import { getItemFromStore } from './reduxStoreUtils';
import cookie from "react-cookies";
import { Span } from '../Tracing/Span';
import {SpanStatus, SpanStatusCode} from "@opentelemetry/api";

const baseUrl = process.env.REACT_APP_BASE_URL;

const $ = require('jquery');

const FETCH_METHOD = {
    POST: "POST",
    GET: "GET"
}

const FETCHAPI_PARAMS = {
    funcName: "funcName",
    requestType: "requestType",
    showLoader: "showLoader",
    sidePanelLoader:"sidePanelLoader",
    path: "path",
    method: "method",
    query: "query",
    onThenCallback: "onThenCallback",
    onErrorCallback: "onErrorCallback",
    useBlob: "useBlob",
    onCompleteHttpStatusCodeCallback: "onCompleteHttpStatusCodeCallback",
    clientId: "clientId",
    onCompleteCallback: "onCompleteCallback",
    email: "email",
    machine_name: "machine_name",
    profitFormat: "profit_format",
    showCustomizedLoader: "showCustomizedLoader",
    url: "url",    //for fetch() and ajax()
    url_options: "url_options",     //for fetch()
    data: "data",   //for ajax()
    useSession:"useSession",
    log_time: "log_time",
    credentials: "credentials",
    useStore:"useStore",
    dataKey: "dataKey",
    saveIntoStore: "saveIntoStore",
    scenarioId:"scenarioId",
    signal:"signal",
    logout:"logout",
    vector:"vector",
    periods:"periods",
    screenName:"screenName",
    requestDescription:"description",
    requestTypeValues: {
        data: "data",
        config: "config"
    },
    credentialsValues: {
        include: "include"
    },
    useIndexedDB: "useIndexedDB",
    index: "index",

}
const COORDINATES_REQUEST_TRACKER = "coordinates_request_tracker";

/**
 * This function is a utility to access nested properties in an object using a dot-separated string path.
 * @param {*} path 
 * @param {*} object 
 * @returns 
 */
const accessNestedProperty = (path, object) => {
    return path?.split('.')?.reduce((o, i) => o[i], object)
}

function formatPeriodsAttribute(periods) {
    if(!periods) {
        return "";
    }
    if(typeof periods === 'object'){
        periods = periods.join();
    }
    periods = periods.replaceAll("'","").replaceAll("\"","");
    return periods;
}

function addAdditionalParams (options, span) {
    let periods = options[FETCHAPI_PARAMS.periods];
    let vector =  options[FETCHAPI_PARAMS.vector];
    let screenName = options[FETCHAPI_PARAMS.screenName];
    let requestDescription = options[FETCHAPI_PARAMS.requestDescription];
    
    if(periods?.length){
        periods = formatPeriodsAttribute(periods);
        span.setAttribute(FETCHAPI_PARAMS.periods, periods);
    }

    if(vector){
        span.setAttribute(FETCHAPI_PARAMS.vector, vector);
    }

    if(screenName){
        span.setAttribute(FETCHAPI_PARAMS.screenName, screenName);
    }

    if(requestDescription){
        span.setAttribute(FETCHAPI_PARAMS.requestDescription, requestDescription);
    }
}

function prepareForRequest(options, _this, span) {

    var funcName = options[FETCHAPI_PARAMS.funcName];
    var path = options[FETCHAPI_PARAMS.path];
    var method = options[FETCHAPI_PARAMS.method] || FETCH_METHOD.POST;
    var query = options[FETCHAPI_PARAMS.query];
    var signal = options[FETCHAPI_PARAMS.signal];
    //default onThen and onComplete callbacks if there was not any
    if(!options[FETCHAPI_PARAMS.onThenCallback]){
        options[FETCHAPI_PARAMS.onThenCallback] = ()=>{};
    }
    if(!options[FETCHAPI_PARAMS.onCompleteCallback]) {
        options[FETCHAPI_PARAMS.onCompleteCallback] = ()=>{};
    }

    //if email, machine name or profitFormat were not set in the params, trying to read them from the state if the function has been bound to the execution context
    let email = options[FETCHAPI_PARAMS.email];
    let machineName = options[FETCHAPI_PARAMS.machine_name];
    let profitFormat = options[FETCHAPI_PARAMS.profitFormat];
    if(!email || email === "") {
        email = _this && _this.state.user ? _this.state.user.email:"";
    }
    if(!machineName || machineName === "") {
        machineName = _this ? _this.state.machineName ? _this.state.machineName: _this.state.machine_name ? _this.state.machine_name : "" : "";
    }
    if(!profitFormat || profitFormat === "") {
        profitFormat = _this ? _this.state.profitFormat : "";     //most probable state to contain the profitFormat value
    }

    //query -- send pi_action and unique_id in all the requests
    var piAction = window._pi_getTrackingParam(LOG_OBJ_KEYS.PI_ACTION);
    var uniqueId = window._pi_getTrackingParam(LOG_OBJ_KEYS.UNIQUE_ID);
    if (typeof query !== "string") {
        if (method === FETCH_METHOD.POST) {
            query[LOG_OBJ_KEYS.PI_ACTION] = piAction;
            query[LOG_OBJ_KEYS.UNIQUE_ID] = uniqueId;
        } else {
            query += "&" + LOG_OBJ_KEYS.PI_ACTION + "=" + piAction +
                "&" + LOG_OBJ_KEYS.UNIQUE_ID + "=" + uniqueId;
        }
    }
    options[FETCHAPI_PARAMS.query] = query;

    //url, url_options and data
    var url = `${baseUrl}${path}?${query}`;
    let headers = {
        'X-Trace-Id': span.getTraceId(),
        'X-Span-Id': span.getSpanId(),
        'Api-Url-Path': funcName,
    };
    var urlOptions = {mode:'cors', credentials:'include', method: method, headers:headers};
    if(signal) {
        urlOptions[FETCHAPI_PARAMS.signal] = signal;
    }
    var data = null;
    if(method === FETCH_METHOD.POST) {
        url = `${baseUrl}${path}`;
        if (typeof query !== "string") {
            urlOptions.body = JSON.stringify(query);
            data = JSON.stringify(query);
        }else {
            urlOptions.body = query;
            data = query;
        }
    }
    options[FETCHAPI_PARAMS.url] = url; //used for fetch() and ajax()
    options[FETCHAPI_PARAMS.url_options] = urlOptions;  //used for fetch()
    options[FETCHAPI_PARAMS.data] = data;   //used for ajax()

    if(window._pi_isCurrentlyTracking()) {
        window._pi_startTrackingSubAction(funcName, email, machineName, profitFormat);
    } else {
        options[FETCHAPI_PARAMS.funcName] = "api_request";
        window._pi_initialiseTracking("", email, machineName, profitFormat, true);  //closing call is in onComplete... for orphan requests
    }
    options[FETCHAPI_PARAMS.clientId] = getLocalStorageValueByParameter(CLIENT_ID_STORAGE);
    return options;
}

/**
 * This function starts a log or a sub_action before send, decrements counter on then(), and checks for logging on complete
 * options.funcName -- use either UI_ACTION if only the function is responsible for this action, or parent function name if it's a sub_action
 * options.allowedtoEndTracking -- pass as true for API calls that are the last ones in the process of an action
 * options.showLoader 
 * options.path 
 * options.method 
 * options.query 
 * options.onThenCallback -- function to be called on then()
 * options.onCompleteCallback -- function to be called on complete
 */
function fetchAPI(options, comp) {
    const _this = comp || this;
    var span = new Span((options[FETCHAPI_PARAMS.method] || FETCH_METHOD.POST) + options[FETCHAPI_PARAMS.path]);
    span.setAttribute("funcName", options[FETCHAPI_PARAMS.funcName]);
    span.setAttribute(FETCHAPI_PARAMS.clientId, getLocalStorageValueByParameter(CLIENT_ID_STORAGE));
    span.setAttribute(FETCHAPI_PARAMS.email, getLocalStorageValueByParameter(USER_EMAIL_STORAGE));
    span.setAttribute("parameters", JSON.stringify(options[FETCHAPI_PARAMS.query]));
    span.setAttribute("actionId", getLocalStorageValueByParameter(ACTION_ID_STORAGE));
    span.setEvent(JSON.stringify(options[FETCHAPI_PARAMS.query]));
    addAdditionalParams(options, span);
    options = prepareForRequest(options, this, span);
    var funcName = options[FETCHAPI_PARAMS.funcName];
    var allowedtoEndTracking = options[FETCHAPI_PARAMS.requestType] === FETCHAPI_PARAMS.requestTypeValues.data;
    var showLoader = options[FETCHAPI_PARAMS.showLoader];
    var showCustomizedLoader = options[FETCHAPI_PARAMS.showCustomizedLoader] ?  options[FETCHAPI_PARAMS.showCustomizedLoader] : false ;
    var onThenCallback = options[FETCHAPI_PARAMS.onThenCallback];
    var onCompleteStatusCallback = options[FETCHAPI_PARAMS.onCompleteHttpStatusCodeCallback];
    var onErrorCallback = options[FETCHAPI_PARAMS.onErrorCallback];
    var onCompleteCallback = options[FETCHAPI_PARAMS.onCompleteCallback];
    var useIndexedDB = options[FETCHAPI_PARAMS.useIndexedDB];

    if (options[FETCHAPI_PARAMS.log_time] !== false) {
        setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
    }
    let query = options[FETCHAPI_PARAMS.query];
    let dataFromSession = getItemFromSession(query);
    let dataFromStore = getItemFromStore(options[FETCHAPI_PARAMS.dataKey], _this, options[FETCHAPI_PARAMS.scenarioId]);
    
    if(options[FETCHAPI_PARAMS.useStore] && dataFromStore){ //for redux
        span.setEvent("Retreiving data from store");
        let data = dataFromStore;
        onThenCallback(data);
        onCompleteCallback(data);
        span.setStatus(SpanStatusCode.OK);
        span.endSpan();
        return ;
    }
    
    if(options[FETCHAPI_PARAMS.useSession] && dataFromSession){
        span.setEvent("Retreiving data from session");
        let data = dataFromSession;
        onThenCallback(data);
        onCompleteCallback(data);
        span.setStatus(SpanStatusCode.OK);
        span.endSpan();
        return ;
    }

    if(showLoader) {
        toggleLoader(true, funcName,false,showCustomizedLoader);
    }

    span.setEvent("Retreiving data from backend using fetch");
    return fetch(options[FETCHAPI_PARAMS.url], options[FETCHAPI_PARAMS.url_options])
        .then((response)=>{    
            if(options[FETCHAPI_PARAMS.clientId] && (options[FETCHAPI_PARAMS.clientId] !== getLocalStorageValueByParameter(CLIENT_ID_STORAGE))){
                return; // to fix logout on switch clients, if a request from the old client is fetched after switching client
            }
            if(response.status === 403 && ((!!_this && !options.isDashBoards) || (options.logout))) {
                span.setEvent("logging out due to response status = 403");
                span.setStatus(SpanStatusCode.ERROR, response.json());
                span.endSpan();
                if (!!_this) {
                    _this.logout();
                } else if (options.logout){
                    options.logout();
                }   
            }
            if(options[FETCHAPI_PARAMS.useBlob]){// for export csv
                return response.blob();
            }
            if(onCompleteStatusCallback) {
                onCompleteStatusCallback(response.status);
            }
            return response.json()})
        .then((data)=>{
            span.setEvent("Request succeeded and returned data successfully");
            
            if(options[FETCHAPI_PARAMS.clientId] && (options[FETCHAPI_PARAMS.clientId] !== getLocalStorageValueByParameter(CLIENT_ID_STORAGE))){
                return;// to fix logout on switch clients, if a request from the old client is fetched after switching client
            }
            var logDates = data.logDates || {};
            window._pi_stopTracking(funcName, logDates);
            if(options[FETCHAPI_PARAMS.useSession]){
                span.setEvent("Saving data in session");
                setItemFromSession(query, data);
            }
            span.setStatus(SpanStatusCode.OK);
            span.endSpan();
            onThenCallback(data, useIndexedDB && options);
            return data;
        }).catch((error)=>{
            if (options[FETCHAPI_PARAMS.dataKey] ==="userSettings" && cookie.load('fromSignIn')) {
                span.setStatus(SpanStatusCode.ERROR, error);
                if (options.logout) {
                    span.setEvent("logging out due to clicking on back when signing in");
                    span.endSpan();
                    options.logout();
                }
            }
            if(onErrorCallback && typeof onErrorCallback === "function") {
                onErrorCallback();
            }
            span.endSpan();
            alertAndLogError(error);
            window._pi_stopTracking(funcName);
        })
        .then(()=>{
            if(options[FETCHAPI_PARAMS.clientId] && (options[FETCHAPI_PARAMS.clientId] !== getLocalStorageValueByParameter(CLIENT_ID_STORAGE))){
                return;// to fix logout on switch clients, if a request from the old client is fetched after switching client
            }
            window._pi_checkActionFinished(allowedtoEndTracking);
            onCompleteCallback();
            if (showLoader) {
                toggleLoader(false, funcName,false,showCustomizedLoader);
            }
        });
}

function fetchAPIAjax(options, async=true) {
    var span = new Span((options[FETCHAPI_PARAMS.method] || FETCH_METHOD.POST) + options[FETCHAPI_PARAMS.path]);
    span.setAttribute("funcName", options[FETCHAPI_PARAMS.funcName]);
    span.setAttribute("actionId", getLocalStorageValueByParameter(ACTION_ID_STORAGE));
    options = prepareForRequest(options, this, span);
    addAdditionalParams(options, span);
    var funcName = options[FETCHAPI_PARAMS.funcName];
    var allowedtoEndTracking = options[FETCHAPI_PARAMS.requestType] === FETCHAPI_PARAMS.requestTypeValues.data;
    var showLoader = options[FETCHAPI_PARAMS.showLoader];
    var onThenCallback = options[FETCHAPI_PARAMS.onThenCallback];
    var onCompleteCallback = options[FETCHAPI_PARAMS.onCompleteCallback];
    var onErrorCallback = options[FETCHAPI_PARAMS.onErrorCallback];

    if(showLoader) {
        toggleLoader(true, funcName);
    }
    span.setEvent("Retreiving data from backend using ajax");
    setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
    var ajaxOptions = {
        headers: options[FETCHAPI_PARAMS.url_options].headers,
        url: options[FETCHAPI_PARAMS.url],
        async: async,
        crossDomain:true,
        type: options[FETCHAPI_PARAMS.method] || FETCH_METHOD.POST,
        xhrFields: { withCredentials: true },
        dataType: 'json',
        data:options[FETCHAPI_PARAMS.data],
        success: function(data) {
            span.setEvent("Request succeeded and returned data successfully");
            if(options[FETCHAPI_PARAMS.clientId] && (options[FETCHAPI_PARAMS.clientId] !== getLocalStorageValueByParameter(CLIENT_ID_STORAGE))){
                return;// to fix logout on switch clients, if a request from the old client is fetched after switching client
            }
            var logDates = data.logDates || {};
            window._pi_stopTracking(funcName, logDates);
            span.setStatus(SpanStatusCode.OK);
            span.endSpan();
            onThenCallback(data);
        },
        error:function(error){
            alertAndLogError(error);
            window._pi_stopTracking(funcName);
            span.setStatus(SpanStatusCode.ERROR, error);
            span.endSpan();
            if(onErrorCallback && typeof onErrorCallback === "function"){
                onErrorCallback();
            }
        },
        complete: function(req) {
            if(options[FETCHAPI_PARAMS.clientId] && (options[FETCHAPI_PARAMS.clientId] !== getLocalStorageValueByParameter(CLIENT_ID_STORAGE))){
                return;// to fix logout on switch clients, if a request from the old client is fetched after switching client
            }
            span.setEvent("Request completed");
            span.setStatus(SpanStatusCode.OK);
            span.endSpan();
            window._pi_checkActionFinished(allowedtoEndTracking);
            onCompleteCallback();
            if(showLoader) {
                toggleLoader(false, funcName);
            }
        }
    }
    if (typeof options[FETCHAPI_PARAMS.data] === "string") {
        delete ajaxOptions.dataType;
    }
    $.ajax(ajaxOptions);
}

function logAPI(logText, logLevel, action) {
    const _this = this;
    var query = {
        logText: logText,
        logLevel: logLevel,
        action: action || "logAPI"
    }
    fetch(`${baseUrl}${API_URL.CUSTOM_LOG}`, {mode:'cors', credentials:'include', method: FETCH_METHOD.POST, body: JSON.stringify(query)})
        .then((response)=>{    
            if(response.status === 403 && !!_this) {
                this.logout();
            }
            return response.json()})
        .then((data)=>{

            // localLog("logged API: " + logText);

        }).catch((error)=>{
            alertAndLogError(error);
        });
}

function logUITracking(logText) {
    logAPI(logText, LOG_LEVEL.INFO, "logUITracking");
}

function logUIError(errorMessage) {
    logAPI(errorMessage, LOG_LEVEL.WARNING);
}

function logUIInfo(message) {
    logAPI(message, LOG_LEVEL.INFO);
}

/**
 * this function returns data from UI session storage
 * @param {*} query 
 */
function getItemFromSession(query) {
    try{
        query= prepareQueryForSession(query);
        let dataFromSession = sessionStorage.getItem(typeof query === "string" ? query : JSON.stringify(query));
        return tryParse(dataFromSession);
    } catch(err){
        console.log("error"+err);
        return null;
    }
}

/**
 * this function save data to UI session storage
 * @param {*} query 
 */
function setItemFromSession(query, data){
    query= prepareQueryForSession(query);
    try {
        sessionStorage.setItem(typeof query === "string" ? query : JSON.stringify(query), JSON.stringify(data)); 
    } catch(err){
        console.log("error="+err);
    }
}

/**
 * this function takes an action and tries to parse all the keys in sessionStorage.
 * If parsable (POST requests), it deletes all those with action equal to the param passed.
 * Otherwise, it deletes all the ones that contain this action as substring (GET requests)
 * @param {*} action 
 */
function deleteItemFromSession(action) {
    let allKeys = Object.keys(sessionStorage);

    for(let keyIndex in allKeys) {
        let key = allKeys[keyIndex];
        let parsedKey = tryParse(key);
        if(parsedKey !== undefined) {
            if(parsedKey["action"] === action) {
                sessionStorage.removeItem(key);
            }
        } else {
            //less accurate, but for GET methods
            if(key.includes(action)) {
                sessionStorage.removeItem(key);
            }
        }
    }
}

function getDataFromSession(action) {
    let allKeys = Object.keys(sessionStorage);

    for(let keyIndex in allKeys) {
      let key = allKeys[keyIndex];
      let parsedKey = tryParse(key);
      if(parsedKey !== undefined) {
          if(parsedKey["action"] === action) {
            return sessionStorage.getItem(key);
          }
      }
    }
}

/**
 * this function removes unique_id from the request query
 * @param {*} query 
 */
function prepareQueryForSession(query) {
    let queryCopy = copyObjectValues(query);
    if(typeof query === "object"){
        delete queryCopy[LOG_OBJ_KEYS.UNIQUE_ID];
        return queryCopy;
    } else if(typeof query === "string"){
        var splitArray = query.split("&");
        splitArray = splitArray.filter(string => !string.includes(LOG_OBJ_KEYS.UNIQUE_ID));
        splitArray = splitArray.join("&");
        return splitArray;
    }

}

/**
 * function tracks requests sent related to map coordinates so they are not fetched more than once when screen is rerendering 
 * by saving their indexes in localStorage and filtering them out on successful requests when removeRequest parameter is sent true
 * @param {*} options 
 * @param {*} removeRequest 
 */
function trackCoordinatesRequest(options, removeRequest) {
    let coordinatesRequestracker= [];
    if (options[FETCHAPI_PARAMS.useIndexedDB] && removeRequest) {
        coordinatesRequestracker = tryParse(getLocalStorageValueByParameter(COORDINATES_REQUEST_TRACKER)) || [];
        coordinatesRequestracker = coordinatesRequestracker.filter(e=>e!== options[FETCHAPI_PARAMS.index])
        setLocalStorageValueByParameter(COORDINATES_REQUEST_TRACKER, JSON.stringify(coordinatesRequestracker));
    } else if(options[FETCHAPI_PARAMS.useIndexedDB]) {
        coordinatesRequestracker = tryParse(getLocalStorageValueByParameter(COORDINATES_REQUEST_TRACKER)) || [];
        coordinatesRequestracker.push(options[FETCHAPI_PARAMS.index]);
        setLocalStorageValueByParameter(COORDINATES_REQUEST_TRACKER, JSON.stringify(coordinatesRequestracker));
    }
}

export {fetchAPI, fetchAPIAjax, logAPI, logUITracking, logUIError, FETCH_METHOD, FETCHAPI_PARAMS, deleteItemFromSession, getDataFromSession,logUIInfo,formatPeriodsAttribute,accessNestedProperty, COORDINATES_REQUEST_TRACKER, trackCoordinatesRequest}