import React, { Component } from 'react';
import { getTranslationFile, copyObjectValues, findOptionByKey, partialCompare, deepCompareObjects } from '../../class/utils';
import { INFO_MESSAGE, VECTOR_MAPPING, UPLOAD_SECTIONS, RAW_ITEMS, BUTTON_VARIANT, SIZES, BUTTON_TYPE, DROPDOWN_TYPE } from '../../class/constants';
import Select from 'react-select';
import { addRowNumberToOptions, addIndexNumberToOptions } from '../../class/jqueries';
import ConcatenatedColumn from './ConcatenateColumn';
import { arrangeFileNames } from '../filter/FilterHelperFunctions';
import { convertPxToViewport } from '../../class/formatting';
import Input from '../../newComponents/Input';
import Button from '../../newComponents/Button';
import DropDown from '../../newComponents/DropDown';
import RadioButton from '../../newComponents/RadioButton';

const $ = require('jquery');
const MESSAGES = getTranslationFile();

class VectorIdentifier extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numberOfCols: 1,
            numberOfColsFalse:1,
            manualFalseDisabled: false,
            selectFalseDisabled: true,
           // isSameAsNumber: false,    
        };
        
        this.addColumn = this.addColumn.bind(this);
        this.onChangeOption = this.onChangeOption.bind(this);
        this.setSameAsKey = this.setSameAsKey.bind(this);
        this.deleteValueRow = this.deleteValueRow.bind(this);
        this.fetchFields = this.fetchFields.bind(this);
        
        this.subtypeIdAttrName = VECTOR_MAPPING.ATTR[(this.props.identifier+"_SUBTYPE_ID").toUpperCase()];
        this.valueAttrName = VECTOR_MAPPING.ATTR[(this.props.identifier+"_VALUE").toUpperCase()];
        this.trueCondition = [];
        this.falseCondition = [];
        this.fieldsValue = {};
    }

    addColumn(condition, e) {
        var obj = this;
        if (condition !== undefined) {
            if (condition) {
                if(this.state["selectTrueDisabled_"+$(e.currentTarget)[0].id]) {
                    return;
                }
            } else {
                if (this.state.selectFalseDisabled) {
                    return;
                }
            }
        }
        
        if (condition === undefined) {
            this.setState({
                numberOfCols: this.state.numberOfCols + 3
            }, function(){
                var connectorSelectOptions = addRowNumberToOptions(this.props.connectors, this.state.numberOfCols - 2);
                if (this.fieldsValue[this.valueAttrName] && this.fieldsValue[this.valueAttrName].length > 0) {
                    obj.onChangeOption(findOptionByKey(connectorSelectOptions, this.fieldsValue[this.valueAttrName] && this.fieldsValue[this.valueAttrName][this.state.numberOfCols-1] && this.fieldsValue[this.valueAttrName][this.state.numberOfCols-1].value ? this.fieldsValue[this.valueAttrName][this.state.numberOfCols-1].value : this.fieldsValue[this.valueAttrName][this.state.numberOfCols-1] ? this.fieldsValue[this.valueAttrName][this.state.numberOfCols-1] : ""), this.valueAttrName, VECTOR_MAPPING.ATTR.TYPE_VALUES.CONNECTOR, true)
                }
            })
        } else if (!condition) {
            this.setState({
                numberOfColsFalse: this.state.numberOfColsFalse + 3
            }, function(){
                var connectorSelectOptions = addRowNumberToOptions(this.props.connectors, this.state.numberOfColsFalse - 2);
                if (this.falseCondition && this.falseCondition[0]) {
                    obj.onChangeOption(findOptionByKey(connectorSelectOptions, this.falseCondition && this.falseCondition[0] && this.falseCondition[0][this.state.numberOfColsFalse-1] && this.falseCondition[0][this.state.numberOfColsFalse-1].value ? this.falseCondition[0][this.state.numberOfColsFalse-1] : ""), this.valueAttrName, VECTOR_MAPPING.ATTR.TYPE_VALUES.CONNECTOR, condition, false)
                }
            }) 
        } else {
            var index = Number($(e.currentTarget)[0].attributes["id"].value);
            this.setState({
                ["numberOfColsTrue_"+index]: !this.state["numberOfColsTrue_"+index] || this.state["numberOfColsTrue_"+index] === NaN  ? 3 : this.state["numberOfColsTrue_"+index] +3
            }, function(){
                var connectorSelectOptions = addRowNumberToOptions(this.props.connectors, this.state.numberOfColsTrue - 2);
                if (this.trueCondition && this.trueCondition[index] && this.trueCondition[index].length > 0) {
                    obj.onChangeOption(findOptionByKey(connectorSelectOptions, this.trueCondition && this.trueCondition[index] && this.trueCondition[index][this.state.numberOfColsTrue-1] && this.trueCondition[index][this.state.numberOfColsTrue-1].value ? this.trueCondition[index][this.state.numberOfColsTrue-1] : ""), this.valueAttrName, VECTOR_MAPPING.ATTR.TYPE_VALUES.CONNECTOR, condition, false)
                }
            }) 
        }

        this.forceUpdate();
    }

    fetchFields(value, condition) {
        if (!this.props.isAdvanced) {
            for (var e in value) {
                if (value[e] && value[e] !== null && value[e].type === RAW_ITEMS.SUBTYPE_ID) {
                    this.props.getAllFileTypeColumns({subSection:value[e].value}, this.props.identifier, undefined, undefined, undefined, undefined, undefined, this.props.identifier+"_value", parseInt(e));
                }
            }
        } else {
            if (value) {
                if (condition) {
                    for (var i = 0; i < value.length; i++) {
                        for (var e in value[i]) {
                            if (value[i] && value[i][e] && value[i][e] !== null && value[i][e].type === RAW_ITEMS.SUBTYPE_ID) {
                                this.props.getAllFileTypeColumns({ subSection: value[i][e].value }, this.props.identifier, i, true, undefined, undefined, undefined, this.props.identifier + "_value", parseInt(e));
                            }
                        }
                    }
                } else {
                    for (var i = 0; i < value.length; i++) {
                        for (var e in value[i]) {
                            if (value[i] && value[i][e] && value[i][e] !== null && value[i][e].type === RAW_ITEMS.SUBTYPE_ID) {
                                this.props.getAllFileTypeColumns({ subSection: value[i][e].value }, this.props.identifier, i, false, undefined, undefined, undefined, this.props.identifier + "_value", parseInt(e));
                            }
                        }
                    }
                }
            }  
        }
    }

    // componentDidMount() {
    //     if(this.props.isKey) {
    //         //for key, only TD files are fetched, as soon as the component mounts, without waiting for a change done by the user
    //         this.props.getAllFileTypeColumns({section:UPLOAD_SECTIONS.FIELDS.TRANSACTION_DATA}, this.props.identifier);
    //     }
    // }

    setSameAsKey(checked, identifier) {
        if (!this.props.isAdvanced) {
            if(checked && !identifier) {
                this.state.isSameAsNumber = false;
                this.state.isSameAsKey = true;
                this.fieldsValue[this.subtypeIdAttrName] = this.props.keyRef.fieldsValue[VECTOR_MAPPING.ATTR.KEY_SUBTYPE_ID];
                this.fieldsValue[this.valueAttrName] = copyObjectValues(this.props.keyRef.fieldsValue[VECTOR_MAPPING.ATTR.KEY_VALUE]);
    
                this.props.updateVectorAttribute(this.subtypeIdAttrName, this.fieldsValue[this.subtypeIdAttrName], true, false,this.falseCondition, this.trueCondition, this.props.identifier);
                this.props.updateVectorAttribute(this.valueAttrName, this.fieldsValue[this.valueAttrName], true, false,this.falseCondition, this.trueCondition, this.props.identifier);
            }else if (checked && identifier) {
                if (identifier === "name") {
                    this.state.isSameAsNumber = false;
                    this.state.isSameAsKey = false;
                    this.fieldsValue[this.subtypeIdAttrName] =""// this.props.numberRef.fieldsValue[VECTOR_MAPPING.ATTR.NUMBER_SUBTYPE_ID];
                    this.fieldsValue[this.valueAttrName] ="";// copyObjectValues(this.props.numberRef.fieldsValue[VECTOR_MAPPING.ATTR.NUMBER_VALUE]);
        
                    this.props.updateVectorAttribute(this.subtypeIdAttrName, this.fieldsValue[this.subtypeIdAttrName], true, false,this.falseCondition, this.trueCondition, this.props.identifier);
                    this.props.updateVectorAttribute(this.valueAttrName, this.fieldsValue[this.valueAttrName], true, false,this.falseCondition, this.trueCondition, this.props.identifier);
                } 
                if(identifier === "number"){
                    this.state.isSameAsNumber = false;
                    this.state.isSameAsKey = false;
                    this.fieldsValue[this.subtypeIdAttrName] =""// this.props.numberRef.fieldsValue[VECTOR_MAPPING.ATTR.NUMBER_SUBTYPE_ID];
                    this.fieldsValue[this.valueAttrName] ="";// copyObjectValues(this.props.numberRef.fieldsValue[VECTOR_MAPPING.ATTR.NUMBER_VALUE]);
        
                    this.props.updateVectorAttribute(this.subtypeIdAttrName, this.fieldsValue[this.subtypeIdAttrName], true, false,this.falseCondition, this.trueCondition, this.props.identifier);
                    this.props.updateVectorAttribute(this.valueAttrName, this.fieldsValue[this.valueAttrName], true, false,this.falseCondition, this.trueCondition, this.props.identifier);
                    this.props.nameRef.forceUpdate();
                }
            }else if (!checked) {
                if (identifier === "name") {
                    this.state.isSameAsNumber = true;
                    this.state.isSameAsKey = false;
                    this.falseCondition = this.props.numberRef.falseCondition;
                    this.trueCondition = this.props.numberRef.trueCondition;
                    this.props.updateVectorAttribute(this.valueAttrName, this.fieldsValue[this.valueAttrName], true, false,this.falseCondition, this.trueCondition, this.props.identifier);
                } else if(identifier === "number"){
                    this.state.isSameAsKey = false;
                    this.props.nameRef.forceUpdate();
                }
            }
        } else {
            if(checked && !identifier){
                this.state.isSameAsNumber = false;
                this.state.isSameAsKey = true;
                this.falseCondition = this.props.keyRef.falseCondition;
                this.trueCondition = this.props.keyRef.trueCondition;
                this.props.updateVectorAttribute(this.valueAttrName, this.fieldsValue[this.valueAttrName], true, false,this.falseCondition, this.trueCondition, this.props.identifier);
            }else if (checked && identifier) {
                if (identifier === "name") {
                    this.state.isSameAsNumber = false;
                    this.state.isSameAsKey = false;
                    this.falseCondition = [];
                    this.trueCondition = [];
                    $("#namefalseInput").val("");
                    this.state["numberOfColsFalse"] = 1;
                    for (var i=0; i < this.props.conditionsNumber; i++) {
                        $("#nametrueInput_"+i).val("");
                        this.state["numberOfColsTrue_"+i] = 1;
                    }
                    this.props.updateVectorAttribute(this.valueAttrName, this.fieldsValue[this.valueAttrName], true, false,this.falseCondition, this.trueCondition, this.props.identifier);
                } else if(identifier === "number"){
                    this.state.isSameAsKey = false;
                    this.state.isSameAsNumber = false;
                    this.falseCondition = [];
                    this.trueCondition = [];
                    this.state["numberOfColsFalse"] = 1;
                    $("#numberfalseInput").val("");
                    for (var i=0; i < this.props.conditionsNumber; i++) {
                        $("#numbertrueInput_"+i).val("");
                        this.state["numberOfColsTrue_"+i] = 1;
                    }
                    this.props.updateVectorAttribute(this.valueAttrName, this.fieldsValue[this.valueAttrName], true, false,this.falseCondition, this.trueCondition, this.props.identifier);
                    this.props.nameRef.forceUpdate();
                }
            }else if (!checked) {
                if (identifier === "name") {
                    this.state.isSameAsNumber = true;
                    this.state.isSameAsKey = false;
                    this.falseCondition = this.props.numberRef.falseCondition;
                    this.trueCondition = this.props.numberRef.trueCondition;
                    $("#namefalseInput").val($("#numberfalseInput").val());
                    for (var i=0; i < this.props.conditionsNumber; i++) {
                        $("#nametrueInput_"+i).val($("#numbertrueInput_"+i).val());
                    }
                    this.props.updateVectorAttribute(this.valueAttrName, this.fieldsValue[this.valueAttrName], true, false,this.falseCondition, this.trueCondition, this.props.identifier);
                } else if(identifier === "number"){
                    this.state.isSameAsKey = false;
                    this.state["numberOfColsFalse"] = 1;
                    $("#numberfalseInput").val("");
                    for (var i=0; i < this.props.conditionsNumber; i++) {
                        $("#numbertrueInput_"+i).val("");
                        this.state["numberOfColsTrue_"+i] = 1;
                    }
                    this.props.nameRef.forceUpdate();
                }
            }
        }
       
        this.setState({
            isSameAsKey: checked && identifier === undefined,
            isSameAsNumber: !checked && identifier=== "name" ? true : false,
            numberOfCols: this.fieldsValue[this.valueAttrName].length,      //update the number of columns after copying key data
        });
    }

    getSameAsNumber(numberRef, isSameAsKey) {
        let numberFieldsValue = numberRef.fieldsValue;
        let identifier = this.props.identifier.toUpperCase();
        
        //check if the field values are the same as the key's
        let sameValues = true;
        var values = this.fieldsValue[VECTOR_MAPPING.ATTR[identifier+"_VALUE"]];
        if (!this.props.isAdvanced && !isSameAsKey ) {
            if(values.length === numberFieldsValue[VECTOR_MAPPING.ATTR.NUMBER_VALUE].length) {
                values.forEach((col, index)=>{
                    if(numberFieldsValue[VECTOR_MAPPING.ATTR.NUMBER_VALUE][index] !== undefined && col && col.value !== undefined &&
                        numberFieldsValue[VECTOR_MAPPING.ATTR.NUMBER_VALUE][index].value !== col.value) {
                            sameValues = false;
                        }
                })
            } else {
                sameValues = false;
            }
    
            // //check if the subtype_id is the same as the key's
            if(this.fieldsValue[identifier.toLowerCase()+"_subtype_id"] === numberFieldsValue[VECTOR_MAPPING.ATTR.NUMBER_SUBTYPE_ID] && sameValues) {
                sameValues = true;
            }else{
                sameValues= false;
            }
        } else {
            var obj = this;
            sameValues= true;
            if(this.trueCondition.length === numberRef.trueCondition.length) {
                obj.trueCondition.forEach((col, index)=>{
                    if(numberRef.trueCondition[index] !== undefined && numberRef.trueCondition[index] !== null && col[index] !== undefined && col[index] !== null){
                        for (var e in numberRef.trueCondition[index]) {
                            if (Number(e) === index) {
                                if (col[index].value !==  numberRef.trueCondition[index][e].value) {
                                    sameValues = false;
                                }
                                if (numberRef.trueCondition[index][e].raw_file_subtype_id && 
                                    numberRef.trueCondition[index][e].raw_file_subtype_id !== col[index].raw_file_subtype_id) {
                                        sameValues = false;
                                }
                            }
                           
                        }
                        
                    }
                     
                })
            }
            if(this.falseCondition.length === numberRef.falseCondition.length) {
                obj.falseCondition.forEach((col, index)=>{
                    if(numberRef.falseCondition[index] !== undefined && numberRef.falseCondition[index] !== null && col){
                        for (var e in numberRef.falseCondition[index]) {
                            if (Number(e) === index) {
                                if (col[index].value !==  numberRef.falseCondition[index][e].value) {
                                    sameValues = false;
                                }
                                if (numberRef.falseCondition[index][e].raw_file_subtype_id && 
                                    numberRef.falseCondition[index][e].raw_file_subtype_id !== col[index].raw_file_subtype_id) {
                                        sameValues = false;
                                }
                            }
                        }
                    }
                })
            }
        }
        return sameValues;
    }

    getSameAsKey(keyRef) {
        let keyFieldsValue = keyRef.fieldsValue;
        let identifier = this.props.identifier.toUpperCase();
        
        //check if the field values are the same as the key's
        let sameValues = true;
        var values = this.fieldsValue[VECTOR_MAPPING.ATTR[identifier+"_VALUE"]];
        if (!this.props.isAdvanced) {
            if(values.length === keyFieldsValue[VECTOR_MAPPING.ATTR.KEY_VALUE].length) {
                values.forEach((col, index)=>{
                    if(keyFieldsValue[VECTOR_MAPPING.ATTR.KEY_VALUE][index] !== undefined && col && col.value !== undefined &&
                        keyFieldsValue[VECTOR_MAPPING.ATTR.KEY_VALUE][index].value !== col.value) {
                            sameValues = false;
                        }
                })
            } else {
                sameValues = false;
            }
    
            // //check if the subtype_id is the same as the key's
            if(this.fieldsValue[identifier.toLowerCase()+"_subtype_id"] === keyFieldsValue[VECTOR_MAPPING.ATTR.KEY_SUBTYPE_ID] && sameValues) {
                sameValues= true;
            }else{
                sameValues= false;
            }
        } else {
            var obj = this;
            sameValues= true;
            if(this.trueCondition.length === keyRef.trueCondition.length) {
                obj.trueCondition.forEach((col, index)=>{
                    if(keyRef.trueCondition[index] !== undefined && col[index] !== undefined){
                        for (var e in keyRef.trueCondition[index]) {
                            if (Number(e) === index) {
                                if (col[index].value !==  keyRef.trueCondition[index][e].value) {
                                    sameValues = false;
                                }
                                if (keyRef.trueCondition[index][e].raw_file_subtype_id && 
                                    keyRef.trueCondition[index][e].raw_file_subtype_id !== col[index].raw_file_subtype_id) {
                                        sameValues = false;
                                }
                            }
                        }
                    }
                })
            }
            if(this.falseCondition.length === keyRef.falseCondition.length) {
                obj.falseCondition.forEach((col, index)=>{
                    if(keyRef.falseCondition[index] !== undefined && col){
                        for (var e in keyRef.falseCondition[index]) {
                            if (Number(e) === index) {
                                if (col[index].value !==  keyRef.falseCondition[index][e].value) {
                                    sameValues = false;
                                }
                                if (keyRef.falseCondition[index][e].raw_file_subtype_id && 
                                    keyRef.falseCondition[index][e].raw_file_subtype_id !== col[index].raw_file_subtype_id) {
                                        sameValues = false;
                                }
                            }
                        }
                    }
                })
            }
        }
        
        return sameValues;
    }

    deleteValueRow(e, condition) {
        var index = $(e.currentTarget).attr("rowIndex");
        var conditionNumber = $(e.currentTarget).attr("index");
        if (condition !== undefined) {
            if (condition) {
                if (this.trueCondition[conditionNumber]) {
                    this.trueCondition[conditionNumber].splice(index, 3);
                    this.state["numberOfColsTrue_"+conditionNumber] = this.trueCondition[conditionNumber].length;  
                } else{
                    this.state["numberOfColsTrue_"+conditionNumber] = this.state["numberOfColsTrue_"+conditionNumber]-3;  
                }
                          
            } else{
                if (this.falseCondition[0]) {
                    this.falseCondition[0].splice(index, 3);
                    this.state.numberOfColsFalse = this.falseCondition[0].length;      
                } else{
                    this.state.numberOfColsFalse = this.state.numberOfColsFalse-3;      
                }  
            }
        } else {
            if (this.fieldsValue[this.valueAttrName] && this.fieldsValue[this.valueAttrName].length > 0) {
                this.fieldsValue[this.valueAttrName].splice(index, 3);  //delete the connector, the file and the column after it
                this.state.numberOfCols = this.fieldsValue[this.valueAttrName].length;
                this.props.updateFields(index, this.props.identifier)
            } else {
                this.state.numberOfCols =  this.state.numberOfCols-3;
            }
                            
        }
        
        if(this.props.isKey) {
            this.props.updateVectorAttribute(VECTOR_MAPPING.ATTR.KEY_VALUE, this.fieldsValue[VECTOR_MAPPING.ATTR.KEY_VALUE], true, true, this.falseCondition, this.trueCondition, this.props.identifier );
        } else if (this.props.isName) {
            this.props.updateVectorAttribute(VECTOR_MAPPING.ATTR.NAME_VALUE, this.fieldsValue[VECTOR_MAPPING.ATTR.NAME_VALUE], true, false, this.falseCondition, this.trueCondition, this.props.identifier );
        } else if (this.props.isNumber) {
            this.props.updateVectorAttribute(VECTOR_MAPPING.ATTR.NUMBER_VALUE, this.fieldsValue[VECTOR_MAPPING.ATTR.NUMBER_VALUE], true, false, this.falseCondition, this.trueCondition, this.props.identifier );
        }

        this.forceUpdate();
    }

    static getDerivedStateFromProps(nextProps, state) {
        var tempState={};

        if(nextProps.fileOptions && !deepCompareObjects(nextProps.fileOptions, state.fileOptions)) {
            var fileOptions = nextProps.fileOptions.map(opt=>{
                return {
                    label: opt[RAW_ITEMS.SUBTYPE_DISPLAY_NAME], value: opt[RAW_ITEMS.SUBTYPE_NAME], raw_file_subtype_id: opt[RAW_ITEMS.SUBTYPE_ID],
                    data_file_type: opt["section"]
                };
            });
            tempState.fileOptions = fileOptions;
        }
      
        return tempState;
    }

    componentDidUpdate(prevProps) {
        var obj = this;
        var tempState={};

        if(this.props.fileOptions && !deepCompareObjects(this.props.fileOptions, this.state.fileOptions)) {
            var fileOptions = this.props.fileOptions.map(opt=>{
                return {
                    label: opt[RAW_ITEMS.SUBTYPE_DISPLAY_NAME], value: opt[RAW_ITEMS.SUBTYPE_NAME], raw_file_subtype_id: opt[RAW_ITEMS.SUBTYPE_ID],
                    data_file_type: opt["section"]
                };
            });
            tempState.fileOptions = fileOptions;
        } 

        if ((!prevProps.trueCondition && this.props.trueCondition) || (this.props.trueCondition  && prevProps.trueCondition && JSON.stringify(this.props.trueCondition) !== JSON.stringify(prevProps.trueCondition) || this.props.fileOptions)) {
            if (!this.props.trueFieldsConcat || (prevProps.trueFieldsConcat && this.props.trueFieldsConcat && JSON.stringify(prevProps.trueFieldsConcat) !== JSON.stringify(this.props.trueFieldsConcat))) {
                this.fetchFields(this.props.trueCondition, true);
            }
            
            this.trueCondition = this.props.trueCondition && this.props.trueCondition.length > 0 ? this.props.trueCondition  : [];
            if(prevProps.trueCondition){
                for (var i=0; i<=prevProps.trueCondition.length-1; i++){
                    let trueConditionLength = this.trueCondition.length;
                    tempState["defaultValue_"+trueConditionLength] = "";
                    tempState["manualTrueDisabled_"+trueConditionLength] = this.state["manualTrueDisabled_"+trueConditionLength];
                    tempState["selectTrueDisabled_"+trueConditionLength] = this.state["selectTrueDisabled_"+trueConditionLength];
                    tempState["numberOfColsTrue_"+trueConditionLength] = 0;
                }
        }
            for (var i = this.trueCondition.length-1 ; i >= 0;i--) {
                for (var e in this.trueCondition[i]) {
                    if (this.trueCondition[i] && this.trueCondition[i][e].raw_file_subtype_id !== "") {
                        tempState["numberOfColsTrue_"+i] = this.trueCondition[i] ? this.trueCondition[i].length : 1;
                        tempState["selectTrueDisabled_"+i] = false;
                        tempState["manualTrueDisabled_"+i] = true;
                        tempState["defaultValue_"+i] = "";
                        $("#"+this.props.identifier+"trueInput_"+i).val("");
                        var valueObj = this.trueCondition[i][e];
                        valueObj.rowNumber = Number(e);
                        if (valueObj.raw_file_subtype_id !== "" && valueObj.raw_file_subtype_id !== undefined){// manual
                            var subSection = undefined;
                            if(tempState.fileOptions) {
                                let temp = tempState.fileOptions.filter(e=>e["raw_file_subtype_id"] === valueObj["raw_file_subtype_id"]);
                                subSection = temp.length ? temp[0].value : undefined
                            }
                            if(this.props.trueFields && prevProps.trueFields && JSON.stringify(this.props.trueFields) !== JSON.stringify(prevProps.trueFields)) {
                                if (this.trueCondition[i][e].type !== "raw_file_subtype_id" && valueObj.rowNumber === 0) {
                                    this.props.getAllFileTypeColumns({subSection:subSection}, this.props.identifier, valueObj.rowNumber, true,this.trueCondition);
                                }
                            }
                        }
                        
                    } else if (this.trueCondition[i] && this.trueCondition[i][e].raw_file_subtype_id === "") {
                        $("#"+this.props.identifier+"trueInput_"+i).val(this.trueCondition[i][e].value);
                        tempState["defaultValue_"+i] = this.trueCondition[i][e].value;
                        tempState["manualTrueDisabled_"+i] = false;
                        tempState["selectTrueDisabled_"+i] = true;
                        tempState["numberOfColsTrue_"+i] = 1;
                    }
                }
            }
        }
        if ((!prevProps.falseCondition && this.props.falseCondition) || (this.props.falseCondition &&  prevProps.falseCondition && JSON.stringify(prevProps.falseCondition) !== JSON.stringify(this.props.falseCondition) || this.props.fileOptions)) {
            this.falseCondition = this.props.falseCondition && this.props.falseCondition.length > 0 ? this.props.falseCondition  : [];
             tempState.numberOfColsFalse = this.props.falseCondition && this.props.falseCondition[0] ?  this.props.falseCondition[0].length : 1;
            if (!prevProps.falseFieldsConcat || ( this.props.falseFieldsConcat &&  prevProps.falseFieldsConcat && JSON.stringify(this.props.falseFieldsConcat) !== JSON.stringify(prevProps.falseFieldsConcat) )) {
                this.fetchFields(this.props.falseCondition, false);
            }
            if (this.falseCondition[0]) {
                for (var i in this.falseCondition[0]) {
                    if(this.falseCondition[0][i].raw_file_subtype_id !== ""){
                        tempState["selectFalseDisabled"] = false;
                        tempState["manualFalseDisabled"] = true;
                        $("#"+this.props.identifier+"falseInput").val("");
                        var valueObj = this.falseCondition[0][i];
                        valueObj.rowNumber = Number(i);
                        if (valueObj.raw_file_subtype_id !== "" && valueObj.raw_file_subtype_id !== undefined){// manual
                            var subSection =  tempState.fileOptions ? tempState.fileOptions.filter(e=>e["raw_file_subtype_id"] === valueObj["raw_file_subtype_id"]).length ?
                                                tempState.fileOptions.filter(e=>e["raw_file_subtype_id"] === valueObj["raw_file_subtype_id"])[0].value :undefined: undefined;
                            if(prevProps.falseFields && this.props.falseFields && JSON.stringify(prevProps.falseFields) !== JSON.stringify(this.props.falseFields)) {
                                if (this.falseCondition[0][i].type !== "raw_file_subtype_id" && valueObj.rowNumber === 0) {
                                    this.props.getAllFileTypeColumns({subSection:subSection}, this.props.identifier, valueObj.rowNumber, false,this.falseCondition);
                                }
                            }
                        } 
                    
                    } else if (this.falseCondition[0] && this.falseCondition[0][i].raw_file_subtype_id === "") {
                        $("#"+this.props.identifier+"falseInput").val(this.falseCondition[0][i].value);
                        tempState["manualFalseDisabled"] = false;
                        tempState["selectFalseDisabled"] = true;
                        tempState["numberOfColsFalse"] = 1;
                    }
                }
            }
        }
            
        if(JSON.stringify(this.props.fieldsValue) !== JSON.stringify(this.fieldsValue)) {
            this.fieldsValue = this.props.fieldsValue;
            if (this.fieldsValue[this.props.identifier+"_value"].length > 0 && ( !this.props.fieldsConcat || (prevProps.fieldsConcat && this.props.fieldsConcat && JSON.stringify(this.props.fieldsConcat) !== JSON.stringify(prevProps.fieldsConcat)))) {
                this.fetchFields(this.fieldsValue[this.props.identifier+"_value"])
            }
        }
        if(tempState.numberOfCols !== this.fieldsValue[this.valueAttrName].length) {
            tempState.numberOfCols = this.fieldsValue[this.valueAttrName].length;      //update the number of columns after receiving new comp data
        }
        
        if(this.props.fields && this.props.field &&  prevProps.fields &&  prevProps.fields.length !== this.props.fields.length) {
            //if the new file doesn't contain any of the chosen column, reset that dropdown
            var fieldNames = this.props.fields.map(field => { return field.value });            
            this.fieldsValue[this.valueAttrName].forEach((col, index)=>{
                if(col && col[VECTOR_MAPPING.ATTR.TYPE] === VECTOR_MAPPING.ATTR.TYPE_VALUES.FIELD && fieldNames.indexOf(col[VECTOR_MAPPING.ATTR.VALUE].toLowerCase()) === -1) {
                    //if the file has been changed AFTER choosing a field, and this field does not belong to the new file, reset the field option
                    obj.fieldsValue[obj.valueAttrName][index] = undefined;
                }
            });
        }     

        for (var i=0; i<=this.props.conditionsNumber; i++) {
            if (this.state["manualTrueDisabled_"+i] === undefined)
                tempState["manualTrueDisabled_"+i] = false;
            if(this.state[["selectTrueDisabled_"+i]] === undefined)
                tempState["selectTrueDisabled_"+i] = true;
            
        }
        this.trueFields = this.props.trueFields ? this.props.trueFields : prevProps.trueFields;
        this.falseFields = this.props.falseFields ? this.props.falseFields :prevProps.falseFields;
        if(!this.props.isKey && !this.props.status) {
                var isSameAsKey = this.getSameAsKey(this.props.keyRef === undefined ? prevProps.keyRef : this.props.keyRef);
                if(this.state.isSameAsKey === undefined && isSameAsKey !== this.state.isSameAsKey ) {
                    tempState.isSameAsKey = isSameAsKey;
                }else{
                    tempState.isSameAsKey = this.state.isSameAsKey;
                }
                if (this.props.identifier === "name") {
                    var isSameAsNumber = this.getSameAsNumber(this.props.numberRef === undefined ? prevProps.numberRef : this.props.numberRef, tempState.isSameAsKey);
                    if(this.state.isSameAsNumber === undefined && isSameAsNumber !== this.state.isSameAsNumber ) {
                        tempState.isSameAsNumber = isSameAsNumber;
                    }else{
                        tempState.isSameAsNumber = this.state.isSameAsNumber;
                    }
                }  
            } else if(!this.props.isKey && this.props.status){
                tempState.isSameAsKey = this.state.isSameAsKey === undefined ? true :  this.state.isSameAsKey;
            }else if (!this.props.isKey){
                tempState.isSameAsKey = this.state.isSameAsKey;

            if (this.props.identifier === "name") {
                var isSameAsNumber = this.getSameAsNumber(this.props.numberRef === undefined ? prevProps.numberRef : this.props.numberRef, tempState.isSameAsKey);
                if(this.state.isSameAsNumber === undefined || isSameAsNumber !== this.state.isSameAsNumber ) {
                    tempState.isSameAsNumber = isSameAsNumber;
                }
            }  
        } else if(!this.props.isKey && this.props.status){
            tempState.isSameAsKey = this.state.isSameAsKey === undefined ? false :  this.state.isSameAsKey;
        }
       
        if(prevProps!==this.props) {
            this.setState(tempState);   //rerender only once
        }
    }

    /**
     * function changes state of the selected condition radio drop down and updates object
     * @param {*} condition 
     * @param {*} e 
     */
    setConditionValue(condition, e) {
    
        if (condition) {
            for (var i in this.trueCondition) {
                if (Number(i) === Number($(e.currentTarget)[0].id)) {
                    for (var elt in this.trueCondition[i]) {
                        if ( $(e.currentTarget)[0].value === "selectValue") {
                            this.trueCondition[i][elt]["value"] = ""
                            this.trueCondition[i][elt]["raw_file_subtype_id"] = "-";
                        }else{
                            this.trueCondition[i]=[{raw_file_subtype_id:"", value:""}]
                        }
                    }  
                }
            }
            if ($(e.currentTarget)[0].value === "selectValue") {
                $("#"+this.props.identifier+"trueInput_"+$(e.currentTarget)[0].id).val("");
            }
            this.props.updateVectorAttribute(this.valueAttrName, this.fieldsValue[this.valueAttrName], true, this.props.isKey,this.falseCondition, this.trueCondition, this.props.identifier);

            this.setState({
                ["manualTrueDisabled_"+$(e.currentTarget)[0].id]: $(e.currentTarget)[0].value === "manualValue" ? false : true,
                ["selectTrueDisabled_"+$(e.currentTarget)[0].id]: $(e.currentTarget)[0].value === "selectValue" ? false :true,
                ["numberOfColsTrue_"+$(e.currentTarget)[0].id] : $(e.currentTarget)[0].value === "manualValue"  ? 1 : this.state["numberOfColsTrue_"+$(e.currentTarget)[0].id]
            });
        } else {
            if ( $(e.currentTarget)[0].value === "selectValue") {
                $("#"+this.props.identifier+"falseInput").val("");
            }
            for (var i in this.falseCondition) {
                if (Number(i) === 0) {
                    for (var elt in this.falseCondition[0]) {
                        if ( $(e.currentTarget)[0].value === "selectValue") {
                            this.falseCondition[i][elt]["value"] = "";
                            this.falseCondition[i][elt]["raw_file_subtype_id"] = "-"
                        }else{
                            this.falseCondition[i]=[{raw_file_subtype_id:"", value:""}]
                        }
                    } 
                }
            }
            this.props.updateVectorAttribute(this.valueAttrName, this.fieldsValue[this.valueAttrName], true, this.props.isKey,this.falseCondition, this.trueCondition, this.props.identifier);
            this.setState({
                manualFalseDisabled: $(e.currentTarget)[0].value === "manualValue" ? false : true,
                selectFalseDisabled: $(e.currentTarget)[0].value === "selectValue" ? false :true,
                numberOfColsFalse :  $(e.currentTarget)[0].value === "manualValue" ? 1 : this.state.numberOfColsFalse

            });
        }
    }

    onChangeOption(valueObj, attr, type, decrement, condition, isManual) {
        if(valueObj === null || valueObj === "") {
            return;
        }
        //this.onChangeOption(valueObj, this.subtypeIdAttrName, undefined, undefined, true, undefined)}
        //var fields = this.props.trueFields ? addRowNumberToOptions(this.props.trueFields[i], i) : this.trueFields ? addRowNumberToOptions(this.trueFields[i], i) : [];

        if (decrement) {
            valueObj.rowNumber = Number(valueObj.rowNumber)-1;
        }
        if (condition === undefined) { // basic filter
            //on change of field options for name/number
            if([VECTOR_MAPPING.ATTR.NAME_VALUE, VECTOR_MAPPING.ATTR.NUMBER_VALUE, VECTOR_MAPPING.ATTR.KEY_VALUE].indexOf(attr) !== -1) {
                if (this.fieldsValue[attr] === ""){
                    this.fieldsValue[attr] = [];
                    this.fieldsValue[attr][valueObj.rowNumber] = {[VECTOR_MAPPING.ATTR.VALUE]: valueObj.value, [VECTOR_MAPPING.ATTR.TYPE]: type, label:valueObj.label, raw_file_subtype_id: valueObj.raw_file_subtype_id}
                } else{
                    this.fieldsValue[attr][valueObj.rowNumber] = {[VECTOR_MAPPING.ATTR.VALUE]: valueObj.value, [VECTOR_MAPPING.ATTR.TYPE]: type, label:valueObj.label, raw_file_subtype_id: valueObj.raw_file_subtype_id}
                }
                if (type === RAW_ITEMS.SUBTYPE_ID) {
                    this.props.getAllFileTypeColumns({subSection:valueObj["value"]}, this.props.identifier, undefined, undefined, undefined, undefined, undefined, attr, valueObj.rowNumber);
                }
            } else {
                this.fieldsValue[attr] = valueObj[RAW_ITEMS.SUBTYPE_ID];
                this.props.getAllFileTypeColumns({subSection: valueObj["value"]}, this.props.identifier);
            }
            if(!this.props.isKey) {
                this.state.isSameAsKey = this.getSameAsKey(this.props.keyRef);
                if(this.props.identifier === "name") {
                    this.state.isSameAsNumber = this.getSameAsNumber(this.props.numberRef);
                }
            }
            this.props.updateVectorAttribute(attr, this.fieldsValue[attr], true, this.props.isKey);
            this.forceUpdate();

        } else if (!condition && !isManual){ // advanced filter for false condition select option radio button
            if([VECTOR_MAPPING.ATTR.NAME_VALUE, VECTOR_MAPPING.ATTR.NUMBER_VALUE, VECTOR_MAPPING.ATTR.KEY_VALUE].indexOf(attr) !== -1) {
                if (this.falseCondition && this.falseCondition.length > 0 && this.falseCondition[0] && this.falseCondition[0][valueObj.rowNumber]) { // editing a drop down
                    this.falseCondition[0][valueObj.rowNumber] = {value: valueObj.value, label: valueObj.label,[VECTOR_MAPPING.ATTR.TYPE]: type, attr: valueObj[RAW_ITEMS.SUBTYPE_ID], raw_file_subtype_id: valueObj[RAW_ITEMS.SUBTYPE_ID]};
                } else {// fililng drop down for the first time
                    if (this.falseCondition && this.falseCondition.length > 0 && this.falseCondition[0]) {
                        if(valueObj.rowNumber > this.falseCondition[0].length) {
                            this.falseCondition[0].push({value:'', label: '',[VECTOR_MAPPING.ATTR.TYPE]: 'connector', attr: '', raw_file_subtype_id: ''});
                            this.falseCondition[0].push({value: valueObj.value, label: valueObj.label,[VECTOR_MAPPING.ATTR.TYPE]: type, attr: valueObj[RAW_ITEMS.SUBTYPE_ID], raw_file_subtype_id: valueObj[RAW_ITEMS.SUBTYPE_ID]});
                        } else {
                            this.falseCondition[0].push({value: valueObj.value, label: valueObj.label,[VECTOR_MAPPING.ATTR.TYPE]: type, attr: valueObj[RAW_ITEMS.SUBTYPE_ID], raw_file_subtype_id: valueObj[RAW_ITEMS.SUBTYPE_ID]});
                        } 
                    } 
                }
                if (type === RAW_ITEMS.SUBTYPE_ID) {
                    this.props.getAllFileTypeColumns({subSection:valueObj["value"]}, this.props.identifier, 0, false, undefined, undefined, undefined, attr, valueObj.rowNumber);
                }
            } else { //adding connectors
                if (this.falseCondition.length === 0) {
                    this.falseCondition = [];
                    this.falseCondition.push([]);
                    this.falseCondition[0].push({value: "", label: "",[VECTOR_MAPPING.ATTR.TYPE]: "", raw_file_subtype_id: valueObj[RAW_ITEMS.SUBTYPE_ID]});
                } else {
                    this.falseCondition[0][valueObj.rowNumber][RAW_ITEMS.SUBTYPE_ID]= valueObj[RAW_ITEMS.SUBTYPE_ID];
                    this.falseCondition[0][valueObj.rowNumber].value= "";
                    this.falseCondition[0][valueObj.rowNumber].label= "";
                }
                this.props.getAllFileTypeColumns({subSection:valueObj.value}, this.props.identifier, 0, false, this.falseCondition);
            }
        } else if (!condition && isManual) { // for advanced false manual condition
            if (this.falseCondition.length === 0 ){
                this.falseCondition = [];
                this.falseCondition.push([]);
                this.falseCondition[0] = [{raw_file_subtype_id: "", value: $(valueObj.currentTarget)[0].value, label:  $(valueObj.currentTarget)[0].value}];
            } else {
                this.falseCondition[0] = [{raw_file_subtype_id: "", value: $(valueObj.currentTarget)[0].value, label:  $(valueObj.currentTarget)[0].value}];
            }
        } else if (condition && !isManual) {// for advanced true condition select option
            if([VECTOR_MAPPING.ATTR.NAME_VALUE, VECTOR_MAPPING.ATTR.NUMBER_VALUE, VECTOR_MAPPING.ATTR.KEY_VALUE].indexOf(attr) !== -1) {
                if (this.trueCondition[valueObj.rowNumber]) {  
                    if (valueObj.indexNumber) { // editing dropdown
                        if (this.trueCondition[valueObj.rowNumber][valueObj.indexNumber]) {
                            this.trueCondition[valueObj.rowNumber][valueObj.indexNumber] = {value: valueObj.value, label: valueObj.label,[VECTOR_MAPPING.ATTR.TYPE]: type, attr: valueObj[RAW_ITEMS.SUBTYPE_ID], raw_file_subtype_id: valueObj[RAW_ITEMS.SUBTYPE_ID]};
                        } else { // adding dropdown
                            if(valueObj.indexNumber > this.trueCondition[valueObj.rowNumber].length) { // if user filled column before connector
                                this.trueCondition[valueObj.rowNumber].push({value:'', label: '',[VECTOR_MAPPING.ATTR.TYPE]: 'connector', attr: '', raw_file_subtype_id: ''});
                                this.trueCondition[valueObj.rowNumber].push({value: valueObj.value, label: valueObj.label,[VECTOR_MAPPING.ATTR.TYPE]: type, attr: valueObj[RAW_ITEMS.SUBTYPE_ID], raw_file_subtype_id: valueObj[RAW_ITEMS.SUBTYPE_ID]});
                            } else {
                                this.trueCondition[valueObj.rowNumber].push({value: valueObj.value, label: valueObj.label,[VECTOR_MAPPING.ATTR.TYPE]: type, attr: valueObj[RAW_ITEMS.SUBTYPE_ID], raw_file_subtype_id: valueObj[RAW_ITEMS.SUBTYPE_ID]});
                            }
                        }
                    } else {
                            this.trueCondition[valueObj.rowNumber]= [{value: valueObj.value, label: valueObj.label,[VECTOR_MAPPING.ATTR.TYPE]: type, attr: valueObj[RAW_ITEMS.SUBTYPE_ID], raw_file_subtype_id: valueObj[RAW_ITEMS.SUBTYPE_ID]}];
                        }
                } else { //adding new drop down 
                    this.trueCondition.push([{value: valueObj.value, label: valueObj.label,[VECTOR_MAPPING.ATTR.TYPE]: type, attr: valueObj[RAW_ITEMS.SUBTYPE_ID], raw_file_subtype_id: valueObj[RAW_ITEMS.SUBTYPE_ID]}]);
                    if (type === "connector") {
                        this.trueCondition[valueObj.rowNumber][valueObj.indexNumber] = {value: valueObj.value, label: valueObj.label,[VECTOR_MAPPING.ATTR.TYPE]: type, attr: '', raw_file_subtype_id:''}
                    }
                }
                if (type === RAW_ITEMS.SUBTYPE_ID) {
                    this.props.getAllFileTypeColumns({subSection:valueObj["value"]}, this.props.identifier, valueObj.rowNumber, true, undefined, undefined, undefined, attr, valueObj.indexNumber);
                }
            } else {
                if (this.trueCondition.length === 0) {
                    this.trueCondition = [];
                    if(valueObj.rowNumber !== 0) {
                        for(var i=0; i< valueObj.rowNumber; i++) {
                            this.trueCondition.push([]);
                            this.trueCondition[i].push({value: "", label: "",[VECTOR_MAPPING.ATTR.TYPE]: "", raw_file_subtype_id: ""});
                        }
                        this.trueCondition.push([]);
                        this.trueCondition[valueObj.rowNumber].push({value: "", label: "",[VECTOR_MAPPING.ATTR.TYPE]: "", raw_file_subtype_id: valueObj[RAW_ITEMS.SUBTYPE_ID]});
                    } else{
                        this.trueCondition.push([]);
                        this.trueCondition[0].push({value: "", label: "",[VECTOR_MAPPING.ATTR.TYPE]: "", raw_file_subtype_id: valueObj[RAW_ITEMS.SUBTYPE_ID]});
                    }
                    this.props.getAllFileTypeColumns({subSection:valueObj.value}, this.props.identifier, valueObj.rowNumber, true, this.trueCondition);
                } else {
                    if (this.trueCondition[valueObj.rowNumber]) { // editing connectors
                        if (valueObj.indexNumber) {
                            if (this.trueCondition[valueObj.rowNumber][valueObj.indexNumber]) {
                                this.trueCondition[valueObj.rowNumber][valueObj.indexNumber][RAW_ITEMS.SUBTYPE_ID]= valueObj[RAW_ITEMS.SUBTYPE_ID];
                                this.trueCondition[valueObj.rowNumber][valueObj.indexNumber].value= "";
                                this.trueCondition[valueObj.rowNumber][valueObj.indexNumber].label= "";              
                            } else {
                                this.trueCondition[valueObj.rowNumber].push({value: valueObj.value, label: valueObj.label,[VECTOR_MAPPING.ATTR.TYPE]: type, attr: valueObj[RAW_ITEMS.SUBTYPE_ID], raw_file_subtype_id: valueObj[RAW_ITEMS.SUBTYPE_ID]});

                            }
                        } else { //adding connectors
                            if(this.trueCondition[valueObj.rowNumber].length === 0){
                                this.trueCondition[valueObj.rowNumber].push({})
                            }
                            this.trueCondition[valueObj.rowNumber][0][RAW_ITEMS.SUBTYPE_ID]= valueObj[RAW_ITEMS.SUBTYPE_ID];
                            this.trueCondition[valueObj.rowNumber][0].value= "";
                            this.trueCondition[valueObj.rowNumber][0].label= "";
                        }
                        this.props.getAllFileTypeColumns({subSection:valueObj.value}, this.props.identifier, valueObj.rowNumber, true, this.trueCondition);
                    } else {
                        if(valueObj.rowNumber !== 0) {
                            for(var i=this.trueCondition.length ; i<= valueObj.rowNumber; i++) {
                                this.trueCondition.push([]);
                            }
                        }
                        this.trueCondition.splice(valueObj.rowNumber, 1, [{value: "", label: "",[VECTOR_MAPPING.ATTR.TYPE]: type, attr: valueObj[RAW_ITEMS.SUBTYPE_ID], raw_file_subtype_id: valueObj[RAW_ITEMS.SUBTYPE_ID]}]);
                        // this.trueCondition.push([{value: "", label: "",[VECTOR_MAPPING.ATTR.TYPE]: type, attr: valueObj[RAW_ITEMS.SUBTYPE_ID], raw_file_subtype_id: valueObj[RAW_ITEMS.SUBTYPE_ID]}]);
                        this.props.getAllFileTypeColumns({subSection:valueObj.value}, this.props.identifier, valueObj.rowNumber, true, this.trueCondition);
                    }
                }
            } 
        } else if (condition && isManual) { // for advanced true condition manual option 
            if (this.trueCondition.length === 0 ){
                this.trueCondition = [];
                this.trueCondition.push([]);
                this.state["defaultValue_"+$(valueObj.currentTarget)[0].id.split("_")[1]] = $(valueObj.currentTarget)[0].value;
                this.trueCondition[Number($(valueObj.currentTarget)[0].id.split("_")[1])] = [{raw_file_subtype_id: "", value: $(valueObj.currentTarget)[0].value, label:  $(valueObj.currentTarget)[0].value}];
            } else {
                this.trueCondition[Number($(valueObj.currentTarget)[0].id.split("_")[1])] = [{raw_file_subtype_id: "", value: $(valueObj.currentTarget)[0].value, label:  $(valueObj.currentTarget)[0].value}];
            }
            // this.props.getAllFileTypeColumns({subSection:$(valueObj.currentTarget)[0].value}, this.props.identifier, $(valueObj.currentTarget)[0].id.split("_")[1], true, this.trueCondition);
        }
        this.props.updateVectorAttribute(attr, this.fieldsValue[attr], true, this.props.isKey,this.falseCondition, this.trueCondition, this.props.identifier);
        this.forceUpdate();
    }

    render() {
        var disabledClass = this.props.isKey && this.props.is_default === "true" ? "disabled" : "";
        var SelectOptions = [];
        if(this.state.isSameAsKey){
            SelectOptions = addRowNumberToOptions(this.props.oldFields, 0);
        } else {
            SelectOptions = addRowNumberToOptions(this.props.fields, 0);
        }
        var optionsFalse = this.state.fileOptions ? addRowNumberToOptions(this.state.fileOptions, 0) : [];
        var falseSelectOptions = this.props.falseFields ? addRowNumberToOptions(this.props.falseFields[0], 0) : this.falseFields ?  addRowNumberToOptions(this.falseFields[0], 0) :[];
        var columns = [];
        var falseColumns = [];
        //to make the list open upwards
        const topStyles = {
            menu: (provided, state) => ({
                ...provided,
                top: 'auto',
                bottom: '100%',
                zIndex: 3
            }),
            option: (styles) => ({
                ...styles,
                padding: convertPxToViewport(8)+' 0 '+' '+convertPxToViewport(8)+' '+convertPxToViewport(9),
            }),
            control: (provided, state) => ({
                ...provided,
                borderRadius: convertPxToViewport(4),
                minHeight: convertPxToViewport(38),
                borderWidth: convertPxToViewport(1),
            }),
            placeholder: (styles) => ({
                ...styles,
                marginLeft: convertPxToViewport(2),
                marginRRight: convertPxToViewport(2),
            })
        }

        const bottomStyles = {
            menu: (provided, state) => ({
            ...provided,
                bottom: 'auto',
                top: '100%',
                zIndex: 3
            }),
            option: (styles) => ({
                ...styles,
                padding: convertPxToViewport(8)+' 0 '+' '+convertPxToViewport(8)+' '+convertPxToViewport(9),
            }),
            control: (provided, state) => ({
                ...provided,
                borderRadius: convertPxToViewport(4),
                minHeight: convertPxToViewport(38),
                borderWidth: convertPxToViewport(1),
            }),
            placeholder: (styles) => ({
                ...styles,
                marginLeft: convertPxToViewport(2),
                marginRRight: convertPxToViewport(2),
            })
        }
        /////////////////////////////

        const optionLabel = ({ value, label, isDisabled }) => (
            <div title={label} className={(isDisabled ? "option-group-header" : "")+ " option-padding"}>{label}</div>
        );


        var conditionValuesContainer = [];
        var trueColumns = [];

        for (var i=this.props.conditionsNumber-1; i>=0; i--) {
            var trueIndexColumns = [] ;   //starts with one because the first field to be chosen is always present
            for(var j=1; j<this.state["numberOfColsTrue_"+i]; j+=3) {
                var connectorSelectOptions = addRowNumberToOptions(this.props.connectors, i);
                connectorSelectOptions = addIndexNumberToOptions(connectorSelectOptions, j);
                
                var fileOptions = addRowNumberToOptions(this.state.fileOptions, i);
                fileOptions = addIndexNumberToOptions(fileOptions, j + 1);
                
                var columnSelectOptions = this.props.trueFieldsConcat &&  this.props.trueFieldsConcat.length > 0 && this.props.trueFieldsConcat[i] && this.props.trueFieldsConcat[i][j+1]? addRowNumberToOptions(this.props.trueFieldsConcat[i][j+1], i).length > 0 ? addRowNumberToOptions(this.props.trueFieldsConcat[i][j+1], i) : [] : [];
                columnSelectOptions = columnSelectOptions.length > 0 ? addIndexNumberToOptions(columnSelectOptions, j + 2) : [];
                
                trueIndexColumns.push(
                    <ConcatenatedColumn rowIndex={i} conditionNumber={j} advanced={true} basic={false} isTrueOrFalse={true} isSameAsKey={this.state.isSameAsKey} fileOptions={fileOptions}
                        fieldsValue={this.fieldsValue} onChangeOption={this.onChangeOption} deleteValueRow={this.deleteValueRow}
                        columnSelectOptions={columnSelectOptions} connectorSelectOptions={connectorSelectOptions} oldFields={this.props.oldFields}
                        fields={this.props.fields} identifier={this.props.identifier} trueCondition={this.trueCondition} identifierName={this.props.identifierName} 
                        advancedIsDisabled={this.state["selectTrueDisabled_" + i]} />
                )
            }
            let subtypeValue = this.trueCondition[i] && this.trueCondition[i][0] && this.trueCondition[i][0].value ? this.trueCondition[i][0].value : "";    
            trueColumns.push(trueIndexColumns);
            var options = addRowNumberToOptions(copyObjectValues(this.state.fileOptions), i);
            var fields = this.props.trueFields ? addRowNumberToOptions(this.props.trueFields[i], i) : this.trueFields ? addRowNumberToOptions(this.trueFields[i], i) : [];
            // conditions for true values of advanced filter are filled here 
            conditionValuesContainer.push(<div key={"condition_"+i}><div className="fs-14 uk-margin-default-bottom">{"Condition "+ (i+1).toString() + " value if true"}</div>
                                            <div className="identifier-container">
                                                <div className="uk-display-flex justify-content-between align-items-center">
                                                    <div className="uk-display-flex align-items-center uk-text-xmedium">
                                                        <RadioButton  className="radioButton radioButtonMargin" onChange={(e)=>{this.setConditionValue(true,e)}}
                                                                value={"manualValue"} id={i} checked={!this.state["manualTrueDisabled_"+i]}
                                                        />
                                                        <Input type="text" className="form-control input-width-150 uk-margin-default-left" disabled={this.state["manualTrueDisabled_"+i]} id={this.props.identifier+"trueInput_"+i} defaultValue={this.state["defaultValue_"+i] ? this.state["defaultValue_"+i] :""}
                                                            onChange={(valueObj) => this.onChangeOption(valueObj, this.subtypeIdAttrName, undefined, undefined, true, true)}
                                                        />
                                                    </div>
                                                    <div className="uk-display-flex align-items-center" style={{gap:"0.208vw"}}>
                                                        <RadioButton className="radioButton radioButtonMargin" onChange={(e)=>{this.setConditionValue(true,e)}}
                                                        value={"selectValue"} checked={!this.state["selectTrueDisabled_"+i]} id={i}
                                                        />
                                                        <Select
                                                            className="uk-display-inline-block input-width-150"
                                                            classNamePrefix="vector-mapping"
                                                            value={findOptionByKey(options || [], this.props.getObjOfSubtypeAttr(options, RAW_ITEMS.SUBTYPE_ID, this.trueCondition[i] && this.trueCondition[i][0] && this.trueCondition[i][0][RAW_ITEMS.SUBTYPE_ID] ? this.trueCondition[i][0][RAW_ITEMS.SUBTYPE_ID] : "").value ? this.props.getObjOfSubtypeAttr(options, RAW_ITEMS.SUBTYPE_ID, this.trueCondition[i] && this.trueCondition[i][0] && this.trueCondition[i][0][RAW_ITEMS.SUBTYPE_ID] ? this.trueCondition[i][0][RAW_ITEMS.SUBTYPE_ID] : "").value : this.props.getObjOfSubtypeAttr(options, RAW_ITEMS.SUBTYPE_ID, this.trueCondition[i] && this.trueCondition[i][0] ? this.trueCondition[i][0][RAW_ITEMS.SUBTYPE_ID] : ""))}
                                                            options={arrangeFileNames(options) || []}
                                                            onChange={(valueObj) => this.onChangeOption(valueObj, this.subtypeIdAttrName, undefined, undefined, true, undefined)}
                                                            styles={this.subtypeIdAttrName && this.subtypeIdAttrName === VECTOR_MAPPING.FIELDS.VECTOR_NAME_SUBTYPE_ID ? topStyles : bottomStyles}
                                                            isDisabled={this.state["selectTrueDisabled_" + i]}
                                                            placeholder={MESSAGES.vectors_mapping_concatenated_column_file_placeholder}
                                                            formatOptionLabel={optionLabel}
                                                            maxMenuHeight= {convertPxToViewport(300)}
                                                        />
                                                        {/* <DropDown // to remove comment and comment <Select
                                                            className="uk-display-inline-block input-width-150 uk-margin-default-left"
                                                            classNamePrefix="vector-mapping"
                                                            value={findOptionByKey(options || [], this.props.getObjOfSubtypeAttr(options, RAW_ITEMS.SUBTYPE_ID, this.trueCondition[i] && this.trueCondition[i][0] && this.trueCondition[i][0][RAW_ITEMS.SUBTYPE_ID] ? this.trueCondition[i][0][RAW_ITEMS.SUBTYPE_ID] : "").value ? this.props.getObjOfSubtypeAttr(options, RAW_ITEMS.SUBTYPE_ID, this.trueCondition[i] && this.trueCondition[i][0] && this.trueCondition[i][0][RAW_ITEMS.SUBTYPE_ID] ? this.trueCondition[i][0][RAW_ITEMS.SUBTYPE_ID] : "").value : this.props.getObjOfSubtypeAttr(options, RAW_ITEMS.SUBTYPE_ID, this.trueCondition[i] && this.trueCondition[i][0] ? this.trueCondition[i][0][RAW_ITEMS.SUBTYPE_ID] : ""))}
                                                            options={arrangeFileNames(options) || []}
                                                            onChange={(valueObj) => this.onChangeOption(valueObj, this.subtypeIdAttrName, undefined, undefined, true, undefined)}
                                                            type={DROPDOWN_TYPE.INPUT}
                                                            disabled={this.state["selectTrueDisabled_" + i]}
                                                            placeholder={MESSAGES.vectors_mapping_concatenated_column_file_placeholder}
                                                            formatOptionLabel={optionLabel}
                                                            maxMenuHeight= {convertPxToViewport(300)}
                                                        /> */}
                                                        <Select
                                                            className={"uk-display-inline-block input-width-150" + disabledClass}
                                                            classNamePrefix="vector-mapping"
                                                            value={findOptionByKey(fields, this.trueCondition[i] && this.trueCondition[i][0] && this.trueCondition[i][0].value
                                                                ? this.trueCondition[i][0].value :
                                                                this.trueCondition[i] && this.trueCondition[i][0] ? this.trueCondition[i][0] : "")}
                                                            options={fields}
                                                            placeholder={MESSAGES.vectors_mapping_concatenated_column_column_placeholder}
                                                            onChange={(valueObj,i) => this.onChangeOption(valueObj, this.valueAttrName, VECTOR_MAPPING.ATTR.TYPE_VALUES.FIELD, 0, true, undefined)}
                                                            styles={this.valueAttrName && this.valueAttrName === VECTOR_MAPPING.FIELDS.VECTOR_NAME_VALUE ? topStyles : bottomStyles}
                                                            isDisabled={this.state["selectTrueDisabled_"+i]}
                                                            maxMenuHeight= {convertPxToViewport(300)}
                                                        />
                                                        {/* <DropDown // to remove comment and comment <Select
                                                            className={"uk-display-inline-block input-width-150 uk-margin-default-left" + disabledClass}
                                                            classNamePrefix="vector-mapping"
                                                            value={findOptionByKey(fields, this.trueCondition[i] && this.trueCondition[i][0] && this.trueCondition[i][0].value
                                                                ? this.trueCondition[i][0].value :
                                                                this.trueCondition[i] && this.trueCondition[i][0] ? this.trueCondition[i][0] : "")}
                                                            options={fields}
                                                            placeholder={MESSAGES.vectors_mapping_concatenated_column_column_placeholder}
                                                            onChange={(valueObj,i) => this.onChangeOption(valueObj, this.valueAttrName, VECTOR_MAPPING.ATTR.TYPE_VALUES.FIELD, 0, true, undefined)}
                                                            type={DROPDOWN_TYPE.INPUT}
                                                            disabled={this.state["selectTrueDisabled_"+i]}
                                                            maxMenuHeight= {convertPxToViewport(300)}
                                                        /> */}
                                                        <div className={this.state["selectTrueDisabled_"+i] || !subtypeValue || subtypeValue === ""? "uk-margin-default-left uk-button-icon disabled" : "uk-margin-default-left uk-button-icon"}  disabled={this.state["selectTrueDisabled_"+i] || !subtypeValue || subtypeValue === ""} id= {i} onClick={(e)=>{this.addColumn(true, e)}}>
                                                            <i className={this.state["selectTrueDisabled_"+i] || !subtypeValue || subtypeValue === "" ? "fa-lg far fa-plus-circle disabled" : "fa-lg far fa-plus-circle"} disabled={this.state["selectTrueDisabled_"+i] || !subtypeValue || subtypeValue === ""}></i><span title={MESSAGES.vectors_mapping_concatenated_column_title_long}>{MESSAGES.vectors_mapping_concatenated_column_title}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {trueColumns[this.props.conditionsNumber-1 -i]}
                                            </div>
                                        </div>);
        }
        // columns and connectors for non advanced filter are filled here
        for (var i = 1; i < this.state.numberOfCols; i += 3) {
               //starts with one because the first field to be chosen is always present
            var connectorSelectOptions = addRowNumberToOptions(this.props.connectors, i);
            var fileOptions = addRowNumberToOptions(this.state.fileOptions, i+1).length > 0 ? addRowNumberToOptions(this.state.fileOptions, i+1) : addRowNumberToOptions(this.state.fileOptions, i+1);
            var columnSelectOptions = this.props.fieldsConcat ? addRowNumberToOptions(this.props.fieldsConcat[i + 1], i + 2).length > 0 ? addRowNumberToOptions(this.props.fieldsConcat[i + 1], i + 2) : [] : [];

            columns.push(
                <ConcatenatedColumn rowIndex={i} advanced={false} basic={true} isTrueOrFalse={false} isSameAsKey={this.state.isSameAsKey} fileOptions={fileOptions} fieldsValue={this.fieldsValue} onChangeOption={this.onChangeOption}
                    deleteValueRow={this.deleteValueRow} columnSelectOptions={columnSelectOptions} connectorSelectOptions={connectorSelectOptions} oldFields={this.props.oldFields}
                    fields={this.props.fields} identifier={this.props.identifier} identifierName={this.props.identifierName}  />
            )
        }
        for(var i=1; i < this.state.numberOfColsFalse; i+=3) {    //starts with one because the first field to be chosen is always present
            var connectorSelectOptions = addRowNumberToOptions(this.props.connectors, i);
            var fileOptions = addRowNumberToOptions(this.state.fileOptions, i+1).length > 0 ? addRowNumberToOptions(this.state.fileOptions, i+1) : addRowNumberToOptions(this.state.fileOptions, i+1);
            var columnSelectOptions = this.props.falseFieldsConcat ? addRowNumberToOptions(this.props.falseFieldsConcat[0][i+1], i + 2).length > 0 ? addRowNumberToOptions(this.props.falseFieldsConcat[0][i+1], i + 2) : [] : [];

            falseColumns.push(
                <ConcatenatedColumn rowIndex={i} advanced={true} basic={false} isTrueOrFalse={false} isSameAsKey={this.state.isSameAsKey} fileOptions={fileOptions}
                fieldsValue={this.fieldsValue} onChangeOption={this.onChangeOption} deleteValueRow={this.deleteValueRow}
                columnSelectOptions={columnSelectOptions} connectorSelectOptions={connectorSelectOptions} oldFields={this.props.oldFields}
                fields={this.props.fields} identifier={this.props.identifier} falseCondition={this.falseCondition}
                selectFalseDisabled={this.state.selectFalseDisabled} identifierName={this.props.identifierName} />
            )
        }
        let fieldBasic = findOptionByKey(SelectOptions, this.fieldsValue && this.fieldsValue[this.valueAttrName] &&
            this.fieldsValue[this.valueAttrName][0] && this.fieldsValue[this.valueAttrName][0].value
            ? this.fieldsValue[this.valueAttrName][0].value :
            this.fieldsValue[this.valueAttrName] ? this.fieldsValue[this.valueAttrName] : "");
        let subtypeValue = this.falseCondition && this.falseCondition[0] && this.falseCondition[0][0] &&  this.falseCondition[0][0].value ? this.falseCondition[0][0].value : "";
        return(
            <div id="vector-mapping-popup" className="uk-border uk-border-rounded uk-padding-small uk-margin-xmedium-bottom">
            {this.props.isAdvanced ? // advanced filter
            <div>
                <div className="uk-display-flex justify-content-between">
                    <div className="uk-margin-default-bottom">
                        <h5 className="uk-text-capitalize uk-text-bold uk-margin-remove">{this.props.identifierName}</h5>
                        <i className="fal fa-info-circle uk-margin-xsmall-left uk-cursor-pointer" uk-tooltip={INFO_MESSAGE}></i>
                    </div>
                    {!this.props.isKey ?
                    <div className="uk-display-flex">
                        <label className="uk-margin-default-right uk-display-flex align-items-center uk-text-xmedium uk-cursor-pointer">
                            <RadioButton className="radioButton uk-margin-remove" onChange={(ev)=>{this.setSameAsKey(true, this.props.identifier)}}
                                checked={!this.state.isSameAsKey && !this.state.isSameAsNumber}
                                value={"New Number"}
                            />
                            <span className="uk-margin-small-left">{"New "+ this.props.type}</span>
                        </label>
                        {this.props.identifier === "name" && this.props.numberRef && !this.props.numberRef.state.isSameAsKey? 
                        <label className="uk-margin-default-right-left uk-display-flex align-items-center uk-text-xmedium uk-cursor-pointer">
                            <RadioButton className="radioButton uk-margin-remove" onChange={(ev)=>{this.setSameAsKey(false, this.props.identifier)}}
                                checked={this.state.isSameAsNumber}
                                value={"Same As Number"}
                            />
                            <span className="uk-margin-small-left">Same As Number</span>
                        </label>
                        :""}
                        <label className="uk-margin-default-left uk-display-flex align-items-center uk-text-xmedium uk-cursor-pointer">
                            <RadioButton className="radioButton uk-margin-remove" onChange={(ev)=>{this.setSameAsKey(true)}}
                                checked={this.state.isSameAsKey}
                                value={"Same As ID"}
                            />
                            <span className="uk-margin-small-left">Same As ID</span>
                        </label>
                    </div>
                        : ""
                    }
                </div>

                <div className="uk-position-relative">
                {!this.props.isKey && (this.state.isSameAsKey || this.state.isSameAsNumber) ?
                    <div className="overlay"></div>
                    :""
                }
                {conditionValuesContainer}
                <div className="fs-14 uk-margin-default-top-bottom">Value if false</div>
                <div className="identifier-container">
                    <div className="uk-display-flex justify-content-between align-items-center">
                        <div className="uk-display-flex align-items-center uk-text-xmedium">
                            <RadioButton className="radioButton radioButtonMargin" onChange={(e)=>{this.setConditionValue(false,e)}}
                                    value={"manualValue"} checked={!this.state.manualFalseDisabled}
                            />
                            <Input type="text" className="form-control input-width-150 uk-margin-default-left" disabled={this.state.manualFalseDisabled} defaultValue={this.state["defaultValue"] ? this.state["defaultValue"] :""} id = {this.props.identifier+"falseInput"}
                                onChange={(valueObj) => this.onChangeOption(valueObj, this.subtypeIdAttrName, undefined, undefined, false, true)}
                            />
                        </div>
                        <div className="uk-display-flex align-items-center" style={{gap:"0.208vw"}}>
                            <RadioButton className="radioButton radioButtonMargin" onChange={(e)=>{this.setConditionValue(false,e)}}
                            value={"selectValue"} checked={!this.state.selectFalseDisabled}
                            />
                            <Select
                                className="uk-display-inline-block input-width-150"
                                classNamePrefix="vector-mapping"
                                value={findOptionByKey(optionsFalse|| [], this.props.getObjOfSubtypeAttr(optionsFalse, RAW_ITEMS.SUBTYPE_ID, this.falseCondition && this.falseCondition[0] && this.falseCondition[0][0] &&  this.falseCondition[0][0][RAW_ITEMS.SUBTYPE_ID] ?
                                      this.falseCondition[0][0][RAW_ITEMS.SUBTYPE_ID] :"").value ? this.props.getObjOfSubtypeAttr(optionsFalse, RAW_ITEMS.SUBTYPE_ID, this.falseCondition && this.falseCondition[0] && this.falseCondition[0][0] && this.falseCondition[0][0][RAW_ITEMS.SUBTYPE_ID] ?
                                         this.falseCondition[0][0][RAW_ITEMS.SUBTYPE_ID] : "").value : this.props.getObjOfSubtypeAttr(optionsFalse, RAW_ITEMS.SUBTYPE_ID, this.falseCondition ? this.falseCondition && this.falseCondition[0] && this.falseCondition[0][0] && this.falseCondition[0][0][RAW_ITEMS.SUBTYPE_ID] ?
                                             this.falseCondition[0][0][RAW_ITEMS.SUBTYPE_ID] : "" : ""))}
                                options={arrangeFileNames(copyObjectValues(optionsFalse)) || []}
                                onChange={(valueObj) => this.onChangeOption(valueObj, this.subtypeIdAttrName, undefined, undefined, false)}
                                styles={this.subtypeIdAttrName && this.subtypeIdAttrName === VECTOR_MAPPING.FIELDS.VECTOR_NAME_SUBTYPE_ID ? topStyles : bottomStyles}
                                isDisabled={this.state.selectFalseDisabled}
                                placeholder={MESSAGES.vectors_mapping_concatenated_column_file_placeholder}
                                formatOptionLabel={optionLabel}
                                maxMenuHeight= {convertPxToViewport(300)}
                            />
                            {/* <DropDown // to remove comment and comment <Select
                                className="uk-display-inline-block input-width-150 uk-margin-default-left"
                                classNamePrefix="vector-mapping"
                                value={findOptionByKey(optionsFalse|| [], this.props.getObjOfSubtypeAttr(optionsFalse, RAW_ITEMS.SUBTYPE_ID, this.falseCondition && this.falseCondition[0] && this.falseCondition[0][0] &&  this.falseCondition[0][0][RAW_ITEMS.SUBTYPE_ID] ?
                                      this.falseCondition[0][0][RAW_ITEMS.SUBTYPE_ID] :"").value ? this.props.getObjOfSubtypeAttr(optionsFalse, RAW_ITEMS.SUBTYPE_ID, this.falseCondition && this.falseCondition[0] && this.falseCondition[0][0] && this.falseCondition[0][0][RAW_ITEMS.SUBTYPE_ID] ?
                                         this.falseCondition[0][0][RAW_ITEMS.SUBTYPE_ID] : "").value : this.props.getObjOfSubtypeAttr(optionsFalse, RAW_ITEMS.SUBTYPE_ID, this.falseCondition ? this.falseCondition && this.falseCondition[0] && this.falseCondition[0][0] && this.falseCondition[0][0][RAW_ITEMS.SUBTYPE_ID] ?
                                             this.falseCondition[0][0][RAW_ITEMS.SUBTYPE_ID] : "" : ""))}
                                options={arrangeFileNames(copyObjectValues(optionsFalse)) || []}
                                onChange={(valueObj) => this.onChangeOption(valueObj, this.subtypeIdAttrName, undefined, undefined, false)}
                                type={DROPDOWN_TYPE.INPUT}
                                disabled={this.state.selectFalseDisabled}
                                placeholder={MESSAGES.vectors_mapping_concatenated_column_file_placeholder}
                                formatOptionLabel={optionLabel}
                                maxMenuHeight= {convertPxToViewport(300)}
                            /> */}
                            <Select
                                className={"uk-display-inline-block input-width-150" + disabledClass}
                                classNamePrefix="vector-mapping"
                                value={findOptionByKey(falseSelectOptions, this.falseCondition[0] && this.falseCondition[0][0] && this.falseCondition[0][0].value
                                    ? this.falseCondition[0][0].value :
                                    this.falseCondition[0] ? this.falseCondition[0][0] : "")}
                                options={falseSelectOptions}
                                placeholder={MESSAGES.vectors_mapping_concatenated_column_column_placeholder}
                                onChange={(valueObj) => this.onChangeOption(valueObj, this.valueAttrName, VECTOR_MAPPING.ATTR.TYPE_VALUES.FIELD, 0, false)}
                                styles={this.valueAttrName && this.valueAttrName === VECTOR_MAPPING.FIELDS.VECTOR_NAME_VALUE ? topStyles : bottomStyles}
                                isDisabled={this.state.selectFalseDisabled}
                                maxMenuHeight= {convertPxToViewport(300)}
                            />
                            {/* <DropDown // to remove comment and comment <Select
                                className={"uk-display-inline-block input-width-150 uk-margin-default-left" + disabledClass}
                                classNamePrefix="vector-mapping"
                                value={findOptionByKey(falseSelectOptions, this.falseCondition[0] && this.falseCondition[0][0] && this.falseCondition[0][0].value
                                    ? this.falseCondition[0][0].value :
                                    this.falseCondition[0] ? this.falseCondition[0][0] : "")}
                                options={falseSelectOptions}
                                placeholder={MESSAGES.vectors_mapping_concatenated_column_column_placeholder}
                                onChange={(valueObj) => this.onChangeOption(valueObj, this.valueAttrName, VECTOR_MAPPING.ATTR.TYPE_VALUES.FIELD, 0, false)}
                                type={DROPDOWN_TYPE.INPUT}
                                disabled={this.state.selectFalseDisabled}
                                maxMenuHeight= {convertPxToViewport(300)}
                            /> */}
                            <div className={this.state.selectFalseDisabled || !subtypeValue || subtypeValue === ""  ? "uk-margin-default-left uk-button-icon disabled" :"uk-margin-default-left uk-button-icon" } disabled={this.state.selectFalseDisabled || !subtypeValue || subtypeValue === "" } onClick={()=>{this.addColumn(false)}}>
                                <i className={this.state.selectFalseDisabled  || !subtypeValue || subtypeValue === "" ? "fa-lg far fa-plus-circle disabled" : "fa-lg far fa-plus-circle"} disabled={this.state.selectFalseDisabled || !subtypeValue || subtypeValue === "" }></i><span title={MESSAGES.vectors_mapping_concatenated_column_title_long}>{MESSAGES.vectors_mapping_concatenated_column_title}</span>
                            </div>
                        </div>
                    </div>
                    {falseColumns}
                    </div>
                </div>
            </div>
            :
            <div className="uk-display-flex justify-content-between uk-flex-column">
                <div className="uk-margin-default-bottom uk-display-flex justify-content-between">
                    <div>
                        <h5 className="uk-text-capitalize uk-margin-remove uk-text-bold">{this.props.identifierName}</h5>
                        <i className="fs-12 fal fa-info-circle uk-margin-xsmall-left uk-cursor-pointer" uk-tooltip={INFO_MESSAGE} />
                    </div>
                    {!this.props.isKey ?
                    <div className="uk-display-flex">
                        <label className="uk-margin-default-right uk-display-flex align-items-center uk-text-xmedium uk-cursor-pointer">
                            <RadioButton className="radioButton uk-margin-remove" onChange={(ev)=>{this.setSameAsKey(true, this.props.identifier)}}
                                checked={!this.state.isSameAsKey && !this.state.isSameAsNumber}
                                value={"New Number"}
                                defaultChecked={false} label={"New "+ this.props.type}
                            />
                            {/* <span className="uk-margin-small-left">{"New "+ this.props.type}</span> */}
                        </label>
                        {this.props.identifier === "name" && this.props.numberRef && !this.props.numberRef.state.isSameAsKey? 
                        <label className="uk-margin-default-right-left uk-display-flex align-items-center uk-text-xmedium uk-cursor-pointer">
                            <RadioButton className="radioButton uk-margin-remove" onChange={(ev)=>{this.setSameAsKey(false, this.props.identifier)}}
                                checked={this.state.isSameAsNumber && !this.state.isSameAsKey}
                                value={"Same As Number"} label={"Same As Number"}
                            />
                             {/* <span className="uk-margin-small-left">Same As Number</span>  */}
                    </label>
                        :""}
                        <label className="uk-margin-default-left uk-display-flex align-items-center uk-text-xmedium uk-cursor-pointer">
                            <RadioButton  className="radioButton uk-margin-remove" onChange={(ev)=>{this.setSameAsKey(true)}}
                                checked={this.state.isSameAsKey}
                                defaultValue={"Same As ID"}
                                defaultChecked={true} label={"Same As ID"}
                            />
                        </label>
                    </div>
                        : ""
                    }
                    
                </div>

                <div className="uk-position-relative">
                {!this.props.isKey && (this.state.isSameAsKey || this.state.isSameAsNumber)?
                    <div className="overlay"></div>
                    :""
                }
                {
                    this.props.isKey && this.props.is_default === "true" ?
                        <Button 
                            label={MESSAGES.vectors_mapping_concatenated_column_title}
                            title={MESSAGES.vectors_mapping_concatenated_column_title_long}
                            variant={BUTTON_VARIANT.SECONDARY}
                            size={SIZES.DEFAULT}
                            type={BUTTON_TYPE.DEFAULT}
                            disabled={fieldBasic === ""}
                            leftIcon={<i className={"fa-lg far fa-plus-circle"} />}
                        /> 
                         :
                         <Button 
                            label={MESSAGES.vectors_mapping_concatenated_column_title}
                            title={MESSAGES.vectors_mapping_concatenated_column_title_long}
                            variant={BUTTON_VARIANT.SECONDARY}
                            size={SIZES.DEFAULT}
                            type={BUTTON_TYPE.DEFAULT}
                            disabled={fieldBasic === ""}
                            leftIcon={<i className={"fa-lg far fa-plus-circle"} />}
                            onBtnClick={()=>{this.addColumn()}}
                        /> 
                }
                
                <div className="uk-display-flex align-items-center uk-margin-small justify-content-between">
                    <div className="width-400 uk-text-xmedium">{this.props.fileQuestion}</div>
                    <Select
                        className="uk-display-inline-block width-200"
                        classNamePrefix="vector-mapping"
                        value={findOptionByKey(this.state.fileOptions || [], this.props.getObjOfSubtypeAttr(this.state.fileOptions, RAW_ITEMS.SUBTYPE_ID, this.fieldsValue[this.subtypeIdAttrName]).value ? this.props.getObjOfSubtypeAttr(this.state.fileOptions, RAW_ITEMS.SUBTYPE_ID, this.fieldsValue[this.subtypeIdAttrName]).value : this.props.getObjOfSubtypeAttr(this.state.fileOptions, RAW_ITEMS.SUBTYPE_ID, this.fieldsValue[this.subtypeIdAttrName]))}
                        options={arrangeFileNames(this.state.fileOptions) || []}
                        onChange={(valueObj) => this.onChangeOption(valueObj, this.subtypeIdAttrName)}
                        styles={this.subtypeIdAttrName && this.subtypeIdAttrName === VECTOR_MAPPING.FIELDS.VECTOR_NAME_SUBTYPE_ID ? topStyles : bottomStyles}
                        formatOptionLabel={optionLabel}
                        placeholder={MESSAGES.vectors_mapping_concatenated_column_file_placeholder}
                        maxMenuHeight= {convertPxToViewport(300)}
                    />
                    {/* <DropDown // to remove comment and comment <Select
                        className="uk-display-inline-block width-200"
                        classNamePrefix="vector-mapping"
                        value={findOptionByKey(this.state.fileOptions || [], this.props.getObjOfSubtypeAttr(this.state.fileOptions, RAW_ITEMS.SUBTYPE_ID, this.fieldsValue[this.subtypeIdAttrName]).value ? this.props.getObjOfSubtypeAttr(this.state.fileOptions, RAW_ITEMS.SUBTYPE_ID, this.fieldsValue[this.subtypeIdAttrName]).value : this.props.getObjOfSubtypeAttr(this.state.fileOptions, RAW_ITEMS.SUBTYPE_ID, this.fieldsValue[this.subtypeIdAttrName]))}
                        options={arrangeFileNames(this.state.fileOptions) || []}
                        onChange={(valueObj) => this.onChangeOption(valueObj, this.subtypeIdAttrName)}
                        type = {DROPDOWN_TYPE.INPUT}
                        formatOptionLabel={optionLabel}
                        placeholder={MESSAGES.vectors_mapping_concatenated_column_file_placeholder}
                        maxMenuHeight= {convertPxToViewport(300)}
                    /> */}
                </div>
                <div className="uk-display-flex align-items-center uk-margin-small justify-content-between">
                    <div className="width-400 uk-text-xmedium">{this.props.fieldQuestion}</div>
                    <Select
                        className={"uk-display-inline-block width-200 " + disabledClass}
                        classNamePrefix="vector-mapping"
                        value={findOptionByKey(SelectOptions, this.fieldsValue && this.fieldsValue[this.valueAttrName] &&
                            this.fieldsValue[this.valueAttrName][0] && this.fieldsValue[this.valueAttrName][0].value
                            ? this.fieldsValue[this.valueAttrName][0].value :
                            this.fieldsValue[this.valueAttrName] ? this.fieldsValue[this.valueAttrName] : "")}
                        options={SelectOptions}
                        placeholder={MESSAGES.vectors_mapping_concatenated_column_column_placeholder}
                        onChange={(valueObj) => this.onChangeOption(valueObj, this.valueAttrName, VECTOR_MAPPING.ATTR.TYPE_VALUES.FIELD, 0)}
                        styles={this.valueAttrName && this.valueAttrName === VECTOR_MAPPING.FIELDS.VECTOR_NAME_VALUE ? topStyles : bottomStyles}
                        maxMenuHeight= {convertPxToViewport(300)}
                    />
                    {/* <DropDown
                        className={"uk-display-inline-block width-200 " + disabledClass}
                        classNamePrefix="vector-mapping"
                        value={findOptionByKey(SelectOptions, this.fieldsValue && this.fieldsValue[this.valueAttrName] &&
                            this.fieldsValue[this.valueAttrName][0] && this.fieldsValue[this.valueAttrName][0].value
                            ? this.fieldsValue[this.valueAttrName][0].value :
                            this.fieldsValue[this.valueAttrName] ? this.fieldsValue[this.valueAttrName] : "")}
                        options={SelectOptions}
                        placeholder={MESSAGES.vectors_mapping_concatenated_column_column_placeholder}
                        onChange={(valueObj) => this.onChangeOption(valueObj, this.valueAttrName, VECTOR_MAPPING.ATTR.TYPE_VALUES.FIELD, 0)}
                        type={DROPDOWN_TYPE.INPUT}
                        maxMenuHeight= {convertPxToViewport(300)}
                    /> */}
                </div>
                {columns}
                </div>
            </div>}
        </div>
        );
    }
}


export default VectorIdentifier;