import "./stepperContainer.css";
import { ReactComponent as BackIcon } from "../../styles/images/icons/back.svg";
import { ReactComponent as NextIcon } from "../../styles/images/icons/next-white.svg";
import { ReactComponent as ErrorIcon } from "../../styles/images/icons/error.svg";
import { ReactComponent as RevertIcon } from "../../styles/images/icons/arrow-rotate-left.svg";

import NewButton from "../../sections/multipleDimensions/entityListDropDown/NewButton";
import { lang } from "../../language/messages_en";
import {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import { BUTTON_TYPE, BUTTON_VARIANT, SIZES } from "../../class/constants";
import Button from "../../newComponents/Button";
import {suggestEndDate, suggestNewPeriod} from "../../sections/profitStack/helpers";
import {FETCH_METHOD, fetchAPI, FETCHAPI_PARAMS} from "../../class/networkUtils";

const StepperContainer = ({
  body,
  hasBack,
  hasNeedHelp,
  nextBtnText,
  hasNextBtn,
  nextBtnClick,
  backBtnClick,
  hasErrors,
  errorMsg,
  nextBtnClassName="",
  errorMsgClassName="",
  showRollbackButton,
  setOpenConfirmRevert,
  previousPeriod,
  isSkippable,
  skip
}, ref) => {
  const [showErrrors, setShowErrrors] = useState(false);
  const [hasErrorsState, setHasErrorsState] = useState(hasErrors);

  useImperativeHandle(ref, () => ({
    showErrorsDialog:()=>{
      showErrorsDialog();
    },
    hideErrorsDialog:()=>{
      hideErrorsDialog();
    }
  }));

  useEffect(() => {
    setHasErrorsState(hasErrors);
  }, [hasErrors]);

  const onNextBtnClick = () => {
    setShowErrrors(true);
    if(nextBtnClick) { nextBtnClick() }
  }

  let hasErrorsTemp = hasErrors;
  const showErrorsDialog = () => {
    setShowErrrors(true);
    setHasErrorsState(true);
  }
  const hideErrorsDialog = () => {
    setShowErrrors(false);
    setHasErrorsState(false);
  }

  return (
    <div className="step-container">
      <div className="step-box">
        {hasBack && (
          <div className="back-container">
             <Button 
                id="header-back"
                label={"Go Back"}
                variant={BUTTON_VARIANT.TERTIARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                className="back-span"
                onBtnClick={backBtnClick}
                leftIcon={ <BackIcon />}
             />
            {/* "i need help" section is not styled as mockup because it is not used for now and is only added to style it with the back button*/}
            {hasNeedHelp && <div>i need help</div>}
          </div>
        )}
        {body}
        {hasNextBtn && (
            <div style={{ display: "flex", width: "100%", justifyContent: nextBtnClassName === "proceed-margin-left" ? "center" : "start", alignItems: "center"}}>
                <div style={{ display: "flex", flexDirection: "row", width: "38.9vw", columnGap: "8px"}}>
                    <div>
                        <Button
                            id={"monthly-build-next"}
                            label={nextBtnText}
                            variant={BUTTON_VARIANT.PRIMARY}
                            size={SIZES.LARGE}
                            type={BUTTON_TYPE.DEFAULT}
                            className={"uk-margin-top next-btn " + nextBtnClassName}
                            rightIcon={<NextIcon />}
                            onBtnClick={onNextBtnClick}
                        />
                    </div>
                    {!hasBack && showRollbackButton && 
                        <div>
                            <Button
                                id={"confirm-revert-btn"}
                                label={lang.monthly_build.rollback.rollback_btn_introduction.replace("[previousPeriod]", previousPeriod)}
                                variant={BUTTON_VARIANT.SECONDARY}
                                size={SIZES.LARGE}
                                type={BUTTON_TYPE.DEFAULT}
                                className={"uk-margin-top next-btn " + nextBtnClassName}
                                rightIcon={<RevertIcon />}
                                onBtnClick={setOpenConfirmRevert}
                            />
                        </div>
                    }
                    {isSkippable && 
                        <div>
                            <Button
                                id={"skip-btn"}
                                label={lang.monthly_build.tie_out_pl_gl.skip_button}
                                variant={BUTTON_VARIANT.SECONDARY}
                                size={SIZES.LARGE}
                                type={BUTTON_TYPE.DEFAULT}
                                className={"uk-margin-top next-btn " + nextBtnClassName}
                                onBtnClick={()=>skip()}
                            />
                        </div>
                    }
            </div>
        </div>
        )}

        {hasErrorsState && showErrrors && errorMsg && (
            <div style={{ display: "flex", width: "100%", justifyContent: nextBtnClassName === "proceed-margin-left" ? "center" : "start", alignItems: "center" }}>
                <div style={{display:"flex", width:"39vw"}}>
                    <span className={"error-text small-margin-top " + errorMsgClassName}>
                        <ErrorIcon />
                        <span style={{ marginLeft: 5 }} id="step-error-msg">
                            {errorMsg}
                        </span>
                    </span>
                </div>
            </div>
        )}
        
      </div>
    </div>
  );
};

export default forwardRef(StepperContainer);
