import React, { Component } from 'react';
import { FORMULA_ELEMENT_TYPE, STAGING_SECTIONS } from '../../class/constants';
import { addSimpleTooltipIcon, setDraggable } from '../../class/common';
import { getTranslationFile } from '../../class/utils';

const $ = require('jquery');
const lang = getTranslationFile();

class ControlButtons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formula: []
        };

        this.appendToFormula = this.appendToFormula.bind(this);

        this.isCalcCols = this.props.isCalcCols;
    }

    appendToFormula(e) {
        let $node = $(e.target);

        let value = $node.attr("node_value");
        let type = $node.attr("node_type");
        let className = $node.attr("node_class");
        if(this.props.report === STAGING_SECTIONS.PROFIT_STACK_MAPPING) {
            this.props.appendToFormula($node);
        } else if(value && type && className) {
            this.props.appendToFormula(value, type, className);
        }
    }

    static getDerivedStateFromProps(nextProps) {
        return {
            metricConfiguration: nextProps.metricConfiguration
        }
    }

    componentDidMount() {
        if(this.props.report === STAGING_SECTIONS.PROFIT_STACK_MAPPING) {
            setDraggable("draggable-numpd-btn");
        }
    }

    render() {
        var _this = this;
        let positionClass = this.isCalcCols ? "uk-position-none " : "";
        let _isNmpd = this.props.numpadOnly;
        return(
            <div className={(_this.state.metricConfiguration ? "" : _isNmpd ? "w50":"control-btns-container-height") + " control-btns-container" + (_isNmpd ? "-nmpd":"")+ " uk-border uk-border-rounded uk-position-relative"}>
                <div className="fs-14 uk-text-bold">
                    {_isNmpd ?
                        <React.Fragment>
                            {lang.control_btns.title_nmpd}
                        </React.Fragment>
                        :
                        <React.Fragment>
                            {lang.control_btns.title_nmpd}
                        </React.Fragment>
                    }
                </div>
                <div className={"control-btns-inner-container" + (_isNmpd ? "-nmpd uk-margin-xmedium-top uk-margin-small-bottom":"")} onClick={(e)=>this.appendToFormula(e)}>
                    <i className={positionClass+"fal fa-divide fa-lg numpd-btn uk-cursor-pointer"} node_class="fa-divide" node_value="/" node_type={FORMULA_ELEMENT_TYPE.OPERATOR} style={_isNmpd ? {} : {gridArea:"div"}}></i>
                    <i className={positionClass+"fal fa-times fa-lg numpd-btn uk-cursor-pointer"} node_class="fa-times uk-text-emphasis" node_value="*" node_type={FORMULA_ELEMENT_TYPE.OPERATOR} style={_isNmpd ? {} : {gridArea:"mul"}}></i>
                    <i className={positionClass+"fal fa-minus fa-lg numpd-btn uk-cursor-pointer"} node_class="fa-minus" node_value="-" node_type={FORMULA_ELEMENT_TYPE.OPERATOR} style={_isNmpd ? {} : {gridArea:"min"}}></i>
                    <i className={positionClass+"uk-display-flex align-items-center fal fa-plus fa-lg numpd-btn uk-cursor-pointer"+(_isNmpd ? "":" plus-btn-padding")} node_class="fa-plus" node_value="+" node_type={FORMULA_ELEMENT_TYPE.OPERATOR} style={_isNmpd ? {} : {gridArea:"plus"}}></i>
                    {_isNmpd ?
                        <React.Fragment>
                            <span className={positionClass+" numpd-btn uk-cursor-pointer"} node_class="control-buttons" node_value="(" node_type={FORMULA_ELEMENT_TYPE.CONTROL}>(</span>
                            <span className={positionClass+" numpd-btn uk-cursor-pointer"} node_class="control-buttons" node_value=")" node_type={FORMULA_ELEMENT_TYPE.CONTROL}>)</span>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <span className={positionClass+" numpd-btn uk-cursor-pointer"} node_class="control-buttons" node_value="7" node_type={FORMULA_ELEMENT_TYPE.CONTROL} style={{gridArea:"seven"}}>7</span>
                            <span className={positionClass+" numpd-btn uk-cursor-pointer"} node_class="control-buttons" node_value="8" node_type={FORMULA_ELEMENT_TYPE.CONTROL} style={{gridArea:"eight"}}>8</span>
                            <span className={positionClass+" numpd-btn uk-cursor-pointer"} node_class="control-buttons" node_value="9" node_type={FORMULA_ELEMENT_TYPE.CONTROL} style={{gridArea:"nine"}}>9</span>
                            <span className={positionClass+" numpd-btn uk-cursor-pointer"} node_class="control-buttons" node_value="4" node_type={FORMULA_ELEMENT_TYPE.CONTROL} style={{gridArea:"four"}}>4</span>
                            <span className={positionClass+" numpd-btn uk-cursor-pointer"} node_class="control-buttons" node_value="5" node_type={FORMULA_ELEMENT_TYPE.CONTROL} style={{gridArea:"five"}}>5</span>
                            <span className={positionClass+" numpd-btn uk-cursor-pointer"} node_class="control-buttons" node_value="6" node_type={FORMULA_ELEMENT_TYPE.CONTROL} style={{gridArea:"six"}}>6</span>
                            <span className={positionClass+" numpd-btn uk-cursor-pointer"} node_class="control-buttons" node_value="(" node_type={FORMULA_ELEMENT_TYPE.CONTROL} style={{gridArea:"open-par"}}>(</span>
                            <span className={positionClass+" numpd-btn uk-cursor-pointer"} node_class="control-buttons" node_value="1" node_type={FORMULA_ELEMENT_TYPE.CONTROL} style={{gridArea:"one"}}>1</span>
                            <span className={positionClass+" numpd-btn uk-cursor-pointer"} node_class="control-buttons" node_value="2" node_type={FORMULA_ELEMENT_TYPE.CONTROL} style={{gridArea:"two"}}>2</span>
                            <span className={positionClass+" numpd-btn uk-cursor-pointer"} node_class="control-buttons" node_value="3" node_type={FORMULA_ELEMENT_TYPE.CONTROL} style={{gridArea:"three"}}>3</span>
                            <span className={positionClass+" numpd-btn uk-cursor-pointer"} node_class="control-buttons" node_value=")" node_type={FORMULA_ELEMENT_TYPE.CONTROL} style={{gridArea:"close-par"}}>)</span>
                            <span className={positionClass+" numpd-btn uk-cursor-pointer zero-btn-padding"} node_class="control-buttons" node_value="0" node_type={FORMULA_ELEMENT_TYPE.CONTROL} style={{gridArea:"zero"}}>0</span>
                            <span className={positionClass+" numpd-btn uk-cursor-pointer"} node_class="control-buttons" node_value="." node_type={FORMULA_ELEMENT_TYPE.CONTROL} style={{gridArea:"dot"}}>.</span>
                        </React.Fragment>
                    }
                </div>
            </div>
        );
    }
}


export default ControlButtons;