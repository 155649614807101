import { useState } from "react";
import { useParams } from 'react-router-dom';
import PasswordInputField from "./PasswordInputField";
import HttpsRedirect from "react-https-redirect";
import "../../styles/login.css";
import "../../styles/mfa.css";
import ConfirmPasswordInputField from "./ConfirmPasswordInputField";
import { Loader } from "../../form/elements.js";

import { lang } from '../../language/messages_en';
import { BUTTON_TYPE, BUTTON_VARIANT, MFA_REPORTS, SIZES } from "../../class/constants";
import { forwardRef, useEffect, useImperativeHandle } from "react";
import Button from "../../newComponents/Button";
import {onEnterClick} from "../../class/utils";
const  PasswordAndConfirmPasswordValidation = forwardRef((props,_ref) =>{

const [passwordError, setPasswordErr] = useState("");
const [confirmPasswordError, setConfirmPasswordError] = useState("");
const [passwordInput, setPasswordInput]= useState({
    password:'',
    confirmPassword:''
})
useImperativeHandle(_ref, () => ({
  getPasswordInput:() =>{
      return passwordInput.password;
  },
}))
const handlePasswordChange =(evnt)=>{

    const passwordInputValue = evnt.target.value.trim();
    const passwordInputFieldName = evnt.target.name;
    const NewPasswordInput = {...passwordInput,[passwordInputFieldName]:passwordInputValue}
    setPasswordInput(NewPasswordInput);
    
}
const handleValidation= (evnt)=>{

    const passwordInputValue = evnt.target.value.trim();
    const passwordInputFieldName = evnt.target.name;

        //for password 
if(passwordInputFieldName==='password'){
   
    const characterRegExp   = /(?=.*?[A-Za-z])/;
    const symbolRegExp = /^(?![#?!@$%^&*-/(/)_{}|:;,.<>])[A-Za-z]/
    const digitsRegExp      = /(?=.*?[0-9])/;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-/(/)_{}|:;,.<>])/;
    const minLengthRegExp   = /.{8,}/;

    const passwordLength = passwordInputValue.length;
    const charactersPassword = characterRegExp.test(passwordInputValue);
    const startWithSymbol = symbolRegExp.test(passwordInputValue);
    const digitsPassword = digitsRegExp.test(passwordInputValue);
    const specialCharPassword = specialCharRegExp.test(passwordInputValue);
    const minLengthPassword = minLengthRegExp.test(passwordInputValue);

    let errMsg ="";
    if(passwordLength===0){
            errMsg=lang.password_length;
    }else if(!startWithSymbol){
            errMsg=lang.password_start_symbol;
    } else if (!charactersPassword) {
            errMsg=lang.passowrd_contain_digits_symbols;
    }else if(!digitsPassword){
            errMsg=lang.passowrd_contain_digits_symbols;
    }else if(!specialCharPassword){
            errMsg=lang.passowrd_contain_digits_symbols;
    }else if(!minLengthPassword){
            errMsg=lang.password_length;
    }else if(props.samePasswordAsPrevious()){
          errMsg=lang.same_password_error;
    }else{
        errMsg="";
    }
    setPasswordErr(errMsg);
    }

    // for confirm password
    if(passwordInputFieldName=== "confirmPassword" || (passwordInputFieldName==="password" && passwordInput.confirmPassword.length>0) ){
            
        if(passwordInput.confirmPassword!==passwordInput.password)
        {
        setConfirmPasswordError("Both passwords must be matching");
        }else{
        setConfirmPasswordError("");
        }
        
    }

}

  const onEnterEvent = (event) => {
    // If the user presses the "Enter" key on the keyboard
        onEnterClick(event, "save-button");
  }

  const stopEnterEvent = (e) =>{
    $(window).keydown(function(e) {
      if(e.keyCode === 13) {
         e.preventDefault();
      }
    });
    props.onNextClick(e, MFA_REPORTS.SET_PASSWORD)
  }

  let isDisabled = passwordInput.confirmPassword!==passwordInput.password || passwordInput.confirmPassword === "" || passwordInput.password === ""  || passwordError !== "" ? " disabled" : "";

  useEffect(() => {
    if (isDisabled !== ' disabled')
      window.addEventListener("keydown", onEnterEvent);
  })

    return(
        <HttpsRedirect>
        <main id="body">
          <section className="container login-container">
            <div id="phoneNumberForm" className="login-form mrgt5 mrgb10">
              <span className="set_password_title">{lang.mfa.set_password.stmt_one}</span>
                <div className="mrgt10 for_container_text">For <b>{props.email}</b></div>
              <div className="set_password_text_container">
                <span className="set_password_stmt_two">{lang.mfa.set_password.stmt_two}</span>
              </div>
              <section className="mrgt30 mrgb15 section-password-setup login-input-container">
                <div className="row mrgb20">
                  <div className="col-12">
                  <label className="col-12 mrgl0">{lang.mfa.set_password.password_title}</label>
                    <PasswordInputField 
                        handlePasswordChange={handlePasswordChange} 
                        handleValidation={handleValidation} 
                        passwordValue={passwordInput.password} 
                        passwordError={passwordError}/>
                    <label className="col-12 mrgl0">{lang.mfa.set_password.confirm_password_title}</label>
                    <ConfirmPasswordInputField 
                    handlePasswordChange={handlePasswordChange} 
                    handleValidation={handleValidation} 
                    confirmPasswordValue={passwordInput.confirmPassword} 
                    confirmPasswordError={confirmPasswordError}/>
                  </div>
                </div>
                <div className="row rememberBtn">
                  <div className="col-12">
                    {/* <input
                      id="save-button"
                      type="button"
                      value={props.is_mfa && props.verification_link_type !== MFA_REPORTS.RESET_PASSWORD ? "Next" :"Save"}
                      className={"red-btn btn_primary sign-in-button uk-margin-right uk-float-right"+isDisabled}
                      onClick={(e) => {props.is_mfa && props.verification_link_type !== MFA_REPORTS.RESET_PASSWORD ? props.onNextClick(e, MFA_REPORTS.SET_PASSWORD):stopEnterEvent(e); }}
                    /> */}
                    <Button
                      label={props.is_mfa && props.verification_link_type !== MFA_REPORTS.RESET_PASSWORD ? "Next" :"Save"}
                      id="save_reset_password"
                      className={"max_width"+isDisabled}
                      variant={BUTTON_VARIANT.PRIMARY}
                      size={SIZES.LARGE}
                      type={BUTTON_TYPE.DEFAULT}
                      onBtnClick={(e) => {props.is_mfa && props.verification_link_type !== MFA_REPORTS.RESET_PASSWORD ? props.onNextClick(e, MFA_REPORTS.SET_PASSWORD):stopEnterEvent(e); }}
                    />
                    <Loader />
                  </div>
                </div>
              </section>
            </div>
          </section>
        </main>
      </HttpsRedirect>
    )
})

export default PasswordAndConfirmPasswordValidation;