import { combineReducers } from 'redux';
import {periodsStatus,datasetOptions, tiers, scenarios, customViewData, profitStackByCostHierarchy, profitFormats, vectorState, periodsStatusState, scenarioState, datasetState, customViewDataState }  from './scenariosReducers';
import {userSettings as userSettings2, userSections as userSections2} from './userSettingsReducer';
import {userSettings, userSectionsData} from '../templateLayout/reducers/userSettingsReducer';
import {quadrantTierOptions, psLinesOptions} from './filterReducers';
import {profileColumns, profiles, customGroups, stateProfiles, columnProfiles} from './manageColumnsReducers';
import { heatmapListData, heatmapObjBeforeBack } from './heatMapReducers';
import { drillListData, listData, listObjBeforeBack } from './listReducers';
import { landscapeBubbleListData, landscapeBubbleObjBeforeBack, landscapeCostHierarchy } from './landscapeBubbleReducers';
import { geographyCostHierarchy, geographyListData, geographyObjBeforeBack } from './geographyConcentrationReducers';
import { bridgeOptionsState } from '../templateLayout/reducers/bridgeOptionsReducer';
import { imagesSignedUrls } from '../templateLayout/reducers/imageSignedUrlsReducer';
import { vectorEntities } from '../templateLayout/reducers/vectorEntitiesReducer';

const allReducers = combineReducers({
  userSettings: userSettings2,
  userSections: userSections2,
  periodsStatus: periodsStatus,
  quadrantTierOptions:quadrantTierOptions,
  psLinesOptions:psLinesOptions,
  datasetOptions:datasetOptions,
  tiers:tiers,
  scenarios:scenarios,
  customViewData: customViewData,
  profileColumns:profileColumns,
  profiles:profiles,
  customGroups:customGroups,
  profitStackByCostHierarchy:profitStackByCostHierarchy,
  profitFormats: profitFormats,
  stateProfiles: stateProfiles,
  columnProfiles: columnProfiles,
  heatmapObjBeforeBack: heatmapObjBeforeBack,
  heatmapListData: heatmapListData,
  landscapeBubbleObjBeforeBack: landscapeBubbleObjBeforeBack,
  landscapeBubbleListData: landscapeBubbleListData,
  landscapeCostHierarchy: landscapeCostHierarchy,
  geographyObjBeforeBack: geographyObjBeforeBack,
  geographyListData: geographyListData,
  geographyCostHierarchy: geographyCostHierarchy,
  // New reducers
  clientUserSettings: userSettings,
  clientUserSections: userSectionsData,
  vectorState: vectorState,
  periodsStatusState:periodsStatusState,
  scenarioState:scenarioState,
  datasetState: datasetState,
  customViewDataState: customViewDataState,
  bridgeOptionsState: bridgeOptionsState,
  imagesSignedUrls: imagesSignedUrls,
  drillListData: drillListData,
  listData: listData,
  listObjBeforeBack: listObjBeforeBack,
  vectorEntities: vectorEntities
});
  
export default allReducers;

