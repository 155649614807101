import React from "react";
import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import PropTypes from "prop-types";
import './draggable.css'

const DraggableOption = ({id, title}) => {
    /**
      attributes: These are attributes that should be spread onto the draggable element to ensure proper accessibility and functionality.
      listeners: These are event listeners that need to be spread onto the draggable element to handle drag events (e.g., onMouseDown, onTouchStart).
      setNodeRef: A ref-setting function that should be attached to the draggable element. It allows @dnd-kit to manage the DOM node for dragging.
      transform: An object representing the current transform state of the item (its x and y translation values). This is used to move the item smoothly on the screen.
      transition: A string representing the CSS transition to apply to the draggable element, typically used to animate the transform property during and after a drag.
     */
    const {attributes, listeners, setNodeRef, transform, transition} = useSortable({id});

    const style = {
        transition,
        transform: CSS.Translate.toString({
            x: 0,
            y: transform ? transform.y : 0,
        }),
    };

    return (
        <div className="draggable-node" ref={setNodeRef} {...attributes} {...listeners} style={style}>
            <div className="drag-handle-container">
                <div className="drag-handle"></div>
                <div className="drag-handle"></div>
                <div className="drag-handle"></div>
            </div>
            <div>{title}</div>
        </div>
    );
};

//This defines the expected types for the props that DraggableOption should receive:
DraggableOption.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    title: PropTypes.string.isRequired,
};

export {DraggableOption};
