import { lang } from "../language/messages_en";

const Footer = ({ className, footerBody }) => {
  return (
    <footer className={className}>
      {footerBody}
    </footer>
  );
};

export { Footer };
