import React, { Component } from 'react';
import 'jquery';
import { getTranslationFile, copyObjectValues} from '../../class/utils.js';
import {API_URL, BUTTON_TYPE, BUTTON_VARIANT, DIALOG_SIZE, FISCAL_YEAR, SIZES} from '../../class/constants';
import FiscalPeriodTable from '../../tables/FiscalPeriodTable.js';
import Popup from 'react-popup';
import { lang } from '../../language/messages_en.js';
import { toggleLoader, setLocalStorageValueByParameter } from '../../class/common.js';
import { deleteItemFromSession, FETCH_METHOD, fetchAPI, FETCHAPI_PARAMS } from '../../class/networkUtils.js';

import Button from '../../newComponents/Button.js';
import Modal from '../../newComponents/Modal.js';
import {updatePeriodsStatusState} from "../../actions/scenariosActions";

const MESSAGES = getTranslationFile();
const $ = require('jquery');

const baseUrl = process.env.REACT_APP_BASE_URL;

const data = [];
const tableData = [{[FISCAL_YEAR.FIELDS.YEAR]:"",[FISCAL_YEAR.FIELDS.PERIOD]:"",[FISCAL_YEAR.FIELDS.START_DATE]:"",[FISCAL_YEAR.FIELDS.END_DATE]:"",[FISCAL_YEAR.FIELDS.QUARTER]:"", [FISCAL_YEAR.FIELDS.ROW_STATUS]:FISCAL_YEAR.ROW_STATUS_VALUES.EDITED}];
const columns = [
    {
        title: FISCAL_YEAR.FIELDS.PERIOD_APPLY_STATUS,
        field: FISCAL_YEAR.FIELDS.PERIOD_APPLY_STATUS,
        visible: false
    },
    {
        title: FISCAL_YEAR.FIELDS.ROW_STATUS,
        field: FISCAL_YEAR.FIELDS.ROW_STATUS,
        visible: false
    },
    {
        title: FISCAL_YEAR.FIELDS.PERIOD_STATUS,
        field: FISCAL_YEAR.FIELDS.PERIOD_STATUS,
        visible: false
    },
    {
        title: FISCAL_YEAR.TITLES.YEAR,
        field: FISCAL_YEAR.FIELDS.YEAR,
        visible: true
    },
    {
        title: FISCAL_YEAR.TITLES.PERIOD,
        field: FISCAL_YEAR.FIELDS.PERIOD,
        visible: true,
    },
    {
        title: FISCAL_YEAR.TITLES.QUARTER,
        field: FISCAL_YEAR.FIELDS.QUARTER,
        visible: true
    },
    {
        title: FISCAL_YEAR.TITLES.START_DATE,
        field: FISCAL_YEAR.FIELDS.START_DATE,
        visible: true
    },
    {
        title: FISCAL_YEAR.TITLES.END_DATE,
        field: FISCAL_YEAR.FIELDS.END_DATE,
        visible: true
    },
    {
        title: "",
        field: FISCAL_YEAR.FIELDS.ACTIONS,
        visible: true
    }
    
];

const firstPeriodColumns = [ 
    {
        title: FISCAL_YEAR.FIELDS.PERIOD_APPLY_STATUS,
        field: FISCAL_YEAR.FIELDS.PERIOD_APPLY_STATUS,
        visible: false
    },
    {
        title: FISCAL_YEAR.FIELDS.ROW_STATUS,
        field: FISCAL_YEAR.FIELDS.ROW_STATUS,
        visible: false
    },
    {
        title: FISCAL_YEAR.TITLES.YEAR,
        field: FISCAL_YEAR.FIELDS.YEAR,
    },
    {
        title: FISCAL_YEAR.TITLES.PERIOD,
        field: FISCAL_YEAR.FIELDS.PERIOD,
    },
    {
        title: FISCAL_YEAR.TITLES.START_DATE,
        field: FISCAL_YEAR.FIELDS.START_DATE,
    },
    {
        title: FISCAL_YEAR.TITLES.END_DATE,
        field: FISCAL_YEAR.FIELDS.END_DATE,
    },
    {
        title: FISCAL_YEAR.TITLES.QUARTER,
        field: FISCAL_YEAR.FIELDS.QUARTER,
    } 
    
];

/**
 * Fiscal Calendar accessed through menu-Adminstration-Manage Setting.
 * A new setting introduced to set the periods for each client by setting start Date  and predicting the end Date
 * and making sure no periods overlap with each others 
 * @author [Sarah Farjallah]
 * @extends Component
 */class FiscalYear extends Component {
		
	constructor(props) {
		super(props);
		this.state = {
            showing:false,
            isFY: false,
            data: this.props.periods,
            previousVersion: copyObjectValues(this.props.periods),
            cell: {},
            isChanged: false
        };
       
        this.toggleTable = this.toggleTable.bind(this);
        this.addNewPeriod = this.addNewPeriod.bind(this);
        this.validatePeriod = this.validatePeriod.bind(this);
        this.addPeriod = this.addPeriod.bind(this);
        this.addYear = this.addYear.bind(this);
        this.addQuarter = this.addQuarter.bind(this);
        this.addDate = this.addDate.bind(this);
        this.discardChange = this.discardChange.bind(this);
        this.savePreviousVersion = this.savePreviousVersion.bind(this);
        this.deactivatePeriod = this.deactivatePeriod.bind(this);
        this.showDeletePopup = this.showDeletePopup.bind(this);
        this.getLatestPeriod = this.getLatestPeriod.bind(this);
    }

    /**
     * function validates that all fields are filled and that start date is less than end date in the first period dialog
     */
    validatePeriod() {
        if (this.firstPeriodTableRef) {
            var table = this.firstPeriodTableRef.tabulator.getData();
            var startDate = table[0][FISCAL_YEAR.FIELDS.START_DATE] !== "" ? Date.parse(table[0][FISCAL_YEAR.FIELDS.START_DATE]) : "";
            var endDate = table[0][FISCAL_YEAR.FIELDS.END_DATE] !== "" ? Date.parse(table[0][FISCAL_YEAR.FIELDS.END_DATE]) : "";
            var validated = true;
            if (table[0][FISCAL_YEAR.FIELDS.YEAR] === "" || table[0][FISCAL_YEAR.FIELDS.PERIOD] === "" ||
                table[0][FISCAL_YEAR.FIELDS.QUARTER] === "" || table[0][FISCAL_YEAR.FIELDS.START_DATE] === "" 
                || table[0][FISCAL_YEAR.FIELDS.END_DATE] === "") {
                     this.setInfoDialogOpen(true, MESSAGES.fill_all_period_fields);
                     validated = false;
                     return;
            } else if(startDate > endDate) {
                this.setInfoDialogOpen(true, MESSAGES.start_data_less_than_end_date);
                validated = false;
                return;
            }
            if (validated) {
                this.addNewPeriod(table[0]);
                this.setOpenAddFirstPeriodDialogOpen(false)
            }
        }
    }
    
    /**
     * @description function opens new period dialog if no periods have been defined and adds periods to table if there's already existing periods
     * @param {object}
     */
    addNewPeriod (periodData) {
        this.savePreviousVersion(copyObjectValues(this.state.data), true)
        if (periodData) {
            var row = [{
            [FISCAL_YEAR.FIELDS.YEAR]:periodData[FISCAL_YEAR.FIELDS.YEAR],
            [FISCAL_YEAR.FIELDS.PERIOD]: periodData[FISCAL_YEAR.FIELDS.PERIOD],
            [FISCAL_YEAR.FIELDS.START_DATE]: periodData[FISCAL_YEAR.FIELDS.START_DATE],
            [FISCAL_YEAR.FIELDS.END_DATE]: periodData[FISCAL_YEAR.FIELDS.END_DATE],
            [FISCAL_YEAR.FIELDS.QUARTER]: periodData[FISCAL_YEAR.FIELDS.QUARTER],
            [FISCAL_YEAR.FIELDS.ROW_STATUS]: periodData[FISCAL_YEAR.FIELDS.QUARTER],
            [FISCAL_YEAR.FIELDS.ROW_STATUS]: FISCAL_YEAR.ROW_STATUS_VALUES.EDITED,
            [FISCAL_YEAR.FIELDS.PERIOD_STATUS]: FISCAL_YEAR.PERIOD_STATUS_VALUES.NEW,
            [FISCAL_YEAR.FIELDS.PERIOD_APPLY_STATUS]: FISCAL_YEAR.PERIOD_APPLY_VALUES.APPLIED,
            [FISCAL_YEAR.FIELDS.ACTIVE_STATUS]: FISCAL_YEAR.PERIOD_STATUS_VALUES.ACTIVE,
            }];
            var newData = row.concat(data);
            let activePeriodsLength = newData.filter(e=>e.is_active === FISCAL_YEAR.PERIOD_STATUS_VALUES.ACTIVE).length;
            this.setState({
                data: newData,
                activePeriodsLength: activePeriodsLength,
            })
        } else if (!periodData && this.periodTableRef.tabulator.getData().length === 0) {
            this.setOpenAddFirstPeriodDialogOpen(true)
        } else {
            if (this.state.isFY) {
                this.addPeriod(12);
            } else {
                this.addPeriod(1);
            }
          
        }
    }

    /**
     * @description function returns latest period in data. See {@link FISCAL_YEAR}   
     * @param {Array} data 
     * @returns {object} object of the latest period
     */

    getLatestPeriod(data) {
        var periodRow = data[0];
        for (var e in data) {
            if(Number(data[e][FISCAL_YEAR.FIELDS.YEAR]) >= Number(periodRow[FISCAL_YEAR.FIELDS.YEAR]) &&
            Number(data[e][FISCAL_YEAR.FIELDS.QUARTER].split("Q")[1]) >= Number(periodRow[FISCAL_YEAR.FIELDS.QUARTER].split("Q")[1])
            && Number(data[e][FISCAL_YEAR.FIELDS.PERIOD].split("P")[1]) >= Number(periodRow[FISCAL_YEAR.FIELDS.PERIOD].split("P")[1])) {
                periodRow = data[e];
            }
        }
        return periodRow;
    }

    /**
     * function suggests a year for the user (each year is present in 4 quarters it it crosses them it increments the year)
     */
    addYear(){
        var table = this.periodTableRef.tabulator.getData();
        var latestPeriod = this.getLatestPeriod(table);
        var period = latestPeriod[FISCAL_YEAR.FIELDS.PERIOD];
        var year = latestPeriod[FISCAL_YEAR.FIELDS.YEAR];
        if (period === "P12" || period === "P13") {
            return (Number(year)+1).toString();
        } else {
            return year.toString();
        }
    }

    /**
     * function suggests a quarter according to the previous quarter
     */
    addQuarter() {
        var table = this.periodTableRef.tabulator.getData();
        var latestPeriod = this.getLatestPeriod(table);
        var count = 0;
        var quarter = latestPeriod[FISCAL_YEAR.FIELDS.QUARTER];
        for (var e in table) {
            if (table[e][FISCAL_YEAR.FIELDS.YEAR] === latestPeriod[FISCAL_YEAR.FIELDS.YEAR]
                && table[e][FISCAL_YEAR.FIELDS.QUARTER] === quarter) {
                    count++;
                }
        }
        if (count === 3) {
            if (quarter === "Q4") {
                return FISCAL_YEAR.FIELDS.QUARTERS_VALUES[0];
            } else {
                return "Q"+((Number(quarter.split("Q")[1]))+1).toString();
    
            } 
        } else {
            return quarter;
        }
       
    }

    /**
     * function adds period to new row according to the previous period
     */
    addPeriodValue(){
        var table = this.periodTableRef.tabulator.getData();
        var latestPeriod = this.getLatestPeriod(table);
        var period = latestPeriod[FISCAL_YEAR.FIELDS.PERIOD];
        if (period === "P12" || period === "P13") {
            return FISCAL_YEAR.FIELDS.PERIODS_VALUES[0];
        } else {
            if ((Number(period.split("P")[1])) >= 9) {
                return "P"+((Number(period.split("P")[1]))+1).toString();
            } else {
                return "P0"+((Number(period.split("P")[1]))+1).toString();
            }
        }
    }
    /**
     * function suggests start and end dates according to previous periods if exist and calculates them if not
     * @param {*} field 
     * @param {*} year 
     * @param {*} period 
     * @param {*} startDate 
     */
    addDate(field, year, period, startDate) {
        var table = this.periodTableRef.tabulator.getData();
        var latestPeriod = this.getLatestPeriod(table);
        var endDate = latestPeriod[FISCAL_YEAR.FIELDS.END_DATE] ? latestPeriod[FISCAL_YEAR.FIELDS.END_DATE].split("T")[0] : 0;
        var date = new Date(endDate+"T23:00:00");
        var existPrevious = true;
        date.setDate(date.getDate()+1);
        if (field === FISCAL_YEAR.FIELDS.START_DATE) {
            var year =  date.getFullYear();
            var month = date.getMonth()+1 < 10 ? "0"+ (date.getMonth()+1) :  date.getMonth()+1;
            var day = date.getDate() < 10 ? "0"+ (date.getDate()) :  date.getDate();
            return year +"-"+month+"-"+day;
        }
        if (field === FISCAL_YEAR.FIELDS.END_DATE) {
            // if (table.length > 12) {
            //    var date =  table.filter(e=>e[FISCAL_YEAR.FIELDS.YEAR] === (year-1).toString() && e[FISCAL_YEAR.FIELDS.PERIOD] === period); 
            //    if (date.length > 0) {
            //        var previousYear = (year-1).toString();
            //        return date[0][FISCAL_YEAR.FIELDS.END_DATE].split("T")[0].replace(previousYear, year.toString());
            //    } else{
            //     existPrevious = false;
            //     }
            // }else {
            //     existPrevious = false;
            // }
            // if (!existPrevious) {
                var date = new Date(startDate+"T23:00:00");
                date.setMonth(date.getMonth() + 1);
                date.setDate(date.getDate() - 1);
                var year =  date.getFullYear();
                var month = date.getMonth()+1 < 10 ? "0"+ (date.getMonth()+1) :  date.getMonth()+1;
                var day = date.getDate() < 10 ? "0"+ (date.getDate()) :  date.getDate();
                return year +"-"+month+"-"+day;
            // }
        } 
    }
    /**
     * function will discard changes if a period is not newly created and applied and discard the whole row if created and not applied
     * @param {*} row 
     */
    discardChange(row) {
        let _this = this;
        let activePeriodsLength = "";
        if (row[FISCAL_YEAR.FIELDS.PERIOD_STATUS] === FISCAL_YEAR.PERIOD_STATUS_VALUES.OLD || 
            (row[FISCAL_YEAR.FIELDS.PERIOD_STATUS] === FISCAL_YEAR.PERIOD_STATUS_VALUES.NEW && row[FISCAL_YEAR.FIELDS.PERIOD_APPLY_STATUS] === FISCAL_YEAR.PERIOD_APPLY_VALUES.APPLIED)) {
                this.state.previousVersion.map((e)=> {if(e[FISCAL_YEAR.FIELDS.YEAR] === row[FISCAL_YEAR.FIELDS.YEAR] && e[FISCAL_YEAR.FIELDS.PERIOD] === row[FISCAL_YEAR.FIELDS.PERIOD]) {
                    e[FISCAL_YEAR.FIELDS.ROW_STATUS] = FISCAL_YEAR.ROW_STATUS_VALUES.ADDED
                }});
                activePeriodsLength = this.state.previousVersion.filter(e=>e.is_active === FISCAL_YEAR.PERIOD_STATUS_VALUES.ACTIVE).length;
                this.setState({
                    data: copyObjectValues(this.state.previousVersion),
                    activePeriodsLength: activePeriodsLength
                },function(){
                    _this.periodTableRef.tabulator.replaceData(this.state.data);
                    _this.periodTableRef.addListeners();
                });
        }else {
            var  table = this.periodTableRef.tabulator.getData();
            for (var e in table) {
                if (table[e][FISCAL_YEAR.FIELDS.YEAR] === row[FISCAL_YEAR.FIELDS.YEAR] && 
                    table[e][FISCAL_YEAR.FIELDS.QUARTER] === row[FISCAL_YEAR.FIELDS.QUARTER] &&
                    table[e][FISCAL_YEAR.FIELDS.PERIOD] === row[FISCAL_YEAR.FIELDS.PERIOD]){
                        table.splice(e,1);
                    }
            }
            activePeriodsLength = table.filter(e=>e.is_active === FISCAL_YEAR.PERIOD_STATUS_VALUES.ACTIVE).length;
            this.setState({
                data: table,
                previousVersion: copyObjectValues(table),
                activePeriodsLength: activePeriodsLength
            },function(){
                this.periodTableRef.tabulator.replaceData(table);
                _this.periodTableRef.addListeners();
            })
        }
    }

    /**
     * 
     * @param {*} data 
     */
    savePreviousVersion(data, comp) {
        let tempState={}
        let _this = this;
        tempState.previousVersion= data;
        if (!comp) {
            let activePeriodsLength = this.periodTableRef.tabulator.getData().filter(e=>e.is_active === FISCAL_YEAR.PERIOD_STATUS_VALUES.ACTIVE).length;
            tempState.data = data;
            tempState.activePeriodsLength= activePeriodsLength;
            tempState.isChanged = true;
        }
        
        this.setState(tempState,function(){
            _this.periodTableRef.addListeners();
        })
    }

    /**
     * 
     * @param {*} cell 
     */
    showDeletePopup(cell) {
        this.setState({
            cell:cell
        }, function(){
          this.setOpenDeactivatePeriodConfirmDialog(true)
        })
    }

    /**
     * functions deactivates the selected period
     */
    deactivatePeriod() {
        let _this = this;
        let cell = this.state.cell;
        let activePeriodsLength = this.periodTableRef.tabulator.getData().filter(e=>e.is_active === FISCAL_YEAR.PERIOD_STATUS_VALUES.ACTIVE).length;
        this.state.data.map((e)=>{
            if (e[FISCAL_YEAR.FIELDS.PERIOD] === cell[FISCAL_YEAR.FIELDS.PERIOD] && e[FISCAL_YEAR.FIELDS.YEAR] === cell[FISCAL_YEAR.FIELDS.YEAR]){
                e[FISCAL_YEAR.FIELDS.OLD_STATUS] = e[FISCAL_YEAR.FIELDS.PERIOD_STATUS];
                e[FISCAL_YEAR.FIELDS.ACTIVE_STATUS] = FISCAL_YEAR.PERIOD_STATUS_VALUES.INACTIVE;
            }
        });
        let data = this.state.data;
        this.setState({
            isChanged: true,
            data:data,
            previousVersion: copyObjectValues(data),
            activePeriodsLength: activePeriodsLength -1,
        },function(){
          _this.periodTableRef.tabulator.replaceData(this.state.data);
			    _this.periodTableRef.addListeners();
          _this.setOpenDeactivatePeriodConfirmDialog(false)
        })
    } 

    /**
     * 
     * @param {*} nextProps 
     */
    setActivePeriodsLength=(param)=>{
        this.setState({
            activePeriodsLength: param
        })
    }

    // static getDerivedStateFromProps(nextProps, prevState) {
    //     let activePeriodsLength = nextProps.periods.filter(e=>e.is_active === FISCAL_YEAR.PERIOD_STATUS_VALUES.ACTIVE).length;
    //     return ({
    //         activePeriodsLength: activePeriodsLength,
    //         data: nextProps.periods,
    //         previousVersion: copyObjectValues(nextProps.periods)
    //     })
    // }
    componentDidUpdate(prevProps) {
        if ((this.props !== prevProps && !this.props.isChangingTab)) {
            let activePeriodsLength = this.props.periods.filter(e=>e.is_active === FISCAL_YEAR.PERIOD_STATUS_VALUES.ACTIVE).length;
            this.setState({
                activePeriodsLength: activePeriodsLength,
                data: this.state.data?.length ? this.state.data : this.props.periods,
                previousVersion: copyObjectValues(this.props.periods)
            },function(){
                    $("#add_period").show();
                    $("#add_period_12").show();
            });
        }
    }

    /**
     * functions add the period row to table
     * @param {*} iteration 
     */
    addPeriod(iteration) {
        for (var i=0; i<iteration; i++) {
            var year = this.addYear();
            var period = this.addPeriodValue();
            var startDate = this.addDate(FISCAL_YEAR.FIELDS.START_DATE, year, period);
            var endDate = this.addDate(FISCAL_YEAR.FIELDS.END_DATE, year, period, startDate);
            var quarter = this.addQuarter();
            var row = [{[FISCAL_YEAR.FIELDS.YEAR]:year,
                [FISCAL_YEAR.FIELDS.PERIOD]: period,
                [FISCAL_YEAR.FIELDS.START_DATE]: startDate,
                [FISCAL_YEAR.FIELDS.END_DATE]: endDate,
                [FISCAL_YEAR.FIELDS.QUARTER]: quarter,
                [FISCAL_YEAR.FIELDS.ROW_STATUS]: FISCAL_YEAR.ROW_STATUS_VALUES.EDITED,
                [FISCAL_YEAR.FIELDS.PERIOD_STATUS]: FISCAL_YEAR.PERIOD_STATUS_VALUES.NEW,
                [FISCAL_YEAR.FIELDS.PERIOD_APPLY_STATUS]: FISCAL_YEAR.PERIOD_APPLY_VALUES.NOT_APPLIED,
                [FISCAL_YEAR.FIELDS.ACTIVE_STATUS]: FISCAL_YEAR.PERIOD_STATUS_VALUES.ACTIVE,
            }];
            this.periodTableRef.tabulator.addRow(row);
            var newData = row.concat(this.state.data);
            this.state.data = newData;               
        }
        let activePeriodsLength = this.state.data.filter(e=>e.is_active === FISCAL_YEAR.PERIOD_STATUS_VALUES.ACTIVE).length;
        this.state.activePeriodsLength = activePeriodsLength;
        this.state.isFY = false;
        this.forceUpdate();
        $("#add_period").hide();
        $("#add_period_12").hide();
        this.periodTableRef.addListeners();
    }

    turnFY(value) {
        this.setState({
            isFY: value
        },function(){
            this.addNewPeriod();
        })

    }

    toggleTable(showing){
        if (this.state.data.length === 0) {
            $("#add_period").hide();
            $("#add_period_12").hide();
        }
        this.periodTableRef.toggleTable(showing);
        if (showing) {
            $("#add_period").show();
            $("#add_period_12").show();
        }else {
            $("#add_period").hide();
            $("#add_period_12").hide();
        }
        
    }

    /**
     * to inform the user when the line is inherited
     */
     launchToast() {
        $("#toastFiscalCalendar").addClass("show");
        setTimeout(function(){
            $("#toastFiscalCalendar").removeClass("show");
        }, 4000);
    }

    /**
     * copied from settings.js to save new periods
     */
    saveFiscalPeriods=(switchAfter, callback)=> {
        let _this = this;
        var data = [];
        if(_this.periodTableRef) {
            data = _this.periodTableRef.tabulator.getData();
        }
        var query = {
            action: "saveFiscalPeriods",
            fiscalPeriods: data
        }

        let onThenCallback = () => {
            _this.setState({
                message:lang.saved,
                isError: false,
            },function(){
                _this.launchToast();
            })
       }

       let onErrorCallback = () => {
            _this.setState({
                message:lang.not_saved,
                isError: true,
            },function(){
                _this.launchToast();
            })
       }

       let onCompleteCallback = () => {
            deleteItemFromSession("getClientPeriods");
            _this.props.getClientPeriods(true,switchAfter, callback);// get new client periods
            $("#add_period").show();
            $("#add_period_12").show();
            _this.props.dispatch(updatePeriodsStatusState([], undefined, true));
       }

        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "saveFiscalPeriods",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader] : true,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.onErrorCallback]: onErrorCallback,
            [FETCHAPI_PARAMS.onCompleteCallback]: onCompleteCallback,
            [FETCHAPI_PARAMS.screenName]:lang.observability.modeling_settings.periods.screen_name,
            [FETCHAPI_PARAMS.requestDescription]:lang.observability.modeling_settings.periods.requests_description.save_fiscal_periods
        }
        fetchAPI(fetchOptions);
    }


    /**
     * copied from settings.js to save new periods
     */
    // saveFiscalPeriods() {
    //     toggleLoader(true, 'saveFiscalPeriods');
    //     var data = [];
    //     if(this.periodTableRef) {
    //         data = this.periodTableRef.tabulator.getData();
    //     }
    //     var query = {
    //         action: "saveFiscalPeriods",
    //         fiscalPeriods: data
    //     }

    //     var myUrl = baseUrl+"/DataModeling";
    //     $.ajax({
    //         url: myUrl, 
    //         type: 'POST',
    //         async: true, 
    //         crossDomain:true, 
    //         xhrFields: { withCredentials: true }, 
    //         data:JSON.stringify(query), 
    //         complete: function () {
    //             toggleLoader(false, 'saveFiscalPeriods');
    //             window.location.reload();
    //         }
    //     }); 
    // }

    setLimits=()=>{
        let _this = this;
        let activePeriodsLength = _this.props.periods.filter(e=>e.is_active === FISCAL_YEAR.PERIOD_STATUS_VALUES.ACTIVE).length;
        _this.setState({
            dataLength: _this.props.periods.length,
            activePeriodsLength: activePeriodsLength
        },function(){
            _this.periodTableRef.addListeners();
        })
        
    }

    /**
     * show warning on reset
     */
    showResetWarningPopup() {
      this.setOpenResetDialog(true)
    }

    /**
     * reset all changes in the fiscal period tabulator
     */
    resetFiscalPeriods() {
        let _this = this;
        let originalData = copyObjectValues(this.props.fiscalOriginalPeriods);
        if(_this.periodTableRef) {
            _this.setState({
                data: originalData,
                activePeriodsLength: originalData.filter(e=>e.is_active === FISCAL_YEAR.PERIOD_STATUS_VALUES.ACTIVE).length,
                isChanged: false,
            },function(){
                _this.periodTableRef.tabulator.replaceData(this.state.data);
                _this.periodTableRef.addListeners();
                _this.setOpenResetDialog(false)
                $("#add_period").show();
                $("#add_period_12").show();
            })
        }
    }

    setOpenDeactivatePeriodConfirmDialog = (isOpen) => {
      let _this = this;
      _this.setState({
        openDeactivatePeriodConfirmDialog: isOpen
      })
    }

   openDeactivatePeriodConfirmDialogContent = () => {
     return (
       <div className="uk-display-flex uk-flex-middle pi-warning-background uk-border-rounded uk-padding-xsmall ">
         <i className="fa-2x fal fa-exclamation-triangle uk-margin-default-right" />
         <div className="fs-16">{lang.dashboards.messages.confirm_deactivate_period}</div>
       </div>
     )
   }

   openDeactivatePeriodConfirmDialogActions = () => {
     return (
       <>
         <Button
           id="create-scenario"
           label={lang.modal.buttons.deactivate}
           variant={BUTTON_VARIANT.PRIMARY}
           size={SIZES.DEFAULT}
           type={BUTTON_TYPE.DEFAULT}
           onBtnClick={this.deactivatePeriod}
         />
         <Button
           label={lang.modal.buttons.cancel}
           variant={BUTTON_VARIANT.SECONDARY}
           size={SIZES.DEFAULT}
           type={BUTTON_TYPE.DEFAULT}
           onBtnClick={() => this.setOpenDeactivatePeriodConfirmDialog(false)}
         />
       </>
     )
   }
   
   setOpenResetDialog = (isOpen) => {
     let _this = this;
     _this.setState({
       openResetDialog: isOpen
     })
   }

   openResetDialogActions = () => {
     return (
       <>
         <Button
           label={lang.navigation.btns.discard}
           variant={BUTTON_VARIANT.PRIMARY}
           size={SIZES.DEFAULT}
           type={BUTTON_TYPE.DEFAULT}
           onBtnClick={() => { this.resetFiscalPeriods() }}
         />
         <Button
           label={lang.modal.buttons.cancel}
           variant={BUTTON_VARIANT.SECONDARY}
           size={SIZES.DEFAULT}
           type={BUTTON_TYPE.DEFAULT}
           onBtnClick={() => this.setOpenResetDialog(false)}
         />
       </>
    )
  }

  openInfoDialogActions = () => {
    return (
      <Button
        label={lang.modal.buttons.ok}
        variant={BUTTON_VARIANT.PRIMARY}
        size={SIZES.DEFAULT}
        type={BUTTON_TYPE.DEFAULT}
        onBtnClick={() => this.setInfoDialogOpen(false)}
      />
    )
  }

  setInfoDialogOpen = (isOpen, infoMsg) => {
    let _this = this;
    _this.setState({
      openInfoDialog: isOpen,
      infoMsg: infoMsg
    })
  }

   openAddFirstPeriodDialogContent = () => {
     return (
       <>
         <h5 className="uk-margin-small-bottom uk-medium text uk-margin-xsmall-left">{MESSAGES.first_period_name}</h5>
         <FiscalPeriodTable
           ref={el => this.firstPeriodTableRef = el}
           id={"period_main_table_first"}
           tableColumns={firstPeriodColumns}
           tableData={tableData}
           setLimits={this.setLimits}
           setActivePeriodsLength={this.setActivePeriodsLength}
         />
       </>
     )
   }
   openAddFirstPeriodDialogActions = () => {
     return (
       <>
         <Button
           label={MESSAGES.modal.buttons.apply}
           variant={BUTTON_VARIANT.PRIMARY}
           size={SIZES.DEFAULT}
           type={BUTTON_TYPE.DEFAULT}
           onBtnClick={() => { this.validatePeriod() }}
         />
         <Button
           label={MESSAGES.modal.buttons.cancel}
           variant={BUTTON_VARIANT.SECONDARY}
           size={SIZES.DEFAULT}
           type={BUTTON_TYPE.DEFAULT}
           onBtnClick={() => this.setOpenAddFirstPeriodDialogOpen(false)}
         />
       </>
     )
   }
  setOpenAddFirstPeriodDialogOpen = (isOpen) => {
    let _this = this;
    _this.setState({
      openAddFirstPeriodDialog: isOpen
    })
  }

    render() {
        let limit = Number(this.props.active_period_limit);
        let activePeriodsLength = this.state.activePeriodsLength;
        let isAdd12Disabled = activePeriodsLength > limit -12;
        let isAddPeriodDisabled = activePeriodsLength >= limit;
        let isSaveDisabled = !this.state.isChanged;
        return (
            <div>
                <div id="active_periods_buttons_container">
                    <div className="uk-display-flex">
                        <span className="uk-text-bold uk-padding-xxsmall-right font-size-16"> {lang.active_periods} </span>
                        <span className="font-size-16">{this.state.activePeriodsLength +"/" + this.props.active_period_limit}</span>
                    </div>
                </div>
                <div>
                    <div id="toastFiscalCalendar" className="toast toast-success">
                        <div id="desc"><i className={"fa-lg fas" +(this.state.isError ? " fa-minus-circle uk-text-primary" : " fa-check-circle  uk-margin-small-right greenText" )}aria-hidden="true"></i>{this.state.message}</div>
                    </div>
                    <div id="calendar_container" style={{display:"flex", flexDirection:"column", height:"33.5vw"}}>
                        <div id="calendar_buttons_container " className="uk-display-flex justify-content-between">
                            <div className="uk-display-flex">
                                <div className="uk-margin-small-left height-45" id="add_period" uk-tooltip={isAddPeriodDisabled? lang.fiscal_calendar.messages.activate_limit_reached:undefined}>
                                    <Button 
                                        label={lang.fiscal_calendar.buttons.add_period}
                                        variant={BUTTON_VARIANT.SECONDARY}
                                        size={SIZES.DEFAULT}
                                        type={BUTTON_TYPE.DEFAULT}
                                        leftIcon={<i className={"far fa-plus-circle fa-lg"}  />}
                                        className="uk-margin-xsmall-top"
                                        disabled={isAddPeriodDisabled}
                                        onBtnClick={()=>{if(!isAddPeriodDisabled) this.addNewPeriod();}}
                                    />
                                </div>
                                <div className="uk-margin-small-left height-45" id="add_period_12" uk-tooltip={isAdd12Disabled? lang.fiscal_calendar.messages.cant_add_12:undefined}>
                                    <Button 
                                        label= {lang.fiscal_calendar.buttons.add_12_period}
                                        variant={BUTTON_VARIANT.SECONDARY}
                                        size={SIZES.DEFAULT}
                                        type={BUTTON_TYPE.DEFAULT}
                                        leftIcon={<i className={"far fa-plus-circle fa-lg"}  />}
                                        className="uk-margin-xsmall-top"
                                        disabled={isAdd12Disabled}
                                        onBtnClick={()=>{if(!isAdd12Disabled) this.turnFY(true);}}
                                    />
                                </div>
                            </div>
                            <div className="uk-display-flex">
                                {!isSaveDisabled?
                                    <div className="uk-display-flex">
                                        <div className="uk-margin-xsmall-top uk-margin-small-right">
                                            <span className="fs-12 red italic uk-display-flex uk-flex-middle uk-margin-xsmall-top">{lang.manage_columns.text.changes_not_saved}</span>
                                        </div>
                                        <div title="Reset" uk-tooltip={"title:"+MESSAGES.reset_warning+ "; pos: bottom"} className="uk-display-inline-block uk-margin-xsmall-top uk-margin-small-right uk-cursor-pointer" onClick={()=>this.showResetWarningPopup()}>
                                            <span className="fs-12 uk-text-decoration-underline uk-display-flex uk-flex-middle uk-margin-xsmall-top">{lang.navigation.btns.reset}</span>
                                        </div>
                                    </div>
                                    :""
                                }
                                <div className="uk-margin-xsmall-top uk-margin-xsmall-bottom" uk-tooltip={isSaveDisabled? "title: Changes already saved; pos: bottom":undefined} onClick={()=>{if(!isSaveDisabled) this.saveFiscalPeriods();}}>                                
                                    <Button 
                                        id="header-next"
                                        label={lang.navigation.btns.save}
                                        variant={BUTTON_VARIANT.PRIMARY}
                                        size={SIZES.DEFAULT}
                                        type={BUTTON_TYPE.DEFAULT}  
                                        disabled={isSaveDisabled}
                                        // onBtnClick={this.deactivatePeriod}
                                    />
                                </div>
                            </div>
                        </div>
                        <FiscalPeriodTable ref={el=> this.periodTableRef = el} id ={"period_main_table"} tableColumns={columns} tableData={this.state.data} discardChange={this.discardChange} 
                        savePreviousVersion={this.savePreviousVersion} showDeletePopup={this.showDeletePopup} setLimits={this.setLimits} setActivePeriodsLength={this.setActivePeriodsLength} active_period_limit={this.props.active_period_limit}
                        setInfoDialogOpen={this.setInfoDialogOpen}
                        />              
                    </div>
                </div>
                <Modal 
                  id={"deactivate-period-confirmation-dialog"}
                  title={lang.modal.buttons.deactivate_period}
                  openDialog={this.state.openDeactivatePeriodConfirmDialog}
                  bodyContent={this.openDeactivatePeriodConfirmDialogContent}
                  dialogActions={this.openDeactivatePeriodConfirmDialogActions}
                  closeClick={() => this.setOpenDeactivatePeriodConfirmDialog(false)}     
                  size={DIALOG_SIZE.MEDIUM}
                />
                <Modal 
                  id={"reset-dialog"}
                  openDialog={this.state.openResetDialog}
                  bodyContent={() => <h4>{MESSAGES.reset_warning}</h4>}
                  dialogActions={this.openResetDialogActions}
                  closeClick={() => this.setOpenResetDialog(false)}     
                  size={DIALOG_SIZE.MEDIUM}
                />
                <Modal 
                  id={"info-msgs-dialog"}
                  openDialog={this.state.openInfoDialog}
                  bodyContent={() => <h4>{this.state.infoMsg}</h4>}
                  dialogActions={this.openInfoDialogActions}
                  closeClick={() => this.setInfoDialogOpen(false, "")}     
                  size={DIALOG_SIZE.MEDIUM}
                />
                <Modal 
                  id={"add-first-period-dialog"}
                  openDialog={this.state.openAddFirstPeriodDialog}
                  bodyContent={this.openAddFirstPeriodDialogContent}
                  dialogActions={this.openAddFirstPeriodDialogActions}
                  closeClick={() => this.setOpenAddFirstPeriodDialogOpen(false)}     
                  size={DIALOG_SIZE.XLARGE}
                />
            </div>
        );
      
    }
}

export default FiscalYear;