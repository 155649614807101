export function updateBridgeOptions(bridgeOptions, scenarioId, clear) {
  return {
    type: 'UPDATE_BRIDGE_OPTIONS',
    bridgeOptions: bridgeOptions,
    scenarioId: scenarioId,
    clear: clear

  }
}

export function getBridgeOptions() {
  return {
    type: 'GET_BRIDGE_OPTIONS'
  }
}