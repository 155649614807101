import React, { Component } from 'react';
import Popup from 'react-popup';
import { TabulatorFull  } from "tabulator-tables"; // for full package

import { cleanUpTabulatorColumns, setLocalStorageValueByParameter, toggleLoader } from '../../class/common.js';
import { BUTTON_TYPE, BUTTON_VARIANT, CHECK_FIELD, DASHBOARDS, DROPDOWN_TYPE, FormatTypes, MANAGE_CUSTOM_REPORT, MANAGE_REPORT, MENU_ITEM, ROW_STATUS, SIZES } from '../../class/constants';
import { convertPxToViewport } from '../../class/formatting';
import { checkForSpecialChars, checkStartsWithDigit, isBlank, isStringSurpassingXCharacters } from "../../class/string";
import { copyObjectValues, getTranslationFile, updateAllData, updateData } from '../../class/utils.js';
import { lang } from '../../language/messages_en';
import Input from '../../newComponents/Input';
import SwitchToggle from '../../newComponents/SwitchToggle';
import Button from '../../newComponents/Button';
import { createCheckbox } from '../../newComponents/CheckBoxTabulator';
import DropDown from '../../newComponents/DropDown';
import Container from '../../components/manageColumns/Container.js';
import { getDestinationReports } from '../../class/acl.js';
import { FETCHAPI_PARAMS, FETCH_METHOD, fetchAPI } from '../../class/networkUtils.js';

const $ = require('jquery');
const MESSAGES = getTranslationFile();


const baseUrl = process.env.REACT_APP_BASE_URL;
const path = "/Admin";
const CHECK_TITLE = "";
const checkboxCellObject = {
    title: CHECK_TITLE,
    field: CHECK_FIELD,
    visible: true,
    headerSort: false,
    width: 5
}

var REPORT_COLUMNS = [
    {   
        title: MANAGE_REPORT.TITLES.ACCESS_GROUPS,
        field: MANAGE_REPORT.FIELDS.NAME,
        format_type: FormatTypes.TEXT,
        widthGrow: 2,
        widthShrink: 0,
        headerSort:false
    },
    {   
        title: MANAGE_REPORT.FIELDS.GROUP_ID,
        field: MANAGE_REPORT.FIELDS.GROUP_ID,
        format_type: FormatTypes.TEXT,
        visible:false,
        headerSort:false
    },
    
    
];

/**
 * Mapping window in Manage Reports
 * @author [Sarah Farjallah]
 */
class ReportMapping extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPeriodAllowed: props.reportRow && props.reportRow[MANAGE_REPORT.FIELDS.ALLOWED_PARAMS] ?  JSON.parse(props.reportRow[MANAGE_REPORT.FIELDS.ALLOWED_PARAMS])[MANAGE_REPORT.FIELDS.PARAMS.PERIOD_SELECTION] : false,
            isValidReport: false,
            [MANAGE_CUSTOM_REPORT.DESCRIPTION]:"",
            [MANAGE_CUSTOM_REPORT.NAME]:"",
            [MANAGE_CUSTOM_REPORT.URL]:"",
            pslines: getDestinationReports(this.props.userAllowedMenuLinks),
            errorMessage: {
                name: "",
                url: ""
            },
        };

        this.updateFields = this.updateFields.bind(this);
        this.giveAccessToAll = this.giveAccessToAll.bind(this);
        this.checkCell = this.checkCell.bind(this);
        this.checkColumn = this.checkColumn.bind(this);
        this.isNameUsed = this.isNameUsed.bind(this);
        this.discardChanges = this.discardChanges.bind(this);
    }

    getRowObject(){
        var obj = this;
        var today = new Date();
        var _day = today.getDate();
        var _month = today.getMonth()+1;
        var _year = today.getFullYear();
        var reportObj = {
            [MANAGE_REPORT.FIELDS.NAME]: obj.state.reportName,
            [MANAGE_REPORT.FIELDS.DESCRIPTION]:  obj.state.reportDescription,
            [MANAGE_REPORT.FIELDS.CREATION_DATE]:obj.props.reportRow[MANAGE_REPORT.FIELDS.CREATION_DATE] ? obj.props.reportRow[MANAGE_REPORT.FIELDS.CREATION_DATE] :_month+"/" +_day + "/" +_year,
            [MANAGE_REPORT.FIELDS.CLICKS]: "",
            [MANAGE_REPORT.FIELDS.ID]: obj.props.reportRow[MANAGE_REPORT.FIELDS.ID],
            [MANAGE_REPORT.FIELDS.SECTION_ID]: obj.props.reportRow[MANAGE_REPORT.FIELDS.SECTION_ID],
            [MANAGE_REPORT.FIELDS.ROW_STATUS]: obj.props.reportRow[MANAGE_REPORT.FIELDS.ROW_STATUS],
            [MANAGE_REPORT.FIELDS.URL]: obj.state.reportUrl,
            [MANAGE_REPORT.FIELDS.IS_TABLEAU_REPORT]: this.isUrlTableau(obj.state.reportUrl),
            [MANAGE_REPORT.FIELDS.ALLOWED_PARAMS]: {
                [MANAGE_REPORT.FIELDS.PARAMS.PERIOD_SELECTION]: this.state.isPeriodAllowed,
            }
        }
        return reportObj;
    }

    isNameUsed(name){
        var currObj = this.getRowObject();
        for(var e in this.props.reports) {
            if (name === this.props.reports[e][MANAGE_REPORT.FIELDS.NAME] && currObj[MANAGE_REPORT.FIELDS.SECTION_ID] !== this.props.reports[e][MANAGE_REPORT.FIELDS.SECTION_ID]) {
                return true
            }
        }
        return false;
    }

    addCheckRowFormatter(cell, params) {
        var div = document.createElement("div");

        var p = document.createElement("p");
        p.innerHTML = cell.getValue();

        var checkbox = createCheckbox();
        checkbox.name='chosenEntity';
        checkbox.id = "main_checkbox";
        checkbox.classList.add('chosenEntity', 'uk-margin-small-right');
        checkbox.onchange = params._func;
        div.appendChild(checkbox);
        return div;
    }

    checkColumn() {
        var data = this.tabulator.getData();
        var countAllRows = 0;

        data.map(function (item) {
            if (item["checked"] && item["checked"] === "true") {
                countAllRows++;
            }
        });

        if (countAllRows === data.length) {
            $("#main_checkbox").prop('indeterminate', false);
            $("#main_checkbox").prop('checked', true);
        } else if (countAllRows === 0) {
            $("#main_checkbox").prop('checked', false);
            $("#main_checkbox").prop('indeterminate', false);
        } else if (countAllRows < data.length) {
            $("#main_checkbox").prop('checked', false);
            $("#main_checkbox").prop('indeterminate', true);
        }
    }

    giveAccessToAll(){
        var data = this.state.groups;
        var count = data.filter(el => el.checked === "true").length;
        var length = this.tabulator.getData().length;
        if (count !== length) {
            data.map(function (group) {
                group["checked"] = "true";
            });
        } else {
            data.map(function(group){
                group["checked"] = "false";
            })
        }
        this.state.groups = data;
        this.tabulator.replaceData(data);
        this.checkColumn();
    }

    checkCell(cell) {
        var data = this.state.groups;
        data.map(function (group) {
            if(group[MANAGE_REPORT.FIELDS.NAME] === cell.getRow().getData()[MANAGE_REPORT.FIELDS.NAME]) {
                group["checked"] =  group["checked"] === "true" ? "false" : "true";
            }
        });
        this.tabulator.replaceData(data);
    }

    
    handleReportDestinationChange(selected){
        let pslines = this.state.pslines;
        updateAllData(pslines, false); // uncheck all checked lines
        updateData(pslines, selected);
        let _this = this
        _this.setState({
            pslines: pslines,
            reportDestination: selected.value
        },()=>{
            _this.validateReport()
            _this.updateErrorOnBlur();
        })
    }

    getReportAccessGroups(name) {
        let _this = this;
        var query = {
            action: "getReportAccessGroups",
            name:name
        }
        let onThenCallback = (data) => {
            var groups = _this.prepareCheckedAccessGroups(data.selectedgroups)
            _this.setState({
                groups: groups,
                originalGroups: copyObjectValues(groups)
            },function(){
                _this.tabulator.replaceData(groups);
            });
          };
          let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getReportAccessGroups",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: path,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]: lang.observability.output.manage_custom_reports.screen_name,
            [FETCHAPI_PARAMS.requestDescription]: lang.observability.output.manage_custom_reports.request_description.access_groups,
          };
          fetchAPI(fetchOptions);
        }
       

    /**
     * Check if the url is a valid tableau report
     * @param {*} url 
     * @returns 
     */
    isUrlTableau = (url) => {
        return url.includes(process.env.REACT_APP_TABLEAU_URL);
    }

    updateErrorOnBlur= ()=>{
        let tempError = {name:"", url:""};
        if(isBlank(this.state.reportName)){
            tempError.name = lang.manage_reports.empty_name;
        }
        if(isBlank(this.state.reportUrl)){
            tempError.url = lang.manage_reports.invalid_url;
        }
        if(isStringSurpassingXCharacters(this.state.reportDescription, this.props.reportDesrcriptionSizeLimit)){
            tempError.description = lang.manage_reports.long_description.replace("%X%",this.props.reportDesrcriptionSizeLimit);
        }
        if (checkForSpecialChars(this.state.reportName.replace(/ /g,'')) || checkStartsWithDigit(this.state.reportName)) {
            tempError.name = lang.manage_reports.invalid_name;
        }
        if (this.isNameUsed(this.state.reportName)) {
            tempError.name = lang.manage_reports.name_used;
        }
        if($("#period_selection")[0].checked && !this.isUrlTableau(this.state.reportUrl)) {
            tempError.url = lang.manage_reports.url_not_tableau;
        }
        if ($("#select_report_destination").text() === "") {
            tempError.destination = lang.manage_reports.select_destination_group;
        }
        this.setState({
            errorMessage : tempError
        });
    }
    

    validateReport=()=>{
        let tempReportValid = true;
        if(isBlank(this.state.reportName) || isBlank(this.state.reportUrl)){
            tempReportValid = false;
        }
        if(isStringSurpassingXCharacters(this.state.reportDescription, this.props.reportDesrcriptionSizeLimit)){
            tempReportValid = false;
        }
        if (checkForSpecialChars(this.state.reportName.replace(/ /g,'')) || checkStartsWithDigit(this.state.reportName)) {
            tempReportValid = false;
        }
        if (this.isNameUsed(this.state.reportName)) {
            tempReportValid = false;
        }
        if($("#period_selection")[0].checked && !this.isUrlTableau(this.state.reportUrl)) {
            tempReportValid = false;
        }
        if ($("#select_report_destination").text() === "") {
            tempReportValid = false;
        }
        this.setState({
            isValidReport: tempReportValid,
        });
    }

    saveReport() {
        var obj = this;
        this.validateReport();
        if (!this.state.isValidReport) {
            return;
        }
        var data = [];
       var checkedData =  obj.tabulator.getData().filter(e=>e["checked"] === "true");
        var groupsToBeGivenAccessTo = [];
        var originalGroups = obj.state.originalGroups;
        data.push(this.getRowObject());
        if (originalGroups.length > 0) {
            for(var e in checkedData) {
                if (originalGroups.filter(elt=>elt[MANAGE_REPORT.FIELDS.NAME] === checkedData[e][MANAGE_REPORT.FIELDS.NAME] && elt["checked"] === "false").length > 0){
                    groupsToBeGivenAccessTo.push(checkedData[e]);
                }
            }
        }else {
            groupsToBeGivenAccessTo = checkedData;
        }
        
        var query = {
            action: "saveReport",
            data:data,
            groups: groupsToBeGivenAccessTo,
            uncheckedGroups: obj.tabulator.getData().filter(e=>e["checked"] === "false"),
            destinationReport: obj.state.reportDestination
        }
        let onThenCallback = (data) => {
            if (data.result === 'SUCCESS') {
                obj.props.hideFormula();
                // setTimeout(()=>{
                //     window.location.reload();
                // },3000)
                obj.props.refreshUserAllowedMenuLinks()
            }
          };
          let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "saveReport",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: path,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]: lang.observability.output.manage_custom_reports.screen_name,
            [FETCHAPI_PARAMS.requestDescription]: lang.observability.output.manage_custom_reports.request_description.save,
          };
          fetchAPI(fetchOptions);
    }


    prepareCheckedAccessGroups(data) {
        var _this = this;
        var _allgroups = copyObjectValues(_this.props.accessGroups);
        if (data) {
            var _returneddata = [];
            for (var e in _allgroups) {
                if (data.filter(elt=>elt[MANAGE_REPORT.FIELDS.NAME] === _allgroups[e][MANAGE_REPORT.FIELDS.NAME]).length > 0){
                    _allgroups[e].checked = "true";
                    _returneddata.push(_allgroups[e]);
                }else {
                    _allgroups[e].checked = "false";
                    _returneddata.push(_allgroups[e]);
                }
            }
        }else{
            return _allgroups;
        }
        
        return _returneddata;
    }

    updateFields(row) {
        let _this = this;
        let jsonParams = row[MANAGE_REPORT.FIELDS.ALLOWED_PARAMS] ? JSON.parse(row[MANAGE_REPORT.FIELDS.ALLOWED_PARAMS]) : undefined;

        $("#period_selection")[0].checked = jsonParams && jsonParams[MANAGE_REPORT.FIELDS.PARAMS.PERIOD_SELECTION]
        let defaultReportValue = {value:row.menu_item_parent_id};
        let pslines = this.state.pslines;
        if(defaultReportValue){
            updateAllData(pslines, false); // uncheck all checked lines
            updateData(pslines, defaultReportValue);
        }
        _this.setState({
            pslines: pslines,
            reportDestination: defaultReportValue?.value,
            reportDescription:row[MANAGE_REPORT.FIELDS.DESCRIPTION],
            reportName:row[MANAGE_REPORT.FIELDS.NAME],
            reportUrl:row[MANAGE_REPORT.FIELDS.URL]
        },()=>{
            _this.validateReport()
        })
    }

    discardChanges(){
        this.props.hideFormula(true);
    }

    getColumnFormatter(colTitle) {
        var obj = this;
		var columnFormatter;		
        switch (colTitle) {
			case CHECK_TITLE:
			columnFormatter = function(cell) {
                var checkbox = createCheckbox();
                checkbox.name='chosenEntity';
                checkbox.classList.add('chosenEntity');
                checkbox.checked = cell.getData()["checked"] === "true";
                obj.checkColumn();
                checkbox.onchange = () =>{
                    obj.checkCell(cell);
                }
                return checkbox;
                
            };
            break;
			default:
			columnFormatter = function(cell, formatterParams) {
				return cell.getValue();
			}
			break;
		}
		
		return columnFormatter;
    }

    componentDidMount() {
        var obj = this;
        if (this.props.reportRow[MANAGE_REPORT.FIELDS.ROW_STATUS] !== ROW_STATUS.VALUES.NEW) {
            this.updateFields(this.props.reportRow);
            this.getReportAccessGroups(this.props.reportRow[MANAGE_REPORT.FIELDS.NAME]);
        }
        var options = {
            layout: "fitColumns",      //fit columns to width of table
            responsiveLayout: true,  //hide columns that dont fit on the table
            tooltips: true,            //show tool tips on cells
            addRowPos: "top",          //when adding a new row, add it to the top of the table
            history: true,             //allow undo and redo actions on the table
            pagination: false, //"local",       //paginate the data
            movableColumns: false,     //allow column order to be changed
            selectable: false,
            movableRows: false,
            resizableColumns: true,
            autoResize: true,
            resizableRows: false,       //allow row order to be changed
            dataTreeStartExpanded: false,
            dataTreeBranchElement: false, //hide branch element
            virtualDomBuffer: 800,
            placeholder: MESSAGES.no_data_available,
            height: convertPxToViewport(275),
            width: "100%",
            tooltips:function(column){
                return column._cell.value;
            }
        };
        this.tabulator = new TabulatorFull(this.refs.mainTable, options);
        var tableColumns = cleanUpTabulatorColumns(copyObjectValues(REPORT_COLUMNS), null, this.refreshFilterDivs, this.tabulator, {id: "report-mapping"});
        tableColumns.unshift(checkboxCellObject);		//add checkbox column as the first column
        tableColumns.forEach(col => {
            if(col.title === CHECK_TITLE){
                col.titleFormatter = this.addCheckRowFormatter;  
                col.titleFormatterParams = {
                    _func:this.giveAccessToAll
                }
            }
            col.formatter = obj.getColumnFormatter(col.title);
        });
        // Set columns and replace data after table is built
        this.tabulator.on("tableBuilt", () => {
            this.tabulator.setColumns(tableColumns);
            if (this.props.reportRow[MANAGE_REPORT.FIELDS.ROW_STATUS] === ROW_STATUS.VALUES.NEW) {
                this.setState({
                    groups: copyObjectValues(this.props.accessGroups),
                    originalGroups: []
                }, () => {
                    this.tabulator.replaceData(copyObjectValues(this.props.accessGroups));
                });
            }
        });
        // this.validateReport();
    }
    
    shouldComponentUpdate(nextProps) {
        var shouldUpdate = true;
        return shouldUpdate;
    }

    onToggleChange = (e) => {
        let _this = this;
        
        _this.setState({
            isPeriodAllowed: e.target.checked
        })
    }
    updateErrorAndToggle = (e) => {
        this.validateReport();
        this.updateErrorOnBlur();
        this.onToggleChange(e);
    }

    handleEelementChange=(attribute, e)=>{
        let _this = this;
        this.setState({
            [attribute]: e.target.value
        },()=>{
            _this.updateErrorOnBlur();
            _this.validateReport();
        })
    }


    render() {

        return(
            // <Popup />
            <div style={{height:"100%"}}>
                <div className="mapping-header">
                     <span className="uk-text-bold uk-text-xmedium">
                            Add Special Report
                        <i className="fs-12 fal fa-info-circle uk-margin-small-left uk-cursor-pointer" uk-tooltip={"TBD"} />
                    </span>
                </div>
                <div className="uk-padding-large-left-right">
                    <div className="uk-margin-xsmall-bottom uk-padding-xmedium-top">
                        <span className="fs-14">Report Name</span>
                        <i className="fs-12 fal fa-info-circle uk-margin-small-left uk-cursor-pointer" uk-tooltip={lang.manage_reports.report_name}/>
                        
                    </div>
                    <div>
                        <Input type="text" id="report_name" className="form-control width-350" value={this.state.reportName} onChange={(e) => this.handleEelementChange(MANAGE_CUSTOM_REPORT.NAME, e)} placeholder={lang.manage_reports.report_name_placeholder}/>
                        {!this.state.isValidReport?
                            <p id="name-warn" className='red italic uk-margin-default-right'>{this.state.errorMessage.name}</p> : ""}
                    </div>
                    <div className="uk-margin-xsmall-bottom uk-padding-xsmall-top">
                        <span className="fs-14">URL</span>
                        <i className="fs-12 fal fa-info-circle uk-margin-small-left uk-cursor-pointer" uk-tooltip={lang.manage_reports.report_link}/>
                    </div>
                    <div className="uk-flex uk-flex-middle uk-flex-between width-576">
                        <Input type="text" value={this.state.reportUrl} id="report_url" className="form-control width-350" onChange={(e) => this.handleEelementChange(MANAGE_CUSTOM_REPORT.URL, e)} placeholder={lang.manage_reports.report_url_placeholder}/>
                        <div className="uk-flex uk-flex-middle">
                            <SwitchToggle id={"period_selection"} onChange={(e) => this.updateErrorAndToggle(e)}/>
                            <p className="fs-14 info-hover">{lang.manage_reports.allow_period_selection}
                            </p>
                        </div>
                    </div>
                    {!this.state.isValidReport?   
                            <p id="url-warn" className='red italic uk-margin-default-right'>{this.state.errorMessage.url}</p>:""}
                    <div className="uk-flex uk-flex-middle uk-flex-between width-350">
                        <Container
                            id="select_report_destination"
                            data={this.state.pslines}
                            texts={{ placeholder: lang.manage_reports.select_a_destination_group }}
                            onChange={(e) => this.handleReportDestinationChange(e)}
                            mode={"radioSelect"}
                            className={
                                "x-axis landscape-configure heatmap-configure dropdown-tree uk-margin-small-top input__dropdown max_width"
                            }
                        />
                    </div>
                    {!this.state.isValidReport?   
                            <p id="url-warn" className='red italic uk-margin-default-right'>{this.state.errorMessage.destination}</p>:""}
                    <div className="uk-margin-xsmall-bottom uk-padding-xsmall-top">
                        <span className="fs-14">Description</span>
                        <i className="fs-12 fal fa-info-circle uk-margin-small-left uk-cursor-pointer" uk-tooltip={lang.manage_reports.report_description}/>
                    </div>
                    <textarea id="report_description" value={this.state.reportDescription} onChange={(e) => this.handleEelementChange(MANAGE_CUSTOM_REPORT.DESCRIPTION, e)} className="form-control uk-textarea textarea-width-350 input-default" placeholder={lang.manage_reports.report_description_placeholder}/>
                    {!this.state.isValidReport? 
                        <p id="name-warn" className='red italic uk-margin-default-right'>{this.state.errorMessage.description}</p> 
                    : ""}

                    <div className="uk-border uk-margin-medium-top-bottom">
                        <div className="mapping-header">
                            <span className="uk-text-bold uk-text-xmedium">Share with</span>
                            <i className="fs-12 fal fa-info-circle uk-margin-small-left uk-cursor-pointer" uk-tooltip={lang.manage_reports.report_access_groups} />
                        </div>
                        <div id="report-mapping" ref="mainTable"/>
                    </div>
                </div>
                <div id="submit_GL" className="gl-mapping-footer uk-display-flex justify-content-end">
                    <Button
                        label={MESSAGES.modal.buttons.cancel}
                        variant={BUTTON_VARIANT.SECONDARY}
                        size={SIZES.DEFAULT}
                        type={BUTTON_TYPE.DEFAULT}
                        onBtnClick={this.discardChanges}
                    />
                    <Button 
                        label={"Save Changes"}
                        variant={BUTTON_VARIANT.PRIMARY}
                        size={SIZES.DEFAULT}
                        type={BUTTON_TYPE.DEFAULT}  
                        className="uk-margin-default-left"
                        disabled={!this.state.isValidReport}
                        onBtnClick={()=>{this.saveReport()}}
                    />
                </div>
            </div>
        );

    }

}

export default ReportMapping;