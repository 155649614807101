export const termsAndConditions = `
<h3>Terms of Service</h3></br>
<p>Last updated: July 5th 2022</p>
</br></br></br>
<p>By using this application you are agreeing to the MSA your company had signed. For any security-related questions or feedback please email us at</p></br></br>

<a href="mailto:security@profitisle.com">security@profitisle.com</a>
`
export const termsRegister = `
<h3>Terms of Service</h3></br>
<p style="font-size: 0.78vw">Last updated: July 5th 2022</p>
</br></br></br>
<p style="font-size: 0.73vw">By clicking next you are agreeing to the MSA your company had signed.</p> 
</br></br>
<p style="font-size: 0.73vw">For any security-related questions or feedback please email us at&nbsp<a style="color: blue" href="mailto:security@profitisle.com">security@profitisle.com</a></p></br></br>
</br>

`